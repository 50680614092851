import React, { Dispatch, SetStateAction } from "react";
import { Col, Row } from "react-bootstrap";
import { ActivityLogInfo } from "@preveil-api";

type AllProps = {
  log: ActivityLogInfo;
  setOpenLog: Dispatch<SetStateAction<ActivityLogInfo | undefined>>;
}

// Description: Row component for each log in the list. 
function LogsRowComponent(props: AllProps) {
  const { log, setOpenLog } = props;

  function navigateToView() {
    setOpenLog(log);
  }

  return (
    <Row onClick={navigateToView}>
      <Col lg={6}>
        <span className="log-user" data-tooltip-id="pv-tooltip" data-tooltip-content={log.actor.address}>
          {log.actor.name}{" "}
        </span>
        {log.tooltip.length > 0 ? (
          <span data-tooltip-id="pv-tooltip" data-tooltip-content={log.tooltip} dangerouslySetInnerHTML={{ __html: log.mapped_action }}></span>
        ) : (
          <span dangerouslySetInnerHTML={{ __html: log.mapped_action }}></span>
        )}
        {!!log.relevant_users && log.relevant_users.length > 0 && log.relevant_users.map((user, i) => (
          <span
            className="log-user"
            key={i}
            data-tooltip-id="pv-tooltip"
            data-tooltip-content={user.address}
          >
            {user.name}
            {i < log.relevant_users.length - 1 ? "," : ""}
          </span>
        ))}
        {!!log.sub_details && <span dangerouslySetInnerHTML={{ __html: log.sub_details }}></span>}
      </Col>
      <Col lg={3}>{log.formatted_timestamp}</Col>
      <Col lg={1} className="with_cap">{log.topic}</Col>
      <Col lg={2}>{log.device || "--"}</Col>
    </Row>
  );
}

export default React.memo(LogsRowComponent);
