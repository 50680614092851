import React, { useEffect, useState, FocusEvent, FormEvent, KeyboardEvent, useRef, useCallback } from "react";
import { InputGroup, Form, Button } from "react-bootstrap";
import { ActionHandlerFunction, CollectionServerUser, UserProfile } from "@preveil-api";
import { isSameProfile, profileDisplayEmail, RegexHelper, KeyCode, SettingsUIActionTypes, SettingsErrorMessages } from "src/common";
import { Icon } from "src/components";
import { UserAutocomplete } from ".";
import _ from "lodash";

type AllProps = {
  focus?: boolean;
  profiles?: UserProfile[];
  org_users?: CollectionServerUser[];
  single_user?: boolean;
  placeholder?: string;
  handleAction: ActionHandlerFunction;
}

function UserSearchFormComponent(props: AllProps) {
  const { profiles, focus, org_users, single_user, handleAction, placeholder } = props;
  const [search_term, setSearchTerm] = useState<string>("");
  const [found_profiles, setFoundProfiles] = useState<UserProfile[] | null>(null);
  const [active, setActive] = useState<UserProfile | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [not_org_user, setNotPartOfOrg] = useState<boolean>(false);
  const search_ref = useRef<HTMLInputElement>(null);
  const debounce_time = 200;

  useEffect(() => {
    (!!search_ref.current && focus) && search_ref.current.focus();
  }, [focus]);

  // Description: Callback with debounce to handle user input in textbox
  const onSearchHandler = useCallback(
    _.debounce((_searchTerm: string, _profiles: UserProfile[]) => {
      if (!!_searchTerm && _searchTerm.length > 0 && _profiles.length > 0) {
        const term = _searchTerm.toLowerCase();
        const found = _.filter(_profiles.slice(), (profile: UserProfile) => {
          const display_email = profileDisplayEmail(profile).toLowerCase();
          const display_name = profile.name?.toLowerCase() || "";
          return !!profile.address && (display_email.includes(term) || display_name.includes(term));
        });
        setFoundProfiles(found);
        found.length > 0 && setActive(found[0]);
      };
    }, debounce_time), []);

  // Description: Set the current state for controlled form and propagate to store state
  function handleChange(e: FocusEvent<HTMLInputElement>) {
    error && setError(false);
    not_org_user && setNotPartOfOrg(false);
    const new_searchterm = e.currentTarget.value.trim();
    if (new_searchterm.includes(" ") || new_searchterm.includes(",")) { // Handle copy paste multiple
      handleSubmitMultiple(new_searchterm);
    } else {
      setSearchTerm(e.currentTarget.value);
      (!!new_searchterm && new_searchterm.length > 0 && !!profiles && profiles.length > 0) ?
        onSearchHandler(new_searchterm, profiles) : handleResetAutocomplete();
    }
  }

  // Description: Reset autocomplete data
  function handleResetAutocomplete() {
    setTimeout(() => {   // Note: set on a timeout to go after the debounce
      setFoundProfiles(null);
      setActive(null);
    }, debounce_time);
  }

  // Description: Handle all types of setting profile Form and autocomplete
  function handleSetProfile(profile: UserProfile) {
    setSearchTerm(""); // Reset search
    handleAction({ actionType: SettingsUIActionTypes.SetUserProfile, params: [profile] });
  }

  // Description: Select a user profile from the autocomplete window
  function handleSelectProfile(profile: UserProfile | null) {
    !!profile && handleSetProfile(profile);
    handleResetAutocomplete();
  }

  // Description: Select a new user email
  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    e.stopPropagation();
    const email = e.currentTarget.email.value.trim().toLowerCase();
    if (!!active) {
      handleSetProfile(active);
    } else if (RegexHelper.testEmailAddress(email)) {
      if (!!org_users && org_users?.filter((u) => u.user_id === email).length === 0) {
        setNotPartOfOrg(true);
      } else {
        handleSetProfile({ address: email, name: null });
      }
    } else {
      setError(true);
    }
    handleResetAutocomplete();
  };

  // Description: Deal with Multiple users at once
  function handleSubmitMultiple(searchterm: string) {
    const delimmit = searchterm.includes(",") ? "," : " ";
    const arr = searchterm.split(delimmit);
    const _profiles = arr.length > 0 ? _.compact(_.map(arr, (email: string) => {
      const address = email.trim();
      return RegexHelper.testEmailAddress(address) ? { address, name: null } : undefined;
    })) : null;
    // NOTE: Only pass the first user if only single_user is set
    handleAction({
      actionType: SettingsUIActionTypes.SetUserProfile,
      params: (!!single_user && !!_profiles && _profiles?.length > 0) ? [_profiles[0]] : _profiles
    });
  }

  // Description: Reset form on close click
  function handleFormReset() {
    setError(false);
    setNotPartOfOrg(false);
    setSearchTerm("");
    handleResetAutocomplete();
  }

  // Description: KeyDown events:
  //    - Key ArrowDown and ArrowUp Select active profile
  //    - On Tab submit form with Selected or item in search box
  //       only if field has content otherwise tab to next field
  function handleKeyDown(e: KeyboardEvent<HTMLFormElement>) {
    if (e.key === KeyCode.Tab) {
      e.currentTarget.email.value.trim().length > 0 && handleSubmit(e);
    }

    if (!!found_profiles && !!active && (e.key === KeyCode.ArrowDown || e.key === KeyCode.ArrowUp)) {
      const index = _.findIndex(found_profiles, (_active: UserProfile) => isSameProfile(_active, active));
      const new_index = e.key === KeyCode.ArrowDown ? index + 1 : index - 1;
      (e.key === KeyCode.ArrowDown) ? setActive(!!found_profiles[new_index] ? { ...found_profiles[new_index] } : { ...found_profiles[0] }) :
        setActive(!!found_profiles[new_index] ? { ...found_profiles[new_index] } : { ...found_profiles[(found_profiles.length - 1)] });
    }
  }

  return <Form className="user-autocomplete" noValidate onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
    <InputGroup className={error || not_org_user ? "is-invalid" : ""}>
      <Form.Control
        type="email"
        ref={search_ref}
        autoComplete="off"
        name="email"
        value={search_term}
        placeholder={placeholder || ""}
        data-tooltip-content={error && search_term.length > 0 ? SettingsErrorMessages.invalid_email_address : not_org_user ?
          SettingsErrorMessages.not_part_of_org : ""}
        data-tooltip-id="pv-tooltip"
        data-tooltip-place="bottom"
        onChange={handleChange} />
      {!!search_term &&
        <Button variant="icon" className="text-muted-light" size="sm"
          onClick={handleFormReset}><Icon className="ficon-x-circle" /></Button>}
    </InputGroup>
    {
      (!!found_profiles && found_profiles.length > 0) &&
      <UserAutocomplete found_profiles={found_profiles} active={active} search_term={search_term} handleAction={handleSelectProfile} />
    }
  </Form>;
}

export default React.memo(UserSearchFormComponent);
