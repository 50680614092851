import React, { Dispatch, SetStateAction } from "react";
import { Button, Modal, Image } from "react-bootstrap";
import lockImg from "src/assets/images/account-lock.svg";
import { DeviceActionInfo, DeviceAction, SettingsMessages } from "src/common";

type AllProps = {
  device_name: string;
  action: string;
  func: Function;
  modal_show: boolean;
  setModalShow: Dispatch<SetStateAction<boolean>>;
};

/* Description: confirmation Modal Component for the disconnect, lock and delete device actions. 
Made into a separate component as they all have a similar modal structure - with the lock image and 
longer descriptions - hence did not use the MessageHandler component for this. */
function DeviceConfirmationComponent(props: AllProps) {
  const { device_name, action, func, modal_show, setModalShow } = props;

  // Description: Returns the string info for the relevant confirmation modal
  function getInfo(): { title: string, warning: string, info: string } {
    if (action === DeviceAction.lock) {
      return DeviceActionInfo.lock;
    } else if (action === DeviceAction.delete) {
      return DeviceActionInfo.delete;
    } else {
      return DeviceActionInfo.disconnect;
    }
  }

  // Description: Returns the Title for the relevant confirmation modal
  function confirmText(): string {
    if (action === DeviceAction.lock) {
      return `${DeviceAction.delete} "${device_name}"?`;
    } else if (action === DeviceAction.delete) {
      return `${DeviceAction.delete} "${device_name}"?`;
    } else {
      return SettingsMessages.confirm_disconnect;
    }
  }

  return (
    <Modal show={modal_show} onHide={() => setModalShow(false)} centered aria-labelledby="device confirmation modal">
      <Modal.Header>
        <Modal.Title>{getInfo().title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Image alt="Lock Image" className="mb-4 lock-image" src={lockImg} />
        <p>{getInfo().warning}</p>
        <p>{getInfo().info}</p>
        <p><b>Are you sure you want to {confirmText()}</b></p>
        <Button className="me-2" onClick={() => { setModalShow(false); func(); }}>Yes</Button>
        <Button onClick={() => setModalShow(false)} variant="no-outline-primary">Cancel</Button>
      </Modal.Body>
    </Modal>
  );
}

export default React.memo(DeviceConfirmationComponent);
