import { OrgInfo } from "@preveil-api";
import * as React from "react";
import { Link } from "react-router-dom";
import { DefaultRoutes, NavigationPathTypes, NavigationPath } from "src/common";
import { Icon } from "src/components";

type AllProps = {
  type?: NavigationPathTypes;
  admin_enabled?: boolean;
  organization?: OrgInfo;
}

function UserNavigation(props: AllProps) {
  const { type, admin_enabled } = props;
  
  return <>
    <Link title="Go to Mail" to={DefaultRoutes.mail_default}
      className={type === NavigationPath.MailNavigation ? " active" : ""}>
      <Icon className="ficon-mail hide-desktop" />
      <span className="show-desktop">Mail</span>
    </Link>
    <Link title="Go to Drive"
      to={DefaultRoutes.drive_root}
      className={type === NavigationPath.DriveNavigation ? " active" : ""}>
      <Icon className="ficon-folder hide-desktop" />
      <span className="show-desktop">Drive</span>
    </Link>
    {admin_enabled && 
    <Link title="Go to Admin"
      to={DefaultRoutes.admin_default}
      className={type === NavigationPath.AdminNavigation ? " active" : ""}>
      <Icon className="ficon-users hide-desktop" />
      <span className="show-desktop">Admin</span>
    </Link>}
    {/* <Link title="Go to Chat"
      to={`/${PrivateRoutes.chat_route}`}
      className={type === NavigationPath.ChatNavigation ? " active" : ""}>
      <Icon className="ficon-message-square hide-desktop" />
      <b className="show-desktop">Chat</b>
    </Link> */}
  </>;
}

export default React.memo(UserNavigation);
