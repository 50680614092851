// Description: This stores the request callback by RequestID
import { FSRequest, FSEnvelope } from "src/common/keys/protos/collections_pb"; // FSMessage, 
import { Account, UUID, CURRENT_CS_API_VERSIONS, PermissionSet, PermissionSetType, Permission, GrantSetType, Grant } from "src/common";
import { DriveCallbackAsyncFunction, ResponseCompleteAction } from "src/store";
import _ from "lodash";

export class DriveRequest {
  request_id: UUID;
  method?: number;
  private _envelope: Uint8Array = new Uint8Array();
  public callback?: DriveCallbackAsyncFunction<any>;
  public success_action?: ResponseCompleteAction<any>;
  constructor(
    public fs_request: FSRequest,
  ) {
    this.request_id = new UUID();
    this.method = fs_request.getMethod();
  }

  set envelope(_fs_envelope: Uint8Array) {
    this._envelope = _fs_envelope;
  }

  get envelope(): Uint8Array {
    return this._envelope;
  }

  // Description: Set up the callback and additional params
  public setCallback<T>(callback: DriveCallbackAsyncFunction<any>, success_action?: ResponseCompleteAction<T>): void {
    this.callback = callback;
    this.success_action = success_action;
  }

  // Description: prepare the request with proper signatures, permissions and grants  // grants: { grants: Node.Grant[], node_id: string | Uint8Array }[]
  static async initializeRequest(
    request: FSRequest,
    account: Account,
    permission_set?: PermissionSetType,
    grant_set?: GrantSetType | GrantSetType[]): Promise<DriveRequest> {
    request.setApiVersion(CURRENT_CS_API_VERSIONS);
    // NOTE: Set DriveRequest instance
    const drive_request = new DriveRequest(request);
    const request_id = drive_request.request_id;
    const device = account.current_device;
    if (!!device.device_key) {
      // NOTE: Collect data for preparing request => device_signature, user_key
      const device_signature = await device.device_key.signing_key.sign(request.serializeBinary());
      const user_key = account.user_key;
      const request_data = request.serializeBinary();

      // NOTE: Sign the payload with the device_signature
      const signed_payload = new Uint8Array(device_signature.length + request_data.length);
      signed_payload.set(device_signature);
      signed_payload.set(request_data, device_signature.length);

      // NOTE:  - GET the user_signature
      const user_signature = await user_key.signing_key.sign(device_signature);

      // NOTE:  - Get the Latest Permissions if permission_set are passed
      const collection_signatures: FSEnvelope.Signature[] = !!permission_set ?
        await DriveRequest.getCollectionSignatures(permission_set, account, device_signature) : [];

      // NOTE:  - Prepare the node_signatures if grants are passed GrantSetType | GrantSetType[]
      const node_signatures: FSEnvelope.Signature[] = !!grant_set ?
        await DriveRequest.getNodeSignatures(Array.isArray(grant_set) ? grant_set : [grant_set], account, device_signature) : [];

      const envelope_signatures = collection_signatures.concat(node_signatures);
      const envelope = new FSEnvelope();
      envelope.setRequestId(request_id.Bytes());
      envelope.setUserId(account.user_id);
      envelope.setUserKeyVersion(account.user_key.key_version);
      envelope.setSignedPayload(signed_payload);
      envelope.setUserSignature(user_signature);
      // Set Device information
      envelope.setDeviceId(new UUID({ uuid: device.device_id }).Bytes());
      envelope.setDeviceKeyVersion(device.device_key?.key_version || 0);
      envelope.setSignaturesList(envelope_signatures);
      drive_request.envelope = envelope.serializeBinary();
      return drive_request;
    } else {
      throw new Error("There was a problem building the Drive Request");
    }
  }

  // Description: Get Collection Signatures
  // NOTE:  - Get the Latest Permissions if permission_set are passed
  //        - Prepare the collection_signatures 
  static async getCollectionSignatures(permission_set: PermissionSetType, account: Account, device_signature: Uint8Array):
    Promise<FSEnvelope.Signature[]> {
    let collection_signatures: FSEnvelope.Signature[] = [];
    const permissions = PermissionSet.latestPermissionByRole(permission_set.permissions);
    collection_signatures = await Promise.all(_.map(permissions, async (permission: Permission) => {
      const permission_key = await permission.key(account);
      const envelope_signature = new FSEnvelope.Signature();
      envelope_signature.setCollectionRole(permission.role);
      envelope_signature.setKeyVersion(permission_key.key_version);
      envelope_signature.setSignature(await permission_key.signing_key.sign(device_signature));
      return envelope_signature;
    }));
    return collection_signatures;
  }

  // Description: Get Node level Signatures
  static async getNodeSignatures(grant_sets: GrantSetType[], account: Account, device_signature: Uint8Array):
    Promise<FSEnvelope.Signature[]> {
    const signatures = await Promise.all(_.map(grant_sets, async (grant_set: GrantSetType) => {
      let node_signatures: FSEnvelope.Signature[] = [];
      node_signatures = await Promise.all(_.map(grant_set.grants, async (grant: Grant) => {
        const grant_key = await grant.key(account);
        const node_signature = new FSEnvelope.Signature();
        node_signature.setAclRole(grant.role);
        node_signature.setKeyVersion(grant.role_key_version);
        node_signature.setSignature(await grant_key.signing_key.sign(device_signature));
        node_signature.setMaintainerId(grant_set.id);
        return node_signature;
      }));
      return node_signatures;
    }));
    return _.flatten(signatures);
  }
}
