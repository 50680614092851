import * as React from "react";
import { Link } from "react-router-dom";
import { PublicRoutes, QueryParamKeys } from "src/common";

function ErrorPanelComponent(props: { user_id?: string }) {
  const qs = props.user_id ? `?${QueryParamKeys.USER_ID_QUERY_KEY}=${encodeURIComponent(props.user_id)}` : "";
  return <>
    <p>PreVeil did not find an approval group associated with this account and is unable
      to perform the recovery request. </p>
    <p>If you have another device with your PreVeil account, you will still be able to
      access your account and copy it onto new devices <Link to={`/${PublicRoutes.copy_account_route}${qs}`}
        title="Copy your private key instead">here</Link>.
    </p>
    <p>
      If you do not have any other PreVeil device, you will not be able to access your account and it will be deleted after a period of activity.
    </p>
    <p>
      Learn more here:
      <a target="_blank" rel="noreferrer"
        href="https://www.preveil.com/support_topic/configure-recovery-approval-group-settings/?id=15">
        Configure Recovery Group Settings</a>
    </p>
    <div className="btn-panel text-end mt-3">
      <Link to={`/${PublicRoutes.account_recovery_route}${qs}`}
        className="btn btn-outline-primary" > Retry</Link>
    </div>
  </>;
}
export default React.memo(ErrorPanelComponent);