import * as React from "react";
import { Alert } from "react-bootstrap";
import { Icon } from "src/components";

function UploadingAlertComponent() {
  return <Alert
    variant="warning"
    className="details-alert">
    <Alert.Heading>
      <Icon className="ficon-alert-circle" />
      You are currently uploading files into this folder.
    </Alert.Heading>
    <p>
      To avoid canceling the upload process and corrupting your data, you can not sync, share, delete or move this folder until the uploading has completed.
    </p>
  </Alert>;
}

export default UploadingAlertComponent;