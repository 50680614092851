import { useState, useCallback, useEffect, useRef } from "react";
import { ErrorStackDataType } from "@preveil-api";
import { randomBytes } from "pvcryptojs";
import { SymmKey } from "src/common/keys";
import { EncryptionStyle } from "src/common/keys/encryption";
import { FSMessage, FSRole, FSWrappedKey, Node, FSStatus } from "src/common/keys/protos/collections_pb";
import {
  Account, useAppDispatch, Message, MessageHandlerDisplayType, CollectionFilesyncAPI, DriveErrorMessages, useGetGrants,
  useAppSelector, DriveRequest, UUID, KeyFactory, GrantSet, Grant, getDirSymmKey, AppUserKey, PermissionSet, PermissionSetType, mapEntryType,
  DriveEntryType, dayjs, Helpers, ActiveUpload, encryptFileName, useGetPermissions, ProgressTracker, addProgress, updateProgress, incrementProgress,
  errorProgress, failAllProgress, useFetchLink, UploadFile, resolveProgress, decryptItemName, ACLRoleType, FileSizeLimits,
  filesyncSocketApi, UploadRequest, ErrorStackItem, getEnumKey
} from "src/common";
import { DriveCallbackAsyncFunction, MessageRequest, WebsocketStatus, driveActions, uiActions, websocketActions } from "src/store";
import { RootState } from "src/store/configureStore";
import _ from "lodash";

/* Description: This is the web hook for uploading a file */
export function useUploadFiles(current_account: Account) {
  const upload_ws_status = useAppSelector((state: RootState) => state.websocket.drive_upload_ws_status);
  const upload_callback = useAppSelector((state: RootState) => state.websocket.drive_upload_ws_callback);
  const default_permissions = useAppSelector((state: RootState) => state.drive.default_permissions);
  const default_grants = useAppSelector((state: RootState) => state.drive.default_grants);
  const [progress, setProgress] = useState<ProgressTracker[]>([]);
  const { permissions, getPermissions, destroyPermissions } = useGetPermissions(current_account);
  const { grants, getGrants, destroyGrants } = useGetGrants(current_account);
  const { fetchLink, directory_id, resetLink, error: link_error } = useFetchLink(current_account, false, true);
  const [initUploadSocket, { data: upload_data, error: upload_error }] = filesyncSocketApi.useLazyInitializeUploadHooksQuery();
  const [sendRequest] = filesyncSocketApi.useSendRequestMutation();
  const [sendUploadRequest] = filesyncSocketApi.useSendUploadRequestMutation();
  const [closeUploadSocket] = filesyncSocketApi.useCloseUploadSocketMutation();
  const dispatch = useAppDispatch();

  const permissionsRef = useRef(permissions);
  function setPermissionsRef(_permissions?: PermissionSetType) {
    permissionsRef.current = _permissions;
  }

  const files_ref = useRef<UploadFile[]>([]);
  const files_to_upload_ref = useRef<string[]>([]);
  const target_dir_ref = useRef<FSMessage.Dir>();
  const node_id_ref = useRef<string>();
  const collection_id_ref = useRef<string>();
  const linked_collection_id_ref = useRef<string>();
  const key_version_ref = useRef<number>(0);
  const active_uploads_ref = useRef<Map<string, ActiveUpload>>(new Map<string, ActiveUpload>());
  const new_entries_ref = useRef(0);
  const request_queue_ref = useRef<DriveRequest[]>([]);
  const awaiting_requests_ref = useRef(0);

  useEffect(() => {
    if (progress.length > 0) {
      (upload_ws_status === WebsocketStatus.not_initialized || upload_ws_status === WebsocketStatus.disconnected)
        && initUploadSocket(new Date().getTime().toString());
      let completed = true;
      progress.forEach(t => {
        if (t.done !== t.total && !t.error) {
          completed = false;
        }
      });
      if (completed) {
        new_entries_ref.current = 0;
        destroyGrants();
        destroyPermissions();
        const id = setTimeout(() => {
          // Note: Check if any new uploads were started. If so, don't close the socket
          if (new_entries_ref.current > 0) return;
          setProgress([]);
          files_ref.current = [];
          files_to_upload_ref.current = [];
          dispatch(driveActions.setFileUploadRequest(undefined));
          closeUploadSocket(upload_ws_status);
        }, 5000);
        return () => clearTimeout(id);
      }
    } else if (progress.length === 0) {
      dispatch(driveActions.setFileUploadRequest(undefined));
      (upload_ws_status === WebsocketStatus.connected) && closeUploadSocket(upload_ws_status);
    }
  }, [progress, upload_ws_status]);

  useEffect(() => {
    if (!!permissions && !!collection_id_ref.current && !!node_id_ref.current && !!files_ref.current) {
      setPermissionsRef(permissions);
      destroyPermissions();
      if (!linked_collection_id_ref.current) {
        prepareUploads(collection_id_ref.current, node_id_ref.current, files_ref.current, files_to_upload_ref.current);
      } else {
        fetchLink(collection_id_ref.current, node_id_ref.current);
      }
    }
  }, [permissions]);

  useEffect(() => {
    if (!!directory_id && !!permissionsRef.current && !!linked_collection_id_ref.current && !!files_ref.current) {
      prepareUploads(linked_collection_id_ref.current, directory_id, files_ref.current, files_to_upload_ref.current);
      resetLink();
    }
  }, [directory_id]);

  // Description: useEffect hook for when grants (from the useGetGrants hook) is initialized.
  useEffect(() => {
    const _collection_id = linked_collection_id_ref.current || collection_id_ref.current;
    if (!!grants && !!target_dir_ref.current && !!_collection_id) {
      if (!GrantSet.getGrantbyRole(grants, latestVersion(grants.grants, key_version_ref.current, Node.ACLRole.WRITER), Node.ACLRole.WRITER)) {
        return handlePageErrorMessage(DriveErrorMessages.no_upload_permission, undefined, undefined, undefined, true);
      }
      const grant = GrantSet.getGrantbyRole(grants, key_version_ref.current);
      if (!!grant) {
        uploadFiles(_collection_id, target_dir_ref.current, files_ref.current, files_to_upload_ref.current, undefined, undefined, grant);
      } else {
        handlePageErrorMessage(DriveErrorMessages.upload_insufficient_permissions);
      }
    }
  }, [grants]);

  useEffect(() => {
    !!link_error && handlePageErrorMessage(DriveErrorMessages.error_fetching_link);
  }, [link_error]);

  useEffect(() => {
    !!upload_data && matchUploadRequestResponse(upload_data);
    !!upload_error && handlePageErrorMessage(DriveErrorMessages.default, {
      stack_message: DriveErrorMessages.default,
      stack_error: upload_error
    });
  }, [upload_data, upload_error]);

  // Description: Ensures permissions and grants are fetched prior to uploading files
  async function prepareUploads(collection_id: string, _directory_id: string, files: UploadFile[], files_to_upload: string[]) {
    if (!permissionsRef.current) {
      return handlePageErrorMessage(DriveErrorMessages.upload_insufficient_permissions);
    }
    const request = await CollectionFilesyncAPI.fetchDirectoryRequest(collection_id, _directory_id);
    const drive_request = await DriveRequest.initializeRequest(request, current_account, permissionsRef.current, grants);
    async function callback(message: FSMessage) {
      const dir = message.getDir();
      if (!dir) {
        return handlePageErrorMessage(DriveErrorMessages.error_no_target_directory);
      }
      target_dir_ref.current = dir;
      const _key_version = dir.hasMaintainerScopedName() ? dir.getMaintainerScopedName()?.getKeyVersion() : dir.getWrappedDirKey()?.getKeyVersion();
      if (!!_key_version) {
        key_version_ref.current = _key_version;
      }
      if (dir.getAclList().length > 0) { // if acl node
        getGrants(_directory_id, collection_id, default_permissions, default_grants);
      } else if (dir.hasMaintainerId()) { // if child of maintainer
        getGrants(dir.getMaintainerId_asB64(), collection_id, default_permissions, default_grants);
      } else { // if under v1 collection or root
        const ps = !!permissionsRef.current && new PermissionSet(permissionsRef.current.collection_id, permissionsRef.current.permissions);
        if (!ps || !ps.permission(FSRole.WRITER)) {
          return handlePageErrorMessage(DriveErrorMessages.no_upload_permission, undefined, undefined, undefined, true);
        }
        const reader = await ps.permission(FSRole.READER, dir.getWrappedDirKey()?.getKeyVersion())?.key(current_account);
        const latest_reader = await ps.permission(FSRole.READER)?.key(current_account);
        if (!!reader) {
          uploadFiles(collection_id, dir, files, files_to_upload, reader, latest_reader);
        } else {
          handlePageErrorMessage(DriveErrorMessages.upload_insufficient_permissions);
        }
      }
      postCallback();
    }
    handleSendUploadRequest(drive_request, callback);
  }

  // Description: Obtains necessary directory information and chunks each file in parallel
  async function uploadFiles(
    coll_id: string,
    dir: FSMessage.Dir,
    files: UploadFile[],
    files_to_upload: string[],
    reader_key?: AppUserKey,
    latest_reader_key?: AppUserKey,
    reader_grant?: Grant
  ) {
    files_to_upload_ref.current = [];
    const user_key = reader_key || (!!reader_grant && await reader_grant.key(current_account));
    const wrapped_dir_key = dir.getWrappedDirKey();
    if (!user_key || !wrapped_dir_key || !permissionsRef.current) {
      return handlePageErrorMessage(DriveErrorMessages.invalid_key);
    }
    const dir_symm_key = await getDirSymmKey(wrapped_dir_key, current_account, permissionsRef.current, user_key);
    if (!dir_symm_key) {
      return handlePageErrorMessage(DriveErrorMessages.invalid_directory_key);
    }
    const latest_reader_grant = !!grants && GrantSet.getGrantbyRole(grants, latestVersion(grants.grants, key_version_ref.current));
    const latest_user_key = latest_reader_key || reader_key || (!!latest_reader_grant && await latest_reader_grant.key(current_account));
    const entries = await Promise.all(dir.getEntriesList().map(async e => await decryptItemName(e.getName_asU8(), dir_symm_key, reader_key)));
    for (const file of files) {
      const in_progress = progress
        .filter(tracker => tracker.file_id !== file.file_id.String() && tracker.destination.id === dir.getId_asB64())
        .map(tracker => tracker.file_name);
      const first_instance_id = progress.find(tracker => tracker.file_name === file.file.name && tracker.destination.id === dir.getId_asB64())?.file_id;
      const unavailable_names = first_instance_id === file.file_id.String() ? entries : entries.concat(in_progress);
      if (unavailable_names.includes(file.new_name || file.file.name) && !active_uploads_ref.current.get(file.file_id.String())) {
        setProgress(progress => updateProgress(progress, file.file_id.String(), undefined, true, unavailable_names));
      } else {
        if (files_to_upload.includes(file.file_id.String()) && !active_uploads_ref.current.get(file.file_id.String())) {
          !file.conflict && await chunkFile(coll_id, dir, file.file_id, file.file, latest_user_key || user_key, dir_symm_key, file.new_name);
        }
      }
    }
  }

  // Description: Call used for dividing a file into uniform blocks
  async function chunkFile(coll_id: string, dir: FSMessage.Dir, file_id: UUID, file: File, user_key: AppUserKey, dir_symm_key: SymmKey, new_name?: string) {
    const file_symm_key = await KeyFactory.newSymmKey({ style: EncryptionStyle.DRIVE });
    const wrapped_key = await user_key.encryption_key.seal(file_symm_key.serialize());
    const wrapped_file_key = new FSWrappedKey();
    wrapped_file_key.setWrappedKey(wrapped_key);
    wrapped_file_key.setKeyVersion(user_key.key_version);
    const content_buffer = await Helpers.getContentBuffer(file);
    const chunks = Helpers.chunkData(content_buffer);
    setProgress(progress => updateProgress(progress, file_id.String(), chunks.length + 1));
    const wrapped_dir_key = dir.getWrappedDirKey();
    if (!wrapped_dir_key) {
      return handlePageErrorMessage(DriveErrorMessages.invalid_directory_key, {
        stack_message: DriveErrorMessages.invalid_directory_key,
        stack_error: dir
      }, null, file_id.String());
    }
    const active_upload = {
      coll_id,
      wrapped_dir_key,
      file_name: new_name || file.name,
      dir_id: dir.getId_asB64(),
      dir_version: dir.getVersion_asB64(),
      dir_symm_key,
      file_id,
      total_blocks: chunks.length,
      completed_block_ids: Array(chunks.length).fill(""),
      grants
    };
    active_uploads_ref.current.set(file_id.String(), active_upload);
    // Note: If the file is empty, upload the file as a single block
    if (chunks.length === 0) {
      await encryptAndUploadBlock(coll_id, content_buffer, file_id.String(), file_symm_key, wrapped_file_key, 0);
    }
    for (let i = 0; i < chunks.length; i++) {
      await encryptAndUploadBlock(coll_id, chunks[i], file_id.String(), file_symm_key, wrapped_file_key, i);
    }
  }

  // Description: Prepares individual blocks for upload by encrypting and then initiating the websocket upload request
  async function encryptAndUploadBlock(
    coll_id: string,
    block: Uint8Array,
    file_id: string,
    file_symm_key: SymmKey,
    wrapped_file_key: FSWrappedKey,
    index: number
  ) {
    const enc_block = await file_symm_key.encrypt(block);
    const block_id = Helpers.hexEncode(await Helpers.sha256Checksum(enc_block));
    const chunk_hash = await Helpers.sha256Checksum(block);
    const hash = await file_symm_key.encrypt(chunk_hash);
    const request = !!permissionsRef.current ? await CollectionFilesyncAPI.uploadBlock(
      current_account, permissionsRef.current, coll_id, block_id, wrapped_file_key, enc_block, block.length, hash, grants) : null;
    async function callback() {
      const active_upload = active_uploads_ref.current.get(file_id);
      if (!active_upload) {
        return handlePageErrorMessage(DriveErrorMessages.error_tracking_upload, {
          stack_message: DriveErrorMessages.error_tracking_upload,
          stack_error: active_uploads_ref.current
        }, null, file_id);
      }
      const block_ids = active_upload.completed_block_ids;
      block_ids[index] = block_id;
      const updated_upload = { ...active_upload, completed_block_ids: block_ids };
      active_uploads_ref.current.set(file_id, updated_upload);
      setProgress(progress => incrementProgress(progress, file_id));
      const non_empty_ids = updated_upload.completed_block_ids.filter(block_id => block_id !== "").length;
      if (non_empty_ids >= updated_upload.total_blocks) {
        await createFile(updated_upload);
        active_uploads_ref.current.delete(file_id);
      }
      postCallback();
    }
    handleSendUploadRequest(request, callback, file_id, DriveErrorMessages.error_uploading_block);
  }

  // Description: Triggered once all blocks for a file are uploaded, creates file and directory entry
  async function createFile(upload: ActiveUpload) {
    if (!permissionsRef.current) {
      return handlePageErrorMessage(DriveErrorMessages.upload_insufficient_permissions, {
        stack_message: DriveErrorMessages.upload_insufficient_permissions,
        stack_error: upload
      }, null, upload.file_id.String());
    }
    const encoded_block_ids = upload.completed_block_ids.map(block_id => Helpers.utf8Encode(block_id));
    const enc_file_name = await encryptFileName(upload.file_name, upload.dir_symm_key);
    const request = !!permissionsRef.current && await CollectionFilesyncAPI.createFile(
      current_account,
      permissionsRef.current,
      upload.coll_id,
      upload.dir_id,
      upload.dir_version,
      upload.file_id.Bytes(),
      randomBytes(32),
      enc_file_name,
      upload.wrapped_dir_key,
      encoded_block_ids,
      grants
    );
    async function callback(message: FSMessage) {
      const new_file = message.getFile();
      if (new_file) {
        setProgress(progress => incrementProgress(progress, upload.file_id.String()));
        const destination_id = progress.find(t => t.file_id === upload.file_id.String())?.destination.id;
        const entry_info = mapEntryType(DriveEntryType.FILE, upload.file_name);
        const latest = {
          deleted: false,
          deleted_at: 0,
          id: new_file.getId_asB64(),
          lastModificationDate: dayjs.utc(new Date()).format(),
          linked_collection_id: upload.coll_id,
          collection_id: upload.coll_id,
          localSyncStatus: 0,
          name: upload.file_name,
          size: 0,
          type_label: entry_info?.type_label,
          mapped_type: entry_info?.mapped_type,
          type_class: entry_info?.type_class,
          type: DriveEntryType.FILE
        };
        new_entries_ref.current++;
        !!destination_id && dispatch(driveActions.setEntryUpdate({ new_entries: new_entries_ref.current, latest, destination_id }));
      } else {
        return handlePageErrorMessage(DriveErrorMessages.error_creating_file, {
          stack_message: DriveErrorMessages.error_creating_file,
          stack_error: upload
        }, message, upload.file_id.String());
      }
    }
    handleSendRequest(request, callback, upload.file_id.String(), DriveErrorMessages.error_creating_file);
  }

  // Description: Sends request to primary Drive socket
  function handleSendRequest<T>(
    request: DriveRequest | null,
    callback: DriveCallbackAsyncFunction<T>,
    file_id: string,
    stack_error: string = DriveErrorMessages.error_sending_request
  ) {
    if (!!request) {
      request.setCallback(callback);
      sendRequest(request);
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, { stack_error, stack_message: DriveErrorMessages.error_sending_request }, null, file_id);
    }
  }

  // Description: Queues request to dedicated Drive Upload socket 
  function handleSendUploadRequest<T>(
    request: DriveRequest | null,
    callback: DriveCallbackAsyncFunction<T>,
    file_id?: string,
    stack_error: string = DriveErrorMessages.error_sending_request
  ) {
    if (!!request) {
      request.setCallback(callback);
      request_queue_ref.current.push(request);
      processQueue();
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, { stack_error, stack_message: DriveErrorMessages.error_sending_request }, null, file_id);
    }
  }

  function postCallback() {
    awaiting_requests_ref.current > 0 && awaiting_requests_ref.current--;
    processQueue();
  }

  function latestVersion(grants: Grant[], default_version: number = 0, role: ACLRoleType = Node.ACLRole.READER): number {
    const role_grants = _.filter(grants, (grant: Grant) => grant.role === role);
    return _.maxBy(role_grants, "role_key_version")?.role_key_version || default_version;
  }

  function processQueue() {
    while (awaiting_requests_ref.current < FileSizeLimits.DRIVE_MAX_CONCURRENT_UPLOAD_REQUESTS && request_queue_ref.current.length > 0) {
      const request = request_queue_ref.current.shift();
      if (!!request) {
        awaiting_requests_ref.current++;
        sendUploadRequest(request);
      }
    }
  }

  // Description: Matches the request message to a response callback function
  function matchUploadRequestResponse(data: MessageRequest) {
    const request_id = data.id;
    const message = data.message;
    if (!!request_id && !!message && !!upload_callback && upload_callback.callback instanceof Function) {
      dispatch(websocketActions.setWsDriveUploadCallback(null));
      upload_callback.callback(message);
    } else {
      const stack = {
        stack_message: DriveErrorMessages.no_request_match_found,
        stack_error: { request_id, message, upload_callback }
      };
      handlePageErrorMessage(DriveErrorMessages.default, stack, message);
    }
  }

  // Description: Logs errors and updates progress indicators appropriately
  // function handlePageErrorMessage(message: string, stack?: any, file_id?: string, warning?: boolean) {
  function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, fsmessage?: FSMessage | null, file_id?: string, warning?: boolean) {
    const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
    const stack = new ErrorStackItem("[useUploadFiles Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
    if (!!file_id) {
      setProgress(progress => errorProgress(progress, file_id, message));
      dispatch(uiActions.handleRequestErrors(new Message(message, MessageHandlerDisplayType.logger), stack));
    } else {
      setProgress(progress => failAllProgress(progress));
      !!warning ? dispatch(uiActions.handleSetMessage(new Message(message, MessageHandlerDisplayType.toastr, "warning")))
        : dispatch(uiActions.handleRequestErrors(new Message(message, MessageHandlerDisplayType.toastr), stack));
    }
  }

  const uploadFilesWeb = useCallback((upload: UploadRequest) => {
    const files_by_id = [];
    for (const file of upload.files) {
      const file_id = new UUID();
      const error = file.size > FileSizeLimits.DRIVE_UPLOAD_FILE_MAX_SIZE;
      const conflict = error || !!upload.destination.directory?.entries.find(entry => entry.name === file.name);
      files_by_id.push({ file_id, file, conflict, error });
    }
    const sorted_files = files_by_id.sort((a, b) => {
      if (a.conflict && !b.conflict) {
        return -1;
      } else if (!a.conflict && b.conflict) {
        return 1;
      }
      return a.file.name.localeCompare(b.file.name);
    });
    for (const file of sorted_files) {
      setProgress(_progress => {
        const unavailable_names = _progress
          .filter(tracker => tracker.file_id !== file.file_id.String() && tracker.destination.id === upload.destination.id)
          .map(tracker => tracker.file_name);
        return addProgress(_progress, {
          file_id: file.file_id.String(),
          file_name: file.file.name,
          destination: upload.destination,
          done: 0,
          total: 1,
          conflict: !file.error ? unavailable_names.includes(file.file.name) || file.conflict : undefined,
          entries: unavailable_names,
          error: file.error ? DriveErrorMessages.error_size_exceeds_limit : undefined
        });
      });
    }
    collection_id_ref.current = upload.collection_id;
    linked_collection_id_ref.current = upload.linked_collection_id;
    files_ref.current = files_ref.current.concat(sorted_files);
    files_to_upload_ref.current = files_to_upload_ref.current.concat(sorted_files.map(f => f.file_id.String()));
    node_id_ref.current = upload.node_id;
    getPermissions(upload.linked_collection_id || upload.collection_id, default_permissions);
  }, []);

  const cancelUpload = useCallback((file_id: string) => {
    setProgress(progress => progress.filter(t => t.file_id !== file_id));
    files_to_upload_ref.current = files_to_upload_ref.current.filter(id => id !== file_id);
    files_ref.current = files_ref.current.filter(f => f.file_id.String() !== file_id);
  }, []);

  const renameUpload = useCallback((file_id: string, new_name: string) => {
    files_to_upload_ref.current.push(file_id);
    const file_index = files_ref.current.findIndex(f => f.file_id.String() === file_id);
    files_ref.current[file_index] = { ...files_ref.current[file_index], conflict: false, new_name };
    setProgress(_progress => {
      const latest_progress = resolveProgress(_progress, file_id, new_name).slice();
      const destination_id = latest_progress.find(tracker => tracker.file_id === file_id)?.destination.id;
      for (const tracker of latest_progress) {
        if (!!tracker.conflict && tracker.destination.id === destination_id) {
          tracker.entries = (tracker?.entries || []).concat([new_name]);
        }
      }
      return latest_progress;
    });
    const _collection_id = linked_collection_id_ref.current || collection_id_ref.current;
    !!_collection_id && getPermissions(_collection_id, default_permissions);
  }, []);

  return {
    uploadFilesWeb,
    cancelUpload,
    renameUpload,
    progress
  };
}
