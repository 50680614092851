import React, { useState } from "react";
import { ActionHandlerFunction } from "@preveil-api";
import { SearchForm, SearchFilters } from "..";
import { AppConfiguration } from "src/common";
type AllProps = {
  mailbox_name: string;
  full_search: boolean;
  handleAction: ActionHandlerFunction;
}

function MailSearchHeaderComponent(props: AllProps) {
  const { mailbox_name, full_search, handleAction } = props;
  const [searchMode, setSearchMode] = useState<boolean>(false);  // Description: Toggle search form and header in mobile view
  const enable_search = AppConfiguration.buildForApp(); // Search will only be available for App build mode for now

  return <>
    <div className={`header-flex${searchMode ? " searching" : ""}`}>
      <h1>{mailbox_name}</h1>
      {
        enable_search &&
        <SearchForm
          searchMode={searchMode}
          handleSearchModeAction={(search_mode: boolean) => setSearchMode(search_mode)}
          handleAction={handleAction} />
      }
    </div>
    {
      (enable_search && full_search) && <SearchFilters />
    }
  </>;
}

export default React.memo(MailSearchHeaderComponent);