import React, { useState, useEffect, useRef, FocusEvent, KeyboardEvent } from "react";
import { Button, Collapse, Form, Offcanvas } from "react-bootstrap";
import { ActionHandlerFunction, IActionHandler, UserProfile, ThreadMessageRecipients, IFetchResponse, CollectionServerRevId, ThreadMessage } from "@preveil-api";
import {
  useAppSelector, AppConfiguration, Account, WebMailData, MailMessageBody, ComposeMailMessage, ComposeModalSize, ComposeModalSizeType, IconSvgData, ComposeMail,
  useAppDispatch, Message, ComposeMessage, MAIL_DEFAULT_SUBJECT, COMPOSE_STATES, DRAFT_STATES, MailSuccessMessages, MailErrorMessages, RecipientKeys, MessageHandlerDisplayType,
  MessageToastTypes, MailMessages, MessageAnchors, useSendEmailMutation, getMailFormData, useSendWebMail, Attachment, Helpers, DefaultMailboxes, getMailboxCollectionIdByRole,
  useSaveDraft, useSaveDraftMutation, SaveDraftApiResponse, useDeleteEmailsMutation, DeleteEmailsApiResponse, KeyCode, useDeleteMailByColIdMbidMessIdMutation, useGetMessages,
  useGetWebMessages, TinyMceCSPHeaders
} from "src/common";
import { uiActions, mailActions } from "src/store";
import { Icon, Loading, InlineListError, UserList, Editor } from "src/components";
import { ComposeMailNotifications, ComposeMailRecipients, ComposeMailSending, ComposeMailAttachment } from ".";
import _ from "lodash";

type AllProps = {
  current_account: Account;
  compose_mail_message: ComposeMailMessage;
  compose_messages: ComposeMailMessage[];
  signature?: string;
  handleAction: ActionHandlerFunction;
  index: number;
  arr: ComposeModalSizeType[];
  handleSizeChange: (index: number, size?: ComposeModalSizeType) => void
}

function ComposeMailComponent(props: AllProps) {
  const { compose_messages, current_account, compose_mail_message, signature, index, arr, handleSizeChange } = props;
  const default_mailboxes = useAppSelector((state) => state.mail.default_mailboxes);
  const is_web = AppConfiguration.buildForWeb();
  const [modal_size, setModalSize] = useState<ComposeModalSizeType>(ComposeModalSize.lg);
  const [step, setStep] = useState<number>(COMPOSE_STATES.LOADING);
  const [recipients_opened, setRecipientsOpen] = useState<boolean>(false);
  const [attachment_opened, setAttachmentOpened] = useState<boolean>(false);
  const [message_body, setMessageBody] = useState<string>();
  const [subject, setSubject] = useState<string>("");
  const [recipients_enable, setRecipientsEnable] = useState<boolean>(true);
  const [compose_mail, setComposeMail] = useState<ComposeMail>();
  const [compose_message, setComposeMessage] = useState<ComposeMessage>();
  const [recipients, setRecipients] = useState<ThreadMessageRecipients | undefined>();
  const [web_mail_data, setWebMailData] = useState<WebMailData[]>();
  const { data: app_message, errors: app_message_errors, getAppMessages } = useGetMessages(current_account);
  const { data: web_message, errors: web_message_errors, getWebMessages } = useGetWebMessages(current_account, true);
  const { data: draft_data, errors: draft_errors, saveDraftData } = useSaveDraft(current_account, default_mailboxes);
  const { data: send_data, errors: send_errors } = useSendWebMail(current_account, compose_message?.save_metadata, web_mail_data, default_mailboxes);
  const [saveDraft] = useSaveDraftMutation();
  const [sendMail] = useSendEmailMutation();
  const [deleteDrafts] = useDeleteEmailsMutation();
  const [deleteWebDrafts] = useDeleteMailByColIdMbidMessIdMutation();
  const dispatch = useAppDispatch();

  // NOTE: Allow Editor eventListener (drop) to have access to current states:
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const active_attachments_ref = useRef(attachments);
  function setActiveAttachments(_attachments: Attachment[]) {
    active_attachments_ref.current = _attachments;
    setAttachments(_attachments);
    compose_message?.updateAttachments(_attachments);
  }

  // Description: Draft States for autosave on changes
  const [draft_state, setDraftState] = useState<number>(DRAFT_STATES.INITIALIZING);
  const active_draft_state_ref = useRef(draft_state);
  function setActiveDraftState(_draft_state: number) {
    active_draft_state_ref.current = _draft_state;
    setDraftState(_draft_state);
  }

  // Description: current compose_messages not detected in setTimeout instances
  const compose_ref = useRef(compose_messages.slice());
  useEffect(() => {
    compose_ref.current = compose_messages.slice();
  }, [compose_messages]);

  // Description: On first load, get all data by content_type and initilize the compose data
  useEffect(() => {
    const _message = compose_mail_message.message;
    if (!!_message) {
      is_web ? getWebMessages([_message]) : getAppMessages([_message.unique_id]);
    } else {
      const processed_message = !!compose_mail_message?.message ? new MailMessageBody(compose_mail_message?.message).processed_message : undefined;
      handleComposeMailInit(processed_message);
    }
  }, []);

  // Description: Allow Editor eventlisteners to update and get current state data
  useEffect(() => {
    compose_message?.updateMessage(subject || "", message_body || null);
    // NOTE: Save draft on changes but skip initialization:
    (!!message_body || subject.length > 0) && handleDraftChange("Changes to subject or message_body");
  }, [subject, message_body]);

  // Description: Capture state changes for web send
  useEffect(() => {
    !!send_data && handleSendMailSuccess();
    !!send_errors && handleMailSendError(send_errors);
  }, [send_data, send_errors]);

  // Description: Capture state changes for web draft saved
  useEffect(() => {
    !!draft_data && handleSaveDraftSuccess(draft_data.id, draft_data.version);
    !!draft_errors && handleComposeErrors(MailErrorMessages.error_saving_draft, draft_errors);
  }, [draft_data, draft_errors]); // draftData, draftLoading, 

  useEffect(() => {
    const msg = app_message || web_message;
    _getMessageCallback(msg);
    const errors = app_message_errors || web_message_errors;
    (!!errors) && handleComposeErrors(MailErrorMessages.error_getting_mail_threads, errors);
  }, [app_message, web_message, app_message_errors, web_message_errors]);

  // Description: track modal size in parent
  useEffect(() => {
    handleSizeChange(index, modal_size);
  }, [modal_size]);


  // Main UI and state cycle actions (Init, discard)
  // --------------------------------------------------------------------------------------
  // Description: Handle onload of compose email. Depending on compose type: recipients, subject and mail body
  //        NOTE: Before setting state to composing need to make sure attachments are fully loaded
  //              (drafts and some previous message responses are not)
  async function handleComposeMailInit(_message?: ThreadMessage) {
    try {
      const _compose_mail = new ComposeMail(compose_mail_message.content_type, _message, signature, true);
      !!compose_mail_message.default_to && _compose_mail.setDefaults(compose_mail_message.default_to, compose_mail_message.default_subject);
      const new_compose_message = new ComposeMessage(current_account, _compose_mail);
      setComposeMail(_compose_mail);
      setComposeMessage(new_compose_message);
      setSubject(new_compose_message.subject);
      setAttachments(new_compose_message.attachments);
      setRecipientsEnable(!_compose_mail.is_reply);
      setStep(COMPOSE_STATES.COMPOSING);
    } catch (error: unknown) {
      handleComposeErrors(MailErrorMessages.error_creating_compose, error);
    }
  }

  // Description: Get WEB Message from server - needed for drafts & forward
  //              Reinitialize the attachments from server call and load into state
  // function _loadInitEmailWeb(_message: ThreadMessage) {
  function _getMessageCallback(_message?: ThreadMessage[]) {
    if (!!_message && _message.length > 0) {
      const processed_message = new MailMessageBody(_message[0]).processed_message;
      handleComposeMailInit(processed_message);
      setStep(COMPOSE_STATES.COMPOSING);
    }
  }

  // Description: Handle initial setup calls
  function handleComposeErrors(message: string, stack?: unknown) {
    dispatch(uiActions.handleRequestErrors(new Message(message)));
    setStep(COMPOSE_STATES.ERROR);
    setActiveDraftState(DRAFT_STATES.ERROR);
  }

  // --------------------------------------------------------------------------------------
  // Message Drafts
  // --------------------------------------------------------------------------------------
  // Description: Monitor changes to Compose Window and trigger saveDraft
  const handleDraftChange = _.debounce((handler?: string) => {
    active_draft_state_ref.current === DRAFT_STATES.INITIALIZING ? setActiveDraftState(DRAFT_STATES.READY) :
      (active_draft_state_ref.current === DRAFT_STATES.READY && handleSaveDraft());
  }, 500);

  // Description: Handle save draft and versioning
  // NOTE: draft_id is the unique_id of previous draft saved (null for first version)
  function handleSaveDraft() {
    if (!!compose_message) {
      if (active_draft_state_ref.current === DRAFT_STATES.SAVING) return;
      // NOTE: If not closing modal set state to SAVING
      (active_draft_state_ref.current !== DRAFT_STATES.CLOSING) && setActiveDraftState(DRAFT_STATES.SAVING);
      is_web ? _saveDraftforWeb(compose_message) : _saveDraftforApp(compose_message);
    } else {
      handleComposeErrors(MailErrorMessages.error_saving_draft, { stack_message: MailErrorMessages.error_initializing_compose });
    }
  }

  // Description: Save Draft for Web - IMPORTANT NOTE: These are always saved in EMAIL_PROTOCOL_VERSIONS.V4
  async function _saveDraftforWeb(_compose_message: ComposeMessage) {
    try {
      const draft_data = await _compose_message.prepareWebMail(current_account, true);
      saveDraftData(draft_data[0], _compose_message.save_metadata);
    } catch (error: unknown) {
      handleComposeErrors(MailErrorMessages.error_saving_draft, error);
    }
  }

  // Description: Save Draft for apps
  function _saveDraftforApp(_compose_message: ComposeMessage) {
    const data = _compose_message.app_data;
    saveDraft({
      senderId: current_account.user_id,
      messageData: getMailFormData(data, _compose_message.attachments)
    }).unwrap()
      .then((response: SaveDraftApiResponse) => {
        handleSaveDraftSuccess(response.unique_id);
      })
      .catch((_error: IFetchResponse) => {
        handleComposeErrors(MailErrorMessages.error_saving_draft, _error);
      });
  }

  // Description: Handle success save draft
  //            - on success save setDraftId(response.body.unique_id)
  //            - activate draftSaved notification in footer amd make set timeout to disappear
  //        NOTE: Update draft_id, _previous_draft_id and version (web) in compose_message instance
  function handleSaveDraftSuccess(unique_id?: string, version?: string) {
    !!compose_message && compose_message.updateDraftInfo(unique_id || null, version);
    if (active_draft_state_ref.current === DRAFT_STATES.CLOSING) {
      setActiveDraftState(DRAFT_STATES.READY);
      dispatch(uiActions.handleSetMessage(new Message(MailSuccessMessages.draft_saved_success)));
      handleDiscard();
    } else {
      setActiveDraftState(DRAFT_STATES.SAVED);
      setTimeout(() => setActiveDraftState(DRAFT_STATES.READY), 5000);
    }
  }

  // Description: Handle discard of unused drafts and draft versions
  //              NOTE: Fixes https://preveil.atlassian.net/browse/NEW-52
  // MailSuccessMessages.draft_saved_success MailSuccessMessages.draft_discard_success
  function handleDiscardDraft() {
    const draft_id = compose_message?.draft_id;
    if (!!draft_id && draft_id.length > 1) {
      is_web ? _discardWebDraft(draft_id) : _discardAppDraft(draft_id);
    } else {
      handleDiscard();
    }
  }

  // Description: Discards / deletes draft App
  function _discardAppDraft(draft_id: string) {
    deleteDrafts({
      userId: current_account.user_id,
      emails: [draft_id]
    }).unwrap()
      .then((response: DeleteEmailsApiResponse) => {
        // Note: If call fails in crypto, no error is passed back but the response does not include {draft_id: ClientThreadMessage;} Object
        if (!!response[draft_id]) {
          dispatch(uiActions.handleSetMessage(new Message(MailSuccessMessages.draft_discard_success)));
        } else {
          throw new Error(`DraftId ${draft_id} was not found or deleted`);
        }
      })
      .catch((error: unknown) => {
        dispatch(uiActions.handleRequestErrors(new Message(MailErrorMessages.error_discard_draft), error));
      }).finally(handleDiscard);
  }

  // Description: Discards / deletes draft from CS (web)
  function _discardWebDraft(draft_id: string) {
    const draft_mailbox_id = !!default_mailboxes ? getMailboxCollectionIdByRole(DefaultMailboxes.drafts, default_mailboxes) : null;
    if (!!draft_mailbox_id) {
      deleteWebDrafts({
        account_ids: Account.getAccountIdentifiers(current_account),
        body: {
          collectionId: current_account.mail_cid,
          mbid: draft_mailbox_id,
          id: draft_id,
        }
      }).unwrap()
        .then((response: CollectionServerRevId) => {
          dispatch(uiActions.handleSetMessage(new Message(MailSuccessMessages.draft_discard_success)));
        })
        .catch((error: unknown) => {
          dispatch(uiActions.handleRequestErrors(new Message(MailErrorMessages.error_discard_draft), error));
        }).finally(handleDiscard);
    } else {
      dispatch(uiActions.handleRequestErrors(new Message(MailErrorMessages.error_discard_draft)));
    }
  }

  // --------------------------------------------------------------------------------------
  // Message Send
  // --------------------------------------------------------------------------------------
  // Description: Handle all sending mail functionality: validation, warnings, state change and callbacks
  function handleSendMail(bypass_confirm: boolean = false) {
    if (!!compose_message && ValidateMessageRecipients() && ValidateMessageContent(bypass_confirm)) {
      setStep(COMPOSE_STATES.SENDING);
      is_web ? _sendMailforWeb(compose_message) : _sendMailforApp(compose_message);
    }
  }

  // Description: Send mail for Web build mode
  // NOTE: updates setWebMailData state to activate useSendWebMail hook
  async function _sendMailforWeb(_compose_message: ComposeMessage) {
    if (_compose_message.validateContacts()) {
      try {
        const email_data = await _compose_message.prepareWebMail(current_account);
        setWebMailData(email_data);
      } catch (error: unknown) {
        handleComposeErrors(MailErrorMessages.error_sending_mail, error);
      }
    } else {
      handleComposeErrors(MailErrorMessages.error_sending_mail, { stack_message: MailErrorMessages.error_building_recipients });
    }
  }

  // Description: Send mail for APP build mode
  function _sendMailforApp(_compose_message: ComposeMessage) {
    const data = _compose_message.app_data;
    sendMail({
      senderId: current_account.user_id,
      messageData: getMailFormData(data, _compose_message.attachments)
    }).unwrap()
      .then(() => {
        handleSendMailSuccess();
      })
      .catch((error: unknown) => {
        handleMailSendError(error);
      });
  }

  // Description: Handle error send Mail
  function handleMailSendError(stack?: unknown) {
    dispatch(uiActions.handleRequestErrors(new Message(MailErrorMessages.error_sending_mail), stack));
    setStep(COMPOSE_STATES.SENDING_FAILED);
    setTimeout(() => {
      handleDiscard();
      dispatch(uiActions.setProgress());
    }, 4000);
  }

  // Description: Handle success send Mail
  function handleSendMailSuccess() {
    setStep(COMPOSE_STATES.SUCCESS);
    setTimeout(() => {
      handleDiscard();
      dispatch(uiActions.setProgress());
    }, 4000);
  }

  // Description: Validate body and subject and trigger modal confirm if none - No subject confirmation dialog
  function ValidateMessageContent(bypass_confirm: boolean): boolean {
    const subject_valid = !!subject && subject.length > 0; // bypass dialog warning
    const body_valid = !!message_body && message_body.length > 0;
    if (bypass_confirm || (subject_valid && body_valid)) {
      return true;
    } else {
      const warning = ComposeMessage.getWarningMessage(subject_valid, body_valid);
      const confirmation_dialog = new Message(
        MailMessages.confirm_send_mail_without_data.replace(MessageAnchors.message_content, warning),
        MessageHandlerDisplayType.confirm,
        MessageToastTypes.primary,
        "Send Mail?",
        {
          label: "Yes",
          data: true,
          action: handleSendMail
        },
        { label: "No" }
      );
      dispatch(uiActions.handleSetMessage(confirmation_dialog));
      return false;
    }
  }

  // Description: Validate for Recipients, body and subject length if 0 => ERROR / warning
  function ValidateMessageRecipients(): boolean {
    const recipients_valid = !!recipients && ComposeMessage.groupAllRecipients(recipients).length > 0;
    !recipients_valid && dispatch(uiActions.handleRequestErrors(new Message(MailErrorMessages.error_no_mail_recipients)));
    return recipients_valid;
  }

  // --------------------------------------------------------------------------------------
  // Attachments and Misc others
  // --------------------------------------------------------------------------------------
  // Description: File Upload Modal: Handle selection of External attachments and set to close
  function handleAttachmentChange(_attachments: Attachment[]) {
    setActiveAttachments(_attachments);
    setAttachmentOpened(false);
    handleDraftChange("handleAttachmentChange");
  }

  // Description: Handle on drop add a file
  function handleAttachmentOnDrop(_attachments: Attachment[]) {
    const attachments = active_attachments_ref.current.slice();
    const new_attachments = attachments.length > 0 ? _attachments.concat(attachments) : _attachments;
    setActiveAttachments(new_attachments);
    handleDraftChange("handleAttachmentOnDrop");
  }

  // Description: update user in parent after userlist users are entered and found
  //              actionObj.params Type: { current_contacts, id, contacts_data }
  function handleUpdateContacts(actionObj: IActionHandler) {
    const params = actionObj.params;
    if (!!compose_message && !!params?.current_contacts && !!params.id) {
      const new_recipients: Record<string, UserProfile[]> = {};
      const key = params.id === RecipientKeys.ccs ? RecipientKeys.ccs : params.id === RecipientKeys.bccs ? RecipientKeys.bccs : RecipientKeys.tos;
      new_recipients[key] = params.current_contacts;
      compose_message.updateRecipients(new_recipients);
      setRecipients(compose_message.recipients);
      compose_message.updateContacts(params.contacts_data);
      // NOTE: Send changes to handleDraftChange only when not INITIALIZING
      active_draft_state_ref.current !== DRAFT_STATES.INITIALIZING && !!recipients && handleDraftChange(`handleUpdateContacts: ${key}`);
    } else {
      handleComposeErrors(MailErrorMessages.error_creating_compose);
    }
  }

  // Description: Set flag for switching between views
  function isComposing(): boolean {
    return step !== COMPOSE_STATES.SENDING && step !== COMPOSE_STATES.SENDING_FAILED && step !== COMPOSE_STATES.SUCCESS;
  }

  // Description: Clear all and close modal
  function handleDiscard() {
    const uid = compose_mail_message.uid;
    const new_compose = compose_ref.current.slice();
    _.remove(new_compose, (compose: ComposeMailMessage) => (compose.uid === uid));
    dispatch(mailActions.setComposeMessage(new_compose));
  }

  // Description: Saves Draft (if there are changes) and Closes the modal
  function handleOnHide() {
    if (compose_message?.has_changed) {
      // Add a dialog to allow user to save on close
      const confirmation_dialog = new Message(
        MailMessages.confirm_save_draft_onclose,
        MessageHandlerDisplayType.confirm,
        MessageToastTypes.primary,
        "Save Draft?",
        {
          label: "Save",
          data: true,
          action: () => {
            setActiveDraftState(DRAFT_STATES.CLOSING);
            handleSaveDraft();
          }
        },
        {
          label: "Discard Changes",
          action: handleDiscard
        },
      );
      dispatch(uiActions.handleSetMessage(confirmation_dialog));
    } else {
      if (!!compose_message?.draft_id) {
        dispatch(uiActions.handleSetMessage(new Message(MailSuccessMessages.draft_saved_success)));
      }
      handleDiscard();
    }
    handleSizeChange(index);
  }

  // Description: Calculate the horizontal position of the compose dialog based on Viewport size, modalSize 
  function getModalPosition(): string {
    let total = 0;
    _.forEach(arr, (size: string, i: number) => {
      if (i < index) {
        total = total + (size === ComposeModalSize.lg ? 520 : size === ComposeModalSize.minimized ? 270 : 0);
      }
    });
    return modal_size !== ComposeModalSize.fullscreen ? `${(total) + 24}px` : "0";
  }

  return <>
    {
      isComposing() ?
        <Offcanvas
          className={`compose-mail ${modal_size}`}
          style={(index > 0) ? { right: getModalPosition() } : undefined}
          backdrop={false}
          show={true}
          enforceFocus={false}
          placement="bottom"
          onHide={handleOnHide} >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              {subject.length > 0 ? subject : MAIL_DEFAULT_SUBJECT}
            </Offcanvas.Title>
            <div className="btns">
              {
                modal_size !== ComposeModalSize.minimized &&
                <Button variant="transparent" onClick={() => (setModalSize(ComposeModalSize.minimized))}>
                  <Icon className="pv-icon-minimize" />
                </Button>
              }
              <Button variant="transparent" onClick={() => (setModalSize(modal_size !== ComposeModalSize.lg ? ComposeModalSize.lg : ComposeModalSize.fullscreen))}>
                {
                  modal_size !== ComposeModalSize.fullscreen ?
                    <Icon className="ficon-maximize-2" /> :
                    <Icon className="ficon-minimize-2" />
                }
              </Button>
            </div>
          </Offcanvas.Header>
          {
            <>
              <Offcanvas.Body className="py-0">
                {
                  !recipients_enable &&
                  <ComposeMailRecipients recipients={recipients} handleAction={() => setRecipientsEnable(true)} />
                }
                {
                  !!compose_message &&
                  <div className={recipients_enable ? "d-block" : "d-none"}>
                    <div className="compose-row tos">
                      <label>To:</label>
                      <UserList
                        id={RecipientKeys.tos}
                        focus={recipients_enable}
                        current_account={current_account}
                        user_ids={compose_message.getUserIdsArray(compose_message.tos)}
                        handleAction={handleUpdateContacts}
                        show_external_users={true} />
                      <Button variant="icon" tabIndex={-1} className="toggle-btn" onClick={() => setRecipientsOpen(!recipients_opened)} title={recipients_opened ? "View Cc and Bcc recipients fields" :
                        "Hide other recipients"}>
                        {recipients_opened ? <Icon className="ficon-chevron-up" /> : <Icon className="ficon-chevron-down" />}
                      </Button>
                    </div>
                    <Collapse in={recipients_opened}>
                      <div>
                        <div className="compose-row">
                          <label>Cc:</label>
                          <UserList
                            id={RecipientKeys.ccs}
                            focus={recipients_opened}
                            current_account={current_account}
                            user_ids={compose_message.getUserIdsArray(compose_message.ccs)}
                            handleAction={handleUpdateContacts}
                            show_external_users={true} />
                        </div>
                        <div className="compose-row">
                          <label>Bcc:</label>
                          {/* NOTE: Dont pass any users for bccs on userlist */}
                          <UserList
                            id={RecipientKeys.bccs}
                            focus={recipients_opened}
                            current_account={current_account}
                            user_ids={compose_message.getUserIdsArray(compose_message.bccs)}
                            handleAction={handleUpdateContacts}
                            show_external_users={true} />
                        </div>
                      </div>
                    </Collapse>
                  </div>
                }
                {
                  !!recipients && <ComposeMailNotifications recipients={recipients} />
                }
                <div className="compose-row">
                  <label>Subject:</label>
                  <div>
                    <Form.Control type="text"
                      className="fs-5"
                      value={subject}
                      onChange={(e: FocusEvent<HTMLInputElement>) => (setSubject(e.currentTarget.value))}
                      onFocus={() => setRecipientsEnable(false)}
                      onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                        // NOTE: Enable Shift+tab to the recipients component
                        if (e.shiftKey && e.key === KeyCode.Tab) {
                          e.preventDefault();
                          setRecipientsEnable(true);
                        }
                      }} />
                  </div>
                </div>
                <ComposeMailAttachment
                  opened={attachment_opened}
                  attachments={attachments}
                  handleAttachmentChange={handleAttachmentChange}
                  resetAttachmentOpened={() => setAttachmentOpened(false)} />
                <div className="tiny">
                  {
                    (step === COMPOSE_STATES.LOADING) ?
                      <Loading className="in-place" /> :
                      step === COMPOSE_STATES.ERROR ?
                        <InlineListError>
                          <p>Load error!</p>
                          <small>Please close and retry</small>
                        </InlineListError> :
                        <Editor
                          tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
                          onEditorChange={(newValue: string, editor) => (setMessageBody(newValue))}
                          initialValue={compose_mail?.compose_body}
                          init={{
                            skin: false,
                            content_css: `${process.env.PUBLIC_URL}/common/iframe-styles.css`,
                            content_css_cors: true,
                            height: "100%",
                            lists_indent_on_tab: true,
                            menubar: false,
                            statusbar: false,
                            plugins: ["lists", "link", "image"],
                            convert_urls: false,
                            fontsize_formats: "10px 12px 14px 16px 18px 24px 36px",
                            toolbar: "bold italic | bullist numlist | forecolor backcolor | fontsize | link Attach | Save",
                            setup: (editor) => {
                              editor.ui.registry.addIcon("ficon-paperclip", IconSvgData.paperclip_svg);
                              editor.ui.registry.addIcon("ficon-save", IconSvgData.save_svg);
                              editor.ui.registry.addButton("Attach", {
                                icon: "ficon-paperclip",
                                tooltip: "Attach File",
                                onAction: () => setAttachmentOpened(true)
                              });
                              editor.ui.registry.addButton("Save", {
                                icon: "ficon-save",
                                tooltip: "Save Draft",
                                onAction: () => handleSaveDraft()
                              });
                              editor.on("init", () => {
                                editor.focus(recipients_enable);
                              });
                              editor.on("focus", () => { // NOTE: Set Recipients component to readonly:
                                setRecipientsEnable(false);
                              });
                              editor.on("drop", (e: DragEvent) => {
                                // should be unnecessary, but if this/these props are missing don't want to crash the app
                                if (!e.dataTransfer || !e.dataTransfer.files) { return; }
                                e.preventDefault();
                                const _attachments: Attachment[] = [];
                                for (let i = 0; i < e.dataTransfer.files.length; i++) {
                                  const file = e.dataTransfer.files[i];
                                  let inline: boolean = false;
                                  let c_id: string = "";
                                  if (file.type.includes("image")) {
                                    c_id = Helpers.newMessageId();
                                    inline = true;
                                  }
                                  const attachment: Attachment = Attachment.fromFile(file, inline, c_id);
                                  _attachments.unshift(attachment);
                                  if (inline && !!attachment.content_id) {
                                    editor.contentDocument.body.innerHTML +=
                                      `<img crossorigin="anonymous" data-att-id="${attachment.content_id.slice(1, -1)}" src="${URL.createObjectURL(file)}"/>`;
                                  }
                                }
                                handleAttachmentOnDrop(_attachments);
                              });
                            },
                            content_security_policy: TinyMceCSPHeaders
                            // end of Setup
                          }}
                        />
                  }
                </div>
                <div className="btn-panel d-flex">
                  <Button onClick={() => handleSendMail()} disabled={active_draft_state_ref.current === DRAFT_STATES.SAVING}>Send</Button>
                  <Button variant="no-outline-primary" title="Discard Draft"
                    onClick={handleDiscardDraft} disabled={!compose_message?.draft_id}>Discard</Button>
                  {active_draft_state_ref.current === DRAFT_STATES.SAVED && <span>Saved!</span>}
                  <Button variant="no-outline-secondary ms-auto" onClick={handleDiscard}>Cancel</Button>
                </div>
              </Offcanvas.Body>
            </>
          }
        </Offcanvas> :
        <ComposeMailSending handleDiscard={handleDiscard} step={step} />
    }
  </>;
}

export default React.memo(ComposeMailComponent);