import React, { useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import { IActionHandler, OrgInfo } from "@preveil-api";
import { useAppDispatch, Message, useAppSelector, PublicRoutes, PrivateRoutes, MessageHandlerDisplayType, GlobalErrorMessages, MessageAnchors } from "src/common";
import { DefaultPanel, SuccessPanel } from ".";
import { uiActions, accountActions } from "src/store";
import { RootState } from "src/store/configureStore";
import SettingsImage from "src/assets/images/settings.svg";

function CreateOrganizationComponent() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const current_account = useAppSelector((state: RootState) => state.account.current_account);
  const organization = useAppSelector((state: RootState) => state.account.organization);
  const user_id = current_account?.user_id;

  // Description: Navigate away if already have an org in place onmount AND Clean up any messages on unmount
  useEffect(() => {
    !!organization && navigate(`/${PrivateRoutes.admin_route}/${PrivateRoutes.users_route}`);
    return () => {
      dispatch(uiActions.handleMessageDismiss());
    };
  }, []);

  // Description: Handle all children component actions and store it
  const CreateOrganizationRequests = {
    // Description: Already validated email 
    handleSubmitCreateOrganization: (_organization: OrgInfo) => {
      !!_organization && dispatch(accountActions.setCurrentAccountOrganizationSuccess(_organization));
    },
    handlePageError: (message: string) => {
      dispatch(uiActions.handleRequestErrors(new Message(message)));
    },
    handlePageErrorMessage: (params: { message: string, stack?: any }) => {
      dispatch(uiActions.handleRequestErrors(new Message(params.message, MessageHandlerDisplayType.logger), params.stack));
    }
  };

  //  Description: Handle all actions from Children forms
  function handlePageActions(actionObj: IActionHandler) {
    dispatch(uiActions.handleMessageDismiss());
    const callback = `handle${actionObj.actionType}`;
    // Handle local calls:
    if ((CreateOrganizationRequests as any)[callback] instanceof Function) {
      (CreateOrganizationRequests as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(MessageAnchors.actionType, actionObj.actionType);
      CreateOrganizationRequests.handlePageErrorMessage({ message, stack: actionObj });
    }
  }

  // Description: Return Panels
  const conditionalRender = () => {
    return !!organization ?
      <SuccessPanel name={organization.org_name} /> :
      !!user_id ? <DefaultPanel user_id={user_id} handleAction={handlePageActions} /> :
        <Navigate to={`/${PublicRoutes.login_route}`} />;
  };

  return <Row className="justify-content-center mt-3">
    <Col md={7}>
      <h1 className="main-header">{!!organization ? "Successfully Created Organization" : "Create New Organization"}</h1>
      <Card className="pv-card">
        {!!organization && <Card.Img variant="top" src={SettingsImage} />}
        <Card.Body>
          {conditionalRender()}
        </Card.Body>
      </Card>
    </Col>
  </Row>;
}

export default React.memo(CreateOrganizationComponent);
