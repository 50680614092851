import { useRef, useState, memo, ChangeEvent, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { AdminErrorMessages, AdminUIActionTypes } from "src/common";
import { Formik } from "formik";

type AllProps = {
    show_modal: boolean;
    handleAction: ActionHandlerFunction;
}

function AddDeviceCertificateModal({ show_modal, handleAction }: AllProps) {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [certificate, setCertificate] = useState<string>();
    const [is_certificate_valid, setIsCertificateValid] = useState<boolean>(false);

    // Description: Enable the save button only if the certificate is valid
    useEffect(() => {
        if (!!certificate) {
            const startIndex = certificate.indexOf("-----BEGIN CERTIFICATE-----");
            const endIndex = certificate.indexOf("-----END CERTIFICATE-----");
            if (startIndex !== -1 && endIndex !== -1) {
                setIsCertificateValid(true);
            } else {
                setIsCertificateValid(false);
            }
        } else {
            setIsCertificateValid(false);
        }
    }, [certificate]);

    // Description: Handles importing and extracting the certificate details
    function handleFileImport(event: ChangeEvent<HTMLInputElement>) {
        const file = event.target.files?.[0];
        if (file) {
            if (file.name.endsWith(".crt") || file.name.endsWith("pem")) {
                const reader = new FileReader();
                reader.onload = () => {
                    const certificate = reader.result as string;
                    const startIndex = certificate.indexOf("-----BEGIN CERTIFICATE-----");
                    const endIndex = certificate.indexOf("-----END CERTIFICATE-----");
                    const extractedCertificate = certificate.substring(startIndex, endIndex + "-----END CERTIFICATE-----".length);
                    setCertificate(extractedCertificate);
                };
                reader.readAsText(file);
            } else {
                handleAction({ actionType: AdminUIActionTypes.PageErrorMessage, params: { message: AdminErrorMessages.error_invalid_certificate_extension } });
            }
        }
    };

    function handleShowModal() {
        setCertificate("");
        setIsCertificateValid(false);
        handleAction({ actionType: AdminUIActionTypes.ShowModal });
    }

    function handleSubmit(): void {
        handleAction({ actionType: AdminUIActionTypes.UploadDeviceCertificate, params: { certificate } });
        handleShowModal();
    }

    return <Modal
        size="lg"
        show={show_modal}
        onHide={handleShowModal}
        aria-labelledby="add device certificate"
        centered>
        <Modal.Header closeButton>
            <Modal.Title>Device Access Requirements</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                <Formik
                    initialValues={{ certificate }}
                    onSubmit={(values, { setSubmitting }) => {
                        handleSubmit();
                        setSubmitting(false);
                    }} >
                    {({ handleSubmit }) => (
                        <Form noValidate className="m-2" onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="certificate">
                                <Form.Control as="textarea" rows={10}
                                    autoFocus={true}
                                    required
                                    value={certificate}
                                    onChange={(e) => setCertificate(e.currentTarget.value)}
                                    name="certificate"
                                    autoComplete="off"
                                    placeholder="Copy and paste your certificate here."
                                    className="certificate-textarea"
                                />
                            </Form.Group>
                            <Form.Group className="float-end">
                                <input type="file" className="d-none" ref={fileInputRef} onChange={handleFileImport} />
                                <Button variant="no-outline-primary" onClick={() => fileInputRef.current?.click()}>Import File</Button>
                            </Form.Group>

                            <footer className="mt-4">
                                <Button type="submit" disabled={!is_certificate_valid} className="me-2">Save</Button>
                                <Button onClick={() => handleShowModal()} variant="no-outline-primary">Cancel</Button>
                            </footer>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal.Body>
    </Modal>;
}

export default memo(AddDeviceCertificateModal);
