import { useCallback, useEffect } from "react";
import {
  Account, useAppSelector, useAppDispatch, PermissionSetType, GrantSet, PathNode, PathList, ActiveGrantSet, useGetGrants
} from "src/common";
import { RootState } from "src/store/configureStore";
import { driveActions } from "src/store";
import _ from "lodash";


export function usePathList(current_account: Account, collection_id?: string, include_deleted: boolean = false) {
  const default_permissions = useAppSelector((state: RootState) => state.drive.default_permissions);
  const default_grants = useAppSelector((state: RootState) => state.drive.default_grants);
  const path_list = useAppSelector((state: RootState) => state.drive.current_directory?.path_list);
  const { grants, getGrants } = useGetGrants(current_account, include_deleted);
  const dispatch = useAppDispatch();

  // Description: Decode all paths
  useEffect(() => {
    (!!path_list && path_list.length > 0) &&
      getBreadcrumbsPath(path_list, collection_id);
  }, [path_list, grants]);

  // Description: Validate grants
  function validateGrants(list: PathNode[], _collection_id?: string): ActiveGrantSet | null {
    if (!!_collection_id) {
      const active_grants: ActiveGrantSet = {};
      _.forEach(list, (node: PathNode) => {
        if (node.id === node.maintainer_id) {
          const grants = GrantSet.getActive(default_grants, _collection_id, node.maintainer_id, current_account.user_id);
          active_grants[node.maintainer_id] = grants || null;
          !grants && getGrants(node.maintainer_id, _collection_id, default_permissions, default_grants);
        }
      });
      return active_grants;
    } else {
      return null;
    }
  }

  // Description: Build and save breadcrumbs PathInfo[]
  async function getBreadcrumbsPath(list: PathNode[], _collection_id?: string) {
    const permissions = _.find(default_permissions, (set: PermissionSetType) => _collection_id === set.collection_id.B64());
    const active_grants = validateGrants(list, _collection_id);
    if (!!permissions && !!active_grants) {
      const paths = await PathList.buildPathInfoFromNodes(list, current_account, permissions, active_grants);
      dispatch(driveActions.setBreadcrumbsPaths(paths));
    }
  }

  const setBreadcrumbs = useCallback((path_list?: PathNode[], _collection_id?: string) => {
    !!path_list && path_list.length > 0 && getBreadcrumbsPath(path_list, _collection_id);
  }, []);

  return {
    setBreadcrumbs
  };
}
