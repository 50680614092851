import { useState, useEffect, useCallback, useRef } from "react";
import { MessageDisplayType, CollectionServerUser, ErrorStackDataType } from "@preveil-api";
import { FSMessage, FSStatus, FSRequest, FSRole } from "src/common/keys/protos/collections_pb";
import { EncryptionStyle } from "src/common/keys/encryption";
import {
  Account, CollectionEntity, PermissionSetType, useAppSelector, useAppDispatch, NodePermissionSet, MessageHandlerDisplayType, Message, Helpers,
  getEnumKey, useGetPermissions, DriveErrorMessages, usePostUsersFindMutation, PermissionSet, FSRoleType, NodePermissionTypeToFSRoleType, PublicKeyUser,
  Permission, KeyFactory, useSendRequestMutation, CollectionFilesyncAPI, DriveSuccessMessages, NodePermissionType, dayjs, NodePermissionTypes, isSameDate,
  parseCollectionUserToPublicKeyUser, AppUserKey, isSameUser, GrantAccessData, DriveRequest, ErrorStackItem
} from "src/common";
import { RootState } from "src/store/configureStore";
import { uiActions, DriveCallbackAsyncFunction } from "src/store";
import _ from "lodash";


interface ChangeSetBase {
  view_only: boolean;
  expiration: string;
  type: NodePermissionTypes;
}

interface ChangeSet extends ChangeSetBase {
  grantee: string;
  roles: FSRoleType[];
  promoted: boolean;
  previous_set?: ChangeSetBase;
};

type PreviousSet = { [key: string]: ChangeSetBase };
type UpdateCollectionRoles = {
  role: FSRoleType;
  grantees: string[];
}


type UserSetType = PublicKeyUser & ChangeSet;
type GranteeSetType = PublicKeyUser & { view_only: boolean };
type NewCollectionRoles = {
  role: FSRoleType;
  grantees: GranteeSetType[];
}

type GrantAccessChages = { changes: ChangeSet[], users: PublicKeyUser[] } | undefined;

// Description: Update Access for V1 collections: Permissions, expiration date, and view only mode
// Params: active: boolean used to activate this hook. // TODO: will need to abstract to a different system V1 vs V2
export function useUpdateV1Collection(current_account: Account, collection_info: CollectionEntity, active: boolean = false) {
  const default_permissions: PermissionSetType[] = useAppSelector((state: RootState) => state.drive.default_permissions);
  const [node_permissions, setNodePermissions] = useState<NodePermissionSet[]>([]);
  const [change_set, setChangeSet] = useState<NodePermissionSet[] | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<NodePermissionSet[] | undefined>();
  const { permissions: permission_set, getPermissions, getDefaultPermissions, destroyPermissions } = useGetPermissions(current_account);
  const [sendRequest] = useSendRequestMutation();
  const [findUsers] = usePostUsersFindMutation();
  const dispatch = useAppDispatch();
  // Note: Hold an active ref of grant_access_changes 
  const grant_access_changes = useRef<GrantAccessChages>();
  function setGrantAccessChanges(_grant_access_changes?: GrantAccessChages) {
    grant_access_changes.current = _grant_access_changes;
  }

  // Description: Fetch permissions and reset on success
  useEffect(() => {
    if (!!collection_info && active) {
      getPermissions(collection_info.collection_id, default_permissions);
      // NOTE: Trigger success after default permissions are reset 
      loading && handleSuccess();
    }
  }, [collection_info]);

  // Description: Initialize calls to update collection by getting the shared with information 
  useEffect(() => {
    if (!!change_set && !!permission_set && !!node_permissions) {
      const _user_ids = _.map(node_permissions, (node: NodePermissionSet) => node.user_id);
      _user_ids.length > 0 ? handleFindUsers(_user_ids, collection_info.collection_id) :
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_users });
    }
  }, [change_set]);

  // Description: Detect changes on the default_permissions after Succesfully Rekeying
  // if need to promote then trigger promotePermissionsV1, else complete rekey
  useEffect(() => {
    if (loading && !data) {
      if (!!grant_access_changes.current) {
        // NOTE: Send Promotions after rekey
        getPermissions(collection_info.collection_id, default_permissions);
        const _permission_set = _.find(default_permissions, (set: PermissionSetType) => collection_info.collection_id === set.collection_id.B64());
        !!_permission_set && promotePermissionsV1(grant_access_changes.current.changes, grant_access_changes.current.users, _permission_set);
      } else {
        // NOTE: Only rekey_required success (no promotions)
        handleSuccess();
      }
    }
  }, [default_permissions]);

  // Description: Get updated Users information
  function handleFindUsers(_user_ids: string[], collection_id: string) {
    const spec = _user_ids.map((user_id: string) => {
      return { user_id, key_version: -1 };
    });
    findUsers({
      account_ids: Account.getAccountIdentifiers(current_account),
      body: { spec }
    }).unwrap()
      .then(async ({ users }) => {
        // NOTE: Handle successful acl_tree
        const _users = await Promise.all(_.map(users, async (cs_user: CollectionServerUser) => {
          return await parseCollectionUserToPublicKeyUser(cs_user);
        }));

        return _.compact(_users);
      }).then((users: PublicKeyUser[]) => {
        users.length > 0 ?
          buildUpdatedPermissions(users) :
          handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_building_node_grant_permissions });
      });
  }

  // Description: Build the new permission sets and prepare the request
  function buildUpdatedPermissions(users: PublicKeyUser[]) {
    const owner = _.find(node_permissions, ((node_permission: NodePermissionSet) => node_permission.is_owner));
    if (!!permission_set && !!owner) {
      const latest_permissions = PermissionSet.latestPermissionByRole(permission_set.permissions);
      const current_roles: UpdateCollectionRoles[] = buildCurrentRoles(node_permissions);
      const previous_sets: PreviousSet = buildPreviousSetbyUser(node_permissions);
      // NOTE: Incorporate the changes to the fsrole - new_roles only used for demotePermissionsV1
      const { changeset, new_roles, rekey_required } = buildChangeSet(current_roles, previous_sets, users);

      // NOTE: Change Grants for promoted users AND for changes in expiration date even if is a demotion
      const changes = _.filter(changeset, (set: ChangeSet) => (set.promoted || !isSameDate(set.expiration, set.previous_set?.expiration)));

      // NOTE: there are promotions in the changeset
      changes.length > 0 && setGrantAccessChanges({ users, changes });
      // NOTE: If Demotions => rekey_required === true - Rekey first then change grant access if needed
      rekey_required ? demotePermissionsV1(new_roles, latest_permissions, users) :
        promotePermissionsV1(changes, users, permission_set);
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_building_node_grant_permissions });
    }
  }

  // Description: Build the Changeset, NewRoles for the FSRoles and set rekey_required
  function buildChangeSet(
    current_roles: UpdateCollectionRoles[],
    previous_sets: PreviousSet,
    users: PublicKeyUser[]
  ): { changeset: ChangeSet[], new_roles: NewCollectionRoles[], rekey_required: boolean } {
    let _new_roles: UpdateCollectionRoles[] = current_roles.slice();
    let rekey_required = false;
    const changeset: ChangeSet[] = _.map(change_set, (change: NodePermissionSet) => {
      // NOTE: Create a new fsroles array with the grantee's NEW ROLES
      const roles = change.type !== NodePermissionType.unshare ? NodePermissionTypeToFSRoleType[change.type] : [];
      const expiration = !!change.expiration && dayjs(change.expiration).isValid() ? dayjs(change.expiration).format() : "";
      const grantee = change.user_id;
      const previous_set = Object.prototype.hasOwnProperty.call(previous_sets, grantee) ? previous_sets[grantee] : undefined;
      let promoted = true;
      // NOTE: Update the new_roles with Changes for this grantee 
      _new_roles = _.map(_new_roles, (current_role: UpdateCollectionRoles) => {
        const new_grantees = current_role.grantees.slice(); // HOLDS the new array of user_ids for a role type
        // NOTE: Check to see if the current role is already in the NEW acl array of the user changeset - 
        const role_exists = roles.length > 0 && roles.includes(current_role.role);
        const grantee_exists = new_grantees.includes(grantee);
        // NOTE: add grantee to current_roles.grantee array; - Don't add because on demote we do not add new grantees -  causes error with BE
        // if (role_exists && !grantee_exists) {  new_grantees.push(grantee); } else
        if (!role_exists && grantee_exists) {
          // NOTE: remove grantee from current_roles.grantee array (Grantee is demoted)
          new_grantees.splice(new_grantees.indexOf(grantee), 1);
          promoted = false;
          rekey_required = true;
        } else if (!!previous_set && (previous_sets[grantee].view_only !== change.view_only && change.view_only)) {
          // NOTE: This block checks if rekey is needed when changes between READ ONLY and VIEW ONLY 
          // Grantee is demoted from read only to view only => Only set rekey if this is a demotion from Read Only to View Only
          promoted = false;
          rekey_required = true;
        }
        return { role: current_role.role, grantees: new_grantees };
      });
      return {
        grantee,
        roles,
        view_only: change.view_only,
        expiration,
        type: change.type,
        promoted,
        previous_set
      };
    });
    // NOTE: Only build new_roles if  rekey_required
    const new_roles = rekey_required ? parseNewRoles(_new_roles, users, previous_sets) : [];
    return { changeset, new_roles, rekey_required };
  }

  // Description: convert UpdateCollectionRoles[] to  NewCollectionRoles[]
  function parseNewRoles(_new_roles: UpdateCollectionRoles[], users: PublicKeyUser[], previous_sets: PreviousSet): NewCollectionRoles[] {
    const new_roles = _.map(_new_roles, (new_role: UpdateCollectionRoles) => {
      const new_grantees = new_role.grantees;
      const grantees = _.compact(_.map(new_grantees, (user_id: string) => {
        const changeset = _.find(change_set, (change: NodePermissionSet) => isSameUser(change.user_id, user_id));
        const user = _.find(users, user => isSameUser(user.user_id, user_id));
        if (!!user) {
          // Description: Get View Only from new changeset or default to previous
          const view_only = (!!changeset && isSameUser(user_id, changeset.user_id)) ?
            changeset.view_only : !!previous_sets[user_id] ? previous_sets[user_id].view_only : false;
          return { ...user, ...{ view_only } };
        }
      }));
      return { role: new_role.role, grantees };
    });
    return new_roles;
  }

  // Description: Build the current roles before new ones get generated
  function buildCurrentRoles(_node_permissions: NodePermissionSet[]): UpdateCollectionRoles[] {
    const current_roles: UpdateCollectionRoles[] = []; // returns 0-5 roles
    const grantee_roles = _.map(_node_permissions, (_node_permission: NodePermissionSet) => {
      return {
        user_id: _node_permission.user_id,
        roles: _node_permission.is_owner ? NodePermissionTypeToFSRoleType.owner : NodePermissionTypeToFSRoleType[_node_permission.type]
      };
    });
    const roles = NodePermissionTypeToFSRoleType.owner;
    _.forEach(roles, (role: FSRoleType) => {
      const grantees: string[] = [];
      _.forEach(grantee_roles, grantee => {
        if (grantee.roles.includes(role)) {
          grantees.push(grantee.user_id);
        }
      });
      current_roles.push({
        role,
        grantees
      });
    });
    return current_roles;
  }

  // Description: build the previous set {view_only, expiration} per grantee 
  function buildPreviousSetbyUser(_node_permissions: NodePermissionSet[]): PreviousSet {
    const previous_set: PreviousSet = {};
    _.forEach(_node_permissions, (node: NodePermissionSet) => {
      previous_set[node.user_id] = {
        view_only: node.view_only,
        expiration: node.expiration,
        type: node.type
      };
    });
    return previous_set;
  }

  // -------------------------------------------------------------------------------------------- //
  //  Share (promote)
  // -------------------------------------------------------------------------------------------- //
  // Description: Promote permissions for V1 collections
  async function promotePermissionsV1(changeset: ChangeSet[], users: PublicKeyUser[], _permission_set: PermissionSetType) {
    !!grant_access_changes.current && setGrantAccessChanges();
    const permissions_set = PermissionSet.init(_permission_set);
    const permission_user_map = new Map<NodePermissionTypes, UserSetType[]>;
    _.forEach(changeset, (set: ChangeSet) => {
      const user = _.find(users, (_user: PublicKeyUser) => isSameUser(_user.user_id, set.grantee));
      if (!!user) {
        const user_set = { ...user, ...set };
        const prev = permission_user_map.get(set.type);
        const _users = !!prev ? prev.concat(user_set) : [user_set];
        permission_user_map.set(set.type, _users);
      }
    });

    // NOTE: Iterate through the mapped object
    _.forEach(Array.from(permission_user_map.keys()), async (type) => {
      const _grantees = permission_user_map.get(type);
      if (!!_grantees) {
        const fsroles: FSRoleType[] = NodePermissionTypeToFSRoleType[type];
        const params = await buildGrantAccessData(permissions_set, _grantees, fsroles);
        !!params && share(params.grant_users, params.roles, _permission_set);
      }
    });
  }

  /**
   * Description: Build the Grant Role Info object for each node_permission type
   * @param collection_name: string,
   * @param permissions_set: PermissionSet,
   * @param users: PublicKeyUser[],
   * @param fsRoles: FSRoleType[]
   * @param view_only: boolean
   * @param expiration optional
   * @returns 
   * 
  */
  async function buildGrantAccessData(
    permissions_set: PermissionSet, // latest permissions_set 
    users: UserSetType[],
    fsRoles: FSRoleType[]
  ): Promise<GrantAccessData | undefined> {
    const collection_name = collection_info.collection_name;
    // NOTE: Build FSRequest.Grant.Role Array
    const roles: FSRequest.Grant.Role[] = [];
    _.forEach(fsRoles, (fsrole: FSRoleType) => {
      const permission = permissions_set.permission(fsrole); // NOTE: returns latest
      if (!!permission) {
        const role = new FSRequest.Grant.Role();
        role.setRole(fsrole);
        role.setVersion(permission.key_version);
        roles.push(role);
      }
    });

    // NOTE: Build Grant Users
    const grant_users = await Promise.all(_.map(users, async (user: UserSetType) => {
      // NOTE: Build the buildGrantRolesInfo to pass it to the buildGrantUser => FSRequest.Grant.User.RoleInfo[]
      const roles_info = await buildGrantRolesInfo(roles, user, permissions_set);
      // NOTE:Build the encrypted_name as Uint8Array
      const encryption_key = await KeyFactory.newEncryptionKey({
        protocol_version: user.public_user_key.public_key.protocol_version,
        key: user.public_user_key.public_key.public_key,
        as_public: true,
        style: EncryptionStyle.DRIVE
      });
      const encrypted_name = await encryption_key.seal(Helpers.utf8Encode(collection_name));
      return buildGrantUser(user, encrypted_name, roles_info);
    }));
    return { grant_users, roles };
  }

  // Description: Build Users Roles Info Array
  async function buildGrantRolesInfo(roles: FSRequest.Grant.Role[], user: UserSetType, permissions_set: PermissionSet) {
    const roles_info: Array<Promise<FSRequest.Grant.User.RoleInfo>> = [];
    _.forEach(roles, async (grant_role: FSRequest.Grant.Role) => {
      const fsrole = grant_role.getRole();
      const permission = fsrole !== undefined ? permissions_set.permission(fsrole) : null;
      if (!!permission) {
        roles_info.push(buildGrantRoleInfo(permissions_set.collection_id.String().toLowerCase(), user, permission, fsrole));
      }
    });
    return await Promise.all(roles_info);
  }

  /**
   * Description: Build the Grant Role Info object for each user
   * https://github.com/PreVeil/core/blob/dev/collection_server/docs/filesync.md#grant
   * Grants a set of roles (collection keys) to a set of users.
   *  All users get the same keys, but the view_only field and the expiration_time is set on a per-user basis. 
   *  The view_only field is set for the user's reader key, and the expiration_time is set for all of the user's keys at once.
   * @param collection_id - uuid lowercase
   * @param user 
   * @param permission 
   * @param role 
   * @param key 
   * @param view_only
   * @returns Promise<FSRequest.Grant.User.RoleInfo>
   */
  async function buildGrantRoleInfo(
    _collection_id: string,  // uuid lowercase
    user: UserSetType,
    permission: Permission,
    role: FSRoleType = FSRole.READER):
    Promise<FSRequest.Grant.User.RoleInfo> {
    const unwrappedKey = await permission.key(current_account);
    const key = await user.public_user_key.public_key.seal(unwrappedKey.serialize());
    const hash_key = await Helpers.sha256Checksum(key);
    const user_id = user.user_id.toLowerCase();
    const user_key_version = user.public_user_key.key_version;
    const role_string = getEnumKey(FSRole, role);
    const key_version = permission.key_version || 0;
    const hash = Helpers.hexEncode(hash_key).toLowerCase();
    // Compute a signed canonical string to verify the role
    const canonicalString = `${user_id},${user_key_version},${_collection_id},${role_string},${key_version},${hash}`;
    const signed_key = await current_account.user_key.signing_key.sign(Helpers.utf8Encode(canonicalString));
    const role_info = new FSRequest.Grant.User.RoleInfo();
    role_info.setSignature(signed_key);
    role_info.setRole(role);
    role_info.setWrappedKey(key);
    // NOTE: The view_only field is set for the user's reader key (READ ONLY KEY === 1)
    role === FSRole.READER && role_info.setViewOnly(user.view_only);
    return role_info;
  }


  // Description: Build the Grant User object
  function buildGrantUser(
    user: UserSetType,
    encrypted_name: Uint8Array,
    roleinfo: FSRequest.Grant.User.RoleInfo[]):
    FSRequest.Grant.User {
    const grant_user = new FSRequest.Grant.User();
    grant_user.setUserId(user.user_id);
    grant_user.setKeyVersion(user.key_version);
    grant_user.setEncCollectionName(encrypted_name);
    grant_user.setRoleInfoList(roleinfo);
    if (!!user.expiration) {
      grant_user.setExpirationTime(user.expiration);
      // NOTE: grant_user.setExpirationTime(!!changeset ? changeset.expiration : previous_set.expiration);
    }
    return grant_user;
  }

  // Description: Grant Access 
  async function share(users_list: FSRequest.Grant.User[], roles: FSRequest.Grant.Role[], _permission_set: PermissionSetType) {
    const drive_request = !!_permission_set ?
      await CollectionFilesyncAPI.grantAccess(current_account, _permission_set, collection_info.collection_id, roles, users_list) : null;
    async function callback(message: FSMessage) {
      (message.getStatus() === FSStatus.OK) ? getDefaultPermissions(collection_info.collection_id) :
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_updating_node_permissions }, message);
    }
    handleSendRequest(drive_request, callback, DriveErrorMessages.error_updating_node_permissions);
  }

  // -------------------------------------------------------------------------------------------- //
  //  Rekey (demote)
  // -------------------------------------------------------------------------------------------- //
  async function demotePermissionsV1(
    new_roles: NewCollectionRoles[],
    latest_permissions: Permission[],
    users: PublicKeyUser[]) {
    const rekey_roles = await prepareRekeyParams(new_roles, latest_permissions, users);
    rekeyPermissions(rekey_roles);
  }

  // Description: Prepare the Rekey Roles Object for demotions
  async function prepareRekeyParams(new_roles: NewCollectionRoles[], latest_permissions: Permission[], users: PublicKeyUser[]):
    Promise<FSRequest.Rekey.RekeyRole[]> {
    const rekey_roles: Array<Promise<FSRequest.Rekey.RekeyRole>> = [];
    _.forEach(new_roles, async (collection_role: NewCollectionRoles) => {
      const permission = _.find(latest_permissions, (_permission: Permission) => _permission.role === collection_role.role);
      if (!!permission) {
        rekey_roles.push(buildRekeyRequest(permission, collection_role, users));
      }
    });
    return await Promise.all(rekey_roles);
  }

  // Desription: Build the rekeyrole
  // https://github.com/PreVeil/core/blob/dev/collection_server/docs/filesync.md#rekey
  async function buildRekeyRequest(
    permission: Permission,
    collection_role: NewCollectionRoles,
    users: PublicKeyUser[]):
    Promise<FSRequest.Rekey.RekeyRole> {
    const role_key = await permission.key(current_account);
    const key_version = permission.key_version + 1;
    const key = await KeyFactory.newUserKey({ key_version, style: EncryptionStyle.DRIVE });
    const wrapped_old_role_key = await key.public_user_key.public_key.seal(role_key.serialize());
    const new_role_public_key = new FSRequest.PublicKey();
    new_role_public_key.setPublicKey(key.public_user_key.public_key.public_key);
    new_role_public_key.setVerifyKey(key.public_user_key.verify_key.public_key);
    new_role_public_key.setVersion(key.key_version);
    new_role_public_key.setPublicKeyProtocol(key.public_user_key.public_key.protocol_version);
    new_role_public_key.setVerifyKeyProtocol(key.public_user_key.verify_key.protocol_version);
    const acl_list = await buildAclListPermissions(collection_role, key);
    // TO DO: Group ACL 
    const rekey_request = new FSRequest.Rekey.RekeyRole();
    rekey_request.setRole(collection_role.role);
    rekey_request.setPublicKey(new_role_public_key);
    rekey_request.setUserAclList(acl_list);
    rekey_request.setWrappedLastKey(wrapped_old_role_key);
    rekey_request.setGroupAclList([]);
    return rekey_request;
  }

  // Description: Build new ACL List Permissions for all grantees
  async function buildAclListPermissions(collection_role: NewCollectionRoles, key: AppUserKey): Promise<FSRequest.Permission[]> {
    const acl_list = _.map(collection_role.grantees, async (grantee: GranteeSetType) => {
      const permission = new FSRequest.Permission();
      permission.setUserId(grantee.user_id);
      permission.setWrappedKey(await grantee.public_user_key.public_key.seal(key.serialize()));
      permission.setUserKeyVersion(grantee.key_version);
      // NOTE: The view_only field is set for the user's reader key (READ ONLY KEY === 1)
      collection_role.role === FSRole.READER && permission.setViewOnly(grantee.view_only);
      return permission;
    });
    return await Promise.all(acl_list);
  }


  // Description: Rekey Permission on demotions (legacy: rekeyRequest)
  async function rekeyPermissions(rekey_roles: FSRequest.Rekey.RekeyRole[]) {
    const drive_request = !!permission_set ?
      await CollectionFilesyncAPI.rekeyCollection(current_account, permission_set, collection_info.collection_id, rekey_roles) : null;
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        getDefaultPermissions(collection_info.collection_id);
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_updating_node_permissions }, message);
      }
    }

    handleSendRequest(drive_request, callback, DriveErrorMessages.error_updating_node_permissions);
  }

  // -----------------------------
  // HOOK General
  // -----------------------------
  // Description: Sends Request and handles error when the request object is null, pass it custom stack message
  function handleSendRequest<T>(request: DriveRequest | null, callback: DriveCallbackAsyncFunction<T>, stack_error: string = DriveErrorMessages.error_sending_request) {
    if (!!request) {
      request.setCallback(callback);
      sendRequest(request);
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, { stack_error, stack_message: DriveErrorMessages.error_sending_request });
    }
  }

  // Description: Handle error message and send error to store
  function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, fsmessage?: FSMessage | null,
    display_type: MessageDisplayType = MessageHandlerDisplayType.toastr) {
    const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
    const stack = new ErrorStackItem("[useUpdateV1Collection Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
    dispatch(uiActions.handleRequestErrors(new Message(message, display_type), stack));
    setLoading(false);
    setData(undefined);
  }

  // Description: Handle successful changing of the permissions
  function handleSuccess() {
    setLoading(false);
    setData(change_set);
    dispatch(uiActions.handleSetMessage(new Message(DriveSuccessMessages.save_node_permissions_success)));
  }

  // Description: Call to get collection level permissions
  const save = useCallback((changes: NodePermissionSet[]) => {
    setLoading(true);
    const all = collection_info.shared_with.slice();
    all.push(collection_info.permissions);
    setNodePermissions(all);
    setChangeSet(changes);
  }, []);

  // Description: Reset hook for next call
  const reset = useCallback(() => {
    setLoading(false);
    setData(undefined);
    setChangeSet(undefined);
    destroyPermissions();
  }, []);

  return {
    save,
    reset,
    loading,
    data
  };
}