import React from "react";
import { Button, Form, Col, Row } from "react-bootstrap";

function FormsComponent() {
  // Description: Forms
  const Forms = () => {
    return (
      <>
        <h4>Basic Form</h4>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Enter email" aria-label="email" />
            <Form.Text className="text-muted d-block mt-1">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Password" aria-label="password" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Check me out" aria-label="checkbox" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formDropdown">
            <Form.Select aria-label="dropdown">
              <option>Open this select menu</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select>
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
        <hr />
        <h4>Form with Column Sizing</h4>
        <Form>
          <Row>
            <Col xs={4}>
              <Form.Group className="mb-3" controlId="formComplexEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter Email" aria-label="email" />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <Form.Group className="mb-3" controlId="formFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control type="text" placeholder="Enter First Name" aria-label="dropdown" />
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group className="mb-3" controlId="formLastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control type="text" placeholder="Enter Last Name" aria-label="last name" />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <Form.Group className="mb-3" controlId="formDepartment">
                <Form.Label>Department</Form.Label>
                <Form.Control type="text" placeholder="Enter Department" aria-label="department" />
              </Form.Group>
            </Col>
          </Row>
          <Button variant="primary" type="submit">
            Create Account
          </Button>{" "}
          <Button variant="no-outline-primary" type="submit">
            Reset
          </Button>{" "}
          <Button variant="no-outline-primary" type="submit">
            Cancel
          </Button>
        </Form>
        <hr />
        <h4>React Switch</h4>
        <Form>
          <Form.Check type="switch" id="custom-switch1" label="click me" />
          <Form.Check disabled type="switch" id="custom-switch2" label="disabled" />
        </Form>
        <h4>PreVeil Switch</h4>
        <div className="switch-preveil">
          <Form.Check type="switch" id="custom-switch3" label="click me" />
        </div>
        <hr />
      </>
    );
  };

  return <Forms />;
};

export default React.memo(FormsComponent);
