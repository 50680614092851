import { useState, useEffect, useCallback, useRef } from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { MessageDisplayType, ErrorStackDataType, Sort } from "@preveil-api";
import { Node, FSMessage, FSStatus } from "src/common/keys/protos/collections_pb";
import {
    Account, useAppSelector, useAppDispatch, PermissionSetType, NodeIdentifier, SearchSnapshot, useGetPermissions, DriveRequest, SnapshotResult,
    COLLECTION_PROTOCOL_VERSIONS, GrantSetType, ErrorStackItem, Message, MessageHandlerDisplayType, getEnumKey, DriveErrorMessages, GrantSet,
    SearchClient, SearchNodeBase, SearchNode, CollectionFilesyncAPI, useSendRequestMutation, sortBy, CollectionEntity, SearchBase
} from "src/common";
import { RootState } from "src/store/configureStore";
import { uiActions, DriveCallbackAsyncFunction } from "src/store";
import _ from "lodash";
const SNAPSHOT_LIMIT = 2000;
const INDEXED_DB_NODE_ROW_MAX = SNAPSHOT_LIMIT - 1;

// Progress Report according to path for each: Promote, ShareV2 and ShareV1
export const SearchProgressValues = {
    initial: 0,
    loading: 10,
    preflight: 15,
    setPendingLinks: 20,
    checkHasMore: 80,
    complete: 100
};

type CollectionsData = {
    collection_id: string;
    id: string;
    permissions?: PermissionSetType;
    collection_protocol_version?: COLLECTION_PROTOCOL_VERSIONS;
    search_snapshot?: SearchSnapshot;
}
type MaintanerGrantsMap = Map<string, GrantSetType>;

type PendingData = {
    collection_id: string;
    id: string;
    permissions: PermissionSetType;
    directory: FSMessage.Dir;
}

export function useDriveSearch(current_account: Account, sort: Sort<keyof SearchNode>, collection_info: CollectionEntity, search_term?: string) {
    const default_permissions: PermissionSetType[] = useAppSelector((state: RootState) => state.drive.default_permissions);
    const root_info = useAppSelector((state: RootState) => state.drive.root_info);
    const default_grants = useAppSelector((state: RootState) => state.drive.default_grants);
    const [search_client] = useState<SearchClient>(new SearchClient(current_account.user_id));
    const [progress, setProgress] = useState<number>(SearchProgressValues.initial);
    const [error, setError] = useState<boolean>(false);
    const [node_identifiers, setNodeIdentifiers] = useState<NodeIdentifier | undefined>(); // NOTE; This holds the identifier of the root snapshot
    const { permissions, getPermissions, destroyPermissions } = useGetPermissions(current_account);
    const [sendRequest] = useSendRequestMutation();
    const dispatch = useAppDispatch();
    const link_queue = useRef<string[]>([]);
    const link_total_ref = useRef<number>(0); // Total number of Links to be fetched
    const link_counts_ref = useRef<number>(0); // Total number of Links already fetched
    const dir_total_ref = useRef<number>(0); // Total number of Directories to decrypt
    const dir_counts_ref = useRef<number>(0); // Total number of Directories  already decrypted and added to db
    const dynamic_progress_ref = useRef<number>(0); // hold progress dynamically

    // Description: Maintainer nodes fetched grants
    const maintainer_grants_ref = useRef<MaintanerGrantsMap>(new Map<string, GrantSetType>);
    function setMaintainerGrantsRef(id: string, maintainer_grants: GrantSetType) {
        maintainer_grants_ref.current.set(id, maintainer_grants);
    }

    // Description: get all the results from DB
    const results = useLiveQuery(
        async () => {
            if (!!search_term) {
                const result = await search_client.db.nodes
                    .where("name_tokens")
                    .startsWithIgnoreCase(search_term)
                    .limit(SNAPSHOT_LIMIT)
                    .toArray()
                    .then((_result: SearchNode[]) => {
                        // Return sorted results
                        const entries = _.uniqBy(_result, "id");
                        return sortBy(entries, sort);
                    });

                return result;
            } else {
                return undefined;
            }
        }, [search_term, sort, progress]  // NOTE: specify vars that affect query:
    );

    // Description: Record the new grants in the object and remove from pending array (fetching_grants_ref)
    useEffect(() => {
        if (!!permissions && !!node_identifiers) {
            // NOTE: If defaultCollection check all children links. If no need for fetching then handleSuccess and allow the hook to search DB
            (!!root_info && root_info.collection_id === node_identifiers.collection_id && root_info.id === node_identifiers.id) ?
                searchCollectionLinks(node_identifiers, permissions) :
                preflightSnapshotCheck(node_identifiers, permissions, collection_info.disjointed_acl_node);
        }

    }, [permissions, node_identifiers]);

    // ----------------------------------------------------------------------------------------------------
    // Description: Handle Fetching data and getting permissions and grants
    // ----------------------------------------------------------------------------------------------------
    // Validating search sequences and updating data
    // ---------------------------------------------
    // Description: Get List of links to do a preflight check individually if Root has not changed sequence
    async function searchCollectionLinks(_node_identifiers: NodeIdentifier, _permissions: PermissionSetType, sequence: number = 0) {
        const drive_request = await CollectionFilesyncAPI.fetchActiveLinks(current_account, _permissions, _node_identifiers.collection_id, sequence);
        async function callback(message: FSMessage) {
            const link_view = message.getLinkView();
            const links = link_view?.getLinksList();
            if (message.getStatus() === FSStatus.OK) {
                preflightSnapshotCheck(_node_identifiers, _permissions); // NOTE: Preflightcheck root first
                if (!!links && links.length > 0) {
                    const _link_arr = await Promise.all(_.map(links, async (link: FSMessage.LinkView.Entry) => link.getLinkedCollectionId_asB64()));
                    handleAddToQueue(_link_arr);
                    if (!!link_view) {
                        const next_seq = link_view.getNextSeq();
                        (link_view.getHasMore() && !!next_seq) && searchCollectionLinks(_node_identifiers, _permissions, next_seq);
                    }
                }
            } else {
                handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_checking_snapshot_sequence }, message);
            }
        }
        handleSendRequest(drive_request, callback, DriveErrorMessages.error_checking_snapshot_sequence);
    }

    // Description: Run a quick check on the parent collection to see if its already stored in db
    async function preflightSnapshotCheck(_node_identifiers: NodeIdentifier, _permissions: PermissionSetType, is_link: boolean = false) {
        setProgress(SearchProgressValues.preflight);
        // NOTE: Don't pass the id for searching LINK types (b/c need maintainer_id not the link_id - it will throw an error)
        const id = is_link ? "" : _node_identifiers.id;
        const collection_id = _node_identifiers.collection_id;
        const drive_request = await CollectionFilesyncAPI.fetchSnapshot(current_account, _permissions, collection_id, id, 0, 0, true, false, 1);
        async function callback(message: FSMessage) {
            const response_snapshot = message.getSnapshot();
            if (message.getStatus() === FSStatus.OK && !!response_snapshot) {
                const sequence = response_snapshot?.getSeq() || 0;
                // NOTE: Check if this collection has a new seq number
                const search_row = await search_client.getSearchByIdentifiers(collection_id);
                if (sequence !== search_row?.sequence) {
                    // NOTE: Delete old data and refetch if search row exists
                    !!search_row && await search_client.deleteItemsByCollectionId(_node_identifiers.collection_id);
                    fetchSnapshot(_node_identifiers, _permissions, is_link);
                } else { // NOTE: if no changes then processed link counts and children
                    handleSuccess();
                }
            } else {
                handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_checking_snapshot_sequence }, message);
            }
        }
        handleSendRequest(drive_request, callback, DriveErrorMessages.error_checking_snapshot_sequence);
    }

    // ---------------------------------------------
    // Fetch Snapshot functions
    // ---------------------------------------------
    // Description: Bulk update permissions
    async function fetchSnapshot(
        _node_identifiers: NodeIdentifier,
        _permissions: PermissionSetType,
        is_link: boolean = false,
        cursor_seq: number = 0,
        cursor_subseq: number = 0,
    ) {
        link_counts_ref.current++;
        // NOTE: Don't pass the id for searching LINK types (b/c need maintainer_id not the link_id - it will throw an error)
        const id = is_link ? "" : _node_identifiers.id;
        const collection_id = _node_identifiers.collection_id;
        const drive_request = await CollectionFilesyncAPI.fetchSnapshot(current_account, _permissions, collection_id, id, cursor_seq, cursor_subseq, true, true, SNAPSHOT_LIMIT);
        async function callback(message: FSMessage) {
            if (message.getStatus() === FSStatus.OK) {
                const response_snapshot = message.getSnapshot();
                if (!!response_snapshot) {
                    // NOTE: Add search to DB
                    search_client.addItemToSearchIndex(collection_id, id, response_snapshot);
                    // NOTE: Index directly
                    handleSnapshotSuccess(response_snapshot, _node_identifiers, _permissions);
                } else {
                    handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_snapshot }, message);
                }
            } else {
                handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_snapshot }, message);
            }
        }
        handleSendRequest(drive_request, callback, DriveErrorMessages.error_updating_node_permissions);
    }

    // Description: Handle success for fetching snapshot
    function handleSnapshotSuccess(_snapshot: FSMessage.Snapshot, _node_identifiers: NodeIdentifier, _permissions: PermissionSetType) {
        // NOTE: Add has more to queue
        !!_snapshot.hasHasMore() && handleAddToQueue([_node_identifiers.collection_id]);
        // NOTE: Process data
        const snapshot_result = { ...{ snapshot: _snapshot, permissions: _permissions }, ..._node_identifiers };
        handleInitializeSnapshotData(snapshot_result);
        error && setError(false);
    }

    // Description: Use new Snapshot to initialize a db and fetch all collection data
    async function handleInitializeSnapshotData(results: SnapshotResult) {
        const search_snapshot = new SearchSnapshot(results);
        const id = results.id;
        const current_data = {
            collection_id: results.collection_id,
            id,
            permissions: results.permissions,
            collection_protocol_version: search_snapshot.collection_protocol_version,
            search_snapshot,
        };
        // NOTE: Get all grants needed for decrypting the names for this link branch
        getPendingMaintainerGrants(search_snapshot, results.permissions);

        // NOTE: Initiate Data building and storing - decrypt every time snapshot is fetched
        handleBuildDirectoryEntries(current_data);
    }

    // Description: Get ACL Nodes Grants from maintainer child directories
    function getPendingMaintainerGrants(search_snapshot: SearchSnapshot, _permissions: PermissionSetType): string[] {
        const collection_id = search_snapshot.collection_id;
        const directories = Array.from(search_snapshot.directory_map.keys());
        const pending_grants: string[] = [];
        _.forEach(directories, (directory_id: string) => {
            const directory = search_snapshot.directory_map.get(directory_id);
            const maintainer_id = directory?.getMaintainerId_asB64();
            const id = directory?.getId_asB64();
            const node_grants = directory?.getAclList();

            if (!!directory && !!id && !!maintainer_id && id === maintainer_id) {
                const current_grants = !!node_grants ? GrantSet.getCurrentUsersGrantSets(collection_id, id, node_grants, current_account.user_id) : null;
                const latest_grants = !!current_grants ? GrantSet.latestGrants(current_grants.grants) : null;
                // NOTE: Set to -1 for safe condition:
                const latest_role_key_version = !!latest_grants ? latest_grants[0].role_key_version : -1;
                // NOTE: If we have the grants no need to fetch 
                !!current_grants && setMaintainerGrantsRef(current_grants?.id, current_grants);
                // NOTE: set to pending fetch if grants need to be fetched to decrypt these
                if (latest_role_key_version > 0 || !current_grants) {
                    pending_grants.push(id);
                    dir_total_ref.current++; // count these for tracking
                    getGrants(id, collection_id, default_permissions, default_grants, { collection_id, id, directory, permissions: _permissions });
                };
            }
        });
        return pending_grants;
    }

    // ----------------------------------------------------------------------------------------------------
    // Description: Handle IndexDB Init and updates, build data
    // ----------------------------------------------------------------------------------------------------
    // Description: Iterate trough directories and decrypt entry names and populate IndexDB
    async function handleBuildDirectoryEntries(collection_data: CollectionsData) {
        if (!!collection_data.search_snapshot && !!collection_data.permissions) {
            const collection_id = collection_data.collection_id;
            const root_maintainer_id = collection_data.search_snapshot.maintainer_id;
            const _permissions = collection_data.permissions;
            const directory_map = collection_data.search_snapshot.directory_map;
            const directory_array = Array.from(directory_map.keys());
            const root_grants = !!root_maintainer_id ? maintainer_grants_ref.current.get(root_maintainer_id) : undefined;

            // NOTE: Have all the information here => populate IndexedDB - Do not call if the search was destroyed
            if (directory_array.length > 0 && !search_client.abort_token) {
                await Promise.all(_.map(directory_array, async (directory_id: string) => {
                    const fs_directory = directory_map.get(directory_id);
                    // NOTE: Do not call if the search was destroyed
                    if (!!fs_directory && !!_permissions && !search_client.abort_token) {
                        dir_total_ref.current++;
                        return await handleInitDirDBNode(collection_id, fs_directory, _permissions, root_maintainer_id, root_grants);
                    }
                }));
            } else {
                handleProgress();
            }
        } else {
            handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_decrypting_entries });
        }
    }

    // Description: Handle the individual FSMessage.Dir and entries add them to IndexedDB
    async function handleInitDirDBNode(
        collection_id: string,
        fs_directory: FSMessage.Dir,
        _permissions: PermissionSetType,
        root_maintainer_id?: string,
        root_grants?: GrantSetType
    ): Promise<SearchNodeBase[] | undefined> {
        const node_maintainer_id = fs_directory.getMaintainerId_asB64();
        const key_version = fs_directory.getWrappedDirKey()?.getKeyVersion();
        let _grants = root_grants;
        // NOTE: Validate all grants have been fetched
        if (!!node_maintainer_id && root_maintainer_id !== node_maintainer_id) {
            _grants = maintainer_grants_ref.current.get(node_maintainer_id);
        }
        const grant = !!_grants ? GrantSet.getGrantbyRole(_grants, key_version) : undefined;
        let nodes = await search_client.initDBNodes(collection_id, fs_directory, current_account, _permissions, grant);
        // NOTE: handle and add disjointed ACL Nodes - otherwise not added
        if (fs_directory.hasMaintainerId() && fs_directory.getId_asB64() === fs_directory.getMaintainerId_asB64() && !fs_directory.getIsRoot()) {
            const acl_nodes = await search_client.handleDisjointedACLNodes(collection_id, fs_directory, current_account, _grants);
            (!!acl_nodes) &&
                (nodes = !!nodes && nodes?.length > 0 ? nodes.concat([acl_nodes]) : [acl_nodes]);
        }
        // NOTE: Adjust Progress after decrypting nodes in a directory
        dir_counts_ref.current++;
        handleProgress();
        return nodes;
    }

    // Description: Set Dynamic progress from a min and a max range using count variables for links or directory
    function handleProgress() {
        if (!search_client.abort_token) {
            const min = SearchProgressValues.setPendingLinks;
            const max = SearchProgressValues.checkHasMore;
            const counts = dir_counts_ref.current + link_counts_ref.current;
            const total = dir_total_ref.current + link_total_ref.current;
            (dir_counts_ref.current >= dir_total_ref.current) && handleSuccess();
            let _dynamic_progress = max;
            if (counts < total) {
                const calc_dynamic_progress = min + Math.round(counts / total * (max - min));
                _dynamic_progress = dynamic_progress_ref.current > calc_dynamic_progress ? dynamic_progress_ref.current : calc_dynamic_progress;
            }
            dynamic_progress_ref.current = _dynamic_progress;
            setProgress(_dynamic_progress);
        }
    }

    // Description: Add a set of collection ids to the fetch queue
    function handleAddToQueue(collection_ids: string[]) {
        link_queue.current = _.uniq(link_queue.current.slice().concat(collection_ids.slice()));
        link_total_ref.current = link_queue.current.length;
        link_counts_ref.current = 0;
    }

    // Description: Pause Search but add remaining links to DB for Manual search more
    async function handlePauseLinkQueue() {
        const result = await Promise.all(_.map(link_queue.current, async (_collection_id: string) => {
            const row = await search_client.getSearchByIdentifiers(_collection_id);
            !row && await search_client.addItemToSearchIndex(_collection_id, "");
            link_queue.current.shift();
            return _collection_id;
        }));
        return result;
    }

    // Description: Wrap up the fetch and build
    async function handleSuccess() {
        const node_rows_count = await search_client.getSearchNodeTotalRowCounts();
        if (node_rows_count < INDEXED_DB_NODE_ROW_MAX && link_queue.current.length > 0) {
            const _next_collection_id = link_queue.current.shift();
            const _permissions = _.find(default_permissions, (set: PermissionSetType) => _next_collection_id === set.collection_id.B64());
            if (!!_next_collection_id && !!_permissions) {
                const search_row = await search_client.getSearchByIdentifiers(_next_collection_id);
                // NOTE: Do not call if the search was destroyed
                if (!search_client.abort_token) {
                    (!!search_row?.has_more || !search_row) ?
                        fetchSnapshot({ collection_id: _next_collection_id, id: "" }, _permissions, true, search_row?.cursor_sequence || 0, search_row?.cursor_sub_sequence || 0) :
                        // NOTE: If this is a link with the search_row.has_more set to false, check seq
                        preflightSnapshotCheck({ collection_id: _next_collection_id, id: "" }, _permissions, true);
                }
            } else {
                handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_permissions });
            }
        } else {
            // NOTE: If link_queue.current.length > 0 then add to the DB for Search more button
            (link_queue.current.length > 0) && await handlePauseLinkQueue();
            reset(SearchProgressValues.complete);
        }
    }

    // ----------------------------------------------------------------------------------------------------
    // Description: Fetch Grants in current hook
    // ----------------------------------------------------------------------------------------------------
    // Description: Get ACL List from a maintainer directory using FETCH_DIR with Limit of 1 
    // NOTE: there is no direct endpoint for getAclList
    async function getGrants(id: string, collection_id: string, default_permissions: PermissionSetType[], default_grants: GrantSetType[], pending_item: PendingData) {
        const permissions = _.find(default_permissions, (set: PermissionSetType) => collection_id === set.collection_id.B64());
        const _request = await CollectionFilesyncAPI.getDirectoryInformationRequest(collection_id, id, false);
        const drive_request = !!permissions ? await DriveRequest.initializeRequest(_request, current_account, permissions) : null;
        // NOTE: Pass down this reference otherwise it will be undefined
        async function callback(message: FSMessage) {
            if (message.getStatus() === FSStatus.OK) {
                const maintainer_dir = message.getDir();
                const acl_list = maintainer_dir?.getAclList();
                if (!!acl_list && !!permissions && !!default_grants) {
                    const current_grants = GrantSet.getCurrentUsersGrantSets(collection_id, id, acl_list, current_account.user_id);
                    const grant_set = !!current_grants ? GrantSet.init(current_grants) : null;
                    const grant = !!grant_set ? grant_set.grant(Node.ACLRole.READER) : null;
                    if (!!grant && !!pending_item) {
                        (grant.role_key_version > 0 && !!grant_set) ?
                            getAclKeyHistory(id, collection_id, default_grants, grant_set, permissions, pending_item) :
                            handleFetchGrantsSuccess(pending_item, current_grants);
                    } else {
                        handleFetchGrantsError(message, {
                            stack_message: DriveErrorMessages.error_fetching_acl_history,
                            stack_error: "grants are undefined or null, or lost pending item data"
                        });
                    }
                } else {
                    handleFetchGrantsError(message, {
                        stack_message: DriveErrorMessages.error_fetching_acl_history,
                        stack_error: "acl_list, permissions or default_grants are undefined or null"
                    });
                }
            } else {
                handleFetchGrantsError(message);
            }
        };

        handleSendRequest(drive_request, callback, DriveErrorMessages.error_fetching_acl_history);
    }

    // Description: Get ACL Key History if needed
    async function getAclKeyHistory(
        id: string,
        collection_id: string,
        default_grants: GrantSetType[],
        grant_set: GrantSet,
        permissions: PermissionSetType,
        pending_item: PendingData) {
        const role = Node.ACLRole.READER;
        const _request = await CollectionFilesyncAPI.getAclKeyHistoryRequest(collection_id, id, role);
        const drive_request = !!permissions ? await DriveRequest.initializeRequest(_request, current_account, permissions) : null;
        async function callback(message: FSMessage) {
            if (message.getStatus() === FSStatus.OK) {
                const key_history = message.getKeyHistoryList().sort((a: FSMessage.KeyHistoryEntry, b: FSMessage.KeyHistoryEntry) =>
                    ((a.getVersion() || 0) - (b.getVersion() || 0)));
                try {
                    const current_grants = await GrantSet.unwrapAclKeyHistory(collection_id, id, key_history, role, grant_set, current_account);
                    handleFetchGrantsSuccess(pending_item, current_grants);
                } catch (stack_error: unknown) {  // NOTE: Catches errors in unwrapping and decrypting keys
                    handleFetchGrantsError(message, stack_error);
                }
            } else {
                handleFetchGrantsError(message);
            }
        };

        handleSendRequest(drive_request, callback, DriveErrorMessages.error_fetching_acl_history);
    }

    // Description: On success for each grant bypass state changes (misses some)
    function handleFetchGrantsSuccess(pending_item: PendingData, current_grants?: GrantSetType) {
        if (!!current_grants) {
            const id = current_grants.id;
            setMaintainerGrantsRef(id, current_grants);
            handleInitDirDBNode(pending_item.collection_id, pending_item.directory, pending_item.permissions);
        } else {
            dir_counts_ref.current++; // NOTE: increase  dir_counts_ref  to finish it
            handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_acl_history });
        }
    }

    // Description: Handle ALL get Grant errors
    function handleFetchGrantsError(message: FSMessage, stack_error?: unknown) {
        dir_counts_ref.current++; // NOTE: increase  dir_counts_ref  to finish it
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_acl_history, stack_error }, message);
    }
    // ----------------------------------------------------------------------------------------------------
    // Description: Callbacks and general calls
    // ----------------------------------------------------------------------------------------------------
    // Description: Send request handles error when the request object is null
    function handleSendRequest<T>(request: DriveRequest | null, callback: DriveCallbackAsyncFunction<T>, stack_error: string = DriveErrorMessages.error_sending_request) {
        if (!!request) {
            request.setCallback(callback);
            sendRequest(request);
        } else {
            handlePageErrorMessage(DriveErrorMessages.default, { stack_error, stack_message: DriveErrorMessages.error_sending_request });
        }
    }

    // Description: Handle error message and send error to store
    function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, fsmessage?: FSMessage | null,
        display_type: MessageDisplayType = MessageHandlerDisplayType.logger) {
        setError(true);
        const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
        const stack = new ErrorStackItem("[useDriveSearch Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
        dispatch(uiActions.handleRequestErrors(new Message(message, display_type), stack));
    }

    // Description: Reset all state for internal and external clean up
    function reset(_progress: number = SearchProgressValues.initial) {
        link_total_ref.current = 0;
        link_counts_ref.current = 0;
        dir_total_ref.current = 0;
        dir_counts_ref.current = 0;
        dynamic_progress_ref.current = 0;
        maintainer_grants_ref.current = new Map<string, GrantSetType>;
        setNodeIdentifiers(undefined);
        destroyPermissions();
        setProgress(_progress);
        setError(false);
    }

    // Description: Reset Search Hook from Component
    const resetSearch = useCallback(async () => {
        reset();
        // NOTE: Destroy search on search close if data page is not 0
        const data_page = await search_client.getPage();
        !!data_page && search_client.destroySearch();
    }, []);

    // Description: Initialize or update the search db
    const initSearch = useCallback(async (collection_id: string, id: string) => {
        // NOTE: Initialize pagination and clear previous search if any
        const _node_identifiers = { collection_id, id };
        const client_id = await search_client.getPageIdentifiers(collection_id);
        search_client.initCurrentNode(_node_identifiers);
        // NOTE: Reset pagination if its a new current directory
        if (!_.isEqual(_node_identifiers, client_id)) {
            search_client.destroySearch(false);
            search_client.addPage();
        }

        getPermissions(collection_id, default_permissions);
        setNodeIdentifiers(_node_identifiers);
        setProgress(SearchProgressValues.loading);
    }, []);

    // Description: Fetch more items after a first batch was populated
    const fetchMore = useCallback(async (search_base: SearchBase[]) => {
        // NOTE: Remove data from Node table to refill and keep search table to track
        search_client.destroyNodeTable();
        // NOTE:  Init new Search:
        search_client.incrementPage();
        setProgress(SearchProgressValues.loading);
        const _search_base = _.orderBy(search_base, ["cursor_sequence"], ["desc"]);

        // NOTE: preflight first search_base and add the remainder to link_current_queue
        const current_search = _search_base.shift();

        // NOTE: Add search_base returned with has_more === 1 to the queue
        handleAddToQueue(_.map(_search_base, _search => _search.collection_id));
        const _permissions = _.find(default_permissions, (set: PermissionSetType) => current_search?.collection_id === set.collection_id.B64());
        (!!current_search && !!_permissions) ?
            fetchSnapshot({ collection_id: current_search.collection_id, id: current_search.id }, _permissions, true, current_search.cursor_sequence, current_search.cursor_sub_sequence) :
            handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_permissions });
    }, []);

    return {
        initSearch,
        fetchMore,
        setProgress,
        resetSearch,
        results,
        progress,
        error
    };
}
