import React from "react";
import { Alert } from "react-bootstrap";

function MessagingComponent() {

  // Description: Messaging/alerts
  const MessagingIntro = () => {
    return <>
      <h4>ALERTS</h4>
      <div>
        <ul className="list-unstyled">
          <li>
            <a target="_blank" rel="noreferrer" href="https://react-bootstrap.github.io/components/alerts/">Bootstrap Alerts</a>
          </li>
          <li>
            <a target="_blank" rel="noreferrer" href="https://github.com/PreVeil/core/wiki/UI-Guide:-Messages">PreVeil Alerts</a>
          </li>
        </ul>
        <hr />
        {
          [
            "primary",
            "secondary",
            "success",
            "danger",
            "warning",
            "info",
            "light",
            "dark",
          ].map((variant, idx) => (
            <Alert key={idx} variant={variant} dismissible={true}>
              This is a {variant} alert—check it out!
            </Alert>
          ))
        }
      </div>
    </>;
  };
  return <MessagingIntro />;
}

export default React.memo(MessagingComponent);
