import {
  JSONAttachment, VersionedEmailBlock, WrappedKey, Thread, ThreadMessage, CollectionThreadsPage, CollectionThreadItem, CryptoThreadItem,
  CryptoThreadsPage, ThreadsPage, JSONEmail, CurrentThread
} from "@preveil-api";
import { Account, WebThread, MailThread, EmailUser, Helpers, Attachment, PrivateMetadataLatest, dayjs, stripHtml } from "src/common";
import { SymmKey } from "src/common/keys";
import { decode } from "html-entities";
import _ from "lodash";

// Description: Prepare email data
export async function prepareMailData(
  symm_key: SymmKey,
  sender: Account,
  tos: EmailUser[],
  ccs: EmailUser[],
  bccs: EmailUser[],
  body: Uint8Array,
  snippet: string,
  attachments: Attachment[]
): Promise<{
  sender_wrapped_key: string,
  _body: {
    snippet: string,
    block_ids: string[],
    size: number
  },
  _attachments: JSONAttachment[],
  _tos: WrappedKey[],
  _ccs: WrappedKey[],
  _bccs: WrappedKey[],
  blocks: VersionedEmailBlock[]
}> {
  const symm_key_serialized = symm_key.serialize();
  const sender_wrapped_key = Helpers.b64Encode(await sender.public_user_key.public_key.seal(symm_key_serialized));
  const wrapKeyFor = (email_user: EmailUser) => {
    const external_email = email_user.external_email || undefined;
    return !!email_user.public_user_key ?
      email_user
        .public_user_key.public_key
        .seal(symm_key_serialized)
        .then(wk => ({
          user_id: email_user.user_id,
          external_email,
          key_version: email_user.key_version,
          wrapped_key: Helpers.b64Encode(wk)
        })) : ({ user_id: email_user.user_id, external_email, wrapped_key: null, key_version: null });
  };
  const encryptChunk = (chunk: any) =>
    symm_key.encrypt(chunk)
      .then(b => Promise.all([
        Helpers.sha256Checksum(b), Promise.resolve(b)
      ]))
      .then(([digest, encrypted_block]) => ({
        id: Helpers.hexEncode(digest),
        data: encrypted_block,
        wrapped_key: sender_wrapped_key,
        key_version: sender.public_user_key.key_version
      })
      );

  const chunkAttachment = (a: any) =>
    a.getContentBuffer()
      .then((content: any) =>
        Promise.all([
          Promise.all(Helpers.chunkData(content).map(encryptChunk)),
          a,
          content.length
        ]));

  const [body_blocks, attachments_blocks, _tos, _ccs, _bccs] = await Promise.all([
    Promise.all(Helpers.chunkData(body).map(encryptChunk)),
    Promise.all(attachments.map(chunkAttachment)),
    Promise.all(tos.map(wrapKeyFor)),
    Promise.all(ccs.map(wrapKeyFor)),
    Promise.all(bccs.map(wrapKeyFor))
  ]);

  return ({
    sender_wrapped_key,
    _body: {
      snippet,
      block_ids: body_blocks.map(b => b.id),
      size: body.length
    },
    _attachments: attachments_blocks.map(
      ([blocks, att, size]) =>
      ({
        block_ids: blocks.map((b: any) => b.id),
        size,
        name: att.filename,
        metadata: {
          content_type: att.metadata.content_type,
          content_id: att.metadata.content_id,
          content_disposition: att.metadata.content_disposition
        },
        _uuid: att.uuid // just to be used in tests
      })
    ),
    _tos, _ccs, _bccs,
    blocks: body_blocks.concat(...attachments_blocks.map(([blocks, ...rest]) => blocks))
  });
}

// Description: Prepare email Metadata 
export async function prepareMailMetadata(metadata: PrivateMetadataLatest, symm_key: SymmKey, sender: Account):
  Promise<{ private_metadata: string; signature: string; }> {
  const raw_metadata = Helpers.utf8Encode(JSON.stringify(metadata));
  const [private_metadata, signature] = await Promise.all([
    symm_key.encrypt(raw_metadata)
      .then(Helpers.b64Encode),
    Helpers.sha256Checksum(raw_metadata)
      .then(d => sender.user_key.signing_key.sign(d))
      .then(Helpers.b64Encode)
  ]);
  return ({ private_metadata, signature });
}

// Description: Legacy export class FileHelper 
// obtained from http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
export function Fileb64ToBlob(b64data: string, content_type: string): Blob {
  const slice_size = 512;
  const byte_characters = atob(b64data);
  const byte_arrays = [];

  for (let offset = 0; offset < byte_characters.length; offset += slice_size) {
    const slice = byte_characters.slice(offset, offset + slice_size);

    const byte_numbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byte_numbers[i] = slice.charCodeAt(i);
    }

    const byte_array = new Uint8Array(byte_numbers);
    byte_arrays.push(byte_array);
  }
  return new Blob(byte_arrays, { type: content_type });
};

// ----------------------------------------------------------------
// MAIL THREAD HELPERS
// ----------------------------------------------------------------
// Description: Get snippet from email body text
export function snippetFromBody(text_body: string): string {
  let snippet = text_body.slice(0, 1024);
  if (text_body.length > snippet.length) {
    snippet += "...";
  }
  return snippet;
}

// Description: Short Time Stamp for Mail (Search results and thread classes)
export function formatShortTimestamp(date: string): string {
  const local_date = dayjs.utc(date).local();
  return local_date.isToday() ? local_date.format("h:mm A") : local_date.format("MM/DD/YYYY");
}

// Description: Long Time Stamp for Mail Message (localizedFormat)
export function formatLongTimestamp(date: string): string {
  const local_date = dayjs.utc(date).local();
  return local_date.format("llll");
}

// Description: Long Time Stamp for Mail Message  including calendar qualifiers
export function formatCalendarTimestamp(date: string): string {
  return dayjs(date).calendar(null, {
    sameDay: "[Today at] h:mm A",
    lastDay: "[Yesterday at] h:mm A",
    lastWeek: "[Last] dddd [at] h:mm A",
    sameElse: "MM/DD/YYYY [at] h:mm A"
  });
}

// Description: Scrubs hml and html entities, dates from snippet body
export function formatSnippet(snippet: string, previous: ThreadMessage | null): string {
  // remove html and html entities
  let updated_snippet: string = stripHtml(snippet);
  updated_snippet = decode(updated_snippet);
  // take out line breaks
  updated_snippet = updated_snippet.replace(/(\n|\r)/g, " ").replace(/((>){1,} )/g, "");
  if (previous) {
    const regex_previous_content_apple: RegExp = new RegExp(`On ${dayjs(previous.date).local().format("MMM D, YYYY")}.*`, "gm");
    const regex_previous_content_outlook: RegExp = new RegExp(`From: ${previous.sender.name}.*`, "gm");
    const regex_previous_content_ios: RegExp = new RegExp(`On ${dayjs(previous.date).format("ddd, D MMM YYYY")}.*`, "gm");
    updated_snippet =
      updated_snippet
        .replace(regex_previous_content_apple, "")
        .replace(regex_previous_content_outlook, "")
        .replace(regex_previous_content_ios, "");
  }
  return !!updated_snippet ? updated_snippet : "(no body)";
}

// Description: for fetchEmailsBody, inject inline attachments
export function injectInlineAttachmentsIntoDOM(html: string, inline_attachments: Attachment[]) {
  let parsed_html = html;
  inline_attachments.forEach(inline_attachment => {
    const content_id = !!inline_attachment.content_id ? inline_attachment.content_id.slice(1, -1) : "";
    const regex_content_id: RegExp = new RegExp(`src=("|')cid:${content_id}("|')`, "g");
    const inline_attachment_blob = !!inline_attachment.blob ? URL.createObjectURL(inline_attachment.blob) : "";
    parsed_html = parsed_html.replace(regex_content_id, `src="${inline_attachment_blob}" data-att-id="${content_id}"`);
  });
  return parsed_html;
}

// Description: Get array of id's by Max number of blocks allowed to be retrieved from the server 
//  in sets of max 'blocks_per_request: number' block[]
export function getIDChunks(block_ids: string[], blocks_per_request: number): string[][] {
  return [...Array(Math.ceil(block_ids.length / blocks_per_request)).keys()]
    .map(i => block_ids.slice(i * blocks_per_request, i * blocks_per_request + blocks_per_request));
}

// Description: Structure the Form Data for Crypto sendMail call
export function getMailFormData(message_data: any, attachments: Attachment[]) {
  const form_data: FormData = new FormData();
  form_data.append("json", JSON.stringify(message_data));
  for (let i = 0; i < attachments.length; i++) {
    const blob = attachments[i].blob;
    !!blob && form_data.append("attachments", blob, attachments[i].uuid);
  }
  return form_data;
}

// Threads page parsing helpers
// Description: Parse CS response to ThreadsPage
export async function parseGetWebThreads(data: CollectionThreadsPage, current_account: Account, mailbox_id: string): Promise<ThreadsPage> {
  const _threads = await Promise.all(_.map(data.threads,
    async (thread: CollectionThreadItem) => {
      const webThread = await WebThread.initWeb(thread, current_account, mailbox_id);
      return webThread?.thread;
    }));

  const threads: Thread[] = [];  // NOTE: Remove invalid threads 
  _.map(_threads, (thread: CryptoThreadItem | undefined) => {
    !!thread && threads.push(new MailThread(thread).thread);
  });
  // NOTE: The issue with expired emails still exists: 
  // https://preveil.atlassian.net/browse/BACK-917 and https://preveil.atlassian.net/browse/BACK-1012
  return { mailbox_id, threads, total: data.total };
}

// Description: Parse Crypto response to ThreadsPage
export function parseGetAppThreads(data: { [key: string]: CryptoThreadsPage }, mailbox_id: string): ThreadsPage {
  const _mailbox = data[mailbox_id];
  const threads = _.map(_mailbox.threads_fetched, (thread: CryptoThreadItem) => {
    return new MailThread(thread).thread;
  });
  return {
    mailbox_id,
    threads: !!threads ? threads : [],
    total: !!mailbox_id && !!_mailbox ? _mailbox.total_number_threads : 0
  };
}

// Description: Parse CS response to CurrentThread for web
// NOTE: returns messages as decrypted ThreadMessage[] ;
export async function parseGetWebThread(thread: CollectionThreadItem, current_account: Account, mailbox_id: string): Promise<CurrentThread> {
  const webThread = await WebThread.initWeb(thread, current_account, mailbox_id);
  const _thread = webThread?.thread;
  const messages: ThreadMessage[] = !!_thread ? _thread.messages : [];

  return {
    thread_id: thread.thread_id,
    messages,
    unique_ids: _.map(messages, (message: ThreadMessage) => message.unique_id),
    message_count: messages.length
  };

}
// Description: Parse CS response to CurrentThread  for app
// NOTE: returns messages as JSONEmail[]; (encrtypted)
export function parseGetAppThread(thread: CollectionThreadItem): CurrentThread {
  const encrypted_messages = WebThread.isValidMessage(thread.messages);
  return {
    thread_id: thread.thread_id,
    messages: [],
    encrypted_messages,
    unique_ids: _.map(encrypted_messages, (message: JSONEmail) => message.id),
    message_count: encrypted_messages.length
  };
}
