import React from "react";
import { AdminUserEntity, DeviceBase } from "@preveil-api";
import { Col, Row } from "react-bootstrap";
import { EmptyView, Loading } from "src/components";
import _ from "lodash";
import { DeviceRow } from ".";
import { useAppSelector } from "src/common";

type AllProps = {
  org_user: AdminUserEntity;
  devices: DeviceBase[] | [];
  refresh: Function;
  loading: boolean;
}

function DevicesListPanel(props: AllProps) {
  const { devices, org_user, refresh, loading } = props;
  const current_account = useAppSelector((state) => state.account.current_account);

  // Description: Render the device rows
  function RenderList() {
    return <>{!!current_account &&
      <div className="list-body">
        {_.map(devices, (device: DeviceBase, i: number) => {
          return <DeviceRow
              key={i}
              device={device}
              current_account={current_account}
              org_user={org_user}
              refresh={refresh}
            ></DeviceRow>;
        })}
      </div>}</>;
  }

  return <div className={`settings-list ${loading ? " isloading" : ""}`}>
    {!!devices && devices.length > 0 ? 
      <>
        <Row className="header-row">
          <Col sm={4} md={6}>Name</Col>
          <Col sm={3} md={4}>Status</Col>
        </Row>
        {RenderList()}
      </> : !loading && <EmptyView message="This user has no devices" />}
      {loading && <Loading className="position-absolute"/>}
  </div>;
}

export default React.memo(DevicesListPanel);