import React, { FocusEvent } from "react";
import { Row, Col } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { Checkbox } from "src/components";
import { AdminUIActionTypes, CheckboxStatesTypes, CheckboxStates } from "src/common";
import { EmptyViewCard, EmailGroupRow } from "..";
import _ from "lodash";

type AllProps = {
  groups_aliases: string[];
  selected: string[];
  handleAction: ActionHandlerFunction;
};

function EmailGroupListViewComponent(props: AllProps) {
  const { groups_aliases, handleAction, selected } = props;

  // Description: Get the selected all checkbox state
  function getSelectedState(): CheckboxStatesTypes {
    const ck_state = !selected.length ? CheckboxStates.empty : (selected.length < groups_aliases.length ? CheckboxStates.indeterminate : CheckboxStates.checked);
    return ck_state;
  }

  // Description: Render the rows
  function RenderList() {
    return (
      <div className="row-container">
        {_.map(groups_aliases, (alias: string, i: number) => {
          return <EmailGroupRow key={i} group_name={alias} selected={selected.includes(alias)} handleAction={handleAction}></EmailGroupRow>;
        })}
      </div>
    );
  }

  return <div className="cover-content">
    {groups_aliases.length > 0 ?
      <>
        <header>
          <Row className="header-row">
            <Col xs="auto">
              <Checkbox
                className="sr-only"
                onChange={(e: FocusEvent<HTMLInputElement>) => handleAction({ actionType: AdminUIActionTypes.Select, params: { selected: e.target.checked } })}
                value="all"
                selected={getSelectedState()}
                label="Select All"
              /></Col>
            <Col className="ps-0 fw-bold">Name</Col>
          </Row>
        </header>
        {RenderList()}
      </> : <div className="admin-panel-center"><EmptyViewCard handleAction={handleAction}></EmptyViewCard></div>}
  </div>;
}

export default React.memo(EmailGroupListViewComponent);
