import * as React from "react";
import { Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { RecoverAccountUIActionTypes } from "src/common";
import { Icon } from "src/components";

type AllProps = {
  handleAction: ActionHandlerFunction;
}

function ErrorPanelComponent(props: AllProps) {
  const { handleAction } = props;
  return <>
    <h3 className="mb-4" >
      <Icon className="ficon-alert-triangle error-icon text-orange" /> Account recovery failed, please try again.</h3>
    <div className="btn-panel text-end">
      <Button onClick={() => handleAction({ actionType: RecoverAccountUIActionTypes.SubmitPassword })}>Retry</Button>
      <Button variant="outline-primary" onClick={() => handleAction({ actionType: RecoverAccountUIActionTypes.ResetForms })}>Reset</Button>
    </div>
  </>;
}
export default React.memo(ErrorPanelComponent);
