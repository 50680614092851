import { Middleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";
import rootReducer from "./root/rootReducer";
import { rootSaga } from "./root/rootSaga";
import { AppConfiguration, collectionApi, cryptoApi, filesyncApi, filesyncSocketApi, keystorageApi } from "src/common";
import { websocketMiddleware } from "./websocket/websocketMiddleware";

// Custom redux logger 
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
const loggerMiddleware: Middleware = createLogger({
  collapsed: true,
  predicate: (getState, action) => (action.type.includes("drive/") || (action.type.includes("ui/")))
});

const sagaMiddleware = createSagaMiddleware();
const wsMiddleware = websocketMiddleware() as Middleware;

// Build ALL Middleware:
const middleware: Middleware[] = [
  wsMiddleware,
  sagaMiddleware as Middleware,
  collectionApi.middleware,
  cryptoApi.middleware,
  filesyncApi.middleware,
  filesyncSocketApi.middleware,
  keystorageApi.middleware
];

// NOTE: ADD Logger if turned on in the .env file
AppConfiguration.turnLoggerOn() && middleware.push(loggerMiddleware);

export const store = configureStore({
  reducer: rootReducer(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middleware),
});

setupListeners(store.dispatch);

// Run the root saga
sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
