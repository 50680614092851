import React, { } from "react";
import { ProgressBar, Button, Toast } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { DriveUIActionTypes, SearchProgressValues } from "src/common";

type AllProps = {
  search_term?: string;
  progress: number;
  counts: number;
  error: boolean;
  handleAction: ActionHandlerFunction;
}

function SearchFooterComponent(props: AllProps) {
  const { counts, progress, search_term, error, handleAction } = props;

  return <div className="search-footer">
    <div>
      <div className="progress-box">
        <b>{progress}%</b>
        <ProgressBar now={progress} animated />
      </div>
      {
        error ?
          <Toast bg="danger" show={true} >
            <Toast.Body>An error occurred during the search. Cancel out of this search and try again.</Toast.Body>
          </Toast> :
          progress === SearchProgressValues.complete ? (!!search_term ?
            <p>Displaying <b>{counts}</b> {counts > 1 ? "results" : "result"} for <b>"{search_term}"</b></p> :
            <p>No matching results found for your search</p>) :
            <p className="disclaimer">Loading... <b>{counts}</b> {counts > 1 ? "results" : "result"} found</p>
      }

    </div>
    <Button
      variant="no-outline-primary"
      onClick={() => handleAction({ actionType: DriveUIActionTypes.Reset })} >Cancel</Button>
  </div>;
}

export default React.memo(SearchFooterComponent);
