import React, { useEffect, useState } from "react";
import { Row, Col, Button, InputGroup, Form } from "react-bootstrap";
import { ActionHandlerFunction, PaginationItem } from "@preveil-api";
import { DirectoryEntity, DriveUIActionTypes, Message, MessageHandlerDisplayType, MessageToastTypes, useAppDispatch, TrashConfirmationMessages } from "src/common";
import { Icon, Pagination, PaginationDisplay } from "src/components";
import { uiActions } from "src/store";

type AllProps = {
  is_loading: boolean;
  handleAction: ActionHandlerFunction;
  pagination?: PaginationItem;
  selected: string[];
  directory?: DirectoryEntity;
  total: number;
};

function TrashToolbarComponent(props: AllProps) {
  const { is_loading, handleAction, pagination, selected, directory, total } = props;
  const [btn_disabled, setButtonDisabled] = useState<boolean>(selected.length <= 0);
  const [filter_param, setFilterParam] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  // Description: sets disabled to true if the total rows are 0. (for disabling filter and empty trash)
  useEffect(() => {
    const not_filtering = !directory && total === 0 || !!directory && directory.entries.length === 0;
    !!pagination && setDisabled(pagination.totalRows <= 0 && not_filtering);
  }, [pagination]);

  // Description: Bulk delete and bulk restore is disabled if no items are selected
  useEffect(() => {
    (selected.length <= 0) ? setButtonDisabled(true) : setButtonDisabled(false);
  }, [selected]);

  // Description: Confirmation modal for emptying trash
  function confirmEmptyTrash() {
    const confirmation_dialog = new Message(TrashConfirmationMessages.empty_trash_message,
      MessageHandlerDisplayType.confirm,
      MessageToastTypes.primary, TrashConfirmationMessages.empty_trash_title,
      {
        label: "Yes",
        data: true,
        action: () => handleAction({ actionType: DriveUIActionTypes.EmptyTrash }),
      },
      { label: "No" },
    );
    dispatch(uiActions.handleSetMessage(confirmation_dialog));
  }

  return (
    <Row className="toolbar trash">
      <Col xs="auto" className="ps-2 ms-1 col-sm-4">
        <InputGroup size="sm">
          <InputGroup.Text>
            <Icon className="ficon-filter" />
          </InputGroup.Text>
          <Form.Control
            disabled={disabled}
            size="sm"
            value={filter_param}
            onChange={(e) => { setFilterParam(e.target.value); handleAction({ actionType: DriveUIActionTypes.Filter, params: e.target.value }); }}
            type="text"
            placeholder="Filter..."
            aria-label="Filter" />
          {
            filter_param &&
            <Button
              variant="icon"
              size="sm"
              className="input-group-btn"
              onClick={() => {
                setFilterParam("");
                handleAction({ actionType: DriveUIActionTypes.Filter, params: "" });
              }} >
              <Icon className="ficon-x-circle" />
            </Button>
          }
        </InputGroup>
      </Col>
      <Col className="order-1">
        {!directory &&
          <>
            <Button
              variant="icon"
              size="sm"
              disabled={btn_disabled}
              onClick={() => handleAction({ actionType: DriveUIActionTypes.RestoreItems })}
              aria-label="Restore Selected"
              data-tooltip-id="pv-tooltip"
              data-tooltip-content="Restore Selected Items"
            >
              <Icon className="pv-icon-move" />
            </Button>
            <Button
              variant="icon"
              size="sm"
              disabled={btn_disabled}
              onClick={() => handleAction({ actionType: DriveUIActionTypes.Delete })}
              aria-label="Delete Selected"
              data-tooltip-id="pv-tooltip"
              data-tooltip-content="Delete Selected Items"
            >
              <Icon className="ficon-trash-2" />
            </Button>
            <Button
              variant="icon"
              size="sm"
              disabled={disabled}
              onClick={() => confirmEmptyTrash()}
              aria-label="Empty Trash"
              data-tooltip-id="pv-tooltip"
              data-tooltip-content="Empty Trash"
            >
              <Icon className="ficon-archive" />
            </Button>
          </>}
      </Col>
      <Col xs="auto" className="order-sm-2 order-3 ms-auto">
        {!!pagination && (
          <Pagination
            className="mb-0 justify-content-end"
            pagination={pagination}
            handleAction={handleAction}
          />
        )}
      </Col>
      <Col xs="auto" className="order-sm-3 order-4 me-2">
        {!!pagination && <PaginationDisplay pagination={pagination} />}
      </Col>
      <Col className="divider order-sm-3 order-1"></Col>
      <Col xs="auto" className="p-0 order-sm-3 order-4 ms-1 refresh">
        <Button
          variant="icon"
          size="sm"
          onClick={() => handleAction({ actionType: DriveUIActionTypes.Refresh, params: !directory })}
          title="Refresh Drive Trash"
        >
          {is_loading ? <i className="spinner"></i> : <Icon className="ficon-rotate-cw" />}
        </Button>
      </Col>
    </Row>
  );
}

export default React.memo(TrashToolbarComponent);
