import React, { useEffect, useState } from "react";
import { FormValidationLimits, Regex_Patterns } from "src/common";
import { Icon } from "src/components";

type AllProps = {
  password: string;
}

/* Description: This is the shared component for the password validator - used in places where the user is either creating a new password
or resetting their password. */
function PasswordValidatorComponent(props: AllProps) {
  const { password } = props;
  const [check_length, setCheckLength] = useState<boolean>(false);
  const [check_number, setCheckNumber] = useState<boolean>(false);
  const [check_upper_case, setCheckUpperCase] = useState<boolean>(false);
  const [check_lower_case, setCheckLowerCase] = useState<boolean>(false);
  const [check_special_car, setCheckSpecialChar] = useState<boolean>(false);

  // Description: The current password requirements: 10 characters, 1 number, 1 uppercase letter, 1 lowercase letter, 1 special character
  const requirements = [
    { label: "10 characters", is_valid: password.length > 0 && check_length },
    { label: "1 number", is_valid: check_number },
    { label: "1 uppercase letter", is_valid: password.length > 0 && check_upper_case },
    { label: "1 lowercase letter", is_valid: password.length > 0 && check_lower_case },
    { label: "1 special character", is_valid: password.length > 0 && check_special_car },
  ];

  // Description: use effect that detects change in password input and updates the checks. 
  useEffect(() => {
    setCheckLength(password.length >= FormValidationLimits.PASSWORD_MIN_LENGTH);
    setCheckNumber(/\d/.test(password));
    setCheckUpperCase(/[A-Z]/.test(password));
    setCheckLowerCase(/[a-z]/.test(password));
    setCheckSpecialChar(Regex_Patterns.SPECIAL_CHARACTERS_REGEX.test(password));
  }, [password]);

  return (
    <div className="password-validator">
      {requirements.map((req, index) => (
        <span key={index}>
          {req.is_valid ? (
            <Icon className="ficon-check" />
          ) : (
            <Icon className="ficon-x" />
          )}
          {req.label}
        </span>
      ))}
    </div>
  );
}

export default React.memo(PasswordValidatorComponent);
