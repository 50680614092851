import React, { useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { AccountUIActionTypes } from "src/common";
import { Icon } from "src/components";

type AllProps = {
  handleAction: ActionHandlerFunction;
}

function SuccessPanelComponent(props: AllProps) {
  const { handleAction } = props;
  
  useEffect(() => {
    handleAction({ actionType: AccountUIActionTypes.Disconnect });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Card.Body>
    <h3 className="content-header">
      <div className="success-icon-border me-2">
        <Icon className="icon pv-icon-mark-read" />
      </div>
      Transfer Successful
    </h3>
    <h4><strong>Nice job.</strong> You're all set here.</h4>
    <Button className="mt-3" onClick={() => handleAction({ actionType: AccountUIActionTypes.Destroy })}>Add Another</Button>
  </Card.Body>;
}
export default React.memo(SuccessPanelComponent);
