import * as React from "react";
import { Card, Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { CreateAccountUIActionTypes, GlobalErrorMessages } from "src/common";
import { ErrorMessage } from "src/components";

type AllProps = {
  handleAction: ActionHandlerFunction;
}

function ErrorPanelComponent(props: AllProps) {
  const { handleAction } = props;

  return <Card.Body className="form-error">
    <ErrorMessage message={GlobalErrorMessages.default} />
    <div className="btn-panel flush-bottom">
      <Button variant="outline-secondary" type="button"
        onClick={() => handleAction({ actionType: CreateAccountUIActionTypes.ResetForms })}>Cancel</Button>
    </div>
  </Card.Body>;
}
export default React.memo(ErrorPanelComponent);
