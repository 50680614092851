import * as React from "react";
import { Nav, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Icon } from "src/components";
import {
  Account, AppConfiguration, PrivateRoutes, useAppSelector, useGetDownloadFolderIdQuery, FSEmptyLinkedCollectionID, DriveEntryType,
  buildNavigationLink, PreveilDownloads, useOpenInFileManagerMutation, DefaultCollectionName, Message, DriveErrorMessages, useAppDispatch
} from "src/common";
import { RootState } from "src/store/configureStore";
import { uiActions } from "src/store";

type AllProps = {
  current_account?: Account;
  active_path?: string;
};

const routes = [
  PrivateRoutes.shared_route,
  PrivateRoutes.invitations_route,
  PrivateRoutes.trash_route
];

function DriveNavigationComponent(props: AllProps) {
  const { active_path, current_account } = props;
  const root_info = useAppSelector((state: RootState) => state.drive.root_info);
  const breadcrumbs = useAppSelector((state: RootState) => state.drive.breadcrumbs);
  const file_manager = useAppSelector((state: RootState) => state.app.os_info?.file_manager);
  const [openLocally] = useOpenInFileManagerMutation();
  const is_web = AppConfiguration.buildForWeb();
  const { data } = useGetDownloadFolderIdQuery({ user_id: current_account?.user_id || "" }, { skip: is_web || (!current_account && !!root_info) });
  const dispatch = useAppDispatch();

  // Description: Creates the folder path of the current directory using the breadcrumbs and opens in file manager through filesync
  // NOTE: if the user is in trash, shared or invitations, the open folder button takes them to the root directory of my preveil (locally).
  function openFolder() {
    let folder_path: string = "";
    if (!!breadcrumbs && !!active_path && !routes.includes(active_path)) {
      for (const path of breadcrumbs) {
        if (path.path !== DefaultCollectionName) {
          folder_path = folder_path + "/" + path.path;
        }
      }
    }
    openFolderPath(folder_path);
  }

  // Description: Opens the folder through filesync 
  async function openFolderPath(path: string) {
    !!current_account &&
      await openLocally({
        user_id: current_account.user_id,
        path
      }).unwrap().catch((msg: any) => {
        dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.error_opening_folder_path), msg));
      });
  }

  return <>
    {
      !is_web &&
      <Button className="left-nav-btn" aria-label={`Open in ${file_manager}`}
        onClick={() => openFolder()}>
        <Icon className="pv-icon-document-multiple" />
        <span>Open in {file_manager}</span>
      </Button>
    }
    <Nav>
      <Link title="My PreVeil Drive"
        className={`nav-link${!!active_path && !routes.includes(active_path) ? " active" : ""}`}
        to={`/${PrivateRoutes.drive_route}/${PrivateRoutes.drive_root}`}>
        <Icon className="ficon-folder" />
        <span>My PreVeil</span>
      </Link>
      <Link title="Shared"
        className={`nav-link${active_path === PrivateRoutes.shared_route ? " active" : ""}`}
        to={`/${PrivateRoutes.drive_route}/${PrivateRoutes.shared_route}`}>
        <Icon className="ficon-share-2" />
        <span>Shared</span>
      </Link>
      <Link title="Invitations"
        className={`nav-link${active_path === PrivateRoutes.invitations_route ? " active" : ""}`}
        to={`/${PrivateRoutes.drive_route}/${PrivateRoutes.invitations_route}`}>
        <Icon className="ficon-send" />
        <span>Invitations</span>
      </Link>
      <Link title="Trash"
        className={`nav-link${active_path === PrivateRoutes.trash_route ? " active" : ""}`}
        to={`/${PrivateRoutes.drive_route}/${PrivateRoutes.trash_route}`} >
        <Icon className="ficon-trash-2" />
        <span>Trash</span>
      </Link>
      {
        (!is_web && !!root_info && !!data && !!data.id && data.id !== FSEmptyLinkedCollectionID) &&
        <Link title={PreveilDownloads}
          className="nav-link"
          to={buildNavigationLink(root_info.collection_id, data.id, DriveEntryType.DIR)} >
          <Icon className="ficon-download" />
          <span>{PreveilDownloads}</span>
        </Link>
      }
    </Nav >
  </>;
}

export default React.memo(DriveNavigationComponent);
