import * as React from "react";
import { useNavigate } from "react-router-dom";
import { ActionHandlerFunction } from "@preveil-api";
import { useAppDispatch, useAppSelector, SearchNode, buildNavigationLink, DriveEntryType, DriveUIActionTypes, getEntityIcon, mapEntryType } from "src/common";
import { Icon } from "src/components";
import { driveActions } from "src/store";
import { RootState } from "src/store/configureStore";

type AllProps = {
    result: SearchNode;
    handleAction: ActionHandlerFunction;
}

// Description: Build each Search Result row
function SearchRowComponent(props: AllProps) {
    const { result, handleAction } = props;
    const current_directory = useAppSelector((state: RootState) => state.drive.current_directory);
    const entry_info = mapEntryType(result.type, result.name);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    // Description: Build the url and navigate 
    function handleRowClick(node: SearchNode) {
        const url = node.type === DriveEntryType.FILE ? buildNavigationLink(node.collection_id, node.parent_id, node.type, node.id) :
            buildNavigationLink(node.collection_id, node.id, node.type);
        navigate(url);
        handleAction({ actionType: DriveUIActionTypes.Reset });
        // NOTE: if the nodes parent directory is the same as the current directory, force reload to trigger the file fetch
        (node.type === DriveEntryType.FILE && current_directory?.id === node.parent_id) && dispatch(driveActions.setReloadDriveList(true));
        return false;
    }

    return <li onClick={() => handleRowClick(result)}>
        <Icon className={entry_info?.type_class || getEntityIcon(result.type)} />
        <div>
            <p data-tooltip-id="pv-tooltip" data-tooltip-content={result.name}>
                {result.name}
            </p>
            <div>
                <span>{entry_info?.type_label}</span>
                <span>{result.last_modification_date}</span>
            </div>
        </div>
    </li>;
}
export default React.memo(SearchRowComponent);
