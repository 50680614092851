import React from "react";
import { Button, Card } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { Icon } from "src/components";
import { LoginWebUIActionTypes, PublicRoutes } from "src/common";
import { Link } from "react-router-dom";

type AllProps = {
  handleAction: ActionHandlerFunction;
}

/* Description: Panel that shows up when the user clicks "forgot my password" but they do not have a shard stored with the server
to recover their password (i.e they had never downloaded the recovery file) */
function RecoveryNotSetupComponent(props: AllProps) {
  const { handleAction } = props;

  return <Card.Body className="p-3 m-1">
    <h2 className="alert-icon"><Icon className="ficon-alert-triangle" />Absent Recovery File</h2>
    <p>For enhanced security, PreVeil requires a recovery file to change your password.</p>
    <p>We see you have not created one yet. You can either...</p>
    <ul className="check-list">
      <li>
        <Icon className="icon-circle-small" />
        <p>
          Try to login again or
        </p>
      </li>
      <li>
        <Icon className="icon-circle-small" />
        <p>
          Create a new account.
        </p>
      </li>
    </ul>
    <div className="btn-panel">
      <Button type="submit" onClick={() => handleAction({ actionType: LoginWebUIActionTypes.ResetLogin })}>Login</Button>
      <Link className="btn btn-primary" to={`/${PublicRoutes.create_account_route}`} title="Create a new account">Create Account</Link>
    </div>
  </Card.Body>;
}
export default React.memo(RecoveryNotSetupComponent);
