// import React, { useState, useEffect } from "react";
import * as React from "react";
import { Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { AccountUIActionTypes } from "src/common";
import { QRCode } from "src/components";

type AllProps = {
  pin: string;
  handleAction: ActionHandlerFunction;
}

function ConnectionEstablishedComponent(props: AllProps) {
  const { pin, handleAction } = props;
  return <div>
    <h2><i className="connected"></i> Connection Active </h2>
    <h4>Enter or scan this code on your existing device</h4>
    <p className="pin">{pin}</p>
    <QRCode code={pin} />
    <div className="btn-panel text-end mt-3">
      <Button variant="outline-primary" onClick={() => handleAction({ actionType: AccountUIActionTypes.Destroy })}>Cancel</Button>
    </div>
  </div>;
}
export default React.memo(ConnectionEstablishedComponent);
