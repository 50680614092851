import * as React from "react";
import { Card, Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { CreateAccountUIActionTypes } from "src/common";

type AllProps = {
  org_name: string;
  handleAction: ActionHandlerFunction;
}

function ExpressUserAlertComponent(props: AllProps) {
  const { org_name, handleAction } = props;

  return <>
    <Card.Body>
      <h1 className="border-bottom pb-3">You have been invited to join organization: {org_name}</h1>
      <p>Please check your email to find the invitation and follow the steps.</p>
      <p>If you are unable to find the invitation, reach out to the organization's admistrator and request another invitation</p>
      <Button variant="outline-secondary" onClick={() => handleAction({ actionType: CreateAccountUIActionTypes.ResetForms })}>Cancel</Button>
    </Card.Body>
  </>;
}
export default React.memo(ExpressUserAlertComponent);
