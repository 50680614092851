import * as React from "react";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import { ActionHandlerFunction, ActivityLogInfo } from "@preveil-api";
import { GlobalConstants, AdminUIActionTypes } from "src/common";
import { Icon, SimplePagination } from "src/components";
import _ from "lodash";

type AllProps = {
  handleAction: ActionHandlerFunction;
  activity_logs: ActivityLogInfo[];
  is_loading: boolean;
  page: number;
  topic: string;
  action: string;
  topic_list: string[];
  action_list: string[];
};

/* Description: Toolbar for activity logs, contains a simple pagination and refresh. */
function LogsToolBarComponent(props: AllProps) {
  const { handleAction, activity_logs, is_loading, page, topic, topic_list, action_list, action } = props;

  return (
    <Row className="toolbar m-0">
      <Col className="d-flex align-items-center">
        <label>Topic:</label>
        <Dropdown className="pv-dropdown me-2">
          <Dropdown.Toggle variant="link" className="btn btn-sm py-0">
            {topic}
          </Dropdown.Toggle>
          <Dropdown.Menu flip={true} className="p-0" align="end">
            {_.map(topic_list, (t: string, i: number) => {
              return (
                <Dropdown.Item
                  key={i}
                  onClick={() =>
                    handleAction({ actionType: AdminUIActionTypes.SetTopic, params: t })
                  }
                >
                  {t}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
        {action_list.length > 0 &&
          <>
            <label>Action:</label>
            <Dropdown className="pv-dropdown">
              <Dropdown.Toggle variant="link" className="btn btn-sm py-0">
                {action}
              </Dropdown.Toggle>
              <Dropdown.Menu flip={true} className="p-0" align="end">
                {_.map(action_list, (a: string, i: number) => {
                  return (
                    <Dropdown.Item
                      key={i}
                      onClick={() =>
                        handleAction({ actionType: AdminUIActionTypes.SetAction, params: a })
                      }
                    >
                      {a}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </>
        }
      </Col>
      <Col xs="auto" className="ms-auto pe-0">
        <SimplePagination
          isFirst={page === 0}
          isLast={activity_logs.length < GlobalConstants.ACTIVITY_PER_PAGE_COUNT} // TO DO: need to ask the backend if they can add a "total_rows" field in the user logs call
          className="mb-0 justify-content-end"
          handleAction={handleAction}
        />
      </Col>
      <Col xs="auto" className="me-2">
        <div className="pagination_info">
          {activity_logs.length === 0 ? <b>0 Items</b> :
            <>
              <b>{page * GlobalConstants.ACTIVITY_PER_PAGE_COUNT + 1}</b> -{" "}
              <b>
                {activity_logs.length < GlobalConstants.ACTIVITY_PER_PAGE_COUNT
                  ? page * GlobalConstants.ACTIVITY_PER_PAGE_COUNT + activity_logs.length
                  : (page + 1) * GlobalConstants.ACTIVITY_PER_PAGE_COUNT}
              </b>
            </>}
        </div>
      </Col>
      <Col className="divider"></Col>
      <Col xs="auto" className="p-1 refresh">
        <Button
          variant="icon"
          size="sm"
          onClick={() => handleAction({ actionType: AdminUIActionTypes.Refresh })}
          title="Refresh Activity Logs">
          {is_loading ? <i className="spinner"></i> : <Icon className="ficon-rotate-cw" />}
        </Button>
      </Col>
    </Row>
  );
}

export default React.memo(LogsToolBarComponent);
