import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { ToastContainer, Toast } from "react-bootstrap";
import { CollectionServerUser } from "@preveil-api";
import {
  Account, account_types, LocalAccountStorage, QueryParamKeys, useAppDispatch, useAppSelector, PublicRoutes, normalizeQueryUserId, Contacts,
  usePostUsersFindMutation
} from "src/common";
import { Loading, useAuthContext } from "src/components";
import { RootState } from "src/store/configureStore";
import { accountActions } from "src/store";
import _ from "lodash";


function LoginComponent() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { loginComplete } = useAuthContext();
  const current_account = useAppSelector((state: RootState) => state.account.current_account);
  const accounts = useAppSelector((state: RootState) => state.account.accounts);
  const error = useAppSelector((state: RootState) => state.ui.errors);
  const redirect_url = useAppSelector((state: RootState) => state.ui.redirect_url);
  const active_status_code = useAppSelector((state: RootState) => state.ui.active_status_code);
  const [findUsers] = usePostUsersFindMutation();
  const dispatch = useAppDispatch();
  // Description: load accounts and urrent account from crypto (First onload event)
  useEffect(() => {
    handleGetAccounts();
  }, []);

  // Description: Update the login status when current account is set in state and pass any external QS from like choose acct
  useEffect(() => {
    const query_redirect_url = searchParams.get(QueryParamKeys.REDIRECT_URL_QUERY_KEY);
    const _redirect_url = !!query_redirect_url ? query_redirect_url : redirect_url;
    (!!current_account) &&
      handleGetUserPublicKeys(current_account, _redirect_url);
  }, [current_account]);

  // Description: Handle navigation on error (to default pages - like failed to log in - user_id does not exist)
  useEffect(() => {
    if (!!active_status_code) {
      !!error && window.location.reload();
    } else {
      // NOTE: Handle login navigate out to redirect_url set -  look at error to handle this once
      !!redirect_url && navigate(redirect_url);
      // NOTE: Handle login errors locally
      !!error && navigate(`/${PublicRoutes.get_started_route}`);
    }
  }, [active_status_code, redirect_url, error]);

  /* Description: Handles get query_user_id from QS or session then gets accounts
   * LOGIN LOGIC AND STEPS:
   * 1. GET user_id from QS -> overrides all
   * 2. If not, try getting from sessions  pv_account.address first or most_recent_user_id
   * 3. If !!user_id => SET get_local_accounts and set as current_account
   *      else => getUsers (crypto or collection - buildmode dep) and pick first account
   * 4. If !!accounts then display links of local accounts in block to select / or connect device (Web)
   *     else = No accounts then redirect to get_started
   */
  function handleGetAccounts() {
    let query_user_id = normalizeQueryUserId(searchParams.get(QueryParamKeys.USER_ID_QUERY_KEY));
    if (!query_user_id) {
      const storage = new LocalAccountStorage(current_account, account_types.full);
      // NOTE: Needs to look first in the current session then if empty most_recent_user_id
      query_user_id = storage.pv_account?.address || storage.most_recent_user_id;
    }
    dispatch(accountActions.getLocalAccounts(query_user_id || undefined));
  }

  // Description: Get UserPublic Keys and Key History
  async function handleGetUserPublicKeys(_current_account: Account, _redirect_url?: string) {
    if (_current_account.user_key.key_version > 0) {
      // NOTE: Get User Public Key and History
      const spec = [{ user_id: _current_account.user_id, key_version: _current_account.user_key.key_version }];
      let key_version = _current_account.user_key.key_version;
      while (key_version > 0) {
        key_version--;
        spec.push({ user_id: _current_account.user_id, key_version });
      }
      const new_user_keys = await findUsers({
        account_ids: Account.getAccountIdentifiers(_current_account),
        body: { spec }
      }).unwrap()
        .then(async ({ users }) => {
          return await Promise.all(_.map(users, async (cs_user: CollectionServerUser) => await Account._initKey(cs_user.user_id, cs_user.public_key)));
        });
      _current_account.updateUserKeys(new_user_keys);
    }
    // NOTE: Complete Login
    loginComplete(_current_account, account_types.full, _redirect_url, !!active_status_code);
    // NOTE: Initialize contacts on retrieving accounts:
    new Contacts(_current_account.user_id, accounts).updateContactSessions();
  }

  return <>
    <ToastContainer
      position="top-center" >
      <Toast bg="warning" show={!!active_status_code}>
        <Toast.Body>
          Please wait while we securely update your expired device key.
        </Toast.Body>
      </Toast>
    </ToastContainer>
    <Loading />
  </>;
}

export default React.memo(LoginComponent);
