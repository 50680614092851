import keyMirror from "keymirror";
import { action } from "typesafe-actions";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { KSSUserBase, KSSUser, CreateUserData, OrgInfo } from "@preveil-api";
import { Account, AccountIdentifiers, KeyStorageUser, UserRequest } from "src/common";

// ----------------------------------------------------------------------
// Types
// ----------------------------------------------------------------------
export interface SubsumeSuccessInfo {
  grant_log_keys_complete?: boolean;
  submit_export_shards_complete?: boolean;
}

export interface AccountState {
  current_account?: Account;
  accounts?: Account[];
  organization?: OrgInfo;
  account_ids?: AccountIdentifiers;
  keystorage_user?: Partial<KSSUser>;
  status: number;
  grant_log_keys_complete?: boolean;
  submit_export_shards_complete?: boolean;
  auth_token?: string; // for express users - saved only for 15 minutes so that they can download their recovery file without re-authenticating
  express_recovery_not_setup?: string | null;
}

export interface RespondToSubsumeRequestParams {
  account_ids: AccountIdentifiers;
  approve: boolean;
  request: UserRequest;
}

export const AccountActionTypes = keyMirror({
  SET_CURRENT_ACCOUNT: null,
  GET_LOCAL_ACCOUNTS: null,
  SET_NEW_LOCAL_ACCOUNT: null,
  GET_KSS_USER: null,
  GET_KSS_USER_SUCCESS: null,
  SET_NEW_KSS_USER: null,
  GET_CS_CURRENT_ACCOUNT: null,
  GET_CS_ACCOUNTS: null,
  SET_NEW_CS_EXPRESS_USER: null,
  UPGRADE_EXPRESS_ACCOUNT: null,
  ACCOUNT_FETCH_ERROR: null,
  GET_KEY_HISTORY: null,
  RESPOND_TO_SUBSUME_REQUEST: null,
  GET_ACCOUNT_ORGANIZATION_INFO: null,
  IS_EXPRESS_RECOVERY_SETUP: null,
});

// ----------------------------------------------------------------------
// Initial State and Slices 
// ----------------------------------------------------------------------
const initialState: AccountState = {
  current_account: undefined,
  organization: undefined,
  accounts: undefined,
  account_ids: undefined,
  keystorage_user: undefined,
  // contacts: [],
  status: 0,
  grant_log_keys_complete: false,
  submit_export_shards_complete: false,
  express_recovery_not_setup: localStorage.getItem("express_recovery_not_setup") || null
};

export const accountSlice = createSlice({
  name: "account",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setComponentStatus: (state, action: PayloadAction<number>) => {
      state.status = action.payload;
    },
    getLocalAccountsSuccess: (state, action: PayloadAction<Account[]>) => {
      state.accounts = action.payload;
    },
    setCurrentAccountSuccess: (state, action: PayloadAction<Account>) => {
      const current_account = action.payload;
      const organization = !!current_account.org_info ? current_account.org_info : undefined;
      return { ...state, ...{ current_account, organization } };
    },
    // Description: Update current account and accounts block to include the new organization information
    setCurrentAccountOrganizationSuccess: (state, action: PayloadAction<OrgInfo>) => {
      state.organization = action.payload;
    },
    getKssUserSuccess: (state, action: PayloadAction<AccountIdentifiers>) => {
      state.account_ids = action.payload;
    },
    setKssUserCredsSuccess: (state, action: PayloadAction<{ keystorage_user: Partial<KSSUser>, status: number }>) => {
      return { ...state, ...action.payload };
    },
    // Description: Destroy intermediate steps and reset forms
    destroyAccountFormStates: (state) => {
      return { ...state, ...{ keystorage_user: initialState.keystorage_user, account_ids: initialState.account_ids, status: initialState.status } };
    },
    // Description: On Fetch Error global call Pass the parts of the AccountState to change
    accountFetchError: (state, action: PayloadAction<Partial<AccountState>>) => {
      return { ...state, ...action.payload };
    },
    logout: () => {
      return initialState;
    },
    revokeCurrentUser: (state) => {
      return { ...state, current_account: undefined };
    },
    setSubsumeSuccess: (state, action: PayloadAction<SubsumeSuccessInfo>) => {
      return { ...state, ...action.payload };
    },
    handleSetAuthToken: (state, action: PayloadAction<string | undefined>) => {
      state.auth_token = action.payload;
    },
    setExpressRecoveryNotSetup: (state, action: PayloadAction<string | null>) => {
      state.express_recovery_not_setup = action.payload;
    }
  }
});

// ----------------------------------------------------------------------
// Saga specific Actions
// ----------------------------------------------------------------------
const getLocalAccounts = (user_id?: string) => action(AccountActionTypes.GET_LOCAL_ACCOUNTS, user_id);
const getKSSUser = (kss_user: KSSUserBase) => action(AccountActionTypes.GET_KSS_USER, kss_user);
const findCollectionServerCurrentUser = (account_ids: AccountIdentifiers) => action(AccountActionTypes.GET_CS_CURRENT_ACCOUNT, account_ids);
const fetchKeyHistoryRequest = (current_account: Account) => action(AccountActionTypes.GET_KEY_HISTORY, current_account);
const setNewLocalAccount = (createUserData: CreateUserData) => action(AccountActionTypes.SET_NEW_LOCAL_ACCOUNT, createUserData);
const setNewKSSUser = (keystorage_user: KeyStorageUser) => action(AccountActionTypes.SET_NEW_KSS_USER, keystorage_user);
const claimCSExpressAccoutRequest = (keystorage_user: KeyStorageUser) => action(AccountActionTypes.SET_NEW_CS_EXPRESS_USER, keystorage_user);
const upgradeExpressAccount = (account_ids: AccountIdentifiers) => action(AccountActionTypes.UPGRADE_EXPRESS_ACCOUNT, account_ids);
const respondToSubsumeRequest = (params: RespondToSubsumeRequestParams) => action(AccountActionTypes.RESPOND_TO_SUBSUME_REQUEST, params);
const getOrganizationInfo = (params: { account_ids: AccountIdentifiers, id?: string, request?: UserRequest, org_info?: OrgInfo }) => action(AccountActionTypes.GET_ACCOUNT_ORGANIZATION_INFO, params);

const accountSagaActions = {
  getLocalAccounts,
  getKSSUser,
  findCollectionServerCurrentUser,
  fetchKeyHistoryRequest,
  setNewLocalAccount,
  setNewKSSUser,
  claimCSExpressAccoutRequest,
  upgradeExpressAccount,
  respondToSubsumeRequest,
  getOrganizationInfo
};

// ----------------------------------------------------------------------
// Global exports
// ----------------------------------------------------------------------
// export const accountActions = accountSlice.actions;
export const accountActions = { ...accountSlice.actions, ...accountSagaActions };
export default accountSlice.reducer;
