import * as React from "react";
import { ActionHandlerFunction } from "@preveil-api";
import { DragEventTypes, TreeItem } from "src/common";
import TreeFolder from "./TreeFolder.component";
import TreeFile from "./TreeFile.component";
import CheckboxTreeFolder from "./CheckboxTreeFolder.component";

type AllProps = {
  data: TreeItem[];
  readonly?: boolean;
  active?: string;
  className?: string;
  fetching_children?: boolean;
  drive?: boolean;
  selective_sync?: boolean;
  drag_events?: DragEventTypes[];
  handleAction: ActionHandlerFunction;
};

function TreeRecursive(props: AllProps) {
  const { active, data, readonly, fetching_children, drive, drag_events, selective_sync, handleAction } = props;

  return <>
    {
      !!data &&
      data.map((item: TreeItem, i: number) => {
        // if its a file render <TreeFile /> -> TO DO If needed
        if (item.type === "file") {
          return <TreeFile key={`file_${i}_${item.id}`} file={item}
            handleAction={handleAction} />;
        } else {

          return selective_sync ?
            <CheckboxTreeFolder key={`folder_${i}_${item.id}`} folder={item}
              active={active === item.id}
              handleAction={handleAction} fetching_children={fetching_children} drive={drive}>
              {!!item.children &&
                <TreeRecursive
                  data={item.children}
                  handleAction={handleAction}
                  active={active}
                  fetching_children={fetching_children}
                  selective_sync={selective_sync}
                  drive={drive} />
              }
            </CheckboxTreeFolder> :
            <TreeFolder key={`folder_${i}_${item.id}`} folder={item}
              active={active === item.id}
              handleAction={handleAction} readonly={readonly} fetching_children={fetching_children} drive={drive}
              drag_events={drag_events} >
              {
                !!item.children &&
                <TreeRecursive
                  data={item.children}
                  handleAction={handleAction}
                  readonly={readonly}
                  active={active}
                  fetching_children={fetching_children}
                  drive={drive}
                  drag_events={drag_events} />
              }
            </TreeFolder>;
        }
      })
    }
  </>;
};

function Tree(props: AllProps) {
  const { data, readonly, active, handleAction, className, fetching_children, drive, drag_events, selective_sync } = props;
  return <div className={`tree${!!className ? ` ${className}` : ""}`}>
    <TreeRecursive
      data={data}
      handleAction={handleAction}
      readonly={readonly}
      active={active}
      fetching_children={fetching_children}
      drive={drive}
      selective_sync={selective_sync}
      drag_events={drag_events} />
  </div>;
}

export default React.memo(Tree);
