/*
* Description: Device class on the client side
*
*/
import { DeviceBase, DeviceLockInfoBase, Platform, PlatformType } from "@preveil-api";
import {
  ContentLoadStatus, DevicePlatform, Helpers, AccountUserPublicKey, AccountUserKey, AppUserKey, RemoteDeviceKey,
  KeyFactory, AccountIdentifiers, AppConfiguration, UUID, DeviceStatus
} from "src/common";

export interface Devices {
  state: ContentLoadStatus; // Not sure if this is needed
  list: Device[];
}

export class Device {
  device_id: string;
  device_name: string;
  metadata: unknown;
  platform: Platform;
  is_current: boolean = false;
  is_active: boolean;
  lock_info?: DeviceLockInfoBase | null;
  device_key?: AccountUserKey;
  public_device_key?: AccountUserPublicKey;

  constructor(user_id: string, device: DeviceBase, device_key: AccountUserKey) {
    this.device_id = device.device_id;
    this.device_name = device.device_name;
    this.metadata = device.metadata;
    this.lock_info = device.lock_info;
    this.platform = DevicePlatform[device.platform] || DevicePlatform.default;
    this.is_active = device.is_active || false;
    this.device_key = device_key;
    this.public_device_key = device_key.public_user_key;
  }

  static deviceStatusColor(device_status: string): string {
    switch (device_status) {
      case DeviceStatus.current_device.class: {
        return "blue";
      }
      case DeviceStatus.active.class: {
        return "green";
      }
    }
    return "";
  }

  /*
   * Description: Initialize all async data in the class then return RemoteDeviceKey class instance
   *     Build remote from user_id & device.public_user_key (key_version)
   */
  static async initDeviceKey(user_id: string, device: DeviceBase): Promise<AccountUserKey> {
    let device_key: AccountUserKey;
    try {
      if (!!device.key) {
        device_key = await KeyFactory.deserializeUserKey(Helpers.b64Decode(device.key));
      } else {
        const _public_device_key = await KeyFactory.deserializePublicUserKey(
          Helpers.b64Decode(device.public_key),
        );
        return new RemoteDeviceKey(user_id, _public_device_key.key_version);
      }
      return device_key;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  /*
   * Description: Returns an initial state for DeviceBase
   */
  static buildLocalBaseDevice(account_ids: AccountIdentifiers): DeviceBase {
    return {
      device_id: account_ids.device_id,
      device_name: "",
      public_key: Helpers.b64Encode(account_ids.device_key.serialize()),
      metadata: null,
      platform: (AppConfiguration.buildForExpress()
        ? DevicePlatform.express.text
        : DevicePlatform.session.text) as PlatformType,
    };
  }

  // Description: Create a new JSON LOCAL DEVICE or DeviceBase (crypto type)
  static async createNewJSONLocalDevice(
    device_name: string,
    platform: PlatformType,
    device_key?: AppUserKey,
    metadata: any = {},
  ): Promise<DeviceBase> {
    // If passed device_key is undefined create a new one
    const _device_key = !!device_key ? device_key : await KeyFactory.newUserKey();
    const device_public_key = _device_key.public_user_key;
    return {
      device_id: new UUID().String(),
      device_name,
      platform,
      public_key: Helpers.b64Encode(device_public_key.serialize()),
      metadata,
    };
  }
}
