import * as React from "react";
import { Row, Col, ListGroup, Button } from "react-bootstrap";
import { PublicRoutes, SupportRoutes, useAppSelector } from "src/common";

function UpgradeInformationComponent() {
  const current_account = useAppSelector((state) => state.account.current_account);
  const user_id = current_account?.user_id;
  return (
    <>
      <Row className="justify-content-center">
        <Col xs={10} md={9}>
          <h1>Upgrade your PreVeil Express Account</h1>
          <p className="mb-4 fs-4">
            Get new features including Gmail and Outlook integration, sharing files, and replace
            your PreVeil Express password with cryptographic keys that cannot be guessed or
            forgotten.
          </p>
          <ListGroup className="public-card" horizontal>
            <ListGroup.Item className="d-flex align-items-center justify-content-center image">
              <div className="option-counter">1</div>
            </ListGroup.Item>
            <ListGroup.Item className="content">
              <p className="title">Verify System Requirements</p>
              <p className="fs-5">
                PreVeil supports Desktop client installation for several versions of macOS and
                Windows.
              </p>
              <Button
                variant="primary"
                target="_blank"
                href={`${SupportRoutes.system_requirements}`}
              >
                System Requirements
              </Button>
            </ListGroup.Item>
          </ListGroup>
          <ListGroup className="public-card" horizontal>
            <ListGroup.Item className="d-flex align-items-center justify-content-center image">
              <div className="option-counter">2</div>
            </ListGroup.Item>
            <ListGroup.Item className="content">
              <p className="title">Download your Copy</p>
              <p className="fs-5">
                Please Read and Agree to the Terms of Use and Privacy Policy and download the
                installation file.
              </p>
              <Button
                variant="primary"
                target="_blank"
                href={SupportRoutes.cookieless_download}>
                Download
              </Button>
            </ListGroup.Item>
          </ListGroup>
          <ListGroup className="public-card" horizontal>
            <ListGroup.Item className="d-flex align-items-center justify-content-center image">
              <div className="option-counter pb-0">3</div>
            </ListGroup.Item>
            <ListGroup.Item className="content">
              <p className="title">Install PreVeil</p>
              <p className="fs-5">
                When the download finishes, go to your Downloads folder to open and run{" "}
                <b>PreVeil Installer</b>
              </p>
              <p className="text-muted fs-5 mb-2">MacOS: PreVeil.dmg</p>
              <p className="text-muted fs-5 mb-2">Windows: PreVeilInstaller.msi</p>
            </ListGroup.Item>
          </ListGroup>
          <ListGroup className="public-card" horizontal>
            <ListGroup.Item className="d-flex align-items-center justify-content-center image">
              <div className="option-counter">4</div>
            </ListGroup.Item>
            <ListGroup.Item className="content">
              <p className="title">Login and Upgrade</p>
              <p className="fs-5">
                Login to your current PreVeil Express account to begin the upgrading and importing
                your information into a full featured PreVeil account
              </p>
              <Button
                href={
                  !!user_id
                    ? `${process.env.REACT_APP_PORT}/${PublicRoutes.upgrade_account_route}?user_id=${encodeURIComponent(
                      user_id,
                    )}`
                    : `${process.env.REACT_APP_PORT}/${PublicRoutes.upgrade_account_route}`
                } >
                Begin Upgrading
              </Button>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </>
  );
}

export default React.memo(UpgradeInformationComponent);
