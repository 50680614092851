import React, { useState, FocusEvent, useEffect, FormEvent, Dispatch, SetStateAction } from "react";
import { Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import {
  Account, AppConfiguration, CreateDirectoryApiResponse, DriveErrorMessages, DriveSuccessMessages,
  EntryItem, Message, MessageAnchors, useAppDispatch, useAppSelector, useCreateDirectory, useCreateDirectoryMutation, validateFileFolderName, sortBy,
  SORT_DIRECTION, DriveEntryType, dayjs
} from "src/common";
import { Icon } from "src/components";
import { driveActions, uiActions } from "src/store";
import { RootState } from "src/store/configureStore";

type AllProps = {
  setCreatingNewFolder: Dispatch<SetStateAction<boolean>>;
  current_account: Account;
};

function CreateDirectoryComponent(props: AllProps) {
  const { setCreatingNewFolder, current_account } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const { new_directory, createDirectoryWeb, error } = useCreateDirectory(current_account);
  const [createDirectory] = useCreateDirectoryMutation();
  const dispatch = useAppDispatch();
  const current_directory = useAppSelector((state: RootState) => state.drive.current_directory);

  // Description: If there are changes in error or NewDirectory from the web hook, this will handle the error or success
  useEffect(() => {
    !!error && handleCreateError();
    if (!!new_directory && !!current_directory) {
      handleCreateSuccess(new_directory);
    }
  }, [new_directory, error]);

  // Description: Escape key to close the new folder modal
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        handleCancel();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // }
  }, []);

  // Description: If there is no name (or has only whitespaces for the name), it just closes the form, if there is, it checks if its a valid name and calls the correct function depending
  // on if the user is in app mode or web mode.
  function submit() {
    if (!loading) { // prevent double dir calls while initial call is still loading
      const trimmed_name = name.trim();
      if (trimmed_name.length === 0) {
        setCreatingNewFolder(false);
        return;
      }
      const validate = validateFileFolderName(trimmed_name, DriveEntryType.DIR, current_directory);
      if (!!validate) {
        dispatch(uiActions.handleRequestErrors(new Message(validate)));
        setName("");
      } else {
        setLoading(true);
        !!current_directory && AppConfiguration.buildForWeb()
          ? createDirectoryWeb(current_directory.collection_id, current_directory.id, trimmed_name)
          : createDirectoryApp(trimmed_name);
      }
    }
  }

  // Description: create directory function for app mode. (filesync)
  async function createDirectoryApp(trimmed_name: string) {
    if (!!current_directory) {
      await createDirectory({
        user_id: current_account.user_id,
        directory_id: current_directory.id,
        collection_id: current_directory.collection_id,
        name: trimmed_name,
      })
        .unwrap()
        .then((response: CreateDirectoryApiResponse) => {
          handleCreateSuccess(response);
        })
        .catch((msg) => {
          handleCreateError(msg);
        });
    }
  }

  // Description: Handles Errors
  function handleCreateError(msg?: any) {
    dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.error_creating_folder.replace(MessageAnchors.file_name, name)), msg));
    handleCancel();
  }

  // Description: Handles create success
  function handleCreateSuccess(dir: EntryItem) {
    const sortedEntries = !!current_directory ? sortBy(current_directory.entries.concat({ ...dir, lastModificationDate: dayjs.utc(new Date()).format() }), { field: "name", direction: SORT_DIRECTION.ascending }) : [];
    dispatch(driveActions.setCurrentDirectorySuccess(Object.assign({}, current_directory, { entries: sortedEntries })));
    dispatch(uiActions.handleSetMessage(new Message(DriveSuccessMessages.create_success.replace(MessageAnchors.file_name, name))));
    handleCancel();
  }

  // Description: Handles Cancel button
  function handleCancel() {
    setName("");
    setCreatingNewFolder(false);
    setLoading(false);
  }

  return (
    <Row className="create-directory">
      <Col xs="1">
        <Button
          variant="transparent"
          className="create-icons"
          size="sm"
          onMouseDown={(e) => e.preventDefault()}
          onClick={() => {
            !loading && handleCancel();
          }}
          title="Create Directory"
        >
          {loading ? <i className="spinner"></i> : <Icon className="ficon-x" />}
        </Button>
      </Col>
      <Col xs="11" md="5">
        <Form
          className="me-2"
          onSubmit={(e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            submit();
          }}>
          <InputGroup size="sm">
            <Form.Control
              autoFocus={true}
              type="text"
              placeholder="New Folder Name"
              aria-label="create new directory"
              value={name}
              required
              maxLength={255}
              onChange={(e: FocusEvent<HTMLInputElement>) => setName(e.currentTarget.value)}
              onBlur={(e: FocusEvent<HTMLInputElement>) => {
                e.preventDefault();
                submit();
              }}
            />
            {!!name && (
              <>
                <Button
                  variant="icon"
                  size="sm"
                  className="text-muted p-1"
                  onMouseDown={(e) => e.preventDefault()}
                  disabled={loading}
                  onClick={() => setName("")}
                >
                  Clear
                </Button>
                <Button variant="icon" size="sm" className="" disabled={loading} onMouseDown={(e) => e.preventDefault()} onClick={submit}>
                  <Icon className="ficon-check" />
                </Button>
              </>
            )}
          </InputGroup>
        </Form>
      </Col>
      <Col xs="6" className="ms-0 hide-mobile">Folder</Col>
    </Row>
  );
}

export default React.memo(CreateDirectoryComponent);
