import React from "react";
import { Row, Col } from "react-bootstrap";
import { AdminRow } from "src/components";

type AllProps = {
  groupName: string;
  approvers: Array<{
    name: string | null;
    address: string;
    role?: string | undefined;
  }>;
}

function ApproversInfoCard({approvers, groupName}: AllProps) {
  return (
    <div className="recovery-groups-card">
      <header>
        <Row className="header-card-row">
          <Col xs={7}>
            <p className="m-0">Approvers</p>
          </Col>
          <Col xs={5} className="ps-2">
            <p className="m-0">Group</p>
          </Col>
        </Row>
      </header>
      <div>
        { approvers.map((approver: any) => {
          const { address, name } = approver;
          const member = {
            user_id: address,
            display_name: name,
            org_info: {
              dept_name: groupName
            }
          };

          return <AdminRow key={address} member={member} />;
        })}
      </div>
    </div>
  );
}

export default React.memo(ApproversInfoCard);