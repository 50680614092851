import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { ActionHandlerFunction, PaginationItem } from "@preveil-api";
import { Account, useAppSelector, DriveUIActionTypes, DirectoryEntity, Feature, DriveFeature, CollectionEntity, isSameIdentifier, DriveEntryType } from "src/common";
import { Icon, Pagination, PaginationDisplay } from "src/components";
import { RootState } from "src/store/configureStore";

type AllProps = {
  current_account: Account;
  is_loading: boolean;
  is_root: boolean;
  pagination?: PaginationItem;
  selected: string[];
  current_directory?: DirectoryEntity;
  collection_info?: CollectionEntity;
  handleAction: ActionHandlerFunction;
}

function ToolbarComponent(props: AllProps) {
  const { is_loading, is_root, pagination, selected, current_directory, collection_info, handleAction } = props;
  const current_link = useAppSelector((state: RootState) => state.drive.current_link);
  const node_permissions = collection_info?.permissions;
  const [btn_disabled, setButtonDisabled] = useState<boolean>(true);
  const [selective_sync_btn_disabled, setSelectiveSyncButtonDisabled] = useState<boolean>(true);
  const [download_btn_disabled, setDownloadButtonDisabled] = useState<boolean>(true);

  useEffect(() => {
    (!!pagination && pagination.totalRows > 0) ?
      setButtonDisabled(selected.length <= 0) : setButtonDisabled(true);

    // NOTE: Handles selective sync button (will only be true if at least 1 folder is selected)
    if (!!current_directory) {
      const isFileSelected = current_directory.entries.some(e => (e.type === DriveEntryType.FILE) && selected.includes(e.id));
      const isFolderSelected = current_directory.entries.some(e => (e.type !== DriveEntryType.FILE) && selected.includes(e.id));

      setDownloadButtonDisabled(!isFileSelected);
      setSelectiveSyncButtonDisabled(!isFolderSelected);
    }
  }, [selected, pagination]);

  // Description: wait for the correct collection_info and current_link information
  function isDetailsReady(): boolean {
    let is_ready = false;
    if (isSameIdentifier(collection_info?.id, current_directory?.id)) {
      is_ready = (!!collection_info && (collection_info.is_maintainer && collection_info.is_root)) ?
        !!current_link : isSameIdentifier(collection_info?.collection_id, current_directory?.collection_id);
    }
    return is_ready;
  }


  return <Row className="toolbar">
    {
      !!current_directory &&
      <>
        <Col className="order-1">
          {
            !!node_permissions &&
            <>
              <Button variant="icon" size="sm"
                hidden={Feature.hidden(DriveFeature.Upload, node_permissions)}
                disabled={is_loading}
                onClick={() => handleAction({ actionType: DriveUIActionTypes.SelectFiles })}
                aria-label="Upload Files"
                data-tooltip-id="pv-tooltip" data-tooltip-content="Upload Files">
                <Icon className="ficon-upload" />
              </Button>
              <Button variant="icon" size="sm"
                hidden={Feature.hidden(DriveFeature.CreateFolder, node_permissions)}
                disabled={is_loading}
                onClick={() => handleAction({ actionType: DriveUIActionTypes.CreateFolder })}
                aria-label="Create New Folder"
                data-tooltip-id="pv-tooltip" data-tooltip-content="Create New Folder">
                <Icon className="ficon-folder-plus" />
              </Button>
              <Button variant="icon" size="sm"
                hidden={Feature.hidden(DriveFeature.Download, node_permissions)}
                disabled={download_btn_disabled}
                onClick={() => handleAction({ actionType: DriveUIActionTypes.Download })}
                aria-label="Upload Files"
                data-tooltip-id="pv-tooltip" data-tooltip-content="Download Files">
                <Icon className="ficon-download" />
              </Button>
              <Button variant="icon" size="sm"
                disabled={btn_disabled}
                hidden={Feature.hidden(DriveFeature.Move, node_permissions)}
                onClick={() => handleAction({ actionType: DriveUIActionTypes.MoveModal, params: { show: true } })}
                aria-label="Move Selected"
                data-tooltip-id="pv-tooltip" data-tooltip-content="Move Selected Items">
                <Icon className="pv-icon-move" />
              </Button>
              <Button variant="icon" size="sm"
                disabled={btn_disabled}
                hidden={Feature.hidden(DriveFeature.Delete, node_permissions)}
                onClick={() => handleAction({ actionType: DriveUIActionTypes.Delete })}
                aria-label="Delete Selected"
                data-tooltip-id="pv-tooltip" data-tooltip-content="Delete Selected Items">
                <Icon className="ficon-trash-2" />
              </Button>
              <Button variant="icon" size="sm"
                hidden={Feature.hidden(DriveFeature.SelectiveSync, node_permissions)}
                disabled={selective_sync_btn_disabled}
                onClick={() => handleAction({ actionType: DriveUIActionTypes.SelectiveSyncModal, params: { show: true, current_directory } })}
                aria-label="Sync"
                data-tooltip-id="pv-tooltip" data-tooltip-content="Sync">
                <Icon className="ficon-refresh-ccw" />
              </Button>
              <Button variant="icon" size="sm"
                hidden={is_root || Feature.hidden(DriveFeature.Details, node_permissions)}
                disabled={is_loading || !isDetailsReady()}
                onClick={() => handleAction({ actionType: DriveUIActionTypes.Details, params: current_directory.id })}
                aria-label="Current Folder Details"
                data-tooltip-id="pv-tooltip" data-tooltip-content="Current Folder Details">
                <Icon className="pv-icon-finder" />
              </Button>
            </>
          }
        </Col>
        <Col xs="auto" className="order-sm-2 order-3 ms-auto">
          {
            !!pagination &&
            <Pagination
              className="mb-0 justify-content-end"
              pagination={pagination}
              handleAction={handleAction} />
          }
        </Col>
        <Col xs="auto" className="order-sm-3 order-4 me-2">
          {!!pagination && <PaginationDisplay pagination={pagination} />}
        </Col>
        <Col className="divider order-sm-3 order-1"></Col>
        <Col xs="auto" className="p-0 order-sm-3 order-4 ms-1 refresh" >
          <Button variant="icon" size="sm"
            onClick={() => handleAction({ actionType: DriveUIActionTypes.Refresh })}
            title="Refresh Current Folder">
            {
              is_loading ?
                <i className="spinner"></i> :
                <Icon className="ficon-rotate-cw" />
            }
          </Button>
        </Col>
      </>
    }
  </Row >;
}

export default React.memo(ToolbarComponent);