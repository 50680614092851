import React, { useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { IActionHandler } from "@preveil-api";
import {
  useAppDispatch, useAppSelector, Message, RecoverAccountSteps, BackupData, BackupDataObject, MessageHandlerDisplayType, account_types,
  AccountErrorMessages, useRestoreAccountBackupMutation, GlobalErrorMessages, MessageAnchors, QueryParamValues, QueryParamKeys, DefaultRoutes
} from "src/common";
import { Backup as BackupPB } from "src/common/keys/protos/backup_pb";
import { uiActions, accountActions } from "src/store";
import { DefaultPanel, PasswordForm, ErrorPanel } from "./";
import { Loading, useAuthContext } from "src/components";
import CopyAccountImage from "src/assets/images/copy_account.svg";

function BackupRecoverAccountComponent() {
  const { loginComplete } = useAuthContext();
  const [backup_pb, setBackupPB] = useState<BackupPB>();
  const [password, setPassword] = useState<string>();
  const dispatch = useAppDispatch();
  const step = useAppSelector((state) => state.account.status);
  const [restoreAccount] = useRestoreAccountBackupMutation();
  const current_account = useAppSelector((state) => state.account.current_account);

  // Description: Recover Account Page actions
  // Find if I need password panel protocol_version === 1 means password;  
  async function handleRestoreAccount(backup_data: BackupDataObject) {
    dispatch(accountActions.setComponentStatus(RecoverAccountSteps.LOADING));
    await restoreAccount(backup_data)
      .unwrap()
      .then((m) => {
        // Need to get Local aacounts and on 
        dispatch(accountActions.getLocalAccounts(backup_data.user_id));
      })
      .catch((error) => {
        RecoverAccountRequests.handlePageError({ message: AccountErrorMessages.default, stack: error });
        RecoverAccountRequests.handleResetForms();
      });
  }

  // Description: Page actions
  const RecoverAccountRequests = {
    handleSubmitRecoverCode: (backup_code: string) => {
      try {
        const _backup_pb = BackupData.getDecodedText(backup_code);
        if (!!_backup_pb) {
          setBackupPB(_backup_pb);
          // NOTE: protocol_version === 1 means password; protocol_version === 2 No password
          _backup_pb.getProtocolVersion() === 1 ?
            dispatch(accountActions.setComponentStatus(RecoverAccountSteps.SUBMIT_PASSWORD)) :
            handleRestoreAccount(BackupData.getBackupDataObject(BackupData.deserialize(_backup_pb.getData_asU8())));
        } else {
          RecoverAccountRequests.handlePageError({ message: AccountErrorMessages.restore_account_code_error });
        }
      } catch {
        RecoverAccountRequests.handlePageError({ message: AccountErrorMessages.restore_account_code_error });
      }
    },

    // Description: Handles password submits and retries (empty submitted_password)
    handleSubmitPassword: (submitted_password?: string) => {
      const _password = !!submitted_password ? submitted_password : password;
      if (!!_password && !!backup_pb) {
        setPassword(_password);
        // Note: Need to decrypt with password first
        BackupData.decrypt(_password, backup_pb.getData_asU8())
          .then((data: Uint8Array) => {
            // Note: Pass deserialize the Buffer array of backup proto obj
            // dispatch(accountActions.restoreAccountBackup(BackupData.getBackupDataObject(BackupData.deserialize(data))));
            handleRestoreAccount(BackupData.getBackupDataObject(BackupData.deserialize(data)));
          }).catch((error: any) => {
            RecoverAccountRequests.handlePageError({ message: AccountErrorMessages.restore_account_password_error, stack: error });
          });
      } else {
        RecoverAccountRequests.handlePageError({ message: AccountErrorMessages.restore_account_password_error });
      }
    },

    // Description: Resets all forms and state to try again
    handleResetForms: () => {
      setPassword(undefined);
      setBackupPB(undefined);
      dispatch(accountActions.setComponentStatus(RecoverAccountSteps.SUBMIT_RECOVER_CODE));
    },
    handlePageError: (params: { message: string, stack?: any }) => {
      dispatch(uiActions.handleRequestErrors(new Message(params.message), params.stack));
    },
    handlePageErrorMessage: (params: { message: string, stack?: any }) => {
      dispatch(uiActions.handleRequestErrors(new Message(params.message, MessageHandlerDisplayType.logger), params.stack));
    }
  };

  //  Description: Handle all actions from Children forms
  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    if ((RecoverAccountRequests as any)[callback] instanceof Function) {
      (RecoverAccountRequests as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(MessageAnchors.actionType, actionObj.actionType);
      RecoverAccountRequests.handlePageErrorMessage({ message, stack: actionObj });
    }
  }

  function RecoverAccountForms() {
    switch (step) {
      case RecoverAccountSteps.SUBMIT_RECOVER_CODE:
        return <DefaultPanel handleAction={handlePageActions} />;
      case RecoverAccountSteps.SUBMIT_PASSWORD:
        return <PasswordForm handleAction={handlePageActions} />;
      case RecoverAccountSteps.PAGE_ERROR:
        return <ErrorPanel handleAction={handlePageActions} />;
      default:
        return <Loading className="in-place" />;
    }
  }

  // Description: load accounts and urrent account from crypto (First onload event)
  useEffect(() => {
    dispatch(accountActions.setComponentStatus(RecoverAccountSteps.SUBMIT_RECOVER_CODE));
    return () => { // On unmount
      dispatch(uiActions.handleMessageDismiss());
      dispatch(accountActions.destroyAccountFormStates());
    };
  }, []);

  // Description: Update the login status when current account is set in state
  useEffect(() => {
    !!current_account &&
      loginComplete(current_account, account_types.full,
        `${DefaultRoutes.mail_default}?${QueryParamKeys.PAGE_ACTION_QUERY_KEY}=${QueryParamValues.recovered}`);
  }, [current_account]);

  return <Row className="justify-content-center">
    <Col md={8}>
      <h1 className="main-header">Restore Account from Recovery File</h1>
      <Card className="pv-card">
        <Card.Img variant="top" src={CopyAccountImage} />
        <Card.Body className="p-4">
          <RecoverAccountForms />
        </Card.Body>
      </Card>
    </Col>
  </Row>;
}

export default React.memo(BackupRecoverAccountComponent);
