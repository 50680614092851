import { useState, useCallback, useRef, useEffect } from "react";
import { MessageDisplayType, ErrorStackDataType } from "@preveil-api";
import {
  Account, useAppDispatch, PermissionSetType, Message, MessageHandlerDisplayType, DriveErrorMessages, PermissionSet, useSendRequestMutation,
  CollectionFilesyncAPI, getEnumKey, useAppSelector, UUID, Permission, FSRoleType, ErrorStackItem, DriveRequest
} from "src/common";
import { FSRole, FSMessage, FSStatus } from "src/common/keys/protos/collections_pb";
import { RootState } from "src/store/configureStore";
import { driveActions, uiActions, DriveCallbackAsyncFunction } from "src/store";
import _ from "lodash";

export function useGetPermissions(current_account: Account) {
  const state_default_permissions = useAppSelector((state: RootState) => state.drive.default_permissions);
  const default_permissions_ref = useRef<PermissionSetType[]>(state_default_permissions);
  const [permissions, setPermissions] = useState<PermissionSetType | undefined>();
  const [error_fetching_permissions, setErrorFetchingPermissions] = useState<boolean>(false);
  const [sendRequest] = useSendRequestMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    default_permissions_ref.current = state_default_permissions;
  }, [state_default_permissions]);

  // Description: Fetch Permissions and set to store; If collection_id then fetch all
  async function getDefaultCollectionPermissions(collection_id?: string) {
    const drive_request = await CollectionFilesyncAPI.getPermissions(current_account, collection_id);
    async function callback(message: FSMessage) {
      const roles = message.getPermissions()?.getRolesList();
      if (message.getStatus() === FSStatus.OK && !!roles && roles.length > 0) {
        const permissions = _.map(roles, (role: FSMessage.Role) => {
          const collection_id = new UUID({ bytes: role.getCollectionId_asU8() });
          const role_type = role.getRole() as FSRoleType;
          const wrapped_key = role.getWrappedKey_asU8();
          const user_key_version = role.getUserKeyVersion() || 0;
          const role_version = role.getVersion() || 0;
          return {
            collection_id,
            permission: new Permission(role_type, role_version, user_key_version, role.getViewOnly(), wrapped_key)
          };
        });
        const permission_sets = PermissionSet.getActivePermissionSets(permissions);
        let new_default_permissions = permission_sets;
        if (!!collection_id && !!default_permissions_ref.current && default_permissions_ref.current?.length > 0) {
          new_default_permissions = PermissionSet.updateDefaultPermissionSets(permission_sets[0], default_permissions_ref.current);
        }
        default_permissions_ref.current = new_default_permissions;
        // NOTE: If Collection Id was passed then setPermissiosn with history else setDefaultPermissions 
        const _permissions = !!collection_id ?
          _.find(new_default_permissions, (set: PermissionSetType) => collection_id === set.collection_id.B64()) : null;
        if (!!_permissions && !!collection_id) {
          getCollectionPermissions(collection_id, new_default_permissions);
        }
        dispatch(driveActions.setDefaultPermissions(new_default_permissions));
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_permissions }, message);
      }
    }
    handleSendRequest(drive_request, callback, DriveErrorMessages.error_fetching_permissions);
  }

  // Description: Get Permissions From the default_permissions store object
  async function getCollectionPermissions(collection_id: string, default_permissions: PermissionSetType[]) {
    const _permissions = _.find(default_permissions, (set: PermissionSetType) => collection_id === set.collection_id.B64());
    if (!!_permissions) {
      // NOTE: Check the permissions for a collection and get KeyHistory
      const permission_set = PermissionSet.init(_permissions);
      // NOTE: Only call history if needed for these 2 roles
      const reader = PermissionSet.getValidKeyHistoryRole(permission_set, FSRole.READER);
      const access = PermissionSet.getValidKeyHistoryRole(permission_set, FSRole.ACCESS);
      const role = reader || access;
      // NOTE: If no history is require return permissions
      !!role ? getKeyHistory(collection_id, default_permissions, _permissions) : setPermissions(_permissions);
    } else {
      // NOTE: Grab all default permissions to reset object again and pass error to user
      getDefaultCollectionPermissions(collection_id);
    }
  }

  // Description: Get Permissions Key History
  async function getKeyHistory(collection_id: string, default_permissions: PermissionSetType[], _permissions: PermissionSetType) {
    const role = FSRole.READER; // payload.role !== undefined ? payload.role : FSRole.READER;
    const drive_request = await CollectionFilesyncAPI.getKeyHistory(current_account, _permissions, collection_id, role);
    async function callback(message: FSMessage) {
      const key_history = message.getKeyHistoryList();
      if (message.getStatus() === FSStatus.OK && !!key_history) {
        const sorted_key_history = key_history.sort((a: FSMessage.KeyHistoryEntry, b: FSMessage.KeyHistoryEntry) => ((a.getVersion() || 0) - (b.getVersion() || 0)));
        const permission_set = PermissionSet.init(_permissions);
        const full_permission_set = (await PermissionSet.unwrapKeyHistory(sorted_key_history, role, permission_set, current_account)).permision_set;
        const new_default_permissions = PermissionSet.updateDefaultPermissionSets(full_permission_set, default_permissions);
        // NOTE: Set new permissions in local state and store
        setPermissions(full_permission_set);
        dispatch(driveActions.setDefaultPermissions(new_default_permissions));
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_key_history }, message);
      }
    };
    handleSendRequest(drive_request, callback, DriveErrorMessages.error_fetching_key_history);
  }

  // Description: Sends Request and handles error when the request object is null, pass it custom stack message
  function handleSendRequest<T>(request: DriveRequest | null, callback: DriveCallbackAsyncFunction<T>, stack_error: string = DriveErrorMessages.error_sending_request) {
    if (!!request) {
      request.setCallback(callback);
      sendRequest(request);
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, { stack_error, stack_message: DriveErrorMessages.error_sending_request }, null);
    }
  }


  // Description: Handle error message and send error to store
  function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, fsmessage?: FSMessage | null,
    display_type: MessageDisplayType = MessageHandlerDisplayType.logger) {
    const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
    const stack = new ErrorStackItem("[useGetPermissions Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
    dispatch(uiActions.handleRequestErrors(new Message(message, display_type), stack));
    setErrorFetchingPermissions(true);
  }

  // Description: Call to get collection level permissions
  const getPermissions = useCallback((collection_id: string, default_permissions: PermissionSetType[]) => {
    getCollectionPermissions(collection_id, default_permissions);
  }, []);

  // Description: Reset permissions
  const destroyPermissions = useCallback(() => {
    setPermissions(undefined);
    setErrorFetchingPermissions(false);
  }, []);

  // Description: Fetch Default permissions
  // Pass a collection Id to fetch specific ones
  const getDefaultPermissions = useCallback((collection_id?: string) => {
    getDefaultCollectionPermissions(collection_id);
  }, []);

  return {
    permissions,
    getDefaultPermissions,
    getPermissions,
    destroyPermissions,
    error_fetching_permissions
  };
}
