import { useCallback, useState } from "react";
import { ThreadMessage, ClientThreadMessage } from "@preveil-api";
import { Account, useGetEmailBodiesMutation, GetEmailBodiesApiResponse, FileSizeLimits, getIDChunks, MailThreadMessage } from "src/common";
import _ from "lodash";

export function useGetMessages(current_account: Account) {
  const [message_results, setMessageResults] = useState<ThreadMessage[]>();
  const [errors, setErrors] = useState<unknown>();
  const [getThread, { isLoading }] = useGetEmailBodiesMutation();

  // ------------------------------------------------------------------------------------------------------
  // Description: Crypto / Local - initialize get thread message's contents
  // ------------------------------------------------------------------------------------------------------
  function handleCryptoGetMessages(_block_ids: string[], inlines_only: boolean) {
    const chunks: string[][] = getIDChunks(_block_ids, FileSizeLimits.MAIL_MAX_BLOCKS_PER_REQUEST);
    Promise.all(_.map(chunks, (chunk: string[]) => {
      return handleCryptoGetMessage(chunk, inlines_only);
    }))
      .then((responses: ThreadMessage[][]) => {
        setMessageResults(_.flatten(responses));
      })
      .catch((error: unknown) => {
        console.error(error);
        setErrors(error);
      });
  }

  // Description: reach out to crypto to get messages
  async function handleCryptoGetMessage(uniqueIds: string[], inlinesOnly: boolean): Promise<ThreadMessage[]> {
    return await getThread({
      userId: current_account.user_id,
      uniqueIds,
      inlinesOnly // Note: inlinesOnly flags set to false for Drafts
    }).unwrap()
      .then((response: GetEmailBodiesApiResponse) => {
        const message_arr = _.values(response.messages);
        return (!!message_arr && message_arr.length > 0) ?
          _.map(message_arr, (message: ClientThreadMessage) => {
            return new MailThreadMessage(null, message).ThreadMessage;
          }) : [];
      });
  }

  // Description: On webData receive send the blocks up to storage
  const getAppMessages = useCallback((unique_ids: string[] | undefined, inlines_only: boolean = false) => {
    !!unique_ids && handleCryptoGetMessages(unique_ids, inlines_only);
  }, []);

  return {
    data: message_results,
    isLoading,
    errors,
    getAppMessages
  };
}