/*
 *   File:           rootSaga.ts
 *   Description:    this is where the Sagas comes together
 *   Author:         PreVeil, LLC
 */
import { all, fork } from "redux-saga/effects";

/// ADD ALL Local Sagas:
import { wsSaga } from "../websocket/saga";
import { appSaga } from "../app/saga";
import { accountSaga } from "../account/saga";
import { mailSaga } from "../mail/saga";
import { adminSaga } from "../admin/saga";

export function* rootSaga() {
  yield all([
    fork(wsSaga),
    fork(appSaga),
    fork(accountSaga),
    fork(mailSaga),
    fork(adminSaga)
    // more sagas...
  ]);
}
