import { all, fork, call, put, takeEvery, select } from "redux-saga/effects";
import { IFetchResponse, MailboxItem, SetFlagsParams, ThreadMessage } from "@preveil-api";
import { MailActionTypes, mailActions } from "./slice";
import {
  Account, CryptoAPI, AppConfiguration, CollectionAPI, GlobalErrorMessages, MailErrorMessages, Message, MessageHandlerDisplayType, DefaultMailboxes,
  SetEmailFlagApiArg, UpdateFlagsApiArg, GlobalConstants, getVirtualMailboxId, MailFlags, MailSuccessMessages, getMailboxCollectionIdByRole, MoveMailApiArg
} from "src/common";
import { uiActions } from "../";
import _ from "lodash";
const isWeb = AppConfiguration.buildForWeb();

// -----------------------------------------------------------------------------------
// Description: Get Mail threads from Crypto or collection depending on build mode
// -----------------------------------------------------------------------------------
function* fetchMailThreadsRequest(action: ReturnType<typeof mailActions.getMailThreads>): Generator {
  const current_account = (yield select((state) => state.account.current_account)) as Account;
  if (!!current_account) {
    const response = isWeb ? (yield call(CollectionAPI.getMailThreads, current_account, action.payload)) as IFetchResponse :
      (yield call(CryptoAPI.getPaginatedThreadsRemote, action.payload)) as IFetchResponse;
    if (!response.isError) { // Success Block:
      yield put(mailActions.setThreadsSuccess(response.data));
      !!action.payload.success_message &&
        (yield put(uiActions.handleSetMessage(new Message(action.payload.success_message))));
    } else {
      if (!isWeb) {
        // NOTE: Log the switch between call types in app and try backup Crypto call if unsuccessful
        yield put(uiActions.handleRequestErrors(new Message(MailErrorMessages.error_getting_mail_threads, MessageHandlerDisplayType.logger),
          { stack_message: "Error getting fetch using default getThreads call, switching to backup", response }));
        yield put(mailActions.getClientMailThreads(action.payload));
      } else {
        yield put(mailActions.setThreadsError());
        yield put(uiActions.handleRequestErrors(new Message(MailErrorMessages.error_getting_mail_threads, MessageHandlerDisplayType.toastr), response));
      }
    }
  } else {
    yield put(uiActions.handleRequestErrors(new Message(GlobalErrorMessages.no_current_account_set, MessageHandlerDisplayType.logger)));
  }
}

function* watchFetchMailThreadsRequestRequest() {
  yield takeEvery(MailActionTypes.FETCH_MAIL_THREADS, fetchMailThreadsRequest);
}

// ---------------------------------------------------------------------------------------------------------------------
// Description: Get Mail threads from Crypto client mailboxes (unread, starred, outbox) or backup call for others
// ---------------------------------------------------------------------------------------------------------------------
function* fetchClientMailThreadsRequest(action: ReturnType<typeof mailActions.getClientMailThreads>): Generator {
  // NOTE:  Try backup Crypto call if unsuccessful
  const response = (yield call(CryptoAPI.getPaginatedThreads, action.payload)) as IFetchResponse;
  if (!response.isError) {
    yield put(mailActions.setThreadsSuccess(response.data));
    !!action.payload.success_message &&
      (yield put(uiActions.handleSetMessage(new Message(action.payload.success_message))));
  } else {
    yield put(mailActions.setThreadsError());
    yield put(uiActions.handleRequestErrors(new Message(MailErrorMessages.error_getting_mail_threads, MessageHandlerDisplayType.toastr), response));
  }
}

function* watchFetchClientMailThreadsRequestRequest() {
  yield takeEvery(MailActionTypes.FETCH_CLIENT_MAIL_THREADS, fetchClientMailThreadsRequest);
}

// ---------------------------------------------------------------------------------------------------------------------
// Description: Refresh current mailbox from state
// ---------------------------------------------------------------------------------------------------------------------
function* fetchCurrentMailThreads(action: ReturnType<typeof mailActions.updateFlags>): Generator {
  const current_account = (yield select((state) => state.account.current_account)) as Account;
  const mailbox_id = (yield select((state) => state.mail.current_mailbox.mailbox_id)) as string;
  if (!!current_account && !!mailbox_id) {
    const virtual_mailbox_id = getVirtualMailboxId(mailbox_id);
    const params = {
      user_id: current_account.user_id,
      collection_id: current_account.mail_cid,
      mailbox_id: !!virtual_mailbox_id ? virtual_mailbox_id : mailbox_id,
      limit: GlobalConstants.LIMIT_NUMBER_THREAD_TO_FETCH,
      offset: action.payload.offset || 0,
      success_message: action.payload.success_message
    };
    yield put(!!virtual_mailbox_id ? mailActions.getClientMailThreads(params) : mailActions.getMailThreads(params));
  } else {
    yield put(uiActions.handleRequestErrors(new Message(GlobalErrorMessages.no_current_account_set, MessageHandlerDisplayType.logger)));
  }
}

function* watchFetchCurrentMailThreadsRequest() {
  yield takeEvery(MailActionTypes.REFRESH_MAIL_THREADS, fetchCurrentMailThreads);
}

// -----------------------------------------------------------------------------------
// Description: Get Mail Thread from Collection
// -----------------------------------------------------------------------------------
// Separate the web and client call in this sub saga
function* fetchCurrentThreadMainRequest(action: ReturnType<typeof mailActions.getCurrentThread>): Generator {
  const current_account = (yield select((state) => state.account.current_account)) as Account;
  if (!!current_account) {
    const response = (yield call(CollectionAPI.getMailThread, current_account, action.payload, isWeb, action.payload.mailbox_id)) as IFetchResponse;
    if (!response.isError) {
      yield put(mailActions.setCurrentThreadSuccess(response.data));
    } else {
      yield put(mailActions.resetCurrentThread());
      yield put(uiActions.handleRequestErrors(new Message(MailErrorMessages.error_getting_mail_threads, MessageHandlerDisplayType.toastr), response));
    }
  } else {
    yield put(uiActions.handleRequestErrors(new Message(GlobalErrorMessages.no_current_account_set, MessageHandlerDisplayType.logger)));
  }
}

function* watchFetchCurrentThreadMainRequest() {
  yield takeEvery(MailActionTypes.FETCH_CURRENT_THREAD, fetchCurrentThreadMainRequest);
}

// -----------------------------------------------------------------------------------------------------
// Description: Set flags for an array of messages/threads Crypto or collection depending on build mode
// NOTES:       Handles both web and crypto by calling getCollectionFlagsParams or getLocalFlagsParams
//              Handles both Add and remove flags: 
//               in crypto, it is handled in the url of the CryptoAPI.updateFlags call (pass in params)
//               in Collection it is handled by updating the params directly to most current flags state
//                  (i.e{ ... flag: ["//Flagged", //Seen] to flag: ["//Seen"] })
// RESULT:       Updates will happen automatically on server change (notify_ws), so only handling errors
// NOTE:         On success update threads in case notify does not work
// -----------------------------------------------------------------------------------------------------
function* fetchSetFlagsRequest(action: ReturnType<typeof mailActions.updateFlags>): Generator {
  const current_account = (yield select((state) => state.account.current_account)) as Account;
  if (!!current_account) {
    const response = isWeb ?
      (yield call(CollectionAPI.updateFlags, current_account, getCollectionFlagsParams(current_account, action.payload))) as IFetchResponse :
      (yield call(CryptoAPI.updateFlags, current_account.user_id, getLocalFlagsParams(action.payload), action.payload.remove)) as IFetchResponse;
    if (!response.isError) { // Success: 
      yield put(mailActions.refreshCurrentMailThreads({ offset: action.payload.offset, success_message: action.payload.success_message }));
      yield put(mailActions.setRefreshCounts(true));
    } else {
      yield put(mailActions.setNewMail(true));
      yield put(uiActions.handleRequestErrors(new Message(MailErrorMessages.error_setting_mail_flag, MessageHandlerDisplayType.toastr), response));
    }
  } else {
    yield put(uiActions.handleRequestErrors(new Message(GlobalErrorMessages.no_current_account_set, MessageHandlerDisplayType.logger)));
  }
}

function* watchFetchSetFlagsRequest() {
  yield takeEvery(MailActionTypes.SET_FLAGS, fetchSetFlagsRequest);
}

// -----------------------------------------------------------------------------------
// Description: Delete Mail from crypto or Collection
// NOTE: Separated the sagas into web and crypto calls
// -----------------------------------------------------------------------------------
// Crypto / Local saga:
function* fetchDeleteThreadsRequest(action: ReturnType<typeof mailActions.deleteMail>): Generator {
  const current_account = (yield select((state) => state.account.current_account)) as Account;
  const messages = action.payload.messages;
  const offset = action.payload.offset || 0;
  if (!!current_account) {
    const response = (yield call(CryptoAPI.deleteMailThreads, {
      userId: current_account.user_id,
      emails: _.map(messages, (message: ThreadMessage) => (message.unique_id))
    })) as IFetchResponse;

    if (!response.isError) {
      yield put(mailActions.refreshCurrentMailThreads({ offset, success_message: MailSuccessMessages.messages_deleted_success }));
    } else {
      yield put(mailActions.setDeleteMailError());
      yield put(uiActions.handleRequestErrors(new Message(MailErrorMessages.error_deleting_messages, MessageHandlerDisplayType.toastr), response));
    }
  } else {
    yield put(uiActions.handleRequestErrors(new Message(GlobalErrorMessages.no_current_account_set, MessageHandlerDisplayType.logger)));
  }
}

// Web saga:
function* fetchWebDeleteThreadsRequest(action: ReturnType<typeof mailActions.deleteMail>): Generator {
  const current_account = (yield select((state) => state.account.current_account)) as Account;
  const default_mailboxes = (yield select((state) => state.mail.default_mailboxes)) as MailboxItem[];
  const trash_mailbox_id = !!default_mailboxes ? getMailboxCollectionIdByRole(DefaultMailboxes.deleted, default_mailboxes) : null;
  const draft_mailbox_id = !!default_mailboxes ? getMailboxCollectionIdByRole(DefaultMailboxes.drafts, default_mailboxes) : null;
  const messages = action.payload.messages;
  const offset = action.payload.offset || 0;
  const mailbox_id = action.payload.mailbox_id;
  if (!!current_account && !!trash_mailbox_id) {
    if ([trash_mailbox_id, draft_mailbox_id].includes(mailbox_id)) {
      const deletions = messages.map(m => ({
        collectionId: current_account.mail_cid,
        mbid: mailbox_id,
        id: m.unique_id
      }));
      const response = (yield call(CollectionAPI.deleteMailByColIdMbidMessId, current_account, deletions)) as IFetchResponse;
      if (!response.isError) {
        yield all([
          put(mailActions.decrementDraftNotifications(deletions.length)),
          put(mailActions.refreshCurrentMailThreads({ offset: action.payload.offset, success_message: MailSuccessMessages.messages_deleted_success }))
        ]);
      } else {
        yield put(uiActions.handleRequestErrors(new Message(MailErrorMessages.error_deleting_messages, MessageHandlerDisplayType.toastr), response));
      }
    } else {
      const response = (yield call(CollectionAPI.copyMailThreads, current_account, {
        collectionId: current_account.mail_cid,
        mbid: trash_mailbox_id,
        source_mbid: mailbox_id,
        uids: _.map(messages, (message: ThreadMessage) => (message.uid))
      })) as IFetchResponse;
      if (!response.isError) { // On success update flags
        yield put(mailActions.updateFlags({
          messages,
          flag: MailFlags.DELETED_FLAG,
          remove: false,
          offset,
          success_message: MailSuccessMessages.messages_deleted_success
        }));
      } else {
        yield put(mailActions.setDeleteMailError());
        yield put(uiActions.handleRequestErrors(new Message(MailErrorMessages.error_deleting_messages, MessageHandlerDisplayType.toastr), response));
      }
    }
  } else {
    yield put(uiActions.handleRequestErrors(new Message(GlobalErrorMessages.no_current_account_set, MessageHandlerDisplayType.logger)));
  }
}

// Separate the web and client call in this sub saga
function* fetchDeleteThreadsMainRequest(action: ReturnType<typeof mailActions.deleteMail>): Generator {
  const params = {
    type: MailActionTypes.DELETE_MAIL_THREADS,
    payload: action.payload
  };
  yield isWeb ? fetchWebDeleteThreadsRequest(params) : fetchDeleteThreadsRequest(params);
}

function* watchFetchDeleteThreadsRequestRequest() {
  yield takeEvery(MailActionTypes.DELETE_MAIL_THREADS, fetchDeleteThreadsMainRequest);
}



// -----------------------------------------------------------------------------------------------------
// Description: Move Mail from source mailbox to another
// -----------------------------------------------------------------------------------------------------
function* fetchAppMoveMailRequest(action: ReturnType<typeof mailActions.moveMail>): Generator {
  const current_account = (yield select((state) => state.account.current_account)) as Account;
  if (!!current_account && action.payload.length > 0) {
    const response = (yield call(CryptoAPI.moveMailThreads, {
      userId: current_account.user_id,
      email_ids: _.map(action.payload, (message: MoveMailApiArg) => message.tid),
      dest_mailbox_id: action.payload[0].dest_mailbox,
      src_mailbox_id: action.payload[0].source_mailbox,
      trash_source: true
    })) as IFetchResponse;
    if (!response.isError) { // Success:
      yield put(mailActions.refreshCurrentMailThreads({ offset: 0, success_message: MailSuccessMessages.messages_moved_success }));
    } else {
      yield put(uiActions.handleRequestErrors(new Message(MailErrorMessages.error_moving_messages, MessageHandlerDisplayType.toastr), response));
    };
  } else {
    yield put(uiActions.handleRequestErrors(new Message(GlobalErrorMessages.no_current_account_set, MessageHandlerDisplayType.logger)));
  }
}

// Web saga:
function* fetchWebMoveMailRequest(action: ReturnType<typeof mailActions.moveMail>): Generator {
  const current_account = (yield select((state) => state.account.current_account)) as Account;
  if (!!current_account) {
    const response = (yield call(CollectionAPI.moveMail, current_account, action.payload)) as IFetchResponse;
    if (!response.isError) { // Success:
      yield put(mailActions.refreshCurrentMailThreads({ offset: 0, success_message: MailSuccessMessages.messages_moved_success }));
    } else {
      yield put(uiActions.handleRequestErrors(new Message(MailErrorMessages.error_moving_messages, MessageHandlerDisplayType.toastr), response));
    };
  } else {
    yield put(uiActions.handleRequestErrors(new Message(GlobalErrorMessages.no_current_account_set, MessageHandlerDisplayType.logger)));
  }
}

// Separate the web and client call in this sub saga
function* fetchMoveMailRequest(action: ReturnType<typeof mailActions.moveMail>): Generator {
  const params = {
    type: MailActionTypes.MOVE_MAIL,
    payload: action.payload
  };
  yield isWeb ? fetchWebMoveMailRequest(params) : fetchAppMoveMailRequest(params);
}

function* watchFetchMoveMailRequestRequest() {
  yield takeEvery(MailActionTypes.MOVE_MAIL, fetchMoveMailRequest);
}


// ----------------------------------------------------------------
// We can also use `fork()` here to split our saga into multiple watchers.
export function* mailSaga() {
  yield all([
    fork(watchFetchMailThreadsRequestRequest),
    fork(watchFetchClientMailThreadsRequestRequest),
    fork(watchFetchCurrentMailThreadsRequest),
    fork(watchFetchCurrentThreadMainRequest),
    fork(watchFetchSetFlagsRequest),
    fork(watchFetchDeleteThreadsRequestRequest),
    fork(watchFetchMoveMailRequestRequest)
  ]);
}

// Exporting Sagas for testing
export { fetchMailThreadsRequest };

// -----------------------------------------------------------------------------------------------------------------------------
// Mail Saga Utilities
// -----------------------------------------------------------------------------------------------------------------------------
// Description: Account Get Correct step depending on server error code - returns emails: unique_ids[]
export function getLocalFlagsParams(params: SetFlagsParams): SetEmailFlagApiArg {
  return {
    emails: _.map(params.messages, (message: ThreadMessage) => (message.unique_id)),
    flag: params.flag
  };
}

// Descroption: Bulk updates flags associated with a set of messages. See below for notes on this method.
export function getCollectionFlagsParams(current_account: Account, params: SetFlagsParams): UpdateFlagsApiArg[] {
  const _update_flags_arg: UpdateFlagsApiArg[] = _.map(params.messages, (message: ThreadMessage) => {
    const flags = !!params.remove ? _.filter(message.flags, (flag: string) => (params.flag !== flag)) :
      (message.flags.slice()).concat([params.flag]);
    return {
      collectionId: current_account.mail_cid,
      mbid: message.mailbox_id,
      updates: [{
        id: message.unique_id,
        last_version: message.version || "",
        flags: _.uniq(flags) // NOTE: Remove any duplicated flags
      }]
    };
  });
  // Group by collectionId and  mailbox_id and merge updates into array
  const update_flags_arg: UpdateFlagsApiArg[] = [];
  const grouped_args = _.groupBy(_update_flags_arg, item => `"${item.mbid}+${item.collectionId}"`);
  _.forEach(grouped_args, (update_flags: UpdateFlagsApiArg[]) => {
    update_flags_arg.push({
      collectionId: update_flags[0].collectionId,
      mbid: update_flags[0].mbid,
      updates: _.flatten(_.map(update_flags, (flag: UpdateFlagsApiArg) => flag.updates))
    });
  });
  return update_flags_arg;
}
