import React from "react";
import { UserProfile } from "@preveil-api";
import { Button, Col, Row } from "react-bootstrap";
import _ from "lodash";

type AllProps = {
  members: Array<{ user: UserProfile; new: boolean }>;
  past_members: UserProfile[];
};

function ApproversComponent(props: AllProps) {
  const { members, past_members } = props;

  function table(user: UserProfile, i: number, past: boolean, new_member: boolean) {
    return (
      <Row className={`approver-row ${past && "removed-row"} ${new_member && "added-row" }`} key={i}>
        <Col sm={4}>{user.name}</Col>
        <Col>{user.address}</Col>
        {(past || new_member) && <Col>
          <Button
            size="sm"
            variant="transparent"
            disabled
            className="notification-count transparent float-end"
          >
            { new_member ? "Added" : "Removed"}
          </Button>
        </Col>}
      </Row>
    );
  }

  return (
    <div className="settings-table mb-2">
      {_.map(members, (member: { user: UserProfile; new: boolean }, i: number) => table(member.user, i, false, member.new))}
      {!!past_members && past_members.length > 0 && (
        <>
          <Row className="table-header rounded-0">
            <Col as="p" className="m-0">
              Past Members (removed)
            </Col>
          </Row>
          {_.map(past_members, (user: UserProfile, i: number) => table(user, i, true, false))}
        </>
      )}
    </div>
  );
}

export default React.memo(ApproversComponent);
