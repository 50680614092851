import React, { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Account, AccountErrorMessages, AppConfiguration, LocalAccountStorage, Message, MessageHandlerDisplayType, PrivateRoutes, useAppDispatch, useAppSelector } from "src/common";
import { useIsRecoverySetUpQuery } from "src/common/api/keystorage/keystorage-rtk-api";
import { Icon } from "src/components";
import { accountActions, uiActions } from "src/store";
import { RootState } from "src/store/configureStore";

type AllProps = {
  active: boolean;
  active_path?: string;
  current_account: Account;
};

// Description: Settings Dropdown
function SettingsDropdown(props: AllProps) {
  const { active, active_path, current_account } = props;
  const recovery_code_pending = useAppSelector((state: RootState) => state.settings.recovery_code_pending);
  const recovery_group_pending = useAppSelector((state: RootState) => state.settings.recovery_group_pending);
  const pending_approvals = useAppSelector((state: RootState) => state.settings.pending_approvals);
  const organization = useAppSelector((state: RootState) => state.account.current_account)?.org_info;
  const auth_token = useAppSelector((state: RootState) => state.account.auth_token);
  const express_recovery_not_setup = useAppSelector((state: RootState) => state.account.express_recovery_not_setup);
  const { data, error } = useIsRecoverySetUpQuery({ user_id: current_account.user_id, auth_token: auth_token || "" }, { skip: !auth_token });
  const dispatch = useAppDispatch();

  // Description: Checks to see if express recovery is set up in the backend - if not set up, a notification dot is displayed
  // This query is only called when an auth token exists (only for express users)
  useEffect(() => {
    if (!!data) {
      if (!data.server_shard_exists) {
        LocalAccountStorage.setTemporaryCookies("express_recovery_not_setup", "true");
        dispatch(accountActions.setExpressRecoveryNotSetup("true"));
      }
    }
    !!error && dispatch(uiActions.handleRequestErrors(new Message(AccountErrorMessages.error_fetching_express_recovery_notification, MessageHandlerDisplayType.logger), error));
  }, [error, data]);

  return (
    // active
    <Dropdown className={`nav-item${active ? " active" : ""} ${!!express_recovery_not_setup ? "dot" : ""}`}>
      <Dropdown.Toggle as="a" title="My Account Settings">
        <Icon className="ficon-settings show-desktop" />
        <span className="hide-desktop">Settings</span>
      </Dropdown.Toggle>
      <Dropdown.Menu align="end">
        <Dropdown.Item
          as={Link}
          title="User Profile"
          className={`${active_path === PrivateRoutes.profile_route ? " active" : ""}`}
          to={`/${PrivateRoutes.settings_route}/${PrivateRoutes.profile_route}`}>
          Profile
        </Dropdown.Item>
        {!!AppConfiguration.buildForExpress() &&
          <>
            <Dropdown.Item
              as={Link}
              title="Account Recovery"
              className={`${active_path === PrivateRoutes.password_recovery_route ? " active" : ""} ${!!express_recovery_not_setup ? "dot" : ""}`}
              to={`/${PrivateRoutes.settings_route}/${PrivateRoutes.password_recovery_route}`}>
              Account Recovery
            </Dropdown.Item>
            <Dropdown.Item
              as={Link}
              title="Account Recovery"
              className={`${active_path === PrivateRoutes.change_password_route ? " active" : ""}`}
              to={`/${PrivateRoutes.settings_route}/${PrivateRoutes.change_password_route}`}>
              Change Password
            </Dropdown.Item>
          </>
        }
        {/* "dot" for notifications */}
        {!!AppConfiguration.buildForApp() && (
          <>
            <Dropdown.Item
              as={Link}
              className={`${active_path === PrivateRoutes.approvals_route ? " active" : ""}`}
              title="Approvals"
              to={`/${PrivateRoutes.settings_route}/${PrivateRoutes.approvals_route}`}>
              Approvals
              {pending_approvals > 0 && <span className="notification-count blue float-end ms-2">{pending_approvals}</span>}
            </Dropdown.Item>
            <Dropdown.Item
              as={Link}
              className={`${active_path === PrivateRoutes.activity_route ? " active" : ""}`}
              title="Activity Logs"
              to={`/${PrivateRoutes.settings_route}/${PrivateRoutes.activity_route}`}>
              Activity Logs
            </Dropdown.Item>
            {
              (!organization || Account.isShowMUAPropt(organization)) &&
              <Dropdown.Item
                as={Link}
                className={`${active_path === PrivateRoutes.install_profile_route ? " active" : ""}`}
                title="Add to Mail Client"
                to={`/${PrivateRoutes.settings_route}/${PrivateRoutes.install_profile_route}`}>
                Add to Mail Client
              </Dropdown.Item>
            }
            <Dropdown.Divider />
            <Dropdown.Item
              as={Link}
              className={`${active_path === PrivateRoutes.device_management_route ? " active" : ""}`}
              title="Device Management"
              to={`/${PrivateRoutes.settings_route}/${PrivateRoutes.device_management_route}`}>
              Device Management
            </Dropdown.Item>
            <Dropdown.Item
              as={Link}
              className={`${active_path === PrivateRoutes.add_device_route ? " active" : ""}`}
              title="Add Device"
              to={`/${PrivateRoutes.settings_route}/${PrivateRoutes.add_device_route}`}>
              Add Device
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
              as={Link}
              className={`${active_path === PrivateRoutes.recovery_route ? " active" : ""}`}
              title="Recovery Group"
              to={`/${PrivateRoutes.settings_route}/${PrivateRoutes.recovery_route}`}>
              Recovery Group
              {!!recovery_group_pending && !organization && <span className="notification-count red float-end ms-2">!</span>}
            </Dropdown.Item>
            <Dropdown.Item
              as={Link}
              title="Account Recovery"
              className={`${active_path === PrivateRoutes.account_backup_route ? " active" : ""}`}
              to={`/${PrivateRoutes.settings_route}/${PrivateRoutes.account_backup_route}`}>
              Account Recovery
              {!!recovery_code_pending && <span className="notification-count red float-end ms-2">!</span>}
            </Dropdown.Item>
            <Dropdown.Item
              as={Link}
              className={`${active_path === PrivateRoutes.approve_recovery_route ? " active" : ""}`}
              title="Recovery Approval"
              to={`/${PrivateRoutes.settings_route}/${PrivateRoutes.approve_recovery_route}`}>
              Recovery Approval
            </Dropdown.Item>{" "}
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default React.memo(SettingsDropdown);
