import { JSONAttachment, CryptoAttachment, ThreadAttachment } from "@preveil-api";
import { Helpers, UUID, ContentLoadStatus, MIME_ICON_MAPPING, AttachmentContentDisposition } from "src/common";
import { SymmKey } from "src/common/keys";
import { Fileb64ToBlob } from "./mail-helpers.class";
import { saveAs } from "file-saver";
import { filesize } from "filesize";

export interface AttachmentMetadata extends JSONAttachment {
  decrypted_name: string;
  decryption_key: SymmKey;
}

export class Attachment {
  public content_status: ContentLoadStatus;
  _uuid: string;
  _size: number;

  constructor(
    public filename: string,
    public content_type: string,
    public blob: Blob | null,
    size: number,
    public content_disposition: string,
    public content_id: string | null, // cross-referencing inline attachments in body
    public block_ids: string[],
    public server_metadata?: AttachmentMetadata
  ) {
    this.content_status = !!this.blob ? ContentLoadStatus.LOADED : ContentLoadStatus.NONE;
    this._uuid = new UUID().String(); // used when sending to crypto for cross-referecing metadata to files in http body.
    this._size = size;
  }

  public get display_size() {
    return filesize(this.size);
  }

  // TODO: storing ArrayBuffer and having `Blob` as a computed prop. is more efficient.
  public async getContentBuffer(): Promise<Uint8Array> {
    if (this.blob === null) {
      throw Error("Content must be loaded!");
    }

    return await new Promise<Uint8Array>((resolve) => {
      const reader = new FileReader();
      !!this.blob && reader.readAsArrayBuffer(this.blob);
      reader.onloadend = (e) => {
        resolve(new Uint8Array((e.target as any).result));
      };
    });
  }

  public get size(): number {
    if (this.isContentLoaded() && !!this.blob) {
      return this.blob.size;
    }
    return this._size;
  }

  public get uuid() {
    return this._uuid;
  }

  // used as a reference to get content from crypto => `",".join(block_ids)`.
  public get content_reference_id(): string {
    return this.block_ids.join(",");
  }

  public get metadata() {
    return {
      filename: this.filename,
      content_type: this.content_type,
      content_disposition: this.content_disposition,
      size: this.size,
      content_id: this.content_id,
      blob_reference: this.uuid,
      content_reference_id: this.content_reference_id
    };
  }

  // Description: returns a CryptoAttachment object
  public get threadAttachment(): ThreadAttachment {
    return {
      blob: this.blob,
      content_id: this.content_id,
      content_disposition: this.content_disposition,
      content_reference_id: this.content_reference_id,
      content: null,
      content_type: this.content_type,
      filename: this.filename,
      size: Number(this._size),
      server_metadata: this.server_metadata,
      block_ids: this.block_ids,
      is_inline: this.is_inline
    };
  }

  // NOTE: only support inline images at the time, so will only return true if both image and inline
  public get is_inline() {
    return MIME_ICON_MAPPING.image.test(this.content_type) && this.content_disposition === AttachmentContentDisposition.inline;
  }

  public isContentLoaded() {
    return this.content_status === ContentLoadStatus.LOADED;
  }

  public isContentFailed() {
    return this.content_status === ContentLoadStatus.FAILED;
  }

  public isContentLoading() {
    return this.content_status === ContentLoadStatus.LOADING;
  }

  public loadContentFromBlob(blob: Blob) {
    this.blob = blob;
    this.content_status = ContentLoadStatus.LOADED;
  }

  public saveToComputer() {
    if (!!this.blob) {
      saveAs(this.blob, this.filename);
    } else {
      throw new Error("The file being saved to the computer is empty or it was not found");
    }
  }

  // Static methods:
  public static fromFile(file: File, is_inline: boolean = false, content_id: string | null = null) {
    const c_d = is_inline ? AttachmentContentDisposition.inline : AttachmentContentDisposition.attachment;
    return new Attachment(file.name, file.type, file, file.size, c_d, content_id, []);
  }

  public static fromCrypto(json_att: CryptoAttachment) {
    let blob = null;
    if (json_att.content) {
      blob = Fileb64ToBlob(json_att.content, json_att.content_type);
    }
    return new Attachment(
      json_att.filename,
      json_att.content_type,
      blob,
      json_att.size,
      json_att.content_disposition || "",
      json_att.content_id || "",
      json_att.content_reference_id.split(",")
    );
  }

  public static fromThreadAttachment(thread_attachment: ThreadAttachment) {
    return new Attachment(
      thread_attachment.filename,
      thread_attachment.content_type,
      thread_attachment.blob,
      thread_attachment.size,
      thread_attachment.content_disposition || "",
      thread_attachment.content_id || "",
      thread_attachment.content_reference_id.split(",")
    );
  }

  public static fromJSONAttachment(attachment: AttachmentMetadata, decrypted_content?: Uint8Array): Attachment {
    return new Attachment(
      attachment.decrypted_name,
      attachment.metadata.content_type,
      decrypted_content ? Fileb64ToBlob(Helpers.b64Encode(decrypted_content), attachment.metadata.content_type) : null,
      attachment.size,
      attachment.metadata.content_disposition,
      attachment.metadata.content_id,
      attachment.block_ids || (!!attachment.blocks ? attachment.blocks.map(b => b.id) : []),
      attachment
    );
  }

  // Description: See if attachment block belongs to thread_attachment
  static isThreadAttachment(block_id: string, attachment: ThreadAttachment): boolean {
    const arr = attachment.content_reference_id.split(",");
    return arr.includes(block_id);
  }

  // Description: makes and returns a string that shows names of the non-inline attachments
  static getAttachmentsFootprint(attachments: ThreadAttachment[]) {
    let footprint = "";
    const non_inline_attachments = attachments.filter(att => !att.is_inline);
    if (non_inline_attachments.length > 0) {
      footprint = "<p>";
      non_inline_attachments.forEach(attachment => {
        footprint += `[${attachment.filename}]<br>`;
      });
      footprint += "</p>";
    }
    return footprint;
  }
}
