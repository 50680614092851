import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { SelectedEmailGroup } from "@preveil-api";
import { RegexHelper, isSameUser } from "src/common";
import { FormField } from "src/components";
import { Formik } from "formik";
import * as yup from "yup";

type AllProps = {
  is_edit?: boolean;
  selected_group?: SelectedEmailGroup
  group_alias: string;
  setGroupAlias: React.Dispatch<React.SetStateAction<string>>;
  setInvalidAlias: React.Dispatch<React.SetStateAction<boolean>>;
  aliases: string[];
};

function EmailGroupFormComponent(props: AllProps) {
  const { is_edit, selected_group, group_alias, setGroupAlias, setInvalidAlias, aliases } = props;
  const error_conflict_message = "The group email is already taken.";
  const invalid_email_message = "The group email address is not in the correct format, e.g. john@gmail.com, outlook.com";
  const [existing_alias, setExistingAlias] = useState<boolean>(false);
  const [invalid_email, setInvalidEmail] = useState<boolean>(false);
  const schema = yup.object().shape({
    alias: yup.string()
      .test("group-email", "The group email address is required.", (value) => {
        !value && setGroupAlias("");
        return !!value;  
      })
      .test("group-email", error_conflict_message, (value) => {
        if (value) {
          const not_existing = aliases.filter((address) => isSameUser(address, value)).length === 0;
          setExistingAlias(!not_existing);
          return not_existing;
        }
        return !!value;
      })
      .test("group-email", invalid_email_message, (value) => {
        if (value) {
          const test = RegexHelper.testEmailAddress(value);
          setInvalidEmail(!test);
          return test;
        }
        return !!value;
      })
  });

  useEffect(() => {
    setInvalidAlias(existing_alias || invalid_email);
  }, [existing_alias, invalid_email]);

  // Description: Handle form submit
  function handleSubmit(values: { alias: string }) {
    const group_alias = values.alias;
    setGroupAlias(group_alias);
  }

  return (
    <>
      {is_edit ?
        <Formik
          validationSchema={schema}
          onSubmit={handleSubmit}
          onBlur={handleSubmit}
          enableReinitialize={true}
          initialValues={{
            alias: !!selected_group ? selected_group.alias : group_alias
          }}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <Form noValidate className="mb-4" onSubmit={handleSubmit}>
              <FormField
                autoComplete="off"
                autoFocus={true}
                className="mb-4"
                label="Group Email Address"
                type="text"
                errors={errors.alias}
                touched={touched.alias}
                handleBlur={handleSubmit}
                handleChange={handleChange("alias")}
                value={values.alias}
                required={true} />
            </Form>
          )}
        </Formik>
        : <>
          {!!selected_group &&
            <div className="group-alias">
              <span>Group Email Address:</span>
              <p>{selected_group.alias}</p>
            </div>
          }
        </>}
    </>);
}

export default React.memo(EmailGroupFormComponent);
