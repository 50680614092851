/*
*  File:            admin/slice.ts
*  Description:     Holds types and constants for Admin module
*  Author:          PreVeil
*/
import keyMirror from "keymirror";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { action } from "typesafe-actions";
import { MessageItem, JSONOrgInfo, PaginationItem } from "@preveil-api";
import { AccountIdentifiers, InitialPaginationState } from "src/common";

export interface AdminState {
  organization?: JSONOrgInfo; // THIS NEEDS TO BE CLEANED UP - right now dumping CS data here JSONOrgInfo
  logs: any[];
  errors?: MessageItem;
  pagination: PaginationItem;
  users_without_recovery?: number;
}
const initialState: AdminState = {
  logs: [],
  errors: undefined,
  pagination: InitialPaginationState,
};

export const AdminActionTypes = keyMirror({
  GET_ORGANIZATION_INFO: null,
  DESTROY_ADMIN: null
});

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    getOrganizationInfoSuccess: (state, action: PayloadAction<JSONOrgInfo>) => {
      state.organization = action.payload;
    },
    destroyAdmin: () => {
      return initialState;
    },
    resetPagination: (state) => {
      state.pagination = initialState.pagination;
    },
    setPagination: (state, action: PayloadAction<PaginationItem>) => {
      state.pagination = action.payload;
    },
    setUsersWithoutRecovery: (state, action: PayloadAction<number>) => {
      state.users_without_recovery = action.payload;
    }
  }
});

// ----------------------------------------------------------------------
// Saga specific Actions
// ----------------------------------------------------------------------
const getOrganizationInfo = (params: { account_ids: AccountIdentifiers, org_id: string }) => action(AdminActionTypes.GET_ORGANIZATION_INFO, params);
const adminSagaActions = {
  getOrganizationInfo
};

// ----------------------------------------------------------------------
// Global exports
// ----------------------------------------------------------------------
export const adminActions = { ...adminSlice.actions, ...adminSagaActions };
export default adminSlice.reducer;
