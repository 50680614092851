import React, { useState, FocusEvent } from "react";
import { Form, Col, Card, Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { AccountUIActionTypes, AdminUIActionTypes } from "src/common";
import connectionImage from "src/assets/images/connection.svg";

type AllProps = {
  handleActions: ActionHandlerFunction;
}

function DataExportEstablishedConnection({ handleActions }: AllProps) {
  const [ pin, setPin ] = useState<string>("");

  function handleSubmit (e: React.FormEvent<HTMLFormElement>) { 
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      handleActions({ actionType: AccountUIActionTypes.TransferKeyRequest, params: { pin: form.pin.value } });
    }
  }

  function handleCancel() {
    handleActions({ actionType: AdminUIActionTypes.Destroy });
  }
  return (
    <main className="data-export-wait-connection">
      <Card className="pv-card">
        <Card.Img variant="top" src={connectionImage} />
        <Card.Body>
          <header className="wait-connection-header">
            <i className="connected"></i>
            <h4>Connection Active</h4>
          </header>
          <h4>Enter the 8-digit code that you were provided</h4>
          <Form noValidate onSubmit={handleSubmit} >
            <Form.Group as={Col} xs="8" controlId="validationConnecting" className="mb-3">
              <Form.Label visuallyHidden>Device Code</Form.Label>
              <Form.Control required
                name="pin"
                autoComplete="off"
                autoFocus={true}
                placeholder="Enter 8-digit code"
                value={pin}
                onChange={(e: FocusEvent<HTMLInputElement>) => setPin(e.currentTarget.value)} />
            </Form.Group>
            <Button variant="primary" type="submit" disabled={pin.length <= 0} className="me-2">Approve</Button>
            <Button variant="outline-primary" onClick={handleCancel}>Cancel</Button>
          </Form>
        </Card.Body>
      </Card>
    </main>
  );
}

export default React.memo(DataExportEstablishedConnection);