import keyMirror from "keymirror";
import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { action } from "typesafe-actions";
import { Action } from "redux";
import { WebsocketCryptoMessage, WebsocketCollectionServerMessage, WebsocketFilesyncMessage } from "@preveil-api";
import { DriveRequest } from "src/common";

export type WebsocketMessageType = WebsocketCryptoMessage | WebsocketCollectionServerMessage | WebsocketFilesyncMessage;
const sliceName = "websocket";
export type MessageType = string | ArrayBufferLike | Blob | ArrayBufferView;
export interface OnConnectParams {
  host: string;
  init?: any;
}

export interface IWebsocketState {
  status: WebsocketStatusType;
  host?: string;
  message?: WebsocketMessageType;
  errors?: any;
  notify?: {
    status: WebsocketStatusType;
    message?: BinaryData;
    errors?: any;
  },
  drive_ws_status: WebsocketStatusType;
  drive_notification_ws_status: WebsocketStatusType;
  drive_upload_ws_status: WebsocketStatusType;
  drive_saga_ws_status: WebsocketStatusType;
  drive_ws_callbacks: DriveRequest[];
  drive_upload_ws_callback: DriveRequest | null;
  fs_notification_ws_status: WebsocketStatusType;
}

// Note merge all action types into 1 object WebsocketActionTypes
const SagasActionTypes = keyMirror({
  WS_CONNECTING: null,
  WS_DISCONNECT: null,
  WS_ON_MESSAGE: null,
  WS_ON_MESSAGE_SUCCESS: null,
  WS_ON_MESSAGE_ERROR: null,
  WS_SEND_MESSAGE: null,
  WS_PING: null,
  WS_SEND_MESSAGE_SUCCESS: null,
  WS_ERROR: null,
  NOTIFY_WS_DISCONNECT: null,
  // Drive Websocket actions:
  INIT_DRIVE_WEBSOCKET: null,
  DRIVE_WS_SEND_REQUEST: null,
  DRIVE_WS_MULTIPLE_CALLBACKS: null
});

const RTKActionTypes = {
  WS_CONNECT: `${sliceName}/wsConnect`,
  WS_DESTROY: `${sliceName}/destroyWebsocket`,
  WS_CONNECTED: `${sliceName}/wsConnected`,
  WS_DISCONNECTED: `${sliceName}/wsDisconnected`,
  WS_ERROR: `${sliceName}/wsOnError`,
  WS_ON_RESET_MESSAGE: `${sliceName}/wsOnResetMessage`,
};

const NotifyActionTypes = {
  NOTIFY_WS_CONNECT: `${sliceName}/wsNotifyConnect`,
  NOTIFY_WS_CONNECTED: `${sliceName}/wsNotifyConnected`,
  NOTIFY_WS_ON_MESSAGE: `${sliceName}/wsNotifyOnMessage`,
  NOTIFY_WS_ON_SEND_MESSAGE: `${sliceName}/wsNotifyOnSendMessage`,
  NOTIFY_WS_ERROR: `${sliceName}/wsNotifyOnError`,
  NOTIFY_WS_DISCONNECTED: `${sliceName}/wsNotifyDisconnected`,
  NOTIFY_WS_DESTROY: `${sliceName}/wsNotifyDestroy`,
};

export const WebsocketStatus = keyMirror({
  connected: null,
  verify_request: null,
  connection_established: null,
  success: null,
  error: null,
  bad_pin: null,
  too_many_attempts: null,
  disconnected: null,
  forbidden: null,
  key_transfer_complete: null,
  not_initialized: null,
  disconnecting: null
});

export type WebsocketStatusType = keyof typeof WebsocketStatus;
const initialState: IWebsocketState = {
  status: WebsocketStatus.disconnected,
  drive_saga_ws_status: WebsocketStatus.not_initialized,
  drive_ws_status: WebsocketStatus.not_initialized,
  drive_notification_ws_status: WebsocketStatus.not_initialized,
  drive_upload_ws_status: WebsocketStatus.not_initialized,
  drive_ws_callbacks: [],
  drive_upload_ws_callback: null,
  fs_notification_ws_status: WebsocketStatus.not_initialized
};

export const websocketSlice = createSlice({
  name: sliceName,
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    wsConnect: (state, action: PayloadAction<OnConnectParams>) => {
      state.host = action.payload.host;
    },
    wsOnMessageSuccess: (state, action: PayloadAction<WebsocketMessageType>) => {
      state.message = action.payload;
    },
    wsOnMessageError: (state, action: PayloadAction<any>) => { // TO BE TYPED
      return { ...state, status: WebsocketStatus.error, errors: action.payload, message: undefined };
    },
    wsOnResetMessage: (state) => {
      state.message = undefined;
    },
    wsConnected: (state, action: PayloadAction<Event>) => {
      state.status = WebsocketStatus.connection_established;
    },
    wsDisconnected: (state, action: PayloadAction<any>) => {
      state.status = WebsocketStatus.disconnected;
    },
    wsOnError: (state, action: PayloadAction<any>) => {
      return { ...state, status: WebsocketStatus.error, message: undefined, errors: action.payload };
    },
    // --------------------------------------------------------
    // Description: Notify App level Websocket actions
    // --------------------------------------------------------
    wsNotifyConnect: (state) => {
      state.notify = { status: WebsocketStatus.connected };
    },
    wsNotifyConnected: (state, action: PayloadAction<Event>) => {
      state.notify = Object.assign({}, state.notify, { status: WebsocketStatus.connection_established });
    },
    wsNotifyOnMessage: (state, action: PayloadAction<any>) => {
      state.notify = Object.assign({}, state.notify, { message: action.payload.message });
    },
    wsNotifyOnSendMessage: (state, action: PayloadAction<any>) => {
      // state.notify = Object.assign({}, state.notify, { status: WebsocketStatus.success });
    },
    wsNotifyOnError: (state, action: PayloadAction<any>) => {
      state.notify = Object.assign({}, state.notify, { message: undefined, errors: action.payload });
    },
    wsNotifyDisconnected: (state) => {
      state.notify = Object.assign({}, state.notify, { status: WebsocketStatus.disconnected, message: undefined, errors: undefined });
    },
    wsNotifyDestroy: (state) => {
      state.notify = undefined;
    },
    // --------------------------------------------------------
    // Description: Drive Websocket actions
    // --------------------------------------------------------
    wsDriveStatus: (state, action: PayloadAction<WebsocketStatusType>) => {
      state.drive_ws_status = action.payload;
    },
    // For Drive ws notification status
    wsDriveNotificationStatus: (state, action: PayloadAction<WebsocketStatusType>) => {
      state.drive_notification_ws_status = action.payload;
    },
    wsDriveUploadStatus: (state, action: PayloadAction<WebsocketStatusType>) => {
      state.drive_upload_ws_status = action.payload;
    },
    setWsDriveCallbacks: (state, action: PayloadAction<DriveRequest[]>) => {
      state.drive_ws_callbacks = action.payload;
    },
    setWsDriveUploadCallback: (state, action: PayloadAction<DriveRequest | null>) => {
      state.drive_upload_ws_callback = action.payload;
    },
    // Filesync Notification Socket dedicated socket
    wsFSDriveNotificationStatus: (state, action: PayloadAction<WebsocketStatusType>) => {
      state.fs_notification_ws_status = action.payload;
    },
    // Sagas websocket:
    wsSagaDriveStatus: (state, action: PayloadAction<WebsocketStatusType>) => {
      state.drive_saga_ws_status = action.payload;
    },
    // --------------------------------------------------------
    destroyWebsocket: () => {
      return initialState;
    }
  }
});

// Note: Export this in 1 object for Websocket middleware usage
export const WebsocketActionTypes = Object.assign(SagasActionTypes, RTKActionTypes, NotifyActionTypes);
// ----------------------------------------------------------------------
// Saga specific Actions
// Note: These are actions that are watched by sagas for side effects
// ----------------------------------------------------------------------
// Used in saga
export const wsOnMessage = (message: any) => action(SagasActionTypes.WS_ON_MESSAGE, message);
// Additional actions used in middleware:
export const wsConnecting = (host: string) => action(SagasActionTypes.WS_CONNECTING, host);
export const wsDisconnect = () => action(SagasActionTypes.WS_DISCONNECT);
export const wsOnSendMessage = (message: any) => action(SagasActionTypes.WS_SEND_MESSAGE, message);
export const wsNotifyDisconnect = () => action(SagasActionTypes.NOTIFY_WS_DISCONNECT);

// Drive Websocket
const initDriveWebsocket = (init: boolean = true) => action(WebsocketActionTypes.INIT_DRIVE_WEBSOCKET, init);
const wsSendDriveMessage = createAction<DriveRequest>(WebsocketActionTypes.DRIVE_WS_SEND_REQUEST);
const makeMultipleCallbacks = (callbacks: Action[]) => action(WebsocketActionTypes.DRIVE_WS_MULTIPLE_CALLBACKS, callbacks);

const websocketSagaActions = {
  wsConnecting,
  wsDisconnect,
  wsOnMessage,
  wsNotifyDisconnect,
  wsOnSendMessage,
  // Drive Websocket
  initDriveWebsocket,
  wsSendDriveMessage,
  makeMultipleCallbacks
};




// ----------------------------------------------------------------------
// Global exports
// ----------------------------------------------------------------------
export const websocketActions = { ...websocketSlice.actions, ...websocketSagaActions };
export default websocketSlice.reducer;
