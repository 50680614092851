import React from "react";
import { Row, Col, Accordion } from "react-bootstrap";
import { Icon } from "src/components";

function IconsComponent() {
  // Description: PreVeil Icons
  const BasicPreveilIcons = () => {
    return <div className="icomoon_icons">
      <Row className="align-items-center">
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-add-account" />
            <small>pv-icon-add-account</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-add-contact" />
            <small>pv-icon-add-contact</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-add" />
            <small>pv-icon-add</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-android" />
            <small>pv-icon-android</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-arrow-up" />
            <small>pv-icon-arrow-up</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-arrow-right" />
            <small>pv-icon-arrow-right</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-arrow-down" />
            <small>pv-icon-arrow-down</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-arrow-left" />
            <small>pv-icon-arrow-left</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-attach" />
            <small>pv-icon-attach</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-attachment" />
            <small>pv-icon-attachment</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-calendar" />
            <small>pv-icon-calendar</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-carat" />
            <small>pv-icon-carat</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-chevron-up" />
            <small>pv-icon-chevron-up</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-chevron-right" />
            <small>pv-icon-chevron-right</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-chevron-down" />
            <small>pv-icon-chevron-down</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-chevron-left" />
            <small>pv-icon-chevron-left</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-clear" />
            <small>pv-icon-clear</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-close" />
            <small>pv-icon-close</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-cloud" />
            <small>pv-icon-cloud</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-compose" />
            <small>pv-icon-compose</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-contact" />
            <small>pv-icon-contact</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-desktop" />
            <small>pv-icon-desktop</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-document-multiple" />
            <small>pv-icon-document-multiple</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-file-audio" />
            <small>pv-icon-file-audio</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-download" />
            <small>pv-icon-download</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-duplicate" />
            <small>pv-icon-duplicate</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-filter" />
            <small>pv-icon-filter</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-filter-alt" />
            <small>pv-icon-filter-alt</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-folder-default" />
            <small>pv-icon-folder-default</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-folder-download" />
            <small>pv-icon-folder-download</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-folder-shared" />
            <small>pv-icon-folder-shared</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-folder-upload" />
            <small>pv-icon-folder-upload</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-forward" />
            <small>pv-icon-forward</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-fullscreen" />
            <small>pv-icon-fullscreen</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-info" />
            <small>pv-icon-info</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-key-small" />
            <small>pv-icon-key-small</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-key" />
            <small>pv-icon-key</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-laptop" />
            <small>pv-icon-laptop</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-mac" />
            <small>pv-icon-mac</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-mailbox-empty" />
            <small>pv-icon-mailbox-empty</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-mark-read" />
            <small>pv-icon-mark-read</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-message-alt" />
            <small>pv-icon-message-alt</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-message" />
            <small>pv-icon-message</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-minimize" />
            <small>pv-icon-minimize</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-mobile" />
            <small>pv-icon-mobile</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-more" />
            <small>pv-icon-more</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-more-alt" />
            <small>pv-icon-more-alt</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-move" />
            <small>pv-icon-move</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-multiple-messages-alt" />
            <small>pv-icon-multiple-messages-alt</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-new-folder" />
            <small>pv-icon-new-folder</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-open" />
            <small>pv-icon-open</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-organization" />
            <small>pv-icon-organization</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-question" />
            <small>pv-icon-question</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-refresh" />
            <small>pv-icon-refresh</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-reply-all" />
            <small>pv-icon-reply-all</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-reply" />
            <small>pv-icon-reply</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-search" />
            <small>pv-icon-search</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-settings" />
            <small>pv-icon-settings</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-share" />
            <small>pv-icon-share</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-starred" />
            <small>pv-icon-starred</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-unstarred" />
            <small>pv-icon-unstarred</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-trash" />
            <small>pv-icon-trash</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-windows" />
            <small>pv-icon-windows</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-multiple-messages" />
            <small>pv-icon-multiple-messages</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-file-code" />
            <small>pv-icon-file-code</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-file-excel" />
            <small>pv-icon-file-excel</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-file-image" />
            <small>pv-icon-file-image</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-file-pdf" />
            <small>pv-icon-file-pdf</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-file-powerpoint" />
            <small>pv-icon-file-powerpoint</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-file-text" />
            <small>pv-icon-file-text</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-file-video" />
            <small>pv-icon-file-video</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-file-word" />
            <small>pv-icon-file-word</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-file-zip" />
            <small>pv-icon-file-zip</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-file" />
            <small>pv-icon-file</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-finder" />
            <small>pv-icon-finder</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-approval-group" />
            <small>pv-icon-approval-group</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-approved" />
            <small>pv-icon-approved</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-receipt" />
            <small>pv-icon-receipt</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-unapproved" />
            <small>pv-icon-unapproved</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-failed" />
            <small>pv-icon-failed</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="pv-icon-domain" />
            <small>pv-icon-domain</small>
          </div>
        </Col>
      </Row>
    </div>;
  };

  // Description: Feather Icons
  const FeatherIcons = () => {
    return <div className="feather_icons">
      <Row className="align-items-center">
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-activity" />
            <small>ficon-activity</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-airplay" />
            <small>ficon-airplay</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-alert-circle" />
            <small>ficon-alert-circle</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-alert-octagon" />
            <small>ficon-alert-octagon</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-alert-triangle" />
            <small>ficon-alert-triangle</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-align-center" />
            <small>ficon-align-center</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-align-justify" />
            <small>ficon-align-justify</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-align-left" />
            <small>ficon-align-left</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-align-right" />
            <small>ficon-align-right</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-anchor" />
            <small>ficon-anchor</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-aperture" />
            <small>ficon-aperture</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-archive" />
            <small>ficon-archive</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-arrow-down" />
            <small>ficon-arrow-down</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-arrow-down-circle" />
            <small>ficon-arrow-down-circle</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-arrow-down-left" />
            <small>ficon-arrow-down-left</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-arrow-down-right" />
            <small>ficon-arrow-down-right</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-arrow-left" />
            <small>ficon-arrow-left</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-arrow-left-circle" />
            <small>ficon-arrow-left-circle</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-arrow-right" />
            <small>ficon-arrow-right</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-arrow-right-circle" />
            <small>ficon-arrow-right-circle</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-arrow-up" />
            <small>ficon-arrow-up</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-arrow-up-circle" />
            <small>ficon-arrow-up-circle</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-arrow-up-left" />
            <small>ficon-arrow-up-left</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-arrow-up-right" />
            <small>ficon-arrow-up-right</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-at-sign" />
            <small>ficon-at-sign</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-award" />
            <small>ficon-award</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-bar-chart" />
            <small>ficon-bar-chart</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-bar-chart-2" />
            <small>ficon-bar-chart-2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-battery" />
            <small>ficon-battery</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-battery-charging" />
            <small>ficon-battery-charging</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-bell" />
            <small>ficon-bell</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-bell-off" />
            <small>ficon-bell-off</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficontooth" />
            <small>ficontooth</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-bold" />
            <small>ficon-bold</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-book" />
            <small>ficon-book</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-book-open" />
            <small>ficon-book-open</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-bookmark" />
            <small>ficon-bookmark</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-box" />
            <small>ficon-box</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-briefcase" />
            <small>ficon-briefcase</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-calendar" />
            <small>ficon-calendar</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-camera" />
            <small>ficon-camera</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-camera-off" />
            <small>ficon-camera-off</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-cast" />
            <small>ficon-cast</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-check" />
            <small>ficon-check</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-check-circle" />
            <small>ficon-check-circle</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-check-square" />
            <small>ficon-check-square</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-chevron-down" />
            <small>ficon-chevron-down</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-chevron-left" />
            <small>ficon-chevron-left</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-chevron-right" />
            <small>ficon-chevron-right</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-chevron-up" />
            <small>ficon-chevron-up</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-chevrons-down" />
            <small>ficon-chevrons-down</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-chevrons-left" />
            <small>ficon-chevrons-left</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-chevrons-right" />
            <small>ficon-chevrons-right</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-chevrons-up" />
            <small>ficon-chevrons-up</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-chrome" />
            <small>ficon-chrome</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-circle" />
            <small>ficon-circle</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-clipboard" />
            <small>ficon-clipboard</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-clock" />
            <small>ficon-clock</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-cloud" />
            <small>ficon-cloud</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-cloud-drizzle" />
            <small>ficon-cloud-drizzle</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-cloud-lightning" />
            <small>ficon-cloud-lightning</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-cloud-off" />
            <small>ficon-cloud-off</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-cloud-rain" />
            <small>ficon-cloud-rain</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-cloud-snow" />
            <small>ficon-cloud-snow</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-code" />
            <small>ficon-code</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-codepen" />
            <small>ficon-codepen</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-codesandbox" />
            <small>ficon-codesandbox</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-coffee" />
            <small>ficon-coffee</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-columns" />
            <small>ficon-columns</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-command" />
            <small>ficon-command</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-compass" />
            <small>ficon-compass</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-copy" />
            <small>ficon-copy</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-corner-down-left" />
            <small>ficon-corner-down-left</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-corner-down-right" />
            <small>ficon-corner-down-right</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-corner-left-down" />
            <small>ficon-corner-left-down</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-corner-left-up" />
            <small>ficon-corner-left-up</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-corner-right-down" />
            <small>ficon-corner-right-down</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-corner-right-up" />
            <small>ficon-corner-right-up</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-corner-up-left" />
            <small>ficon-corner-up-left</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-corner-up-right" />
            <small>ficon-corner-up-right</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-cpu" />
            <small>ficon-cpu</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-credit-card" />
            <small>ficon-credit-card</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-crop" />
            <small>ficon-crop</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-crosshair" />
            <small>ficon-crosshair</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-database" />
            <small>ficon-database</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-delete" />
            <small>ficon-delete</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-disc" />
            <small>ficon-disc</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-dollar-sign" />
            <small>ficon-dollar-sign</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-download" />
            <small>ficon-download</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-download-cloud" />
            <small>ficon-download-cloud</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-droplet" />
            <small>ficon-droplet</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-edit-3" />
            <small>ficon-edit-3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-external-link" />
            <small>ficon-external-link</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-eye" />
            <small>ficon-eye</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-eye-off" />
            <small>ficon-eye-off</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-facebook" />
            <small>ficon-facebook</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-fast-forward" />
            <small>ficon-fast-forward</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-feather" />
            <small>ficon-feather</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-figma" />
            <small>ficon-figma</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-file" />
            <small>ficon-file</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-file-minus" />
            <small>ficon-file-minus</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-file-plus" />
            <small>ficon-file-plus</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-file-text" />
            <small>ficon-file-text</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-film" />
            <small>ficon-film</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-filter" />
            <small>ficon-filter</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-flag" />
            <small>ficon-flag</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-folder" />
            <small>ficon-folder</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-folder-minus" />
            <small>ficon-folder-minus</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-folder-plus" />
            <small>ficon-folder-plus</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-framer" />
            <small>ficon-framer</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-frown" />
            <small>ficon-frown</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-gift" />
            <small>ficon-gift</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-git-branch" />
            <small>ficon-git-branch</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-git-commit" />
            <small>ficon-git-commit</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-git-merge" />
            <small>ficon-git-merge</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-git-pull-request" />
            <small>ficon-git-pull-request</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-github" />
            <small>ficon-github</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-gitlab" />
            <small>ficon-gitlab</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-globe" />
            <small>ficon-globe</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-grid" />
            <small>ficon-grid</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-hard-drive" />
            <small>ficon-hard-drive</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-hash" />
            <small>ficon-hash</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-headphones" />
            <small>ficon-headphones</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-heart" />
            <small>ficon-heart</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-help-circle" />
            <small>ficon-help-circle</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-hexagon" />
            <small>ficon-hexagon</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-home" />
            <small>ficon-home</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-image" />
            <small>ficon-image</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-inbox" />
            <small>ficon-inbox</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-info" />
            <small>ficon-info</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-instagram" />
            <small>ficon-instagram</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-italic" />
            <small>ficon-italic</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-key" />
            <small>ficon-key</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-layers" />
            <small>ficon-layers</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-layout" />
            <small>ficon-layout</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-life-buoy" />
            <small>ficon-life-buoy</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-link" />
            <small>ficon-link</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-link-2" />
            <small>ficon-link-2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-linkedin" />
            <small>ficon-linkedin</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-list" />
            <small>ficon-list</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-loader" />
            <small>ficon-loader</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-lock" />
            <small>ficon-lock</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-log-in" />
            <small>ficon-log-in</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-log-out" />
            <small>ficon-log-out</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-mail" />
            <small>ficon-mail</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-map" />
            <small>ficon-map</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-map-pin" />
            <small>ficon-map-pin</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-maximize" />
            <small>ficon-maximize</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-maximize-2" />
            <small>ficon-maximize-2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-meh" />
            <small>ficon-meh</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-menu" />
            <small>ficon-menu</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-message-circle" />
            <small>ficon-message-circle</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-message-square" />
            <small>ficon-message-square</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-mic" />
            <small>ficon-mic</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-mic-off" />
            <small>ficon-mic-off</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-minimize" />
            <small>ficon-minimize</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-minimize-2" />
            <small>ficon-minimize-2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-minus" />
            <small>ficon-minus</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-minus-circle" />
            <small>ficon-minus-circle</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-minus-square" />
            <small>ficon-minus-square</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-monitor" />
            <small>ficon-monitor</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-moon" />
            <small>ficon-moon</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-more-horizontal" />
            <small>ficon-more-horizontal</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-more-vertical" />
            <small>ficon-more-vertical</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-mouse-pointer" />
            <small>ficon-mouse-pointer</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-music" />
            <small>ficon-music</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-navigation" />
            <small>ficon-navigation</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-navigation-2" />
            <small>ficon-navigation-2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-octagon" />
            <small>ficon-octagon</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-package" />
            <small>ficon-package</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-paperclip" />
            <small>ficon-paperclip</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-pause" />
            <small>ficon-pause</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-pause-circle" />
            <small>ficon-pause-circle</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-pen-tool" />
            <small>ficon-pen-tool</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-percent" />
            <small>ficon-percent</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-phone" />
            <small>ficon-phone</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-phone-call" />
            <small>ficon-phone-call</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-phone-forwarded" />
            <small>ficon-phone-forwarded</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-phone-incoming" />
            <small>ficon-phone-incoming</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-phone-missed" />
            <small>ficon-phone-missed</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-phone-off" />
            <small>ficon-phone-off</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-phone-outgoing" />
            <small>ficon-phone-outgoing</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-pie-chart" />
            <small>ficon-pie-chart</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-play" />
            <small>ficon-play</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-play-circle" />
            <small>ficon-play-circle</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-plus" />
            <small>ficon-plus</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-plus-circle" />
            <small>ficon-plus-circle</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-plus-square" />
            <small>ficon-plus-square</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-pocket" />
            <small>ficon-pocket</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-power" />
            <small>ficon-power</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-printer" />
            <small>ficon-printer</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-radio" />
            <small>ficon-radio</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-refresh-ccw" />
            <small>ficon-refresh-ccw</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-refresh-cw" />
            <small>ficon-refresh-cw</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-repeat" />
            <small>ficon-repeat</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-rewind" />
            <small>ficon-rewind</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-rotate-ccw" />
            <small>ficon-rotate-ccw</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-rotate-cw" />
            <small>ficon-rotate-cw</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-rss" />
            <small>ficon-rss</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-save" />
            <small>ficon-save</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-scissors" />
            <small>ficon-scissors</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-search" />
            <small>ficon-search</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-send" />
            <small>ficon-send</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-server" />
            <small>ficon-server</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-settings" />
            <small>ficon-settings</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-share" />
            <small>ficon-share</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-share-2" />
            <small>ficon-share-2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-shield" />
            <small>ficon-shield</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-shield-off" />
            <small>ficon-shield-off</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-shopping-bag" />
            <small>ficon-shopping-bag</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-shopping-cart" />
            <small>ficon-shopping-cart</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-shuffle" />
            <small>ficon-shuffle</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-sidebar" />
            <small>ficon-sidebar</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-skip-back" />
            <small>ficon-skip-back</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-skip-forward" />
            <small>ficon-skip-forward</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-slack" />
            <small>ficon-slack</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-slash" />
            <small>ficon-slash</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-sliders" />
            <small>ficon-sliders</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-smartphone" />
            <small>ficon-smartphone</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-smile" />
            <small>ficon-smile</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-speaker" />
            <small>ficon-speaker</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-square" />
            <small>ficon-square</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-star" />
            <small>ficon-star</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-stop-circle" />
            <small>ficon-stop-circle</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-sun" />
            <small>ficon-sun</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-sunrise" />
            <small>ficon-sunrise</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-sunset" />
            <small>ficon-sunset</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-tablet" />
            <small>ficon-tablet</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-tag" />
            <small>ficon-tag</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-target" />
            <small>ficon-target</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-terminal" />
            <small>ficon-terminal</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-thermometer" />
            <small>ficon-thermometer</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-thumbs-down" />
            <small>ficon-thumbs-down</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-thumbs-up" />
            <small>ficon-thumbs-up</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-toggle-left" />
            <small>ficon-toggle-left</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-toggle-right" />
            <small>ficon-toggle-right</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-tool" />
            <small>ficon-tool</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-trash" />
            <small>ficon-trash</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-trash-2" />
            <small>ficon-trash-2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-trello" />
            <small>ficon-trello</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-trending-down" />
            <small>ficon-trending-down</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-trending-up" />
            <small>ficon-trending-up</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-triangle" />
            <small>ficon-triangle</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-truck" />
            <small>ficon-truck</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-tv" />
            <small>ficon-tv</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-twitch" />
            <small>ficon-twitch</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-twitter" />
            <small>ficon-twitter</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-type" />
            <small>ficon-type</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-umbrella" />
            <small>ficon-umbrella</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-underline" />
            <small>ficon-underline</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-unlock" />
            <small>ficon-unlock</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-upload" />
            <small>ficon-upload</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-upload-cloud" />
            <small>ficon-upload-cloud</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-user" />
            <small>ficon-user</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-user-check" />
            <small>ficon-user-check</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-user-minus" />
            <small>ficon-user-minus</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-user-plus" />
            <small>ficon-user-plus</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-user-x" />
            <small>ficon-user-x</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-users" />
            <small>ficon-users</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-video" />
            <small>ficon-video</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-video-off" />
            <small>ficon-video-off</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-voicemail" />
            <small>ficon-voicemail</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-volume" />
            <small>ficon-volume</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-volume-1" />
            <small>ficon-volume-1</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-volume-2" />
            <small>ficon-volume-2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-volume-x" />
            <small>ficon-volume-x</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-watch" />
            <small>ficon-watch</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-wifi" />
            <small>ficon-wifi</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-wifi-off" />
            <small>ficon-wifi-off</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-wind" />
            <small>ficon-wind</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-x" />
            <small>ficon-x</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-x-circle" />
            <small>ficon-x-circle</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-x-octagon" />
            <small>ficon-x-octagon</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-x-square" />
            <small>ficon-x-square</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-zap-off" />
            <small>ficon-zap-off</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-zoom-in" />
            <small>ficon-zoom-in</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="ficon-zoom-out" />
            <small>ficon-zoom-out</small>
          </div>
        </Col>
      </Row>
    </div>;
  };

  // Description: Icomoon Icons
  const AdditionalIcons = () => {
    return <div className="icomoon_icons">
      <Row className="align-items-center">
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-home" />
            <small>icon-home</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-home2" />
            <small>icon-home2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-home5" />
            <small>icon-home5</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-home7" />
            <small>icon-home7</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-home8" />
            <small>icon-home8</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-home9" />
            <small>icon-home9</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-office" />
            <small>icon-office</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-city" />
            <small>icon-city</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-newspaper" />
            <small>icon-newspaper</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-magazine" />
            <small>icon-magazine</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-design" />
            <small>icon-design</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pencil" />
            <small>icon-pencil</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pencil3" />
            <small>icon-pencil3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pencil4" />
            <small>icon-pencil4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pencil5" />
            <small>icon-pencil5</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pencil6" />
            <small>icon-pencil6</small>

          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pencil7" />
            <small>icon-pencil7</small>

          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-eraser" />
            <small>icon-eraser</small>

          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-eraser2" />
            <small>icon-eraser2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-eraser3" />
            <small>icon-eraser3</small>

          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-quill2" />
            <small>icon-quill2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-quill4" />
            <small>icon-quill4</small>

          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pen" />
            <small>icon-pen</small>

          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pen" />
            <small>icon-pen</small>

          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pen-minus" />
            <small>icon-pen-minus</small>

          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pen2" />
            <small>icon-pen2</small>

          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-blog" />
            <small>icon-blog</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pen6" />
            <small>icon-pen6</small>

          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-brush" />
            <small>icon-brush</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-spray" />
            <small>icon-spray</small>

          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-color-sampler" />
            <small>icon-color-sampler</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-toggle" />
            <small>icon-toggle</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bucket" />
            <small>icon-bucket</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-gradient" />
            <small>icon-gradient</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-eyedropper" />
            <small>icon-eyedropper</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-eyedropper2" />
            <small>icon-eyedropper2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-eyedropper3" />
            <small>icon-eyedropper3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-droplet" />
            <small>icon-droplet</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-droplet2" />
            <small>icon-droplet2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-color-clear" />
            <small>icon-color-clear</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-paint-format" />
            <small>icon-paint-format</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stamp" />
            <small>icon-stamp</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-image2" />
            <small>icon-image2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-image-compare" />
            <small>icon-image-compare</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-images2" />
            <small>icon-images2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-image3" />
            <small>icon-image3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-images3" />
            <small>icon-images3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-image4" />
            <small>icon-image4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-image5" />
            <small>icon-image5</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-camera" />
            <small>icon-camera</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-shutter" />
            <small>icon-shutter</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-headphones" />
            <small>icon-headphones</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-headset" />
            <small>icon-headset</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-music" />
            <small>icon-music</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-album" />
            <small>icon-album</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-tape" />
            <small>icon-tape</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-piano" />
            <small>icon-piano</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-speakers" />
            <small>icon-speakers</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-play" />
            <small>icon-play</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-clapboard-play" />
            <small>icon-clapboard-play</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-clapboard" />
            <small>icon-clapboard</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-media" />
            <small>icon-media</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-presentation" />
            <small>icon-presentation</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-movie" />
            <small>icon-movie</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-film" />
            <small>icon-film</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-film2" />
            <small>icon-film2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-film3" />
            <small>icon-film3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-film4" />
            <small>icon-film4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-video-camera" />
            <small>icon-video-camera</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-video-camera2" />
            <small>icon-video-camera2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-video-camera-slash" />
            <small>icon-video-camera-slash</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-video-camera3" />
            <small>icon-video-camera3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-dice" />
            <small>icon-dice</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-chess-king" />
            <small>icon-chess-king</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-chess-queen" />
            <small>icon-chess-queen</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-chess" />
            <small>icon-chess</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-megaphone" />
            <small>icon-megaphone</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-new" />
            <small>icon-new</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-connection" />
            <small>icon-connection</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-station" />
            <small>icon-station</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-satellite-dish2" />
            <small>icon-satellite-dish2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-feed" />
            <small>icon-feed</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-mic2" />
            <small>icon-mic2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-mic-off2" />
            <small>icon-mic-off2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-book" />
            <small>icon-book</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-book2" />
            <small>icon-book2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-book-play" />
            <small>icon-book-play</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-book3" />
            <small>icon-book3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bookmark" />
            <small>icon-bookmark</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-books" />
            <small>icon-books</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-archive" />
            <small>icon-archive</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-reading" />
            <small>icon-reading</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-library2" />
            <small>icon-library2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-graduation2" />
            <small>icon-graduation2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-text" />
            <small>icon-file-text</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-profile" />
            <small>icon-profile</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-empty" />
            <small>icon-file-empty</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-empty2" />
            <small>icon-file-empty2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-files-empty" />
            <small>icon-files-empty</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-files-empty2" />
            <small>icon-files-empty2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-plus" />
            <small>icon-file-plus</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-plus2" />
            <small>icon-file-plus2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-minus" />
            <small>icon-file-minus</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-minus2" />
            <small>icon-file-minus2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-download" />
            <small>icon-file-download</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-download2" />
            <small>icon-file-download2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-upload" />
            <small>icon-file-upload</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-upload2" />
            <small>icon-file-upload2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-check" />
            <small>icon-file-check</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-check2" />
            <small>icon-file-check2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-eye" />
            <small>icon-file-eye</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-eye2" />
            <small>icon-file-eye2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-text2" />
            <small>icon-file-text2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-text3" />
            <small>icon-file-text3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-picture" />
            <small>icon-file-picture</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-picture2" />
            <small>icon-file-picture2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-music" />
            <small>icon-file-music</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-music2" />
            <small>icon-file-music2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-play" />
            <small>icon-file-play</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-play2" />
            <small>icon-file-play2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-video" />
            <small>icon-file-video</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-video2" />
            <small>icon-file-video2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-copy" />
            <small>icon-copy</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-copy2" />
            <small>icon-copy2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-zip" />
            <small>icon-file-zip</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-zip2" />
            <small>icon-file-zip2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-xml" />
            <small>icon-file-xml</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-xml2" />
            <small>icon-file-xml2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-css" />
            <small>icon-file-css</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-css2" />
            <small>icon-file-css2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-presentation" />
            <small>icon-file-presentation</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-presentation2" />
            <small>icon-file-presentation2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-stats" />
            <small>icon-file-stats</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-stats2" />
            <small>icon-file-stats2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-locked" />
            <small>icon-file-locked</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-locked2" />
            <small>icon-file-locked2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-spreadsheet" />
            <small>icon-file-spreadsheet</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-spreadsheet2" />
            <small>icon-file-spreadsheet2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-copy3" />
            <small>icon-copy3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-copy4" />
            <small>icon-copy4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-paste" />
            <small>icon-paste</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-paste2" />
            <small>icon-paste2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-paste3" />
            <small>icon-paste3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-paste4" />
            <small>icon-paste4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stack" />
            <small>icon-stack</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stack2" />
            <small>icon-stack2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stack3" />
            <small>icon-stack3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-folder" />
            <small>icon-folder</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-folder-search" />
            <small>icon-folder-search</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-folder-download" />
            <small>icon-folder-download</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-folder-upload" />
            <small>icon-folder-upload</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-folder-plus" />
            <small>icon-folder-plus</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-folder-plus2" />
            <small>icon-folder-plus2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-folder-minus" />
            <small>icon-folder-minus</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-folder-minus2" />
            <small>icon-folder-minus2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-folder-check" />
            <small>icon-folder-check</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-folder-heart" />
            <small>icon-folder-heart</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-folder-remove" />
            <small>icon-folder-remove</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-folder2" />
            <small>icon-folder2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-folder-open" />
            <small>icon-folder-open</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-folder3" />
            <small>icon-folder3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-folder4" />
            <small>icon-folder4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-folder-plus3" />
            <small>icon-folder-plus3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-folder-minus3" />
            <small>icon-folder-minus3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-folder-plus4" />
            <small>icon-folder-plus4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-folder-minus4" />
            <small>icon-folder-minus4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-folder-download2" />
            <small>icon-folder-download2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-folder-upload2" />
            <small>icon-folder-upload2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-folder-download3" />
            <small>icon-folder-download3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-folder-upload3" />
            <small>icon-folder-upload3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-folder5" />
            <small>icon-folder5</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-folder-open2" />
            <small>icon-folder-open2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-folder6" />
            <small>icon-folder6</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-folder-open3" />
            <small>icon-folder-open3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-certificate" />
            <small>icon-certificate</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cc" />
            <small>icon-cc</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-price-tag" />
            <small>icon-price-tag</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-price-tag2" />
            <small>icon-price-tag2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-price-tags" />
            <small>icon-price-tags</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-price-tag3" />
            <small>icon-price-tag3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-price-tags2" />
            <small>icon-price-tags2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-barcode2" />
            <small>icon-barcode2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-qrcode" />
            <small>icon-qrcode</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-ticket" />
            <small>icon-ticket</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-theater" />
            <small>icon-theater</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-store" />
            <small>icon-store</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-store2" />
            <small>icon-store2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cart" />
            <small>icon-cart</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cart2" />
            <small>icon-cart2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cart4" />
            <small>icon-cart4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cart5" />
            <small>icon-cart5</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cart-add" />
            <small>icon-cart-add</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cart-add2" />
            <small>icon-cart-add2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cart-remove" />
            <small>icon-cart-remove</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-basket" />
            <small>icon-basket</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bag" />
            <small>icon-bag</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-percent" />
            <small>icon-percent</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-coins" />
            <small>icon-coins</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-coin-dollar" />
            <small>icon-coin-dollar</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-coin-euro" />
            <small>icon-coin-euro</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-coin-pound" />
            <small>icon-coin-pound</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-coin-yen" />
            <small>icon-coin-yen</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-piggy-bank" />
            <small>icon-piggy-bank</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-wallet" />
            <small>icon-wallet</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cash" />
            <small>icon-cash</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cash2" />
            <small>icon-cash2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cash3" />
            <small>icon-cash3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cash4" />
            <small>icon-cash4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-credit-card" />
            <small>icon-credit-card</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-credit-card2" />
            <small>icon-credit-card2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-calculator4" />
            <small>icon-calculator4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-calculator2" />
            <small>icon-calculator2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-calculator3" />
            <small>icon-calculator3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-chip" />
            <small>icon-chip</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-lifebuoy" />
            <small>icon-lifebuoy</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-phone" />
            <small>icon-phone</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-phone2" />
            <small>icon-phone2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-phone-slash" />
            <small>icon-phone-slash</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-phone-wave" />
            <small>icon-phone-wave</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-phone-plus" />
            <small>icon-phone-plus</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-phone-minus" />
            <small>icon-phone-minus</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-phone-plus2" />
            <small>icon-phone-plus2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-phone-minus2" />
            <small>icon-phone-minus2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-phone-incoming" />
            <small>icon-phone-incoming</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-phone-outgoing" />
            <small>icon-phone-outgoing</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-phone-hang-up" />
            <small>icon-phone-hang-up</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-address-book" />
            <small>icon-address-book</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-address-book2" />
            <small>icon-address-book2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-address-book3" />
            <small>icon-address-book3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-notebook" />
            <small>icon-notebook</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-envelop" />
            <small>icon-envelop</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-envelop2" />
            <small>icon-envelop2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-envelop3" />
            <small>icon-envelop3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-envelop4" />
            <small>icon-envelop4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-envelop5" />
            <small>icon-envelop5</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-mailbox" />
            <small>icon-mailbox</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pushpin" />
            <small>icon-pushpin</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-location3" />
            <small>icon-location3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-location4" />
            <small>icon-location4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-compass4" />
            <small>icon-compass4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-map" />
            <small>icon-map</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-map4" />
            <small>icon-map4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-map5" />
            <small>icon-map5</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-direction" />
            <small>icon-direction</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-reset" />
            <small>icon-reset</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-history" />
            <small>icon-history</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-watch" />
            <small>icon-watch</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-watch2" />
            <small>icon-watch2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-alarm" />
            <small>icon-alarm</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-alarm-add" />
            <small>icon-alarm-add</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-alarm-check" />
            <small>icon-alarm-check</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-alarm-cancel" />
            <small>icon-alarm-cancel</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bell2" />
            <small>icon-bell2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bell3" />
            <small>icon-bell3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bell-plus" />
            <small>icon-bell-plus</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bell-minus" />
            <small>icon-bell-minus</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bell-check" />
            <small>icon-bell-check</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bell-cross" />
            <small>icon-bell-cross</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-calendar" />
            <small>icon-calendar</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-calendar2" />
            <small>icon-calendar2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-calendar3" />
            <small>icon-calendar3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-calendar52" />
            <small>icon-calendar52</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-printer" />
            <small>icon-printer</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-printer2" />
            <small>icon-printer2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-printer4" />
            <small>icon-printer4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-shredder" />
            <small>icon-shredder</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-mouse" />
            <small>icon-mouse</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-mouse-left" />
            <small>icon-mouse-left</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-mouse-right" />
            <small>icon-mouse-right</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-keyboard" />
            <small>icon-keyboard</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-typewriter" />
            <small>icon-typewriter</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-display" />
            <small>icon-display</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-display4" />
            <small>icon-display4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-laptop" />
            <small>icon-laptop</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-mobile" />
            <small>icon-mobile</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-mobile2" />
            <small>icon-mobile2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-tablet" />
            <small>icon-tablet</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-mobile3" />
            <small>icon-mobile3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-tv" />
            <small>icon-tv</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-radio" />
            <small>icon-radio</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cabinet" />
            <small>icon-cabinet</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-drawer" />
            <small>icon-drawer</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-drawer2" />
            <small>icon-drawer2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-drawer-out" />
            <small>icon-drawer-out</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-drawer-in" />
            <small>icon-drawer-in</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-drawer3" />
            <small>icon-drawer3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-box" />
            <small>icon-box</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-box-add" />
            <small>icon-box-add</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-box-remove" />
            <small>icon-box-remove</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-download" />
            <small>icon-download</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-upload" />
            <small>icon-upload</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-floppy-disk" />
            <small>icon-floppy-disk</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-floppy-disks" />
            <small>icon-floppy-disks</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-usb-stick" />
            <small>icon-usb-stick</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-drive" />
            <small>icon-drive</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-server" />
            <small>icon-server</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-database" />
            <small>icon-database</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-database2" />
            <small>icon-database2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-database4" />
            <small>icon-database4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-database-menu" />
            <small>icon-database-menu</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-database-add" />
            <small>icon-database-add</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-database-remove" />
            <small>icon-database-remove</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-database-insert" />
            <small>icon-database-insert</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-database-export" />
            <small>icon-database-export</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-database-upload" />
            <small>icon-database-upload</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-database-refresh" />
            <small>icon-database-refresh</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-database-diff" />
            <small>icon-database-diff</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-database-edit2" />
            <small>icon-database-edit2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-database-check" />
            <small>icon-database-check</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-database-arrow" />
            <small>icon-database-arrow</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-database-time2" />
            <small>icon-database-time2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-undo" />
            <small>icon-undo</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-redo" />
            <small>icon-redo</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-rotate-ccw" />
            <small>icon-rotate-ccw</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-rotate-cw" />
            <small>icon-rotate-cw</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-rotate-ccw2" />
            <small>icon-rotate-ccw2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-rotate-cw2" />
            <small>icon-rotate-cw2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-rotate-ccw3" />
            <small>icon-rotate-ccw3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-rotate-cw3" />
            <small>icon-rotate-cw3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-flip-vertical2" />
            <small>icon-flip-vertical2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-flip-horizontal2" />
            <small>icon-flip-horizontal2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-flip-vertical3" />
            <small>icon-flip-vertical3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-flip-vertical4" />
            <small>icon-flip-vertical4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-angle" />
            <small>icon-angle</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-shear" />
            <small>icon-shear</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-align-left" />
            <small>icon-align-left</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-align-center-horizontal" />
            <small>icon-align-center-horizontal</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-align-right" />
            <small>icon-align-right</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-align-top" />
            <small>icon-align-top</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-align-center-vertical" />
            <small>icon-align-center-vertical</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-align-bottom" />
            <small>icon-align-bottom</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-undo2" />
            <small>icon-undo2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-redo2" />
            <small>icon-redo2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-forward" />
            <small>icon-forward</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-reply" />
            <small>icon-reply</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-reply-all" />
            <small>icon-reply-all</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bubble" />
            <small>icon-bubble</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bubbles" />
            <small>icon-bubbles</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bubbles2" />
            <small>icon-bubbles2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bubble2" />
            <small>icon-bubble2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bubbles3" />
            <small>icon-bubbles3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bubbles4" />
            <small>icon-bubbles4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bubble-notification" />
            <small>icon-bubble-notification</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bubbles5" />
            <small>icon-bubbles5</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bubbles6" />
            <small>icon-bubbles6</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bubble6" />
            <small>icon-bubble6</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bubbles7" />
            <small>icon-bubbles7</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bubble7" />
            <small>icon-bubble7</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bubbles8" />
            <small>icon-bubbles8</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bubble8" />
            <small>icon-bubble8</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bubble-dots3" />
            <small>icon-bubble-dots3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bubble-lines3" />
            <small>icon-bubble-lines3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bubble9" />
            <small>icon-bubble9</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bubble-dots4" />
            <small>icon-bubble-dots4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bubble-lines4" />
            <small>icon-bubble-lines4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bubbles9" />
            <small>icon-bubbles9</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bubbles10" />
            <small>icon-bubbles10</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-user" />
            <small>icon-user</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-users" />
            <small>icon-users</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-user-plus" />
            <small>icon-user-plus</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-user-minus" />
            <small>icon-user-minus</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-user-cancel" />
            <small>icon-user-cancel</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-user-block" />
            <small>icon-user-block</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-user-lock" />
            <small>icon-user-lock</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-user-check" />
            <small>icon-user-check</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-users2" />
            <small>icon-users2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-users4" />
            <small>icon-users4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-user-tie" />
            <small>icon-user-tie</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-collaboration" />
            <small>icon-collaboration</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-vcard" />
            <small>icon-vcard</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-hat" />
            <small>icon-hat</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bowtie" />
            <small>icon-bowtie</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-quotes-left" />
            <small>icon-quotes-left</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-quotes-right" />
            <small>icon-quotes-right</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-quotes-left2" />
            <small>icon-quotes-left2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-quotes-right2" />
            <small>icon-quotes-right2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-hour-glass" />
            <small>icon-hour-glass</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-hour-glass2" />
            <small>icon-hour-glass2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-hour-glass3" />
            <small>icon-hour-glass3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-spinner" />
            <small>icon-spinner</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-spinner2" />
            <small>icon-spinner2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-spinner3" />
            <small>icon-spinner3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-spinner4" />
            <small>icon-spinner4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-spinner6" />
            <small>icon-spinner6</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-spinner9" />
            <small>icon-spinner9</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-spinner10" />
            <small>icon-spinner10</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-spinner11" />
            <small>icon-spinner11</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-microscope" />
            <small>icon-microscope</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-enlarge" />
            <small>icon-enlarge</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-shrink" />
            <small>icon-shrink</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-enlarge3" />
            <small>icon-enlarge3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-shrink3" />
            <small>icon-shrink3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-enlarge5" />
            <small>icon-enlarge5</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-shrink5" />
            <small>icon-shrink5</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-enlarge6" />
            <small>icon-enlarge6</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-shrink6" />
            <small>icon-shrink6</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-enlarge7" />
            <small>icon-enlarge7</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-shrink7" />
            <small>icon-shrink7</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-key" />
            <small>icon-key</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-lock" />
            <small>icon-lock</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-lock2" />
            <small>icon-lock2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-lock4" />
            <small>icon-lock4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-unlocked" />
            <small>icon-unlocked</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-lock5" />
            <small>icon-lock5</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-unlocked2" />
            <small>icon-unlocked2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-safe" />
            <small>icon-safe</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-wrench" />
            <small>icon-wrench</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-wrench2" />
            <small>icon-wrench2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-wrench3" />
            <small>icon-wrench3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-equalizer" />
            <small>icon-equalizer</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-equalizer2" />
            <small>icon-equalizer2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-equalizer3" />
            <small>icon-equalizer3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-equalizer4" />
            <small>icon-equalizer4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cog" />
            <small>icon-cog</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cogs" />
            <small>icon-cogs</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cog2" />
            <small>icon-cog2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cog3" />
            <small>icon-cog3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cog4" />
            <small>icon-cog4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cog52" />
            <small>icon-cog52</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cog6" />
            <small>icon-cog6</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cog7" />
            <small>icon-cog7</small>
          </div>
        </Col>

        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-hammer" />
            <small>icon-hammer</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-hammer-wrench" />
            <small>icon-hammer-wrench</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-magic-wand" />
            <small>icon-magic-wand</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-magic-wand2" />
            <small>icon-magic-wand2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pulse2" />
            <small>icon-pulse2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-aid-kit" />
            <small>icon-aid-kit</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bug2" />
            <small>icon-bug2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-construction" />
            <small>icon-construction</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-traffic-cone" />
            <small>icon-traffic-cone</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-traffic-lights" />
            <small>icon-traffic-lights</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pie-chart" />
            <small>icon-pie-chart</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pie-chart2" />
            <small>icon-pie-chart2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pie-chart3" />
            <small>icon-pie-chart3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pie-chart4" />
            <small>icon-pie-chart4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pie-chart5" />
            <small>icon-pie-chart5</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pie-chart6" />
            <small>icon-pie-chart6</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pie-chart7" />
            <small>icon-pie-chart7</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stats-dots" />
            <small>icon-stats-dots</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stats-bars" />
            <small>icon-stats-bars</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pie-chart8" />
            <small>icon-pie-chart8</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stats-bars2" />
            <small>icon-stats-bars2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stats-bars3" />
            <small>icon-stats-bars3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stats-bars4" />
            <small>icon-stats-bars4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-chart" />
            <small>icon-chart</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stats-growth" />
            <small>icon-stats-growth</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stats-decline" />
            <small>icon-stats-decline</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stats-growth2" />
            <small>icon-stats-growth2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stats-decline2" />
            <small>icon-stats-decline2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stairs-up" />
            <small>icon-stairs-up</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stairs-down" />
            <small>icon-stairs-down</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stairs" />
            <small>icon-stairs</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-ladder" />
            <small>icon-ladder</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-rating" />
            <small>icon-rating</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-rating2" />
            <small>icon-rating2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-rating3" />
            <small>icon-rating3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-podium" />
            <small>icon-podium</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stars" />
            <small>icon-stars</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-medal-star" />
            <small>icon-medal-star</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-medal" />
            <small>icon-medal</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-medal2" />
            <small>icon-medal2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-medal-first" />
            <small>icon-medal-first</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-medal-second" />
            <small>icon-medal-second</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-medal-third" />
            <small>icon-medal-third</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-crown" />
            <small>icon-crown</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-trophy2" />
            <small>icon-trophy2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-trophy3" />
            <small>icon-trophy3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-diamond" />
            <small>icon-diamond</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-trophy4" />
            <small>icon-trophy4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-gift" />
            <small>icon-gift</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pipe" />
            <small>icon-pipe</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-mustache" />
            <small>icon-mustache</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cup2" />
            <small>icon-cup2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-coffee" />
            <small>icon-coffee</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-paw" />
            <small>icon-paw</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-footprint" />
            <small>icon-footprint</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-rocket" />
            <small>icon-rocket</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-meter2" />
            <small>icon-meter2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-meter-slow" />
            <small>icon-meter-slow</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-meter-fast" />
            <small>icon-meter-fast</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-hammer2" />
            <small>icon-hammer2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-balance" />
            <small>icon-balance</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-fire" />
            <small>icon-fire</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-fire2" />
            <small>icon-fire2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-lab" />
            <small>icon-lab</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-atom" />
            <small>icon-atom</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-atom2" />
            <small>icon-atom2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bin" />
            <small>icon-bin</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bin2" />
            <small>icon-bin2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-briefcase" />
            <small>icon-briefcase</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-briefcase3" />
            <small>icon-briefcase3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-airplane2" />
            <small>icon-airplane2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-airplane3" />
            <small>icon-airplane3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-airplane4" />
            <small>icon-airplane4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-paperplane" />
            <small>icon-paperplane</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-car" />
            <small>icon-car</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-steering-wheel" />
            <small>icon-steering-wheel</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-car2" />
            <small>icon-car2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-gas" />
            <small>icon-gas</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bus" />
            <small>icon-bus</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-truck" />
            <small>icon-truck</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bike" />
            <small>icon-bike</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-road" />
            <small>icon-road</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-train" />
            <small>icon-train</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-train2" />
            <small>icon-train2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-ship" />
            <small>icon-ship</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-boat" />
            <small>icon-boat</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-chopper" />
            <small>icon-chopper</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cube" />
            <small>icon-cube</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cube2" />
            <small>icon-cube2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cube3" />
            <small>icon-cube3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cube4" />
            <small>icon-cube4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pyramid" />
            <small>icon-pyramid</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pyramid2" />
            <small>icon-pyramid2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-package" />
            <small>icon-package</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-puzzle" />
            <small>icon-puzzle</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-puzzle2" />
            <small>icon-puzzle2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-puzzle3" />
            <small>icon-puzzle3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-puzzle4" />
            <small>icon-puzzle4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-glasses-3d2" />
            <small>icon-glasses-3d2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-brain" />
            <small>icon-brain</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-accessibility" />
            <small>icon-accessibility</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-accessibility2" />
            <small>icon-accessibility2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-strategy" />
            <small>icon-strategy</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-target" />
            <small>icon-target</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-target2" />
            <small>icon-target2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-shield-check" />
            <small>icon-shield-check</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-shield-notice" />
            <small>icon-shield-notice</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-shield2" />
            <small>icon-shield2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-racing" />
            <small>icon-racing</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-finish" />
            <small>icon-finish</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-power2" />
            <small>icon-power2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-power3" />
            <small>icon-power3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-switch" />
            <small>icon-switch</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-switch22" />
            <small>icon-switch22</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-power-cord" />
            <small>icon-power-cord</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-clipboard" />
            <small>icon-clipboard</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-clipboard2" />
            <small>icon-clipboard2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-clipboard3" />
            <small>icon-clipboard3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-clipboard4" />
            <small>icon-clipboard4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-clipboard5" />
            <small>icon-clipboard5</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-clipboard6" />
            <small>icon-clipboard6</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-playlist" />
            <small>icon-playlist</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-playlist-add" />
            <small>icon-playlist-add</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-list-numbered" />
            <small>icon-list-numbered</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-list" />
            <small>icon-list</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-list2" />
            <small>icon-list2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-more" />
            <small>icon-more</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-more2" />
            <small>icon-more2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-grid" />
            <small>icon-grid</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-grid2" />
            <small>icon-grid2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-grid3" />
            <small>icon-grid3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-grid4" />
            <small>icon-grid4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-grid52" />
            <small>icon-grid52</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-grid6" />
            <small>icon-grid6</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-grid7" />
            <small>icon-grid7</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-tree5" />
            <small>icon-tree5</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-tree6" />
            <small>icon-tree6</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-tree7" />
            <small>icon-tree7</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-lan" />
            <small>icon-lan</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-lan2" />
            <small>icon-lan2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-lan3" />
            <small>icon-lan3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-menu" />
            <small>icon-menu</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-circle-small" />
            <small>icon-circle-small</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-menu2" />
            <small>icon-menu2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-menu3" />
            <small>icon-menu3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-menu4" />
            <small>icon-menu4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-menu5" />
            <small>icon-menu5</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-menu62" />
            <small>icon-menu62</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-menu7" />
            <small>icon-menu7</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-menu8" />
            <small>icon-menu8</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-menu9" />
            <small>icon-menu9</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-menu10" />
            <small>icon-menu10</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cloud" />
            <small>icon-cloud</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cloud-download" />
            <small>icon-cloud-download</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cloud-upload" />
            <small>icon-cloud-upload</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cloud-check" />
            <small>icon-cloud-check</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cloud2" />
            <small>icon-cloud2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cloud-download2" />
            <small>icon-cloud-download2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cloud-upload2" />
            <small>icon-cloud-upload2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cloud-check2" />
            <small>icon-cloud-check2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-import" />
            <small>icon-import</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-download4" />
            <small>icon-download4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-upload4" />
            <small>icon-upload4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-download7" />
            <small>icon-download7</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-upload7" />
            <small>icon-upload7</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-download10" />
            <small>icon-download10</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-upload10" />
            <small>icon-upload10</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-sphere" />
            <small>icon-sphere</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-sphere3" />
            <small>icon-sphere3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-earth" />
            <small>icon-earth</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-link" />
            <small>icon-link</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-unlink" />
            <small>icon-unlink</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-link2" />
            <small>icon-link2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-unlink2" />
            <small>icon-unlink2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-anchor" />
            <small>icon-anchor</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-flag3" />
            <small>icon-flag3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-flag4" />
            <small>icon-flag4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-flag7" />
            <small>icon-flag7</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-flag8" />
            <small>icon-flag8</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-attachment" />
            <small>icon-attachment</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-attachment2" />
            <small>icon-attachment2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-eye" />
            <small>icon-eye</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-eye-plus" />
            <small>icon-eye-plus</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-eye-minus" />
            <small>icon-eye-minus</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-eye-blocked" />
            <small>icon-eye-blocked</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-eye2" />
            <small>icon-eye2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-eye-blocked2" />
            <small>icon-eye-blocked2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-eye4" />
            <small>icon-eye4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bookmark2" />
            <small>icon-bookmark2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bookmark3" />
            <small>icon-bookmark3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bookmarks" />
            <small>icon-bookmarks</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bookmark4" />
            <small>icon-bookmark4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-spotlight2" />
            <small>icon-spotlight2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-starburst" />
            <small>icon-starburst</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-snowflake" />
            <small>icon-snowflake</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-weather-windy" />
            <small>icon-weather-windy</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-fan" />
            <small>icon-fan</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-umbrella" />
            <small>icon-umbrella</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-sun3" />
            <small>icon-sun3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-contrast" />
            <small>icon-contrast</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bed2" />
            <small>icon-bed2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-furniture" />
            <small>icon-furniture</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-chair" />
            <small>icon-chair</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-star-empty3" />
            <small>icon-star-empty3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-star-half" />
            <small>icon-star-half</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-star-full2" />
            <small>icon-star-full2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-heart5" />
            <small>icon-heart5</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-heart6" />
            <small>icon-heart6</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-heart-broken2" />
            <small>icon-heart-broken2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-thumbs-up2" />
            <small>icon-thumbs-up2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-thumbs-down2" />
            <small>icon-thumbs-down2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-thumbs-up3" />
            <small>icon-thumbs-up3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-thumbs-down3" />
            <small>icon-thumbs-down3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-height" />
            <small>icon-height</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-man" />
            <small>icon-man</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-woman" />
            <small>icon-woman</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-man-woman" />
            <small>icon-man-woman</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-yin-yang" />
            <small>icon-yin-yang</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cursor" />
            <small>icon-cursor</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cursor2" />
            <small>icon-cursor2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-lasso2" />
            <small>icon-lasso2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-select2" />
            <small>icon-select2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-point-up" />
            <small>icon-point-up</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-point-right" />
            <small>icon-point-right</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-point-down" />
            <small>icon-point-down</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-point-left" />
            <small>icon-point-left</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pointer" />
            <small>icon-pointer</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-reminder" />
            <small>icon-reminder</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-drag-left-right" />
            <small>icon-drag-left-right</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-drag-left" />
            <small>icon-drag-left</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-drag-right" />
            <small>icon-drag-right</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-touch" />
            <small>icon-touch</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-multitouch" />
            <small>icon-multitouch</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-touch-zoom" />
            <small>icon-touch-zoom</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-touch-pinch" />
            <small>icon-touch-pinch</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-hand" />
            <small>icon-hand</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-grab" />
            <small>icon-grab</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stack-empty" />
            <small>icon-stack-empty</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stack-plus" />
            <small>icon-stack-plus</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stack-minus" />
            <small>icon-stack-minus</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stack-star" />
            <small>icon-stack-star</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stack-picture" />
            <small>icon-stack-picture</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stack-down" />
            <small>icon-stack-down</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stack-up" />
            <small>icon-stack-up</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stack-cancel" />
            <small>icon-stack-cancel</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stack-check" />
            <small>icon-stack-check</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stack-text" />
            <small>icon-stack-text</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stack4" />
            <small>icon-stack4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stack-music" />
            <small>icon-stack-music</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stack-play" />
            <small>icon-stack-play</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-move" />
            <small>icon-move</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-dots" />
            <small>icon-dots</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-warning" />
            <small>icon-warning</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-warning22" />
            <small>icon-warning22</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-notification2" />
            <small>icon-notification2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-question3" />
            <small>icon-question3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-question4" />
            <small>icon-question4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-plus3" />
            <small>icon-plus3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-minus3" />
            <small>icon-minus3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-plus-circle2" />
            <small>icon-plus-circle2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-minus-circle2" />
            <small>icon-minus-circle2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cancel-circle2" />
            <small>icon-cancel-circle2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-blocked" />
            <small>icon-blocked</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cancel-square" />
            <small>icon-cancel-square</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cancel-square2" />
            <small>icon-cancel-square2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-spam" />
            <small>icon-spam</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cross2" />
            <small>icon-cross2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cross3" />
            <small>icon-cross3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-checkmark" />
            <small>icon-checkmark</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-checkmark3" />
            <small>icon-checkmark3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-checkmark2" />
            <small>icon-checkmark2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-checkmark4" />
            <small>icon-checkmark4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-spell-check" />
            <small>icon-spell-check</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-spell-check2" />
            <small>icon-spell-check2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-enter" />
            <small>icon-enter</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-exit" />
            <small>icon-exit</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-enter2" />
            <small>icon-enter2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-exit2" />
            <small>icon-exit2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-enter3" />
            <small>icon-enter3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-exit3" />
            <small>icon-exit3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-wall" />
            <small>icon-wall</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-fence" />
            <small>icon-fence</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-play3" />
            <small>icon-play3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pause" />
            <small>icon-pause</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stop" />
            <small>icon-stop</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-previous" />
            <small>icon-previous</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-next" />
            <small>icon-next</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-backward" />
            <small>icon-backward</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-forward2" />
            <small>icon-forward2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-play4" />
            <small>icon-play4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pause2" />
            <small>icon-pause2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stop2" />
            <small>icon-stop2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-backward2" />
            <small>icon-backward2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-forward3" />
            <small>icon-forward3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-first" />
            <small>icon-first</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-last" />
            <small>icon-last</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-previous2" />
            <small>icon-previous2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-next2" />
            <small>icon-next2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-eject" />
            <small>icon-eject</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-volume-high" />
            <small>icon-volume-high</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-volume-medium" />
            <small>icon-volume-medium</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-volume-low" />
            <small>icon-volume-low</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-volume-mute" />
            <small>icon-volume-mute</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-speaker-left" />
            <small>icon-speaker-left</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-speaker-right" />
            <small>icon-speaker-right</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-volume-mute2" />
            <small>icon-volume-mute2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-volume-increase" />
            <small>icon-volume-increase</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-volume-decrease" />
            <small>icon-volume-decrease</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-volume-mute5" />
            <small>icon-volume-mute5</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-loop" />
            <small>icon-loop</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-loop3" />
            <small>icon-loop3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-infinite-square" />
            <small>icon-infinite-square</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-infinite" />
            <small>icon-infinite</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-loop4" />
            <small>icon-loop4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-shuffle" />
            <small>icon-shuffle</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-wave" />
            <small>icon-wave</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-wave2" />
            <small>icon-wave2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-split" />
            <small>icon-split</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-merge" />
            <small>icon-merge</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-up5" />
            <small>icon-arrow-up5</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-right5" />
            <small>icon-arrow-right5</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-down5" />
            <small>icon-arrow-down5</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-left5" />
            <small>icon-arrow-left5</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-up-left2" />
            <small>icon-arrow-up-left2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-up7" />
            <small>icon-arrow-up7</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-up-right2" />
            <small>icon-arrow-up-right2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-right7" />
            <small>icon-arrow-right7</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-down-right2" />
            <small>icon-arrow-down-right2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-down7" />
            <small>icon-arrow-down7</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-down-left2" />
            <small>icon-arrow-down-left2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-left7" />
            <small>icon-arrow-left7</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-up-left3" />
            <small>icon-arrow-up-left3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-up8" />
            <small>icon-arrow-up8</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-up-right3" />
            <small>icon-arrow-up-right3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-right8" />
            <small>icon-arrow-right8</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-down-right3" />
            <small>icon-arrow-down-right3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-down8" />
            <small>icon-arrow-down8</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-down-left3" />
            <small>icon-arrow-down-left3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-left8" />
            <small>icon-arrow-left8</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-circle-up2" />
            <small>icon-circle-up2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-circle-right2" />
            <small>icon-circle-right2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-circle-down2" />
            <small>icon-circle-down2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-circle-left2" />
            <small>icon-circle-left2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-resize7" />
            <small>icon-arrow-resize7</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-resize8" />
            <small>icon-arrow-resize</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-square-up-left" />
            <small>icon-square-up-left</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-square-up" />
            <small>icon-square-up</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-square-up-right" />
            <small>icon-square-up-right</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-square-right" />
            <small>icon-square-right</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-square-down-right" />
            <small>icon-square-down-right</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-square-down" />
            <small>icon-square-down</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-square-down-left" />
            <small>icon-square-down-left</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-square-left" />
            <small>icon-square-left</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-up15" />
            <small>icon-arrow-up15</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-right15" />
            <small>icon--arrow-right15</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-down15" />
            <small>icon-arrow-down15</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-left15" />
            <small>icon-arrow-left15</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-up16" />
            <small>icon-arrow-up16</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-right16" />
            <small>icon-arrow-right16</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-down16" />
            <small>icon-arrow-down16</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-left16" />
            <small>icon-arrow-left16</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-menu-open" />
            <small>icon-menu-open</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-menu-open2" />
            <small>icon-menu-open2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-menu-close" />
            <small>icon-menu-close</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-menu-close2" />
            <small>icon-close2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-enter5" />
            <small>icon-enter5</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-esc" />
            <small>icon-esc</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-enter6" />
            <small>icon-enter6</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-backspace" />
            <small>icon-backspace</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-backspace2" />
            <small>icon-backspace2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-tab" />
            <small>icon-tab</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-transmission" />
            <small>icon-transmission</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-sort" />
            <small>icon-sort</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-move-up2" />
            <small>icon-move-up2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-move-down2" />
            <small>icon-move-down2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-sort-alpha-asc" />
            <small>icon-sort-alpha-asc</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-sort-alpha-desc" />
            <small>icon-sort-alpha-desc</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-sort-numeric-asc" />
            <small>icon-sort-numeric-asc</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-sort-numberic-desc" />
            <small>icon-sort-numberic-desc</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-sort-amount-asc" />
            <small>icon-sort-amount-asc</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-sort-amount-desc" />
            <small>icon-sort-amount-desc</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-sort-time-asc" />
            <small>icon-sort-time-asc</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-sort-time-desc" />
            <small>icon-sort-time-desc</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-battery-6" />
            <small>icon-battery-6</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-battery-0" />
            <small>icon-battery-0</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-battery-charging" />
            <small>icon-battery-charging</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-command" />
            <small>icon-command</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-shift" />
            <small>icon-shift</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-ctrl" />
            <small>icon-icon-ctrl</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-opt" />
            <small>icon-icon-opt</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-checkbox-checked" />
            <small>icon-checkbox-checked</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-checkbox-unchecked" />
            <small>icon-checkbox-unchecked</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-checkbox-partial" />
            <small>icon-checkbox-partial</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-square" />
            <small>icon-square</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-triangle" />
            <small>icon-triangle</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-triangle2" />
            <small>icon-triangle2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-diamond3" />
            <small>icon-diamond3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-diamond4" />
            <small>icon-diamond4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-checkbox-checked2" />
            <small>icon-checkbox-checked2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-checkbox-unchecked2" />
            <small>icon-checkbox-unchecked2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-checkbox-partial2" />
            <small>icon-checkbox-partial</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-radio-checked" />
            <small>icon-radio-checked</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-radio-checked2" />
            <small>icon-checked2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-radio-unchecked" />
            <small>icon-radio-unchecked</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-checkmark-circle" />
            <small>icon-checkmark-circle</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-circle" />
            <small>icon-circle</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-circle2" />
            <small>icon-circle2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-circles" />
            <small>icon-circles</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-circles2" />
            <small>icon-circles2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-crop" />
            <small>icon-crop</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-crop2" />
            <small>icon-crop2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-make-group" />
            <small>icon-make-group</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-ungroup" />
            <small>icon-ungroup</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-vector" />
            <small>icon-vector</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-vector2" />
            <small>icon-vector2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-rulers" />
            <small>icon-rulers</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pencil-ruler" />
            <small>icon-pencil-ruler</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-scissors" />
            <small>icon-scissors</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-filter3" />
            <small>icon-filter3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-filter4" />
            <small>icon-filter4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-font" />
            <small>icon-font</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-ampersand2" />
            <small>icon-ampersand2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-ligature" />
            <small>icon-ligature</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-font-size" />
            <small>icon-font-size</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-typography" />
            <small>icon-typography</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-text-height" />
            <small>icon-text-height</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-text-width" />
            <small>icon-text-width</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-height2" />
            <small>icon-height2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-width" />
            <small>icon-width</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-strikethrough2" />
            <small>icon-strikethrough2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-font-size2" />
            <small>icon-font-size2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bold2" />
            <small>icon-bold2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-underline2" />
            <small>icon-underline2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-italic2" />
            <small>icon-italic2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-strikethrough3" />
            <small>icon-strikethrough3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-omega" />
            <small>icon-omega</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-sigma" />
            <small>icon-sigma</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-nbsp" />
            <small>icon-nbsp</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-page-break" />
            <small>icon-page-break</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-page-break2" />
            <small>icon-page-break2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-superscript" />
            <small>icon-superscript</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-subscript" />
            <small>icon-subscript</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-superscript2" />
            <small>icon-superscript2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-subscript2" />
            <small>icon-subscript2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-text-color" />
            <small>icon-text-color</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-highlight" />
            <small>icon-highlight</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pagebreak" />
            <small>icon-pagebreak</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-clear-formatting" />
            <small>icon-clear-formatting</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-table" />
            <small>icon-table</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-table2" />
            <small>icon-table2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-insert-template" />
            <small>icon-insert-template</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pilcrow" />
            <small>icon-pilcrow</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-ltr" />
            <small>icon-ltr</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-rtl" />
            <small>icon-rtl</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-ltr2" />
            <small>icon-ltr2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-rtl2" />
            <small>icon-rtl2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-section" />
            <small>icon-section</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-paragraph-left2" />
            <small>icon-paragraph-left2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-paragraph-center2" />
            <small>icon-paragraph-center2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-paragraph-right2" />
            <small>icon-paragraph-right2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-paragraph-justify2" />
            <small>icon-paragraph-justify2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-indent-increase" />
            <small>icon-indent-increase</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-indent-decrease" />
            <small>icon-indent-decrease</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-paragraph-left3" />
            <small>icon-paragraph-left3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-paragraph-center3" />
            <small>icon-paragraph-center3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-paragraph-right3" />
            <small>icon-paragraph-right3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-paragraph-justify3" />
            <small>icon-paragraph-justify3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-indent-increase2" />
            <small>icon-indent-increase2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-indent-decrease2" />
            <small>icon-indent-decrease2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-share" />
            <small>icon-share</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-share2" />
            <small>icon-share2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-new-tab" />
            <small>icon-new-tab</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-new-tab2" />
            <small>icon-new-tab2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-popout" />
            <small>icon-popout</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-embed" />
            <small>icon-embed</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-embed2" />
            <small>icon-embed2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-markup" />
            <small>icon-markup</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-regexp" />
            <small>icon-regexp</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-regexp2" />
            <small>icon-regexp2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-code" />
            <small>icon-code</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-circle-css" />
            <small>icon-circle-css</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-circle-code" />
            <small>icon-circle-code</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-terminal" />
            <small>icon-terminal</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-unicode" />
            <small>icon-unicode</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-seven-segment-0" />
            <small>icon-seven-segment-0</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-seven-segment-1" />
            <small>icon-seven-segment-1</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-seven-segment-2" />
            <small>icon-seven-segment-2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-seven-segment-3" />
            <small>icon-seven-segment-3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-seven-segment-4" />
            <small>icon-seven-segment-4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-seven-segment-5" />
            <small>icon-seven-segment-5</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-seven-segment-6" />
            <small>icon-seven-segment-6</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-seven-segment-7" />
            <small>icon-seven-segment-7</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-seven-segment-8" />
            <small>icon-seven-segment-8</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-seven-segment-9" />
            <small>icon-seven-segment-9</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-share3" />
            <small>icon-share3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-share4" />
            <small>icon-share4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-google" />
            <small>icon-google</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-google-plus" />
            <small>icon-google-plus</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-google-plus2" />
            <small>icon-google-plus2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-google-drive" />
            <small>icon-google-drive</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-facebook" />
            <small>icon-facebook</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-facebook2" />
            <small>icon-facebook2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-instagram" />
            <small>icon-instagram</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-twitter" />
            <small>icon-twitter</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-twitter2" />
            <small>icon-twitter2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-feed2" />
            <small>icon-feed2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-feed3" />
            <small>icon-feed3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-youtube" />
            <small>icon-youtube</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-youtube2" />
            <small>icon-youtube2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-youtube3" />
            <small>icon-youtube3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-vimeo" />
            <small>icon-vimeo</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-vimeo2" />
            <small>icon-vimeo2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-lanyrd" />
            <small>icon-lanyrd</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-flickr" />
            <small>icon-flickr</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-flickr2" />
            <small>icon-flickr2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-flickr3" />
            <small>icon-flickr3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-picassa" />
            <small>icon-picassa</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-picassa2" />
            <small>icon-picassa2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-dribbble" />
            <small>icon-dribbble</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-dribbble2" />
            <small>icon-dribbble2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-dribbble3" />
            <small>icon-dribbble3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-forrst" />
            <small>icon-forrst</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-forrst2" />
            <small>icon-forrst2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-deviantart" />
            <small>icon-deviantart</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-deviantart2" />
            <small>icon-deviantart2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-steam" />
            <small>icon-steam</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-steam2" />
            <small>icon-steam2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-dropbox" />
            <small>icon-dropbox</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-onedrive" />
            <small>icon-onedrive</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-github" />
            <small>icon-github</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-github4" />
            <small>icon-github4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-github5" />
            <small>icon-github5</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-wordpress" />
            <small>icon-wordpress</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-wordpress2" />
            <small>icon-wordpress2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-joomla" />
            <small>icon-joomla</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-blogger" />
            <small>icon-blogger</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-blogger2" />
            <small>icon-blogger2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-tumblr" />
            <small>icon-tumblr</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-tumblr2" />
            <small>icon-tumblr2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-yahoo" />
            <small>icon-yahoo</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-tux" />
            <small>icon-tux</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-apple2" />
            <small>icon-apple2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-finder" />
            <small>icon-finder</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-android" />
            <small>icon-android</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-windows" />
            <small>icon-windows</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-windows8" />
            <small>icon-windows8</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-soundcloud" />
            <small>icon-soundcloud</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-soundcloud2" />
            <small>icon-soundcloud2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-skype" />
            <small>icon-icon-skype</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-reddit" />
            <small>icon-reddit</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-linkedin" />
            <small>icon-linkedin</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-linkedin2" />
            <small>icon-linkedin2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-lastfm" />
            <small>icon-lastfm</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-lastfm2" />
            <small>icon-lastfm2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-delicious" />
            <small>icon-delicious</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stumbleupon" />
            <small>icon-stumbleupon</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stumbleupon2" />
            <small>icon-stumbleupon2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-stackoverflow" />
            <small>icon-stackoverflow</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pinterest2" />
            <small>icon-pinterest2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-xing" />
            <small>icon-xing</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-flattr" />
            <small>icon-flattr</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-foursquare" />
            <small>icon-foursquare</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-paypal" />
            <small>icon-paypal</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-paypal2" />
            <small>icon-paypal2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-yelp" />
            <small>icon-yelp</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-pdf" />
            <small>icon-file-pdf</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-openoffice" />
            <small>icon-file-openoffice</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-word" />
            <small>icon-file-word</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-excel" />
            <small>icon-file-excel</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-libreoffice" />
            <small>icon-libreoffice</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-html5" />
            <small>icon-html5</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-html52" />
            <small>icon-html52</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-css3" />
            <small>icon-css3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-git" />
            <small>icon-git</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-svg" />
            <small>icon-svg</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-codepen" />
            <small>icon-codepen</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-chrome" />
            <small>icon-chrome</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-firefox" />
            <small>icon-firefox</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-IE" />
            <small>icon-IE</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-opera" />
            <small>icon-opera</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-safari" />
            <small>icon-safari</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-check2" />
            <small>icon-check2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-home4" />
            <small>icon-home4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-people" />
            <small>icon-people</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-checkmark-circle2" />
            <small>icon-checkmark-circle2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-up-left32" />
            <small>icon-arrow-up-left32</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-up52" />
            <small>icon-arrow-up52</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-up-right32" />
            <small>icon-arrow-up-right32</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-right6" />
            <small>icon-arrow-right6</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-down-right32" />
            <small>icon-arrow-down-right32</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-down52" />
            <small>icon-arrow-down52</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-down-left32" />
            <small>icon-arrow-down-left32</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-left52" />
            <small>icon-arrow-left52</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-calendar5" />
            <small>icon-calendar5</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-move-alt1" />
            <small>icon-move-alt1</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-reload-alt" />
            <small>icon-reload-alt</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-move-vertical" />
            <small>icon-move-vertical</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-move-horizontal" />
            <small>icon-move-horizontal</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-hash" />
            <small>icon-hash</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-bars-alt" />
            <small>icon-bars-alt</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-eye8" />
            <small>icon-eye8</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-search4" />
            <small>icon-search4</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-zoomin3" />
            <small>icon-zoomin3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-zoomout3" />
            <small>icon-zoomout3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-add" />
            <small>icon-add</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-subtract" />
            <small>icon-subtract</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-exclamation" />
            <small>icon-exclamation</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-question6" />
            <small>icon-question6</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-close2" />
            <small>icon-close2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-task" />
            <small>icon-task</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-inbox" />
            <small>icon-inbox</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-inbox-alt" />
            <small>icon-inbox-alt</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-envelope" />
            <small>icon-envelope</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-compose" />
            <small>icon-compose</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-newspaper2" />
            <small>icon-newspaper2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-calendar22" />
            <small>icon-calendar22</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-hyperlink" />
            <small>icon-hyperlink</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-trash" />
            <small>icon-trash</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-trash-alt" />
            <small>icon-trash-alt</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-grid5" />
            <small>icon-grid5</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-grid-alt" />
            <small>icon-grid-alt</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-menu6" />
            <small>icon-menu6</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-list3" />
            <small>icon-list3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-gallery" />
            <small>icon-gallery</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-calculator" />
            <small>icon-calculator</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-windows2" />
            <small>icon-windows2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-browser" />
            <small>icon-browser</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-portfolio" />
            <small>icon-portfolio</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-comments" />
            <small>icon-comments</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-screen3" />
            <small>icon-screen3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-iphone" />
            <small>icon-iphone</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-ipad" />
            <small>icon-ipad</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-googleplus5" />
            <small>icon-googleplus5</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pin" />
            <small>icon-pin</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pin-alt" />
            <small>icon-pin-alt</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cog5" />
            <small>icon-cog5</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-graduation" />
            <small>icon-graduation</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-air" />
            <small>icon-air</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-droplets" />
            <small>icon-droplets</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-statistics" />
            <small>icon-statistics</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-pie5" />
            <small>icon-pie5</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-cross" />
            <small>icon-cross</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-minus2" />
            <small>icon-minus2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-plus2" />
            <small>icon-plus2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-info3" />
            <small>icon-info3</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-info22" />
            <small>icon-info22</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-question7" />
            <small>icon-question7</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-help" />
            <small>icon-help</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-warning2" />
            <small>icon-warning2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-add-to-list" />
            <small>icon-add-to-list</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-left12" />
            <small>icon-arrow-left12</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-down12" />
            <small>icon-arrow-down12</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-up12" />
            <small>icon-arrow-up12</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-right13" />
            <small>icon-arrow-right13</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-left22" />
            <small>icon-arrow-left22</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-down22" />
            <small>icon-arrow-down22</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-up22" />
            <small>icon-arrow-up22</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-right22" />
            <small>icon-arrow-right22</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-left32" />
            <small>icon-arrow-left32</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-down32" />
            <small>icon-arrow-down32</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-up32" />
            <small>icon-arrow-up32</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-right32" />
            <small>icon-arrow-right32</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-switch2" />
            <small>icon-switch2</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-checkmark5" />
            <small>icon-checkmark5</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-ampersand" />
            <small>icon-ampersand</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-alert" />
            <small>icon-alert</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-alignment-align" />
            <small>icon-alignment-align</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-alignment-aligned-to" />
            <small>icon-alignment-aligned-to</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-alignment-unalign" />
            <small>icon-alignment-unalign</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-down132" />
            <small>icon-arrow-down132</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2"><Icon className="icon-arrow-up13" />
            <small>icon-arrow-up13</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2"><Icon className="icon-arrow-left13" />
            <small>icon-arrow-left13</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2"><Icon className="icon-arrow-right14" />
            <small>icon-arrow-right14</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-small-down" />
            <small>icon-arrow-small-down</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-small-left" />
            <small>icon-arrow-small-left</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-small-right" />
            <small>icon-arrow-small-right</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-arrow-small-up" />
            <small>icon-arrow-small-up</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-check" />
            <small>icon-check</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-chevron-down" />
            <small>icon-chevron-down </small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-chevron-left" />
            <small>icon-chevron-left</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-chevron-right" />
            <small>icon-chevron-right</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-chevron-up" />
            <small>icon-chevron-up</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-clippy" />
            <small>icon-clippy</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-comment" />
            <small>icon-comment</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-comment-discussion" />
            <small>icon-comment-discussion</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-dash" />
            <small>icon-dash</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-diff" />
            <small>icon-diff</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-diff-added" />
            <small>icon-diff-added</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-diff-ignored" />
            <small>icon-diff-ignored</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-diff-modified" />
            <small>icon-diff-modified</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-diff-removed" />
            <small>icon-diff-removed</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-renamed" />
            <small>icon-renamed</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-file-media" />
            <small>icon-file-media</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-fold" />
            <small>icon-fold</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-icon-gear" />
            <small>icon-icon-gear</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-git-branch" />
            <small>icon-git-branch</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-git-commit" />
            <small>icon-git-commit</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-git-compare" />
            <small>icon-git-compare</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-git-merge" />
            <small>icon-git-merge</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-git-pull" />
            <small>icon-git-pull</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-graph" />
            <small>icon-graph</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-law" />
            <small>icon-law</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-list-ordered" />
            <small>icon-list-ordered</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-list-unordered" />
            <small>icon-list-unordered</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-mail5" />
            <small>icon-mail5</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-mail-read" />
            <small>icon-mail-read</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-mention" />
            <small>icon-mention</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-mirror" />
            <small>icon-mirror</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-move-down" />
            <small>icon-move-down</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-move-left" />
            <small>icon-move-left</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-move-right" />
            <small>icon-move-right</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-move-up" />
            <small>icon-move-up</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-person" />
            <small>icon-person</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-plus22" />
            <small>icon-plus22</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-primitive-dot" />
            <small>icon-primitive-dot</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-primitive-square" />
            <small>icon-primitive-square</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-repo-forked" />
            <small>icon-repo-forked</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-screen-full" />
            <small>icon-screen-full</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-screen-normal" />
            <small>icon-screen-normal</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-sync" />
            <small>icon-sync</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-three-bars" />
            <small>icon-three-bars</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-unfold" />
            <small>icon-unfold</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-versions" />
            <small>icon-versions</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="icon-x" />
            <small>icon-x</small>
          </div>
        </Col>
      </Row>
    </div>;
  };

  // Description: Custom Icons for drive
  const PreveilDriveIcons = () => {
    return <div className="drive_icons">
      <Row className="align-items-center">
        <h2>Default Sized Icons</h2>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="fsi-synced" />
            <small>fsi-synced</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="fsi-synced-partial" />
            <small>fsi-synced-partial</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="fsi-folder" />
            <small>fsi-folder</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="fsi-unsynced-partial" />
            <small>fsi-unsynced-partial</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="fsi-shared-synced" />
            <small>fsi-shared-synced</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="fsi-shared-synced-partial" />
            <small>fsi-shared-synced-partial</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="fsi-shared" />
            <small>fsi-shared</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="fsi-shared-unsynced-partial" />
            <small>fsi-shared-unsynced-partial</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="fsi-pending" />
            <small>fsi-pending</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="fsi-shared-pending" />
            <small>fsi-shared-pending</small>
          </div>
        </Col>
      </Row>
      <h2>Small Icons</h2>
      <Row className="align-items-center">
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="fsi-synced sm" />
            <small>fsi-synced sm</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="fsi-synced-partial sm" />
            <small>fsi-synced-partial sm</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="fsi-folder sm" />
            <small>fsi-folder sm</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="fsi-unsynced-partial sm" />
            <small>fsi-unsynced-partial sm</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="fsi-shared-synced sm" />
            <small>fsi-shared-synced sm</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="fsi-shared-synced-partial sm" />
            <small>fsi-shared-synced-partial sm</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="fsi-shared sm" />
            <small>fsi-shared sm</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="fsi-shared-unsynced-partial sm" />
            <small>fsi-shared-unsynced-partial sm</small>
          </div>
        </Col>
      </Row>
      <h2>Extra Large Icons</h2>
      <Row className="align-items-center">
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="fsi-synced xlg" />
            <small>fsi-synced xlg</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="fsi-synced-partial xlg" />
            <small>fsi-synced-partial xlg</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="fsi-folder xlg" />
            <small>fsi-folder xlg</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="fsi-unsynced-partial xlg" />
            <small>fsi-unsynced-partial xlg</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="fsi-shared-synced xlg" />
            <small>fsi-shared-synced xlg</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="fsi-shared-synced-partial xlg" />
            <small>fsi-shared-synced-partial xlg</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="fsi-shared xlg" />
            <small>fsi-shared xlg</small>
          </div>
        </Col>
        <Col lg={3} sm={4}>
          <div className="p-2 border mb-2">
            <Icon className="fsi-shared-unsynced-partial xlg" />
            <small>fsi-shared-unsynced-partial xlg</small>
          </div>
        </Col>
      </Row>
    </div>;
  };

  return <>
    <h4>ICONS</h4>
    <Accordion className="accordian mt-4" defaultActiveKey="3">
      <Accordion.Item eventKey="0">
        <Accordion.Header>PreVeil Icons</Accordion.Header>
        <Accordion.Body>
          <BasicPreveilIcons />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Feather Icons</Accordion.Header>
        <Accordion.Body>
          <FeatherIcons />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Additional Icons</Accordion.Header>
        <Accordion.Body>
          <AdditionalIcons />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Drive Icons</Accordion.Header>
        <Accordion.Body>
          <PreveilDriveIcons />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  </>;
}

export default React.memo(IconsComponent);
