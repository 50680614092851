/*
* Description: Collection API endpoints: RTK Hooks and types
* Author:      PreVeil, LLC
*/
import {
  AccountType, IFetchResponse, CollectionServerError, PlatformType, MailboxesBase, CollectionThreadsPage, JSONBlock, JSONRequestBlock,
  JSONUrlsResponseBlock, JSONApiBlockResponse, CollectionServerFindUsers, VersionedEmailBlock, MailMessageV4, UserInvite, MailMessageResponse,
  CollectionServerRevId, Invite, MailboxNotificationCounts, SetCollectionsFlagsParams, CollectionThreadMessages, JSONOrgInfo, UUID, LogKey,
  ExportShard, Event, RequestMetadataObject,
  DeviceBase,
  EmptyObject
} from "@preveil-api";
import { AccountIdentifiers, BaseAPI, ApiMethods, MessageLatest, CURRENT_COLLECTION_API_VERSION } from "src/common";
import { collectionServerAPI as api } from "../base/base-api";
import _ from "lodash";

export interface CollectionServerFetchRequest<T> {
  account_ids: AccountIdentifiers;
  body: T;
}

const base_url = `${process.env.REACT_APP_BACKEND_SERVER}`;
const default_headers: Record<string, string> = {
  "Content-Type": "application/json",
  "accept-version": CURRENT_COLLECTION_API_VERSION
};
// --------------------------------------------------------------------------------------------
// RTK Queries Hooks: Open API generated endpoints and types 
// --------------------------------------------------------------------------------------------
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    // ------------------------------------------ USING QUERIES ------------------------------------------ //
    postUsers: build.mutation<PostUsersApiResponse, PostUsersApiArg>({
      // using only this one
      query: (queryArg) => ({ url: `/users`, method: ApiMethods.post, body: queryArg }),
    }),
    getUsersType: build.query<GetUsersTypeApiResponse, GetUsersTypeApiArg>({
      // using this one
      query: (queryArg) => ({ url: `/users/type`, params: { user_id: queryArg.userId } }),
    }),
    // Custom Mutation for get
    postUsersType: build.mutation<GetUsersTypeApiResponse, GetUsersTypeApiArg>({
      // using this one
      query: (queryArg) => ({
        url: `/users/type`,
        method: ApiMethods.get,
        params: { user_id: queryArg.userId },
      }),
    }),
    postUsersVerify: build.mutation<PostUsersVerifyApiResponse, PostUsersVerifyApiArg>({
      query: (queryArg) => ({
        url: `/users/verify?user_id=${!!queryArg.user_id ? encodeURIComponent(queryArg.user_id) : ""
          }`,
        method: ApiMethods.post,
        body: { secret: queryArg.secret },
      }),
    }),
    getUsersApproversSecret: build.query<
      GetUsersApproversSecretApiResponse,
      GetUsersApproversSecretApiArg
    >({
      query: (queryArg) => ({
        url: `/users/approvers/secret`,
        params: { user_id: queryArg.userId },
      }),
    }),
    // -----------------------------------------------------------------------------------------------
    // The Hooks below require dynamically built headers:
    // NOTE: Use queryFn with BaseAPI to pass the custom headers
    // queryFn: async (params, queryApi, extraOptions, baseQuery) => {...}
    // Returns: { data: typed, errors: IFetchResponse }
    // -----------------------------------------------------------------------------------------------
    deleteUsers: build.mutation<DeleteUsersApiResponse, CollectionServerFetchRequest<DeleteUsersApiArg>>({
      // query: (queryArg) => ({ url: `/users`, method: "DELETE", body: queryArg.body }),
      queryFn: async (params) => {
        const url = "/users";
        const headers = await BaseAPI.prepareSignedRequestHeader(
          params.account_ids,
          url,
          ApiMethods.delete,
          params.body,
        );
        const result: IFetchResponse = await BaseAPI.axiosDelete(`${base_url}${url}`, params.body, headers);
        return result.data
          ? { data: result.data as DeleteUsersApiArg }
          : { error: result as IFetchResponse };
      },
    }),
    postUsersFind: build.mutation<PostUsersFindApiResponse, CollectionServerFetchRequest<PostUsersFindApiArg>>({
      queryFn: async (params) => {
        const url = "/users/find";
        const headers = await BaseAPI.prepareSignedRequestHeader(
          params.account_ids,
          url,
          ApiMethods.post,
          params.body
        );
        const result: IFetchResponse = await BaseAPI.post(
          `${base_url}${url}`,
          params.body,
          headers
        );
        return result.data
          ? { data: result.data as CollectionServerFindUsers }
          : { error: result as IFetchResponse };
      },
    }),
    putUsersKeyval: build.mutation<
      PutUsersKeyvalApiResponse,
      CollectionServerFetchRequest<PutUsersKeyvalApiArg>
    >({
      queryFn: async (params) => {
        const url = "/users/keyval";
        const headers = await BaseAPI.prepareSignedRequestHeader(
          params.account_ids,
          url,
          ApiMethods.put,
          params.body
        );
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, params.body, headers);
        return result.data
          ? { data: result.data as PutUsersKeyvalApiResponse }
          : { error: result as IFetchResponse };
      },
      invalidatesTags: ["keyval"],
    }),
    getUsersKeyval: build.query<
      GetUsersKeyvalApiResponse,
      CollectionServerFetchRequest<GetUsersKeyvalApiArg>
    >({
      queryFn: async (params) => {
        const url = "/users/keyval";
        const headers = await BaseAPI.prepareSignedRequestHeader(
          params.account_ids,
          url,
          ApiMethods.get,
          null
        );
        const result: IFetchResponse = await BaseAPI.get(`${base_url}${url}`, params.body, headers);
        return result.data
          ? { data: result.data as GetUsersKeyvalApiResponse }
          : { error: result as IFetchResponse };
      },
      providesTags: ["keyval"],
    }),
    // Settings
    //--------------------------------------------------------
    getUsersBackup: build.query<GetUsersBackupApiResponse, GetUsersBackupApiArg>({
      queryFn: async (params) => {
        const url = "/users/backup";
        const headers = Object.assign(
          {},
          default_headers,
          await BaseAPI.prepareSignedRequestHeader(params.account_ids, url, ApiMethods.get, null),
        );
        const result: IFetchResponse = await BaseAPI.get(`${base_url}${url}`, params.body, headers);
        return result.data
          ? { data: result.data as GetUsersBackupApiResponse }
          : { error: result as IFetchResponse };
      },
    }),
    putUsersBackup: build.mutation<PutUsersBackupApiResponse, PutUsersBackupApiArg>({
      queryFn: async (params) => {
        const url = "/users/backup";
        const headers = Object.assign(
          {},
          default_headers,
          await BaseAPI.prepareSignedRequestHeader(
            params.account_ids,
            url,
            ApiMethods.put,
            params.body,
          ),
        );
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, params.body, headers);
        return result.data
          ? { data: result.data as PutUsersBackupApiResponse }
          : { error: result as IFetchResponse };
      },
    }),
    deleteUsersBackup: build.mutation<DeleteUsersBackupApiResponse, DeleteUsersBackupApiArg>({
      queryFn: async (params) => {
        const url = "/users/backup";
        const headers = Object.assign(
          {},
          default_headers,
          await BaseAPI.prepareSignedRequestHeader(
            params.account_ids,
            url,
            ApiMethods.delete,
            params.body
          ),
        );
        const result: IFetchResponse = await BaseAPI.axiosDelete(
          `${base_url}${url}`,
          params.body,
          headers
        );
        return result.data
          ? { data: result.data as DeleteUsersBackupApiResponse }
          : { error: result as IFetchResponse };
      },
    }),
    postUsersDevicesKeys: build.mutation<
      PostUsersDevicesKeysApiResponse,
      CollectionServerFetchRequest<PostUsersDevicesKeysApiArg>
    >({
      queryFn: async (params) => {
        const url = `/users/devices/keys`;
        const headers = await BaseAPI.prepareSignedRequestHeader(
          params.account_ids,
          url,
          ApiMethods.post,
          params.body,
          true
        );
        const result: IFetchResponse = await BaseAPI.post(
          `${base_url}${url}`,
          params.body,
          headers,
        );
        return result.data
          ? { data: result.data as PostUsersDevicesKeysApiResponse }
          : { error: result as IFetchResponse };
      },
    }),
    getUsersDevicesKeys: build.mutation<GetUsersDevicesKeysApiResponse, CollectionServerFetchRequest<GetUsersDevicesKeysApiArg>>({
      queryFn: async (params) => {
        const url = `/users/devices/keys`;
        const headers = await BaseAPI.prepareSignedRequestHeader(
          params.account_ids,
          url,
          ApiMethods.get,
          null
        );
        const result: IFetchResponse = await BaseAPI.get(
          `${base_url}${url}`,
          { user_id: params.body.userId },
          headers,
        );
        return result.data
          ? { data: result.data as GetUsersDevicesKeysApiResponse }
          : { error: result as IFetchResponse };
      },
    }),
    deleteUsersDevicesKeysByDeviceId: build.mutation<
      DeleteUsersDevicesKeysByDeviceIdApiResponse,
      CollectionServerFetchRequest<DeleteUsersDevicesKeysByDeviceIdApiArg>
    >({
      queryFn: async (params) => {
        const url = `/users/devices/keys/${params.body.device_id}`;
        const body = { user_id: params.body.user_id };
        const headers = await BaseAPI.prepareSignedRequestHeader(
          params.account_ids,
          url,
          ApiMethods.delete,
          body
        );
        const result: IFetchResponse = await BaseAPI.axiosDelete(
          `${base_url}${url}`,
          body,
          headers,
        );
        return result.data
          ? { data: result.data as DeleteUsersDevicesKeysByDeviceIdApiResponse }
          : { error: result as IFetchResponse };
      },
    }),
    // Description: Gets the list of approvals requested of the user
    getUsersApprovals: build.query<GetUsersApprovalsApiResponse, CollectionServerFetchRequest<GetUsersApprovalsApiArg>>({
      queryFn: async (params) => {
        const url = "/users/approvals";
        const headers = Object.assign(
          {},
          default_headers,
          await BaseAPI.prepareSignedRequestHeader(params.account_ids, url, ApiMethods.get, null),
        );
        const result: IFetchResponse = await BaseAPI.get(`${base_url}${url}`, params.body, headers);
        return result.data
          ? { data: result.data as GetUsersApprovalsApiResponse }
          : { error: result as IFetchResponse };
      },
    }),
    // ------------------------------------------/ END OF USING  ------------------------------------------ //
    // ---------------------------------------------------------------------------------------------------------
    // MailBoxes: https://github.com/PreVeil/core/blob/dev/collection_server/docs/mail.md#get-mailcidmailboxes
    // ---------------------------------------------------------------------------------------------------------
    // Description: Retrieves a list of mailboxes created or deleted since the specified revision.
    // Note: Optionally (default: true), fetches all messages since that revision as well
    getMailByCollectionIdMailboxes: build.query<
      MailboxesBase,
      CollectionServerFetchRequest<GetMailByCollectionIdMailboxesApiArg>
    >({
      queryFn: async (params) => {
        const url = `/mail/${params.body.collectionId}/mailboxes`;
        const headers = await BaseAPI.prepareSignedRequestHeader(
          params.account_ids,
          url,
          ApiMethods.get,
          null,
        );
        const result: IFetchResponse = await BaseAPI.get(
          `${base_url}${url}`,
          { since_rev_id: params.body.since_rev_id, fetch_messages: params.body.fetch_messages },
          headers,
        );
        return !result.isError
          ? { data: result.data as MailboxesBase }
          : { error: result as IFetchResponse };
      },
      providesTags: ["mailbox"],
    }),
    // Description: Adds a new mailbox to the user's mail collection
    postMailByCollectionIdMailboxes: build.mutation<
      PostMailboxesApiResponse,
      CollectionServerFetchRequest<PostMailboxesApiArg>
    >({
      queryFn: async (params) => {
        const url = `/mail/${params.body.collectionId}/mailboxes`;
        const body = { name: params.body.name };
        const headers = await BaseAPI.prepareSignedRequestHeader(
          params.account_ids,
          url,
          ApiMethods.post,
          body,
        );
        const result: IFetchResponse = await BaseAPI.post(`${base_url}${url}`, body, headers);
        return !result.isError
          ? { data: result.data as PostMailboxesApiResponse }
          : { error: result as IFetchResponse };
      },
      invalidatesTags: ["mailbox"],
    }),
    // Description: Renames a mailbox
    putMailByCollectionIdMailboxesAndMbid: build.mutation<
      PutMailboxesAndMbidApiResponse,
      CollectionServerFetchRequest<PutMailboxesAndMbidApiArg>
    >({
      queryFn: async (params) => {
        const url = `/mail/${params.body.collectionId}/mailboxes/${params.body.mbid}`;
        const body = { name: params.body.name };
        const headers = await BaseAPI.prepareSignedRequestHeader(
          params.account_ids,
          url,
          ApiMethods.put,
          body,
        );
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, body, headers);
        return !result.isError
          ? { data: result.data as PutMailboxesAndMbidApiResponse }
          : { error: result as IFetchResponse };
      },
      invalidatesTags: ["mailbox"],
    }),
    // Description: Deletes a mailbox
    deleteMailByCollectionIdMailboxesAndMbid: build.mutation<
      DeleteMailboxesApiResponse,
      CollectionServerFetchRequest<DeleteMailboxesApiArg>
    >({
      queryFn: async (params) => {
        const url = `/mail/${params.body.collectionId}/mailboxes/${params.body.mbid}`;
        const headers = await BaseAPI.prepareSignedRequestHeader(
          params.account_ids,
          url,
          ApiMethods.delete,
          null,
        );
        const result: IFetchResponse = await BaseAPI.axiosDelete(
          `${base_url}${url}`,
          null,
          headers,
        );
        return !result.isError
          ? { data: result.data as DeleteMailboxesApiResponse }
          : { error: result as IFetchResponse };
      },
      invalidatesTags: ["mailbox"],
    }),
    // ---------------------------------------------------------------------------------------------------------
    // Mail: Unseen Counts:
    // ---------------------------------------------------------------------------------------------------------
    getMailByCollectionIdMailboxesAndMbidUnseenCount: build.query<
      GetMailUnseenCountApiResponse,
      CollectionServerFetchRequest<GetMailUnseenCountApiArg>
    >({
      queryFn: async (params) => {
        const url = `/mail/${params.body.collectionId}/mailboxes/${params.body.mbid}/unseen_count`;
        const headers = await BaseAPI.prepareSignedRequestHeader(
          params.account_ids,
          url,
          ApiMethods.get,
          null,
        );
        const result: IFetchResponse = await BaseAPI.get(`${base_url}${url}`, null, headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      },
    }),
    // Description: NEW Endpoint (https://github.com/PreVeil/core/blob/dev/collection_server/docs/mail.md)
    getSummaryCounts: build.query<
      GetSummaryCountsApiResponse,
      CollectionServerFetchRequest<GetSummaryCountsApiArg>
    >({
      queryFn: async (params) => {
        const url = `/mail/${params.body.collectionId}/summary_counts`;
        const headers = await BaseAPI.prepareSignedRequestHeader(
          params.account_ids,
          url,
          ApiMethods.get,
          null,
        );
        const result: IFetchResponse = await BaseAPI.get(`${base_url}${url}`, null, headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      },
    }),
    // ---------------------------------------------------------------------------------------------------------
    // Threads:
    // ---------------------------------------------------------------------------------------------------------
    // Description: Get threads by page
    getMailByCollectionIdMailboxesAndMbidThreads: build.query<
      CollectionThreadsPage,
      CollectionServerFetchRequest<GetMailThreadsArgs>
    >({
      queryFn: async (params) => {
        const url = `/mail/${params.body.collectionId}/mailboxes/${params.body.mbid}/threads`;
        const headers = await BaseAPI.prepareSignedRequestHeader(
          params.account_ids,
          url,
          ApiMethods.get,
          null,
        );
        const result: IFetchResponse = await BaseAPI.get(
          `${base_url}${url}`,
          { limit: params.body.limit, offset: params.body.offset, fetch_expired: params.body.fetch_expired || false },
          headers,
        );
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      },
      providesTags: ["threads"],
    }),
    // Gets blocks from Collection server = deprecated in favor of  postStorageDownloadDirect and postStorageDownloadProxy
    getStorageByCollectionIdBlocksAndBlockId: build.query<
      GetStorageByCollectionIdBlocksAndBlockIdApiResponse,
      CollectionServerFetchRequest<GetStorageByCollectionIdBlocksAndBlockIdApiArg>
    >({
      queryFn: async (params) => {
        const url = `/storage/${params.body.collectionId}/blocks?block_ids=${encodeURIComponent(
          params.body.blockId,
        )}`;
        const headers = await BaseAPI.prepareSignedRequestHeader(
          params.account_ids,
          url,
          ApiMethods.get,
          null,
        );
        const result: IFetchResponse = await BaseAPI.get(`${base_url}${url}`, null, headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      },
    }),
    postStorageDownloadDirect: build.mutation<
      PostStorageDownloadApiResponse,
      CollectionServerFetchRequest<PostStorageDownloadApiArg>
    >({
      queryFn: async (params) => {
        const url = `/storage/download/direct`;
        const body = { blocks: params.body.blocks };
        const headers = await BaseAPI.prepareSignedRequestHeader(
          params.account_ids,
          url,
          ApiMethods.post,
          body,
        );
        const result: IFetchResponse = await BaseAPI.post(`${base_url}${url}`, body, headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      },
    }),
    postStorageDownloadProxy: build.mutation<
      JSONApiBlockResponse,
      CollectionServerFetchRequest<PostStorageDownloadApiArg>
    >({
      queryFn: async (params) => {
        const url = `/storage/download/proxy`;
        const body = { blocks: params.body.blocks };
        const headers = await BaseAPI.prepareSignedRequestHeader(
          params.account_ids,
          url,
          ApiMethods.post,
          body,
        );
        const result: IFetchResponse = await BaseAPI.post(`${base_url}${url}`, body, headers);
        const is_error =
          result.isError || (Array.isArray(result.data.errors) && result.data.errors.length);
        return !is_error
          ? { data: result.data as JSONApiBlockResponse }
          : { error: result as IFetchResponse };
      },
    }),
    postStorageByCollectionIdBlocks: build.mutation<
      PostStorageByCollectionIdBlocksApiResponse,
      CollectionServerFetchRequest<PostStorageByCollectionIdBlocksApiArg>
    >({
      queryFn: async (params) => {
        const url = `/storage/${params.body.collectionId}/blocks`;
        const body = { blocks: params.body.blocks };
        const headers = await BaseAPI.prepareSignedRequestHeader(
          params.account_ids,
          url,
          ApiMethods.post,
          body,
        );
        const result: IFetchResponse = await BaseAPI.post(`${base_url}${url}`, body, headers);
        const is_error =
          result.isError || (Array.isArray(result.data.errors) && result.data.errors.length);
        return !is_error
          ? { data: result.data as PostStorageByCollectionIdBlocksApiResponse }
          : { error: result as IFetchResponse };
      },
    }),
    getUsersInvite: build.query<
      GetUsersInviteApiResponse,
      CollectionServerFetchRequest<GetUsersInviteApiArg>
    >({
      queryFn: async (params) => {
        const url = "/users/invite";
        const headers = await BaseAPI.prepareSignedRequestHeader(
          params.account_ids,
          url,
          ApiMethods.get,
          null,
        );
        const result: IFetchResponse = await BaseAPI.get(`${base_url}${url}`, params.body, headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      },
    }),
    // Description: Send Invites to unclaimed user accounts
    putUsersInvite: build.mutation<
      PutUsersInviteApiResponse,
      CollectionServerFetchRequest<PutUsersInviteApiArg>
    >({
      queryFn: async (params) => {
        const url = "/users/invite";
        const body = { user_id: params.body.user_id, invitee_email: params.body.invitee_email };
        const headers = await BaseAPI.prepareSignedRequestHeader(
          params.account_ids,
          url,
          ApiMethods.put,
          body,
        );
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, body, headers);
        return !result.isError
          ? { data: result.data as PutUsersInviteApiResponse }
          : { error: result as IFetchResponse };
      },
    }),
    // NOTES: postMailByCollectionId: Soft Deprecated call (V4 emails) - Legacy: backend_service.sendEmail(this.sender, recipient, metadata);
    // https://github.com/PreVeil/core/blob/dev/collection_server/docs/mail.md#post-mailcid
    //postMailByCollectionId: build.mutation<PostMailByCollectionIdApiResponse, PostMailByCollectionIdApiArg>({ ...}),
    // https://github.com/PreVeil/core/blob/dev/collection_server/docs/mail.md#post-mailsend
    postMailSend: build.mutation<
      PostMailSendApiResponse,
      CollectionServerFetchRequest<PostMailSendApiArg>
    >({
      queryFn: async (params) => {
        const url = "/mail/send";
        const headers = await BaseAPI.prepareSignedRequestHeader(
          params.account_ids,
          url,
          ApiMethods.post,
          params.body.message,
        );
        const result: IFetchResponse = await BaseAPI.post(
          `${base_url}${url}`,
          params.body.message,
          headers,
        );
        return !result.isError
          ? { data: result.data as PostMailSendApiResponse }
          : { error: result as IFetchResponse };
      },
    }),
    // Description: UPDATE FLAGS - Legacy updateFlags() - after send email flags need to be updated
    patchMailByCollectionIdMailboxesAndMbidMessages: build.mutation<
      UpdateFlagsApiResponse,
      CollectionServerFetchRequest<UpdateFlagsApiArg>
    >({
      queryFn: async (params) => {
        const url = `/mail/${params.body.collectionId}/mailboxes/${params.body.mbid}/messages`;
        const updates = { updates: params.body.updates };
        const headers = await BaseAPI.prepareSignedRequestHeader(
          params.account_ids,
          url,
          ApiMethods.patch,
          updates,
        );
        const result: IFetchResponse = await BaseAPI.patch(`${base_url}${url}`, updates, headers);
        return !result.isError
          ? { data: result.data as UpdateFlagsApiResponse }
          : { error: result as IFetchResponse };
      },
    }),
    // Description: Copy a message to another mailbox - for deleting messages
    postMailByCollectionIdMailboxesAndMbidMessagesCopy: build.mutation<
      PostMessagesCopyApiResponse,
      CollectionServerFetchRequest<PosMessagesCopyApiArg>
    >({
      queryFn: async (params) => {
        const url = `/mail/${params.body.collectionId}/mailboxes/${params.body.mbid}/messages/copy`;
        const update = { uids: params.body.uids, source_mbid: params.body.source_mbid };
        const headers = await BaseAPI.prepareSignedRequestHeader(
          params.account_ids,
          url,
          ApiMethods.post,
          update,
        );
        const result: IFetchResponse = await BaseAPI.post(`${base_url}${url}`, update, headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      },
    }),
    // Note: This is a single send per recipient (collectionId is the recipients coll id)
    postMailByCollectionId: build.mutation<
      PostMailByCollectionIdApiResponse,
      CollectionServerFetchRequest<PostMailByCollectionIdApiArg>
    >({
      queryFn: async (params) => {
        const url = `/mail/${params.body.collectionId}`;
        const headers = await BaseAPI.prepareSignedRequestHeader(
          params.account_ids,
          url,
          ApiMethods.post,
          params.body.message,
        );
        const result: IFetchResponse = await BaseAPI.post(
          `${base_url}${url}`,
          params.body.message,
          headers,
        );
        return !result.isError
          ? { data: result.data as PostMailByCollectionIdApiResponse }
          : { error: result as IFetchResponse };
      },
    }),
    // Description: Used for copying a message to the send mailbox on send mail (V4 protocol only)
    postMailByCollectionIdMailboxesAndMbidMessages: build.mutation<
      PostMailByCollectionIdMailboxesAndMbidMessagesApiResponse,
      CollectionServerFetchRequest<PostMailByCollectionIdMailboxesAndMbidMessagesApiArg>
    >({
      queryFn: async (params) => {
        const url = `/mail/${params.body.collectionId}/mailboxes/${params.body.mbid}/messages`;
        const message = { message: params.body.message };
        const headers = await BaseAPI.prepareSignedRequestHeader(
          params.account_ids,
          url,
          ApiMethods.post,
          message,
        );
        const result: IFetchResponse = await BaseAPI.post(`${base_url}${url}`, message, headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      },
    }),
    // Deletes a message from mailbox (used for deleting drafts)
    deleteMailByColIdMbidMessId: build.mutation<
      DeleteMailByColIdMbidMessIdApiResponse,
      CollectionServerFetchRequest<DeleteMailByColIdMbidMessIdApiArg>
    >({
      queryFn: async (params) => {
        const url = `/mail/${params.body.collectionId}/mailboxes/${params.body.mbid}/messages/${params.body.id}`;
        const headers = await BaseAPI.prepareSignedRequestHeader(
          params.account_ids,
          url,
          ApiMethods.delete,
          null,
        );
        const result: IFetchResponse = await BaseAPI.axiosDelete(
          `${base_url}${url}`,
          null,
          headers,
        );
        return !result.isError
          ? { data: result.data as DeleteMailByColIdMbidMessIdApiResponse }
          : { error: result as IFetchResponse };
      },
    }),
    putMailByCollectionIdThreadsAndTid: build.mutation<
      PutMailByCollectionIdThreadsAndTidApiResponse,
      CollectionServerFetchRequest<MoveMailApiArg>
    >({
      queryFn: async (params) => {
        const url = `/mail/${params.body.collectionId}/threads/${params.body.tid}`;
        const body = {
          source_mailbox: params.body.source_mailbox,
          dest_mailbox: params.body.dest_mailbox,
        };
        const headers = await BaseAPI.prepareSignedRequestHeader(
          params.account_ids,
          url,
          ApiMethods.put,
          body,
        );
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, body, headers);
        return !result.isError
          ? { data: result.data as PutMailByCollectionIdThreadsAndTidApiResponse }
          : { error: result as IFetchResponse };
      },
    }),
    // ---------------------------------------------------------------------------------------------------------
    // ADMIN AND ORG:
    // ---------------------------------------------------------------------------------------------------------
    putUsersOrgsByEntityIdKeyval: build.mutation<
      PutUsersOrgsByEntityIdKeyvalApiResponse,
      CollectionServerFetchRequest<PutUsersOrgsByEntityIdKeyvalApiArg>
    >({
      queryFn: async (params) => {
        const url = `/users/orgs/${params.body.entityId}/keyval`;
        const body = {
          value: params.body.value,
          key: params.body.key,
        };
        const headers = Object.assign({}, default_headers, await BaseAPI.prepareSignedRequestHeader(params.account_ids, url, ApiMethods.put, body));
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, body, headers);
        return !result.isError ? { data: result.data as PutUsersOrgsByEntityIdKeyvalApiResponse } :
          { error: result as IFetchResponse };
      },
    }),
    patchUsersOrgsByEntityIdShowMuaPrompt: build.mutation<
      PatchUsersOrgsByEntityIdShowMuaPromptApiResponse,
      CollectionServerFetchRequest<PatchUsersOrgsByEntityIdShowMuaPromptApiArg>
    >({
      queryFn: async (params) => {
        const url = `/users/orgs/${params.body.entityId}/show_mua_prompt`;
        const body = {
          show_prompt: params.body.show_prompt
        };
        const headers = Object.assign({}, default_headers, await BaseAPI.prepareSignedRequestHeader(params.account_ids, url, ApiMethods.patch, body));
        const result: IFetchResponse = await BaseAPI.patch(`${base_url}${url}`, body, headers);
        return !result.isError ? { data: result.data as PatchUsersOrgsByEntityIdShowMuaPromptApiResponse } :
          { error: result as IFetchResponse };
      },
    }),
    postUsersOrgsByEntityIdAllowAddDevice: build.mutation<
      PostUsersOrgsByEntityIdAllowAddDeviceApiResponse,
      CollectionServerFetchRequest<PostUsersOrgsByEntityIdAllowAddDeviceApiArg>
    >({
      queryFn: async (params) => {
        const url = !!params.body.userId ? `/users/orgs/${params.body.entityId}/allow_add_device/override` : `/users/orgs/${params.body.entityId}/allow_add_device`;
        const body = {
          is_allowed: params.body.is_allowed,
          user_id: params.body.userId
        };
        const headers = Object.assign({}, default_headers, await BaseAPI.prepareSignedRequestHeader(params.account_ids, url, ApiMethods.post, body));
        const result: IFetchResponse = await BaseAPI.post(`${base_url}${url}`, body, headers);
        return !result.isError ? { data: result.data as PostUsersOrgsByEntityIdAllowAddDeviceApiResponse } :
          { error: result as IFetchResponse };
      },
      invalidatesTags: (result, error, arg) => {
        const { body } = arg;
        return !body?.userId || error ? [] : ["org_data"];
      }
    }),
    // ADMIN EMAIL GATEWAY
    getUsersOrgsByEntityIdGateway: build.query<
      GetUsersOrgsByEntityIdGatewayApiResponse,
      CollectionServerFetchRequest<GetUsersOrgsByEntityIdGatewayApiArg>
    >({
      queryFn: async (params) => {
        const url = `/users/orgs/${params.body.entityId}/gateway`;
        const headers = Object.assign({}, default_headers, await BaseAPI.prepareSignedRequestHeader(params.account_ids, url, ApiMethods.get, null));
        const result: IFetchResponse = await BaseAPI.get(`${base_url}${url}`, null, headers);
        if (result.isError) {
          return { error: result as IFetchResponse }
        }
        const { user_id, enabled, health_history } = result.data;
        const aliases = result.data.aliases.map((alias: any) => alias.email_address);
        const response: GetUsersOrgsByEntityIdGatewayApiResponse = { aliases, user_id, enabled, health_history };
        return { data: response }
      },
      providesTags: ["gateway"],
    }),
    postUsersOrgsByEntityIdGatewayAliasesRemove: build.mutation<
      PostUsersOrgsByEntityIdGatewayAliasesRemoveApiResponse,
      CollectionServerFetchRequest<PostUsersOrgsByEntityIdGatewayAliasesRemoveApiArg>
    >({
      queryFn: async (params) => {
        const url = `/users/orgs/${params.body.entityId}/gateway/aliases/remove`;
        const headers = Object.assign({}, default_headers, await BaseAPI.prepareSignedRequestHeader(params.account_ids, url, ApiMethods.post, { aliases: params.body.aliases }));
        const result: IFetchResponse = await BaseAPI.post(`${base_url}${url}`, { aliases: params.body.aliases }, headers);
        if (result.isError) {
          return { error: result as IFetchResponse }
        }
        return { data: result.data as PostUsersOrgsByEntityIdGatewayAliasesRemoveApiResponse };
      },
      invalidatesTags: ["gateway"]
    }),
    getUsersOrgsByEntityIdGatewayStatus: build.query<
      GetUsersOrgsByEntityIdGatewayStatusApiResponse,
      CollectionServerFetchRequest<GetUsersOrgsByEntityIdGatewayStatusApiArg>
    >({
      queryFn: async (params) => {
        const url = `/users/orgs/${params.body.entityId}/gateway/status`;
        const headers = Object.assign({}, default_headers, await BaseAPI.prepareSignedRequestHeader(params.account_ids, url, ApiMethods.get, null));
        const result: IFetchResponse = await BaseAPI.get(`${base_url}${url}`, null, headers);
        if (result.isError) {
          return { error: result as IFetchResponse }
        }
        return { data: result.data as GetUsersOrgsByEntityIdGatewayStatusApiResponse };
      }
    }),
    postUsersOrgsByEntityIdGatewayAliases: build.mutation<
      PostUsersOrgsByEntityIdGatewayAliasesApiResponse,
      CollectionServerFetchRequest<PostUsersOrgsByEntityIdGatewayAliasesApiArg>
    >({
      queryFn: async (params) => {
        const url = `/users/orgs/${params.body.entityId}/gateway/aliases`;
        const headers = Object.assign({}, default_headers, await BaseAPI.prepareSignedRequestHeader(params.account_ids, url, ApiMethods.post, { aliases: params.body.aliases }));
        const result: IFetchResponse = await BaseAPI.post(`${base_url}${url}`, { aliases: params.body.aliases }, headers);
        if (result.isError) {
          return { error: result as IFetchResponse }
        }
        return { data: result.data as PostUsersOrgsByEntityIdGatewayAliasesApiResponse };
      },
      invalidatesTags: ["gateway"]
    }),
    putUsersOrgsByEntityIdGatewayUser: build.mutation<
      PutUsersOrgsByEntityIdGatewayUserApiResponse,
      CollectionServerFetchRequest<PutUsersOrgsByEntityIdGatewayUserApiArg>
    >({
      queryFn: async (params) => {
        const url = `/users/orgs/${params.body.entityId}/gateway/user`;
        const headers = Object.assign({}, default_headers, await BaseAPI.prepareSignedRequestHeader(params.account_ids, url, ApiMethods.put, { user: params.body.user_id }));
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, { user: params.body.user_id }, headers);
        if (result.isError) {
          return { error: result as IFetchResponse }
        }
        return { data: result.data as PutUsersOrgsByEntityIdGatewayUserApiResponse };
      },
      invalidatesTags: ["gateway"]
    }),
    putUsersOrgsByEntityIdGatewayEnabled: build.mutation<
      PutUsersOrgsByEntityIdGatewayEnabledApiResponse,
      CollectionServerFetchRequest<PutUsersOrgsByEntityIdGatewayEnabledApiArg>
    >({
      queryFn: async (params) => {
        const url = `/users/orgs/${params.body.entityId}/gateway/enabled`;
        const headers = Object.assign({}, default_headers, await BaseAPI.prepareSignedRequestHeader(params.account_ids, url, ApiMethods.put, { enabled: params.body.enabled }));
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, { enabled: params.body.enabled }, headers);
        if (result.isError) {
          return { error: result as IFetchResponse }
        }
        return { data: result.data as PutUsersOrgsByEntityIdGatewayEnabledApiResponse };
      },
      invalidatesTags: ["gateway"]
    }),
    // ADMIN GROUP
    getUsersOrgsByEntityIdRecipientGroups: build.query<
      GetUsersOrgsByEntityIdRecipientGroupsApiResponse,
      CollectionServerFetchRequest<GetUsersOrgsByEntityIdRecipientGroupsApiArg>
    >({
      queryFn: async (params) => {
        const url = `/users/orgs/${params.body.entityId}/recipient_groups`;
        const headers = Object.assign({}, default_headers, await BaseAPI.prepareSignedRequestHeader(params.account_ids, url, ApiMethods.get, null));
        const result: IFetchResponse = await BaseAPI.get(`${base_url}${url}`, null, headers);
        if (result.isError) {
          return { error: result as IFetchResponse }
        }
        return { data: result.data as GetUsersOrgsByEntityIdRecipientGroupsApiResponse };
      },
      providesTags: ["groups"]
    }),
    putUsersOrgsByEntityIdRecipientGroupsAndAlias: build.mutation<
      PutUsersOrgsByEntityIdRecipientGroupsAndAliasApiResponse,
      CollectionServerFetchRequest<PutUsersOrgsByEntityIdRecipientGroupsAndAliasApiArg>
    >({
      queryFn: async (params) => {
        const url = `/users/orgs/${params.body.entityId}/recipient_groups/${params.body.alias}`;
        const payload = {
          add: params.body.add,
          alias: params.body.alias,
          remove: params.body.remove
        }
        const headers = Object.assign({}, default_headers, await BaseAPI.prepareSignedRequestHeader(params.account_ids, url, ApiMethods.put, payload));
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, payload, headers);
        return !result.isError ? { data: result.data as PutUsersOrgsByEntityIdRecipientGroupsAndAliasApiResponse } :
          { error: result as IFetchResponse };
      },
      invalidatesTags: ["groups"]
    }),
    postUsersOrgsByEntityIdRecipientGroups: build.mutation<
      PostUsersOrgsByEntityIdRecipientGroupsApiResponse,
      CollectionServerFetchRequest<PostUsersOrgsByEntityIdRecipientGroupsApiArg>
    >({
      queryFn: async (params) => {
        const url = `/users/orgs/${params.body.entityId}/recipient_groups`;
        const payload = {
          alias: params.body.alias,
          members: params.body.members
        }
        const headers = Object.assign({}, default_headers, await BaseAPI.prepareSignedRequestHeader(params.account_ids, url, ApiMethods.post, payload));
        const result: IFetchResponse = await BaseAPI.post(`${base_url}${url}`, payload, headers)
        if (result.isError) {
          return { error: result as IFetchResponse }
        }
        return { data: result.data as PostUsersOrgsByEntityIdRecipientGroupsApiResponse };
      },
      invalidatesTags: ["groups"]
    }),
    getUsersOrgsByEntityIdRecipientGroupsAndAlias: build.query<
      GetUsersOrgsByEntityIdRecipientGroupsAndAliasApiResponse,
      CollectionServerFetchRequest<GetUsersOrgsByEntityIdRecipientGroupsAndAliasApiArg>
    >({
      queryFn: async (params) => {
        const url = `/users/orgs/${params.body.entityId}/recipient_groups/${params.body.alias}`;
        const headers = Object.assign({}, default_headers, await BaseAPI.prepareSignedRequestHeader(params.account_ids, url, ApiMethods.get, null));
        const result: IFetchResponse = await BaseAPI.get(`${base_url}${url}`, null, headers);
        if (result.isError) {
          return { error: result as IFetchResponse }
        }
        return { data: result.data as GetUsersOrgsByEntityIdRecipientGroupsAndAliasApiResponse };
      }
    }),
    deleteUsersOrgsByEntityIdRecipientGroupsAndAlias: build.mutation<
      DeleteUsersOrgsByEntityIdRecipientGroupsAndAliasApiResponse,
      CollectionServerFetchRequest<DeleteUsersOrgsByEntityIdRecipientGroupsAndAliasApiArg>
    >({
      queryFn: async (params) => {
        const url = `/users/orgs/${params.body.entityId}/recipient_groups/${params.body.alias}`;
        const headers = Object.assign({}, default_headers, await BaseAPI.prepareSignedRequestHeader(params.account_ids, url, ApiMethods.delete, null));
        const result: IFetchResponse = await BaseAPI.axiosDelete(`${base_url}${url}`, null, headers);
        if (result.isError) {
          return { error: result as IFetchResponse }
        }
        return { data: result.data as DeleteUsersOrgsByEntityIdRecipientGroupsAndAliasApiResponse }
      },
      invalidatesTags: ["groups"]
    }),
    // Description: Gets the org info given the org id, response is limited if user is not admin
    getUsersOrgsByEntityId: build.query<JSONOrgInfo, CollectionServerFetchRequest<GetUsersOrgsByEntityIdApiArg>>({
      queryFn: async (params) => {
        const url = `/users/orgs/${params.body.entity_id}`;
        const headers = Object.assign(
          {},
          default_headers,
          await BaseAPI.prepareSignedRequestHeader(params.account_ids, url, ApiMethods.get, null),
        );
        const result: IFetchResponse = await BaseAPI.get(`${base_url}${url}`, null, headers);
        return result.data
          ? {
            data: Object.assign(result.data, {
              id: params.body.entity_id,
              // admin_group_id: { uuid: result.data.admin_group_id } as UUID,
              // NOTE: REMOVED THE  org_info.admin_group_id.String() since it is not a UUID in JSONOrgInfo
            }) as JSONOrgInfo,
          }
          : { error: result as IFetchResponse };
      },
      providesTags: ["org_data"],
    }),
    getUsersOrgsByEntityIdGroups: build.query<
      GetUsersOrgsByEntityIdGroupsApiResponse,
      CollectionServerFetchRequest<GetUsersOrgsByEntityIdGroupsApiArg>
    >({
      queryFn: async (params) => {
        const url = `/users/orgs/${params.body.entityId}/groups`;
        const headers = Object.assign(
          {},
          default_headers,
          await BaseAPI.prepareSignedRequestHeader(params.account_ids, url, ApiMethods.get, null),
        );
        const result: IFetchResponse = await BaseAPI.get(`${base_url}${url}`, null, headers);
        return !result.isError
          ? { data: result.data as GetUsersOrgsByEntityIdGroupsApiResponse }
          : { error: result as IFetchResponse };
      },
    }),
    getUsersOrgsByEntityIdKeyvalAndKey: build.query<
      GetUsersOrgsByEntityIdKeyvalAndKeyApiResponse,
      CollectionServerFetchRequest<GetUsersOrgsByEntityIdKeyvalAndKeyApiArg>
    >({
      queryFn: async (params) => {
        const url = `/users/orgs/${params.body.entityId}/keyval/${params.body.key}`;
        const headers = Object.assign(
          {},
          default_headers,
          await BaseAPI.prepareSignedRequestHeader(params.account_ids, url, ApiMethods.get, null),
        );
        const result: IFetchResponse = await BaseAPI.get(`${base_url}${url}`, null, headers);
        return result.data
          ? { data: result.data as GetUsersOrgsByEntityIdKeyvalAndKeyApiResponse }
          : { error: result as IFetchResponse };
      },
    }),
    getUsersOrgsByEntityIdRequests: build.query<
      GetUsersOrgsByEntityIdRequestsApiResponse,
      CollectionServerFetchRequest<GetUsersOrgsByEntityIdRequestsApiArg>
    >({
      queryFn: async (params) => {
        const url = `/users/orgs/${params.body.entityId}/requests`;
        const body = {
          user_id: params.account_ids.user_id,
          status: params.body.status,
          request_type: params.body.requestType,
          hide_expired: params.body.hideExpired,
          limit: params.body.limit,
          offset: params.body.offset,
        };
        const headers = Object.assign(
          {},
          default_headers,
          await BaseAPI.prepareSignedRequestHeader(params.account_ids, url, ApiMethods.get, null),
        );
        const result: IFetchResponse = await BaseAPI.get(`${base_url}${url}`, body, headers);
        return !result.isError
          ? { data: result.data as GetUsersOrgsByEntityIdRequestsApiResponse }
          : { error: result as IFetchResponse };
      },
    }),
    getUsersOrgsByEntityIdRequestsAndRequestIdResponses: build.query<
      GetUsersOrgsByEntityIdRequestsAndRequestIdResponsesApiResponse,
      CollectionServerFetchRequest<GetUsersOrgsByEntityIdRequestsAndRequestIdResponsesApiArg>
    >({
      queryFn: async (params) => {
        const url = `/users/orgs/${params.body.entityId}/requests/${params.body.requestId}/responses`;
        const headers = Object.assign(
          {},
          default_headers,
          await BaseAPI.prepareSignedRequestHeader(params.account_ids, url, ApiMethods.get, null),
        );
        const result: IFetchResponse = await BaseAPI.get(`${base_url}${url}`, null, headers);
        return !result.isError
          ? { data: result.data as GetUsersOrgsByEntityIdRequestsAndRequestIdResponsesApiResponse }
          : { error: result as IFetchResponse };
      },
    }),
    deleteUsersOrgsByEntityIdRequestsAndRequestId: build.mutation<
      DeleteUsersOrgsByEntityIdRequestsAndRequestIdApiResponse,
      DeleteUsersOrgsByEntityIdRequestsAndRequestIdApiArg
    >({
      queryFn: async (params) => {
        const url = `/users/orgs/${params.body.entityId}/requests/${params.body.requestId}`;
        const headers = Object.assign(
          {},
          default_headers,
          await BaseAPI.prepareSignedRequestHeader(
            params.account_ids,
            url,
            ApiMethods.delete,
            null,
          ),
        );
        const result: IFetchResponse = await BaseAPI.axiosDelete(
          `${base_url}${url}`,
          null,
          headers,
        );
        return result.data
          ? { data: result.data as DeleteUsersOrgsByEntityIdRequestsAndRequestIdApiResponse }
          : { error: result as IFetchResponse };
      },
    }),
    deleteUsersOrgsByEntityIdGroupsAndGroupId: build.mutation<
      DeleteUsersOrgsByEntityIdGroupsAndGroupIdApiResponse,
      DeleteUsersOrgsByEntityIdGroupsAndGroupIdApiArg
    >({
      queryFn: async (params) => {
        const url = `/users/orgs/${params.entityId}/groups/${params.groupId}`;
        const headers = Object.assign(
          {},
          default_headers,
          await BaseAPI.prepareSignedRequestHeader(
            params.account_ids,
            url,
            ApiMethods.delete,
            null,
          ),
        );
        const result: IFetchResponse = await BaseAPI.axiosDelete(
          `${base_url}${url}`,
          params.body,
          headers,
        );
        return result.data
          ? { data: result.data as DeleteUsersOrgsByEntityIdGroupsAndGroupIdApiResponse }
          : { error: result as IFetchResponse };
      },
    }),
    postUsersOrgsByEntityIdGroups: build.mutation<
      PostUsersOrgsByEntityIdGroupsApiResponse,
      CollectionServerFetchRequest<PostUsersOrgsByEntityIdGroupsApiArg>
    >({
      queryFn: async (params) => {
        const url = `/users/orgs/${params.body.entityId}/groups`;
        const body = {
          name: params.body.name,
          optionals_required: params.body.optionals_required,
          approvers: params.body.approvers,
        };
        const headers = Object.assign(
          {},
          default_headers,
          await BaseAPI.prepareSignedRequestHeader(params.account_ids, url, ApiMethods.post, null),
        );
        const result: IFetchResponse = await BaseAPI.post(`${base_url}${url}`, body, headers);
        return !result.isError
          ? { data: result.data as GetUsersOrgsByEntityIdRequestsApiResponse }
          : { error: result as IFetchResponse };
      },
    }),
    getOrgDeviceCertificates: build.query<
      GetOrgDeviceCertificatesApiResponse,
      CollectionServerFetchRequest<GetOrgDeviceCertificatesApiArg>
    >({
      queryFn: async (params) => {
        const url = `/users/orgs/${params.body.entity_id}/device_rules`;
        const headers = Object.assign({}, default_headers, await BaseAPI.prepareSignedRequestHeader(params.account_ids, url, ApiMethods.get, null));
        const result: IFetchResponse = await BaseAPI.get(`${base_url}${url}`, null, headers);
        return !result.isError
          ? { data: result.data as GetOrgDeviceCertificatesApiResponse }
          : { error: result as IFetchResponse };
      }
    }),
    deleteOrgDeviceCertificates: build.mutation<
      DeleteOrgDeviceCertificatesApiResponse,
      CollectionServerFetchRequest<DeleteOrgDeviceCertificatesApiArg>
    >({
      queryFn: async (params) => {
        const url = `/users/orgs/${params.body.entity_id}/device_rules`;
        const body = {
          acl_ids: params.body.acl_ids
        };
        const headers = Object.assign({}, default_headers, await BaseAPI.prepareSignedRequestHeader(params.account_ids, url, ApiMethods.delete, body));
        const result: IFetchResponse = await BaseAPI.axiosDelete(`${base_url}${url}`, body, headers);
        return !result.isError
          ? { data: result.data as DeleteOrgDeviceCertificatesApiResponse }
          : { error: result as IFetchResponse };
      }
    }),
    putOrgDeviceCertificates: build.mutation<
      PutOrgDeviceCertificatesApiResponse,
      CollectionServerFetchRequest<PutOrgDeviceCertificatesApiArg>
    >({
      queryFn: async (params) => {
        const url = `/users/orgs/${params.body.entity_id}/device_rules`;
        const body = {
          acl_id: params.body.acl_id,
          specifier_type: params.body.specifier_type,
          specifier_body: params.body.specifier_body,
        };
        const headers = Object.assign({}, default_headers, await BaseAPI.prepareSignedRequestHeader(params.account_ids, url, ApiMethods.put, body));
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, body, headers);
        return !result.isError
          ? { data: result.data as PutOrgDeviceCertificatesApiResponse }
          : { error: result as IFetchResponse };
      }
    }),
    // ADD MORE...
    // ------------------------------------------/ END OF USING QUERIES  ------------------------------------------ //
  }),
  overrideExisting: false,
});
export { injectedRtkApi as collectionApi };
// ------------------------------------------------------------
// Request and Response types:
// ------------------------------------------------------------
export type PostUsersApiResponse = { expires: string; status: string; }
export type PostUsersApiArg = {
  user_id?: string
  captcha_token?: string
  display_name?: string
}
export type PostUsersVerifyApiResponse = {
  account_claim_receipt: string;
  display_name: string | null;
  key_version: number;
  org_name: string | null;
}
export type PostUsersVerifyApiArg = {
  user_id?: string
  secret?: string
}
export type DeleteUsersApiResponse = unknown;
export type DeleteUsersApiArg = {
  user_id: string
  admin_changes?: {
    user_id?: string
    signature?: string
    payload?: string
    key_version?: number
  }
}
export type GetUsersTypeApiResponse = { account_type: AccountType }
export type GetUsersTypeApiArg = {
  userId: string
}
export type GetUsersApproversSecretApiResponse = {}; // Empty Object
export type GetUsersApproversSecretApiArg = {
  userId: string
};
// NOTE: PASS { account_ids: AccountIdentifiers; }  for headers
export type PostUsersFindApiResponse = CollectionServerFindUsers;
export type PostUsersFindApiArg = {
  spec: {
    user_id?: string
    key_version?: number
  }[];
}
export type PutUsersKeyvalApiResponse = {
  errors?: any;
}; // empty unless there are errors
export type PutUsersKeyvalApiArg = {
  val?: string;
  user_id: string;
  key: string;
};
export type GetUsersKeyvalApiResponse = { value: string };
export type GetUsersKeyvalApiArg = {
  user_id: string;
  key: string;
};
export type GetUsersApprovalsApiResponse = {
  approvals: {
    expiration: string,
    payload: string,
    request_id: string,
    response: string,
    signature: string,
    status: string,
    __dirty__: boolean
  }[];
  total_rows: number;
};
export type GetUsersApprovalsApiArg = {
  user_id: string;
  status?: string;
  response?: string;
  hide_expired?: boolean;
  limit?: number;
  offset?: number;
};
export type GetUsersEventsApiResponse = {
  events: Event[];
  last_rev_id: number;
};
export type GetUsersEventsApiArg = {
  user_id: string;
  since_rev_id?: string;
};
export type PutUsersEventsByEventIdApiArg = {
  event_id: string;
  body: {
    user_id: string;
    request: object;
  };
};
// Mailboxes
export type GetMailByCollectionIdMailboxesApiArg = {
  collectionId: string;
  since_rev_id: number;
  cursor?: number;
  fetch_messages: boolean;
};
export type PostMailboxesApiResponse = {
  mailbox_id: string;
  rev_id: number;
};
export type PostMailboxesApiArg = {
  collectionId: string;
  name: string;
};
export type PutMailboxesAndMbidApiResponse = CollectionServerRevId;
export type PutMailboxesAndMbidApiArg = {
  collectionId: string;
  mbid: string;
  name: string;
};
export type DeleteMailboxesApiResponse = CollectionServerRevId;
export type DeleteMailboxesApiArg = {
  collectionId: string;
  mbid: string;
};

// Threads
export type GetMailThreadsArgs = {
  collectionId: string;
  mbid: string;
  limit: number;
  offset: number;
  fetch_expired?: boolean;
};
export type GetMailUnseenCountApiResponse = {
  rev_id: number;
  unseen_count: number;
};
export type GetMailUnseenCountApiArg = {
  collectionId: string;
  mbid: string;
};
export type GetUsersBackupApiResponse = {
  backup_id: string;
  backup_public_key: string;
};
export type GetUsersBackupApiArg = {
  account_ids: AccountIdentifiers;
  body: {
    user_id: string;
  };
};
export type PutUsersBackupApiResponse = {
  errors?: any;
}; // Empty unless it has errors
export type PutUsersBackupApiArg = {
  account_ids: AccountIdentifiers;
  body: {
    user_id: string;
    backup_public_key: string;
    backup_id: string;
  };
};
export type DeleteUsersBackupApiResponse = {
  errors?: any;
}; // Empty unless it has errors
export type DeleteUsersBackupApiArg = {
  account_ids: AccountIdentifiers;
  body: {
    user_id: string;
    backup_id: string;
  };
};
export type GetSummaryCountsApiResponse = MailboxNotificationCounts;
export type GetSummaryCountsApiArg = { collectionId: string; }
export type GetUsersInviteApiResponse = { invites: Invite[] };
export type GetUsersInviteApiArg = {
  invitee: string;
  inviter?: string;
};
export type PutUsersInviteApiResponse = { status: string; };
export type PutUsersInviteApiArg = UserInvite;
export type GetStorageByCollectionIdBlocksAndBlockIdApiResponse = {
  errors: CollectionServerError[];
  blocks: JSONBlock[];
};
export type GetStorageByCollectionIdBlocksAndBlockIdApiArg = {
  collectionId: string;
  blockId: string;
};
export type PostStorageDownloadApiResponse = {
  urls: JSONUrlsResponseBlock[]
};
export type PostStorageDownloadApiArg = {
  blocks: JSONRequestBlock[];
};
export type DeleteUsersDevicesKeysByDeviceIdApiResponse = EmptyObject;
export type DeleteUsersDevicesKeysByDeviceIdApiArg = {
  device_id: string;
  user_id: string;
};
export type PostStorageByCollectionIdBlocksApiResponse = {
  data: { block_id: string }[];
  error: unknown;
};
export type PostStorageByCollectionIdBlocksApiArg = {
  collectionId: string;
  blocks: VersionedEmailBlock[];
};
export type PostMailSendApiResponse = MailMessageResponse;
export type PostMailSendApiArg = {
  message: MessageLatest;
};
export type PostMailByCollectionIdApiResponse = MailMessageResponse;
export type PostMailByCollectionIdApiArg = {
  collectionId: string;
  message: MailMessageV4;
};
export type PostMessagesCopyApiResponse = {
  data: {
    status: boolean;
    source_uid: number;
    result: CopyMailDataApi[];
  };
};
export type CopyMailDataApi = {
  uid: string;
  id: string;
  version: string;
  rev_id: number;
  thread_id: string;
  timestamp: string;
  error?: string;
}
export type PosMessagesCopyApiArg = {
  collectionId: string;
  mbid: string;
  uids: number[];
  source_mbid: string;
};
export type UpdateFlagsApiResponse = {
  data: UpdateFlagsDataApi[]
};
export type UpdateFlagsDataApi = {
  id: string;
  mailbox_id: string;
  version: string;
  rev_id: number;
  flags: string[];
  is_deleted: boolean;
  error: string; // defaults to error : "no_error"
}
export type UpdateFlagsApiArg = {
  collectionId: string;
  mbid: string;
  updates: SetCollectionsFlagsParams[];
};
export type PostMailByCollectionIdMailboxesAndMbidMessagesApiResponse = MailMessageResponse;
export type PostMailByCollectionIdMailboxesAndMbidMessagesApiArg = {
  collectionId: string;
  mbid: string;
  message: MailMessageV4;
  source?: {
    uid?: number;
    mbid?: string;
  };
};
export type DeleteMailByColIdMbidMessIdApiResponse = CollectionServerRevId;
export type DeleteMailByColIdMbidMessIdApiArg = {
  collectionId: string;
  mbid: string;
  id: string;
};
export type PutMailByCollectionIdThreadsAndTidApiResponse = { messages: MoveMailDataApi[] };
export type MoveMailApiArg = {
  collectionId: string;
  tid: string;
  source_mailbox: string;
  dest_mailbox: string;
};
export type MoveMailDataApi = {
  id: string;
  uid: string;
  version: string;
  rev_id: number;
  timestamp: string;
}
export type GetThreadApiResponse = CollectionThreadMessages;
export type GetThreadApiArg = {
  collectionId: string;
  tid: string;
  sinceRevId: number;
};
export type PutUsersOrgsByEntityIdRequestsAndRequestIdApiArg = {
  entityId: string;
  requestId: string;
  payload: string; // payload to be signed, replaces body.signature
  body: {
    requester_user_id: string;
    grant_group_key?: {
      wrapped_key?: string;
      user_key_version?: number;
      signature?: string;
      sharee_user_id?: string;
      group_key_version?: number;
      group_id?: string;
    };
    approve: boolean;
    admin_changes?: {
      user_id?: string;
      signature?: string;
      payload?: string;
      key_version?: number;
    };
  };
};
export type PostUsersOrgsByEntityIdSubmitExportShardsApiArg = {
  entityId: string;
  body: {
    shards?: ExportShard[];
    group_version: string;
    group_id: string;
  };
};
export type GetUsersLogKeysApiResponse = {
  log_keys: LogKey[];
};
export type PutUsersCollectionGrantApiArg = {
  body: {
    users?: {
      user_id: string;
      role_info?: {
        wrapped_key: string;
        signature: string;
        role: string;
      }[];
      key_version: number;
      enc_collection_name?: string;
    }[];
    user_id: string;
    roles?: {
      version: number;
      role: string;
    }[];
    groups?: {
      role_info?: {
        wrapped_key: string;
        signature: string;
        role: string;
      }[];
      key_version: number;
      group_id: string;
      enc_collection_name?: string;
    }[];
    collection_id: string;
  };
};
export type GetUsersOrgsByEntityIdGroupsAndGroupIdApiArg = {
  entityId: string;
  groupId: string;
  version: string;
};
export type GetUsersOrgsByEntityIdGroupsApiResponse = {
  groups: {
    name: string,
    id: string,
    version: string,
    group: {
      approvers: {
        account_version: number,
        required: boolean,
        user_id: string
      }[],
      optionals_required: number
    }
    is_deleted: boolean,
    rev_id: number
  }[]
  last_rev_id: number
};
export type GetUsersOrgsByEntityIdGroupsApiArg = {
  entityId: string;
  sinceRevId: number;
};
export type GetUsersOrgsByEntityIdRequestsApiResponse = {
  total_rows: number;
  requests: {
    request_id: string;
    collection_id: string;
    requester: string;
    type: string;
    timestamp: string;
    expiration: string;
    metadata: RequestMetadataObject;
    payload: string;
    status: string; // ["pending"|"approved"|"denied"|"expired"]
    group_id: string;
    group_version: string;
  }[];
};
export type GetUsersOrgsByEntityIdRequestsApiArg = {
  entityId: string;
  status?: string;
  requestType?: string;
  hideExpired: boolean;
  limit?: number;
  offset?: number;
};
export type GetUsersOrgsByEntityIdRequestsAndRequestIdResponsesApiResponse = {
  responses: {
    approver: string;
    response: string;
  }[]
};
export type GetUsersOrgsByEntityIdRequestsAndRequestIdResponsesApiArg = {
  entityId: string;
  requestId: string;
};
export type DeleteUsersOrgsByEntityIdRequestsAndRequestIdApiResponse = {
  errors?: any;
};
export type DeleteUsersOrgsByEntityIdRequestsAndRequestIdApiArg = {
  account_ids: AccountIdentifiers;
  body: {
    entityId: string;
    requestId: string;
  };
};
export type DeleteUsersOrgsByEntityIdGroupsAndGroupIdApiResponse = {
  errors?: any;
};
export type DeleteUsersOrgsByEntityIdGroupsAndGroupIdApiArg = {
  account_ids: AccountIdentifiers;
  entityId: string;
  groupId: string;
  body?: {
    force?: boolean;
  };
};
export type PostUsersOrgsByEntityIdGroupsApiResponse = {}; // Empty Object - Note: This one is throwing an incompatiblity error when replacing to EmptyObject type.
export type PostUsersOrgsByEntityIdGroupsApiArg = {
  entityId: string;
  optionals_required: number;
  name: string;
  approvers?: {
    user_id: string;
    required: boolean;
    account_version: number;
  }[];
};
export type GetUsersOrgsByEntityIdGatewayApiResponse = {
  health_history: boolean[];
  user_id: string;
  enabled: boolean;
  aliases: string[];
};
export type GetUsersOrgsByEntityIdGatewayApiArg = {
  entityId?: string;
};
export type PostUsersOrgsByEntityIdGatewayAliasesRemoveApiResponse = EmptyObject;
export type PostUsersOrgsByEntityIdGatewayAliasesRemoveApiArg = {
  entityId: string;
  aliases: string[];
};
export type GetUsersOrgsByEntityIdGatewayStatusApiResponse = {
  details: string;
  healthy: boolean;
  version: any; //not sure if version will return any other value than null
};
export type GetUsersOrgsByEntityIdGatewayStatusApiArg = {
  entityId: string;
};
export type PostUsersOrgsByEntityIdGatewayAliasesApiResponse = EmptyObject;
export type PostUsersOrgsByEntityIdGatewayAliasesApiArg = {
  entityId: string;
  aliases?: {
    type: "individual" | "domain";
    email_address: string;
    display_name: string;
  }[];
};
export type PutUsersOrgsByEntityIdGatewayEnabledApiResponse = EmptyObject;
export type PutUsersOrgsByEntityIdGatewayEnabledApiArg = {
  entityId: string;
  enabled: boolean;
};
// Admin and Org
export type PutUsersOrgsByEntityIdKeyvalApiResponse = EmptyObject;
export type PutUsersOrgsByEntityIdKeyvalApiArg = {
  entityId: string;
  value: string;
  key: string;
};
export type PatchUsersOrgsByEntityIdShowMuaPromptApiResponse = EmptyObject;
export type PatchUsersOrgsByEntityIdShowMuaPromptApiArg = {
  entityId: string;
  show_prompt: boolean;
};
export type PostUsersOrgsByEntityIdAllowAddDeviceApiResponse = EmptyObject;
export type PostUsersOrgsByEntityIdAllowAddDeviceApiArg = {
  entityId: string;
  is_allowed: boolean;
  userId?: string;
};
export type GetUsersOrgsByEntityIdRecipientGroupsApiResponse = {
  aliases: string[];
};
export type GetUsersOrgsByEntityIdRecipientGroupsApiArg = {
  entityId?: string;
};
export type GetUsersOrgsByEntityIdRecipientGroupsAndAliasApiResponse = {
  members: string[];
};
export type GetUsersOrgsByEntityIdRecipientGroupsAndAliasApiArg = {
  entityId: string;
  alias: string;
};
export type PostUsersOrgsByEntityIdRecipientGroupsApiResponse = EmptyObject;
export type PostUsersOrgsByEntityIdRecipientGroupsApiArg = {
  entityId: string;
  members?: string[];
  alias: string;
};
export type DeleteUsersOrgsByEntityIdRecipientGroupsAndAliasApiResponse = EmptyObject;
export type DeleteUsersOrgsByEntityIdRecipientGroupsAndAliasApiArg = {
  entityId: string;
  alias: string;
};
export type GetUsersOrgsByEntityIdApiArg = {
  entity_id: string;
};
export type PutUsersOrgsByEntityIdGatewayUserApiResponse = EmptyObject;
export type PutUsersOrgsByEntityIdGatewayUserApiArg = {
  entityId: string;
  user_id?: string;
};
export type GetUsersOrgsByEntityIdKeyvalAndKeyApiResponse = { value: boolean };
export type GetUsersOrgsByEntityIdKeyvalAndKeyApiArg = {
  entityId: string;
  key: string;
};
export type PutUsersOrgsByEntityIdRecipientGroupsAndAliasApiResponse = EmptyObject;
export type PutUsersOrgsByEntityIdRecipientGroupsAndAliasApiArg = {
  entityId: string;
  alias: string;
  remove?: string[];
  add?: string[];
};
export type PostUsersDevicesKeysApiResponse = {
  data: DeviceBase;
}
export type PostUsersDevicesKeysApiArg = {
  user_id: string;
  serialized_request?: string;
  device?: DeviceBase;
  approver_signatures?: {
    user_key_version: number;
    user_id: string;
    signature: string;
  }[];
};
export type GetUsersDevicesKeysApiResponse = {
  data: {
    devices: DeviceBase[];
  }
}
export type GetUsersDevicesKeysApiArg = {
  userId: string;
};
export type GetOrgDeviceCertificatesApiArg = {
  entity_id: string;
};
export type GetOrgDeviceCertificatesApiResponse = {
  rules: Array<{
    acl_id: string;
    specifier_type: string;
    specifier_body: string;
  }>;
};
export type PutOrgDeviceCertificatesApiArg = {
  entity_id: string;
  acl_id: string;
  specifier_type: string;
  specifier_body: string;
};
export type PutOrgDeviceCertificatesApiResponse = unknown;
export type DeleteOrgDeviceCertificatesApiArg = {
  entity_id: string;
  acl_ids: string[];
};
export type DeleteOrgDeviceCertificatesApiResponse = unknown;

// ------------------------------------------/ END OF USING TYPES  ------------------------------------------ //
export const {
  usePostUsersMutation,
  useDeleteUsersMutation,
  useGetUsersTypeQuery,
  usePostUsersTypeMutation, // Custom mutation
  usePostUsersFindMutation,
  usePostUsersVerifyMutation,
  useGetUsersApproversSecretQuery,
  usePutUsersKeyvalMutation,
  useGetUsersKeyvalQuery,
  useGetUsersBackupQuery,
  usePutUsersBackupMutation,
  useDeleteUsersBackupMutation,
  useGetUsersApprovalsQuery,
  useGetMailByCollectionIdMailboxesQuery,
  usePostMailByCollectionIdMailboxesMutation,
  usePutMailByCollectionIdMailboxesAndMbidMutation,
  useDeleteMailByCollectionIdMailboxesAndMbidMutation,
  useGetMailByCollectionIdMailboxesAndMbidThreadsQuery,
  useGetMailByCollectionIdMailboxesAndMbidUnseenCountQuery,
  usePostMailByCollectionIdMailboxesAndMbidMessagesCopyMutation,
  useGetSummaryCountsQuery,
  useGetStorageByCollectionIdBlocksAndBlockIdQuery,
  usePostStorageDownloadDirectMutation,
  usePostStorageDownloadProxyMutation,
  usePostStorageByCollectionIdBlocksMutation,
  useGetUsersInviteQuery,
  usePutUsersInviteMutation,
  usePostMailSendMutation,
  usePostMailByCollectionIdMutation,
  usePostMailByCollectionIdMailboxesAndMbidMessagesMutation,
  usePatchMailByCollectionIdMailboxesAndMbidMessagesMutation,
  useDeleteMailByColIdMbidMessIdMutation,
  usePutMailByCollectionIdThreadsAndTidMutation,
  useDeleteUsersDevicesKeysByDeviceIdMutation,
  usePutUsersOrgsByEntityIdKeyvalMutation,
  usePatchUsersOrgsByEntityIdShowMuaPromptMutation,
  usePostUsersOrgsByEntityIdAllowAddDeviceMutation,
  useGetUsersOrgsByEntityIdGatewayQuery,
  usePostUsersOrgsByEntityIdGatewayAliasesRemoveMutation,
  usePostUsersOrgsByEntityIdGatewayAliasesMutation,
  usePutUsersOrgsByEntityIdGatewayUserMutation,
  usePutUsersOrgsByEntityIdGatewayEnabledMutation,
  useGetUsersOrgsByEntityIdGatewayStatusQuery,
  useGetUsersOrgsByEntityIdRecipientGroupsQuery,
  usePostUsersOrgsByEntityIdRecipientGroupsMutation,
  useGetUsersOrgsByEntityIdRecipientGroupsAndAliasQuery,
  useDeleteUsersOrgsByEntityIdRecipientGroupsAndAliasMutation,
  useGetUsersOrgsByEntityIdQuery,
  useGetUsersOrgsByEntityIdGroupsQuery,
  useGetUsersOrgsByEntityIdRequestsQuery,
  useGetUsersOrgsByEntityIdRequestsAndRequestIdResponsesQuery,
  useDeleteUsersOrgsByEntityIdRequestsAndRequestIdMutation,
  useDeleteUsersOrgsByEntityIdGroupsAndGroupIdMutation,
  usePostUsersOrgsByEntityIdGroupsMutation,
  useGetUsersOrgsByEntityIdKeyvalAndKeyQuery,
  usePutUsersOrgsByEntityIdRecipientGroupsAndAliasMutation,
  usePostUsersDevicesKeysMutation,
  useGetUsersDevicesKeysMutation,
  useGetOrgDeviceCertificatesQuery,
  usePutOrgDeviceCertificatesMutation,
  useDeleteOrgDeviceCertificatesMutation,
} = injectedRtkApi;
