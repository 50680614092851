import { useState, useCallback, useEffect, useRef } from "react";
import { Account, DownloadRequest, DriveMessages, Message, MessageHandlerDisplayType, MessageToastTypes, DonwloadProgressTracker, useAppDispatch, useDownloadFile } from "src/common";
import { driveActions, uiActions } from "src/store";
import saveAs from "file-saver";

/* Description:  Hook for bulk downloading files */
export function useBulkDownloadFiles(current_account: Account, use_filesync: boolean = false) {
  const [progress, setProgress] = useState<DonwloadProgressTracker[]>([]);
  const { file, downloadFile, error: download_error, resetDownload, file_name, progress: file_progress } = useDownloadFile(current_account, use_filesync);
  const dispatch = useAppDispatch();
  
  const files_to_download_ref = useRef<DownloadRequest[]>([]);
  const files_ids_ref = useRef<string[]>([]);

  /* Description: Detects success, error and progress for each file while its downloading
  and then resets and iterates to the next file in the queue to be downloaded */
  useEffect(() => {
    !!file_progress && updateProgress(file_progress);
    !!download_error && handleIterate(); // just iterates to the next file since the error is handled by the progress view and the download file hook.
    if (!!file && !!file_name) {
      !use_filesync && saveAs(file, file_name);
      handleIterate();
    }
  }, [file, download_error, file_name, file_progress]);

  /* Description:  Updates the progress everytime its updated to display in the UI */
  function updateProgress(_progress: DonwloadProgressTracker) {
    setProgress((progress) => {
      const index = progress.findIndex(t => t.file_id === _progress.file_id);
      if (index === -1) {
        return progress.concat(_progress);
      }
      return progress.map((t, i) => i === index ? _progress : t);
    });
  }
  /* Description: Iterates to the next file to download. */
  function handleIterate() {
    resetDownload();
    const download_request = files_to_download_ref.current.shift();
    if (!!download_request) {
      const { collection_id, file_id, file_name } = download_request;
      downloadFile(collection_id, file_id, file_name);
    } else {
      reset();
    }
  }

  /* Description: When all files have been downloaded/errored out, we reset the progress, the download request and the download queue after 5 seconds. */
  function reset() {
    setTimeout(() => { // timeout to allow user to see the progress before the view closes.
      files_to_download_ref.current = [];
      files_ids_ref.current = [];
      setProgress([]);
      dispatch(driveActions.setFileDownloadRequest(undefined));
    }, 5000);
  }

  // Description: Callback for download files - adds files to download queue and starts downloading one file at a time
  const downloadFiles = useCallback((requests: DownloadRequest[]) => {
    for (const file of requests) {
      if (!files_ids_ref.current.includes(file.file_id)) {
        files_to_download_ref.current.push(file);
        files_ids_ref.current.push(file.file_id);
        setProgress(_progress => {
          return _progress.concat( {
            file_id: file.file_id,
            file_name: file.file_name,
            done: 0,
            total: 1,
          });
        });
      } else {
        dispatch(uiActions.handleSetMessage(new Message(DriveMessages.file_in_queue, MessageHandlerDisplayType.toastr,
          MessageToastTypes.warning)));
      }
    }
    const download_request = files_to_download_ref.current.shift();
    if (!!download_request) {
      const { collection_id, file_id, file_name } = download_request;
      downloadFile(collection_id, file_id, file_name);
    }
  }, []);

  return {
    downloadFiles,
    progress
  };
}
