import * as React from "react";
import { UserProfile } from "@preveil-api";
import { ApprovalRequestStatus, UserRequest } from "src/common";
import { Icon } from "src/components";

type AllProps = {
  request: UserRequest;
  current_optionals_required: number | false;
  current_optional_users: UserProfile[];
}

/* Description: shows the progress of which approver has approved/rejected and who is yet to respond to the request. 
i.e: the progress of the request responses */
function ProgressComponent(props: AllProps) {
  const { request, current_optionals_required, current_optional_users } = props;

  /* Description: checks the status of a users response: either pending, approved, or rejected */
  function checkUserStatus(user_id: string): string | undefined {
    const user_response = request.request_responses.find(
      (request_response) => request_response.approver.address === user_id,
    );
    if (user_response) {
      switch (user_response.response) {
        case ApprovalRequestStatus.denied:
          return "failed";
        case ApprovalRequestStatus.pending:
          return "unapproved";
        default:
          return user_response.response;
      }
    }
    return "unapproved";
  }

  /* Description: Gets the number of users who have approved the request */
  function approvedRequestCount(): number {
    if (!request.request_responses) {
      return 0;
    }
    return request.request_responses.filter(
      (response) => response.response === ApprovalRequestStatus.approved,
    ).length;
  }

  return (
    <ul className="recovery-group-progress-list">
      <h4>
        Progress
        <span className="subtitle">
          {approvedRequestCount()} of {current_optionals_required}
        </span>
      </h4>
      {current_optional_users.map((user, i) => (
        <li key={i}>
          <div>
            <div>{user.name}</div>
            <div className="subtitle">{user.address}</div>
          </div>
          <Icon className={`approved-icon pv-icon-${checkUserStatus(user.address)}`} />
        </li>
      ))}
    </ul>
  );
}

export default React.memo(ProgressComponent);
