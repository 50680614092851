// Pending updates to protos files
import { Backup as BackupPB, UserKey } from "../keys/protos/backup_pb";
import { FipsCrypto } from "pvcryptojs";
import { Helpers } from "src/common";

export interface BackupDataObject {
  user_id: string;
  backup_id: string;
  user_key: string;
  backup_key: string;
  backup_signing_key?: string;
}

export class BackupData {
  constructor(
    public user_id: string,
    public backup_id: string,
    public user_key: UserKey,
    public backup_key: UserKey
  ) {
  }

  public static deserialize(blob: Uint8Array): BackupPB.Data {
    return BackupPB.Data.deserializeBinary(blob);
  }

  // Description: Returs decrypted BackupPB.Data from password as Uint8Array
  public static async decrypt(passphrase: string, serialized_data: Uint8Array): Promise<Uint8Array> {
    return await FipsCrypto.decryptWithPassphrase(passphrase, serialized_data);
  }

  public get buffer(): BackupPB.Data {
    const pbInst = new BackupPB.Data();
    pbInst.setUserId(this.user_id);
    pbInst.setBackupId(this.backup_id);
    pbInst.setBackupKey(this.backup_key);
    pbInst.setUserKey(this.user_key);
    return pbInst;
  }

  public serialize(): Uint8Array {
    return this.buffer.serializeBinary();
  }

  public async encrypt(passphrase: string, serialized_data: Uint8Array): Promise<Uint8Array> {
    return await FipsCrypto.encryptWithPassphrase(passphrase, serialized_data);
  }

  // Description: Returs decoded text in the form of a backup proto object
  public static getDecodedText(backup_code: string): BackupPB {
    const decoded_text = Helpers.b64Decode(backup_code);
    return BackupPB.deserializeBinary(decoded_text);
  }

  // Description: decrypts the backup code and return a BackupDataObject
  public static getBackupDataObject(backupData: BackupPB.Data): BackupDataObject {
    const user_id = backupData.getUserId();
    const backup_id = backupData.getBackupId();
    const backup_key = backupData.getBackupKey();
    const user_key = backupData.getUserKey();
    const backup_signing_key = backup_key?.getSigningKey();
    if (!!backup_key && !!user_key && !!user_id && !!backup_id && !!backup_signing_key) {
      return {
        user_id,
        backup_id,
        user_key: Helpers.b64Encode(user_key.serializeBinary()),
        backup_key: Helpers.b64Encode(backup_key.serializeBinary()),
        backup_signing_key: Helpers.b64Encode(backup_signing_key.serializeBinary())
      };
    } else {
      throw new Error("Failed to get Backup object!");
    }
  }
}

export class Backup {
  constructor(
    public encrypted_data: Uint8Array,
    public protocol_version: number
  ) {
  }

  public get buffer(): BackupPB {
    const pbInst = new BackupPB();
    pbInst.setProtocolVersion(this.protocol_version);
    pbInst.setData(this.encrypted_data);
    return pbInst;
  }

  public serialize(): Uint8Array {
    return this.buffer.serializeBinary();
  }

  public deserialize(blob: Uint8Array): BackupPB {
    return BackupPB.deserializeBinary(blob);
  }
}
