import { UserProfile } from "@preveil-api";
import { Entity } from "src/common";

export class RecoveryGroup extends Entity {
  constructor(
    public user_id: string,
    public required_users: UserProfile[],
    public optional_users: UserProfile[],
    public optionals_required: number
  ) {
    super (user_id);
  }

  public get group_set(): boolean {
    return this.required_users.length > 0 || this.optional_users.length > 0;
  }
}
