import * as React from "react";
import { Icon } from "src/components";

function EmptyMailboxComponent(props: { message?: string }) {
  return <div className="list-messages">
    <div className="center-vertical">
      <Icon className="pv-icon-mailbox-empty" />
      {
        !!props.message ?
          <p dangerouslySetInnerHTML={{ __html: (props.message) }} /> :
          <p>You have no messages in this mail folder</p>
      }
    </div>
  </div>;
}

export default React.memo(EmptyMailboxComponent);
