import { combineReducers } from "redux";
import { collectionApi, cryptoApi, filesyncApi, filesyncSocketApi, keystorageApi } from "src/common";
import appReducer from "../app/slice";
import accountReducer from "../account/slice";
import adminReducer from "../admin/slice";
import driveReducer from "../drive/slice";
import mailReducer from "../mail/slice";
import settingsReducer from "../settings/slice";
import uiManagerReducer from "../ui-manager/slice";
import websocketReducer from "../websocket/slice";

export default () =>
  combineReducers({
    [cryptoApi.reducerPath]: cryptoApi.reducer,
    [collectionApi.reducerPath]: collectionApi.reducer,
    [filesyncApi.reducerPath]: filesyncApi.reducer,
    [filesyncSocketApi.reducerPath]: filesyncSocketApi.reducer,
    [keystorageApi.reducerPath]: keystorageApi.reducer,
    account: accountReducer,
    admin: adminReducer,
    app: appReducer,
    drive: driveReducer,
    mail: mailReducer,
    settings: settingsReducer,
    ui: uiManagerReducer,
    websocket: websocketReducer
  });
