import React, { Dispatch, SetStateAction } from "react";
import { Modal, Button } from "react-bootstrap";
import { NodePermissionSet, GranteesNodePermissions } from "src/common";
import { AccessHeader, AccessRow } from ".";
import _ from "lodash";

type AllProps = {
  changes: GranteesNodePermissions;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  handleAction: () => void
};

function ReviewChangesModalComponent(props: AllProps) {
  const { show, setShow, changes, handleAction } = props;

  return (
    <>
      <Modal
        size="lg"
        className="drive-details"
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="Edit Expiration"
        centered>
        <Modal.Header>
          <Modal.Title>Review Changes ({Object.keys(changes).length})</Modal.Title>
        </Modal.Header>
        <Modal.Body className="drive-share">
          <div className="access-list">
            <AccessHeader />
            <div className="access-body">
              {
                _.map(changes, (node_permission: NodePermissionSet & { class_name?: string }, i) => {
                  return <AccessRow
                    key={`access_${i}`}
                    class_name={node_permission?.class_name || ""}
                    edit_mode={true}
                    node_permission={node_permission} />;
                })
              }
            </div>
          </div>
          <div className="btn-panel">
            <Button className="me-2" size="sm" onClick={handleAction}>
              Save
            </Button>
            <Button size="sm" variant="no-outline-primary" onClick={() => setShow(false)}>
              Back
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default React.memo(ReviewChangesModalComponent);
