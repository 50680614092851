// @ts-nocheck
// @ts-ignore
import { EncryptionKey, EncryptionStyle, PublicKey } from "./encryption";
import { text_header, binary_header } from "./encryption_v0";
import {
  NaclCrypto,
  PVCryptoUsage,
  randomBytes
} from "pvcryptojs";
import { Helpers } from "../..";

export class EncryptionKeyV2 extends EncryptionKey {
  static protocol_version = 2;
  private _private_key: Promise<Uint8Array>;

  constructor(public_key: Uint8Array, public seed?: Uint8Array, private style?: EncryptionStyle) {
    super(EncryptionKeyV2.protocol_version, public_key, seed);
    this._private_key = seed ? Helpers.sha512Checksum(seed) : null;
  }

  static async new(sk?: Uint8Array, style?: EncryptionStyle): Promise<EncryptionKeyV2> {
    const seed = sk || randomBytes(32);
    const secret = (await Helpers.sha512Checksum(seed)).slice(0, 32);
    const pk = await NaclCrypto.publicKeyFromPrivate(secret, PVCryptoUsage.ENCRYPT);
    return new EncryptionKeyV2(pk, seed, style);
  }

  clonePublic(): PublicKey {
    return new EncryptionKeyV2(this.public_key, undefined, this.style);
  }

  async unseal(cipher: Uint8Array): Promise<Uint8Array> {
    if (!this._private_key) {
      throw Error("Invalid usage");
    }
    let decrypted = NaclCrypto.hybridDecrypt((await this._private_key).slice(0, 32), cipher);
    if (this.style === EncryptionStyle.EMAIL) {
      decrypted = decrypted.then(p => !p ? p : p.slice(text_header.length));
    }
    return decrypted;
  }

  async seal(plaintext: Uint8Array, is_text: boolean = false): Promise<Uint8Array> {
    if (this.style === EncryptionStyle.EMAIL) {
      plaintext = is_text ?
        new Uint8Array([...text_header, ...plaintext]) :
        new Uint8Array([...binary_header, ...plaintext]);
    }
    return NaclCrypto.hybridEncrypt(this.public_key, plaintext);
  }
}
