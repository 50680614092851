import React, { useEffect, createRef } from "react";
import { toCanvas } from "qrcode";

type AllProps = {
  code: string;
  width?: number;
}

function QRCodeComponent(props: AllProps) {
  const { code, width=128 } = props;
  const canvasRef = createRef<HTMLCanvasElement>();

  useEffect(() => {
    const canvas = canvasRef.current;
    toCanvas(canvas, code, {
      margin: 0,
      errorCorrectionLevel: "Q",
      width
    });
  }, []);

  return <canvas ref={canvasRef}></canvas>;

}

export default React.memo(QRCodeComponent);