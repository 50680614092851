import { all, fork, put, takeEvery, take, ActionPattern, call } from "redux-saga/effects";
import { WebsocketStatus, WebsocketActionTypes, websocketActions } from "./slice";
import { eventChannel, END } from "redux-saga";
import { Action } from "redux";
import { FSMessage } from "src/common/keys/protos/collections_pb";
import { Message, DriveErrorMessages, MessageHandlerDisplayType, DriveRequest, DriveSocket } from "src/common";
import { uiActions } from "..";

// ----------------------------------------------------------------
// Description: Send Message to server
// ----------------------------------------------------------------
function* fetchOnMessage(action: ReturnType<typeof websocketActions.wsOnMessage>) {
  const message = action.payload.message;
  // NOTE: Handle ui level message in component
  if (message.status === WebsocketStatus.error || message?.action === WebsocketStatus.error) {
    yield put(websocketActions.wsOnMessageError(message));
  } else {
    yield put(websocketActions.wsOnMessageSuccess(message));
  }
}

function* watchFetchMessageRequest() {
  yield takeEvery(WebsocketActionTypes.WS_ON_MESSAGE, fetchOnMessage);
}

// -----------------------------------------------------------------------------------------------------------------------------
// Drive Saga Utilities - will be moved to websocket
// ----------------------------------------------------------------------------------------------------------------------------
// Description: Saga for sending message 
export function* sendMessageSaga(saga_drive_socket: DriveSocket) {
  while (true) {
    const { payload } = yield take(websocketActions.wsSendDriveMessage);
    const request = payload as DriveRequest;
    try {
      // NOTE: Set the request for matching with response
      saga_drive_socket.setRequest(payload.request_id.String(), request);
      yield call([saga_drive_socket.socket, "send"], request.envelope);
    } catch (stack_error: unknown) {
      yield put(uiActions.handleRequestErrors(new Message(DriveErrorMessages.default, MessageHandlerDisplayType.logger),
        { stack_message: "sendMessageSaga error", stack_error }));
    }
  }
}

// Description: Incoming messages from  _onMessage()
function* receiveMessageSaga(socket_channel: ActionPattern, saga_drive_socket: DriveSocket) {
  while (true) { // NOTE: incoming messages from  _onMessage()
    const payload = (yield take(socket_channel)) as ArrayBuffer;
    const response = saga_drive_socket._onMessage(payload);
    const message = response.message;
    // if (!!message && message.getType() !== FSMessage.Type.RESPONSE) {
    //   // NOTE: On initializing new express account the socket recieves a series of notifications FSMessage.Type.NOTIFICATION
    //   // These can be captured here and addressed in the future 
    //   // const default_message = DriveErrorMessages.not_response_type.replace(MessageAnchors.actionType, getEnumKey(FSMessage.Type, message.getType()) || "NONE");
    //   // yield put(websocketActions.setDriveSocketNotifications({ default_message, message, message_type: message.getType(), request_id: message.getRequestId() }));
    // } else
    if (!!message && message.getType() === FSMessage.Type.RESPONSE && response.callback) {
      const _response = (yield call(response.callback, message, response.success_action)) as Action;
      // NOTE: If the callback is returning a type - put the action to the store here:
      if (!!_response.type) {
        yield put(_response);
      }
    } else {
      yield put(!!response.error_action ?
        response.error_action :
        uiActions.handleRequestErrors(new Message(DriveErrorMessages.default, MessageHandlerDisplayType.logger),
          {
            stack_messge: DriveErrorMessages.no_action_defined,
            stack_error: response
          }));
    }
  }
}

let saga_drive_socket: DriveSocket;
// Description: Initialize and bind websocket channels
function* initializeWebSocketsChannel(action: ReturnType<typeof websocketActions.initDriveWebsocket>): Generator {
  // NOTE: Prevent reinitializing the websocket on navigation
  if (!!saga_drive_socket && action.payload) {
    return;
  }

  try {
    saga_drive_socket = new DriveSocket();
    const socket_channel = yield call(webSocketListener, saga_drive_socket);
    yield put(websocketActions.wsSagaDriveStatus(WebsocketStatus.connected));
    // NOTE: Set permissions and default_collection_id only on init
    yield fork(sendMessageSaga, saga_drive_socket);
    yield call(receiveMessageSaga, socket_channel as ActionPattern, saga_drive_socket);
  } catch (error: unknown) {
    yield put(uiActions.handleRequestErrors(new Message(DriveErrorMessages.default, MessageHandlerDisplayType.logger),
      {
        stack_message: DriveErrorMessages.websocket_catch_all_error,
        stack_error: error
      }));
  } finally {
    // NOTE: Disconnected 
    // console.log("SOCKET Disconnected!");
    yield put(websocketActions.wsSagaDriveStatus(WebsocketStatus.disconnected));
  }
}

// Decription: Generic method for passing an array of actions to be run in parallel from 1 DriveRequest Callback (same request_id)
function* makeMultipleCallbacks(action: ReturnType<typeof websocketActions.makeMultipleCallbacks>): Generator {
  try {
    for (const callback of action.payload) {
      if (typeof callback.type === "string") {
        yield put(callback);
      }
    }
  } catch (error: unknown) {
    yield put(uiActions.handleRequestErrors(new Message(DriveErrorMessages.default, MessageHandlerDisplayType.logger),
      {
        stack_message: DriveErrorMessages.websocket_catch_all_error,
        stack_error: error
      }));
  }
}

// Description: Initialize the socket channel events
function webSocketListener(saga_drive_socket: DriveSocket) {
  const serviceWebSocket = saga_drive_socket.socket;
  return eventChannel((emitter) => {
    serviceWebSocket.onmessage = ({ data }: MessageEvent) => emitter(data);
    serviceWebSocket.onclose = () => emitter(END);
    serviceWebSocket.onerror = () => emitter(END);
    return () => serviceWebSocket.close();
  });
}

// ------------------------------------------------------------------------------------------
// Saga for Drive WebSocket
// ------------------------------------------------------------------------------------------
// Description: Get Drive collection information on load (root)
function* watchInitSocketSagaRequest() {
  yield takeEvery(WebsocketActionTypes.INIT_DRIVE_WEBSOCKET, initializeWebSocketsChannel);
}

// Description: Get Drive collection information on load (root)
function* watchMakeMultipleCallbacksRequest() {
  yield takeEvery(WebsocketActionTypes.DRIVE_WS_MULTIPLE_CALLBACKS, makeMultipleCallbacks);
}


// ----------------------------------------------------------------
// We can also use `fork()` here to split our saga into multiple watchers.
export function* wsSaga() {
  yield all([
    fork(watchFetchMessageRequest),
    fork(watchInitSocketSagaRequest),
    fork(watchMakeMultipleCallbacksRequest)
  ]);
}
