import React, { Dispatch, SetStateAction } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import { Icon, Loading } from "src/components";
import { EntryItem, VersionHistoryItem, Account, useDownloadFileMutation, useRestoreFileVersionMutation, DriveSuccessMessages, Message, MessageHandlerDisplayType, MessageToastTypes, useAppDispatch, DriveErrorMessages, NodePermissionSet, MessageAnchors } from "src/common";
import { uiActions } from "src/store";
import _ from "lodash";

type AllProps = {
  permissions: NodePermissionSet;
  entry: EntryItem;
  is_loading: boolean;
  history_list: VersionHistoryItem[];
  current_account: Account;
  getVersionHistory: Function;
  setDownloadingOrReverting: Dispatch<SetStateAction<boolean>>;
}
/* Description: This is the version history component for files. It only exists on app and not web hence all calls are going through filesync. 
  users can view list of versions, download a specific version or restore a past version of the file. */
function VersionHistoryComponent(props: AllProps) {
  const { is_loading, history_list, current_account, entry, getVersionHistory, setDownloadingOrReverting, permissions } = props;
  const [download_file] = useDownloadFileMutation();
  const [restore_file_version] = useRestoreFileVersionMutation();
  const dispatch = useAppDispatch();

  // Description: Download the specific file version. 
  async function downloadFile(item: VersionHistoryItem) {
    setDownloadingOrReverting(true);
    await download_file({
      user_id: current_account.user_id,
      file_id: entry.id,
      collection_id: entry.collection_id,
      file_name: entry.name,
      version: item.version
    })
      .unwrap()
      .then(() => {
        successfullyDownloaded();
        setDownloadingOrReverting(false);
      })
      .catch((msg) => {
        setDownloadingOrReverting(false);
        dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.error_downloading_file_version), msg));
      });
  }

  // Description: Restore the file to the version that has been selected.
  async function revertFile(item: VersionHistoryItem) {
    setDownloadingOrReverting(true);
    await restore_file_version({
      user_id: current_account.user_id,
      file_id: entry.id,
      collection_id: entry.collection_id,
      version: item.version
    })
      .unwrap()
      .then(() => {
        dispatch(uiActions.handleSetMessage(new Message(DriveSuccessMessages.success_reverting_file)));
        setDownloadingOrReverting(false);
        getVersionHistory();
      })
      .catch((msg) => {
        setDownloadingOrReverting(false);
        dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.error_reverting_file), msg));
      });
  }

  // Description: Popup message telling user that downloaded file is in PreVeil downloads folder (only for app not web)
  function successfullyDownloaded() {
    const message = DriveSuccessMessages.success_downloading_app;
    const title = DriveSuccessMessages.success_downloading.replace(MessageAnchors.file_name, entry.name);;
    const confirmation_dialog = new Message(message, MessageHandlerDisplayType.dialog, MessageToastTypes.primary, title, undefined,
      { label: "Close" },
    );
    dispatch(uiActions.handleSetMessage(confirmation_dialog));
  }

  return <div className="version-history">
    {!!is_loading && <Loading className="in-place" message="Fetching" />}
    {!is_loading && (
      <>
        {history_list &&
          _.map(history_list, (item: VersionHistoryItem, i: number) => {
            return (
              <Row key={`item_${i}`} className={i === 0 ? "text-muted" : ""} data-tooltip-id="pv-tooltip"
                data-tooltip-content={i === 0 ? "Current Version of File" : undefined}
                data-tooltip-place="right">
                <Col xs={6}>
                  {item.last_modified}
                </Col>
                <Col className="text-muted">
                  {item.size}
                </Col>
                <Col xs="auto" className="ms-auto">
                  {!!permissions.write && <Dropdown
                    className="btn btn-icon btn-sm pv-dropdown hide-arrow"
                    as="button"
                    disabled={i === 0}
                  >
                    <Dropdown.Toggle as="span">
                      <Icon className="ficon-more-vertical"></Icon>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="mt-1" align="end">
                      <Dropdown.Item onClick={() => revertFile(item)}>Revert</Dropdown.Item>
                      <Dropdown.Item onClick={() => downloadFile(item)}>Download</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>}
                </Col>
              </Row>
            );
          })}
      </>
    )}
  </div>;
}

export default React.memo(VersionHistoryComponent);
