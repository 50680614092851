/*
 *  File:            settings/slice.ts
 *  Description:     Holds types and constants for Settings module
 *  Author:          PreVeil
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationItem } from "@preveil-api";
import { InitialPaginationState } from "src/common";

export interface SettingsState {
  pagination: PaginationItem;
  /* NOTE: We will be using a new backend summary endpoint for setting
  the settings notification bubbles - BACK-851 */
  recovery_code_pending: boolean;
  recovery_group_pending: boolean;
  pending_approvals: number;
}

const initialState: SettingsState = {
  pagination: InitialPaginationState,
  recovery_code_pending: false,
  recovery_group_pending: false,
  pending_approvals: 0,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    destroySettings: () => {
      return initialState;
    },
    resetPagination: (state) => {
      state.pagination = initialState.pagination;
    },
    setPagination: (state, action: PayloadAction<PaginationItem>) => {
      state.pagination = action.payload;
    },
    setNotifications: (
      state,
      action: PayloadAction<{
        recoveryCodePending?: boolean;
        recoveryGroupPending?: boolean;
        approvals?: number;
      }>,
    ) => {
      state.recovery_code_pending =
        action.payload.recoveryCodePending !== undefined
          ? action.payload.recoveryCodePending
          : state.recovery_code_pending;
      state.recovery_group_pending =
        action.payload.recoveryGroupPending !== undefined
          ? action.payload.recoveryGroupPending
          : state.recovery_code_pending;
      state.pending_approvals =
        action.payload.approvals !== undefined ? action.payload.approvals : state.pending_approvals;
    },
  },
});

// ----------------------------------------------------------------------
// Global exports
// ----------------------------------------------------------------------
export const settingsActions = { ...settingsSlice.actions };
export default settingsSlice.reducer;
