import _ from "lodash";
import { ProgressTracker } from "src/common";

export function addProgress(progress: ProgressTracker[], tracker: ProgressTracker): ProgressTracker[] {
  return [...progress, tracker];
}

export function updateProgress(progress: ProgressTracker[], file_id: string, total?: number, conflict?: boolean, entries?: string[]): ProgressTracker[] {
  const index = progress.findIndex(t => t.file_id === file_id);
  const updated_progress = [...progress];
  const tracker = updated_progress[index];
  if (!tracker) return progress;
  const is_complete = tracker.done === tracker.total;
  updated_progress[index] = {
    ...tracker,
    total: total || tracker.total,
    conflict: is_complete ? tracker.conflict : conflict || tracker.conflict,
    entries: entries || tracker.entries
  };
  return updated_progress;
}

export function resolveProgress(progress: ProgressTracker[], file_id: string, file_name: string): ProgressTracker[] {
  const index = progress.findIndex(t => t.file_id === file_id);
  const updated_progress = [...progress];
  const tracker = updated_progress[index];
  if (!tracker) return progress;
  updated_progress[index] = { ...tracker, file_name, conflict: false };
  return updated_progress;
}

export function incrementProgress(progress: ProgressTracker[], file_id: string): ProgressTracker[] {
  const index = progress.findIndex(t => t.file_id === file_id);
  const updated_progress = [...progress];
  const tracker = updated_progress[index];
  if (!tracker) return progress;
  const done = _.min([tracker.done + 1, tracker.total]) || tracker.done + 1;
  updated_progress[index] = { ...tracker, done };
  return updated_progress;
}

export function errorProgress(progress: ProgressTracker[], file_id: string, error: string): ProgressTracker[] {
  const index = progress.findIndex(t => t.file_id === file_id);
  const updated_progress = [...progress];
  const tracker = updated_progress[index];
  if (!tracker) return progress;
  updated_progress[index] = { ...tracker, error };
  return updated_progress;
}

export function failAllProgress(progress: ProgressTracker[]): ProgressTracker[] {
  return progress.map(tracker => ({ ...tracker, error: "An error occured." }));
}
