import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import {
  useAppDispatch, AccountIdentifiers, useGetUsersKeyvalQuery, usePutUsersKeyvalMutation, CollectionKeyValCodes,
  Message, MessageHandlerDisplayType, SettingsErrorMessages, SettingsSuccessMessages, UUID, PutUsersKeyvalApiResponse, TinyMceCSPHeaders
} from "src/common";
import { Loading, Editor } from "src/components";
import { uiActions } from "src/store";

type AllProps = {
  account_ids: AccountIdentifiers;
}

function EmailSignatureComponent(props: AllProps) {
  const { account_ids } = props;
  const dispatch = useAppDispatch();
  const params = {
    account_ids,
    body: {
      user_id: account_ids.user_id,
      key: CollectionKeyValCodes.email_signature,
    },
  };
  const [saveEmailSignature, { isLoading: is_saving }] = usePutUsersKeyvalMutation();
  const { data, isLoading, error } = useGetUsersKeyvalQuery(params);
  const [signature, setSignature] = useState<string>();
  const [editor_key, setEditorKey] = useState<string>(new UUID().String());

  /* Description: set editor key as new random UUID - this key is needed for tinymce because without it, handleClear doesn't work. */
  function handleClear() {
    const new_key = new UUID().String();
    setEditorKey(new_key);
  }

  /* Description: Submit signature */
  function handleSubmit() {
    saveEmailSignature(
      Object.assign({}, params, { body: { ...params.body, ...{ val: signature } } }),
    )
      .unwrap()
      .then((response: PutUsersKeyvalApiResponse) => {
        !!response.errors
          ? dispatch(
            uiActions.handleRequestErrors(
              new Message(SettingsErrorMessages.error_saving_signature),
              response,
            ),
          )
          : dispatch(
            uiActions.handleSetMessage(new Message(SettingsSuccessMessages.signature_added)),
          );
      });
  }

  /* Description: load email signature, handle on complete and on error */
  useEffect(() => {
    !!data && setSignature(data?.value || "");
    // Dispatch error to logger quietly
    !!error &&
      dispatch(
        uiActions.handleRequestErrors(
          new Message(
            SettingsErrorMessages.error_fetching_signature,
            MessageHandlerDisplayType.logger,
          ),
          error,
        ),
      );
  }, [data, error]);

  return (
    <>
      <h2 className="content-header border-bottom">Email Signature</h2>
      <div className="max-700">
        <h5>
          Personalize your PreVeil email. Create an email signature to include specific information
          in every message that you send.
        </h5>
        {!isLoading ? (
          <>
            <div className="tiny">
              <Editor
                key={editor_key} // Note: added this key here because without it, handleClear doesn't work.
                tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
                onEditorChange={(newValue) => setSignature(newValue)}
                initialValue={data?.value || ""}
                init={{
                  skin: false,
                  content_css: `${process.env.PUBLIC_URL}/common/iframe-styles.css`,
                  content_css_cors: true,
                  height: 200,
                  menubar: false,
                  statusbar: false,
                  plugins: ["lists", "link", "image"],
                  convert_urls: false,
                  toolbar: "bold italic | bullist numlist | forecolor backcolor | fontsize | link",
                  fontsize_formats: "10px 12px 14px 16px 18px 24px 36px",
                  lists_indent_on_tab: true,
                  content_security_policy: TinyMceCSPHeaders
                }}
              />
            </div>
            <div className="email-signature-footer">
              <Button disabled={is_saving} onClick={handleSubmit} className="send-button">
                Submit
              </Button>
              <Button variant="outline-primary" disabled={is_saving} onClick={handleClear}>
                Clear
              </Button>
            </div>
          </>
        ) : (
          <Loading className="in-place" />
        )}
      </div>
    </>
  );
}

export default React.memo(EmailSignatureComponent);

