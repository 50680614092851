import * as React from "react";
import { Row, Col } from "react-bootstrap";

function AccessHeaderComponent() {
  return <Row className="access-header">
    <Col xs={5}>
      User
    </Col>
    <Col xs={4}>
      Expiration Date
    </Col>
    <Col xs={"auto"} className="ms-auto">
      Access
    </Col>
  </Row>;
}

export default React.memo(AccessHeaderComponent);