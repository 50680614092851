
import React from "react";
import { Button, Row, Col } from "react-bootstrap";
import { AdminUIActionTypes } from "src/common";
import { Icon } from "src/components";
import { ActionHandlerFunction } from "@preveil-api";
// type User = UserProfile & CollectionServerUser;

type AllProps ={
  is_read_only?: boolean;
  approve_requester?: boolean;
  user: any; // TODO: Fix type here. User type above
  is_approved?: boolean;
  handleActions?: ActionHandlerFunction;
}

function DataExportApproversRow({ is_read_only, user, is_approved, handleActions }: AllProps) {
  const address = user?.address || user?.user_id || "";
  const name = user?.name || user?.display_name || null;
  function showUiActions() {
    if (is_read_only) {
      return;
    }
    if (!is_approved) {
      return (
        <Button
          className="start-btn btn-sm"
          variant="outline-secondary"
          onClick={() => handleActions && handleActions({ actionType: AdminUIActionTypes.Start, params: { subsumeUser: address } })}
        >
          Start
        </Button>
      );
    } else {
      return (
        <div className="approver-check">
          <Icon className="icon-check" />
        </div>
      );
    }
  }

  return (
    <Row className={`align-items-center ${!name ? "user-id-format" : ""}`}>
      <Col xs={10}>
        {!!name && <p className="semi-bold">{name}</p> }
        <p>{address}</p>
      </Col>
      <Col xs={2} className="d-flex justify-contend-end">
        {showUiActions()}
      </Col>
    </Row>
  );
}

export default React.memo(DataExportApproversRow);
