import React, { useState } from "react";
import { Button, Modal, Dropdown } from "react-bootstrap";
import { ActionHandlerFunction, SelectedUser } from "@preveil-api";
import { RecoveryGroupMembersCard } from "..";
import { AdminUIActionTypes, AdminRecoveryGroup } from "src/common";

type GroupMember = {
  name: string | null;
  address: string;
  role?: string | undefined;
};

type AllProps = {
  show_modal: boolean;
  recovery_groups: AdminRecoveryGroup[];
  handleActions: ActionHandlerFunction;
  users: SelectedUser[];
};

// Description: This component is a modal that allows an admin to set a recovery group for an user.
function SetRecoveryGroupModal({ show_modal, recovery_groups, handleActions, users }: AllProps) {
  const [ selectRecoveryGroup, setRecoveryGroup ] = useState<AdminRecoveryGroup | null>(null);
  const [ recoveryGroupMembers, setRecoveryGroupMembers ] = useState<GroupMember[]>([]);
  
  function handleDropdownChange(event: React.MouseEvent<HTMLElement>) {
    const { id } = event.target as HTMLElement;
    const recoveryGroup = recovery_groups.find((group: AdminRecoveryGroup) => group.groupId === id);
    if (recoveryGroup && recoveryGroup?.approvers) {
      setRecoveryGroup(recoveryGroup);
      setRecoveryGroupMembers(recoveryGroup.approvers);
    }
  }

  function handleCancel() {
    setRecoveryGroup(null);
    setRecoveryGroupMembers([]);
    handleActions({ actionType: AdminUIActionTypes.ShowModal, params: "set-recovery-group" });
  }

  return (
    <>
      <Modal
        size="lg"
        show={show_modal}
        onHide={handleCancel}
        aria-labelledby="set-recovery-group"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Recovery Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="mt-2 mb-3">
              <h5>Set User Recovery Group</h5>
              <div>
                <Dropdown>
                  <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                    {!!selectRecoveryGroup ? selectRecoveryGroup?.name : "Select Recovery Group"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="start">
                    {recovery_groups.map((group: any) => {
                      const { groupId, name } = group;
                      return (
                        <Dropdown.Item key={groupId} id={groupId} onClick={handleDropdownChange}>{name}</Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {!!selectRecoveryGroup && (
                <section className="group-recovery-section">
                  <div className="group-recovery-members">
                    <h5>{`Members of ${selectRecoveryGroup.name} Group`}</h5>
                    <RecoveryGroupMembersCard members={recoveryGroupMembers} />
                  </div>
                  <p>Approvers required for recovery: <strong>{`${selectRecoveryGroup.requiredApprovers}`}</strong></p>
                </section>
              )}
              <div className={`${!selectRecoveryGroup ? "mt-4" : ""}`}>
                <p><strong>Notice:</strong> By assigning the recovery group to the selected users, you are protecting their accounts against accidental loss or theft.</p>
              </div>
            </div>
            <footer className="mt-4">
              <Button
                disabled={!selectRecoveryGroup}
                onClick={() => handleActions({ actionType: AdminUIActionTypes.SetRecoveryGroup, params: { recoveryGroup: selectRecoveryGroup} })}
              >
                Set Recovery Group
              </Button>
              <Button
                onClick={handleCancel}
                variant="no-outline-primary"
              >
                Cancel
              </Button>
            </footer>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default React.memo(SetRecoveryGroupModal);