import React, { FocusEvent, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { ActionHandlerFunction, DeviceCertificate } from "@preveil-api";
import { AdminUIActionTypes, CheckboxStatesTypes, dayjs } from "src/common";
import { Checkbox, InfoPopover } from "src/components";

type AllProps = {
  certificate: DeviceCertificate;
  isChecked: CheckboxStatesTypes;
  handleActions: ActionHandlerFunction;
};

function DeviceCertificateRowComponent(props: AllProps) {
  const { certificate, isChecked, handleActions } = props;
  const expiration_date = dayjs(certificate.expiration_date);
  const [expiration_class, setExpirationClass] = useState<string>("");
  const [popover_message, setPopoverMessage] = useState<string>("");

  useEffect(() => {
    const now = dayjs();
    const one_week_from_now = dayjs().add(1, "week");

    if (expiration_date.isBefore(now)) {
      setExpirationClass("strikethrough-red");
      setPopoverMessage("This certificate has expired.");
    } else if (expiration_date.isAfter(now) && expiration_date.isBefore(one_week_from_now)) {
      setExpirationClass("warning-yellow");
      setPopoverMessage("This certificate will expire soon.");
    } else {
      setExpirationClass("");
      setPopoverMessage("");
    }
  }, []);

  return (
    <>
      <Row>
        <div className="user-row-checkbox">
          <Checkbox
            className="sr-only"
            onChange={(e: FocusEvent<HTMLInputElement>) => handleActions({ actionType: AdminUIActionTypes.SingleSelect, params: { e, certificate } })}
            selected={isChecked}
            value={certificate}
            label="Select Certificate"
          />
        </div>
        <Col>
          {certificate.issued_to}
        </Col>
        <Col>
          {certificate.issued_by}
        </Col>
        <Col className={expiration_class}>
          {certificate.expiration_date}
          {popover_message !== "" && <InfoPopover message={popover_message} />}
        </Col>
        <Col>
          {certificate.friendly_name}
        </Col>
      </Row>
    </>
  );
}

export default React.memo(DeviceCertificateRowComponent);
