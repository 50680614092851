import * as React from "react";
import { Dropdown } from "react-bootstrap";
import { DriveUIActionTypes, Feature, DriveFeature, useAppSelector, viewerUnsupported, buildNavigationLink } from "src/common";
import { Icon } from "src/components";
import { useNavigate } from "react-router-dom";
import { DropdownProps } from ".";
import { RootState } from "src/store/configureStore";

// NOTE: all_actions Pass true for outside detail view component
// NOTE: permissions Pass parent collection permissions
function FileMenuComponent(props: DropdownProps) {
  const { entry, all_actions, permissions, handleAction } = props;
  const current_directory = useAppSelector((state: RootState) => state.drive.current_directory);
  const root_info = useAppSelector((state: RootState) => state.drive.root_info);
  const navigate = useNavigate();

  function handleView() {
    const url =
      !!current_directory ?
        buildNavigationLink(entry.collection_id, current_directory.id, entry.type, entry.id) : undefined;
    !!url && navigate(url);
    handleAction({ actionType: DriveUIActionTypes.DownloadAndView, params: entry });
  }

  return <>
    <Dropdown.Item aria-label="Details"
      hidden={!all_actions}
      data-tooltip-id="dd-tooltip" data-tooltip-content="File Details"
      data-tooltip-place="right"
      onClick={() => handleAction({ actionType: DriveUIActionTypes.Details, params: { entry, action: DriveUIActionTypes.Lock } })}>
      <Icon className="pv-icon-finder" />Details
    </Dropdown.Item>

    {
      !!permissions &&
      <>
        {/* NOTE: Direct Link does not work for Web build */}
        <Dropdown.Item aria-label="Copy Link"
          hidden={Feature.hidden(DriveFeature.CopyLink)}
          data-tooltip-id="dd-tooltip" data-tooltip-content="Copy Link to File"
          data-tooltip-place="right"
          onClick={() => handleAction({ actionType: DriveUIActionTypes.CopyLink, params: entry })}>
          <Icon className="ficon-link" />Copy Link
        </Dropdown.Item>
        <Dropdown.Item
          hidden={viewerUnsupported(entry)}
          aria-label="View File"
          data-tooltip-id="dd-tooltip" data-tooltip-content="View File"
          data-tooltip-place="right"
          onClick={() => handleView()}>
          <Icon className="ficon-maximize" />View {entry.type_label || "File"}
        </Dropdown.Item>
        <Dropdown.Item aria-label="Download"
          hidden={Feature.hidden(DriveFeature.Download, permissions)}
          data-tooltip-id="dd-tooltip" data-tooltip-content="Download File"
          data-tooltip-place="right"
          onClick={() => handleAction({ actionType: DriveUIActionTypes.Download, params: entry })}>
          <Icon className="ficon-download" />Download
        </Dropdown.Item>
        <Dropdown.Item aria-label="Rename"
          hidden={Feature.hidden(DriveFeature.Rename, permissions)}
          data-tooltip-id="dd-tooltip" data-tooltip-content="Rename File"
          data-tooltip-place="right"
          onClick={() => handleAction({ actionType: DriveUIActionTypes.Details, params: { entry, action: DriveUIActionTypes.Rename } })}>
          <Icon className="pv-icon-compose" />Rename
        </Dropdown.Item>
        <Dropdown.Item aria-label="Edit"
          hidden={Feature.hidden(DriveFeature.EditFile, permissions)}
          data-tooltip-id="dd-tooltip"
          data-tooltip-content="Edit File"
          data-tooltip-place="right"
          onClick={() => handleAction({ actionType: DriveUIActionTypes.EditFile, params: entry })}>
          <Icon className="icon-pencil7" />Edit File
        </Dropdown.Item>
        <Dropdown.Item aria-label="Lock File"
          hidden={Feature.hidden(DriveFeature.Lock, permissions) || entry.collection_id === root_info?.collection_id}
          data-tooltip-id="dd-tooltip" data-tooltip-content="Lock File"
          data-tooltip-place="right"
          onClick={() => handleAction({ actionType: DriveUIActionTypes.Details, params: { entry, action: DriveUIActionTypes.Lock } })}>
          <Icon className="ficon-lock" />{!!entry.is_locked ? "Unlock" : "Lock"}
        </Dropdown.Item>
        <Dropdown.Item aria-label="Delete"
          hidden={Feature.hidden(DriveFeature.Delete, permissions)}
          data-tooltip-id="dd-tooltip" data-tooltip-content="Delete File"
          data-tooltip-place="right"
          onClick={() => handleAction({ actionType: DriveUIActionTypes.Delete, params: entry })}>
          <Icon className="pv-icon-trash" />Delete
        </Dropdown.Item>
        <Dropdown.Item aria-label="Move"
          hidden={Feature.hidden(DriveFeature.Move, permissions)}
          data-tooltip-id="dd-tooltip" data-tooltip-content="Move File"
          data-tooltip-place="right"
          onClick={() => handleAction({ actionType: DriveUIActionTypes.MoveModal, params: { show: true, entry } })}>
          <Icon className="pv-icon-move" />Move
        </Dropdown.Item>
        <Dropdown.Item aria-label="Duplicate"
          hidden={Feature.hidden(DriveFeature.DuplicateFile, permissions)}
          data-tooltip-id="dd-tooltip" data-tooltip-content="Duplicate File"
          data-tooltip-place="right"
          onClick={() => handleAction({ actionType: DriveUIActionTypes.DuplicateFile, params: entry })}>
          <Icon className="ficon-copy" />Duplicate
        </Dropdown.Item>
      </>
    }
  </>;
}

export default React.memo(FileMenuComponent);