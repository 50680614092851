import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MailboxesBase, MailboxItem, MailboxesState } from "@preveil-api";
import {
  Account, GlobalErrorMessages, Message, MessageHandlerDisplayType, useAppDispatch, getActiveMailboxByUrlName, MailErrorMessages, prepareMailboxes,
  DefaultRoutes, useAppSelector, useGetMailByCollectionIdMailboxesQuery, AppConfiguration,
  SupportRoutes
} from "src/common";
import { mailActions, uiActions } from "src/store";
import { InlineListError, Icon } from "src/components";
import { RootState } from "src/store/configureStore";

type AllProps = {
  current_account: Account;
  mailbox_name: string;
}

function MailboxesComponent(props: AllProps) {
  const { current_account, mailbox_name } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const account_ids = Account.getAccountIdentifiers(current_account);
  const redirect_url = useAppSelector((state: RootState) => state.ui.redirect_url);
  const default_mailboxes = useAppSelector((state: RootState) => state.mail.default_mailboxes);
  const custom_mailboxes = useAppSelector((state: RootState) => state.mail.custom_mailboxes);
  const { data, error } = useGetMailByCollectionIdMailboxesQuery({
    account_ids,
    body: {
      collectionId: current_account.mail_cid,
      since_rev_id: 0,
      fetch_messages: false,
    }
  });

  useEffect(() => {
    !!data && handleFetchMailboxesSuccess(data);
    !!error && dispatch(uiActions.handleRequestErrors(new Message(GlobalErrorMessages.default, MessageHandlerDisplayType.logger), error));
    // NOTE: If mailbox is updated but data has not come in then load the mailbox from store (solves navigation stale state)
    (!!mailbox_name && !data && !!default_mailboxes) && handleSetActiveMailbox(mailbox_name, default_mailboxes, custom_mailboxes);
  }, [data, error, mailbox_name]);

  // Description: Get the mailboxes from the server and set the state for default mailboxes, custom mailboxes and current_mailbox 
  // To Do: Client Default Mailbox: outbox, unread, starred
  function handleFetchMailboxesSuccess(mailboxes_base: MailboxesBase) {
    const mailboxes_state: MailboxesState = prepareMailboxes(mailboxes_base);
    dispatch(mailActions.setMailboxesSuccess(mailboxes_state));

    // Decription: Set Current Mailbox: 
    !!mailbox_name && handleSetActiveMailbox(mailbox_name, mailboxes_state.default_mailboxes, mailboxes_state.custom_mailboxes, mailboxes_base.mailboxes);
  }

  // Decription: Set Current Mailbox: 
  function handleSetActiveMailbox(_mailbox_name: string, default_mailboxes: MailboxItem[], custom_mailboxes?: MailboxItem[], mailboxes?: MailboxItem[]) {
    const _current_mailbox = getActiveMailboxByUrlName(mailbox_name, default_mailboxes, custom_mailboxes);
    _current_mailbox ? dispatch(mailActions.setCurrentMailboxSuccess(_current_mailbox)) :
      handleMailboxNotFoundError(mailboxes);
  }

  // Description: Navigate away if the current active param is not an existing mailbox
  function handleMailboxNotFoundError(mailboxes?: MailboxItem[]) {
    if (!!redirect_url) {
      dispatch(uiActions.handleSetRedirectUrl()); // Reset Redirect in state
      navigate(redirect_url);
    } else {
      dispatch(uiActions.handleRequestErrors(new Message(MailErrorMessages.no_mailbox_found),
        {
          error: `User is attempting to access an invalid mailbox: ${mailbox_name}. Not found in the mailboxes object,`,
          mailboxes
        }));
      navigate(`${DefaultRoutes.mail_default}`);
    }
  }

  return !!error ? <InlineListError>
    <p className="mb-0">Error fetching data</p>
    <p>Please refresh or contact support</p>
    <small className="d-flex mt-3">
      <Icon className="pv-icon-question" />
      {
        AppConfiguration.buildForWeb() ?
          <span>If this problem persists please contact support</span> :
          <span>If this problem persists after refreshing, try: <br />
            <a rel="noreferrer" target="_blank"
              href={SupportRoutes.restarting_preveil_services}>Restarting PreVeil Services
            </a>&nbsp;or&nbsp;<a rel="noreferrer" target="_blank"
              href={SupportRoutes.troubleshooting_preveil} >
              Troubleshooting PreVeil</a>
          </span>
      }
    </small>
  </InlineListError> : <></>;
}

export default React.memo(MailboxesComponent);