import React, { createRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import {
  DriveFileType, DocRenderTypes, FileSizeLimits, Message, MessageHandlerDisplayType, useAppDispatch, EntryItem,
  useDownloadFile, Account, AppConfiguration, useAppSelector, NodePermissionSet, DOCS_PERMISSIONS_TYPES, buildNavigationLink, DriveEntryType,
  DriveUrlParams, DriveErrorMessages, MessageAnchors, DefaultRoutes
} from "src/common";
import { uiActions, driveActions } from "src/store";
import { RootState } from "src/store/configureStore";
import { Icon, Loading } from "src/components";
import FileViewer from "./FileViewer.component";

type AllProps = {
  file_entry: EntryItem;
  current_account: Account;
  router_params?: DriveUrlParams;
  permissions?: NodePermissionSet;
  resetFileEntry: () => void;
};

function FileDownloadAndViewComponent(props: AllProps) {
  const { file_entry, resetFileEntry, current_account, permissions, router_params } = props;
  const file_type = !!file_entry.type_label ? file_entry.type_label : undefined;
  const is_app = AppConfiguration.buildForApp();
  const [loading, setLoading] = useState<boolean>(true);
  const [fullscreen, setFullscreen] = useState<boolean>(false);
  const [view_only, setViewOnly] = useState<boolean>(false);
  const [file_to_view, setFileToView] = useState<File>();
  const [rendering_type, setRenderingType] = useState<string>();
  const { file, downloadFile, error, resetDownload, file_name } = useDownloadFile(current_account, is_app);
  const docContainer = createRef<HTMLDivElement>();
  const dispatch = useAppDispatch();
  const current_directory = useAppSelector((state: RootState) => state.drive.current_directory);
  const root_info = useAppSelector((state: RootState) => state.drive.root_info);
  const navigate = useNavigate();

  // Description: On load - calls appropriate function depending on app configuration (web or local)
  useEffect(() => {
    isViewOnly();
    resetDownload();
    downloadFile(file_entry.collection_id, file_entry.id, file_entry.name, true);
  }, []);

  // Description: handles what the downloadFileWeb hook returns (either an error or the file blob).
  useEffect(() => {
    !!file && handleDownloadSuccess(file);
    error && handleDownloadError();
  }, [file, error, file_name]);

  // Description: setsRenderType once the downloaded file is set
  useEffect(() => {
    !!file_to_view && !!file_type && setRenderType(file_to_view, file_type);
  }, [file_to_view]);

  // Description: calls prepareFileReader once rendering type is set`
  useEffect(() => {
    !!file_to_view && prepareFileReader(file_to_view);
  }, [rendering_type]);

  // Description: sets the view only state depending on permissions and location of file.
  function isViewOnly() {
    // discern if default collection, if so then show
    if (
      !!current_directory &&
      !!root_info &&
      current_directory.collection_id === root_info.collection_id
    ) {
      setViewOnly(false);
    } else if (!permissions) {
      setViewOnly(true);
    } else {
      setViewOnly(permissions.type === DOCS_PERMISSIONS_TYPES.view_only);
    }
  }

  // Description: based on the file type, it sets the endering type. This is used to prepare the file viewer. 
  // (if its of type viewer it will use pdftron)
  function setRenderType(downloaded_file: File, type: string): void {
    switch (type) {
      case DriveFileType.Word:
      case DriveFileType.Excel:
      case DriveFileType.PowerPoint:
      case DriveFileType.PDF:
        setRenderingType(DocRenderTypes.viewer);
        break;
      case DriveFileType.Image:
        if (
          downloaded_file.name.toLowerCase().endsWith(".tif") ||
          downloaded_file.name.toLowerCase().endsWith(".tiff")
        ) {
          setRenderingType(DocRenderTypes.viewer);
        } else {
          setRenderingType(DocRenderTypes.image);
        }
        break;
      case DriveFileType.Text:
      case DriveFileType.Code:
        setRenderingType(DocRenderTypes.text);
        break;
      default:
        dispatch(
          uiActions.handleRequestErrors(
            new Message(
              DriveErrorMessages.invalid_document_type.replace(MessageAnchors.file_name, type),
              MessageHandlerDisplayType.logger,
            ),
          ),
        );
        resetFileEntry();
        break;
    }
  }

  // Description: If the rendering type is an image or text, it prepares the file viewer using a docContainer and FileReader
  async function prepareFileReader(downloaded_file: File) {
    switch (rendering_type) {
      case DocRenderTypes.image:
        const fileUrl = URL.createObjectURL(downloaded_file);
        if (!!docContainer.current) {
          docContainer.current.style.backgroundImage = `url(${fileUrl})`;
          docContainer.current.classList.add("image");
          // At a certain size, using image contain makes sense to make sure it fits
          if (downloaded_file.size > FileSizeLimits.DOC_VIEWER_IMAGE_SIZE_CAP) {
            docContainer.current.classList.add("large-image");
          }
          setLoading(false);
        }
        break;
      case DocRenderTypes.text:
        // using FileReader as its generally supported across browsers
        const file_reader = new FileReader();
        file_reader.onload = (e) => {
          // @ts-expect-error
          let text = e.target.result as string;
          if (!!text) {
            // format spaces and line endings
            text = text
              // replace line ends with <br>
              .replace(/\r|\v|\n/gm, "<br>")
              // replace multiple spaces with &nbsp;
              // but individual spaces can stay as that can negatively impact word wrapping
              .replace(/\s{2,}/gm, (spaces) => "&nbsp;".repeat(spaces.length));
          }
          if (!!docContainer.current) {
            docContainer.current.innerHTML = text;
            docContainer.current.classList.add("text");
          }
          setLoading(false);
        };
        file_reader.readAsText(downloaded_file);
        break;
    }
  }

  // Description: Converts a Blob Object to a File.
  function blobToFile(blob: Blob, modified_date: Date, name: string): File {
    // defining as any so we can add properties
    const updated_blob: any = blob;
    updated_blob.lastModified = modified_date;
    updated_blob.name = name;
    // Cast to a File() type
    return updated_blob as File;
  }

  // Description: (for both web and app) when there is an error on download - displays an error and resets the state.
  function handleDownloadError(msg?: any) {
    dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.error_downloading_file.replace(MessageAnchors.file_name, file_entry.name)), msg));
    reset();
    resetDownload();
  }

  // Description: (for both web and app) when successfully downloaded - checks if the user just wants to download or also view the file and 
  // handles it respectively
  function handleDownloadSuccess(blob: Blob) {
    const _file: File = blobToFile(blob, new Date(), file_entry.name);
    setFileToView(_file);
    setLoading(false);
  }

  // Description: When the user closes the file viewer it navigates back to the directory
  // (since we are now using urls for files as well in v2), if the ids are equal to the root then navigate to the root path (/drive/root)
  function closeFileViewer() {
    reset();
    if (!!router_params) {
      const { collection_id, id } = router_params;
      !!root_info && root_info.collection_id === collection_id && root_info.id === id ?
        navigate(DefaultRoutes.drive_root) :
        navigate(buildNavigationLink(collection_id, id, DriveEntryType.DIR));
    }
  }

  function reset() {
    dispatch(driveActions.setLoading(false));
    resetFileEntry();
    setLoading(false);
  }

  return (
    <Modal
      size="xl"
      centered
      show={!!file_to_view}
      fullscreen={!!fullscreen || undefined}
      className="file-viewer"
      dialogClassName={`${fullscreen ? "fullscreen" : ""}`}
      scrollable={true}
      aria-labelledby="fullscreen modal"
      onHide={() => closeFileViewer()}>
      <Modal.Header closeButton className="ps-3 pe-3" closeVariant="white">
        <Modal.Title>{file_entry.name}</Modal.Title>
        <Button
          variant="transparent"
          className="btn-icon ms-auto maximize"
          size="sm"
          onClick={() => setFullscreen(!fullscreen)}
        >
          <span>
            <Icon className={!!fullscreen ? "ficon-minimize-2" : "ficon-maximize-2"} />
          </span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        {(!!loading) && (
          <Loading message="Loading File" className="text-white"></Loading>
        )}
        <div id="doc-outer-container">
          {!!file_to_view && rendering_type === DocRenderTypes.viewer && !!file_type && (
            <FileViewer
              file={file_to_view}
              type={file_type}
              is_view_only={view_only}
              setLoading={setLoading}
            />
          )}
          <div id="doc-container" ref={docContainer}></div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default React.memo(FileDownloadAndViewComponent);
