import React, { useEffect, useState } from "react";
import { Image, Button } from "react-bootstrap";
import errorImg from "src/assets/images/embarassed-face.svg";
import { useAppDispatch, useAppSelector, QueryParamKeys, PublicRoutes } from "src/common";
import { accountActions } from "src/store";

function PageNotFoundComponent(props: any) {
  const dispatch = useAppDispatch();
  const [user_id, setUserId] = useState<string>();
  const current_user_id = useAppSelector((state) => state.account.current_account?.user_id);

  // Notes: Save current user_id to local state to be able to  relogin with it.
  // Logout so the top nav is reset
  useEffect(() => {
    !!current_user_id && setUserId(current_user_id);
    dispatch(accountActions.logout());
  }, []);

  return (
    <div className="mx-auto max-600 mt-5">
      <div className="not-found">
        <Image alt="PreVeil Logo" className="mb-4" src={errorImg} />
        <h3>Error 404</h3>
        <p><em>Uh oh - we weren't able to find anything at this address!</em></p>
        <Button href={`/${PublicRoutes.login_route}${!!user_id ? `?${QueryParamKeys.USER_ID_QUERY_KEY}=${user_id}` : ""}`}>Go Back</Button>
      </div>
    </div>
  );
}

export default React.memo(PageNotFoundComponent);
