import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ButtonGroup, Button, Form, Offcanvas, InputGroup, Col, Row } from "react-bootstrap";
import { Account, useAppDispatch, useAppSelector, usePutDeviceKeyRotationMutation, PublicRoutes, usePostUsersFindMutation, useDeleteUsersMutation } from "src/common";
import { Icon } from "src/components";
import { uiActions } from "src/store";

type AllProps = {
  is_polling: boolean;
  setPolling: (setPolling: boolean) => void;
}
// NOTE: Switch this to true to activate the OffCanvasDevToolsComponent, BUT Save as false before committing
const activate_off_canvas = false;
function OffCanvasDevToolsComponent(props: AllProps) {
  const { setPolling, is_polling } = props;
  const current_account = useAppSelector((state) => state.account.current_account);
  const userId = current_account?.user_id;
  const [show, setShow] = useState(activate_off_canvas);
  const [hide_tools, sethide_tools] = useState(!activate_off_canvas);
  const [delete_user_id, setDeleteUserId] = useState<string>("");
  const [rotateKey] = usePutDeviceKeyRotationMutation();
  const [findUsers, { isLoading }] = usePostUsersFindMutation();
  const [deleteUser] = useDeleteUsersMutation();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Description: Rotate device key for testing
  function TriggerKeyRotation() {
    !!userId && rotateKey({ userId }).unwrap()
      .then(() => {
        console.log("rotateDeviceUserKey SUCCESS: ");
        setShow(false);
      });
  }

  // Description: Rotate device key for testing
  async function TriggerCallback() {
    if (!!current_account) {
      findUsers({
        account_ids: Account.getAccountIdentifiers(current_account),
        body: { spec: [{ user_id: userId, key_version: -1 }] }
      }).unwrap()
        .then((response: any) => {
          console.log("findUsers SUCCESS: ", response);
        })
        .catch((errors: unknown) => {
          console.log("catch: ", errors);
        });
    }
  }

  function handleDeleteUserId() {
    console.log("handleDeleteUserId", delete_user_id);
    if (!!current_account && !!delete_user_id) {
      deleteUser({
        account_ids: Account.getAccountIdentifiers(current_account),
        body: { user_id: delete_user_id }
      }).unwrap()
        .then((response: any) => {
          console.log(response);
          navigate(`/${PublicRoutes.logout_route}`);
        })
        .catch((errors: unknown) => {
          console.log("catch: ", errors);
        });
    }
  }

  return <>
    {/* Remove d-none to have the dev tools off canvas avail */}
    <ButtonGroup className={`dev-tools ${hide_tools ? "d-none" : ""}`}>
      <Button variant="info" className="btn-icon" onClick={handleShow}>
        <Icon className="pv-icon-chevron-up" />
      </Button>
      <Button variant="secondary" className="btn-icon" onClick={() => sethide_tools(true)}>
        <Icon className="pv-icon-close" />
      </Button>
    </ButtonGroup>
    <Offcanvas show={show} onHide={handleClose} placement="bottom">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Dev Tools</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="py-0">
        {
          <p>{isLoading.toString()}</p>
        }
        <Form.Check
          type="switch"
          id="custom-switch"
          label="Toggle Crypto Check Polling Calls"
          checked={is_polling}
          onChange={() => {
            setPolling(!is_polling);
          }} />
        <div className="btn-panel mt-2">
          <Button onClick={TriggerKeyRotation}>TriggerKeyRotation</Button>
          <Link className="btn btn-primary" to={`/${PublicRoutes.get_started_route}`}>
            Logout
          </Link>
          <Button onClick={TriggerCallback}>Find Users CS Call</Button>
          <Button
            onClick={() => {
              dispatch(uiActions.handleSetDirtyMessage(true));
            }}>
            Dirty Warning</Button>
        </div>
        <Row>
          <Col sm={4}>
            <InputGroup size="sm">
              <Form.Control
                type="text"
                name="user_id"
                value={delete_user_id}
                onChange={(e: React.FocusEvent<HTMLInputElement>) => setDeleteUserId(e.currentTarget.value)}
              />
              <Button
                size="sm"
                tabIndex={-1}
                onClick={handleDeleteUserId}>Delete</Button>
            </InputGroup>
          </Col>
        </Row>
      </Offcanvas.Body>
    </Offcanvas >
  </>;
}

export default React.memo(OffCanvasDevToolsComponent);