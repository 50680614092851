import * as React from "react";
import { ActionHandlerFunction } from "@preveil-api";
import { Modal } from "react-bootstrap";
import { ApprovalGroup, OrganizationInformation } from "src/common";
import { CreateApprovalGroupForm } from "..";

type AllProps = {
  saved_approval_groups: ApprovalGroup[];
  createModalShow: boolean;
  setCreateModalShow: React.Dispatch<React.SetStateAction<boolean>>;
  copiedApprovalGroup?: ApprovalGroup;
  handleAction: ActionHandlerFunction;
  setCopiedApprovalGroup: React.Dispatch<React.SetStateAction<ApprovalGroup | undefined>>;
  organizationInfo: OrganizationInformation;
};

function CreateApprovalGroupModalComponent(props: AllProps) {
  const { createModalShow, setCreateModalShow, copiedApprovalGroup, handleAction, setCopiedApprovalGroup, organizationInfo, saved_approval_groups } = props;

  return (
    <Modal
      size="lg"
      show={createModalShow}
      onHide={() => { setCopiedApprovalGroup(undefined); setCreateModalShow(false);}}
      aria-labelledby="create approval group"
      centered
    >
      <Modal.Header>
        <Modal.Title>Create Approval Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CreateApprovalGroupForm
          saved_approval_groups={saved_approval_groups}
          setCreateApprovalGroupShow={setCreateModalShow}
          copiedApprovalGroup={copiedApprovalGroup}
          handleAction={handleAction}
          setCopiedApprovalGroup={setCopiedApprovalGroup}
          org_users={organizationInfo.org_users}
        />
      </Modal.Body>
    </Modal>
  );
}

export default React.memo(CreateApprovalGroupModalComponent);
