import { MiddlewareAPI } from "redux";
import { WebsocketMessage } from "@preveil-api";
import { GlobalErrorMessages, Message, WebsocketErrors, isValidJSON, MessageHandlerDisplayType, WebsocketConnectionRoutes } from "src/common";
import { WebsocketActionTypes, uiActions, OnConnectParams } from "src/store";

/*
* Description:  Handles interactions between the Server and Client through Websocket
* USAGE:        Initialize websocket  with wsConnect(DataObj);
* DataObject:   { host: TargetURL, init?: InitObject } - InitObject will default to { action: "init" } if undefined
*               Note: might remove allow for null | undefined in the future.
*/
export const websocketMiddleware = () => {
    return (store: MiddlewareAPI<any, any>) => {
        let socket: WebSocket;
        let notify_socket: WebSocket;
        const onOpen = (store: MiddlewareAPI, params: OnConnectParams) => (event: Event) => {
            // NOTE: Initialize socket - only send init if passed in payload
            !!params.init && socket.send(params.init);
            !!params.init && JSON.parse(params.init).action === "pre_init" && socket.send(JSON.stringify({ action: "init" }));
            store.dispatch({ type: WebsocketActionTypes.WS_CONNECTED, payload: event });
        };
        const onClose = (store: MiddlewareAPI) => (payload: CloseEvent) => store.dispatch({ type: WebsocketActionTypes.WS_DISCONNECTED, payload });
        const onError = (store: MiddlewareAPI) => (payload: Event) => store.dispatch({ type: WebsocketActionTypes.WS_ERROR, payload });
        const onMessage = (store: MiddlewareAPI) => (message: MessageEvent) => {
            const _message = isValidJSON(message.data) ? JSON.parse(message.data) : message.data;
            store.dispatch({ type: WebsocketActionTypes.WS_ON_MESSAGE, payload: { message: _message } });
        };

        // Notify Handlers
        const onNotifyOpen = (store: MiddlewareAPI) => (event: Event) => store.dispatch({ type: WebsocketActionTypes.NOTIFY_WS_CONNECTED, payload: event });
        const onNotifyClose = (store: MiddlewareAPI) => (payload: CloseEvent) => store.dispatch({ type: WebsocketActionTypes.NOTIFY_WS_DISCONNECTED, payload });
        const onNotifyError = (store: MiddlewareAPI) => (payload: Event) => store.dispatch({ type: WebsocketActionTypes.NOTIFY_WS_ERROR, payload });
        const onNotifyMessage = (store: MiddlewareAPI) => (message: MessageEvent) =>
            store.dispatch({ type: WebsocketActionTypes.NOTIFY_WS_ON_MESSAGE, payload: { message: isValidJSON(message.data) ? JSON.parse(message.data) : message.data } });

        return (next: (action: WebsocketMessage) => void) => (action: WebsocketMessage) => {
            switch (action.type) {
                // NOTE: Websocket base actions
                case WebsocketActionTypes.WS_CONNECT:
                    const host = action.payload.host;
                    const init = !!action.payload.init ? JSON.stringify(action.payload.init) : undefined;
                    // If existing socket - close
                    (!!socket) && socket.close();
                    if (!!host) {
                        // connect to the remote host
                        socket = new WebSocket(host);
                        // websocket handlers
                        socket.onmessage = onMessage(store);
                        socket.onclose = onClose(store);
                        socket.onopen = onOpen(store, { host, init });
                        socket.onerror = onError(store);
                    } else {
                        // Error Handling else send to UI error handling
                        store.dispatch(uiActions.handleRequestErrors(new Message(WebsocketErrors.missing_host_url, MessageHandlerDisplayType.logger)));
                    }
                    break;
                //  NOTE: Ignore WebsocketActionTypes.WS_ON_MESSAGE on here as it will be handled in saga
                case WebsocketActionTypes.WS_SEND_MESSAGE:
                    // NOTE: Check the socket is in readyState == 1.
                    if (!!socket && socket.readyState === 1) {
                        socket.send(action.payload);
                    } else { // Error Handling
                        store.dispatch(uiActions.handleRequestErrors(new Message(WebsocketErrors.websocket_disconnected, MessageHandlerDisplayType.logger)));
                    }
                    break;
                case WebsocketActionTypes.WS_ERROR:
                    store.dispatch(uiActions.handleRequestErrors(new Message(GlobalErrorMessages.default), { stack_message: "WebsocketActionTypes.WS_ERROR", stack_error: action.payload }));
                    // TO DO: HANDLE CONNECTION HERE:
                    socket.close();
                    break;
                case WebsocketActionTypes.WS_DESTROY:
                case WebsocketActionTypes.WS_DISCONNECT:
                    if (!!socket) {
                        socket.close();
                    }
                    break;
                // --------------------------------------------------------
                // Description: Notify App level Websocket actions
                // --------------------------------------------------------
                case WebsocketActionTypes.NOTIFY_WS_CONNECT:
                    const notify_host = `${process.env.REACT_APP_BACKEND_WEBSOCKET_SERVER}/${WebsocketConnectionRoutes.notify_ws}`;
                    // If existing socket - close
                    (!!notify_socket) && notify_socket.close();
                    notify_socket = new WebSocket(notify_host);
                    notify_socket.binaryType = "arraybuffer"; // Note: for dealing with Binary data
                    // Notify websocket handlers:
                    notify_socket.onopen = onNotifyOpen(store);
                    notify_socket.onclose = onNotifyClose(store);
                    notify_socket.onmessage = onNotifyMessage(store);
                    notify_socket.onerror = onNotifyError(store);
                    break;
                case WebsocketActionTypes.NOTIFY_WS_ON_SEND_MESSAGE:
                    // NOTE: Check the socket is in readyState == 1.
                    (!!notify_socket && notify_socket.readyState === 1) ?
                        notify_socket.send(action.payload) :
                        store.dispatch(uiActions.handleRequestErrors(new Message(WebsocketErrors.notify_websocket_disconnected, MessageHandlerDisplayType.logger)));
                    break;
                case WebsocketActionTypes.NOTIFY_WS_ERROR:
                    store.dispatch(uiActions.handleRequestErrors(new Message(GlobalErrorMessages.default), { stack_message: "WebsocketActionTypes.NOTIFY_WS_ERROR", stack_error: action.payload }));
                    notify_socket.close();
                    break;
                case WebsocketActionTypes.NOTIFY_WS_DESTROY:
                case WebsocketActionTypes.NOTIFY_WS_DISCONNECT:
                    !!notify_socket && notify_socket.close();
                    break;
                default:
                    return next(action);
            }
            return next(action);
        };
    };
};
