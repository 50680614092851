import React, { useState, useEffect, useRef } from "react";
import { Card, Button } from "react-bootstrap";
import { ActionHandlerFunction, ActivityLogInfo, IActionHandler, MessageDisplayType, UserProfile } from "@preveil-api";
import {
  GlobalConstants,
  ActivityLog,
  filesyncApi,
  GlobalErrorMessages,
  Message,
  MessageAnchors,
  MessageHandlerDisplayType,
  SettingsErrorMessages,
  SimplePaginationAction,
  SimplePaginationActionType,
  useAppDispatch,
  useAppSelector,
  GetLogsApiResponse,
  dayjs,
  TopicMap,
  UserListActionTypes,
} from "src/common";
import { PageHeader, DateSelectorDropdown, UserList, CoverTemplate } from "src/components";
import { uiActions } from "src/store";
import { LogsListView, LogsToolBar } from ".";

type AdminAction = "All" | "Create Organization" | "Users" | "Approval Group" | "Approval Request" | "Whitelist";
type SystemAction = "All" | "Users" | "Approval Group" | "Approval Request" | "Approval Recovery" | "Device";
type DriveAction = "All" | "Edit" | "Share";
type TopicType = "All" | "Admin" | "System" | "Email" | "Drive" | "Group";
type ActionType = AdminAction | SystemAction | DriveAction | ""

/* Description: Parent component for admin activity logs */
function AdminActivityLogsComponent() {
  const dispatch = useAppDispatch();
  const current_account = useAppSelector((state) => state.account.current_account);
  const [start, setStart] = useState<string>(dayjs().subtract(2, "week").toDate().toISOString()); // The default time frame is set to the last 2 weeks
  const [end, setEnd] = useState<string>(dayjs().toDate().toISOString());
  const [topic, setTopic] = useState<TopicType>("All");
  const [action, setAction] = useState<ActionType>("All");
  const [user_ids, setUserIds] = useState<string[]>([]);
  const [page, setPage] = useState<number>(0);
  const [is_loading, setIsLoading] = useState<boolean>(true);
  const [activity_logs, setActivityLogs] = useState<{ [page: number]: ActivityLogInfo[] }>({
    0: [],
  });
  const [getAdminLogs] = filesyncApi.endpoints.getAdminLogs.useLazyQuery();
  const topic_list: string[] = ["All", "Admin", "System", "Email", "Drive", "Group"];
  const user_list_ref = useRef<{ handlePageActions: ActionHandlerFunction }>();
  const [changes_exist, setChangesExist] = useState<boolean>(false);

  /* Description: on initial load, get the users logs based on the pagination.
  on clean up of the component (unmounting) - reset the pagination */
  useEffect(() => {
    const previous_page = page - 1;
    let last_log: ActivityLogInfo | undefined;
    if (previous_page in activity_logs) {
      last_log = activity_logs[previous_page].slice(-1)[0];
    }
    const to_index = !!last_log ? last_log.global_idx : undefined;
    const offset = page * GlobalConstants.ACTIVITY_PER_PAGE_COUNT;
    getLogs(offset, start, end, user_ids, TopicMap[topic].name, actionsFilter(), to_index);
  }, []);

  /* Description: retrieves the action list based on the topic chosen. If the topic has no actions, an empty array is returned */
  function actionsFilter(params?: ActionType): string[] {
    const act = !!params ? params : action;
    if (TopicMap[topic].action_list.length > 0) {
      if (topic === "Admin") {
        return TopicMap[topic].action_map[act as AdminAction];
      } else if (topic === "System") {
        return TopicMap[topic].action_map[act as SystemAction];
      } else if (topic === "Drive") {
        return TopicMap[topic].action_map[act as DriveAction];
      }
    }
    return [];
  }

  /* Description: Gets the admin logs from the backend depending on the time frame, and offset, page number, and to_index
  we then map the response entries and create an ActivityLog object for each entry. */
  function getLogs(
    offset: number,
    start_time: string,
    end_time: string,
    user_ids: string[],
    topic: string,
    actions: string[],
    to_index?: number,
    user?: string,
    page_index?: number,
  ) {
    !!current_account &&
      getAdminLogs({
        offset,
        start_time,
        end_time,
        user_ids,
        topic,
        actions,
        to_index,
        user,
        user_id: current_account.user_id,
      })
        .unwrap()
        .then((response: GetLogsApiResponse) => {
          const logs: ActivityLogInfo[] = response.log_entries
          // filter out selective sync logs with no nodes
          .filter(log => !(log.action === "enable_selective_sync" && log.metadata.nodes.length === 0))
          .map((log) => {
            const user = { name: log.user_info.display_name, address: log.user_info.user_id };
            const device =
              !!log.user_info.devices && log.user_info.devices.length === 1
                ? log.user_info.devices[0].device_name
                : "";
            const al = new ActivityLog(
              user.address,
              log.entry_ts,
              log.collection_id,
              log.topic,
              log.action,
              device,
              user,
              log.metadata,
              log.global_idx,
              current_account.user_id
            );
            return al.getActivityLogInfo();
          });
          const p = !!page_index || page_index === 0 ? page_index : page;
          activity_logs[p] = logs;
          setPage(p);
          setActivityLogs(activity_logs);
          setIsLoading(false);
          setChangesExist(false);
        })
        .catch((stack) => {
          setIsLoading(false);
          dispatch(
            uiActions.handleRequestErrors(
              new Message(SettingsErrorMessages.error_fetching_activity),
              stack,
            ),
          );
        });
  }

  /* Description: Handle all children component actions and store it */
  const AdminActivityLogCalls = {
    /* Description: refresh the page by calling get logs */
    handleRefresh: () => {
      setIsLoading(true);
      const offset = page * GlobalConstants.ACTIVITY_PER_PAGE_COUNT;
      const _end = dayjs(end).isSame(dayjs(), "date") ? dayjs().toDate().toISOString() : end;
      getLogs(offset, start, _end, user_ids, TopicMap[topic].name, actionsFilter());
    },
    /* Description: handled when the user goes to the next page or previous page - calls get log based on the new page */
    handleSimplePaging: (action: SimplePaginationActionType) => {
      if (!!action) {
        const new_page = action === SimplePaginationAction.next ? page + 1 : page - 1;
        if (!!activity_logs[new_page]) {
          setPage(new_page);
        } else {
          setIsLoading(true);
          const offset = new_page * GlobalConstants.ACTIVITY_PER_PAGE_COUNT;
          let last_log: ActivityLogInfo | undefined;
          if (page in activity_logs) {
            last_log = activity_logs[page].slice(-1)[0];
          }
          const to_index = !!last_log ? last_log.global_idx : undefined;
          getLogs(
            offset,
            start,
            end,
            user_ids,
            TopicMap[topic].name,
            actionsFilter(),
            to_index,
            undefined,
            new_page,
          );
        }
      }
    },
    /* Description: When a new date or time frame is selected, get logs is called again */
    handleDateSelector: (params: { start: Date; end: Date }) => {
      setIsLoading(true);
      setStart(params.start.toISOString());
      setEnd(params.end.toISOString());
      const offset = (page - 1) * GlobalConstants.ACTIVITY_PER_PAGE_COUNT;
      getLogs(
        offset,
        params.start.toISOString(),
        params.end.toISOString(),
        user_ids,
        TopicMap[topic].name,
        actionsFilter(),
      );
    },
    /* Description: Handler for setting the topic filter, the action is also set back to "All" and pagination is reset */
    handleSetTopic: (params: TopicType) => {
      setIsLoading(true);
      setTopic(params);
      setAction("All");
      setPage(0);
      getLogs(
        0,
        start,
        end,
        user_ids,
        TopicMap[params].name,
        actionsFilter("All"),
        undefined,
        undefined,
        0,
      );
    },
    /* Description: Handler for setting the filtered user */
    handleSetFilterByUser: (params: UserProfile) => {
      setIsLoading(true);
      setPage(0);
      setUserIds([params.address]);
      if (!!user_list_ref.current) {
        user_list_ref.current.handlePageActions({
          actionType: UserListActionTypes.Reset,
        });
        user_list_ref.current.handlePageActions({
          actionType: UserListActionTypes.SetUserProfile,
          params
        });
      }
      getLogs(
        0,
        start,
        end,
        [params.address],
        TopicMap[topic].name,
        actionsFilter(),
        undefined,
        undefined,
        0,
      );
    },
    /* Description: Handler for setting the action filter, pagination is reset */
    handleSetAction: (params: ActionType) => {
      setIsLoading(true);
      setAction(params);
      setPage(0);
      getLogs(
        0,
        start,
        end,
        user_ids,
        TopicMap[topic].name,
        actionsFilter(params),
        undefined,
        undefined,
        0,
      );
    },
    handlePageErrorMessage: (params: {
      message: string;
      stack?: any;
      display_type?: MessageDisplayType;
    }) => {
      const display_type = !!params.display_type
        ? params.display_type
        : MessageHandlerDisplayType.logger;
      dispatch(
        uiActions.handleRequestErrors(new Message(params.message, display_type), params.stack),
      );
    },
  };

  function handlePageActions(actionObj: IActionHandler) {
    dispatch(uiActions.handleMessageDismiss());
    const callback = `handle${actionObj.actionType}`;
    // Handle local calls:
    if ((AdminActivityLogCalls as any)[callback] instanceof Function) {
      (AdminActivityLogCalls as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(
        MessageAnchors.actionType,
        actionObj.actionType,
      );
      AdminActivityLogCalls.handlePageErrorMessage({ message, stack: actionObj });
    }
  }

  /* Description: handler for updating the user list, the user ids are added and pagination is reset, logs are refetched */
  function handleUpdateUsers(actionObj: IActionHandler) {
    const current_contacts = actionObj.params.current_contacts as UserProfile[];
    const user_ids = current_contacts.map((c) => c.address);
    setUserIds(user_ids);
    setChangesExist(true);
  }

  function filterByUsers() {
    setIsLoading(true);
    setPage(0);
    getLogs(0, start, end, user_ids, TopicMap[topic].name, actionsFilter());
  }

  return (
    <CoverTemplate>
      <PageHeader>
        <h1>Activity Logs</h1>
      </PageHeader>
      <Card className="activity-logs card-section settings-height">
        <b>Timeframe:</b>
        <DateSelectorDropdown handleAction={handlePageActions}></DateSelectorDropdown>
        <b>Users:</b>
        {!!current_account && (
          <div className="users">
            <UserList ref={user_list_ref} current_account={current_account} handleAction={handleUpdateUsers} />
            <Button className="ms-2" size="sm" variant="outline-primary" disabled={!changes_exist && !is_loading} onClick={filterByUsers}>Submit</Button>
          </div>
        )}
        <LogsToolBar
          handleAction={handlePageActions}
          activity_logs={activity_logs[page]}
          page={page}
          is_loading={is_loading}
          topic={topic}
          topic_list={topic_list}
          action_list={TopicMap[topic].action_list}
          action={action} />
        {!!current_account && (
          <LogsListView loading={is_loading} activity_logs={activity_logs[page]} handleAction={handlePageActions}></LogsListView>
        )}
      </Card>
    </CoverTemplate>
  );
}

export default React.memo(AdminActivityLogsComponent);
