import React, { useEffect } from "react";
import { Routes, Route, Navigate, Params } from "react-router-dom";
import { NavigationPath, useAppDispatch, useAppSelector, DRIVE_MANAGER_STATE } from "src/common";
import { Wrapper, DriveWrapper } from "src/components";
import { uiActions, driveActions } from "src/store";

function DrivePage() {
  const dispatch = useAppDispatch();
  const drive_state = useAppSelector((state) => state.drive.drive_state);
  useEffect(() => {
    dispatch(uiActions.setUploadProgressHidden(false));
    dispatch(uiActions.setDownloadProgressHidden(false));
    // NOTE: Activate the fetch coll info and default permissions if this is a new app user
    // We need a lag to allow for full account and default collection creation in CS from Crypto
    drive_state === DRIVE_MANAGER_STATE.NEW_APP_ACCOUNT &&
      dispatch(driveActions.setDriveState(DRIVE_MANAGER_STATE.NOT_INITIALIZED));
    return () => {
      dispatch(uiActions.setUploadProgressHidden(true));
      dispatch(uiActions.setDownloadProgressHidden(true));
    };
  }, []);

  return (
    <Wrapper type={NavigationPath.DriveNavigation}>
      <DrivePagesRoutes />
    </Wrapper>
  );
}

// NOTES: - First param will always refer to the collection_id of the item requested (most of the time root_collection_id)
//        - Second parameter is the id of the item requested (dir or collection)
//        - Third is the type of Item: LINK (l), FILE (f), DIR (d) - see DriveConstants
export function DrivePagesRoutes(props: Readonly<Params<string>>) {
  return (
    <Routes>
      <Route path=":collection_id/:id/:file_id/:item_type" element={<DriveWrapper {...props} />} />
      <Route path=":collection_id/:id/:item_type" element={<DriveWrapper {...props} />} />
      <Route path=":collection_id/:id" element={<DriveWrapper {...props} />} />
      {/* This route is for additional pages: /root /invitations, /trash,  or /shared - TBD */}
      <Route path=":collection_id" element={<DriveWrapper {...props} />} />
      <Route path="/" element={<Navigate to="root" replace />} />
    </Routes>
  );
}

export default React.memo(DrivePage);
