import * as React from "react";
import { Nav, Button } from "react-bootstrap";
import { useAppSelector, useAppDispatch, ComposeTypes, ComposeMail } from "src/common";
import { mailActions } from "src/store";
import { Icon, MailboxesTree, DefaultMailboxes } from "src/components";

type AllProps = {
  active_path?: string;
}

function MailNavigationComponent(props: AllProps) {
  const { active_path } = props;
  const custom_mailboxes = useAppSelector((state) => state.mail.custom_mailboxes);
  const dispatch = useAppDispatch();

  return <>
    <Button className="left-nav-btn" title="Compose Message"
      data-tooltip-id="pv-tooltip" data-tooltip-content="Compose Message"
      data-bs-placement="right"
      onClick={() => (dispatch(mailActions.addComposeMessage(new ComposeMail(ComposeTypes.new))))}>
      <Icon className="pv-icon-compose" />
      <span>Compose</span>
    </Button>
    <Nav>
      <DefaultMailboxes active_path={active_path} />
      <hr />
      <MailboxesTree custom_mailboxes={custom_mailboxes} />
    </Nav>
  </>;
}

export default React.memo(MailNavigationComponent);
