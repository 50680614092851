import React from "react";
import { ProgressBar } from "react-bootstrap";

function ProgressBarComponent() {

  // Description: Progress Bar
  const Bar = () => {
    return (
      <>
        <h4>Progress Bar</h4>
        <ProgressBar now={60} label={`${60}%`} visuallyHidden />
        <hr />
        <ProgressBar now={0} label={`${0}%`} visuallyHidden />
        <hr />
        <ProgressBar now={100} label={`${100}%`} visuallyHidden />
        <hr />
      </>
    );
  };

  return <Bar />;
};

export default React.memo(ProgressBarComponent);