import * as React from "react";
import { DriveMessages } from "src/common";
import { Icon } from "src/components";

function EmptyFolderComponent(props: { message?: string }) {
  return <div className="list-messages">
    <div className="center-vertical">
      <Icon className="pv-icon-mailbox-empty" />
      {
        !!props.message ?
          <p dangerouslySetInnerHTML={{ __html: (props.message) }} /> :
          <p>{DriveMessages.empty_folder}</p>
      }
    </div>
  </div>;
}

export default React.memo(EmptyFolderComponent);
