import * as React from "react";
import { Toast } from "react-bootstrap";
import { ChangeOrgApprovalGroupRequest } from "@preveil-api";
import { AdminApprovalGroupRoleMapping, UserRequest } from "src/common";

type AllProps = {
  request: UserRequest;
};

function ChangeApproverRoleComponent(props: AllProps) {
  const { request } = props;

  /* Description: For a specific role, get the AdminApprovalGroupRoleMapping. */
  function getApprovalGroupRoleMapping(role: string): {
    name: string;
    all_admin: boolean;
    details: string;
  } {
    return role === "admin_approval_group"
      ? AdminApprovalGroupRoleMapping.admin_approval_group
      : AdminApprovalGroupRoleMapping.export_approval_group;
  }
  
  return (
    <>
      <h3>
        Updated Activity:{" "}
        {
          getApprovalGroupRoleMapping(
            (request.parsed_payload.data as ChangeOrgApprovalGroupRequest).group_role,
          ).name
        }
      </h3>
      <Toast bg="warning" show={true} className="d-inline-block mt-0 mb-3">
        <Toast.Body
          dangerouslySetInnerHTML={{
            __html: getApprovalGroupRoleMapping(
              (request.parsed_payload.data as ChangeOrgApprovalGroupRequest).group_role,
            ).details,
          }}
        ></Toast.Body>
      </Toast>
    </>
  );
}

export default React.memo(ChangeApproverRoleComponent);
