import { useEffect, useState } from "react";
import { MessageDisplayType, ErrorStackDataType } from "@preveil-api";
import { FSMessage, FSStatus } from "src/common/keys/protos/collections_pb";
import {
  useAppDispatch, Message, MessageHandlerDisplayType, DriveErrorMessages, useAppSelector, DriveRequest, filesyncSocketApi, NotificationACKLimit,
  FilesyncNotifications, DriveNotification, DriveNotificationMap, ErrorStackItem, getEnumKey
} from "src/common";
import { RootState } from "src/store/configureStore";
import { uiActions, driveActions, websocketActions, MessageRequest, WebsocketStatus } from "src/store";
import _ from "lodash";

export function useDriveSocket() {
  const callbacks = useAppSelector((state: RootState) => state.websocket.drive_ws_callbacks);
  const ws_status = useAppSelector((state: RootState) => state.websocket.drive_ws_status);
  const notifications_ws_status = useAppSelector((state: RootState) => state.websocket.drive_notification_ws_status);
  const notifications = useAppSelector((state: RootState) => state.drive.notifications);
  const [ack_counts, setACKCounts] = useState<string[]>([]);
  const [initSocket, { data, error }] = filesyncSocketApi.useLazyInitializeDriveHooksQuery();
  const [initNotificationSocket, { data: notification_data, error: notification_error }] = filesyncSocketApi.useLazyInitializeNotificationHooksQuery();
  const dispatch = useAppDispatch();

  useEffect(() => {
    // NOTE: Initialize the main drive socket connection
    // Pass a timestamp to rebind the  onCacheEntryAdded otherwise it is cached
    (ws_status === WebsocketStatus.not_initialized || ws_status === WebsocketStatus.disconnected) &&
      initSocket(new Date().getTime().toString());
  }, [ws_status]);

  useEffect(() => {
    // NOTE: Initialize the notifications socket connection
    (notifications_ws_status === WebsocketStatus.not_initialized || notifications_ws_status === WebsocketStatus.disconnected) &&
      initNotificationSocket(new Date().getTime().toString());
  }, [notifications_ws_status]);

  useEffect(() => {
    // NOTE: Handle the data object  FSMessage.Type.RESPONSE only the NOTIFICATION type will go to drive_ws_notifications
    if (!!data && data.length > 0) {
      _.forEach(data, (request: MessageRequest) => {
        (!!request.id && request.type === FSMessage.Type.RESPONSE) ? matchRequestResponse(request) : handleNotifications(request);
      });
    }

    !!error &&
      handlePageErrorMessage(DriveErrorMessages.default, { stack_message: "error through drive websocket", stack_error: error }, null, MessageHandlerDisplayType.toastr);
  }, [data, error]);

  useEffect(() => {
    // NOTE: Handle the data object FSMessage.Type.NOTIFICATION and FSMessage.Type.RESPONSE
    if (!!notification_data) {
      notification_data.type === FSMessage.Type.NOTIFICATION ? handleNotifications(notification_data) :
        handleNotificationResponses(notification_data);
    }

    !!notification_error &&
      handlePageErrorMessage(DriveErrorMessages.default, { stack_message: "error through drive notification websocket", stack_error: notification_error }, null, MessageHandlerDisplayType.toastr);
  }, [notification_data, notification_error]);

  // Description: Matches the request message to a response callback function
  function matchRequestResponse(data: MessageRequest) {
    const request_id = data.id;
    const message = data.message;
    const message_callback = _.find(callbacks, (request: DriveRequest) => request.request_id.B64() === request_id);
    if (!!request_id && !!message && !!message_callback && message_callback.callback instanceof Function) {
      message_callback.callback(message);
      removeCallback(request_id);
    } else {
      const stack = {
        stack_message: DriveErrorMessages.no_request_match_found,
        stack_error: { request_id, message, message_callback }
      };
      handlePageErrorMessage(DriveErrorMessages.default, stack);
    }
  }

  // Description: Handle Notification Pings - place them in store
  // Note: Notifications are coming from both websocket into this: TOPICS: Permissions and Collections
  function handleNotifications(data: MessageRequest) {
    const notification = data.message.getNotification();
    if (!!notification) {
      const info = new FilesyncNotifications(notification).info;
      // NOTE: Check if collection_id is not empty, some notifications return empty collection_id (i.e. 12 on accept invite)
      if (!!info && !!info.collection_id) {
        const new_notifications = Object.assign({}, notifications);
        const key = String(info.event);
        const obj: DriveNotificationMap = {};
        obj[key] = info; // NOTE: New sub-DriveNotification by event type
        new_notifications[info.collection_id] = (_.has(new_notifications, info.collection_id)) ?
          Object.assign({}, new_notifications[info.collection_id], obj) :
          new_notifications[info.collection_id] = obj;
        dispatch(driveActions.setNotification(new_notifications));
        handlACKCounts(info);
      }
    }
  }

  // Description: Keep Notification counts by topic and send ACK right before reaching limit 10 (8)
  // Only do this for notification WS as key topics will throw errors
  function handlACKCounts(info: DriveNotification) {
    const topic = info.topic;
    if (!!topic) {
      let ack_topics: string[] = ack_counts.slice();
      const _ack_counts = _.filter(ack_counts, (_topic: string) => _topic === topic).length;
      if (_ack_counts >= NotificationACKLimit) {
        // NOTE: Dispactch ACK for this topic and remove all instances for this topic from array; else add 1 more
        dispatch(driveActions.setAck({ sequence: info.sequence, topic }));
        ack_topics = _.filter(ack_counts, (_topic: string) => _topic !== topic);
      } else {
        ack_topics.push(topic);
      }
      setACKCounts(ack_topics);
    }
  }

  // Description: Handle Errors from SUBSCRIBE_ALL or ACK
  function handleNotificationResponses(data: MessageRequest) {
    const message = data.message;
    (message.getStatus() !== FSStatus.OK) && handlePageErrorMessage(DriveErrorMessages.default, {
      stack_message: "error in handleNotificationResponses"
    }, message);
  }

  // Description: Remove callback from store after consuming it
  function removeCallback(request_id: string) {
    const new_callback = callbacks.slice();
    _.remove(new_callback, (request: DriveRequest) => request_id === request.request_id.B64());
    dispatch(websocketActions.setWsDriveCallbacks(new_callback));
    invalidateCache();
  }

  // Description: remove cache after managing the MessageRequest and matching them to Callbacks 
  function invalidateCache() {
    dispatch(filesyncSocketApi.util.invalidateTags(["collection_ws"]));
  }

  // Description: Handle error message and send error to store
  function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, fsmessage?: FSMessage | null,
    display_type: MessageDisplayType = MessageHandlerDisplayType.logger) {
    const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
    const stack = new ErrorStackItem("[useDriveSocket Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
    dispatch(uiActions.handleRequestErrors(new Message(message, display_type), stack));
  }

  return {
    data,
    error
  };
}