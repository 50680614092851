import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { MessageItem } from "@preveil-api";

interface AllProps {
    message_item: MessageItem;
    size?: "sm" | "lg" | "xl";
    onModalDismiss?: () => void;
}

function UIMessageModal(props: AllProps) {
    const { message_item, onModalDismiss, size } = props;
    const [show, setShow] = useState(true);

    const closeModal = () => {
        setShow(false);
        !!onModalDismiss && onModalDismiss();
    };

    const confirm = () => {
        try {
            const action = message_item?.confirm?.action as (...arg: any) => void;
            (typeof action === "function") ?
                action(message_item?.confirm?.data) : console.error("THIS ACTION DOES NOT EXIST");
        } catch (error) {
            console.error(error);
        }
        !message_item.confirm?.keepOpenedState && closeModal();
    };

    const dismiss = () => {
        if (!!message_item?.dismiss?.action) {
            try {
                const action = message_item?.dismiss?.action as (...arg: any) => void;
                (typeof action === "function") ?
                    action(message_item?.dismiss?.data) : console.error("THIS ACTION DOES NOT EXIST");
            } catch (error) {
                console.error(error);
            }
        }
        closeModal();
    };

    return (
        <div>
            <Modal show={show} onHide={closeModal} size={size}
                aria-labelledby="preveil message"
                centered>
                {
                    !!message_item.title ? <Modal.Header closeButton>
                        <Modal.Title>{message_item.title}</Modal.Title>
                    </Modal.Header> :
                        <Button variant="link" type="button" className="btn-close no-title" aria-label="Close" onClick={closeModal}></Button>
                }
                <Modal.Body>
                    <div dangerouslySetInnerHTML={{ __html: (message_item.message) }} />
                </Modal.Body>
                {
                    (!!message_item.confirm || !!message_item.dismiss) &&
                    <Modal.Footer>
                        {
                            !!message_item.confirm &&
                            <Button variant="primary" onClick={confirm} >
                                {(!!message_item.confirm && !!message_item.confirm.label) ? message_item.confirm.label : "Yes"}
                            </Button>
                        }
                        <Button variant="no-outline-primary" onClick={dismiss}>
                            {(!!message_item.dismiss && !!message_item.dismiss.label) ? message_item.dismiss.label : "Cancel"}
                        </Button>
                    </Modal.Footer>
                }
            </Modal>
        </div>
    );

}

export default React.memo(UIMessageModal);
