import Dexie from "dexie";
import { FSMessage, FSType } from "src/common/keys/protos/collections_pb";
import { SymmKey } from "src/common/keys";
import {
    Account, PermissionSetType, getEnumKey, DriveEntryTypes, decryptItemName, getDirSymmKey, Grant, dayjs, DriveEntryType, DriveFileType,
    GrantSetType, Directory, SearchNodeBase, SearchNode, SearchBase,
    NodeIdentifier
} from "src/common";
import { SearchDatabase } from "./db";
import { FilenameTokenizer } from "./search-tokenizer.class";
import _ from "lodash";

export class SearchClient {
    databases: Map<string, SearchDatabase> = new Map<string, SearchDatabase>();
    db!: SearchDatabase;
    readonly tokenizer = new FilenameTokenizer();
    abort_token = false;
    node_identifier!: NodeIdentifier;
    constructor(user_id: string) {
        this.databases = new Map<string, SearchDatabase>();
        this.setCurrentUser(user_id);
    }
    // GLOBAL DB Fns
    // -------------------------------------------------------------------------------------------------------------------
    // Description: Retrieve current db from the map OR create a new DB with current_account.user_id
    public setCurrentUser(user_id: string): void {
        let db = this.databases.has(user_id) ? this.databases.get(user_id) : undefined;
        if (!db) {
            db = new SearchDatabase(user_id);
            this.databases.set(user_id, db);
        }
        this.db = db;
    }

    // Description: set current node identifier for current directory scoped search and set abort to false
    public initCurrentNode(node_identifier: NodeIdentifier): void {
        this.abort_token = false;
        this.node_identifier = node_identifier;
    }

    // Description: Initialize the SearchNodeBase from Dir Entries
    public async initDBNodes(
        collection_id: string,
        dir: FSMessage.Dir,
        current_account: Account,
        permissions: PermissionSetType,
        grant?: Grant
    ): Promise<SearchNodeBase[] | undefined> {
        const id = dir.getId_asB64();
        const entry_list = dir.getEntriesList();
        const dir_symm_key = dir.getWrappedDirKey();
        const reader_user_key = !!grant ? await grant.key(current_account) : undefined;
        const symmetric_key = !!dir_symm_key ? await getDirSymmKey(dir_symm_key, current_account, permissions, reader_user_key) : undefined;
        if (!!symmetric_key && !this.abort_token) {
            return await this.addDecryptedEntries(collection_id, id, entry_list, symmetric_key);
        }
    }

    // Description: Add Decrypted Entries to IndexedDB
    public async addDecryptedEntries(collection_id: string, parent_id: string, entry_list: FSMessage.Dir.Entry[], directory_symmetric_key: SymmKey) {
        const nodes = _.compact(await Promise.all(_.map(entry_list, async (entry: FSMessage.Dir.Entry) => {
            // NOTE: Do not call if the search was destroyed
            if (!this.abort_token) {
                const _date = entry.getLastUpdate();
                const latest_date = entry.hasLastLocalFsUpdate() && dayjs(entry.getLastLocalFsUpdate()).isValid() ? dayjs.utc(entry.getLastLocalFsUpdate()).local() :
                    !!_date ? dayjs.unix(_date) : undefined;
                const decoded_name = await decryptItemName(entry.getName_asU8(), directory_symmetric_key) || "";
                const type = getEnumKey(FSType, entry.getType()) as DriveEntryTypes || null;
                const search_node: SearchNodeBase = {
                    id: entry.getId_asB64(), // NOTE: THIS IS THE DIRECTORY ID for root_node entry.getId_asB64() OR Helpers.b64Encode(entry.getId_asU8(), true)
                    collection_id,
                    name: decoded_name,
                    type,
                    last_modification_date: !!latest_date ? latest_date.format("MM/DD/YYYY h:mm A") : "",
                    parent_id,
                    name_tokens: this.tokenizer.getTokens(decoded_name)
                };
                // await this.addItemToIndex(new SearchNode(search_node));
                return new SearchNode(search_node);
            }
        })));
        await this.addBulkItemsToIndex(nodes);
        return nodes;
    }

    // Description: Add Disjointed ACL Nodes to DB
    public async handleDisjointedACLNodes(collection_id: string, dir: FSMessage.Dir, current_account: Account, grant_set?: GrantSetType):
        Promise<SearchNodeBase | undefined> {
        const decoded_name = !!grant_set ? await Directory.getDirectoryMaintainersName(dir, current_account, grant_set) : DriveFileType.Shared_Folder;
        const _date = dir.getLastUpdate();
        const latest_date = dir.hasLastLocalFsUpdate() && dayjs(dir.getLastLocalFsUpdate()).isValid() ? dayjs.utc(dir.getLastLocalFsUpdate()).local() :
            !!_date ? dayjs.unix(_date) : undefined;
        const search_node = !!decoded_name ? {
            id: dir.getId_asB64(), // NOTE: THIS IS THE DIRECTORY ID for root_node entry.getId_asB64() OR Helpers.b64Encode(entry.getId_asU8(), true)
            collection_id,
            name: decoded_name,
            type: DriveEntryType.DIR,
            last_modification_date: !!latest_date ? latest_date.format("MM/DD/YYYY h:mm A") : "",
            parent_id: dir.getParentId_asB64(),
            name_tokens: this.tokenizer.getTokens(decoded_name)
        } : undefined;
        if (!!search_node) {
            await this.addItemToIndex(new SearchNode(search_node));
            return search_node;
        }
    }

    // Description: Delete all data to start over
    public async destroySearch(abort: boolean = true): Promise<void> {
        this.abort_token = abort;
        await this.db.pagination.clear();
        await this.db.search.clear();
        await this.db.nodes.clear();
    }

    // -------------------------------------------------------------------------------------------------------------------
    // Node tables
    // -------------------------------------------------------------------------------------------------------------------
    // Description: Add single node to DB
    public async addItemToIndex(node: SearchNode): Promise<void> {
        await this.db.nodes.put(node).then(() => {
            return Promise.resolve();
        }).catch(Dexie.BulkError, (e) => { console.error("addItemToIndex: ", e.failures); });
    }

    // Description: Bulk Add nodes to the table
    public async addBulkItemsToIndex(nodes: SearchNode[]) {
        await this.db.nodes.bulkAdd(nodes)
            .then(() => {
                return Promise.resolve();
            })
            .catch(Dexie.BulkError, (e) => { console.error("Some inserts failed: ", e.failures); });
    }

    // Description: Delete items by id 
    public async deleteItemsByCollectionId(collection_id: string): Promise<number> {
        const result = await this.db.nodes.where("collection_id").equals(collection_id).delete();
        return result;
    }

    // Description: Return the total number of node entries in DB
    public async getSearchNodeTotalRowCounts(): Promise<number> {
        const counts = await this.db.nodes.count();
        return counts;
    }

    // Description: Delete all data to start over
    public async destroyNodeTable(): Promise<void> {
        await this.db.nodes.clear().then(() => {
            return true;
        }).catch(Dexie.BulkError, (e) => { console.error("ERROR Clearing the nodes table: ", e.failures); });
    }

    // -------------------------------------------------------------------------------------------------------------------
    // Search tracking tables
    // -------------------------------------------------------------------------------------------------------------------
    // Description: Save search sequence and params
    //              IF snapshot is undefined then initialize links to Index for later fetch seq = 0
    public async addItemToSearchIndex(collection_id: string, id: string, snapshot?: FSMessage.Snapshot): Promise<void> {
        const search: SearchBase = !!snapshot ? {
            id,
            collection_id,
            sequence: snapshot.getSeq() || 0,
            cursor_sequence: snapshot.getCursorSeq() || 0,
            cursor_sub_sequence: snapshot.getCursorSubseq() || 0,
            has_more: snapshot.hasHasMore() ? 1 : 0
        } : { // NOTE: initialize links 
            id,
            collection_id,
            sequence: 0,
            cursor_sequence: 0,
            cursor_sub_sequence: 0,
            has_more: 1
        };
        await this.db.search.put(search).then(() => Promise.resolve());
    }

    // Description: Initialize a search row
    public async initItemInSearchIndex(collection_id: string, id: string): Promise<void> {
        const search: SearchBase = {
            id,
            collection_id,
            sequence: 0,
            cursor_sequence: 0,
            cursor_sub_sequence: 0,
            has_more: 1
        };

        await this.db.search.put(search).then(() => Promise.resolve());
    }

    // Description: Return the current version of a search row
    public async getSearchByIdentifiers(collection_id: string): Promise<SearchBase | undefined> {
        const result = await this.db.search.get([collection_id]);
        return result;
    }


    // Description: Get Search rows where has_more is true
    public async getSearchHasMoreRows(): Promise<SearchBase[]> {
        const result = await this.db.search
            .where("has_more")
            .equals(1)
            .toArray();
        return result;
    }

    // Description: Delete items by id 
    public async deleteSearchByCollectionId(collection_id: string): Promise<number> {
        const result = await this.db.search.where("collection_id").equals(collection_id).delete();
        return result;
    }

    // -------------------------------------------------------------------------------------------------------------------
    // Pagination table - store pagination for long term storage
    // -------------------------------------------------------------------------------------------------------------------
    // Description: Add a page by collection to pagination table
    public async addPage(page: number = 0): Promise<void> {
        await this.db.pagination.put({ ...this.node_identifier, ...{ page } }).then(() => Promise.resolve());
    }

    // Description: increment page number by 1
    public async incrementPage(): Promise<void> {
        const page = await this.getPage();
        await this.addPage(Number(page + 1));
    }

    // Description: get page for a current collection
    public async getPage(): Promise<number> {
        const result = await this.db.pagination.get([this.node_identifier.collection_id]);
        return result?.page || 0;
    }

    public async getPageIdentifiers(collection_id: string): Promise<NodeIdentifier | undefined> {
        const result = await this.db.pagination.get([collection_id]);
        return !!result ? { collection_id: result.collection_id, id: result.id } : undefined;
    }
}
