
import {
  ThreadMessage, UserProfile, ThreadMessageBase, GroupEmailBase, ThreadMessageRecipients, MessageContents, ContactsData, UserInvite, WebMailRecipients, UserIdentifierBase, MessageSendMetadata, ThreadAttachment, MailMessageV4
} from "@preveil-api";
import { SymmKey } from "src/common/keys";
import {
  ComposeTypesType, UUID, ComposeTypes, MailThreadMessage, Account, isSameUser, parseProfileToUserBase, parseProfileToEmailUser,
  UserListStatus, KeyFactory, encryptAndPrepareEmail, snippetFromBody, EmailUser, WebMailData, getRecipientMailRecipient, CURRENT_EMAIL_PROTOCOL_VERSIONS,
  EMAIL_PROTOCOL_VERSIONS, Contacts, Attachment, MailRecipient, stripHtml, RegexHelper, AttachmentContentDisposition
} from "src/common";
import { decode } from "html-entities";
import _ from "lodash";


// Compose Mail
export interface ComposeMailWebData {
  tos: EmailUser[];
  ccs: EmailUser[];
  bccs: EmailUser[];
  recipients: WebMailRecipients;
  unclaimed: UserInvite[];
  subject: string;
  text: string;
  html: string;
  snippet: string;
  draft_id: string | null;
  in_reply_to: string;
  references: string[];
  attachments: [] | Attachment[];
}

export interface ComposeMailMessage {
  uid: string; // This is a random clientside generated UUID
  content_type: ComposeTypesType;
  message?: ThreadMessage;
  default_to?: string;
  default_subject?: string;
}

export class ComposeMail implements ComposeMailMessage {
  uid: string = "";
  compose_body: string = "";
  _default_to?: string;
  _default_subject?: string;
  constructor(
    public content_type: ComposeTypesType,
    public message?: ThreadMessage,
    public signature?: string,
    set_message_body?: boolean
  ) {
    this.uid = !!message ? message.unique_id : new UUID().String();
    this.signature = this._getSignature(signature);
    this.compose_body = set_message_body ? this.setComposeBody() : "";
  }

  // Description: Get the flat object for storage
  get compose_mail(): ComposeMailMessage {
    return {
      uid: this.uid,
      content_type: this.content_type,
      message: this.message,
      default_to: this._default_to,
      default_subject: this._default_subject
    };
  }

  // Description: Gets the Title prefix for Reply, Reply All and Forward messages
  get subject_prefix(): string {
    return this.content_type === ComposeTypes.reply || this.content_type === ComposeTypes.reply_all ? "Re: " :
      this.content_type === ComposeTypes.forward ? "Fw: " : "";
  }

  // Description: Gets the Title from subject
  get subject(): string {
    return !!this.message ? `${this.subject_prefix}${this.message.subject}` : "";
  }

  // // Description: compose is a reply or reply all
  get is_reply(): boolean {
    return this.content_type === ComposeTypes.reply || this.content_type === ComposeTypes.reply_all;
  }

  get is_draft(): boolean {
    return this.content_type === ComposeTypes.draft;
  }

  get is_new(): boolean {
    return this.content_type === ComposeTypes.new;
  }

  // Description: Gets the Compose mail body depending on content_type
  private setComposeBody(): string {
    const signature = this.signature || "";
    let _message_body = `<br/>${signature}`; // holds new string
    if (!!this.message) {
      _message_body = this.content_type === ComposeTypes.draft ?
        `${this.message.message_body || this.message.snippet}<br/>${signature}` :
        this.content_type !== ComposeTypes.new ?
          `${signature}<br/>${MailThreadMessage.buildPreviousMailBody(this.message, false)}` : `<br/>${signature}`;
    }
    return _message_body;
  };

  // Description: returns whether in web compose needs to return all data (cases: draft and forwards)
  get required_web_data(): boolean {
    return this.content_type === ComposeTypes.draft || this.content_type === ComposeTypes.forward;
  }

  // Description: wrap a div around the signature to add templated styles
  private _getSignature(signature?: string): string {
    return !!signature ? `<br/><br/><hr class="signature-divider"/><div class="signature-block">${signature}<div>` : "";
  }

  // Description: Set default values for new messages
  setDefaults(to: string, subject?: string) {
    this._default_to = to;
    this._default_subject = subject;
  }

  // Description: Get tos from ccs, bccs
  getRecipients(sender: Account): ThreadMessageRecipients | undefined {
    // Note: will get user information from the server with useContacts hook @ userlist level
    let recipients;
    if (!!this.message) {
      // Note: this is a Reply
      recipients = (this.content_type === ComposeTypes.reply) ? { tos: [this.message.sender], ccs: [] } : undefined;
      // Note: this is a ReplyAll, drafts, and Reply (when current user is the original sender)
      if (this.content_type === ComposeTypes.reply_all || this.content_type === ComposeTypes.draft) {
        let tos = this.content_type === ComposeTypes.reply_all ? [this.message.sender] : [];
        if (!!this.message.tos && this.message.tos.length > 0) {
          const message_tos = this.content_type === ComposeTypes.reply_all ?
            this.message.tos.filter(to => !isSameUser(to.address, sender.profile.address))
            : this.message.tos;
          tos = tos.concat(message_tos);
        };
        (!!this.message.tos_groups && this.message.tos_groups.length > 0) &&
          this.message.tos_groups.map((group: GroupEmailBase) => {
            return tos.push({ address: group.alias, name: group.alias });
          });

        const ccs = !!this.message.ccs ? this.message.ccs : [];
        (!!this.message.ccs_groups && this.message.ccs_groups.length > 0) &&
          this.message.ccs_groups.map((group: GroupEmailBase) => {
            return ccs.push({ address: group.alias, name: group.alias });
          });

        // NOTE: Only add bccs if its a draft
        const bccs = (this.content_type === ComposeTypes.draft && this.message.bccs.length > 0) ? this.message.bccs : undefined;
        recipients = { tos, ccs, bccs };
      }
    } else if (!!this._default_to) {
      // NOTE: Send Default values from external links
      recipients = { tos: [{ address: this._default_to, name: this._default_to }], ccs: [] };
    }
    return recipients;
  };
}

// Build compose message from pre-existing one or new from scratch
export class ComposeMessage implements ThreadMessageBase {
  attachments: Attachment[] | [] = [];
  bccs: UserProfile[] = [];
  ccs: UserProfile[] = [];
  draft_id: string | null = null;
  draft_version: string | null = null; // This applies only to web (b64 version)
  html: string | null = null;
  in_reply_to: string | null = null;  // this is in reply to message_id (of prev message)
  in_reply_to_unique_id?: string | null = null; // This holds the reply / reply_all to email unique_id (null for forward, new, draft)
  references: string[] = [];
  sender: UserProfile = { address: "", name: null };
  subject: string = "";
  text: string | null = null;
  tos: UserProfile[] = [];
  unique_id: string = "";
  signature?: string;
  content_type: ComposeTypesType;
  _contacts_data: ContactsData = { cs_profiles: [], cs_aliases: [], cs_groups: [], unclaimed: [] };
  _previous_draft_id: string | null = null;
  symm_key?: SymmKey;
  constructor(sender: Account, compose_mail: ComposeMail) {
    const recipients = compose_mail.getRecipients(sender);
    this.sender = this._adjustMessageSender(Account.getAccountProfile(sender));
    this.subject = !!compose_mail._default_subject ? compose_mail._default_subject : compose_mail.subject;
    this.signature = compose_mail.signature;
    this.content_type = compose_mail.content_type;
    if (!!recipients) {
      this.tos = recipients.tos;
      this.ccs = recipients.ccs;
      this.bccs = recipients.bccs || [];
    }
    // Initialize other props from previous messages If Reply, ReplyALL or Forward
    this._setPreviousMessageInfo(compose_mail);
  }

  get recipients(): ThreadMessageRecipients {
    return {
      tos: this.tos,
      ccs: this.ccs,
      bccs: this.bccs
    };
  }

  // Description: Get data parsed for SendMail and SaveDraft Crypto Calls 
  // NOTE: Parse tos, ccs, bccs to  "user_id, display_name -> UserBase
  get app_data() {
    const message_contents = this.message_contents;
    return {
      sender_name: this.sender.name,
      tos: parseProfileToUserBase(this.tos),
      ccs: parseProfileToUserBase(this.ccs),
      bccs: parseProfileToUserBase(this.bccs),
      subject: this.subject,
      text: message_contents?.text || "",
      html: message_contents?.html || "",
      draft_id: this.draft_id || null,
      in_reply_to: this.in_reply_to || "",
      in_reply_to_unique_id: this.in_reply_to_unique_id,
      references: this.references,
      attachments: this.attachments.map(att => att.metadata)
    };
  }

  // Description: Return unclaimed users for invites
  get unclaimed_users(): UserInvite[] {
    const unclaimed: UserInvite[] = [];
    _.forEach(ComposeMessage.groupAllRecipients(this.recipients),
      (profile: UserProfile) => {
        (profile.status === UserListStatus.unclaimed) && unclaimed.push({
          invitee_email: profile.address.toLowerCase(),
          user_id: this.sender.address
        });
      });
    return unclaimed;
  }

  // Description: get sanitized and ready to send text and html contents
  get message_contents(): MessageContents | null {
    return !!this.html ? this._cleanUpMessageBody(this.html, this.attachments) : null;
  }

  // Description: Get supporting data for saving draft and sending mail
  get save_metadata(): MessageSendMetadata {
    return {
      draft_id: this.draft_id,
      draft_version: this.draft_version,
      unclaimed: this.unclaimed_users,
    };
  }

  // Description: Detect changes for save on close
  get has_changed(): boolean {
    const recipient_count = ComposeMessage.groupAllRecipients(this.recipients).length;
    return !this.draft_id && (this.subject.length > 0 || recipient_count > 0);
  }

  // Description: Update model before save or send
  updateMessage(subject: string, message_body: string | null) {
    this.subject = subject;
    this.html = message_body;
  }

  // Description: Update attachments on adding 
  updateAttachments(attachments: Attachment[]) {
    this.attachments = attachments;
  }

  // Description: Keep draft ids previous and current
  updateDraftInfo(draft_id: string | null, draft_version: string | null = null) {
    this._previous_draft_id = this.draft_id;
    this.draft_id = draft_id;
    this.draft_version = draft_version;
  }

  // Description: Get a string array from the group of recipients
  getUserIdsArray(recipients: UserProfile[]): string[] | undefined {
    if (recipients.length > 0) {
      return _.uniq(recipients.map((recipient: UserProfile) => (!!recipient.external_email ? recipient.external_email : recipient.address)));
    }
  }

  // Description: Updaete tos, ccs, and bccs
  updateRecipients(recipients: Partial<ThreadMessageRecipients>) {
    if (!!recipients.tos) {
      this.tos = recipients.tos;
    }
    if (!!recipients.ccs) {
      this.ccs = recipients.ccs;
    }
    if (!!recipients.bccs) {
      this.bccs = recipients.bccs;
    }
  }

  // Description: Seed contacts with CS user find data for all fields
  // NOTE: Need to merge all data before updating prop
  updateContacts(_contact_data: ContactsData) {
    this._contacts_data = Contacts.mergeComposeRecipientContacts(Object.assign({}, this._contacts_data), _contact_data);
  }

  // Description: Get data parsed for Web version of SendMail CS Call s
  async getWebData(contacts: ContactsData): Promise<ComposeMailWebData> {
    const message_contents = this.message_contents;
    const tos = await parseProfileToEmailUser(this.tos, contacts);
    const ccs = await parseProfileToEmailUser(this.ccs, contacts);
    return {
      tos,
      ccs,
      bccs: await parseProfileToEmailUser(this.bccs, contacts),
      recipients: this._parseUserProfilesToUserIdentifier([...tos, ...ccs]),
      unclaimed: this.unclaimed_users,
      subject: this.subject,
      text: message_contents?.text || "",
      html: message_contents?.html || "",
      snippet: !!message_contents?.text ? snippetFromBody(message_contents.text) : "",
      draft_id: this.draft_id || null,
      in_reply_to: this.in_reply_to || "",
      references: this.references,
      attachments: this.attachments,
    };
  }

  // Description: Prepare Mail Data based on Protocol version for draft save and send mail
  async prepareWebMail(current_account: Account, is_draft: boolean = false): Promise<WebMailData[]> {
    const protocol_version = is_draft ? EMAIL_PROTOCOL_VERSIONS.V4 : CURRENT_EMAIL_PROTOCOL_VERSIONS;
    const contacts = this._contacts_data;
    this.symm_key = !!this.symm_key ? this.symm_key : await KeyFactory.newSymmKey();
    const metadata = await this.getWebData(contacts);
    let recipient_users: MailRecipient[] = [];
    if (protocol_version === EMAIL_PROTOCOL_VERSIONS.V4) {
      recipient_users = is_draft ? [current_account.mail_recipient] :
        getRecipientMailRecipient(contacts, [...metadata.tos, ...metadata.ccs, ...metadata.bccs], current_account);
    }
    const web_mail_data = await encryptAndPrepareEmail(
      metadata, this.symm_key, current_account, recipient_users, is_draft, protocol_version, !this.symm_key);
    if (is_draft) {
      const attachments = (web_mail_data[0].metadata.message as MailMessageV4).attachments;
      if (!attachments) return web_mail_data;
      for (const att of attachments) {
        const block_ids = att.block_ids;
        if (!block_ids) continue;
        const index = this.attachments.findIndex(a => a._uuid === att._uuid);
        if (index < 0) continue;
        this.attachments[index].block_ids = block_ids;
      }
    }
    return web_mail_data;
  }

  // Description: Quick validation of contacts length
  public validateContacts(): boolean {
    return [...this._contacts_data.cs_profiles, ...this._contacts_data.cs_aliases, ...this._contacts_data.unclaimed].length >= [...this.recipients.tos, ...this.recipients.ccs, ...this.recipients.bccs || []].length;
  }

  // Description: Load attachments from get web message on load 
  public loadAttachments(thread_attachments: ThreadAttachment[]) {
    this.attachments = _.map(thread_attachments, (attachment: ThreadAttachment) => (Attachment.fromThreadAttachment(attachment)));
  }

  // Description: Get information from previous message
  private _setPreviousMessageInfo(compose_mail: ComposeMail) {
    const message = compose_mail.message;
    if (!!message && this.content_type !== ComposeTypes.new) {
      !compose_mail.is_reply && this.loadAttachments(message.attachments);
      this.draft_id = compose_mail.is_draft ? message.unique_id : null;
      this.draft_version = !!message.version ? message.version : null;
      this.in_reply_to = compose_mail.is_reply ? message.message_id : null;
      this.in_reply_to_unique_id = compose_mail.is_reply ? message.unique_id : null;
      this.references = [...message.references, message.message_id];
    }
  }

  // Description: Set up user to display the prefix for mail
  private _adjustMessageSender(profile: UserProfile) {
    return { ...profile, ...{ name: !!profile.name ? `(PV) ${profile.name}` : "" } };
  }

  // Description: Returns users for web mail send 
  private _parseUserProfilesToUserIdentifier(email_users: EmailUser[]): WebMailRecipients {
    const _tos_recipients = this._parseEmailUserToWebRecipients(this.recipients.tos, email_users);
    const _ccs_recipients = this._parseEmailUserToWebRecipients(this.recipients.ccs, email_users);
    return {
      tos: _tos_recipients.users,
      tos_groups: _tos_recipients.groups,
      ccs: _ccs_recipients.users,
      ccs_groups: _ccs_recipients.groups
    };
  }

  // Description: Converts EmailUser to WebMailRecipients subtypes
  private _parseEmailUserToWebRecipients(profiles: UserProfile[], email_users: EmailUser[]): { users: UserIdentifierBase[]; groups: GroupEmailBase[] | null } {
    const groups: GroupEmailBase[] = [];
    const users: UserIdentifierBase[] = [];
    _.map(profiles, (profile: UserProfile) => {
      if (profile.status === UserListStatus.group && !!profile.members) {
        const members: UserIdentifierBase[] = [];
        _.map(profile.members, (address: string) => {
          const eu = _.find(email_users, (_user: EmailUser) => isSameUser(_user.user_id, address));
          return !!eu && members.push({ user_id: eu.user_id, key_version: eu.key_version });
        });
        groups.push({
          alias: profile.address,
          users: members
        });
      } else {
        const email_user = _.find(email_users, (_user: EmailUser) => isSameUser(_user.user_id, profile.address));
        !!email_user && users.push({
          user_id: email_user.user_id,
          key_version: email_user.key_version,
          external_email: email_user.external_email
        });
      };
    });
    return {
      users,
      groups: groups.length > 0 ? groups : []
    };
  }

  // Description: Clean body coming from TinyMCE 
  private _cleanUpMessageBody(html: string, attachments: Attachment[] = []): MessageContents {
    let cleaned_body: string = html;
    if (html.length > 0) { // this.compose_email.html
      // NOTE: tinyMCE has a <p>&nbsp;</p> for empty lines, this breaks comparisons later so tossing them
      cleaned_body = html.replace(/<p>&nbsp;<\/p>/g, "<br>");
      // NOTE: MUAs handle divs better than p tags, so making that switch
      cleaned_body = cleaned_body.replace(/<p>/g, "<div>");
      cleaned_body = cleaned_body.replace(/<\/p>/g, "</div>");
    }
    // NOTE: make a body from what the user created, and append previous if they exist
    let html_body = cleaned_body || "<span style='font-style: italic; color: lightgray;'>(no body)</span>";
    let text_body: string = stripHtml(html_body);
    text_body = decode(text_body); //  NOTE: decode the text body to use unicode

    const inline_attachments: Attachment[] = attachments.filter((attachment: Attachment) => attachment.is_inline);
    inline_attachments.forEach(inline_attachment => {
      // see if inline image has been deleted from the body, if so, remove
      if (!!inline_attachment.content_id && html_body.includes(`data-att-id="${inline_attachment.content_id.slice(1, -1)}"`)) {
        const inline_element_regex: RegExp = new RegExp(`src=".*?".*?data-att-id="${inline_attachment.content_id.slice(1, -1)}"`);
        html_body = html_body.replace(inline_element_regex, `src="cid:${inline_attachment.content_id.slice(1, -1)}"`);
      } else {
        // NOTE: If it cant find the image tag in html body add the image at the end inline
        if (!!inline_attachment.content_id) {
          html_body = `${html_body} <img src="cid:${inline_attachment.content_id.slice(1, -1)}" />`;
        } else {
          // NOTE: ELSE Instead removing attachment change its content_disposition"to send as "attachment"
          inline_attachment.content_disposition = AttachmentContentDisposition.attachment;
          this.attachments = attachments.map(att => att.uuid === inline_attachment.uuid ? inline_attachment : att);
        }
      }
    });
    html_body = RegexHelper.makeLinks(html_body);
    return {
      text: text_body,
      html: html_body
    };
  }

  // Description: Group all recipients
  static groupAllRecipients(recipients: ThreadMessageRecipients): UserProfile[] {
    return _.flatten([...recipients.tos, ...recipients.ccs, ...(recipients.bccs || [])]);
  }

  // Description: Build string for warning modal
  static getWarningMessage(subject_valid: boolean, body_valid: boolean) {
    const subject_text: string = !subject_valid ? "a subject" : "";
    const body_text: string = !body_valid ? "a body" : "";
    return `${subject_text}${!subject_valid && !body_valid ? " or " : ""}${body_text}`;
  }
}
