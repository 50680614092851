import React, { MouseEvent } from "react";
import { Row, Col, Dropdown } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { EntryItem, NodePermissionSet, parseValidDate, parseValidUTCDate } from "src/common";
import { Icon } from "src/components";
import { DriveDropdown } from "../";

type AllProps = {
  entry: EntryItem;
  permissions?: NodePermissionSet;
  handleAction: ActionHandlerFunction;
  under_downloads: boolean;
};

function ItemDetailsComponent(props: AllProps) {
  const { entry, permissions, handleAction, under_downloads } = props;
  const modified_date = parseValidDate(entry.lastModificationDate);

  return <Row className="metadata-list">
    <Col md={3} sm={12}>
      <label>Type</label>
      <b>{entry.type_label || entry.type}</b>
    </Col>
    {
      !!modified_date && <Col md sm={12}>
        <label>Modified</label>
        <b>
          {modified_date}
        </b>
      </Col>
    }
    {
      // NOTE: Hide label for pseudo directories type
      (!!permissions && !!permissions.label) &&
      <Col md sm={12} className="ps-md-5">
        <label>My Access</label>
        <b>{permissions.label}</b>
        {
          permissions.is_owner &&
          <small className="text-muted ms-2">(Owner)</small>
        }
      </Col>
    }
    {
      (!!permissions && permissions.expiration) &&
      <Col md sm={12}>
        <label>Expiration</label>
        <b>{parseValidUTCDate(permissions.expiration)}</b>
      </Col>
    }
    <Col xs="auto" className="ml-auto">
      {
        (!!permissions && !permissions.effective_root_node) &&
        <Dropdown as="button" className="pv-dropdown hide-arrow btn btn-icon btn-sm"
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          title="More actions..." disabled={false}>
          <Dropdown.Toggle as="span">
            <Icon className="ficon-more-vertical" />
          </Dropdown.Toggle>
          <DriveDropdown entry={entry} permissions={permissions} handleAction={handleAction} under_downloads={under_downloads}/>
        </Dropdown>
      }
    </Col>
  </Row>;
}

export default React.memo(ItemDetailsComponent);
