import * as React from "react";

function LoremComponent(props: { count?: number }) {
  const { count } = props;

  function Paragraphs(props: { total: number }) {
    const p = [];
    for (let i = 0; i < props.total; i++) {
      const position = i + 1;
      p.push(<p key={`p_${position}`}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum a est malesuada, vehicula orci nec, varius purus. Proin suscipit laoreet dolor ut gravida. Nullam quis iaculis lectus. Etiam egestas, dui sed cursus mattis, velit metus faucibus neque, id eleifend massa leo a risus. Donec nec tortor mauris. Vestibulum ultrices leo eget eros molestie, vitae maximus augue fermentum. Suspendisse velit neque, molestie et rutrum sed, ultricies sed dolor. Sed ac lectus sed libero interdum dignissim. Etiam vel nisi ac velit efficitur venenatis.</p>);
    }
    return <>{p}</>;
  }

  return !!count ?
    <Paragraphs total={count} /> :
    <>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum a est malesuada, vehicula orci nec, varius purus. Proin suscipit laoreet dolor ut gravida. Nullam quis iaculis lectus. Etiam egestas, dui sed cursus mattis, velit metus faucibus neque, id eleifend massa leo a risus. Donec nec tortor mauris. Vestibulum ultrices leo eget eros molestie, vitae maximus augue fermentum. Suspendisse velit neque, molestie et rutrum sed, ultricies sed dolor. Sed ac lectus sed libero interdum dignissim. Etiam vel nisi ac velit efficitur venenatis.</p>
      <p>Pellentesque rutrum urna id diam pellentesque mattis nec vel erat. Proin consequat, ipsum et viverra tempus, erat purus bibendum purus, et finibus metus mi convallis turpis. Mauris eget massa commodo, fringilla lorem sed, ornare lectus. Aenean lacinia massa lacinia urna sagittis varius. Suspendisse quis suscipit purus. In semper ultrices lacus, quis scelerisque urna feugiat eu. In mauris nisl, volutpat in pulvinar sit amet, rutrum nec mi. Vestibulum ac lorem nec eros vehicula imperdiet. Donec non bibendum elit, non blandit nibh. Sed non semper ligula. Etiam sed feugiat arcu. Duis non lacus sed sapien pretium semper. Aliquam sed maximus sapien. Quisque rhoncus accumsan dolor, eget facilisis velit accumsan eu.</p>
      <p> Phasellus feugiat elementum varius. Curabitur sit amet sagittis nibh, sed lobortis sapien. Donec semper in lorem eu eleifend. Cras tincidunt, sapien vitae iaculis ullamcorper, quam ligula placerat est, non viverra arcu nisi vitae enim. Donec sit amet ligula ac metus sagittis mollis eu quis nibh. Suspendisse auctor in quam ac vulputate. Ut commodo maximus dui, sit amet euismod tortor hendrerit at. Donec in facilisis felis. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Sed id lorem ac quam viverra fringilla eget vel lectus. Phasellus quam lectus, lacinia sit amet accumsan eget, convallis non nisi. Etiam a odio a lacus pharetra dictum. Aliquam efficitur et quam quis placerat.</p>
      <p>Morbi id pellentesque est. Sed placerat pretium accumsan. Nulla vehicula metus sit amet nunc tincidunt ullamcorper. In hac habitasse platea dictumst. Nulla dapibus vitae orci nec sagittis. Donec a tortor quis magna dapibus condimentum quis sed elit. Donec sem nisl, semper nec metus vitae, pulvinar efficitur dui. Sed aliquam, augue ac posuere efficitur, dolor felis hendrerit metus, nec ultricies diam tellus et purus. Praesent nec felis ante. Nulla sit amet velit vestibulum ipsum tincidunt blandit. Nulla nulla urna, pulvinar vitae nunc et, consequat porta nibh. Pellentesque at metus posuere, aliquam urna eu, egestas dui. Quisque et congue magna.</p>
      <p>Pellentesque sodales felis non felis porta tempor. Nam id hendrerit eros. Donec sit amet dapibus mi, quis dictum felis. Nunc et accumsan sem. Nam tristique pretium tincidunt. Vestibulum quis tempus nibh, in congue ipsum. Praesent tempor metus ut diam ultrices luctus.</p>
    </>;
}

export default React.memo(LoremComponent);