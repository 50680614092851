import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, Toast } from "react-bootstrap";
import { useAppDispatch, useAppSelector, GlobalConstants, PublicRoutes, AppConfiguration, QueryParamKeys, usePingQuery } from "src/common";
import { Icon } from "src/components";
import { accountActions } from "src/store";
import { OffCanvasDevTools } from "src/components/developer";
import { RootState } from "src/store/configureStore";

// Handle Server connections
function CryptoCheckComponent() {
  const [is_polling, setPolling] = useState<boolean>(true);
  const [user_id, setUserId] = useState<string>();
  const [offline, setOffline] = useState<boolean>(false);
  const [retry_count, setRetryCount] = useState<number>(-1);
  const account_user_id = useAppSelector((state: RootState) => state.account.current_account?.user_id);
  const { data, error } = usePingQuery(undefined, {
    pollingInterval: GlobalConstants.CRYPTO_PING_INTERVALS,
    skipPollingIfUnfocused: true,
    refetchOnReconnect: true,
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const current_location = useLocation();

  useEffect(() => {
    if (!!data) {
      data.cs_connection ? handleCryptoConnectionChanges() :
        setOffline(true);
    }

    (!!error) &&
      handleCryptoConnectionErrors();
  }, [data, error]);

  // Description: Handle changes in crypto cs_connection 
  // If error redirect to system_error_route (if not already there)
  // if already in system_error_route, redirect to login with user_id & redirect url -> clear crash
  function handleCryptoConnectionChanges() {
    const is_error_page = current_location.pathname === `/${PublicRoutes.system_error_route}`;
    const is_get_started = current_location.pathname === `/${PublicRoutes.get_started_route}`;
    offline && setOffline(false);
    const exceeds_max_retries = retry_count >= GlobalConstants.CRYPTO_PING_RETRY_MAX || retry_count < 0;
    exceeds_max_retries && setRetryCount(0);
    // If in error page or get started while offline then re-login
    if (is_error_page || (is_get_started && offline)) {
      const qs = !!user_id ? `?${QueryParamKeys.USER_ID_QUERY_KEY}=${encodeURIComponent(user_id)}` : "";
      navigate(`/${PublicRoutes.login_route}${qs}`);
    }

  }

  // Description: Handle Disconnections from Crypto
  function handleCryptoConnectionErrors() {
    // Note: Save user_id to local store for reusing in redirect after logout
    setUserId(account_user_id);
    const is_error_page = current_location.pathname === `/${PublicRoutes.system_error_route}`;
    const exceeds_max_retries = retry_count >= GlobalConstants.CRYPTO_PING_RETRY_MAX || retry_count < 0;
    // Check if retries are exceed and if is not error page then go to System Error (on Crypto disconnect)
    if (exceeds_max_retries && !is_error_page) {
      setRetryCount(0);
      setOffline(false);
      dispatch(accountActions.logout());
      navigate(`/${PublicRoutes.system_error_route}`);
    } else {
      // On Collection Disconnect or loss of connetion (500 error from crypt)
      setRetryCount(Number(retry_count + 1));
      setOffline(true);
    }
  }


  return <>
    {
      offline &&
      <ToastContainer position="top-start">
        <Toast bg="warning" show={offline} className="offline" >
          <Toast.Body>
            You are Offline <Icon className="pv-icon-info" />
          </Toast.Body>
        </Toast>
      </ToastContainer>
    }
    {
      AppConfiguration.isDevelopmentRunMode() &&
      <OffCanvasDevTools setPolling={setPolling} is_polling={is_polling} />
    }
  </>;
}

export default React.memo(CryptoCheckComponent);
