import { FSMessage } from "src/common/keys/protos/collections_pb";
import { Notification as FSNotification } from "src/common/keys/protos/async_job_pb";
import { DriveSocket, DriveRequest, Message, DriveErrorMessages, MessageHandlerDisplayType, WebsocketConnectionRoutes } from "src/common";
import { MessageRequest, WebsocketStatus, WebsocketStatusType, websocketActions, uiActions, AsyncJobRequest } from "src/store";
import { RootState } from "src/store/configureStore";
import { filesyncSocketAPI as api } from "../../base/base-api";

// Drive Websocket Connections
let drive_socket: DriveSocket;
let notification_socket: DriveSocket;
let upload_socket: DriveSocket;
let fs_notification_socket: DriveSocket;

// --------------------------------------------------------------------------------------------
// RTK Queries Hooks: Open API generated endpoints and types 
// --------------------------------------------------------------------------------------------
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    initializeDriveHooks: build.query<MessageRequest[], string>({
      queryFn: () => ({ data: [] }),
      async onCacheEntryAdded(
        arg,
        { dispatch, cacheDataLoaded, cacheEntryRemoved, updateCachedData },
      ) {
        // create a websocket connection when the cache subscription starts
        drive_socket = new DriveSocket();
        const socket = drive_socket.socket;
        try {
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;
          // NOTE: when data is received from the socket connection to the server,update our query result with the received message
          const listener = (event: MessageEvent) => {
            const data = event.data as ArrayBuffer;
            const serialized = new Uint8Array(data);
            const message = FSMessage.deserializeBinary(serialized);
            const request_id = message.getRequestId_asB64();
            const type = message.getType();
            updateCachedData((request: MessageRequest[]) => {
              request.push({
                id: request_id,
                message,
                type
              });
            })
          }
          socket.addEventListener('message', listener);
          socket.addEventListener('open', () => {
            if (socket.readyState === 1) {
              drive_socket.status = WebsocketStatus.connected;
              dispatch(websocketActions.wsDriveStatus(WebsocketStatus.connected));
            }
          });
          socket.addEventListener('close', () => {
            drive_socket.status = WebsocketStatus.disconnected;
            dispatch(websocketActions.wsDriveStatus(WebsocketStatus.disconnected));
          });
          socket.addEventListener('error', (event: Event) => {
            dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.error_drive_socket, MessageHandlerDisplayType.logger), {
              stack_message: DriveErrorMessages.error_drive_socket,
              stack_error: event
            }));
          });
        } catch (stack_error: unknown) {
          dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.default, MessageHandlerDisplayType.logger), { stack_message: DriveErrorMessages.error_drive_socket, stack_error }));
        }
        await cacheEntryRemoved;
        socket.close();
      },
      providesTags: ["collection_ws"]
    }),
    sendRequest: build.mutation<DriveRequest, DriveRequest>({
      queryFn: (data, api) => {
        const socket = drive_socket?.socket;
        if (!!socket && socket.readyState === 1) {
          socket.send(data.envelope);
          // Add DriveRequest to state
          const callbacks = (api.getState() as RootState).websocket.drive_ws_callbacks;
          const new_callbacks = callbacks.slice();
          new_callbacks.push(data);
          api.dispatch(websocketActions.setWsDriveCallbacks(new_callbacks))
        } else {
          api.dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.default, MessageHandlerDisplayType.logger),
            {
              stack_message: DriveErrorMessages.error_socket_not_ready,
              stack_error: { websocket_readyState: socket.readyState }
            }));
        }

        return { data };
      }
    }),
    closeSocket: build.mutation<WebsocketStatusType, any>({
      queryFn: (ws_status, api) => {
        if (drive_socket.status === WebsocketStatus.connected) {
          drive_socket.socket.close();
          api.dispatch(websocketActions.wsDriveStatus(WebsocketStatus.disconnecting));
        }
        return ws_status;
      }
    }),

    // --------------------------------------------------------------------------------
    // Collection Server Filesync Notification Socket
    // https://github.com/PreVeil/core/blob/dev/collection_server/docs/filesync_notifications.md
    // --------------------------------------------------------------------------------
    initializeNotificationHooks: build.query<MessageRequest | null, string>({
      queryFn: () => ({ data: null }),
      async onCacheEntryAdded(
        arg,
        { dispatch, cacheDataLoaded, cacheEntryRemoved, updateCachedData },
      ) {
        // create a websocket connection when the cache subscription starts
        notification_socket = new DriveSocket();
        const socket = notification_socket.socket;
        try {
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;
          // NOTE: when data is received from the socket connection to the server,update our query result with the received message
          const listener = (event: MessageEvent) => {
            const data = event.data as ArrayBuffer;
            const serialized = new Uint8Array(data);
            const message = FSMessage.deserializeBinary(serialized);
            const request_id = message.getRequestId_asB64();
            const type = message.getType();
            updateCachedData(() => {
              return {
                id: request_id,
                message,
                type
              }
            })
          }
          socket.addEventListener('message', listener);
          socket.addEventListener('open', () => {
            if (socket.readyState === 1) {
              notification_socket.status = WebsocketStatus.connected;
              dispatch(websocketActions.wsDriveNotificationStatus(WebsocketStatus.connected));
            }
          });
          socket.addEventListener('close', () => {
            notification_socket.status = WebsocketStatus.disconnected;
            dispatch(websocketActions.wsDriveNotificationStatus(WebsocketStatus.disconnected));
          });
          socket.addEventListener('error', (event: Event) => {
            dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.error_notification_socket, MessageHandlerDisplayType.logger), {
              stack_message: DriveErrorMessages.error_notification_socket,
              stack_error: event
            }));
          });
        } catch (stack_error: unknown) {
          dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.default, MessageHandlerDisplayType.logger), { stack_message: DriveErrorMessages.error_notification_socket, stack_error }));
        }

        await cacheEntryRemoved;
        socket.close();
      },
    }),
    sendNotificationRequest: build.mutation<DriveRequest, DriveRequest>({
      queryFn: (data, api) => {
        const socket = notification_socket?.socket;
        (!!socket && socket.readyState === 1) ? socket.send(data.envelope) :
          api.dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.default, MessageHandlerDisplayType.logger), {
            stack_message: DriveErrorMessages.error_notif_socket_not_ready,
            stack_error: { websocket_readyState: socket.readyState }
          }));
        return { data };
      }
    }),

    // --------------------------------------------------------------------------------
    // Drive Upload dedicated socket
    // --------------------------------------------------------------------------------
    initializeUploadHooks: build.query<MessageRequest | null, any>({
      queryFn: () => ({ data: null }),
      async onCacheEntryAdded(
        arg,
        { dispatch, getState, cacheDataLoaded, cacheEntryRemoved, updateCachedData },
      ) {
        // create a websocket connection when the cache subscription starts
        upload_socket = new DriveSocket();
        const socket = upload_socket.socket;
        try {
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;
          // NOTE: when data is received from the socket connection to the server,update our query result with the received message
          const listener = (event: MessageEvent) => {
            const data = event.data as ArrayBuffer;
            const serialized = new Uint8Array(data);
            const message = FSMessage.deserializeBinary(serialized);
            const request_id = message.getRequestId_asB64();
            const type = message.getType();
            updateCachedData(() => {
              return {
                id: request_id,
                message,
                type
              };
            })
          }
          socket.addEventListener('message', listener);
          socket.addEventListener('open', () => {
            if (socket.readyState === 1) {
              upload_socket.status = WebsocketStatus.connected;
              dispatch(websocketActions.wsDriveUploadStatus(WebsocketStatus.connected));
              // Note: If socket closed mid-upload we need to pick up where it last left off
              const pending_callback = (getState() as RootState).websocket.drive_upload_ws_callback;
              !!pending_callback && socket.send(pending_callback.envelope);
            }
          });
          socket.addEventListener('close', (event: CloseEvent) => {
            if (event.code === 1006) {
              // Note: Indicates the connection closed due to an abnormal error and must be reopened
              dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.default, MessageHandlerDisplayType.logger), { error: event }));
            }
            upload_socket.status = WebsocketStatus.disconnected;
            dispatch(websocketActions.wsDriveUploadStatus(WebsocketStatus.disconnected));
          });
          socket.addEventListener('error', (event: Event) => {
            dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.error_upload_socket, MessageHandlerDisplayType.logger),
              {
                stack_message: DriveErrorMessages.error_upload_socket,
                stack_error: event
              }));
          });
        } catch (stack_error: unknown) {
          dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.default, MessageHandlerDisplayType.logger),
            { stack_message: DriveErrorMessages.error_upload_socket, stack_error }));
        }
        await cacheEntryRemoved;
        socket.close();
      },
    }),
    sendUploadRequest: build.mutation<DriveRequest, DriveRequest>({
      queryFn: (data, api) => {
        const socket = upload_socket.socket;
        if (!!socket && socket.readyState === 1) {
          socket.send(data.envelope);
          api.dispatch(websocketActions.setWsDriveUploadCallback(data));
        } else {
          if (!!socket && socket.readyState === 0) {
            // Note: If socket is connecting, we need to store the request and try again once connected
            api.dispatch(websocketActions.setWsDriveUploadCallback(data));
          } else {
            api.dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.default, MessageHandlerDisplayType.logger),
              {
                stack_message: DriveErrorMessages.error_socket_not_ready,
                stack_error: { websocket_readyState: socket.readyState }
              }));
          }
        }
        return { data };
      }
    }),
    closeUploadSocket: build.mutation<WebsocketStatusType, WebsocketStatusType>({
      queryFn: (data, api) => {
        if (upload_socket.status === WebsocketStatus.connected) {
          upload_socket.socket.close();
          api.dispatch(websocketActions.wsDriveUploadStatus(WebsocketStatus.disconnecting));
        }
        return { data };
      }
    }),
    // --------------------------------------------------------------------------------
    // LOCAL Filesync Notification Socket dedicated socket
    // --------------------------------------------------------------------------------
    initializeFSNotificationHooks: build.query<AsyncJobRequest | null, string>({
      queryFn: () => ({ data: null }),
      async onCacheEntryAdded(
        arg,
        { dispatch, cacheDataLoaded, cacheEntryRemoved, updateCachedData },
      ) {
        // create a websocket connection when the cache subscription starts
        fs_notification_socket = new DriveSocket(`${process.env.REACT_APP_FILESYNC_WEBSOCKET_SERVER}/${WebsocketConnectionRoutes.subscribe_all}`);
        const socket = fs_notification_socket.socket;
        try {
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;
          // NOTE: when data is received from the socket connection to the server,update our query result with the received message
          const listener = (event: MessageEvent) => {
            const data = event.data as ArrayBuffer;
            const serialized = new Uint8Array(data);
            const message = FSNotification.deserializeBinary(serialized);
            const type = message.getType();
            updateCachedData(() => {
              return {
                message,
                type
              }
            })
          }
          socket.addEventListener('message', listener);
          socket.addEventListener('open', () => {
            if (socket.readyState === 1) {
              fs_notification_socket.status = WebsocketStatus.connected;
              dispatch(websocketActions.wsFSDriveNotificationStatus(WebsocketStatus.connected));
            }
          });
          socket.addEventListener('close', () => {
            fs_notification_socket.status = WebsocketStatus.disconnected;
            dispatch(websocketActions.wsFSDriveNotificationStatus(WebsocketStatus.disconnected));
          });
          socket.addEventListener('error', (event: Event) => {
            dispatch(uiActions.handleRequestErrors(
              new Message(DriveErrorMessages.error_fs_notification_socket, MessageHandlerDisplayType.logger),
              {
                stack_message: DriveErrorMessages.error_fs_notification_socket,
                stack_error: event
              }));
          });
        } catch (stack_error: unknown) {
          dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.default, MessageHandlerDisplayType.logger),
            {
              stack_message: DriveErrorMessages.error_fs_notification_socket,
              stack_error
            }));
        }

        await cacheEntryRemoved;
        socket.close();
      },
    }),
    sendFSNotificationRequest: build.mutation<DriveRequest, DriveRequest>({
      queryFn: (data, api) => {
        const socket = fs_notification_socket?.socket;
        (!!socket && socket.readyState === 1) ? socket.send(data.envelope) :
          api.dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.default, MessageHandlerDisplayType.logger), {
            stack_message: DriveErrorMessages.error_fs_notification_socket_not_ready,
            stack_error: { websocket_readyState: socket.readyState }
          }));
        return { data };
      }
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as filesyncSocketApi };


// export { api as filesyncSocketApi };
export const {
  useInitializeDriveHooksQuery,
  useLazyInitializeDriveHooksQuery,
  useSendRequestMutation,
  useCloseSocketMutation,
  // Collection Server Filesync Notification Socket
  useInitializeNotificationHooksQuery,
  useLazyInitializeNotificationHooksQuery,
  useSendNotificationRequestMutation,
  // Upload Socket
  useInitializeUploadHooksQuery,
  useLazyInitializeUploadHooksQuery,
  useSendUploadRequestMutation,
  useCloseUploadSocketMutation,
  // Filesync Notification Socket
  useInitializeFSNotificationHooksQuery,
  useLazyInitializeFSNotificationHooksQuery,
  useSendFSNotificationRequestMutation
} = injectedRtkApi;
