import * as React from "react";
import { Icon } from "src/components";

function NotMatchesFoundView() {
  return (
    <div className="message-box d-flex flex-column justify-content-center">
      <Icon className="pv-icon-mailbox-empty" />
      <p>No matches found!</p>
    </div>
  );
}

export default React.memo(NotMatchesFoundView);
