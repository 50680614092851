import React from "react";
import { Row } from "react-bootstrap";
// import { DateSelectorDropdown } from "src/components";

function ActivityLogsDatePickerComponent() {
  const ActivityLogsDatePicker = () => (
    <Row className="d-flex flex-row">
      <b>Timeframe:</b>
      {/* <DateSelectorDropdown></DateSelectorDropdown> */}
    </Row>
  );

  return <ActivityLogsDatePicker />;
}

export default React.memo(ActivityLogsDatePickerComponent);
