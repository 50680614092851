/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
// @ts-ignore
import { concatArrays, NaclCrypto, randomBytes } from "pvcryptojs";
import { Helpers } from "../..";
import { SymmKey } from "./symm";
import { EncryptionStyle } from "../encryption/encryption";

export const text_header = new Uint8Array([160, 0, 0, 0]);
export const binary_header = new Uint8Array([32, 0, 0, 0]);

export class SymmKeyV0 extends SymmKey {
  static protocol_version = 0;
  constructor(key: Uint8Array, private style?: EncryptionStyle) {
    super(SymmKeyV0.protocol_version, key);
  }

  static async new(secret?: Uint8Array, style?: EncryptionStyle): Promise<SymmKeyV0> {
    return new SymmKeyV0(
      secret || await NaclCrypto.generateSymmKey(), style || EncryptionStyle.EMAIL);
  }
  /* eslint-disable */
  public serialize() {
    switch (this.style) {
      case EncryptionStyle.EMAIL:
        return Helpers.utf8Encode(Helpers.b64Encode(Helpers.utf8Encode(JSON.stringify({material: Helpers.b64Encode(this.key)}))));
      case EncryptionStyle.DRIVE:
        return this.key;
    }
  }

  public async encrypt(
    plaintext: Uint8Array,
    iv?: Uint8Array, is_text: boolean = false
  ): Promise<Uint8Array> {
    if (this.style === EncryptionStyle.EMAIL) {
      plaintext = is_text ?
        new Uint8Array([...text_header, ...plaintext]) :
        new Uint8Array([...binary_header, ...plaintext]);
    }
    const _iv = iv || randomBytes(NaclCrypto.ivLength);
    const [ciphertext, tag] =
      await NaclCrypto.symmetricEncrypt(this.key, _iv, plaintext);

    return concatArrays(_iv, tag, ciphertext);
  }

  public async decrypt(ciphertext: Uint8Array): Promise<Uint8Array> {
    const iv = ciphertext.slice(0, NaclCrypto.ivLength),
          tag = ciphertext.slice(NaclCrypto.ivLength, NaclCrypto.ivLength + NaclCrypto.tagLength),
          cipher = ciphertext.slice(NaclCrypto.ivLength + NaclCrypto.tagLength);

    // ignore header bytes
    let decrypted = NaclCrypto.symmetricDecrypt(this.key, iv, tag, cipher);
    if (this.style === EncryptionStyle.EMAIL) {
      decrypted = decrypted.then(p => !p ? p : p.slice(text_header.length));
    }
    return decrypted;
  }
}
