import React, { Dispatch, SetStateAction } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { ActionHandlerFunction, PaginationItem } from "@preveil-api";
import {
  RequestTab, SettingsUIActionTypes, ApprovalRequestStatusMapping,
  ApprovalRequestStatus,
  GlobalConstants,
  UserRequest
} from "src/common";
import { Icon, PaginationDisplay, SimplePagination } from "src/components";

type AllProps = {
  tab: string;
  pagination?: PaginationItem;
  handleAction: ActionHandlerFunction;
  confirmDelete: Function;
  checked_list: UserRequest[];
  setCheckedList: Dispatch<SetStateAction<UserRequest[]>>;
  approval_requests: UserRequest[];
  setShow: Dispatch<SetStateAction<boolean>>;
  is_approval: boolean;
  is_loading: boolean;
};

/* Description: Toolbar for approval requests: approvals, recovery group requests and admin approval requests. 
Changes depending on which component and also which tab - pending or history. */
function ApprovalRequestsToolbarComponent(props: AllProps) {
  const {
    tab,
    pagination,
    handleAction,
    confirmDelete,
    checked_list,
    setCheckedList,
    approval_requests,
    setShow,
    is_approval,
    is_loading
  } = props;

  /* Description: Changes the filter status when the user selects a different option. - For the history tab */
  function handleOptionSelect(e: React.ChangeEvent<HTMLSelectElement>) {
    setShow(false);
    setCheckedList([]);
    const value = e.target.value;
    handleAction({
      actionType: SettingsUIActionTypes.UpdateFilterStatus,
      params: value,
    });
  };

  function isLastPage(pagination: PaginationItem) {
    return (
      (pagination.pageIndex + 1) * GlobalConstants.REQUESTS_PER_PAGE_COUNT > pagination.totalRows ||
      (approval_requests.length === GlobalConstants.REQUESTS_PER_PAGE_COUNT &&
        (pagination.pageIndex + 1) * GlobalConstants.REQUESTS_PER_PAGE_COUNT ===
          pagination.totalRows) ||
      approval_requests.length === 0
    );     
  }

  function refresh() {
    setShow(false);
    setCheckedList([]);
    handleAction({
      actionType: SettingsUIActionTypes.Refresh,
      params: tab,
    });
  }

  return (
    <>
      <Row className="toolbar m-0">
        {tab === RequestTab.history && (
          <Col xs="auto" className="order-0">
            <Form.Select size="sm" onChange={handleOptionSelect}>
              <option value={ApprovalRequestStatus.approved}>
                {ApprovalRequestStatusMapping.approved}
              </option>
              <option value={ApprovalRequestStatus.denied}>
                {ApprovalRequestStatusMapping.denied}
              </option>
            </Form.Select>
          </Col>
        )}
        <Col className="order-1 ps-2">
          {tab === RequestTab.pending && !is_approval && (
            <Button
              variant="icon"
              size="sm"
              disabled={checked_list.length === 0}
              onClick={() => confirmDelete()}
              title="Delete Selected"
            >
              <Icon className="ficon-trash-2" />
            </Button>
          )}
        </Col>
        <Col xs="auto" className="order-sm-2 order-3 ms-auto pe-0">
          {tab === RequestTab.history && !!pagination && approval_requests.length > 0 && (
            <SimplePagination
              isFirst={pagination.pageIndex === 0}
              isLast={isLastPage(pagination)}
              className="mb-0 pe-0 justify-content-end"
              handleAction={handleAction}
            />
          )}
        </Col>
        <Col xs="auto" className="order-sm-3 order-4 me-2">
          {!!pagination && !is_loading && <PaginationDisplay pagination={pagination} />}
        </Col>
        <Col className="divider order-sm-3 order-1"></Col>
        <Col xs="auto" className="p-0 order-sm-3 order-4 refresh">
          <Button
            variant="icon"
            size="sm"
            title="Refresh Approval Requests"
            onClick={() => refresh()}
          >
            {is_loading ? <i className="spinner"></i> : <Icon className="ficon-rotate-cw" />}
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default React.memo(ApprovalRequestsToolbarComponent);
