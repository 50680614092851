import React, { useState } from "react";
import { ActionHandlerFunction, IActionHandler } from "@preveil-api";
import { AdminUIActionTypes, AdminErrorMessages, useCsvDataValidation } from "src/common";
import { Icon, CsvSubmitData, DraggableContainer, AddressListModalPanel } from "src/components";
import { Card, Button, Modal, Tabs, Tab, Collapse } from "react-bootstrap";
import { EnterAddressesForm } from ".";
import _ from "lodash";
import { ParseError, ParseMeta } from "papaparse";

type AllProps = {
  handleActions: ActionHandlerFunction;
}

type PapaparseData = {
  data: {
    "Domain or User Address": string
  }
}

function CreateWhitelistComponent({ handleActions }: AllProps) {
  // Headers for Modal tabs.
  const tabEventKey = {
    individual: "Add Trusted Aliases",
    csvUpload: "Importing Trusted Community Aliases"
  };
  const csvTrustedCommunityColumnName = "Domain or User Address"; // column name that matches the document template provided to the user.
  const [tab_heading, setTabHeading] = useState<string>(tabEventKey.individual);
  const [open_collapse, setOpenCollapse] = useState<boolean>(false);
  const [show_create_whitelist_modal, setShowCreateWhitelistModal] = useState<boolean>(false);
  const { validAddresses, invalidAddresses, isDataDrop, handleValidateData, resetData } = useCsvDataValidation();

  const AddTrustedAddressRequests = {
    // Handle change for individual addresses/domain tab
    handleSubmitAddresses: (collection?: string[]) => {
      handleActions({ actionType: AdminUIActionTypes.SubmitAddresses, params: collection });
    },
    // Handle change when using CSV Upload
    handleSubmitCsvData: () => {
      handleActions({ actionType: AdminUIActionTypes.SubmitAddresses, params: validAddresses });
    },
    handleReset: () => {
      resetData();
    },
    handleCsvData: (csvFile: { data: PapaparseData[], errors: ParseError[], meta: ParseMeta }) => {
      handleCsvFileData(csvFile);
    },
    // Handle error from Draggable component (wrong file extension).
    handlePageError: (params: { message: string, stack: string }) => {
      const { message, stack } = params;
      handleActions({ actionType: AdminUIActionTypes.PageError, params: { message, stack } });
    },
    handleShowModal: () => {
      toggleModal();
      resetData();
    }
  };

  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    if ((AddTrustedAddressRequests as any)[callback] instanceof Function) {
      (AddTrustedAddressRequests as any)[callback](actionObj.params);
    }
  }

  // handling csv data.
  function handleCsvFileData(csvFile: { data: PapaparseData[], errors: ParseError[], meta: ParseMeta }) {
    if (csvFile?.errors && csvFile?.errors.length >= 1) {
      // Papaparse returns some errors when the CSV formatting is not correct
      // or the data is missing a delimiter in each row, but that doesn't mean the data will not be parse.
      // so we are just logging those errors.
      handleActions({ actionType: AdminUIActionTypes.PageErrorMessage, params: { message: AdminErrorMessages.error_csv_upload_format, stack: csvFile.errors } });
    }
    const { data, meta } = csvFile;
    // we need to verify if the columns name inside the csv file matches the one on the csv template.
    const isValidColumns = meta.fields?.includes(csvTrustedCommunityColumnName);

    if (data.length === 0 || !isValidColumns) {
      if (data.length === 0) {
        handleActions({ actionType: AdminUIActionTypes.PageError, params: { message: AdminErrorMessages.error_csv_empty_file, stack: data } });
      } else if (!isValidColumns) {
        handleActions({ actionType: AdminUIActionTypes.PageError, params: { message: AdminErrorMessages.error_csv_wrong_heading, stack: meta.fields } });
      }
    } else {
      // any repeated address/domain from the csv file will be removed (only within the document).
      const addressesFromCsvFile: string[] = _.uniq(data.map((dataRow: any) => dataRow[csvTrustedCommunityColumnName].trim().toLowerCase()));
      handleValidateData(addressesFromCsvFile);
    }
  };

  // Description: Handle delete of a single valid address
  function handleDeleteAction(id?: string) {
    if (!!id) {
      const data = [...validAddresses, ...invalidAddresses].filter((address: string) => address !== id);
      handleValidateData(data);
    }
  };

  const toggleModal = () => {
    setShowCreateWhitelistModal(!show_create_whitelist_modal);
    resetData();
  };

  // handling changing modal tabs and including the correct header.
  function handleTabSelect(eventKey: string | null) {
    const tab_heading = eventKey === "csvUpload" ? tabEventKey.csvUpload : tabEventKey.individual;
    setTabHeading(tab_heading);
  }

  const isValid = _.isEmpty(invalidAddresses) && !_.isEmpty(validAddresses);
  const showDivisor = !isValid;
  return (
    <>
      <Card className="admin-card">
        <Icon className="trusted-community" />
        <Card.Body>
          <Card.Title as="p">Create a list of trusted addresses</Card.Title>
          <Card.Text as="p" className="create-list-text">
            <span className="text-muted">Learn More:</span>
            <a href="https://www.preveil.com">preveil.com/support</a>
          </Card.Text>
          <Button variant="primary" onClick={toggleModal}>
            <Icon className="icon-plus2" />
            Add to Address List
          </Button>
          <Modal
            size="lg"
            show={show_create_whitelist_modal}
            onHide={toggleModal}
            aria-labelledby="create approval group"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>{tab_heading}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Tabs defaultActiveKey="individual" id="add-users-tabs" onSelect={handleTabSelect}>
                <Tab eventKey="individual" title="Individual">
                  <EnterAddressesForm handleAction={handlePageActions} />
                </Tab>
                <Tab eventKey="csv-upload" title="CSV Upload">
                  <CsvSubmitData handleAction={handlePageActions} isValid={isValid}>
                    {validAddresses.length >= 1 && isDataDrop && (
                      <AddressListModalPanel
                        validation_state="valid_trusted"
                        collection={validAddresses}
                        handleDeleteAction={handleDeleteAction}
                      />
                    )}
                    {invalidAddresses.length >= 1 && (
                      <section
                        className={`invalid-addresses-card ${showDivisor ? "show-divider" : ""
                          }`}
                      >
                        <header>
                          <h3 className="mb-2">
                            Addresses that will <i className="emphasis">not</i> be added
                          </h3>
                          <Button
                            variant="icon"
                            onClick={() => setOpenCollapse(!open_collapse)}
                            aria-controls="collapse-invalid-area"
                          >
                            <Icon
                              className={`${open_collapse ? "ficon-chevron-up" : "ficon-chevron-down"
                                }`}
                            />
                          </Button>
                        </header>
                        <Collapse in={open_collapse}>
                          <div id="collapse-invalid-area">
                            <div className="pt-2 pb-0">
                              {invalidAddresses.length >= 1 && isDataDrop && (
                                <AddressListModalPanel
                                  validation_state="invalid"
                                  collection={invalidAddresses}
                                  handleDeleteAction={handleDeleteAction}
                                />
                              )}
                            </div>
                          </div>
                        </Collapse>
                      </section>
                    )}
                    {!isDataDrop && (
                      <>
                        <p className="download-csv-legend">
                          Download and use the upload template to accurately add multiple users at
                          once
                          <a
                            href="/templates/PreVeil-Trusted-Community-Upload-Template.csv"
                            target="_blank"
                          >
                            <Icon className="ficon-download" />
                            PreVeil-Trusted-Community-Upload-Template.csv
                          </a>
                        </p>
                        <DraggableContainer handleAction={handlePageActions} />
                      </>
                    )}
                  </CsvSubmitData>
                </Tab>
              </Tabs>
            </Modal.Body>
          </Modal>
        </Card.Body>
      </Card>
    </>
  );
}

export default React.memo(CreateWhitelistComponent);
