export const EMAIL_PROTOCOL_VERSIONS: Record<string, number> = {
  V4: 4,
  V5: 5,
  V6: 6,
  V7: 7,
  current: 6
};


// Global protocol versions
export const CURRENT_EMAIL_PROTOCOL_VERSIONS: number = !!process.env.REACT_APP_CURRENT_EMAIL_PROTOCOL_VERSIONS ? EMAIL_PROTOCOL_VERSIONS[process.env.REACT_APP_CURRENT_EMAIL_PROTOCOL_VERSIONS] : 7;
export const CURRENT_CS_API_VERSIONS: number = !!process.env.REACT_APP_CURRENT_CS_API_VERSIONS ? Number(process.env.REACT_APP_CURRENT_CS_API_VERSIONS) : 13;
export enum COLLECTION_PROTOCOL_VERSIONS {
  none,
  V1,
  V2
}
export const CURRENT_COLLECTION_API_VERSION = process.env.REACT_APP_COLLECTION_API_VERSION || "v9";

