import React, { ReactNode } from "react";
import { ActionHandlerFunction } from "@preveil-api";
import { AdminUIActionTypes } from "src/common";
import { Button } from "react-bootstrap";

type AllProps = {
  children: ReactNode;
  handleActions: ActionHandlerFunction;
}

function ConfirmAccountInvite({ children, handleActions }: AllProps) {
  return (
    <>
      {children}
      <footer className="mt-4">
        <Button
          onClick={() => handleActions({ actionType: AdminUIActionTypes.SubmitUserInvite })}
        >
          Invite
        </Button>
        <Button
          onClick={() => handleActions({ actionType: AdminUIActionTypes.ResetSingleSubsumeInvite })}
          variant="no-outline-primary">Reset</Button>
        <Button
          onClick={() => handleActions({ actionType: AdminUIActionTypes.CancelSingleSubsumeInvite })}
          variant="no-outline-primary"
        >
          Cancel
        </Button>
      </footer>
    </>
  );
}

export default React.memo(ConfirmAccountInvite);