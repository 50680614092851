import * as React from "react";
import { Card, Button } from "react-bootstrap";
import { PrivateRoutes } from "src/common";
import { Icon } from "src/components";
type AllProps = {
  name?: string;
}
function SuccessPanelComponent(props: AllProps) {
  return (
    <>
      <Card.Title>You are now an administrator for <b>"{props.name || "Your Organization"}"</b>.</Card.Title>
      <Card.Text>
        You can now add additional users and administrators to your
        organization through the Admin Console by clicking the "Add Users"
        button on the Users tab.
      </Card.Text>
      <Card.Text className="d-flex">
        <Icon className="pv-icon-question text-muted" /><span>
          Remember to assign Approval Groups to users once they join.
          Approval Groups allow trusted parties to recover user account
          information without giving them access to any of that user's
          data.</span>
      </Card.Text >
      <div className="text-center mt-4">
        <Button href={`/${PrivateRoutes.admin_route}/${PrivateRoutes.users_route}`}>Manage My New Organization</Button>
      </div>
    </>
  );
}

export default React.memo(SuccessPanelComponent);
