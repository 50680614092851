import React, { ReactNode } from "react";
import { Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { AdminUIActionTypes } from "src/common";

type CsvComponentPanelProps = {
  handleAction: ActionHandlerFunction;
  showResetButton?: boolean;
  submitButtonLabel?: string;
  isValid: boolean;
  isDataLoading?: boolean;
  children?: ReactNode;
};

function CsvSbumitData({ handleAction, showResetButton, submitButtonLabel = "Add to List", isValid, children, isDataLoading }: CsvComponentPanelProps) {
  return (
    <>
      {children}
      <footer className="mt-5">
        <Button
          className="me-2"
          onClick={() => handleAction({ actionType: AdminUIActionTypes.SubmitCsvData })}
          disabled={!isValid || isDataLoading}
        >
          {submitButtonLabel}
        </Button>
        {showResetButton && (
          <Button
            onClick={() => handleAction({ actionType: AdminUIActionTypes.Reset })}
            disabled={!isValid}
            className="me-2"
            variant="no-outline-primary"
          >
            Reset
          </Button>
        )}
        <Button
          className="me-2"
          variant="no-outline-primary"
          onClick={() => handleAction({ actionType: AdminUIActionTypes.ShowModal })}
        >
          Cancel
        </Button>
      </footer>
    </>
  );
}

export default React.memo(CsvSbumitData);

