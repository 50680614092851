import { useEffect } from "react";
import { MessageDisplayType, ErrorStackDataType } from "@preveil-api";
import { FSMessage, FSStatus } from "src/common/keys/protos/collections_pb";
import {
  Account, useSendNotificationRequestMutation, DriveRequest, DriveErrorMessages, MessageHandlerDisplayType, CollectionFilesyncAPI, useAppDispatch,
  Message, getEnumKey, useGetPermissions, PermissionSetType, useAppSelector, ACK, useSendRequestMutation, ErrorStackItem
} from "src/common";
import { RootState } from "src/store/configureStore";
import { uiActions, driveActions } from "src/store";

// Description: SubscribeAll for Collection Server Notifications. Will ACK on a limit of calls
export function useDriveSubscribe(current_account: Account, collection_id?: string, default_permissions?: PermissionSetType[]) {
  const ack_notification = useAppSelector((state: RootState) => state.drive.ack_notification);
  const { permissions, getPermissions } = useGetPermissions(current_account);
  const [sendNotificationRequest] = useSendNotificationRequestMutation();
  const [sendRequest] = useSendRequestMutation();
  const dispatch = useAppDispatch();

  // Description: Fetch latest collection permissions
  useEffect(() => {
    (!!collection_id && !!default_permissions) && getPermissions(collection_id, default_permissions);
  }, [collection_id, default_permissions]);

  // Description: Use latest permissions to call subscribeAll api
  useEffect(() => {
    (!!collection_id && !!permissions) && subscribeToDriveNotifications(permissions);
  }, [permissions]);

  // Description: Monitor ACK to send
  useEffect(() => {
    (!!ack_notification && !!permissions) && AcknowledgeNotification(permissions, ack_notification);
  }, [ack_notification]);

  // Description: Subscribe to current colledction
  async function subscribeToDriveNotifications(_permissions: PermissionSetType) {
    const drive_request = await CollectionFilesyncAPI.subscribeAll(current_account, _permissions);
    handleSendRequest(drive_request, DriveErrorMessages.no_default_collection_id);
  }

  // Description: Acknowledge Notifications to keep them coming
  // Ref: https://github.com/PreVeil/core/blob/dev/collection_server/docs/filesync.md#ack
  // Return ACK notifications for "key:[collection_id[" topics through Main WS. Since that is where those come from
  async function AcknowledgeNotification(_permissions: PermissionSetType, _ack_notification: ACK) {
    const is_notification = !!ack_notification?.topic && !ack_notification.topic.includes("key:");
    const drive_request = await CollectionFilesyncAPI.AcknowledgeNotification(current_account, _permissions,
      _ack_notification.topic, _ack_notification.sequence);
    // NOTE: Add a callback for main ws error handling
    drive_request.setCallback(async (message: FSMessage) => {
      (message.getStatus() !== FSStatus.OK) &&
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_directory }, message);
    });
    handleSendRequest(drive_request, DriveErrorMessages.error_ack_notifications, is_notification);
    dispatch(driveActions.setAck(undefined)); // NOTE: Reset ACK in store
  }

  // -----------------------------
  // HOOK General
  // -----------------------------
  // Description: Sends Request through the notification_ws and handles error when the request object is null, pass it custom stack message
  function handleSendRequest(request: DriveRequest | null, stack_error: string = DriveErrorMessages.error_sending_request, is_notification: boolean = true) {
    if (!!request) {
      is_notification ? sendNotificationRequest(request) : sendRequest(request);
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, { stack_error, stack_message: DriveErrorMessages.error_sending_request });
    }
  }

  // Description: Handle error message and send error to store
  function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, fsmessage?: FSMessage | null,
    display_type: MessageDisplayType = MessageHandlerDisplayType.logger) {
    const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
    const stack = new ErrorStackItem("[useDriveSubscribe Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
    dispatch(uiActions.handleRequestErrors(new Message(message, display_type), stack));
  }

}
