import * as React from "react";
import { Col, Row, Offcanvas } from "react-bootstrap";
import { ApprovalGroup } from "src/common";
import _ from "lodash";
import { UserProfile } from "@preveil-api";

type AllProps = {
  approval_group: ApprovalGroup;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setCheckedList: React.Dispatch<React.SetStateAction<ApprovalGroup[]>>;
};

/* Description: Sidepanel component for when you click on an approval group row */
function ApprovalGroupSidePanelComponent(props: AllProps) {
  const { approval_group, show, setShow, setCheckedList } = props;

  return (
    <Offcanvas
      show={show}
      onHide={() => {
        setShow(false);
        setCheckedList([]);
      }}
      className="sidebar narrower-panel"
      placement="end"
      backdrop={false}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="semi-bold" as="h2">
          {approval_group.name}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="admin-table mb-3">
          <Row className="table-header d-none d-sm-flex">
            <Col sm={5}>
              User
            </Col>
            <Col sm={6}>
              Role
            </Col>
          </Row>
          {_.map(approval_group.approvers, (approver: UserProfile, i: number) => {
            return (
              <Row className="table-cell" key={i}>
                <Col sm={5}>
                  <label>User: </label>
                  {approver.name}
                </Col>
                <Col sm={6} className="with_cap">
                  <label>Role: </label>
                  {approver.role}
                </Col>
              </Row>
            );
          })}
        </div>
        <h4>Needed for approval: <b>{approval_group.required_approvers}</b></h4>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default React.memo(ApprovalGroupSidePanelComponent);
