import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { Icon } from "src/components";
import { DefaultRoutes, PublicRoutes, QueryParamKeys, Account, AppConfiguration, useAppSelector } from "src/common";
import _ from "lodash";

type AllProps = {
  current_account?: Account;
  accounts?: Account[];
  active: boolean;
}

const AccountLink = (props: { account: Account }) => {
  const account = props.account;
  return <Link
    to={`/${PublicRoutes.login_route}?${QueryParamKeys.USER_ID_QUERY_KEY}=${encodeURIComponent(account.user_id)}`}
    className="dropdown-item"
    target="_blank"
    rel="noopener noreferrer">
    {account.display_name}
    <small>{account.user_id}</small>
  </Link>;
};

function AccountDropdown(props: AllProps) {
  const { current_account, accounts } = props;
  const isWebBuild: boolean = AppConfiguration.buildForWeb();
  const version = useAppSelector((state) => state.app.version);
  const organization = useAppSelector((state) => state.account.organization);
  const dropdownRef = useRef(null);

  // Description: build the Account specific for build mode: Web, Express
  function WebDropdown() {
    return <>
      <Link className="dropdown-item light" to={`/${PublicRoutes.logout_route}`} reloadDocument>
        <Icon className="ficon-log-out" /> Logout</Link>
      {
        AppConfiguration.buildForExpress() &&
        <Link className="dropdown-item light" to={`/${PublicRoutes.upgrade_information_route}`} title="Go to PreVeil Upgrade page to learn more">
          <Icon className="ficon-star" />Upgrade PreVeil
        </Link>
      }
    </>;
  }

  return <Dropdown className="nav-item account-menu" ref={dropdownRef}>
    <Dropdown.Toggle as="a"
      className={`${!current_account ? " choose" : ""}`} title="My Accounts">
      <Icon className="pv-icon-contact show-desktop" />
      <span className="hide-desktop">Account</span>
    </Dropdown.Toggle>
    <Dropdown.Menu align="end" >
      {
        !!current_account &&
        <Dropdown.Item className="active cursor-default">
          <Icon className="pv-icon-contact me-0" />
          {current_account.display_name || "My Account"}
          <small>{!!organization && !!organization.org_name ? organization.org_name : current_account.user_id}</small>
        </Dropdown.Item>
      }
      {!!accounts &&
        _.map(accounts, (account: Account, i: number) => {
          return !_.isEqual(account, current_account) && <AccountLink key={`account_${i}`} account={account} />;
        })
      }
      {
        isWebBuild ? <WebDropdown /> :
          <Link className="dropdown-item light" to={`/${PublicRoutes.get_started_route}`} reloadDocument>
            <Icon className="pv-icon-add-account" />Add Account
          </Link>
      }
      <Dropdown.Item href={`${DefaultRoutes.remote_webapp_url}`} target="_blank" title="Go to PreVeil Website">
        <Icon className="pv-icon-open" />PreVeil Website
      </Dropdown.Item>
      {
        !!version &&
        <Dropdown.Item title="PreVeil Version">
          <Icon className="pv-icon-key-small" />Build {version}
        </Dropdown.Item>
      }
    </Dropdown.Menu>
  </Dropdown >;
}

export default React.memo(AccountDropdown);
