import { OSInfo, UserProfile, CollectionServerUser } from "@preveil-api";
import { dayjs, AdminErrorMessages, MessageAnchors, Account, SupportedPlatform, DRIVE_INVALID_CHARACTERS } from "src/common";
import _ from "lodash";

type CreateExportRequest = ExportRequest & {
    request_payload?: string;
    drop_dir: string;
}

export type ExportRequest = {
  collection_id?: string;
  group_id?: string;
  group_version?: string;
  request_id?: string;
  requester?: string;
  payload?: string;
  type?: string;
  started?: string | boolean | undefined;
  status?: string;
  timestamp?: string;
  users?: Account[]
  expiration?: string | number | undefined;
  export_content?: {
    from?: string;
    include_emails?: boolean;
    include_files?: boolean;
    include_logs?: boolean;
    include_orgs_acl_report?: boolean;
    until?: string;
  };
  for_users?: Array<{
    name?: string;
    address?: string;
  }>;
  metadata?: {
    from?: string;
    include_emails?: boolean;
    include_files?: boolean;
    include_logs?: boolean;
    include_orgs_acl_report?: boolean;
    include_user_acl_report?: boolean;
    until?: string;
    users?: Account[];
  };
  total_rows?: number;
  raw_request?: string;
  requester_id?: string;
  approvedUsers?: any;
};

export function createExportRequest(request: CreateExportRequest, account: Account, raw_request: string, status: string, current_users_approved: UserProfile[], approvers_group: UserProfile[]) {
    const { request_id, drop_dir } = request;
    const parsed_payload = JSON.parse(raw_request);
    const { data, type, timestamp, expiration, requester_id } = parsed_payload;
    const { from, until, users, include_emails: include_email, include_files: include_drive, include_logs, include_org_acl_report } = data;
    return {
      request_id,
      status,
      requester: account.display_name,
      approval_group: approvers_group,
      timestamp,
      type,
      from: dayjs.utc(from).local().format("l"),
      until: dayjs.utc(until).local().format("l"),
      export_content: {
        include_email,
        include_drive,
        include_logs,
        include_org_acl_report
      },
      expiration: dayjs.utc(expiration).utcOffset(new Date().getTimezoneOffset(), true).local().format("lll"),
      drop_dir,
      raw_request,
      requester_id,
      approvedUsers: current_users_approved,
      users
    };
  }

  export function parseExportRequest(request: ExportRequest | null, group_users: CollectionServerUser[], group_approvers?: UserProfile[], account?: Account) {
    const contentLabels = {
      users: "All Users",
      content: {
        emails: "Email",
        email: "Email",
        mail: "Email",
        files: "Drive",
        drive: "Drive",
        logs: "Logs",
        acl_report: "ACL Report",
        org_acl_report: "ACL Report"
      },
      date: "Forever"
    };
    let response = {};
    if (!request ) return null;
    if (request.status === "pending") {
      // TODO: The two cases below (if/ if else) look very similar. Maybe we can combine them into one.
      // Looks like it can be done.
      const { requester, expiration, metadata } = request;
      const { users } = metadata as any; // TODO: issue with metadata missing in some cases.
      const content = metadata && Object.keys(metadata).filter((key) => (metadata as any)[key]).map((key) => {
        const _key = key.split("include_")[1];
        return (contentLabels.content as any)[_key];
      }).filter((value) => !!value).join(" & ");
      const _users = users && _.compact(users.map((user: Account) => {
        return group_users.filter((user_profile: CollectionServerUser) => user_profile.user_id === user.user_id)[0];
      }));
      const requester_name = group_approvers && group_approvers.filter((approver) => approver.address === requester)[0]?.name || account?.display_name;
      response = {
        requesterName: requester_name,
        expirationTime: dayjs.utc(expiration).utcOffset(new Date().getTimezoneOffset(), true).local().format("lll"),
        timeframe: `${dayjs(request.metadata?.from).format("MM/DD/YYYY")} - ${dayjs(request.metadata?.until).format("MM/DD/YYYY")}`,
        content,
        approvers: group_approvers,
        users: _users
      };
    } else if (request.status === "init" || request.status === "cancelled") {
        const { requester, expiration, export_content, users, approvedUsers, raw_request } = request;
        const content = export_content && Object.keys(export_content).filter((key) => (export_content as any)[key]).map((key) => {
            const _key = key.split("include_")[1];
            return (contentLabels.content as any)[_key];
          }).filter((value) => !!value).join(" & ");
        const _users = users && _.compact(users.map((user: Account) => {
          return group_users.filter((user_profile: CollectionServerUser ) => user_profile.user_id === user.user_id)[0];
        }));
        const data = raw_request && JSON.parse(raw_request).data;
        response = {
            requesterName: requester,
            expirationTime: expiration,
            timeframe: `${dayjs(data?.from).format("MM/DD/YYYY")} - ${dayjs(data?.until).format("MM/DD/YYYY")}`,
            content,
            approvedUsers,
            users: _users
        };
    }
    return response;
  }

  // Parse the error response from start data export
  // and return the appropriate error message.
  export function parseDataExportErrorMessage (dataExportError: any) {
    let message = AdminErrorMessages.error_start_data_export;
    try {
      const { errors } = dataExportError;
      const errorCause = errors[0].cause;
      if (errorCause.includes("file exists")) {
        message = AdminErrorMessages.error_export_file_exists;
      } else if (errorCause.includes("permission denied")) {
        message = AdminErrorMessages.error_export_permission_denied;
      } else if (errorCause.includes("no such file or directory")) {
        try {
          const entered_path = /\/.*\//.exec(errorCause)?.[0];
          if (entered_path) {
            message = AdminErrorMessages.error_export_entered_path_no_found.replace(MessageAnchors.message_content, entered_path);
          } else {
            message = AdminErrorMessages.error_export_path_not_found;
          }
        } catch (error) {
          message = AdminErrorMessages.error_export_path_not_found;
        }
      }
    } catch (error) {
      return message;
    }
    return message;
  }

  // We need to verify the export path before starting the export
  // no illegal characters or spaces are allowed in the path.
  // If the path is invalid, return null.
  export function preVerifyStartExport(file_location: string, os_info?: OSInfo | null | undefined) {
    let new_folder: string;
    if (os_info?.user_platform === SupportedPlatform.WIN) {
      new_folder = file_location.replace(/.*\\/, "");
    } else {
      new_folder = file_location.replace(/.*\//, "");
    }

    if (/\s/.test(new_folder) || DRIVE_INVALID_CHARACTERS.find(exception => new_folder.includes(exception))) {
      return null;
    }

    return file_location.toString().trim();
  }