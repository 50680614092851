// import * as React from "react";
import React, { useState, useEffect } from "react";
import VerificationInput from "./VerificationInput.component";
import { isNumber } from "src/common";
import _ from "lodash";
type AllProps = {
  count: number;
  handleAction: (code: string) => void;
}

function VerificationCodeComponent(props: AllProps) {
  const { count, handleAction } = props;
  const [focus, setFocus] = useState(0);
  const [secret, setSecret] = useState<string[]>(new Array<string>());

  // Description: Handle Copy & Paste into the UI. Validate code blur add to secret array and blur to button
  function handlePasteAnywhere(event: any) {
    const code = event.clipboardData.getData("text").trim();
    const arr = code.split("");
    if (arr.length <= count) {
      const _secret = _.map(arr, (char: string) => {
        return isNumber(char) ? char : "";
      });
      if (!!_secret) {
        setSecret(_secret);
        // removes unnecessary characters
        const valid_secret = _.without(_secret, undefined, null, "");
        if (valid_secret.length === count) {
          setFocus(count - 1);
          handleAction(valid_secret.join(""));
        };
      }
    }
  };

  // Description: handle Copy & Paste of code
  useEffect(() => {
    document.addEventListener("paste", handlePasteAnywhere);
    return () => {
      document.removeEventListener("paste", handlePasteAnywhere);
    };
  }, []);

  // Description: Build the Secret code and propagate up
  function handleOnChange(code: string, position: number, new_position: number) {
    const _secret = secret.slice();
    const index = position - 1;
    if (index !== -1) {
      _secret[index] = code;
    }
    setSecret(_secret);
    setFocus(new_position);
    const valid_secret = _.without(_secret, undefined, null, ""); // removes unnecessary characters
    handleAction(valid_secret.join(""));

  }

  function Inputs() {
    const inputs = [];
    for (let i = 0; i < count; i++) {
      const position = i + 1;
      const input_id = `secret_${position}`;
      inputs.push(<VerificationInput
        key={`secret_${position}`}
        value={secret[i] || ""}
        position={position}
        autoFocus={focus === i}
        input_id={input_id}
        handleAction={handleOnChange} />);
    }
    return <div className="verification-code no-wrap">{inputs}</div>;
  }

  return <Inputs />;
}

export default React.memo(VerificationCodeComponent);
