import React from "react";
import { Row, Col, Button, Toast, ToastContainer } from "react-bootstrap";
import { useAppDispatch, Message, MessageToastTypes, MessageHandlerDisplayType } from "src/common";
import { uiActions } from "src/store";
function ToastrsComponent() {
  const dispatch = useAppDispatch();
  const Toastrs = () => {
    return (
      <>
        <h4>Toastrs</h4>
        <>
          <div className="btn-panel mt-5">
            <Button variant="danger"
              onClick={() => {
                dispatch(uiActions.handleRequestErrors(new Message("I AM A CUSTOM ERROR MESSAGE"), { error: "CRAZY STACK HERE" }));
              }}>Error Message</Button>

            <Button variant="success" onClick={() => {
              dispatch(uiActions.handleSetMessage(new Message("I AM A Success MESSAGE")));
            }}>Default Success Message</Button>

            <Button variant="warning" onClick={() => {
              dispatch(uiActions.handleSetMessage(new Message("I AM A Warning MESSAGE", MessageHandlerDisplayType.toastr, MessageToastTypes.warning)));
            }}>Warning Message</Button>

            <Button variant="info" onClick={() => {
              dispatch(uiActions.handleSetMessage(new Message("I AM A INFO MESSAGE", MessageHandlerDisplayType.toastr, MessageToastTypes.info)));
            }}>Info Message</Button>

            <Button variant="primary" onClick={() => {
              dispatch(uiActions.handleSetMessage(new Message("I AM A Primary MESSAGE", MessageHandlerDisplayType.toastr, MessageToastTypes.primary)));
            }}>Primary Message</Button>
          </div>
        </>

        <h4 className="mt-4 border-top-1">Toasts Messaging</h4>
        <p>
          'top-start' | 'top-center' | 'top-end' | 'middle-start' | 'middle-center' | 'middle-end' | 'bottom-start' | 'bottom-center' | 'bottom-end';
        </p>
        <Row>
          <Col>
            <ToastContainer position="middle-start">
              <Toast
                bg="success"
                show={true}
                onClose={console.log}>
                <Toast.Body>
                  <strong>Title success</strong>
                  <span>When PreVeil Drive folders are shared with others.message</span>
                  <Button className="btn-close" aria-label="Close" onClick={close}></Button>
                </Toast.Body>
              </Toast></ToastContainer>

            <ToastContainer position="bottom-end">
              <Toast
                bg="primary"
                show={true}
                onClose={console.log}>
                <Toast.Body>
                  <strong>Title primary</strong>
                  <span>When PreVeil Drive folders are shared with others.message</span>
                  <Button className="btn-close" aria-label="Close" onClick={close}></Button>
                </Toast.Body>
              </Toast></ToastContainer>

            <ToastContainer position="middle-end">
              <Toast
                bg="warning"
                show={true}
                onClose={console.log}>
                <Toast.Body>
                  <strong>Title warning</strong>
                  <span>When PreVeil Drive folders are shared with others.message</span>
                  <Button className="btn-close" aria-label="Close" onClick={close}></Button>
                </Toast.Body>
              </Toast></ToastContainer>

            <ToastContainer position="bottom-start">
              <Toast
                bg="danger"
                show={true}
                onClose={console.log}>
                <Toast.Body>
                  <strong>Title danger</strong>
                  <span>When PreVeil Drive folders are shared with others.message</span>
                  <Button className="btn-close" aria-label="Close" onClick={close}></Button>
                </Toast.Body>
              </Toast></ToastContainer>
          </Col>
        </Row>
      </>
    );
  };

  return <Toastrs />;
};

export default React.memo(ToastrsComponent);
