import React, { useEffect, useState, useRef } from "react";
import { ToastContainer, Toast } from "react-bootstrap";
import { useAppSelector } from "src/common";

function DirtyWarning() {
  const is_dirty = useAppSelector((state) => state.ui.is_dirty);
  const [show, setShow] = useState(is_dirty);
  const is_dirty_ref = useRef<boolean>(is_dirty);

  // First onload event
  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  // Description: update ref so that the modal opens
  useEffect(() => {
    is_dirty_ref.current = is_dirty;
    setShow(is_dirty);
  }, [is_dirty]);

  // Description: Block user from leaving
  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    if (is_dirty_ref.current) {
      event.preventDefault();
      event.returnValue = true;
    }
  };

  return <ToastContainer
    className="dirty-warning"
    position="bottom-start" >
    <Toast bg="warning" show={show} onClose={() => setShow(false)}>
      <Toast.Header>Leave Browser Open</Toast.Header>
      <Toast.Body>
        Reloading your browser may result in cancelling your process and corrupting your data.
      </Toast.Body>
    </Toast>
  </ToastContainer>;
}

export default React.memo(DirtyWarning);
