import { useState, useCallback } from "react";
import { MessageDisplayType, ErrorStackDataType } from "@preveil-api";
import {
    Account, useAppSelector, CollectionFilesyncAPI, DriveRequest, useSendRequestMutation, DriveErrorMessages, useAppDispatch,
    MessageHandlerDisplayType, Message, PermissionSetType, getEnumKey, ErrorStackItem, LinkEntity, COLLECTION_PROTOCOL_VERSIONS,
} from "src/common";
import { FSMessage, FSStatus } from "src/common/keys/protos/collections_pb";
import { RootState } from "src/store/configureStore";
import { driveActions, uiActions, DriveCallbackAsyncFunction } from "src/store";
import _ from "lodash";

// Description: Web hook to fetch the node ID for a link (v1 or v2)
export function useFetchLinkItem(current_account: Account, set_state: boolean = false) {
    const default_permissions = useAppSelector((state: RootState) => state.drive.default_permissions);
    const default_collection_id = useAppSelector((state: RootState) => state.drive.root_info?.collection_id);
    const [error, setError] = useState<boolean>(false);
    const [link_entity, setLinkEntity] = useState<LinkEntity>();
    const [sendRequest] = useSendRequestMutation();
    const dispatch = useAppDispatch();

    // Description: Find a link entity from the defaultCollection id and the collection id
    async function findLink(collection_id: string) {
        const _permissions = _.find(default_permissions, (set: PermissionSetType) => default_collection_id === set.collection_id.B64());
        const drive_request = (!!_permissions && !!default_collection_id) ? await CollectionFilesyncAPI.findLinkRequest(current_account, _permissions, default_collection_id, collection_id) : null;
        async function callback(message: FSMessage) {
            if (message.getStatus() === FSStatus.OK) {
                const fs_links = message.getLinks()?.getLinksList();
                const fs_link = !!fs_links && fs_links.length > 0 ? _.find(fs_links, (link: FSMessage.Link) => link.getLinkedCollectionId_asB64() === collection_id) : null;
                if (!!fs_link) {
                    const _link_entity: LinkEntity = {
                        id: fs_link.getId_asB64(),
                        collection_id: default_collection_id || collection_id,
                        collection_protocol_version: fs_link.getCollectionProtocolVersion() || COLLECTION_PROTOCOL_VERSIONS.V2,
                        version: fs_link.getVersion_asB64(),
                        deleted: fs_link.getIsDeleted(),
                        linked_collection_id: fs_link.getLinkedCollectionId_asB64(),
                        is_expunge: fs_link.getIsExpunged(),
                        last_modified_device_id: fs_link.getLastModifiedDeviceId_asB64(),
                        parent_id: fs_link.getParentId_asB64(),
                        // Working in https://preveil.atlassian.net/browse/BACK-1102
                        // name: getName_asB64()
                        // key_version = fs_link.getKeyVersion();
                    };
                    set_state && dispatch(driveActions.setLinkEntity(_link_entity));
                    setLinkEntity(_link_entity);
                } else {
                    handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_link_entity }, message);
                }
            } else {
                handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_link_entity }, message);
            }
        }
        handleSendRequest(drive_request, callback, DriveErrorMessages.error_fetching_link_entity);
    }

    // -----------------------------
    // HOOK General
    // -----------------------------
    // Description: Sends Request and handles error when the request object is null, pass it custom stack message
    function handleSendRequest<T>(request: DriveRequest | null, callback: DriveCallbackAsyncFunction<T>,
        stack_error: string = DriveErrorMessages.error_sending_request, user_message: string = DriveErrorMessages.default) {
        if (!!request) {
            request.setCallback(callback);
            sendRequest(request);
        } else {
            handlePageErrorMessage(user_message, { stack_error, stack_message: DriveErrorMessages.error_sending_request }, null, MessageHandlerDisplayType.toastr);
        }
    }

    // Description: Handle error message and send error to store
    function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, fsmessage?: FSMessage | null,
        display_type: MessageDisplayType = MessageHandlerDisplayType.logger) {
        const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
        const stack = new ErrorStackItem("[useFetchLinkItem Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
        dispatch(uiActions.handleRequestErrors(new Message(message, display_type), stack));
        setError(true);
    }

    // Description: Callback for fetching link node id - takes in linked_collection_id and the protocol version
    const fetchLinkEntity = useCallback((linked_collection_id: string) => {
        findLink(linked_collection_id);
    }, []);

    // Description: reset hook 
    const resetLinkEntity = useCallback(() => {
        setError(false);
        setLinkEntity(undefined);
        set_state && dispatch(driveActions.setLinkEntity(undefined));
    }, []);

    return {
        link_entity,
        fetchLinkEntity,
        resetLinkEntity,
        error
    };
}
