
/*
* Description: Local install Filesync websocket Notifications
* Author:      PreVeil, LLC
*/
import { AsyncJob } from "src/common/keys/protos/async_job_pb";

export type AsyncJobStatus = AsyncJob.StatusMap[keyof AsyncJob.StatusMap];
export type AsyncJobType = AsyncJob.TypeMap[keyof AsyncJob.TypeMap];
export const AsyncJobTypeMap = AsyncJob.Type;
export const AsyncJobStatusMap = AsyncJob.Status;
export interface FilesyncLocalNotification {
  user_id: string;
  request_id: string;
  type: AsyncJobType;
  status?: AsyncJobStatus;
  percentage?: number;
  error_message?: string;
}

export type FilesyncLocalNotificationsMap = Record<string, FilesyncLocalNotification>;

export class FilesyncLocalNotifications implements FilesyncLocalNotification {
  user_id: string;
  request_id: string;
  type: AsyncJobType;
  status?: AsyncJobStatus;
  percentage?: number;
  error_message?: string;

  constructor(public job: AsyncJob) {
    this.user_id = job.getUserid() || "";
    this.request_id = job.getRequestid() || "";
    this.type = job.getJobType() || AsyncJobTypeMap.UNKNOWN;
    this.status = job.getStatus();
    this.error_message = job.getErrorMessage();
  }

  // Description: Flatten and return the notification by event
  get info(): FilesyncLocalNotification | null {
    return this.status !== undefined ? {
      user_id: this.user_id,
      request_id: this.request_id,
      type: this.type,
      status: this.status,
      percentage: this.percentage,
      error_message: this.error_message,
    } : null;
  }
}
