import * as React from "react";
import { Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { AccountUIActionTypes } from "src/common";

type AllProps = {
  handleAction: ActionHandlerFunction;
}

function ErrorPanelComponent(props: AllProps) {
  const { handleAction } = props;

  return <>
    <h1 className="text-orange border-bottom pb-3">Transfer Failed</h1>
    <h3>Lost secure connection: Please try again</h3>
    <Button onClick={() => handleAction({ actionType: AccountUIActionTypes.Destroy })}>Retry</Button>
  </>;
}
export default React.memo(ErrorPanelComponent);
