import React from "react";
import { Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { AdminUIActionTypes, DataExportRequestStatus } from "src/common";

type AllProps = {
  handleActions: ActionHandlerFunction;
  step: keyof typeof DataExportRequestStatus;
  approved_users: number;
  required_approvers?: number;
};

const buttonState = {
  complete: DataExportRequestStatus.complete,
  started: DataExportRequestStatus.started,
  running: DataExportRequestStatus.running
} as const;

function DataExportHeader({ handleActions, step, approved_users, required_approvers }: AllProps) {
  const disableStartExport = (approved_users !== required_approvers || !!buttonState[step as keyof typeof buttonState]) && step !== DataExportRequestStatus.cancelled;
  const showCancel = !buttonState[step as keyof typeof buttonState]; // step is not equal to complete, started, or running.
  const showStopExport = step === DataExportRequestStatus.started || step === DataExportRequestStatus.running;
  return (
    <div className="d-flex align-items-center justify-content-between">
      <h1 className="m-0">Data Export</h1>
      <div>
        {step !== DataExportRequestStatus.not_init && step !== DataExportRequestStatus.not_started && 
          <>
            <Button disabled={disableStartExport} onClick={() => handleActions({ actionType: AdminUIActionTypes.ShowModal })}>Start Export</Button>
            {showCancel && (
              <Button
                variant="no-outline-primary"
                onClick={() => handleActions({ actionType: AdminUIActionTypes.Cancel })}
              >Cancel</Button>
            )}
            {showStopExport && (
              <Button
                variant="no-outline-primary"
                onClick={() => handleActions({ actionType: AdminUIActionTypes.StopDataExportRunning })}
              >Stop Export</Button>
            )}
            {step === DataExportRequestStatus.complete &&
              <Button
                variant="no-outline-primary"
                onClick={() => handleActions({ actionType: AdminUIActionTypes.Finish })}
              >Finish</Button>
            }
          </>
        }
      </div>
    </div>
  );
}

export default React.memo(DataExportHeader);