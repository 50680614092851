import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { RecoveryCodeInfo } from "@preveil-api";
import {
  Message,
  MessageHandlerDisplayType,
  SettingsErrorMessages,
  useAppDispatch,
  collectionApi,
  useAppSelector,
  Account,
  GetUsersBackupApiResponse,
} from "src/common";
import { Loading, PageHeader } from "src/components";
import { uiActions } from "src/store";
import { EmptyBackup, ExistingBackup, PDFGenerator } from ".";

function AccountBackupComponent() {
  const [backup_id, setBackupId] = useState<string | undefined>(undefined);
  const [download_recovery_code, setDownloadRecoveryCode] = useState<RecoveryCodeInfo | undefined>(undefined);
  const current_account = useAppSelector((state) => state.account.current_account);
  const dispatch = useAppDispatch();
  const [getUsersBackup] = collectionApi.endpoints.getUsersBackup.useLazyQuery();
  const [is_loading, setIsLoading] = useState<boolean>(true);

  /* Description: On Initial Load - get account backup */
  useEffect(() => {
    getBackup();
  }, []);

  /* Description: Gets the users current backup (if any) and sets it in the store. This will determine whether
  to display the empty backup component or the existing backup component. */
  function getBackup() {
    if (!!current_account) {
      setIsLoading(true);
      const account_ids = Account.getAccountIdentifiers(current_account);
      const params = {
        account_ids,
        body: {
          user_id: account_ids.user_id,
        },
      };
      getUsersBackup(params)
        .unwrap()
        .then((response: GetUsersBackupApiResponse) => {
          setBackupId(response.backup_id);
          setIsLoading(false);
        })
        .catch(stack => {
          setIsLoading(false);
          dispatch(uiActions.handleRequestErrors(new Message(SettingsErrorMessages.error_getting_backup, MessageHandlerDisplayType.logger), stack));
        });
    }
  };

  return (
    <>
      <PageHeader>
        <h1 className="content-header">Account Recovery</h1>
      </PageHeader>
      {
        <Card className="pv-card">
          {
            is_loading ?
              <Loading className="in-place" /> :
              !!backup_id ?
                <ExistingBackup backup_id={backup_id} setBackupId={setBackupId} /> :
                <EmptyBackup
                  setIsLoading={setIsLoading}
                  setDownloadRecoveryCode={setDownloadRecoveryCode}
                />
          }
        </Card>
      }
      {/* {!is_loading ? (
        <>
          {!!backup_id ? (
            <Card className="card-section">
              <ExistingBackup backup_id={backup_id} setBackupId={setBackupId} />
            </Card>
          ) : (
            <EmptyBackup
              setIsLoading={setIsLoading}
              setDownloadRecoveryCode={setDownloadRecoveryCode}
            />
          )}
        </>
      ) : (
        <Loading />
      )} */}
      {
        !!download_recovery_code &&
        !!current_account &&
        <PDFGenerator
          user_id={current_account.user_id}
          recovery_code={download_recovery_code}
          getBackup={getBackup}
          setDownloadRecoveryCode={setDownloadRecoveryCode}
        />
      }
    </>
  );
}

export default React.memo(AccountBackupComponent);
