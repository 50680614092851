import React, { useState } from "react";
import { Breadcrumb, Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ActionHandlerFunction, PaginationItem, Sort } from "@preveil-api";
import { ApprovalGroup, RecoveryGroup, RequestTab, SettingsUIActionTypes, UserRequest } from "src/common";
import { PageHeader } from "src/components";
import { ApprovalRequestsTabContent } from ".";
import _ from "lodash";

type AllProps = {
  header_navigate_list?: Array<{ path: string; display: string }>;
  handleAction: ActionHandlerFunction;
  is_approval: boolean;
  approval_requests: UserRequest[];
  history_requests: UserRequest[];
  current_sort: Sort<keyof UserRequest>;
  loading: boolean;
  can_select: boolean;
  pagination: PaginationItem;
  is_details_loading: boolean;
  get_responses: boolean;
  current_recovery_group?: RecoveryGroup | ApprovalGroup;
};

/* Description: Parent Wrapper for the approval requests component - this is a shared component
as it is used in Settings Approvals, Settings User Requests and Admin Approval Requests. */
function ApprovalRequestsParentComponent(props: AllProps) {
  const navigate = useNavigate();
  const {
    header_navigate_list,
    handleAction,
    is_approval,
    approval_requests,
    history_requests,
    current_sort,
    loading,
    can_select,
    pagination,
    is_details_loading,
    get_responses,
    current_recovery_group
  } = props;
  const [key, setKey] = useState<string | null>(RequestTab.pending);

  return (
    <>
      {!!header_navigate_list ? (
        <Breadcrumb>
          <>
            {_.map(header_navigate_list, (x: { path: string; display: string }, i: number) => {
              return (
                <Breadcrumb.Item key={i} onClick={() => navigate(x.path)} href="">
                  {x.display}
                </Breadcrumb.Item>
              );
            })}
          </>
        </Breadcrumb>
      ) : null}
      <PageHeader>
        <h1>Approval Requests</h1>
      </PageHeader>
      <Tabs
        defaultActiveKey={key || "pending"}
        id="approval-requests-tab"
        onSelect={(k) => {
          setKey(k);
          handleAction({
            actionType: SettingsUIActionTypes.Refresh,
            params: k,
          });
        }}
      >
        <Tab eventKey="pending" title="Pending" className="settings-height">
          <ApprovalRequestsTabContent
            tab={RequestTab.pending}
            handleAction={handleAction}
            is_approval={is_approval}
            requests={approval_requests}
            current_sort={current_sort}
            loading={loading}
            can_select={can_select}
            is_details_loading={is_details_loading}
            pagination={pagination}
            get_responses={get_responses}
            current_recovery_group={current_recovery_group}
          ></ApprovalRequestsTabContent>
        </Tab>
        <Tab eventKey="history" title="History" className="settings-height">
          <ApprovalRequestsTabContent
            tab={RequestTab.history}
            handleAction={handleAction}
            is_approval={is_approval}
            requests={history_requests}
            current_sort={current_sort}
            loading={loading}
            can_select={false}
            pagination={pagination}
            is_details_loading={false}
            get_responses={false}
            current_recovery_group={current_recovery_group}
          ></ApprovalRequestsTabContent>
        </Tab>
      </Tabs>
    </>
  );
}

export default React.memo(ApprovalRequestsParentComponent);
