export abstract class Entity {
  protected _uid: string;

  constructor(uid: string) {
    this._uid = uid;
  }

  public get uid(): string {
    return this._uid;
  }
}
