import React from "react";
import { RegexHelper, EmailGatewayEntity, EmailGateway } from "src/common";
import { AddressListRow } from "src/components";
import keyMirror from "keymirror";
import _ from "lodash";

type ErrorState = "duplicate" | "duplicate_alias" | "invalid" | "non_org_user";
type ValidationState = ErrorState | "valid_trusted" | "valid_gateway" | "valid_group";

type AddressListModalPanelProps = {
  validation_state: ValidationState;
  collection: string[] | EmailGateway[];
  handleDeleteAction: (id: string | undefined) => void;
  optional_name_collection?: Map<string, string> | undefined;
}

const statusHeading = {
  duplicate: "Duplicate addresses",
  duplicate_alias: "Duplicate aliases",
  non_org_user: "Users are not active members of the organization",
  invalid: "Invalid format",
  valid_trusted: "New aliases to be added to Trusted Community.",
  valid_gateway: "New aliases to be added to Email Gateway.",
  valid_group: "New addresses to be added to Admin Group."
};

const error_state_object = keyMirror({
  duplicate: null,
  duplicate_alias: null,
  invalid: null,
  non_org_user: null
});


function AddressListModalPanel({ validation_state, collection, handleDeleteAction, optional_name_collection }: AddressListModalPanelProps) {
  let heading = "";
  const headingText = statusHeading[validation_state];
  const invalidColor = (validation_state in error_state_object) ? "invalid" : "";
  const totalAddresses = collection.length;
  switch (validation_state) {
    case "valid_trusted":
    case "valid_gateway":
    case "duplicate_alias":
      heading = totalAddresses === 1 ? headingText.replace("aliases", "alias") : headingText;
      break;
    default:
      heading = totalAddresses === 1 ? headingText.replace("addresses", "address") : headingText;
  }
  return (
    <section className="csv-upload-card">
      <div>
        <header className="d-flex align-items-center pt-2 pb-2">
          <h5 className="m-0">{`${totalAddresses} ${heading}`}</h5>
        </header>
        <div>
          {collection.map((param: string | EmailGateway, index: number) => {
            if (param instanceof EmailGatewayEntity) {
              const formattedAddress = param.gatewayAddressFormat();
              const { id, address, display_name, type } = formattedAddress;
              const icon = type === "individual" ? "ficon-user" : "ficon-globe";
              const subTitle = type === "individual" ? display_name : undefined;
              return (
                <AddressListRow
                  key={id}
                  sub_title={subTitle}
                  icon={icon}
                  id={address}
                  handleDeleteAction={handleDeleteAction}
                  selectable={false}
                  validation_state={validation_state}
                />
              );
            }

            const name = optional_name_collection && optional_name_collection.get(param as string) || "";
            const address = param as string;
            const key = _.uniqueId(address);
            const icon = validation_state === "invalid" ? "ficon-x-circle" : RegexHelper.testEmailAddress(address) ? "ficon-user" : "ficon-globe";
            return (
              <AddressListRow
                key={key}
                sub_title={name}
                icon={icon}
                id={address}
                handleDeleteAction={handleDeleteAction}
                selectable={false}
                validation_state={validation_state}
                color={invalidColor}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default React.memo(AddressListModalPanel);
