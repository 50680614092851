import { useEffect, useState } from "react";
import {
  usePostUsersFindMutation, AccountIdentifiers, UserRequest, ExpressSubsumeErrorMessages, Message,
  MessageHandlerDisplayType, RequestTypes, collectionApi, GetUserApprovalsApiResponse
} from "src/common";
import { JSONOrgInfo, SubsumeRequest } from "@preveil-api";

export function useGetSubsumeApproval(account_ids: AccountIdentifiers, request_id: string) {
  const [request, setRequest] = useState<UserRequest>();
  const [error, setError] = useState<{ message: Message, dismiss: boolean }>();
  const [getUsersApprovals] = collectionApi.endpoints.getUsersApprovals.useLazyQuery();
  const [getUsersOrg] = collectionApi.endpoints.getUsersOrgsByEntityId.useLazyQuery();
  const [findUsers] = usePostUsersFindMutation();

  useEffect(() => {
    handleGetUsersApprovals(request_id);
  }, []);

  function handleGetUsersApprovals(request_id: string) {
    getUsersApprovals({
      account_ids,
      body: { user_id: account_ids.user_id, hide_expired: false }
    }).unwrap()
      .then((response: GetUserApprovalsApiResponse) => {
        const approval = response.approvals.find(approval => approval.request_id === request_id);
        if (!approval) {
          return setError({
            message: new Message(ExpressSubsumeErrorMessages.error_locating_approval),
            dismiss: true
          });
        }
        const user_request = new UserRequest(request_id, null, approval.payload, approval.response);
        if (user_request.type !== RequestTypes.subsume_account) {
          return setError({
            message: new Message(ExpressSubsumeErrorMessages.unsupported_request_type),
            dismiss: true
          });
        }
        handlePostUsersFind(user_request);
      })
      .catch(_=> {
        setError({
          message: new Message(ExpressSubsumeErrorMessages.error_fetching_approvals),
          dismiss: true
        });
      });
  }

  function handlePostUsersFind(user_request: UserRequest) {
    const requester_id = user_request.requester_id;
    findUsers({
      account_ids,
      body: { spec: [{ user_id: requester_id, key_version: -1 }] },
    }).unwrap()
      .then(({ users }) => {
        const requester = users.find(user => user.user_id.toLowerCase() === requester_id.toLowerCase());
        const requester_profile = !!requester && { address: requester.user_id, name: requester.display_name };
        user_request.requester = requester_profile || { address: requester_id, name: requester_id };
        handleGetUsersOrg(user_request);
        !requester_profile && setError({
          message: new Message(ExpressSubsumeErrorMessages.error_fetching_requester_info, MessageHandlerDisplayType.logger),
          dismiss: false
        });
      })
      .catch(_ => {
        user_request.requester = { address: requester_id, name: requester_id };
        handleGetUsersOrg(user_request);
        setError({
          message: new Message(ExpressSubsumeErrorMessages.error_fetching_requester_info, MessageHandlerDisplayType.logger),
          dismiss: false
        });
      });
  }

  function handleGetUsersOrg(user_request: UserRequest) {
    const request_data = user_request.parsed_payload.data as SubsumeRequest;
    const entity_id = request_data.entity_id;
    if (!entity_id) {
      return setError({
        message: new Message(ExpressSubsumeErrorMessages.error_fetching_org_info),
        dismiss: true
      });
    }
    getUsersOrg({
      account_ids,
      body: { entity_id }
    }).unwrap()
      .then((response: JSONOrgInfo) => {
        user_request.org_details = { loaded: true, org_id: entity_id, org_name: response.display_name, roled_approval_groups: response.roled_approval_groups };
        setRequest(user_request);
      })
      .catch(_ => {
        setError({
          message: new Message(ExpressSubsumeErrorMessages.error_fetching_org_info),
          dismiss: true
        });
      });
  }

  return {
    request,
    error
  };
}
