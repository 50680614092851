import { AxiosRequestConfig } from "axios";
import { IFetchResponse } from "@preveil-api";
type RequestParams = {
    url: string,
    data?: unknown,
    header?: Record<string, string>,
    _config?: AxiosRequestConfig
}
type Request = (url: string, data?: unknown, header?: Record<string, string>, _config?: AxiosRequestConfig) => Promise<IFetchResponse>
const CONCURRENT_CALLS_MAX = 1000;

export class DynamicQueue {
    private readonly queue: Array<{ request: Request, params: RequestParams, resolve: (value: any) => void, reject: (reason?: any) => void }>;
    private activeRequests: number;

    constructor() {
        this.queue = [];
        this.activeRequests = 0;
    }

    public addRequest(request: Request, params: RequestParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.queue.push({ request, params, resolve, reject });
            this.processQueue();
        });
    }
    private async processQueue(): Promise<void> {
        while (this.activeRequests < CONCURRENT_CALLS_MAX && this.queue.length > 0) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const { request, params, resolve, reject } = this.queue.shift()!;
            this.activeRequests++;
            request(params.url, params.data, params.header, params._config)
                .then(resolve)
                .catch(reject)
                .finally(() => {
                    this.activeRequests--;
                    this.processQueue(); // Process the next request in the queue
                });
        }
    }
}
