import { ActionHandlerFunction, ActivityLogInfo } from "@preveil-api";
import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { GlobalConstants, SettingsUIActionTypes } from "src/common";
import { Icon, SimplePagination } from "src/components";

type AllProps = {
  handleAction: ActionHandlerFunction;
  activity_logs: ActivityLogInfo[];
  is_loading: boolean;
  page: number;
};

/* Description: Toolbar for activity logs, contains a simple pagination and refresh. */
function LogsToolBarComponent(props: AllProps) {
  const { handleAction, activity_logs, is_loading, page } = props;

  return (
    <Row className="toolbar m-0">
      <Col xs="auto" className="order-sm-2 order-3 ms-auto pe-0">
        <SimplePagination
          isFirst={page === 0}
          isLast={activity_logs.length < GlobalConstants.ACTIVITY_PER_PAGE_COUNT} // TO DO: need to ask the backend if they can add a "total_rows" field in the user logs call (BACK-899)
          className="mb-0 justify-content-end"
          handleAction={handleAction}
        />
      </Col>
      <Col xs="auto" className="order-sm-3 order-4 me-2">
        <div className="pagination_info">
          {activity_logs.length === 0 ? <b>0 Items</b> :
          <>
            <b>{page * GlobalConstants.ACTIVITY_PER_PAGE_COUNT + 1}</b> -{" "}
            <b>
              {activity_logs.length < GlobalConstants.ACTIVITY_PER_PAGE_COUNT
                ? page * GlobalConstants.ACTIVITY_PER_PAGE_COUNT + activity_logs.length
                : (page + 1) * GlobalConstants.ACTIVITY_PER_PAGE_COUNT}
            </b>
          </>}
        </div>
      </Col>
      <Col className="divider order-sm-3 order-1"></Col>
      <Col xs="auto" className="p-0 order-sm-3 order-4 ms-1 refresh">
        <Button
          variant="icon"
          size="sm"
          onClick={() => handleAction({ actionType: SettingsUIActionTypes.Refresh })}
          title="Refresh Activity Logs"
        >
          {is_loading ? <i className="spinner"></i> : <Icon className="ficon-rotate-cw" />}
        </Button>
      </Col>
    </Row>
  );
}

export default React.memo(LogsToolBarComponent);
