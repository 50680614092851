import React from "react";
import { Button, Collapse } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { AdminUIActionTypes } from "src/common";
import { Icon } from "src/components";

type AllProps = {
  id: string;
  field_name: string;
  label: string;
  is_collapse: boolean;
  handleActions: ActionHandlerFunction;
  children: React.ReactNode;
}

function CollapseSection({ id, field_name, label, is_collapse, handleActions, children }: AllProps) {
  return (
    <div className="export-select-content">
      <header>
        <h4>{`${field_name}${label}`}</h4>
        <Button
          className="ps-1"
          variant="Icon"
          onClick={() => handleActions({ actionType: AdminUIActionTypes.ToggleCollapse, params: { id } })}
          aria-controls={`collapse-${id}`}
        >
          <Icon className={`${is_collapse ? "ficon-chevron-up" : "ficon-chevron-down"}`} />
        </Button>
      </header>
      <Collapse in={is_collapse}>
        <div id={`collapse-${id}`}>
          <div>
            {children}
          </div>
        </div>
      </Collapse>
    </div>
  );
}

export default React.memo(CollapseSection);