import React from "react";
import { OverlayTrigger, Popover, Button } from "react-bootstrap";
import { Placement } from "react-bootstrap/types";
import { OverlayTriggerType } from "react-bootstrap/OverlayTrigger";
import { Icon } from "../..";

type AllProps = {
  message?: string;
  className?: string;
  placement?: Placement;
  trigger?: OverlayTriggerType | OverlayTriggerType[];
}
// VariationPlacement = "top-start" | "top-end" | "bottom-start" | "bottom-end" | "right-start" | "right-end" | "left-start" | "left-end";
function InfoPopoverComponent(props: AllProps) {
  const { message, className, placement, trigger } = props;

  return (
    <OverlayTrigger
      rootClose={true}
      trigger={!!trigger ? trigger : ["click", "hover"]}
      placement={!!placement ? placement : "bottom-start"}
      overlay={
        <Popover>
          {!!message &&
            <Popover.Body>
              <span dangerouslySetInnerHTML={{ __html: message }} />
            </Popover.Body>}
        </Popover>
      }
    >
      <Button variant="transparent" size="sm" className={`btn-icon popover-button ${className}`} >
        <Icon className="pv-icon-info" />{" "}
      </Button>
    </OverlayTrigger>
  );
}

export default React.memo(InfoPopoverComponent);