/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
// @ts-ignore
import { FipsCrypto, PVCryptoUsage } from "pvcryptojs";
import { SigningKey, VerifyKey } from "./sign";

export class SigningKeyV3 extends SigningKey {
  static protocol_version = 3;

  constructor(public_key: Uint8Array, public private_key?: Uint8Array) {
    super(SigningKeyV3.protocol_version, public_key, private_key);
  }
  static async new(sk: Uint8Array): Promise<SigningKeyV3> {
    const [_sk, _pk] =
      !sk ?
      await FipsCrypto.generateAsymmKey(PVCryptoUsage.SIGN) :
      [sk, await FipsCrypto.publicKeyFromPrivate(sk, PVCryptoUsage.SIGN)];

    return new SigningKeyV3(_pk, _sk);
  }

 
  public cloneVerify(): VerifyKey {
    return new SigningKeyV3(this.public_key);
  }

  public async sign(plaintext: Uint8Array): Promise<Uint8Array> {
    if (!this.private_key) {
      throw Error("Invalid usage");
    }
    return FipsCrypto.sign(this.private_key, plaintext);
  }

  public async verify(plaintext: Uint8Array, signature: Uint8Array): Promise<boolean> {
    return FipsCrypto.verify(this.public_key, plaintext, signature);
  }
}
