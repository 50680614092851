import React, { useState } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import { FormValidationLimits, Regex_Patterns, AccountErrorMessages } from "src/common";
import { Icon } from "src/components";
import { Formik } from "formik";
import * as yup from "yup";

type AllProps = {
  submit: (old_password: string) => Promise<void>;
}
/* Description: Component for user to re enter their password when trying to reset their password */
function CurrentPasswordFormComponent(props: AllProps) {
  const { submit } = props;
  const [show_password, setShowPassword] = useState<boolean>(false);
  const schema = yup.object().shape({
    password: yup.string()
      .required(AccountErrorMessages.password_required_error)
      .min(
        FormValidationLimits.PASSWORD_OLD_MIN_LENGTH,
        AccountErrorMessages.password_old_min_length_error
      )
      .matches(Regex_Patterns.OLD_PASSWORD_REGEX,
        AccountErrorMessages.old_password_format_error)
  });

  // Description: Handle form submit 
  function handleSubmit(formValues: { password: string; }) {
    submit(formValues.password);
  }

  return <Formik
    validationSchema={schema}
    onSubmit={handleSubmit}
    initialValues={{
      password: "",
      confirmPassword: ""
    }} >
    {({ dirty, values, errors, touched, handleChange, handleSubmit, handleBlur, isValid }) => (
      <Form onSubmit={handleSubmit} className="max-600">
        <p className="fw-bold fs-4">Please enter your current PreVeil Express password.</p>
        <Form.Group className="mb-4" controlId="login_step_2">
          <Form.Label className="fs-6">Current Password</Form.Label>
          <InputGroup className={`password-form-control ${!!errors.password && touched.password ? "invalid" : ""}`} hasValidation={true}>
            <Icon className="ficon-lock" />
            <Form.Control type={!!show_password ? "text" : "password"}
              autoFocus={true}
              aria-describedby="passwordInput"
              placeholder="Enter Your Current Password"
              name="password"
              value={values.password}
              onBlur={handleBlur}
              onChange={handleChange}
              isInvalid={!!errors.password && touched.password} />
            <Button
              variant="transparent"
              className="btn-icon"
              size="sm"
              onClick={() => setShowPassword(!show_password)}
              aria-label="show password"
            >
              <span>
                <Icon className={!!show_password ? "ficon-eye" : "ficon-eye-off"} />
              </span>
            </Button>
          </InputGroup>
          <Form.Control.Feedback type="invalid" className={`${!!errors.password && touched.password ? "d-block" : ""}`}>
            * {errors.password}</Form.Control.Feedback>
        </Form.Group>
        <div className="btn-panel">
          <Button type="submit" disabled={!dirty || !isValid} >Continue</Button>
        </div>
      </Form>
    )}
  </Formik>;
}
export default React.memo(CurrentPasswordFormComponent);
