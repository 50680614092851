import * as React from "react";
import { Button, Row, Col, Card } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { Icon } from "src/components";
import { AccountUIActionTypes } from "src/common";

type AllProps = {
  handleAction: ActionHandlerFunction;
};

function AwaitConnectionComponent(props: AllProps) {
  const { handleAction } = props;

  return <Card.Body>
    <h3 className="content-header">
      <i className="waiting"></i>Awaiting Connection to new device&hellip;
    </h3>
    <Row className="description-card border-bottom pb-2">
      <Col className="image" xs={2}>
        <Icon className="pv-icon-mobile" />
      </Col>
      <Col>
        <ul className="content">
          <p className="fs-3 mb-2">
            On an iOS or Android mobile device without your PreVeil account:
          </p>
          <ul className="list-unstyled">
            <li>
              Download the <b>PreVeil</b> app from the app store
            </li>
            <li>
              Launch the app and tap <b>Add Existing Account</b>
            </li>
          </ul>
        </ul>
      </Col>
    </Row>
    <Row className="description-card mt-4">
      <Col className="image" xs={2}>
        <Icon className="pv-icon-laptop" />
      </Col>
      <Col className="p-0">
        <ul className="content">
          <p className="fs-3 mb-2">On a computer without your PreVeil account:</p>
          <ul className="list-unstyled">
            <li>
              Download and install PreVeil by going to <b>www.preveil.com</b>
            </li>
            <li>
              Go to www.preveil.com/app then select <b>Add Existing Account</b>
            </li>
          </ul>
        </ul>
      </Col>
    </Row>
    <div className="text-end mt-4">
      <Button
        variant="outline-primary"
        className=""
        onClick={() => handleAction({ actionType: AccountUIActionTypes.Destroy })}  >
        Cancel
      </Button>
    </div>
  </Card.Body>;
}
export default React.memo(AwaitConnectionComponent);
