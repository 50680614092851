import React from "react";
import { Account, dayjs } from "src/common";
import { Icon, Loading } from "src/components";
import { DataExportApproversRow } from ".";
import _ from "lodash";

type AllProps = {
  drop_dir: string;
  export_request: any; // TODO: fix this type look at ParseExportRequest
  export_status: string;
}

function DataExportRequestDone({ export_request, drop_dir, export_status }: AllProps) {
  const is_response_ready = !_.isEmpty(export_request);
  const created = dayjs().format("LLL");
  if (!is_response_ready) {
    return <Loading className="in-place" />;
  }
  return (
    <main className="data-export-main bg-white px-4">
      <section className="export-left-section">
        <header className="export-receipt-header">
          <Icon className="pv-icon-receipt" />
          <div>
            <h5>Data Export Request</h5>
            <p>Receipt</p>
          </div>
        </header>
        <div className="readonly-details small">
          <p><span>Timeframe</span>{export_request?.timeframe}</p>
          <p><span>Content</span>{export_request?.content}</p>
          <p><span>Output File Path</span>{drop_dir}</p>
        </div>
        <div className="recovery-groups-card users-export-card">
          <header>
            <p className="m-0">Users: {export_request?.users.length}</p>
          </header>
          <div>
            {export_request?.users.map((user: Account) => {
              const formattedUser = {
                address: user.user_id,
                name: user.display_name
              };
              return (
                <DataExportApproversRow key={user.user_id} user={formattedUser} is_read_only />
              );
            })}
          </div>
        </div>
      </section>
      <section className="export-right-section">
        <header className="export-receipt-header">
          <h5>Details</h5>
        </header>
        <div className="readonly-details small">
          <p><span>Status</span>{export_status}</p>
          <p><span>Created</span>{created}</p>
          <p><span>Requester</span>{export_request.requesterName}</p>
        </div>
      </section>
    </main>
  );
}

export default React.memo(DataExportRequestDone);