import React from "react";
import { Button, Modal  } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { AdminUIActionTypes, AdminUserExternalInvite } from "src/common";
import { UserInfoCard } from "..";

type AllProps = {
  show_modal: boolean;
  handleActions: ActionHandlerFunction;
  users: AdminUserExternalInvite[];
};

function ResendInvitesModal({ show_modal, handleActions, users }: AllProps) {
  
  return (
    <Modal
      size="lg"
      show={show_modal}
      onHide={() => handleActions({ actionType: AdminUIActionTypes.ShowModal, params: "resend-invite" })}
      aria-labelledby="resent-invite"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Resend Invites</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="mt-3 mb-3">
            <h5>Invites to be sent</h5>
          </div>
          <UserInfoCard users={users} />
          <footer className="mt-4">
            <Button
              onClick={() => handleActions({ actionType: AdminUIActionTypes.ResendInvite })}>
              Send Invites
            </Button>
            <Button
              onClick={() => handleActions({ actionType: AdminUIActionTypes.ShowModal, params: "resend-invite" })}
              variant="no-outline-primary">
              Cancel
            </Button>
          </footer>
        </>
      </Modal.Body>
    </Modal>
  );
}

export default React.memo(ResendInvitesModal);