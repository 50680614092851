import React, { useState, useEffect, useRef, Dispatch, SetStateAction } from "react";
import { RecoveryCodeInfo } from "@preveil-api";
import { AppConfiguration, dayjs, Message, PDFNETJS_WEBVIEWER_OPTIONS, SettingsErrorMessages, useAppDispatch } from "src/common";
import { uiActions } from "src/store";
import WebViewer, { WebViewerInstance } from "@pdftron/webviewer";
import { toDataURL } from "qrcode";
import { saveAs } from "file-saver";

type AllProps = {
  recovery_code: { backup_id: string; text_encoding?: string };
  user_id: string;
  getBackup: Function;
  setDownloadRecoveryCode: Dispatch<SetStateAction<RecoveryCodeInfo | undefined>>;
};

function PDFGeneratorComponent(props: AllProps) {
  const { recovery_code, user_id, getBackup, setDownloadRecoveryCode } = props;
  const backup_prefix = recovery_code.backup_id.substring(0, 6);
  const file_name = `recovery-file-${backup_prefix}.pdf`;
  const config = new AppConfiguration();
  const recovery_url = `${config.local_webapp_server}/backup-recovery`;
  const viewer = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const [instance, setInstance] = useState<WebViewerInstance | undefined>(undefined);

  /* Description: on initial load, get the qr code and instantiate a new instance of web viewer.
  We use a template for the initial doc which we will then modify with the current users backup information */
  useEffect(() => {
    (async () => {
      const qrcode = await getQRCode();

      if (!!qrcode && !!viewer) {
        const instance = await WebViewer(
          Object.assign({}, PDFNETJS_WEBVIEWER_OPTIONS, {
            initialDoc: "/templates/Account-Recovery-File-v2-Template.pdf",
            fullAPI: true,
          }),
          viewer.current as HTMLDivElement,
        );
        const { documentViewer, PDFNet } = instance.Core;

        documentViewer.addEventListener("documentLoaded", async () => {
          await PDFNet.runWithCleanup(async () => {
            await modifyPDF(instance, qrcode);
          }, PDFNETJS_WEBVIEWER_OPTIONS.licenseKey);
        });

        setInstance(instance);
      }
    })();
  }, []);

  /* Description: cleanup function, disposes the current documents data */
  useEffect(() => {
    return () => {
      instance?.Core.documentViewer.dispose();
    };
  }, [instance]);

  /* Description: Notify the store that the backup pdf file has been downloaded and call the getBackup function again. */
  function downloadComplete() {
    setDownloadRecoveryCode(undefined);
    getBackup();
  };

  /* Description: create and modify the initial template with the users backup info */
  async function modifyPDF(instance: WebViewerInstance, qrcode: string | undefined) {
    if (!!qrcode) {
      const { documentViewer, PDFNet, annotationManager } = instance.Core;
      await PDFNet.initialize();
      const doc = await documentViewer.getDocument().getPDFDoc();

      // lock the document before a write operation
      doc.lock();

      const replacer = await PDFNet.ContentReplacer.create();
      const page = await doc.getPage(1);
      const current_date = dayjs().local().format("MM/DD/YYYY"); 

      await replacer.addString("backup_prefix", backup_prefix);
      await replacer.addString("email_address", user_id);
      await replacer.addString("create_date", current_date);
      await replacer.addString("recovery_link", recovery_url);
      await replacer.process(page);

      // Modify text field
      // Create a widget annotation
      documentViewer.getAnnotationsLoadedPromise().then(async () => {
        const fieldManager = annotationManager.getFieldManager();
        const field = fieldManager.getField("recovery_code");
        if (!!recovery_code.text_encoding) {
          field.setValue(recovery_code.text_encoding);
        }
        // Insert QR code Image
        // ElementBuilder is used to build new Element objects
        const builder = await PDFNet.ElementBuilder.create();

        // ElementWriter is used to write Elements to the page
        const writer = await PDFNet.ElementWriter.create();

        // Writing to the page
        writer.beginOnPage(page);
        const img = await PDFNet.Image.createFromURL(doc, qrcode);
        const element = await builder.createImageScaled(img, 200, 50, 200, 200);
        writer.writePlacedElement(element);
        writer.end();

        // Unlock the document after write
        doc.unlock();

        documentViewer.refreshAll();
        documentViewer.updateView([1], 1);
        documentViewer.getDocument().refreshTextData();

        // Get Blob data and download
        const xfdfString = await annotationManager.exportAnnotations();
        const options = { xfdfString };
        const data = await documentViewer.getDocument().getFileData(options);
        const arr = new Uint8Array(data);
        const blob = new Blob([arr], { type: "application/pdf" });
        saveAs(blob, file_name);
        downloadComplete();
      });
    } else {
      dispatch(uiActions.handleRequestErrors(new Message(SettingsErrorMessages.error_generating_qr)));
    }
  }

  /* Description: gets a Data URI containing a representation of the QR Code image for the users recovery code. */
  async function getQRCode(): Promise<string | undefined> {
    if (!!recovery_code.text_encoding) {
      return await toDataURL(recovery_code.text_encoding, {
        errorCorrectionLevel: "H",
        type: "image/jpeg",
        width: 500,
        color: {
          dark: "#000000",
          light: "#FFFFFF",
        },
      })
        .then((url) => {
          return url;
        })
        .catch(() => {
          return undefined;
        });
    } else {
      return undefined;
    }
  }

  return (
    <div>
      <div className="invisible" ref={viewer}></div>
    </div>
  );
}

export default React.memo(PDFGeneratorComponent);
