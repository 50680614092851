import * as React from "react";
import { Account, useAppSelector, useDriveSocket, usePathList, useDefaultCollectionInfo, useDriveSubscribe } from "src/common";
import { RootState } from "src/store/configureStore";
import { WebsocketStatus } from "src/store";

// Description: Web Drive Initialize sets up the app for web use through websocket
function WebDriveInitializeComponent(props: { current_account: Account }) {
  const { current_account } = props;
  const ws_status = useAppSelector((state: RootState) => state.websocket.drive_ws_status);
  const default_permissions = useAppSelector((state: RootState) => state.drive.default_permissions);
  const collection_id = useAppSelector((state: RootState) => state.drive.current_directory?.collection_id);
  useDriveSocket();
  useDefaultCollectionInfo(current_account, default_permissions, ws_status === WebsocketStatus.connected);
  useDriveSubscribe(current_account, collection_id, default_permissions);
  usePathList(current_account, collection_id);

  return null;
}

export default React.memo(WebDriveInitializeComponent);
