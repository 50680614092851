import React, { Dispatch, SetStateAction } from "react";
import { Button, Modal } from "react-bootstrap";
import { AdminUIActionTypes } from "src/common";
import { ActionHandlerFunction } from "@preveil-api";

type AllProps = {
    show_modal: boolean;
    setShowConfirmationModal: Dispatch<SetStateAction<boolean>>;
    handleAction: ActionHandlerFunction;
};

function DeleteCertificateConfirmationModal(props: AllProps) {
    const { show_modal, setShowConfirmationModal, handleAction } = props;

    function handleSubmit(): void {
        handleAction({ actionType: AdminUIActionTypes.DeleteDeviceCertificate });
        setShowConfirmationModal(false);
    }
    
    return <Modal show={show_modal} onHide={() => setShowConfirmationModal(false)} centered aria-labelledby="device confirmation modal">
        <Modal.Header>
            <Modal.Title>Delete Device Certificate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Are you sure you want to remove the selected certificates?</p>
            <Button className="me-2" onClick={() =>  handleSubmit() }>Yes</Button>
            <Button onClick={() => setShowConfirmationModal(false)} variant="no-outline-primary">Cancel</Button>
        </Modal.Body>
    </Modal>;
}

export default React.memo(DeleteCertificateConfirmationModal);
