import * as React from "react";
import { ListGroup, Button, Card } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { CreateAccountSteps, CreateAccountUIActionTypes } from "src/common";

type AllProps = {
  handleAction: ActionHandlerFunction;
}

function Pick2FAFormComponent(props: AllProps) {
  const { handleAction } = props;

  return <Card.Body>
    <h4>Select a two-factor login method</h4>
    <ListGroup className="public-card mb-1" horizontal>
      <ListGroup.Item className="image">
        <div className="pick-sms-2fa-image"></div>
      </ListGroup.Item>
      <ListGroup.Item className="content">
        <p className="title">Text Message</p>
        <p>You will receive a one-time passcode.</p>
        <Button onClick={() => handleAction({ actionType: CreateAccountUIActionTypes.ChangeStep, params: CreateAccountSteps.SUBMIT_PHONE })} >Text Message</Button>
      </ListGroup.Item>
    </ListGroup>
    <span className="totp-separator">--------  Or  --------</span>
    <ListGroup className="public-card mt-1" horizontal>
      <ListGroup.Item className="image">
        <div className="pick-totp-2fa-image"></div>
      </ListGroup.Item>
      <ListGroup.Item className="content">
        <p className="title">Authenticator Application</p>
        <p>Set up your favorite authenticator app to log in to PreVeil.</p>
        <Button onClick={() => handleAction({ actionType: CreateAccountUIActionTypes.ChangeStep, params: CreateAccountSteps.SUBMIT_TOTP_CODE })} >Authenticator</Button>
      </ListGroup.Item>
    </ListGroup>

    <div className="btn-panel mt-4">
      <Button variant="outline-secondary" type="button"
        onClick={() => handleAction({ actionType: CreateAccountUIActionTypes.ResetForms })}>Cancel</Button>
    </div>
  </Card.Body>;
}

export default React.memo(Pick2FAFormComponent);
