import { ActionHandlerFunction } from "@preveil-api";
import React, { FormEvent, FocusEvent } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { DriveEntryType, getEntityIcon, mapEntryType, ProgressTracker, UploadUIActionTypes } from "src/common";
import { Icon } from "src/components";

type AllProps = {
  tracker: ProgressTracker;
  renaming: Map<string, string>;
  allowSubmit: (file_id: string, entries?: string[]) => boolean;
  handleAction: ActionHandlerFunction;
}

function ProgressRowComponent(props: AllProps) {
  const { tracker, renaming, allowSubmit, handleAction } = props;

  const percentDoneFor = (tracker: ProgressTracker) => {
    return Math.floor(tracker.done / tracker.total * 100);
  };

  const rowStyle = () => {
    if (!!tracker.error) return "upload-error";
    if (!!tracker.conflict) return "upload-conflict";
    if (tracker.done === tracker.total) return "upload-success";
    return "";
  };

  return (
    <Row className={
      `progress-row ${rowStyle()}`
    }>
      <Col xs={1} className="icon-col p-0">
        <Icon className={`file-icon ${rowStyle()} ${mapEntryType(DriveEntryType.FILE, tracker.file_name)?.type_class || getEntityIcon(DriveEntryType.FILE)}`} />
      </Col>
      {!tracker.error ? (!tracker.conflict ? (
        <>
          <Col xs={8} title={tracker.file_name} className="file-name p-0">{tracker.file_name}</Col>
          <Col xs={2} className="percent p-0">{tracker.done !== tracker.total && (<span>{`${percentDoneFor(tracker)}%`}</span>)}</Col>
          <Col xs={1} className="status">
            {tracker.done === tracker.total ? (
              <Icon className="pv-icon-mark-read" />
            ) : (
              <Icon className="spinner" />
            )}
          </Col>
        </>
      ) : (
        <>
          {renaming.has(tracker.file_id) ? (
            <>
              <Col xs="10">
                <Form
                  className="me-2"
                  onSubmit={(e: FormEvent<HTMLFormElement>) => {
                    e.preventDefault();
                    handleAction({ actionType: UploadUIActionTypes.SubmitRename, params: tracker });
                  }}>
                  <InputGroup size="sm">
                    <Form.Control
                      autoFocus={true}
                      type="text"
                      placeholder={renaming.get(tracker.file_id)}
                      aria-label="rename file"
                      value={renaming.get(tracker.file_id)}
                      required
                      maxLength={255}
                      onChange={(e: FocusEvent<HTMLInputElement>) => {
                        const name = e.currentTarget.value;
                        handleAction({ actionType: UploadUIActionTypes.UpdateRename, params: { tracker, name } });
                      }}
                    />
                    {renaming.get(tracker.file_id) !== "" && (
                      <Button
                        variant="icon"
                        size="sm"
                        className="text-muted p-1"
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => handleAction({ actionType: UploadUIActionTypes.UpdateRename, params: { tracker, name: "" } })}
                      >Clear</Button>
                    )}
                    {allowSubmit(tracker.file_id, tracker.entries) && (
                      <>
                        <Button variant="icon" size="sm"
                          onClick={() => handleAction({ actionType: UploadUIActionTypes.SubmitRename, params: tracker })}
                        ><Icon className="ficon-check" /></Button>
                      </>
                    )}
                  </InputGroup>
                </Form>
              </Col>
              <Col xs={1}>
                <Button variant="icon" size="sm"
                  onClick={() => {
                    handleAction({ actionType: UploadUIActionTypes.Cancel, params: tracker.file_id });
                  }}
                  aria-label="Cancel upload"
                  data-tooltip-id="pv-tooltip" data-tooltip-content="Cancel upload"
                ><Icon className="ficon-x" /></Button>
              </Col>
            </>
          ) : (
            <>
              <Col xs={5} title={tracker.file_name} className="file-name p-0">{tracker.file_name}</Col>
              <Col xs={3} className="file-name"><small>Already exists</small></Col>
              <Col xs={3} className="d-flex justify-content-end">
                <Button variant="light" size="sm"
                  className="m-1"
                  onClick={() => handleAction({ actionType: UploadUIActionTypes.UpdateRename, params: { tracker, name: tracker.file_name } })}
                  aria-label="Rename file"
                  data-tooltip-id="pv-tooltip" data-tooltip-content="Rename file"
                >Rename</Button>
                <Button variant="icon" size="sm"
                  onClick={() => handleAction({ actionType: UploadUIActionTypes.Cancel, params: tracker.file_id })}
                  aria-label="Cancel upload"
                  data-tooltip-id="pv-tooltip" data-tooltip-content="Cancel upload"
                ><Icon className="ficon-x" /></Button>
              </Col>
            </>
          )}
        </>
      )) : (
        <>
          <Col xs={4} title={tracker.file_name} className="file-name p-0">{tracker.file_name}</Col>
          <Col xs={6} className="file-name pl-1" title={tracker.error}><small>{tracker.error}</small></Col>
          <Col xs={1} className="status"><Icon className="pv-icon-failed" /></Col>
        </>
      )}
    </Row>
  );
}

export default React.memo(ProgressRowComponent);
