import React from "react";
import { Card, Button, Form, Col, CardGroup } from "react-bootstrap";
import recoveryImage from "src/assets/images/recovery_group.svg";
import restoreAccount from "src/assets/images/restore-account.svg";
import appleScreenshot from "src/assets/images/apple-mail.png";
import gmailImg from "src/assets/images/screenshot-gmail.png";
import dataExportImage from "src/assets/images/data-export.svg";
import { Icon } from "src/components";

function CardsComponent() {
  // Description: Cards
  const Cards = () => {
    return (
      <>
        <h4>CARDS</h4>
        <h5>Approve Recovery</h5>
        <Card className="pv-card">
          <Card.Img variant="top" src={recoveryImage} />
          <Card.Body>
            <Card.Title as="p">
              If you are a member of another user's recovery group, you may be
              asked to help recover their private key.
            </Card.Title>
            <Card.Text as="p">
              To help another user recover their private key now, click
              'continue'
            </Card.Text>
            <Button variant="primary">Continue</Button>
          </Card.Body>
        </Card>
        <hr />
        <h5>Account Recovery</h5>
        <Card className="pv-card">
          <Card.Img variant="top" src={restoreAccount} />
          <Card.Body>
            <Card.Title as="p">
              Recover your account from a file or QR Code instead of a
              Recovery Group.
            </Card.Title>
            <Card.Text>
              To protect your private PreVeil key from being lost, you can
              generate an individual QR code and key that can be used to
              recover your account.
              Print your recovery code and keep it somewhere safe or store the
              recovery file on a trusted device (not on your computer).
              In the future, if you lose access to all your PreVeil devices,
              you can recover your account and its data by using this
              information on a new device.
              <label>Set a password to encrypt your recovery file</label> <span className="text-muted font-size-sm">(optional)</span>:
            </Card.Text>
            <Col xs={6}>
              <Form.Group className="mb-3" controlId="formPassword">
                <Form.Control type="password" />
                {/* <Button variant="transparent" className="btn-icon" size="sm"><Icon className="ficon-eye" /> </Button> */}
              </Form.Group>
            </Col>
            <Button variant="primary">Create Recovery File</Button>
          </Card.Body>
        </Card>
        <hr />
        <h5>Add Device</h5>
        <Card className="pv-card">
          <Card.Img variant="top" src={restoreAccount} />
          <Card.Body>
            <Card.Title as="p">
              To access your PreVeil account on another computer or phone, you need to install PreVeil software and your private key onto that device.
            </Card.Title>
            <Card.Text as="p">
              Once your other device has PreVeil installed, click ‘continue’ to securely copy your private key from this computer
            </Card.Text>
            <Button variant="primary">Continue</Button>
          </Card.Body>
        </Card>
        <hr />
        <hr />
        <h5>Automatically Add to Mail Client</h5>
        <CardGroup className="mail-client">
          <Card className="gmail">
            <Card.Header>Gmail Plugin</Card.Header>
            <Card.Img variant="top" src={gmailImg} className="gmail-image" />
            <Card.Body>
              <Card.Text as="p">
                PreVeil makes it easy to access your encrypted emails in Gmail
                by automatically adding a secure inbox.
              </Card.Text>
            </Card.Body>
            <Button variant="primary">Install Gmail Plugin</Button>
          </Card>
          <Card>
            <Card.Header>Apple Mail</Card.Header>
            <Card.Img variant="top" src={appleScreenshot} className="apple-image" />
            <Card.Body>
              <Card.Text as="p">
                PreVeil makes it easy to access your encrypted emails in Apple
                Mail by automatically adding a secure inbox. Please close Apple Mail if it is currently open.
              </Card.Text>
            </Card.Body>
            <Button variant="primary">Add PreVeil to Apple Mail</Button>
          </Card>
        </CardGroup>
        <hr />
        <h4>ADMIN CARDS</h4>
        <h5>Data Export</h5>
        <Card className="pv-card">
          <Card.Img variant="top" src={dataExportImage} />
          <Card.Body>
            <Card.Title as="p">
              Data export allows you to to download a decrypted copy of your organizations data.
            </Card.Title>
            <Card.Text as="p">
              To begin, please assign an approval group to be responsible for approving all data export requests.
            </Card.Text>
            <Button variant="primary">Set Approval Group</Button>
          </Card.Body>
        </Card>
        <hr />
        <Card className="admin-card">
          <Icon className="trusted-community" />
          <Card.Body>
            <Card.Title as="p">
              Create a Whitelist of Trusted Addresses
            </Card.Title>
            <Card.Text as="p">
              <span className="text-muted">Learn More:</span> <a href="https://www.preveil.com">preveil.com/support</a>
            </Card.Text>
            <Button variant="primary"><Icon className="icon-plus2" />Add to Whitelist</Button>
          </Card.Body>
        </Card>
        <hr />
        <Card className="admin-card">
          <Icon className="trusted-community" />
          <Card.Body>
            <Card.Title as="p">
              Create a Group Email
            </Card.Title>
            <Card.Text as="p">
              <span className="text-muted">Learn More:</span> <a href="https://www.preveil.com">preveil.com/support</a>
            </Card.Text>
            <Button variant="primary"><Icon className="icon-plus2" />Create Group Email</Button>
          </Card.Body>
        </Card>
        <hr />
        <Card className="admin-card">
          <Icon className="email-gateway" />
          <Card.Body>
            <Card.Title as="p">
              Create a list of external addresses
            </Card.Title>
            <Card.Text as="p">
              <span className="text-muted">Learn More:</span> <a href="https://www.preveil.com">preveil.com/support</a>
            </Card.Text>
            <Button variant="primary"><Icon className="icon-plus2" />Add to Email Gateway List</Button>
          </Card.Body>
        </Card>
        <hr />
        <Card className="admin-card">
          <Icon className="approval-group-image" />
          <Card.Body>
            <Card.Title as="p">
              Create your first approval group
            </Card.Title>
            <Card.Text as="p">
              <span className="text-muted">Learn More:</span> <a href="https://www.preveil.com">preveil.com/support</a>
            </Card.Text>
            <Button variant="primary"><Icon className="icon-plus2" />Create Approval Group</Button>
          </Card.Body>
        </Card>
        <hr />
        <Card className="admin-card">
          <Icon className="approval-group-image" />
          <Card.Body>
            <Card.Title as="p">
              Create your first approval group
            </Card.Title>
            <Card.Text as="p">
              <span className="text-muted">Learn More:</span> <a href="https://www.preveil.com">preveil.com/support</a>
            </Card.Text>
            <Button variant="primary"><Icon className="icon-plus2" />Create Approval Group</Button>
          </Card.Body>
        </Card>
      </>
    );
  };

  return <Cards />;
};

export default React.memo(CardsComponent);
