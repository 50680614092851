import React, { Dispatch, SetStateAction } from "react";
import { Alert, Dropdown, DropdownButton } from "react-bootstrap";
import { UserProfile } from "@preveil-api";
import { RecoveryGroup } from "src/common";
import _ from "lodash";

type AllProps = {
  editing: boolean;
  optionals_required: number;
  setOptionalsRequired: Dispatch<SetStateAction<number>>;
  editing_users: UserProfile[];
  current_recovery_group?: RecoveryGroup;
}

/* Description: Component for the options alert. On Edit, contains a dropdown where the user can choose how many
 users are required to approve a request. */
function OptionsAlertComponent(props: AllProps) {
  const { editing, optionals_required, setOptionalsRequired, editing_users, current_recovery_group } = props;

  function maxOptionals(users: UserProfile[]): number[] {
    const numbers: number[] = users.map((user: UserProfile, index: number) => (index + 1));
    if (numbers.length > 1) {
      numbers.splice(numbers.length - 1, 1);
    }
    return numbers;
  }

  return (
    <>
      <h3 className="text-muted mt-3">Options</h3>
      <Alert variant="dark" className="text-muted d-flex">
        {maxOptionals(!editing && !!current_recovery_group ? current_recovery_group.optional_users : editing_users).length === 0 ? (
          <>Choose how many Recovery Group members you need to regain access to your account.</>
        ) : (
          <>
            How many approvals are required for recovery?
            {!!editing &&
              <DropdownButton
                variant="outline-secondary"
                className="ms-auto hide-arrow pv-dropdown"
                title={optionals_required}
              >
                {_.map(maxOptionals(editing_users), (number: number, i: number) => (
                  <Dropdown.Item key={i} onClick={() => setOptionalsRequired(number)}>
                    {number}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            }
            {!!current_recovery_group && !editing && <b className="position-relative ms-auto">{current_recovery_group.optionals_required}</b>}
          </>
        )}
      </Alert>
    </>
  );
}

export default React.memo(OptionsAlertComponent);
