
import React from "react";
import { ActionHandlerFunction, UserProfile } from "@preveil-api";
import { AdminUIActionTypes } from "src/common";
import { Card, Button } from "react-bootstrap";
import connectionImage from "src/assets/images/connection.svg";

type AllProps = {
  requester: UserProfile | null;
  handleActions: ActionHandlerFunction;
}

function DataExportAwaitConnection({ requester, handleActions }: AllProps) {
  return (
    <main className="data-export-wait-connection">
      <Card className="pv-card">
        <Card.Img variant="top" src={connectionImage} />
        <Card.Body>
          <header className="wait-connection-header">
            <span className="waiting"></span>
            <h4>Awaiting Connection&hellip;</h4>
          </header>
          <section className="wait-connection-instructions">
            <p>Contact <strong>{`${requester?.name}[${requester?.address}]`}</strong> and ask them to:</p>
            <ol>
              <li><p>Access their PreVeil account on a computer</p></li>
              <li><p>Click on Admin then Data Export</p></li>
              <li><p>Click Start in the approval list</p></li>
              <li><p>Type in the code that will be provided</p></li>
            </ol>
          </section>
          <footer>
            <Button variant="outline-secondary" onClick={() => handleActions({ actionType: AdminUIActionTypes.Destroy })}>Cancel</Button>
          </footer>
        </Card.Body>
      </Card>
    </main>
  );
}

export default React.memo(DataExportAwaitConnection);
