/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
// @ts-ignore
import { SymmKey } from "./symm";
import {
  randomBytes,
  concatArrays,
  FipsCrypto
} from "pvcryptojs";

export class SymmKeyV1 extends SymmKey {
  static protocol_version = 1;
  constructor(key: Uint8Array) {
    super(SymmKeyV1.protocol_version, key);
  }

  static async new(secret?: Uint8Array): Promise<SymmKeyV1> {
    if (!secret) {
      return new SymmKeyV1(await FipsCrypto.generateSymmKey());
    }

    return new SymmKeyV1(secret);
  }

  public async encrypt(
    plaintext: Uint8Array,
    iv?: Uint8Array
  ): Promise<Uint8Array> {
    const _iv = iv || randomBytes(FipsCrypto.ivLength);
    const [ciphertext, tag] =
      await FipsCrypto.symmetricEncrypt(this.key, _iv, plaintext);
    return concatArrays(ciphertext, _iv, tag);
  }

  public decrypt(ciphertext: Uint8Array): Promise<Uint8Array> {
    const tag = ciphertext.slice(-FipsCrypto.tagLength),

          cipher = ciphertext.slice(
            0, -(FipsCrypto.ivLength + FipsCrypto.tagLength)),

          iv = ciphertext.slice(
            -(FipsCrypto.ivLength + FipsCrypto.tagLength),
            -FipsCrypto.tagLength);

    return FipsCrypto.symmetricDecrypt(this.key, iv, tag, cipher);
  }
}
