import { ActionHandlerFunction } from "@preveil-api";
import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { AdminUIActionTypes, ApprovalGroup, Message, MessageHandlerDisplayType, MessageToastTypes, useAppDispatch } from "src/common";
import { Icon } from "src/components";
import { uiActions } from "src/store";

type AllProps = {
  handleAction: ActionHandlerFunction;
  approval_groups: ApprovalGroup[];
  loading: boolean;
  setCreateModalShow: React.Dispatch<React.SetStateAction<boolean>>;
  checkedList: ApprovalGroup[];
  setCopiedApprovalGroup: React.Dispatch<React.SetStateAction<ApprovalGroup | undefined>>;
};

/* Description: Toolbar for approval groups */
function ApprovalGroupToolbarComponent(props: AllProps) {
  const { handleAction, approval_groups, loading, setCreateModalShow, checkedList, setCopiedApprovalGroup } = props;
  const dispatch = useAppDispatch();

  function handleDelete() {
    handleAction({ actionType: AdminUIActionTypes.Delete });
  }

  /* Description: Confirmation Modal for deleting an approval group. */
  function confirmDelete() {
    const confirmation_dialog = new Message(
      "Are you sure you want to delete the selected approval group(s)?",
      MessageHandlerDisplayType.confirm,
      MessageToastTypes.primary,
      "Delete Approval Group(s)?",
      {
        label: "Yes",
        data: true,
        action: handleDelete,
      },
      { label: "No" },
    );
    dispatch(uiActions.handleSetMessage(confirmation_dialog));
  }

  return (
    <Row className="toolbar m-0">
      <Col className="d-flex align-items-center ps-2">
        <Button
          variant="icon"
          size="sm"
          onClick={() => setCreateModalShow(true)}
          title="Create Approval Group"
        >
          <Icon className="ficon-plus" />
        </Button>
        <Button
          variant="icon"
          size="sm"
          disabled={checkedList.length !== 1}
          onClick={() => { setCopiedApprovalGroup(checkedList[0]); setCreateModalShow(true); }}
          title="Copy Approval Group"
        >
          <Icon className="ficon-copy" />
        </Button>
        <Button
          variant="icon"
          size="sm"
          disabled={
            checkedList.length < 1 || checkedList.filter((ag) => ag.roles.length > 0).length > 0
          }
          onClick={() => confirmDelete()}
          title="Delete Selected"
        >
          <Icon className="ficon-trash-2" />
        </Button>
      </Col>
      <Col xs="auto" className="order-sm-2 order-3 ms-auto">
        <div className="pagination_info">
          <b>{approval_groups.length} </b>
          <span>
            Approval Group{approval_groups.length > 1 || approval_groups.length === 0 ? "s" : ""}
          </span>
        </div>
      </Col>
      <Col className="divider order-sm-3 order-1"></Col>
      <Col xs="auto" className="p-1 order-sm-3 order-4 refresh">
        <Button
          variant="icon"
          size="sm"
          onClick={() => handleAction({ actionType: AdminUIActionTypes.Refresh })}
          title="Refresh Approval Groups"
        >
          {loading ? <i className="spinner"></i> : <Icon className="ficon-rotate-cw" />}
        </Button>
      </Col>
    </Row>
  );
}

export default React.memo(ApprovalGroupToolbarComponent);
