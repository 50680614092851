import React, { useEffect, useState } from "react";
import { Button, Col, Row, Toast } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import {
  Account, DriveMessages, DriveSuccessMessages, DriveUIActionTypes, EntryItem, Message, MessageAnchors, MessageHandlerDisplayType, MessageToastTypes,
  NodePermissionSet, dayjs, isSameUser, useAppDispatch, useSetLockStateMutation
} from "src/common";
import { CountdownDisplay, Icon } from "src/components";
import { uiActions } from "src/store";

type AllProps = {
  current_account: Account;
  entry: EntryItem;
  permissions: NodePermissionSet;
  handleAction: ActionHandlerFunction;
};

function FileLockComponent(props: AllProps) {
  const { current_account, entry, permissions, handleAction } = props;
  const [timer_active, setTimerActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [setLockState] = useSetLockStateMutation();
  const dispatch = useAppDispatch();
  const locked = !!entry.is_locked;
  const is_lock_enactor = !!entry.lock_info && isSameUser(current_account.user_id, entry.lock_info.lockedByUserId);

  useEffect(() => {
    setTimerActive(locked);
    loading && dispatch(uiActions.handleSetMessage(new Message(
      DriveSuccessMessages.success_lock.replace(MessageAnchors.message_content, locked ? "locked" : "unlocked")
    )));
    setLoading(false);
  }, [entry]);

  // Description: Toggle file lock/unlock. Force unlock the file with warning if not lock enactor
  function onLockStateClick() {
    setError(false);
    if (locked && !is_lock_enactor) {
      const confirmation_dialog = new Message(
        DriveMessages.confirm_force_unlock.replace(MessageAnchors.message_content, entry.lock_info?.lockedByUserId || "another user"),
        MessageHandlerDisplayType.confirm,
        MessageToastTypes.primary,
        "Force Unlock",
        {
          label: "Yes",
          data: true,
          action: updateLockState
        },
        { label: "No" }
      );
      dispatch(uiActions.handleSetMessage(confirmation_dialog));
    } else {
      updateLockState();
    }
  }

  function updateLockState() {
    setLoading(true);
    setLockState({
      user_id: current_account.user_id,
      coll_id: entry.collection_id,
      entity_id: entry.id,
      lock_state: !locked
    }).unwrap()
      .then(() => {
        handleAction({ actionType: DriveUIActionTypes.Refresh });
      })
      .catch(() => {
        setError(true);
      });
  }

  return <div className="py-3">
    {
      !!error &&
      <Toast bg="warning" className="mb-3 d-inline-block mt-2">
        <Toast.Body>
          <span>There was a problem with the file lock feature. Please, try again or contact our customer support team.</span>
        </Toast.Body>
      </Toast>
    }
    {
      !!permissions.write && (!timer_active || is_lock_enactor) &&
      <div className="lock-button-row">
        <Button variant="outline-light" size="sm" className="mb-2" disabled={loading}
          onClick={onLockStateClick}>
          <Icon className={locked ? "ficon-unlock" : "ficon-lock"} />
          {locked ? "Unlock" : "Lock"}
        </Button>
        {loading && <div><Icon className="spinner mb-1" /></div>}
      </div>
    }
    {timer_active && locked && (
      <Row className="pb-2">
        <Col sm={6}>
          <div className="text-muted">Locked For</div>
          <div className="semi-bold">
            <CountdownDisplay
              is_lock={true}
              expires={dayjs(entry.lock_info?.canForceUnlockAt).utc(true).local().toString()}
              handleTimeExpired={() => setTimerActive(false)}
            ></CountdownDisplay>
          </div>
        </Col>
        <Col sm={6}>
          <div className="text-muted">Force unlock on</div>
          <div>
            {!!entry.lock_info ? dayjs(entry.lock_info.canForceUnlockAt).utc(true).local().format("MM/DD/YYYY h:mm A") : "Unavailable"}
          </div>
        </Col>
      </Row>
    )}
    {locked && (
      <Row>
        <Col sm={6}>
          <div className="text-muted">Locked by</div>
          <div className="semi-bold">
            {!!entry.lock_info ? (is_lock_enactor ? "Me" : entry.lock_info.lockedByUserId) : "Unavailable"}
          </div>
        </Col>
        <Col sm={6}>
          <div className="text-muted">Locked at</div>
          <div className="with_cap semi-bold">
            {!!entry.lock_info ? dayjs(entry.lock_info.lockedAt).utc(true).local().format("MM/DD/YYYY h:mm A") : "Unavailable"}
          </div>
        </Col>
      </Row>
    )}
  </div>;
}

export default React.memo(FileLockComponent);
