import * as React from "react";
import { Button } from "react-bootstrap";
import { Icon } from "src/components";
import { PublicRoutes } from "src/common";

type AllProps = {
  user_id?: string;
}

function SuccessPanelComponent(props: AllProps) {
  const { user_id } = props;
  return <>
    <p className="fs-3">
      <b>On this computer</b> check <b>{user_id}</b> for an email from PreVeil and follow its instructions.
    </p>
    <p className="d-flex"><Icon className="pv-icon-question" /><span>
      We sent you an email to verify that you are the owner of this email account.
      You must use this computer to follow the instructions in the email.</span>
    </p>
    <Button className="mt-3" href={`/${PublicRoutes.get_started_route}`}>Go Back</Button>
  </>;
}
export default React.memo(SuccessPanelComponent);
