import { useState, useCallback, useEffect } from "react";
import { MessageDisplayType, ErrorStackDataType } from "@preveil-api";
import { randomBytes } from "pvcryptojs";
import { EncryptionStyle } from "src/common/keys/encryption";
import { FSMessage, FSRole, FSWrappedKey, FSStatus } from "src/common/keys/protos/collections_pb";
import {
  Account, useAppDispatch, Message, MessageHandlerDisplayType, useSendRequestMutation, CollectionFilesyncAPI, DriveErrorMessages, useGetGrants,
  useAppSelector, DriveRequest, EntryItem, UUID, KeyFactory, GrantSet, Grant, getDirSymmKey, AppUserKey, PermissionSet, PermissionSetType,
  encryptItemName, mapEntryType, DriveEntryType, GrantSetType, dayjs, ErrorStackItem, getEnumKey
} from "src/common";
import { DriveCallbackAsyncFunction, uiActions } from "src/store";
import { RootState } from "src/store/configureStore";
import _ from "lodash";

/* Description: This is the web hook for creating a directory. */
export function useCreateDirectory(current_account: Account) {
  const default_permissions = useAppSelector((state: RootState) => state.drive.default_permissions);
  const default_grants = useAppSelector((state: RootState) => state.drive.default_grants);
  const [new_directory, setNewDirectory] = useState<EntryItem>();
  const [error, setError] = useState<boolean>(false);
  const [new_directory_name, setNewDirName] = useState<string>("");
  const [current_directory, setCurrentDirectory] = useState<FSMessage.Dir>();
  const [key_version, setKeyVersion] = useState<number>(0);
  const [collection_id, setCollectionId] = useState<string>("");
  const [sendRequest] = useSendRequestMutation();
  const { grants, getGrants } = useGetGrants(current_account);
  const dispatch = useAppDispatch();

  // Description: useEffect hook for when grants (from the useGetGrants hook) is initialized.
  useEffect(() => {
    if (!!grants && !!current_directory) {
      const grant = GrantSet.getGrantbyRole(grants, key_version);
      !!grant && encryptNameAndCreate(collection_id, current_directory, new_directory_name, grant, undefined, grants);
    }
  }, [grants]);

  // Description: Initial function for create dir - calls Fetch Dir and depending on if its an acl node, has a maintainer or a v1 collection, gets grants or
  async function createDir(_collection_id: string, directory_id: string, name: string) {
    const permissions = _.find(default_permissions, (set: PermissionSetType) => _collection_id === set.collection_id.B64());
    const request = await CollectionFilesyncAPI.fetchDirectoryRequest(_collection_id, directory_id);
    const drive_request = await DriveRequest.initializeRequest(request, current_account, permissions);
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        const dir = message.getDir();
        if (!!dir) {
          const _key_version = dir.getWrappedDirKey()?.getKeyVersion();
          (_key_version !== undefined) && setKeyVersion(_key_version);
          setCurrentDirectory(dir);
          if (dir.getAclList().length > 0) { // if acl node.
            getGrants(directory_id, _collection_id, default_permissions, default_grants);
          } else if (dir.hasMaintainerId()) { // if child of maintainer.
            fetchMaintainer(_collection_id, dir.getMaintainerId_asB64(), permissions);
          } else { // if under v1 collection or root.
            const ps = !!permissions ? new PermissionSet(permissions.collection_id, permissions?.permissions) : null;
            const reader = !!ps ? await ps.permission(FSRole.READER, dir.getWrappedDirKey()?.getKeyVersion())?.key(current_account) : null;
            if (!!reader) {
              encryptNameAndCreate(_collection_id, dir, name, undefined, reader);
            }
          }
        }
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_directory }, message);
      }
    }

    handleSendRequest(drive_request, callback, DriveErrorMessages.error_creating_folder);
  }

  // Description: Encrypts the new dir name with it's parents dir symm key and calls the CREATE backend call.
  async function encryptNameAndCreate(_collection_id: string, dir: FSMessage.Dir, name: string, grant?: Grant, reader?: AppUserKey, grants?: GrantSetType) {
    const dir_id = new UUID().B64();
    const dir_version = randomBytes(32);
    const dir_symm_key = await KeyFactory.newSymmKey({ style: EncryptionStyle.DRIVE });
    const user_key = !!reader ? reader : !!grant ? await grant.key(current_account) : null;
    const wrapped_key = !!dir ? dir.getWrappedDirKey() : undefined;
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        const new_dir = message.getDir();
        if (new_dir) {
          const entry_info = mapEntryType(DriveEntryType.DIR, name);
          const entry = {
            deleted: false,
            deleted_at: 0,
            id: new_dir.getId_asB64(),
            lastModificationDate: dayjs.utc(new Date()).format(),
            linked_collection_id: _collection_id,
            collection_id: _collection_id,
            localSyncStatus: 0,
            name,
            size: 0,
            type_label: entry_info?.type_label,
            mapped_type: entry_info?.mapped_type,
            type_class: entry_info?.type_class,
            type: DriveEntryType.DIR,
          };
          setNewDirectory(entry);
        } else {
          setError(true);
        }
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_creating_folder }, message);
      }
    }

    if (!!user_key) {
      const permissions = _.find(default_permissions, (set: PermissionSetType) => _collection_id === set.collection_id.B64());
      const symm_key = (!!wrapped_key && !!permissions) ? await getDirSymmKey(wrapped_key, current_account, permissions, user_key) : undefined;
      const encrypted_name = !!symm_key ? await encryptItemName(name, symm_key) : null;
      const new_dir_wrapped_key = await user_key.encryption_key.seal(dir_symm_key.serialize());
      const fs_wrapped_key = new FSWrappedKey();
      fs_wrapped_key.setWrappedKey(new_dir_wrapped_key);
      fs_wrapped_key.setKeyVersion(user_key.key_version);
      const drive_request = !!permissions && !!dir && !!encrypted_name ?
        await CollectionFilesyncAPI.createDir(
          current_account, permissions, _collection_id, dir.getId_asB64(), dir.getVersion_asB64(), dir_id, dir_version,
          encrypted_name, fs_wrapped_key, grants) : null;
      handleSendRequest(drive_request, callback, DriveErrorMessages.error_downloading_block);
    }
  }

  // Description: If the dir has a maintainer node (v2) then it needs to fetch dir for that maintainer id and get the grants for that directory (to get the appropriate user keys from ACL_KEY_HISTORY)
  async function fetchMaintainer(_collection_id: string, maintainer_id: string, permissions?: PermissionSetType) {
    const fetchDir = await CollectionFilesyncAPI.getDirectoryInformationRequest(_collection_id, maintainer_id);
    const drive_request = await DriveRequest.initializeRequest(fetchDir, current_account, permissions);
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        const dir = message.getDir();
        if (!!dir) {
          const _key_version = dir.getWrappedDirKey()?.getKeyVersion();
          _key_version !== undefined && setKeyVersion(_key_version);
          getGrants(dir.getId_asB64(), _collection_id, default_permissions, default_grants);
        }
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_maintainer_directory }, message);
      }
    }
    handleSendRequest(drive_request, callback, DriveErrorMessages.error_fetching_maintainer_directory);
  }

  // Description: handles error when the request object is null
  function handleSendRequest<T>(request: DriveRequest | null, callback: DriveCallbackAsyncFunction<T>, stack_error: string = DriveErrorMessages.error_sending_request) {
    if (!!request) {
      request.setCallback(callback);
      sendRequest(request);
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, { stack_error, stack_message: DriveErrorMessages.error_sending_request });
    }
  }

  // Description: Logs Errors and sets Error to true
  function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, fsmessage?: FSMessage | null, display_type: MessageDisplayType = MessageHandlerDisplayType.toastr) {
    const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
    const stack = new ErrorStackItem("[useCreateDirectory Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
    dispatch(uiActions.handleRequestErrors(new Message(message, display_type), stack));
    setError(true);
  }

  // Description: Callback for create directory
  const createDirectoryWeb = useCallback((_collection_id: string, directory_id: string, name: string) => {
    setNewDirName(name);
    setCollectionId(_collection_id);
    createDir(_collection_id, directory_id, name);
  }, []);

  return {
    new_directory,
    createDirectoryWeb,
    error,
  };
}
