import { FipsCrypto } from "pvcryptojs";
import { Helpers, KeyFactory, AppPublicUserKey, AppUserKey, UUID } from "src/common/";
import { PublicKey } from "../";

export class EphemeralKey {
    private _key: Promise<AppUserKey>;

    constructor() {
        this._key = KeyFactory.newUserKey();
    }

    public async serialized(): Promise<string> {
        return Helpers.b64Encode((await this._key).public_user_key.serialize());
    }

    public async signature(data: Uint8Array): Promise<string> {
        return (await this._key).signing_key.sign(data).then(d => Helpers.b64Encode(d));
    }

    public async sharedSecret(public_key: PublicKey, use_fips_derivation: boolean): Promise<Uint8Array> {
        const _private_key = (await this._key).encryption_key.private_key;
        if (!!_private_key) {
            return FipsCrypto.sharedSecret(_private_key, public_key.public_key, use_fips_derivation);
        } else {
            throw (new Error("Could not generate Shared Secret"));
        }
    }

    public async decrypt(message: Uint8Array, public_key: PublicKey, use_fips_derivation: boolean): Promise<Uint8Array> {
        const key = await this.sharedSecret(public_key, use_fips_derivation);
        const symm_key = await KeyFactory.newSymmKey({ key });
        return symm_key.decrypt(message);
    }

    public async encrypt(message: Uint8Array, public_key: PublicKey, use_fips_derivation: boolean): Promise<Uint8Array> {
        const key = await this.sharedSecret(public_key, use_fips_derivation);
        const symm_key = await KeyFactory.newSymmKey({ key });
        return symm_key.encrypt(message);
    }
}

export class KeyExchangeManager {
    static handshake_protocol = 1;
    static supported_protocols = [2, 3];
    static current_protocol = 2;
    private _ephemeral_key: EphemeralKey;
    private _other_public_key?: AppPublicUserKey;
    private _proposed_device?: { id: string; name: string; device_key: AppUserKey; };

    constructor(key: EphemeralKey) {
        this._ephemeral_key = key;
    }
    public get proposed_device() {
        return this._proposed_device;
    }
    public async getSerializedPublicKey(): Promise<string> {
        return this._ephemeral_key.serialized();
    }

    public async getPublicKeyHash(): Promise<string> {
        const serialized_public_key = await this.getSerializedPublicKey();
        return Helpers.sha512Checksum(Helpers.utf8Encode(serialized_public_key)).then(b => Helpers.hexEncode(b).toLowerCase());
    }

    public async verifyHashAndCompleteHandshake(other_public_key_hash: string, public_key_hash: string, protocol_version: number): Promise<string | null> {
        this._other_public_key = await KeyFactory.deserializePublicUserKey(Helpers.b64Decode(other_public_key_hash));

        if (!this._other_public_key) {
            return Promise.reject("invalid public key");
        }

        const computed_hash = await Helpers.sha512Checksum(Helpers.utf8Encode(other_public_key_hash)).then(b => Helpers.hexEncode(b));

        if (computed_hash.toLowerCase() !== public_key_hash.toLowerCase()) {
            return Promise.reject("hash mismatch");
        }

        return this.getPin(protocol_version);
    }

    public async getPin(protocol_version: number): Promise<string> {
        const use_fips_derivation = await this.useFipsDerivation(protocol_version);
        if (!!this._other_public_key) {
            const shared_secret = await this._ephemeral_key.sharedSecret(this._other_public_key.public_key, use_fips_derivation);
            const encoded_secret = Helpers.hexEncode(await Helpers.sha512Checksum(shared_secret));
            return encoded_secret.substring(0, 8).toLowerCase();
        } else {
            throw new Error("Could not get pin");
        }
    }

    public async decryptMessage(encrypted_message: string, protocol_version: number): Promise<string> {
        const use_fips_derivation = await this.useFipsDerivation(protocol_version);
        if (!!this._other_public_key) {
            return this._ephemeral_key.decrypt(Helpers.b64Decode(encrypted_message), this._other_public_key.public_key, use_fips_derivation)
                .then(p => Helpers.utf8Decode(p));
        } else {
            throw new Error("Could not Decrypt Message, as other_public_key is undefined");
        }
    }

    public async encryptMessage(plaintext: string, protocol_version: number): Promise<string> {
        const use_fips_derivation = await this.useFipsDerivation(protocol_version);
        if (!!this._other_public_key) {
            return this._ephemeral_key.encrypt(Helpers.utf8Encode(plaintext), this._other_public_key.public_key, use_fips_derivation)
                .then(c => Helpers.b64Encode(c));
        } else {
            throw new Error("Could not Encrypt Message, as other_public_key is undefined");
        }
    }

    public async getProtocol(other_accepted_protocols: number[]): Promise<number> {
        const intersection = KeyExchangeManager.supported_protocols.filter(value => other_accepted_protocols.includes(value));

        if (intersection.length === 0) {
            return Promise.reject("no agreed upon protocol");
        }

        return Math.max(...intersection);
    }

    // Description: Return AppUserKey from decryptedMessage
    public async getDeserializeUserKey(decrypted_user_key: string): Promise<AppUserKey> {
        return await KeyFactory.deserializeUserKey(Helpers.b64Decode(decrypted_user_key));
    }

    // Prepare Public Key for Exchange send 
    public async preparePublicKey(): Promise<{ device_req: string; message: string; signature: string }> {
        this._proposed_device = {
            device_key: await KeyFactory.newUserKey(),
            id: new UUID().String(),
            name: "Browser"
        };
        const device_public_key = this._proposed_device.device_key.public_user_key;
        const device = {
            device_id: this._proposed_device.id,
            device_name: this._proposed_device.name,
            public_key: Helpers.b64Encode(device_public_key.serialize()),
            platform: "session",
            metadata: {}
        };

        const encoded_device_req = JSON.stringify(device);
        const encoded_public_key = await this.getSerializedPublicKey();
        const signature = await this._ephemeral_key.signature(Helpers.utf8Encode(encoded_device_req));
        return {
            device_req: encoded_device_req,
            message: encoded_public_key,
            signature
        };
    }

    private async useFipsDerivation(protocol_version: number): Promise<boolean> {
        if (!KeyExchangeManager.supported_protocols.includes(protocol_version)) {
            return Promise.reject("unsupported protocol version");
        }

        return protocol_version === 3;
    }

    // STATIC METHODS
    // -------------------------------------------------------------------------------------------
    // Description: Initialize this class with an EphemeralKey 
    static initKeyExchangeManager() {
        const _ephemeral_key = new EphemeralKey();
        return new KeyExchangeManager(_ephemeral_key);
    }
}
