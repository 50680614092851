import React, { useEffect, useState, useRef } from "react";
import { Card } from "react-bootstrap";
import { ActionHandlerFunction, CurrentThread, JSONEmail, MailboxItem, ThreadMessage } from "@preveil-api";
import { Account, MailThread, MailErrorMessages, useGetMessages, useGetWebMessages, AppConfiguration, MailUIActionTypes, MessageHandlerDisplayType, MailThreadMessage } from "src/common";
import { MessageHeader, MessageBody } from "./";
import { EmptyMailbox, } from "..";
import { Loading } from "src/components";
import _ from "lodash";

type AllProps = {
  current_thread: CurrentThread;
  isLoading: boolean;
  current_account: Account;
  current_mailbox: MailboxItem;
  offset: number;
  page?: string;
  handleAction: ActionHandlerFunction;
};

function ThreadViewComponent(props: AllProps) {
  const { current_thread, current_mailbox, current_account, isLoading, handleAction } = props;
  const is_web = AppConfiguration.buildForWeb();
  const [thread_subject, setThreadSubject] = useState<string>();
  const [messages, setMessages] = useState<ThreadMessage[]>();
  const [isError, setError] = useState<string | null>(null); // MailErrorMessages.error_getting_message
  const { data, errors, getAppMessages } = useGetMessages(current_account);
  const { data: web_data, errors: web_errors, getWebMessages } = useGetWebMessages(current_account);

  // Description: Allow ongoing getThreads callback to have access to current messages state
  const messagesRef = useRef(messages);
  function setActiveMessages(msgs: ThreadMessage[]) {
    messagesRef.current = msgs;
    setMessages(msgs);
  }

  useEffect(() => {
    return () => { // NOTE: Destroy current thread on leave
      handleAction({ actionType: MailUIActionTypes.ResetCurrentThread });
    };
  }, []);

  /*
  * Description: Get Active / current thread and messages 
  *             NOTES: We need to account for when threads are missing then get all information with unique_ids => block_ids 
  *             There are 2 ways to get the current thread: 
  *                 1. from thread_page (has all info except message body information = html / text )
  *                 2. In rare occasions (coming from search on fresh reloads as in bookmarks, there will be no information in threads_page)
  *                    for the current thread. In that case fetch the mail from collection directly and for app get ALL bodies, for web it
  *                    will be decrypted on callback
  *   
  */
  useEffect(() => {
    if (!!current_thread.messages && current_thread.messages.length > 0) {
      const crypto_thread = { thread_id: current_thread.thread_id, messages: current_thread.messages, mailbox_name: current_mailbox.name || "" };
      const _active_thread = new MailThread(crypto_thread);
      !thread_subject && setThreadSubject(_active_thread.subject);
      const thread_messages = _active_thread.messages.slice();
      handleSetNewThreads(thread_messages);
    } else {
      // NOTES: - Only set the empty error if current_thread is not undefined = finished call but no messages
      //        - On App need to get the threads from uniqueIds
      (!!current_thread?.encrypted_messages && current_thread.encrypted_messages.length > 0) ?
        handleGetAllMessagesFromIds(current_thread.encrypted_messages) :
        (!!current_thread && setError(MailErrorMessages.no_thread_found));
    }
  }, [current_thread]);

  // Description: Handle data coming in from custom hooks
  useEffect(() => {
    !!data && handleGetMessageBodySuccess(data);
    !!web_data && handleGetMessageBodySuccess(web_data);
    !!errors || !!web_errors && handleGetMessageBodyError(is_web ? web_errors : errors);
  }, [data, web_data, errors, web_errors]);

  // Description: if ThreadPage is not available or cant find active thread then fill messages from encrypted_messages (CurrentThread)
  //              - Used for coming from search or for exapmple when thread pagination information is not available (ie bookmark etc)
  function handleGetAllMessagesFromIds(encrypted_messages: JSONEmail[]) {
    const unique_ids = _.map(encrypted_messages, (json_mail: JSONEmail) => json_mail.id);
    !is_web && getAppMessages(unique_ids, true);
  }

  // Description: Add new mail data to message state
  function handleGetMessageBodySuccess(updated_messages: ThreadMessage[]) {
    if (!!messages && updated_messages.length > 0) {
      const new_messages = _.map(messages, (_message: ThreadMessage) => {
        const new_message = _.find(updated_messages, (updated_message: ThreadMessage) => {
          return (updated_message.message_id === _message.message_id) && (updated_message.mailbox_id === _message.mailbox_id);
        });
        const updated_message = !!new_message && MailThreadMessage.updateAttachments(new_message, _message.attachments);
        return updated_message || _message;
      });
      setActiveMessages(new_messages);
    } else if (updated_messages.length > 0) {
      setActiveMessages(updated_messages);
    }
  }

  function handleGetMessageBodyError(_errors: any) {
    // If message is passed
    const requested_message = _errors.requested_message;
    if (_errors.is_proxy) {
      !!requested_message && handleGetMessageBodySuccess([{ ...requested_message, ...{ html: `<p class='error'>${MailErrorMessages.error_getting_message}</p>` } }]);
      _errors.message &&
        handleAction({
          actionType: MailUIActionTypes.PageErrorMessage,
          params: {
            message: MailErrorMessages.error_getting_mail_threads,
            stack: _errors.stack,
            display_type: MessageHandlerDisplayType.toastr
          }
        });
    } else {
      _errors.message &&
        handleAction({
          actionType: MailUIActionTypes.PageErrorMessage,
          params: {
            message: MailErrorMessages.error_getting_mail_threads,
            stack: _errors.stack,
            display_type: MessageHandlerDisplayType.logger
          }
        });
    }
  }

  // Description: Update threads on new mail - coming thread messages counts will be different than local store messages
  function handleSetNewThreads(thread_messages: ThreadMessage[]) {
    let new_messages = thread_messages;
    if (!!messages) { // NOTE: do this on refresh too but keep previous state for those already there
      new_messages = _.map(thread_messages, (_message: ThreadMessage, i: number) => {
        const existing_message = _.find(messages, (m: ThreadMessage) => (m.message_id === _message.message_id) && (m.mailbox_id === _message.mailbox_id));
        // NOTE: Update flags and version from most current threads (server) for existing messages
        return !!existing_message ? { ...existing_message, ...{ flags: _message.flags, version: _message.version } } : _message;
      });
    }
    setActiveMessages(new_messages);
    initThreadMessagesBodies(new_messages);
  }

  // Description: After Setting the messages get the bodies of the ones displayed
  function initThreadMessagesBodies(new_messages: ThreadMessage[]) {
    const initial_load = !messages;
    // NOTE: initialize thread messages with first 3
    let fetch_messages: ThreadMessage[] = [];
    if (initial_load) {
      // NOTE: initialize thread messages -Mark unread if thread is unread
      const _chunks = _.chunk(new_messages, 3);
      fetch_messages = fetch_messages.concat(_chunks[0]);
      markThreadMessagesUnread(new_messages);
    } else if (!new_messages[0].html) { // New Message added:
      fetch_messages.push(new_messages[0]);
      markThreadMessagesUnread(new_messages);
    }
    // initialize thread when first 3 messages are not loaded
    if (fetch_messages.length > 0) {
      is_web ? getWebMessages(fetch_messages) : getAppMessages(fetch_messages.map(m => m.unique_id), true);
    }
  }

  // Description: Initialize threads by marking unread only on first load
  function markThreadMessagesUnread(_thread_messages: ThreadMessage[]) {
    const unseen_messages = MailThread.unseenMessages(_thread_messages);
    unseen_messages.length !== 0 && handleAction({ actionType: MailUIActionTypes.UpdateSeenFlag, params: { messages: unseen_messages, remove: false } });
  }

  // Description: Get Body on open of collapsible
  function handleGetBody(message: ThreadMessage) {
    const new_messages = [message];
    const previous_message = findPreviousMessage(message.in_reply_to);
    // NOTE: Don't push if previous_message has html field done
    (!!previous_message && !previous_message.html) && new_messages.push(previous_message);
    // Need to find message_id from message.in_reply_to
    is_web ? getWebMessages(new_messages) : getAppMessages(new_messages.map(m => m.unique_id), true);
  }

  // Description: Get message by Id 
  function findPreviousMessage(message_id: string | null): ThreadMessage | undefined {
    return (!!message_id && !!messages) ?
      _.find(messages, (_message => _message.message_id === message_id)) : undefined;
  }

  return <>
    {
      !!messages ?
        <div className="thread">
          <h2>{thread_subject}</h2>
          <>
            {
              _.map(messages, (_message: ThreadMessage, i: number) => {
                return <Card key={`thread_${i}`}>
                  <MessageHeader message={_message} user_id={current_account.user_id} handleAction={handleAction} />
                  {
                    !MailThreadMessage.isDraft(_message) &&
                    <MessageBody
                      is_web={is_web}
                      default_open={i <= 1}
                      message={_message}
                      messages={messages}
                      current_account={current_account}
                      collection_id={current_account.mail_cid}
                      handleGetBody={handleGetBody} />
                  }
                </Card>;
              })
            }
          </>
        </div> :
        isLoading ? <Loading className="in-place" /> :
          !!isError ? <EmptyMailbox message={isError} /> : <></>
    }
  </>;
}

export default React.memo(ThreadViewComponent);