import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { SupportedPlatform, useAppSelector } from "src/common";

function ManualInstructionsComponent(props: { show: boolean }) {
  const current_profile = useAppSelector((state) => state.account.current_account);
  const profile = current_profile?.profile;
  const mua_info = current_profile?.mua_info;
  const is_osx = !!(useAppSelector((state) => state.app.os_info)?.user_platform === SupportedPlatform.OSX);

  /* Description: Returns React Fragment for information based on the server - imap or smtp */
  function infoList(imap: boolean) {
    return (
      <>
        <Row className="secondary">
          <Col xs={5}>
            Username
          </Col>
          <Col>
            {!!imap ? mua_info?.imap_username : mua_info?.smtp_username}
          </Col>
        </Row>
        <Row>
          <Col xs={5}>
            Password
          </Col>
          <Col className="text-break">
            {!!imap ? mua_info?.imap_password : mua_info?.smtp_password}
          </Col>
        </Row>
        <Row className="secondary">
          <Col xs={5}>
            Address/IP
          </Col>
          <Col>{!!imap ? mua_info?.imap_addr : mua_info?.smtp_addr}</Col>
        </Row>
        <Row>
          <Col xs={5}>
            Port
          </Col>
          <Col>{!!imap ? mua_info?.imap_port : mua_info?.smtp_port}</Col>
        </Row>
        <Row className="secondary">
          <Col xs={5}>
            Requires SSL
          </Col>
          <Col>No</Col>
        </Row>
        <Row>
          <Col xs={5}>
            Override default port
          </Col>
          <Col>Yes</Col>
        </Row>
      </>
    );
  };

  return props.show ? (
    <>
      <h2 className="content-header border-top pt-3">Manually Add to Other Mail Clients</h2>
      <h4>Set your IMAP settings in your email client<i className="required">*</i></h4>
      <p className="text-muted sub-text">
        Use the table below to update your client with the correct information. For help updating
        your settings, search your email client's Help Center for instructions on setting up IMAP.
      </p>
      <div className="mail-client-details mb-3">
        <Row className="gray-background">
          <Col xs={4}>
            Full Name or Display Name
          </Col>
          <Col>(Encrypted) {profile?.name}</Col>
        </Row>
        <Row>
          <Col xs={4}>
            Email Address
          </Col>
          <Col>{profile?.address}</Col>
        </Row>
        <Row>
          <Col xs={7} lg={4} className="gray-background">
            Incoming Mail (IMAP) Server
          </Col>
          <Col className="p-0 fw-normal">{infoList(true)}</Col>
        </Row>
        <Row>
          <Col xs={4}>
            Outgoing Mail (SMTP) Server
          </Col>
          <Col className="p-0 fw-normal">{infoList(false)}</Col>
        </Row>
      </div>
      {is_osx ? ( <p>
        <span className="text-primary">*For Outlook on Mac:</span> Click on <em>"More Options"</em>{" "}
        under the Outgoing Server settings, then select <em>"None"</em> for Authentication type.
      </p> ) : null}
    </>
  ) : null;
}

export default React.memo(ManualInstructionsComponent);
