import { useState, useCallback, useRef, useEffect } from "react";
import { MessageDisplayType, ErrorStackDataType } from "@preveil-api";
import { FSMessage, FSRole, FSStatus, FSType, FSWrappedKey, SealedContent } from "src/common/keys/protos/collections_pb";
import {
  Account, useAppDispatch, Message, MessageHandlerDisplayType, useSendRequestMutation, CollectionFilesyncAPI, DriveErrorMessages, useAppSelector,
  DriveRequest, DriveTrashItem, getEnumKey, PermissionSetType, PermissionSet, Grant, getDirSymmKey, decryptItemName, GrantSet, DriveEntryTypes,
  useGetPermissions, mapEntryType, TrashErrorMessages, dayjs, ErrorStackItem, useGetGrants,
  BrowseTrashApiResponse,
  filesyncApi
} from "src/common";
import { RootState } from "src/store/configureStore";
import { DriveCallbackAsyncFunction, uiActions } from "src/store";

/* Description: This is the web hook for fetching items in trash. */
export function useFetchTrash(current_account: Account, use_filesync: boolean = false) {
  const default_permissions = useAppSelector((state: RootState) => state.drive.default_permissions);
  const default_grants = useAppSelector((state: RootState) => state.drive.default_grants);
  const [trash_items, setTrashItems] = useState<DriveTrashItem[]>();
  const [error, setError] = useState<boolean>(false);
  const { permissions, getPermissions, destroyPermissions } = useGetPermissions(current_account);
  const { grants, error: grant_error, getGrants, destroyGrants, maintainer_node } = useGetGrants(current_account, true);
  const [browse_trash] = filesyncApi.endpoints.browseTrash.useLazyQuery();
  const [sendRequest] = useSendRequestMutation();
  const dispatch = useAppDispatch();

  const permissions_ref = useRef(permissions);
  function setPermissionsRef(_permissions?: PermissionSetType) {
    permissions_ref.current = _permissions;
  }

  const trash_items_ref = useRef<FSMessage.DeletedEntry[]>([]);
  const trash_item_ref = useRef<FSMessage.DeletedEntry>();
  const temp_entries_ref = useRef<DriveTrashItem[]>([]);

  useEffect(() => {
    if (!!permissions) {
      setPermissionsRef(permissions);
      // NOTE: Return the permission state to undefined to be able to detect future chages
      destroyPermissions();
      getTrashItemName();
    }
  }, [permissions]);

  // Description: useEffect hook for when grants (from the useGetGrants hook) is initialized.
  useEffect(() => {
    const item = trash_item_ref.current;
    if (!!grants && !!item && !!permissions_ref.current) {
      const key_version = item.getWrappedDirKey()?.getKeyVersion();
      const grant = GrantSet.getGrantbyRole(grants, key_version);
      const key = item.getReadKeyNodeId_asB64() === item.getId_asB64() && !!maintainer_node ? maintainer_node.getWrappedDirKey() : undefined;
      !!grant && !!maintainer_node && decryptName(item, permissions_ref.current, grant, key, maintainer_node.getMaintainerScopedName());
    }

    grant_error && handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_grant });
  }, [grants, grant_error]);

  // Description: If v2 collection - gets grants and then decrypts name, If V1 collection - decrypt name directly with permissions
  function getTrashItemName() {
    if (!!permissions_ref.current && !!trash_item_ref.current) {
      const permission_set = PermissionSet.init(permissions_ref.current);
      const access = permission_set.permission(FSRole.ACCESS);
      if (!!access) {
        getGrants(trash_item_ref.current.getReadKeyNodeId_asB64(), trash_item_ref.current.getCollectionId_asB64(), default_permissions, default_grants);
      } else {
        decryptName(trash_item_ref.current, permissions_ref.current);
      }
    }
  }

  // Description: fetches all items in trash through the FETCH_DELETED backend call
  async function fetchTrashItems() {
    const drive_request = await CollectionFilesyncAPI.fetchDeletedEntries(current_account);
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        const deleted = message.getDeletedList(); // 1163 items
        trash_items_ref.current = deleted;
        trash_item_ref.current = trash_items_ref.current.pop(); // uses a stack to get permissions of each item (incase it is not in default permissions)
        if (!!trash_item_ref.current) {
          while (trash_item_ref.current?.getType() === FSType.LINK || (!trash_item_ref.current?.getDeletedBy() || trash_item_ref.current?.getDeletedBy().length <= 0)) {
            trash_item_ref.current = trash_items_ref.current.pop(); // filter out links
          }
        }
        !!trash_item_ref.current ? getPermissions(trash_item_ref.current.getCollectionId_asB64(), default_permissions) : setTrashItems(temp_entries_ref.current);
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: TrashErrorMessages.error_fetching_trash }, message);
      }
    }
    handleSendRequest(drive_request, callback, TrashErrorMessages.error_fetching_trash);
  }

  // Description: Decrypts trash item name using the parent directory key and, if v2 collection - the grant key 
  async function decryptName(item: FSMessage.DeletedEntry, permissions: PermissionSetType, grant?: Grant, dir_key?: FSWrappedKey, m_name?: SealedContent) {
    const user_key = !!grant ? await grant.key(current_account) : undefined;
    const parent_wrapped_key = !!dir_key ? dir_key : item.getWrappedDirKey();
    const parent_key = !!parent_wrapped_key ? await getDirSymmKey(parent_wrapped_key, current_account, permissions, user_key) : undefined;
    const name = item.getReadKeyNodeId_asB64() === item.getId_asB64() && !!m_name ? m_name.getContent_asU8() : item.getName_asU8();
    const decoded_name = !!parent_key && !!name ? (await decryptItemName(name, parent_key, user_key)) : undefined;
    const deleted_at = item.getDeletedAt();
    const deleted_by_item = item.getDeletedBy();
    const is_acl_node = item.getReadKeyNodeId_asB64() === item.getId_asB64();
    const type = (getEnumKey(FSType, item.getType()) as DriveEntryTypes) || null;
    if (!!decoded_name) {
      const entry_info = !!type ? mapEntryType(type, decoded_name) : null;
      const trash_item = {
        name: decoded_name,
        collection_id: item.getCollectionId_asB64(),
        id: item.getId_asB64(),
        type,
        type_label: entry_info?.type_label,
        deleted_at: !!deleted_at ? dayjs.unix(deleted_at).format("MM/DD/YYYY h:mm A") : "",
        deleted_by: !!deleted_by_item ? deleted_by_item.toString() : "",
        parent_id: item.getParentId_asB64(),
        maintainer_id: item.getReadKeyNodeId_asB64(),
        parent_dir_key: parent_key,
        is_acl_node,
      };
      temp_entries_ref.current.push(trash_item);
    }
    trash_item_ref.current = trash_items_ref.current.pop();
    if (!!trash_item_ref.current) {
      while (trash_item_ref.current?.getType() === FSType.LINK || (!trash_item_ref.current?.getDeletedBy() || trash_item_ref.current?.getDeletedBy().length <= 0)) {
        trash_item_ref.current = trash_items_ref.current.pop(); // filter out links
      }
    }
    !!trash_item_ref.current ? getPermissions(trash_item_ref.current.getCollectionId_asB64(), default_permissions) : setTrashItems(temp_entries_ref.current);
  }

  // Description: Sends Request and handles error when the request object is null, pass it custom stack message
  function handleSendRequest<T>(request: DriveRequest | null, callback: DriveCallbackAsyncFunction<T>, stack_error: string = DriveErrorMessages.error_sending_request) {
    if (!!request) {
      request.setCallback(callback);
      sendRequest(request);
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, { stack_error, stack_message: DriveErrorMessages.error_sending_request }, null);
    }
  }

  // Description: Handle error message and send error to store
  function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, fsmessage?: FSMessage | null, display_type: MessageDisplayType = MessageHandlerDisplayType.toastr) {
    const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
    const stack = new ErrorStackItem("[useFetchTrash Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
    dispatch(uiActions.handleRequestErrors(new Message(message, display_type), stack));
    setError(true);
  }

  function fetchTrashForApp() {
    browse_trash({
      user_id: current_account.user_id
    })
      .unwrap()
      .then((response: BrowseTrashApiResponse) => {
        const _trash_items = response.map((item) => {
          const entry_info = !!item.type ? mapEntryType(item.type, item.name) : null;
          return {
            name: item.name,
            collection_id: item.coll_id,
            id: item.id,
            type: item.type, 
            type_label: entry_info?.type_label,
            deleted_at: !!item.deleted_at ? dayjs.unix(item.deleted_at).format("MM/DD/YYYY h:mm A") : "",
            deleted_by: item.deleted_by,
            parent_id: item.parent_id,
            is_acl_node: !!item.acl
          };
        });
        setTrashItems(_trash_items);
      })
      .catch((stack_error: unknown) => {
        handlePageErrorMessage(DriveErrorMessages.default, {
          stack_message: TrashErrorMessages.error_fetching_trash,
          stack_error
        });
      });
  }

  // Description: callback for fetching items in trash
  const fetchTrashList = useCallback(() => {
    if (use_filesync) {
      fetchTrashForApp();
    } else {
      fetchTrashItems();
    }
  }, []);

  // Description: reset the hook
  const resetFetchTrash = useCallback(() => {
    setError(false);
    setTrashItems(undefined);
    temp_entries_ref.current = [];
    trash_items_ref.current = [];
    trash_item_ref.current = undefined;
    destroyGrants();
  }, []);

  return {
    fetchTrashList,
    resetFetchTrash,
    error,
    trash_items,
  };
}
