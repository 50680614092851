import keyMirror from "keymirror";
import { FSWrappedKey, FSMessage, Node, FSRequest, SealedContent, FSRoleMap, FSRole, FSTypeMap } from "src/common/keys/protos/collections_pb";
import { ShareInviteMetadata } from "../keys/protos/invite_pb";
import { SymmKey } from "src/common/keys";
import { AppUserKey, COLLECTION_PROTOCOL_VERSIONS, PermissionSet, Grant, GrantSet, UUID, PermissionSetType } from "src/common";

/* ------------------------------------------------------------------------------
 * Description: Constants and general types
 * ---------------------------------------------------------------------------- */
export const DefaultCollectionName: string = "defaultCollection";
export type DefaultCollectionType = typeof DefaultCollectionName;
export const PreveilDownloads: string = "PreVeil Downloads";
// NOTE: Filesync returns this string for empty linkedCollectionID field for browse calls
export const FSEmptyLinkedCollectionID = "AAAAAAAAAAAAAAAAAAAAAA==";
export const AppCollectionShareSnapshotSizeMax = 500; // NOTE: Share through CS up to this Snapshot size

export interface DriveUrlParams {
  collection_id: string;
  id?: string;
  file_id?: string;
  item_type?: string;
}

export const DriveTypeMapping = {
  DIR: "Folder",
  LINK: "Shared Folder",
  FILE: "File",
  ACL_NODE: "Folder"
};
export type DriveTypeMappingType = keyof typeof DriveTypeMapping;

export const DriveConstants: Record<string, string> = { // DriveConstants.DIRECT_LINK_MASK_ID
  DEFAULT_DRIVE: "Drive",
  SHARED_DRIVE: "Shared",
  TRASH_DRIVE: "Trash",
  INVITATIONS_DRIVE: "Invitations",
  ROOT_DIRECTORY: "My PreVeil",
  DIRECT_LINK_MASK_ID: "list"  // Legacy: DEFAULT_ROOT_SUB_NAV
};

// Legacy: DRIVE_ROUTE_DIR_TYPE: "d", DRIVE_ROUTE_LINK_TYPE: "l", DRIVE_ROUTE_FILE_TYPE: "f", DRIVE_ROUTE_DEFAULT_TYPE: "r", DRIVE_ROUTE_DIRECT_LINK_TYPE: "dl"
export const DriveRouteType: Record<string, string> = {
  DIR: "d",
  LINK: "l",
  FILE: "f",
  DIRECT_LINK: "dl", // LEGACY
  ACL_NODE: "dl" // LEGACY
};

export const DriveEntryType = keyMirror({
  DIR: null,
  LINK: null,
  FILE: null,
  ACL_NODE: null
});
export type DriveEntryTypes = keyof typeof DriveEntryType; //  "LINK" | "DIR" | "FILE";

// Description Map objects for EntryInfo (friendly labels) - Legacy: DRIVE_MAPPED_TYPES
export const DriveFileType = {
  Folder: "Folder",
  Pending_Folder: "Folder Syncing",
  Synced_Folder: "Synced Folder",
  Shared_Folder: "Shared Folder",
  Synced_Shared_Folder: "Synced Shared Folder",
  Partially_Synced_Folder: "Partially Synced Folder",
  Partially_Synced_Shared_Folder: "Partially Synced Shared Folder",
  File: "File",
  PDF: "PDF",
  Word: "Word",
  Excel: "Excel",
  PowerPoint: "PowerPoint",
  Image: "Image",
  Text: "Text",
  Code: "Code",
  Archive: "Archive",
  Audio: "Audio",
  Video: "Video",
};

// Description Map objects for EntryInfo icons
export const DriveEntryIcons = {
  Folder: "fsi-folder", // Defaults to Unsynced for web
  Shared_Folder: "fsi-shared",  // Defaults to Unsynced for web
  File: "pv-icon-file",
  PDF: "pv-icon-file-pdf",
  Word: "pv-icon-file-word",
  Excel: "pv-icon-file-excel",
  PowerPoint: "pv-icon-file-powerpoint",
  Image: "pv-icon-file-image",
  Text: "pv-icon-file-text",
  Code: "pv-icon-file-code",
  Archive: "pv-icon-file-zip",
  Audio: "pv-icon-file-audio",
  Video: "pv-icon-file-video"
};

export const DocRenderTypes = keyMirror({
  viewer: null,
  image: null,
  text: null,
});

export interface ACLInfo { // LEGACY: interface AclObject
  acl_reader: boolean;
  acl_writer: boolean;
  expiration: string;
  grantees: Grantee[] | null;  // NOTES: this is null when the call is not to Link
  node_id: string;
  reader: boolean;
  // shareable?: boolean; // NOTE: NOT NEEDED **** Should remove from Filesync call
  viewer: boolean;
  writer: boolean;
}

// DRIVE SEARCH types
export interface SnapshotResult extends NodeIdentifier {
  snapshot: FSMessage.Snapshot;
  permissions: PermissionSetType;
};


/* ------------------------------------------------------------------------------
 * Description:  Types for Filesync (App mode)
 * ---------------------------------------------------------------------------- */
export enum SELECTIVE_SYNC_STATES {
  OFF,
  ON,
  PARTIAL,
  Pending,
  InheritedON,
  InheritedOFF
}

export const FolderIconSyncState: { [key in SELECTIVE_SYNC_STATES]: string } = {
  0: "fsi-folder",
  1: "fsi-synced",
  2: "fsi-unsynced-partial",
  3: "fsi-pending",
  4: "fsi-synced",
  5: "fsi-folder"
};

export const SharedFolderIconSyncState: { [key in SELECTIVE_SYNC_STATES]: string } = {
  0: "fsi-shared",
  1: "fsi-shared-synced",
  2: "fsi-shared-unsynced-partial",
  3: "fsi-shared-pending",
  4: "fsi-shared-synced",
  5: "fsi-shared",
};

export interface DriveNodeBase {
  id: string;
  name: string;
  entries: EntryItemLocal[];
}

// Description: Filesync Type -  Data returned from  for /browse/drive
export interface DriveRoot extends DriveNodeBase {
  localSyncStatus: SELECTIVE_SYNC_STATES;
  rootid: string;
  rootacl: ACLInfo;
}

export interface DriveDirectory {
  entries: EntryItemLocal[];
  acl: ACLInfo;
  path: PathInfo[];
}

export interface DriveDirectoryPaginated extends DriveDirectory {
  localSyncStatus: SELECTIVE_SYNC_STATES;
  next_seq: number;
  next_subseq: number;
  total_count: number;
}

/* ------------------------------------------------------------------------------
 * Description:  Types for Both App and Web
 * ---------------------------------------------------------------------------- */
// NOTE: Building types of FSMessage 
export type DirectoryWrappedKey = FSWrappedKey;

export interface NodeIdentifier {
  id: string;
  collection_id: string;
  maintainer_id?: string; // maintainer_id is the node_id of the ACL node that provides the permission for the operation. 
}

// Description: Link types
export interface LinkEntity extends NodeIdentifier {
  collection_protocol_version: COLLECTION_PROTOCOL_VERSIONS;
  version: string;
  deleted?: boolean;
  linked_collection_id?: string;
  is_expunge?: boolean;
  last_modified_device_id: string;
  acl_tree?: FSMessage.ACLTree;
  parent_id?: string;
}

export interface CollectionEntity extends NodeIdentifier {
  collection_name: string;
  collection_protocol_version: COLLECTION_PROTOCOL_VERSIONS;
  root_id: string; // Only set for V1 collections
  shared_with: NodePermissionSet[];
  permissions: NodePermissionSet;
  node_type: DriveEntryTypes; // Include the node type which will correspond to a DIR or a LINK depending on the EntryItem used to build the CollectionEntity
  is_root: boolean; // NOTE: For V2 collections it describes true for True roots and false for acl_nodes
  is_maintainer: boolean; // a shared folder under a V2 collection maybe true root or a disjointed acl_node
  disjointed_acl_node: boolean; // Is this a child dir node of a V2 collection
}

export interface CollectionInfo {
  [key: string]: CollectionEntity;
};

// Description: Directories
export interface DirectoryEntity extends NodeIdentifier {
  acl?: ACLInfo | null; // ACL_NODE;
  entries: EntryItem[]; //  ENTRIES[];
  localSyncStatus: SELECTIVE_SYNC_STATES; // SELECTIVE_SYNC_STATES OR number;
  path?: PathInfo[];
  path_list?: PathNode[];
  wrapped_key?: FSWrappedKey,
  symmetric_key?: SymmKey;
  version: string;
  grants?: GrantSetType;
  parent_id?: string;
}

export interface DirectoryInfoEntity extends NodeIdentifier {
  name: string;
  version: string;
  wrapped_dir_key?: FSWrappedKey;
}

// Description: Each directory item set a base to initialize parent collection 
export interface EntryItemBase extends NodeIdentifier {
  linked_collection_id: string; // will be linkedCollectionID  in app
  type: DriveEntryTypes;
  name?: string;
  lock_info?: LockInfo;
  is_locked?: boolean;
}

export interface EntryItem extends EntryItemBase, EntryInfo {
  acl?: ACLInfo;
  deleted: boolean;
  deleted_at: number;
  lastModificationDate: string;
  localSyncStatus: SELECTIVE_SYNC_STATES;
  name: string; //  NOTE: make name a required param
  size: number; //  NOTE: Always 0 for now
  parent_id?: string;
}
export interface EntryItemLocal extends EntryItem {
  linkedCollectionID?: string;
  lockInfo?: LockInfo;
}

export type LockInfo = FSMessage.LockInfo.AsObject;
export type EntryItemType = keyof EntryItem;

// Descriptioon:  UI interface to hold UI information such as: icon type, labels, etc...
export interface EntryInfo {
  mapped_type?: DriveEntryTypes;
  type_class?: string;
  type_label?: string;
}

export interface DirectoryPaginationInfo {
  total_count: number;
  has_more?: boolean;
  next_seq?: number;
  next_subseq?: number;
}

// Description: Node information
export interface PathInfo {
  path: DefaultCollectionType | string; // NOTE: defaultCollection refers to ROOT
  id: string;
  url?: string;
}

export interface DriveInvitationInfo {
  collection_id: string;
  share_id: string;
  link_id?: string;
  name: string;
  sharer_user_id: string;
  expiration: string;
  permissions_label: string;
  sync_by_default: boolean;
  processing: keyof typeof ProcessingType;
}
export const ProcessingType = keyMirror({
  accepting: null,
  rejecting: null,
  not_processing: null,
  accepted: null
});

export const SortFieldType = keyMirror({
  name: null,
  type_label: null,
  lastModificationDate: null,
  // add more ....
});
export const SearchFieldType = keyMirror({
  name: null,
  last_modification_date: null,
});

export type SortType = EntryItem /* & .. add more.. */
export interface DriveSharedInfo {
  id: string;
  name: string;
  linked: boolean;
  path: PathInfo[];
  link_id: string;
  processing: boolean;
  status: string;
}

export interface DriveTrashItem extends NodeIdentifier {
  is_acl_node?: boolean;
  name: string;
  type: DriveEntryTypes;
  type_label?: string;
  deleted_at: string;
  deleted_by: string;
  parent_id: string;
  parent_dir_key?: SymmKey;
}

export interface PathNode extends PathInfo {
  id: string;
  deleted?: boolean; // Web only
  wrapped_name: Uint8Array;
  wrapped_dir_key?: FSWrappedKey; // Web only
  key_version?: number; // Web only
  maintainer_id?: string;  // Web only
  maintainer_node?: MaintainerPathNode; // Web only
}

// Description: Maintainer information for getting PathNode content (name)
export interface MaintainerPathNode {
  scoped_name?: SealedContent;
  key_version?: number;
  content?: Uint8Array;
}

export type VersionHistoryItem = {
  last_modified: string;
  size: string;
  version: string;
};

// -------------------------------------------------------
// Grants, Permissions and Node permissions for Sharing
// -------------------------------------------------------
// Description: - key is the user_id of the sharee
export interface GranteeBase {
  user_id: string;
  expiration: string;
  view_only: boolean;
  pending: boolean;
  is_owner: boolean;
}

export interface GranteeInfo extends GranteeBase {
  role: FSRoleType;
  acl: ACLRoleType;
}

// - acl type is an array of numbers [1, 2, 3, 4]
export interface Grantee extends GranteeBase {
  acl: ACLRoleType[];
  roles?: FSRoleType[];
}

export type ActiveGrants = {
  user_id: string;
  grant: Grant;
}

export type ActiveGrantSet = {
  [key: string]: GrantSetType | null;
}

export interface GrantItem {
  user_key_version: number;
  expiration: string;
  grant?: SealedContent;
  role?: ACLRoleType;
  role_key_version: number;
  view_only?: boolean;
}

export interface FolderSyncState {
  node_id: string;
  sync: boolean;
}

export interface SelectiveSyncRulesMap {
  [collection_id: string]: FolderSyncState[];
}

export type ACLRoleType = Node.ACLRoleMap[keyof Node.ACLRoleMap];
export type FSRoleType = FSRoleMap[keyof FSRoleMap];
export type FSTypeMapType = FSTypeMap[keyof FSTypeMap];
export type GrantSetType = Pick<GrantSet, "collection_id" | "id" | "user_id" | "grants">;

// NOTE: Drive constants for settings
// -------------------------------------------------------
export const NodePermissionType = keyMirror({
  edit_and_share: null,
  edit: null,
  read_only: null,
  view_only: null,
  unshare: null
});
export type NodePermissionTypes = keyof typeof NodePermissionType;

export const NodePermissionTooltips = {
  edit_and_share: "People can view, modify and download items in a shared folder as well as share it.",
  edit: "People can view, modify and download items in a shared folder, but not share it.",
  read_only: "People can view and download items in a shared folder, but not add, edit, or delete files.",
  view_only: "People can view items in a shared folder, but not add, edit, or delete files; none of the items are synced locally.",
  unshare: "Remove all access to this shared folder from this user."
};

export const NodePermissionLabels = {
  select_a_permission: "Select a Permission",
  edit_and_share: "Edit & Share",
  edit: "Edit",
  read_only: "Read Only",
  view_only: "View Only",
  unshare: "Unshare",
};
export type NodePermissionsName = "Edit & Share" | "Edit" | "Read Only" | "View Only" | "Unshare";

export interface NodePermissionSet extends GranteeBase {
  label: string;
  type: NodePermissionTypes;
  read: boolean;
  write: boolean;
  share: boolean;
  sync: boolean;
  locked: boolean;
  // NOTE: effective root or a fake dir created for recipients of a disjointed_acl_node 
  // or the parent "fake/pseudo" directory created for disjointed acl nodes
  effective_root_node?: boolean;
  disjointed_maintainer?: boolean; // NOTE: A Disjointed acl  maintainer node - only the maintainer is true
}

// These are used to check NodePermissionSet additional rules in feature class
export const NodePermissionSetRules = keyMirror({
  view_only: null,
  pending: null,
  is_owner: null,
  read: null,
  write: null,
  share: null,
  sync: null
});
export type NodePermissionSetRuleKeys = keyof typeof NodePermissionSetRules;

export type NodePermissionChange = Pick<NodePermissionSet, "type" | "expiration">
export type GranteesNodePermissions = { [key: string]: NodePermissionSet & { class_name?: string } };

// Description: Convert NodePermissionTypes to ACLRoleType[]
export const NodePermissionTypeToACLRoleType: { [key: string]: ACLRoleType[] } = {
  edit_and_share: [Node.ACLRole.READER, Node.ACLRole.WRITER, Node.ACLRole.ACL_READER, Node.ACLRole.ACL_WRITER],
  edit: [Node.ACLRole.READER, Node.ACLRole.WRITER, Node.ACLRole.ACL_READER],
  read_only: [Node.ACLRole.READER],
  view_only: [Node.ACLRole.READER],
  unshare: []
};

export const PermissionsShareType: { [key: string]: ShareInviteMetadata.ShareTypeMap[keyof ShareInviteMetadata.ShareTypeMap] } = {
  edit_and_share: ShareInviteMetadata.ShareType.EDIT_AND_SHARE,
  edit: ShareInviteMetadata.ShareType.EDIT,
  read_only: ShareInviteMetadata.ShareType.READ_ONLY,
  view_only: ShareInviteMetadata.ShareType.VIEW_ONLY,
  unshare: ShareInviteMetadata.ShareType.VIEW_ONLY
};

// Description: Convert NodePermissionTypes & "Owner" to FSRoleType[]
export const NodePermissionTypeToFSRoleType: { [key: string]: FSRoleType[] } = {
  owner: [FSRole.OWNER, FSRole.READER, FSRole.WRITER, FSRole.ACL_VIEWER, FSRole.SHARER, FSRole.LOG_VIEWER],
  edit_and_share: [FSRole.READER, FSRole.WRITER, FSRole.ACL_VIEWER, FSRole.SHARER],
  edit: [FSRole.READER, FSRole.WRITER, FSRole.ACL_VIEWER],
  read_only: [FSRole.READER],
  view_only: [FSRole.READER],
  unshare: []
};

// Description: V1 collections should not include  FSRole.ACCESS
export const FSRolesByCollectionProtocol: { [key: string]: FSRoleType[] } = {
  V1: [FSRole.OWNER, FSRole.READER, FSRole.WRITER, FSRole.ACL_VIEWER, FSRole.SHARER, FSRole.LOG_VIEWER],
  V2: [FSRole.OWNER, FSRole.READER, FSRole.WRITER, FSRole.ACL_VIEWER, FSRole.SHARER, FSRole.LOG_VIEWER, FSRole.ACCESS]
};

// ---------------------------------------------------------------------------------
// Description: Types for UI, Args, API call requests and responses
// ---------------------------------------------------------------------------------
export interface RootInfo extends NodeIdentifier {
  localSyncStatus: number;
  name: string;
  acl?: ACLInfo;
  version: string | null;
  wrapped_dir_key: DirectoryWrappedKey | null;
};

// -------------------------------------------
// Types for params in actions
// -------------------------------------------
// For initializing new drive and default collection:
export interface InitCollectionData {
  keys: FSRequest.Init.Key[];
  new_permissions: PermissionSet;
  dir_symm_key: SymmKey;
}

export interface InitCollectionEncryptedData {
  key_data: InitCollectionData;
  encrypted_name: Uint8Array; // using FSRole.READER Key
  wrapped_key: Uint8Array;
}

export interface InitV2CollectionEncryptedData extends InitCollectionEncryptedData {
  encrypted_name: Uint8Array; // using FSRole.READER Key
  encrypted_access_name: Uint8Array; // Required for initializing V2 collections using FSRole.ACCESS Key
  maintainer_scoped_name: SealedContent;
  wrapped_key: Uint8Array;
  node_permissions: { [role: number]: AppUserKey };
}

export interface BulkUpdateParams {
  create: FSRequest.Create[];
  updates: FSRequest.Update[];
  blocks: FSRequest.BulkUpdate.Block[];
}

export interface LogKeyGrantAccessData {
  group: FSRequest.Grant.Group;
  role: FSRequest.Grant.Role;
}

export interface GrantAccessData {
  roles: FSRequest.Grant.Role[];
  grant_users: FSRequest.Grant.User[]
}

export type NodeKeys = { [role: number]: AppUserKey };

export interface GrantsAndPermissions {
  grants: Node.Grant[];
  permissions: Node.Permission[];
  node_keys?: NodeKeys;
}

// -------------------------------------------
// Types for FILE UPLOAD
// -------------------------------------------
export interface ActiveUpload {
  coll_id: string;
  dir_id: string;
  dir_version: string;
  wrapped_dir_key: FSWrappedKey;
  dir_symm_key: SymmKey;
  file_name: string;
  file_id: UUID;
  total_blocks: number;
  completed_block_ids: string[];
  grants?: GrantSetType;
}

export interface UploadFile {
  file_id: UUID;
  file: File;
  conflict: boolean;
  new_name?: string;
}

export interface UploadDestination {
  id: string;
  directory?: DirectoryEntity;
  tracker_id?: string;
}

export interface ProgressTracker {
  file_id: string;
  file_name: string;
  destination: UploadDestination;
  done: number;
  total: number;
  error?: string;
  conflict?: boolean;
  entries?: string[];
}

export interface DonwloadProgressTracker {
  file_id: string;
  file_name: string;
  done: number;
  total: number;
  error?: string;
}

export interface UploadRequest {
  collection_id: string;
  node_id: string;
  files: File[];
  linked_collection_id?: string;
  destination: UploadDestination;
}

export interface DownloadRequest {
  collection_id: string;
  file_id: string;
  file_name: string;
}
export interface EntryUpdate {
  new_entries: number;
  latest: EntryItem;
  destination_id: string;
}

export const DriveReservedNames: string[] = [
  "com1",
  "com2",
  "com3",
  "com4",
  "com5",
  "com6",
  "com7",
  "com8",
  "com9",
  "lpt1",
  "lpt2",
  "lpt3",
  "lpt4",
  "lpt5",
  "lpt6",
  "lpt7",
  "lpt8",
  "lpt9",
  "con",
  "nul",
  "prn",
  "aux",
];
