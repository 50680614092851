import React, { useState, useRef } from "react";

type AllProps = {
  iframeHead?: string;
  srcDoc: string;
  title: string;
  height: string;
  width: string;
  sandbox: string;
}


function InlineIframeComponent(props: AllProps) {
  const { iframeHead, srcDoc, title, width, sandbox } = props;
  const [iFrameHeight, setIFrameHeight] = useState("0");
  const iframeRef = useRef<HTMLIFrameElement>(null);
  return (
    <iframe
      className="inline-iframe"
      onLoad={() => {
        // Delay the set setIFrameHeight to allow for inline image tags loading 
        // If needed, Integrate: https://www.npmjs.com/package/iframe-resizer-react
        setTimeout(() => {
          !!iframeRef.current?.contentWindow && setIFrameHeight(`${iframeRef.current?.contentWindow.document.body.scrollHeight + 20}px`);
        }, 200);

        // Insert the header information directly into the iframe 
        if (!!iframeHead && !!iframeRef.current?.contentDocument) {
          const head = iframeRef.current.contentDocument.getElementsByTagName("head");
          (!!head && head.length > 0) && head[0].insertAdjacentHTML("beforeend", iframeHead);
        }
      }}
      ref={iframeRef}
      srcDoc={srcDoc}
      title={title}
      width={width}
      height={iFrameHeight}
      scrolling="no"
      sandbox={sandbox}
    />
    // NOTE: Scrolling is deprecated but there is no clean way to remove scrollbars from the iframe
    // TO DO: find a better way. html and body has to be overflow:visible for height determination
  );
};

export default React.memo(InlineIframeComponent);
