import React, { ReactElement, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  useAppSelector, NavigationPath, NavigationPathTypes, getDefaultPageTitle, MailConstants, DriveConstants, useDocumentTitle, getSubPath,
  UIGridBreakpoints, PanelState, useAppDispatch
} from "src/common";
import { ErrorBoundary, TopNavigation, SplitPaneTemplate, DefaultTemplate } from "src/components";
import { RootState } from "src/store/configureStore";
import { uiActions } from "src/store";

interface AllProps {
  children: ReactElement;
  type?: NavigationPathTypes;
  className?: string;
}

function WrapperComponent(props: AllProps) {
  const { children, type, className } = props;
  const current_location = useLocation();
  const [active_path, setActivePath] = useState<string>();
  const location = useLocation();
  const current_account = useAppSelector((state: RootState) => state.account.current_account);
  const organization = useAppSelector((state: RootState) => state.account.organization);
  const accounts = useAppSelector((state: RootState) => state.account.accounts);
  const document_title = useAppSelector((state: RootState) => state.ui.document_title);
  const left_nav_state = useAppSelector((state: RootState) => state.ui.left_nav_state);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const path = getSubPath(current_location.pathname);
    !!path && setActivePath(path);
    // NOTE: If left nav is opened and it is mobile, close it on navigation 
    (window.innerWidth <= UIGridBreakpoints.md && left_nav_state === PanelState.on) &&
      dispatch(uiActions.handleToggleLeftNavigation(PanelState.off));
  }, [current_location]);

  useDocumentTitle(getTitlebyType());
  function getTitlebyType(): string {
    const user_id_title = current_account?.user_id || "PreVeil";
    switch (type) {
      case NavigationPath.MailNavigation: // This will depend on the active mailbox
        return `${document_title || MailConstants.DEFAULT_MAILBOX} - ${user_id_title}`;
      case NavigationPath.DriveNavigation: // This will depend on the active drive folder
        return `${document_title || DriveConstants.DEFAULT_DRIVE} - ${user_id_title}`;
      default:
        return getDefaultPageTitle(location.pathname);
    }
  }

  return (
    <ErrorBoundary>
      <>
        <TopNavigation
          current_account={current_account}
          organization={organization}
          accounts={accounts}
          type={type}
          active_path={active_path} />
        <div className={`wrapper${!!className ? " " + className : ""}`}>
          {
            !!type ?
              <SplitPaneTemplate type={type} active_path={active_path}>
                {children}
              </SplitPaneTemplate> :
              <DefaultTemplate>{children}</DefaultTemplate>
          }
        </div>
      </>
    </ErrorBoundary>
  );
}

export default React.memo(WrapperComponent);