import { ActionHandlerFunction, UserProfile } from "@preveil-api";
import React, { useState } from "react";
import { Modal, Row, Col, Dropdown, Button, Toast } from "react-bootstrap";
import { ApprovalGroup, OrgUserRoles, AdminUIActionTypes } from "src/common";
import _ from "lodash";

type AllProps = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  role: {
    name: string;
    all_admin: boolean;
    details: string;
  }
  approval_groups: ApprovalGroup[];
  handleAction: ActionHandlerFunction;
  role_name: string
  existingGroup?: ApprovalGroup;
};

function AssignApprovalGroupModalComponent(props: AllProps) {
  const { show, setShow, role, approval_groups, handleAction, role_name, existingGroup } = props;
  const ags = role.all_admin ? approval_groups.filter(a => isApprovalGroupAdminOnly(a)) : approval_groups;
  const [approvalGroup, setApprovalGroup] = useState<ApprovalGroup | undefined>(existingGroup);

  function isApprovalGroupAdminOnly(approval_group: ApprovalGroup): boolean {
    return (
      approval_group.approvers.filter((approver) => approver.role === OrgUserRoles.admin).length ===
      approval_group.approvers.length
    );
  }

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="assign approval group"
      centered
    >
      <Modal.Header>
        <Modal.Title>Assign Approval Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!!role.all_admin && (
          <Toast bg="warning" show={true} className="d-inline-block mt-0 mb-3">
            <Toast.Body>All Approvers for this action must have admin privileges.</Toast.Body>
          </Toast>
        )}
        <p className="mb-3">
          Choose the group responsible for approving <b>{role.name}</b>
        </p>
        <Dropdown className="w-60 mb-3 pv-dropdown">
          <Dropdown.Toggle
            variant="outline-secondary"
            className="btn btn-sm"
            disabled={ags.length === 0}
          >
            {!!approvalGroup ? approvalGroup.name : "Select Approval Group"}
          </Dropdown.Toggle>
          <Dropdown.Menu flip={true} className="p-0" align="end">
            {_.map(ags, (approval_group: ApprovalGroup, i: number) => {
              return (
                <Dropdown.Item key={i} onClick={() => setApprovalGroup(approval_group)}>
                  {approval_group.name}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
        {!!approvalGroup && (
          <>
            <p>
              Members of <b>"{approvalGroup.name}"</b> Group
            </p>
            <div className="admin-table mt-3 mb-3">
              <Row className="table-header">
                <Col sm={5}>User</Col>
                <Col sm={6}>Role</Col>
              </Row>
              {_.map(approvalGroup.approvers, (approver: UserProfile, i: number) => {
                return (
                  <Row key={i}>
                    <Col sm={5}>
                      {approver.name}
                    </Col>
                    <Col sm={4} className="with_cap">
                      {approver.role}
                    </Col>
                  </Row>
                );
              })}
            </div>
            <p>Approvers required for activity: {approvalGroup.required_approvers}</p>
          </>
        )}
        <div className="mt-4">
          <Button
            disabled={
              !approvalGroup || (!!existingGroup && approvalGroup.uid === existingGroup?.uid)
            }
            onClick={() => {
              handleAction({
                actionType: AdminUIActionTypes.Assign,
                params: { approval_group: approvalGroup, role: role_name },
              });
              setShow(false);
            }}
            className="me-2"
          >
            Assign
          </Button>
          <Button variant="no-outline-primary" onClick={() => setShow(false)}>
            Close
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default React.memo(AssignApprovalGroupModalComponent);
