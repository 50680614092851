import React, { useEffect, useState, createRef, FocusEvent } from "react"; // 
import { Badge, Button } from "react-bootstrap";
import { MailErrorMessages, Attachment } from "src/common";
import { Icon } from "src/components";
import { filesize } from "filesize";
import _ from "lodash";

type AllProps = {
  opened: boolean;
  attachments: Attachment[];
  handleAttachmentChange: (attachments: Attachment[]) => void;
  resetAttachmentOpened: () => void;
}

function ComposeMailAttachmentComponent(props: AllProps) {
  const { opened, attachments, handleAttachmentChange, resetAttachmentOpened } = props;
  const [badgeAttachments, setBadgeAttachments] = useState<Attachment[]>();
  const attachmentRef = createRef<HTMLInputElement>();

  useEffect(() => {
    opened && handleOpenAttachmentModal();
  }, [opened]);

  useEffect(() => {
    const _att = _.filter(attachments, (_attachment: Attachment) => !_attachment.is_inline);
    setBadgeAttachments(_att);
  }, [attachments]);

  // FILE UPLOAD:
  // --------------------------------------------------------------------------------------
  // Description: Handle Attachment dialog and attachment processing for mail
  function handleOpenAttachmentModal() {
    !!attachmentRef.current ?
      attachmentRef.current.click() : console.error(MailErrorMessages.error_opening_editor_file_upload);
    resetAttachmentOpened();
  }

  // Description: File Upload to Compose  and update parent statement
  function handleAddAttachments(e: FocusEvent<HTMLInputElement>) {
    const files = e.target.files;
    if (!!files && files.length > 0) {
      const _attachments = _.map(files, (file: File) => (Attachment.fromFile(file)));
      const new_attachments = attachments.length > 0 ? _attachments.concat(attachments.slice()) : _attachments;
      handleAttachmentChange(new_attachments);
    }
  }

  // Description: Remove Attachments and update parent statement
  function handleRemoveAttachment(_attachment: Attachment): void {
    const new_attachments = attachments.filter(att => att.uuid !== _attachment.uuid);
    handleAttachmentChange(new_attachments);
  }

  // Description: Render each badge
  // NOTE: Dont show inline attachments as badges
  function AttachmentBadge(props: { attachment: Attachment }) {
    const _attachment: Attachment = props.attachment;
    return <Badge pill bg="light"
      title={`Click to remove ${_attachment.filename}`}>
      <Icon className="ficon-paperclip" />
      {_attachment.filename} <span>{filesize(_attachment.size).toString()}</span>
      <Button variant="icon" size="sm" onClick={() => handleRemoveAttachment(_attachment)}>
        <Icon className="ficon-x" />
      </Button>
    </Badge>;
  }

  return <>
    {
      (!!badgeAttachments && badgeAttachments.length > 0) &&
      <div className="compose-row attachments">
        {
          _.map(badgeAttachments, (attachment: Attachment, i: number) => {
            return <AttachmentBadge key={`attachment_${i}`} attachment={attachment} />;
          })
        }
      </div>
    }
    <input ref={attachmentRef} className="d-none" multiple type="file" onChange={handleAddAttachments} />
  </>;
}

export default React.memo(ComposeMailAttachmentComponent);
