import React, { useEffect } from "react";
import { IActionHandler, MessageDisplayType } from "@preveil-api";
import {
  Account, useAppDispatch, useAppSelector, Message, MessageHandlerDisplayType, GlobalErrorMessages, useUploadFiles, MessageAnchors
} from "src/common";
import { uiActions } from "src/store";
import { UploadProgress } from "../drive/drive-manager";
import { RootState } from "src/store/configureStore";


function UploadManagerComponent(props: { current_account: Account }) {
  const { current_account } = props;
  const upload_request = useAppSelector((state: RootState) => state.drive.upload_request);
  const download_request = useAppSelector((state: RootState) => state.drive.download_request);
  const upload_progress_hidden = useAppSelector((state: RootState) => state.ui.upload_progress_hidden);
  const upload_folder_ids = useAppSelector((state: RootState) => state.ui.upload_folder_ids);
  const { uploadFilesWeb, cancelUpload, renameUpload, progress } = useUploadFiles(current_account);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!!upload_request) {
      dispatch(uiActions.setUploadFolderIds(upload_folder_ids.concat([upload_request.destination.tracker_id || upload_request.destination.id])));
      uploadFilesWeb(upload_request);
    }
    // Description: Set Dirty Warning based on upload_progress_hidden
    dispatch(uiActions.handleSetDirtyMessage(!!upload_request || !!download_request));
  }, [upload_request]);

  const UploadManagerRequests = {
    // Description: Cancel the specified file upload if there is a conflict
    handleCancel: (file_id: string) => {
      cancelUpload(file_id);
    },

    // Description: Rename the specified file before uploading
    handleRename: (params: { file_id: string, name: string }) => {
      renameUpload(params.file_id, params.name);
    },

    // Description: Handle all page / components top level errors
    handlePageErrorMessage: (params: { message: string, stack?: any, display_type?: MessageDisplayType }) => {
      const display_type = !!params.display_type ? params.display_type : MessageHandlerDisplayType.logger;
      dispatch(uiActions.handleRequestErrors(new Message(params.message, display_type), params.stack));
    }
  };

  // Description: Handle all actions from the UploadProgress component
  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    if ((UploadManagerRequests as any)[callback] instanceof Function) {
      (UploadManagerRequests as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(MessageAnchors.actionType, actionObj.actionType);
      UploadManagerRequests.handlePageErrorMessage({ message, stack: actionObj });
    }
  }

  return <>
    {
      !upload_progress_hidden &&
      <UploadProgress progress={progress} handleAction={handlePageActions} />
    }
  </>;
}

export default React.memo(UploadManagerComponent);
