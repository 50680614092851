import React from "react";
import { Col, Row } from "react-bootstrap";
import { DriveEntryType, getEntityIcon, mapEntryType, DonwloadProgressTracker } from "src/common";
import { Icon } from "src/components";

type AllProps = {
  tracker: DonwloadProgressTracker;
}

function ProgressRowComponent(props: AllProps) {
  const { tracker } = props;

  const percentDoneFor = (tracker: DonwloadProgressTracker) => {
    return Math.floor(tracker.done / tracker.total * 100);
  };

  const rowStyle = () => {
    if (!!tracker.error) return "upload-error";
    if (tracker.done === tracker.total) return "upload-success";
    return "";
  };

  return (
    <Row className={
      `progress-row ${rowStyle()}`
    }>
      <Col xs={1} className="icon-col p-0">
        <Icon className={`file-icon ${rowStyle()} ${mapEntryType(DriveEntryType.FILE, tracker.file_name)?.type_class || getEntityIcon(DriveEntryType.FILE)}`} />
      </Col>
      {!tracker.error ? (
        <>
          <Col xs={8} title={tracker.file_name} className="file-name p-0">{tracker.file_name}</Col>
          <Col xs={2} className="percent p-0">{tracker.done !== tracker.total && (<span>{`${percentDoneFor(tracker)}%`}</span>)}</Col>
          <Col xs={1} className="status">
            {tracker.done === tracker.total ? (
              <Icon className="pv-icon-mark-read" />
            ) : (
              <Icon className="spinner" />
            )}
          </Col>
        </>
      ) : (
        <>
          <Col xs={4} title={tracker.file_name} className="file-name p-0">{tracker.file_name}</Col>
          <Col xs={6} className="file-name pl-1" title={tracker.error}><small>{tracker.error}</small></Col>
          <Col xs={1} className="status"><Icon className="pv-icon-failed" /></Col>
        </>
      )}
    </Row>
  );
}

export default React.memo(ProgressRowComponent);
