import React, { useState, FocusEvent } from "react";
import { Link } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { PublicRoutes, RecoverAccountUIActionTypes } from "src/common";
type AllProps = {
  handleAction: ActionHandlerFunction;
}

function DefaultPanelComponent(props: AllProps) {
  const { handleAction } = props;
  const [validated, setValidated] = useState(false);
  const [code, setCode] = useState<string>();

  // Description: Submit Backup Recovery code
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      handleAction({ actionType: RecoverAccountUIActionTypes.SubmitRecoverCode, params: form.backup_code.value.trim() });
    }
    setValidated(true);
  };

  return <>
    <p>
      Copy and paste the secret code text block from your recovery file that you created when you first setup your
      PreVeil account. </p>
    <p>If you cannot access to the recovery file text, locate the printed QR code and scan it using a mobile
      device running the PreVeil App instead
    </p>
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="secret_form_group">
        <Form.Label visuallyHidden>Recovery Code Text</Form.Label>
        <Form.Control as="textarea" rows={5}
          autoFocus={true}
          required
          value={code}
          onChange={(e: FocusEvent<HTMLInputElement>) => setCode(e.currentTarget.value)}
          name="backup_code" autoComplete="off" />
      </Form.Group>
      <div className="btn-panel">
        <Button disabled={!code} type="submit">Restore</Button>
        <Link className="btn btn-outline-primary" to={`/${PublicRoutes.get_started_route}`}>Cancel</Link>
      </div>
    </Form>
  </>;
}

export default React.memo(DefaultPanelComponent);
