import React, { useEffect, useState } from "react";
import { ProgressBar, Offcanvas } from "react-bootstrap";
import { ComposeMailSending, COMPOSE_STATES, useAppSelector } from "src/common";
import { Icon } from "src/components";
type AllProps = {
  step: COMPOSE_STATES;
  handleDiscard: () => void
}
// SENDING |  SENDING_FAILED | ERROR | SUCCESS
function ComposeMailSendingComponent(props: AllProps) {
  const { step, handleDiscard } = props;
  const [percent, setPercent] = useState(0);
  const progress = useAppSelector((state) => state.ui.progress);
  useEffect(() => {
    const _percent = step === COMPOSE_STATES.SENDING_FAILED ? 0 :
      step === COMPOSE_STATES.SUCCESS ? 100 :
        !!progress ? progress : 50;
    setPercent(_percent);
  }, [step, progress]);

  function getMessage(): string {
    return step === COMPOSE_STATES.SENDING_FAILED ? ComposeMailSending.failed :
      step === COMPOSE_STATES.SUCCESS ? ComposeMailSending.success : ComposeMailSending.default;
  }

  function getClassName(): string {
    return step === COMPOSE_STATES.SENDING_FAILED ? " error" :
      step === COMPOSE_STATES.SUCCESS ? " success" : "";
  }

  return <Offcanvas
    className={`compose-mail sending${getClassName()}`}
    backdrop={false}
    show={true}
    enforceFocus={false}
    placement="bottom"
    onClick={handleDiscard}>
    <div>
      <p>
        {getMessage()}
      </p>
      <div className="sending-progressbar inline">
        <ProgressBar variant="success"
          now={percent} label={`${percent}%`} visuallyHidden />
      </div>
    </div>
    <span className="circle-icon">{
      step === COMPOSE_STATES.SENDING_FAILED ?
        <Icon className="ficon-x" /> :
        <Icon className="pv-icon-mark-read" />
    }
    </span>
  </Offcanvas>;
}

export default React.memo(ComposeMailSendingComponent);
