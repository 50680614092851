import { IActionHandler, MessageDisplayType, OrgInfo, UserProfile, Sort } from "@preveil-api";
import * as React from "react";
import { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import {
  Account,
  AdminErrorMessages,
  ApprovalGroup,
  DeleteUsersOrgsByEntityIdGroupsAndGroupIdApiResponse,
  GlobalErrorMessages,
  Message,
  MessageAnchors,
  MessageHandlerDisplayType,
  useAppDispatch,
  useGetOrgInfo,
  useDeleteUsersOrgsByEntityIdGroupsAndGroupIdMutation,
  AdminSuccessMessages,
  useCreateOrgApprovalGroupMutation,
  useAssignApprovalGroupActivityMutation,
  getOppositeSortDirection,
  sortBy,
  SORT_DIRECTION,
  OrganizationInformation
} from "src/common";
import { PageHeader, CoverTemplate } from "src/components";
import { uiActions } from "src/store";
import { ApprovalGroupListView, ApprovalGroupToolbar, ApprovalGroupSidePanel, AssignTab, CreateApprovalGroupModal } from ".";
import { useLocation } from "react-router-dom";

type AllProps = {
  current_account: Account;
  org_info: OrgInfo;
  default_active_tab?: string;
};

function ApprovalGroupsComponent({ ...props }: AllProps) {
  const { current_account, org_info } = props;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [createModalShow, setCreateModalShow] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [approvalGroups, setApprovalGroups] = useState<ApprovalGroup[]>([]);
  const [organizationInfo, setOrganizationInfo] = useState<OrganizationInformation>();
  const [checkedList, setCheckedList] = useState<ApprovalGroup[]>([]);
  const [copiedApprovalGroup, setCopiedApprovalGroup] = useState<ApprovalGroup | undefined>(undefined);
  const { data, error, refresh } = useGetOrgInfo(current_account, org_info);
  const [deleteApprovalGroup] = useDeleteUsersOrgsByEntityIdGroupsAndGroupIdMutation();
  const [createApprovalGroup] = useCreateOrgApprovalGroupMutation();
  const [assignApprovalGroup] = useAssignApprovalGroupActivityMutation();
  const dispatch = useAppDispatch();
  const [sort, setSort] = useState<Sort<keyof ApprovalGroup>>({ field: "name", direction: "asc" });
  const location = useLocation();
  const defaultActiveTab = location.state?.default_active_tab || "manage";

  /* Description: On initial load - call the use get org info hook to get the approval groups and requests. */
  useEffect(() => {
    refresh({
      history: false,
      status: "pending",
      hideExpired: true,
      limit: 200,
    });
  }, []);

  /* Description: This handles the data and error whenever the refresh() callback is triggered and sets all the 
  data in the components state. */
  useEffect(() => {
    if (!!data) {
      const sorted_groups = sortBy(
        data.approval_groups.filter((ag) => !ag.is_deleted),
        { field: sort.field, direction: sort.direction },
      );
      setApprovalGroups(sorted_groups);
      setOrganizationInfo(data);
      setIsLoading(false);
    }
    !!error &&
      dispatch(
        uiActions.handleRequestErrors(
          new Message(AdminErrorMessages.error_fetching_approval_groups),
          error,
        ),
      );
  }, [data, error]);

  /* Description: Handle all children component actions and store it */
  const AdminApprovalGroupsCalls = {
    handleSetSort: (params: "name" | "approvers" | "required_approvers") => {
      const direction = sort.field === params ? getOppositeSortDirection(sort.direction) : SORT_DIRECTION.ascending;
      const sorted_requests = sortBy(approvalGroups, { field: params, direction });
      setSort({ field: params, direction });
      setApprovalGroups(sorted_requests);
    },
    /* Description: refresh the page by calling get logs */
    handleRefresh: () => {
      setIsLoading(true);
      refresh({
        history: false,
        status: "pending",
        hideExpired: true,
        limit: 200,
      });
    },
    /* Description: open create modal */
    handleSetShow: () => {
      setCreateModalShow(true);
    },
    handleAssign: (params: { approval_group: ApprovalGroup; role: string }) => {
      const api_params = {
        userId: current_account.user_id,
        orgId: org_info.org_id,
        approvalGroupUid: params.approval_group.uid,
        groupRole: params.role,
        groupVersion: params.approval_group.group_version,
      };
      assignApprovalGroup(api_params)
        .unwrap()
        .then(() => {
          setIsLoading(true);
          refresh({
            history: false,
            status: "pending",
            hideExpired: true,
            limit: 200,
          });
          dispatch(
            uiActions.handleSetMessage(
              new Message(AdminSuccessMessages.success_assigned_approval_group),
            ),
          );
        })
        .catch((msg) => {
          dispatch(
            uiActions.handleRequestErrors(new Message(AdminErrorMessages.error_assigning, MessageHandlerDisplayType.toastr), { msg }),
          );
        });
    },
    handleCreate: (params: {
      groupName: string;
      approvers: UserProfile[];
      requiredApprovals: number;
    }) => {
      const mapped_approvers = params.approvers.map((approver) => {
        return {
          user_id: approver.address,
          account_version: 0, // need account version
          required: false,
        };
      });
      const api_params = {
        userId: current_account.user_id,
        orgId: org_info.org_id,
        name: params.groupName,
        optionalsRequired: params.requiredApprovals,
        approvers: mapped_approvers,
      };
      createApprovalGroup(api_params)
        .unwrap()
        .then(() => {
          dispatch(
            uiActions.handleSetMessage(
              new Message(AdminSuccessMessages.success_creating_approval_group),
            ),
          );
          setIsLoading(true);
          refresh({
            history: false,
            status: "pending",
            hideExpired: true,
            limit: 200,
          });
        })
        .catch((msg) => {
          const message =
            msg.status === 409
              ? AdminErrorMessages.existing_approval_group
              : AdminErrorMessages.error_creating_approval_group;
          dispatch(
            uiActions.handleRequestErrors(new Message(message, MessageHandlerDisplayType.toastr), { msg }),
          );
        });
    },
    handleDelete: async () => {
      const total = checkedList.length;
      let errors: number = 0;
      setShow(false);
      const delete_responses = await Promise.all(
        checkedList.map(async (approval) => {
          const account_ids = Account.getAccountIdentifiers(current_account);
          const params = {
            account_ids,
            entityId: org_info.org_id,
            groupId: approval.uid,
          };
          return await deleteApprovalGroup(params)
            .unwrap()
            .then((response: DeleteUsersOrgsByEntityIdGroupsAndGroupIdApiResponse) => {
              if (!!response.errors) {
                errors++;
              }
              return !!response.errors ? null : approval;
            });
        }),
      );
      const successes = delete_responses.flatMap((response) => (!!response ? [response] : []));
      const success_ids = successes.map((s) => s.uid);
      if (successes.length > 0) {
        setApprovalGroups(
          approvalGroups.filter(
            (approval) => !successes.find((response) => response.uid === approval.uid),
          ),
        );
      }
      setApprovalGroups(approvalGroups.filter((ag) => !success_ids.includes(ag.uid)));
      setCheckedList([]);
      if (total === 1) {
        if (successes.length === 1) {
          dispatch(
            uiActions.handleSetMessage(
              new Message(AdminSuccessMessages.success_deleting_approval_group),
            ),
          );
        } else {
          dispatch(
            uiActions.handleRequestErrors(
              new Message(AdminErrorMessages.error_deleting_approval_group),
            ),
          );
        }
        return;
      }
      if (successes.length === 0) {
        dispatch(
          uiActions.handleRequestErrors(new Message(`Error deleting ${total} approval groups`)),
        );
      } else if (errors > 0) {
        dispatch(
          uiActions.handleRequestErrors(
            new Message(`Error deleting ${errors} of ${total} approval groups`),
          ),
        );
      } else {
        dispatch(
          uiActions.handleSetMessage(
            new Message(`Successfully deleted ${successes.length} approval groups`),
          ),
        );
      }
    },
    handlePageErrorMessage: (params: {
      message: string;
      stack?: any;
      display_type?: MessageDisplayType;
    }) => {
      const display_type = !!params.display_type
        ? params.display_type
        : MessageHandlerDisplayType.logger;
      dispatch(
        uiActions.handleRequestErrors(new Message(params.message, display_type), params.stack),
      );
    },
  };

  function handlePageActions(actionObj: IActionHandler) {
    dispatch(uiActions.handleMessageDismiss());
    const callback = `handle${actionObj.actionType}`;
    // Handle local calls:
    if ((AdminApprovalGroupsCalls as any)[callback] instanceof Function) {
      (AdminApprovalGroupsCalls as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(
        MessageAnchors.actionType,
        actionObj.actionType,
      );
      AdminApprovalGroupsCalls.handlePageErrorMessage({ message, stack: actionObj });
    }
  }

  return (
    <CoverTemplate>
      <PageHeader>
        <h1>Approval Groups</h1>
      </PageHeader>
      <Tabs defaultActiveKey={defaultActiveTab} id="approval-requests-tab">
        <Tab eventKey="manage" title="Manage" className="settings-height">
          <ApprovalGroupToolbar
            handleAction={handlePageActions}
            loading={isLoading}
            approval_groups={approvalGroups}
            setCreateModalShow={setCreateModalShow}
            checkedList={checkedList}
            setCopiedApprovalGroup={setCopiedApprovalGroup}
          ></ApprovalGroupToolbar>
          <ApprovalGroupListView
            loading={isLoading}
            approval_groups={approvalGroups}
            show={show}
            setShow={setShow}
            checkedList={checkedList}
            setCheckedList={setCheckedList}
            setCreateModalShow={setCreateModalShow}
            sort={sort}
            handleAction={handlePageActions}
          ></ApprovalGroupListView>
          {!!show && (
            <ApprovalGroupSidePanel
              approval_group={checkedList[0]}
              show={show}
              setShow={setShow}
              setCheckedList={setCheckedList}
            ></ApprovalGroupSidePanel>
          )}
          {!!organizationInfo && <CreateApprovalGroupModal
            saved_approval_groups={approvalGroups}
            createModalShow={createModalShow}
            setCreateModalShow={setCreateModalShow}
            copiedApprovalGroup={copiedApprovalGroup}
            handleAction={handlePageActions}
            setCopiedApprovalGroup={setCopiedApprovalGroup}
            organizationInfo={organizationInfo}
          ></CreateApprovalGroupModal>}
        </Tab>
        <Tab eventKey="assign" title="Assign" className="settings-height">
          {!!organizationInfo && <AssignTab
            approval_groups={approvalGroups}
            organizationInfo={organizationInfo}
            isLoading={isLoading}
            handleAction={handlePageActions}
          ></AssignTab>}
        </Tab>
      </Tabs>
    </CoverTemplate>
  );
}

export default React.memo(ApprovalGroupsComponent);
