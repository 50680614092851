import React from "react";
import { Card, Button } from "react-bootstrap";
import { AdminUIActionTypes } from "src/common";
import { ActionHandlerFunction } from "@preveil-api";
import dataExportImage from "src/assets/images/data-export.svg";

type AllProps = {
  approvalGroup?: {
    name: string;
    required_approvers: number;
  };
  children?: React.ReactNode;
  handleActions: ActionHandlerFunction;
  isGetStartedDisabled: boolean;
};

function DataExportGetStartedStep({ approvalGroup, children, handleActions, isGetStartedDisabled }: AllProps) {
  return (
    <main className="data-export-main bg-white px-4">
      <section className="export-left-section">
        <Card className="pv-card data-export-card">
          <Card.Img variant="top" src={dataExportImage} />
          <Card.Body>
            <Card.Title as="p">
              Download a decrypted copy of your organization's data.
            </Card.Title>
            <Card.Text as="p">
              To begin your data export, click "Get Started" below. Be sure to review the scope of the export below.
            </Card.Text>
            {children}
            <Button
              className="mt-3"
              variant="primary"
              disabled={isGetStartedDisabled}
              onClick={() => handleActions({ actionType: AdminUIActionTypes.RequestDataExport })}
            >
              Get Started
            </Button>
          </Card.Body>
        </Card>
      </section>
      <section className="export-right-section">
        <h5>Details</h5>
        <hr />
        <div className="group-details">
          <div className="group-content">
            <span>Approval Group</span>
            <p>{approvalGroup?.name}</p>
          </div>
          <div className="group-content">
            <span>Required Approvers</span>
            <p>{approvalGroup?.required_approvers}</p>
          </div>
        </div>
      </section>
    </main>
  );
}

export default React.memo(DataExportGetStartedStep);