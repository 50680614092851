import React, { useEffect, useState } from "react";
import { ThreadMessageRecipients, UserProfile } from "@preveil-api";
import { ComposeMessage } from "src/common";
import { UserBadges } from "src/components";

type AllProps = {
  recipients?: ThreadMessageRecipients;
  handleAction: () => void;
}

function ComposeMailRecipientsComponent(props: AllProps) {
  const { recipients, handleAction } = props;
  const [contacts, setContacts] = useState<UserProfile[] | null>();

  useEffect(() => {
    !!recipients && setContacts(ComposeMessage.groupAllRecipients(recipients));
  }, [recipients]);

  return <div className="compose-row" onClick={handleAction}>
    <label>Recipients:</label>
    {
      !!contacts && <UserBadges show_external_users={true} current_contacts={contacts} handleAction={() => { }} readonly />
    }
  </div>;
}

export default React.memo(ComposeMailRecipientsComponent);
