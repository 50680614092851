import { PublicKey, EncryptionKey, VerifyKey, SigningKey } from "..";
import { Key as KeyPB } from "../protos/keys_pb";
import { CryptoAPI, AccountUserPublicKey } from "src/common";

export class RemotePublicUserKey {
  constructor(
    public user_id: string,
    public key_version: number,
  ) { }

  get buffer() {
    throw Error("not available on remote key");
  }

  get protocol_version() {
    throw Error("not available on remote key");
  }

  get public_key(): PublicKey {
    throw Error("not available on remote key");
  }

  get verify_key(): VerifyKey {
    throw Error("not available on remote key");
  }

  serialize(): Uint8Array {
    throw Error("not available on remote key");
  }
}

export class RemoteUserKey {
  private _puk: AccountUserPublicKey;
  constructor(
    public user_id: string,
    public key_version: number,
    public_user_key?: AccountUserPublicKey
  ) {
    this._puk = !!public_user_key ? public_user_key : new RemotePublicUserKey(user_id, key_version);
  }

  get buffer() {
    throw Error("not available on remote key");
  }

  get protocol_version() {
    throw Error("not available on remote key");
  }

  get public_user_key() {
    return this._puk;
  }

  get encryption_key(): EncryptionKey {
    return {
      unseal: (cipher: Uint8Array): Promise<Uint8Array> => {
        return CryptoAPI.decrypt(cipher, this.user_id, this.key_version);
      },
      seal: (plaintext: Uint8Array): Promise<Uint8Array> => {
        return CryptoAPI.encrypt(plaintext, this.user_id, this.key_version);
      },
      serialize: (): Uint8Array => { throw Error("not available on remote key"); },
      buffer: {} as KeyPB,
      public_buffer: {} as KeyPB,
      private_key: new Uint8Array(),
      public_key: new Uint8Array(),
      protocol_version: 0,
      clonePublic: (): PublicKey => { throw Error("not available on remote key"); }
    }
  }

  get signing_key(): SigningKey {
    return {
      sign: (plaintext: Uint8Array): Promise<Uint8Array> => {
        return CryptoAPI.signWithUserKey(plaintext, this.user_id)
          .then(s => s.signature)
      },
      cloneVerify: (): VerifyKey => { throw Error("not available on remote key"); },
      serialize: (): Uint8Array => { throw Error("not available on remote key"); },
      verify: (): Promise<boolean> => { throw Error("not available on remote key"); },
      buffer: {} as KeyPB,
      public_buffer: {} as KeyPB,
      private_key: new Uint8Array(),
      public_key: new Uint8Array(),
      protocol_version: 0
    }
  }

  serialize(): Uint8Array {
    throw Error("not available on remote key");
  }

  async getPublicUserKey(): Promise<AccountUserPublicKey> {
    return this._puk || (this._puk = new RemotePublicUserKey(this.user_id, this.key_version));
  }
}

export class RemoteDeviceKey extends RemoteUserKey {
  constructor(
    user_id: string,
    key_version: number,
  ) {
    super(user_id, key_version);
  }

  public get signing_key(): SigningKey {
    return {
      sign: (plaintext: Uint8Array): Promise<Uint8Array> => {
        return CryptoAPI.signWithDeviceKey(plaintext, this.user_id)
          .then(s => s.signature)
      },
      cloneVerify: (): VerifyKey => { throw Error("not available on remote key"); },
      serialize: (): Uint8Array => { throw Error("not available on remote key"); },
      verify: (): Promise<boolean> => { throw Error("not available on remote key"); },
      buffer: {} as KeyPB,
      public_buffer: {} as KeyPB,
      private_key: new Uint8Array(),
      public_key: new Uint8Array(),
      protocol_version: 0
    }
  }
}
