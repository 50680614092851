import React, { FocusEvent } from "react";
import { Col, Row } from "react-bootstrap";
import { ActionHandlerFunction, DeviceCertificate } from "@preveil-api";
import { AdminUIActionTypes, CheckboxStates, CheckboxStatesTypes, useAppSelector } from "src/common";
import { Checkbox, EmptyView, Loading } from "src/components";
import { DeviceCertificateRow } from ".";
import _ from "lodash";

type AllProps = {
    certificates: DeviceCertificate[];
    all_selected_state: CheckboxStatesTypes;
    selected_certificates: DeviceCertificate[];
    filtered_certificates: DeviceCertificate[];
    loading: boolean;
    handleActions: ActionHandlerFunction;
}

function DevicesCertificateListPanel(props: AllProps) {
    const { certificates, all_selected_state, selected_certificates, filtered_certificates, loading, handleActions } = props;
    const current_account = useAppSelector((state) => state.account.current_account);

    // Description: Render the device rows
    function RenderList() {
        if (_.isEmpty(filtered_certificates)) {
            return <EmptyView className="position-relative" message={"No matches found!"} />;
        }
        return !!current_account &&
            <div className="list-body">
                {filtered_certificates.map((certificate: DeviceCertificate, i: number) => {
                    const isUserSelected = selected_certificates.filter(
                        (selected_certificate: DeviceCertificate) => selected_certificate.id === certificate.id
                    ).length > 0 ? CheckboxStates.checked : CheckboxStates.empty;
                    return <DeviceCertificateRow
                        key={i}
                        certificate={certificate}
                        isChecked={isUserSelected}
                        handleActions={handleActions}
                    />;
                })}
            </div>;
    }

    return <div className="settings-list devices">
        {
            loading ?
                <div className="cover-content">
                    <div className="admin-panel-center">
                        <Loading className="in-place" />
                    </div>
                </div> :
                !!certificates && certificates.length > 0 ?
                    <>
                        <Row className="header-row">
                            <Col md="1" lg="auto">
                                <Checkbox
                                    className="sr-only"
                                    onChange={(e: FocusEvent<HTMLInputElement>) => handleActions({ actionType: AdminUIActionTypes.MultiSelect, params: { e } })}
                                    value="all"
                                    selected={all_selected_state}
                                    label="Select All"
                                />
                            </Col>
                            <Col>Issued To</Col>
                            <Col>Issued By</Col>
                            <Col>Expiration Date</Col>
                            <Col>Friendly Name</Col>
                        </Row>
                        {
                            RenderList()
                        }
                    </> :
                    <EmptyView className="position-relative" message={"No Device Certificates"} />
        }
    </div>;
}

export default React.memo(DevicesCertificateListPanel);
