import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { ActionHandlerFunction, AGUserInfo, } from "@preveil-api";
import { RecoverAccountUIActionTypes, AccountErrorMessages, PublicRoutes, useGetApproversMutation, GetApproversApiResponse } from "src/common";
import { Loading } from "src/components";
import _ from "lodash";
// useGetApprovalGroupLocalInfoMutation
type AllProps = {
  user_info: AGUserInfo;
  handleAction: ActionHandlerFunction;
}

function DefaultPanelComponent(props: AllProps) {
  const { user_info, handleAction } = props;
  const [approvers, setApprovers] = useState<any>();
  const [getApprovers, { isLoading }] = useGetApproversMutation();

  useEffect(() => {
    handleInitApprovalGroup();
    return () => { };
  }, []);

  // Description: get the initial requrired groups and the 
  async function handleInitApprovalGroup() {
    await getApprovers(user_info).unwrap()
      .then((params: GetApproversApiResponse) => {
        isValid(params) ?
          setApprovers(params) :
          handleDataError({
            errors: "The data returned from getting approvers does not pass validation",
            params
          });
      })
      .catch((msg) => {
        handleDataError(msg);
      });
  }

  // Description: Handle Error on the call
  function handleDataError(stack?: any) {
    handleAction({
      actionType: RecoverAccountUIActionTypes.PageError,
      params: { message: AccountErrorMessages.default, stack }
    });
  }

  // Description: Validate returned approval object response
  function isValid(params: GetApproversApiResponse): boolean {
    return (!!params.optional_users || !!params.required_users) &&
      (!!params.optionals_required && !isNaN(params.optionals_required) && params.optionals_required > 0);
  }

  // Description: convert Response object to UI usable ApproversList and pass up to parent
  function handleSubmit() {
    const _required = !!approvers?.required_users && approvers.required_users.length ?
      _.map(approvers.required_users.slice(), u => _.extend({ required: true, approved: false }, u)) : [];
    const _optional = !!approvers?.optional_users && approvers.optional_users.length ?
      _.map(approvers.optional_users.slice(), u => _.extend({ required: false, approved: false }, u)) : [];

    handleAction({
      actionType: RecoverAccountUIActionTypes.SubmitAcknowledgeInformation,
      params: {
        approvers: _optional.concat(_required),
        optionals_required: approvers.optionals_required
      }
    });
  }
  // }

  return !isLoading ? <>
    <p className="fs-3 semi-bold">
      To recover your private key, you will need to contact members of your recovery group &mdash; ideally by phone.
    </p>
    <p>
      Each person you contact will need to access their PreVeil account on a computer.
    </p>
    <div className="btn-panel text-end mt-3">
      <Button variant="primary" disabled={!approvers} onClick={handleSubmit}>Continue</Button>
      <Link className="btn btn-outline-primary" to={`/${PublicRoutes.get_started_route}`}>Cancel</Link>
    </div>
  </> : <Loading className="in-place" />;
}

export default React.memo(DefaultPanelComponent);
