/* This component will handle the display of a single address or domain for Trusted Community, Gateway and Admin Group
  component, is also used in CsvComponentPanel component to display the list of addresses and domains,
  there are two render states controlled by selectable:
  If selectable true: component will display Checkbox and render as a Trusted Community/Gateway Panel row
  If selectable false: component won't display Checkbox and Dropdown, and it will render as a CsvComponentPanel row
*/

import React, { FocusEvent } from "react";
import { ActionHandlerFunction } from "@preveil-api";
import { Checkbox, Icon } from "src/components";
import { CheckboxStatesTypes, AdminUIActionTypes } from "src/common";
import { Col, Row, Button } from "react-bootstrap";

type AllProps = {
  color?: string;
  icon: string;
  id: string;
  is_checked?: CheckboxStatesTypes;
  handleActions?: ActionHandlerFunction;
  handleDeleteAction?: (id: string | undefined) => void;
  selectable?: boolean;
  validation_state?: string | null;
  sub_title?: string | undefined;
};

function AddressListRow({ id, handleActions, is_checked = "empty", handleDeleteAction, color = "", selectable, icon, validation_state, sub_title }: AllProps) {
  const addressIsValid = validation_state === "valid_trusted" || validation_state === "valid_gateway" || validation_state === "valid_group";
  const columnSize = sub_title ? 7 : undefined;
  function handleDeleteCallback() {
    // NOTE: dropdown was removed from AddressListRow (only delete action)
    // but based on where the componed was previously built, there are 2 render states
    // - Inside AddressListPanel - select/multiselect.
    // - Rendering list of addresses from CSV - not selectable and only one handleAction.
    // This can be refactor once other parts of admin are done.
    if (validation_state && selectable && handleActions) {
      handleActions({ actionType: AdminUIActionTypes.Delete, params: [id] });
    } else if (!selectable && addressIsValid && handleDeleteAction) {
      handleDeleteAction(id);
    }
  }
  return (
    <Row className="align-items-center address-row flex-nowrap">
      {selectable && (
        <Col xs="auto" className="pe-0">
          <Checkbox
            className="sr-only"
            onChange={(e: FocusEvent<HTMLInputElement>) =>
              handleActions &&
              handleActions({ actionType: AdminUIActionTypes.SingleSelect, params: { e, id } })
            }
            selected={is_checked}
            value={id}
          />
        </Col>
      )}
      <Col xs={columnSize} className="ps-2">
        <div className="address-name">
          <Icon className={`${icon}`} />
          <p className={`title mb-0 ms-2 ${color}`}>{id}</p>
        </div>
      </Col>
      {sub_title && (
        <Col xs={4}>
          <div className="address-subtitle ps-2">
            <p className="ms-2 mb-0 pb-0">{sub_title}</p>
          </div>
        </Col>
      )}
      <Col xs="auto" className="ms-auto">
        <Button
          className="ms-auto"
          variant="icon"
          size="sm"
          onClick={handleDeleteCallback}
          title="Delete Address or Domain"
          data-tooltip-id="pv-tooltip"
          data-tooltip-content="Delete Address or Domain"
        >
          <Icon className="ficon-x" />
        </Button>
      </Col>
    </Row>
  );
}

export default React.memo(AddressListRow);
