import { useEffect } from "react";
import { CollectionServerUser } from "@preveil-api";
import { Account, usePostUsersFindMutation, Contacts } from "src/common";

export function useContacts(current_account: Account, user_ids?: string[]) {
  const [findUsers, { isLoading, isError, error, data }] = usePostUsersFindMutation();

  function handleFindUsers(_user_ids: string[]) {
    const spec = _user_ids.map((user_id: string) => {
      return { user_id, key_version: -1 };
    });
    findUsers({
      account_ids: Account.getAccountIdentifiers(current_account),
      body: { spec }
    }).unwrap()
      .then(({ users, groups, aliases, errors }) => {
        const _users: CollectionServerUser[] = !!users && users.length > 0 ? users : [];
        const _contacts = new Contacts(current_account.user_id, null);
        _contacts.updateCsProfiles(_users, _user_ids);
        _contacts.updateCsProps(aliases, groups, errors);
        _contacts.updateContactSessions();
      });
  }

  useEffect(() => {
    !!user_ids && user_ids.length > 0 && handleFindUsers(user_ids);
  }, [user_ids]);

  return {
    data,
    isLoading,
    isError,
    error
  };
}
