import React, { ReactNode } from "react";

type AllProps = {
  children?: ReactNode;
  className?: string;
  // handleAction: ActionHandlerFunction;
}

function PageHeaderComponent(props: AllProps) {
  const { children, className } = props;
  return <div className={`page-header${!!className ? ` ${className}` : ""}`}>
    {children}
  </div>;
}

export default React.memo(PageHeaderComponent);