import React, { useEffect, useState, MouseEvent } from "react";
import { Card, ButtonGroup, Button, Badge } from "react-bootstrap";
import { ActionHandlerFunction, ThreadMessage, UserProfile, GroupEmailBase } from "@preveil-api";
import { MailUIActionTypes, formatCalendarTimestamp, isSameUser, ComposeTypes, MailThreadMessage } from "src/common";
import { Icon, UserEmail } from "src/components";
import _ from "lodash";

type AllProps = {
  user_id: string;
  message: ThreadMessage;
  handleAction: ActionHandlerFunction;
}

function MessageHeaderComponent(props: AllProps) {
  const { message, user_id, handleAction } = props;
  const is_draft = MailThreadMessage.isDraft(message);
  const [is_unread, setUnread] = useState<boolean>(false);
  const [is_starred, setStarred] = useState<boolean>();
  const [is_loading, setIsLoading] = useState<boolean>(true);
  const [initial_load, setInitialLoad] = useState<boolean>(true);
  const is_self = isSameUser(message.sender.address, user_id);
  const is_sent = MailThreadMessage.isSent(message);

  // Description: Set star immediately for UI visual - NOTE: It will revert on error
  useEffect(() => {
    setStarred(MailThreadMessage.isStarred(message));
    const unread = MailThreadMessage.isUnread(message);
    setUnread(unread);
    // Note: An error will occur if we try to update star/unread status while \\Seen flag is already updating.
    if (initial_load) {
      if (!unread) {
        setIsLoading(false);
        setInitialLoad(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [message]);

  // Description: Build the recipients
  function UserItem(profiles: UserProfile[], group_emails?: GroupEmailBase[] | null) {
    const _profile = profiles.slice();
    (!!group_emails && group_emails.length > 0) &&
      _.map(group_emails, (group: GroupEmailBase) => _profile.push({ address: group.alias, name: null, external_email: null }));
    return _.map(_profile, (profile: UserProfile, i: number) => {
      const not_last = _profile.length > i + 1;
      return <React.Fragment key={`profile_${i}`}>
        <UserEmail
          email={profile.address}
          display_name={profile.name}
          external_email={profile.external_email || null} />
        {not_last && ", "}
      </React.Fragment>;
    });
  }

  // Description: Set badges for draft and sent messages
  function MailboxBadges() {
    return <>
      {is_draft ? <Badge bg="info">Draft</Badge> :
        is_sent ? <Badge bg="light" text="dark">Sent</Badge> : null}
    </>;
  }

  function handleStarClick(e: MouseEvent<HTMLButtonElement>) {
    const new_star = !is_starred;
    e.stopPropagation();
    e.preventDefault();
    setStarred(new_star);
    setIsLoading(true);
    handleAction({ actionType: MailUIActionTypes.Star, params: { messages: [message], is_starred } });
  }

  function handleUnreadClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    e.preventDefault();
    setUnread(true);
    setIsLoading(true);
    handleAction({ actionType: MailUIActionTypes.UpdateSeenFlag, params: { messages: [message], remove: true } });
  }

  return <Card.Header>
    <ul className={is_unread ? "unread" : "read"}>
      <li className="sender">
        {
          !!message.sender.name && !is_self &&
          <b>{message.sender.name}</b>
        }
        <UserEmail
          email={message.sender.address}
          display_name={message.sender.name}
          external_email={message.sender.external_email || null} />
        <MailboxBadges />
      </li>
      {
        !!message.tos &&
        <li><b>To:</b> {UserItem(message.tos, message.tos_groups)}</li>
      }
      {
        (message.ccs.length > 0 || !!message.ccs_groups && message.ccs_groups.length > 0) &&
        <li><b>Cc:</b> {UserItem(message.ccs, message.ccs_groups)}</li>
      }
      {
        !!message.bccs && message.bccs.length > 0 &&
        <li><b>Bcc:</b> {UserItem(message.bccs)}</li>
      }
    </ul>
    <ul className="mail-header-info">
      <li>
        <ButtonGroup size="sm" >
          {
            is_draft ?
              <Button variant="icon"
                aria-label="Edit Draft Message"
                data-tooltip-id="pv-tooltip" data-tooltip-content="Edit Draft Message"
                onClick={() =>
                  handleAction({ actionType: MailUIActionTypes.AddComposeMessage, params: { message, content_type: ComposeTypes.draft } })}>
                <Icon className="pv-icon-compose" />
              </Button> :
              <>
                <Button variant="icon"
                  aria-label="Reply to message"
                  data-tooltip-id="pv-tooltip"
                  data-tooltip-content="Reply to message"
                  onClick={() => handleAction({ actionType: MailUIActionTypes.AddComposeMessage, params: { message, content_type: ComposeTypes.reply } })}>
                  <Icon className="pv-icon-reply" />
                </Button>
                <Button variant="icon" aria-label="Reply to all recipients in message"
                  data-tooltip-id="pv-tooltip" data-tooltip-content="Reply to all recipients in message"
                  onClick={() => handleAction({ actionType: MailUIActionTypes.AddComposeMessage, params: { message, content_type: ComposeTypes.reply_all } })}>
                  <Icon className="pv-icon-reply-all" />
                </Button>
                <Button variant="icon" aria-label="Forward message to new recipient"
                  data-tooltip-id="pv-tooltip" data-tooltip-content="Forward message to new recipient"
                  onClick={() =>
                    handleAction({ actionType: MailUIActionTypes.AddComposeMessage, params: { message, content_type: ComposeTypes.forward } })}>
                  <Icon className="pv-icon-reply pv-icon-flip-horizontal" />
                </Button>
                <Button variant="icon" disabled={is_unread || is_loading}
                  aria-label="Mark Unread"
                  data-tooltip-id="pv-tooltip" data-tooltip-content="Mark Unread"
                  onClick={handleUnreadClick}>
                  <Icon className="ficon-mail" />
                </Button>
                <Button variant="icon" aria-label={`${is_starred ? "Unstar" : "Star"} this thread`}
                  data-tooltip-id="pv-tooltip" data-tooltip-content={`${is_starred ? "Unstar" : "Star"} this thread`}
                  onClick={handleStarClick} disabled={is_loading}>
                  {
                    is_starred ?
                      <Icon className="pv-icon-starred" /> :
                      <Icon className="pv-icon-unstarred" />
                  }
                </Button>
              </>
          }
          <Button variant="icon" aria-label="Delete message"
            data-tooltip-id="pv-tooltip" data-tooltip-content="Delete Message"
            onClick={() => handleAction({ actionType: MailUIActionTypes.Delete, params: { messages: [message] } })}>
            <Icon className="ficon-trash-2" />
          </Button>
        </ButtonGroup>
      </li>
      <li className="date">{formatCalendarTimestamp(message.date)}</li>
    </ul>
  </Card.Header >;
}

export default React.memo(MessageHeaderComponent);
