import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { AppConfiguration, GlobalConstants, useAppDispatch, useAppSelector, PublicRoutes } from "src/common";
import { Loading, ErrorHandler, MessageHandler, CryptoCheck, NotificationManager, CUINoticeModal, WebDriveInitialize, AppDriveInitialize, UploadManager, DownloadManager, SyncNotificationTray } from "src/components";
import DirtyWarning from "./DirtyWarning.component";
import { uiActions, appActions, accountActions } from "src/store";
const onboardingPage = [`/${PublicRoutes.create_account_route}`];

function UIManagerComponent() {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.ui.loading);
  const error = useAppSelector((state) => state.ui.errors);
  const message_item = useAppSelector((state) => state.ui.message_item);
  const current_account = useAppSelector((state) => state.account.current_account);

  // Description:First onload event
  useEffect(() => {
    getAppInformation();
  }, []);

  const location = useLocation();
  useEffect(() => {
    onboardingPage.includes(location.pathname) ?
      document.body.classList.add("create-account-pages") :
      document.body.classList.remove("create-account-pages");
  }, [location]);

  useEffect(() => {
    if (!!current_account && AppConfiguration.buildForExpress()) {
      // Use setTimeout to remove auth token from store after 900000 milliseconds (15 minutes)
      setTimeout(() => {
        dispatch(accountActions.handleSetAuthToken(undefined));
      }, GlobalConstants.EXPRESS_AUTH_TOKEN_EXPIRATION);
    }
  }, [current_account]);

  // Description: get App config
  function getAppInformation() {
    const config = new AppConfiguration();
    const build_mode = config.build_mode;
    const os_info = config.os_info;
    const browser = config.browser;
    config.buildForApp() ?
      dispatch(appActions.getBuildVersion({ build_mode, os_info, browser })) :
      dispatch(appActions.getBuildVersionComplete({ build_mode, os_info, browser }));

    // Add a top level class to the body tag for conditional styles
    document.body.classList.add(`${build_mode}_build`);
  };

  // Description: Dismiss messages and errors and clean up UI State 
  function handleMessageDismiss() {
    dispatch(uiActions.handleMessageDismiss());
  }

  // Description: Render functional components based on UI state changes
  function UIManagerUtilities() {
    if (loading) {
      return <Loading />;
    };

    if (!!error) {
      return <ErrorHandler error={error} onErrorHandlerDismiss={handleMessageDismiss} />;
    };

    if (!!message_item) {
      return <MessageHandler message_item={message_item} onMessageHandlerDismiss={handleMessageDismiss} />;
    };
  }

  // NOTE: REACT TOOLTIP IMPLEMENTATION https://github.com/ReactTooltip/react-tooltip
  return <>
    <Tooltip id="pv-tooltip" disableStyleInjection="core" />
    {UIManagerUtilities()}
    {
      (!!current_account && !!current_account.org_info && AppConfiguration.buildForWeb()) &&
      <CUINoticeModal current_account={current_account} org_id={current_account.org_info.org_id} />
    }
    {
      !!current_account &&
      (AppConfiguration.buildForWeb() ?
        <WebDriveInitialize current_account={current_account} /> :
        <AppDriveInitialize current_account={current_account} />)
    }
    {
      AppConfiguration.buildForApp() && <CryptoCheck />
    }
    {
      !!current_account &&
      <>
        <NotificationManager current_account={current_account} />
        <SyncNotificationTray  />
        <UploadManager current_account={current_account} />
        <DownloadManager current_account={current_account} />
        <DirtyWarning />
      </>
    }
  </>;
}

export default React.memo(UIManagerComponent);
