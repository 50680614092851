import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { Card, Button, Modal } from "react-bootstrap";
import { Account, AccountErrorMessages, AccountIdentifiers, AccountUserKey, GlobalConstants, Helpers, KeyStorageAPI, KeyStorageData, Message, QueryParamKeys, create, useAppDispatch, useAppSelector } from "src/common";
import { PageHeader } from "src/components";
import { RootState } from "src/store/configureStore";
import { uiActions } from "src/store";
import { EnterPasswordForm, UserShardPDFGenerator } from ".";

/* Description: Component for user to download their express account recovery file. */
// NOTE: when a file is downloaded it will replace any previously downloaded recovery files. (those will no longer be valid since we create new shards each time a new file is downloaded)
function ExpressAccountRecoveryComponent() {
  const [searchParams, setSearchParams] = useSearchParams();
  const current_account = useAppSelector((state: RootState) => state.account.current_account);
  const auth_token_store = useAppSelector((state: RootState) => state.account.auth_token);
  const express_recovery_not_setup = useAppSelector((state: RootState) => state.account.express_recovery_not_setup);
  const [user_shard, setUserShard] = useState<string>();
  const [modal_show, setModalShow] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const retry_count_ref = useRef<number>(0);
  const dispatch = useAppDispatch();

  // Description: the save now url param will be there when a user clicks "Save now" from the welcome modal 
  // after creating a new account. In this case when they click save now, it will immediately start the download process
  // or show the password modal if 15 minutes has passed and the auth token is not stored anymore in the store.
  useEffect(() => {
    const page_action_qs = searchParams.get(QueryParamKeys.PAGE_ACTION_QUERY_KEY);
    if (!!page_action_qs) {
      !!auth_token_store ? downloadRecoveryFile(undefined, auth_token_store) : setModalShow(true);
      searchParams.delete(QueryParamKeys.PAGE_ACTION_QUERY_KEY);
      setSearchParams(searchParams);
    }
  }, []);

  // Description: Create the auth token or use the one stored in the store and call createShard to shard
  // the user key and send the server shard to the backend
  async function downloadRecoveryFile(password?: string, auth_token?: string) {
    setModalShow(false);
    if (!!current_account) {
      setDownloading(true);
      let _auth_token: string | undefined;
      if (!!password) {
        const token = await KeyStorageData.authenticationToken(current_account.user_id, password);
        _auth_token = Helpers.b64Encode(token);
      } else if (!!auth_token) {
        _auth_token = auth_token;
      }
      !!_auth_token && createShard(current_account, _auth_token);
    }
  }

  // Description: Shards the user key into two parts using the shamir secret sharing function and 
  // send one of the shards to the backend server.
  async function createShard(account: Account, auth_token: string) {
    const user_key = account.user_key;
    const shards = create(2, 2, Helpers.b64Encode(user_key.serialize()));
    if (!!shards && shards.length === 2) {
      const server_shard = Helpers.b64Encode((Helpers.utf8Encode(shards[0])));
      const user_shard = Helpers.b64Encode((Helpers.utf8Encode(shards[1])));
      const account_ids = Account.getAccountIdentifiers(account);
      sendServerShardToBackend(account_ids, server_shard, user_shard, auth_token, user_key);
    }
  }

  // Description: Send the server shard to the backend to be stored in the database.
  async function sendServerShardToBackend(account_ids: AccountIdentifiers, server_shard: string, user_shard: string, auth_token: string, user_key: AccountUserKey) {
    await KeyStorageAPI.rekeyUser(account_ids, auth_token, undefined, server_shard, Helpers.b64Encode(user_key.public_user_key.verify_key.public_key))
    .then((response) => {
      if (!response.isError) {
        setUserShard(user_shard);
      } else {
        if (response.data.error === "bad_password") {
          resetLocalState();
          dispatch(uiActions.handleRequestErrors(new Message(AccountErrorMessages.bad_password)));
          setModalShow(true);
        } else {
          retry(account_ids, server_shard, user_shard, auth_token, user_key, response.data.error);
        }
      }
    }).catch((error) => {  
      retry(account_ids, server_shard, user_shard, auth_token, user_key, error);
    });
  }

  function retry(account_ids: AccountIdentifiers, server_shard: string, user_shard: string, auth_token: string, user_key: AccountUserKey, error?: any) {
    if (retry_count_ref.current === GlobalConstants.EXPRESS_REKEY_RETRY_MAX) {
      setDownloading(false);
      resetLocalState();
      dispatch(uiActions.handleRequestErrors(new Message(AccountErrorMessages.error_downloading_recovery_file), error));
    } else {
      retry_count_ref.current++;
      setTimeout(() => {
        sendServerShardToBackend(account_ids, server_shard, user_shard, auth_token, user_key);
      }, 1000);
    }
  }

  // Description: Reset Local state when download is complete so that it can be downloaded again.
  function resetLocalState() {
    setDownloading(false);
    setUserShard(undefined);
    retry_count_ref.current = 0;
  }

  return <>
    <PageHeader>
      <h1>Account Recovery</h1>
    </PageHeader>
    <Card className="card-section">
      <div className="mw-300">
        {!!express_recovery_not_setup ?
          <>
            <p>Download and save your recovery file.</p>
            <p><b>You will need it if you forget your password.</b></p>
            <p>This recovery file contains your access key and is required for you to reset your password.</p>
          </> :
          <>
            <p>Your account recovery file is required if you need to reset your password.</p>
            <p>You have already downloaded a recovery file. If you would like to create another file, select the Download button below.</p>
            <p><b>Note: Your previously downloaded account recovery files will no longer be valid.</b></p>
          </>
        }
        <Button
          disabled={downloading}
          className={downloading ? "btn-loading" : ""}
          onClick={() => {
            !!auth_token_store ? downloadRecoveryFile(undefined, auth_token_store) :
              setModalShow(true);
          }}>{`${downloading ? "Downloading" : "Download"} Recovery File`}</Button>
      </div>
    </Card>
    <Modal show={modal_show} onHide={() => setModalShow(false)} centered aria-labelledby="device confirmation modal">
      <Modal.Header>
        <Modal.Title>Please Re-Enter your Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EnterPasswordForm setModalShow={setModalShow} submit={downloadRecoveryFile} />
      </Modal.Body>
    </Modal>
    {
      !!user_shard &&
      !!current_account &&
      <UserShardPDFGenerator
        user_id={current_account.user_id}
        recovery_shard={user_shard}
        downloadComplete={resetLocalState}
      />
    }
  </>;
}

export default React.memo(ExpressAccountRecoveryComponent);
