import * as React from "react";
import { UserRequest } from "src/common";

type AllProps = {
  is_approval: boolean;
  request: UserRequest;
};

function SubsumeAccountComponent(props: AllProps) {
  const { is_approval, request } = props;
  
  return (
    <>
      {is_approval ? (
        <>
          <p> 
            You've been invited to join the organization
            <b> {!!request.org_details && request.org_details.org_name}</b> by
            <b> {!!request.requester && request.requester.name}</b>. By joining this organization, 
            you would be <b>transferring ownership of your account</b> to the organization.
          </p>
          <p>
            If you choose to accept the invitation, all of your email messages and files will
            still be available to you.
            However <b>your account will be managed by the administrators</b> at the organization and
            some settings may change.
          </p>
        </>
      ) : (
        <>
          <p>
            You have invited <b>{request.for_users[0].name}</b> to join
            <b> {!!request.org_details && request.org_details.org_name}</b>.
            By joining this organization, they will be transferring ownership of their account to
            the organization.
          </p>
          <p>
            If the user accepts the invitation, their account will be managed by the organizations
            administrators.
            The organization will be responsible for some user settings, recovery group, billing
            considerations, and data.
          </p>
        </>
      )}
    </>
  );
}

export default React.memo(SubsumeAccountComponent);
