// ----------------------------------------------------------------
// EMAIL VERSION HELPERS AND TYPE GUARDS: 
// ----------------------------------------------------------------
import { concatArrays } from "pvcryptojs";
import {
  GroupEmailBase, JSONResponseBlock, JSONEmail, JSONEmailV4, JSONEmailLatest, JSONAttachment, UserIdentifierBase, WrappedKey, ThreadMessage,
  ThreadAttachment, JSONBlock, MessageContents, WebMailRecipients, VersionedEmailBlock, JSONEmailBase
} from "@preveil-api";
import {
  Account, EmailUser, DecryptedEmailV4, EMAIL_PROTOCOL_VERSIONS, CURRENT_EMAIL_PROTOCOL_VERSIONS, Helpers, AttachmentMetadata, decryptAndVerifyEmailV4, AccountUserKey, MailThreadMessage,
  Attachment, prepareMailData, prepareMailMetadata, prepareEmailV4, ComposeMailWebData, MailRecipient, MessageV4, MailFlags, isSameUser, getProfileUserId
} from "src/common";
import { SymmKey, VerifyKey } from "src/common/keys";
import _ from "lodash";


// Misc Helpers for lateset protocol version:
export const AllMailProtocolVersions = [EMAIL_PROTOCOL_VERSIONS.V4, EMAIL_PROTOCOL_VERSIONS.V5, EMAIL_PROTOCOL_VERSIONS.V6, EMAIL_PROTOCOL_VERSIONS.V7];
export const SupportedMailProtocolVersions = [EMAIL_PROTOCOL_VERSIONS.V5, EMAIL_PROTOCOL_VERSIONS.V6, EMAIL_PROTOCOL_VERSIONS.V7];

// NEED TO CONVERT export interface DecryptedEmailV4 extends JSONEmailV4 to this
export interface DecryptedEmailLatest extends JSONEmail {
  decryption_key: SymmKey;
  decrypted_metadata: PrivateMetadataLatest;
  decrypted_attachments: AttachmentMetadata[];
  bccs: string[];
  recipients?: string[]; // avail at v7 and future versions
}

export type DecryptedEmail = DecryptedEmailV4 | DecryptedEmailLatest;

// This Interface accomodates protocol versions > 4
export interface PrivateMetadataLatest {
  sender: string;
  subject: string;
  body: {
    snippet: string;
    block_ids: string[];
    size: number;
  };
  attachments: JSONAttachment[];
  other_headers: { [key: string]: string };
  // People:
  tos: UserIdentifierBase[];
  ccs: UserIdentifierBase[];
  tos_groups: GroupEmailBase[] | null; // Added in V6
  ccs_groups: GroupEmailBase[] | null; // Added in V6
  external_sender: string | null;  // Added in V7
}


// Send message from V5-7

// Full latest mail message interface (send)
export interface MailMessageLatest extends JSONEmailBase { // Legacy JSONEmailV5
  block_ids: string[];
}

export interface MessageLatest {  // wrapper
  message: MailMessageLatest;
  bccs?: WrappedKey[];
  recipients: WrappedKey[];
}

export interface WebMailData {
  metadata: MessageLatest | MessageV4;
  blocks: VersionedEmailBlock[];
};

// Decription: if you want to check for V5, V6, or V7, which is pretty common
export function isDecryptedEmailV5toV7(version?: number): boolean {
  return !!version && SupportedMailProtocolVersions.includes(version);
}

// Decription: is C
export function isProtocolV4(version: number) {
  return version === EMAIL_PROTOCOL_VERSIONS.V4;
}

// Description: Validate message protocol_version as supported type
export function isValidMailProtocol(protocol_version: number): boolean {
  return AllMailProtocolVersions.includes(protocol_version);
}

// Description: Decrypt mail body by Version
// Legacy equivalent: EmailFactory.decryptBody(...args),
export async function decryptBody(blocks: JSONResponseBlock[], message: ThreadMessage):
  Promise<MessageContents | undefined> {
  const decryption_key = message.decryption_key as SymmKey;
  const block_ids = message.body_ids;
  if (!!block_ids && !!decryption_key) {
    const arr: Array<Promise<Uint8Array>> = [];
    _.map(block_ids, (id: string) => {
      const _block = blocks.find((b: JSONResponseBlock) => b.block_id === id);
      !!_block &&
        arr.push(decryption_key.decrypt(Helpers.b64Decode(_block.data)));
    });
    if (arr.length > 0) {
      const decrypted_content = await Promise.all(arr).then(blocks => concatArrays(...blocks));
      return JSON.parse(Helpers.utf8Decode(decrypted_content));
    }
  } else {
    throw new Error(`Unsupported email protocol version ${message.protocol_version}`);
  }
}

// Description: Decrypts attachments
// decrypted_meta: AttachmentMetadata, blocks: { [key: string]: JSONBlock } 
export async function decryptAttachment(attachment: ThreadAttachment, blocks: JSONBlock[]): Promise<Attachment> {
  const decrypted_meta = attachment.server_metadata;
  const decryption_key = decrypted_meta?.decryption_key as SymmKey;
  const block_ids = attachment.block_ids;
  if (!!decrypted_meta && !!decryption_key && block_ids) {
    const arr: Array<Promise<Uint8Array>> = [];
    _.map(block_ids, (id: string) => {
      const _block = blocks.find((b: JSONBlock) => b.block_id === id);
      !!_block &&
        arr.push(decryption_key.decrypt(_block.data));
    });

    const decrypted_content = await Promise.all(arr).then(blocks => (concatArrays(...blocks)));
    return Attachment.fromJSONAttachment(Object.assign({}, decrypted_meta), decrypted_content);
  } else {
    throw new Error(`Unsupported attachment ${attachment.filename}`);
  }
}

// Description: Get message_wrappred_key from mesage amd return with version
export function findWrappedKey(message: JSONEmailLatest): { user_key_version: number, message_wrappred_key: string } | undefined {
  if (isDecryptedEmailV5toV7(message.protocol_version)) {
    return {
      user_key_version: message.recipient_key_version || 0,
      message_wrappred_key: message.wrapped_key
    };
  } else if (message.protocol_version === EMAIL_PROTOCOL_VERSIONS.V4) {
    // eslint-disable-next-line no-unreachable-loop
    for (const b of (message as any).body.blocks.concat(...(message as any).attachments.map((a: JSONAttachment) => a.blocks))) {
      return {
        user_key_version: b.key_version,
        message_wrappred_key: b.wrapped_key
      };
    };
    throw new Error("no wrapped key found!");
  } else {
    throw new Error(`Unsupported email protocol version ${message.protocol_version}`);
  }
}

// Description: Decrypt and Verify email - returns [verified: boolean, decrypted_metadata: DecryptedEmail]
export async function decryptAndVerifyEmail(
  metadata: JSONEmail, decryption_key: SymmKey, user_key: AccountUserKey, verifying_key?: VerifyKey
): Promise<{ verified: boolean, message: ThreadMessage }> {
  let response;
  if (metadata.protocol_version === EMAIL_PROTOCOL_VERSIONS.V4) {
    response = await decryptAndVerifyEmailV4(metadata as JSONEmailV4, decryption_key, verifying_key);
  } else if (isDecryptedEmailV5toV7(metadata.protocol_version)) {
    response = await decryptAndVerifyEmailLatest(metadata, decryption_key, user_key, verifying_key);
  } else {
    throw new Error(`Unsupported email protocol version ${metadata.protocol_version}`);
  }

  // NOTE: Processs decrypted email into usable client model
  const message = response[1];
  // NOTE: Validate users in metadata against the server recipients in wrapper (for V5 and up)
  const verified = response[0] && validateRecipients(message);
  const client_message = new MailThreadMessage(response[1]);
  return { verified, message: client_message.ThreadMessage };
}

// Description: Decrypt and Verify email in Versions 5-7
// validates recipients for V5-7 and Initializes a client Message object that can be used in the UI
export async function decryptAndVerifyEmailLatest(
  metadata: JSONEmail,
  decryption_key: SymmKey,
  user_key: AccountUserKey,
  verifying_key?: VerifyKey
): Promise<[boolean, DecryptedEmailLatest]> {
  const [checksum, decrypted_metadata]: [Uint8Array, PrivateMetadataLatest] = await decryption_key.decrypt(Helpers.b64Decode(metadata.private_metadata))
    .then(raw => Promise.all([Helpers.sha256Checksum(raw), JSON.parse(Helpers.utf8Decode(raw)) as PrivateMetadataLatest]));
  const verified =
    !!verifying_key ?
      await verifying_key.verify(checksum, Helpers.b64Decode(metadata.signature)) : true;

  // Support verification of recipients and external sender validity:
  const bccs: string[] = metadata.wrapped_bccs ? await decryptMetadataUsers(user_key, metadata.wrapped_bccs) : [];
  const recipients: string[] = metadata.wrapped_recipients ? await decryptMetadataUsers(user_key, metadata.wrapped_recipients) : [];
  const external_sender: string = metadata.wrapped_external_sender ? await decryptMetadataExternalUser(user_key, metadata.wrapped_external_sender) : "";

  const decrypted_attachments = decrypted_metadata.attachments.map(
    a => Object.assign({}, a, { decryption_key, decrypted_name: a.name })
  );
  const message = Object.assign({}, metadata,
    {
      decrypted_metadata,
      decrypted_attachments,
      decryption_key,
      bccs,
      recipients,
      external_sender
    });

  return [verified, message];
}

// Notes: Supports > decryptAndVerifyEmailV5
async function decryptMetadataUsers(user_key: AccountUserKey, metadata_string: string): Promise<string[]> {
  return await user_key.encryption_key.unseal(Helpers.b64Decode(metadata_string))
    .then(encoded => Helpers.utf8Decode(encoded))
    .then(serialized => JSON.parse(serialized) as WrappedKey[])
    .then(user => user.map(i => !!i.external_email ? i.external_email.toLowerCase() : i.user_id.toLowerCase()));
}

// Notes: Supports > decryptAndVerifyEmailV5 for external senders
async function decryptMetadataExternalUser(user_key: AccountUserKey, metadata_string: string): Promise<string> {
  return await user_key.encryption_key.unseal(Helpers.b64Decode(metadata_string))
    .then(encoded => Helpers.utf8Decode(encoded).toLowerCase());
}

// Description: Verify recipients validity.
// users should always see the recipients that are inside private metadata.
// users need to check that recipients in private metadata is a super set of server wrapped recipients
export function validateRecipients(message: DecryptedEmail): boolean {
  const recipients = message.recipients;
  if (message.protocol_version > 4 && !!recipients) {
    const _message = message.decrypted_metadata as PrivateMetadataLatest;
    let merger: string[] = [];
    _message.tos.length > 0 &&
      _.map(_message.tos, (user: UserIdentifierBase) => {
        const _id = getProfileUserId(user);
        !!_id && merger.push(_id);
      });
    _message.ccs.length > 0 &&
      _.map(_message.ccs, (user: UserIdentifierBase) => {
        const _id = getProfileUserId(user);
        !!_id && merger.push(_id);
      });
    if (!!_message.tos_groups && _message.tos_groups.length > 0) {
      merger = merger.concat(getGroupUsersArray(_message.tos_groups));
    }
    if (!!_message.ccs_groups && _message.ccs_groups.length > 0) {
      merger = merger.concat(getGroupUsersArray(_message.ccs_groups));
    }
    return _.isEqual(_.uniq(merger).sort(), _.uniq(recipients).sort());
  } else {
    return true;
  }
}

// Description: gets array of users from a GroupEmailBase array of objects  
export function getGroupUsersArray(groups: GroupEmailBase[]): string[] {
  let arr: string[] = [];
  _.map(groups, (group: GroupEmailBase) => {
    arr = _.compact(_.map(group.users, (user: UserIdentifierBase) => getProfileUserId(user)));
  });
  return arr;
};

// -----------------------------------------------------------------------------------------------------------------
// Compose mail
// -----------------------------------------------------------------------------------------------------------------
// Description: Prepare mail dependent on Protocol version
// NOTES:       - Pass cs_recipients if V4 => needed to create individual emails
//              - For V4 mails, build 1 set of WebMailData per recipient (not the case for latest versions: 5-7)
//                  - Drafts are saved as V4 and need to have both flags: [MailFlags.SEEN_FLAG, MailFlags.DRAFT_FLAG] 
//                  - V4 emails have to send self Mail to current account to store in send so needs flag [MailFlags.SEEN_FLAG]
export async function encryptAndPrepareEmail(
  metadata: ComposeMailWebData,
  encryption_key: SymmKey,
  sender: Account,
  recipients: MailRecipient[] = [],
  is_draft: boolean = false,
  protocol_version: number = CURRENT_EMAIL_PROTOCOL_VERSIONS,
  new_symm_key_required: boolean = false
): Promise<WebMailData[]> {
  if (protocol_version === EMAIL_PROTOCOL_VERSIONS.V4) {
    return await Promise.all(_.map(recipients, async (recipient: MailRecipient) => {
      const flags = is_draft ? [MailFlags.SEEN_FLAG, MailFlags.DRAFT_FLAG] :
        isSameUser(sender.user_id, recipient.user_id) ? [MailFlags.SEEN_FLAG] : null;
      return await prepareEmailV4(
        encryption_key,
        sender,
        recipient,
        metadata.tos,
        metadata.ccs,
        metadata.bccs,
        metadata.subject,
        metadata.text,
        metadata.html,
        metadata.snippet,
        metadata.in_reply_to,
        metadata.references,
        metadata.attachments,
        flags,
        new_symm_key_required
      );
    }));
  } else {
    const emailPrep = await prepareEmailLatest(
      encryption_key,
      sender,
      metadata.tos,
      metadata.ccs,
      metadata.bccs,
      metadata.recipients,
      metadata.subject,
      metadata.text,
      metadata.html,
      metadata.snippet,
      metadata.in_reply_to,
      metadata.references,
      metadata.attachments,
      protocol_version
    );
    return [emailPrep];
  }

}


// Description: Prepare message with recipients, metadata and bccs
// NOTE: Pass all users in the correct Type
export async function prepareEmailLatest(
  symm_key: SymmKey,
  sender: Account,
  tos: EmailUser[],
  ccs: EmailUser[],
  bccs: EmailUser[],
  recipients: WebMailRecipients,
  subject: string,
  text: string,
  html: string,
  snippet: string,
  in_reply_to: string, references: string[],
  attachments: Attachment[],
  protocol_version: number = CURRENT_EMAIL_PROTOCOL_VERSIONS,
  external_sender: string | null = null
): Promise<WebMailData> {
  const body = Helpers.utf8Encode(JSON.stringify({ text, html }));
  const { sender_wrapped_key, _body, _attachments, _tos, _ccs, _bccs, blocks } =
    await prepareMailData(symm_key, sender, tos, ccs, bccs, body, snippet, attachments);
  const metadata: PrivateMetadataLatest = {
    sender: sender.user_id,
    external_sender,
    subject,
    tos: recipients.tos,
    tos_groups: recipients.tos_groups,
    ccs: recipients.ccs,
    ccs_groups: recipients.ccs_groups,
    other_headers: {},
    body: _body,
    attachments: _attachments
  };

  const { private_metadata, signature } = await prepareMailMetadata(metadata, symm_key, sender);
  // NOTES: protocol_version: SupportedMailProtocolVersions 5, 6, 7,
  //        external_sender: null, => Optional field not needed for send       
  const message = {
    protocol_version: isDecryptedEmailV5toV7(protocol_version) ? protocol_version : EMAIL_PROTOCOL_VERSIONS.V7,
    message_id: Helpers.newMessageId(),
    in_reply_to,
    references,
    private_metadata,
    signature,
    wrapped_key: sender_wrapped_key,
    block_ids: blocks.map(b => b.id),
  };

  return {
    metadata: {
      message,
      recipients: [..._tos, ..._ccs],
      bccs: _bccs
    },
    blocks
  };
}
