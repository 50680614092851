import { Key as KeyPB } from "../protos/keys_pb";

export abstract class VerifyKey {
  constructor(
    public protocol_version: number,
    public public_key: Uint8Array
  ) {}

  get public_buffer(): KeyPB {
    const pbIns = new KeyPB();
    pbIns.setProtocolVersion(this.protocol_version);
    pbIns.setKey(this.public_key);
    return pbIns;
  }

  abstract verify(plaintext: Uint8Array, signature: Uint8Array): Promise<boolean>;
}

export abstract class SigningKey extends VerifyKey {
  constructor(
    protocol_version: number,
    public_key: Uint8Array,
    public private_key: Uint8Array
  ) {
    super(protocol_version, public_key);
  }

  get buffer(): KeyPB {
    const pbIns = new KeyPB();
    pbIns.setProtocolVersion(this.protocol_version);
    pbIns.setKey(this.private_key);
    return pbIns;
  }

  serialize(): Uint8Array {
    return this.buffer.serializeBinary();
  }

  abstract cloneVerify(): VerifyKey;
  abstract sign(plaintext: Uint8Array): Promise<Uint8Array>;
}

