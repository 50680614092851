/* eslint-disable */
/* tslint:disable */
import { EncryptionKey, PublicKey } from "./encryption";
import {
  FipsCrypto,
  PVCryptoUsage
} from "pvcryptojs";
import { SealedData } from "../protos/key_pb";

export class EncryptionKeyV3 extends EncryptionKey {
  static protocol_version = 3;

  constructor(public_key: Uint8Array, public private_key?: Uint8Array) {
    super(EncryptionKeyV3.protocol_version, public_key, private_key);
  }

  static async new(sk?: Uint8Array): Promise<EncryptionKeyV3> {
    const [_sk, _pk] =
      !sk ?
        await FipsCrypto.generateAsymmKey(PVCryptoUsage.ENCRYPT) :
        [sk, await FipsCrypto.publicKeyFromPrivate(sk, PVCryptoUsage.ENCRYPT)];
    return new EncryptionKeyV3(_pk, _sk);
  }

  public clonePublic(): PublicKey {
    return new EncryptionKeyV3(this.public_key);
  }

  public async unseal(cipher: Uint8Array): Promise<Uint8Array> {
    if (!this.private_key) {
      throw Error("Invalid usage");
    }

    try {
      const sealed_data = SealedData.deserializeBinary(cipher),
        proof = sealed_data.getProof(),
        ciphertext = sealed_data.getCiphertext_asU8();

      if (proof !== 1) {
        return FipsCrypto.hybridDecrypt(this.private_key, cipher, false);
      }

      return FipsCrypto.hybridDecrypt(this.private_key, ciphertext, true);
    } catch (e) {
      return FipsCrypto.hybridDecrypt(this.private_key, cipher, false);
    }
  }

  public async seal(plaintext: Uint8Array): Promise<Uint8Array> {
    const ciphertext = await FipsCrypto.hybridEncrypt(this.public_key, plaintext, true);
    const sealedData = new SealedData();
    sealedData.setProtocolVersion(1);
    sealedData.setCiphertext(ciphertext);
    sealedData.setProof(1);
    return sealedData.serializeBinary();
  }
}
