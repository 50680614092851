import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { SupportRoutes, LocalAccountStorage, support_portal } from "src/common";
import { Icon } from "src/components";

// Description: Support Dropdown
function SupportDropdown() {
  const [support_notification, setSupportNotification] = useState<string | null>(localStorage.getItem("support_notification"));

  return (
    <Dropdown className={`nav-item${!!support_notification ? "" : " dot"}`}
      onClick={() => {
        setSupportNotification("true");
        LocalAccountStorage.setTemporaryCookies("support_notification", "true");
      }}>
      <Dropdown.Toggle as="a" title="Customer Support"
        data-tooltip-id="pv-tooltip"
        data-tooltip-content="What's New with PreVeil">
        <Icon className="ficon-help-circle show-desktop" />
        <span className="hide-desktop">Support</span>
      </Dropdown.Toggle>
      <Dropdown.Menu align="end">
        <Dropdown.Item target="_blank" title="Help Center & Support"
          href={`${support_portal}`} >
          Help Center
        </Dropdown.Item>
        <Dropdown.Item target="_blank" title="Getting started Support"
          href={`${SupportRoutes.getting_started}`}>
          Getting Started
        </Dropdown.Item>
        <Dropdown.Item target="_blank" title="Using Drive Support"
          href={`${SupportRoutes.using_drive}`}>
          Using Drive
        </Dropdown.Item>
        <Dropdown.Item target="_blank" title="Account & Recovery Settings Support"
          href={`${SupportRoutes.accounts_recovery_settings}`} >
          Account & Recovery Settings
        </Dropdown.Item>
        <Dropdown.Item target="_blank" title="Security & Privacy Support"
          href={`${SupportRoutes.security_privacy}`} >
          Security & Privacy
        </Dropdown.Item>
        <Dropdown.Item target="_blank" title="What's New with PreVeil"
          href={SupportRoutes.whats_new}>
          <Icon className="pv-icon-starred featured" />
          What's New with PreVeil
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default React.memo(SupportDropdown);
