import * as React from "react";
import { Badge, Button } from "react-bootstrap";
import { UserProfile } from "@preveil-api";
import { UserListStatus, userProfileTooltip } from "src/common";
import { Icon } from "src/components";
import _ from "lodash";

type AllProps = {
  current_contacts: UserProfile[];
  readonly?: boolean;
  handleAction: (_user_profile: UserProfile) => void;
  show_external_users?: boolean;
}

function UserBadgesComponent(props: AllProps) {
  const { current_contacts, readonly, handleAction, show_external_users } = props;

  function getClassName(user_profile: UserProfile): string {
    const status = !!user_profile.status
      ? user_profile.status === UserListStatus.external && !show_external_users
        ? UserListStatus.unclaimed
        : user_profile.status
      : "";

    return `user-block ${status}${readonly ? " readonly" : ""}`;
  }

  return <>
    {
      _.map(current_contacts, (user_profile: UserProfile, i: number) => {
        return <Badge key={`contact_${i}`}
          className={getClassName(user_profile)}
          bg="light" title={userProfileTooltip(user_profile)}>
          <span className="display-name">{user_profile.name}</span>
          {
            !readonly &&
            <Button variant="icon" size="sm" tabIndex={-1}
              onClick={() => handleAction(user_profile)}>
              <Icon className="ficon-x" /></Button>
          }
        </Badge>;
      })
    }</>;
}
export default React.memo(UserBadgesComponent);
