import * as React from "react";
import { Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { AccountUIActionTypes } from "src/common";

type AllProps = {
  handleAction: ActionHandlerFunction;
}

function AwaitConnectionComponent(props: AllProps) {
  const { handleAction } = props;

  return <>
    <h4><span className="waiting"></span>Awaiting Connection to new device&hellip;</h4>
    <div className="btn-panel mt-4">
      <Button variant="outline-primary" onClick={() => handleAction({ actionType: AccountUIActionTypes.Destroy })}>Cancel</Button>
    </div>
  </>;
}
export default React.memo(AwaitConnectionComponent);
