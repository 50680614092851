import React, { useState, useEffect, FocusEvent } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button, Dropdown } from "react-bootstrap";
import { ActionHandlerFunction, CurrentThread, PaginationItem } from "@preveil-api";
import { MailUIActionTypes, CheckboxStates, CheckboxStatesTypes, GlobalConstants, ClientDefaultMailboxesType, isSearchMailbox, isDraftMailbox } from "src/common";
import { Checkbox, Icon, Pagination, PaginationDisplay } from "src/components";

type AllProps = {
  isLoading: boolean;
  thread?: CurrentThread;
  mailbox_name: string;
  pagination?: PaginationItem;
  selected: string[];
  handleAction: ActionHandlerFunction;
}

function ToolbarComponent(props: AllProps) {
  const { isLoading, thread, mailbox_name, pagination, selected, handleAction } = props;
  const [btn_disabled, setButtonDisabled] = useState<boolean>(selected.length <= 0);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [show_menu, setShowMenu] = useState(false);

  useEffect(() => {
    !!pagination && setDisabled(pagination.totalRows <= 0 && !thread);
  }, [pagination, thread]);

  useEffect(() => {
    setButtonDisabled(selected.length <= 0 && !thread);
  }, [selected, thread]);

  // Description: Get the selected all checkbox state
  function getSelectedState(): CheckboxStatesTypes {
    let ck_state: CheckboxStatesTypes = CheckboxStates.empty;
    if (!!pagination) {
      ck_state = (!selected.length ? CheckboxStates.empty :
        (selected.length < GlobalConstants.LIMIT_NUMBER_THREAD_TO_FETCH && selected.length < pagination.totalRows) ?
          CheckboxStates.indeterminate : CheckboxStates.checked);
    }
    return ck_state;
  }

  // Description: Is current mailbox a client mailbox (starred, unread, outbox) OR Drafts (DA-2910)
  // NOTE: Move action is not allowed for those
  function isInvalidMoveMailbox(): boolean {
    return ClientDefaultMailboxesType.includes(mailbox_name) || isDraftMailbox(mailbox_name);
  }

  return <Row className={`toolbar${disabled ? " disabled" : ""}${btn_disabled ? " btn-disabled" : ""}`}>
    <Col xs="auto" className="order-0">
      {
        !!thread ?
          <Link className="btn btn-icon btn-sm" to={`/mail/${encodeURIComponent(mailbox_name)}/${!!pagination ? pagination.pageIndex + 1 : 1}`}>
            <Icon className="pv-icon-arrow-left" />
          </Link> :
          <Checkbox className="sr-only"
            onChange={(e: FocusEvent<HTMLInputElement>) => handleAction({ actionType: MailUIActionTypes.Select, params: { selected: e.target.checked } })}
            value="all" label="Select All" selected={getSelectedState()} />
      }
    </Col>
    <Col className="divider"></Col>
    <Col className="order-1">
      <Button variant="icon" size="sm" disabled={btn_disabled}
        onClick={() => handleAction({ actionType: MailUIActionTypes.Star, params: { messages: thread?.messages } })}
        aria-label="Star Selected"
        data-tooltip-id="pv-tooltip" data-tooltip-content={`Star ${!thread ? "Selected" : ""} Messages`}>
        <Icon className="pv-icon-unstarred" />
      </Button>
      <Button variant="icon" size="sm" disabled={btn_disabled}
        onClick={() => handleAction({ actionType: MailUIActionTypes.UpdateSeenFlag, params: { messages: thread?.messages, remove: true } })}
        aria-label="Mark Selected Unread"
        data-tooltip-id="pv-tooltip" data-tooltip-content={`Mark ${!thread ? "Selected" : ""} Messages Unread`}>
        <Icon className="ficon-mail" />
      </Button>
      {
        !isInvalidMoveMailbox() &&
        <Button variant="icon" size="sm" disabled={btn_disabled}
          onClick={() => handleAction({ actionType: MailUIActionTypes.MoveModal, params: true })}
          aria-label="Move Selected"
          data-tooltip-id="pv-tooltip" data-tooltip-content={`Move ${!thread ? "Selected" : ""} Messages`}>
          <Icon className="pv-icon-move" />
        </Button>
      }
      <Button variant="icon" size="sm" disabled={btn_disabled}
        onClick={() => handleAction({ actionType: MailUIActionTypes.Delete, params: { messages: thread?.messages } })}
        aria-label="Delete Selected"
        data-tooltip-id="pv-tooltip" data-tooltip-content={`Delete ${!thread ? "Selected" : ""} Messages`}>
        <Icon className="ficon-trash-2" />
      </Button>
      <Dropdown as="button" className="btn btn-icon btn-sm" title="More actions..." disabled={btn_disabled} onToggle={() => setShowMenu(!show_menu)} show={show_menu && !btn_disabled}>
        <Dropdown.Toggle as="span">
          <Icon className="ficon-more-vertical" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Item title="Mark selected messages as read"
            data-tooltip-id="pv-tooltip" data-tooltip-content={`Mark ${!thread ? "selected" : ""} messages as read`}
            data-tooltip-place="right"
            onClick={() => handleAction({ actionType: MailUIActionTypes.UpdateSeenFlag, params: { messages: thread?.messages, remove: false } })}>
            {`Mark ${!thread ? "selected" : "messages"} as read`}
          </Dropdown.Item>
          <Dropdown.Item aria-label="Remove Star from all selected messages"
            data-tooltip-id="pv-tooltip" data-tooltip-content={`Remove Star from ${!thread ? "all selected" : ""} messages`}
            data-tooltip-place="right"
            onClick={() => handleAction({ actionType: MailUIActionTypes.Star, params: { messages: thread?.messages, is_starred: true } })}>
            {`Remove Star from ${!thread ? "selected" : "thread"}`}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {/* TODO: Will wire this in phase II */}
      {/* {
        !!threadMode &&
        <Button variant="icon" size="sm"
          onClick={() => handleAction({ actionType: MailUIActionTypes.Print })}
          title="Print all" >
          <Icon className="ficon-printer" />
        </Button>
      } */}
    </Col>
    {
      (!thread && !!pagination) &&
      <Col className="order-sm-2 order-3">
        <PaginationDisplay pagination={pagination} />
      </Col>
    }
    {/* <Col className="divider order-sm-3 order-3"></Col> */}
    {/* 
      // !!thread ?
      // *****  NEED TO DETERMINE IF SELECTED THREAD IS FIRST OR LAST IN TABLE - TO BE DONE ********************** //
      //   <SimplePagination
      //     isFirst={false}
      //     isLast={false}
      //     className="mb-0 justify-content-end"
      //     handleAction={handleAction} /> : */}
    {
      !thread && !!pagination && <>
        <Col xs="auto" className="order-sm-3 order-4">
          <Pagination
            className="mb-0 justify-content-end"
            pagination={pagination}
            handleAction={handleAction} />
        </Col>
        <Col className="divider order-sm-4 order-2" />
      </>
    }
    {
      !isSearchMailbox(mailbox_name) &&
      <Col xs="auto" className="order-sm-4 order-2 refresh" >
        <Button variant="icon" size="sm"
          onClick={() => handleAction({ actionType: MailUIActionTypes.Refresh })}
          title="Refresh Mailbox">
          {
            isLoading ?
              <i className="spinner"></i> :
              <Icon className="ficon-rotate-cw" />
          }
        </Button>
      </Col>
    }
  </Row>;
}

export default React.memo(ToolbarComponent);