import React, { useState, useEffect } from "react";
import { UserProfile, ActionHandlerFunction } from "@preveil-api";
import { ApprovalRequestStatus, ApprovalGroup, RecoveryGroup, RequestTypes, UserRequest } from "src/common";
import { CollapseWrapper } from "src/components";
import { Details, Progress, SubsumeAccount, ForUsers, Approvers, ChangeApproverRole, DataExportStepSettings } from ".";

type AllProps = {
  step?: number;
  is_approval: boolean;
  request: UserRequest;
  current_recovery_group?: RecoveryGroup | ApprovalGroup;
  handleActions: ActionHandlerFunction;
};

function OffCanvasBodySectionComponent(props: AllProps) {
  const { is_approval, request, current_recovery_group, step, handleActions } = props;
  const [user_request, setUserRequest] = useState<UserRequest | null>(null);
  const [is_loading, setIsLoading] = useState<boolean>(true);
  const current_optional_users =
    !!current_recovery_group &&
    (current_recovery_group instanceof RecoveryGroup
      ? current_recovery_group.optional_users
      : current_recovery_group.approvers);
  const current_optionals_required =
    !!current_recovery_group &&
    (current_recovery_group instanceof RecoveryGroup
      ? current_recovery_group.optionals_required
      : current_recovery_group.required_approvers);
  const [members, setMembers] = useState<Array<{ user: UserProfile; new: boolean }>>([]);
  const [past_members, setPastMembers] = useState<UserProfile[]>([]);

  /* Description: On initial load, set the data from the request. */
  useEffect(() => {
    if (request && request?.request_id) {
      initializeUserRequestMetadata(request);
    }
    setData();
    setIsLoading(false);
  }, [request]);

  function initializeUserRequestMetadata(request: UserRequest) {
    setUserRequest(request);
  }

  /* Description: set the members and the past members. */
  function setData() {
    let current_optional_users: UserProfile[];
    if (!!current_recovery_group) {
      if (current_recovery_group instanceof RecoveryGroup) {
        current_optional_users = current_recovery_group.optional_users;
      } else {
        current_optional_users = current_recovery_group.approvers;
      }
      const m = request.approvers.map((user) => {
        return {
          user,
          new: !current_optional_users.find(
            (prev_optional) => prev_optional.address === user.address,
          ),
        };
      });
      setMembers(m);
      current_optional_users.forEach((prev_optional) => {
        if (!request.approvers.find((u) => u.address === prev_optional.address)) {
          if (!!past_members) {
            setPastMembers(past_members.concat([prev_optional]));
          } else {
            setPastMembers([prev_optional]);
          }
        }
      });
    } else {
      setMembers(request.approvers.map((approver) => ({ user: approver, new: false })));
    }
  }

  const is_export_request = request && request.type === RequestTypes.export && !!request?.export_content;
 
  return (
    <>
      {request.type === RequestTypes.change_org_approval_group_role && (
        <ChangeApproverRole request={request}></ChangeApproverRole>
      )}
      {request.type === RequestTypes.change_admin_status && !!request.updated_role && (
        <h3>Updated Role:<b className="with_cap"> {request.updated_role}</b></h3>
      )}
      {request.type === RequestTypes.subsume_account && (
        <SubsumeAccount is_approval={is_approval} request={request}></SubsumeAccount>
      )}
      {is_export_request && !is_loading && 
        <DataExportStepSettings
          handleActions={handleActions}
          step={step}
          data_export_request={user_request}
        />
      }
      {!!request.for_users && request.for_users.length > 0 && request.type !== RequestTypes.subsume_account && (
        <CollapseWrapper title={"For Users"}>
          <ForUsers request={request} />
        </CollapseWrapper>)
      }
      {!!members && members.length > 0 && (
        <CollapseWrapper title={!!request.approval_group_name ? `New Approval Group: ${request.approval_group_name}` : `Approver${members.length > 1 ? "s" : ""}`}>
          <Approvers members={members} past_members={past_members} />
        </CollapseWrapper>)
      }
      {!is_export_request &&
        <CollapseWrapper title={"Details"}>
          <Details
            request={request}
            current_recovery_group={current_recovery_group}
            current_optionals_required={current_optionals_required}
          />
        </CollapseWrapper>
      }
      {request.status === ApprovalRequestStatus.pending && !!current_optional_users && !!current_recovery_group && !!request.request_responses && (
        <Progress
          request={request}
          current_optional_users={current_optional_users}
          current_optionals_required={current_optionals_required}
        ></Progress>
      )}
    </>
  );
}

export default React.memo(OffCanvasBodySectionComponent);
