import React, { MouseEvent, useEffect, useState, FocusEvent } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Dropdown } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { CheckboxStates, DriveUIActionTypes, getDriveRouteType, DriveTrashItem, getEntityIcon, DriveEntryType, UUID, Helpers, DefaultRoutes } from "src/common";
import { Checkbox, Icon } from "src/components";


type AllProps = {
  entry: DriveTrashItem;
  selected: boolean;
  handleAction: ActionHandlerFunction;
};

function TrashRowComponent(props: AllProps) {
  const { entry, handleAction, selected } = props;
  const navigate = useNavigate();
  const [_selected, setSelected] = useState<boolean>(selected);

  useEffect(() => {
    setSelected(selected);
  }, [selected]);

  // Description: Handle the click of checkbox and changes of checkbox state
  function handleCheckboxChange(e: FocusEvent<HTMLInputElement>) {
    const new_selected = !_selected;
    setSelected(new_selected);
    handleAction({
      actionType: DriveUIActionTypes.Select,
      params: { selected: e.target.checked, entry },
    });
  }

  // Description: Build the url and navigate
  function handleRowClick() {
    if (entry.type === DriveEntryType.DIR) {
      const _collection_id = new UUID({ bytes: Helpers.b64Decode(entry.collection_id) }).String();
      const _id = new UUID({ bytes: Helpers.b64Decode(entry.id) }).String();
      navigate(`${DefaultRoutes.drive_trash}/${_collection_id}/${encodeURIComponent(_id)}/${getDriveRouteType(entry.type)}`);
    } 
    return false;
  }

  return <Row className={entry.type === DriveEntryType.FILE ? "hidden" : ""} onClick={handleRowClick}>
    <Col md="1">
      <Checkbox
        className="sr-only"
        onChange={(e: FocusEvent<HTMLInputElement>) => { handleCheckboxChange(e); }}
        selected={_selected ? CheckboxStates.checked : CheckboxStates.empty}
        value="all" label="Select All" />
    </Col>
    <Col md="5" className="entry-name col">
      <span>
        <Icon className={getEntityIcon(entry.type)} />{entry.name}
      </span>
    </Col>
    <Col md="2" className="order-3">
      {entry.type_label || entry.type}
    </Col>
    <Col md="3" className="order-4 overflow-text">
      {entry.deleted_at}
    </Col>
    <Col md="1" className="order-md-5 order-2">
      <Dropdown
        as="button"
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          e.preventDefault();
          document.body.click();
        }}
        className="btn btn-icon btn-sm" title="More actions..." disabled={false} >
        <Dropdown.Toggle as="span">
          <Icon className="ficon-more-vertical" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Item
            title="Restore"
            data-tooltip-id="pv-tooltip"
            data-tooltip-content="Restore"
            data-tooltip-place="right"
            onClick={() => handleAction({ actionType: DriveUIActionTypes.RestoreItems, params: entry  })}
          >
            <Icon className="pv-icon-move" /> Restore
          </Dropdown.Item>
          <Dropdown.Item
            title="Restore"
            data-tooltip-id="pv-tooltip"
            data-tooltip-content="Permanently Delete"
            data-tooltip-place="right"
            onClick={() => handleAction({ actionType: DriveUIActionTypes.Delete, params: entry })}
          >
            <Icon className="pv-icon-trash" /> Permanently Delete
          </Dropdown.Item>
          {entry.type === DriveEntryType.FILE && 
            <Dropdown.Item
              title="Download"
              data-tooltip-id="pv-tooltip"
              data-tooltip-content="Download"
              data-tooltip-place="right"
              onClick={() => handleAction({ actionType: DriveUIActionTypes.Download, params: { collection_id: entry.collection_id, id: entry.id, name: entry.name } })}
            >
              <Icon className="pv-icon-download" /> Download
            </Dropdown.Item>
          }
        </Dropdown.Menu>
      </Dropdown>
    </Col>
  </Row>;
}

export default React.memo(TrashRowComponent);
