import React from "react";
import _ from "lodash";

type AllProps = {
  total_steps: number;
  current_step: number;
}

// Description: Shared component - progress circles that shows the progress of the account flows
// currently used in create account, create express account, login and upgrade
function ProgressCirclesComponent(props: AllProps) {
    const { total_steps, current_step } = props;

    return <div className="progress-circles-container">
      {_.map(_.range(1, total_steps), (i) => {
        return <span key={i} className={`progress-circle ${current_step >= i && "filled-in"}`}></span>;
      })}
    </div>;
}

export default React.memo(ProgressCirclesComponent);
