import * as React from "react";
import { Link } from "react-router-dom";
import { Row, Col, ListGroup } from "react-bootstrap";
import { Icon } from "..";
import { PublicRoutes } from "src/common";

function AddAccountComponent() {
  return <Row className="justify-content-center">
    <Col md={8}>
      <h1 className="main-header">Add Existing Account</h1>
      <p className="mb-4 fs-3">
        Enable this computer to access your PreVeil account by installing your private key onto it.
      </p>
      <ListGroup className="public-card" horizontal>
        <ListGroup.Item className="image">
          <div className="copy-account-image"></div>
        </ListGroup.Item>
        <ListGroup.Item className="content">
          <p className="title">Copy Private Key</p>
          <p>
            Copy your private key from a computer or phone that already has
            your PreVeil account installed.
          </p>
          <Link className="btn btn-primary" to={`/${PublicRoutes.copy_account_route}`}>Copy from Device</Link>
        </ListGroup.Item>
      </ListGroup>
      <ListGroup className="public-card" horizontal>
        <ListGroup.Item className="image">
          <div className="recovery-group-image"></div>
        </ListGroup.Item>
        <ListGroup.Item className="content">
          <p className="title">Recover Private Key</p>
          <p>
            Your private key will be reconstructed with the authorization of
            your recovery group members.
          </p>
          <Link className="btn btn-primary" to={`/${PublicRoutes.account_recovery_route}`}>Recover Key</Link>
        </ListGroup.Item>
      </ListGroup>
      <ListGroup className="public-card" horizontal>
        <ListGroup.Item className="image">
          <div className="copy-account-image"></div>
        </ListGroup.Item>
        <ListGroup.Item className="content">
          <p className="title">Recover using backup file</p>
          <p>Use your PDF file to recover account.</p>
          <Link className="btn btn-primary" to={`/${PublicRoutes.account_backup_recovery_route}`}>Recover Account</Link>
        </ListGroup.Item>
      </ListGroup>
      <ListGroup className="public-card" horizontal>
        <ListGroup.Item className="image">
          <div className="upgrade-account-image"></div>
        </ListGroup.Item>
        <ListGroup.Item className="content">
          <p className="title">Upgrade from PreVeil Express</p>
          <p>
            Transfer your current PreVeil Express Account into a full
            featured PreVeil account.
          </p>
          <Link className="btn btn-primary" to={`/${PublicRoutes.upgrade_account_route}`}>Upgrade Express PreVeil</Link>
        </ListGroup.Item>
      </ListGroup>
      <p className="d-flex"><Icon className="pv-icon-question" />
        <span>To access your account, PreVeil uses a private encryption key that is stored only on your devices.
          When you add PreVeil to a new computer or phone, this private key is either copied from an existing device or
          retrieved using your recovery group.</span></p>
    </Col>
  </Row>;
}

export default React.memo(AddAccountComponent);
