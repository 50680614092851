import { useState, useCallback, useEffect, useRef } from "react";
import { MessageDisplayType, ErrorStackDataType } from "@preveil-api";
import {
  Account, useAppDispatch, Message, MessageHandlerDisplayType, DriveErrorMessages, useAppSelector, useFetchDirectory, useGetPermissions, getEnumKey,
  CollectionFilesyncAPI, DriveRequest, GrantSet, encryptName, useSendRequestMutation, DirectoryEntity, DriveEntryType, EntryItem, GrantSetType,
  ErrorStackItem
} from "src/common";
import { DriveCallbackAsyncFunction, uiActions } from "src/store";
import { FSMessage, FSStatus } from "src/common/keys/protos/collections_pb";
import { PermissionSetType } from "src/common/drive/permissions.class";
import { RootState } from "src/store/configureStore";
import { randomBytes } from "pvcryptojs";
import _ from "lodash";


type ResponseInfo = {
  entry?: EntryItem;
  error?: boolean;
  conflict?: boolean;
};

/* Description: The Web hook for moving an item in Drive */
export function useMove(current_account: Account) {
  const [error, setError] = useState<boolean>(false);
  const [done, setDone] = useState<boolean>(false);
  const [errors, setErrors] = useState<ResponseInfo[]>([]);
  const [successes, setSuccesses] = useState<EntryItem[]>([]);
  const [collection_id, setCollectionId] = useState<string>("");
  const [current_directory, setCurrentDirectory] = useState<DirectoryEntity>();
  const [items, setItems] = useState<string[]>();
  const [destination_id, setDestinationId] = useState<string>();
  const default_permissions = useAppSelector((state: RootState) => state.drive.default_permissions);
  const { permissions, getPermissions, destroyPermissions } = useGetPermissions(current_account);
  const { fetchDirectory, directory, resetDirectory, error: dir_error } = useFetchDirectory(current_account);
  const dispatch = useAppDispatch();
  const [sendRequest] = useSendRequestMutation();

  const permissionsRef = useRef(permissions);
  function setPermissionsRef(_permissions?: PermissionSetType) {
    permissionsRef.current = _permissions;
  }

  // Description: Handles responses for each item to move and sets done when all items have been responded.
  useEffect(() => {
    const responded = errors.length + successes.length;
    if (!!items && responded === items.length) {
      setDone(true);
    }
  }, [errors, successes]);

  useEffect(() => {
    if (!!permissions && !!destination_id) {
      setPermissionsRef(permissions);
      // NOTE: Return the permission state to undefined to be able to detect future chages
      destroyPermissions();
      fetchDirectory(permissions.collection_id.B64(), destination_id);
    }
  }, [permissions]);

  // Description: Detects changes success and error for the fetchDirectory hook and sets the entityInfo
  useEffect(() => {
    if (!!directory) {
      !!permissionsRef.current && !!current_directory && fetchItems(collection_id, permissionsRef.current, directory, current_directory);
    }
    if (!!dir_error) {
      handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_item_during_move });
    }
    resetDirectory();
  }, [directory, dir_error]);

  // Description: fetch item information based on type.
  function fetchItems(collection_id: string, permissions: PermissionSetType, destination: DirectoryEntity, current_directory: DirectoryEntity) {
    _.forEach(items, (entry_id: string) => {
      const entry = current_directory.entries.find((e) => e.id === entry_id);
      if (!!entry) {
        if (!!destination.entries.find((e) => e.name.toLowerCase() === entry.name.toLowerCase())) {
          setErrors((errors) => errors.concat([{ entry, error: true, conflict: true }]));
        } else {
          switch (entry.type) {
            case DriveEntryType.FILE:
              handleFetchFile(collection_id, entry.id, permissions, destination, entry);
              break;
            case DriveEntryType.DIR:
              handlefetchDirectory(collection_id, entry.id, permissions, destination, entry);
              break;
            case DriveEntryType.LINK:
              handleFetchLink(collection_id, entry.id, permissions, destination, entry);
              break;
          }
        }
      } else {
        setErrors(errors.concat([{ error: true }]));
      }
    });
  }

  async function handlefetchDirectory(_collection_id: string, id: string, permissions: PermissionSetType, destination: DirectoryEntity, entry: EntryItem, entry_version?: string, grants?: GrantSetType) {
    const request = await CollectionFilesyncAPI.getDirectoryInformationRequest(_collection_id, id);
    const drive_request = !!request && !!permissions ? await DriveRequest.initializeRequest(request, current_account, permissions) : null;
    async function fetchDirCallback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        const dir = message.getDir();
        if (!!dir && !!permissions) {
          const version = !!entry_version ? entry_version : dir.getVersion_asB64();
          const acl_list = dir.getAclList();
          if (acl_list.length > 0) { // if acl node.
            const grant_sets = GrantSet.getActiveGrantSets(_collection_id, dir.getId_asB64(), acl_list);
            const current_grants = !!grant_sets ? GrantSet.getActive(grant_sets, _collection_id, dir.getId_asB64(), current_account.user_id) : undefined;
            moveItem(entry, version, destination, permissions, current_grants, destination.grants); // We must send grants of the acl node as well as the grants of the destination.
          } else if (dir.hasMaintainerId()) { // if child of maintainer.
            if (dir.getMaintainerId_asB64() !== destination.grants?.id) {
              handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.cannot_move_to_acl_node }, null, entry);
            } else {
              handlefetchDirectory(_collection_id, dir.getMaintainerId_asB64(), permissions, destination, entry, version, grants);
            }
          } else { // if under v1 collection or root.
            moveItem(entry, version, destination, permissions);
          }
        } else {
          handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_directory }, message, entry, MessageHandlerDisplayType.toastr);
        }
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_directory }, message);
      }
    }
    handleSendRequest(drive_request, fetchDirCallback, entry);
  }

  // Description: useEffect hook for when grants (from the useGetGrants hook) is initialized.
  async function handleFetchFile(_collection_id: string, file_id: string, permissions: PermissionSetType, destination: DirectoryEntity, entry: EntryItem) {
    const request = !!permissions ? await CollectionFilesyncAPI.fetchFile(current_account, _collection_id, file_id, permissions) : null;
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        const file = message.getFile();
        if (!!file) {
          if (file.hasMaintainerId()) {
            if (file.getMaintainerId_asB64() !== destination.grants?.id) {
              handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.cannot_move_to_acl_node }, null, entry);
            } else {
              handlefetchDirectory(_collection_id, file.getMaintainerId_asB64(), permissions, destination, entry, file.getVersion_asB64());
            }
          } else {
            moveItem(entry, file.getVersion_asB64(), destination, permissions);
          }
        }
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.cannot_move_to_acl_node }, message, entry);
      }
    }
    handleSendRequest(request, callback, entry);
  }

  // Description: Fetch link to get link version and call moveItem
  async function handleFetchLink(_collection_id: string, id: string, permissions: PermissionSetType, destination: DirectoryEntity, entry: EntryItem) {
    const request = await CollectionFilesyncAPI.fetchLinkRequest(_collection_id, id);
    const drive_request = !!permissions ? await DriveRequest.initializeRequest(request, current_account, permissions) : null;
    async function callback(message: FSMessage) {
      const link = message.getLink();
      if (!!link && message.getStatus() === FSStatus.OK) {
        moveItem(entry, link.getVersion_asB64(), destination, permissions);
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_link }, message, entry);
      }
    }
    handleSendRequest(drive_request, callback, entry);
  }

  // Description: encrypt name with new parent's symmetric key and moveItem (with backend UPDATE method)
  async function moveItem(entry: EntryItem, old_version: string, destination: DirectoryEntity, permissions: PermissionSetType, grants?: GrantSetType, parent_grants?: GrantSetType) {
    const new_version = randomBytes(32);
    const { symmetric_key, version, wrapped_key, id } = destination;
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        setSuccesses(successes => successes.concat([entry]));
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_moving_item }, message, entry);
      }
    }
    if (!!symmetric_key && !!wrapped_key) {
      const encrypted_name = await encryptName(entry.name, symmetric_key);
      const request = await CollectionFilesyncAPI.moveItem(current_account, permissions, permissions.collection_id.B64(),
        entry.id, old_version, id, version, encrypted_name, new_version, wrapped_key, !!grants ? (!!parent_grants ? [grants, parent_grants] : [grants]) : undefined);
      handleSendRequest(request, callback, entry);
    }
  }

  // Description: Sends Request and handles error when the request object is null, pass it custom stack message
  function handleSendRequest<T>(request: DriveRequest | null, callback: DriveCallbackAsyncFunction<T>, entry?: EntryItem, stack_error: string = DriveErrorMessages.error_sending_request) {
    if (!!request) {
      request.setCallback(callback);
      sendRequest(request);
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, { stack_error, stack_message: DriveErrorMessages.error_sending_request }, null, entry);
    }
  }

  // Description: Handle error message and send error to store
  function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, fsmessage?: FSMessage | null, entry?: EntryItem, display_type: MessageDisplayType = MessageHandlerDisplayType.logger) {
    !!entry && setErrors(errors.concat([{ entry, error: true }]));
    const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
    const stack = new ErrorStackItem("[useMove Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
    dispatch(uiActions.handleRequestErrors(new Message(message, display_type), stack));
    !entry && setError(true);
  }

  // Description: Callback for moving items
  const move = useCallback(
    (_collection_id: string, current_directory: DirectoryEntity, destination: string, items_to_move: string[]) => {
      setCurrentDirectory(current_directory);
      setCollectionId(_collection_id);
      setItems(items_to_move);
      setDestinationId(destination);
      getPermissions(_collection_id, default_permissions);
    },
    [],
  );

  // Description: reset hook 
  const resetMove = useCallback(() => {
    setError(false);
    setItems(undefined);
    setDone(false);
    setDestinationId(undefined);
    destroyPermissions();
  }, []);

  return {
    move,
    resetMove,
    error,
    errors,
    successes,
    done
  };
}
