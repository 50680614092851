import React, { useState, useEffect, FocusEvent } from "react";
import { Card, Alert } from "react-bootstrap";
import { IActionHandler, OrgInfo, CollectionServerUser } from "@preveil-api";
import {
  Account, AdminMessages, AdminErrorMessages, AdminSuccessMessages, collectionApi, Message, MessageAnchors, MessageHandlerDisplayType, GlobalErrorMessages,
  MessageToastTypes, useAppDispatch, useGetUsersOrgsByEntityIdQuery, useGetUsersOrgsByEntityIdGatewayQuery, CheckboxStates,
  usePostUsersOrgsByEntityIdGatewayAliasesRemoveMutation, usePostUsersOrgsByEntityIdGatewayAliasesMutation, usePutUsersOrgsByEntityIdGatewayEnabledMutation,
  usePutUsersOrgsByEntityIdGatewayUserMutation,
  CheckboxStatesTypes, GatewayConnectivityStatus
} from "src/common";
import { AddressesListPanel, AdminToolbar, PageHeader, AlertWithSwitch, Loading, InfoPopover } from "src/components";
import { EmailGatewayTopPanel, AddToEmailGateway, AddEmailGatewayModal } from ".";
import { uiActions } from "src/store";
import _ from "lodash";

type AllProps = {
  account: Account;
  org_info: OrgInfo;
}

type EmailGatewayAlias = {
  display_name: string;
  email_address: string;
  type: "domain" | "individual";
}

function EmailGatewayComponent({ account, org_info }: AllProps) {
  const dispatch = useAppDispatch();

  // Local State
  const [show_modal, setShowModal] = useState<boolean>(false);
  const [email_gateway_list, setEmailGatewayList] = useState<string[]>([]);
  const [gateway_user_id, setGatewayUserId] = useState<string>("");
  const [is_email_gateway_enabled, setEmailGatewayStatus] = useState<boolean>(false);
  const [organization_info, setOrganizationInfo] = useState<CollectionServerUser[]>([]);
  const [filter_param, setFilterParam] = useState<string>("");
  const [selected_gateway_emails, setSelectedGatewayEmails] = useState<string[]>([]);
  const [gateway_connectivity_status, setGatewayConnectivityStatus] = useState<GatewayConnectivityStatus | null>(null);
  const [is_invalid_gateway_id, setIsInvalidGatewayId] = useState<boolean>(false);
  // Loading state
  const [is_loading, setIsLoading] = useState(true);
  const [is_user_loading, setIsUserIdLoading] = useState<boolean>(true);

  // RTK Hooks
  const [postUsersOrgsByEntityIdGatewayAliasesRemove] = usePostUsersOrgsByEntityIdGatewayAliasesRemoveMutation();
  const [postUsersOrgsByEntityIdGatewayAliases] = usePostUsersOrgsByEntityIdGatewayAliasesMutation();
  const [putUsersOrgsByEntityIdGatewayUser] = usePutUsersOrgsByEntityIdGatewayUserMutation();
  const [putUsersOrgsByEntityIdGatewayEnabled] = usePutUsersOrgsByEntityIdGatewayEnabledMutation();
  const [getUsersOrgsByEntityIdGatewayStatus] = collectionApi.endpoints.getUsersOrgsByEntityIdGatewayStatus.useLazyQuery();
  const [getUsersOrgsByEntityIdGateway] = collectionApi.endpoints.getUsersOrgsByEntityIdGateway.useLazyQuery();
  const { data, error, refetch, isFetching } = useGetUsersOrgsByEntityIdGatewayQuery({
    account_ids: Account.getAccountIdentifiers(account),
    body: {
      entityId: org_info.org_id
    }
  });

  const { data: orgData, error: orgError } = useGetUsersOrgsByEntityIdQuery({
    account_ids: Account.getAccountIdentifiers(account),
    body: {
      entity_id: org_info.org_id,
    }
  });

  const filtered_addresses = _.isEmpty(filter_param) ? email_gateway_list : filterAddressList(filter_param);
  const dialog_message = is_email_gateway_enabled ? AdminMessages.disable_gateway : AdminMessages.enable_gateway;
  const isSelectedListEmpty = selected_gateway_emails.length === 0;

  useEffect(() => {
    if (!!account) {
      getGatewayConnectivityStatus();
    }
  }, [account]);

  useEffect(() => {
    if (!isFetching) {
      refetch();
    }
  }, []);

  useEffect(() => {
    const isDataFetched = !_.isEmpty(data) && !_.isEmpty(orgData);
    if (isDataFetched) {
      try {
        const { enabled, user_id } = data;
        const { users } = orgData;
        const orgUsers = users.filter((user) => !user.deleted);
        user_id !== gateway_user_id && setGatewayUserId(user_id);
        enabled !== is_email_gateway_enabled && setEmailGatewayStatus(enabled);
        setOrganizationInfo(orgUsers);
        setIsUserIdLoading(false);
      } catch (error) {
        const params = {
          message: AdminErrorMessages.error_fetching_gateway_users_data,
          stack: error
        };
        dispatchPageError(params);
      }
    }

    if (error) {
      dispatch(uiActions.handleRequestErrors(new Message(AdminErrorMessages.error_fetching_email_gateway), error));
    }
    if (orgError) {
      const params = {
        message: AdminErrorMessages.error_fetching_org_info,
        stack: orgError
      };
      dispatchPageError(params);
    }
  }, [data, error, orgData?.users, orgError]);

  useEffect(() => {
    if (data && data?.aliases) {
      const { aliases } = data;
      setEmailGatewayList(aliases);
      setIsLoading(false);
    }
  }, [data?.aliases]);

  // Description: dispatch any error found in the page.
  function dispatchPageError(params: { message: string, stack?: any }) {
    dispatch(uiActions.handleRequestErrors(new Message(params.message, MessageHandlerDisplayType.toastr), params.stack));
  }

  // Description: Connectivity Test status.
  async function getGatewayConnectivityStatus(notification?: { show: boolean }) {
    const entityId = org_info.org_id;
    const account_ids = Account.getAccountIdentifiers(account);
    const body = { entityId };
    await getUsersOrgsByEntityIdGatewayStatus({
      account_ids, body
    })
      .unwrap()
      .then((response) => {
        setGatewayConnectivityStatus(response);
        // We don't want to notify the user on page load, just fetch what the status is.
        if (notification?.show) {
          dispatch(
            uiActions.handleSetMessage(
              new Message(AdminSuccessMessages.success_gateway_connectivity_test),
            ),
          );
        }
      })
      .catch((error) => {
        const params = {
          message: AdminErrorMessages.error_gateway_connectivity_test,
          stack: error
        };
        dispatchPageError(params);
      });
    
  }

  // Description: Use for Refresh action in Toolbar. Fetches list of aliases.
  async function refreshGatewayList() {
    setIsLoading(true);
    const entityId = org_info.org_id;
    const account_ids = Account.getAccountIdentifiers(account);
    const body = { entityId };
    await getUsersOrgsByEntityIdGateway({
      account_ids, body
    })
      .unwrap()
      .then((result) => {
        const { aliases } = result;
        setEmailGatewayList(aliases);
        setIsLoading(false);
      })
      .catch((error) => {
        const params = {
          message: AdminErrorMessages.error_fetching_gateway_aliases,
          stack: error
        };
        dispatchPageError(params);
      });

  }

  // Description: Set/Change Gateway User ID.
  async function changeGatewayUserIdRequest(userId: string) {
    const entityId = org_info.org_id;
    const account_ids = Account.getAccountIdentifiers(account);
    await putUsersOrgsByEntityIdGatewayUser({
      account_ids,
      body: {
        entityId,
        user_id: userId
      }
    })
      .unwrap()
      .then(() => {
        setGatewayUserId(userId);
        setIsInvalidGatewayId(false);
        dispatch(
          uiActions.handleSetMessage(
            new Message(AdminSuccessMessages.success_assign_email_gateway_user_id),
          ),
        );
      })
      .catch((error) => {
        let params;
        const { status, statusText, data } = error;
        // If user is not part of the organization it will return a 409 status error.
        // Note Update: now we are filtering out non-org users, so it shouldn't return this error
        // But just keeping it in case.
        if (status === 409 && statusText === "Conflict") {
          const { errors } = data;
          const { cause: userId } = errors[0];
          params = {
            message: AdminErrorMessages.error_gateway_user_no_org.replace(MessageAnchors.user_id, userId),
            stack: errors
          };
        } else {
          params = {
            message: AdminErrorMessages.error_gateway_assign_user,
            stack: error
          };
        }
        setIsInvalidGatewayId(true);
        dispatchPageError(params);
      });
  };

  // Description: handle filtering address list based
  // on the input search param from toolbar component
  function filterAddressList(param: string) {
    return email_gateway_list.filter((address: any) => {
      return (
        address.toLowerCase().indexOf(param.toLowerCase()) !== -1 ||
        address.includes(param.toLowerCase())
      );
    });
  };

  // Description: Add new Aliases (Addreses)
  async function addToEmailGatewayAddresses(addresses: EmailGatewayAlias[]) {
    const entityId = org_info.org_id;
    const account_ids = Account.getAccountIdentifiers(account);
    await postUsersOrgsByEntityIdGatewayAliases({
      account_ids,
      body: {
        entityId,
        aliases: addresses
      }
    })
      .unwrap()
      .then(() => {
        dispatch(
          uiActions.handleSetMessage(
            new Message(AdminSuccessMessages.success_add_email_gateway_address),
          ),
        );
      })
      .catch((error) => {
        const params = {
          message: AdminErrorMessages.error_adding_trusted_community,
          stack: error
        };
        dispatchPageError(params);
      });
  }

  // Description: Remove Aliases (addresses) from Email Gateway
  async function removeFromEmailGatewayAddresses(addresses: string[]) {
    const entityId = org_info.org_id;
    const aliases = _.isArray(addresses) ? addresses : selected_gateway_emails;
    const account_ids = Account.getAccountIdentifiers(account);
    await postUsersOrgsByEntityIdGatewayAliasesRemove({
      account_ids,
      body: {
        entityId,
        aliases
      }
    })
      .unwrap()
      .then(() => {
        if (selected_gateway_emails.length > 1 && addresses) {
          const selectedAddresses = selected_gateway_emails.filter((selectedAddress) => !addresses.includes(selectedAddress));
          setSelectedGatewayEmails(selectedAddresses);
        } else {
          setSelectedGatewayEmails([]);
        }
        dispatch(
          uiActions.handleSetMessage(
            new Message(AdminSuccessMessages.success_delete_email_gateway_addresses),
          ),
        );
      })
      .catch((error) => {
        const params = {
          message: AdminErrorMessages.error_removing_email_gateway,
          stack: error
        };
        dispatchPageError(params);
      });
  };

  // Description: Enable/Disable Email Gateway org.
  async function toggleEmailGatewayRequest() {
    const entityId = org_info.org_id;
    const account_ids = Account.getAccountIdentifiers(account);
    await putUsersOrgsByEntityIdGatewayEnabled({
      account_ids,
      body: {
        entityId,
        enabled: !is_email_gateway_enabled
      }
    })
    .unwrap()
    .then(() => {
      const message = is_email_gateway_enabled ? AdminSuccessMessages.success_disable_email_gateway : AdminSuccessMessages.success_enable_email_gateway;
      setEmailGatewayStatus(!is_email_gateway_enabled);
      dispatch(uiActions.handleSetMessage(new Message(message)));
    })
    .catch((error) => {
      const gatewayStatus = is_email_gateway_enabled ? "Disabling" : "Enabling";
      const message = AdminErrorMessages.error_toggle_email_gateway.replace(MessageAnchors.message_content, gatewayStatus);
      const params = {
        message,
        stack: error
      };
      dispatchPageError(params);
    });
  }

  // Description: if only one address/domain is selected (checked) it will add it to the selected_gateway_emails
  // if deselected (unchecked) it will remove it from the collection.
  function singleSelectAddress(params: { e: FocusEvent<HTMLInputElement>, id: string }) {
    const { e, id } = params;
    const { checked } = e.target;
    if (checked) {
      setSelectedGatewayEmails([...selected_gateway_emails, id]);
    } else if (!checked) {
      setSelectedGatewayEmails(selected_gateway_emails.filter((address) => address !== id));
    }
  };

  // Description: When user checks the Name checkbox it will select/checked
  // all the addresses in the current list, if the current list is filtered
  // it will only seletect those only, if filter param is remove, list will show select/unselected addresses.
  function multiSelectAddress(e: FocusEvent<HTMLInputElement>) {
    const { checked } = e.target;
    if (checked) {
      setSelectedGatewayEmails([...filtered_addresses]);
    } else if (!checked) {
      setSelectedGatewayEmails([]);
    }
  };

  // Description: Handles the confirmation dialog, warning user if they want to
  // delete the selected addresses/domains.
  function confirmDeleteAddressesDialog(addresses: string[]) {
    const confirmation_dialog = new Message(
      AdminMessages.confirm_delete_email_gateway.body,
      MessageHandlerDisplayType.confirm,
      MessageToastTypes.primary,
      AdminMessages.confirm_delete_email_gateway.title,
      {
        label: "Yes",
        data: true,
        action: () => removeFromEmailGatewayAddresses(addresses)
      },
      {
        label: "No"
      }
    );
    dispatch(uiActions.handleSetMessage(confirmation_dialog));
  }

  // Description: handles state email gateway (active/no active).
  function handleToggleEmailGateway() {
    const confirmation_dialog = new Message(
      dialog_message.body,
      MessageHandlerDisplayType.confirm,
      MessageToastTypes.primary,
      dialog_message.title,
      {
        label: "Yes",
        data: true,
        action: () => toggleEmailGatewayRequest()
      },
      {
        label: "No"
      }
    );
    dispatch(uiActions.handleSetMessage(confirmation_dialog));
  }

  const EmailGatewayRequests = {
    handleGetRefreshRequest: () => {
      refreshGatewayList();
    },
    handleSearchParam: (param: string) => {
      setFilterParam(param);
    },
    handleDelete: (addresses: string[]) => {
      confirmDeleteAddressesDialog(addresses);
    },
    handleShowModal: () => {
      setShowModal(!show_modal);
    },
    handleSubmitAddresses: (collection: EmailGatewayAlias[]) => {
      if (gateway_user_id) {
        // we want to ensure that there is a gateway user id returned from services (previously set by user)
        // if not, it will return a 500 server error if the user tries to add new addresses
        setShowModal(false);
        addToEmailGatewayAddresses(collection);
      } else {
        // error notifying user to set email gateway id before adding addresses.
        const params = {
          message: AdminErrorMessages.error_no_gateway_id,
          stack: gateway_user_id
        };
        dispatchPageError(params);
      }
    },
    handleTestGatewayConnectivity: () => {
      getGatewayConnectivityStatus({ show: true });
    },
    handlePageError: (params: { message: string, stack?: any }) => {
      dispatchPageError(params);
    },
    handleSingleSelect: (params: { e: FocusEvent<HTMLInputElement>, id: string }) => {
      singleSelectAddress(params);
    },
    handleMultiSelect: (e: FocusEvent<HTMLInputElement>) => {
      multiSelectAddress(e);
    },
    handleReset: () => {
      setFilterParam("");
    },
    handleChangeGatewayUserId: (userId: string) => {
      changeGatewayUserIdRequest(userId);
    },
    unHandleErrorMessage: (params: { message: string, stack?: any }) => {
      const { message, stack } = params;
      dispatch(uiActions.handleRequestErrors(new Message(message, MessageHandlerDisplayType.logger), stack));
    }
  };

  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    if ((EmailGatewayRequests as any)[callback] instanceof Function) {
      (EmailGatewayRequests as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(MessageAnchors.actionType, actionObj.actionType);
      EmailGatewayRequests.unHandleErrorMessage({ message, stack: actionObj });
    }
  }

  // if there is no gateway_user_id assigned we keep the "toggle disabled" and "add address" disabled as well (the button at the toolbar).
  const disable_email_gateway_buttons_actions = !gateway_user_id;
  const createGatewayAddressesBtnMessage = disable_email_gateway_buttons_actions ? "Please choose a Gateway User ID, before creating Gateway Aliases" : "Create Email Gateway Alias";
  const currentGatewayUserId = !!gateway_user_id ? [gateway_user_id] : [];
  const disableRefresh = email_gateway_list.length === 0;

  let selectAllCheckboxState = CheckboxStates.empty as CheckboxStatesTypes;

  if (email_gateway_list.length === selected_gateway_emails.length && selected_gateway_emails.length !== 0) {
    selectAllCheckboxState = CheckboxStates.checked;
  } else if (selected_gateway_emails.length < email_gateway_list.length && selected_gateway_emails.length !== 0) {
    selectAllCheckboxState = CheckboxStates.indeterminate;
  }
  const total_aliases_label = email_gateway_list.length === 1 ? "Entry" : "Entries";

  const info_message = is_email_gateway_enabled ? AdminMessages.enabled_gateway_info : AdminMessages.disabled_gateway_info;

  return (
    <div className="admin-wrapper content-wrapper">
      <PageHeader>
        <h1>Secure Email Gateway</h1>
      </PageHeader>

      <Card className="card-section">

        <Alert className="gateway-steps" variant="light" dismissible={true}>
          <p>Steps to activate Secure Email Gateway</p>
          <ol>
            <li>Assign a gateway user ID.</li>
            <li>Toggle the secure email gateway to on.</li>
            <li>Define the controlled address and domain list.</li>
          </ol>
        </Alert>

        <Card className="gateway-card">
          {!is_user_loading ? (
            <EmailGatewayTopPanel
              org_users={organization_info}
              is_invalid_gateway_id={is_invalid_gateway_id}
              gateway_user_id={currentGatewayUserId}
              gateway_connectivity_status={gateway_connectivity_status}
              current_account={account}
              handleActions={handlePageActions} />
          ) : null}
        </Card>

        <AlertWithSwitch
          popover={false}
          disabled={is_loading || disable_email_gateway_buttons_actions}
          toggleState={is_email_gateway_enabled}
          handleChange={handleToggleEmailGateway}
        >
          <label className="switch-legend">
            <span>{`The organization's Secure Email Gateway is ${is_email_gateway_enabled ? "enabled" : "disabled"
              }.`}</span>
            <InfoPopover
              message={info_message}
              placement="right"
            />
          </label>
        </AlertWithSwitch>

        <AddEmailGatewayModal
          show_modal={show_modal}
          handleActions={handlePageActions}
          saved_addresses={filtered_addresses}
        />

        {!!gateway_user_id && !!is_email_gateway_enabled &&
          <>
            <AdminToolbar
              is_loading={is_loading}
              is_add_button_disable={disable_email_gateway_buttons_actions}
              is_refresh_disable={disableRefresh}
              is_delete_disable={isSelectedListEmpty}
              total_items_filtered={filtered_addresses.length}
              total_items={email_gateway_list.length}
              handleActions={handlePageActions}
              filter_param={filter_param}
              label={total_aliases_label}
              button_title={createGatewayAddressesBtnMessage}
            />

            {is_loading ? (
              <div className="cover-content">
                <div className="admin-panel-center">
                  <Loading className="in-place" />
                </div>
              </div>
            ) : (
              <AddressesListPanel
                allSelectState={selectAllCheckboxState}
                filtered_addresses={filtered_addresses}
                total_addresses={email_gateway_list}
                selected_address_list={selected_gateway_emails}
                handleActions={handlePageActions}
              >
                <div className="admin-panel-center">
                  <AddToEmailGateway handleActions={handlePageActions} />
                </div>
              </AddressesListPanel>
            )}
          </>
        }
      </Card>
    </div>
  );
}

export default React.memo(EmailGatewayComponent);
