import React, { useEffect, useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import { AsyncJobStatus, AsyncJobStatusMap, AsyncJobTypeMap, ComposeModalSize, ComposeModalSizeType, DriveErrorMessages, DriveSuccessMessages, Message, MessageHandlerDisplayType, useAppDispatch, useAppSelector, useFSProcessNotification } from "src/common";
import { RootState } from "src/store/configureStore";
import { driveActions, uiActions } from "src/store";
import { Icon } from "src/components";

function SyncNotificationTrayComponent() {
  const show_sync_notification = useAppSelector((state: RootState) => state.ui.show_sync_status);
  const job_id = useAppSelector((state: RootState) => state.ui.sync_job_id);
  const upload_request = useAppSelector((state: RootState) => state.drive.upload_request);
  const download_request = useAppSelector((state: RootState) => state.drive.download_request);
  const [job_status, setJobStatus] = useState<AsyncJobStatus | undefined>();
  const [modal_size, setModalSize] = useState<ComposeModalSizeType>(ComposeModalSize.lg);
  const [displace, setDisplace] = useState<boolean>(false);
  const { new_notification: sync_notification } = useFSProcessNotification(AsyncJobTypeMap.SYNC_V2, job_id);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (sync_notification && sync_notification.status && sync_notification.request_id === job_id) {
      handleSyncNotification(sync_notification.status);
    }
  }, [sync_notification, job_id, dispatch]);

  useEffect(() => {
    if (!upload_request && !download_request) {
      setDisplace(false);
    } else {
      setDisplace(true);
    }
  }, [upload_request, download_request]);

  const handleSyncNotification = (status: AsyncJobStatus) => {
    setJobStatus(status);
    const is_completed = status === AsyncJobStatusMap.COMPLETED;
    const is_in_progress_or_queued = status === AsyncJobStatusMap.IN_PROGRESS || status === AsyncJobStatusMap.QUEUED;
    const is_failed = status === AsyncJobStatusMap.FAILED || status === AsyncJobStatusMap.HARD_FAILED;
  
    if (is_completed || is_in_progress_or_queued) {
      dispatch(driveActions.setReloadDriveList(true));
    }
  
    if (is_completed) {
      dispatch(uiActions.handleSetMessage(new Message(DriveSuccessMessages.success_selective_sync)));
    }
  
    if (is_failed) {
      dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.error_selective_sync, MessageHandlerDisplayType.toastr)));
    }
  
    if (is_completed || is_failed) {
      setTimeout(() => {
        dispatch(uiActions.setShowSyncStatus(false));
      }, 3500);
    }
  };
  return <Offcanvas
    className={`bottom-progress ${modal_size} ${displace ? "displace" : ""}`}
    backdrop={false}
    show={show_sync_notification}
    enforceFocus={false}
    placement="bottom">
    <Offcanvas.Header>
      <Offcanvas.Title>Sync Status</Offcanvas.Title>
      <div className="btns">
        <Button variant="transparent" onClick={() =>
          modal_size === ComposeModalSize.minimized ? setModalSize(ComposeModalSize.lg) : setModalSize(ComposeModalSize.minimized)
        }>
          {modal_size === ComposeModalSize.minimized ? <Icon className="ficon-maximize-2" /> : <Icon className="pv-icon-minimize" />}
        </Button>
      </div>
    </Offcanvas.Header>
    <Offcanvas.Body>
      {modal_size === ComposeModalSize.lg && (
        <div className="py-1">
          {job_status === AsyncJobStatusMap.FAILED || job_status === AsyncJobStatusMap.HARD_FAILED ? (
            <div className="status-icon-append">
              <Icon className="ficon-alert-triangle error-icon text-orange" />
              Syncing Failed
            </div>
          ) : job_status === AsyncJobStatusMap.COMPLETED ? (
            <div className="status-icon-append">
              <Icon className="pv-icon-mark-read upload-success" />
              Finished syncing your folders
            </div>
          ) : (
            <div className="status-icon-append">
              <Icon className="spinner" />
              Syncing your folders
            </div>
          )}
        </div>
      )}
    </Offcanvas.Body>
  </Offcanvas>;
}

export default React.memo(SyncNotificationTrayComponent);
