// Bundling TinyMCE with the React application using a module loader (Webpack). https://www.tiny.cloud/docs/integrations/react/

import { Editor } from "@tinymce/tinymce-react";

// Import TinyMCE so the global var exists. 
// Otherwise we'll get an error on the console saying TinyMCE needs to be on the global scope, and TinyMCE-React won't load.
import "tinymce/tinymce";

// Toolbar icons
import "tinymce/icons/default";

// Editor styles
import "tinymce/themes/silver";
import "tinymce/skins/ui/oxide/skin.min.css";

// Any Plugins you want to use has to be imported
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/table";
import "tinymce/plugins/lists";
import "tinymce/models/dom/model";
// These content css are not being loaded properly - added to iframe-styles.css
// import "tinymce/skins/ui/oxide/content.min.css";
// import "tinymce/skins/content/default/content.min.css";

export default Editor;
