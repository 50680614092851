import React from "react";
import Pagination from "react-bootstrap/Pagination";
import { Icon } from "src/components";

function PaginationComponent() {
  // Description: Pagination
  const PaginationBar = () => {
    return (
      <>
        <h4>PAGINATION</h4>
        <Pagination>
          <Pagination.First disabled className="bordered" >
            <Icon className="ficon-chevron-left" />
          </Pagination.First>
          <Pagination.Prev disabled className="bordered">
            <Icon className="ficon-chevrons-left" />
          </Pagination.Prev>
          <Pagination.Item active>{1}</Pagination.Item>
          <Pagination.Item>{2}</Pagination.Item>
          <Pagination.Item>{3}</Pagination.Item>
          <Pagination.Next className="bordered">
            <Icon className="ficon-chevron-right" />
          </Pagination.Next>
          <Pagination.Last className="bordered">
            <Icon className="ficon-chevrons-right" />
          </Pagination.Last>
        </Pagination>
      </>
    );
  };

  return <PaginationBar />;
};

export default React.memo(PaginationComponent);
