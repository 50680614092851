import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { MailClient, ProtectAccount, Express } from ".";
import { Account, AppConfiguration, WelcomeModalHeader, WelcomeModalStates, WelcomeModalType, useAppSelector, useAppDispatch } from "src/common";
import { uiActions } from "src/store";

function AddToMailClient() {
  const is_express = AppConfiguration.buildForExpress();
  const organization = useAppSelector((state) => state.account.organization);
  const [modalState, setModalState] = useState<WelcomeModalType>(Account.isShowMUAPropt(organization) ? WelcomeModalStates.page_one : WelcomeModalStates.page_two);
  const dispatch = useAppDispatch();

  // Description: Handle states of modal. If is a close, dismiss the message too
  const handleShowModal = (modal: WelcomeModalType) => {
    modal === WelcomeModalStates.close &&
      dispatch(uiActions.handleMessageDismiss());
    setModalState(modal);
  };

  // Description: Return modal content depending on Buildmode and page number
  function ModalContent() {
    return is_express ?
      <Express handleShowModal={handleShowModal} /> :
      modalState === WelcomeModalStates.page_one ?
        <MailClient handleShowModal={handleShowModal} /> :
        <ProtectAccount handleShowModal={handleShowModal} />;
  }

  // Show Page one only for ORG users
  return <Modal
    size="lg"
    dialogClassName="welcome-modal"
    show={modalState !== WelcomeModalStates.close}
    onHide={() => handleShowModal(WelcomeModalStates.close)}
    aria-labelledby="welcome modal">
    <Modal.Header>
      <Modal.Title>
        {
          is_express ? WelcomeModalHeader.express :
            modalState === WelcomeModalStates.page_one ? WelcomeModalHeader.page_one : WelcomeModalHeader.page_two
        }
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <ModalContent />
    </Modal.Body>
  </Modal>;
}

export default React.memo(AddToMailClient);
