import React, { useRef, useEffect, useState, FocusEvent, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, InputGroup, Button } from "react-bootstrap";
import { ActionHandlerFunction, SearchResultItem, MailSearchMatch, UserProfile, Thread, ThreadsPage } from "@preveil-api";
import {
  useAppSelector, useAppDispatch, useSearchMutation, SearchFilters, SearchApiResponse, MailResultItem, Contacts, MailErrorMessages, MailUIActionTypes,
  MessageAnchors, QueryParamKeys, MessageHandlerDisplayType, Message, PrivateRoutes, SearchResultFilter, isSearchMailbox, DefaultMailboxes, MailThread,
  SearchMatchesType
} from "src/common";
import { uiActions, mailActions } from "src/store";
import { Icon } from "src/components";
import SearchAutocomplete from "./SearchAutocomplete.component";
import _ from "lodash";

type AllProps = {
  searchMode: boolean;
  handleSearchModeAction: (search_mode: boolean) => void;
  handleAction: ActionHandlerFunction;
}

function SearchFormComponent(props: AllProps) {
  const { searchMode, handleSearchModeAction, handleAction } = props;
  const { mailbox } = useParams();
  const searchRef = useRef<HTMLDivElement>(null);
  const user_id = useAppSelector((state) => state.account.current_account?.user_id);
  const search_term = useAppSelector((state) => state.mail.search_term);
  const [mailResults, setMailResults] = useState<MailSearchMatch[]>();
  const [userResults, setUserResults] = useState<UserProfile[]>();
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [searchMail, { isLoading }] = useSearchMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Description: Update search form state when query is coming from page load QS string
  useEffect(() => {
    (!!search_term && !searchTerm) && setSearchTerm(search_term);
  }, [search_term]);

  useEffect(() => {
    (isSearchMailbox(mailbox || "") && !!searchTerm && !!user_id) &&
      onSearchHandler(searchTerm, user_id, true);
  }, [mailbox]);

  // Description: Callback with debounce to handle user input in textbox
  const onSearchHandler = useCallback(
    _.debounce((_searchTerm: string, userId: string, is_search: boolean) => {
      // NOTE: Handle contacts search
      if (!!user_id) {
        const user_matches = new Contacts(user_id).search(_searchTerm);
        setUserResults(user_matches);
      };

      // NOTE: Handle Mail search
      searchMail({
        userId,
        query: [{
          type: SearchFilters.FullTextSearchFilter,
          value: _searchTerm
        }]
      }).unwrap()
        .then((response: SearchApiResponse) => {
          const filter_matches: SearchMatchesType = { to: [], from: [], message: [] };
          const mail_matches = _.map(response, (mail_match: SearchResultItem) => {
            const result_item = new MailResultItem(mail_match, _searchTerm);
            // NOTE: Update threads and filters if this is search result view only
            if (is_search) {
              const thread_id = mail_match.thread.thread_id;
              const filter_types = result_item.filter_types;
              (filter_types.includes(SearchResultFilter.to) && !filter_matches.to.includes(thread_id)) && filter_matches.to.push(thread_id);
              (filter_types.includes(SearchResultFilter.from) && !filter_matches.from.includes(thread_id)) && filter_matches.from.push(thread_id);
              (filter_types.includes(SearchResultFilter.message) && !filter_matches.message.includes(thread_id)) && filter_matches.message.push(thread_id);
            }
            return result_item.best_match;
          });
          is_search && setThreadsSuccess(response, filter_matches);
          !!mail_matches && setMailResults(mail_matches);
          dispatch(mailActions.setSearchTerm(_searchTerm));
        })
        .catch((error: unknown) => {
          const msg = new Message(MailErrorMessages.error_searching.replace(MessageAnchors.search_term, _searchTerm), MessageHandlerDisplayType.toastr);
          dispatch(uiActions.handleRequestErrors(msg, error));
        });
    }, 300), []);

  useEffect(() => {
    (!!searchTerm && searchTerm.length > 0 && !!user_id) &&
      onSearchHandler(searchTerm, user_id, isSearchMailbox(mailbox || ""));
  }, [searchTerm]);

  // Description: Handle the Hide and show of the mobile search box
  useEffect(() => {
    function handleClickOutside(event: any) {
      (searchRef.current && !searchRef.current.contains(event.target)) && handleSearchModeAction(false);
    }
    // Description: Bind the outside event listener for closing the search
    document.addEventListener("mousedown", handleClickOutside);
    return () => { // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchRef]);

  // Description: Load results into store for list display
  // NOTE: Only applies for Search Result View so also add filters data
  function setThreadsSuccess(response: SearchApiResponse, matches?: SearchMatchesType) {
    const threads = _.map(response, (mail_match: SearchResultItem) => {
      return new MailThread(mail_match.thread).thread;
    });
    handleSetServiceData(threads, matches);
  }

  // Description: Handles set threads and reset, sets pagination
  function handleSetServiceData(threads: Thread[], matches?: SearchMatchesType) {
    const thread_page: ThreadsPage = {
      mailbox_id: DefaultMailboxes.search,
      threads: !!threads ? threads : [],
      total: threads.length
    };
    dispatch(mailActions.setThreadsSuccess(thread_page));
    dispatch(mailActions.setSearchMatches(matches));
    handleAction({ actionType: MailUIActionTypes.SetThreadsSuccess, params: thread_page });
  }

  // Description: Submit Search Mail
  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    e.stopPropagation();
    handleSearchModeAction(false);
    const qs = !!searchTerm ? `?${QueryParamKeys.SEARCH_RESULT_QUERY_KEY}=${encodeURIComponent(searchTerm)}` : "";
    navigate(`/${PrivateRoutes.mail_search_route}${qs}`);
  };

  // Description: Submit Search Mail  
  function handleReset() {
    setSearchTerm(null);
    handleSearchModeAction(false);
    isSearchMailbox(mailbox || "") && handleSetServiceData([]);
    dispatch(mailActions.setSearchTerm());
    dispatch(mailActions.setSearchFilter(SearchResultFilter.all));
  }

  // Description: Set the current state for controlled form and propagate to store state
  function handleChange(e: FocusEvent<HTMLInputElement>) {
    setSearchTerm(e.currentTarget.value);
    !searchMode && handleSearchModeAction(true);
  }

  // Description: handle autocomplete set match
  function handleAutoCompleteAction(search_mode: boolean, _search_term?: string) {
    !!_search_term && setSearchTerm(_search_term);
    handleSearchModeAction(search_mode);
  }

  return <div className="search-box">
    <div className="search-form" ref={searchRef} >
      <Form noValidate onSubmit={handleSubmit}>
        <Form.Label visuallyHidden>Search Mail</Form.Label>
        <InputGroup>
          <Form.Control
            name="search_mail"
            autoComplete="off"
            placeholder="Search Mail"
            aria-label="Search Mail"
            value={searchTerm || ""}
            onChange={handleChange}
            onFocus={(e: FocusEvent<HTMLInputElement>) => { !searchMode && handleSearchModeAction(true); }} />
          <Button variant="icon" type="submit"><Icon className="ficon-search" /></Button>
          {
            !!searchTerm &&
            <Button variant="icon" className="text-muted-light"
              onClick={() => handleReset()}><Icon className="ficon-x-circle" /></Button>
          }
        </InputGroup>
      </Form>{
        (!!searchTerm && !!user_id && searchMode) &&
        <SearchAutocomplete
          handleAction={handleAutoCompleteAction}
          searchTerm={searchTerm}
          mailResults={mailResults}
          userResults={userResults}
          isLoading={isLoading}
        />
      }
    </div>
    {/* Mobile Version */}
    <Button variant="icon"
      onClick={() => handleSearchModeAction(true)}
      className="toggle-search">
      <Icon className="ficon-search" />
    </Button>
  </div>;
}

export default React.memo(SearchFormComponent);
