import * as React from "react";
import { Card, Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { AccountUIActionTypes, WebsocketErrors } from "src/common";

type AllProps = {
  error?: string;
  handleAction: ActionHandlerFunction;
}

function ErrorPanelComponent(props: AllProps) {
  const { error, handleAction } = props;

  return <Card.Body>
    <h3 className="content-header text-orange fw-normal">Approval Failed</h3>
    <h4>{error || WebsocketErrors.default}</h4>
    <Button className="mt-3" variant="primary" onClick={() => handleAction({ actionType: AccountUIActionTypes.Destroy })}>Retry</Button>
  </Card.Body>;
}
export default React.memo(ErrorPanelComponent);