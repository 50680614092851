import { useEffect, useRef } from "react";
import { MessageDisplayType, ErrorStackDataType } from "@preveil-api";
import { randomBytes } from "pvcryptojs";
import { FSMessage, FSStatus } from "src/common/keys/protos/collections_pb";
import {
  Account, useAppSelector, useAppDispatch, useGetPermissions, CollectionFilesyncAPI, Message, getEnumKey, UUID, DefaultCollectionName,
  MessageHandlerDisplayType, DriveErrorMessages, useSendRequestMutation, DriveRequest, PermissionSetType, decryptRootName, AppConfiguration,
  Collection, DRIVE_MANAGER_STATE, ErrorStackItem
} from "src/common";
import { RootState } from "src/store/configureStore";
import { uiActions, driveActions, DriveCallbackAsyncFunction } from "src/store";

export function useDefaultCollectionInfo(current_account: Account, default_permissions: PermissionSetType[], websocket_ready = false) {
  const drive_state = useAppSelector((state: RootState) => state.drive.drive_state);
  const { permissions, getPermissions, getDefaultPermissions } = useGetPermissions(current_account);
  const [sendRequest] = useSendRequestMutation();
  const dispatch = useAppDispatch();
  const default_collection_id_ref = useRef<string | undefined>();
  function setDefaultCollectionId(default_collection_id: string) {
    default_collection_id_ref.current = default_collection_id;
  }

  // Description: Handle Manager States
  useEffect(() => {
    if (websocket_ready) {
      drive_state === DRIVE_MANAGER_STATE.NEW_EXPRESS_ACCOUNT ? initializeDefaultCollection() :
        (drive_state === DRIVE_MANAGER_STATE.NOT_INITIALIZED && getDefaultCollectionId());
    }
  }, [drive_state, websocket_ready]);

  useEffect(() => {
    (drive_state === DRIVE_MANAGER_STATE.DEFAULT_COLLECTION_ID_SUCCESS && !!default_collection_id_ref.current && default_permissions.length > 0) &&
      getPermissions(default_collection_id_ref.current, default_permissions);
  }, [default_collection_id_ref.current, default_permissions]);

  useEffect(() => {
    (!!permissions && !!default_collection_id_ref.current && permissions.collection_id.B64() === default_collection_id_ref.current)
      && getRootInfo(default_collection_id_ref.current, permissions);
  }, [permissions]);

  // Description: Step 1 - Fetch the Collection Id and get the root information
  async function getDefaultCollectionId() {
    getDefaultPermissions();
    const drive_request = await CollectionFilesyncAPI.getDefaultCollectionId(current_account);
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        const default_collection_id = message.hasDefaultCollectionId() ? message.getDefaultCollectionId_asB64() : null;
        if (!!default_collection_id) {
          setDefaultCollectionId(default_collection_id);
          dispatch(driveActions.setDriveState(DRIVE_MANAGER_STATE.DEFAULT_COLLECTION_ID_SUCCESS));
        } else if (!message.hasDefaultCollectionId() && AppConfiguration.buildForExpress()) {
          // NOTE: Initializing default collection step in Create Express Account failed - try again
          dispatch(driveActions.setDriveState(DRIVE_MANAGER_STATE.NEW_EXPRESS_ACCOUNT));
        } else {
          handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.no_default_collection_id }, message,
            MessageHandlerDisplayType.toastr);
        }
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.no_default_collection_id }, message,
          MessageHandlerDisplayType.toastr);
      }
    }
    handleSendRequest(drive_request, callback, DriveErrorMessages.no_default_collection_id);
  }

  // Description: Step 2 - Fetch Root Info for default Collection
  async function getRootInfo(collection_id: string, _permissions: PermissionSetType) {
    const request = await CollectionFilesyncAPI.getRootInfoRequest(collection_id);
    const drive_request = !!permissions ? await DriveRequest.initializeRequest(request, current_account, _permissions) : null;
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        const message_root_info = message.getRootInfo();
        if (!!message_root_info) {
          const wrapped_dir_key = message_root_info?.getWrappedDirKey() || null;
          const root_info = {
            collection_id,
            id: message_root_info.getId_asB64(),
            localSyncStatus: 0,
            name: await decryptRootName(message_root_info.getName_asU8(), _permissions, current_account),
            version: message_root_info.getVersion_asB64(),
            wrapped_dir_key,
          };
          dispatch(driveActions.setRootInfoSuccess(root_info));
          dispatch(driveActions.setDriveState(DRIVE_MANAGER_STATE.READY));
        }
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.no_default_collection_id }, message,
          MessageHandlerDisplayType.toastr);
      }
    }

    handleSendRequest(drive_request, callback, DriveErrorMessages.no_default_collection_id);
  }

  // Description: Step 3 - Initializing default collection step in Create Express Account
  async function initializeDefaultCollection() {
    const collection_id_uuid = new UUID();
    const id = new UUID().Bytes();
    const default_name = DefaultCollectionName;
    const version = randomBytes(32);
    const collection_data = await Collection.getNewV1CollectionData(current_account, collection_id_uuid, default_name);
    const collection_id_bytes = collection_id_uuid.Bytes();
    const drive_request = !!collection_data ? await CollectionFilesyncAPI.initCollection(current_account,
      collection_id_bytes,
      id,
      collection_data.encrypted_name,
      version,
      collection_data.wrapped_key,
      collection_data.key_data.keys) : null;

    async function callback(message: FSMessage) {
      (message.getStatus() === FSStatus.OK && !!collection_data) ?
        setDefaultCollection(collection_id_uuid, collection_data.key_data.new_permissions.permision_set) :
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_initializing_collection }, message,
          MessageHandlerDisplayType.toastr);
    }

    handleSendRequest(drive_request, callback, DriveErrorMessages.error_initializing_collection);
  }

  // Description: Step 4 - On successfully initializing a new collection set the new Default Collection ID
  async function setDefaultCollection(collection_id_uuid: UUID, _permissions: PermissionSetType) {
    const collection_id_bytes = collection_id_uuid.Bytes();
    const drive_request = await CollectionFilesyncAPI.setDefaultCollectionId(current_account, _permissions, collection_id_bytes);
    const _default_collection_id = collection_id_uuid.B64();
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        setDefaultCollectionId(_default_collection_id);
        getPermissions(_default_collection_id, [_permissions]);
        dispatch(driveActions.setDefaultPermissions([_permissions]));
        dispatch(driveActions.setDriveState(DRIVE_MANAGER_STATE.DEFAULT_COLLECTION_INITIALIZED));
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_initializing_collection }, message,
          MessageHandlerDisplayType.toastr);
      }
    }

    handleSendRequest(drive_request, callback, DriveErrorMessages.error_initializing_collection);
  }

  // -----------------------------
  // HOOK General
  // -----------------------------
  // Description: Sends Request and handles error when the request object is null, pass it custom stack message
  function handleSendRequest<T>(request: DriveRequest | null, callback: DriveCallbackAsyncFunction<T>, stack_error: string = DriveErrorMessages.error_sending_request) {
    if (!!request) {
      request.setCallback(callback);
      sendRequest(request);
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, { stack_error, stack_message: DriveErrorMessages.error_sending_request });
    }
  }
  // Description:  send error to store
  function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, fsmessage?: FSMessage | null, display_type: MessageDisplayType = MessageHandlerDisplayType.logger) {
    const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
    const stack = new ErrorStackItem("[useDefaultCollectionInfo Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
    dispatch(uiActions.handleRequestErrors(new Message(message, display_type), stack));
    dispatch(driveActions.setDriveState(DRIVE_MANAGER_STATE.ERROR));
  }
}

