import React, { ReactElement } from "react";
import { Modal } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { DriveUIActionTypes } from "src/common";

interface AllProps {
    active: boolean;
    children: ReactElement;
    handleAction: ActionHandlerFunction;
}

function SearchWrapperComponent(props: AllProps) {
    const { children, active, handleAction } = props;

    return active ?
        <Modal
            className="search-modal"
            show={true}
            size="lg"
            onHide={() => handleAction({ actionType: DriveUIActionTypes.Reset })}
            animation={false}
            aria-labelledby="Search My PreVeil"  >
            {children}
        </Modal> :
        <>
            {children}
        </>;
}

export default React.memo(SearchWrapperComponent);
