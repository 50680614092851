import * as React from "react";
import { Button, Row, Col } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { AccountUIActionTypes } from "src/common";
import { Icon } from "src/components";
type AllProps = {
  handleAction: ActionHandlerFunction;
}

function AwaitConnectionComponent(props: AllProps) {
  const { handleAction } = props;

  return <>
    <h4><span className="waiting"></span>Awaiting Connection to existing device&hellip;</h4>
    <Row className="description-card border-bottom pb-2">
      <Col className="image" xs={2}>
        <Icon className="pv-icon-mobile" />
      </Col>
      <Col>
        <ul className="content">
          <p className="fs-3 mb-2">
            On an iOS or Android mobile device with your PreVeil account:
          </p>
          <ul className="list-unstyled">
            <li>
              Launch the <b>PreVeil</b> app
            </li>
            <li>Tap the <b>Settings</b> icon (<i>gear symbol at the top right corner</i>)</li>
            <li>Tap <b>Add Device</b></li>
          </ul>
        </ul>
      </Col>
    </Row>
    <Row className="description-card mt-4">
      <Col className="image" xs={2}>
        <Icon className="pv-icon-laptop" />
      </Col>
      <Col className="p-0">
        <ul className="content">
          <p className="fs-3 mb-2">On a computer with your PreVeil account:</p>
          <ul className="list-unstyled">
            <li>Open the PreVeil Web App (<b>https://www.preveil.com/app</b>)</li>
            <li>Go to <b>Settings</b></li>
            <li>Click <b>Add Device</b></li>
          </ul>
        </ul>
      </Col>
    </Row>
    <div className="btn-panel mt-4">
      <Button variant="outline-primary" onClick={() => handleAction({ actionType: AccountUIActionTypes.Destroy })}>Cancel</Button>
    </div>
  </>;
}
export default React.memo(AwaitConnectionComponent);
