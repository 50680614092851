import React from "react";
import { Button, Card } from "react-bootstrap";
import restoreAccount from "src/assets/images/restore-account.svg";
import { PrivateRoutes, WelcomeModalStates, WelcomeModalType } from "src/common";

type AllProps = {
  handleShowModal: (modal: WelcomeModalType) => void;
};

function ProtectAccount(props: AllProps) {
  const { handleShowModal } = props;
  return (
    <>
      <Card.Img variant="top" src={restoreAccount} className="mb-4 welcome-image" />
      <p>
        <b>Did you know that PreVeil never stores a copy of private encryption keys?</b>
      </p>
      <p>Only you have access to your encrypted files and messages, PreVeil can never see them.</p>
      <p>
        If your device is lost or damaged, your PreVeil account cannot be recovered by anyone;
        unless you have created a recovery file, or have been assigned a recovery group by your
        admin.
      </p>
      <p>
        Please take a minute to create your PreVeil QR Code recovery file and print it or save it in
        a safe place (not on this computer).
      </p>
      <div className="welcome-buttons">
        <Button href={`/${PrivateRoutes.settings_route}/${PrivateRoutes.account_backup_route}`}>
          Create Recovery File
        </Button>
        <Button
          variant="no-outline-primary"
          className="float-end bah"
          onClick={() => handleShowModal(WelcomeModalStates.close)}
        >
          Skip for now
        </Button>
      </div>
    </>
  );
}

export default React.memo(ProtectAccount);
