import * as React from "react";
import { Icon } from "src/components";

type AllProps = {
  children: React.ReactNode;
  iconClassName?: string;
}

function InlineListErrorComponent(props: AllProps) {
  const { children, iconClassName } = props;
  return <div className="list-messages">
    <div className="center-vertical">
      <Icon className={!!iconClassName ? iconClassName : "ficon-alert-triangle"} />
      {
        children
      }
    </div>
  </div>;
}

export default React.memo(InlineListErrorComponent);
