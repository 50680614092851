
import * as React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { PanelState, useAppDispatch, useAppSelector } from "src/common";
import { uiActions } from "src/store";

function RightSidebarComponent() {
  const state = useAppSelector((state) => state.ui.right_sidebar_state);
  const dispatch = useAppDispatch();

  return <Offcanvas
    className="sidebar"
    backdrop={false}
    show={state === PanelState.on}
    onHide={() => { dispatch(uiActions.handleToggleRightSidebar(PanelState.off)); }} placement="end">
    <Offcanvas.Header closeButton className="border-bottom">
      <Offcanvas.Title>Drive Functionality here</Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body>
      Some text as placeholder. In real life you can have the elements you
      have chosen. Like, text, images, lists, etc.
    </Offcanvas.Body>
  </Offcanvas>;
}

export default React.memo(RightSidebarComponent);
