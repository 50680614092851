import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { UserProfile } from "@preveil-api";
import { RecoveryGroup } from "src/common";
import { Icon } from "src/components";

type AllProps = {
  editing: boolean;
  editing_users: UserProfile[];
  current_recovery_group: RecoveryGroup | undefined;
  removeUser: Function;
};

function RecoveryGroupFormComponent(props: AllProps) {
  const { editing, editing_users, current_recovery_group, removeUser } = props;
  const users = editing ? editing_users : !!current_recovery_group ? current_recovery_group.optional_users : [];

  function removeButton(user: UserProfile) {
    return (
      <Button
        type="submit"
        variant="icon"
        className="text-muted-light float-end"
        onClick={() => removeUser(user)}
      >
        <Icon className="ficon-x" />
      </Button>
    );
  }

  return (
    <>
      <Row className="table-header">
        <Col sm={4}>Name</Col>
        <Col sm={6}>Email</Col>
      </Row>
      {users.map((user: UserProfile, i: number) => (
        <Row className="approver-row" key={i}>
          <div className="d-block d-sm-none border-0">
            <Col xs={12} sm={4}>Name {editing && removeButton(user)}</Col>
            <Col className="fw-bold">{user.name}</Col>
            <Col xs={12} sm={4}>Email</Col>
            <Col className="fw-bold">{user.address}</Col>
          </div>
          <div className="d-none d-sm-flex align-items-center">
            <Col sm={4}>{user.name}</Col>
            <Col>{user.address}</Col>
            {editing && <Col>{removeButton(user)}</Col>}
          </div>
        </Row>
      ))}
    </>
  );
}

export default React.memo(RecoveryGroupFormComponent);
