import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import { RekeyAndChangeRecoveryRequest } from "@preveil-api";
import {
  Account,
  GetApproversApiResponse,
  GetUserRequestsApiResponse,
  Message,
  MessageHandlerDisplayType,
  RecoveryGroup,
  useAppDispatch,
  useAppSelector,
  useGetApproversMutation,
  useGetUserRequestsMutation,
  usePostUsersFindMutation,
  UserRequest,
  Pagination,
  GlobalConstants,
  PrivateRoutes
} from "src/common";
import { Loading, PageHeader } from "src/components";
import { settingsActions, uiActions } from "src/store";
import { RecoveryGroupForm, UserRequests, Notifications } from ".";
import _ from "lodash";

function RecoveryComponent() {
  const dispatch = useAppDispatch();
  const [current_recovery_group, setCurrentRecoveryGroup] = useState<RecoveryGroup | undefined>(undefined);
  const navigate = useNavigate();
  const [pending_requests, setPendingRequests] = useState<UserRequest[]>([]);
  const [history_requests, setHistoryRequests] = useState<UserRequest[]>([]);
  const [submitting_form, setSubmittingForm] = useState<boolean>(false);
  const [disable_edits, setDisableEdits] = useState<boolean>(false);
  const is_org = useAppSelector((state) => state.account.current_account?.org_info);
  const [optionals_required, setOptionalsRequired] = useState<number>(1);
  const [getApprovers] = useGetApproversMutation();
  const [rg_loading, setRGLoading] = useState<boolean>(true);
  const current_account = useAppSelector((state) => state.account.current_account);
  const [is_loading, setIsLoading] = useState<boolean>(true);
  const [findUsers] = usePostUsersFindMutation();
  const [getRequests] = useGetUserRequestsMutation();
  const [page, setPage] = useState<number>(0);

  /* On initial Load: Get Recovery Group and get pending user requests for the warning toast */
  useEffect(() => {
    getRecoveryGroup();
    getUserRequests(false, "pending", true);
  }, []);

  /* Description: when current_recovery_group is populated/changed - set whether the user can edit it or not
  depending on the number of optional users and whether the user is in an organization. (Users in organizations)
  cannot edit their recovery group. */
  useEffect(() => {
    if (is_org) {
      setDisableEdits(true);
    }
  }, [current_recovery_group]);

  /* Description: Gets the user requests, creates a new UserRequest object for each request and populates the certain properties of the
  user request object. */
  async function getUserRequests(
    history: boolean,
    status: string,
    hideExpired: boolean,
    limit?: number,
    offset?: number,
  ) {
    if (!!current_account) {
      await getRequests({ userId: current_account.user_id, status, hideExpired, limit, offset })
        .unwrap()
        .then(async (response: GetUserRequestsApiResponse) => {
          const user_requests = _.uniqBy(
            response.requests.map((request) => {
              const new_request = new UserRequest(
                request.request_id,
                request.metadata,
                request.payload,
                request.status,
                request.requester,
              );
              const l_request = pending_requests.find(
                (local_request) => local_request.request_id === request.request_id,
              );
              if (!!l_request) {
                new_request.request_responses = l_request.request_responses;
              }
              return new_request;
            }),
            (req) => req.request_id,
          );
          let user_ids = new Array<string>();
          user_requests.forEach((request) => {
            const parsed = request.parsed_payload.data as RekeyAndChangeRecoveryRequest;
            user_ids = user_ids.concat(request.requester_id);
            if (!!parsed.group) {
              user_ids = user_ids.concat(
                parsed.group.map((approver: { user_id: string }) => approver.user_id),
              );
            }
          });
          user_ids = _.uniqBy(user_ids, (id) => id);
          const requests = await getUsers(user_ids, user_requests);
          if (!!requests) {
            if (history) {
              setHistoryRequests(requests);
            }
            const totalPageRows = requests.length;
            const totalRows = response.total_rows || requests.length;
            if (totalPageRows > 0 && totalRows > 0) {
              const paginationItem = new Pagination(
                page,
                totalPageRows,
                totalRows,
                GlobalConstants.REQUESTS_PER_PAGE_COUNT,
              ).pagination_item;
              !!paginationItem && dispatch(settingsActions.setPagination(paginationItem));
            } else {
              dispatch(settingsActions.resetPagination());
              setPage(0);
            }
            !history && setPendingRequests(requests);
          }
          setIsLoading(false);
        })
        .catch((stack) => {
          dispatch(
            uiActions.handleRequestErrors(new Message(stack, MessageHandlerDisplayType.logger)),
          );
        });
    }
  }

  /* Description: Gets the Recovery Group from the backend and creates a new 
  Recovery group object and sets that in the store. */
  async function getRecoveryGroup() {
    if (!!current_account) {
      await getApprovers({ user_id: current_account.user_id })
        .unwrap()
        .then((params: GetApproversApiResponse) => {
          const recovery_group = new RecoveryGroup(
            params.user_id,
            params.required_users.map((user) => {
              return { name: user.display_name || user.user_id, address: user.user_id };
            }),
            params.optional_users.map((user) => {
              return { name: user.display_name || user.user_id, address: user.user_id };
            }),
            params.optionals_required,
          );
          setCurrentRecoveryGroup(recovery_group);
          setRGLoading(false);
        })
        .catch((stack) => {
          dispatch(
            uiActions.handleRequestErrors(new Message(stack, MessageHandlerDisplayType.logger)),
          );
          setRGLoading(false);
        });
    }
  }

  /* Description: Returns an array of UserRequest Objects. It gets user objects from the backend based on the requester_ids
  and sets UserProfile arrays for approvers and requesters. */
  async function getUsers(
    user_ids: string[],
    user_requests: UserRequest[],
  ): Promise<UserRequest[] | undefined> {
    if (!!current_account) {
      return await findUsers({
        account_ids: Account.getAccountIdentifiers(current_account),
        body: { spec: user_ids.map((id) => ({ user_id: id, key_version: -1 })) },
      })
        .unwrap()
        .then(({ users }) => {
          const updated_user_requests = user_requests.map((request) => {
            const u = users.find((u) => u.user_id === request.requester_id.toLowerCase());
            request.requester = u
              ? { name: u.display_name, address: u.user_id, external_email: u.external_email }
              : null;
            const parsed = request.parsed_payload.data as RekeyAndChangeRecoveryRequest;
            if (!!parsed.group) {
              request.approvers = parsed.group.map((approver) => {
                const u = users.find((u) => u.user_id === approver.user_id.toLowerCase());
                return { name: u?.display_name || null, address: u?.user_id || "" };
              });
            }
            return request;
          });
          return updated_user_requests;
        })
        .catch((msg: string) => {
          dispatch(
            uiActions.handleRequestErrors(new Message(msg, MessageHandlerDisplayType.logger)),
          );
          const updated_response = user_requests.map((request) => {
            if (!!request.parsed_payload.approvers) {
              request.approvers = request.parsed_payload.approvers.map((approver) => ({
                name: null,
                address: approver.user_id,
              }));
            }
            return request;
          });
          return updated_response;
        });
    }
    return undefined;
  }

  /* Description: Navigate to the recovery requests page on settings - this is done when
  the user clicks on the "number of updates" button on the toast. */
  function goToPendingRequests() {
    navigate(`/settings/${PrivateRoutes.recovery_requests_route}`);
  }

  return (
    <>
      {window.location.pathname.match("requests") ? (
        <UserRequests
          getUserRequests={getUserRequests}
          is_loading={is_loading}
          setIsLoading={setIsLoading}
          pending_requests={pending_requests}
          setPendingRequests={setPendingRequests}
          history_requests={history_requests}
          setHistoryRequests={setHistoryRequests}
          current_recovery_group={current_recovery_group}
          page={page}
          setPage={setPage}
        ></UserRequests>
      ) : (
        <>
          <PageHeader>
            <h1>Recovery Group</h1>
          </PageHeader>
          {!!submitting_form || !!rg_loading ? (
            <Loading></Loading>
          ) : (
            <Card className="card-section">
              <Notifications
                current_recovery_group={current_recovery_group}
                pending_requests={pending_requests}
                disable_edits={disable_edits}
                goToPendingRequests={goToPendingRequests}
              ></Notifications>
              <RecoveryGroupForm
                disable_edits={disable_edits}
                setSubmittingForm={setSubmittingForm}
                setOptionalsRequired={setOptionalsRequired}
                optionals_required={optionals_required}
                submitting_form={submitting_form}
                current_recovery_group={current_recovery_group}
                setCurrentRecoveryGroup={setCurrentRecoveryGroup}
                pending_requests={pending_requests}
                setPendingRequests={setPendingRequests}
              ></RecoveryGroupForm>
            </Card>
          )}
        </>
      )}
    </>
  );
}

export default React.memo(RecoveryComponent);
