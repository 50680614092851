import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, CardGroup, Card } from "react-bootstrap";
import appleImg from "src/assets/images/apple-mail.png";
import gmailImg from "src/assets/images/screenshot-gmail.png";
import windowsImg from "src/assets/images/windows.png";
import {
  Account, Message, SettingsErrorMessages, SupportedPlatform, SupportRoutes, useAppDispatch, useAppSelector, useInstallMuaProfileMutation,
  DefaultRoutes,
  SettingsSuccessMessages,
  MessageAnchors
} from "src/common";
import { PageHeader } from "src/components";
import { uiActions } from "src/store";
import { RootState } from "src/store/configureStore";
import { ManualInstructions } from ".";


/* Description: Component for users to add to their mail client - either mac/windows or gmail */
function AddToMailClient() {
  const navigate = useNavigate();
  const [show, setShow] = useState<boolean>(false);
  const [installing, setInstalling] = useState<boolean>(false);
  const [installMuaProfile] = useInstallMuaProfileMutation();
  const user_id = useAppSelector((state: RootState) => state.account.current_account)?.user_id || "";
  const os_info = useAppSelector((state: RootState) => state.app.os_info);
  const organization = useAppSelector((state: RootState) => state.account.current_account)?.org_info;
  const is_os = os_info?.user_platform === SupportedPlatform.OSX;
  const default_MUA = os_info?.default_MUA;
  const img = !!default_MUA && default_MUA === "Apple Mail" ? appleImg : windowsImg;
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Description: If user belongs to an org and the show_mua_propt is set to false navigate out
    (!!organization && !Account.isShowMUAPropt(organization)) &&
      navigate(`${DefaultRoutes.mail_default}`);
  }, []);

  // Descriptions: Add Google
  function installGmailPlugin() {
    window.open(SupportRoutes.manually_add_google);
  };

  /* Descriptions: Installs the Mail Client Profile. There are some known issues while installing mac so there 
   is a specific error message for mac users to try to install it manually. */
  async function installDefaultMUAProfile() {
    if (installing) {
      return;
    }
    setInstalling(true);
    await installMuaProfile({ userId: user_id }).unwrap()
      .then(() => {
        setInstalling(false);
        !!default_MUA && dispatch(uiActions.handleSetMessage(new Message(SettingsSuccessMessages.installed_mail_client.replace(MessageAnchors.mail_client, default_MUA))));
      }).catch((msg) => {
        setInstalling(false);
        dispatch(uiActions.handleRequestErrors(new Message(
          is_os ? SettingsErrorMessages.failed_to_install_profile_mac : SettingsErrorMessages.failed_to_install_profile), msg));
      });
  };

  return <>
    <PageHeader>
      <h1>Automatically Add to Mail Client</h1>
    </PageHeader>
    <Card className="card-section">
      <CardGroup className="mail-client mb-5">
        <Card className="gmail">
          <Card.Header>Gmail Plugin</Card.Header>
          <Card.Img variant="top" src={gmailImg} className="gmail-image" />
          <Card.Body>
            <Card.Text>
              PreVeil makes it easy to access your encrypted emails in Gmail by automatically
              adding a secure inbox.
            </Card.Text>
          </Card.Body>
          <Button onClick={() => installGmailPlugin()} variant="primary">
            Install Gmail Plugin
          </Button>
        </Card>
        <Card>
          <Card.Header>{default_MUA}</Card.Header>
          <Card.Img variant="top" src={img} className="mua-image" />
          <Card.Body>
            <Card.Text>
              PreVeil makes it easy to access your encrypted emails in {default_MUA} by
              automatically adding a secure inbox.{" "}
              <b>Please close {default_MUA} if it is currently open.</b>
            </Card.Text>
            {!!is_os ? (
              <p className="text-muted sub-text">
                <span className="text-primary">*</span>
                One-click support for newest Mac versions is planned for a future release. If
                one-click fails, contact PreVeil support to manually add Apple Mail. You can
                also refer to
                <a
                  href={SupportRoutes.mannually_add_apple_mail}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  these instructions.
                </a>
              </p>
            ) : null}
          </Card.Body>
          {!installing ?
            <Button variant="primary" onClick={() => installDefaultMUAProfile()}>
              Add PreVeil to {default_MUA}
            </Button> : (
              <Button variant="primary" className="btnloading">
                Adding to {default_MUA}&hellip;
              </Button>)}
        </Card>
      </CardGroup>
      {!show ? (
        <Button variant="link" className="text-start" onClick={() => setShow(!show)}>
          Click here for instructions to manually add PreVeil to other email programs (Outlook on
          Mac, etc.)
        </Button>
      ) : null}
      <ManualInstructions show={show} />
    </Card>
  </>;
}

export default React.memo(AddToMailClient);
