import * as React from "react";
import { Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { AccountUIActionTypes } from "src/common";
type AllProps = {
  handleAction: ActionHandlerFunction;
}

function AwaitConnectionComponent(props: AllProps) {
  const { handleAction } = props;

  return <>
    <h3 className="content-header">
      <i className="waiting"></i>Awaiting Connection&hellip;
    </h3>
    <p className="fs-3">
      Contact your approver and ask them to:
    </p>
    <div className="semi-bold ms-4">
      <p>Access their PreVeil account on a computer</p>
      <p>Click on "Settings" then "Recovery Approval".</p>
      <p>Enter your PreVeil ID (your email address).</p>
      <p>Type in the code that will appear on this screen.</p>
    </div>
    <div className="btn-panel text-end mt-3">
      <Button variant="outline-primary" onClick={() => handleAction({ actionType: AccountUIActionTypes.Destroy })}>Cancel</Button>
    </div>
  </>;
}
export default React.memo(AwaitConnectionComponent);
