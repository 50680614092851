import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ActionHandlerFunction, ApproversInfo, AGUserInfo, ApproversList } from "@preveil-api";
import { Button } from "react-bootstrap";
import {
  RecoverAccountUIActionTypes, PublicRoutes, useGetApprovalGroupLocalInfoMutation,
  GetApprovalGroupLocalInfoApiResponse, AccountErrorMessages, isSameUser
} from "src/common";
import { Icon, Loading } from "src/components";
import _ from "lodash";

type AllProps = {
  user_info: AGUserInfo;
  approvers_list: ApproversList;
  handleAction: ActionHandlerFunction;
}

//  <div *ngIf="state === MISSING_PARTS_STATE || state === SUFFICIENT_PARTS_STATE">
function RecoveryPanel(props: AllProps) {
  const { handleAction, user_info, approvers_list } = props;
  const [approved_count, setApprovedCount] = useState<number>(0);
  const [approvers, setApprovers] = useState<ApproversInfo[]>(approvers_list.approvers);
  const [getApprovalGroupLocalInfo, { isLoading }] = useGetApprovalGroupLocalInfoMutation();

  // First onload event
  useEffect(() => {
    handleApprovalGroupInit();
  }, []);

  // Description: get the Local Info requrired group approver status
  async function handleApprovalGroupInit() {
    await getApprovalGroupLocalInfo(user_info).unwrap()
      .then((params: GetApprovalGroupLocalInfoApiResponse) => {
        const newapprovers = _.map(approvers.slice(),
          (approver: ApproversInfo) => {
            approver.approved = params[approver.user_id];
            return approver;
          });
        const count = newapprovers.filter(user => user.approved).length;
        setApprovedCount(count >= 0 ? count : 0);
        setApprovers(newapprovers);
      })
      .catch((msg) => {
        handleDataError(msg);
      });
  }

  // Description: Handle Error on the call
  function handleDataError(stack?: any) {
    handleAction({
      actionType: RecoverAccountUIActionTypes.PageError,
      params: { message: AccountErrorMessages.default, stack }
    });
  }

  // Description: Build approvers rows (optionals and required)
  function ApproverRows() {
    return _.map(approvers, (approver: ApproversInfo, i: number) => {
      return !isSameUser(approver.user_id, user_info.user_id) &&
        <li key={`approver_${i}`} className={approver.approved ? "text-muted" : ""}>
          <div>
            <h2>{approver.display_name} </h2>
            <p>{approver.user_id}</p>
            {
              approver.required && <span className="text-danger fs-5">*required</span>
            }
          </div>
          {
            approver.approved ?
              < Icon className="icon ficon-user-check text-success" /> :
              <Button variant="outline-secondary"
                onClick={() => handleAction({
                  actionType: RecoverAccountUIActionTypes.GetShard,
                  params: approver
                })} >
                Start
              </Button>}
        </li >;
    });
  }

  return isLoading ?
    <Loading className="in-place" /> :
    <>
      <p className="fs-4 border-bottom pb-3">You have received approval
        from <b>{approved_count}</b> of <b>{approvers_list.optionals_required}</b> required group members.</p>
      <ul className="recovery-options">
        {ApproverRows()}
      </ul>
      <div className="btn-panel text-end mt-3">
        <Button onClick={() => handleAction({ actionType: RecoverAccountUIActionTypes.ReconstructPrivateKey })}
          disabled={approved_count < approvers_list.optionals_required}>Recover Key</Button>
        <Link className="btn btn-outline-primary" to={`/${PublicRoutes.get_started_route}`}>Cancel</Link>
      </div>
    </>;
}

export default React.memo(RecoveryPanel);
