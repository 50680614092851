import * as React from "react";
import { Account, OrganizationLevelSettings, useGetUsersOrgsByEntityIdKeyvalAndKeyQuery } from "src/common";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Icon } from "src/components";

interface AllProps {
    current_account: Account;
    org_id: string;
}

function CUINoticeModalComponent(props: AllProps) {
    const { current_account, org_id } = props;
    const [showCUIModal, setShowCUIModal] = useState<boolean>();
    const { data } = useGetUsersOrgsByEntityIdKeyvalAndKeyQuery({
        account_ids: Account.getAccountIdentifiers(current_account),
        body: {
            entityId: org_id,
            key: OrganizationLevelSettings.present_cui_notice,
        }
    });
    useEffect(() => {
        !!data && setShowCUIModal(data.value);
    }, [data]);

    return <Modal
        size="lg"
        centered
        show={showCUIModal}
        onHide={() => { setShowCUIModal(false); }}
        aria-labelledby="confirmation modal" >
        <Modal.Header>
            <Modal.Title>Compliance Control Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Please be advised, this device is under <b>Controlled Unclassified Information (CUI) controls.</b></p>
            <p>The information system contains CUI with specific requirements imposed by the Department of Defense.</p>
            <p>Use of the information system may be subject to other specified requirements associated with certain types of CUI such as Export Controlled information.</p>
            <p>As a CUI device it is subject to the following system use requirements.</p>
            <p><Icon className="pv-icon-sm" />
                The company may monitor, record, and audit system usage information.</p>
            <p><Icon className="pv-icon-sm" />
                Unauthorized use of the information is prohibited, and may result in civil or criminal penalties.</p>
            <p className="d-flex">
                <Icon className="pv-icon-sm" />
                Use of this information system indicates consent to these requirements.
            </p>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={() => setShowCUIModal(false)} className="me-2">Dismiss</Button>
        </Modal.Footer>
    </Modal>;
}

export default React.memo(CUINoticeModalComponent);
