import { EventPayload, Event } from "@preveil-api";
import { Helpers } from "../helpers/helpers.class";
import { UUID } from "../helpers/uuid.class";
import { KeyFactory } from "../keys/factory";

export enum EventType {
  SET_APPROVAL_GROUP = "set_approval_group",
  REKEY_AND_SET_APPROVAL_GROUP = "rekey_and_set_approval_group",
  SUBMIT_SHARDS_TO_EXPORT_GROUP = "submit_shards_to_export_group"
}

// Description: Verifies the signature sent in event payload. Used to determine whether or not
// we should handle the given event.
export async function verifyEvent(event: Event, raw_public_key: string): Promise<boolean> {
  const public_key = await KeyFactory.deserializePublicUserKey(Helpers.b64Decode(raw_public_key));
  const signature = Helpers.b64Decode(event.signature);
  const data = Helpers.utf8Encode(event.payload);
  return await public_key.verify_key.verify(data, signature);
}

// Description: Takes the raw event list received from collection server and filters it based on
// whether or not it has already been handled. It also filters out rekey events if we are not
// currently in the proccess of logging the user in.
export function getPendingEventsSorted(events: Event[], is_login: boolean): Event[] {
  return events.filter(event => !event.handled)
    .sort((a, b) => a.rev_id - b.rev_id)
    .map(event => {
      try {
        event.parsed_payload = parseEventPayload(event.payload);
        return event;
      } catch {
        return event;
      }
    })
    .filter(event => {
      if (!is_login) return event.parsed_payload?.type !== EventType.REKEY_AND_SET_APPROVAL_GROUP;
      return true;
    });
}

// Description: Per the collection server documentation we are expecting UUIDs for the group_id
// and id parameters therefore we create UUID instances for them here.
function parseEventPayload(payload: string): EventPayload {
  const parsed_payload = JSON.parse(payload);
  if (!!parsed_payload.data.group_id) {
    parsed_payload.data.group_id = new UUID({ uuid: parsed_payload.data.group_id });
  }
  if (!!parsed_payload.data.id) {
    parsed_payload.data.id = new UUID({ uuid: parsed_payload.data.id });
  }
  return parsed_payload;
}
