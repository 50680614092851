/*
*   File:           base.model.ts
*   Description:    Model for base services
*   Author:         PreVeil, LLC
*/
import axios, { AxiosError, AxiosRequestConfig, Method } from "axios";
import { IFetchResponse } from "@preveil-api";
import { GlobalErrorMessages, Helpers, AccountIdentifiers } from "src/common";

// Description: generic get return data as Promise<IFetchResponse>
async function get(url: string, params?: unknown, header?: Record<string, string>): Promise<IFetchResponse> {
  const config = !!header ? _getAxiosRequestConfig(header, params) : { params };
  return await axios.get(url, config)
    .catch((errors: AxiosError) => {
      return _hadleAxiosError(errors);
    });
}

// Regular posts
async function post(url: string, data: any, header?: Record<string, string>): Promise<IFetchResponse> {
  const config = _getAxiosRequestConfig(!!header ? header : { "Content-Type": "application/json" });
  return await axios.post(url, data, config)
    .catch((errors: AxiosError) => {
      return _hadleAxiosError(errors);
    });
}

// Put actions
async function put(url: string, data: unknown = null, header?: Record<string, string>, _config?: AxiosRequestConfig): Promise<IFetchResponse> {
  let config = _getAxiosRequestConfig(!!header ? header : { "Content-Type": "application/json" });
  if (_config) {
    config = Object.assign(config, _config);
  }
  return await axios.put(url, data, config)
    .catch((errors: AxiosError) => {
      return _hadleAxiosError(errors);
    });
}

// Patch actions
async function patch(url: string, data: unknown = null, header?: Record<string, string>): Promise<IFetchResponse> {
  const config = _getAxiosRequestConfig(!!header ? header : { "Content-Type": "application/json" });
  return await axios.patch(url, data, config)
    .catch((errors: AxiosError) => {
      return _hadleAxiosError(errors);
    });
}

// Delete actions
async function axiosDelete(url: string, data: unknown = null, header?: Record<string, string>): Promise<IFetchResponse> {
  const headers = !!header ? header : { "Content-Type": "application/json" };
  const config = _getAxiosRequestConfig(headers, undefined, undefined, data);
  return await axios.delete(url, config)
    .catch((errors: AxiosError) => {
      return _hadleAxiosError(errors);
    });
}

// HANDLE GLOBAL ERRORS:
function _hadleAxiosError(errors: AxiosError) {
  // HANDLE GLOBAL ERRORS:
  // error_details?.stack use this for logging purposes
  return Object.assign(errors.response || {
    status: 0, // Bad Gateway server error
    statusText: errors.message || GlobalErrorMessages.server_error,
    headers: null,
    config: errors.config,
    data: { errors }, // AxiosResponse
  }, { isError: true });
}


// Description: Add more call Axios config 
function _getAxiosRequestConfig(headers: Record<string, string>, params?: any, method?: string, data?: any): AxiosRequestConfig {
  const config = !!method ? { headers, method } : { headers };
  return !!params || !!data ? Object.assign(config, { params }, { data }) : config;
}

// Description: Prepare collection server authentication headers for requests
// TYPE: account: Legacy LocalUser
async function prepareSignedRequestHeader(account_ids: AccountIdentifiers, resource: string, method: Method, body: object | null, sign_only_with_user_signature?: boolean):
  Promise<Record<string, string>> {
  const encoded_body = body === null ? "" : JSON.stringify(body);
  const canonical_request = `${resource};${method};${encoded_body}`;
  const device_signature = await account_ids.device_key.signing_key.sign(Helpers.utf8Encode(canonical_request));
  const user_signature = await account_ids.user_key.signing_key.sign(sign_only_with_user_signature ? Helpers.utf8Encode(canonical_request) : device_signature);
  return {
    "x-user-key-version": account_ids.user_key.key_version.toString(),
    "x-user-id": account_ids.user_id,
    "x-user-signature": Helpers.b64Encode(user_signature),
    "x-device-signature": Helpers.b64Encode(device_signature),
    "x-device-id": account_ids.device_id,
    "x-device-key-version": account_ids.device_key.key_version.toString()
  };
}

// Description: Prepares signature for collection server calls which require a signed payload
async function prepareSignedPayload(account_ids: AccountIdentifiers, payload: string): Promise<string> {
  const signature = await account_ids.user_key.signing_key.sign(Helpers.utf8Encode(payload));
  return Helpers.b64Encode(signature);
}

export const ApiMethods: { [key: string]: Method } = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
  patch: "PATCH"
};

export const form_headers = { "Content-Type": "multipart/form-data" };

const BaseAPI = {
  get,
  post,
  put,
  patch,
  axiosDelete,
  prepareSignedRequestHeader,
  prepareSignedPayload
};

export default BaseAPI;

