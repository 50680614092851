/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
// @ts-ignore
import { NaclCrypto, PVCryptoUsage } from "pvcryptojs";
import { SigningKey, VerifyKey } from "./sign";


export class SigningKeyV1 extends SigningKey {
  static protocol_version = 1;

  constructor(public_key: Uint8Array, public private_key?: Uint8Array) {
    super(SigningKeyV1.protocol_version, public_key, private_key);
  }

  static async new(sk): Promise<SigningKeyV1> {
    const [_sk, _pk] = !sk ?
    await NaclCrypto.generateAsymmKey(PVCryptoUsage.SIGN) :
    [sk, await NaclCrypto.publicKeyFromPrivate(sk, PVCryptoUsage.SIGN)];

    return new SigningKeyV1(_pk, _sk);
  }

  public cloneVerify(): VerifyKey {
    return new SigningKeyV1(this.public_key);
  }

  public async sign(plaintext: Uint8Array): Promise<Uint8Array> {
    if (!this.private_key) {
      throw Error("Invalid usage");
    }
    return NaclCrypto.sign(this.private_key, plaintext);
  }

  public async verify(plaintext: Uint8Array, signature: Uint8Array): Promise<boolean> {
    return NaclCrypto.verify(this.public_key, plaintext, signature);
  }
}
