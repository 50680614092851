import { useState, useCallback, useEffect, useRef } from "react";
import { MessageDisplayType, ErrorStackDataType } from "@preveil-api";
import {
  Account, useAppDispatch, Message, MessageHandlerDisplayType, DriveErrorMessages, useAppSelector, getEnumKey, CollectionFilesyncAPI,
  DriveRequest, GrantSet, useSendRequestMutation, DriveEntryType, GrantSetType, useGetPermissions, PermissionSetType, DriveEntryTypes,
  TrashErrorMessages, ErrorStackItem
} from "src/common";
import { FSMessage, FSStatus } from "src/common/keys/protos/collections_pb";
import { DriveCallbackAsyncFunction, uiActions } from "src/store";
import { RootState } from "src/store/configureStore";

type Item = {
  id: string;
  collection_id: string;
  type: DriveEntryTypes;
};

/* Description: The Web hook for deleting/permanently deleting items from drive */
export function useDeleteItems(current_account: Account, expunge: boolean = false) {
  const default_permissions = useAppSelector((state: RootState) => state.drive.default_permissions);
  const [successes, setSuccesses] = useState<string[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [delete_complete, setDeleteComplete] = useState<boolean>(false);
  const [total, setTotal] = useState<number>();
  const { permissions, getPermissions, destroyPermissions } = useGetPermissions(current_account);
  const [sendRequest] = useSendRequestMutation();
  const dispatch = useAppDispatch();

  const permissions_ref = useRef(permissions);
  function setPermissionsRef(_permissions?: PermissionSetType) {
    permissions_ref.current = _permissions;
  }

  const items_ref = useRef<Item[]>([]);
  const item_ref = useRef<Item>();

  useEffect(() => {
    const responded = errors.length + successes.length;
    if (!!total && responded === total) {
      setDeleteComplete(true);
    }
  }, [errors, successes]);

  useEffect(() => {
    if (!!permissions) {
      setPermissionsRef(permissions);
      // NOTE: Return the permission state to undefined to be able to detect future chages
      destroyPermissions();
      getVersion();
    }
  }, [permissions]);

  // Description: Fetch Item information based on type
  function getVersion() {
    if (!!item_ref.current && !!permissions_ref.current) {
      switch (item_ref.current.type) {
        case DriveEntryType.FILE:
          handleFetchFile(item_ref.current.id, item_ref.current.collection_id, permissions_ref.current);
          break;
        case DriveEntryType.DIR:
          handlefetchDirectory(item_ref.current.id, item_ref.current.collection_id, permissions_ref.current);
          break;
        default:
          handleFetchLink(item_ref.current.id, item_ref.current.collection_id, permissions_ref.current);
          break;
      }
    }
  }

  // Description: Fetch link to get link version 
  async function handleFetchLink(item_id: string, _collection_id: string, permissions: PermissionSetType) {
    const request = await CollectionFilesyncAPI.fetchLinkRequest(_collection_id, item_id);
    const drive_request = !!permissions ? await DriveRequest.initializeRequest(request, current_account, permissions) : null;
    async function callback(message: FSMessage) {
      const link = message.getLink();
      if (!!link && message.getStatus() === FSStatus.OK) {
        deleteItem(item_id, _collection_id, link.getVersion_asB64(), permissions);
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_link }, message, item_id);
      }
    }
    handleSendRequest(drive_request, callback, item_id);
  }

  // Description: Fetch directory to get version and grants
  async function handlefetchDirectory(item_id: string, collection_id: string, permissions: PermissionSetType, entry_version?: string, maintainer_id?: string) {
    const id = !!maintainer_id ? maintainer_id : item_id;
    const request = await CollectionFilesyncAPI.getDirectoryInformationRequest(collection_id, id, true);
    const drive_request = !!request && !!permissions ? await DriveRequest.initializeRequest(request, current_account, permissions) : null;
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        const dir = message.getDir();
        if (!!dir && !!permissions) {
          const version = !!entry_version ? entry_version : dir.getVersion_asB64();
          const acl_list = dir.getAclList();
          if (acl_list.length > 0) { // if acl node.
            const grant_sets = GrantSet.getActiveGrantSets(collection_id, dir.getId_asB64(), acl_list);
            const current_grants = !!grant_sets ? GrantSet.getActive(grant_sets, collection_id, dir.getId_asB64(), current_account.user_id) : undefined;
            deleteItem(item_id, collection_id, version, permissions, current_grants);
          } else if (dir.hasMaintainerId()) { // if child of maintainer.
            handlefetchDirectory(item_id, collection_id, permissions, version, dir.getMaintainerId_asB64());
          } else { // if under v1 collection or root.
            deleteItem(item_id, collection_id, dir.getVersion_asB64(), permissions);
          }
        } else {
          handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_directory }, message, item_id);
        }
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_directory }, message);
      }
    }
    handleSendRequest(drive_request, callback, item_id);
  }

  // Description: useEffect hook for when grants (from the useGetGrants hook) is initialized.
  async function handleFetchFile(item_id: string, collection_id: string, permissions: PermissionSetType) {
    const drive_request = !!permissions ? await CollectionFilesyncAPI.fetchFile(current_account, collection_id, item_id, permissions, true) : null;
    async function callback(message: FSMessage) {
      const file = message.getFile();
      if (!!file && message.getStatus() === FSStatus.OK) {
        if (file.hasMaintainerId()) {
          handlefetchDirectory(item_id, collection_id, permissions, file.getVersion_asB64(), file.getMaintainerId_asB64());
        } else {
          deleteItem(item_id, collection_id, file.getVersion_asB64(), permissions);
        }
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_file }, message, item_id);
      }
    }
    handleSendRequest(drive_request, callback, item_id);
  }

  // Description: encrypt name with new parent's symmetric key and moveItem (with backend UPDATE method)
  async function deleteItem(item_id: string, collection_id: string, version: string, permissions: PermissionSetType, grants?: GrantSetType) {
    const drive_request = await CollectionFilesyncAPI.deleteItem(current_account, collection_id, item_id, version, expunge, permissions, grants);
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        setSuccesses((successes) => successes.concat([item_id]));
        item_ref.current = items_ref.current.pop();
        !!item_ref.current && getPermissions(item_ref.current.collection_id, default_permissions);
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: TrashErrorMessages.error_deleting_item }, message);
      }
    }
    handleSendRequest(drive_request, callback, item_id);
  }

  // Description: Sends Request and handles error when the request object is null, pass it custom stack message
  function handleSendRequest<T>(request: DriveRequest | null, callback: DriveCallbackAsyncFunction<T>, item_id?: string, stack_error: string = DriveErrorMessages.error_sending_request) {
    if (!!request) {
      request.setCallback(callback);
      sendRequest(request);
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, { stack_error, stack_message: DriveErrorMessages.error_sending_request }, null, item_id);
    }
  }

  // Description: Logs Errors and sets Error to true
  function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, fsmessage?: FSMessage | null, item_id?: string,
    display_type: MessageDisplayType = MessageHandlerDisplayType.logger) {
    const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
    const stack = new ErrorStackItem("[useDeleteItems Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
    dispatch(uiActions.handleRequestErrors(new Message(message, display_type), stack));
    !!item_id ? setErrors((errors) => errors.concat([item_id])) : setError(true);
  }

  // Description: Callback for moving items
  const deleteItems = useCallback((items: Item[]) => {
    setTotal(items.length);
    items_ref.current = items;
    item_ref.current = items_ref.current.pop();
    !!item_ref.current && getPermissions(item_ref.current.collection_id, default_permissions);
  }, []);

  // Description: Reset the hook
  const resetDeleteItems = useCallback(() => {
    setError(false);
    setErrors([]);
    setSuccesses([]);
    setDeleteComplete(false);
    setTotal(undefined);
    items_ref.current = [];
    item_ref.current = undefined;
  }, []);

  return {
    deleteItems,
    resetDeleteItems,
    error,
    errors,
    successes,
    delete_complete,
  };
}
