import * as React from "react";
import { Pagination } from "react-bootstrap";
import { PaginationItem, ActionHandlerFunction } from "@preveil-api";
import { UIManagerActionTypes, GlobalConstants } from "src/common";
import { Icon } from "src/components";

interface AllProps {
    pagination: PaginationItem;
    className?: string;
    handleAction: ActionHandlerFunction;
}

function PaginationComponent(props: AllProps) {
    const { pagination, className, handleAction } = props;
    const numberPagerLinks: number = GlobalConstants.PAGINATION_LINKS_MAX;

    function renderPaginationLinks() {
        const pageNumber = (pagination.pageIndex + 1);
        let rangeIndex = Math.ceil(pageNumber / numberPagerLinks); // This is the number interval of pager links
        rangeIndex = rangeIndex <= 0 ? 1 : rangeIndex;
        const max = (numberPagerLinks * rangeIndex) >= pagination.pageCount ? pagination.pageCount : (numberPagerLinks * rangeIndex);
        let min = max - numberPagerLinks + 1;
        (min <= 0) && (min = 1);
        const links = [];
        for (let i = min; i <= max; i++) {
            links.push(
                (pageNumber === i) ?
                    <Pagination.Item className="hide-mobile-sm" key={`pagenumber_${i}`} active >{i}</Pagination.Item> :
                    <Pagination.Item className="hide-mobile-sm"
                        onClick={() => handleAction({ actionType: UIManagerActionTypes.Paging, params: i })} key={`pagenumber_${i}`} >{i}</Pagination.Item>
            );
        }
        return links;
    }

    return (!!pagination && pagination.pageCount > 1) ?
        <Pagination
            size="sm"
            className={`${className}`}
            aria-label={"Pagination"} >
            <Pagination.First className="hide-mobile-sm"
                onClick={() => handleAction({ actionType: UIManagerActionTypes.Paging, params: 1 })}
                disabled={pagination.pageIndex === 0} >
                <Icon className="ficon-chevrons-left" />
            </Pagination.First>
            <Pagination.Prev
                onClick={() => handleAction({ actionType: UIManagerActionTypes.Paging, params: (pagination.pageCount > 1 ? (pagination.pageIndex) : 1) })}
                disabled={pagination.pageIndex === 0} >
                <Icon className="ficon-chevron-left" />
            </Pagination.Prev>
            {
                renderPaginationLinks()
            }
            <Pagination.Next
                onClick={() => handleAction({ actionType: UIManagerActionTypes.Paging, params: (pagination.pageIndex < pagination.pageCount ? (pagination.pageIndex + 2) : pagination.pageCount) })}
                disabled={(pagination.pageIndex + 1) === pagination.pageCount}>
                <Icon className="ficon-chevron-right" />
            </Pagination.Next>
            <Pagination.Last className="hide-mobile-sm"
                onClick={() => handleAction({ actionType: UIManagerActionTypes.Paging, params: pagination.pageCount })}
                disabled={(pagination.pageIndex + 1) === pagination.pageCount} >
                <Icon className="ficon-chevrons-right" />
            </Pagination.Last>
        </Pagination> : <></>;
}

export default React.memo(PaginationComponent);
