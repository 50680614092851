import { PaginationItem } from "@preveil-api";
import { GlobalConstants, isNumber } from "..";

export class Pagination implements PaginationItem {
  pageIndex: number = 0; // Number(Page Number - 1) 
  pageSize: number = GlobalConstants.LIMIT_NUMBER_THREAD_TO_FETCH; // limit - how many rows per page
  pageCount: number = 0; // Total number of pages (totalRows / pageSize)
  totalPageRows: number = 0; // total rows returned in a page may be different from pageSize
  totalRows: number = 0; // total rows in the table
  offset: number = 0; // page number or pageIndex+1 * pagesize
  isLastPage: boolean; // Is the current page the last page?

  // Determines offset, isLastPage, pageCount from current_pageIndex, pagesize and total rows
  constructor(
    pageIndex: number,
    totalPageRows: number,
    totalRows: number,
    pageSize?: number,
  ) {
    this.pageIndex = pageIndex;
    this.totalPageRows = totalPageRows;  // total rows returned for this page
    this.totalRows = totalRows; // total rows in the query ( exceeds the page size limit at times )
    this.pageSize = !!pageSize ? pageSize : GlobalConstants.LIMIT_NUMBER_THREAD_TO_FETCH; // Defaults to stored constant
    this.offset = (this.pageIndex + 1) * this.pageSize;
    this.isLastPage = true; // is true until otherwise calculated
    this.pageCount = this.totalRows > 0 ? Math.ceil(totalRows / this.pageSize) : 0;
  }

  get pagination_item(): PaginationItem {
    return {
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      pageCount: this.pageCount,
      totalRows: this.totalRows,
      offset: this.offset,
      isLastPage: this.isLastPage
    };
  }

  // Description: Set the initial offset by page number
  static getInitialOffset(page: string): number {
    return isNumber(page) ? (Number(page) - 1) * GlobalConstants.LIMIT_NUMBER_THREAD_TO_FETCH : 0;
  }

  // Description: Set the initial offset by page number
  static getPageIndex(page?: string): number {
    return !!page && isNumber(page) ? (Number(page) - 1) : 0;
  }
}