/* eslint-disable no-useless-escape */
/* eslint-disable prefer-regex-literals */
import { ThreadMessage, ThreadAttachment } from "@preveil-api";
import { RegexHelper, injectInlineAttachmentsIntoDOM, Attachment, formatShortTimestamp, MailErrorMessages, regexEscape } from "src/common";
import _ from "lodash";

// Handle Message bodies
export class MailMessageBody {
  html: string | null = null;
  text: string | null = null;
  inline_attachments: Attachment[] | [] = [];
  message_body: string = "";
  is_response: boolean = false; // May not need this
  error: any;
  private readonly _previous_message?: ThreadMessage;
  private readonly _message: ThreadMessage;
  constructor(message: ThreadMessage, previous_message?: ThreadMessage) {
    this._previous_message = previous_message as ThreadMessage;
    this._message = message;
    this.html = message.html;
    this.text = message.text;
    this.inline_attachments = !!message.inline_attachments ? this.getInlineAttachments(message.inline_attachments) : [];
    this._formatEmailInThread();
  }

  // Description: Return the ThreadMessage object with the processed body
  get processed_message(): ThreadMessage {
    return Object.assign({}, this._message, { message_body: this.message_body, error: this.error });
  }

  // Description: Select attachments from the _message.attachments prop
  private getInlineAttachments(inline_attachments: string[]): Attachment[] {
    const _inline_attachments: Attachment[] = [];
    _.forEach(inline_attachments, (attachment_id: string) => {
      const thread_attachment = _.find(this._message.attachments, (attachment: ThreadAttachment) => {
        const arr = attachment.content_reference_id.split(",");
        return arr.includes(attachment_id);
      });
      !!thread_attachment && _inline_attachments.push(Attachment.fromThreadAttachment(thread_attachment));
    });
    return _inline_attachments;
  }

  // Description: Remove artifacts and previous emails sent in the same message
  // NOTE: Catch all errors in the process of building the message and funnel them to error prop
  private _formatEmailInThread() {
    try {
      !!this.html ? this._formatHTMLContent(this.html, this._previous_message) :
        !!this.text ? this._formatTextContent(this.text, this._previous_message) : (this.message_body = "");
    } catch (error: unknown) {
      this.message_body = `<div class="error">${MailErrorMessages.error_getting_message}</div>`;
      this.error = error;
    }
  }

  // Description: Format and sanitize html field of message
  private _formatHTMLContent(html: string, previous_email?: ThreadMessage): void {
    html = RegexHelper.makeLinks(html);
    html = injectInlineAttachmentsIntoDOM(html, this.inline_attachments);

    // Apple Mail leaves <[email address]> which html interprets as an html element. Transform that to be a mailto link
    html = html.replace(/<(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))>/gm,
      "&lt;<a href=\"mailto:$1\">$1</a>&gt;");
    // replace MUA additions of "> " at the start of each line of response
    html = html.replace(/<br>>\s/gm, "<br>");
    html = RegexHelper.filterLinks(html);
    let email_without_previous_content: string;
    const body_without_carriage_returns: string = email_without_previous_content = html.replace(/(\r|\n)/g, " ");
    if (!!previous_email) {
      const regex_previous_content_apple: RegExp = new RegExp(`On ${formatShortTimestamp(previous_email.date)}.*`, "g");
      const regex_previous_content_outlook: RegExp = new RegExp(`\<b\>(.*)From:.*${!!previous_email.sender.name ? regexEscape(previous_email.sender.name) : ""}.*\[mailto\:${regexEscape(previous_email.sender.address)}\].*`, "g");
      const regex_previous_content_ios: RegExp = new RegExp("<blockquote.*", "g");
      email_without_previous_content = body_without_carriage_returns.replace(regex_previous_content_apple, "");
      email_without_previous_content = email_without_previous_content.replace(regex_previous_content_outlook, "");
      email_without_previous_content = email_without_previous_content.replace(regex_previous_content_ios, "");
      // clean up space before previous content
      email_without_previous_content = email_without_previous_content.replace(/(\<br\>){2,}$/gmi, "");
    }

    this.message_body = email_without_previous_content;
    this.is_response = body_without_carriage_returns !== email_without_previous_content;
  }

  // Description: Format and sanitize Text field of message
  private _formatTextContent(text: string, previous_email?: ThreadMessage): void {
    let displayed_original_body: string = text; // = this._dom_sanitation_service.sanitize(SecurityContext.HTML, text);
    displayed_original_body = displayed_original_body.replace(/(&#10;)/g, "<br>");
    displayed_original_body = displayed_original_body.replace(/((&gt;){1,} )/g, "");
    const body_without_carriage_returns: string = displayed_original_body.replace(/(\r|\n)/g, "");
    let email_without_previous_content: string = displayed_original_body;
    this.is_response = !!previous_email;
    if (!!previous_email) {
      const regex_previous_content_apple: RegExp = new RegExp(`On ${formatShortTimestamp(previous_email.date)}.*`, "g");
      const regex_previous_content_outlook: RegExp = new RegExp(`.From:. ${previous_email.sender.name} [mailto:${previous_email.sender.address}].*`, "g");
      email_without_previous_content = body_without_carriage_returns.replace(regex_previous_content_apple, "");
      email_without_previous_content = email_without_previous_content.replace(regex_previous_content_outlook, "");
    }
    this.message_body = email_without_previous_content;
  }
}