import React, { useEffect, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { PublicRoutes, useAppDispatch, LocalAccountStorage, QueryParamKeys } from "src/common";
import { accountActions, mailActions, driveActions, websocketActions, uiActions, settingsActions, adminActions } from "src/store";
import { useAuthContext, Loading } from "src/components";

function LogoutComponent() {
  const [searchParams] = useSearchParams();
  const [redirect_url, setRedirectURL] = useState<string | undefined>();
  const { setProfile } = useAuthContext();
  const dispatch = useAppDispatch();


  useEffect(() => {
    handleLogout();
  }, []);

  function handleLogout() {
    dispatch(accountActions.logout());
    dispatch(mailActions.destroyMail());
    dispatch(driveActions.destroyDrive());
    dispatch(websocketActions.destroyWebsocket());
    dispatch(uiActions.destroyUIManager());
    dispatch(settingsActions.destroySettings());
    dispatch(adminActions.destroyAdmin());
    setProfile();
    LocalAccountStorage.destroyLocalStorageSessions();
    LocalAccountStorage.removeTemporaryCookies("express_recovery_not_setup", "false");
    // NOTE: If redirect comes in as a querystring handle custom redirect
    const query_redirect_url = searchParams.get(QueryParamKeys.REDIRECT_URL_QUERY_KEY);
    setRedirectURL(!!query_redirect_url ? query_redirect_url : `/${PublicRoutes.choose_account_route}`);
  }

  return !!redirect_url ? <Navigate to={redirect_url} /> : <Loading />;
}

export default React.memo(LogoutComponent);