import { CollectionServerUser, JSONGroupEmail, CollectionServerError, UserIdentifierBase, UserListStatusType, ContactsData, UserProfile } from "@preveil-api";
import { Account, isSameUser, LocalAccountStorage, UserResultItem, regexEscape, isSameProfile, UserListStatus } from "src/common";
import _ from "lodash";

// Description: A list of contacts that will populate store and will be used globally on User-list components
export class Contacts {
  profiles: UserProfile[] = []; // Holds all contacts from session plus new ones if any
  contacts_fetch_key: string = "contacts";
  current_contacts: UserProfile[] = []; // Holds new profile per instance
  cs_profiles: CollectionServerUser[] = [];
  cs_aliases: CollectionServerUser[] = [];
  cs_groups: JSONGroupEmail[] = [];
  unclaimed: UserProfile[] = [];
  constructor(
    public current_user_id: string,
    accounts?: Account[] | null,
    org_users?: CollectionServerUser[],
    public exclude_self: boolean = false,
    public exclude_users: string[] = []
  ) {
    this.contacts_fetch_key = `${current_user_id}/contacts`;
    this.profiles = !!org_users ? this.parseCollectionServerUsers(org_users) : this.initialize();
    this.current_contacts = !!accounts && accounts.length ? this._parseAccount(accounts) : []; // Note: Will not have status
    this.addContacts(this.current_contacts);
  }

  // Description: Return detailed data from Find (CollectionServerUser[] and JSONGroupEmail[])
  get data(): ContactsData {
    return {
      cs_profiles: this.cs_profiles,
      cs_aliases: this.cs_aliases,
      cs_groups: this.cs_groups,
      unclaimed: this.unclaimed
    };
  }

  // Description: Parse Account[] to UserProfile[]
  private _parseAccount(accounts: Account[]): UserProfile[] {
    return _.map(accounts, (account: Account) => {
      return {
        address: account.user_id,
        // NOTE: Do not add user_id if external email should not show main address as name ( b/c gateway user)
        name: account.display_name || account.external_email || null,
        external_email: account.external_email,
        status: UserListStatus.claimed,
        org_id: !!account.org_info ? account.org_info.org_id : undefined,
      };
    });
  }

  // Description: Get contacts from local storage to init class instance
  public initialize(): UserProfile[] {
    let contacts: UserProfile[] = [];
    const cached_contacts_data = localStorage.getItem(this.contacts_fetch_key);
    if (!!cached_contacts_data) {
      const json_contacts = JSON.parse(cached_contacts_data) as UserProfile[];
      // NOTE: if exclude_self => Remove current id from profiles
      if (this.exclude_self) {
        const index = _.findIndex(json_contacts, (_contact => isSameUser(this.current_user_id, _contact.address)));
        index >= 0 && json_contacts.splice(index, 1);
      }

      // NOTE: Remove users from contacts
      (this.exclude_users.length > 0) &&
        _.remove(json_contacts, (profile: UserProfile) => this.exclude_users.includes(profile.address));

      contacts = _.map(json_contacts, (user_profile: UserProfile) => {
        const address = user_profile.address; //  u.address || (u as any).address || (u as any).user_id;
        return {
          address,
          name: !!user_profile.name && user_profile.name.length ? user_profile.name : address,
          external_email: user_profile.external_email,
          status: user_profile.status,
          members: user_profile.members,
          org_id: user_profile.org_id,
          role: user_profile.role,
        };
      });
    }
    return contacts || [];
  }

  // Description: Update profiles from CollectionServerUser models
  public updateCsProfiles(cs_users: CollectionServerUser[], user_ids?: string[]): void {
    if (cs_users.length > 0) {
      this.addCSContacts(cs_users);
      this.addContacts(this.parseCollectionServerUsers(cs_users, UserListStatus.claimed));
      !!user_ids && this.updateCurrentContacts(user_ids);
    }
  }

  // Description: Update rest of CollectionServerUser models
  public updateCsProps(
    aliases: CollectionServerUser[],
    groups: JSONGroupEmail[],
    errors: CollectionServerError[],
  ): void {
    !!aliases && aliases.length > 0 && this.addAliasesContacts(aliases);
    !!groups && groups.length > 0 && this.addGroupsContacts(groups);
    !!errors && errors.length > 0 && this.addUnclaimedContacts(errors);
  }

  // Description: Parse CollectionServerUser[] array to UserProfile[] - mostly after callbacks (i.e getOrgInfo)
  public parseCollectionServerUsers(
    users: CollectionServerUser[],
    status?: UserListStatusType,
    members?: string[]
  ): UserProfile[] {
    const _users = users.slice();
    // NOTE: if exclude_self => Remove current id from profiles
    if (this.exclude_self) {
      const index = _.findIndex(_users, ((_contact: CollectionServerUser) => {
        const id = !!status && status === UserListStatus.external && !!_contact.external_email ? _contact.external_email : _contact.user_id;
        return isSameUser(this.current_user_id, id);
      }));
      index >= 0 && _users.splice(index, 1);
    }

    // NOTE: Remove users from contacts
    (this.exclude_users.length > 0) &&
      _.remove(_users, (profile: CollectionServerUser) => {
        const id = !!status && status === UserListStatus.external && !!profile.external_email ? profile.external_email : profile.user_id;
        return this.exclude_users.includes(id);
      });

    return _.map(_users, (cs_user: CollectionServerUser) => {
      const _status = status === UserListStatus.claimed && !cs_user.claimed ? UserListStatus.pending : !!status ? status : cs_user.claimed ? UserListStatus.claimed : undefined;
      return {
        address: cs_user.user_id.toLowerCase(),
        name: cs_user.display_name,
        external_email: cs_user.external_email,
        role: !!cs_user.entity_metadata ? cs_user.entity_metadata.role : undefined,
        status: _status,
        org_id: cs_user.entity_id,
        members,
      };
    });
  }

  // Description: Add to current contacts (no duplicates)
  public addCurrentContacts(profiles: UserProfile[]) {
    const new_current = this.current_contacts.slice();
    _.map(profiles, (profile: UserProfile) => {
      const is_contact = _.find(this.current_contacts, (current_contact: UserProfile) =>
        isSameProfile(current_contact, profile),
      );
      !is_contact && new_current.push(profile);
    });
    this.current_contacts = new_current;
  }

  // Description: reset contacts;
  public reset() {
    this.current_contacts = [];
  }

  // Description: Add to current contacts (no duplicates)
  public removeCurrentContact(profile: UserProfile) {
    const new_current = this.current_contacts.slice();
    const index = _.findIndex(new_current, (_profile: UserProfile) => isSameProfile(profile, _profile));
    index >= 0 && new_current.splice(index, 1);
    this.current_contacts = new_current;
  }

  // Description: Add contacts to this.profiles, carefully removing duplicates
  public addContacts(users: UserProfile[]): void {
    const updated_contacts: UserProfile[] = this.profiles.concat(users);
    this.profiles = _.uniqBy(updated_contacts, (u: UserProfile) =>
      !!u.external_email ? u.external_email : u.address,
    );
    // Remove current user from its own contacts list - (** commenting this out because we want the current user to be in the list ** - mahima)
    // const i = _.findIndex(this.profiles, (contact: Profile) => isSameUser(this.current_user_id, contact.address));
    // (i >= 0) && this.profiles.splice(i, 1);
  }

  // Description: Add contacts to this.profiles, carefully removing duplicates
  public addCSContacts(users: CollectionServerUser[]): void {
    const updated_contacts: CollectionServerUser[] = this.cs_profiles.concat(users);
    this.cs_profiles = _.uniqBy(updated_contacts, "user_id");
  }

  // Description: Update Claimed current user from the user_ids array
  public updateCurrentContacts(user_ids: string[]) {
    const current: CollectionServerUser[] = [];
    _.map(user_ids, (user_id: string) => {
      const is_contact = _.find(this.current_contacts, (contact: UserProfile) =>
        isSameUser(user_id, contact.address),
      );
      if (!is_contact) {
        const _current = _.find(this.cs_profiles, (profile: CollectionServerUser) =>
          isSameUser(user_id, profile.user_id),
        );
        !!_current && current.push(_current);
      }
      return user_id;
    });
    current.length > 0 &&
      this.addCurrentContacts(this.parseCollectionServerUsers(current, UserListStatus.claimed));
  }

  // Description: External users returned as Aliases objects returned from cs can be added as Contacts too for easy access
  public addAliasesContacts(cs_aliases: CollectionServerUser[]): void {
    const updated_contacts: CollectionServerUser[] = this.cs_aliases.concat(cs_aliases);
    this.cs_aliases = _.uniqBy(updated_contacts, (u: CollectionServerUser) =>
      !!u.external_email ? u.external_email : u.user_id,
    );
    const profiles = this.parseCollectionServerUsers(cs_aliases, UserListStatus.external);
    this.addContacts(profiles);
    this.addCurrentContacts(profiles.slice());
  }

  // Description: Groups to contacts and save returned from cs can be added as Contacts too for easy access
  public addGroupsContacts(cs_groups: JSONGroupEmail[]): void {
    const updated_groups: JSONGroupEmail[] = this.cs_groups.concat(cs_groups);
    this.cs_groups = _.uniqBy(updated_groups, "alias");
    const profiles: UserProfile[] = [];
    _.map(cs_groups, (groups: JSONGroupEmail) => {
      profiles.push({
        address: groups.alias.toLowerCase(),
        name: groups.alias,
        status: UserListStatus.group,
        members: groups.users,
      });
    });
    this.addContacts(profiles);
    this.addCurrentContacts(profiles.slice());
  }

  // Description: Unclaimed users
  public addUnclaimedContacts(errors: CollectionServerError[]): void {
    const unclaimed_users: UserProfile[] = [];
    _.map(errors, (error: CollectionServerError) => {
      const unclaimed = error.cause as UserIdentifierBase;
      return (
        !!unclaimed.user_id &&
        unclaimed_users.push({
          address: unclaimed.user_id.toLowerCase(),
          name: unclaimed.user_id,
          status: UserListStatus.unclaimed,
        })
      );
    });

    // Note: only update if there are any
    if (unclaimed_users.length > 0) this.unclaimed = unclaimed_users;
    this.addCurrentContacts(unclaimed_users);
  }

  // Description: Updates the sessions
  public updateContactSessions() {
    !!this.contacts_fetch_key &&
      this.profiles.length &&
      LocalAccountStorage.setContactSessionItems(this.contacts_fetch_key, this.profiles);
  }

  // Description: Get users by query for search
  public search(query: string): UserProfile[] {
    const regexp = new RegExp(`${regexEscape(query)}`, "i");
    const matches: UserProfile[] = [];
    _.map(this.profiles, (contact: UserProfile) => {
      ((!!contact.name && regexp.test(contact.name)) || regexp.test(contact.address)) &&
        matches.push(new UserResultItem(contact, query).profile);
    });
    return matches;
  }

  // Description: Remove a set of selected profiles - exclude users
  public filterSelected(selected: UserProfile[], exclude_users: string[] = []): UserProfile[] {
    const new_profiles: UserProfile[] = this.profiles.slice();
    (selected.length > 0 || exclude_users.length > 0) &&
      _.remove(new_profiles,
        (profile: UserProfile) => (!!_.find(selected, (_profile) => isSameProfile(profile, _profile)) ||
          (!!profile.address && exclude_users.includes(profile.address.toLowerCase()))));
    return new_profiles;
  }

  // Description: Merge 2 ContactsData when more than one UserList component are used in same sate
  static mergeComposeRecipientContacts(original: ContactsData, update: ContactsData): ContactsData {
    const new_contacts_data = {
      cs_profiles: _.unionBy(original.cs_profiles, update.cs_profiles, "user_id"),
      cs_aliases: _.unionBy(original.cs_aliases, update.cs_aliases, "external_email"),
      cs_groups: _.unionBy(original.cs_groups, update.cs_groups, "alias"),
      unclaimed: _.unionBy(original.unclaimed, update.unclaimed, "address"),
    };
    return new_contacts_data;
  }
};
