import React from "react";
import { ActionHandlerFunction } from "@preveil-api";
import { AdminUIActionTypes } from "src/common";
import { Card, Button } from "react-bootstrap";
import connectionImage from "src/assets/images/connection.svg";

type AllProps = {
  handleActions: ActionHandlerFunction;
  approver: any;
}

function DataExportErrorConnection({ handleActions, approver }: AllProps) {
  const { address } = approver;
  return (
    <main className="data-export-wait-connection">
      <Card className="pv-card">
        <Card.Img variant="top" src={connectionImage} />
        <Card.Body>
          <header className="wait-connection-header">
            <h3 className="content-header">Connection Disconnected</h3>
          </header>
          <section className="wait-connection-instructions">
            <h5>Please try again!</h5>
          </section>
          <footer>
            <Button variant="primary" onClick={() => handleActions({ actionType: AdminUIActionTypes.Start, params: { subsumeUser: address } })}>Retry</Button>
            <Button  variant="no-outline-primary" onClick={() => handleActions({ actionType: AdminUIActionTypes.Reset })}>Back</Button>
          </footer>
        </Card.Body>
      </Card>
    </main>
  );
}

export default React.memo(DataExportErrorConnection);