import { ActionHandlerFunction, Sort } from "@preveil-api";
import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { CheckboxStates, CheckboxStatesTypes, RequestTab, SettingsUIActionTypes, UserRequest } from "src/common";
import { ApprovalRequestsRow, Checkbox, EmptyView, Icon, Loading } from "src/components";
import _ from "lodash";

type AllProps = {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  checked_list: UserRequest[];
  setCheckedList: React.Dispatch<React.SetStateAction<UserRequest[]>>;
  show: boolean;
  approval_requests: UserRequest[];
  can_select: boolean;
  current_sort: Sort<keyof UserRequest>;
  loading: boolean;
  handleAction: ActionHandlerFunction;
  tab: string;
};

function ApprovalRequestsListViewComponent(props: AllProps) {
  const {
    setShow,
    show,
    setCheckedList,
    checked_list,
    approval_requests,
    can_select,
    current_sort,
    loading,
    handleAction,
    tab,
  } = props;

  /* Descriptions: Sets the field for the sort (calls handle Action depending on which component is using this shared component) */
  function setSort(field: string) {
    handleAction({
      actionType: SettingsUIActionTypes.SetSort,
      params: { tab, field},
    });
  }

  /* Descriptions: Shareable react fragment - for each field returns the header with sort icon
  if applicable */
  function headerWithSortIcon(name: string, field: string) {
    return (
      <div className="clickable">
        {name}
        {!!current_sort && current_sort.field === field && approval_requests.length > 1 && (
          <Icon className={`clickable ${current_sort.direction === "desc" ? "ficon-arrow-down" : "ficon-arrow-up"} small-icon ms-1 d-none d-sm-inline`} />
        )}
      </div>
    );
  }

  /* Description: Get the selected all checkbox state */
  function getSelectedState(): CheckboxStatesTypes {
    let ck_state: CheckboxStatesTypes = CheckboxStates.empty;
    ck_state =
      checked_list.length > 0 && checked_list.length < approval_requests.length
        ? CheckboxStates.indeterminate
        : checked_list.length === approval_requests.length && approval_requests.length > 0
          ? CheckboxStates.checked
          : CheckboxStates.empty;
    return ck_state;
  }

  /* Description: Handles Checkbox change - opens side panel if one request or adds to checked list. */
  function handleCheckboxChange() {
    if (checked_list.length > 0 && checked_list.length < approval_requests.length) {
      setCheckedList(approval_requests);
    } else if (checked_list.length === approval_requests.length) {
      setShow(false);
      setCheckedList([]);
    } else if (checked_list.length === 0 && approval_requests.length === 1) {
      setCheckedList(approval_requests);
      setShow(true);
    } else if (checked_list.length === 0) {
      setCheckedList(approval_requests);
    }
  }

  // Description: Render the rows
  function RenderList() {
    return (
      <div className="list-body">
        {_.map(approval_requests, (r: UserRequest, i: number) => {
          return (
            <ApprovalRequestsRow
              key={i}
              request={r}
              setShow={setShow}
              show={show}
              setCheckedList={setCheckedList}
              checked_list={checked_list}
              can_select={can_select}
            ></ApprovalRequestsRow>
          );
        })}
      </div>
    );
  }

  return <div className={`settings-list${loading ? " isloading" : ""} ${can_select ? "approval-requests-with-select" : "approval-requests"} clickable`}>
    {!loading && <>{approval_requests.length > 0 ?
    <>
      <Row className="header-row">
        {tab === RequestTab.pending && can_select && (
          <Col md="1" lg="auto">
            <Checkbox
              className="sr-only"
              onChange={() => handleCheckboxChange()}
              value={getSelectedState()}
              label="Select All"
              selected={getSelectedState()}
            />
          </Col>
        )}
        <Col className="col" lg={3} onClick={() => !!current_sort && setSort("mapped_action")}>
          {headerWithSortIcon("Action", "mapped_action")}
        </Col>
        <Col lg={2} onClick={() => !!current_sort && setSort("requester_id")}>
          {headerWithSortIcon("Requester", "requester_id")}
        </Col>
        <Col lg={can_select ? 2 : 3}
          onClick={() => !!current_sort && setSort("timestamp")}
        >
          {headerWithSortIcon("Timestamp", "timestamp")}
        </Col>
        <Col lg={3} onClick={() => !!current_sort && setSort("expiration")}>
          {headerWithSortIcon("Expiration", "expiration")}
        </Col>
        <Col lg={1}></Col>
      </Row>
      {RenderList()}
    </> : <EmptyView message="There are no current approval requests." />}</>}
    {loading && <Loading className="in-place" />}
  </div>;
}

export default React.memo(ApprovalRequestsListViewComponent);
