import * as React from "react";
import { PaginationItem } from "@preveil-api";

type AllProps = {
    pagination: PaginationItem;
    empty_message?: string; // Default: No items to show
}

function PaginationDisplayComponent(props: AllProps) {
    const { pagination, empty_message } = props;
    let max = Number((Number(pagination.pageIndex) + 1) * pagination.pageSize);
    const min = Number(max - pagination.pageSize) + 1;
    if (max > pagination.totalRows) {
        max = pagination.totalRows;
    }

    return <div className="pagination_info">
        {
            pagination.totalRows > 0 ?
                <>
                    <b>{min}</b> - <b>{max}</b> of <b>{pagination.totalRows}</b>
                </> :
                empty_message || ""
        }
    </div>;
}

export default React.memo(PaginationDisplayComponent);
