/*
*   File:           useCsvvalidation
*   Description:    Reuse stateful logic between components in Trusted Community, Gateway, Email Groups, and Admin Users.
*                   to validate addresses/domains inside csv files.      
*/

import { useState } from "react";
import { findInvalidAddress } from "src/common/helpers/utility";

const initialState = {
  validCollection: [],
  invalidCollection: [],
  duplicateCollection: [],
  isDataDrop: false
};

export function useCsvDataValidation(collection: string[] = []) {
  const [state, setState] = useState({...initialState});

  function handleValidateData(csvData: string[]) {
    let validAddresses: string[];
    const invalidDataFound = findInvalidAddress(csvData);
    const duplicatesDataFound = collection.filter(function (this: any, address: string) {
      return this.has(address);
    }, new Set(csvData));
  
    // if any duplicates or invalid addresses/domains found we need to filter out the valid ones
    if (duplicatesDataFound.length >= 1 || invalidDataFound.length >= 1) {
      const mergedInvalidCollections = new Set([...duplicatesDataFound, ...invalidDataFound]);
      validAddresses = csvData.filter((address) => !mergedInvalidCollections.has(address));
    } else {
      // if no duplicates or invalid found.
      validAddresses = csvData;
    }
    // dataDropState: so we stop rendering the Draggable component. If the CSV file was already dragged/loaded into the component
    // then we unmount Draggable component from the tree and render the list of addresses.
    const dataDropState = !(validAddresses.length === 0 && invalidDataFound.length === 0 && duplicatesDataFound.length === 0);
    setState((prevState: any) => {
      return {
        ...prevState,
        validCollection: validAddresses,
        invalidCollection: invalidDataFound,
        duplicateCollection: duplicatesDataFound,
        isDataDrop: dataDropState
      };
    });
  }

  function resetData() {
    setState({...initialState});
  }

  const { validCollection, invalidCollection, duplicateCollection, isDataDrop } = state;

  return {
    validAddresses: validCollection,
    invalidAddresses: invalidCollection,
    duplicateAddresses: duplicateCollection,
    isDataDrop,
    handleValidateData,
    resetData
  };
};
