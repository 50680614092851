import React, { Component, ErrorInfo, ReactElement } from "react";
import { UIErrorState } from "@preveil-api";
import ErrorComponent from "./ErrorComponent";

type AllProps = {
  children?: ReactElement;
};

class ErrorBoundaryComponent extends Component<AllProps, UIErrorState> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      hasError: false,
      error: { name: "", message: "", stack: "" },
      errorInfo: { componentStack: "" }
    };
  }

  resetState() {
    this.setState({
      hasError: false,
      error: { name: "", message: "", stack: "" },
      errorInfo: { componentStack: "" }
    });
  }

  static getDerivedStateFromError = (error: Error) => {
    return {
      hasError: true,
      error
    };
  };

  // WORKING ON THIS
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo
    });
  }

  render() {
    const { error, errorInfo } = this.state;
    return this.state.hasError ?
      <ErrorComponent error={error} errorInfo={errorInfo} resetErrorBoundary={() => { this.resetState(); }} /> :
      this.props.children;
  }
}

export default React.memo(ErrorBoundaryComponent);
