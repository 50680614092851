import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ActionHandlerFunction } from "@preveil-api";
import {
  Account, useAppSelector, DefaultCollectionName, DefaultRoutes, PathInfo, CollectionEntity, isSameIdentifier, useFetchLinkItem, buildNavigationLink, DriveEntryType
} from "src/common";
import { Breadcrumb } from "react-bootstrap";
import { RootState } from "src/store/configureStore";
import _ from "lodash";

type AllProps = {
  current_account: Account;
  id?: string;
  paths?: PathInfo[];
  collection_info?: CollectionEntity;
  handleAction?: ActionHandlerFunction;
  trash?: boolean;
}

function BreadcrumbsComponent(props: AllProps) {
  const { current_account, paths, id, collection_info, trash } = props;
  const root_collection_id = useAppSelector((state: RootState) => state.drive.root_info?.collection_id);
  const [effective_root, setEffectiveRoot] = useState<PathInfo | undefined>();
  const { link_entity, fetchLinkEntity } = useFetchLinkItem(current_account);
  useEffect(() => {
    if (!!paths && paths.length > 0 && !!collection_info) {
      buildEffectiveRootNode(paths[0], collection_info);
      (paths.length > 1 && paths[0].path !== DefaultCollectionName && !!root_collection_id && root_collection_id !== collection_info.collection_id) &&
        fetchLinkEntity(collection_info.collection_id);
    }
  }, [paths, collection_info]);

  // Description: Perform all checks to build the ACL Node Effective root
  function buildEffectiveRootNode(info: PathInfo, _collection_info: CollectionEntity) {
    const id = info.id;
    const maintainer_id = _collection_info.maintainer_id;
    // NOTE: Need to check this collection_info is NOT root so it wont duplicate node on owner accts
    if (isSameIdentifier(id, maintainer_id) && collection_info?.disjointed_acl_node) {
      fetchLinkEntity(collection_info.collection_id);
      setEffectiveRoot({
        id,
        path: _collection_info.collection_name,
        url: DefaultRoutes.drive_root // NOTE: Will build the correct url after link_entity comes back
      });
    } else {
      setEffectiveRoot(undefined);
    }
  }

  // Description: Build link / first node url 
  function getLinkEntityURL(node: PathInfo) {
    return !!link_entity ? buildNavigationLink(link_entity.collection_id, link_entity.id, DriveEntryType.LINK) :
      (node.url || DefaultRoutes.drive_root);
  }

  function BreadcrumbItem(props: { node: PathInfo, index: number, length: number }) {
    const { node, index, length } = props;
    // NOTE: Skip defaultCollection (root)
    return (node.path !== DefaultCollectionName) ? <Breadcrumb.Item
      // NOTE: Check to see if this is the last node which is current node and make active
      active={node.id === id || (index + 1 >= length)}
      linkAs={Link}
      linkProps={{ to: (index === 0 && length > 1) ? getLinkEntityURL(node) : (node.url || DefaultRoutes.drive_root) }}>
      {/* 
        TODO : If (node.path !== DefaultCollectionName) (node.path !== DefaultCollectionName) this is a link and need to get the LINK.name 
        */}
      {node.path}
    </Breadcrumb.Item> : <></>;
  }

  return <Breadcrumb className="drive-breadcrumbs">
    <Breadcrumb.Item className="h1"
      linkProps={{ to: trash ? DefaultRoutes.drive_trash : DefaultRoutes.drive_root }}
      linkAs={Link}>{trash ? "Trash" : "My PreVeil"}</Breadcrumb.Item>
    {
      (!!paths && paths.length > 0) &&
      <>
        {
          !!effective_root &&
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: !!link_entity ? buildNavigationLink(link_entity.collection_id, link_entity.id, DriveEntryType.LINK) : DefaultRoutes.drive_root }}>{effective_root.path}</Breadcrumb.Item>
        }
        {
          _.map(paths, (node: PathInfo, index: number) => {
            return <BreadcrumbItem key={`path_${index}`} node={node} index={index} length={paths.length} />;
          })
        }
      </>
    }
  </Breadcrumb >;
}

export default React.memo(BreadcrumbsComponent);
