import { useEffect } from "react";
import { MessageDisplayType, ErrorStackDataType } from "@preveil-api";
import { AsyncJob } from "src/common/keys/protos/async_job_pb";
import {
  Account, useAppSelector, useAppDispatch, filesyncSocketApi, Message, MessageHandlerDisplayType, DriveErrorMessages, AppConfiguration,
  FilesyncLocalNotifications, isSameUser, ErrorStackItem, FilesyncStatusCode
} from "src/common";
import { RootState } from "src/store/configureStore";
import { uiActions, driveActions, WebsocketStatus, AsyncJobRequest } from "src/store";
import _ from "lodash";

export function useFilesyncSocket(current_account: Account) {
  const fs_notification_ws_status = useAppSelector((state: RootState) => state.websocket.fs_notification_ws_status);
  const fs_notifications = useAppSelector((state: RootState) => state.drive.fs_notifications);
  const [initSocket, { data, error }] = filesyncSocketApi.useLazyInitializeFSNotificationHooksQuery();
  const dispatch = useAppDispatch();

  useEffect(() => {
    // NOTE: Initialize the notifications socket connection - Check first if WS is set to "ON"
    (AppConfiguration.filesycnWSNotificationOn() &&
      (fs_notification_ws_status === WebsocketStatus.not_initialized || fs_notification_ws_status === WebsocketStatus.disconnected)) &&
      initSocket(new Date().getTime().toString());
  }, [fs_notification_ws_status]);

  useEffect(() => {
    // NOTE: Handle the data object
    !!data && handleNotificationResponses(data);

    !!error &&
      handlePageErrorMessage(DriveErrorMessages.default, { stack_message: "FSNotificationHooks error", stack_error: error }, MessageHandlerDisplayType.toastr);
  }, [data, error]);


  // Description: Handle data from FS
  function handleNotificationResponses(data: AsyncJobRequest) {
    const job_list = data.message.getAsyncJobsList();
    if (job_list.length > 0) {
      _.forEach(job_list, (job: AsyncJob) => {
        const info = new FilesyncLocalNotifications(job).info;
        if (isSameUser(current_account.user_id, info?.user_id)) {
          if (!!info && !!info.request_id) {
            const _fs_notifications = Object.assign({}, fs_notifications);
            _fs_notifications[info.request_id] = info;
            dispatch(driveActions.setLocalFSNotification(_fs_notifications));
          }
          // NOTE: Notifications may come from other accounts owned by this user logged all errors
          if (!!info?.error_message) {
            console.error({
              stack_message: info.error_message,
              stack_error: info
            });
          }
        }
      });
    }
  }

  // Description:  send error to store
  function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, display_type: MessageDisplayType = MessageHandlerDisplayType.logger) {
    const stack = new ErrorStackItem("[useFilesyncSocket Hook]", message, FilesyncStatusCode.FILESYNC_WEBSOCKET_ERROR, stack_data).info;
    dispatch(uiActions.handleRequestErrors(new Message(message, display_type), stack));
  }
}
