import React, { useState } from "react";
import { MessageItem } from "@preveil-api";
import { Button, Toast, ToastContainer } from "react-bootstrap";
import { MessageToastTypes, isOfBoostrapVariantTypeGuard, GlobalConstants } from "src/common";

type AllProps = {
  message_item: MessageItem;
  onMessageDismiss: () => void;
}

function ToastrComponent(props: AllProps) {
  const { message_item, onMessageDismiss } = props;
  const [show, setShowToastr] = useState(true);
  const message_type = isOfBoostrapVariantTypeGuard(message_item.type) ? message_item.type :
    message_item.type === MessageToastTypes.error ? MessageToastTypes.danger : MessageToastTypes.info;
  function close() {
    setShowToastr(false);
    onMessageDismiss();
  }

  return <ToastContainer position="top-center">
    <Toast
      bg={message_type}
      show={show}
      delay={GlobalConstants.TOASTR_DEFAULT_DELAY}
      autohide
      onClose={close}>
      <Toast.Body>
        {
          !!message_item.title && <strong>{message_item.title}</strong>
        }
        <span dangerouslySetInnerHTML={{ __html: message_item.message }} />
        <Button className="btn-close" aria-label="Close" onClick={close}></Button>
      </Toast.Body>
    </Toast></ToastContainer>;
}

export default React.memo(ToastrComponent);
