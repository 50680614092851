import {
    UUID, Account, AppUserKey, KeyFactory, FSRoleType, InitCollectionData, LogKeyGrantAccessData, COLLECTION_PROTOCOL_VERSIONS,
    FSRolesByCollectionProtocol
} from "src/common";
import { FSRole, FSMessage, FSRequest } from "src/common/keys/protos/collections_pb";
import { EncryptionStyle } from "src/common/keys/encryption";
import { SymmKey } from "src/common/keys";
import _ from "lodash";

export type ActivePermissions = {
    collection_id: UUID;
    permission: Permission;
}

// NOTE: Return only the collection_id as a string and permissions as a flat object
export type PermissionSetType = Pick<PermissionSet, "collection_id" | "permissions">;
// NOTE: Only pass this roles to V2 collections on specific calls (might crash issues in filesync otherwise)
export const CollectionV2AllowedKeys: Array<Partial<FSRoleType>> = [FSRole.OWNER, FSRole.LOG_VIEWER, FSRole.ACCESS];
export class PermissionSet {
    constructor(
        public readonly collection_id: UUID,
        public readonly permissions: Permission[]
    ) { }

    get view_only(): boolean {
        const reader_permission = this.permission(FSRole.READER);
        return reader_permission?.view_only || false;
    }

    get permision_set(): PermissionSetType {
        return {
            collection_id: this.collection_id,
            permissions: this.permissions
        };
    }

    // Description: Build the permissions for the set - returns the latest if version is undefined
    public permission(role: FSRoleType, version?: number): Permission | null {
        const role_permissions = this.permissions.filter(p => p.role === role);

        if (role_permissions.length === 0) {
            return null;
        }

        if (version !== undefined) {
            return role_permissions.find(p => p.key_version === version) || null;
        } else {
            return _.maxBy(role_permissions, "key_version") || null;
        }
    }

    // Description: Quick init form converting GrantSetType to GrantSet
    static init(set_type: PermissionSetType): PermissionSet {
        return new PermissionSet(set_type.collection_id, set_type.permissions);
    }

    // Description: Get the latest permissions from the set
    // https://preveil.atlassian.net/browse/DA-3485
    static latestPermissionByRole(permissions: Permission[]): Permission[] {
        try {
            const latest_permission: Permission[] = [];
            const by_role = _.groupBy(permissions, "role");
            _.map(Object.keys(by_role), (role) => {
                const _permission: Permission[] = by_role[role].slice();
                const max_version = _.maxBy(_permission, "key_version")?.key_version || 0;
                const latest = _permission.find(p => p.key_version === max_version);
                !!latest && latest_permission.push(latest);
            });
            return latest_permission;
        } catch (m) {
            console.error("Error fetchinglatestPermissionByRole: ", permissions.slice(), m);
            // NOTE: Legacy latestPermissions - Description: Get the latest permissions from the set
            const max_version = _.maxBy(permissions, "key_version")?.key_version;
            return _.filter(permissions, p => p.key_version === max_version);
        }
    }

    // Description: Recursively unwrap previous key history versions 
    // 
    static async unwrapKeyHistory(entries: FSMessage.KeyHistoryEntry[],
        role: FSRoleType,
        permission_set: PermissionSet,
        current_account: Account): Promise<PermissionSet> {
        if (entries.length === 0) {
            return permission_set;
        }

        const entry = entries[entries.length - 1];
        const previous_permission = permission_set.permission(role, entry.getVersion());
        if (!previous_permission) {
            throw new Error("no available previous permission");
        }

        const previous_key = await previous_permission.key(current_account);
        const unwrapped_key = await previous_key.encryption_key.unseal(entry.getWrappedLastKey_asU8());
        const key = await KeyFactory.deserializeUserKey(unwrapped_key, EncryptionStyle.DRIVE);
        const permission = new Permission(role, key.key_version, previous_permission.user_key_version, permission_set.view_only, undefined, key);

        // NOTE: Only do this if permission is not there already 
        let new_permissions = permission_set.permissions.slice();
        const index = new_permissions.findIndex((ps: Permission) => (ps.role === permission.role && ps.key_version === permission.key_version));
        if (index >= 0) {
            new_permissions[index] = permission;
        } else {
            new_permissions = [permission, ...new_permissions];
        }
        const new_permission_set = new PermissionSet(permission_set.collection_id, new_permissions);
        return await PermissionSet.unwrapKeyHistory(entries.slice(0, entries.length - 1), role, new_permission_set, current_account);
    }

    // Description: Process Permissions coming from CS to an array of PermissionSet 
    static getActivePermissionSets(permissions: ActivePermissions[]): PermissionSetType[] {
        const collection_permissions = _.groupBy(permissions, p => p.collection_id.String());
        const _sets: PermissionSetType[] = [];
        _.forEach(collection_permissions, (permissions, id) => {
            const collection_id = new UUID({ uuid: id });
            const permission_set = new PermissionSet(collection_id, _.map(permissions, "permission"));
            const reader = permission_set.permission(FSRole.READER);
            const access = permission_set.permission(FSRole.ACCESS);
            // NOTE: only add permissions if there are reader or access permissions
            (!!reader || !!access) && _sets.push(permission_set.permision_set);
        });
        return _sets;
    }

    // Description: Add grand set to the state array  default_grants: GrantSetType[] 
    static updateDefaultPermissionSets(full_permission_set: PermissionSetType, default_permissions: PermissionSetType[]): PermissionSetType[] {
        const new_default_permissions = default_permissions.slice();
        const index = _.findIndex(new_default_permissions, (ps: PermissionSetType) => ps.collection_id.B64() === full_permission_set.collection_id.B64());
        if (index >= 0) { // Replace permission if it exists:
            new_default_permissions[index] = full_permission_set;
        } else {
            new_default_permissions.push(full_permission_set);
        }
        return new_default_permissions;
    }

    // Description: Do not return role when the history is already fetched
    static getValidKeyHistoryRole(permission_set: PermissionSet, role: FSRoleType = FSRole.READER): Permission | null {
        const set = permission_set.permission(role);
        return (!!set && set.key_version > 0 && !permission_set.permission(role, 0)) ? set : null;
    }

    // Description: Build a new set of keys per role and permissions;
    // NOTE: v2 collections only have OWNER, ACCESS, and LOG_VIEWER (default to COLLECTION_PROTOCOL_VERSIONS.V1) 
    static async getNewPermissions(current_account: Account, collection_id: UUID, dir_symm_key?: SymmKey, collection_protocol_version: COLLECTION_PROTOCOL_VERSIONS = COLLECTION_PROTOCOL_VERSIONS.V1):
        Promise<InitCollectionData> {
        const new_permissions: Permission[] = [];
        const keys: FSRequest.Init.Key[] = [];
        // NOTE: Build a permission role array to include per collection_protocol_version
        // Need FSRole.READER for transitioning v1 to v2 (INIT V2 directly) - but do not add to Keys array
        const roles: FSRoleType[] = collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V1 ? FSRolesByCollectionProtocol.V1 : FSRolesByCollectionProtocol.V2;
        for (const role of roles) {
            const user_key = await KeyFactory.newUserKey({ style: EncryptionStyle.DRIVE });
            const wrapped_key = await current_account.user_key.encryption_key.seal(user_key.serialize());
            const init_key = new FSRequest.Init.Key();
            const publicKey = new FSRequest.PublicKey();
            publicKey.setPublicKey(user_key.public_user_key.public_key.public_key);
            publicKey.setVersion(user_key.public_user_key.key_version);
            publicKey.setVerifyKey(user_key.public_user_key.verify_key.public_key);
            publicKey.setVerifyKeyProtocol(3);
            publicKey.setPublicKeyProtocol(3);
            init_key.setRole(role);
            init_key.setPublicKey(publicKey);
            init_key.setWrappedKey(wrapped_key);
            const permission = new Permission(role, user_key.key_version, current_account.user_key.key_version, false, wrapped_key, user_key);
            new_permissions.push(permission);

            // ONLY ADD THE OWNER, ACCESS, and LOG_VIEWER to the KEYS array for V2 Collections
            (collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V1 ||
                (collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V2 && CollectionV2AllowedKeys.includes(role))) &&
                keys.push(init_key);
        };
        // NOTE:return dir_symm_key or new (for sharing and promoting vs Initializing users drive Default Collection - create express accts)
        return {
            keys,
            dir_symm_key: !!dir_symm_key ? dir_symm_key : await KeyFactory.newSymmKey({ style: EncryptionStyle.DRIVE }),
            new_permissions: new PermissionSet(collection_id, new_permissions)
        };
    }

    // Description: Build a LOG VIEWER grant access for admin groups
    static getGroupLogViewerGrantAccess(
        log_viewer: Permission,
        encoded_name: Uint8Array,
        wrapped_key: Uint8Array,
        group_key_version: number,
        signature: Uint8Array,
        admin_group_id: Uint8Array): LogKeyGrantAccessData {
        const role = new FSRequest.Grant.Role();
        role.setRole(log_viewer.role);
        role.setVersion(log_viewer.key_version);

        const role_info = new FSRequest.Grant.RoleInfo();
        role_info.setRole(log_viewer.role);
        role_info.setWrappedKey(wrapped_key);
        role_info.setSignature(signature);

        const group = new FSRequest.Grant.Group();
        group.setGroupId(admin_group_id);
        group.setKeyVersion(group_key_version);
        group.setEncCollectionName(encoded_name);
        group.setRoleInfoList([role_info]);
        return { group, role };
    }

    // Description: Take a set with any role set and convert to v2 collection specific set (only allows: OWNER, ACCESS, LOG_VIEWER)
    static buildV2CollectionPermissionSet(source_set: PermissionSetType) {
        const new_permissions = _.filter(source_set.permissions.slice(), (permission: Permission) => CollectionV2AllowedKeys.includes(permission.role));
        return new PermissionSet(source_set.collection_id, new_permissions);
    }
}


// Permission class
export class Permission {
    private readonly _wrapped_key?: Uint8Array;
    private _key?: AppUserKey;

    constructor(
        public readonly role: FSRoleType,
        public readonly key_version: number,
        public readonly user_key_version: number,
        public readonly view_only?: boolean,
        wrapped_key?: Uint8Array,
        key?: AppUserKey
    ) {
        this._wrapped_key = wrapped_key;
        this._key = key;
    }

    // Description: Returns null if key has not been unwrapped and prepared for use
    get decoded_key(): AppUserKey | null {
        return this._key || null;
    }

    // Description: return AppUserKey  from _wrapped_key (the unseal & deserializeUserKey)
    public async key(account: Account): Promise<AppUserKey> {
        if (!this._key) {
            const user_key = this.user_key_version !== undefined ? account.getUserKeyWithVersion(this.user_key_version) : account.user_key;
            if (!!user_key && !!this._wrapped_key) {
                const unwrapped_key = await user_key.encryption_key.unseal(this._wrapped_key);
                this._key = await KeyFactory.deserializeUserKey(unwrapped_key, EncryptionStyle.DRIVE);
            } else {
                throw new Error("UserKey is not defined");
            }
        }
        return this._key;
    }
}

