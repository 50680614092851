import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

function ScrollbarComponent() {
  // Description: Breadcrumbs
  const Breadcrumbs = () => {
    return (
      <>
        <h4>SCROLLBAR</h4>
        <PerfectScrollbar>
          <div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
            <div>qefwagresthrdjytfkuyiluo</div>
          </div>
        </PerfectScrollbar>
      </>
    );
  };

  return <Breadcrumbs />;
}

export default React.memo(ScrollbarComponent);
