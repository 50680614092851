import { useEffect, useState, useRef, useCallback } from "react";
import { MessageDisplayType, ErrorStackDataType } from "@preveil-api";
import {
  Account, AppConfiguration, useAppSelector, useGetGrants, GrantSet, ActiveGrantSet, LinkEntity, Helpers, DriveEntryType, mapEntryType, DriveErrorMessages, useAppDispatch,
  MessageAnchors, MessageHandlerDisplayType, Message, Directory, useSendRequestMutation, useGetPermissions, CollectionFilesyncAPI, PermissionSet, PermissionSetType,
  buildNavigationLink, PathInfo, getEnumKey, DriveFileType, DriveRequest, ErrorStackItem, useFindLinkIdMutation, FindLinkApiResponse,
  useBrowseLinkMutation, DriveRoot, DirectoryEntity, useGetCollectionInfo, EntryItemBase, DriveRouteType
} from "src/common";
import { FSMessage, FSRole, FSStatus } from "src/common/keys/protos/collections_pb";
import { RootState } from "src/store/configureStore";
import { uiActions, driveActions, DriveCallbackAsyncFunction } from "src/store";
import _ from "lodash";

// Description: This hook renders the parent directory for a node with no true root but one or more effective roots 
// This case happens when the user is the recipient of a shared acl node without a true root parent 
// resulting in the creation of an artificial directory to house the directory(s) with acl
export function useGetACLNodeDirectory(current_account: Account, link?: LinkEntity, set_state: boolean = true) {
  const default_permissions = useAppSelector((state: RootState) => state.drive.default_permissions);
  const default_grants = useAppSelector((state: RootState) => state.drive.default_grants);
  const [active_grants, setActiveGrants] = useState<ActiveGrantSet | null>(null);
  const [path_info, setPathInfo] = useState<PathInfo[]>([]);
  const [entry_base, setEntryBase] = useState<EntryItemBase | undefined>();
  const { grants, getGrants } = useGetGrants(current_account);
  const [error, setError] = useState<boolean>(false);
  const [directory_entity, setDirectoryEntity] = useState<DirectoryEntity>();
  const { permissions, getPermissions, destroyPermissions } = useGetPermissions(current_account);
  const { collection_info_error } = useGetCollectionInfo(current_account, entry_base, true);
  const [browseLink] = useBrowseLinkMutation();
  const [findLink] = useFindLinkIdMutation();
  const [sendRequest] = useSendRequestMutation();
  const dispatch = useAppDispatch();

  // Description: Allow active permissions to be accessed
  const permissionsRef = useRef(permissions);
  function setPermissionsRef(_permissions?: PermissionSetType) {
    permissionsRef.current = _permissions;
  }

  // Description: Allow active grants to be accessed
  const activeGrantsRef = useRef(active_grants);
  function setActiveGrantsRef(_active_grants: ActiveGrantSet) {
    activeGrantsRef.current = _active_grants;
    setActiveGrants(_active_grants);
  }

  // Description: Allow active path Info to be accessed
  const activePathInfoRef = useRef(path_info);
  function setActivePathInfoRef(_path_info: PathInfo[]) {
    activePathInfoRef.current = _path_info;
    setPathInfo(_path_info);
  }

  // Description: Onload sort throught the acl_tree directory and build the entries from the fsmessage.dir[]
  useEffect(() => {

    !!link &&
      getPermissions(link.collection_id, default_permissions);
  }, [link]);

  // Description: Add the missing reader grants to the active grants object 
  useEffect(() => {
    if (!!grants) {
      const _active_grants = Object.assign({}, activeGrantsRef.current);
      _active_grants[grants.id] = grants;
      setActiveGrantsRef(_active_grants);
      activeGrantValid(_active_grants) && buildDirectory(_active_grants);
    }
  }, [grants]);

  useEffect(() => {
    if (!!permissions && !!link) {
      setPermissionsRef(permissions);
      // NOTE: Return the permission state to undefined to be able to detect future chages
      destroyPermissions();
      fetchCollectionAttributes(link);
    }
  }, [permissions]);


  // Description: useEffect hook for when grants (from the useGetGrants hook) is initialized.
  useEffect(() => {
    !!collection_info_error && handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_collection_information }, null, MessageHandlerDisplayType.toastr);
  }, [collection_info_error]);


  // Description: Validate that all grants are fetched
  function activeGrantValid(_active_grants: ActiveGrantSet): boolean {
    if (!!activeGrantsRef.current) {
      const completed_grants = _.omitBy(activeGrantsRef.current, v => v === null);
      return _.values(activeGrantsRef.current).length === _.values(completed_grants).length;
    }
    return false;
  }

  // Description: Get Collection Attributes for the Collection Name
  async function fetchCollectionAttributes(_link: LinkEntity) {
    const drive_request = !!permissions ?
      await CollectionFilesyncAPI.getCollectionAttributes(current_account, permissions, _link.collection_id) : null;
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        const wrapped_name = message.getCollectionAttributes()?.getName()?.getContent_asU8();
        const key_version = message.getCollectionAttributes()?.getName()?.getKeyVersion();
        if (!!permissions && !!wrapped_name) {
          const permission_set = PermissionSet.init(permissions);
          const permission = permission_set.permission(FSRole.READER, key_version) || permission_set.permission(FSRole.ACCESS, key_version);
          const user_key = !!permission ? await permission.key(current_account) : null;
          const decoded = !!user_key ? Helpers.utf8Decode(await user_key.encryption_key.unseal(wrapped_name)) : null;
          !!decoded &&
            setActivePathInfoRef([{
              path: decoded,
              id: _link.id,
              url: buildNavigationLink(_link.collection_id, _link.id, DriveEntryType.DIR)
            }]);
          // NOTE: Initialize the fetch of the entries after grabbing the collection attributes
          AppConfiguration.buildForWeb() ? initFetchGrants(_link) :
            (_link.id === DriveRouteType.DIRECT_LINK ? handleFindLinkId(_link) : (handlefetchLinkApp(_link)));
        } else {
          handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_collection_protocol_version });
        }
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_collection_information });
      }
    }
    handleSendRequest(drive_request, callback, DriveErrorMessages.error_fetching_collection_information);
  }

  // Description: Validate grants for all nodes
  function initFetchGrants(_link: LinkEntity) {
    const collection_id = _link.collection_id;
    const list = _link.acl_tree?.getDirsList();
    if (!!list) {
      const _active_grants: ActiveGrantSet = {};
      _.forEach(list, (dir: FSMessage.Dir) => {
        const key_version = dir.hasMaintainerScopedName() ? dir.getMaintainerScopedName()?.getKeyVersion() : dir.getWrappedDirKey()?.getKeyVersion();
        const maintainer_id = dir.getMaintainerId_asB64();
        const acl_list = dir.getAclList();
        const current_grants = GrantSet.getCurrentUsersGrantSets(collection_id, maintainer_id, acl_list, current_account.user_id);
        const grant = !!current_grants ? GrantSet.getGrantbyRole(current_grants, key_version) : undefined;
        _active_grants[maintainer_id] = (!!current_grants && !!grant) ? current_grants : null;
        !grant && getGrants(maintainer_id, collection_id, default_permissions, default_grants);
      });
      setActiveGrantsRef(_active_grants);
      activeGrantValid(_active_grants) && buildDirectory(_active_grants);
    };
  }

  // Description: Build ACL Node Directory from ACL_TREE > getDirsList() > FSMessage.Dir
  async function buildDirectory(_active_grants: ActiveGrantSet) {
    if (!!link) {
      const collection_id = link.collection_id;
      const list = link.acl_tree?.getDirsList();
      const pseudo_entries = await Promise.all(_.map(list, async (dir: FSMessage.Dir) => {
        const maintainer_id = dir.getMaintainerId_asB64();
        const current_grants = _active_grants[maintainer_id];
        const decoded_name = !!current_grants ? await Directory.getDirectoryMaintainersName(dir, current_account, current_grants) : DriveFileType.Shared_Folder;
        if (!!decoded_name) {
          const entry_info = mapEntryType(DriveEntryType.DIR, decoded_name);
          return {
            id: dir.getId_asB64(),
            collection_id,
            name: decoded_name,
            type: DriveEntryType.DIR,
            size: 0,
            deleted: false,
            deleted_at: 0,
            lastModificationDate: "",
            localSyncStatus: 0,
            type_label: entry_info?.type_label,
            mapped_type: entry_info?.mapped_type,
            type_class: entry_info?.type_class,
            linked_collection_id: ""
          };
        } else {
          const stack_message = DriveErrorMessages.error_decrypting_acl_node_entry.replace(MessageAnchors.node_id, dir.getId_asB64());
          handlePageErrorMessage(DriveErrorMessages.default, { stack_message });
        }
      }));

      // NOTE: removes any undefines from the array
      const entries = _.compact(pseudo_entries);
      const directory = Directory.buildDirFromACLTree(entries, link);
      handleSuccess(directory.item, link);
    }
  }

  // -----------------------------
  // APP - Filesync
  // -----------------------------
  // Description: This is a direct link and call findlinkid before handlefetchLinkApp
  async function handleFindLinkId(_link: LinkEntity) {
    findLink({
      user_id: current_account.user_id,
      collection_id: _link.collection_id
    }).unwrap()
      .then((response: FindLinkApiResponse) => {
        handlefetchLinkApp(Object.assign({}, _link, { id: response.id }));
      })
      .catch((stack_error: unknown) => {
        handlePageErrorMessage(DriveErrorMessages.error_finding_direct_link,
          {
            stack_message: DriveErrorMessages.error_fetching_link,
            stack_error
          }, null, MessageHandlerDisplayType.toastr);
      });
  }

  // Description: Fetch Link from FS to get the Correct LocalSyncStatus for ACL nodes
  async function handlefetchLinkApp(_link: LinkEntity) {
    const link_id = _link.id;
    if (!!_link.linked_collection_id && !!link_id) {
      browseLink({
        user_id: current_account.user_id,
        collection_id: _link.linked_collection_id,
        link_id
      })
        .unwrap()
        .then((response: DriveRoot) => {
          const directory_entity = Directory.parseFSLink(response, { collection_id: _link.collection_id, id: link_id });
          handleSuccess(directory_entity, _link);
        })
        .catch((stack_error: unknown) => {
          handlePageErrorMessage(DriveErrorMessages.default,
            {
              stack_message: DriveErrorMessages.error_fetching_link,
              stack_error
            }, null, MessageHandlerDisplayType.toastr);
        });
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_link }, null, MessageHandlerDisplayType.toastr);
    }
  }

  // Description: Handle success for current_directory and current_collection
  function handleSuccess(directory_entity: DirectoryEntity, _link: LinkEntity) {
    setDirectoryEntity(directory_entity);
    if (set_state) {
    dispatch(driveActions.browseDirectorySuccess(directory_entity));
    dispatch(driveActions.setBreadcrumbsPaths(activePathInfoRef.current));
    dispatch(driveActions.setLinkEntity(undefined));
    // NOTE: get Nodepermissions for lists - Get Collection Info for listview
    setEntryBase({
      linked_collection_id: _link.collection_id,
      collection_id: directory_entity.collection_id,
      id: directory_entity.id,
      type: DriveEntryType.LINK
    });
  }
}

  // Description: Sends Request and handles error when the request object is null, pass it custom stack message
  function handleSendRequest<T>(request: DriveRequest | null, callback: DriveCallbackAsyncFunction<T>, stack_error: string = DriveErrorMessages.error_sending_request) {
    if (!!request) {
      request.setCallback(callback);
      sendRequest(request);
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, { stack_error, stack_message: DriveErrorMessages.error_sending_request }, null);
    }
  }

  // Description: Handle error message and send error to store
  function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, fsmessage?: FSMessage | null, display_type: MessageDisplayType = MessageHandlerDisplayType.logger) {
    const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
    const stack = new ErrorStackItem("[useGetACLNodeDirectory Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
    dispatch(uiActions.handleRequestErrors(new Message(message, display_type), stack));
    setError(true);
  }


  // Description: reset hook 
  const resetACLDirectory = useCallback(() => {
    setError(false);
    setActiveGrants(null);
  }, []);

  return {
    directory_entity,
    error,
    resetACLDirectory
  };
}
