import { useState, useCallback, useRef } from "react";
import { MessageDisplayType, ErrorStackDataType } from "@preveil-api";
import {
  Account, useAppSelector, CollectionFilesyncAPI, DriveRequest, useSendRequestMutation,
  DriveErrorMessages, useAppDispatch, COLLECTION_PROTOCOL_VERSIONS, MessageHandlerDisplayType, Message, useGetPermissions, PermissionSetType,
  getEnumKey, ErrorStackItem
} from "src/common";
import { FSMessage, FSStatus } from "src/common/keys/protos/collections_pb";
import { RootState } from "src/store/configureStore";
import { uiActions } from "src/store";
import _ from "lodash";

// Description: Web hook to fetch the node ID for a link (v1 or v2)
export function useFetchLinkNodeId(current_account: Account) {
  const default_permissions = useAppSelector((state: RootState) => state.drive.default_permissions);
  const { permissions, destroyPermissions } = useGetPermissions(current_account);
  const [link_node_id, setLinkNodeId] = useState<string>();
  const [error, setError] = useState<boolean>(false);
  const [sendRequest] = useSendRequestMutation();
  const dispatch = useAppDispatch();


  // Description: Allow active permissions to be accessed
  const permissionsRef = useRef(permissions);
  function setPermissionsRef(_permissions?: PermissionSetType) {
    permissionsRef.current = _permissions;
  }

  // Description: Get V2 directory node_id through the acl tree request
  async function getACLTree(collection_id: string) {
    const request = await CollectionFilesyncAPI.getACLTreeRequest(collection_id);
    const drive_request = !!permissionsRef.current ? await DriveRequest.initializeRequest(request, current_account, permissionsRef.current) : null;
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        const acl_tree = message.getAclTree();
        const dir_list = acl_tree?.getDirsList();
        const dir = _.find(dir_list, (dir: FSMessage.Dir) => dir.getIsRoot());
        const root_node_id = (!!dir && dir instanceof FSMessage.Dir) ? dir.getId_asB64() : null;
        if (!!root_node_id) {
          setLinkNodeId(root_node_id);
        } else {
          handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_acl_tree });
        };
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_acl_tree }, message);
      }
    }
    if (!!drive_request) {
      drive_request.setCallback(callback);
      sendRequest(drive_request);
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_acl_tree });
    }
  }

  // Description: Get V1 directory node_id through the root info request
  async function getRootInfo(collection_id: string) {
    const request = await CollectionFilesyncAPI.getRootInfoRequest(collection_id);
    const drive_request = !!permissionsRef.current ? await DriveRequest.initializeRequest(request, current_account, permissionsRef.current) : null;
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        const message_root_info = message.getRootInfo();
        if (!!message_root_info) {
          const root_info = {
            collection_id,
            id: message_root_info.getId_asB64()
          };
          setLinkNodeId(root_info.id);
        } else {
          handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_root_info }, message, MessageHandlerDisplayType.toastr);
        }
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_root_info }, message, MessageHandlerDisplayType.toastr);
      }
    }

    if (!!drive_request) {
      drive_request.setCallback(callback);
      sendRequest(drive_request);
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_root_info }, null, MessageHandlerDisplayType.toastr);
    }
  }

  // Description: Handle error message and send error to store
  function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, fsmessage?: FSMessage | null,
    display_type: MessageDisplayType = MessageHandlerDisplayType.logger) {
    const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
    const stack = new ErrorStackItem("[useFetchLinkNodeId Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
    dispatch(uiActions.handleRequestErrors(new Message(message, display_type), stack));
    setError(true);
  }

  // Description: Callback for fetching link node id - takes in linked_collection_id and the protocol version
  const fetchLinkNodeId = useCallback((linked_collection_id: string, protocol_version: COLLECTION_PROTOCOL_VERSIONS) => {
    const permissions = _.find(default_permissions, (set: PermissionSetType) => linked_collection_id === set.collection_id.B64());
    setPermissionsRef(permissions);
    protocol_version === COLLECTION_PROTOCOL_VERSIONS.V2
      ? getACLTree(linked_collection_id)
      : getRootInfo(linked_collection_id);
  }, []);

  // Description: reset hook 
  const resetLinkNodeId = useCallback(() => {
    setError(false);
    setLinkNodeId(undefined);
    destroyPermissions();
  }, []);

  return {
    link_node_id,
    fetchLinkNodeId,
    resetLinkNodeId,
    error
  };
}
