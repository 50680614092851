import * as React from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PrivateRoutes } from "src/common";
import { Icon } from "src/components";

type AllProps = {
  active: boolean;
  active_path?: string;
};

// Description: Support Dropdown
function AdminDropdown(props: AllProps) {
  const { active, active_path } = props;

  return <Dropdown className={`admin nav-item${active ? " active" : ""}`} title="Organization Administration">
    <Dropdown.Toggle as="a">
      <Icon className="ficon-users show-desktop" />
      <span className="hide-desktop">Admin</span>
    </Dropdown.Toggle>
    <Dropdown.Menu align="end">
      <Dropdown.Item
        as={Link}
        title="Organization Users"
        className={`${active_path === PrivateRoutes.users_route ? " active" : ""}`}
        to={`/${PrivateRoutes.admin_route}/${PrivateRoutes.users_route}`} >
        Users
      </Dropdown.Item>
      <Dropdown.Item
        as={Link}
        title="Approval Groups"
        className={`${active_path === PrivateRoutes.approval_groups_route ? " active" : ""}`}
        to={`/${PrivateRoutes.admin_route}/${PrivateRoutes.approval_groups_route}`} >
        Approval Groups
      </Dropdown.Item>
      <Dropdown.Item
        as={Link}
        title="Trusted Community"
        className={`${active_path === PrivateRoutes.trusted_community_route ? " active" : ""}`}
        to={`/${PrivateRoutes.admin_route}/${PrivateRoutes.trusted_community_route}`} >
        Trusted Community
      </Dropdown.Item>
      <Dropdown.Item
        as={Link}
        title="Groups"
        className={`${active_path === PrivateRoutes.groups_route ? " active" : ""}`}
        to={`/${PrivateRoutes.admin_route}/${PrivateRoutes.groups_route}`}>
        Email Groups
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item
        as={Link}
        title="Approval Requests"
        className={`${active_path === PrivateRoutes.requests_route ? " active" : ""}`}
        to={`/${PrivateRoutes.admin_route}/${PrivateRoutes.requests_route}`} >
        Approval Requests
      </Dropdown.Item>
      <Dropdown.Item
        as={Link}
        title="Activity Logs"
        className={`${active_path === PrivateRoutes.activity_logs_route ? " active" : ""}`}
        to={`/${PrivateRoutes.admin_route}/${PrivateRoutes.activity_logs_route}`} >
        Activity Logs
      </Dropdown.Item>
      <Dropdown.Item
        as={Link}
        title="Data Export"
        className={`${active_path === PrivateRoutes.data_export_route ? " active" : ""}`}
        to={`/${PrivateRoutes.admin_route}/${PrivateRoutes.data_export_route}`} >
        Data Export
      </Dropdown.Item>
      <Dropdown.Item
        as={Link}
        title="Email Gateway"
        className={`${active_path === PrivateRoutes.email_gateway_route ? " active" : ""}`}
        to={`/${PrivateRoutes.admin_route}/${PrivateRoutes.email_gateway_route}`} >
        Email Gateway
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item
        as={Link}
        title="Settings"
        className={`${active_path === PrivateRoutes.settings_route ? " active" : ""}`}
        to={`/${PrivateRoutes.admin_route}/${PrivateRoutes.settings_route}`} >
        Settings
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>;

}

export default React.memo(AdminDropdown);
