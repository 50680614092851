import { RegexHelper } from "src/common";
import _ from "lodash";

type EmailGatewayType = "domain" | "individual";

export type GatewayConnectivityStatus = {
  healthy: boolean;
  details: string;
  version: boolean | null | string;
};

export interface EmailGateway {
  id: string;
  display_name?: string;
  email_address: string;
  type: EmailGatewayType;
  aliasFormat: () => { display_name: string; email_address: string; type: EmailGatewayType };
  gatewayAddressFormat: () => { 
    id: string;
    address: string;
    display_name: string;
    type: EmailGatewayType;
  }
}

export class EmailGatewayEntity {
  id: string;
  display_name: string;
  email_address: string;
  type: EmailGatewayType;
  constructor(email_address: string, display_name: string) {
    this.id = _.uniqueId(email_address);
    this.display_name = display_name;
    this.email_address = email_address;
    this.type = RegexHelper.testDomain(email_address) ? "domain" : "individual";
  }

  // Description: Format for when user is ready to submit address/domains.
  // it should only be call when submitting data and not related to the UI.
  public aliasFormat() {
    return {
      display_name: _.isEmpty(this.display_name) ? this.email_address : this.display_name,
      email_address: this.email_address,
      type: this.type
    };
  }

  // Description: Format to display in Individual Form (only for UI purposes).
  public gatewayAddressFormat() {
    return {
      id: this.id,
      address: this.email_address,
      display_name: !_.isEmpty(this.display_name) ? this.display_name : "",
      type: this.type
    };
  }
}