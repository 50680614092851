import * as React from "react";
import { MessageItem } from "@preveil-api";
import { Alert } from "react-bootstrap";
import { MessageToastTypes, isOfBoostrapVariantTypeGuard } from "src/common";

type AllProps = {
  message_item: MessageItem;
  onMessageDismiss: () => void;
}

function InlineMessageComponent(props: AllProps) {
  const { message_item, onMessageDismiss } = props;
  const message_type = isOfBoostrapVariantTypeGuard(message_item.type) ? message_item.type : MessageToastTypes.primary;

  return <Alert variant={message_type} onClose={onMessageDismiss} dismissible >
    <p>{message_item.message}</p>
  </Alert>;
}

export default React.memo(InlineMessageComponent);
