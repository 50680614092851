import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Badge } from "react-bootstrap";
import { MailSearchMatch, UserProfile } from "@preveil-api";
import { MailErrorMessages, prettyUserProfile, PrivateRoutes, isSearchMailbox } from "src/common";
import { Icon, Loading } from "src/components";
// import { mailActions } from "src/store";
import _ from "lodash";

type AllProps = {
  // user_id: string;
  mailResults?: MailSearchMatch[];
  userResults?: UserProfile[];
  searchTerm: string;
  isLoading?: boolean;
  handleAction: (search_mode: boolean, _search_term?: string) => void;
}

function SearchAutocomplete(props: AllProps) {
  const { searchTerm, mailResults, isLoading, userResults, handleAction } = props;
  const navigate = useNavigate();
  const { mailbox } = useParams();
  // Description: Handle click of a search Item
  function handleMailResultClick(item: MailSearchMatch) {
    const url_identifier = encodeURIComponent(item.thread_id); // Helpers.getURLHashFromUID(item.thread_id);
    navigate(`/mail/${encodeURIComponent(item.mailbox_name)}/1/${url_identifier}`);
    handleAction(false);
  };

  function handleViewSearchResults(match?: UserProfile) {
    handleAction(false, !!match && match.uid ? match?.uid : searchTerm);
    (!!mailbox && !isSearchMailbox(mailbox)) && navigate(`/${PrivateRoutes.mail_search_route}`);
  }

  // Description: Renders a mail result
  function MailResult(props: { item: MailSearchMatch }) {
    const best_match = props.item;
    return <li className={`mail-result${best_match.unread ? " unread" : ""}`}
      onClick={() => handleMailResultClick(best_match)}>
      <div>
        {
          !!best_match.sender ? <p className="people" dangerouslySetInnerHTML={{ __html: best_match.sender }} /> :
            <p>{best_match.sender || ""}</p>
        }
        <p dangerouslySetInnerHTML={{ __html: best_match.subject }} />
        <p className="text-muted" dangerouslySetInnerHTML={{ __html: best_match.snippet }} />
      </div>
      <span>
        <span className="date">{best_match.date}</span>
        <Badge pill bg="light">{best_match.mailbox_name}</Badge>
        {
          best_match.has_attachments && <Icon className="ficon-paperclip" />
        }
      </span>
    </li>;
  }

  // Description: Renders a contact result 
  function ContactResult(props: { item: UserProfile }) {
    const match = props.item;
    const email = !!match.external_email ? prettyUserProfile(match) : match.address;
    return <li className="contact" onClick={() => handleViewSearchResults(match)}>
      <Icon className="pv-icon-contact " />
      <span>
        <span dangerouslySetInnerHTML={{ __html: (match.name || "") }} />
        <small dangerouslySetInnerHTML={{ __html: email }} />
      </span>
    </li>;
  }

  // Description: no data li
  function EmptyResult() {
    return <li className="empty">
      {MailErrorMessages.no_search_results_found}
    </li>;
  }

  // Description: Render All results top row
  function renderAllResults(data: MailSearchMatch[]) {
    return <>

      {
        data.length > 1 &&
        <li className="view-all" onClick={() => handleViewSearchResults()}>
          <span className="title">Display <b>{getTotals()}</b> results for: <b>"{searchTerm}"</b></span>
          <small>View all <Icon className="ficon-chevrons-right" /></small>
        </li>
      }
    </>;
  }

  // Description: iterate through the User results and build the user items
  function renderMailResults(data: MailSearchMatch[]) {
    return <>
      {
        _.map(data, (item: MailSearchMatch, i: number) => {
          return <MailResult key={`mail_${i}`} item={item} />;
        })
      }
    </>;
  }
  // Description: iterate through the results and build the mail items
  function renderUserResults(data: UserProfile[]) {
    return <>
      {
        _.map(data, (item: UserProfile, i: number) => {
          return <ContactResult key={`user_${i}`} item={item} />;
        })
      }
      <li className="line"></li>
    </>;
  }

  // Description: Calculate total found - exclude contacts
  // const total = Number(userResults?.length || 0) + Number(mailResults?.length || 0);
  function getTotals(): string {
    return (mailResults?.length || 0).toString() || "all";
  }

  return <div className="search-results">
    <ul className={isLoading ? "processing" : ""}>
      {(!!mailResults && mailResults.length > 0) && renderAllResults(mailResults)}
      {(!!userResults && userResults.length > 0) && renderUserResults(userResults)}
      {(!!mailResults && mailResults.length > 0) ? renderMailResults(mailResults) : <EmptyResult />}
      {
        isLoading &&
        <li>
          <Loading noLogo />
        </li>
      }
    </ul>
  </div>;

}
export default React.memo(SearchAutocomplete);
