/* eslint-disable */
/* tslint:disable */
import { Regex_Patterns, LINK_EXCEPTIONS, QueryParamKeys } from "src/common";

// Needs to be Revisited DO NOT INSTALL  platform.js
export class platform {
  name: string;
  constructor() {
    this.name = this._detectBrowser();
  }
  private _detectBrowser() {
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf("Chrome") != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf("MSIE") != -1) {  //|| (!! == true )) {
      return 'IE';
    } else {
      return 'Unknown';
    }
  }
}

// escapes special regexp special characters from string
// useful for user generated str that are to be matched/searched
// Remove characters that can crash the ui in Regex replace
export function regexEscape(str: string) {
  // return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // LEGACY was missing chars(like dashes)
  return str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

// Descriptiom: Removes the added part of the External link so that it can be properly render in Editor
export function removeExternalLinkFilters(url: string): string {
  let decoded = decodeURIComponent(url);
  console.log(decoded);
  decoded = decoded.substring(decoded.indexOf(QueryParamKeys.EXTERNAL_LINK_PARAM)).replaceAll(new RegExp(QueryParamKeys.EXTERNAL_LINK_PARAM, "gi"), "");
  return decoded;
}

export class RegexHelper {
  static makeLinks(body: string): string {
    const url_regex = /(\<a .*)?((src|href|background)=)?("|')?(https?|ftp|file):\/\/([-a-z0-9\:]{1,}(\.)?){1,}((\/|\?)([-a-zA-Z0-9@:%_\+.~#?&//=\;]){1,})?[a-zA-Z0-9]{0,}(.*\<\/a\>|"|')?/gmi;
    body = body.replace(url_regex, (url) => {
      if (LINK_EXCEPTIONS.map(exception => url.includes(exception)).includes(true)) {
        return url;
      }
      return `<a href="${url}">${url}</a>`;
    });
    return body;
  }

  static filterLinks(body: string): string {
    if (platform.name === "IE" || platform.name === "Microsoft Edge") {
      const is_ie = platform.name === "IE";
      // changed to current window for IE11 because ie won't escape the iframe otherwise
      const target = is_ie ? "_top" : "_blank";
      // change normal links to be opened in new page
      const updated_body = body.replace(/(<a.*?href=["'])(?!mailto\:)(.*?)(["'])(.*?>)/gi, (match, $1, $2, $3, $4) => `${$1}${window.location.origin}/static/link.html?ie=${is_ie}&url=${encodeURIComponent($2)}${$3} target="${target}" rel="noopener" class="external-link" ${$4}`);
      // change mailtos to be open in the current page, only way to make it work so that the page can interpret it
      return updated_body.replace(/(<a.*?href=["'])(mailto\:.*?)(["'])(.*?>)/gi, (match, $1, $2, $3, $4) => `${$1}${window.location.pathname + window.location.search}${QueryParamKeys.EXTERNAL_LINK_PARAM}${encodeURIComponent($2.replace(/\?.*$/, ""))}${$3} target="_top" class="external-link" ${$4}`);
    }
    // with negative lookaheads for PreVeil sites
    return body.replace(/(<a.*?href=["'])(?!\s*?(https:\/\/www\.preveil.com).*?)(.*?)(["'])(.*?>)/gi, (match, $1, $2, $3, $4, $5) => `${$1}${window.location.pathname + window.location.search}${QueryParamKeys.EXTERNAL_LINK_PARAM}${encodeURIComponent($3)}${$4} target="_top" class="external-link" ${$5}`);
  }

  // Description: Sanitize body to remove external link format for replies and forward mail messages
  static removeExternalLinks(body: string): string {
    return body.replace(/(<a.*?href=["'])(?!\s*?(https:\/\/www\.preveil.com).*?)(.*?)(["'])(.*?>)/gi, (match, $1, $2, $3, $4, $5) =>
      `${$1}${removeExternalLinkFilters($3)}${$4} target="_top" class="external-link" ${$5}`);
  }

  static testEmailAddress(email: string): boolean {
    return Regex_Patterns.EMAIL_REGEX.test(email);
  }

  static testDomain(domain: string): boolean {
    return Regex_Patterns.DOMAIN_REGEX.test(domain);
  }

  static addTimestampToFileName(name: string, timestamp: string): string {
    return name.replace(/\.([^/.]*)$/, (ext) => `_${timestamp}${ext}`);
  }

}
