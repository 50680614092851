import React, { ReactNode } from "react";
import { RightSidebar } from "src/components";
type AllProps = {
  children: ReactNode;
}

function DefaultTemplate(props: AllProps) {
  const { children } = props;
  return <>
    <RightSidebar />
    <div className="content-wrapper">{children}</div>
  </>;
}

export default React.memo(DefaultTemplate);
