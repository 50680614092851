import { useEffect, useState, useCallback } from "react";
import { useAppSelector, FilesyncLocalNotification, FilesyncLocalNotificationsMap, AsyncJobType } from "src/common";
import { RootState } from "src/store/configureStore";
import _ from "lodash";

//
export function useFSProcessNotification(notification_type: AsyncJobType, request_watch?: string) {
  const fs_notifications = useAppSelector((state: RootState) => state.drive.fs_notifications);
  const [new_notification, setNewNotification] = useState<FilesyncLocalNotification | undefined>();

  // Description: Update notifications
  useEffect(() => {
    processNotifications(fs_notifications);
  }, [fs_notifications]);

  // Description: Process notifications by type 
  // Check latest version and if the UI is already loading
  function processNotifications(_notification: FilesyncLocalNotificationsMap) {
    if (!!request_watch) {
      const _notification = _.has(fs_notifications, request_watch) ? fs_notifications[request_watch] : null;
      // NOTE: Match the notification type and request_id
      (!!_notification && _notification.type === notification_type) && setNewNotification(_notification);
    }
  }

  // Description: Reset hook for next set
  const reset = useCallback(() => {
    setNewNotification(undefined);
  }, []);

  return {
    new_notification,
    reset
  };
}
