import React from "react";
import { Button, ListGroup } from "react-bootstrap";

function PublicCardsComponent() {
  // Description: Public Cards
  const PublicCards = () => {
    return (
      <>
        <h4>PUBLIC CARDS (using List Groups)</h4>
        <ListGroup className="public-card" horizontal>
          <ListGroup.Item className="image">
            <div className="create-new-account-image"></div>
          </ListGroup.Item>
          <ListGroup.Item className="content">
            <p className="title">Create New Account.</p>
            <p>Use your email address as your PreVeil ID.</p>
            <Button variant="primary">Create Account</Button>
          </ListGroup.Item>
        </ListGroup>
        <ListGroup className="public-card" horizontal>
          <ListGroup.Item className="image">
            <div className="copy-account-image"></div>
          </ListGroup.Item>
          <ListGroup.Item className="content">
            <p className="title">Add Existing Account.</p>
            <p>
              Enable this computer to access an existing PreVeil account.
            </p>
            <Button variant="primary">Add Account</Button>
          </ListGroup.Item>
        </ListGroup>
        <ListGroup className="public-card" horizontal>
          <ListGroup.Item className="image">
            <div className="copy-account-image"></div>
          </ListGroup.Item>
          <ListGroup.Item className="content">
            <p className="title">Copy Private Key</p>
            <p>
              Copy your private key from a computer or phone that already has
              your PreVeil account installed.
            </p>
            <Button variant="primary">Copy from Device</Button>
          </ListGroup.Item>
        </ListGroup>
        <ListGroup className="public-card" horizontal>
          <ListGroup.Item className="image">
            <div className="recovery-group-image"></div>
          </ListGroup.Item>
          <ListGroup.Item className="content">
            <p className="title">Recover Private Key</p>
            <p>
              Your private key will be reconstructed with the authorization of
              your recovery group members.
            </p>
            <Button variant="primary">Recover Key</Button>
          </ListGroup.Item>
        </ListGroup>
        <ListGroup className="public-card" horizontal>
          <ListGroup.Item className="image">
            <div className="copy-account-image"></div>
          </ListGroup.Item>
          <ListGroup.Item className="content">
            <p className="title">Recover using backup file</p>
            <p>Use your PDF file to recover account.</p>
            <Button variant="primary">Recover Account</Button>
          </ListGroup.Item>
        </ListGroup>
        <ListGroup className="public-card" horizontal>
          <ListGroup.Item className="image">
            <div className="upgrade-account-image"></div>
          </ListGroup.Item>
          <ListGroup.Item className="content">
            <p className="title">Upgrade from PreVeil Express</p>
            <p>
              Transfer your current PreVeil Express Account into a full
              featured PreVeil account.
            </p>
            <Button variant="primary">Upgrade Express PreVeil</Button>
          </ListGroup.Item>
        </ListGroup>
        <hr />
      </>
    );
  };

  return <PublicCards />;
};

export default React.memo(PublicCardsComponent);
