import React, { useState } from "react";
import { Badge } from "react-bootstrap";
import { ThreadMessage, JSONRequestBlock, JSONBlock, ThreadAttachment, JSONUrlsResponseBlock, JSONResponseBlock, JSONApiBlockResponse } from "@preveil-api";
import {
  Account, usePostStorageDownloadProxyMutation, useAppDispatch, Helpers, useAppSelector, decryptAttachment, Attachment, Message,
  usePostStorageDownloadDirectMutation, PostStorageDownloadApiResponse, MessageAnchors, FileSizeLimits, DriveSuccessMessages,
  getIDChunks, FetchS3AttachmentBlocks, MessageHandlerDisplayType, MailErrorMessages, useDownloadAttachmentMutation, MessageToastTypes
} from "src/common";
import { appActions, uiActions } from "src/store";
import { Icon } from "src/components";
import { filesize } from "filesize";
import _ from "lodash";

type AllProps = {
  collection_id: string;
  current_account: Account;
  message: ThreadMessage;
  attachment: ThreadAttachment;
  is_web?: boolean;
}

function AttachmentComponent(props: AllProps) {
  const { is_web, attachment, message, current_account, collection_id } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [getMessageBlocksProxy] = usePostStorageDownloadProxyMutation();
  const [getMessageBlocksDirect] = usePostStorageDownloadDirectMutation();
  const [downloadAttachment] = useDownloadAttachmentMutation();
  const useDirectMailFetch = useAppSelector((state) => state.app.mail_direct_fetch);
  const dispatch = useAppDispatch();

  // Description: On click to download file
  function handleDownloadFile() {
    setIsLoading(true);
    if (is_web) {
      (!!collection_id) ?
        handleFetchWebBlocks({ collection_id: message.message_collection_id || collection_id, block_id: attachment.content_reference_id }) :
        handleDownloadErrors(MailErrorMessages.error_loading_attachment);
    } else {
      handleDownloadLocalAttachments();
    }
  }

  // Description: Need to break up the block_id in max of MAIL_MAX_BLOCKS_PER_REQUEST per each request
  function handleFetchWebBlocks(blocks: JSONRequestBlock, retry: boolean = false): void {
    const is_direct = useDirectMailFetch && !retry;
    const block_ids = blocks.block_id.split(",");
    const chunks: string[][] = getIDChunks(block_ids, FileSizeLimits.MAIL_MAX_BLOCKS_PER_REQUEST);
    // NOTE: Iterate all calls and assemble blocks
    Promise.all(_.map(chunks, (chunk: string[]) => {
      const block_chunk: JSONRequestBlock[] = _.map(chunk, (id: string) => ({ collection_id: blocks.collection_id, block_id: id }));
      // NOTE: Choose to call direct first if not allowed call Proxy
      return is_direct ? handleWebGetBlocksDirect(block_chunk) : handleWebGetBlocksProxy(block_chunk);
    }))
      .then((responses: JSONBlock[][]) => {
        const result: JSONBlock[] = _.flatten(responses);
        handleGetBlockDataSuccess(result);
      })
      .catch((error: unknown) => {
        is_direct ? handleDirectDownloadErrors(blocks, error) :
          handleDownloadErrors(MailErrorMessages.error_downloading_attachment.replace(MessageAnchors.file_name, attachment.filename), error);
      });
  }

  // Description: Try to get blocks from S3 directly first - If failed fallback to Proxy call
  async function handleWebGetBlocksDirect(blocks: JSONRequestBlock[]): Promise<JSONBlock[]> { //  Promise<JSONApiBlockResponse[]>
    return await getMessageBlocksDirect({
      account_ids: Account.getAccountIdentifiers(current_account),
      body: { blocks }
    }).unwrap()
      .then(async (data: PostStorageDownloadApiResponse) => {
        return await Promise.all(_.map(data.urls, (block_response: JSONUrlsResponseBlock) => {
          return FetchS3AttachmentBlocks(block_response.block_url, block_response.block_id);
        }));
      });
  }

  // Description: Get Mail through Collection Server as Proxy if Direct call fails
  async function handleWebGetBlocksProxy(blocks: JSONRequestBlock[]): Promise<JSONBlock[]> {
    return await getMessageBlocksProxy({
      account_ids: Account.getAccountIdentifiers(current_account),
      body: { blocks }
    }).unwrap()
      .then((response: JSONApiBlockResponse) => {
        if (!!response.blocks && response.blocks.length > 0) {
          return _.map(response.blocks, (block: JSONResponseBlock) => {
            return {
              block_id: block.block_id,
              data: Helpers.b64Decode(block.data)
            };
          });
        } else { // Note: this will trigger catch block
          throw new Error("Failed to retrieve blocks from Proxy call");
        }
      });
  }

  // Description: handle returned data from either direct call or proxy
  function handleGetBlockDataSuccess(blocks: JSONBlock[]): void {
    decryptAttachment(attachment, blocks)
      .then((decrypted_attachment: Attachment) => {
        if (decrypted_attachment.blob) {
          decrypted_attachment.loadContentFromBlob(decrypted_attachment.blob);
          decrypted_attachment.saveToComputer();
          handleDownloadSuccess();
        } else { // Note: this will trigger catch block
          throw new Error("Failed to decrypted attachment");
        }
      }).catch((error: unknown) => {
        handleDownloadErrors(MailErrorMessages.error_downloading_attachment.replace(MessageAnchors.file_name, attachment.filename), error);
      });
  }

  // App Build Mode
  // ------------------------------------------------------------------
  // Description: Download attachment to the PV Downloads folder
  function handleDownloadLocalAttachments() {
    downloadAttachment({
      user_id: current_account.user_id,
      unique_id: message.unique_id,
      reference_id: attachment.content_reference_id,
      name: attachment.filename
    }).unwrap()
      .then(() => {
        handleDownloadSuccess(attachment.filename);
      })
      .catch((error: unknown) => {
        handleDownloadErrors(MailErrorMessages.error_downloading_attachment.replace(MessageAnchors.file_name, attachment.filename), error);
      });
  }

  // ------------------------------------------------------------------
  // Callback Functions: 
  // ------------------------------------------------------------------
  // Description: After download clean up
  function handleDownloadSuccess(file_name?: string) {
    setIsLoading(false);
    if (!!file_name) {
      const message = DriveSuccessMessages.success_downloading_app;
      const title = DriveSuccessMessages.success_downloading.replace(MessageAnchors.file_name, file_name);
      const confirmation_dialog = new Message(message, MessageHandlerDisplayType.dialog, MessageToastTypes.primary, title, undefined,
        { label: "Close" },
      );
      dispatch(uiActions.handleSetMessage(confirmation_dialog));
    }
  }

  // Description: After download clean up
  function handleDownloadErrors(error_message: string, stack?: unknown, is_toastr: boolean = true) {
    // NOTE: Add file name to error message if anchor in error_message
    const msg = error_message.replace(MessageAnchors.file_name, attachment.filename);
    setIsLoading(false);
    setIsError(true);
    dispatch(uiActions.handleRequestErrors(new Message(msg, is_toastr ? MessageHandlerDisplayType.toastr : MessageHandlerDisplayType.logger), stack));
    // Need to add a timer to reset message and error state
    setTimeout(() => {
      dispatch(uiActions.handleMessageDismiss());
      setIsError(false);
    }, 5000);
  }

  // Description: Handle Downloads directly error, set store value and try Proxy
  //  Handle Direct call Failure - set flag to not use again in this session
  function handleDirectDownloadErrors(blocks: JSONRequestBlock, stack?: unknown) {
    dispatch(appActions.setMailDirectFetch(false));
    const msg = MailErrorMessages.error_downloading_attachment.replace(MessageAnchors.file_name, attachment.filename);
    dispatch(uiActions.handleRequestErrors(new Message(msg, MessageHandlerDisplayType.logger), stack));
    setTimeout(() => (dispatch(uiActions.handleMessageDismiss())), 1000);
    !!blocks && handleFetchWebBlocks(blocks, true);
  };

  return <Badge as="button" pill bg="light" disabled={isLoading} className={isError ? "text-danger" : ""}
    title={`Click to Download ${attachment.filename}`}
    onClick={() => { handleDownloadFile(); }}>
    {
      isLoading ? <i className="spinner"></i> :
        isError ? <Icon className="ficon-alert-triangle text-danger" />
          : <Icon className="ficon-paperclip" />
    }
    {attachment.filename} <span>{filesize(attachment.size).toString()}</span>
  </Badge>;
}

export default React.memo(AttachmentComponent);
