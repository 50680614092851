import React, { useState, useEffect, ReactNode } from "react";
import { Button, Collapse } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { Icon, InfoPopover } from "src/components";
import { DriveUIActionTypes } from "src/common";

type AllProps = {
  title: string;
  hidden?: boolean;
  children?: ReactNode;
  popover?: string;
  refresh?: Function;
  isLoading?: boolean;
  className?: string;
  open: boolean;
  disable_toggle?: boolean
  handleAction: ActionHandlerFunction;
};

function CollapseWrapperComponent(props: AllProps) {
  const { children, title, className, hidden, popover, refresh, isLoading, open, disable_toggle, handleAction } = props;
  const [opened, setOpen] = useState<boolean>(open);

  // Description: Change collapsable state on action changes from within detail panel
  useEffect(() => {
    !opened && setOpen(open);
  }, [open]);

  // Description: toggle
  function toggle() {
    setOpen(!opened);
    opened && handleAction({ actionType: DriveUIActionTypes.ResetAction });
  }

  return !hidden ?
    <div className={`collapse-section${!opened ? " closed" : ""} ${!!className ? className : ""}`}>
      <h5>
        {title}
        {!!popover ? <InfoPopover message={popover} /> : null}
        <span className="ms-auto">
          {
            !!refresh &&
            <Button
              variant="icon"
              size="sm"
              onClick={() => refresh()}
              title="Refresh">
              {isLoading ? <i className="spinner"></i> : <Icon className="ficon-rotate-cw" />}
            </Button>
          }
          {
            !disable_toggle &&
            <Button
              onClick={toggle}
              tabIndex={-1}
              className="toggle-btn"
              variant="icon">
              {!!opened ? (
                <Icon className="ficon-chevron-up m-0" />
              ) : (
                <Icon className="ficon-chevron-down m-0" />
              )}
            </Button>
          }
        </span>
      </h5>
      <Collapse in={opened}>
        <div>{children}</div>
      </Collapse>
    </div> : <></>;
}

export default CollapseWrapperComponent;
