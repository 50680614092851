import { useCallback, useState } from "react";
import { IFetchResponse, VersionedEmailBlock, MailMessageResponse, MailboxItem, MailMessageV4, MessageSendMetadata } from "@preveil-api";
import {
  Account, WebMailData, FileSizeLimits, MessageV4, Helpers, getMailboxCollectionIdByRole, DefaultMailboxes, MailFlags,
  usePostStorageByCollectionIdBlocksMutation, usePostMailByCollectionIdMailboxesAndMbidMessagesMutation, PostStorageByCollectionIdBlocksApiResponse,
  usePatchMailByCollectionIdMailboxesAndMbidMessagesMutation
} from "src/common";
import _ from "lodash";

export function useSaveDraft(current_account: Account, default_mailboxes?: MailboxItem[]) {
  const [errors, setErrors] = useState<IFetchResponse>();
  const [updateFlags] = usePatchMailByCollectionIdMailboxesAndMbidMessagesMutation(); // FOR ALL MAIL VERSIONS
  const [postBlocks] = usePostStorageByCollectionIdBlocksMutation();
  const [appendToMailbox, { isLoading, data }] = usePostMailByCollectionIdMailboxesAndMbidMessagesMutation();

  // Description: Send / Post data Blocks to storage first
  async function handleSaveDraft(_draft_data: WebMailData, _send_metadata: MessageSendMetadata) {
    const results = await handleAllBlocks(_draft_data.blocks, _draft_data.metadata as MessageV4);
    // Examine for errors if none then update flags
    (!!results.error) ? handleSaveDraftError(results.error as IFetchResponse) : handleUpdateFlags(_send_metadata);
  }

  // Description: Iterate through all WebMailData and breakdown blocks at FileSizeLimits.MAIL_MAX_BLOCKS_PER_REQUEST
  // On successful putBlocks send mail by protocol version
  async function handleAllBlocks(blocks: VersionedEmailBlock[], metadata: MessageV4):
    Promise<{ result?: MailMessageResponse; error?: unknown }> {
    const blocks_per_request = FileSizeLimits.MAIL_MAX_BLOCKS_PER_UPLOAD;
    const chunks =
      [...Array(Math.ceil(blocks.length / blocks_per_request)).keys()]
        .map(i => blocks.slice(i * blocks_per_request, Number((i * blocks_per_request) + blocks_per_request)));
    // NOTE: Iterate all calls and assemble blocks
    const collection_id = metadata.recipient.mail_cid;
    return await Promise.all(_.map(chunks, (block_chunk: VersionedEmailBlock[]) => {
      return handlePostBlocks(block_chunk, collection_id);
    }))
      .then((responses: PostStorageByCollectionIdBlocksApiResponse[]) => {
        return handleV4AppendToMailbox(metadata.message)
          .then((result: MailMessageResponse) => ({ result }));
      })
      .catch((error: IFetchResponse) => {
        return { error };
      });
  };

  // Description: Handle Posting block chunks
  async function handlePostBlocks(_blocks: VersionedEmailBlock[], collectionId: string): Promise<PostStorageByCollectionIdBlocksApiResponse> {
    const blocks = _blocks.map(b => (Object.assign({}, b, { data: Helpers.b64Encode(b.data) })));
    return await postBlocks({
      account_ids: Account.getAccountIdentifiers(current_account),
      body: {
        collectionId,
        blocks
      }
    }).unwrap()
      .then((response: PostStorageByCollectionIdBlocksApiResponse) => {
        return response;
      });
  }

  // Description: On V4 Mail Send Success need to call this one with the same message object 
  // NOTE:  For every message a user sends should put a copy of that message in the sender’s sent mailbox. 
  //        for V4 needs to be explicitly done.
  async function handleV4AppendToMailbox(message: MailMessageV4) {
    let result = null;
    const draft_mailbox_id = !!default_mailboxes ? getMailboxCollectionIdByRole(DefaultMailboxes.drafts, default_mailboxes) : null;
    if (!!draft_mailbox_id) {
      result = await appendToMailbox({
        account_ids: Account.getAccountIdentifiers(current_account),
        body: {
          collectionId: current_account.mail_cid,
          mbid: draft_mailbox_id,  // This needs to be the draft mailbox
          message
        }
      }).unwrap()
        .then((response: MailMessageResponse) => {
          return response;
        });
      return result;
    } else {
      throw new Error("Failed sending message");
    }
  }

  // Description: On Success Update flags 
  function handleUpdateFlags(send_metadata: MessageSendMetadata) {
    const draft_mailbox_id = !!default_mailboxes ? getMailboxCollectionIdByRole(DefaultMailboxes.drafts, default_mailboxes) : null;
    if (!!draft_mailbox_id && !!send_metadata?.draft_id && !!send_metadata?.draft_version) {
      updateFlags({
        account_ids: Account.getAccountIdentifiers(current_account),
        body: {
          collectionId: current_account.mail_cid,
          mbid: draft_mailbox_id,
          updates: [{
            id: send_metadata.draft_id,
            last_version: send_metadata.draft_version,
            flags: [MailFlags.DELETED_FLAG, MailFlags.SEEN_FLAG, MailFlags.DRAFT_FLAG]
          }]
        }
      }).unwrap()
        .catch((error: IFetchResponse) => {
          handleSaveDraftError(error);
        });
    }
  }

  // Description: Handle all errors
  function handleSaveDraftError(_error: IFetchResponse) {
    console.error("handleSaveDraftError", _error);
    setErrors(_error);
  }

  // Description: On webData receive save draft and send the blocks up to storage
  const saveDraftData = useCallback((draft_data?: WebMailData, send_metadata?: MessageSendMetadata) => {
    !!draft_data && !!send_metadata && handleSaveDraft(draft_data, send_metadata);
  }, []);

  return {
    data,
    isLoading,
    errors,
    saveDraftData
  };
}