import React, { useState, useEffect } from "react";
import { Button, Toast } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CollectionServerUser } from "@preveil-api";
import { useAppDispatch, usePostUsersFindMutation, useDeleteUsersMutation, Account, Message, MessageHandlerDisplayType, DeleteAccountMessages, PublicRoutes } from "src/common";
import { uiActions } from "src/store";

type AllProps = {
  current_account: Account;
}

function DeleteAccountComponent(props: AllProps) {
  const { current_account } = props;
  const [fetched_account, setFetchedAccount] = useState<CollectionServerUser>();
  const [findUsers] = usePostUsersFindMutation();
  const [deleteUser] = useDeleteUsersMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    handleCanDelete();
  }, []);

  function handleCanDelete() {
    findUsers({
      account_ids: Account.getAccountIdentifiers(current_account),
      body: { spec: [{ user_id: current_account.user_id, key_version: -1 }] }
    }).unwrap()
      .then(({ users }) => {
        setFetchedAccount(users[0]);
      })
      .catch((stack_error) => {
        dispatch(uiActions.handleRequestErrors(new Message(DeleteAccountMessages.error_deleting_message), { stack_error }));
      });
  }

  function confirmDelete() {
    const confirmation_dialog = new Message(
      DeleteAccountMessages.confirm_delete_message,
      MessageHandlerDisplayType.confirm,
      undefined,
      DeleteAccountMessages.confirm_delete_title,
      {
        label: "Yes",
        data: true,
        action: deleteAccount
      },
      { label: "No" }
    );
    dispatch(uiActions.handleSetMessage(confirmation_dialog));
  }

  function deleteAccount() {
    deleteUser({
      account_ids: Account.getAccountIdentifiers(current_account),
      body: { user_id: current_account.user_id }
    }).unwrap()
      .then(() => {
        navigate(`/${PublicRoutes.logout_route}`);
      })
      .catch((stack_error) => {
        dispatch(uiActions.handleRequestErrors(new Message(DeleteAccountMessages.error_deleting_message), { stack_error }));
      });
  }

  return !!fetched_account ?
    <div className="delete-account">
      <h2>Delete Account</h2>
      <div className="delete-body">
        {
          !!fetched_account.entity_id ?
            <Toast bg="warning" className="d-inline-block mb-3 mt-2">
              <Toast.Body>
                {DeleteAccountMessages.org_user_warning}
              </Toast.Body>
            </Toast> :
            <>
              <h5>Deleting this account will remove your files.</h5>
              <Button variant="outline-primary" onClick={confirmDelete}>Delete this Account</Button>
            </>
        }
      </div>
    </div> : <></>;
}

export default React.memo(DeleteAccountComponent);
