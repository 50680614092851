import * as React from "react";
import { Card, Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { AccountUIActionTypes } from "src/common";
import img from "src/assets/images/copy_account.svg";

type AllProps = {
  handleAction: ActionHandlerFunction;
}

function DefaultPanelComponent(props: AllProps) {
  const { handleAction } = props;

  return <>
    <Card.Img variant="top" src={img} />
    <Card.Body>
      <Card.Title>
        To access your PreVeil account on another computer or phone, you need to install PreVeil software and your private key onto that device.
      </Card.Title>
      <Card.Text>
        Once your other device has PreVeil installed, click ‘continue’ to securely copy your private key from this computer
      </Card.Text>
      <Button variant="primary" onClick={() => handleAction({ actionType: AccountUIActionTypes.InitWebsocketConnection })}>Continue</Button>
    </Card.Body>
  </>;
}
export default React.memo(DefaultPanelComponent);
