import { Variant } from "react-bootstrap/types";
import { MessageItem, MessageDisplayType, MessageActions } from "@preveil-api";
import { MessageToastTypes, MessageHandlerDisplayType } from "src/common";

export class Message implements MessageItem {
  message: string = "";
  displayType;
  type: Variant;
  title?: string;
  confirm?: MessageActions;
  dismiss?: MessageActions;
  constructor(
    message: string,
    displayType?: MessageDisplayType,
    type?: Variant,
    title?: string,
    confirm?: MessageActions,
    dismiss?: MessageActions,
  ) {
    this.message = message;
    this.displayType = displayType || MessageHandlerDisplayType.toastr;
    this.type = type || MessageToastTypes.success;
    this.title = title;
    this.confirm = confirm;
    this.dismiss = dismiss;
  }

  // Description: Return MessageItem type
  get messageItem(): MessageItem {
    return {
      message: this.message,
      type: this.type,
      displayType: this.displayType,
      title: this.title,
      confirm: this.confirm,
      dismiss: this.dismiss
    };
  }

  public set_type(_type: Variant) {
    this.type = _type;
  }
}
