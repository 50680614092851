import React, { useEffect } from "react";
import { Account, AppConfiguration, useAppDispatch, useAppSelector, useBulkDownloadFiles } from "src/common";
import { uiActions } from "src/store";
import { DownloadProgress } from "../drive/drive-manager";
import { RootState } from "src/store/configureStore";


function DownloadManagerComponent(props: { current_account: Account }) {
  const { current_account } = props;
  const download_request = useAppSelector((state: RootState) => state.drive.download_request);
  const upload_request = useAppSelector((state: RootState) => state.drive.upload_request);
  const download_progress_hidden = useAppSelector((state: RootState) => state.ui.download_progress_hidden);
  const is_app = AppConfiguration.buildForApp();
  const { downloadFiles, progress } = useBulkDownloadFiles(current_account, is_app);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!!download_request) {
      downloadFiles(download_request);
    }
    // Description: Set Dirty Warning based on upload_progress_hidden
    dispatch(uiActions.handleSetDirtyMessage(!!download_request || !!upload_request));
  }, [download_request]);

  return <>
    {
      !download_progress_hidden &&
      <DownloadProgress progress={progress} />
    }
  </>;
}

export default React.memo(DownloadManagerComponent);
