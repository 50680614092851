import React, { useRef } from "react";
import { Form, Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { LoginWebUIActionTypes, GlobalConstants } from "src/common";
import { VerificationCode } from "src/components";

type AllProps = {
  handleAction: ActionHandlerFunction;
  forgot_password?: boolean;
}

function LoginTOTPCodeVerificationFormComponent(props: AllProps) {
  const { handleAction, forgot_password } = props;
  const buttonRef = useRef<HTMLButtonElement>(null);
  let secret = "";
  // Description: Handle form submit
  function handleSubmit(e: React.FocusEvent<HTMLFormElement>) {
    e.stopPropagation();
    e.preventDefault();
    const params = secret;
    handleAction({ actionType: LoginWebUIActionTypes.SubmitTOTPVerificationCode, params });
  }

  // Description: Validate and set secret code on finished
  function handleSetSecretCode(code: string) {
    secret = code;
    const button = buttonRef.current;
    const isValid = code.length === GlobalConstants.CREATE_ACCOUNT_VERIFICATION_CODE_MAX;
    if (!!button) {
      button.disabled = !isValid;
      isValid && setTimeout(() => button.focus());
    }
  }

  // Description: Render the enter code form
  function VerificationForm() {
    return <>
      <p>Open your Time-based One-Time Password (TOTP) authenticator application and enter the verification code below.</p>
      <Form onSubmit={handleSubmit} noValidate>
        <Form.Group className="mb-3">
          <Form.Label>TOTP Verification Code</Form.Label>
          <VerificationCode count={GlobalConstants.CREATE_ACCOUNT_VERIFICATION_CODE_MAX} handleAction={handleSetSecretCode} />
        </Form.Group>
        <div className="btn-panel">
          <Button type="submit" ref={buttonRef} disabled={true}>{forgot_password ? "Continue" : "Sign in"}</Button>
          <Button variant="outline-secondary" type="button"
            onClick={() => handleAction({ actionType: LoginWebUIActionTypes.ResetLogin })}>Cancel</Button>
        </div>
      </Form>
    </>;
  }

  return <VerificationForm />;
}

export default React.memo(LoginTOTPCodeVerificationFormComponent);
