import { useState, useCallback, useEffect, useRef } from "react";
import { MessageDisplayType, ErrorStackDataType } from "@preveil-api";
import { randomBytes } from "pvcryptojs";
import { FSMessage, FSStatus, FSWrappedKey } from "src/common/keys/protos/collections_pb";
import {
  Account, useAppDispatch, Message, MessageHandlerDisplayType, useSendRequestMutation, CollectionFilesyncAPI, DriveErrorMessages, useGetGrants,
  useAppSelector, DriveRequest, UUID, PermissionSetType, ErrorStackItem, getEnumKey, useGetPermissions, GrantSetType, encryptFileName,
  getDirSymmKey, Helpers, GrantSet, DirectoryEntity
} from "src/common";
import { DriveCallbackAsyncFunction, uiActions } from "src/store";
import { RootState } from "src/store/configureStore";
import _ from "lodash";

/* Description: This is the web hook for duplicating a file. */
export function useDuplicateFile(current_account: Account) {
  const default_permissions = useAppSelector((state: RootState) => state.drive.default_permissions);
  const default_grants = useAppSelector((state: RootState) => state.drive.default_grants);
  const current_directory = useAppSelector((state: RootState) => state.drive.current_directory);
  const [error, setError] = useState<boolean>(false);
  const [duplicated_file, setDuplicatedFile] = useState<string>();
  const [collection_id, setCollectionId] = useState<string>();
  const [file_id, setFileId] = useState<string>();
  const [original_file_name, setOriginalFileName] = useState<string>();
  const [sendRequest] = useSendRequestMutation();
  const { permissions, getPermissions, destroyPermissions } = useGetPermissions(current_account);
  const { grants, getGrants } = useGetGrants(current_account);
  const dispatch = useAppDispatch();

  const permissions_ref = useRef(permissions);
  function setPermissionsRef(_permissions?: PermissionSetType) {
    permissions_ref.current = _permissions;
  }

  const wrapped_key_ref = useRef<FSWrappedKey>();

  useEffect(() => {
    if (!!permissions && !!current_directory) {
      setPermissionsRef(permissions);
      // NOTE: Return the permission state to undefined to be able to detect future chages
      destroyPermissions();
      !!collection_id && !!file_id && !!permissions_ref.current && !!original_file_name && handleFetchDirectory(collection_id, current_directory.id, file_id, permissions_ref.current, original_file_name);
    };
  }, [permissions, current_directory]);

  // Description: useEffect hook for when grants (from the useGetGrants hook) is initialized.
  useEffect(() => {
    if (!!grants && !!permissions_ref.current && !!wrapped_key_ref.current && !!collection_id && !!file_id && !!original_file_name) {
      handleFetchFile(collection_id, file_id, permissions_ref.current, wrapped_key_ref.current, original_file_name, grants);
    }
  }, [grants]);

  // Description: Fetch Directory Information of the current directory (since the user is in the current directory when choosing to duplicate a file)
  async function handleFetchDirectory(_collection_id: string, _directory_id: string, _file_id: string, _permissions: PermissionSetType, file_name: string) {
    const request = await CollectionFilesyncAPI.getDirectoryInformationRequest(_collection_id, _directory_id);
    const drive_request = await DriveRequest.initializeRequest(request, current_account, _permissions);
    handleSendRequest(drive_request, callback, DriveErrorMessages.undefined_current_directory);
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        const dir = message.getDir();
        if (!!dir) {
          const wrapped_key = dir.getWrappedDirKey();
          if (!!wrapped_key) {
            wrapped_key_ref.current = wrapped_key;
            const acl_list = dir.getAclList();
            if (acl_list.length > 0) { // acl node
              const grant_sets = GrantSet.getActiveGrantSets(_collection_id, dir.getId_asB64(), acl_list);
              const current_grants = !!grant_sets ? GrantSet.getActive(grant_sets, _collection_id, dir.getId_asB64(), current_account.user_id) : undefined;
              handleFetchFile(_collection_id, _file_id, _permissions, wrapped_key, file_name, current_grants);
            } else if (dir.hasMaintainerId()) { // under acl node or v2 collection
              getGrants(dir.getMaintainerId_asB64(), _collection_id, default_permissions, default_grants);
            } else { // in my preveil or v1 collection
              handleFetchFile(_collection_id, _file_id, _permissions, wrapped_key, file_name);
            }
          } else {
            handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.undefined_wrapped_key }, message);
          }
        }
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_directory }, message);
      }
    }
  }

  // Description: fetch file that the user is trying to duplicate to get the file ids.
  async function handleFetchFile(_collection_id: string, _file_id: string, permissions: PermissionSetType, wrapped_key: FSWrappedKey, file_name: string, grants?: GrantSetType) {
    const request = !!permissions ? await CollectionFilesyncAPI.fetchFile(current_account, _collection_id, _file_id, permissions) : null;
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        const file = message.getFile();
        if (!!file) {
          const block_ids = _.compact(_.map(file.getDataList(), (data) => {
            const _id = data.getId();
            return !!_id ? Helpers.utf8Encode(_id) : undefined;
          }));
          !!file_name && handleCreateDuplicatedFile(_collection_id, permissions, block_ids, wrapped_key, file_name, grants);
        } else {
          handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_file }, message);
        }
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_file }, message);
      }
    }
    handleSendRequest(request, callback);
  }

  // Description: Duplicates file with backend using the CREATE api method.
  async function handleCreateDuplicatedFile(_collection_id: string, permissions: PermissionSetType, _block_ids: Uint8Array[], wrapped_key: FSWrappedKey, file_name: string, grants?: GrantSetType) {
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        setDuplicatedFile(duplicated_name);
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_creating_file }, message);
      }
    }
    const new_id = new UUID().Bytes();
    const grant = !!grants ? GrantSet.getGrantbyRole(grants, wrapped_key.getKeyVersion()) : undefined;
    const reader_user_key = !!grant ? await grant.key(current_account) : undefined;
    const symm_key = await getDirSymmKey(wrapped_key, current_account, permissions, reader_user_key);
    const new_version = randomBytes(32);
    const duplicated_name = !!current_directory ? createNewName(file_name, current_directory, 1) : undefined;
    const encrypted_new_name = !!symm_key && !!duplicated_name ? await encryptFileName(duplicated_name, symm_key) : undefined;
    if (!!duplicated_name && !!encrypted_new_name) {
      const parent_id = !!current_directory ? current_directory.id : "";
      const parent_version = !!current_directory ? current_directory.version : "";
      const request = !!permissions ? await CollectionFilesyncAPI.createFile(current_account, permissions, _collection_id, parent_id, parent_version, new_id, new_version, encrypted_new_name, wrapped_key, _block_ids, grants) : null;
      handleSendRequest(request, callback);
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_creating_file });
    }
  }

  // Description: Creates a new name for the duplicated file : <file_name> copy i.<extension> where i is dependant on if they have an existing file with the same name or not.
  function createNewName(file_name: string, current_directory: DirectoryEntity, i: number): string {
    const period = file_name.lastIndexOf(".");
    const file = file_name.substring(0, period);
    const extension = file_name.substring(period + 1);
    const test = file.match(/^(.*) copy (\d+)$/);
    let new_name: string;
    if (!!test) {
      const _name = test[1];
      const number = parseInt(test[2]);
      new_name = `${_name} copy ${(number + i).toString()}.${extension}`;
    } else {
      new_name = `${file} copy ${i.toString()}.${extension}`;
    }
    if (current_directory.entries.find((entry) => entry.name === new_name)) {
      return createNewName(file_name, current_directory, i + 1);
    } else {
      return new_name;
    }
  }

  // Description: handles error when the request object is null
  function handleSendRequest<T>(request: DriveRequest | null, callback: DriveCallbackAsyncFunction<T>, stack_error: string = DriveErrorMessages.error_sending_request) {
    if (!!request) {
      request.setCallback(callback);
      sendRequest(request);
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, { stack_error, stack_message: DriveErrorMessages.error_sending_request });
    }
  }

  // Description: Logs Errors and sets Error to true
  function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, fsmessage?: FSMessage | null, display_type: MessageDisplayType = MessageHandlerDisplayType.toastr) {
    const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
    const stack = new ErrorStackItem("[useDuplicateFile Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
    dispatch(uiActions.handleRequestErrors(new Message(message, display_type), stack));
    setError(true);
  }

  // Description: reset hook 
  const resetDuplicate = useCallback(() => {
    setError(false);
    setDuplicatedFile(undefined);
    wrapped_key_ref.current = undefined;
    permissions_ref.current = undefined;
    destroyPermissions();
  }, []);

  // Description: Callback for duplicating a file
  const duplicateFile = useCallback((_collection_id: string, _file_id: string, file_name: string) => {
    setOriginalFileName(file_name);
    setCollectionId(_collection_id);
    setFileId(_file_id);
    getPermissions(_collection_id, default_permissions);
  }, []);

  return {
    duplicated_file,
    error,
    duplicateFile,
    resetDuplicate
  };
}
