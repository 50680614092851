import { ActionHandlerFunction } from "@preveil-api";
import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Account, buildNavigationLink, DriveEntryType, DriveSharedInfo, DriveUIActionTypes, useAppSelector } from "src/common";
import { Icon } from "src/components";
import { RootState } from "src/store/configureStore";

type AllProps = {
  entry: DriveSharedInfo;
  handleAction: ActionHandlerFunction;
  relinking: boolean;
  current_account: Account;
};

function SharedRowComponent(props: AllProps) {
  const { entry, handleAction, relinking } = props;
  const root_info = useAppSelector((state: RootState) => state.drive.root_info);
  const navigate = useNavigate();

  function handleNameClick() {
    if (entry.linked && !!root_info) {
      const url = buildNavigationLink(root_info.collection_id, entry.link_id, DriveEntryType.LINK);
      navigate(url);
    }
  }

  return <Row className={!entry.linked ? "hidden" : ""} onClick={handleNameClick}>
    <Col md="5" className="entry-name">
      <span>
        <Icon className="fsi-shared" /> {entry.name}
      </span>
    </Col>
    <Col md="4">{entry.status}</Col>
    <Col md="auto" className="btn-panel">
      {
        !entry.linked &&
        <>
          {
            entry.processing && relinking ? <Icon className="spinner me-2" /> :
              <Button variant="outline-secondary" size="sm"
                className="me-2"
                onClick={() => handleAction({ actionType: DriveUIActionTypes.AddFolder, params: { folder: entry, id: entry.id, name: entry.name } })}>
                Show
              </Button>
          }
        </>
      }
    </Col>
  </Row>;
}

export default React.memo(SharedRowComponent);
