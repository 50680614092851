import React from "react";
import { DataExportRequestStatus } from "src/common";
import { Icon } from "src/components";

type AllProps = {
  status: keyof typeof DataExportRequestStatus;
}

function DataExportRequestProgress({ status }: AllProps) {
  const approvalStep = status === "init" || status === "complete" || status === "running" || status === "started";
  return (
    <div className="export-multistep-progress">
      <div className="export-single-step">
        {DataExportRequestStatus[status] ? <Icon className="icon-checkmark" /> : <span className="request-step-number">1</span>}
        <p className={`${status === "init" || status === "pending" || status === "complete" ? "step-complete-color" : ""}`}>Request</p>
      </div>
      <div className="export-single-step">
        <hr className={`${status === "complete" ? "complete" : ""}`}/>
        {approvalStep ? <Icon className="icon-checkmark" /> : <span className="request-step-number">2</span>}
        <p className={`${status === "init" || status === "complete" ? "step-complete-color" : ""}`}>Approval</p>
        <hr className={`${status === "complete" ? "complete" : ""}`}/>
      </div>
      <div className="export-single-step">
        {status === "complete" ? <Icon className="icon-checkmark" /> : <span className="request-step-number">3</span>}
        <p className={`${status === "complete" ? "step-complete-color" : ""}`}>Export</p>
      </div>
    </div>
  );
}

export default React.memo(DataExportRequestProgress);