import { KSSData, UserKey as UserKeyPB } from "../keys/protos/key_storage_pb";
import { FipsCrypto, PVCryptoUsage } from "pvcryptojs";
import { KSSUserBase } from "@preveil-api";
import { Helpers, KeyFactory, AccountIdentifiers, AppUserKey } from "src/common";

export class KeyStorageData {
  constructor(
    public protocol_version: number,
    public user_key: UserKeyPB,
    public device_key: UserKeyPB,
    public device_id: string
  ) { }

  public get buffer(): KSSData {
    const pbInst = new KSSData();
    pbInst.setProtocolVersion(this.protocol_version);
    pbInst.setUserKey(this.user_key);
    pbInst.setDeviceKey(this.device_key);
    pbInst.setDeviceId(this.device_id);
    return pbInst;
  }
  public serialize(): Uint8Array {
    return this.buffer.serializeBinary();
  }

  public async encrypt(passphrase: string, serialized_data: Uint8Array): Promise<Uint8Array> {
    return await FipsCrypto.encryptWithPassphrase(passphrase, serialized_data);
  }

  static deserialize(blob: Uint8Array): KSSData {
    return KSSData.deserializeBinary(blob);
  }

  static async decrypt(passphrase: string, serialized_data: Uint8Array): Promise<Uint8Array> {
    return await FipsCrypto.decryptWithPassphrase(passphrase, serialized_data);
  }

  static async publicKeyFromPrivate(private_key: Uint8Array): Promise<Uint8Array> {
    return await FipsCrypto.publicKeyFromPrivate(private_key, PVCryptoUsage.ENCRYPT);
  }

  static async authenticationToken(user_id: string, password: string): Promise<Uint8Array> {
    const salt = new TextEncoder().encode(`PreVeilKSSAuth:${user_id}`);
    return await FipsCrypto.keyForPassphrase(password, salt);
  }

  // Description: Wrap data for KSS server
  static async wrapKSSData(password: string, user_key: AppUserKey, device_key: AppUserKey, protocol_version: number, device_id: string): Promise<string> {
    const _wrapped_data = new KeyStorageData(protocol_version, user_key.buffer, device_key.buffer, device_id);
    const serialized = _wrapped_data.serialize();
    return Helpers.b64Encode(await _wrapped_data.encrypt(password, serialized));
  }

  // Description: Get Keys from KSS Response - Unwrap KSS wrapped_data AND GET return KSSUnwrappedData;
  // Removed protocol_version: number = current_protocol_version
  static async unwrapKSSData(kss_user: KSSUserBase, wrapped_data: string):
    Promise<AccountIdentifiers> {
    const serialized_kss_data = await KeyStorageData.decrypt(kss_user.password, Helpers.b64Decode(wrapped_data));
    const kss_data = KeyStorageData.deserialize(serialized_kss_data);
    const _uk = kss_data.getUserKey();
    const _dk = kss_data.getDeviceKey();
    const device_id = kss_data.getDeviceId();
    if (!!_uk && !!_dk && device_id) {
      const user_key = await KeyFactory.deserializeUserKeyObj(_uk);
      const device_key = await KeyFactory.deserializeUserKeyObj(_dk);
      const user_key_serialized = Helpers.b64Encode(_uk.serializeBinary());
      const device_key_serialized = Helpers.b64Encode(_dk.serializeBinary());
      return {
        user_id: kss_user.user_id,
        user_key,
        user_key_serialized,
        device_key,
        device_id,
        device_key_serialized
      };
    } else {
      // HANDLE ERROR 
      throw new Error("Failed to get Device Id, User and/or Device Keys");
    }
  }
}
