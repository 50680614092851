import * as React from "react";
import { Row, Col, Alert, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AdminApprovalGroupRoleMapping, AdminApprovalGroupRoles, AdminUIActionTypes, ApprovalGroup, OrganizationInformation, OrgUserRoles, PrivateRoutes } from "src/common";
import { AssignApprovalGroupModal } from ".";
import _ from "lodash";
import { useState } from "react";
import { ActionHandlerFunction, ChangeOrgApprovalGroupRequest } from "@preveil-api";
import { InfoPopover, Loading } from "src/components";

type AllProps = {
  approval_groups: ApprovalGroup[];
  organizationInfo: OrganizationInformation;
  isLoading: boolean;
  handleAction: ActionHandlerFunction;
};

/* Description: Assign tab component where a user can assign an approval group specifically for admin management or data export */
function AssignTabComponent(props: AllProps) {
  const { approval_groups, organizationInfo, isLoading, handleAction } = props;
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [role, setRole] = useState<string>("");
  const [existingGroup, setExistingGroup] = useState<ApprovalGroup | undefined>();

  /* Description: For a specific role, get the AdminApprovalGroupRoleMapping. */
  function getApprovalGroupRoleMapping(role: string): {
    name: string;
    all_admin: boolean;
    details: string;
  } {
    return role === "admin_approval_group"
      ? AdminApprovalGroupRoleMapping.admin_approval_group
      : AdminApprovalGroupRoleMapping.export_approval_group;
  }

  function getActivityApprovalGroup(activity: string): ApprovalGroup | undefined {
    return approval_groups.find((a_g) => a_g.roles.includes(activity));
  }

  function getUsersWithRecovery(): number {
    return organizationInfo.org_users.filter((user) => !!user.recovery_group).length;
  }

  /* Description: Checks if the specific role (admin management or data export) has pending requests */
  function roleHasRequests(role: string): boolean {
    return (
      organizationInfo.org_requests.filter((request) => {
        if (request.type === "change_org_approval_group_role") {
          const parsed_payload_data = request.parsed_payload.data as ChangeOrgApprovalGroupRequest;
          return parsed_payload_data.group_role === role;
        } else {
          return false;
        }
      }).length > 0
    );
  }

  /* Description: Checks if there exists an approval group that has approvers that all have admin privileges */
  function adminOnlyGroupsExist(role: string): boolean {
    return (
      !!getApprovalGroupRoleMapping(role).all_admin &&
      approval_groups.filter((a) => {
        return (
          a.approvers.filter((approver) => approver.role === OrgUserRoles.admin).length ===
          a.approvers.length
        );
      }).length > 0
    );
  }

  function assignOnClick(role: string) {
    const admin_only = !!getApprovalGroupRoleMapping(role).all_admin && !adminOnlyGroupsExist(role);
    if (admin_only || approval_groups.length === 0) {
      handleAction({
        actionType: AdminUIActionTypes.SetShow
      });
    } else {
      setRole(role);
      setExistingGroup(getActivityApprovalGroup(role));
      setShowModal(true);
    }
  }

  return (
    <>
      {!!isLoading && <Loading className="in-place mt-5"></Loading>}
      {!isLoading && (
        <div className="max-800">
          {!!showModal && (
            <AssignApprovalGroupModal
              show={showModal}
              setShow={setShowModal}
              role={getApprovalGroupRoleMapping(role)}
              role_name={role}
              approval_groups={approval_groups}
              handleAction={handleAction}
              existingGroup={existingGroup}
            ></AssignApprovalGroupModal>
          )}
          <h3 className="text-muted">Activities</h3>
          <div className="admin-table mb-3">
            <Row className="table-header d-none d-sm-flex">
              <Col sm={5} className="p-0">
                Activity
              </Col>
              <Col className="ps-0" sm={3}>
                Approved By
              </Col>
              <Col></Col>
            </Row>
            {_.map(AdminApprovalGroupRoles, (r: string, i: number) => {
              return (
                <Row key={i} className="table-cell">
                  <Col sm={5} className="ps-0">
                    {getApprovalGroupRoleMapping(r).name}
                    <InfoPopover message={getApprovalGroupRoleMapping(r).details} />
                  </Col>
                  <Col sm={3} className={!getActivityApprovalGroup(r) ? "error-red ps-0" : "ps-0"}>
                    {!!getActivityApprovalGroup(r)
                      ? getActivityApprovalGroup(r)?.name
                      : "Unassigned"}
                  </Col>
                  <Col sm="auto" className="ms-auto ps-0 pe-2">
                    {!!roleHasRequests(r) && (
                      <Button
                        className="notification-count yellow me-3"
                        aria-label="warning"
                        onClick={() => navigate(`/admin/${PrivateRoutes.requests_route}`)}>!</Button>
                    )}
                    <Button
                      variant="outline-secondary"
                      onClick={() => assignOnClick(r)}
                      aria-label="Assign"
                    >
                      {(!!getApprovalGroupRoleMapping(r).all_admin && !adminOnlyGroupsExist(r)) ||
                        approval_groups.length === 0
                        ? "Create"
                        : "Assign"}
                    </Button>
                  </Col>
                </Row>
              );
            })}
          </div>
          <h3 className="text-muted">Account Recovery</h3>
          <Alert variant="dark" className="text-muted d-flex align-items-center">
            <span>
              <b>{getUsersWithRecovery()}</b> of <b>{organizationInfo.org_users.length}</b> users
              have a recovery group assigned
            </span>
            <Button
              className="ms-auto"
              variant="outline-secondary"
              onClick={() => navigate(`/admin/${PrivateRoutes.users_route}`)}
            >
              Manage Users
            </Button>
          </Alert>
        </div>
      )}
    </>
  );
}

export default React.memo(AssignTabComponent);
