import React, { useEffect, useState, FocusEvent } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { DeviceBase, IActionHandler, OrgInfo, DeviceCertificate, CollectionServerUser } from "@preveil-api";
import {
  Account, AdminErrorMessages, AdminMessages, AdminSuccessMessages, CheckboxStates, CheckboxStatesTypes, GlobalErrorMessages, Message, MessageAnchors,
  MessageHandlerDisplayType, PutOrgDeviceCertificatesApiResponse, SupportRoutes, UUID, collectionApi, dayjs, useAppDispatch, useDeleteOrgDeviceCertificatesMutation,
  useGetCertificateDetailsMutation, usePostUsersOrgsByEntityIdAllowAddDeviceMutation, usePutOrgDeviceCertificatesMutation
} from "src/common";
import { AdminToolbar, AlertWithSwitch, CoverTemplate, Icon, PageHeader } from "src/components";
import { AddDeviceCertificateModal, DeleteCertificateConfirmationModal, DeviceCertificateListPanel } from ".";
import { uiActions } from "src/store";
import saveAs from "file-saver";
import _ from "lodash";

type AllProps = {
  current_account: Account;
  org_info: OrgInfo;
}

type DeviceObject = {
  user_id: string;
  device_name: string;
  device_id: string;
  user_name: string;
  platform: string;
  active: string;
  locked_by: string | null | undefined;
  lock_time: string | null | undefined;
}

// Device List headers for CSV File.
const HEADERS: string[] = [
  "User Id",
  "Device Name",
  "Device Id",
  "User Name",
  "Device Type",
  "Active",
  "Locked By",
  "Lock Time",
];

// Byte Order Mark (BOM)
// it may be required by some apps to be present in UTF-8 encoded files.
const BOM = "\ufeff";

function DeviceSettings({ current_account, org_info }: AllProps) {
  const dispatch = useAppDispatch();
  const [org_users, setOrgUsers] = useState<CollectionServerUser[]>();
  const [selected_certificates, setSelectedCertificates] = useState<DeviceCertificate[]>([]);
  const [certificates, setCertificates] = useState<DeviceCertificate[]>([]);
  const [filter_param, setFilterParam] = useState<string>("");
  const [device_lock_status, setDeviceLockStatus] = useState<boolean>(false);
  const [show_add_certificate_modal, setShowAddCertificateModal] = useState<boolean>(false);
  const [show_delete_confirmation_modal, setShowDeleteConfirmationModal] = useState<boolean>(false);
  const [is_loading, setIsLoading] = useState<boolean>(true);
  const device_lock_message = device_lock_status ? AdminMessages.device_settings.enabled : AdminMessages.device_settings.disabled;
  const device_lock_hint = device_lock_status ? AdminMessages.device_settings.enabled_hint : AdminMessages.device_settings.disabled_hint;
  const select_all_checkbox_state: CheckboxStatesTypes = getAllSelectedCheckboxState();
  const filtered_certificates = _.isEmpty(filter_param) ? certificates : filterCertificateList(filter_param);
  const [getOrgDeviceCertificates] = collectionApi.endpoints.getOrgDeviceCertificates.useLazyQuery();
  const [getUsersOrgsByEntityId] = collectionApi.endpoints.getUsersOrgsByEntityId.useLazyQuery();
  const [postUsersOrgsByEntityIdAllowAddDevice] = usePostUsersOrgsByEntityIdAllowAddDeviceMutation();
  const [uploadDeviceCertificate] = usePutOrgDeviceCertificatesMutation();
  const [deleteDeviceCertificate] = useDeleteOrgDeviceCertificatesMutation();
  const [getCertificateDetails] = useGetCertificateDetailsMutation();

  useEffect(() => {
    getDeviceSettingsState();
  }, []);

  // Description: Returns the state of the select all checkbox
  function getAllSelectedCheckboxState() {
    if (selected_certificates.length === 0) {
      return CheckboxStates.empty;
    } else if (certificates.length === selected_certificates.length && selected_certificates.length !== 0) {
      return CheckboxStates.checked;
    } else {
      return CheckboxStates.indeterminate;
    }
  }

  // Description: Handles Request for User Device Management.
  async function toggleUserDeviceManagementRequest() {
    const is_allowed = !device_lock_status;
    await postUsersOrgsByEntityIdAllowAddDevice({
      account_ids: Account.getAccountIdentifiers(current_account),
      body: {
        entityId: org_info.org_id,
        is_allowed
      }
    })
      .unwrap()
      .then(() => {
        setDeviceLockStatus(is_allowed);
      })
      .catch((error) => {
        const params = {
          message: AdminErrorMessages.error_admin_settings_switch_options.replace(MessageAnchors.message_content, "changing user device management options"),
          stack: error
        };
        DeviceSettingRequests.handlePageErrorMessage(params);
      });
  };

  // Description: Fetches the initial state of the device settings
  async function getDeviceSettingsState() {
    getUsersOrgsByEntityId({
      account_ids: Account.getAccountIdentifiers(current_account),
      body: {
        entity_id: org_info.org_id
      }
    })
      .unwrap()
      .then(({ allow_add_devices, users }) => {
        setDeviceLockStatus(allow_add_devices || false);
        setOrgUsers(users);
      })
      .catch((error) => {
        const params = {
          message: AdminErrorMessages.error_admin_settings_data,
          stack: error
        };
        DeviceSettingRequests.handlePageErrorMessage(params);
      })
      .finally(() => {
        fetchCertificates();
      });
  }

  // Description: Fetches the certificates for the current organization
  async function fetchCertificates() {
    await getOrgDeviceCertificates({
      account_ids: Account.getAccountIdentifiers(current_account),
      body: {
        entity_id: org_info.org_id
      }
    })
      .unwrap()
      .then(({ rules }) => {
        if (rules.length > 0) {
          const certificate_details_promises = rules.map(rule =>
            getCertificateDetails({ certificate_body: rule.specifier_body }).unwrap()
          );

          Promise.all(certificate_details_promises.map((promise, index) =>
            promise.then(response => ({
              ...typeof response === "string" ? JSON.parse(response) : response,
              id: rules[index].acl_id
            }))
          ))
            .then((parsed_certificates: DeviceCertificate[]) => {
              setCertificates(parsed_certificates);
              setIsLoading(false);
            })
            .catch((error) => {
              const params = {
                message: AdminErrorMessages.error_admin_parse_device_certificate,
                stack: error
              };
              DeviceSettingRequests.handlePageErrorMessage(params);

              // If there's an error fetching certificate details, set certificates to empty
              clearState();
            });
        } else {
          // If there are no rules, set certificates to empty
          clearState();
        }
      })
      .catch((error) => {
        const params = {
          message: AdminErrorMessages.error_admin_settings_data,
          stack: error
        };
        DeviceSettingRequests.handlePageErrorMessage(params);
        // In case of any error in the try block, set certificates to empty
        clearState();
      });
  }

  function clearState() {
    setCertificates([]);
    setIsLoading(false);
  }

  // Description: returns a single device object with the columns data for the CSV file.
  function createDeviceObject(user_ata: { user_id: string, display_name: string }, device_data: DeviceBase): DeviceObject {
    const { user_id, display_name: user_name } = user_ata;
    const { device_id, platform, device_name, is_active, lock_info } = device_data;
    const active = is_active ? "Yes" : "No";
    const { lock_by_user_id: locked_by, lock_time } = lock_info || {};
    return {
      user_id,
      device_name,
      device_id,
      user_name,
      platform,
      active,
      locked_by,
      lock_time
    };
  };

  // Description: Export devices list as a CSV file
  function handleExportDevicesList() {
    try {
      // creating an array of objects from device list and user_id.
      const devices_array = (org_users || []).map((user: any) => {
        const user_ata = {
          user_id: user.user_id,
          display_name: user.display_name
        };
        if (user.devices) {
          return user.devices.map((device: any) => {
            return createDeviceObject(user_ata, device);
          });
        } else {
          return [];
        }
      }).flat();

      const csvString = [
        [...HEADERS],
        ...devices_array.map((device: DeviceObject) => [
          device.user_id,
          device.device_name,
          device.device_id,
          device.user_name,
          device.platform,
          device.active,
          device.locked_by,
          device.lock_time
        ])
      ]
        .map(row => row.join(","))
        .join("\n");

      const csv_data = `${BOM}${csvString}`;
      const blob = new Blob([csv_data], { type: "text/csv; charset=utf-8" });
      saveAs(blob, `users-${dayjs(new Date()).format("MM.DD.YYYY-HH_mm_ss")}.csv`);
    } catch (error) {
      const params = {
        message: AdminErrorMessages.error_admin_settings_export_csv,
        stack: error
      };
      DeviceSettingRequests.handlePageErrorMessage(params);
    };
  };

  const DeviceSettingRequests = {
    handleShowModal: () => {
      setShowAddCertificateModal(!show_add_certificate_modal);
    },
    handleSingleSelect: (params: { e: FocusEvent<HTMLInputElement>, certificate: DeviceCertificate }) => {
      selectCertificate(params);
    },
    handleMultiSelect: (params: { e: FocusEvent<HTMLInputElement> }) => {
      handleMultiSelectCertificates(params);
    },
    handleSearchParam: (param: string) => {
      setFilterParam(param);
    },
    handleDelete: () => {
      setShowDeleteConfirmationModal(true);
    },
    handleReset: () => {
      setFilterParam("");
    },
    handleGetRefreshRequest: () => {
      setIsLoading(true);
      fetchCertificates();
    },
    handlePageErrorMessage: (params: { message: string, stack?: any }) => {
      dispatch(uiActions.handleRequestErrors(new Message(params.message, MessageHandlerDisplayType.toastr), params.stack));
    },
    // Description: Handles Request for Uploading Device Certificates
    handleUploadDeviceCertificate: (params: { certificate: string }) => {
      if (!!current_account) {
        setIsLoading(true);
        const account_ids = Account.getAccountIdentifiers(current_account);
        const req_params = {
          account_ids,
          body: {
            entity_id: org_info.org_id,
            acl_id: new UUID().String(),
            specifier_type: "certificate",
            specifier_body: params.certificate,
          }
        };
        uploadDeviceCertificate(req_params)
          .unwrap()
          .then((_: PutOrgDeviceCertificatesApiResponse) => {
            fetchCertificates();
            dispatch(uiActions.handleSetMessage(new Message(AdminSuccessMessages.success_uploaded_certificate)));
          })
          .catch((error) => {
            const params = {
              message: AdminErrorMessages.error_admin_uploading_device_certificate,
              stack: error
            };
            DeviceSettingRequests.handlePageErrorMessage(params);
            setIsLoading(false);
          });
      }
    },
    // Description: Handles Request for Deleting Device Certificates
    handleDeleteDeviceCertificate: () => {
      setIsLoading(true);
      const account_ids = Account.getAccountIdentifiers(current_account);
      const req_params = {
        account_ids,
        body: {
          entity_id: org_info.org_id,
          acl_ids: selected_certificates.map(certificate => certificate.id),
        }
      };
      deleteDeviceCertificate(req_params)
        .unwrap()
        .then((_: PutOrgDeviceCertificatesApiResponse) => {
          fetchCertificates();
          dispatch(uiActions.handleSetMessage(new Message(AdminSuccessMessages.success_deleted_certificate)));
        })
        .catch((error) => {
          const params = {
            message: AdminErrorMessages.error_admin_deleting_device_certificate,
            stack: error
          };
          DeviceSettingRequests.handlePageErrorMessage(params);
          setIsLoading(false);
        });
    }
  };

  // Description: Handles the selection of multiple certificates
  function handleMultiSelectCertificates(params: { e: React.FocusEvent<HTMLInputElement> }) {
    const { e } = params;
    const { checked } = e.target;
    if (checked) {
      setSelectedCertificates(certificates);
    } else if (!checked) {
      setSelectedCertificates([]);
    }
  }

  // Description: Handles the selection of a certificate
  function selectCertificate(params: { e: React.FocusEvent<HTMLInputElement>, certificate: DeviceCertificate }) {
    const { e, certificate } = params;
    const { checked } = e.target;
    if (checked) {
      setSelectedCertificates([...selected_certificates, certificate]);
    } else {
      setSelectedCertificates(selected_certificates.filter((selected_certificate) => selected_certificate.id !== certificate.id));
    }
  }

  // Description: Handle all actions from Children forms
  function handlePageActions(actionObj: IActionHandler) {
    dispatch(uiActions.handleMessageDismiss());
    const callback = `handle${actionObj.actionType}`;
    // Handle local calls:
    if ((DeviceSettingRequests as any)[callback] instanceof Function) {
      (DeviceSettingRequests as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(MessageAnchors.actionType, actionObj.actionType);
      DeviceSettingRequests.handlePageErrorMessage({ message, stack: actionObj });
    }
  }

  // Description: Filters the certificates list based on the search parameter (matches all data for the certificate)
  function filterCertificateList(param: string) {
    return certificates.filter((certificate: any) => {
      return (
        Object.values(certificate).some((value: unknown) =>
          (value?.toString() ?? "").toLowerCase().includes(param.toLowerCase())
        )
      );
    });
  };

  return (
    <CoverTemplate className="admin-settings-content">
      <PageHeader>
        <h1>Device Settings</h1>
      </PageHeader>
      <Card className="card-section">

        <Row>
          <Col sm={12} xl={8}>
            <h3 className="content-header">User Device Management</h3>
            <AlertWithSwitch
              popover={true}
              popover_message={device_lock_hint}
              toggleState={device_lock_status}
              handleChange={toggleUserDeviceManagementRequest} >
              <span dangerouslySetInnerHTML={{ __html: device_lock_message }} />
            </AlertWithSwitch>
          </Col>
        </Row>

        <hr />

        <h3>Trusted Devices</h3>
        <div className="d-flex">
          <p>Add a <b>Trusted Root Certificate</b> requirement to restrict user access.</p>
          <a target="_blank" rel="noreferrer" href={SupportRoutes.device_certificate}>
            <Icon className="pv-icon-question text-muted" />
          </a>
        </div>

        <AdminToolbar
          label={certificates.length > 1 ? " Certificates" : " Certificate"}
          total_items={certificates.length}
          filter_param={filter_param}
          total_items_filtered={filtered_certificates.length}
          is_delete_disable={selected_certificates.length === 0}
          is_loading={is_loading}
          handleActions={handlePageActions} />

        <DeviceCertificateListPanel
          certificates={certificates}
          selected_certificates={selected_certificates}
          filtered_certificates={filtered_certificates}
          all_selected_state={select_all_checkbox_state}
          loading={is_loading}
          handleActions={handlePageActions} />

        <AddDeviceCertificateModal
          show_modal={show_add_certificate_modal}
          handleAction={handlePageActions} />

        <DeleteCertificateConfirmationModal
          show_modal={show_delete_confirmation_modal}
          setShowConfirmationModal={setShowDeleteConfirmationModal}
          handleAction={handlePageActions} />

        <hr />
        <Row>
          <h3 className="content-header pt-3">Organization Device List</h3>
          <div className="d-flex align-items-center">
            <Button className="me-2" onClick={handleExportDevicesList}>Export</Button>
            <p className="mb-0">Export information about your current organization members' devices into a .csv file.</p>
          </div>
        </Row>
      </Card>
    </CoverTemplate>
  );
}

export default React.memo(DeviceSettings);
