import React, { useState, FocusEvent, useEffect } from "react";
import { Form } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import {
  Account, useAppDispatch, EntryItem, Message, DriveErrorMessages, DriveUIActionTypes, UUID, useSelectiveSyncMutation, convertB64toUUID,
  DriveEntryType, useAppSelector, isSynced, SelectiveSyncRulesMap
} from "src/common";
import { uiActions } from "src/store";
import { RootState } from "src/store/configureStore";

type AllProps = {
  current_account: Account;
  entry: EntryItem;
  handleAction: ActionHandlerFunction;
}

function SyncDetailsComponent(props: AllProps) {
  const { current_account, entry, handleAction } = props;
  const [disabled, setDisabled] = useState<boolean>(false);
  const is_synced = isSynced(entry.localSyncStatus);
  const ongoing_sync = useAppSelector((state: RootState) => state.ui.show_sync_status);
  const [selective_sync] = useSelectiveSyncMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setDisabled(ongoing_sync);
  }, [ongoing_sync]);

  // Description: handle checkbox change
  function handleChange(event: FocusEvent<HTMLInputElement>) {
    setDisabled(true);
    handleSyncUnsync(event.target.checked);
  }

  // Description: call sync or unsync (start or stop)
  // NOTE: File-sync expects the linked_collection_id for both identifiers (collection_id and directory_id) for LINK type entries
  async function handleSyncUnsync(sync_state: boolean) {
    const collection_id = entry.type === DriveEntryType.LINK ? entry.linked_collection_id : entry.collection_id;
    const node_id = entry.type === DriveEntryType.LINK ? entry.linked_collection_id : entry.id;

    const params: SelectiveSyncRulesMap = {
      [convertB64toUUID(collection_id)]: [
        {
          node_id: convertB64toUUID(node_id),
          sync: sync_state
        }
      ]
    };

    const request_id = new UUID().String();
    await selective_sync({
      uid: current_account.user_id,
      syncRulesMap: params,
      request_id,
    })
      .unwrap()
      .catch(() => {
        dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.error_selective_sync)));
        setTimeout(() => {
          dispatch(uiActions.setShowSyncStatus(false));
        }, 3500);
      });

    handleAction({ actionType: DriveUIActionTypes.SelectiveSyncNotification, params: { request_id } });
  }

  return <div className={`switch-preveil d-flex p-3 ps-0${disabled ? " disabled" : ""}`}>
    <Form.Check
      type="switch"
      id="sync-switch"
      className="me-2"
      checked={is_synced}
      disabled={disabled}
      onChange={handleChange} />
    <span className="property-name">{is_synced ? "Synced" : "Unsynced"}</span>
  </div>;
}

export default React.memo(SyncDetailsComponent);
