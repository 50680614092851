import React from "react";
import { Card } from "react-bootstrap";
import connectionImage from "src/assets/images/connection.svg";

type AllProps = {
  pin: string;
}

function DataExportActiveConnection({ pin }: AllProps) {
  return (
    <main className="data-export-wait-connection">
      <Card className="pv-card">
        <Card.Img variant="top" src={connectionImage} />
        <Card.Body>
          <header className="wait-connection-header">
            <i className="connected"></i>
            <h4>Connection Active</h4>
          </header>
          <p className="pin">{pin}</p>
        </Card.Body>
      </Card>
    </main>
  );
}

export default React.memo(DataExportActiveConnection);