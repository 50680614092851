import React, { useEffect, useState, FocusEvent } from "react";
import { Card } from "react-bootstrap";
import { IActionHandler, OrgInfo } from "@preveil-api";
import {
  Account, AdminMessages, AdminErrorMessages, AdminSuccessMessages, GetTrustedCommunitiesApiResponse, useAppDispatch, useToggleTrustedCommunityMutation,
  useGetTrustedCommunitiesMutation, useAddToTrustedCommunityMutation, useRemoveFromTrustedCommunityMutation, Message, MessageAnchors,
  MessageHandlerDisplayType, MessageToastTypes, findDuplicateAddress, findInvalidAddress, RegexHelper, CheckboxStates, CheckboxStatesTypes
} from "src/common";
import { AlertWithSwitch, PageHeader, CoverTemplate, AddressesListPanel, AdminToolbar, Loading } from "src/components";
import { uiActions } from "src/store";
import { CreateWhitelist, AddTrustedAddressModal } from ".";
import _ from "lodash";

type AllProps = {
  current_account: Account;
  org_info: OrgInfo;
};

function TrustedCommunityComponent({ current_account, org_info }: AllProps) {
  const dispatch = useAppDispatch();

  // RTK Hooks
  // TODO: Move to a custom hook maybe.
  const [toggleTrustedCommunity] = useToggleTrustedCommunityMutation();
  const [getTrustedCommunities] = useGetTrustedCommunitiesMutation();
  const [addToTrustedCommunity] = useAddToTrustedCommunityMutation();
  const [removeFromTrustedCommunity] = useRemoveFromTrustedCommunityMutation();

  // Local State
  const [show_modal, setShowModal] = useState<boolean>(false);
  const [trusted_community_address_list, setTrustedCommunityAddressList] = useState<string[]>([]);
  const [is_trusted_community_active, setTrustedCommunityActiveState] = useState<boolean | undefined>(false);
  const [filter_param, setFilterParam] = useState("");
  const [selected_address_list, setSelectedAddressList] = useState<string[]>([]);
  // Note: Set local state for isLoading, getTrustedCommunities is a mutation and the isLoading condition
  //       is false when component mounts and before we make the call.
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Description: filteredAddresses will contain the addresses/domains filtered based on the input
  // filter param, or if not filter param, it will have all the addresses/domains fetched from the server.
  const filteredAddresses = _.isEmpty(filter_param) ? trusted_community_address_list : filterAddressList(filter_param);
  const dialog_message = is_trusted_community_active ? AdminMessages.disable_community : AdminMessages.enable_community;
  const isSelectedListEmpty = selected_address_list.length === 0;

  useEffect(() => {
    getWhitelistAddresses();
  }, []);

  // Description: handle filtering address list based
  // on the input search param from toolbar component
  function filterAddressList(param: string) {
    return trusted_community_address_list.filter((address: any) => {
      return (
        address.toLowerCase().indexOf(param.toLowerCase()) !== -1 ||
        address.includes(param.toLowerCase())
      );
    });
  };

  // Description: API is expecting the data to be separated into domains and addresses (users).
  function prepareAddressesForRequest(collection: string[]) {
    const domains = collection.filter((element) => RegexHelper.testDomain(element));
    const users = collection.filter((element) => RegexHelper.testEmailAddress(element));
    return {
      domains,
      users
    };
  };

  // Description: Fetch the list of addresses/domains from server
  async function getWhitelistAddresses() {
    const { user_id } = current_account;
    setIsLoading(true);
    await getTrustedCommunities({ userId: user_id, orgId: org_info.org_id })
      .unwrap()
      .then((payload: GetTrustedCommunitiesApiResponse) => {
        if (payload?.domains || payload?.users) {
          const { users, domains, is_active } = payload;
          const _users = !!users ? users : [];
          const _domains = !!domains ? domains : [];
          const trusted_community_address_list: string[] = [..._users, ..._domains];
          if (!_.isEmpty(selected_address_list)) {
            setSelectedAddressList([]);
          }
          setIsLoading(false);
          setTrustedCommunityAddressList(trusted_community_address_list);
          setTrustedCommunityActiveState(is_active);
        }
      })
      .catch((error) => {
        const params = {
          message: AdminErrorMessages.error_fetching_trusted_community,
          stack: error
        };
        setIsLoading(false);
        dispatchPageError(params);
      });
  }

  // Description: Handles request to the server to add new addresses/domains.
  async function addToWhitelistAddresses(data: { domains: string[], users: string[] }) {
    const { user_id } = current_account;
    const { domains, users } = data;
    await addToTrustedCommunity({ userId: user_id, orgId: org_info.org_id, users, domains })
      .unwrap()
      .then(() => {
        getWhitelistAddresses();
        dispatch(uiActions.handleSetMessage(new Message(AdminSuccessMessages.success_add_trusted_community_address)));
      })
      .catch((error) => {
        const params = {
          message: AdminErrorMessages.error_adding_trusted_community,
          stack: error
        };
        dispatchPageError(params);
      });
  }

  // Description: Handles request to remove selected addresses/domains.
  async function removeFromWhitelistAddresses(addresses: string[]) {
    const { user_id } = current_account;
    const collection = _.isArray(addresses) ? addresses : selected_address_list;
    const { users, domains } = prepareAddressesForRequest(collection);
    setFilterParam("");
    await removeFromTrustedCommunity({ userId: user_id, orgId: org_info.org_id, users, domains })
      .unwrap()
      .then(() => {
        getWhitelistAddresses();
        dispatch(uiActions.handleSetMessage(new Message(AdminSuccessMessages.success_delete_trusted_community_addresses)));
      })
      .catch((error) => {
        const params = {
          message: AdminErrorMessages.error_removing_trusted_community,
          stack: error
        };
        dispatchPageError(params);
      });
  };

  // Description: Handles request to activate/deactivate trusted community.
  async function toggleTrustedCommunityRequest() {
    const { user_id } = current_account;
    await toggleTrustedCommunity({ userId: user_id, orgId: org_info.org_id, setActive: !is_trusted_community_active })
      .unwrap()
      .then(() => {
        const message = is_trusted_community_active ? AdminSuccessMessages.success_disable_trusted_community : AdminSuccessMessages.success_enable_trusted_community;
        setTrustedCommunityActiveState(!is_trusted_community_active);
        dispatch(uiActions.handleSetMessage(new Message(message)));
      })
      .catch((error) => {
        const failedToggleState = is_trusted_community_active ? "disabling" : "enabling";
        const params = {
          message: AdminErrorMessages.error_toggle_trusted_community.replace(MessageAnchors.message_content, failedToggleState),
          stack: error
        };
        dispatchPageError(params);
      });
  };

  // Description: dispatch any error found in the page.
  function dispatchPageError(params: { message: string, stack?: any }) {
    dispatch(uiActions.handleRequestErrors(new Message(params.message, MessageHandlerDisplayType.toastr), params.stack));
  }

  // Description: function will handle the confirmation from the user
  // if they want to change the status of the trusted community (active/no active).
  function handleToggleTrustedCommunity() {
    const confirmation_dialog = new Message(
      dialog_message.body,
      MessageHandlerDisplayType.confirm,
      MessageToastTypes.primary,
      dialog_message.title,
      {
        label: "Yes",
        data: true,
        action: () => toggleTrustedCommunityRequest()
      },
      {
        label: "No"
      }
    );
    dispatch(uiActions.handleSetMessage(confirmation_dialog));
  };

  // Description: Handles the confirmation dialog, warning user if they want to
  // delete the selected addresses/domains.
  function confirmDeleteAddressesDialog(addresses: string[]) {
    const confirmation_dialog = new Message(
      AdminMessages.confirm_delete_address.body,
      MessageHandlerDisplayType.confirm,
      MessageToastTypes.primary,
      AdminMessages.confirm_delete_address.title,
      {
        label: "Yes",
        data: true,
        action: () => removeFromWhitelistAddresses(addresses)
      },
      {
        label: "No"
      }
    );
    dispatch(uiActions.handleSetMessage(confirmation_dialog));
  }

  // Description: handle the validation and submit the new address/domain collection.
  function submitAddresses(collection: string[]) {
    if (_.isEmpty(collection)) {
      return;
    };
    const isDuplicatedFound = findDuplicateAddress(trusted_community_address_list, collection);
    const collectionOfInvalidAddresses = findInvalidAddress(collection);
    const isValidAddressCollection = collectionOfInvalidAddresses.length >= 1;

    if (isDuplicatedFound) {
      const params = { message: AdminErrorMessages.error_duplicate_trusted_address, stack: {} };
      dispatchPageError(params);
    } else if (isValidAddressCollection) {
      const params = { message: AdminErrorMessages.error_invalid_address, stack: {} };
      dispatchPageError(params);
    } else {
      const formattedCollection = prepareAddressesForRequest(collection);
      setShowModal(false);
      addToWhitelistAddresses(formattedCollection);
    }
  };

  // Description: if only one address/domain is selected (checked) it will add it to the selected_address_list
  // if deselected (unchecked) it will remove it from the collection.
  function singleSelectAddress(params: { e: FocusEvent<HTMLInputElement>, id: string }) {
    const { e, id } = params;
    const { checked } = e.target;
    if (checked) {
      setSelectedAddressList([...selected_address_list, id]);
    } else if (!checked) {
      setSelectedAddressList(selected_address_list.filter((address) => address !== id));
    }
  };

  // Description: When user checks the Name checkbox it will select/checked
  // all the addresses in the current list, if the current list is filtered
  // it will only seletect those only, if filter param is remove, list will show select/unselected addresses.
  function multiSelectAddress(e: FocusEvent<HTMLInputElement>) {
    const { checked } = e.target;
    if (checked) {
      setSelectedAddressList([...filteredAddresses]);
    } else if (!checked) {
      setSelectedAddressList([]);
    }
  };

  // Description: Handle all children component actions.
  const TrustedCommunitiesRequests = {
    handleGetRefreshRequest: () => {
      getWhitelistAddresses();
    },
    handleDelete: (addresses: string[]) => {
      confirmDeleteAddressesDialog(addresses);
    },
    handlePageError: (params: { message: string, stack?: any }) => {
      dispatchPageError(params);
    },
    handleSearchParam: (param: string) => {
      setFilterParam(param);
    },
    handleShowModal: () => {
      setShowModal(!show_modal);
    },
    handleSubmitAddresses: (collection: string[]) => {
      submitAddresses(collection);
    },
    handleSingleSelect: (params: { e: FocusEvent<HTMLInputElement>, id: string }) => {
      singleSelectAddress(params);
    },
    handleMultiSelect: (e: FocusEvent<HTMLInputElement>) => {
      multiSelectAddress(e);
    },
    handleReset: () => {
      // Reset Filter Param if user click (x) input field.
      setFilterParam("");
    }
  };

  function handleDefaultErrorMessages(message: string, stack: any) {
    dispatch(uiActions.handleRequestErrors(new Message(message, MessageHandlerDisplayType.logger), stack));
  }

  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    if ((TrustedCommunitiesRequests as any)[callback] instanceof Function) {
      (TrustedCommunitiesRequests as any)[callback](actionObj.params);
    } else {
      const { params } = actionObj;
      const { message, stack } = params;
      handleDefaultErrorMessages(message, stack);
    }
  }

  let selectAllCheckboxState = CheckboxStates.empty as CheckboxStatesTypes;

  if (trusted_community_address_list.length === selected_address_list.length && selected_address_list.length !== 0) {
    selectAllCheckboxState = CheckboxStates.checked;
  } else if (selected_address_list.length < trusted_community_address_list.length && selected_address_list.length !== 0) {
    selectAllCheckboxState = CheckboxStates.indeterminate;
  }

  const disableRefreshAction = trusted_community_address_list.length === 0;
  const total_aliases_label = trusted_community_address_list.length === 1 ? "Entry" : "Entries";
  return (
    <CoverTemplate className="admin-wrapper">
      <PageHeader>
        <h1>Trusted Community</h1>
      </PageHeader>

      <Card className="card-section h-100">
        <AlertWithSwitch
          popover={false}
          toggleState={is_trusted_community_active}
          handleChange={handleToggleTrustedCommunity}
        >
          <>Your organization's trusted community is turned {is_trusted_community_active ? "on" : "off"}</>
        </AlertWithSwitch>

        <AdminToolbar
          is_loading={isLoading}
          is_delete_disable={isSelectedListEmpty}
          is_refresh_disable={disableRefreshAction}
          total_items_filtered={filteredAddresses.length}
          total_items={trusted_community_address_list.length}
          handleActions={handlePageActions}
          filter_param={filter_param}
          label={total_aliases_label}
          button_title="Create Trusted Address"
        />
        <AddTrustedAddressModal
          show_modal={show_modal}
          handleActions={handlePageActions}
          saved_addresses={filteredAddresses}
        />
        {isLoading ? (
          <div className="cover-content">
            <div className="admin-panel-center">
              <Loading className="in-place" />
            </div>
          </div>
        ) : (
          <AddressesListPanel
            allSelectState={selectAllCheckboxState}
            filtered_addresses={filteredAddresses}
            total_addresses={trusted_community_address_list}
            selected_address_list={selected_address_list}
            handleActions={handlePageActions}
          >
            <div className="admin-panel-center">
              <CreateWhitelist handleActions={handlePageActions} />
            </div>
          </AddressesListPanel>
        )}
      </Card>
    </CoverTemplate>
  );
}

export default React.memo(TrustedCommunityComponent);
