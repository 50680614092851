import * as React from "react";
import { Button } from "react-bootstrap";
import { PanelState, PanelStateType, useAppSelector } from "src/common";
// import { Icon } from "src/components";
import { RootState } from "src/store/configureStore";

type AllProps = {
  className?: string;
  handleAction: (state: PanelStateType) => void;
}

function ToggleLeftNav(props: AllProps) {
  const { className, handleAction } = props;
  const state: PanelStateType = useAppSelector((state: RootState) => state.ui.left_nav_state);
  return <Button variant="no-outline-info"
    className={`toggle-nav btn-icon ${!!className ? className : ""}`}
    title="Toggle left Navigation"
    onClick={() => { handleAction(state === PanelState.off ? PanelState.on : PanelState.off); }}>
    <i></i>
  </Button>;
}

export default React.memo(ToggleLeftNav);
