import React, { useState, FocusEvent } from "react";
import { Row, Col, Button, Dropdown } from "react-bootstrap";
import { DateSelector } from ".";
import DatePicker from "react-datepicker";
import { dayjs, UIManagerActionTypes } from "src/common";
import { Icon } from "src/components";
import { ActionHandlerFunction } from "@preveil-api";

type AllProps = {
  handleAction: ActionHandlerFunction;
}

function DateSelectorDropdownComponent(props: AllProps) {
  const { handleAction } = props;
  const now = dayjs().toDate();
  const [startDate, setStartDate] = useState<Date | null>(dayjs().subtract(2, "week").toDate());
  const [endDate, setEndDate] = useState<Date | null>(now);
  const [activeItem, setActiveItem] = useState<string>("");
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const handleChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setShowDatePicker(true);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement, Element>) => {
    const input = event.target.value;
    if (input) {
      setActiveItem("custom range");
      const dates = input.split(" - ");
      const start = dayjs(new Date(dates[0]).toISOString());
      const end = dayjs(new Date(dates[1]).toISOString());
      const sDate =
        start.isValid() && start < end && start < dayjs() ? start.toDate() : startDate || now;
      const eDate = end.isValid() && end > start && end < dayjs() ? end.toDate() : endDate || now;
      handleChange([sDate, eDate]);
    }
  };

  const handleClick = (date: dayjs.Dayjs, activeItem: string) => {
    handleChange([date.toDate(), now]);
    setShowDatePicker(false);
    setShowDropdown(!showDropdown);
    setActiveItem(activeItem);
    handleAction({
      actionType: UIManagerActionTypes.DateSelector,
      params: { start: date.toDate(), end: now, selected_range: activeItem},
    });
  };

  const handleSubmit = () => {
    setShowDatePicker(false);
    setShowDropdown(false);
    handleAction({
      actionType: UIManagerActionTypes.DateSelector,
      params: { start: startDate, end: endDate, selected_range: activeItem},
    });
  };

  const DateSelectorDropdown = () => (
    <Dropdown className="date-picker-dropdown" show={showDropdown}>
      <div
        className="date-picker-input main-picker"
        onClick={() => {
          setShowDropdown(true);
        }}
      >
        <span>
          <Icon className="pv-icon-calendar text-muted" />
        </span>
        <DatePicker
          className="border-0 input"
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          onChange={handleChange}
          onBlur={handleBlur}
          popperClassName="d-none"
          selectsRange
          dateFormat="MMM d, yyyy"
          placeholderText="Select start date"
          maxDate={now}
          shouldCloseOnSelect={false}
        />
      </div>
      <Dropdown.Menu className="mw-100 p-0 date-picker-menu">
        <Row className="ms-0 me-0">
          <Col
            xs={showDatePicker ? "3" : undefined}
            className={!!showDatePicker ? "ranges right-border" : "ranges"}
          >
            <Dropdown.Item
              className={activeItem === "last week" ? "active" : ""}
              onClick={() => handleClick(dayjs().subtract(1, "week"), "last week")}
            >
              Past Week
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleClick(dayjs().subtract(2, "week"), "last two weeks")}
              className={activeItem === "last two weeks" ? "active" : ""}
            >
              Past Two Weeks
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleClick(dayjs().subtract(1, "month"), "last month")}
              className={activeItem === "last month" ? "active" : ""}
            >
              Past Month
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleClick(dayjs().subtract(6, "month"), "last 6 months")}
              className={activeItem === "last 6 months" ? "active" : ""}
            >
              Past 6 Months
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleClick(dayjs().subtract(1, "year"), "last year")}
              className={activeItem === "last year" ? "active" : ""}
            >
              Past Year
            </Dropdown.Item>
            <Dropdown.Item
              // When the "Forever" tab is selected, set the start date to 20180101 (release data of the product)
              onClick={() => handleClick(dayjs("2018-01-01"), "forever")}
              className={activeItem === "forever" ? "active" : ""}
            >
              Forever
            </Dropdown.Item>
            <Dropdown.Item
              className={activeItem === "custom range" ? "active" : ""}
              onClick={() => {
                setShowDatePicker(true);
                setActiveItem("custom range");
              }}
            >
              Custom Range
            </Dropdown.Item>
            <Dropdown.Item className="buttons">
              {" "}
              <Button
                variant="primary"
                className="me-2"
                disabled={!startDate || !endDate}
                onClick={() => handleSubmit()}
              >
                Apply
              </Button>
              <Button
                variant="no-outline-primary"
                onClick={() => setShowDropdown(false)}
              >
                Cancel
              </Button>
            </Dropdown.Item>
          </Col>
          {showDatePicker ? (
            <Col className="selector">
              <DateSelector
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                handleChange={handleChange}
              ></DateSelector>
            </Col>
          ) : null}
        </Row>
      </Dropdown.Menu>
    </Dropdown>
  );

  return <DateSelectorDropdown />;
}

export default React.memo(DateSelectorDropdownComponent);
