import * as React from "react";
import { Dropdown } from "react-bootstrap";
import { DriveUIActionTypes, Feature, DriveFeature, PreveilDownloads, useAppSelector, isUploadInProgres, isSynced } from "src/common";
import { Icon } from "src/components";
import { RootState } from "src/store/configureStore";
import { DropdownProps } from ".";

function DirMenuComponent(props: DropdownProps) {
  const { entry, all_actions, permissions, handleAction, under_downloads } = props;
  const upload_folder_ids = useAppSelector((state: RootState) => state.ui.upload_folder_ids);
  const uploading_in_progress = isUploadInProgres(entry, upload_folder_ids);

  return <>
    <Dropdown.Item aria-label="Details"
      hidden={!all_actions}
      data-tooltip-id="dd-tooltip" data-tooltip-content="Folder Details"
      data-tooltip-place="right"
      // NOTE: Default to share component opened
      onClick={() => handleAction({ actionType: DriveUIActionTypes.Details, params: { entry, action: DriveUIActionTypes.Details } })}>
      <Icon className="pv-icon-finder" />Details
    </Dropdown.Item>

    {/* Require user permissions for the parent collection */}
    {
      !!permissions && <>
        {/* NOTE: Direct Link does not work for Web build */}
        <Dropdown.Item aria-label="Copy Link"
          hidden={Feature.hidden(DriveFeature.CopyLink)}
          data-tooltip-id="dd-tooltip" data-tooltip-content="Copy Link to Folder"
          data-tooltip-place="right"
          onClick={() => handleAction({ actionType: DriveUIActionTypes.CopyLink, params: entry })}>
          <Icon className="ficon-link" />Copy Link
        </Dropdown.Item>
        <Dropdown.Item aria-label="Rename"
          hidden={Feature.hidden(DriveFeature.Rename, permissions)}
          data-tooltip-id="dd-tooltip" data-tooltip-content="Rename Folder"
          data-tooltip-place="right"
          onClick={() => handleAction({ actionType: DriveUIActionTypes.Details, params: { entry, action: DriveUIActionTypes.Rename } })}>
          <Icon className="pv-icon-compose" />Rename
        </Dropdown.Item>
        {!uploading_in_progress && <>
          <Dropdown.Item aria-label="Share"
            hidden={Feature.hidden(DriveFeature.Share, permissions, under_downloads ? PreveilDownloads : entry.name)}
            data-tooltip-id="dd-tooltip" data-tooltip-content="Share Folder"
            data-tooltip-place="right"
            onClick={() => handleAction({ actionType: DriveUIActionTypes.Details, params: { entry, action: DriveUIActionTypes.Share } })}>
            <Icon className="pv-icon-share" />Share
          </Dropdown.Item>
          <Dropdown.Item aria-label="Delete"
            hidden={Feature.hidden(DriveFeature.Delete, permissions)}
            data-tooltip-id="dd-tooltip" data-tooltip-content="Delete Folder"
            data-tooltip-place="right"
            onClick={() => handleAction({ actionType: DriveUIActionTypes.Delete, params: entry })}>
            <Icon className="pv-icon-trash" />Delete
          </Dropdown.Item>
          <Dropdown.Item aria-label="Move"
            hidden={Feature.hidden(DriveFeature.Move, permissions)}
            data-tooltip-id="dd-tooltip" data-tooltip-content="Move Folder"
            data-tooltip-place="right"
            onClick={() => handleAction({ actionType: DriveUIActionTypes.MoveModal, params: { show: true, entry } })}>
            <Icon className="pv-icon-move" />Move
          </Dropdown.Item>
        </>
        }
        <Dropdown.Item aria-label="Sync"
          hidden={Feature.hidden(DriveFeature.Sync, permissions)}
          data-tooltip-id="dd-tooltip"
          data-tooltip-content={
            isSynced(entry.localSyncStatus) ? "This folder is synced. Unsync folder" :
              "This folder is unsynced. Sync folder"}
          data-tooltip-place="right"
          onClick={() => {
            handleAction({
              actionType: DriveUIActionTypes.Sync,
              params: { entry },
            });
          }}>
          {
            isSynced(entry.localSyncStatus) ?
              <>
                <Icon className=" fsi-unsynced" />
                Unsync
              </> : <>
                <Icon className="ficon-refresh-ccw" />
                Sync
              </>
          }
        </Dropdown.Item>
      </>
    }
  </>;
}

export default React.memo(DirMenuComponent);