
/*
* Description: User class on the client side
*
*/
import { UserBase, UserProfile, ContactsData, CollectionServerUser } from "@preveil-api";
import { Account, isSameUser, UserListStatus, KeyFactory, Helpers, EmailUser, MailRecipient, AccountUserPublicKey } from "src/common";
import _ from "lodash";

// Utilities for Users and Profiles
// ---------------------------------------------------
// Return a formatted name <address> string 
// OR if external address (aliased)  a formatted: [External] name <external_email>
export function prettyUserProfile(profile: UserProfile): string {
  const display_name = !!profile.name ? `${profile.name} ` : "";
  const address = !!profile.address && !!display_name && display_name.length > 0 && !isSameUser(profile.address, profile.name || "") ?
    `${display_name}&lt;${profile.address}&gt;` :
    !!profile.address ? profile.address : "";
  const external_email = !!profile.external_email ? `&lt;${profile.external_email}&gt;` : "";
  return external_email.length > 0 ? `[External] ${display_name}${external_email}` : address;
}

// Description: Returns pretty user profile with parts of the string highlighted
export function highlightProfileSubstrings(profile: UserProfile, search_term: string): string {
  const external_email = !!profile.external_email ? profile.external_email.replaceAll(search_term, `<b>${search_term}</b>`) : null;
  const display_name = !!profile.name ? profile.name.replaceAll(search_term, `<b>${search_term}</b>`) : null;
  const display_email = !!profile.address ? profile.address.toLowerCase().replaceAll(search_term, `<b>${search_term}</b>`) : "";
  return !!external_email ? external_email : !!display_name && !isSameUser(display_email, display_name) ?
    `${display_name} &lt;${display_email}&gt;` : display_email;
}

// Description: Return the correct display email for a profile (first external_email)
export function profileDisplayEmail(profile: UserProfile): string {
  return !!profile.external_email ? profile.external_email.toLowerCase() : !!profile.address ? profile.address.toLowerCase() : "";
}

// Description: Build tooltip for user components
export function userProfileTooltip(profile: UserProfile): string {
  if (profile.status === UserListStatus.group) {
    const members = !!profile.members && profile.members.length > 0 ? `: ${profile.members.join(", ")}` : "";
    return `${profile.address}${members}`;
  } else {
    const display_name = !!profile.name ? `${profile.name} ` : "";
    const address = !!profile.address && !!display_name && display_name.length > 0 && !isSameUser(profile.address, profile.name || "") ?
      `${display_name} (${profile.address})` :
      !!profile.address ? profile.address : "";
    const external_email = !!profile.external_email ? `(${profile.external_email})` : "";
    return external_email.length > 0 ? `[External] ${display_name}${external_email}` : address;
  }
}

// Description:  Convert user profiles to Userbase for crypto saving (mailsend)
// NOTE: external_email needs to be undefined if none, as crypto does NOT like to receive null from sendMail/saveDraft calls
export function parseProfileToUserBase(profiles: UserProfile[]): UserBase[] {
  return _.map(profiles, (profile: UserProfile) => {
    return {
      user_id: profile.address,
      display_name: profile.name || profile.address,
      external_email: profile.external_email || undefined
    };
  });
}

// Description: Convert Grouped Recipients from UserProfiles to individual UserProfile
export function resolveGroupToUserProfile(profiles: UserProfile[]): UserProfile[] {
  const user_profiles: UserProfile[] = _.flatten(_.map(profiles, (profile: UserProfile) => {
    if (profile.status === UserListStatus.group) {
      return _.map(profile.members, (_id: string) => ({ address: _id, name: null }));
    } else {
      return [profile];
    }
  }));
  return user_profiles;
}

// Description: Convert Recipients from UserProfiles and CollectionServerUsers to EmailUser
export async function parseProfileToEmailUser(profiles: UserProfile[], contacts: ContactsData): Promise<EmailUser[]> {
  const user_profiles = resolveGroupToUserProfile(profiles);
  return await Promise.all(_.map(user_profiles, async (profile: UserProfile) => {
    const cs_user = _.find([...contacts.cs_profiles, ...contacts.cs_aliases], (cs_profile: CollectionServerUser) => {
      return !!profile.external_email ? isSameUser(profile.external_email, cs_profile.external_email) : isSameUser(profile.address, cs_profile.user_id);
    });
    const public_user_key = !!cs_user ? await KeyFactory.deserializePublicUserKey(Helpers.b64Decode(cs_user.public_key)) : null;
    return {
      user_id: profile.address,
      external_email: profile.external_email,
      public_user_key,
      key_version: public_user_key?.key_version
    };
  }));
}

// Description: Get MailRecipient for V4 emails
export function getRecipientMailRecipient(contacts: ContactsData, recipients: EmailUser[], sender: Account): MailRecipient[] {
  const all_users: MailRecipient[] = [];
  _.forEach(recipients, (recipient: EmailUser) => {
    const cs_contact = _.find(contacts.cs_profiles, (cs_profile: CollectionServerUser) => isSameUser(recipient.user_id, cs_profile.user_id));
    if (!!recipient.public_user_key && !!cs_contact) {
      all_users.push({
        user_id: recipient.user_id.toLowerCase(),
        mail_cid: cs_contact.mail_collection_id,
        key_version: recipient.public_user_key.key_version,
        public_user_key: recipient.public_user_key
      });
    }
  });
  all_users.push(sender.mail_recipient);
  return all_users;
}

export interface PublicKeyUser {
  user_id: string;
  external_email?: string | null;
  key_version: number;
  public_user_key: AccountUserPublicKey;
}
// Description: Transform CollectionServerUser to EmailUser
export async function parseCollectionUserToPublicKeyUser(cs_user: CollectionServerUser): Promise<PublicKeyUser | undefined> {
  const public_user_key = !!cs_user.public_key ? await KeyFactory.deserializePublicUserKey(Helpers.b64Decode(cs_user.public_key)) : undefined;
  return public_user_key ? {
    user_id: cs_user.user_id.toLowerCase(),
    external_email: cs_user.external_email,
    public_user_key,
    key_version: public_user_key.key_version
  } : undefined;
}
