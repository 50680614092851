import React, { useState, useEffect, FocusEvent } from "react";
import { Form, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ActionHandlerFunction, AccountType } from "@preveil-api";
import { AccountUIActionTypes, RegexHelper, usePostUsersTypeMutation, account_types, AccountErrorMessages, PublicRoutes } from "src/common";
import { Icon } from "src/components";

type AllProps = {
  user_id?: string;
  handleAction: ActionHandlerFunction;
}

function DefaultPanelComponent(props: AllProps) {
  const { user_id, handleAction } = props;
  const [validated, setValidated] = useState(false);
  const [_user_id, setUserId] = useState<string>(user_id || "");
  const [getAccountType] = usePostUsersTypeMutation();

  // Description: Onload trigger the validation of user redirect if needed
  useEffect(() => {
    user_id && handleUserValidation(user_id);
  }, []);

  // Description: Do an account type check on load and on submit
  function handleUserValidation(userId: string, submit: boolean = false) {
    getAccountType({ userId }).unwrap()
      .then((params: { account_type: AccountType }) => {
        const account_type = params.account_type;
        submit && account_type === account_types.full ?
          handleAction({ actionType: AccountUIActionTypes.InitWebsocketConnection, params: { user_id: userId } }) :
          submit && handleError(AccountErrorMessages.account_user_id_error);
      })
      .catch((stack) => {
        handleError(AccountErrorMessages.default, stack);
      });
  };

  // Description: Send error up and reset form
  function handleError(message: string, stack?: any) {
    setUserId("");
    setValidated(false);
    handleAction({
      actionType: AccountUIActionTypes.PageError,
      params: { message, stack }
    });
  };

  // Description: is form valid
  function isValid(email: string): boolean {
    return RegexHelper.testEmailAddress(email);
  };

  // Description: Submit Backup Recovery code
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
     // TODO - to Fix: Not getting currentTarget property from the Event when in testing environment
    // currentTarget returns undefined.
    // https://github.com/testing-library/dom-testing-library/issues/73
    const userId = (e.currentTarget?.user_id?.value || _user_id).trim().toLowerCase();
    if (isValid(userId)) { // Validate email in form
      handleUserValidation(userId, true);
      setValidated(true);
    } else {
      handleError(AccountErrorMessages.account_user_id_error);
    };
  };

  return <>
    <h4>Enter your PreVeil ID. You will need to access your PreVeil account on your other computer or phone.</h4>
    <Form noValidate validated={validated} onSubmit={handleSubmit} >
      <Form.Group as={Col} controlId="validationConnecting">
        <Form.Label>PreVeil ID (your email address)</Form.Label>
        <div className="input-icon-prepend">
          <Icon className="ficon-user" />
          <Form.Control
            required
            type="email"
            placeholder="Enter your email address"
            name="user_id"
            autoComplete="off"
            autoFocus={true}
            value={_user_id}
            onChange={(e: FocusEvent<HTMLInputElement>) => setUserId(e.currentTarget.value)} />
        </div>
      </Form.Group>
      <div className="btn-panel my-3">
        <Button type="submit" disabled={!_user_id || !isValid(_user_id)} >Continue</Button>
        <Link className="btn btn-outline-primary" to={`/${PublicRoutes.add_account_route}`}>Cancel</Link>
      </div>
      <p className="d-flex"><Icon className="pv-icon-question" /><span>
        If you are unable to access your PreVeil account on another computer or phone&nbsp;
        <Link to={`/${PublicRoutes.account_recovery_route}`}>click here</Link> to recover your private key using your recovery group.
      </span>
      </p>
    </Form>
  </>;
}

export default React.memo(DefaultPanelComponent);
