/* eslint-disable */
/*
*   File:           helpers.ts
*   Description:    Utility functions for encoding / decoding
*   Author:         PreVeil, LLC
*/
import { UUID, UUIDStringType, UUIDStringTypes } from "src/common/";
import Hashids from "hashids";
import _ from "lodash";

const ENCODED_VALS_BASE = "ABCDEFGHIJKLMNOPQRSTUVWXYZ" +
  "abcdefghijklmnopqrstuvwxyz" +
  "0123456789",
  ENCODED_VALS = ENCODED_VALS_BASE + "+/=",
  ENCODED_VALS_WEBSAFE = ENCODED_VALS_BASE + "-_=",
  HEX_CHARS = "0123456789ABCDEF",
  charToByteMap_: { [key: string]: number; } = {},
  byteToCharMap_: { [key: number]: string; } = {},
  byteToCharMapWebSafe_: { [key: number]: string; } = {};

for (let i = 0; i < ENCODED_VALS.length; i++) {
  byteToCharMap_[i] = ENCODED_VALS.charAt(i);
  charToByteMap_[byteToCharMap_[i]] = i;
  byteToCharMapWebSafe_[i] = ENCODED_VALS_WEBSAFE.charAt(i);

  // Be forgiving when decoding and correctly decode both encodings.
  if (i >= ENCODED_VALS_BASE.length) {
    charToByteMap_[ENCODED_VALS_WEBSAFE.charAt(i)] = i;
  }
}


export class Helpers {
  // https://github.com/google/closure-library/blob/master/closure/goog/crypt/crypt.js#L117
  static utf8Encode(s: string): Uint8Array {
    let i = 0;
    const bytes = new Uint8Array(s.length * 4);
    for (let ci = 0; ci !== s.length; ci++) {
      let c = s.charCodeAt(ci);
      if (c < 128) {
        bytes[i++] = c;
        continue;
      }
      if (c < 2048) {
        bytes[i++] = (c >> 6) | 192;
      } else {
        if (c > 0xd7ff && c < 0xdc00) {
          if (++ci >= s.length) {
            throw new Error("UTF-8 encode: incomplete surrogate pair");
          }
          const c2 = s.charCodeAt(ci);
          if (c2 < 0xdc00 || c2 > 0xdfff) {
            throw new Error(
              "UTF-8 encode: second surrogate character 0x" +
              c2.toString(16) +
              " at index " +
              ci +
              " out of range",
            );
          }
          c = 0x10000 + ((c & 0x03ff) << 10) + (c2 & 0x03ff);
          bytes[i++] = (c >> 18) | 240;
          bytes[i++] = ((c >> 12) & 63) | 128;
        } else {
          bytes[i++] = (c >> 12) | 224;
        }
        bytes[i++] = ((c >> 6) & 63) | 128;
      }
      bytes[i++] = (c & 63) | 128;
    }
    return bytes.subarray(0, i);
  }

  // https://github.com/google/closure-library/blob/master/closure/goog/crypt/crypt.js#L151
  static utf8Decode(bytes: Uint8Array): string {
    let i = 0,
      s = "";
    while (i < bytes.length) {
      let c = bytes[i++];
      if (c > 127) {
        if (c > 191 && c < 224) {
          if (i >= bytes.length) {
            throw new Error("UTF-8 decode: incomplete 2-byte sequence");
          }
          c = ((c & 31) << 6) | (bytes[i++] & 63);
        } else if (c > 223 && c < 240) {
          if (i + 1 >= bytes.length) {
            throw new Error("UTF-8 decode: incomplete 3-byte sequence");
          }
          c = ((c & 15) << 12) | ((bytes[i++] & 63) << 6) | (bytes[i++] & 63);
        } else if (c > 239 && c < 248) {
          if (i + 2 >= bytes.length) {
            throw new Error("UTF-8 decode: incomplete 4-byte sequence");
          }
          c =
            ((c & 7) << 18) |
            ((bytes[i++] & 63) << 12) |
            ((bytes[i++] & 63) << 6) |
            (bytes[i++] & 63);
        } else {
          throw new Error(
            "UTF-8 decode: unknown multibyte start 0x" + c.toString(16) + " at index " + (i - 1),
          );
        }
      }
      if (c <= 0xffff) {
        s += String.fromCharCode(c);
      } else if (c <= 0x10ffff) {
        c -= 0x10000;
        s += String.fromCharCode((c >> 10) | 0xd800);
        s += String.fromCharCode((c & 0x3ff) | 0xdc00);
      } else {
        throw new Error("UTF-8 decode: code point 0x" + c.toString(16) + " exceeds UTF-16 reach");
      }
    }
    return s;
  }

  // https://github.com/google/closure-library/blob/master/closure/goog/crypt/base64.js#L124
  static b64Encode(bytes: Uint8Array, uriSafe: boolean = false): string {
    const byteToCharMap = uriSafe ? byteToCharMapWebSafe_ : byteToCharMap_;
    const output = [];

    for (let i = 0; i < bytes.length; i += 3) {
      const byte1 = bytes[i];
      const haveByte2 = i + 1 < bytes.length;
      const byte2 = haveByte2 ? bytes[i + 1] : 0;
      const haveByte3 = i + 2 < bytes.length;
      const byte3 = haveByte3 ? bytes[i + 2] : 0;

      const outByte1 = byte1 >> 2;
      const outByte2 = ((byte1 & 0x03) << 4) | (byte2 >> 4);
      let outByte3 = ((byte2 & 0x0f) << 2) | (byte3 >> 6);
      let outByte4 = byte3 & 0x3f;

      if (!haveByte3) {
        outByte4 = 64;

        if (!haveByte2) {
          outByte3 = 64;
        }
      }

      output.push(
        byteToCharMap[outByte1],
        byteToCharMap[outByte2],
        byteToCharMap[outByte3],
        byteToCharMap[outByte4],
      );
    }

    return output.join("");
  }

  // https://github.com/google/closure-library/blob/master/closure/goog/crypt/base64.js#L255
  static b64Decode(str: string): Uint8Array {
    const len = str.length;
    // Check if there are trailing '=' as padding in the b64 string.
    let placeholders = 0;
    if (str[len - 2] === "=") {
      placeholders = 2;
    } else if (str[len - 1] === "=") {
      placeholders = 1;
    }
    const output = new Uint8Array(Math.ceil((len * 3) / 4) - placeholders);
    let outLen = 0;
    function pushByte(b: number) {
      output[outLen++] = b;
    }

    Helpers.decodeStringInternal_(str, pushByte);

    return output.subarray(0, outLen);
  }

  // https://github.com/google/closure-library/blob/master/closure/goog/string/internal.js#L98
  static isEmptyOrWhitespace(str: string): boolean {
    // testing length == 0 first is actually slower in all browsers (about the
    // same in Opera).
    // Since IE doesn't include non-breaking-space (0xa0) in their \s character
    // class (as required by section 7.2 of the ECMAScript spec), we explicitly
    // include it in the regexp to enforce consistent cross-browser behavior.
    return /^[\s\xa0]*$/.test(str);
  }

  static async sha256Checksum(b: Uint8Array): Promise<Uint8Array> {
    return window.crypto.subtle.digest("SHA-256", b).then((d) => new Uint8Array(d));
  }

  static async sha512Checksum(b: Uint8Array): Promise<Uint8Array> {
    return window.crypto.subtle.digest("SHA-512", b).then((d) => new Uint8Array(d));
  }

  static hexEncode(bytes: Uint8Array): string {
    const ret = new Array(bytes.length * 2);
    for (let i = 0; i < bytes.length; ++i) {
      ret[i * 2] = HEX_CHARS.charAt((bytes[i] >> 4) & 15);
      ret[i * 2 + 1] = HEX_CHARS.charAt(bytes[i] & 15);
    }
    return ret.join("");
  }

  static hexDecode(hex_string: string): Uint8Array {
    let bytes: Uint8Array = new Uint8Array(Math.ceil(hex_string.length / 2));
    for (let i = 0; i < bytes.length; i++) {
      bytes[i] = parseInt(hex_string.substr(i * 2, 2), 16);
    }
    return bytes;
  }

  // Description: return a new Message UUID
  static newMessageId(): string {
    return `<${new UUID().String()}@preveil.com>`;
  }

  // Decription: Encodes uid for mailbox and mail threads for urls - legacy in URLHelpers.getURLHashFromUID
  static getURLHashFromUID(uid: string) {
    const hashids = new Hashids();
    let code: number = 0;
    uid.split("").forEach((char) => {
      code = (code << 5) - code + char.charCodeAt(0);
      code &= code;
    });
    // making sure overflown integers will have
    // their negative sign replaced w an acceptable HEX code
    let hash = code.toString().replace("-", "F");
    return hashids.encodeHex(hash);
  }

  static chunkData(blob: Uint8Array, chunk_size: number = 2 * 1024 * 1024): Array<Uint8Array> {
    const ret = [];
    for (let i = 0; i < blob.length; i += chunk_size) {
      ret.push(blob.slice(i, i + chunk_size));
    }
    return ret;
  }

  // Description: Some identifiers come in with the wrong characters and need replacements for FS
  static convertToURLSafeId(id: string): string {
    return id.replaceAll("+", "-").replaceAll("/", "_").trim();
  }

  // Description: Some identifiers come in with the wrong characters and to be replaced back for CS
  static convertFromURLSafeId(id: string): string {
    return id.replaceAll("-", "+").replaceAll("_", "/").trim();
  }

  public static async getContentBuffer(file: File): Promise<Uint8Array> {
    return new Promise<Uint8Array>(resolve => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = (e) => {
        resolve(new Uint8Array((e.target as any).result));
      };
    });
  }

  private static decodeStringInternal_(
    input: string,
    pushByte: { (b: any): void; (arg0: any): void },
  ) {
    let nextCharIndex = 0;

    function getByte(default_val: number) {
      while (nextCharIndex < input.length) {
        const ch = input.charAt(nextCharIndex++);
        const b = charToByteMap_[ch];
        if (b != null) {
          return b; // Common case: decoded the char.
        }
        if (!Helpers.isEmptyOrWhitespace(ch)) {
          throw new Error("Unknown base64 encoding at char: " + ch);
        }
        // We encountered whitespace: loop around to the next input char.
      }
      return default_val; // No more input remaining.
    }

    while (true) {
      const byte1 = getByte(-1);
      const byte2 = getByte(0);
      const byte3 = getByte(64);
      const byte4 = getByte(64);

      // The common case is that all four bytes are present, so if we have byte4
      // we can skip over the truncated input special case handling.
      if (byte4 === 64) {
        if (byte1 === -1) {
          return; // Terminal case: no input left to decode.
        }
        // Here we know an intermediate number of bytes are missing.
        // The defaults for byte2, byte3 and byte4 apply the inferred padding
        // rules per the public API documentation. i.e: 1 byte
        // missing should yield 2 bytes of output, but 2 or 3 missing bytes yield
        // a single byte of output. (Recall that 64 corresponds the padding char).
      }

      const outByte1 = (byte1 << 2) | (byte2 >> 4);
      pushByte(outByte1);

      if (byte3 !== 64) {
        const outByte2 = ((byte2 << 4) & 0xf0) | (byte3 >> 2);
        pushByte(outByte2);

        if (byte4 !== 64) {
          const outByte3 = ((byte3 << 6) & 0xc0) | byte4;
          pushByte(outByte3);
        }
      }
    }
  }

  // Description: Determines whether the provided string is a UUID or Base64 encoded string
  static identifyUUIDStringType(str: string): UUIDStringTypes {
    // NOTE: general UUID pattern
    const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

    // NOTE: Pattern specifically tailored for UUIDs encoded in Base64 (22 characters + optional padding)
    const uuidBase64Pattern = /^[A-Za-z0-9-_]{22}(==|)$/;

    return uuidPattern.test(str) ? UUIDStringType.uuid : uuidBase64Pattern.test(str) ? UUIDStringType.base64 : UUIDStringType.unknown;
  }
}
