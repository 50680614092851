import React from "react";
import { Button } from "react-bootstrap";

function DescriptionComponent() {

  const Cards = () => {
    return <>
      <h2 className="intro-title">Hey devs! Welcome to the PreVeil UI Guide</h2>
      <p >Here you will find as assortment of UI components configured to PreVeil's theme/brand for easy access during development</p>
      <p>The idea here is to create a set of already styled building blocks for frequently used components in web preveil.
        These components make use of base bootstrap templates properly styled to fit PreVeil's theme</p>
      <div>
        <Button href="/">Go back to Mail</Button>
      </div>
    </>;
  };

  return <Cards />;
};

export default React.memo(DescriptionComponent);
