/*
* Description: Key Storage Server API and API endpoints
*/
import { IFetchResponse, KSSUserBase, KSSUser } from "@preveil-api";
import { AccountIdentifiers, ApiMethods, BaseAPI, Helpers } from "src/common";

const base_url = `${process.env.REACT_APP_KEYSTORAGE_SERVER}`;
const default_headers: Record<string, string> = {
  "Content-Type": "application/json",
  "accept-version": process.env.REACT_APP_KEYSTORAGE_API_VERSION || "v0"
};
// --------------------------------------------------------------------------------------------
// Saga calls
// --------------------------------------------------------------------------------------------
/*
 * Description: Get KSS User with or without SMS code
 * Request: GET; 
 *    @params: user_id: string
 *    @params: auth_token: string - already stringified (b64Encoded)
 *    @params: sms_code?: string - optional - will return error but will send out SMS code to stored phone
 * Response: { ... }
 */
const getUser = (kss_user: KSSUserBase): Promise<IFetchResponse> => {
  let headers = default_headers
  if (!!kss_user.sms_code) {
    headers = Object.assign({}, default_headers, { "Authorization": `sms ${kss_user.sms_code}` })
  } else if (!!kss_user.totp_code) {
    headers = Object.assign({}, default_headers, { "Authorization": `totp ${kss_user.totp_code}` })
  }
  const url = `${base_url}/users/${kss_user.user_id}`;
  return BaseAPI.get(url, !!kss_user.auth_token ? { auth_token: kss_user.auth_token } : undefined, headers);
}

/*
 * Description: Create a new KSS User with or without SMS code 
 * Request: POST; 
 *    @params: kss_user: KSSUser
 *    @params: auth_token: string - already stringified (b64Encoded)
 *    @params: sms_code?: string - optional - will return error but will send out SMS code to stored phone
 *    @header:  "Authorization": `sms ${kss_user.sms_code}`
 * Response: { ... }
 */
const createUser = (kss_user: Partial<KSSUser>, sms_code?: string): Promise<IFetchResponse> => {
  const headers = !!sms_code ? Object.assign({}, default_headers, { "Authorization": `sms ${sms_code}` }) : default_headers;
  return BaseAPI.post(`${base_url}/users`, kss_user, headers);
}

const rekeyUser = async (account_ids: AccountIdentifiers, auth_token?: string, wrapped_data?: string, server_shard?: string, verify_key?: string, new_auth_token?: string): Promise<IFetchResponse> => {
  const url = `/users/${account_ids.user_id}`;
  const current_key_version = account_ids.user_key.key_version;
  const key_version = !!wrapped_data ? !!new_auth_token ? current_key_version : current_key_version + 1 : undefined;
  const body = { auth_token, wrapped_data, key_version, server_shard, verify_key, new_auth_token };
  const sign_only_with_user_signature = !!new_auth_token;
  const headers = await BaseAPI.prepareSignedRequestHeader(account_ids, url, ApiMethods.patch, body, sign_only_with_user_signature);
  return BaseAPI.patch(`${base_url}${url}`, body, headers);
}

const isRecoverySetUp = async (account_ids: AccountIdentifiers, auth_token: string): Promise<IFetchResponse> => {
  const url = `/users/${account_ids.user_id}/shard_metadata`;
  const body = { auth_token };
  const headers = await BaseAPI.prepareSignedRequestHeader(account_ids, url, ApiMethods.get, body);
  return BaseAPI.get(`${base_url}${url}`, body, headers);
}

const getServerShard = async (user_id: string, email_code?: string, sms_code?: string, totp_code?: string): Promise<IFetchResponse> => {
  const url = `/users/${user_id}/shard`;
  let headers = default_headers
  if (!!email_code && !sms_code && !totp_code) {
    headers = Object.assign({}, default_headers, { "Authorization": `email ${email_code}` })
  } else if (!!sms_code) {
    headers = Object.assign({}, default_headers, { "Authorization": `email ${email_code};sms ${sms_code}` })
  } else if (!!totp_code) {
    headers = Object.assign({}, default_headers, { "Authorization": `email ${email_code};totp ${totp_code}` })
  }
  return BaseAPI.get(`${base_url}${url}`, undefined, headers);
}




export const KeyStorageAPI = {
  getUser,
  createUser,
  rekeyUser,
  getServerShard,
  isRecoverySetUp
}

// --------------------------------------------------------------------------------------------
// RTK Queries Calls:  WORKING
// --------------------------------------------------------------------------------------------
// API and Request API Types
export type GetKSSUsersApiResponse = /** status 200 successful operation */ {
  protocol_version: number;
  wrapped_data: string;
}
export type GetKSSUsersApiError = /** status 401 unath error response */ {
  error: string;
}
