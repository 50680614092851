import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { useAppSelector } from "src/common";

function ProfileFormComponent() {
  const current_profile = useAppSelector((state) => state.account.current_account)?.profile;
  const name = current_profile?.name?.split(/\s+/g) || [];

  return (
    <>
      <h2 className="content-header border-bottom">Name and Email</h2>
      <div className="profile">
        <Row>
          <Col md={4}>First Name</Col>
          <Col>{name[0] || ""}</Col>
        </Row>
        <Row>
          <Col md={4}>Last Name</Col>
          <Col>{name[1] || ""}</Col>
        </Row>
        <Row>
          <Col md={4}>Email</Col>
          <Col>{current_profile?.address || "Email"}</Col>
        </Row>
      </div>
    </>
  );
}

export default React.memo(ProfileFormComponent);
