import * as React from "react";
import { Nav, Navbar, Container, Image, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "src/assets/images/logos/prevel-logo.png";


function UIGuideNavigationComponent() {
  return (
    <header id="preveil-header">
      <Navbar variant="dark" expand="md" fixed="top" className="edit">
        <Container fluid>
          <Navbar.Brand>
            <Link to="/developer/ui-guide">
              <Image alt="PreVeil Logo" src={logo} />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="left-navbar" />
          <Navbar.Collapse id="left-navbar">
            <Nav className="me-auto">
              <Link className="nav-link" to="buttons">
                Buttons
              </Link>
              <Link className="nav-link" to="cards">
                Cards
              </Link>
              <Link className="nav-link" to="public-cards">
                Public Cards
              </Link>
              <Link className="nav-link" to="icons">
                PreVeil Icons
              </Link>
              <Link className="nav-link" to="forms">
                Forms
              </Link>
              <Link className="nav-link" to="modals">
                Modals
              </Link>
              {/*  ADD ADDITIONAL UIKIT COMPONENTS BELOW IN THE DROPDOWN */}
              <Dropdown className="nav-item">
                <Dropdown.Toggle as="a" className="nav-link">
                  More{" "}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Link className="dropdown-item" to="messaging">
                    Messaging
                  </Link>
                  <Link className="dropdown-item" to="tabs">
                    Tabs
                  </Link>
                  <Link className="dropdown-item" to="breadcrumbs">
                    Breadcrumbs
                  </Link>
                  <Link className="dropdown-item" to="pagination">
                    Pagination
                  </Link>
                  <Link className="dropdown-item" to="drag-and-drop">
                    Drag And Drop
                  </Link>
                  <Link className="dropdown-item" to="popovers">
                    Popovers
                  </Link>
                  <Link className="dropdown-item" to="toastrs">
                    Toastrs
                  </Link>
                  <Link className="dropdown-item" to="progress-bar">
                    Progress Bar
                  </Link>
                  <Link className="dropdown-item" to="typography">
                    Typography
                  </Link>
                  <Link className="dropdown-item" to="scrollbar">
                    Scrollbar
                  </Link>

                  <Link className="dropdown-item" to="date-picker">
                    Date Picker
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
              ;
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default React.memo(UIGuideNavigationComponent);
