import React, { FocusEvent } from "react";
import { Row, Col } from "react-bootstrap";
import { ActionHandlerFunction, PaginationItem, Sort } from "@preveil-api";
import { CheckboxStates, CheckboxStatesTypes, DriveUIActionTypes, DriveLimits, DriveTrashItem, SORT_DIRECTION, EntryItem, useAppSelector } from "src/common";
import { Checkbox, Icon, Loading } from "src/components";
import { EmptyMessage } from "../drive-manager";
import { TrashRow, TrashChild } from ".";
import { RootState } from "src/store/configureStore";
import _ from "lodash";

type AllProps = {
  is_loading: boolean;
  handleAction: ActionHandlerFunction;
  trash_list: DriveTrashItem[];
  child_entries?: EntryItem[];
  page?: string;
  pagination?: PaginationItem;
  selected: string[];
  sort: Sort<keyof DriveTrashItem>;
};

function TrashListViewComponent(props: AllProps) {
  const { is_loading, handleAction, pagination, selected, trash_list, child_entries, sort } = props;
  const sort_children = useAppSelector((state: RootState) => state.drive.sort);

  // Description: Get the selected all checkbox state
  function getSelectedState(): CheckboxStatesTypes {
    let ck_state: CheckboxStatesTypes = CheckboxStates.empty;
    if (!!pagination) {
      ck_state = !selected.length
        ? CheckboxStates.empty
        : selected.length < DriveLimits.DRIVE_PAGINATION_PAGE_SIZE &&
          selected.length < pagination.totalRows
          ? CheckboxStates.indeterminate
          : CheckboxStates.checked;
    }
    return ck_state;
  }

  // Descriptions: Shareable react fragment - for each field - returns the header with sort icon
  function headerWithSortIcon(name: string, field: string) {
    const _sort = !!child_entries ? sort_children : sort;
    const length = !!child_entries ? child_entries.length : trash_list.length;
    return (
      <>
        {name}
        {!!_sort && _sort.field === field && length > 0 && (
          <Icon className={`${_sort.direction === SORT_DIRECTION.descending ? "ficon-arrow-down" : "ficon-arrow-up"} small-icon ps-1`} />
        )}
      </>
    );
  }

  // Description: Render the rows of root trash view
  function RenderList(_entries: DriveTrashItem[]) {
    return (
      <div className="list-body">
        {_.map(_entries, (entry: DriveTrashItem, i: number) => {
          return (
            <TrashRow
              key={`item_${i}`}
              entry={entry}
              handleAction={handleAction}
              selected={selected.includes(entry.id)}
            />
          );
        })}
      </div>
    );
  }

  // Description: Render the rows of children of sub folders (when navigating)
  function RenderChildList(_entries: EntryItem[]) {
    return (
      <div className="list-body">
        {_.map(_entries, (entry: EntryItem, i: number) => {
          return (
            <TrashChild
              key={`item_${i}`}
              entry={entry}
              handleAction={handleAction}
            />
          );
        })}
      </div>
    );
  }

  // Description: Handles Sorting depending on whether we are in a child directory or in the trash root. 
  function handleSort(field: string) {
    if (!!child_entries) {
      handleAction({ actionType: DriveUIActionTypes.SetSortChildren, params: { field } });
    } else {
      handleAction({ actionType: DriveUIActionTypes.SetSort, params: { field } });
    }
  }

  return (
    <div className={`drive-list trash${is_loading ? " isloading" : ""}`}>
      {((!!trash_list && trash_list.length > 0 && !child_entries) || (!!child_entries && child_entries.length > 0)) && (
        <Row className="header-row">
          {!child_entries && <Col md="1">
            <Checkbox
              className="sr-only"
              onChange={(e: FocusEvent<HTMLInputElement>) => {
                handleAction({
                  actionType: DriveUIActionTypes.Select,
                  params: { selected: e.target.checked },
                });
              }}
              value="all"
              label="Select All"
              selected={getSelectedState()}
            />
          </Col>}
          <Col md="5" className="col ps-2" onClick={() => handleSort("name")}>{headerWithSortIcon("Name", "name")}</Col>
          <Col md="2" onClick={() => handleSort("type_label")}>{headerWithSortIcon("Type", "type_label")}</Col>
          <Col md="3" onClick={() => handleSort("deleted_at")}>{headerWithSortIcon("Deleted At", "deleted_at")}</Col>
          <Col md="1">
            <span hidden>actions</span>
          </Col>
        </Row>
      )}
      {!is_loading && (!!trash_list && trash_list.length === 0 && !child_entries) && <EmptyMessage message="You have no items in Trash." />}
      {!is_loading && (!!child_entries && child_entries.length === 0) && <EmptyMessage message="There are no items in this folder." />}

      {!!child_entries ? RenderChildList(child_entries) : !!trash_list && trash_list.length > 0 && RenderList(trash_list)}

      {is_loading && <Loading />}
    </div>
  );
}

export default React.memo(TrashListViewComponent);
