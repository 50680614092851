/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
// @ts-ignore
import { Key as KeyPB } from "../protos/keys_pb";

export abstract class SymmKey {
  constructor(
    public protocol_version: number,
    public key: Uint8Array
  ) {}

  public get buffer(): KeyPB {
    let pbInst = new KeyPB();
    pbInst.setProtocolVersion(this.protocol_version);
    pbInst.setKey(this.key);
    return pbInst;
  }

  public serialize(): Uint8Array {
    return this.buffer.serializeBinary();
  }

  abstract encrypt(plaintext: Uint8Array, iv?: Uint8Array): Promise<Uint8Array>;
  abstract decrypt(ciphertext: Uint8Array): Promise<Uint8Array>;
}
