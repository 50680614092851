import React, { ErrorInfo, useEffect } from "react";
import { Container, Card, Button } from "react-bootstrap";
import { useAppDispatch, RunMode, GlobalErrorMessages, MessageHandlerDisplayType, Message } from "src/common";
import { uiActions } from "src/store";
type AllProps = {
  error: Error;
  errorInfo: ErrorInfo;
  resetErrorBoundary: () => void;
};

function ErrorComponent(props: AllProps) {
  const { error, errorInfo, resetErrorBoundary } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    (!!error || !!errorInfo) &&
      dispatch(uiActions.handleRequestErrors(new Message(GlobalErrorMessages.default, MessageHandlerDisplayType.logger), { error, errorInfo }));
  }, [error, errorInfo]);

  return <Container className="my-5">
    {process.env.NODE_ENV !== RunMode.production ?
      <Card>
        <Card.Header as="h2">
          Something went wrong.
        </Card.Header>
        <Card.Body>
          <details>
            {error && error.toString()}
            <br />
            {errorInfo.componentStack}
          </details>
          <Button onClick={resetErrorBoundary} className="mt-5">Try again</Button>
        </Card.Body>
      </Card> :
      <Card>
        <Card.Body>
          <h1 className="intro-title">PreVeil System Message</h1>
          <hr />
          <p>
            PreVeil is updating. You may need to log into your account again. </p>
          <p>If you have a question, please contact us at <a href="mailto:support@preveil.com" target="_top">support@preveil.com</a>.
          </p>
          <p >We apologize for the inconvenience.</p>
          <Button onClick={() => window.location.reload()} className="mt-5">Go Back</Button>
        </Card.Body>
      </Card>
    }
  </Container>;
}

export default React.memo(ErrorComponent);
