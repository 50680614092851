import * as React from "react";
import { MailNavigation, DriveNavigation, AdminNavigation, SettingsNavigation, Feedback } from "./";
import { PanelState, useAppSelector, PanelStateType } from "src/common";
import ToggleLeftNav from "./ToggleLeftNavigation.component";
import { RootState } from "src/store/configureStore";

type AllProps = {
  type: string;
  active_path?: string;
  handleAction: (state: PanelStateType) => void;
}
const components = {
  MailNavigation,
  DriveNavigation,
  AdminNavigation,
  SettingsNavigation
  // others ... to be added
};

function LeftNavigationComponent(props: AllProps) {
  const { type, active_path, handleAction } = props;
  const current_account = useAppSelector((state: RootState) => state.account.current_account);
  const organization = useAppSelector((state: RootState) => state.account.organization);
  const state = useAppSelector((state: RootState) => state.ui.left_nav_state);

  // Description:  Renders the speficic links to the module
  const ComponentLinks = () => {
    const TagName = (components as any)[type];
    return (!!TagName) ?
      <TagName handleAction={handleAction} active_path={active_path} current_account={current_account} organization={organization} /> :
      <></>;
  };

  return <div className={`${state === PanelState.off ? " closed" : ""}`}>
    <div className="toggle-box">
      <ToggleLeftNav handleAction={handleAction} />
      <p>
        {current_account?.display_name || "My Account"}
        <small>{!!organization ? organization.org_name : current_account?.user_id}</small>
      </p>
    </div>
    <div className="left-navigation">
      <ComponentLinks />
      <hr />
      <Feedback type={type} />
    </div>
  </div>;
}

export default React.memo(LeftNavigationComponent);
