import React, { useState, useEffect, FocusEvent, forwardRef, useImperativeHandle } from "react";
import { Button } from "react-bootstrap";
import { dayjs, NodePermissionTypes, NodePermissionType, isSameDate } from "src/common";
import { Icon } from "src/components";
import DatePicker from "react-datepicker";

type AllProps = {
  expiration?: string;
  type: NodePermissionTypes;
  disabled: boolean;
  handleAction: (new_expiration: string) => void;
  handleReset: () => void;
};

// Description:
const EditExpirationComponent = forwardRef(function EditExpirationComponent(props: AllProps, ref) {
  const { expiration, type, disabled, handleReset, handleAction } = props;
  const [show, setShow] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [expiration_date, setExpirationDate] = useState<Date | null>(!!expiration ? new Date(expiration) : null);
  const [expiration_string, setExpirationString] = useState<string>(!!expiration ? getDateString(expiration, disabled) : "");

  useEffect(() => {
    setShow(type !== NodePermissionType.edit_and_share && type !== NodePermissionType.unshare);
  }, [type]);

  useEffect(() => {
    setExpirationDate(!!expiration ? new Date(expiration) : null);
    setExpirationString(!!expiration ? getDateString(expiration, disabled) : "");
  }, [expiration]);

  useImperativeHandle(ref, () => ({
    // Description: Reset to saved values
    handleReset(saved_expiration: string) {
      setExpirationDate(!!saved_expiration ? new Date(saved_expiration) : null);
      setExpirationString(!!saved_expiration ? getDateString(saved_expiration, disabled) : "");
    }
  }));

  // Description: Handle changes to datepicker and validate date. If valid send to parent
  function handleChange(input?: string) {
    const is_valid = !!input && handleClientSideValidation(input);
    if (is_valid) {
      const is_same = isSameDate(input, expiration);
      // Adjust to todays date end of date so it is accepted
      const input_adjusted = dayjs(input).isToday() ? dayjs.utc(input).endOf("date").toString() : input;
      const _string = is_same && !!expiration ? expiration : input_adjusted;
      setExpirationDate(new Date(_string));
      setExpirationString(getDateString(_string, !is_same));
      handleAction(_string);
    } else {
      handleReset();
    }
  };

  // Description: clear current selected date 
  function handleClear() {
    setExpirationString("");
    setExpirationDate(null);
    handleAction("");
  }

  function handleClientSideValidation(input: string) {
    if (dayjs(input).isValid()) {
      const is_today = dayjs(input).isToday();
      const is_expired = dayjs(input).isBefore(Date.now()) && !is_today;
      const error_message = is_expired ? "Expiration date can't be in the past" : null;
      !!error_message && setError(error_message);
      return !error_message;
    } else {
      input.trim().length > 0 && setError("The expiration date selected is invalid");
      return false;
    }
  }

  // Description: Get proper display version of the date for read only mode
  function getDateString(_expiration: string, short: boolean = false) {
    return short ? dayjs.utc(_expiration).format("l") : dayjs.utc(_expiration).format("MMM D, YYYY hh:mm A");
  }

  return show ?
    <>
      {
        !disabled ?
          <>
            <div className="expiration-input">
              <Button variant="icon" size="sm">
                <Icon className="pv-icon-calendar text-muted" />
              </Button>
              <DatePicker
                calendarClassName="drive-expiration"
                popperClassName="pt-0"
                className="border-0 input"
                focusSelectedMonth={false}
                minDate={dayjs().toDate()}
                selected={expiration_date}
                onChange={(date) => handleChange(date?.toDateString())}
                onBlur={(event: FocusEvent<HTMLInputElement, Element>) => handleChange(event.target.value)}
                onFocus={(event: FocusEvent<HTMLInputElement, Element>) => setError(undefined)}
                selectsEnd
                placeholderText="Choose Date"
                dateFormat="MM/dd/yyyy"
                shouldCloseOnSelect={true}
                autoComplete={"off"} />
              {
                !!expiration_string &&
                <Button variant="icon" size="sm" onClick={handleClear}>
                  <Icon className="ficon-x" />
                </Button>
              }
            </div>
            {
              !!error &&
              <small className="expiration-error">{error}</small>
            }
          </>
          : <span>{expiration_string}</span>
      }
    </> : null;
});

export default React.memo(EditExpirationComponent);
