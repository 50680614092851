import React, { useEffect, useState, MouseEvent, FocusEvent } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Badge } from "react-bootstrap";
import { Thread, ActionHandlerFunction, UserProfile, MailboxItem } from "@preveil-api";
import {
  useAppSelector, MailUIActionTypes, MailMessages, isSentMailbox, isDraftMailbox, MailThread, CheckboxStates, ComposeTypes,
  getSearchFolderUrlParam, isSearchMailbox, DefaultRoutes, DriveUIActionTypes
} from "src/common";
import { Checkbox, Icon, UserEmail } from "src/components";
import _ from "lodash";

type AllProps = {
  user_id: string;
  thread: Thread;
  current_mailbox: MailboxItem;
  page?: string;
  selected: boolean;
  handleAction: ActionHandlerFunction;
  dragging_threads?: string[];
}

function MessageRowComponent(props: AllProps) {
  const { current_mailbox, thread, page, selected, user_id, handleAction, dragging_threads } = props;
  const mailbox_name = current_mailbox.role;
  const [_selected, setSelected] = useState<boolean>(selected);
  const [starred, setStarred] = useState<boolean>(thread.starred);
  const default_mailboxes = useAppSelector((state) => state.mail.default_mailboxes);
  const custom_mailboxes = useAppSelector((state) => state.mail.custom_mailboxes);
  const navigate = useNavigate();
  const [class_name, setClassName] = useState<string>("");

  useEffect(() => {
    !!dragging_threads && dragging_threads.includes(thread.thread_id) ? setClassName("dragging-item") : setClassName("");
  }, [dragging_threads]);

  useEffect(() => {
    setSelected(selected);
  }, [selected]);

  // Description: Set star immediately for UI visual - NOTE: It will revert on error
  useEffect(() => {
    setStarred(thread.starred);
  }, [thread]);

  // Description: On row click navigate to the thread view Unless draft 
  // NOTE: Get mailbox from Thread (for search mailbox); will be undefined in web threads
  function NavigateToView(e: MouseEvent<HTMLTableRowElement>) {
    const mailbox_param = getMailboxUrlParam(thread, current_mailbox);
    (isDraftMailbox(current_mailbox.role)) ?
      handleAction({ actionType: MailUIActionTypes.AddComposeMessage, params: { message: thread.messages[0], content_type: ComposeTypes.draft } }) :
      !!mailbox_param ? navigate(`/mail/${encodeURIComponent(mailbox_param)}/${page || "1"}/${encodeURIComponent(thread.url_identifier)}`) :
        navigate(`${DefaultRoutes.mail_default}`);
    return false;
  }

  // Description: Get the url params for different mailbox types 
  function getMailboxUrlParam(thread: Thread, current_mailbox: MailboxItem): string | null {
    return isSearchMailbox(mailbox_name) ? getSearchFolderUrlParam(thread, default_mailboxes, custom_mailboxes) :
      !!current_mailbox.url_identifier ? current_mailbox.url_identifier :
        !!thread.mailbox_name ? thread.mailbox_name : mailbox_name;
  }

  // Description: Handle the click of checkbox and changes of checkbox state 
  function handleCheckboxChange(e: FocusEvent<HTMLInputElement>) {
    const new_selected = !_selected;
    setSelected(new_selected);
    handleAction({ actionType: MailUIActionTypes.Select, params: { thread, selected: new_selected } });
  }

  // Description:  Handle click of the row star
  function handleStarClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    e.preventDefault();
    setStarred(!thread.starred);
    handleAction({ actionType: MailUIActionTypes.Star, params: { messages: thread.messages, is_starred: thread.starred } });
  }

  // Description: Get classess for row
  function getClassList() {
    const row_type = isDraftMailbox(mailbox_name) ? "draft" : thread.unread ? "unread" : "";
    return `${row_type}${_selected ? " active" : ""} ${class_name}`;
  }

  // Description: Build list for the sender field depending on Mailbox
  function SenderItems() {
    const _self_mailboxes = (isSentMailbox(mailbox_name) || isDraftMailbox(mailbox_name));
    const _profiles = _self_mailboxes ?
      [...thread.messages[0].tos, thread.messages[0].sender] : thread.thread_sender_list;
    const modified = MailThread.getDisplaySendersField(_profiles, user_id, _self_mailboxes);
    return <div className="sender">
      {
        _self_mailboxes && <>To: </>
      }
      {
        _.map(modified.user_list, (profile: UserProfile, i: number) => {
          const not_last = modified.user_list.length > i + 1;
          return <React.Fragment key={`profile_${i}`}>
            <UserEmail
              abbreviated
              email={profile.address}
              display_name={profile.name}
              external_email={profile.external_email || null} />
            {not_last && ", "}
          </React.Fragment>;
        })
      }
      {
        !!modified.others_string &&
        <small title={modified.others_tooltip}>{modified.others_string}</small>
      }
    </div>;
  }

  return <tr onClick={NavigateToView}
    draggable={true}
    onDragStart={(e) => handleAction({ actionType: DriveUIActionTypes.OnDrag, params: { e, thread } })}
    onDragEnd={(e) => handleAction({ actionType: DriveUIActionTypes.OnDragEnd })}
    className={getClassList()}>
    <td>
      <Checkbox
        onChange={(e: FocusEvent<HTMLInputElement>) => { handleCheckboxChange(e); }}
        selected={_selected ? CheckboxStates.checked : CheckboxStates.empty}
        value={thread.thread_id} />
    </td>
    <td>
      <Button variant="icon" size="sm" onClick={handleStarClick} title={`${starred ? "Unstar" : "Star"} this thread`}>
        {
          starred ?
            <Icon className="pv-icon-starred" /> :
            <Icon className="pv-icon-unstarred" />
        }
      </Button>
    </td>
    <td className="mail-body">
      {SenderItems()}
      <p className="subject">
        {
          !!thread.subject && thread.subject.length > 0 ?
            thread.subject : <i className="text-muted fs-5">{MailMessages.message_no_subject}</i>
        }
      </p>
      <p className="snippet">
        {
          !!thread.snippet && thread.snippet.length > 0 ?
            thread.snippet : <i className="text-muted fs-5">{MailMessages.message_no_body}</i>
        }
      </p>
    </td>
    <td className="mail-info">
      <p>{thread.date}</p>
      {
        thread.attachment_count > 0 &&
        <Icon className="ficon-paperclip" />
      }
      {
        thread.message_count > 1 &&
        <Badge pill >{thread.message_count}</Badge>
      }
    </td>
  </tr >;
}

export default React.memo(MessageRowComponent);
