import React from "react";
import { ActionHandlerFunction } from "@preveil-api";
import { AdminUIActionTypes } from "src/common";
import { Icon } from "src/components";
import { Button, Col, Row } from "react-bootstrap";


type AllProps = {
  handleAction: ActionHandlerFunction;
  selected: string[];
  is_loading: boolean;
  total_items: number;
};

function EmailGroupToolbarComponent(props: AllProps) {
  const { handleAction, is_loading, total_items, selected } = props;
  const groups_label = total_items === 1 ? "Group" : "Groups";
  return (
    <Row className="toolbar m-0">
      <Col className="order-1">
        <Button
          variant="icon"
          size="sm"
          onClick={() => handleAction({ actionType: AdminUIActionTypes.ShowModal })}
          title="Create Group"
          data-tooltip-id="pv-tooltip"
          data-tooltip-content="Create Group"
        >
          <Icon className="ficon-plus" />
        </Button>
        <Button
          className="action-toolbar-buttons"
          variant="icon"
          size="sm"
          onClick={() => handleAction({ actionType: AdminUIActionTypes.Copy })}
          disabled={selected.length !== 1}
          title="Copy Group"
          data-tooltip-id="pv-tooltip"
          data-tooltip-content="Copy Group"
        >
          <Icon className="ficon-copy" />
        </Button>
        <Button
          className="action-toolbar-buttons"
          variant="icon"
          size="sm"
          onClick={() => handleAction({ actionType: AdminUIActionTypes.Delete })}
          disabled={selected.length === 0}
          title="Delete Selected"
          data-tooltip-id="pv-tooltip"
          data-tooltip-content="Delete Selected"
        >
          <Icon className="ficon-trash-2" />
        </Button>
      </Col>
      <Col className="text-end order-sm-3 order-4">
        <span className="total-addresses">
          <b >{total_items}</b>
          {groups_label}
        </span>
    </Col>
      <Col className="divider order-sm-3 order-1"></Col>
      <Col xs="auto" className="p-0 order-sm-3 order-4 ms-1 refresh">
        <Button
          variant="icon"
          size="sm"
          onClick={() => handleAction({ actionType: AdminUIActionTypes.Refresh })}
          title="Refresh Email Groups"
        >
          {is_loading ? <i className="spinner"></i> : <Icon className="ficon-rotate-cw" />}
        </Button>
      </Col>
    </Row>
  );
}

export default React.memo(EmailGroupToolbarComponent);
