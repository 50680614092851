import { CryptoThreadItem, ThreadMessage, CollectionThreadItem, JSONEmailLatest, JSONEmail } from "@preveil-api";
import { isValidMailProtocol, Account, findWrappedKey, Helpers, KeyFactory, decryptAndVerifyEmail, MailFlags, MailErrorMessages } from "src/common";
import _ from "lodash";

// Description: Class to handle threads retrieved from Collection directly and convert to working client threads
export class WebThread {
  thread?: CryptoThreadItem;
  _mailbox_id!: string;
  constructor(
    public thread_id: string,
    public messages: Array<ThreadMessage | undefined>,
    mailbox_id: string) {
    this._mailbox_id = mailbox_id;
    this.thread = this.parseToClientThread();
  }

  // Description: Convert Webthread to CryptoThreadItem and filter errors and duplicate mails
  parseToClientThread(): CryptoThreadItem | undefined {
    const messages = this.filterInvalidMessages();
    if (messages.length > 0) {
      return {
        mailbox_name: "",
        messages,
        thread_id: this.thread_id
      };
    }
  }

  // Description: filter errors and duplicate mails
  private filterInvalidMessages(): ThreadMessage[] {
    // NOTE: Remove undefined object (artifact from the decryptMessage process)
    const messages = _.compact(this.messages);
    // NOTE: Remove duplicate messages when emailing self
    const unique_messages = _.groupBy(messages, "message_id");
    const filtered_messages = _.filter(messages, (_message: ThreadMessage) => {
      const messages_with_id = unique_messages[_message.message_id].length;
      if (messages_with_id > 1) {
        // Note: In the case there are multiple messages with the same message_id
        // only include those within the current mailbox.
        return _message.mailbox_id === this._mailbox_id;
      } else {
        return messages_with_id === 1;
      }
    });
    return filtered_messages;
  }

  // Decription: Handle threads from Collection call
  // Note: Collate Valid messages -is valid protocol, remove any message with is_deleted set to true OR with DELETED_FLAG
  static async initWeb(thread: CollectionThreadItem, account: Account, mailbox_id: string): Promise<WebThread | null> {
    const encrypted_valid_messages = this.isValidMessage(thread.messages);
    // Note: begin decrypting remaining messages
    const messages = await Promise.all(_.map(encrypted_valid_messages,
      async (message: JSONEmail) => {
        return await WebThread.decryptMessage(message, account);
      }));

    // Return an instance of WebThread
    return !!(messages && messages.length > 0) ?
      new WebThread(thread.thread_id, messages, mailbox_id) : null;
  }

  // Decription: Decrypt Collection message
  static async decryptMessage(message: JSONEmail, account: Account):
    Promise<ThreadMessage | undefined> {
    const wrapped_key = findWrappedKey(message as JSONEmailLatest);
    const user_key = !!wrapped_key ? account.getUserKeyWithVersion(wrapped_key.user_key_version) : null;
    if (!!user_key && !!wrapped_key) {
      try {
        const unwrapped = await user_key.encryption_key.unseal(Helpers.b64Decode(wrapped_key.message_wrappred_key));
        const decryption_key = KeyFactory.deserializeSymmKey(unwrapped);
        const decrypted_verified_message = await decryptAndVerifyEmail(message, decryption_key, user_key);
        if (decrypted_verified_message.verified && !!decrypted_verified_message.message) {
          return decrypted_verified_message.message;
        } else {
          console.error("There was an error verifying the decrypted message: ", message.id);
        }
      } catch (m) {
        console.log("There was an error with the wrapped_key", m);
      }
    } else {
      // NOTE: return error within messsage object to display others
      return {
        attachments: [],
        body_ids: [],
        protocol_version: -1,
        date: message.timestamp,
        flags: [],
        is_local: false,
        mailbox_id: "",
        mailbox_name: null,
        message_id: message.id,
        other_headers: {},
        rev_id: -1,
        snippet: "",
        thread_id: "",
        uid: -1,
        bccs: [],
        ccs: [],
        in_reply_to: null,
        references: [],
        sender: { address: "", name: "" },
        subject: MailErrorMessages.error_getting_message,
        tos: [],
        unique_id: "",
        text: null,
        html: null,
        error: MailErrorMessages.error_getting_message
      };
    }
  }

  // Decription: remove deleted messages and some without proper protocol version 
  static isValidMessage(messages: JSONEmail[]) {
    return _.filter(messages, (message: JSONEmail) => {
      const is_flag_deleted = message.flags.includes(MailFlags.DELETED_FLAG);
      return !is_flag_deleted && !message.is_deleted && !message.is_expired && isValidMailProtocol(message.protocol_version);
    });
  }
}
