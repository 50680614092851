
import {
  Account, PermissionSetType, AppUserKey, getEnumKey, EntryItem, SORT_DIRECTION, DriveEntryTypes, decryptItemName,
  getDirSymmKey, sortBy, ACL, Grant, DirectoryEntity, ACLInfo, PathNode, mapEntryType, PathList, DriveRoot, Helpers, GrantSetType,
  GrantSet, dayjs, LinkEntity, DriveDirectoryPaginated, NodeIdentifier, FSEmptyLinkedCollectionID, EntryItemLocal, isEntryLocked, DriveEntryType,
  CollectionEntity, SELECTIVE_SYNC_STATES
} from "src/common";
import { FSMessage, FSType, FSWrappedKey } from "src/common/keys/protos/collections_pb";
import { SymmKey } from "src/common/keys";
import _ from "lodash";

export class Directory implements DirectoryEntity {
  collection_id!: string;
  id!: string;
  maintainer_id?: string;
  entries!: EntryItem[];
  acl?: ACLInfo | null;
  localSyncStatus!: SELECTIVE_SYNC_STATES;
  path!: PathNode[];
  path_list?: PathNode[];
  wrapped_key?: FSWrappedKey;
  symmetric_key?: SymmKey;
  version!: string;
  grants?: GrantSetType;
  parent_id?: string;
  constructor(
    directory_entity: DirectoryEntity
  ) {
    Object.assign(this, directory_entity);
  }

  get item(): DirectoryEntity {
    return {
      id: this.id,
      collection_id: this.collection_id,
      maintainer_id: this.maintainer_id,
      entries: this.entries,
      acl: this.acl,
      localSyncStatus: this.localSyncStatus,
      path: this.path,
      path_list: this.path_list,
      wrapped_key: this.wrapped_key,
      symmetric_key: this.symmetric_key,
      version: this.version,
      grants: this.grants,
      parent_id: this.parent_id
    };
  }

  // Description: Initialize a new Directory
  static async init(
    collection_id: string,
    dir: FSMessage.Dir,
    current_account: Account,
    permissions: PermissionSetType,
    grant?: Grant,
    grants?: GrantSetType
  ): Promise<Directory | undefined> {
    const id = dir.getId_asB64();
    const maintainer_id = dir.getMaintainerId_asB64();
    const entry_list = dir.getEntriesList();
    const dir_symm_key = dir.getWrappedDirKey();
    const reader_user_key = !!grant ? await grant.key(current_account) : undefined;
    const symmetric_key = !!dir_symm_key ? await getDirSymmKey(dir_symm_key, current_account, permissions, reader_user_key) : undefined;
    if (!!symmetric_key) {
      const entries = await getDecryptedEntries(collection_id, entry_list, symmetric_key);
      const acl_list = dir.getAclList();
      const acl = !!acl_list ? new ACL(acl_list, id, current_account.user_id).info : null;
      const _list = dir.getPathList();
      const directory_entity = {
        id,
        collection_id,
        maintainer_id,
        entries: sortBy(entries, { field: "name", direction: SORT_DIRECTION.ascending }),
        acl,
        path_list: _list.length > 0 ? new PathList(collection_id, _list).path_list : undefined,
        localSyncStatus: SELECTIVE_SYNC_STATES.OFF,
        wrapped_key: dir_symm_key,
        symmetric_key,
        version: dir.getVersion_asB64(),
        grants,
        parent_id: dir.getParentId_asB64()
      };
      return new Directory(directory_entity);
    }
  }


  // Description: parse paginated Directory calls Object into DirectoryEntity 
  static parseFSDirectory(_directory: DriveDirectoryPaginated, identifiers: NodeIdentifier, dir?: FSMessage.Dir): DirectoryEntity {
    return {
      id: identifiers.id,
      collection_id: identifiers.collection_id,
      entries: getSortedFSEntryItem(_directory.entries, identifiers.collection_id),
      acl: _directory.acl || null,
      path: PathList.buildPathInfoFromFilesyncPaths(_directory.path, identifiers.collection_id),
      localSyncStatus: _directory.localSyncStatus,
      version: dir?.getVersion_asB64() || "",
      parent_id: dir?.getParentId_asB64()
    };
  }

  // Description: parse drive/link call directory info Object into DirectoryEntity 
  static parseFSLink(_directory: DriveRoot, identifiers: NodeIdentifier): DirectoryEntity {
    return {
      id: identifiers.id,
      collection_id: identifiers.collection_id,
      entries: getSortedFSEntryItem(_directory.entries, identifiers.collection_id),
      acl: _directory.rootacl || null,
      path: [],  // NOTE: link call does not return Path[]
      localSyncStatus: _directory.localSyncStatus,
      version: ""
    };
  }

  // Description: parse main DriveRoot call Object into DirectoryEntity 
  static parseFSRootDirectory(browse_drive: DriveRoot, collection_id: string): DirectoryEntity {
    return {
      id: browse_drive.rootid,
      collection_id: browse_drive.id,
      entries: getSortedFSEntryItem(browse_drive.entries, collection_id),
      acl: browse_drive.rootacl || null,
      path: [],
      localSyncStatus: browse_drive.localSyncStatus,
      version: "",
    };
  }

  // Description: Get Directory Name from FSMessage.Dir Maintainer scopename content and key version
  // This only works for V2 collections
  static async getDirectoryMaintainersName(dir: FSMessage.Dir, current_account: Account, grant_set: GrantSetType): Promise<string | undefined> {
    const key_version = dir.hasMaintainerScopedName() ? dir.getMaintainerScopedName()?.getKeyVersion() : dir.getWrappedDirKey()?.getKeyVersion();
    const name_content = dir.hasMaintainerScopedName() ? dir.getMaintainerScopedName()?.getContent_asU8() : undefined;
    const grant = !!grant_set ? GrantSet.getGrantbyRole(grant_set, key_version) : undefined;
    const reader_user_key = !!grant ? await grant.key(current_account) : undefined;
    return (!!reader_user_key && !!name_content) ? Helpers.utf8Decode(await reader_user_key.encryption_key.unseal(name_content)) : undefined;
  }

  // Description: Build ACL Node Directory from ACL_TREE > getDirsList() > FSMessage.Dir
  static buildDirFromACLTree(entries: EntryItem[], link: LinkEntity): Directory {
    const directory_entity = {
      id: link.id,
      collection_id: link.collection_id,
      maintainer_id: link.maintainer_id,
      entries: sortBy(entries, { field: "name", direction: SORT_DIRECTION.ascending }),
      acl: null,
      path_list: [],
      localSyncStatus: SELECTIVE_SYNC_STATES.OFF,
      version: link.version
    };
    return new Directory(directory_entity);
  }

  // Description: Convert Current Directory into an EntryItem
  static parseDirectorytoEntry(directory: DirectoryEntity, directory_name: string, collection_info: CollectionEntity, link_entity?: LinkEntity): EntryItem {
    const type = !!link_entity ? DriveEntryType.LINK : DriveEntryType.DIR;
    const entry_info = mapEntryType(type, directory_name);
    return {
      id: !!link_entity ? link_entity.id : directory.id, // !!linked_entity ? linked_entity.id : directory.id
      collection_id: !!link_entity ? link_entity.collection_id : directory.collection_id,
      linked_collection_id: !!link_entity && !!link_entity.linked_collection_id ? link_entity.linked_collection_id : "",
      name: directory_name,
      type,
      size: 0,
      deleted: false,
      deleted_at: 0,
      lastModificationDate: "",
      localSyncStatus: directory.localSyncStatus,
      type_label: entry_info?.type_label,
      mapped_type: entry_info?.mapped_type,
      type_class: entry_info?.type_class,
      maintainer_id: !!link_entity ? undefined : collection_info.maintainer_id,
      is_locked: collection_info.permissions.locked,
      parent_id: !!link_entity ? link_entity?.parent_id : directory.parent_id
    };
  }
}

// Description: Decrypt and build Entries
async function getDecryptedEntries(collection_id: string, entry_list: FSMessage.Dir.Entry[], directory_symmetric_key: SymmKey, user_key?: AppUserKey):
  Promise<EntryItem[]> {
  return await Promise.all(_.map(entry_list, async (entry: FSMessage.Dir.Entry) => {
    const _date = entry.getLastUpdate();
    const latest_date = entry.hasLastLocalFsUpdate() && dayjs(entry.getLastLocalFsUpdate()).isValid() ? dayjs.utc(entry.getLastLocalFsUpdate()).local() :
      !!_date ? dayjs.unix(_date) : undefined;
    const decoded_name = await decryptItemName(entry.getName_asU8(), directory_symmetric_key, user_key) || "";
    const type = getEnumKey(FSType, entry.getType()) as DriveEntryTypes || null;
    const entry_info = !!type ? mapEntryType(type, decoded_name) : null;
    const lock_info = entry.getLockInfo()?.toObject();
    return {
      id: entry.getId_asB64(), // NOTE: THIS IS THE DIRECTORY ID for root_node entry.getId_asB64() OR Helpers.b64Encode(entry.getId_asU8(), true)
      collection_id,
      name: decoded_name,
      type,
      size: entry.getFileSize() || 0,
      deleted: entry.getIsDeleted() || false,
      deleted_at: entry.getDeletedAt() || 0,
      lastModificationDate: !!latest_date ? latest_date.format("MM/DD/YYYY h:mm A") : "",
      localSyncStatus: SELECTIVE_SYNC_STATES.OFF,
      // entry_info,
      type_label: entry_info?.type_label,
      mapped_type: entry_info?.mapped_type,
      type_class: entry_info?.type_class,
      // NOTE: only applicable to links:
      lock_info,
      linked_collection_id: entry.getLinkedCollectionId_asB64(),
      maintainer_id: entry.getMaintainerId_asB64(),
      is_locked: isEntryLocked(lock_info)
    };
  }));
}

// Description: Parse and complete Filesync EntryItem objects
function getSortedFSEntryItem(_entries: EntryItemLocal[], collection_id: string): EntryItem[] {
  let entries = _.map(_entries, (entry: EntryItemLocal) => {
    const entry_info = mapEntryType(entry.type, entry.name, entry.localSyncStatus);
    const id = Helpers.convertFromURLSafeId(entry.id);
    const linked_collection_id = (!!entry.linkedCollectionID && entry.linkedCollectionID !== FSEmptyLinkedCollectionID) ?
      Helpers.convertFromURLSafeId(entry.linkedCollectionID) : "";
    const lock_info = entry.lockInfo;
    const _entry = _.omit(entry, ["linkedCollectionID", "id", "lockInfo"]);
    return {
      ..._entry,
      ...{
        id,
        collection_id,
        // NOTE: get rest of the EntryItem info
        type_label: entry_info?.type_label,
        mapped_type: entry_info?.mapped_type,
        type_class: entry_info?.type_class,
        linked_collection_id,
        lock_info,
        is_locked: isEntryLocked(lock_info)
      }
    };
  });
  entries = sortBy(entries, { field: "name", direction: SORT_DIRECTION.ascending });
  return entries;
}
