/*
* Description: User class on the client side
*
*/
import { BuildModeType, IAppConfiguration, OSInfo } from "@preveil-api";
import { BuildMode, RunMode, AuthenticationConstants, account_types, LocalAccountStorage, SupportedPlatform } from "src/common";

export class AppConfiguration implements IAppConfiguration {
  build_mode: BuildModeType; // legacy: buildFor
  os_info: OSInfo;
  browser: string;
  run_mode: string; // legacy: process.env.runMode;
  crypto_server: string;
  local_websocket_server: string;
  local_webapp_server: string;
  backend_server: string;
  backend_websocket_server: string;
  filesync_server: string;
  ready: boolean;
  constructor() {
    this.build_mode = `${process.env.REACT_APP_BUILD_MODE as BuildModeType}`;
    this.os_info = this.getOSInfo();
    this.browser = this.getBrowser();
    this.run_mode = `${process.env.NODE_ENV}`;
    this.crypto_server = `${process.env.REACT_APP_CRYPTO_SERVER}`;
    this.local_websocket_server = `${process.env.REACT_APP_LOCAL_WEBSOCKET_SERVER}`;
    this.local_webapp_server = this.buildForWeb() ? `${process.env.REACT_WEB_PORT}` : `${process.env.REACT_APP_PORT}`;
    this.backend_server = `${process.env.REACT_APP_BACKEND_SERVER}`;
    this.backend_websocket_server = `${process.env.REACT_APP_BACKEND_WEBSOCKET_SERVER}`;
    this.filesync_server = `${process.env.REACT_APP_FILESYNC_SERVER}`;
    this.ready = true;
  }

  buildForWeb(): boolean {
    return this.build_mode === BuildMode.web;
  }

  buildForPreveilExpress(): boolean {
    return this.build_mode === BuildMode.express;
  }

  buildForApp(): boolean {
    return this.build_mode === BuildMode.app;
  }

  getOSInfo(): OSInfo {
    let user_platform: string;
    let default_MUA: string;
    let file_manager: string;
    let data_export_example_path: string;

    if (navigator.userAgent.search("Mac") !== -1) {
      user_platform = SupportedPlatform.OSX;
      file_manager = "Finder";
      default_MUA = "Apple Mail";
      data_export_example_path = "/Users/(username)/Desktop/New-Export-Folder";
    } else if (navigator.userAgent.search("Windows") !== -1) {
      user_platform = SupportedPlatform.WIN;
      file_manager = "Explorer";
      default_MUA = "Outlook";
      data_export_example_path = "c:\\Users\\(username)\\Desktop\\New-Export-Folder";
    } else {
      user_platform = "other";
      file_manager = "File Manager";
      default_MUA = "Unsupported";
      data_export_example_path = "/Users/user/Desktop/New-Export-Folder";
    }

    return { user_platform, default_MUA, file_manager, data_export_example_path };
  }

  getBrowser(): string {
    const userAgent = navigator.userAgent;
    let browser = "unkown";
    // Detect browser name
    browser = /ucbrowser/i.test(userAgent) ? "UCBrowser" : browser;
    browser = /edg/i.test(userAgent) ? "Edge" : browser;
    browser = /googlebot/i.test(userAgent) ? "GoogleBot" : browser;
    browser = /chromium/i.test(userAgent) ? "Chromium" : browser;
    browser =
      /firefox|fxios/i.test(userAgent) && !/seamonkey/i.test(userAgent) ? "Firefox" : browser;
    browser =
      /; msie|trident/i.test(userAgent) && !/ucbrowser/i.test(userAgent) ? "IE" : browser;
    browser =
      /chrome|crios/i.test(userAgent) &&
        !/opr|opera|chromium|edg|ucbrowser|googlebot/i.test(userAgent)
        ? "Chrome"
        : browser;
    browser =
      /safari/i.test(userAgent) &&
        !/chromium|edg|ucbrowser|chrome|crios|opr|opera|fxios|firefox/i.test(userAgent)
        ? "Safari"
        : browser;
    browser = /opr|opera/i.test(userAgent) ? "Opera" : browser;
    return browser;
  }

  // Public static methods
  static buildForApp(): boolean {
    return process.env.REACT_APP_BUILD_MODE === BuildMode.app;
  }

  static isDevelopmentRunMode(): boolean {
    return process.env.NODE_ENV === RunMode.development;
  }

  static buildForWeb(): boolean {
    return process.env.REACT_APP_BUILD_MODE !== BuildMode.app;
  }

  // Description: Turn the loggerMiddleware on for console logging all actions "ON" || "OFF"
  static turnLoggerOn(): boolean {
    return !!process.env.REACT_APP_LOGGER_ON && process.env.REACT_APP_LOGGER_ON === "ON";
  }

  // Description: Turn the loggerMiddleware on for console logging all actions ConfigSwitch "ON" || "OFF"
  static filesycnWSNotificationOn(): boolean {
    return !this.buildForWeb() && !!process.env.REACT_APP_FILESYNC_WEBSOCKET && process.env.REACT_APP_FILESYNC_WEBSOCKET === "ON";
  }

  static buildForExpress(): boolean {
    const account_type = LocalAccountStorage.getStorageItem(AuthenticationConstants.ACCOUNT_TYPE_SESSION_KEY);
    return this.buildForWeb() && account_type === account_types.express;
  }
}
