/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.PVKeys.Key', null, global);
goog.exportSymbol('proto.PVKeys.PublicUserKey', null, global);
goog.exportSymbol('proto.PVKeys.UserKey', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PVKeys.Key = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PVKeys.Key, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.PVKeys.Key.displayName = 'proto.PVKeys.Key';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PVKeys.Key.prototype.toObject = function(opt_includeInstance) {
  return proto.PVKeys.Key.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PVKeys.Key} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PVKeys.Key.toObject = function(includeInstance, msg) {
  var f, obj = {
    protocolVersion: jspb.Message.getField(msg, 1),
    key: msg.getKey_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PVKeys.Key}
 */
proto.PVKeys.Key.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PVKeys.Key;
  return proto.PVKeys.Key.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PVKeys.Key} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PVKeys.Key}
 */
proto.PVKeys.Key.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setProtocolVersion(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PVKeys.Key.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PVKeys.Key.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PVKeys.Key} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PVKeys.Key.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional uint64 protocol_version = 1;
 * @return {number}
 */
proto.PVKeys.Key.prototype.getProtocolVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.PVKeys.Key.prototype.setProtocolVersion = function(value) {
  jspb.Message.setField(this, 1, value);
};


proto.PVKeys.Key.prototype.clearProtocolVersion = function() {
  jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.PVKeys.Key.prototype.hasProtocolVersion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes key = 2;
 * @return {!(string|Uint8Array)}
 */
proto.PVKeys.Key.prototype.getKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes key = 2;
 * This is a type-conversion wrapper around `getKey()`
 * @return {string}
 */
proto.PVKeys.Key.prototype.getKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getKey()));
};


/**
 * optional bytes key = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getKey()`
 * @return {!Uint8Array}
 */
proto.PVKeys.Key.prototype.getKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getKey()));
};


/** @param {!(string|Uint8Array)} value */
proto.PVKeys.Key.prototype.setKey = function(value) {
  jspb.Message.setField(this, 2, value);
};


proto.PVKeys.Key.prototype.clearKey = function() {
  jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.PVKeys.Key.prototype.hasKey = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PVKeys.UserKey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PVKeys.UserKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.PVKeys.UserKey.displayName = 'proto.PVKeys.UserKey';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PVKeys.UserKey.prototype.toObject = function(opt_includeInstance) {
  return proto.PVKeys.UserKey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PVKeys.UserKey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PVKeys.UserKey.toObject = function(includeInstance, msg) {
  var f, obj = {
    protocolVersion: jspb.Message.getField(msg, 1),
    keyVersion: jspb.Message.getField(msg, 2),
    privateKey: (f = msg.getPrivateKey()) && proto.PVKeys.Key.toObject(includeInstance, f),
    signingKey: (f = msg.getSigningKey()) && proto.PVKeys.Key.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PVKeys.UserKey}
 */
proto.PVKeys.UserKey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PVKeys.UserKey;
  return proto.PVKeys.UserKey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PVKeys.UserKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PVKeys.UserKey}
 */
proto.PVKeys.UserKey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setProtocolVersion(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setKeyVersion(value);
      break;
    case 3:
      var value = new proto.PVKeys.Key;
      reader.readMessage(value,proto.PVKeys.Key.deserializeBinaryFromReader);
      msg.setPrivateKey(value);
      break;
    case 4:
      var value = new proto.PVKeys.Key;
      reader.readMessage(value,proto.PVKeys.Key.deserializeBinaryFromReader);
      msg.setSigningKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PVKeys.UserKey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PVKeys.UserKey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PVKeys.UserKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PVKeys.UserKey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getPrivateKey();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.PVKeys.Key.serializeBinaryToWriter
    );
  }
  f = message.getSigningKey();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.PVKeys.Key.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 protocol_version = 1;
 * @return {number}
 */
proto.PVKeys.UserKey.prototype.getProtocolVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.PVKeys.UserKey.prototype.setProtocolVersion = function(value) {
  jspb.Message.setField(this, 1, value);
};


proto.PVKeys.UserKey.prototype.clearProtocolVersion = function() {
  jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.PVKeys.UserKey.prototype.hasProtocolVersion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 key_version = 2;
 * @return {number}
 */
proto.PVKeys.UserKey.prototype.getKeyVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.PVKeys.UserKey.prototype.setKeyVersion = function(value) {
  jspb.Message.setField(this, 2, value);
};


proto.PVKeys.UserKey.prototype.clearKeyVersion = function() {
  jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.PVKeys.UserKey.prototype.hasKeyVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Key private_key = 3;
 * @return {?proto.PVKeys.Key}
 */
proto.PVKeys.UserKey.prototype.getPrivateKey = function() {
  return /** @type{?proto.PVKeys.Key} */ (
    jspb.Message.getWrapperField(this, proto.PVKeys.Key, 3));
};


/** @param {?proto.PVKeys.Key|undefined} value */
proto.PVKeys.UserKey.prototype.setPrivateKey = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.PVKeys.UserKey.prototype.clearPrivateKey = function() {
  this.setPrivateKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.PVKeys.UserKey.prototype.hasPrivateKey = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Key signing_key = 4;
 * @return {?proto.PVKeys.Key}
 */
proto.PVKeys.UserKey.prototype.getSigningKey = function() {
  return /** @type{?proto.PVKeys.Key} */ (
    jspb.Message.getWrapperField(this, proto.PVKeys.Key, 4));
};


/** @param {?proto.PVKeys.Key|undefined} value */
proto.PVKeys.UserKey.prototype.setSigningKey = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.PVKeys.UserKey.prototype.clearSigningKey = function() {
  this.setSigningKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.PVKeys.UserKey.prototype.hasSigningKey = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PVKeys.PublicUserKey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PVKeys.PublicUserKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.PVKeys.PublicUserKey.displayName = 'proto.PVKeys.PublicUserKey';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PVKeys.PublicUserKey.prototype.toObject = function(opt_includeInstance) {
  return proto.PVKeys.PublicUserKey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PVKeys.PublicUserKey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PVKeys.PublicUserKey.toObject = function(includeInstance, msg) {
  var f, obj = {
    protocolVersion: jspb.Message.getField(msg, 1),
    keyVersion: jspb.Message.getField(msg, 2),
    publicKey: (f = msg.getPublicKey()) && proto.PVKeys.Key.toObject(includeInstance, f),
    verifyKey: (f = msg.getVerifyKey()) && proto.PVKeys.Key.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PVKeys.PublicUserKey}
 */
proto.PVKeys.PublicUserKey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PVKeys.PublicUserKey;
  return proto.PVKeys.PublicUserKey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PVKeys.PublicUserKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PVKeys.PublicUserKey}
 */
proto.PVKeys.PublicUserKey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setProtocolVersion(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setKeyVersion(value);
      break;
    case 3:
      var value = new proto.PVKeys.Key;
      reader.readMessage(value,proto.PVKeys.Key.deserializeBinaryFromReader);
      msg.setPublicKey(value);
      break;
    case 4:
      var value = new proto.PVKeys.Key;
      reader.readMessage(value,proto.PVKeys.Key.deserializeBinaryFromReader);
      msg.setVerifyKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PVKeys.PublicUserKey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PVKeys.PublicUserKey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PVKeys.PublicUserKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PVKeys.PublicUserKey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getPublicKey();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.PVKeys.Key.serializeBinaryToWriter
    );
  }
  f = message.getVerifyKey();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.PVKeys.Key.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 protocol_version = 1;
 * @return {number}
 */
proto.PVKeys.PublicUserKey.prototype.getProtocolVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.PVKeys.PublicUserKey.prototype.setProtocolVersion = function(value) {
  jspb.Message.setField(this, 1, value);
};


proto.PVKeys.PublicUserKey.prototype.clearProtocolVersion = function() {
  jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.PVKeys.PublicUserKey.prototype.hasProtocolVersion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 key_version = 2;
 * @return {number}
 */
proto.PVKeys.PublicUserKey.prototype.getKeyVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.PVKeys.PublicUserKey.prototype.setKeyVersion = function(value) {
  jspb.Message.setField(this, 2, value);
};


proto.PVKeys.PublicUserKey.prototype.clearKeyVersion = function() {
  jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.PVKeys.PublicUserKey.prototype.hasKeyVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Key public_key = 3;
 * @return {?proto.PVKeys.Key}
 */
proto.PVKeys.PublicUserKey.prototype.getPublicKey = function() {
  return /** @type{?proto.PVKeys.Key} */ (
    jspb.Message.getWrapperField(this, proto.PVKeys.Key, 3));
};


/** @param {?proto.PVKeys.Key|undefined} value */
proto.PVKeys.PublicUserKey.prototype.setPublicKey = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.PVKeys.PublicUserKey.prototype.clearPublicKey = function() {
  this.setPublicKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.PVKeys.PublicUserKey.prototype.hasPublicKey = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Key verify_key = 4;
 * @return {?proto.PVKeys.Key}
 */
proto.PVKeys.PublicUserKey.prototype.getVerifyKey = function() {
  return /** @type{?proto.PVKeys.Key} */ (
    jspb.Message.getWrapperField(this, proto.PVKeys.Key, 4));
};


/** @param {?proto.PVKeys.Key|undefined} value */
proto.PVKeys.PublicUserKey.prototype.setVerifyKey = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.PVKeys.PublicUserKey.prototype.clearVerifyKey = function() {
  this.setVerifyKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.PVKeys.PublicUserKey.prototype.hasVerifyKey = function() {
  return jspb.Message.getField(this, 4) != null;
};


goog.object.extend(exports, proto.PVKeys);
