import { useState, useCallback, useEffect, useRef } from "react";
import { MessageDisplayType, ErrorStackDataType } from "@preveil-api";
import {
  Account, DriveErrorMessages, Message, MessageHandlerDisplayType, useAppDispatch, useSendRequestMutation, CollectionFilesyncAPI, useAppSelector,
  DriveRequest, getDirSymmKey, decryptString, DriveSharedInfo, PathList, DefaultCollectionName, sortBy, SORT_DIRECTION, PathInfo, getEnumKey,
  decryptRootName, getStatusFromPath, useGetPermissions, PermissionSetType, ErrorStackItem
} from "src/common";
import { FSMessage, FSWrappedKey, FSStatus } from "src/common/keys/protos/collections_pb";
import { DriveCallbackAsyncFunction, uiActions } from "src/store";
import { RootState } from "src/store/configureStore";
import _ from "lodash";

// Description: Web hook for fetching a users shared folders (linked AND unlinked (not in my preveil))
export function useGetSharedFolders(current_account: Account) {
  const [error_fetching, setErrorFetching] = useState<boolean>(false);
  const [done, setDone] = useState<boolean>(false);
  const [shared_folders, setSharedFolders] = useState<DriveSharedInfo[]>();
  const [linked_folders, setLinkedFolders] = useState<DriveSharedInfo[]>();
  const default_permissions = useAppSelector((state: RootState) => state.drive.default_permissions);
  const { permissions, getPermissions, destroyPermissions, error_fetching_permissions } = useGetPermissions(current_account);
  const root_info = useAppSelector((state: RootState) => state.drive.root_info);
  const [sendRequest] = useSendRequestMutation();
  const dispatch = useAppDispatch();

  const permissions_ref = useRef(permissions);
  function setPermissionsRef(_permissions?: PermissionSetType) {
    permissions_ref.current = _permissions;
  }

  const unlinked_entry_ref = useRef<FSMessage.UnlinkedEntry>();
  const unlinked_entries_ref = useRef<FSMessage.UnlinkedEntry[]>([]);
  const unlinked_list_ref = useRef<DriveSharedInfo[]>([]);

  useEffect(() => {
    if (!!permissions) {
      setPermissionsRef(permissions);
      // NOTE: Return the permission state to undefined to be able to detect future chages
      destroyPermissions();
      decryptUnlinkedName();
    }
    if (error_fetching_permissions) {
      destroyPermissions();
      unlinked_entry_ref.current = unlinked_entries_ref.current.pop();
      !!unlinked_entry_ref.current ? getPermissions(unlinked_entry_ref.current.getCollectionId_asB64(), default_permissions) : setDone(true);
    }
  }, [permissions, error_fetching_permissions]);

  // Description: Checks whether unlinked and linked folders have been populated and sets the state
  useEffect(() => {
    if (!!linked_folders && done) {
      const sorted_unlinked = sortBy(unlinked_list_ref.current, { field: "name", direction: SORT_DIRECTION.ascending });
      setSharedFolders(
        sorted_unlinked.concat(
          sortBy(linked_folders, { field: "name", direction: SORT_DIRECTION.ascending }),
        ),
      );
    }
  }, [linked_folders, done]);

  // Description: Fetches the Active Links from the backend recursively using nextSeq.
  async function _fetchActiveLinks(seq: number, collection_id: string, root_permissions: PermissionSetType, wrapped_dir_key?: FSWrappedKey) {
    const request = await CollectionFilesyncAPI.fetchActiveLinks(current_account, root_permissions, collection_id, seq);
    async function callback(message: FSMessage) {
      const link_view = message.getLinkView();
      if (message.getStatus() === FSStatus.OK && !!link_view) {
        !!wrapped_dir_key && _fetchActiveLinksInfo(link_view.getLinksList(), root_permissions, wrapped_dir_key);
        const nextSeq = link_view.getNextSeq();
        if (link_view.getHasMore() && !!nextSeq) {
          _fetchActiveLinks(nextSeq, collection_id, root_permissions, wrapped_dir_key);
        }
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_active_link }, message);
      }
    }
    handleSendRequest(request, callback, DriveErrorMessages.error_fetching_active_link);
  }

  // Description: Fetches Inactive links from the backend (new API call as of 12/03/2023)
  // returns the encrypted name as we do the decrypting of the name in the row component. 
  // (so that we can fetch the permissions respectively for each row)
  async function _fetchInactiveLinks(root_permissions: PermissionSetType, root_collection_id: string) {
    const request = !!root_permissions ? await CollectionFilesyncAPI.fetchInactiveLinks(current_account, root_permissions, root_collection_id) : null;
    async function callback(message: FSMessage) {
      const unlinked_entries = message.getUnlinkedEntriesList();
      if (message.getStatus() === FSStatus.OK && !!unlinked_entries) {
        unlinked_entries_ref.current = unlinked_entries;
        unlinked_entry_ref.current = unlinked_entries_ref.current.pop(); // using a stack to get the permissions for each entry and decrypt its name
        !!unlinked_entry_ref.current ? getPermissions(unlinked_entry_ref.current.getCollectionId_asB64(), default_permissions) : setDone(true);
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_inactive_link }, message);
      }
    }
    handleSendRequest(request, callback, DriveErrorMessages.error_fetching_inactive_link);
  }

  // Description: Decrypt the name of the unlinked entry after getting the permissions of that unlinked collection
  // uses a stack ref object to decrypt each name in the list, when the list is empty - sets done to true. 
  async function decryptUnlinkedName() {
    const unlinked_entry = unlinked_entry_ref.current;
    if (!!unlinked_entry) {
      const name = unlinked_entry.getName();
      const decrypted_name = !!permissions_ref.current && !!name ? await decryptRootName(name.getContent_asU8(), permissions_ref.current, current_account, name.getKeyVersion()) : undefined;
      if (!!decrypted_name) {
        unlinked_list_ref.current.push({
          id: unlinked_entry.getCollectionId_asB64(),
          name: decrypted_name,
          linked: false,
          path: [],
          link_id: "",
          processing: false,
          status: "Hidden",
        });
      }
    }
    unlinked_entry_ref.current = unlinked_entries_ref.current.pop();
    !!unlinked_entry_ref.current ? getPermissions(unlinked_entry_ref.current.getCollectionId_asB64(), default_permissions) : setDone(true);
  }

  // Description: This function decrypts the path and the name for the linked entries of the list. 
  async function _fetchActiveLinksInfo(links: FSMessage.LinkView.Entry[], root_permissions: PermissionSetType, wrapped_dir_key: FSWrappedKey) {
    const unwrapped_dir_key = await getDirSymmKey(wrapped_dir_key, current_account, root_permissions);
    if (!!unwrapped_dir_key) {
      const link_list: DriveSharedInfo[] = [];
      links.length === 0 && setLinkedFolders([]);
      let index = 0;
      links.forEach(async (link_entry) => {
        const p = link_entry.getPathList();
        let name: string;
        let path: PathInfo[];
        if (p.length > 1) {
          // this link lives in a sub directory of the default collection
          // need to unwrap the link name from that dir key.
          name = await _getSubDirectoryLinkName(link_entry, root_permissions, p[p.length - 1].getWrappedDirKey());
          const path_list = new PathList(link_entry.getLinkedCollectionId_asB64(), link_entry.getPathList()).path_list;
          path = await PathList.buildPathInfoFromNodes(path_list, current_account, root_permissions, {});
        } else {
          path = link_entry.getPathList().map((p) => ({ id: p.getId_asB64(), path: DefaultCollectionName, url: "" }));
          try {
            name = await decryptString(link_entry.getName_asU8(), unwrapped_dir_key);
          } catch (error) {
            name = "";
          }
        }
        const status = getStatusFromPath(path, true);
        if (!!name) {
          link_list.push({
            id: link_entry.getLinkedCollectionId_asB64(),
            name,
            linked: true,
            path,
            link_id: link_entry.getId_asB64(),
            processing: false,
            status
          });
        }
        index === links.length - 1 && setLinkedFolders(link_list);
        index++;
      });
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, {
        stack_message: DriveErrorMessages.undefined_symm_key
      });
    }
  }

  // Description: Decryptes the name of the subdirectory and returns.
  async function _getSubDirectoryLinkName(link_entry: FSMessage.LinkView.Entry, root_permissions: PermissionSetType, wrapped_key?: FSWrappedKey): Promise<string> {
    let name: string = "";
    try {
      const unwrapped_key = !!wrapped_key ? await getDirSymmKey(wrapped_key, current_account, root_permissions) : null;
      name = !!unwrapped_key ? await decryptString(link_entry.getName_asU8(), unwrapped_key) : "";
    } catch (error) {
      console.error("Error Decrypting Name");
      name = "";
    }
    return name;
  }

  // Description: Sends Request and handles error when the request object is null, pass it custom stack message
  function handleSendRequest<T>(request: DriveRequest | null, callback: DriveCallbackAsyncFunction<T>, stack_error: string = DriveErrorMessages.error_sending_request) {
    if (!!request) {
      request.setCallback(callback);
      sendRequest(request);
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, { stack_error, stack_message: DriveErrorMessages.error_sending_request }, null);
    }
  }

  // Description: Handle error message and send error to store
  function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, fsmessage?: FSMessage | null,
    display_type: MessageDisplayType = MessageHandlerDisplayType.logger) {
    const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
    const stack = new ErrorStackItem("[useGetSharedFolders Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
    dispatch(uiActions.handleRequestErrors(new Message(message, display_type), stack));
    setErrorFetching(true);
  }

  // Description: Callback for getting the shared folders
  const getSharedFolders = useCallback(() => {
    if (!!root_info) {
      const root_permissions = _.find(default_permissions, (set: PermissionSetType) => root_info.collection_id === set.collection_id.B64());
      const wrapped_key = root_info.wrapped_dir_key;
      if (!!root_permissions) {
        !!wrapped_key && _fetchActiveLinks(0, root_info.collection_id, root_permissions, wrapped_key);
        _fetchInactiveLinks(root_permissions, root_info.collection_id);
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, {
          stack_message: DriveErrorMessages.error_fetching_root_info,
        });
      }
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_root_info });
    }
  }, []);

  // Description: Reset the hook
  const reset = useCallback(() => {
    setSharedFolders(undefined);
    setLinkedFolders(undefined);
    setDone(false);
    unlinked_entry_ref.current = undefined;
    unlinked_entries_ref.current = [];
    unlinked_list_ref.current = [];
  }, []);

  return {
    error_fetching,
    shared_folders,
    getSharedFolders,
    reset,
  };
}
