import { useEffect, useState } from "react";
import { VersionedEmailBlock, UserInvite, MailboxItem, MailMessageResponse, MailMessageV4, IFetchResponse, MessageSendMetadata } from "@preveil-api";
import {
  MessageV4, WebMailData, MessageLatest, Account, MailFlags, isSameUser, FileSizeLimits, Helpers, CURRENT_EMAIL_PROTOCOL_VERSIONS, DefaultMailboxes, getMailboxCollectionIdByRole,
  usePostStorageByCollectionIdBlocksMutation, PostStorageByCollectionIdBlocksApiResponse, usePostMailSendMutation, usePutUsersInviteMutation, isProtocolV4,
  usePatchMailByCollectionIdMailboxesAndMbidMessagesMutation, usePostMailByCollectionIdMailboxesAndMbidMessagesMutation, usePostMailByCollectionIdMutation,
} from "src/common";
import _ from "lodash";


export function useSendWebMail(current_account: Account, send_metadata?: MessageSendMetadata, mail_data?: WebMailData[], default_mailboxes?: MailboxItem[]) {
  const protocol_version = getMessageProtocolVersion();
  const [errors, setErrors] = useState<IFetchResponse[]>();
  const [sendInvites] = usePutUsersInviteMutation();
  const [postBlocks] = usePostStorageByCollectionIdBlocksMutation();
  const [sendMailLatest, { isLoading, data }] = usePostMailSendMutation();
  const [updateFlags] = usePatchMailByCollectionIdMailboxesAndMbidMessagesMutation(); // FOR ALL MAIL VERSIONS
  const [sendMailV4] = usePostMailByCollectionIdMutation();
  const [appendToMailbox] = usePostMailByCollectionIdMailboxesAndMbidMessagesMutation(); // ONLY FOR V4 mails

  // Description: Send / Post data Blocks to storage first
  async function handleSendWebMail(_webdata: WebMailData[]) {
    const results = await handleAllBlocks(_webdata);
    // Examine for errors if none then update flags
    const errors = _.filter(results, (result: { result?: MailMessageResponse; error?: IFetchResponse }) => (!!result.error));
    (!!errors && errors.length > 0) ? handleMailSendError(errors as IFetchResponse[]) : handleUpdateFlags();
  }

  // Description: Iterate through all WebMailData and breakdown blocks at FileSizeLimits.MAIL_MAX_BLOCKS_PER_REQUEST
  // On successful putBlocks send mail by protocol version
  async function handleAllBlocks(_webdata: WebMailData[]): Promise<Array<{ result?: MailMessageResponse; error?: unknown }>> {
    return await Promise.all(_.map(_webdata, async (data: WebMailData) => {
      const blocks = data.blocks;
      const blocks_per_request = FileSizeLimits.MAIL_MAX_BLOCKS_PER_UPLOAD;
      const chunks =
        [...Array(Math.ceil(blocks.length / blocks_per_request)).keys()]
          .map(i => blocks.slice(i * blocks_per_request, i * blocks_per_request + blocks_per_request));
      // NOTE: Iterate all calls and assemble blocks
      return await Promise.all(_.map(chunks, (block_chunk: VersionedEmailBlock[]) => {
        const collection_id = isProtocolV4(protocol_version) ? (data.metadata as MessageV4).recipient.mail_cid : current_account.mail_cid;
        return handlePostBlocks(block_chunk, collection_id);
      }))
        .then((responses: PostStorageByCollectionIdBlocksApiResponse[]) => {
          let result: Array<{ block_id: string }> = [];
          for (const i of responses) {
            result = result.concat(i.data);
          }
          return handleSendMessagebyVersion(data)
            .then((result: MailMessageResponse) => ({ result }));
        })
        .catch((error: IFetchResponse) => {

          return { error };
        });
    }));
  };

  // Description: Handle Posting block chunkss
  async function handlePostBlocks(_blocks: VersionedEmailBlock[], collectionId: string): Promise<PostStorageByCollectionIdBlocksApiResponse> {
    const blocks = _blocks.map(b => (Object.assign({}, b, { data: Helpers.b64Encode(b.data) })));
    return await postBlocks({
      account_ids: Account.getAccountIdentifiers(current_account),
      body: {
        collectionId,
        blocks
      }
    }).unwrap()
      .then((response: PostStorageByCollectionIdBlocksApiResponse) => {
        return response;
      });
  }

  // Description:  Handle the send part
  async function handleSendMessagebyVersion(data: WebMailData): Promise<MailMessageResponse> {
    if (isProtocolV4(protocol_version)) {
      const metadata = data.metadata as MessageV4;
      // NOTE: If recipient is the sender (needed for uploading the blocks) => update the Send mailbox; else send to recipient
      return (isSameUser(metadata.recipient.user_id, current_account.user_id)) ?
        await handleV4AppendToMailbox(metadata.message) :
        await handleSendMailV4(metadata);
    } else {
      return await handleSendMailLatest(data.metadata as MessageLatest);
    }
  }

  // Description: Send Mail to Collection Server after PUT BLOCKS (Latest versions)
  async function handleSendMailLatest(message: MessageLatest): Promise<MailMessageResponse> {
    return await sendMailLatest({
      account_ids: Account.getAccountIdentifiers(current_account),
      body: {
        message
      }
    }).unwrap()
      .then((response: MailMessageResponse) => {
        return response;
      });
  }

  // Description: Send Mail to Collection Server after PUT BLOCKS (Latest versions)
  // NOTES: Send mail is a single recipient send. It needs to loop through all recipients to send mail_data out
  async function handleSendMailV4(metadata: MessageV4): Promise<MailMessageResponse> {
    const message = metadata.message;
    const collectionId = metadata.recipient.mail_cid;
    return await sendMailV4({
      account_ids: Account.getAccountIdentifiers(current_account),
      body: {
        collectionId, // THIS IS THE RECIPIENTS mail_cid!!!!!
        message
      }
    }).unwrap()
      .then((response: MailMessageResponse) => {
        return response;
      });
  }

  // Description: On V4 Mail Send Success need to call this one with the same message object 
  // NOTE:  For every message a user sends should put a copy of that message in the sender’s sent mailbox. 
  //        for V4 needs to be explicitly done.
  async function handleV4AppendToMailbox(message: MailMessageV4) {
    let result = null;
    const sent_mailbox_id = !!default_mailboxes ? getMailboxCollectionIdByRole(DefaultMailboxes.sent, default_mailboxes) : null;
    if (!!sent_mailbox_id) {
      result = await appendToMailbox({
        account_ids: Account.getAccountIdentifiers(current_account),
        body: {
          collectionId: current_account.mail_cid,
          mbid: sent_mailbox_id,
          message
        }
      }).unwrap()
        .then((response: MailMessageResponse) => {
          return response;
        });
      return result;
    } else {
      throw new Error("Failed sending message");
    }
  }

  // Description: On Success Update flags 
  function handleUpdateFlags() {
    const draft_mailbox_id = !!default_mailboxes ? getMailboxCollectionIdByRole(DefaultMailboxes.drafts, default_mailboxes) : null;
    if (!!draft_mailbox_id && !!send_metadata?.draft_id && !!send_metadata?.draft_version) {
      updateFlags({
        account_ids: Account.getAccountIdentifiers(current_account),
        body: {
          collectionId: current_account.mail_cid,
          mbid: draft_mailbox_id,
          updates: [{
            id: send_metadata.draft_id,
            last_version: send_metadata.draft_version,
            flags: [MailFlags.DELETED_FLAG, MailFlags.SEEN_FLAG, MailFlags.DRAFT_FLAG]
          }]
        }
      }).unwrap()
        .catch((error: IFetchResponse) => {
          handleMailSendError([error]);
        });
    }
  }

  // Description: Handle all errors
  function handleMailSendError(_errors: IFetchResponse[]) {
    setErrors(_errors);
  }

  // Description: Send invite to unclaimed users in mail in parallel
  function handleSendInvites(_unclaimed: UserInvite[]) {
    _.forEach(_unclaimed, (body: UserInvite) => {
      sendInvites({
        account_ids: Account.getAccountIdentifiers(current_account),
        body
      }).unwrap()
        .catch((error: IFetchResponse) => {
          handleMailSendError([error]);
        });
    });
  }

  // Description: Get protocol_version from message or default to current
  function getMessageProtocolVersion() {
    return !!mail_data ? mail_data[0].metadata.message.protocol_version : CURRENT_EMAIL_PROTOCOL_VERSIONS;
  };

  // Description: On webData receive send the blocks up to storage
  //              Send invite to unclaimed users in mail in parallel
  //              On Success also update flags 
  useEffect(() => {
    if (!!mail_data) {
      handleSendWebMail(mail_data);
      (!!send_metadata?.unclaimed && send_metadata?.unclaimed.length > 0) && handleSendInvites(send_metadata.unclaimed);
    }
  }, [mail_data]);

  return {
    data,
    isLoading,
    errors
  };
}
