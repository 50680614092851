import React, { useEffect, useState } from "react";
import { UserProfile } from "@preveil-api";
import { getStatusCounts, MailMessages, MessageAnchors, UserListStatus } from "src/common";

type AllProps = {
  recipients: UserProfile[];
  show_external_users?: boolean;
};

function UserListNotificationComponent(props: AllProps) {
  const { recipients, show_external_users } = props;
  const [unclaimed, setUnclaimed] = useState<string | null>();
  const [external, setExternal] = useState<string | null>();

  useEffect(() => {
    // NOTE: Need to sort out unclaimed and external recipients
    const unclaimed_counts = show_external_users ? getStatusCounts(recipients, [UserListStatus.unclaimed, UserListStatus.pending]) :
      getStatusCounts(recipients, [UserListStatus.unclaimed, UserListStatus.pending, UserListStatus.external]);
    const external_counts = show_external_users ? getStatusCounts(recipients, UserListStatus.external) : null;
    // NOTE: Replace anchor with count and proper singular/plural "recipient(s)" strings
    setUnclaimed(!!unclaimed_counts ?
      MailMessages.unclaimed_recipient_notification.replace(MessageAnchors.recipient_count, unclaimed_counts)
      : null,
    );
    setExternal(
      !!external_counts
        ? MailMessages.external_recipient_notification.replace(
          MessageAnchors.recipient_count,
          external_counts,
        )
        : null,
    );
  }, [recipients]);

  return !!unclaimed || !!external ? (
    <div className="user-notification">
      {!!unclaimed && <p className="unclaimed">{unclaimed}</p>}
      {!!external && <p className="external">{external}</p>}
    </div>
  ) : null;
}

export default React.memo(UserListNotificationComponent);
