import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import { IActionHandler } from "@preveil-api";
import {
  useAppDispatch, Message, QueryParamKeys, normalizeQueryUserId, ApprovalGroupAccountRecoverySteps,
  GlobalErrorMessages, MessageAnchors, MessageHandlerDisplayType
} from "src/common";
import { uiActions } from "src/store";
import VerifiedImage from "src/assets/images/verified.svg";
import SettingsImage from "src/assets/images/settings.svg";
import { Loading } from "src/components";
import {
  DefaultPanel,
  SuccessPanel,
  ErrorPanel
} from ".";
function RecoverAccountComponent() {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [step, setStep] = useState<number>(ApprovalGroupAccountRecoverySteps.LOADING);
  const [user_id, setUserId] = useState<string>();

  // Description: load accounts and urrent account from crypto (First onload event)
  useEffect(() => {
    handleInitRecoverAccount();
  }, []);

  /* 
  *   Description: Initialize Component:
  *            - Get user ID from QS and pass it to the email input
  *            - Sets up KeyExchangeManager Instance
  */
  function handleInitRecoverAccount() {
    const query = searchParams.get(QueryParamKeys.USER_ID_QUERY_KEY);
    const query_user_id = normalizeQueryUserId(query);
    !!query_user_id && setUserId(query_user_id);
    if (!!query) {
      searchParams.delete(QueryParamKeys.USER_ID_QUERY_KEY);
      setSearchParams(searchParams);
    }
    setStep(ApprovalGroupAccountRecoverySteps.SUBMIT_EMAIL);
  }

  // Description: Render panels by steps
  function RecoverAccountPanels() {
    switch (step) {
      case ApprovalGroupAccountRecoverySteps.SUBMIT_EMAIL:
        return <DefaultPanel handleAction={handlePageActions} user_id={user_id} />;
      case ApprovalGroupAccountRecoverySteps.ERROR:
        return <ErrorPanel handleAction={handlePageActions} />;
      case ApprovalGroupAccountRecoverySteps.SUCCESS:
        return <SuccessPanel user_id={user_id} />;
      default:
        return <Loading className="in-place" />;
    }
  };

  const RecoverAccountRequests = {
    handleSubmitUserId: (_user_id: string) => {
      setUserId(_user_id);
      setStep(ApprovalGroupAccountRecoverySteps.SUCCESS);
    },
    handleDestroy: () => {
      setUserId(undefined);
      setStep(ApprovalGroupAccountRecoverySteps.SUBMIT_EMAIL);
    },
    handlePageError: (params: { message: string, stack?: any, error_panel?: boolean }) => {
      const displayType = params.error_panel ? MessageHandlerDisplayType.logger : MessageHandlerDisplayType.toastr;
      dispatch(uiActions.handleRequestErrors(new Message(params.message, displayType), params.stack));
      params.error_panel && setStep(ApprovalGroupAccountRecoverySteps.ERROR);
    },
    handlePageErrorMessage: (params: { message: string, stack?: any }) => {
      dispatch(uiActions.handleRequestErrors(new Message(params.message, MessageHandlerDisplayType.logger), params.stack));
    }
  };

  //  Description: Handle all actions from Children forms
  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    if ((RecoverAccountRequests as any)[callback] instanceof Function) {
      (RecoverAccountRequests as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(MessageAnchors.actionType, actionObj.actionType);
      RecoverAccountRequests.handlePageErrorMessage({ message, stack: actionObj });
    }
  }

  return <Row className="justify-content-center mt-4">
    <Col md={8}>
      <h1 className="main-header">Recover Your Private Key</h1>
      <Card className="pv-card">
        <Card.Img variant="top" src={step === ApprovalGroupAccountRecoverySteps.SUCCESS ? VerifiedImage : SettingsImage} />
        <Card.Body className="p-4">
          <RecoverAccountPanels />
        </Card.Body>
      </Card>
    </Col>
  </Row>;
}

export default React.memo(RecoverAccountComponent);
