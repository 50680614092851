/* eslint-disable */
/* tslint:disable */
import { EncryptionKey, EncryptionStyle, PublicKey } from "./encryption";
import { text_header, binary_header } from "./encryption_v0";
import { NaclCrypto, PVCryptoUsage } from "pvcryptojs";


export class EncryptionKeyV1 extends EncryptionKey {
  static protocol_version = 1;

  constructor(public_key: Uint8Array, public private_key?: Uint8Array, private style?: EncryptionStyle) {
    super(EncryptionKeyV1.protocol_version, public_key, private_key);
  }
  static async new(sk?: Uint8Array, style?: EncryptionStyle): Promise<EncryptionKeyV1> {
    const [_sk, _pk] = !sk ?
      await NaclCrypto.generateAsymmKey(PVCryptoUsage.ENCRYPT) :
      [sk, await NaclCrypto.publicKeyFromPrivate(sk, PVCryptoUsage.ENCRYPT)];
    return new EncryptionKeyV1(_pk, _sk, style);
  }

  public clonePublic(): PublicKey {
    return new EncryptionKeyV1(this.public_key, undefined, this.style);
  }

  public async unseal(cipher: Uint8Array): Promise<Uint8Array> {
    if (!this.private_key) {
      throw Error("Invalid usage");
    }
    let decrypted = NaclCrypto.hybridDecrypt(this.private_key, cipher);
    if (this.style === EncryptionStyle.EMAIL) {
      decrypted = decrypted.then(p => !p ? p : p.slice(text_header.length));
    }
    return decrypted;
  }

  public async seal(plaintext: Uint8Array, is_text: boolean = false): Promise<Uint8Array> {
    if (this.style === EncryptionStyle.EMAIL) {
      plaintext = is_text ?
        new Uint8Array([...text_header, ...plaintext]) :
        new Uint8Array([...binary_header, ...plaintext]);
    }
    return NaclCrypto.hybridEncrypt(this.public_key, plaintext);
  }
}
