import React from "react";
import appleImg from "src/assets/images/apple-mail.png";
import windowsImg from "src/assets/images/windows.png";
import gmailImg from "src/assets/images/screenshot-gmail.png";
import { Card, CardGroup, Button } from "react-bootstrap";
import { PrivateRoutes, useAppSelector, WelcomeModalStates, WelcomeModalType } from "src/common";

type AllProps = {
  handleShowModal: (modal: WelcomeModalType) => void;
};

function MailClient(props: AllProps) {
  const { handleShowModal } = props;
  const default_MUA = useAppSelector((state) => state.app.os_info)?.default_MUA;
  const img = !!default_MUA && default_MUA === "Apple Mail" ? appleImg : windowsImg;
  return (
    <>
      <CardGroup className="mail-client mb-2">
        <Card className="gmail">
          <Card.Header>Gmail Plugin</Card.Header>
          <Card.Img variant="top" src={gmailImg} className="gmail-image" />
          <Card.Body>
            <Card.Text as="p">
              PreVeil makes it easy to access your encrypted emails in Gmail by automatically adding
              a secure inbox.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>{default_MUA}</Card.Header>
          <Card.Img variant="top" src={img} className="mua-image" />
          <Card.Body>
            <Card.Text as="p">
              PreVeil makes it easy to access your encrypted emails in {default_MUA} by
              automatically adding a secure inbox.{" "}
              <b>Please close {default_MUA} if it is currently open.</b>
            </Card.Text>
          </Card.Body>
        </Card>
      </CardGroup>
      <div className="welcome-buttons">
        <Button href={`/${PrivateRoutes.settings_route}/${PrivateRoutes.install_profile_route}`}>
          Install Mail Client
        </Button>
        <Button
          variant="no-outline-primary"
          className="float-end"
          onClick={() => handleShowModal(WelcomeModalStates.page_two)}
        >
          Skip for now
        </Button>
      </div>
    </>
  );
}

export default React.memo(MailClient);
