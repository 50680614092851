import {
  ExportContentInfo,
  RequestParsedPayload,
  RequestMetadataObject,
  UserProfile
} from "@preveil-api";
import { Entity, ApprovalGroup, RequestTypes, dayjs } from "src/common";

export class UserRequest extends Entity {
  public type: string;
  public mapped_action: string;
  public timestamp: string;
  public expiration: string;
  public requester: UserProfile | null;
  public parsed_payload: RequestParsedPayload;
  public request_responses: Array<{ approver: UserProfile; response: string }> = [];
  public approval_group_name: string | undefined;
  public for_users: UserProfile[] = [];
  public optionals_required: number | undefined;
  public current_group: ApprovalGroup | undefined;
  public updated_role: string | undefined;
  public invalid: boolean = false;
  public is_admin: boolean = false;
  public export_content: ExportContentInfo | undefined;
  public org_details:
    | { loaded: boolean; org_id: string; org_name?: string; roled_approval_groups?: any }
    | undefined;
  public requester_id: string;

  constructor(
    public request_id: string,
    public metadata: RequestMetadataObject | null,
    public payload: string,
    public status: string,
    requester_id: string | null = null,
    public approvers: UserProfile[] = [],
    requester: UserProfile | null = null,
    public current_group_id: string | null = null,
    public pending_response: boolean = false,
  ) {
    super(request_id);
    this.parsed_payload = JSON.parse(payload);
    this.requester_id = requester_id || this.parsed_payload.user_id;
    this.type = this.parsed_payload.type;
    this.mapped_action = this._mapType(this.parsed_payload.type);
    this.timestamp = dayjs
      .utc(this.parsed_payload.timestamp)
      .local()
      .format("MMM D, YYYY hh:mm A");
    this.expiration = dayjs
      .utc(this.parsed_payload.expiration)
      .local()
      .format("MMM D, YYYY hh:mm A");
    this.requester = requester;
  }

  public setOrgDetails(details: {
    loaded: boolean;
    org_id: string;
    org_name?: string;
    roled_approval_groups?: any;
  }): void {
    this.org_details = details;
  }

  private _mapType(type: string): string {
    switch (type) {
      case RequestTypes.change_approval_group:
      case RequestTypes.rekey_and_change_approval_group:
      case RequestTypes.member_rekey_and_set_approval_group:
        return "Updated User Recovery Group";
      case RequestTypes.set_member_approval_group:
        return "Set Org User Recovery Group";
      case RequestTypes.export:
        return "Data Export";
      case RequestTypes.change_org_approval_group_role:
        return "Change Approval Group Activity";
      case RequestTypes.change_admin_status:
        return "Update Admin User Role";
      case RequestTypes.delete_user:
        return "Delete User";
      case RequestTypes.subsume_account:
        return "Existing Account Subsume";
      default:
        return type;
    }
  }
}
