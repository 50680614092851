import React from "react";
import { Button, Col, Row, InputGroup, Form } from "react-bootstrap";
import { Icon } from "src/components";
import { ActionHandlerFunction } from "@preveil-api";
import { AdminUIActionTypes } from "src/common";

type ToolbarComponentProps = {
  is_loading: boolean;
  is_add_button_disable?: boolean;
  is_recovery_group_button_disable?: boolean;
  is_resend_invite_button_disable?: boolean;
  is_delete_disable?: boolean;
  is_refresh_disable?: boolean;
  total_items_filtered?: number;
  total_items: number;
  filter_param?: string;
  button_title?: string;
  label: string;
  children?: React.ReactElement;
  non_edit_mode?: boolean;
  is_admin_user_page?: boolean;
  set_recovery_group_message?: string;
  is_data_export_page?: boolean;
  handleActions: ActionHandlerFunction;
}

function ToolbarComponent({
  is_loading,
  is_delete_disable,
  is_refresh_disable,
  is_add_button_disable,
  is_recovery_group_button_disable,
  is_resend_invite_button_disable,
  total_items_filtered,
  total_items,
  filter_param,
  button_title,
  label,
  children,
  non_edit_mode,
  is_admin_user_page,
  set_recovery_group_message,
  is_data_export_page,
  handleActions,
}: ToolbarComponentProps) {
  return <Row className="toolbar m-0">
    {React.isValidElement(children) ? (
      children
    ) : (
      (!non_edit_mode &&
        <Col xs="auto" className="d-flex ps-2 ms-1 col-sm-6">
          <InputGroup size="sm">
            <InputGroup.Text>
              <Icon className="ficon-filter" />
            </InputGroup.Text>
            <Form.Control
              value={filter_param}
              onChange={(e) =>
                handleActions({
                  actionType: AdminUIActionTypes.SearchParam,
                  params: e.target.value,
                })
              }
              type="text"
              placeholder="Filter..."
              aria-label="Filter"
            />
            {
              filter_param &&
              <Button
                variant="icon"
                size="sm"
                className="input-group-btn"
                onClick={() => {
                  handleActions({
                    actionType: AdminUIActionTypes.Reset,
                  });
                }} >
                <Icon className="ficon-x-circle" />
              </Button>
            }
          </InputGroup>
          {!is_data_export_page && (
            <>
              <Button
                className="ms-1 action-toolbar-buttons"
                variant="icon"
                size="sm"
                onClick={() => handleActions({ actionType: AdminUIActionTypes.ShowModal })}
                disabled={is_add_button_disable}
                title={button_title}
                data-tooltip-id="pv-tooltip"
                data-tooltip-content={button_title} >
                <Icon className="ficon-plus" />
              </Button>
              <Button
                className="action-toolbar-buttons"
                variant="icon"
                size="sm"
                onClick={() => handleActions({ actionType: AdminUIActionTypes.Delete })}
                disabled={is_delete_disable}
                title="Delete Selected"
                data-tooltip-id="pv-tooltip"
                data-tooltip-content="Delete Selected"
              >
                <Icon className="ficon-trash-2" />
              </Button>
            </>
          )}
          {is_admin_user_page && (
            <>
              <Button
                variant="icon"
                size="sm"
                onClick={() => handleActions({ actionType: AdminUIActionTypes.ExportCSV })}
                title="Export Users List"
                data-tooltip-id="pv-tooltip"
                data-tooltip-content="Export Users List"
              >
                <Icon className="ficon-download" />
              </Button>
              <Button
                className="action-toolbar-buttons"
                variant="icon"
                size="sm"
                disabled={is_recovery_group_button_disable}
                onClick={() => handleActions({ actionType: AdminUIActionTypes.ShowModal, params: "set-recovery-group" })}
                title="Set Recovery Group"
                data-tooltip-id="pv-tooltip"
                data-tooltip-content={set_recovery_group_message}
              >
                <Icon className="ficon-users" />
              </Button>
              <Button
                className="action-toolbar-buttons"
                variant="icon"
                size="sm"
                disabled={is_resend_invite_button_disable}
                onClick={() => handleActions({ actionType: AdminUIActionTypes.ShowModal, params: "resend-invite" })}
                title="Resend Invite"
                data-tooltip-id="pv-tooltip"
                data-tooltip-content="To resend invitations, please select users who are listed as pending."
              >
                <Icon className="ficon-mail" />
              </Button>
            </>
          )}
        </Col>
      )
    )}
    <Col className="text-end px-0">
      {!non_edit_mode && filter_param && filter_param.length >= 1 && (
        <span className="m-1">
          <b>{total_items_filtered}</b> of
        </span>
      )}
      <span className={`total-addresses ${non_edit_mode ? "m-0" : ""}`}>
        <b className={`${non_edit_mode ? "me-1" : ""}`}>{total_items}</b>
        {label}
      </span>
    </Col>
    {!is_data_export_page && (
      <Col xs="auto" className="px-0 refresh">
        <Button
          className="action-toolbar-buttons"
          variant="icon"
          size="sm"
          onClick={() => handleActions({ actionType: AdminUIActionTypes.GetRefreshRequest })}
          disabled={is_refresh_disable}
          title="Refresh"
          data-tooltip-id="pv-tooltip"
          data-tooltip-content="Refresh"
        >
          {is_loading ? <i className="spinner"></i> : <Icon className="ficon-rotate-cw" />}
        </Button>
      </Col>
    )}
  </Row>;
}

export default React.memo(ToolbarComponent);