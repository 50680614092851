import { UUID, Helpers, KeyFactory, AppUserKey } from "../";
import { Backup, BackupData } from "./backup.class";

export class RecoveryCode {
  public backup_id: string;
  public backup_public_key?: string; // b64Encoded for server
  public text_encoding?: string; // base_64_encode(serialized_backup)
  readonly _key: Promise<AppUserKey>; // New UserKey for backup
  readonly _protocol_version: number; // 0 = no password encryption / 1 = password encrypted
  private _backup?: Backup;

  constructor(
    public user_id: string,
    readonly _current_user_key: Promise<AppUserKey | undefined>,
    readonly _password?: string,
  ) {
    this._key = KeyFactory.newUserKey();
    this.backup_id = new UUID().String();
    this._protocol_version = !!_password ? 1 : 0;
  }

  // Description: Create the new backup_public_key
  public async createBackup(): Promise<RecoveryCode | undefined> {
    const backup_key = await this._key;
    const user_key = await this._current_user_key;
    if (!!user_key) {
      const backup = new BackupData(
        this.user_id,
        this.backup_id,
        user_key.buffer,
        backup_key.buffer,
      );
      const serialized_backup = backup.serialize();

      // If password was entered, encrypt with password first
      const encrypted_backup =
        this._protocol_version === 1 && !!this._password
          ? await backup.encrypt(this._password, serialized_backup)
          : serialized_backup;

      // Create the backup itself
      this._backup = new Backup(encrypted_backup, this._protocol_version);

      // then serialize and encode the backup {backup_data (encrypted / serialized), protocol_version }
      this.text_encoding = Helpers.b64Encode(this._backup.serialize());
      this.backup_public_key = Helpers.b64Encode(backup_key.public_user_key.serialize());

      return this;
    } else {
      return undefined;
    }
  }
}
