import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IActionHandler, DeviceBase } from "@preveil-api";
import {
  GlobalErrorMessages, MessageAnchors, useAppDispatch, Message, MessageHandlerDisplayType, useAppSelector, AccountIdentifiers, ForgotPasswordSteps,
  AccountErrorMessages, KeyStorageAPI, KeystorageResponseErrorCodes, dayjs, Helpers, KeyStorageData, AppUserKey, KeyFactory, Device,
  DevicePlatform, usePostUsersDevicesKeysMutation, useDeleteUsersDevicesKeysByDeviceIdMutation, useGetUsersDevicesKeysMutation, QueryParamKeys,
  normalizeQueryUserId, PublicRoutes, account_types
} from "src/common";
import { Loading, useAuthContext, ErrorMessage } from "src/components";
import { RootState } from "src/store/configureStore";
import { uiActions, accountActions } from "src/store";
import { getLoginWebStepOnServerError } from "src/store/account/saga";
import { LoginEmailForm, LoginSMSCodeVerificationForm, LoginTOTPCodeVerificationForm } from "../login";
import { CreateNewPasswordForm, EmailCodeVerificationForm, PasteRecoveryCodeForm, RecoveryNotSetup } from ".";
import _ from "lodash";

/* Description:  Component that handles the flow when the user forgets their password and wants to reset it. */
function ForgotPasswordComponent() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { loginComplete } = useAuthContext();
  const step = useAppSelector((state: RootState) => state.account.status);
  const [enter_email, setEnterEmail] = useState<boolean>(true);
  const [email_code, setEmailCode] = useState<string>();
  const [server_shard, setServerShard] = useState<string>();
  const [user_key, setUserKey] = useState<AppUserKey>();
  const [user_id, setUserId] = useState<string>();
  const [deleteDevice] = useDeleteUsersDevicesKeysByDeviceIdMutation(); // going to be used for device cleanup
  const [getDevices] = useGetUsersDevicesKeysMutation();
  const [addDevice] = usePostUsersDevicesKeysMutation();
  const [verification_error_count, setVerificationErrorCount] = useState<number>(0);
  const account_ids = useAppSelector((state: RootState) => state.account.account_ids);
  const redirect_url = useAppSelector((state: RootState) => state.ui.redirect_url);
  const current_account = useAppSelector((state: RootState) => state.account.current_account);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(accountActions.setComponentStatus(ForgotPasswordSteps.LOADING));
    const query_user_id = normalizeQueryUserId(searchParams.get(QueryParamKeys.USER_ID_QUERY_KEY));
    if (!!query_user_id) {
      setEnterEmail(false);
      setUserId(query_user_id);
      searchParams.delete(QueryParamKeys.USER_ID_QUERY_KEY);
      setSearchParams(searchParams);
    } else {
      setEnterEmail(true);
      dispatch(accountActions.setComponentStatus(ForgotPasswordSteps.SUBMIT_EMAIL));
    }
  }, []);

  useEffect(() => {
    if (!!user_id) {
      getServerShard();
    }
  }, [user_id]);

  // Description: Complete step after KSS User creds
  useEffect(() => {
    !!account_ids && dispatch(accountActions.findCollectionServerCurrentUser(account_ids));
  }, [account_ids]);

  // Description: Update the login status when current account is set in state
  useEffect(() => {
    (!!current_account || !!redirect_url) &&
      loginComplete(current_account, account_types.express, redirect_url);
  }, [current_account, redirect_url]);

  /* Description:  Get Server Shard for user recovery */
  function getServerShard(email_code?: string, sms_code?: string, totp_code?: string) {
    if (!!user_id) {
      KeyStorageAPI.getServerShard(user_id, email_code, sms_code, totp_code).then((response) => {
        if (!response.isError) {
          setServerShard(response.data.server_shard);
          if (!!response.data.server_shard) {
            dispatch(accountActions.setComponentStatus(ForgotPasswordSteps.PASTE_RECOVERY_CODE));
          } else {
            dispatch(accountActions.setComponentStatus(ForgotPasswordSteps.NO_RECOVERY_SETUP));
          }
        } else {
          const message_code = response.data.error || response.data.bad_parameter || "";
          const two_factor_codes = [KeystorageResponseErrorCodes.no_sms_auth_header, KeystorageResponseErrorCodes.no_totp_auth_header];
          if (message_code === KeystorageResponseErrorCodes.no_email_auth_header) {
            dispatch(accountActions.setComponentStatus(ForgotPasswordSteps.SUBMIT_EMAIL_CODE));
          } else if (two_factor_codes.includes(message_code)) {
            if (message_code === KeystorageResponseErrorCodes.no_sms_auth_header) {
              dispatch(accountActions.setComponentStatus(ForgotPasswordSteps.SUBMIT_SMS_CODE));
            } else if (message_code === KeystorageResponseErrorCodes.no_totp_auth_header) {
              dispatch(accountActions.setComponentStatus(ForgotPasswordSteps.SUBMIT_TOTP_CODE));
            }
          } else if (message_code === KeystorageResponseErrorCodes.bad_email_code) {
            if (verification_error_count === 4) {
              dispatch(uiActions.handleRequestErrors(new Message(AccountErrorMessages.bad_code_reset)));
              setVerificationErrorCount(0);
              dispatch(accountActions.setComponentStatus(ForgotPasswordSteps.SUBMIT_EMAIL));
            } else {
              setVerificationErrorCount(verification_error_count + 1);
              dispatch(uiActions.handleRequestErrors(new Message(AccountErrorMessages.bad_verification_code)));
            }
          } else {
            const step_error = getLoginWebStepOnServerError(message_code);
            const message = _.has(AccountErrorMessages, message_code) ? (AccountErrorMessages as any)[message_code] : AccountErrorMessages.default;
            dispatch(accountActions.setComponentStatus(step_error));
            dispatch(uiActions.handleRequestErrors(new Message(message)));
            step_error === ForgotPasswordSteps.SUBMIT_EMAIL && ForgotPasswordRequests.handleResetLogin();
          }
        }
      }).catch((error) => {
        dispatch(uiActions.handleRequestErrors(new Message(AccountErrorMessages.error_resetting_password), error));
        ForgotPasswordRequests.handleResetLogin();
      });
    }
  }

  /* Description: Creates a new device key and recovers the users account with the new password and auth token. */
  async function recoverExpressUser(auth_token: string, pass: string) {
    const device_key = await KeyFactory.newUserKey();
    const kss_device: DeviceBase = await Device.createNewJSONLocalDevice(DevicePlatform.express.text, "express", device_key);
    if (!!user_id && !!user_key) {
      const account_ids = {
        user_id,
        user_key,
        device_id: kss_device.device_id,
        device_key,
        user_key_serialized: Helpers.b64Encode(user_key.serialize()),
        device_key_serialized: Helpers.b64Encode(device_key.serialize())
      };
      const wrapped_data = await KeyStorageData.wrapKSSData(pass, user_key, device_key, 1, account_ids.device_id);
      KeyStorageAPI.rekeyUser(account_ids, undefined, wrapped_data, undefined, Helpers.b64Encode(user_key.public_user_key.verify_key.public_key), auth_token).then((response) => {
        if (!response.isError) {
          addNewDevice(account_ids, kss_device, auth_token);
        } else {
          const message = response.data.bad_parameter === AccountErrorMessages.previous_password_existed ? AccountErrorMessages.cannot_user_previous_password : AccountErrorMessages.error_changing_password;
          dispatch(uiActions.handleRequestErrors(new Message(message), response.data));
          response.data.bad_parameter === AccountErrorMessages.previous_password_existed ? dispatch(accountActions.setComponentStatus(ForgotPasswordSteps.CREATE_NEW_PASSWORD)) : ForgotPasswordRequests.handleResetLogin();
        }
      }).catch((error) => {
        dispatch(uiActions.handleRequestErrors(new Message(AccountErrorMessages.default), error));
        ForgotPasswordRequests.handleResetLogin();
      });
    }
  }

  /* Description: Adds a new device since we needed to create a new one in order to recover the account */
  function addNewDevice(_account_ids: AccountIdentifiers, device: DeviceBase, auth_token: string) {
    if (!!user_id) {
      const request = {
        account_ids: _account_ids,
        body: {
          device,
          user_id
        },
      };
      addDevice(request).then(() => {
        deviceCleanUp(_account_ids, device, auth_token);
      }).catch((error: any) => {
        dispatch(uiActions.handleRequestErrors(new Message(AccountErrorMessages.restore_account_error), error));
        ForgotPasswordRequests.handleResetLogin();
      });
    }
  }

  /* Description: After creating a new device key, we must delete the other devices 
  we first get all the devices and delete the ones that are not the current one. */
  function deviceCleanUp(_account_ids: AccountIdentifiers, device: DeviceBase, auth_token: string) {
    const request = {
      account_ids: _account_ids,
      body: {
        userId: _account_ids.user_id,
      },
    };
    getDevices(request).then((response: any) => {
      const non_current_devices = response.data.devices.filter((_device: any) => _device.device_id !== device.device_id);
      _.forEach(non_current_devices, (_device: any, index: number) => {
        const request = {
          account_ids: _account_ids,
          body: {
            user_id: _account_ids.user_id,
            device_id: _device.device_id,
          },
        };
        deleteDevice(request).then(() => {
          if (index === non_current_devices.length - 1) {
            login(_account_ids, auth_token);
          }
        }).catch((error: any) => {
          dispatch(uiActions.handleRequestErrors(new Message("Error Deleting Device", MessageHandlerDisplayType.logger), error));
          if (index === non_current_devices.length - 1) {
            login(_account_ids, auth_token);
          }
        });
      });
    }).catch((error: any) => {
      dispatch(uiActions.handleRequestErrors(new Message("Error Deleting Devices", MessageHandlerDisplayType.logger), error));
      login(_account_ids, auth_token);
    });
  }

  function login(_account_ids: AccountIdentifiers, auth_token: string) {
    dispatch((accountActions.handleSetAuthToken(auth_token)));
    dispatch(accountActions.getKssUserSuccess(_account_ids));
  }

  // Description: Handle all children component actions and store it
  const ForgotPasswordRequests = {
    handleSubmitEmail: (user_email: string) => {
      dispatch(accountActions.setComponentStatus(ForgotPasswordSteps.LOADING));
      setUserId(user_email);
    },
    // Description: (** FOR PASSWORD RECOVERY) Combine the server shard and the user shard to get the user key and set it. 
    // Continue on to next step - create a new password
    handleSubmitRecoverCode: async (user_key?: AppUserKey) => {
      if (!!server_shard) {
        dispatch(accountActions.setComponentStatus(ForgotPasswordSteps.LOADING));
        if (!!user_key) {
          setUserKey(user_key);
          dispatch(accountActions.setComponentStatus(ForgotPasswordSteps.CREATE_NEW_PASSWORD));
        } else {
          dispatch(uiActions.handleRequestErrors(new Message("Wrong Recovery Code. Please try again.")));
          dispatch(accountActions.setComponentStatus(ForgotPasswordSteps.PASTE_RECOVERY_CODE));
        }
      }
    },
    handleSubmitSMSVerificationCode: (sms_code: string) => {
      dispatch(accountActions.setComponentStatus(ForgotPasswordSteps.LOADING));
      getServerShard(email_code, sms_code);
    },
    handleSubmitTOTPVerificationCode: (totp_code: string) => {
      dispatch(accountActions.setComponentStatus(ForgotPasswordSteps.LOADING));
      getServerShard(email_code, undefined, totp_code); // NOTE: if the user is in the forgot password flow then we need to get the server shard
    },
    // Description: Reset all form states and go back to Login page
    handleResetLogin: () => {
      setEmailCode(undefined);
      setServerShard(undefined);
      setUserKey(undefined);
      setUserId(undefined);
      setEnterEmail(true);
      navigate(`/${PublicRoutes.login_route}`); // Redirect to login page
    },
    // Description: creates a new auth token with the new password and 
    // rekeys the user with the new password and the new auth token.
    handleSubmitNewPassword: async (pass: string) => {
      if (!!user_key && !!user_id) {
        dispatch(accountActions.setComponentStatus(ForgotPasswordSteps.LOADING));
        const token = await KeyStorageData.authenticationToken(user_id, pass);
        const auth_token = Helpers.b64Encode(token);
        recoverExpressUser(auth_token, pass);
      }
    },
    handleSubmitVerificationCode: (params: { secret: string, user_id: string }) => {
      setEmailCode(params.secret);
      getServerShard(params.secret, undefined, undefined);
    },
    handlePageError: (message: string) => {
      ForgotPasswordRequests.handleResetLogin();
      dispatch(uiActions.handleRequestErrors(new Message(message)));
    },
    handlePageErrorMessage: (params: { message: string, stack?: any }) => {
      dispatch(uiActions.handleRequestErrors(new Message(params.message, MessageHandlerDisplayType.logger), params.stack));
    }
  };

  //  Description: Handle all actions from Children forms
  function handlePageActions(actionObj: IActionHandler) {
    dispatch(uiActions.handleMessageDismiss());
    const callback = `handle${actionObj.actionType}`;
    // Handle local calls:
    if ((ForgotPasswordRequests as any)[callback] instanceof Function) {
      (ForgotPasswordRequests as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(MessageAnchors.actionType, actionObj.actionType);
      ForgotPasswordRequests.handlePageErrorMessage({ message, stack: actionObj });
    }
  }

  const stepDisplayNumbers: { [key: number]: number } = {
    [ForgotPasswordSteps.SUBMIT_EMAIL]: 1,
    [ForgotPasswordSteps.SUBMIT_EMAIL_CODE]: enter_email ? 2 : 1,
    [ForgotPasswordSteps.SUBMIT_SMS_CODE]: enter_email ? 3 : 2,
    [ForgotPasswordSteps.SUBMIT_TOTP_CODE]: enter_email ? 3 : 2,
    [ForgotPasswordSteps.PASTE_RECOVERY_CODE]: enter_email ? 4 : 3,
    [ForgotPasswordSteps.CREATE_NEW_PASSWORD]: enter_email ? 5 : 4,
  };

  // Description: Render the correct form
  function ForgotPasswordForms() {
    switch (step) {
      case 1:
        return <Card.Body className="p-3 m-1">
          <LoginEmailForm handleAction={handlePageActions} forgot_password={true}>
            <p>Please enter your <b>PreVeil Express user ID</b> to get back into your account.</p>
          </LoginEmailForm>
        </Card.Body>;
      case 2:
        return !!user_id ?
          <EmailCodeVerificationForm user_id={user_id} expires={dayjs().add(15, "minutes").format("YYYY-MM-DD HH:mm:ss")}
            handleAction={handlePageActions} /> : <FPErrorMessage />;
      case 3:
        return <Card.Body className="p-3 m-1">
          <LoginSMSCodeVerificationForm handleAction={handlePageActions} forgot_password={true} />
        </Card.Body>;
      case 4:
        return <Card.Body className="p-3 m-1">
          <LoginTOTPCodeVerificationForm handleAction={handlePageActions} forgot_password={true} />
        </Card.Body>;
      case 5:
        return !!server_shard ?
          <PasteRecoveryCodeForm handleAction={handlePageActions} server_shard={server_shard} /> :
          <FPErrorMessage />;
      case 6:
        return <CreateNewPasswordForm handleAction={handlePageActions} />;
      case 7:
        return <RecoveryNotSetup handleAction={handlePageActions} />;
      default:
        return <Loading className="in-place" />;
    }
  }

  // Description: Alternate card for errors
  function FPErrorMessage() {
    return <Card.Body className="p-3 m-1">
      <ErrorMessage message={GlobalErrorMessages.default} />
    </Card.Body>;
  }

  return step > 0 ? <Row className="justify-content-center">
    <Col md={7} className={step === 1 ? "max-400 mt-5" : ""}>
      <h1 className="main-header">Forgot Your Password?{step !== 7 && <small>{stepDisplayNumbers[step]} of {enter_email ? "5" : "4"}</small>}</h1>
      <Card className="pv-card">
        <ForgotPasswordForms />
      </Card>
    </Col>
  </Row> : <Loading />;
};

export default React.memo(ForgotPasswordComponent);
