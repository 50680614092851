import React from "react";
import { UserProfile, ActionHandlerFunction, CollectionServerUser } from "@preveil-api";
import { DataExportApproversRow } from ".";

type User = UserProfile & CollectionServerUser;

type AllProps = {
  approvers: UserProfile[];
  current_users_approved: UserProfile[];
  handleActions: ActionHandlerFunction;
  required_approvers: number | undefined;
}

function DataExportApproversPanel({ approvers, current_users_approved, handleActions, required_approvers }: AllProps) {
  const users_approved_total = current_users_approved && current_users_approved.length;
  const current_users_approved_map: any = current_users_approved.length > 0 && current_users_approved.reduce((map, user) => {
    if (!!user && user?.address) {
      return {
        ...map,
        [user.address]: user
      };
    } else {
      return map;
    }
  }, {});
  return (
    <>
      <div className="required-approvals-header">
        <h3>{`You've received ${users_approved_total} of ${required_approvers} required approvals`}</h3>
      </div>
      <div className="groups-card approvers-card mb-0">
        <header>
          <p className="m-0">Approvers</p>
        </header>
        <div>
          {approvers && approvers.map((approver) => {
            const is_approved = current_users_approved_map && current_users_approved_map[approver.address];
            const isReadOnly = required_approvers === current_users_approved.length && !is_approved;
            return <DataExportApproversRow key={approver.address} user={approver as User} is_approved={is_approved} handleActions={handleActions} is_read_only={isReadOnly} />;
          })}
        </div>
      </div>
    </>
  );
}

export default React.memo(DataExportApproversPanel);