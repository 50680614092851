import React, { useState } from "react";
import { Tabs, Tab, Button, Modal, Form, Row, Col } from "react-bootstrap";

function ModalsComponent() {
  const [lgShow, setLgShow] = useState(false);
  const [confShow, setConfShow] = useState(false);
  const [fullScreenShow, setFullScreenShow] = useState(false);

  // Description: Modals
  const BasicModals = () => {
    return (
      <>
        <h4>MODALS</h4>
        <div>
          <Button onClick={() => setLgShow(true)} className="me-2">
            Standard modal
          </Button>
          <Button onClick={() => setConfShow(true)} className="me-2">
            Confirmation Dialog
          </Button>
          <Button onClick={() => setFullScreenShow(true)} className="me-2">
            Full Screen
          </Button>
          <Modal
            size="lg"
            show={lgShow}
            onHide={() => setLgShow(false)}
            aria-labelledby="standard modal"
            centered
          >
            <Modal.Header>
              <Modal.Title>Add Users</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Tabs
                defaultActiveKey="individual"
                id="uncontrolled-tab-example">
                <Tab eventKey="individual" title="Individual">
                  <Form>
                    <Row>
                      <Col xs={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Email address</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter email"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control type="text" placeholder="Enter email" />
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicPassword"
                        >
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control type="text" placeholder="Password" />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Department</Form.Label>
                          <Form.Control type="text" placeholder="Enter email" />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Button onClick={() => setLgShow(false)} className="me-2">
                      Create Account
                    </Button>
                    <Button
                      onClick={() => setLgShow(false)}
                      className="me-2"
                      variant="no-outline-primary"
                    >
                      Reset
                    </Button>
                    <Button
                      onClick={() => setLgShow(false)}
                      variant="no-outline-primary"
                    >
                      Cancel
                    </Button>
                  </Form>
                </Tab>
                <Tab eventKey="group-upload" title="Group Upload">
                  <p>
                    Download and use the upload template to accurately add
                    multiple users at once
                  </p>
                  <div className="draggable-container">
                    <div className="draggable-container-cover"></div>
                    <div className="draggable-container-text">
                      <div>Drag .CSV file here</div>
                      <div>
                        <i>or</i>
                      </div>
                      <div>Click to choose file</div>
                    </div>
                  </div>
                  <Button
                    onClick={() => setConfShow(false)}
                    variant="no-outline-primary"
                  >
                    Cancel
                  </Button>
                </Tab>
              </Tabs>
            </Modal.Body>
          </Modal>
          <Modal
            show={confShow}
            onHide={() => setConfShow(false)}
            centered
            aria-labelledby="confirmation modal"
          >
            <Modal.Header>
              <Modal.Title>Delete Folder:"Apple"</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Are you sure you want to delete this folder? All items under
                this folder will also be deleted
              </p>
              <Button onClick={() => setConfShow(false)} className="me-2">
                Yes
              </Button>
              <Button
                onClick={() => setConfShow(false)}
                variant="no-outline-primary"
              >
                No
              </Button>
            </Modal.Body>
          </Modal>
          <Modal
            size="lg"
            show={fullScreenShow}
            fullscreen={true}
            onHide={() => setFullScreenShow(false)}
            aria-labelledby="fullscreen modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Full Screen Modal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Modal Body Content</p>
            </Modal.Body>
          </Modal>
        </div>
      </>
    );
  };

  return <BasicModals />;
};

export default React.memo(ModalsComponent);
