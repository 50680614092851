import React, { createRef, FocusEvent, forwardRef, useImperativeHandle } from "react";
import { DirectoryEntity, DriveErrorMessages, DriveUIActionTypes } from "src/common";
import { ActionHandlerFunction } from "@preveil-api";

type AllProps = {
  current_directory?: DirectoryEntity;
  handleAction: ActionHandlerFunction;
}

const FileUploadComponent = forwardRef(function FileUploadComponent(props: AllProps, ref) {
  const { current_directory, handleAction } = props;
  const fileRef = createRef<HTMLInputElement>();

  useImperativeHandle(ref, () => {
    return {
      click() {
        !!fileRef.current ? fileRef.current.click() : console.error(DriveErrorMessages.error_opening_editor_file_upload);
      }
    };
  });

  // Description: Pass selected files to upload hook
  function handleAddFiles(e: FocusEvent<HTMLInputElement>) {
    const files = e.target.files;
    if (!!files && files.length > 0) {
      handleAction({ actionType: DriveUIActionTypes.Upload, params: { destination_id: current_directory?.id, files } });
    }
    if (!!fileRef.current) {
      fileRef.current.value = "";
    }
  }

  return <input ref={fileRef} className="d-none" multiple type="file" onChange={handleAddFiles} />;
});

export default React.memo(FileUploadComponent);
