import { randomBytes } from "pvcryptojs";
import { Helpers } from "src/common";

const prime = BigInt("115792089237316195423570985008687907853269984665640564039457584007913129639747");

function padding(substringLength: number): string {
  return [...Array(64 - substringLength).keys()].map(_ => "0").join("");
}

function hexBytesFrom(value: bigint): Uint8Array {
  const hex = value.toString(16);
  const hexBytesString = padding(hex.length) + hex;
  return new Uint8Array(
    Array.from({ length: hexBytesString.length / 2 }, (_, i) => i * 2).map(index => {
      const substring = hexBytesString.substring(index, index + 2);
      return parseInt(substring, 16);
    })
  );
}

export function toB64(value: bigint): string {
  const data = hexBytesFrom(value);
  return Helpers.b64Encode(data, true);
}

export function fromB64(encoded: string): bigint {
  const hex = Helpers.hexEncode(Helpers.b64Decode(encoded));
  return BigInt('0x' + hex + padding(hex.length));
}

export function randomIntegerLessThanPrime(): bigint {
  const random = fromB64(Helpers.b64Encode(randomBytes(32)));
  if (random < prime) return random;
  return randomIntegerLessThanPrime();
}

// Description: Converts the raw user key (secret) into a large byte array so that mathematical operations can be performed
export function splitInts(secret: string): bigint[] {
  const hex = Helpers.hexEncode(Helpers.utf8Encode(secret));
  if (Helpers.utf8Encode(hex).byteLength <= 64) {
    return [BigInt('0x' + hex + padding(hex.length))];
  }

  return Array.from({ length: Math.ceil(hex.length / 64) }, (_, i) => i * 64).map(index => {
    const endIndex = index + 64;
    if (endIndex > hex.length) {
      const substring = hex.substring(index, hex.length);
      return BigInt('0x' + substring + padding(hex.length - index));
    }
    const substring = hex.substring(index, endIndex);
    return BigInt('0x' + substring);
  });
}

// Description: Converts a byte array into a string representing the recovered user key
export function mergeInts(ints: bigint[]): string {
  const data = ints.map(int => {
    const hexBytes = hexBytesFrom(int);
    return hexBytes.filter(byte => byte !== 0);
  }).reduce((totalBytes, currentBytes) => {
      return new Uint8Array([...totalBytes, ...currentBytes]);
    },
    new Uint8Array()
  );
  return Helpers.utf8Decode(data);
}

// Description: Takes an array of coefficients which are used to evaluate a polynomial given x
export function evaluatePolynomial(polynomial: bigint[], value: bigint): bigint {
  return polynomial.slice().reverse()
    .reduce((result, coefficient) => {
        return (result * value + coefficient) % prime;
      },
      BigInt(0)
    );
}

// Description: Takes a base64 encoded user key and returns an array of length "shares" containing key shards
export function create(minimum: number, shares: number, raw: string): string[] | undefined {
  if (shares < minimum) return;
  const secret = splitInts(raw);
  let numbers: bigint[] = [BigInt(0)];
  let polynomial: bigint[][] = [];
  secret.forEach((share, index) => {
    polynomial.push([share]);
    for (let j = 1; j < minimum; j++) {
      let value = randomIntegerLessThanPrime();
      while (numbers.includes(value)) {
        value = randomIntegerLessThanPrime();
      }
      numbers.push(value);
      polynomial[index].push(value);
    }
  });
  let result = [...Array(shares).keys()].map(_ => "");
  for (let i = 0; i < shares; i++) {
    for (let j = 0; j < secret.length; j++) {
      let value = randomIntegerLessThanPrime();
      while (numbers.includes(value)) {
        value = randomIntegerLessThanPrime();
      }
      const yValue = evaluatePolynomial(polynomial[j], value);
      const valueB64 = toB64(value);
      const yB64 = toB64(yValue);
      result[i] += valueB64 + yB64;
    }
  }
  return result;
}

// Description: Takes an array of key shards with a length of at least "minimum" as defined in the create() function
// Combines the shards to reconstruct the original user key
export function combine(shares: string[]): string {
  const secrets: bigint[][][] = [];
  shares.forEach((share, i) => {
    if (share.length % 88 !== 0) return;
    const count = share.length / 88;
    secrets.push([]);
    for (let j = 0; j < count; j++) {
      const cShare = share.substring(j * 88, (j + 1) * 88);
      secrets[i].push([
        fromB64(cShare.substring(0, 44)),
        fromB64(cShare.substring(44, 88))
      ]);
    }
  });

  const secret = [...Array(secrets[0].length)].map(_ => BigInt(0));

  for (let i = 0; i < secret.length; i++) {
    secrets.forEach((share, shareIndex) => {
      const origin = share[i][0];
      const originY = share[i][1];
      let numerator = BigInt(1);
      let denominator = BigInt(1);
      secrets.forEach((product, productIndex) => {
        if (productIndex === shareIndex) return;
        const current = product[i][0];
        numerator = (numerator * (prime - current) ) % prime;
        denominator = (denominator * (prime + origin - current)) % prime;
      });
      const working = originY * numerator * (modInverse(denominator, prime)!);
      secret[i] = (secret[i] + working) % prime;
    });
  }
  return mergeInts(secret);
}

const abs = (n: bigint) => (n < BigInt(0) ? -n : n);

export function modInverse(value: bigint, modulus: bigint): bigint | undefined {
  let t1 = BigInt(0);
  let t2 = BigInt(1);
  let r1 = modulus;
  let r2 = value;
  while (r2 !== BigInt(0)) {
    const quotient = r1 / r2;
    [t1, t2] = [t2, t1 - quotient * t2];
    [r1, r2] = [r2, r1 - quotient * r2];
  }
  if (r1 > 1) return undefined;
  if (t1 < 0) return modulus - abs(t1);
  return abs(t1);
}
