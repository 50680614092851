import { FSMessage, FSRequest, FSStatus, FSMethod } from "src/common/keys/protos/collections_pb";
import {
  UUID, DriveRequest, WebsocketConnectionRoutes, Message, DriveErrorMessages, MessageHandlerDisplayType, getEnumKey,
  ErrorStackItem
} from "src/common";
import { WebsocketStatusType, WebsocketStatus, uiActions, MessageResponse } from "src/store";
import _ from "lodash";

export class DriveSocket {
  status: WebsocketStatusType = WebsocketStatus.disconnected;
  socket!: WebSocket;
  request_by_id: { [id: string]: DriveRequest } = {};
  host!: string;
  constructor(host?: string) {
    this.host = !!host ? host : `${process.env.REACT_APP_BACKEND_WEBSOCKET_SERVER}/${WebsocketConnectionRoutes.collection_ws}`;
    this.initSocket();
  }

  // Description: Initialize drive socket specifically with arraybuffer as type
  protected initSocket() {
    const _socket = new WebSocket(this.host);
    _socket.binaryType = "arraybuffer";
    this.socket = this._socketListener(_socket);
    this.request_by_id = { default: new DriveRequest(new FSRequest()) };
  }

  // Description: bind socket listeners
  private _socketListener(_socket: WebSocket) {
    _socket.onopen = this._onOpen;
    _socket.onmessage = (message_event: MessageEvent) => { return this._onMessage(message_event.data); };
    _socket.onclose = this._onClose;
    _socket.onerror = this._onError;
    return _socket;
  }

  _onOpen() {
    this.status = WebsocketStatus.connected;
  }

  _onError(event: Event) {
    console.log("SOCKET ERROR: ", event);
    // this.error = true;
  }

  _onClose() {
    this.status = WebsocketStatus.disconnected;
  }

  // Description: On message from the server link request to response and pass it back to the receiveMessageSaga
  _onMessage(data: ArrayBuffer): MessageResponse<any> {
    const _challenge = new Uint8Array(data);
    const message = FSMessage.deserializeBinary(_challenge);

    // NOTE: Set the request for matching with response
    const request_id = new UUID({ bytes: message.getRequestId_asU8() }).String();
    if (!!this.request_by_id && Object.prototype.hasOwnProperty.call(this.request_by_id, request_id)) {
      const drive_request = this.request_by_id[request_id];
      this.request_by_id = _.omit(this.request_by_id, [request_id]);

      //  NOTE: Handles response message status and callbacks
      const successStatuses = [FSStatus.OK, FSStatus.PARTIALLY_APPLIED, FSStatus.BULK_UPDATE_FAILED] as Number[];
      const status = Number(message.getStatus());
      if (successStatuses.includes(status) && !!drive_request.callback && drive_request.callback instanceof Function) {
        return { message, callback: drive_request.callback, success_action: drive_request.success_action };
      } else { // return error
        const method = !!drive_request?.fs_request.getMethod() ? getEnumKey(FSMethod, drive_request?.fs_request.getMethod()) : "empty";
        const status_str = !!status ? getEnumKey(FSStatus, status) : "empty";
        const error_msg = `Method: ${method}"} | Status: ${status_str} | Message: ${message?.getErrorMessage() || "none"}`;
        const stack = new ErrorStackItem("[DriveSocket _onMessage ]", message?.getErrorMessage() || error_msg, status_str || "error",
          { stack_message: error_msg, stack_error: request_id }).info;
        const error_action = (uiActions.handleRequestErrors(new Message(DriveErrorMessages.default, MessageHandlerDisplayType.toastr), stack));
        return { message, error_action };
      }
    } else {
      const error_msg = "request_by_id NOT Found!";
      const error_action = (uiActions.handleRequestErrors(new Message(DriveErrorMessages.default, MessageHandlerDisplayType.logger),
        { stack_message: error_msg, stack_error: request_id }));
      return { message, error_action };
    }
  }

  // Description: Set the specific request by id
  setRequest(id: string, request: DriveRequest): void {
    this.request_by_id[id] = request;
  }

  // Description: class send if not using sagas for a drive websocket instance (backup method)
  sendRequest(request: DriveRequest): void {
    this.setRequest(request.request_id.String(), request);
    this.socket.send(request.envelope);
  }

  // Description: public method to close socket
  close() {
    this.socket.close();
  }
}
