import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { NodePermissionSet, NodePermissionTypes, NodePermissionLabels, NodePermissionChange, NodePermissionTooltips, NodePermissions } from "src/common";
import { InfoPopover } from "src/components";
import { EditExpiration } from ".";
import { PermissionsMenu } from "../../../action-menus";

type AllProps = {
  edit_mode: boolean;
  class_name: string;
  node_permission: NodePermissionSet;
  owner_row?: boolean; // NOTE: Disable row if grantee is owner but current User is NOT an Owner
  handleAction?: (new_permission: NodePermissionSet, saved: NodePermissionChange) => void;
};

function AccessRowComponent(props: AllProps) {
  const { node_permission, class_name, edit_mode, owner_row, handleAction } = props;
  const [new_type, setNewType] = useState<NodePermissionTypes>(node_permission.type);
  const [new_expiration, setNewExpiration] = useState<string>(node_permission.expiration);
  const expirationRef = useRef<{ handleReset: (expiration: string) => void }>();

  // Description: Reset type value when 
  useEffect(() => {
    if (!class_name) {
      setNewType(node_permission.type);
      setNewExpiration(node_permission.expiration);
      expirationRef.current?.handleReset(node_permission.expiration);
    }
  }, [class_name]);

  useEffect(() => {
    if (!class_name) {
      setNewExpiration(node_permission.expiration);
      setNewType(node_permission.type);
    }
  }, [node_permission]);

  // Description: Handles permission change
  function handleChange(type: NodePermissionTypes, expiration: string) {
    setNewExpiration(expiration);
    setNewType(type);
    const _node_permission = NodePermissions.getNewNodePermissions(node_permission, type, expiration);
    !!handleAction &&
      handleAction(_node_permission, { type: node_permission.type, expiration: node_permission.expiration });
  }

  // Description: Reset from child EditExpirationComponent
  function handleRowResetExpirationDate() {
    setNewExpiration(node_permission.expiration);
    expirationRef.current?.handleReset(node_permission.expiration);
    !!handleAction &&
      handleAction(node_permission, { type: new_type, expiration: node_permission.expiration });
  }

  function getOwnersTooltip() {
    return `${node_permission.user_id} is an owner of this folder. ${!!owner_row ? "You are Unable to modify access" : ""} `;
  }

  function getPendingTooltip() {
    return `${node_permission.user_id} has not accepted the invitation to share this folder`;
  }

  return <Row className={`${class_name}${!!owner_row ? " owner" : ""}${node_permission.pending ? " pending" : ""}`}>
    <Col xs={5}
      data-tooltip-id="pv-tooltip"
      data-tooltip-content={!!node_permission.is_owner ? getOwnersTooltip() : node_permission.pending ? getPendingTooltip() : node_permission.user_id}>
      {node_permission.user_id}
      {
        node_permission.pending &&
        <InfoPopover
          message={`Access Pending: ${node_permission.user_id} has not accepted the invitation to share this folder`}
          placement="right-start"
          trigger={"click"} />
      }
    </Col>
    <Col>
      <EditExpiration
        disabled={edit_mode || !!owner_row}
        type={new_type}
        expiration={new_expiration}
        ref={expirationRef}
        handleReset={handleRowResetExpirationDate}
        handleAction={(_expiration: string) => handleChange(new_type, _expiration)} />
    </Col>
    <Col xs="auto" className="ms-auto">
      {
        edit_mode || !!owner_row ?
          <span
            className="me-2"
            data-tooltip-id="pv-tooltip"
            data-tooltip-content={owner_row ? getOwnersTooltip() : NodePermissionTooltips[new_type]}
            data-tooltip-place="right">{`${node_permission.pending ? "Access Pending: " : ""}${NodePermissionLabels[new_type]}`}
            {
              (edit_mode && node_permission.is_owner) &&
              <span className="text-muted ms-1 fs-6">(Owner)</span>
            }
          </span> :
          <PermissionsMenu
            permission_type={new_type}
            handleAction={(type: NodePermissionTypes) => handleChange(type, new_expiration)} />
      }
    </Col>
  </Row>;
}

export default React.memo(AccessRowComponent);