import React, { useState, useEffect } from "react";
import { Challenge, Response, Result } from "src/common/keys/protos/challenge_response_pb";
import { Notification, Topic } from "src/common/keys/protos/notification_pb";
import {
  Account, useAppDispatch, useAppSelector, NotifyWStSteps, Helpers, Message, MessageHandlerDisplayType, GlobalErrorMessages
} from "src/common";
import { uiActions, websocketActions, WebsocketStatus, mailActions, appActions } from "src/store";


function NotificationManagerComponent(props: { current_account: Account }) {
  const { current_account } = props;
  const [step, setStep] = useState<number>(NotifyWStSteps.INITIALIZE_CONNECTION);
  const dispatch = useAppDispatch();
  const message = useAppSelector((state) => state.websocket.notify?.message);
  const status = useAppSelector((state) => state.websocket.notify?.status);
  const new_event = useAppSelector((state) => state.app.new_event);

  // Description: First onload event
  useEffect(() => {
    handleInitNotifyConnect();
    return () => {
      dispatch(websocketActions.wsNotifyDestroy());
    };
  }, []);

  // Description: Handle status and message changes and results
  useEffect(() => {
    if (!!message && (status === WebsocketStatus.connection_established)) {
      switch (step) {
        case NotifyWStSteps.PROCESS_CHALLENGE:
          handleProcessChallenge(message as ArrayBuffer);
          break;
        case NotifyWStSteps.EXPECTING_RESULT:
          handleResult(message as ArrayBuffer);
          break;
        case NotifyWStSteps.PROCESS_NOTIFICATIONS:
          handleNotifications(message as ArrayBuffer);
          break;
      }
    }
  }, [message, status]);

  // Description: Load events when an event notification comes through notify_ws
  useEffect(() => {
    if (!!current_account && new_event === 1) {
      const account_ids = Account.getAccountIdentifiers(current_account);
      dispatch(appActions.getEvents(account_ids, current_account.user_keys));
    }
  }, [new_event]);

  // Description: Initialize Component and request challenge response authentication
  function handleInitNotifyConnect() {
    dispatch(websocketActions.wsNotifyConnect());
    setStep(NotifyWStSteps.PROCESS_CHALLENGE);
  }

  // Description: Handle process nonce challenge for authentication
  async function handleProcessChallenge(_message: ArrayBuffer) {
    const _challenge = new Uint8Array(_message);
    const deserialized = Challenge.deserializeBinary(_challenge);
    const challenge_nonce = deserialized.getNonce();
    if (!!challenge_nonce && challenge_nonce.length > 5) { // Note: arbitrary length validation, but greater than "0"
      const challenge_str = `/notify_ws:${current_account.user_id}:${BigInt(challenge_nonce)}`;
      const _challenge_str = Helpers.utf8Encode(challenge_str);
      const signature = await current_account.user_key.signing_key.sign(_challenge_str);
      const response = new Response();
      response.setUsername(current_account.user_id);
      response.setSignature(signature);
      const serialized = response.serializeBinary();
      dispatch(websocketActions.wsNotifyOnSendMessage(serialized));
      setStep(NotifyWStSteps.EXPECTING_RESULT);
    } else {
      dispatch(uiActions.handleRequestErrors(new Message(GlobalErrorMessages.default, MessageHandlerDisplayType.logger),
        { stack_message: GlobalErrorMessages.server_ws_challenge_failure, challenge_nonce }));
    }
  }

  // Description: Handle Authentication results (matching on server side success: boolean)
  function handleResult(_message: ArrayBuffer) {
    const _result = new Uint8Array(_message);
    const deserialized_result = Result.deserializeBinary(_result);
    deserialized_result.getSuccess() ? setStep(NotifyWStSteps.PROCESS_NOTIFICATIONS) :
      dispatch(uiActions.handleRequestErrors(new Message(GlobalErrorMessages.default, MessageHandlerDisplayType.logger),
        { stack_message: GlobalErrorMessages.server_ws_authentication_failure }));
  }

  // Description: Handle all Notifications
  // NEW_EVENT: 1; => set approval group, rekey and set approval group, submit shard to export group
  // NEW_MAIL: 2; => new email receive (includes drafts, and sends)
  // INVITEE_SIGNUP: 3; => an invitee has signed up
  function handleNotifications(_message: ArrayBuffer) {
    const _response = new Uint8Array(message as ArrayBuffer);
    const deserialized = Notification.deserializeBinary(_response);
    const topic = deserialized.getTopic();
    // Note: Track new notifications in store
    (!!topic && topic === Topic.NEW_MAIL) && dispatch(mailActions.setNewMail(true));
    (!!topic && topic === Topic.NEW_EVENT) && dispatch(appActions.setNewEvent());
    (!!topic && topic === Topic.INVITEE_SIGNUP) && dispatch(appActions.setNewInviteeSignup(true));
  }

  return null;
}

export default React.memo(NotificationManagerComponent);