import { useState, useCallback, useRef } from "react";
import {
  ThreadMessage, JSONRequestBlock, CollectionServerUser, JSONResponseBlock, JSONUrlsResponseBlock, JSONApiBlockResponse, ThreadAttachment,
  MessageContents
} from "@preveil-api";
import {
  Account, isSameUser, usePostUsersFindMutation, MailErrorMessages, Helpers, useAppSelector, EMAIL_PROTOCOL_VERSIONS, decryptBody,
  FetchS3MessageBlocks, MailThreadMessage, MessageAnchors, usePostStorageDownloadProxyMutation,
  usePostStorageDownloadDirectMutation, PostStorageDownloadApiResponse, Attachment, decryptAttachment
} from "src/common";
import _ from "lodash";
import { RootState } from "src/store/configureStore";

interface CollIds {
  user_id: string;
  mail_collection_id: string;
}

interface GetMessageError {
  message: string;
  is_proxy: boolean;
  requested_message?: ThreadMessage;
  stack?: unknown;
}

export function useGetWebMessages(current_account: Account, include_attachments: boolean = false) {
  const [message_results, setMessageResults] = useState<ThreadMessage[]>([]);
  const [collection_ids, setCollectionIds] = useState<CollIds[]>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<GetMessageError>();
  const useDirectMailFetch = useAppSelector((state: RootState) => state.app.mail_direct_fetch);
  const [findUsers] = usePostUsersFindMutation();
  const [getMessageBlocksProxy] = usePostStorageDownloadProxyMutation();
  const [getMessageBlocksDirect] = usePostStorageDownloadDirectMutation();
  // Description: Allow ongoing getThreads callback to have access to current messages state
  const resultsRef = useRef(message_results);
  function setActiveResults(msgs: ThreadMessage[]) {
    resultsRef.current = msgs;
    setMessageResults(msgs);
  }
  // ------------------------------------------------------------------------------------------------------
  // Description: Web - initialize get thread message's contents
  // ------------------------------------------------------------------------------------------------------
  async function handleWebGetMessages(_messages: ThreadMessage[]) {
    // Description: Add to senders object
    const senders = await handleAddSenders(_messages);
    // NOTE: For Protocol V4 the mail_cid (mail_collection Id) is the senders mcid, for latest versions it is current users (recipients)
    // for EMAIL_PROTOCOL_VERSIONS.V4 get the users sender.address from 
    // const collection_ids = (message.protocol_version <= EMAIL_PROTOCOL_VERSIONS.V4 || isSameUser(message.sender.address, current_account.user_id)) ?
    //   current_account.mail_cid : await handleFindCollectionIds(_messages.map(m=>m.sender.address));
    _.forEach(_messages, (message: ThreadMessage) => {
      const sender_user_id = (message.protocol_version <= EMAIL_PROTOCOL_VERSIONS.V4 || isSameUser(message.sender.address, current_account.user_id)) ?
        current_account.user_id : message.sender.address;
      const sender = _.find(senders, (_collection_id: CollIds) => isSameUser(sender_user_id, _collection_id.user_id));
      if (!!sender) {
        const message_collection_id = sender.mail_collection_id;
        const _message = { ...message, ...{ message_collection_id } };
        const blocks = MailThreadMessage.collectAllBlocks(message, message_collection_id, include_attachments);
        (useDirectMailFetch) ? handleWebGetBlocksDirect(blocks, _message) : handleWebGetBlocksProxy(blocks, _message);
      }
    });
  }

  // Description: Add to senders object
  async function handleAddSenders(_messages: ThreadMessage[]): Promise<CollIds[]> {
    const senders = _.uniq(_.map(_messages, (message: ThreadMessage) => {
      return (message.protocol_version <= EMAIL_PROTOCOL_VERSIONS.V4 || isSameUser(message.sender.address, current_account.user_id)) ?
        current_account.user_id : message.sender.address;
    }));
    _.remove(senders, (id: string) => { return _.find(collection_ids, (_collection_id: CollIds) => (_collection_id.user_id === id)); });
    let ids = await handleFindUsers(senders);
    if (!!collection_ids && !!ids) {
      ids = _.uniqBy(collection_ids.concat(ids), "user_id");
    };
    setCollectionIds(ids);
    return ids;
  };

  // Description: get collection mail id for message
  async function handleFindUsers(_user_ids: string[]) {
    const spec = _user_ids.map((user_id: string) => {
      return { user_id, key_version: -1 };
    });
    return await findUsers({
      account_ids: Account.getAccountIdentifiers(current_account),
      body: { spec }
    }).unwrap()
      .then(({ users }) => {
        return users.map((user: CollectionServerUser) => {
          return { user_id: user.user_id, mail_collection_id: user.mail_collection_id };
        });
      });
  }


  // // Description: Get Mail through Collection Server as Proxy if Direct call fails
  async function handleWebGetBlocksProxy(blocks: JSONRequestBlock[], message: ThreadMessage) {
    return await getMessageBlocksProxy({
      account_ids: Account.getAccountIdentifiers(current_account),
      body: { blocks }
    }).unwrap()
      .then((data: JSONApiBlockResponse) => {
        (!!message.decryption_key && !!data.blocks && data.blocks.length > 0) &&
          handleGetBlockDataSuccess(data.blocks, message);
      })
      .catch((error: unknown) => {
        handleGetBlockDataErrors(MailErrorMessages.error_getting_mail_threads, error, message);
      });
  }

  // Description: Try to get blocks from S3 directly first - If failed fallback to Proxy call
  async function handleWebGetBlocksDirect(blocks: JSONRequestBlock[], message: ThreadMessage) {
    return await getMessageBlocksDirect({
      account_ids: Account.getAccountIdentifiers(current_account),
      body: { blocks }
    }).unwrap()
      .then(async (data: PostStorageDownloadApiResponse) => {
        return await Promise.all(_.map(data.urls, (block_response: JSONUrlsResponseBlock, i: number) => {
          return FetchS3MessageBlocks(block_response.block_url, block_response.block_id, block_response.collection_id);
        }));
      })
      .then((responses: JSONResponseBlock[]) => {
        handleGetBlockDataSuccess(responses, message);
      })
      .catch((error: unknown) => {
        handleDirectFailure(error, blocks, message);
      });
  };

  // // --------------------------------------------------------------------------------------------------
  // // Callback Functions: 
  // // --------------------------------------------------------------------------------------------------
  // // Description: handle returned data from either direct call or proxy
  async function handleGetBlockDataSuccess(blocks: JSONResponseBlock[], message: ThreadMessage) {
    const attachments: ThreadAttachment[] = include_attachments ?
      await handleAllAttachments(blocks, message) :
      await handleInlineAttachments(blocks, message);

    decryptBody(blocks, message)
      .then((data?: MessageContents) => {
        if (!!data) {
          const new_message = { ...message, ...data, ...{ attachments } };
          const results = resultsRef.current.slice();
          results.push(new_message);
          setActiveResults(results);
          setLoading(false);
        };
      })
      .catch((error: unknown) => {
        handleGetBlockDataErrors(MailErrorMessages.error_getting_mail_threads, error, message);
      });
  }

  // Description: Modify ALL the attachments in thread with the ThreadAttachment with correct blob prop
  async function handleAllAttachments(blocks: JSONResponseBlock[], message: ThreadMessage) {
    const _attachments: ThreadAttachment[] = [];
    if (message.attachments.length > 0) {
      const attachments = await handleAttachmentData(message.attachments.slice(), blocks);
      if (attachments.length > 0) {
        message.attachments.forEach((attachment: ThreadAttachment) => {
          const _attachment = _.find(attachments, (_att: ThreadAttachment) => (_att.content_reference_id === attachment.content_reference_id));
          _attachments.push(!!_attachment ? _attachment : attachment);
        });
      }
    };
    return _attachments;
  }

  // Description: Modify the attachments in thread with the ThreadAttachment with correct blob prop
  async function handleInlineAttachments(blocks: JSONResponseBlock[], message: ThreadMessage) {
    const has_inlines = !!message.inline_attachments && message.inline_attachments.length > 0;
    const attachments: ThreadAttachment[] = has_inlines ? [] : message.attachments;
    const _inline_attachments = _.filter(message.attachments, (att: ThreadAttachment) => (att.is_inline));
    if (has_inlines && _inline_attachments.length > 0) {
      const inline_attachments = await handleAttachmentData(_inline_attachments, blocks);
      if (inline_attachments.length > 0) {
        message.attachments.forEach((attachment: ThreadAttachment) => {
          const inline_attachment = _.find(inline_attachments, (inline_attachment: ThreadAttachment) => (attachment.content_id === inline_attachment.content_id));
          attachments.push(!!inline_attachment ? inline_attachment : attachment);
        });
      }
    };
    return attachments;
  }

  // Description: Decrypt Inline Attachments
  async function handleAttachmentData(_attachments: ThreadAttachment[], blocks: JSONResponseBlock[]): Promise<ThreadAttachment[]> {
    return await Promise.all(_.map(_attachments, (inline_attachment: ThreadAttachment) => {
      const attachment_block_ids = inline_attachment.content_reference_id.split(",");
      const arr: any = [];
      attachment_block_ids.forEach((attachment_block_id: string) => {
        const attachment_block = _.find(blocks, (block: JSONResponseBlock) => (block.block_id === attachment_block_id));
        !!attachment_block && arr.push({
          block_id: attachment_block.block_id,
          data: Helpers.b64Decode(attachment_block.data) // This is coming in as string; need as UInt8Array
        });
      });

      // NOTE: Decrypt attachment blocks and combine into blob
      return decryptAttachment(inline_attachment, arr)
        .then((decrypted_attachment: Attachment) => {
          return decrypted_attachment.threadAttachment;
        })
        .catch((error: unknown) => {
          handleGetBlockDataErrors(
            MailErrorMessages.error_downloading_attachment.replace(MessageAnchors.file_name, inline_attachment.filename), error);
        });
    }))
      .then((obj) => {
        return _.filter(obj, (b) => !!b) as ThreadAttachment[];
      });
  }

  // Description: Handles Most errors in teh UI for getting threads
  function handleGetBlockDataErrors(error_message: string, stack?: unknown, requested_message?: ThreadMessage) {
    setLoading(false);
    setErrors({
      is_proxy: true,
      requested_message,
      message: error_message,
      stack
    });
  }

  // Description: Handle Direct call Failure
  function handleDirectFailure(error: unknown, blocks: JSONRequestBlock[], message: ThreadMessage) {
    console.error(error);
    // ---------------------------------------- TBD cant dispatch from Custom hook
    setErrors({
      requested_message: message,
      is_proxy: false,
      message: MailErrorMessages.error_getting_mail_threads,
      stack: error
    });
    // NOTE: Follow up with a call to proxy
    handleWebGetBlocksProxy(blocks, message);
  };

  // Description: On webData receive send the blocks up to storage
  const getWebMessages = useCallback((messages: ThreadMessage[] | undefined) => {
    !!messages && handleWebGetMessages(messages);
  }, []);

  return {
    data: message_results,
    isLoading,
    errors,
    getWebMessages
  };
}