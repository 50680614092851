import React, { FocusEvent, ReactNode } from "react";
import { ActionHandlerFunction } from "@preveil-api";
import { CheckboxStates, AdminUIActionTypes, RegexHelper, CheckboxStatesTypes } from "src/common";
import { Checkbox, AddressListRow, NotMatchesFoundView } from "src/components";
import _ from "lodash";

type AllProps = {
  allSelectState: CheckboxStatesTypes;
  filtered_addresses: string[];
  total_addresses: string[];
  selected_address_list: string[];
  handleActions: ActionHandlerFunction;
  children?: ReactNode;
};

type RenderAddressListProps = {
  filtered_addresses: string[];
  selected_address_list: string[];
  handleActions: ActionHandlerFunction;
}

function RenderAddressList({ filtered_addresses, selected_address_list, handleActions }: RenderAddressListProps) {
  if (_.isEmpty(filtered_addresses)) {
    return <NotMatchesFoundView />;
  }
  return (
    <div className="row-container">
      {filtered_addresses.map((singleAddress: string, index: number) => {
        const key = _.uniqueId(singleAddress);
        const isChecked = selected_address_list.includes(singleAddress) ? CheckboxStates.checked : CheckboxStates.empty;
        const icon = RegexHelper.testEmailAddress(singleAddress) ? "ficon-user" : "ficon-globe";
        return <AddressListRow key={key} id={singleAddress} handleActions={handleActions} is_checked={isChecked} selectable={true} validation_state="valid_trusted" icon={icon} />;
      })}
    </div>
  );
};

function AddressListPanel({ allSelectState, filtered_addresses, total_addresses, selected_address_list, handleActions, children }: AllProps) {
 return <div className="cover-content list-group content-dynamic">
    {total_addresses.length >= 1 ? (
      <>
        <header>
          <Checkbox
            className="sr-only"
            onChange={(e: FocusEvent<HTMLInputElement>) =>
              handleActions({ actionType: AdminUIActionTypes.MultiSelect, params: e })
            }
            value="all"
            selected={allSelectState}
            label="Select All"
          />
          <h5>Email Address/Domain</h5>
        </header>
        <RenderAddressList
          filtered_addresses={filtered_addresses}
          selected_address_list={selected_address_list}
          handleActions={handleActions}
        />
      </>
    ) : (
      <>{children}</>
    )}
  </div>;
};

export default React.memo(AddressListPanel);
