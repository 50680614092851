import React, { createRef, ReactElement, useEffect } from "react";
import { SplitPaneFullWidth, SplitPaneWidth } from "src/common";

type AllProps = {
  leftWidth: SplitPaneWidth;
  setLeftWidth: (value: number) => void;
  children: ReactElement;
};

function LeftPaneComponent(props: AllProps) {
  const { leftWidth, setLeftWidth, children } = props;
  const leftRef = createRef<HTMLDivElement>();
  useEffect(() => {
    if (leftRef.current) {
      if (!leftWidth) {
        setLeftWidth(leftRef.current.clientWidth);
        return;
      }
      leftRef.current.style.width = (leftWidth === SplitPaneFullWidth ? "100%" : `${leftWidth}px`);
    }
  }, [leftWidth]);

  return (
    <div className="left-pane" ref={leftRef}>
      {children}
    </div>
  );
}

export default React.memo(LeftPaneComponent);
