import { useState, useEffect } from "react";
import { MessageDisplayType, ErrorStackDataType } from "@preveil-api";
import { FSMessage, FSStatus } from "src/common/keys/protos/collections_pb";
import {
  Account, useAppDispatch, PermissionSetType, CollectionFilesyncAPI, DriveErrorMessages, MessageHandlerDisplayType, useSendRequestMutation, Message,
  getEnumKey, NodeIdentifier, ErrorStackItem, Snapshot
} from "src/common";
import { uiActions } from "src/store";

// Description: Return the entire state of the collection in a single response message.
// https://github.com/PreVeil/core/blob/dev/collection_server/docs/filesync.md#snapshot
export function useFetchSnapshot(current_account: Account, node_identifiers?: NodeIdentifier, permissions?: PermissionSetType) {
  const [snapshot, setSnapshot] = useState<FSMessage.Snapshot | undefined>();
  const [error, setError] = useState<boolean>(false);
  const [sendRequest] = useSendRequestMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    (!!permissions && !!node_identifiers) ?
      fetchSnapshot(node_identifiers) :
      // NOTE: else destroy previously fetched 
      (!!snapshot && setSnapshot(undefined));
  }, [permissions, node_identifiers]);

  // Description: Bulk update permissions
  async function fetchSnapshot(_node_identifiers: NodeIdentifier, seq: number = 0, subseq: number = 0, prev_snapshot: FSMessage.Snapshot = new FSMessage.Snapshot()) {
    const drive_request = !!permissions ?
      await CollectionFilesyncAPI.fetchSnapshot(current_account, permissions, _node_identifiers.collection_id, _node_identifiers.id, seq, subseq) : null;
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        const response_snapshot = message.getSnapshot();
        if (!!response_snapshot) {
          // NOTE: Recursive and cummulative 
          const new_snapshot = Snapshot.buildSnapshot(seq, subseq, response_snapshot, prev_snapshot);
          response_snapshot.hasHasMore() ? fetchSnapshot(_node_identifiers, response_snapshot.getCursorSeq(), response_snapshot.getCursorSubseq(), new_snapshot) :
            handleSuccess(response_snapshot);
        } else {
          handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_snapshot }, message);
        }
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_snapshot }, message);
      }
    }

    if (!!drive_request) {
      drive_request.setCallback(callback);
      sendRequest(drive_request);
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_updating_node_permissions });
    }
  }

  // Description: Handle success for fetching snapshot
  function handleSuccess(_snapshot: FSMessage.Snapshot) {
    setSnapshot(_snapshot);
    setError(false);
  }

  // Description: Handle error message and send error to store
  function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, fsmessage?: FSMessage | null,
    display_type: MessageDisplayType = MessageHandlerDisplayType.toastr) {
    setError(true);
    const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
    const stack = new ErrorStackItem("[useFetchSnapshot Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
    dispatch(uiActions.handleRequestErrors(new Message(message, display_type), stack));
    setSnapshot(undefined);
  }

  return {
    snapshot,
    error
  };
}