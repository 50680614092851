import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import { IActionHandler, AGUserInfo, ApproversInfo, ApproversList } from "@preveil-api";
import {
  ApprovalGroupSteps, useAppDispatch, Message, QueryParamKeys, instanceofApproversList,
  AccountErrorMessages, MessageHandlerDisplayType, normalizeQueryUserId, GlobalErrorMessages, useReconstructPrivateKeyMutation,
  ReconstructPrivateKeyApiResponse, DefaultRoutes, QueryParamValues, MessageAnchors
} from "src/common";
import { uiActions } from "src/store";
import { Loading, GetShard } from "src/components";
import recovery_img from "src/assets/images/recovery_group.svg";
import {
  DefaultPanel,
  RecoveryPanel,
  ErrorPanel
} from ".";

function ApprovalGroupComponent() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [step, setStep] = useState<number>(ApprovalGroupSteps.LOADING);
  const [user_info, setUserInfo] = useState<AGUserInfo>();
  const [approvers_list, setApproversList] = useState<ApproversList>();
  const [active_approver, setActiveApprover] = useState<ApproversInfo>();
  const navigate = useNavigate();
  const [reconstructPrivateKey, { isLoading }] = useReconstructPrivateKeyMutation();
  const dispatch = useAppDispatch();

  /* 
  *   Description: Initialize Approval Group
  *            - Get user ID from QS and pass it to state
  *            - Get secret from QS and pass it to state
  */
  function handleInitApprovalGroup() {
    const query = searchParams.get(QueryParamKeys.USER_ID_QUERY_KEY);
    const user_id = normalizeQueryUserId(query);
    const secret = searchParams.get(QueryParamKeys.SECRET_QUERY_KEY)?.trim();
    if (!!user_id && !!secret) {
      !!user_id && setUserInfo({ user_id, secret });
      setStep(ApprovalGroupSteps.SUBMIT_ACKNOWLEDGE_INFO);
    } else {
      setStep(ApprovalGroupSteps.PAGE_ERROR);
      dispatch(uiActions.handleRequestErrors(
        new Message(AccountErrorMessages.approval_group_qs_stack_error, MessageHandlerDisplayType.logger),
        {
          error: AccountErrorMessages.approval_group_qs_stack_error,
          user_id,
          secret
        }
      ));
    };

    // Remove params
    if (!!searchParams.toString()) {
      !!query && searchParams.delete(QueryParamKeys.USER_ID_QUERY_KEY);
      !!secret && searchParams.delete(QueryParamKeys.SECRET_QUERY_KEY);
      setSearchParams(searchParams);
    }
  }

  // Description: load accounts and urrent account from crypto (First onload event)
  useEffect(() => {
    handleInitApprovalGroup();
  }, []);

  const ApprovalGroupRequests = {
    handleResetForms: () => {
      setStep(ApprovalGroupSteps.SUBMIT_ACKNOWLEDGE_INFO);
      setApproversList(undefined);
      setActiveApprover(undefined);
    },
    handleSubmitAcknowledgeInformation: (params: ApproversList) => {
      if (instanceofApproversList(params)) {
        setApproversList(params); // Validated in the control
        setStep(ApprovalGroupSteps.PROCESS_APPROVERS);
      } else {
        ApprovalGroupRequests.handlePageError({
          message: GlobalErrorMessages.default,
          stack: {
            error: "Error in the type returned from get user info call",
            params
          }
        });
      }
    },
    handleGetShard: (approver: ApproversInfo) => {
      setActiveApprover(approver);
    },
    handlePageSuccess: () => {
      setActiveApprover(undefined);
      setStep(ApprovalGroupSteps.PROCESS_APPROVERS);
    },
    handleReconstructPrivateKey: () => { // ************ WORKING -  NEED TO TEST
      user_info ? reconstructPrivateKey(user_info).unwrap()
        .then((response: ReconstructPrivateKeyApiResponse) => {
          navigate(`${DefaultRoutes.mail_default}?user_id=${encodeURIComponent(user_info.user_id)}&${QueryParamKeys.PAGE_ACTION_QUERY_KEY}=${QueryParamValues.recovered}`);
        })
        .catch((msg: unknown) => {
          ApprovalGroupRequests.handlePageError({
            message: AccountErrorMessages.recover_key_failed,
            stack: {
              error: AccountErrorMessages.recover_key_failed,
              params: msg
            }
          });
        }) :
        ApprovalGroupRequests.handlePageError({
          message: AccountErrorMessages.recover_key_failed,
          stack: {
            error: AccountErrorMessages.missing_user_id
          }
        });
    },
    handleDestroy: () => { // Usage TBD
      ApprovalGroupRequests.handleResetForms();
    },
    handlePageError: (params: { message: string, stack?: any }) => {
      dispatch(uiActions.handleRequestErrors(new Message(params.message, MessageHandlerDisplayType.toastr), params.stack));
      setStep(ApprovalGroupSteps.PAGE_ERROR);
    },
    handlePageErrorMessage: (params: { message: string, stack?: any }) => {
      dispatch(uiActions.handleRequestErrors(new Message(params.message, MessageHandlerDisplayType.logger), params.stack));
    }
  };

  //  Description: Handle all actions from Children forms
  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    if ((ApprovalGroupRequests as any)[callback] instanceof Function) {
      (ApprovalGroupRequests as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(MessageAnchors.actionType, actionObj.actionType);
      ApprovalGroupRequests.handlePageErrorMessage({ message, stack: actionObj });
    }
  }

  // Description: Render panels by steps
  const ApproveGroupPanels = () => {
    if (isLoading) {
      return <Loading className="in-place" />;
    };

    switch (step) {
      case ApprovalGroupSteps.PAGE_ERROR: // {/* state === FAILED_STATE">
        return <ErrorPanel user_id={user_info?.user_id} />;
      case ApprovalGroupSteps.SUBMIT_ACKNOWLEDGE_INFO:
        return !!user_info ?
          <DefaultPanel handleAction={handlePageActions} user_info={user_info} /> :
          <ErrorPanel />;
      case ApprovalGroupSteps.PROCESS_APPROVERS:
        return !!user_info && !!approvers_list ?
          <RecoveryPanel
            approvers_list={approvers_list}
            user_info={user_info}
            handleAction={handlePageActions} /> :
          <ErrorPanel user_id={user_info?.user_id} />;
      default:
        return <Loading className="in-place" />;
    };
  };

  return !!active_approver && !!user_info ?
    <GetShard user_id={user_info.user_id} approver_user_id={active_approver.user_id} handleAction={handlePageActions} /> :
    <Row className="justify-content-center mt-4">
      <Col md={8}>
        <h1 className="main-header">
          {
            step === ApprovalGroupSteps.PAGE_ERROR ? "Recovery Group Not Set" : "Recover Your Private Key"
          }
        </h1>
        <Card className="pv-card">
          {
            step === ApprovalGroupSteps.SUBMIT_ACKNOWLEDGE_INFO &&
            <Card.Img variant="top" src={recovery_img} className="py-3" />
          }
          <Card.Body className="p-4">
            {ApproveGroupPanels()}
          </Card.Body>
        </Card>
      </Col>
    </Row>;
}

export default React.memo(ApprovalGroupComponent);
