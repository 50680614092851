import React, { Dispatch, SetStateAction } from "react";
import { Card, Button } from "react-bootstrap";
import {
  Account,
  DeleteUsersBackupApiResponse,
  Message,
  SettingsErrorMessages,
  SettingsSuccessMessages,
  useAppDispatch,
  useAppSelector,
  useDeleteUsersBackupMutation,
} from "src/common";
import { uiActions } from "src/store";

type AllProps = {
  backup_id: string;
  setBackupId: Dispatch<SetStateAction<string | undefined>>;
};

function ExistingBackupComponent(props: AllProps) {
  const { backup_id, setBackupId } = props;
  const backup_prefix = backup_id.substring(0, 6);
  const dispatch = useAppDispatch();
  const current_account = useAppSelector((state) => state.account.current_account);
  const [deleteAccountBackup] = useDeleteUsersBackupMutation();

  /* Deletes the users current account backup. */
  function handleDelete() {
    if (!!current_account) {
      const account_ids = Account.getAccountIdentifiers(current_account);
      const params = {
        account_ids,
        body: {
          user_id: account_ids.user_id,
          backup_id,
        },
      };
      deleteAccountBackup(params)
        .unwrap()
        .then((response: DeleteUsersBackupApiResponse) => {
          !!response.errors
            ? dispatch(
              uiActions.handleRequestErrors(
                new Message(SettingsErrorMessages.error_deleting_backup),
                response,
              ),
            )
            : setBackupId(undefined);
          dispatch(
            uiActions.handleSetMessage(new Message(SettingsSuccessMessages.account_backup_deleted)),
          );
        })
        .catch((stack) => {
          dispatch(
            uiActions.handleRequestErrors(
              new Message(SettingsErrorMessages.error_deleting_backup),
              stack,
            ),
          );
        });
    }
  };
  return <Card.Body> 
    <p>Your account recovery file is <b>recovery-file-{backup_prefix}.pdf</b></p>
    <p>It contains your account access key and can be used to recover your PreVeil account. <b>Keep it in a secure place.</b></p>
    <p><em>Note:</em> If you lose this file you can create a new one. <br />It can be downloaded after you click the <span className="text-orange">Remove Old Key</span> button below</p>
    <div>
       <Button  onClick={handleDelete}>
         Remove Old Key
       </Button>
     </div>

  </Card.Body>;
}

export default React.memo(ExistingBackupComponent);
