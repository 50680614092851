/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
// @ts-ignore
import { Helpers } from "../helpers/helpers.class";
import { EncryptionKey, EncryptionKeyV0, EncryptionKeyV1, EncryptionKeyV2, EncryptionKeyV3, EncryptionStyle } from "./encryption";
import { ENCRYPTION_KEY_PROTOCOLS, SIGN_KEY_PROTOCOLS, SYMM_KEY_PROTOCOLS, USER_KEY_PROTOCOLS } from "./protocols";
import { Key as KeyPB, PublicUserKey as PublicUserKeyPB, UserKey as UserKeyPB } from "./protos/keys_pb";
import { SigningKey, SigningKeyV0, SigningKeyV1, SigningKeyV3 } from "./sign";
import { SymmKey, SymmKeyV0, SymmKeyV1 } from "./symm";
import { AppPublicUserKey as PublicUserKey, AppUserKey as UserKey } from "./user-keys";

interface KeyFactoryProps {
  protocol_version?: number;
  key?: Uint8Array;
  style?: number;
};

export namespace KeyFactory {
  /* Symmteric Key */
  export async function newSymmKey({
    protocol_version = SYMM_KEY_PROTOCOLS.Current,
    key = undefined,
    style = EncryptionStyle.EMAIL
  }: KeyFactoryProps = {}): Promise<SymmKey> {
    switch (protocol_version) {
      case SYMM_KEY_PROTOCOLS.V0:
        return SymmKeyV0.new(key, style);
      case SYMM_KEY_PROTOCOLS.V1:
        return SymmKeyV1.new(key);
    }
  }

  export function deserializeSymmKey(blob: Uint8Array, style: EncryptionStyle = EncryptionStyle.EMAIL): SymmKey {
    try {
      const pbIns = KeyPB.deserializeBinary(blob),
        pv = pbIns.getProtocolVersion(),
        k = pbIns.getKey_asU8();

      switch (pv) {
        case SYMM_KEY_PROTOCOLS.V1:
          return new SymmKeyV1(k);
      }
      throw Error(`Invalid protocol_version: ${pv}`);
    } catch (e) {
      // attempt v0
      switch (style) {
        case EncryptionStyle.EMAIL:
          const { material } = JSON.parse(Helpers.utf8Decode(Helpers.b64Decode(Helpers.utf8Decode(blob))));
          return new SymmKeyV0(Helpers.b64Decode(material), style);
        case EncryptionStyle.DRIVE:
          return new SymmKeyV0(blob, style);
      }
    }
  }

  /* User Key */
  type EncryptionKeyArg = {
    protocol_version?: number,
    as_public?: boolean,
    key?: Uint8Array,
    style?: EncryptionStyle
  };

  /* Encryption Key */
  export async function newEncryptionKey({
    protocol_version = ENCRYPTION_KEY_PROTOCOLS.Current,
    as_public = false,
    key = undefined,
    style = EncryptionStyle.EMAIL
  }: EncryptionKeyArg = {}): Promise<EncryptionKey> {
    if (as_public && !(key instanceof Uint8Array)) {
      throw Error("Key is required!");
    }
    switch (protocol_version) {
      case ENCRYPTION_KEY_PROTOCOLS.V0:
        return as_public ? new EncryptionKeyV0(key, null, style) : EncryptionKeyV0.new(key, style);
      case ENCRYPTION_KEY_PROTOCOLS.V1:
        return as_public ? new EncryptionKeyV1(key, null, style) : EncryptionKeyV1.new(key, style);
      case ENCRYPTION_KEY_PROTOCOLS.V2:
        return as_public ? new EncryptionKeyV2(key, null, style) : EncryptionKeyV2.new(key, style);
      case ENCRYPTION_KEY_PROTOCOLS.V3:
        return as_public ? new EncryptionKeyV3(key) : EncryptionKeyV3.new(key);
    }
    throw Error(`Invalid protocol_version: ${protocol_version}`);
  }

  export async function deserializeEncryptionKey(blob: Uint8Array, style = EncryptionStyle.EMAIL): Promise<EncryptionKey> {
    try {
      const pbIns = KeyPB.deserializeBinary(blob),
        protocol_version = pbIns.getProtocolVersion(),
        key = pbIns.getKey_asU8();

      return await newEncryptionKey({ protocol_version, key, style });
    } catch (e) {
      // attempt protocol version 0
      switch (style) {
        case EncryptionStyle.EMAIL:
          return await newEncryptionKey({ protocol_version: 0, key: blob, style });
        case EncryptionStyle.DRIVE:
          return await newEncryptionKey({ protocol_version: 0, key: (await Helpers.sha512Checksum(blob)).slice(0, 32), style });
      }
    }
  }

  /* Signing Key */
  export async function newSigningKey({
    protocol_version = SIGN_KEY_PROTOCOLS.Current,
    as_verify = false,
    key = undefined
  } = {}): Promise<SigningKey> {
    if (as_verify && !(key instanceof Uint8Array)) {
      throw Error("Key is required!");
    }
    switch (protocol_version) {
      case SIGN_KEY_PROTOCOLS.V0:
        return as_verify ? new SigningKeyV0(key) : SigningKeyV0.new(key);
      case SIGN_KEY_PROTOCOLS.V1:
        return as_verify ? new SigningKeyV1(key) : SigningKeyV1.new(key);
      case SIGN_KEY_PROTOCOLS.V3:
        return as_verify ? new SigningKeyV3(key) : SigningKeyV3.new(key);
    }
    throw Error(`Invalid protocol_version: ${protocol_version}`);
  }

  export async function deserializeSigningKey(blob: Uint8Array): Promise<SigningKey> {
    try {
      const pbIns = KeyPB.deserializeBinary(blob),
        protocol_version = pbIns.getProtocolVersion(),
        key = pbIns.getKey_asU8();

      if (protocol_version === 0) {
        throw Error("incorrect protocol version");
      }

      return await newSigningKey({ protocol_version, key });
    } catch (e) {
      return await newSigningKey({ protocol_version: 0, key: blob });
    }
  }

  /* User Key */
  type UserKeyArg = {
    protocol_version?: number,
    key_version?: number,
    encryption_key?: EncryptionKey,
    signing_key?: SigningKey,
    style?: EncryptionStyle
  };

  export async function newUserKey({
    protocol_version = USER_KEY_PROTOCOLS.Current,
    key_version = 0,
    encryption_key = undefined,
    signing_key = undefined,
    style = EncryptionStyle.EMAIL
  }: UserKeyArg = {}): Promise<UserKey> {
    const [eK, sK] = await Promise.all([
      encryption_key || KeyFactory.newEncryptionKey({ style }),
      signing_key || KeyFactory.newSigningKey()
    ]);
    return new UserKey(protocol_version, key_version, eK, sK);
  }

  export async function deserializeUserKey(blob: Uint8Array, style = EncryptionStyle.EMAIL): Promise<UserKey> {
    try {
      const pbIns = UserKeyPB.deserializeBinary(blob),
        protocol_version = pbIns.getProtocolVersion(),
        key_version = pbIns.getKeyVersion(),
        encryption_key = await KeyFactory.newEncryptionKey({
          protocol_version: pbIns.getPrivateKey().getProtocolVersion(),
          key: pbIns.getPrivateKey().getKey_asU8(),
          style
        }),
        signing_key = await KeyFactory.newSigningKey({
          protocol_version: pbIns.getSigningKey().getProtocolVersion(),
          key: pbIns.getSigningKey().getKey_asU8()
        });

      if (protocol_version === 0) {
        throw Error("invalid protocol");
      }

      return await KeyFactory.newUserKey({ protocol_version, key_version, encryption_key, signing_key, style });
    } catch (e) {
      // Attempt v0
      const { version, protocol_version, private_key, signing_key } = JSON.parse(Helpers.utf8Decode(blob));
      return newUserKey({
        protocol_version: protocol_version || 0,
        key_version: version,
        encryption_key: await deserializeEncryptionKey(Helpers.b64Decode(private_key), style),
        signing_key: await deserializeSigningKey(Helpers.b64Decode(signing_key)),
        style
      });
    }
  }

  export async function deserializePublicUserKey(blob: Uint8Array, style = EncryptionStyle.EMAIL): Promise<PublicUserKey> {
    try {
      const pbIns = PublicUserKeyPB.deserializeBinary(blob),
        protocol_version = pbIns.getProtocolVersion(),
        key_version = pbIns.getKeyVersion(),
        public_key = await KeyFactory.newEncryptionKey({
          protocol_version: pbIns.getPublicKey().getProtocolVersion(),
          key: pbIns.getPublicKey().getKey_asU8(),
          as_public: true,
          style
        }),
        verify_key = await KeyFactory.newSigningKey({
          protocol_version: pbIns.getVerifyKey().getProtocolVersion(),
          key: pbIns.getVerifyKey().getKey_asU8(),
          as_verify: true
        });
      return new PublicUserKey(protocol_version, key_version, public_key, verify_key);
    } catch (e) {
      // Attempt v0
      const { version, protocol_version = 0, public_key, verify_key } = JSON.parse(Helpers.utf8Decode(blob));
      return new PublicUserKey(
        protocol_version,
        version,
        await KeyFactory.newEncryptionKey({
          protocol_version: 0,
          as_public: true,
          key: Helpers.b64Decode(public_key),
          style
        }),
        await KeyFactory.newSigningKey({
          protocol_version: 0,
          as_verify: true,
          key: Helpers.b64Decode(verify_key)
        })
      );
    }
  }

  // Description: Get full UserKey from UserKeyPB proto object
  export async function deserializeUserKeyObj(pbIns: UserKeyPB): Promise<UserKey> {
    try {
      const protocol_version = pbIns.getProtocolVersion(),
        key_version = pbIns.getKeyVersion(),
        encryption_key = await KeyFactory.newEncryptionKey({
          protocol_version: pbIns.getPrivateKey().getProtocolVersion(),
          key: pbIns.getPrivateKey().getKey_asU8()
        }),
        signing_key = await KeyFactory.newSigningKey({
          protocol_version: pbIns.getSigningKey().getProtocolVersion(),
          key: pbIns.getSigningKey().getKey_asU8()
        });

      if (protocol_version === 0) {
        throw Error("invalid protocol");
      }

      return await KeyFactory.newUserKey({ protocol_version, key_version, encryption_key, signing_key });
    } catch (e) {
      return newUserKey({
        protocol_version: pbIns.getProtocolVersion() || 0,
        key_version: pbIns.getKeyVersion(),
        encryption_key: await deserializeEncryptionKey(Helpers.b64Decode(pbIns.getPrivateKey().getKey_asB64())),
        signing_key: await deserializeSigningKey(Helpers.b64Decode(pbIns.getSigningKey().getKey_asB64()))
      });
    }
  }
}
