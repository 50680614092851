import React, { Dispatch, SetStateAction } from "react";
import { Offcanvas } from "react-bootstrap";
import { ActivityLogInfo } from "@preveil-api";

type AllProps = {
    log: ActivityLogInfo;
    setLog: Dispatch<SetStateAction<ActivityLogInfo | undefined>>;
};

// Description: Row component for each log in the list. 
function LogsRowComponent(props: AllProps) {
    const { log, setLog } = props;

    return (
        <Offcanvas show={!!log}
            onHide={() => { setLog(undefined); }}
            className="sidebar narrower-panel"
            placement="end"
            backdrop={true}
      >
            <Offcanvas.Header closeButton><Offcanvas.Title className="semi-bold" as="h2">
                Log Details
            </Offcanvas.Title></Offcanvas.Header>
            <Offcanvas.Body>
                    <div className="metadata-list">
                    <ul>
                        <li>
                            <p>Event </p>
                            <b><span className="log-user" data-tooltip-id="pv-tooltip" data-tooltip-content={log.actor.address}>
                                {log.actor.name}{" "}
                            </span>
                                {log.tooltip.length > 0 ? (
                                    <span data-tooltip-id="pv-tooltip" data-tooltip-content={log.tooltip} dangerouslySetInnerHTML={{ __html: log.mapped_action }}></span>
                                ) : (
                                    <span dangerouslySetInnerHTML={{ __html: log.mapped_action }}></span>
                                )}
                                {!!log.relevant_users && log.relevant_users.length > 0 && log.relevant_users.map((user, i) => (
                                    <span
                                        className="log-user"
                                        key={i}
                                        data-tooltip-id="pv-tooltip"
                                        data-tooltip-content={user.address}
                                    >
                                        {user.name}
                                        {i < log.relevant_users.length - 1 ? "," : ""}
                                    </span>
                                ))}
                                {!!log.sub_details && <span dangerouslySetInnerHTML={{ __html: log.sub_details }}></span>}</b>
                        </li>
                        <li>
                            <p>Timestamp </p>
                            <b>{log.formatted_timestamp}</b>
                        </li>
                        <li>
                            <p>Topic</p>
                            <b>{log.topic}</b>
                        </li>
                        <li>
                            <p>Device</p>
                            <b>{log.device}</b>
                        </li>
                    </ul>
                    </div>
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default React.memo(LogsRowComponent);
