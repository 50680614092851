import React from "react";
import { Row, Col } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { AdminUserExternalInvite } from "src/common";
import { AdminRow } from "src/components";

type AllProps = {
  handleActions?: ActionHandlerFunction;
  is_delete?: boolean;
  users: AdminUserExternalInvite[];
}

function UserInfoCard({ users, is_delete, handleActions}: AllProps) {
  return (
    <div className="groups-card">
      <header>
        <Row className="header-card-row">
          <Col xs={7}>
            <p className="m-0">Name</p>
          </Col>
          <Col xs={5} className="ps-2">
            <p className="m-0">Department</p>
          </Col>
        </Row>
      </header>
      {users.map((user: any) => {
        const id = user?.id || user?.user_id;
        return (
          <AdminRow key={id} member={user} is_delete={is_delete} handleActions={handleActions}/>
        );
      })}
    </div >
  );
}


export default React.memo(UserInfoCard);