// import * as React from "react";
import React, { useEffect } from "react";
import _ from "lodash";

type AllProps = {
  found_extensions: string[];
  active: string | null;
  handleAction: (extension: string | null) => void;
}

function SearchAutocompleteComponent(props: AllProps) {
  const { found_extensions, active, handleAction } = props;

  useEffect(() => {
    window.addEventListener("click", resetAutocomplete);
    return () => {
      resetAutocomplete();
      window.removeEventListener("click", resetAutocomplete);
    };
  }, []);

  // Description: Remove autocomplete on click outside
  function resetAutocomplete() {
    handleAction(null);
  }

  // Description: Renders a user list item
  function ExtensionResult(props: { extension: string }) {
    const extension = props.extension;
    return <li
      className={!!active && active === extension ? "active" : ""}
      onClick={() => (handleAction(extension))}>{extension}</li>;
  }

  return <ul className="autocomplete-results">
    {
      _.map(found_extensions, (extension: string, i: number) => {
        return <ExtensionResult key={`extension_${i}`} extension={extension} />;
      })
    }
  </ul>;
}

export default React.memo(SearchAutocompleteComponent);