import { useState, useEffect, useCallback } from "react";
import { CollectionServerUser, MessageDisplayType, ErrorStackDataType } from "@preveil-api";
import { FSMessage, FSStatus, FSRequest, Node, SealedContent } from "src/common/keys/protos/collections_pb";
import { EncryptionStyle } from "src/common/keys/encryption";
import { randomBytes } from "pvcryptojs";
import {
  Account, useAppDispatch, useGetPermissions, DriveSuccessMessages, DriveErrorMessages, CollectionFilesyncAPI, CollectionEntity, useAppSelector, DriveRequest,
  useSendRequestMutation, Message, MessageHandlerDisplayType, getEnumKey, usePostUsersFindMutation, PermissionSetType, NodePermissionSet, Grant,
  GrantSet, ACLRoleType, NodePermissionTypeToACLRoleType, dayjs, NodePermissionType, KeyFactory, isSameUser, parseCollectionUserToPublicKeyUser, useGetGrants,
  PublicKeyUser, ACL, GrantsAndPermissions, ErrorStackItem
} from "src/common";
import { RootState } from "src/store/configureStore";
import { uiActions, DriveCallbackAsyncFunction } from "src/store";
import _ from "lodash";

// Type for updating and creating ACL Roles
type PreviousSet = { [key: string]: ChangeSetBase };
type UpdateCollectionRoles = {
  role: ACLRoleType;
  grantees: string[];
  previous_set: PreviousSet;
};

interface ChangeSetBase {
  view_only: boolean;
  expiration: string;
}

interface ChangeSet extends ChangeSetBase {
  grantee: string;
  acl: ACLRoleType[];
};

export function useUpdateCollection(current_account: Account, collection_info: CollectionEntity, active: boolean = false) {
  const default_permissions: PermissionSetType[] = useAppSelector((state: RootState) => state.drive.default_permissions);
  const default_grants = useAppSelector((state: RootState) => state.drive.default_grants);
  const [change_set, setChangeSet] = useState<NodePermissionSet[] | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<NodePermissionSet[] | undefined>();
  const { permissions, getPermissions, destroyPermissions } = useGetPermissions(current_account);
  const { grants, getGrants, destroyGrants } = useGetGrants(current_account);
  const [sendRequest] = useSendRequestMutation();
  const [findUsers] = usePostUsersFindMutation();
  const dispatch = useAppDispatch();

  // Description: Fetch grants and permissions 
  useEffect(() => {
    if (!!collection_info && active) {
      getPermissions(collection_info.collection_id, default_permissions);
      getGrants(collection_info.maintainer_id || collection_info.id, collection_info.collection_id, default_permissions, default_grants);
    }
  }, [collection_info]);

  // Description: Initialize calls to update collection by getting the permissions and grants
  useEffect(() => {
    if (!!change_set && !!grants && !!permissions) {
      getACLTree(collection_info.collection_id);
    }
  }, [change_set]);

  
  // Description: Get V2 directory node_id
  //              We scope the ACL tree to the ACL node so that all nodes in the returned tree are underneath it.
  async function getACLTree(collection_id: string) {
    const request = await CollectionFilesyncAPI.getACLTreeRequest(collection_id, collection_info.maintainer_id);
    const drive_request = !!permissions ? await DriveRequest.initializeRequest(request, current_account, permissions) : null;
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        // NOTE: Handle successful acl_tree
        const all_acls = message.getAclTree()?.getDirsList()?.flatMap((dir: FSMessage.Dir) => dir.getAclList());
        const all_grantees = all_acls?.flatMap((grant: Node.Grant) => grant.getGranteesList());
        const users = all_grantees?.map((grantee: Node.Grantee) => grantee.getUserId() || "");
        !!users ? handleFindUsers(users, message, collection_id) : 
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_acl_tree });
      } else {
        // NOTE: Handle error locally
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_acl_tree }, message);
      }
    }
    handleSendRequest(drive_request, callback, DriveErrorMessages.error_fetching_acl_tree);
  }
  
  // Description: Get updated Users information
  function handleFindUsers(_user_ids: string[], message: FSMessage, collection_id: string) {
    const spec = _.uniq(_user_ids).map((user_id: string) => {
      return { user_id, key_version: -1 };
    });
    findUsers({
      account_ids: Account.getAccountIdentifiers(current_account),
      body: { spec }
    }).unwrap()
      .then(({ users }) => {
        performBulkUpdate(collection_id, users, message);
      });
  }

  // Description: Take user information and combine with the ACL tree to update the permissions
  async function performBulkUpdate(collection_id: string, _users: CollectionServerUser[], message: FSMessage) {
    const users = _.compact(await Promise.all(_.map(_users, async (cs_user: CollectionServerUser) => {
      return await parseCollectionUserToPublicKeyUser(cs_user);
    })));
    const updates = _users.length > 0 ? await buildUpdatesMessage(message, collection_id, users) : undefined;
    !!updates ? bulkUpdateNodePermissions(collection_id, updates) :
    handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_users });
  }

  // Description: Iterate through all ACL Tree nodes and add create an update for each one of 
  async function buildUpdatesMessage(message: FSMessage, collection_id: string, users: PublicKeyUser[]):
    Promise<FSRequest.Update[]> {
    // NOTE: Iterate through all nodes of the tree and add create an update for each one
    const directory_list = message.getAclTree()?.getDirsList();
    const updates = _.map(directory_list, async (node: FSMessage.Dir) => {
      const grants_and_permissions = await updatedGrantsAndPermissions(collection_id, node, users);
      const acl = new FSRequest.Update.Change();
      acl.setOp(FSRequest.Update.Op.ACL);
      if (!!grants_and_permissions) {
        acl.setNewNodeGrantsList(grants_and_permissions.grants);
        acl.setNewNodePermissionsList(grants_and_permissions.permissions);
      }
      const update = new FSRequest.Update();
      update.setId(node.getId());
      update.setChangesList([acl]);
      update.setOldVersion(node.getVersion());
      update.setNewVersion(randomBytes(32));
      return update;
    });
    return await Promise.all(updates); // Promise.all();
  }

  // Description: Init updating the collection
  async function updatedGrantsAndPermissions(collection_id: string, node: FSMessage.Dir, users: PublicKeyUser[]):
    Promise<GrantsAndPermissions | undefined> {
    const node_grants: Node.Grant[] = node.getAclList();
    const maintainer_id = node.getMaintainerId_asB64();
    const current_grants = GrantSet.getCurrentUsersGrantSets(collection_id, maintainer_id, node_grants, current_account.user_id);
    if (!!current_grants && current_grants.grants.length > 0) {
      const latest_grants = GrantSet.latestGrants(current_grants.grants);
      const latest_role_key_version = latest_grants[0].role_key_version;
      const current_roles: UpdateCollectionRoles[] = buildCurrentRoles(node_grants);
      // NOTE:  Build Changeset, new_roles and set rekey_required
      const { changeset, new_roles, rekey_required } = buildChangeSet(current_roles);
      // NOTE: Add a version if rekey_required current_roles, new_roles,
      const key_version = latest_role_key_version + (rekey_required ? 1 : 0);
      // NOTE: Build new grants and permissions
      const _permissions: Node.Permission[] = [];
      const _grants: Node.Grant[] = await Promise.all(_.map(new_roles, async (new_grant: UpdateCollectionRoles) => {
        const local_grant = _.find(current_grants.grants, (_grant: Grant) => (_grant.role === new_grant.role));
        const local_grantee_key = !!local_grant ? current_account.getUserKeyWithVersion(local_grant.user_key_version) : current_account.user_key;
        // NOTE: unwraps the local_grantee key => local_grantee.getGrant().getContent_asU8()
        if (!!local_grant && !!local_grant.wrapped_key && !!local_grantee_key) {
          const current_serialized_key = await local_grantee_key.encryption_key.unseal(local_grant.wrapped_key); // LEGACY: opened_grant
          const new_user_key = await KeyFactory.newUserKey({ key_version, style: EncryptionStyle.DRIVE }); // Legacy: user_key
          // NOTE: serialized_key new if rekey is required (legacy: !promote_only)
          // rekey_required && _permissions.push(await buildPermission(new_grant, new_user_key, current_serialized_key));
          if (rekey_required) {
            const wrapped_key = await new_user_key.public_user_key.public_key.seal(current_serialized_key);
            _permissions.push(await ACL.buildPermission(new_grant.role, new_user_key, wrapped_key));
          }
          const serialized_key = rekey_required ? new_user_key.serialize() : current_serialized_key;
          return await buildGrant(new_grant, changeset, key_version, serialized_key, users);
        } else {
          throw new Error(DriveErrorMessages.error_building_node_gratees);
        }
      }));
      return { grants: _grants, permissions: _permissions };
    }
  }

  // Description: Incorporate the changes to the grants role
  function buildChangeSet(current_roles: UpdateCollectionRoles[])
    : { changeset: ChangeSet[], new_roles: UpdateCollectionRoles[], rekey_required: boolean } {
    let new_roles: UpdateCollectionRoles[] = current_roles.slice();
    let rekey_required = false;
    const changeset: ChangeSet[] = _.map(change_set, (change: NodePermissionSet) => {
      // NOTE: Create a new acl array with the grantee's NEW ROLES
      const acl = change.type !== NodePermissionType.unshare ? NodePermissionTypeToACLRoleType[change.type] : [];
      const expiration = !!change.expiration && dayjs(change.expiration).isValid() ? dayjs(change.expiration).format() : "";
      const grantee = change.user_id;
      // NOTE: Update the new_roles with Changes for this grantee 
      new_roles = _.map(new_roles, (current_role: UpdateCollectionRoles) => {
        const new_grantees = current_role.grantees.slice(); // HOLDS the new array of user_ids for a role type
        // NOTE: Check to see if the current role is already in the NEW acl array of the user changeset - 
        const role_exists = acl.length > 0 && acl.includes(current_role.role);
        const grantee_exists = new_grantees.includes(grantee);
        if (role_exists && !grantee_exists) {
          // NOTE: add grantee to current_roles.grantee array;
          new_grantees.push(grantee);
        } else if (!role_exists && grantee_exists) {
          // NOTE: remove grantee from current_roles.grantee array
          new_grantees.splice(new_grantees.indexOf(grantee), 1);
          rekey_required = true;
        } else if (Object.prototype.hasOwnProperty.call(current_role.previous_set, grantee)
          && (current_role.previous_set[grantee].view_only !== change.view_only && change.view_only)) {
          // NOTE: This block checks if rekey is needed when changes between READ ONLY and VIEW ONLY 
          //        Only set rekey if this is a demotion from Read Only to View Only
          rekey_required = true;
        }
        return { role: current_role.role, grantees: new_grantees, previous_set: current_role.previous_set };
      });

      return {
        grantee,
        acl,
        view_only: change.view_only,
        expiration
      };
    });
    return { changeset, new_roles, rekey_required };
  }

  // Description: Build the current roles before new grants are generated
  function buildCurrentRoles(node_grants: Node.Grant[]): UpdateCollectionRoles[] {
    const current_roles: UpdateCollectionRoles[] = [];
    _.forEach(node_grants, (node_grant: Node.Grant) => {
      const role = node_grant.getRole() as ACLRoleType;
      const grantees = _.map(node_grant.getGranteesList(), (grantee: Node.Grantee) => grantee.getUserId() || "");
      const previous_set: PreviousSet = {};
      _.forEach(node_grant.getGranteesList(), (grantee: Node.Grantee) => {
        const user_id = grantee.getUserId();
        if (!!user_id) {
          previous_set[user_id] = {
            view_only: grantee.getViewOnly() || false,
            expiration: grantee.getExpirationTime() || ""
          };
        }
      });

      role !== undefined &&
        current_roles.push({
          role,
          grantees: !!grantees ? grantees : [],
          previous_set
        });
    });
    return current_roles;
  }

  // Description: Build new grants for users
  async function buildGrant(
    new_grant: UpdateCollectionRoles,
    changeset: ChangeSet[],
    key_version: number,
    serialized_key: Uint8Array,
    users: PublicKeyUser[]): Promise<Node.Grant> {
    const grant = new Node.Grant();
    grant.setRole(new_grant.role); // Set Role from x in 
    grant.setKeyVersion(key_version); // Set NEW_key_version
    // NOTE: Create the grantee list fo this grant by Role type
    const grantee_list: Node.Grantee[] = [];
    await Promise.all(_.map(new_grant.grantees, async (user_id: string) => {
      // NOTE: Get current user from backend service find call
      const user: PublicKeyUser | undefined = _.find(users, (cs_user: PublicKeyUser) => isSameUser(cs_user.user_id, user_id));
      if (!!user) {
        const has_change = _.find(changeset, (set: ChangeSet) => isSameUser(set.grantee, user_id));
        const new_grantee = await buildGrantee(user, serialized_key, new_grant.previous_set[user.user_id], has_change);
        grantee_list.push(new_grantee);
      }
    }));

    grant.setGranteesList(grantee_list);
    return grant;
  }

  // Description: Build Grantee
  async function buildGrantee(user: PublicKeyUser, serialized_key: Uint8Array, previous_set: ChangeSetBase, changeset?: ChangeSet): Promise<Node.Grantee> {
    const content = await user.public_user_key.public_key.seal(serialized_key); // Wrap the serialized key using the user public key *****
    const new_grantee = new Node.Grantee();
    new_grantee.setUserId(user.user_id);
    const grant = new SealedContent();
    grant.setKeyVersion(user.key_version);
    grant.setContent(content);
    new_grantee.setGrant(grant);
    if (!!changeset || !!previous_set) {
      // NOTE: Set view_only and expiration time if grantee has changes or previous values if not
      new_grantee.setViewOnly(!!changeset ? changeset.view_only : previous_set.view_only);
      new_grantee.setExpirationTime(!!changeset ? changeset.expiration : previous_set.expiration);
    }
    return new_grantee;
  }

  // Description: Bulk update permissions
  async function bulkUpdateNodePermissions(collection_id: string, updates: FSRequest.Update[]) {
    const drive_request = !!permissions && !!grants ?
      await CollectionFilesyncAPI.bulkUpdate(current_account, collection_id, [], updates, [], [], [], permissions, [grants]) : null;
    async function callback(message: FSMessage) {
      message.getStatus() === FSStatus.OK ? handleSuccess() :
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_updating_node_permissions }, message);
    }
    handleSendRequest(drive_request, callback, DriveErrorMessages.error_updating_node_permissions);
  }

  // Description: Handle successful changing of the permissions
  function handleSuccess() {
    dispatch(uiActions.handleSetMessage(new Message(DriveSuccessMessages.save_node_permissions_success)));
    setLoading(false);
    setData(change_set);
  }

  // Description: Sends Request and handles error when the request object is null, pass it custom stack message
  function handleSendRequest<T>(request: DriveRequest | null, callback: DriveCallbackAsyncFunction<T>, stack_error: string = DriveErrorMessages.error_sending_request) {
    if (!!request) {
      request.setCallback(callback);
      sendRequest(request);
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, { stack_error, stack_message: DriveErrorMessages.error_sending_request }, null);
    }
  }

  // Description: Handle error message and send error to store
  function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, fsmessage?: FSMessage | null, display_type: MessageDisplayType = MessageHandlerDisplayType.toastr) {
    const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
    const stack = new ErrorStackItem("[useUpdateCollection Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
    dispatch(uiActions.handleRequestErrors(new Message(message, display_type), stack));
    setLoading(false);
    setData(undefined);
  }

  // Description: Call to get collection level permissions
  const save = useCallback((changes: NodePermissionSet[]) => {
    setLoading(true);
    setChangeSet(changes);
  }, []);

  // Description: Reset hook for next call
  const reset = useCallback(() => {
    setLoading(false);
    setData(undefined);
    setChangeSet(undefined);
    destroyPermissions();
    destroyGrants();
  }, []);

  return {
    save,
    reset,
    loading,
    data
  };
}
