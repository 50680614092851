import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { Row, Col } from "react-bootstrap";
import { ActionHandlerFunction, Sort } from "@preveil-api";
import { SearchNode, EntryItem, DriveSearchFilterSet, DriveSearchFilters } from "src/common";
import { SearchResults, SearchTypeFilter, SearchScopeFilter, SearchFooter } from ".";

type AllProps = {
  search_term?: string;
  results?: SearchNode[];
  sort: Sort<keyof SearchNode>;
  filters: DriveSearchFilterSet;
  progress: number;
  selected_folders: EntryItem[];
  error: boolean;
  setSearchTerm: Dispatch<SetStateAction<string | undefined>>;
  handleAction: ActionHandlerFunction;
}

function SearchDropdownComponent(props: AllProps) {
  const { selected_folders, search_term, results, sort, filters, progress, error, handleAction } = props;
  const [filtered_results, setFilteredResults] = useState<SearchNode[] | undefined>(results);

  useEffect(() => {
    setFilteredResults(!!results ? DriveSearchFilters.applyFilters(results, filters) : results);
  }, [results, filters]);

  return <div className="search-results">
    <Row>
      <Col sm="8" className="results">
        <SearchResults
          search_term={search_term}
          sort={sort}
          error={error}
          results={filtered_results}
          progress={progress}
          handleAction={handleAction} />
      </Col>
      <Col sm="4" className="filters">
        {
          selected_folders.length > 0 &&
          <SearchScopeFilter
            selected_folders={selected_folders}
            handleAction={handleAction} />
        }
        <SearchTypeFilter handleAction={handleAction} />
      </Col>
    </Row>
    <SearchFooter
      search_term={search_term}
      counts={filtered_results?.length || 0}
      progress={progress}
      error={error}
      handleAction={handleAction} />
  </div>;
}

export default React.memo(SearchDropdownComponent);
