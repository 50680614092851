import React, { FocusEvent } from "react";
import { Col, Row, Badge } from "react-bootstrap";
import { ActionHandlerFunction, AccountType } from "@preveil-api";
import { CheckboxStatesTypes, AdminUIActionTypes, OrgUserStatus, account_types } from "src/common";
import { Checkbox, Icon } from "src/components";
import _ from "lodash";

type AllProps = {
  accountType?: AccountType;
  status: string;
  userName: string;
  userEmail: string;
  department: string;
  updatesPending?: number;
  recoveryGroup: {
    groupId: string;
    name: string;
  } | null;
  isChecked: CheckboxStatesTypes;
  handleActions: ActionHandlerFunction;
};



function UserListRow({ accountType, status, userName, userEmail, department, updatesPending, recoveryGroup, isChecked, handleActions }: AllProps) {
  const recoveryGroupName = !!recoveryGroup ? recoveryGroup.name : "Not Assigned";
  const iconTooltip = accountType === account_types.full ? "Full Account User" : accountType === account_types.express ? "Express User" : "User has not accepted the invitation to join";
  const pendingRequests = updatesPending && updatesPending >= 1 ? updatesPending : null;
  const label = pendingRequests && pendingRequests === 1 ? "Update" : "Updates";
  const _status = _.capitalize(status);
  const formatted_email = userEmail.toLowerCase(); // Note: lowercase email for UI purposes. 
  function recoveryGroupStatus() {
    if (status === OrgUserStatus.active && !recoveryGroup) { 
      // For active users with no recovery group
      // It will return Not Assigned
      return <p className="no-recovery-group">{recoveryGroupName}</p>;
    } else if (status === OrgUserStatus.active && !pendingRequests) {
      // For active users with an assigned recovery group and no pending updates.
      return <p>{recoveryGroupName}</p>;
    } else if (pendingRequests) {
      // Active users with pending updates.
      // For this case, the user has a recovery group but has pending updates
      // to change the recovery group.
      return <Badge bg="warning" aria-label="Status"><i>{pendingRequests} {label}</i></Badge>;
    } else {
      // If the account is on pending or invited status, nothing to show under recovery group column for that user.
      return "";
    }
  }

  return (
    <Row
      className="admin-item-list"
      onClick={() => handleActions({ actionType: AdminUIActionTypes.EditUser, params: userEmail })}
    >
      <Col sm="auto">
        <Checkbox
          className="sr-only"
          onChange={(e: FocusEvent<HTMLInputElement>) => handleActions({ actionType: AdminUIActionTypes.SelectUser, params: { e, user: { userName, userEmail, recoveryGroup } } })}
          value={userEmail}
          selected={isChecked}
          label="Select User"
        />
      </Col>
      <Col sm={2}>  
        <Icon
          tooltip={iconTooltip}
          className={`account-type-icon ${accountType === account_types.full ? "icon-user-plus" : accountType === account_types.express ? "icon-user" : "icon-user-plus inactive"}`} />
        <p>{userName}</p>  
      </Col>
      <Col sm={3}>
        <p>{formatted_email}</p>
      </Col>
      <Col sm={2}>
        <p>{department}</p>
      </Col>
      <Col sm={3}>
        {recoveryGroupStatus()}
      </Col>
      <Col sm={1} className="ps-2">
        {
          status === OrgUserStatus.active ?
            <p className="text-capitalize">{accountType}</p>
            :
            <Badge bg="info" aria-label="Status"><i>{_status}</i></Badge>
        }
      </Col>
    </Row>
  );
}

export default React.memo(UserListRow);
