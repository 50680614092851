import { UserProfile } from "@preveil-api";
import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { UserRequest } from "src/common";
import _ from "lodash";

type AllProps = {
  request: UserRequest;
};

function ForUsersComponent(props: AllProps) {
  const { request } = props;
  return (
    <div className="settings-table mb-2">
      {_.map(request.for_users, (user: UserProfile, i: number) => {
        return (
          <Row className="approver-row" key={i}>
            <Col sm={4}>{user.name}</Col>
            <Col>{user.address}</Col>
          </Row>
        );
      })}
    </div>
  );
}

export default React.memo(ForUsersComponent);
