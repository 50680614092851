import * as React from "react";
import { Route, Routes } from "react-router-dom";
import {
  UIGuideNavigation,
  Icons,
  Buttons,
  Messaging,
  Typography,
  Forms,
  Cards,
  Description,
  Modals,
  Tabs,
  Breadcrumbs,
  Pagination,
  DragAndDrop,
  Popovers,
  ProgressBar,
  PublicCards,
  Scrollbar,
  DatePicker,
  Toastrs,
} from "../developer/ui-guide";

function UIGuides() {
  return <>
    <UIGuideNavigation />
    <Routes>
      <Route path="/" element={<Description />} />
      <Route path="buttons" element={<Buttons />} />
      <Route path="icons" element={<Icons />} />
      <Route path="messaging" element={<Messaging />} />
      <Route path="typography" element={<Typography />} />
      <Route path="forms" element={<Forms />} />
      <Route path="cards" element={<Cards />} />
      <Route path="tabs" element={<Tabs />} />
      <Route path="modals" element={<Modals />} />
      <Route path="breadcrumbs" element={<Breadcrumbs />} />
      <Route path="pagination" element={<Pagination />} />
      <Route path="drag-and-drop" element={<DragAndDrop />} />
      <Route path="popovers" element={<Popovers />} />
      <Route path="progress-bar" element={<ProgressBar />} />
      <Route path="public-cards" element={<PublicCards />} />
      <Route path="scrollbar" element={<Scrollbar />} />
      <Route path="date-picker" element={<DatePicker />} />
      <Route path="toastrs" element={<Toastrs />} />
    </Routes>
  </>;
}

export default React.memo(UIGuides);
