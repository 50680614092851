/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
// @ts-ignore
import { NaclCrypto, PVCryptoUsage } from "pvcryptojs";
import { SigningKey, VerifyKey } from "./sign";

export class SigningKeyV0 extends SigningKey {
  static protocol_version = 0;

  constructor(public_key: Uint8Array, public private_key?: Uint8Array) {
    super(SigningKeyV0.protocol_version, public_key, private_key);
  }

  static async new(sk): Promise<SigningKeyV0> {
    if (!sk) {
      const [_sk, _pk] = await NaclCrypto.generateAsymmKey(PVCryptoUsage.SIGN);
      return new SigningKeyV0(_pk, _sk);
    }

    const [_sk, _pk] = await NaclCrypto.generateAsymmKey(PVCryptoUsage.SIGN, sk);
    return new SigningKeyV0(_pk, _sk);
  }

  public serialize() {
    return new Uint8Array(this.private_key);
  }

  public cloneVerify(): VerifyKey {
    return new SigningKeyV0(this.public_key);
  }

  public async sign(plaintext: Uint8Array): Promise<Uint8Array> {
    if (!this.private_key) {
      throw Error("Invalid usage");
    }
    return NaclCrypto.sign(this.private_key, plaintext);
  }

  public async verify(plaintext: Uint8Array, signature: Uint8Array): Promise<boolean> {
    return NaclCrypto.verify(this.public_key, plaintext, signature);
  }
}
