import * as React from "react";
import { Account, useAppSelector, useDriveSocket, usePathList, useDefaultCollectionInfo, useDriveSubscribe, useFilesyncSocket } from "src/common";
import { RootState } from "src/store/configureStore";
import { WebsocketStatus } from "src/store";

// Description: App Drive Initialize sets up the app for app use through websocket
// NOTE: we might merge this with the WebDriveInit component if we dont add anything specific to app
function AppDriveInitializeComponent(props: { current_account: Account; }) {
    const { current_account } = props;
    const ws_status = useAppSelector((state: RootState) => state.websocket.drive_ws_status);
    const default_permissions = useAppSelector((state: RootState) => state.drive.default_permissions);
    const collection_id = useAppSelector((state: RootState) => state.drive.current_directory?.collection_id);
    useDriveSocket();
    useFilesyncSocket(current_account);
    useDefaultCollectionInfo(current_account, default_permissions, ws_status === WebsocketStatus.connected);
    useDriveSubscribe(current_account, collection_id, default_permissions);
    usePathList(current_account, collection_id);

    return null;
}

export default React.memo(AppDriveInitializeComponent);
