import { JSONEmailV4, MailMessageV4 } from "@preveil-api";
import { Account, AccountUserPublicKey, EmailUser, Helpers, EMAIL_PROTOCOL_VERSIONS, WebMailData } from "src/common";
import { SymmKey, VerifyKey } from "src/common/keys";
import { Attachment, AttachmentMetadata } from "../attachment.class";

export interface MailRecipient {
  user_id: string;
  mail_cid: string;
  key_version: number;
  public_user_key: AccountUserPublicKey;
}

// Send message from V4 - wrapper
export interface MessageV4 {
  message: MailMessageV4;
  recipient: MailRecipient;
}

export interface PrivateMetadataV4 {
  sender: string;
  subject: string;
  tos: string[];
  ccs: string[];
  bccs: string[];
  signature: string;
  signing_key_version: number;
  other_headers: { [key: string]: string };
}

export interface DecryptedEmailV4 extends JSONEmailV4 {
  decrypted_snippet: string;
  decrypted_attachments: AttachmentMetadata[];
  decryption_key: SymmKey;
  decrypted_metadata: PrivateMetadataV4;
}

function getCanonnicalString(block_ids: string[]) {
  return block_ids.sort().join("");
}


export async function decryptAndVerifyEmailV4(
  metadata: JSONEmailV4, decryption_key: SymmKey, verifying_key?: VerifyKey
): Promise<[boolean, DecryptedEmailV4]> {
  const [decrypted_metadata, decrypted_snippet, decrypted_attachments] = await Promise.all([
    decryption_key.decrypt(Helpers.b64Decode(metadata.private_metadata))
      .then(raw => Helpers.utf8Decode(raw))
      .then(json_serialized => JSON.parse(json_serialized) as PrivateMetadataV4),

    decryption_key.decrypt(Helpers.b64Decode(metadata.body.snippet))
      .then(raw => Helpers.utf8Decode(raw)),

    Promise.all(
      metadata.attachments.map(
        (a_meta: any) =>
          decryption_key.decrypt(Helpers.b64Decode(a_meta.name))
            .then(c => Object.assign({}, a_meta, { decryption_key, decrypted_name: Helpers.utf8Decode(c), decrypted_content: null }))
      )
    )
  ]);
  let block_ids: any = [];
  for (const i of [...metadata.attachments, metadata.body]) {
    if (i.block_ids) {
      block_ids = block_ids.concat(i.block_ids);
    } else if (i.blocks) {
      block_ids = block_ids.concat(i.blocks.map(b => b.id));
    }
  }
  const signature = Helpers.b64Decode(decrypted_metadata.signature);
  const canonical_string = getCanonnicalString(block_ids);

  const verified =
    !!verifying_key ?
      (await Promise.all([
        verifying_key.verify(Helpers.utf8Encode(canonical_string.toLowerCase()), signature),
        verifying_key.verify(Helpers.utf8Encode(canonical_string.toUpperCase()), signature)
      ])).includes(true) :
      true;

  return [
    verified,
    Object.assign({}, metadata, {
      decrypted_metadata,
      decrypted_snippet,
      decrypted_attachments,
      decryption_key
    })
  ];
}

// Description: Prepare message with recipients, metadata and bccs
export async function prepareEmailV4(
  symm_key: SymmKey,
  sender: Account,
  recipient: MailRecipient,
  tos: EmailUser[],
  ccs: EmailUser[],
  bccs: EmailUser[],
  subject: string,
  text: string,
  html: string,
  snippet: string,
  in_reply_to: string,
  references: string[],
  attachments: Attachment[],
  flags: string[] | null = null,
  new_symm_key_required: boolean = false): Promise<WebMailData> {
  const recipient_key = recipient.public_user_key;
  const wrapped_key = Helpers.b64Encode(await recipient_key.public_key.seal(symm_key.serialize()));

  const encryptChunk = (chunk: any) =>
    symm_key.encrypt(chunk)
      .then(b => Promise.all([Helpers.sha256Checksum(b), Promise.resolve(b)]))
      .then(([digest, encrypted_block]) => ({
        id: Helpers.hexEncode(digest),
        data: encrypted_block,
        wrapped_key,
        key_version: recipient_key.key_version
      }));
  const validBlockIds = (attachment: Attachment) => attachment.block_ids.length === 0 || new_symm_key_required;

  const body = Helpers.utf8Encode(JSON.stringify({ text, html }));
  const encrypted_snippet = Helpers.b64Encode(await symm_key.encrypt(Helpers.utf8Encode(snippet)));
  
  const attachments_p = Promise.all(
    attachments.map(a =>
      a.getContentBuffer()
        .then(content =>
          Promise.all([
            Promise.all(Helpers.chunkData(content).map(encryptChunk)),
            a,
            symm_key.encrypt(Helpers.utf8Encode(a.filename)),
            content.length
          ])
        )
    )
  );
  const body_p = Promise.all(Helpers.chunkData(body).map(encryptChunk));

  const [body_blocks, attachments_blocks] = await Promise.all([body_p, attachments_p]);

  const canonical_string = getCanonnicalString([
    ...body_blocks.map(b => b.id),
    ...attachments_blocks.reduce((l: string[], [blocks, att, ...r]) => l.concat(validBlockIds(att) ? blocks.map(b => b.id) : att.block_ids), [])
  ]);

  const attachments_meta = attachments_blocks.map(([blocks, att, encrypted_name, size]) => {
    return {
      block_ids: validBlockIds(att) ? blocks.map(b => b.id) : att.block_ids,
      size,
      name: Helpers.b64Encode(encrypted_name),
      metadata: {
        content_type: att.metadata.content_type,
        content_id: att.metadata.content_id || "",
        content_disposition: att.metadata.content_disposition
      },
      _uuid: att.uuid
    };
  });

  const decrypted_metadata: PrivateMetadataV4 = {
    sender: sender.user_id,
    subject,
    tos: tos.map(u => u.user_id),
    ccs: ccs.map(u => u.user_id),
    bccs: bccs.map(u => u.user_id),
    other_headers: {},
    signature: Helpers.b64Encode(await sender.user_key.signing_key.sign(Helpers.utf8Encode(canonical_string))),
    signing_key_version: sender.user_key.key_version
  };

  const message: MailMessageV4 = {
    // more
    id: null,
    thread_id: null,
    version: null,
    mailbox_id: null,
    uid: null,
    is_deleted: null,
    timestamp: null,
    rev_id: null,
    protocol_version: EMAIL_PROTOCOL_VERSIONS.V4,
    message_id: Helpers.newMessageId(),
    flags,
    in_reply_to,
    references,
    private_metadata: Helpers.b64Encode(await symm_key.encrypt(Helpers.utf8Encode(JSON.stringify(decrypted_metadata)))),
    body: {
      snippet: encrypted_snippet,
      block_ids: body_blocks.map(b => b.id),
      size: body.length
    },
    attachments: attachments_meta,
  };

  return {
    metadata: {
      message,
      recipient
    },
    blocks: body_blocks.concat(...attachments_blocks.filter(([_, att, ...r]) => validBlockIds(att)).map(([blocks, ...rest]) => blocks))
  };
}
