import React, { useState, FocusEvent } from "react";
import { Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { CheckboxStates, DriveUIActionTypes, DriveSearchFilterType, DriveEntryType } from "src/common";
import { Checkbox } from "src/components";
import { SearchExtensionFilter } from ".";
import _ from "lodash";

type AllProps = {
  search_term?: string;
  handleAction: ActionHandlerFunction;
}


function SearchTypeFilterComponent(props: AllProps) {
  const { handleAction } = props;
  const [files_active, setFilesActive] = useState<boolean>(true);
  const [dir_active, setDirActive] = useState<boolean>(true);
  const [link_active, setLinkActive] = useState<boolean>(true);
  const [extensions_active, setExtensionsActive] = useState<string[]>([]);

  // Description: set all back to defaults on clear
  function resetDefaults() {
    handleAction({ actionType: DriveUIActionTypes.ResetFilters, params: DriveSearchFilterType.fs_type });
    setFilesActive(true);
    setDirActive(true);
    setLinkActive(true);
    setExtensionsActive([]);
  }

  // Description: Add Extension to keep checked state 
  function setExtensionsActiveState(extension: string, active: boolean) {
    const new_ext = extensions_active.slice();
    const index = _.findIndex(new_ext, (_extension) => (_extension === extension));
    index < 0 && active ? new_ext.push(extension) :
      (index >= 0 && !active && new_ext.splice(index, 1));
    setExtensionsActive(new_ext);
    handleAction({
      actionType: DriveUIActionTypes.SetFilter,
      params: { filter_type: DriveSearchFilterType.extension, extension, active }
    });
  }

  return <section>
    <header>
      <h5>Filter by Type</h5>
      <Button variant="link" size="sm"
        onClick={resetDefaults}>
        Reset
      </Button>
    </header>
    <ul>
      <li>
        <Checkbox
          onChange={(e: FocusEvent<HTMLInputElement>) => {
            setDirActive(e.target.checked);
            handleAction({
              actionType: DriveUIActionTypes.SetFilter,
              params: { filter_type: DriveSearchFilterType.fs_type, fs_type: DriveEntryType.DIR, active: e.target.checked }
            });
          }}
          selected={dir_active ? CheckboxStates.checked : CheckboxStates.empty}
          value="folders" label="Folders" />
      </li>
      <li>
        <Checkbox
          onChange={(e: FocusEvent<HTMLInputElement>) => {
            setLinkActive(e.target.checked);
            handleAction({
              actionType: DriveUIActionTypes.SetFilter,
              params: { filter_type: DriveSearchFilterType.fs_type, fs_type: DriveEntryType.LINK, active: e.target.checked }
            });
          }}
          selected={link_active ? CheckboxStates.checked : CheckboxStates.empty}
          value="shared" label="Shared Folders" />
      </li>
      <li>
        <Checkbox
          onChange={(e: FocusEvent<HTMLInputElement>) => {
            const active = e.target.checked;
            setFilesActive(active);
            // NOTE: Reset Extensions if FILES are not active
            if (!active) {
              setExtensionsActive([]);
              handleAction({ actionType: DriveUIActionTypes.ResetFilters, params: DriveSearchFilterType.extension });
            }
            handleAction({
              actionType: DriveUIActionTypes.SetFilter,
              params: { filter_type: DriveSearchFilterType.fs_type, fs_type: DriveEntryType.FILE, active }
            });
          }}
          selected={files_active ? CheckboxStates.checked : CheckboxStates.empty}
          value="files" label="Files" />
      </li>
      <li className="file-extensions" hidden={!files_active}>
        <SearchExtensionFilter
          extensions_active={extensions_active}
          setExtensionsActiveState={setExtensionsActiveState} />
      </li>
    </ul>
  </section>;
}

export default React.memo(SearchTypeFilterComponent);
