import React, { FocusEvent, useEffect, useState } from "react";
import { ActionHandlerFunction } from "@preveil-api";
import { AdminUIActionTypes, CheckboxStates } from "src/common";
import { Checkbox} from "src/components";
import { Col, Row} from "react-bootstrap";

type AllProps = {
  handleAction: ActionHandlerFunction;
  group_name: string;
  selected: boolean;
};

function EmailGroupRowComponent(props: AllProps) {
  const { handleAction, group_name, selected } = props;
  const [_selected, setSelected] = useState<boolean>(selected);

  useEffect(() => {
    setSelected(selected);
  }, [selected]);

  // Description: Handle the click of checkbox and changes of checkbox state
  function handleCheckboxChange(e: FocusEvent<HTMLInputElement>) {
    const new_selected = !_selected;
    setSelected(new_selected);
    handleAction({ actionType: AdminUIActionTypes.Select, params: { selected: e.target.checked, id: group_name } });
  }

  return (
    <Row className="align-items-center address-row">
      <Col xs="auto" className="pe-0">
        <Checkbox
          className="sr-only"
          onChange={(e: FocusEvent<HTMLInputElement>) => handleCheckboxChange(e)}
          selected={_selected ? CheckboxStates.checked : CheckboxStates.empty}
          value={group_name}
        />
      </Col>
      <Col md="5">
        <p className="mb-0">{group_name}</p>
      </Col>
    </Row>
  );
}

export default React.memo(EmailGroupRowComponent);
