import React, { useState } from "react";
import { Card, Form, Button, InputGroup } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { FormValidationLimits, Regex_Patterns, AccountErrorMessages, LoginWebUIActionTypes } from "src/common";
import { Icon, PasswordValidator } from "src/components";
import { Formik } from "formik";
import * as yup from "yup";

type AllProps = {
  handleAction: ActionHandlerFunction;
}

type FormProps = {
  password: string;
  confirm_password: string;
}

/* Description: Create new password form for PreVeil Express Password Recovery */
function CreateNewPasswordFormComponent(props: AllProps) {
  const { handleAction } = props;
  const [show_password, setShowPassword] = useState<boolean>(false);
  const [show_confirm_password, setShowConfirmPassword] = useState<boolean>(false);
  const schema = yup.object().shape({
    password: yup.string()
      .required(AccountErrorMessages.password_required_error)
      .min(
        FormValidationLimits.PASSWORD_MIN_LENGTH,
        AccountErrorMessages.password_min_length_error
      )
      .matches(Regex_Patterns.PASSWORD_REGEX,
        AccountErrorMessages.password_format_error),
    confirm_password: yup.string()
      .required(AccountErrorMessages.password_required_error)
      .oneOf([yup.ref("password"), null], "Passwords must match")
  });

  // Description: Handle form submit
  function handleSubmit(formValues: FormProps) {
    const params = formValues.password;
    handleAction({ actionType: LoginWebUIActionTypes.SubmitNewPassword, params });
  }

  return <Card.Body className="p-3 m-1">
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={{
        password: "",
        confirm_password: ""
      }} >
      {({ dirty, values, errors, touched, handleChange, handleSubmit, handleBlur, isValid }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <p className="fw-bold fs-4">Create a new PreVeil Password</p>
          <p>Please enter your new PreVeil Express password.</p>
          <Form.Group className="mb-3" controlId="password_control">
            <Form.Label className="fs-6" >Password</Form.Label>
            <InputGroup className={`password-form-control ${!!errors.password && touched.password ? "invalid" : ""}`} hasValidation={true}>
              <Icon className="ficon-lock" />
              <Form.Control
                type={!!show_password ? "text" : "password"}
                autoFocus={true}
                aria-describedby="passwordInput"
                placeholder="Enter Password"
                name="password"
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
                isInvalid={!!errors.password && touched.password} />
              <Button
                variant="transparent"
                className="btn-icon"
                size="sm"
                tabIndex={-1}
                onClick={() => setShowPassword(!show_password)}
                aria-label="show password"
              >
                <span>
                  <Icon className={!!show_password ? "ficon-eye" : "ficon-eye-off"} />
                </span>
              </Button>
            </InputGroup>
            {
              dirty ? <PasswordValidator password={values.password} /> : 
              <Form.Text className="text-muted d-block" as="div">
                {AccountErrorMessages.password_helper}
              </Form.Text>
            }
          </Form.Group>
          <Form.Group
            controlId="confirm_password_control">
            <Form.Label className="fs-6" >Confirm Password</Form.Label>
            <InputGroup className={`password-form-control ${!!errors.confirm_password && touched.confirm_password ? "invalid" : ""}`} hasValidation={true}>
              <Icon className="ficon-lock" />
              <Form.Control
                type={!!show_confirm_password ? "text" : "password"}
                aria-describedby="confirmPasswordInput"
                placeholder="Confirm Password"
                name="confirm_password"
                value={values.confirm_password}
                onBlur={handleBlur}
                onChange={handleChange}
                isInvalid={!!errors.confirm_password && touched.confirm_password} />
              <Button
                variant="transparent"
                className="btn-icon"
                size="sm"
                tabIndex={-1}
                onClick={() => setShowConfirmPassword(!show_confirm_password)}
                aria-label="show confirm password"
              >
                <span>
                  <Icon className={!!show_confirm_password ? "ficon-eye" : "ficon-eye-off"} />
                </span>
              </Button>
            </InputGroup>
            <Form.Control.Feedback type="invalid" className={`${!!errors.confirm_password && touched.confirm_password ? "d-block" : ""}`}>
              {errors.confirm_password}
            </Form.Control.Feedback>
          </Form.Group>
          <div className="btn-panel mt-4">
            <Button type="submit" disabled={!dirty || !isValid} >Continue</Button>
            <Button variant="outline-secondary" type="button"
              onClick={() => handleAction({ actionType: LoginWebUIActionTypes.ResetLogin })}>Cancel</Button>
          </div>
        </Form>
      )}
    </Formik>
  </Card.Body>;
}
export default React.memo(CreateNewPasswordFormComponent);
