import React, { useState, useEffect, FocusEvent, FormEvent, useRef } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { DriveUIActionTypes, EntryItem } from "src/common";
import { Icon } from "src/components";

type AllProps = {
  entry: EntryItem;
  action?: string;
  handleAction: ActionHandlerFunction;
};

function RenameItemFormComponent(props: AllProps) {
  const { entry, action, handleAction } = props;
  const [edit_mode, setEditMode] = useState<boolean>(action === DriveUIActionTypes.Rename);
  const [new_name, setName] = useState<string>(entry.name);
  const input_ref = useRef<HTMLInputElement>(null);

  // Description: Change panel state on entry change
  useEffect(() => {
    setEditMode(action === DriveUIActionTypes.Rename);
    if (action === DriveUIActionTypes.ResetName) {
      setName(entry.name);
      handleAction({ actionType: DriveUIActionTypes.ResetAction });
    }
  }, [action]);

  // Description: Ensures cursor is in input field when "Rename" option is selected in row dropdown
  useEffect(() => {
    (edit_mode && !!input_ref.current) && input_ref.current.focus();
  }, [edit_mode]);

  // Description: Update form if entry name changes
  useEffect(() => {
    setName(entry.name);
  }, [entry]);

  // Description: Clear form 
  function handleReset() {
    setEditMode(false);
    setName(entry.name);
    handleAction({ actionType: DriveUIActionTypes.ResetAction });
  }

  // Description: Validate and Save name 
  function handleSubmit() {
    const _new_name = new_name.trim();
    if (entry.name !== _new_name) {
      setEditMode(false);
      handleAction({ actionType: DriveUIActionTypes.Rename, params: { entry, new_name: _new_name } });
    } else {
      handleReset();
    }
  }

  return edit_mode ?
    <Form
      onSubmit={(e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleSubmit();
      }}>
      <Form.Label visuallyHidden>Selected Item Name</Form.Label>
      <InputGroup size="sm">
        <Form.Control
          ref={input_ref}
          autoFocus={true}
          type="text"
          placeholder={entry.name}
          value={new_name}
          onChange={(e: FocusEvent<HTMLInputElement>) => setName(e.currentTarget.value)}
          onBlur={(e: FocusEvent<HTMLInputElement>) => {
            e.preventDefault();
            handleSubmit();
          }} />
        {
          !!new_name &&
          <>
            <Button variant="icon" size="sm"
              onMouseDown={(e) => e.preventDefault()}
              onClick={handleSubmit}><Icon className="ficon-check" /></Button>
            <Button variant="icon" size="sm" className="text-muted-light"
              onMouseDown={(e) => e.preventDefault()}
              onClick={handleReset}><Icon className="ficon-x-circle" /></Button>
          </>
        }
      </InputGroup>
    </Form> :
    <p onClick={() => setEditMode(true)}>
      {new_name}<Icon className="pv-icon-compose text-muted" />
    </p>;
}

export default React.memo(RenameItemFormComponent);
