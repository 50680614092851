import { useCallback, useState } from "react";
import { MessageDisplayType, ErrorStackDataType } from "@preveil-api";
import { randomBytes } from "pvcryptojs";
import { FSMessage, FSWrappedKey, FSStatus } from "src/common/keys/protos/collections_pb";
import {
  Account, DriveErrorMessages, Message, MessageHandlerDisplayType, useAppDispatch, useSendRequestMutation, CollectionFilesyncAPI,
  UUID, DriveRequest, getDirSymmKey, Directory, useAppSelector, encryptName, PermissionSetType, getEnumKey, MessageAnchors,
  ErrorStackItem
} from "src/common";
import { DriveCallbackAsyncFunction, uiActions } from "src/store";
import { RootState } from "src/store/configureStore";
import _ from "lodash";

/* Description this is the relink web hook which is used both in the shared tab as well as the invitations
tab in drive. It adds(relinks) a folder back to My PreVeil */
export function useRelinkCollection(current_account: Account) {
  const [success, setSuccess] = useState<{ collection_id: string; collection_name: string, link_id: string }>();
  const [error, setError] = useState<{ name: string, id: string, conflict?: boolean }>();
  const default_permissions = useAppSelector((state: RootState) => state.drive.default_permissions);
  const root_info = useAppSelector((state: RootState) => state.drive.root_info);
  const [sendRequest] = useSendRequestMutation();
  const dispatch = useAppDispatch();

  // Description: Fetches the root directory object and checks if there are any conflicts with the roots entries,
  // if not, it encrypts the name and calls createLink()
  async function fetchRoot(root_collection_id: string, root_id: string, version: string, collection_name: string, collection_id: string, permissions: PermissionSetType) {
    const request = await CollectionFilesyncAPI.fetchDirectoryRequest(root_collection_id, root_id);
    const drive_request = await DriveRequest.initializeRequest(request, current_account, permissions);
    async function callback(message: FSMessage) {
      const root_dir = message.getDir();
      if (!!root_dir && message.getStatus() === FSStatus.OK) {
        const directory = await Directory.init(root_collection_id, root_dir, current_account, permissions, undefined);
        const wrapped_dir_key = root_dir.getWrappedDirKey() as FSWrappedKey;
        const unwrapped_dir_key = await getDirSymmKey(wrapped_dir_key, current_account, permissions);
        if (!!directory && !!unwrapped_dir_key) {
          const matching_entries = directory.entries.filter(
            (entry) => entry.name.toLowerCase() === collection_name.toLowerCase(),
          );
          if (matching_entries.length > 0) {
            handlePageErrorMessage(DriveErrorMessages.default, { name: collection_name, id: collection_id, conflict: true }, {
              stack_message: DriveErrorMessages.conflict_while_adding_folder.replace(MessageAnchors.folder_name, collection_name)
            });
          } else {
            const enc_link_name = await encryptName(collection_name, unwrapped_dir_key);
            createLink(root_collection_id, root_id, version, enc_link_name, collection_name, collection_id, permissions);
          }
        } else {
          handlePageErrorMessage(DriveErrorMessages.default, { name: collection_name, id: collection_id, conflict: false }, {
            stack_message: DriveErrorMessages.error_fetching_root_info,
          });
        }
      } else {
        handlePageErrorMessage(DriveErrorMessages.default,
          { name: collection_name, id: collection_id, conflict: false }, {
          stack_message: DriveErrorMessages.error_fetching_root_info,
        }, message);
      }
    }
    handleRequestErrors(drive_request, callback, collection_name, collection_id);
  }

  // Description: Creates a new link through the backend.
  async function createLink(root_collection_id: string, root_id: string, version: string, enc_link_name: Uint8Array, collection_name: string, collection_id: string, permissions: PermissionSetType) {
    const new_link_uuid = new UUID();
    const link_id = new_link_uuid.B64();
    const link_version = randomBytes(32);
    const request = await CollectionFilesyncAPI.createLink(
      current_account,
      permissions,
      root_collection_id,
      root_id,
      version,
      new_link_uuid.Bytes(),
      link_version,
      enc_link_name,
      collection_id
    );
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        setSuccess({ collection_id, collection_name, link_id });
      } else {
        handlePageErrorMessage(DriveErrorMessages.default,
          { name: collection_name, id: collection_id, conflict: false },
          {
            stack_message: DriveErrorMessages.error_creating_link,
            stack_error: message.getErrorMessage(),
          }, message);
      }
    }
    handleRequestErrors(request, callback, collection_name, collection_id);
  }

  // Descriptions: Handles undefined request error.
  function handleRequestErrors(request: DriveRequest | null, callback: DriveCallbackAsyncFunction<any>, name: string, id: string) {
    if (!!request) {
      request.setCallback(callback);
      sendRequest(request);
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, { name, id, conflict: false }, {
        stack_message: DriveErrorMessages.error_sending_request,
        stack_error: DriveErrorMessages.no_permissions_found,
      });
    }
  }

  // Description: Handle error message and send error to store
  function handlePageErrorMessage(message: string, set_error: { name: string, id: string, conflict: boolean }, stack_data?: ErrorStackDataType,
    fsmessage?: FSMessage | null, display_type: MessageDisplayType = MessageHandlerDisplayType.logger) {
    setError(set_error);
    const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
    const stack = new ErrorStackItem("[useRelinkCollection Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
    dispatch(uiActions.handleRequestErrors(new Message(message, display_type), stack));

  }
  // Description: callback for relink collection
  const relink = useCallback((collection_id: string, collection_name: string) => {
    const root_permissions = !!root_info ? _.find(default_permissions, (set: PermissionSetType) => root_info.collection_id === set.collection_id.B64()) : undefined;
    if (!!root_permissions) {
      if (!!root_info && !!root_info.version) {
        fetchRoot(root_info.collection_id, root_info.id, root_info.version, collection_name, collection_id, root_permissions);
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { name: collection_name, id: collection_id, conflict: false }, {
          stack_message: DriveErrorMessages.error_fetching_root_info
        });
      }
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, { name: collection_name, id: collection_id, conflict: false }, {
        stack_message: DriveErrorMessages.error_fetching_root_info
      });
    }
  }, []);

  // Description: Reset the hook
  const resetRelink = useCallback(() => {
    setSuccess(undefined);
    setError(undefined);
  }, []);

  return {
    success,
    error,
    relink,
    resetRelink
  };
}
