import * as React from "react";
import { Card, Button, Image } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { RecoverAccountUIActionTypes } from "src/common";
import img from "src/assets/images/recovery_approval.svg";

type AllProps = {
  handleAction: ActionHandlerFunction
}

function DefaultPanelComponent(props: AllProps) {
  const { handleAction } = props;

  return <>
    <Card.Body>
      <Card.Title as="p" className="semi-bold fs-4">
        If you were asked to <b>help</b> another user replace their private key, click the <span className="pv-orange">Recover</span> button below.
      </Card.Title>
      <Card.Text as="p">
        Note: You must be a member of their recovery approval group.
      </Card.Text>
      <Image alt="PreVeil Logo" src={img} className="float-end"/>
      <Button variant="primary" className="recovery-approval-button" onClick={() => handleAction({ actionType: RecoverAccountUIActionTypes.SubmitAcknowledgeInformation })}>Recover</Button>
    </Card.Body>
  </>;
}
export default React.memo(DefaultPanelComponent);
