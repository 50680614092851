import React, { useState, useEffect } from "react";
import { useSearchParams, Navigate } from "react-router-dom";
import { Card, Row, Col, Button } from "react-bootstrap";
import { IActionHandler, WebsocketCryptoMessage, MessageDisplayType } from "@preveil-api";
import {
  useAppSelector, useAppDispatch, QueryParamKeys, Message, normalizeQueryUserId, AccounWStSteps, DefaultRoutes,
  QueryParamValues, AccountErrorMessages, MessageHandlerDisplayType, GlobalErrorMessages, MessageAnchors
} from "src/common";
import { uiActions, websocketActions, WebsocketStatus } from "src/store";
import { Loading } from "src/components";
import {
  DefaultPanel,
  AwaitConnection,
  ConnectionEstablished,
  ErrorPanel
} from ".";
import CopyAccountImage from "src/assets/images/copy_account.svg";

function CopyAccountComponent() {
  const dispatch = useAppDispatch();
  const [user_id, setUserId] = useState<string>();
  const [step, setStep] = useState<number>(AccounWStSteps.DISCONNECTED);
  const [pin, setPin] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  const message = useAppSelector((state) => state.websocket.message as WebsocketCryptoMessage);
  const status = useAppSelector((state) => state.websocket.status);
  const errors = useAppSelector((state) => state.websocket.errors);

  // Description: load accounts and urrent account from crypto (First onload event)
  useEffect(() => {
    handleInitCopyAccount();
    return () => {  // Destroy websocket State onleave
      dispatch(websocketActions.destroyWebsocket());
    };
  }, []);

  // Description: Responds to changes in message and websocket status
  useEffect(() => {
    const isError = status === WebsocketStatus.error || message?.status === WebsocketStatus.error;
    let next = isError ? AccounWStSteps.ERROR : !!message ? step + 1 : step;

    if (!!message?.pin) {
      setPin(message.pin);
      next = AccounWStSteps.GET_CONNECTION_ESTABLISHED;
    }

    setStep(next);
  }, [message, status]);


  // Description: load accounts and urrent account from crypto (First onload event)
  useEffect(() => {
    !!errors &&
      CopyAccountRequests.handlePageErrorMessage({ message: AccountErrorMessages.default, stack: errors });
  }, [errors]);


  /* 
  *   Description: Initialize Component:
  *            - Get user ID from QS and pass it to the email input
  *            - Sets up KeyExchangeManager Instance
  */
  function handleInitCopyAccount() {
    const query = searchParams.get(QueryParamKeys.USER_ID_QUERY_KEY);
    const query_user_id = normalizeQueryUserId(query);
    !!query_user_id && setUserId(query_user_id);
    if (!!query) {
      searchParams.delete(QueryParamKeys.USER_ID_QUERY_KEY);
      setSearchParams(searchParams);
    }
  }

  // Description: Allow user to reset form if ws gets stuck or connection is disrupted
  function InPlaceLoading() {
    return <>
      <Loading className="in-place" />
      <Button onClick={() => CopyAccountRequests.handleDestroy()} >Try again</Button>
    </>;
  }

  // Description: Render panels by steps
  const CopyAccountPanels = () => {
    switch (step) {
      case AccounWStSteps.ERROR:
        return <ErrorPanel handleAction={handlePageActions} />;
      case AccounWStSteps.DISCONNECTED:
        return <DefaultPanel handleAction={handlePageActions} user_id={user_id} />;
      case AccounWStSteps.INITIALIZE_CONNECTION:
      case AccounWStSteps.GET_ACCEPTED_INIT_OK:
        return <AwaitConnection handleAction={handlePageActions} />;
      case AccounWStSteps.GET_CONNECTION_ESTABLISHED:
        return !!pin ? <ConnectionEstablished pin={pin} handleAction={handlePageActions} /> : <InPlaceLoading />;
      case AccounWStSteps.GET_KEY_TRANSFER_COMPLETE:
        return <Navigate to={`${DefaultRoutes.mail_default}?user_id=${!!user_id ? encodeURIComponent(user_id) : ""}&${QueryParamKeys.PAGE_ACTION_QUERY_KEY}=${QueryParamValues.added}`} />;
      default:
        return <InPlaceLoading />;
    };
  };

  // Description: Page actions
  const CopyAccountRequests = {
    // Description: Initialize the connection
    handleInitWebsocketConnection: (params: { user_id: string }) => {
      const _user_id = params.user_id;
      if (!!_user_id) {
        const host = `${process.env.REACT_APP_LOCAL_WEBSOCKET_SERVER}/get/account/${encodeURIComponent(_user_id)}/key/get`;
        dispatch(websocketActions.wsConnect({ host, init: { action: "init" } }));
        setUserId(_user_id);
      };
    },

    // Description: Reset message state and ws connection
    handleResetMessage: () => {
      dispatch(websocketActions.wsOnResetMessage());
    },

    // Description: Reset   ws connection
    handleDisconnect: () => {
      dispatch(websocketActions.wsDisconnect());
    },

    // Description: Reset page state and ws connection
    handleDestroy: () => {
      setStep(AccounWStSteps.DISCONNECTED);
      setUserId(undefined);
      dispatch(websocketActions.destroyWebsocket());
    },

    handlePageError: (params: { message: string, stack?: any, displayType?: MessageDisplayType }) => {
      const displayType = params.displayType || MessageHandlerDisplayType.toastr;
      dispatch(uiActions.handleRequestErrors(new Message(params.message, displayType), params.stack));
    },

    handlePageErrorMessage: (params: { message: string, stack?: any }) => {
      dispatch(uiActions.handleRequestErrors(new Message(params.message, MessageHandlerDisplayType.logger), params.stack));
    }
  };

  //  Description: Handle all actions from Children forms
  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    if ((CopyAccountRequests as any)[callback] instanceof Function) {
      (CopyAccountRequests as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(MessageAnchors.actionType, actionObj.actionType);
      CopyAccountRequests.handlePageErrorMessage({ message, stack: actionObj });
    }
  }

  return <Row className="justify-content-center">
    <Col md={8}>
      <h1 className="main-header">Copy account from computer or phone</h1>
      <Card className="pv-card">
        <Card.Img variant="top" src={CopyAccountImage} />
        <Card.Body className="p-4">
          <CopyAccountPanels />
        </Card.Body>
      </Card>
    </Col>
  </Row>;
}

export default React.memo(CopyAccountComponent);
