// source: backup.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var elixir_pb = require('./elixir_pb.js');
goog.object.extend(proto, elixir_pb);
var key_pb = require('./key_pb.js');
goog.object.extend(proto, key_pb);
goog.exportSymbol('proto.PVKeys.Backup', null, global);
goog.exportSymbol('proto.PVKeys.Backup.Data', null, global);
goog.exportSymbol('proto.PVKeys.PublicUserKey', null, global);
goog.exportSymbol('proto.PVKeys.UserKey', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PVKeys.UserKey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PVKeys.UserKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PVKeys.UserKey.displayName = 'proto.PVKeys.UserKey';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PVKeys.PublicUserKey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PVKeys.PublicUserKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PVKeys.PublicUserKey.displayName = 'proto.PVKeys.PublicUserKey';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PVKeys.Backup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PVKeys.Backup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PVKeys.Backup.displayName = 'proto.PVKeys.Backup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PVKeys.Backup.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PVKeys.Backup.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PVKeys.Backup.Data.displayName = 'proto.PVKeys.Backup.Data';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PVKeys.UserKey.prototype.toObject = function(opt_includeInstance) {
  return proto.PVKeys.UserKey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PVKeys.UserKey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PVKeys.UserKey.toObject = function(includeInstance, msg) {
  var f, obj = {
    protocolVersion: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    keyVersion: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    privateKey: (f = msg.getPrivateKey()) && key_pb.Key.toObject(includeInstance, f),
    signingKey: (f = msg.getSigningKey()) && key_pb.Key.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PVKeys.UserKey}
 */
proto.PVKeys.UserKey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PVKeys.UserKey;
  return proto.PVKeys.UserKey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PVKeys.UserKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PVKeys.UserKey}
 */
proto.PVKeys.UserKey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setProtocolVersion(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setKeyVersion(value);
      break;
    case 3:
      var value = new key_pb.Key;
      reader.readMessage(value,key_pb.Key.deserializeBinaryFromReader);
      msg.setPrivateKey(value);
      break;
    case 4:
      var value = new key_pb.Key;
      reader.readMessage(value,key_pb.Key.deserializeBinaryFromReader);
      msg.setSigningKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PVKeys.UserKey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PVKeys.UserKey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PVKeys.UserKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PVKeys.UserKey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getPrivateKey();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      key_pb.Key.serializeBinaryToWriter
    );
  }
  f = message.getSigningKey();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      key_pb.Key.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 protocol_version = 1;
 * @return {number}
 */
proto.PVKeys.UserKey.prototype.getProtocolVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.PVKeys.UserKey} returns this
 */
proto.PVKeys.UserKey.prototype.setProtocolVersion = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.PVKeys.UserKey} returns this
 */
proto.PVKeys.UserKey.prototype.clearProtocolVersion = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PVKeys.UserKey.prototype.hasProtocolVersion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 key_version = 2;
 * @return {number}
 */
proto.PVKeys.UserKey.prototype.getKeyVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.PVKeys.UserKey} returns this
 */
proto.PVKeys.UserKey.prototype.setKeyVersion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.PVKeys.UserKey} returns this
 */
proto.PVKeys.UserKey.prototype.clearKeyVersion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PVKeys.UserKey.prototype.hasKeyVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Key private_key = 3;
 * @return {?proto.PVKeys.Key}
 */
proto.PVKeys.UserKey.prototype.getPrivateKey = function() {
  return /** @type{?proto.PVKeys.Key} */ (
    jspb.Message.getWrapperField(this, key_pb.Key, 3));
};


/**
 * @param {?proto.PVKeys.Key|undefined} value
 * @return {!proto.PVKeys.UserKey} returns this
*/
proto.PVKeys.UserKey.prototype.setPrivateKey = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PVKeys.UserKey} returns this
 */
proto.PVKeys.UserKey.prototype.clearPrivateKey = function() {
  return this.setPrivateKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PVKeys.UserKey.prototype.hasPrivateKey = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Key signing_key = 4;
 * @return {?proto.PVKeys.Key}
 */
proto.PVKeys.UserKey.prototype.getSigningKey = function() {
  return /** @type{?proto.PVKeys.Key} */ (
    jspb.Message.getWrapperField(this, key_pb.Key, 4));
};


/**
 * @param {?proto.PVKeys.Key|undefined} value
 * @return {!proto.PVKeys.UserKey} returns this
*/
proto.PVKeys.UserKey.prototype.setSigningKey = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PVKeys.UserKey} returns this
 */
proto.PVKeys.UserKey.prototype.clearSigningKey = function() {
  return this.setSigningKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PVKeys.UserKey.prototype.hasSigningKey = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PVKeys.PublicUserKey.prototype.toObject = function(opt_includeInstance) {
  return proto.PVKeys.PublicUserKey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PVKeys.PublicUserKey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PVKeys.PublicUserKey.toObject = function(includeInstance, msg) {
  var f, obj = {
    protocolVersion: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    keyVersion: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    publicKey: (f = msg.getPublicKey()) && key_pb.Key.toObject(includeInstance, f),
    verifyKey: (f = msg.getVerifyKey()) && key_pb.Key.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PVKeys.PublicUserKey}
 */
proto.PVKeys.PublicUserKey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PVKeys.PublicUserKey;
  return proto.PVKeys.PublicUserKey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PVKeys.PublicUserKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PVKeys.PublicUserKey}
 */
proto.PVKeys.PublicUserKey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setProtocolVersion(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setKeyVersion(value);
      break;
    case 3:
      var value = new key_pb.Key;
      reader.readMessage(value,key_pb.Key.deserializeBinaryFromReader);
      msg.setPublicKey(value);
      break;
    case 4:
      var value = new key_pb.Key;
      reader.readMessage(value,key_pb.Key.deserializeBinaryFromReader);
      msg.setVerifyKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PVKeys.PublicUserKey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PVKeys.PublicUserKey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PVKeys.PublicUserKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PVKeys.PublicUserKey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getPublicKey();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      key_pb.Key.serializeBinaryToWriter
    );
  }
  f = message.getVerifyKey();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      key_pb.Key.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 protocol_version = 1;
 * @return {number}
 */
proto.PVKeys.PublicUserKey.prototype.getProtocolVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.PVKeys.PublicUserKey} returns this
 */
proto.PVKeys.PublicUserKey.prototype.setProtocolVersion = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.PVKeys.PublicUserKey} returns this
 */
proto.PVKeys.PublicUserKey.prototype.clearProtocolVersion = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PVKeys.PublicUserKey.prototype.hasProtocolVersion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 key_version = 2;
 * @return {number}
 */
proto.PVKeys.PublicUserKey.prototype.getKeyVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.PVKeys.PublicUserKey} returns this
 */
proto.PVKeys.PublicUserKey.prototype.setKeyVersion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.PVKeys.PublicUserKey} returns this
 */
proto.PVKeys.PublicUserKey.prototype.clearKeyVersion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PVKeys.PublicUserKey.prototype.hasKeyVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Key public_key = 3;
 * @return {?proto.PVKeys.Key}
 */
proto.PVKeys.PublicUserKey.prototype.getPublicKey = function() {
  return /** @type{?proto.PVKeys.Key} */ (
    jspb.Message.getWrapperField(this, key_pb.Key, 3));
};


/**
 * @param {?proto.PVKeys.Key|undefined} value
 * @return {!proto.PVKeys.PublicUserKey} returns this
*/
proto.PVKeys.PublicUserKey.prototype.setPublicKey = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PVKeys.PublicUserKey} returns this
 */
proto.PVKeys.PublicUserKey.prototype.clearPublicKey = function() {
  return this.setPublicKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PVKeys.PublicUserKey.prototype.hasPublicKey = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Key verify_key = 4;
 * @return {?proto.PVKeys.Key}
 */
proto.PVKeys.PublicUserKey.prototype.getVerifyKey = function() {
  return /** @type{?proto.PVKeys.Key} */ (
    jspb.Message.getWrapperField(this, key_pb.Key, 4));
};


/**
 * @param {?proto.PVKeys.Key|undefined} value
 * @return {!proto.PVKeys.PublicUserKey} returns this
*/
proto.PVKeys.PublicUserKey.prototype.setVerifyKey = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PVKeys.PublicUserKey} returns this
 */
proto.PVKeys.PublicUserKey.prototype.clearVerifyKey = function() {
  return this.setVerifyKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PVKeys.PublicUserKey.prototype.hasVerifyKey = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PVKeys.Backup.prototype.toObject = function(opt_includeInstance) {
  return proto.PVKeys.Backup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PVKeys.Backup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PVKeys.Backup.toObject = function(includeInstance, msg) {
  var f, obj = {
    protocolVersion: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PVKeys.Backup}
 */
proto.PVKeys.Backup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PVKeys.Backup;
  return proto.PVKeys.Backup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PVKeys.Backup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PVKeys.Backup}
 */
proto.PVKeys.Backup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setProtocolVersion(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PVKeys.Backup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PVKeys.Backup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PVKeys.Backup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PVKeys.Backup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PVKeys.Backup.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.PVKeys.Backup.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PVKeys.Backup.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PVKeys.Backup.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    backupId: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    userKey: (f = msg.getUserKey()) && proto.PVKeys.UserKey.toObject(includeInstance, f),
    backupKey: (f = msg.getBackupKey()) && proto.PVKeys.UserKey.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PVKeys.Backup.Data}
 */
proto.PVKeys.Backup.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PVKeys.Backup.Data;
  return proto.PVKeys.Backup.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PVKeys.Backup.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PVKeys.Backup.Data}
 */
proto.PVKeys.Backup.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackupId(value);
      break;
    case 3:
      var value = new proto.PVKeys.UserKey;
      reader.readMessage(value,proto.PVKeys.UserKey.deserializeBinaryFromReader);
      msg.setUserKey(value);
      break;
    case 4:
      var value = new proto.PVKeys.UserKey;
      reader.readMessage(value,proto.PVKeys.UserKey.deserializeBinaryFromReader);
      msg.setBackupKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PVKeys.Backup.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PVKeys.Backup.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PVKeys.Backup.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PVKeys.Backup.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserKey();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.PVKeys.UserKey.serializeBinaryToWriter
    );
  }
  f = message.getBackupKey();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.PVKeys.UserKey.serializeBinaryToWriter
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.PVKeys.Backup.Data.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.PVKeys.Backup.Data} returns this
 */
proto.PVKeys.Backup.Data.prototype.setUserId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.PVKeys.Backup.Data} returns this
 */
proto.PVKeys.Backup.Data.prototype.clearUserId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PVKeys.Backup.Data.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string backup_id = 2;
 * @return {string}
 */
proto.PVKeys.Backup.Data.prototype.getBackupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.PVKeys.Backup.Data} returns this
 */
proto.PVKeys.Backup.Data.prototype.setBackupId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.PVKeys.Backup.Data} returns this
 */
proto.PVKeys.Backup.Data.prototype.clearBackupId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PVKeys.Backup.Data.prototype.hasBackupId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional UserKey user_key = 3;
 * @return {?proto.PVKeys.UserKey}
 */
proto.PVKeys.Backup.Data.prototype.getUserKey = function() {
  return /** @type{?proto.PVKeys.UserKey} */ (
    jspb.Message.getWrapperField(this, proto.PVKeys.UserKey, 3));
};


/**
 * @param {?proto.PVKeys.UserKey|undefined} value
 * @return {!proto.PVKeys.Backup.Data} returns this
*/
proto.PVKeys.Backup.Data.prototype.setUserKey = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PVKeys.Backup.Data} returns this
 */
proto.PVKeys.Backup.Data.prototype.clearUserKey = function() {
  return this.setUserKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PVKeys.Backup.Data.prototype.hasUserKey = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional UserKey backup_key = 4;
 * @return {?proto.PVKeys.UserKey}
 */
proto.PVKeys.Backup.Data.prototype.getBackupKey = function() {
  return /** @type{?proto.PVKeys.UserKey} */ (
    jspb.Message.getWrapperField(this, proto.PVKeys.UserKey, 4));
};


/**
 * @param {?proto.PVKeys.UserKey|undefined} value
 * @return {!proto.PVKeys.Backup.Data} returns this
*/
proto.PVKeys.Backup.Data.prototype.setBackupKey = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PVKeys.Backup.Data} returns this
 */
proto.PVKeys.Backup.Data.prototype.clearBackupKey = function() {
  return this.setBackupKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PVKeys.Backup.Data.prototype.hasBackupKey = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint64 protocol_version = 1;
 * @return {number}
 */
proto.PVKeys.Backup.prototype.getProtocolVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.PVKeys.Backup} returns this
 */
proto.PVKeys.Backup.prototype.setProtocolVersion = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.PVKeys.Backup} returns this
 */
proto.PVKeys.Backup.prototype.clearProtocolVersion = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PVKeys.Backup.prototype.hasProtocolVersion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes data = 2;
 * @return {!(string|Uint8Array)}
 */
proto.PVKeys.Backup.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes data = 2;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.PVKeys.Backup.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.PVKeys.Backup.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.PVKeys.Backup} returns this
 */
proto.PVKeys.Backup.prototype.setData = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.PVKeys.Backup} returns this
 */
proto.PVKeys.Backup.prototype.clearData = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PVKeys.Backup.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.PVKeys);
