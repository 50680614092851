import * as React from "react";
import { Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, ComposeTypes, ComposeMail, NavigationPath, DefaultRoutes, QueryParamKeys } from "src/common";
import { Icon } from "src/components";
import { mailActions } from "src/store";

function FeedbackComponent(props: { type: string }) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Description: handle compose mail from all parts of the application
  function composeFeedback() {
    if (props.type === NavigationPath.MailNavigation) {
      const compose_message = new ComposeMail(ComposeTypes.new);
      compose_message.setDefaults("feedback@preveil.com", "Feedback for PreVeil");
      dispatch(mailActions.addComposeMessage(compose_message));
    } else {
      navigate(`${DefaultRoutes.mail_default}?${QueryParamKeys.PAGE_FEEDBACK_QUERY_KEY}=1`);
    }
  }

  return <Nav.Link title="We welcome your feedback"
    className="feedback"
    onClick={composeFeedback}>
    <Icon className="ficon-message-square" />
    <span>We welcome your feedback</span>
  </Nav.Link>;
}

export default React.memo(FeedbackComponent);