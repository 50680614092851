/*
*   File:           utilities.ts
*   Description:    Collection of utility and misc helper functions
*                   i.e: global regex tests, and validators
*   Author:         PreVeil, LLC
*/
import { UserProfile, Sort, IActionHandler, UserListStatusType, UserIdentifierBase, CollectionServerUser } from "@preveil-api";
import { RegexHelper, MimeTypes, mimeTypes, GlobalErrorMessages, MessageAnchors, UIManagerActionTypes, dayjs, EntryItem, DriveFileType, account_types } from "../";
import _ from "lodash";
import { SORT_DIRECTION } from "../constants/constants";


// Note: use this instead of navigator.cookieEnabled -> stronger full capability test for localstorage with iOS private browsing protection
export function isSessionStorageEnabled(): boolean {
  try {
    sessionStorage.setItem("testing_sessions", "value");
    const enabled = sessionStorage.getItem("testing_sessions") === "value";
    sessionStorage.removeItem("testing_sessions");
    return enabled;
  } catch (err) {
    return false;
  }
}

// Description: Returns the Page Title by pathname. This is default for most pages (legacy: appComponent > _setFormattedPageTitle)
// Exceptions add on demand on: Mail, Drive, Approval Groups, more... 
export function getDefaultPageTitle(pathname: string): string {
  // Format page title for readability based on the url
  // If we"re at the base url then routes to Get Started page, so force that into the title
  let title = "Get Started - PreVeil";
  if (pathname !== "/") {
    const url_without_query_string = pathname.replace(/\?.*/, "");
    const url_without_slahes = url_without_query_string.replace(/.*\//, "");
    // split the string based on the dash into an array
    const page_title_words = url_without_slahes.split("-");
    // capitalize the start of each word in the array
    // including check to see if first char is letter, show empty if not
    const formatted_page_title = page_title_words.map(word => word[0] && word[0].match(/[a-z]/i) ? capitalize(word) : "");
    // put the words back into a single string with a space between them
    const formatted_title = formatted_page_title.join(" ");
    title = formatted_title.length ? `${formatted_title} - PreVeil` : "PreVeil";
  }
  return title;
}

// Description: gets the second path param (subpage)
export function getSubPath(pathname: string): string | null {
  const arr = window.location.pathname.split("/");
  return arr.length > 2 ? arr[2] : null;
}

// Description: Check if string is a number
export function isNumber(str: string): boolean {
  return /^\d+$/.test(str);
}

// Description: return Capitalized word/ words
export function capitalize(text: string): string {
  const _text = text.split(" ").map((t) => {
    return t.charAt(0).toUpperCase() + t.slice(1);
  });
  return _text.join(" ");
}

// Description: Receives the USER_ID_QUERY_KEY and returns a validated, trimmed, lower caps string for user_id
// Returns undefined when query is not there
export function normalizeQueryUserId(query?: string | null): string | null | undefined {
  const user_id = query?.trim();
  return (!!user_id && RegexHelper.testEmailAddress(user_id)) ? decodeURIComponent(user_id).toLowerCase() : undefined;
}

// Description: Remove HTML from string
export function stripHtml(html: string): string {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
}

// Description: Takes an array of Uint8Array and merges them into 1
export function mergeUint8Arrays(original_array: Uint8Array[]): Uint8Array {
  let offset = 0;
  let length = 0;  // Get the total length of all arrays.
  original_array.forEach((item: Uint8Array) => (length += item.length));

  // Create a new array with total length and merge all source arrays.
  const mergedArray = new Uint8Array(length);
  original_array.forEach((item: Uint8Array) => {
    mergedArray.set(item, offset);
    offset += item.length;
  });
  return mergedArray;
}

// Description: Returns a value from an enum or object enum FSTypeMap[keyof FSTypeMap]
export function getEnumKey(used_enum: Object, index?: number): string | null {
  return index !== undefined ? Object.keys(used_enum).filter(x => !(parseInt(x) >= 0))[index] : null;
}

// -----------------------------------------------------------------------------
// Validators
// -----------------------------------------------------------------------------
// Description: pass an array of User Models that include a user_id field 
export function ValidateUserExists(users: CollectionServerUser[], user_id: string): boolean {
  return _.findIndex(users, (user: CollectionServerUser) => {
    return isSameUser(user.user_id, user_id) && (user.account_type === account_types.full || user.account_type === account_types.express);
  }) > -1;
}

// Description: Quickly compare 2 users email (will take into account the undefined values too)
export function isSameUser(user_id?: string | null, user_id_compare?: string | null): boolean {
  return !!user_id && !!user_id_compare && user_id.toLowerCase() === user_id_compare.toLowerCase();
}

// Description: gets the correct user_id from UserIdentifierBase safely and return external email where appropriate
export function getProfileUserId(user: UserIdentifierBase): string | undefined {
  return !!user.external_email ? user.external_email.toLowerCase() : !!user.user_id ? user.user_id.toLowerCase() : undefined;
}

// Description: Compare 2 user Profiles make sure to compare external_email first if there
export function isSameProfile(profile?: UserProfile, profile_compare?: UserProfile): boolean {
  if (!!profile && !!profile.address && !!profile_compare) {
    let email1 = profile.address.toLowerCase();
    let email2 = profile_compare.address.toLowerCase();
    if (!!profile.external_email || !!profile_compare.external_email) {
      email1 = !!profile.external_email ? profile.external_email.toLowerCase() : "email1";
      email2 = !!profile_compare.external_email ? profile_compare.external_email.toLowerCase() : "email2";
    }
    return email1 === email2;
  } else {
    return false;
  }
}

// Description: Sort tables by field and direction
export function sortBy<T>(items: T[], sort: Sort<keyof T>, is_date: boolean = false): T[] {
  return _.orderBy(items, [item =>
    is_date && typeof item[sort.field] === "string" ? new Date(String(item[sort.field])) :
      typeof item[sort.field] === "string" ? String(item[sort.field]).toLowerCase() : item[sort.field]
  ], [sort.direction]);
}

export function getOppositeSortDirection(direction: "asc" | "desc"): "asc" | "desc" {
  return direction === "asc" ? SORT_DIRECTION.descending : SORT_DIRECTION.ascending;
}

// Description: This lookup function replaces the library mime-types in legacy code
// uses the types in constants/file-mime-types.ts
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
// returns a string with mimetype OR null if non found
export function mimeLookup(filename: string): string | null {
  const extension = `.${filename.split(".").pop()}`.toLowerCase(); // the period for the extension is removed in this operation
  return (!!extension && extension in MimeTypes) ?
    MimeTypes[extension as keyof typeof MimeTypes] : null;
}

// Description: match a file name to a subset of mimeTypes extension filters
export function extensionMatch(filename: string, extensions: string[]): boolean {
  const extension = `.${filename.split(".").pop()}`.toLowerCase(); // the period for the extension is removed in this operation
  return !!extension ? extensions.includes(extension) : false;
}

// Description: Return an array of file extensions:
export function fileExtensions(): string[] {
  return _.keys(mimeTypes);
}

// Description: Checks to see if answer is a JSON Object
export function isValidJSON(obj: any): boolean {
  try {
    JSON.parse(obj);
    return true;
  } catch (e) {
    return false;
  }
}

// Description: Takes an array of addresses or domains and returns a collection of invalid addresses or domains
// if not invalid value is found, array will return empty.
export function findInvalidAddress(collection: string[]) {
  return collection.filter((address: string) => !(RegexHelper.testEmailAddress(address) || RegexHelper.testDomain(address)));
};

// Description: Takes two arrays of addresses or domains (strings) and returns true if there a duplicates between the two
export function findDuplicateAddress(collection_one: string[], collection_two: string[]) {
  return collection_two.some((address: string) => collection_one.includes(address));
};

// Description: Takes a string and returns a string with non-ascii characters removed.
// This is similar to the function in V1: removeInvalidChar
// but we are using a regex to avoid all the array manipulation with split, map and join
export function removeNonAsciiChars(str: string) {
  // eslint-disable-next-line no-control-regex
  return str.replace(/[^\x00-\x7F]/g, "");
}

// Description: will type the handlePageActions for global use **** TO DO: Finish and implement *****
type PageRequestObject = { [key: string]: Function };
export function handlePageActions(ActionRequestObject: PageRequestObject, actionObj: IActionHandler) {
  const callback = `handle${actionObj.actionType}`;
  const exists = Object.prototype.hasOwnProperty.call(ActionRequestObject, callback);
  if (exists && ActionRequestObject[callback] instanceof Function) {
    ActionRequestObject[callback](actionObj.params);
  } else {
    const message = GlobalErrorMessages.no_handler_found.replace(MessageAnchors.actionType, actionObj.actionType);
    (ActionRequestObject[UIManagerActionTypes.PageError] instanceof Function) ?
      ActionRequestObject.handlePageErrorMessage({ message, stack: actionObj }) :
      console.error(message);
  }
}

// Description: Get counts for notifications
export function getStatusCounts(profiles: UserProfile[], type: UserListStatusType | UserListStatusType[]): string | null {
  const types = Array.isArray(type) ? type : [type];
  const count = _.filter(profiles, (profile: UserProfile) => (!!profile.status && types.includes(profile.status))).length;
  return count > 0 ? `${count} recipient${count > 1 ? "s" : ""}` : null;
};

// Description: Utility to compare dates - return true if the strings are equal
export function isSameDate(date1?: string, date2?: string): boolean {
  if (!!date1 && !!date2) {
    const _date1 = dayjs(date1).format("l");
    const _date2 = dayjs(date2).format("l");
    return _date1 === _date2;
  } else {
    return date1 === date2;
  }
}

// Description: Get a date from server and parse it to user friendly label
export function parseValidDate(date: string): string {
  const days_date = dayjs(date);
  return days_date.isValid() ? days_date.format("MM/DD/YYYY h:mm A") : "";
}
export function parseValidUTCDate(date: string): string {
  const days_date = dayjs(date);
  return days_date.isValid() ? dayjs.utc(date).format("l") : "";
}


// Description: false if the viewer is supported (and if it is in the list of supported files) and true if nots
export function viewerUnsupported(entry: EntryItem): boolean {
  const file_type = !!entry.type_label ? entry.type_label : undefined;
  const supported = [DriveFileType.PDF, DriveFileType.Image, DriveFileType.Text, DriveFileType.Code,
  DriveFileType.Word, DriveFileType.Excel, DriveFileType.PowerPoint];
  return !(!!file_type && supported.includes(file_type));
}
