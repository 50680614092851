/*
*  File:            mail/slice.ts
*  Description:     Holds types and constants for Mail module
*  Author:          PreVeil
*/
import keyMirror from "keymirror";
import { action } from "typesafe-actions";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MailboxItem, MailboxesState, PaginationItem, MailboxNotificationCounts, ThreadsPage, GetThreadsParams, SetFlagsParams, ThreadMessage, CurrentThread } from "@preveil-api";
import {
  InitialPaginationState, ComposeMailMessage, ComposeMail, MoveMailApiArg, SearchResultFilterType, SearchResultFilter, SearchMatchesType,
  GetThreadApiArg, dayjs
} from "src/common";
export interface MailState {
  is_loading: boolean;
  mailbox_root_rev_id?: number;
  custom_mailboxes?: MailboxItem[];
  default_mailboxes?: MailboxItem[];
  current_mailbox?: MailboxItem;
  mailboxes_counts?: MailboxNotificationCounts;
  threads_page?: ThreadsPage,
  current_thread?: CurrentThread,
  pagination: PaginationItem,
  expanded: string[]; // Holds state for Custom Mailbox tree (navigation prooved)
  compose: ComposeMailMessage[]; // Holds the information for opened compose components
  new_mail: boolean;
  selected: string[];
  is_bulk_action: boolean;
  search_term?: string;
  search_filter: SearchResultFilterType;
  search_matches?: SearchMatchesType;
  move_destination?: MailboxItem;
  refresh_counts?: boolean;
};

const initialState: MailState = {
  is_loading: false,
  expanded: [],
  pagination: InitialPaginationState,
  compose: [],
  new_mail: false,
  selected: [],
  is_bulk_action: false,
  search_filter: SearchResultFilter.all,
  refresh_counts: false
};

export const MailActionTypes = keyMirror({
  FETCH_MAIL_THREADS: null,
  FETCH_CLIENT_MAIL_THREADS: null, // Triggers the older crypto call to mail/user_id
  FETCH_CURRENT_THREAD: null,
  REFRESH_MAIL_THREADS: null,
  SET_FLAGS: null,
  DELETE_MAIL_THREADS: null,
  MOVE_MAIL: null
});

export const mailSlice = createSlice({
  name: "mail",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.is_loading = action.payload;
    },
    setNewMail: (state, action: PayloadAction<boolean>) => {
      state.new_mail = action.payload;
    },
    setRefreshCounts: (state, action: PayloadAction<boolean>) => {
      state.refresh_counts = action.payload;
    },
    setSelected: (state, action: PayloadAction<string[]>) => {
      state.selected = action.payload;
    },
    setBulkAction: (state, action: PayloadAction<boolean>) => {
      state.is_bulk_action = action.payload;
    },
    resetBulkAction: (state) => {
      state.is_bulk_action = false;
      state.selected = [];
      state.is_loading = false;
    },
    setMailboxesSuccess: (state, action: PayloadAction<MailboxesState>) => {
      state.mailbox_root_rev_id = action.payload.rev_id >= 0 ? action.payload.rev_id : 0; // NOTE: Do not save rev_id if its -1
      state.custom_mailboxes = action.payload.custom_mailboxes;
      state.default_mailboxes = action.payload.default_mailboxes;
    },
    setCurrentMailboxSuccess: (state, action: PayloadAction<MailboxItem>) => {
      state.current_mailbox = action.payload;
    },
    setThreadsSuccess: (state, action: PayloadAction<ThreadsPage>) => {
      if (state.is_bulk_action) { // Note: remove selected from bulk actions
        state.is_bulk_action = false;
        state.selected = [];
      }
      state.is_loading = false;
      const threads = action.payload.threads.sort((a, b) => dayjs(b._timestamp).diff(a._timestamp));
      state.threads_page = Object.assign({}, action.payload, { threads });
    },
    setCurrentThreadSuccess: (state, action: PayloadAction<CurrentThread>) => {
      state.current_thread = action.payload;
    },
    resetCurrentThread: (state) => {
      state.current_thread = undefined;
    },
    // Description: Allows the loading to be resolved and displays empty mailbox with error message
    setThreadsError: (state) => {
      state.threads_page = { total: 0, threads: [] };
    },
    setMailboxRevId: (state, action: PayloadAction<number>) => {
      state.mailbox_root_rev_id = action.payload;
    },
    setMailboxesNotificationCountsSuccess: (state, action: PayloadAction<MailboxNotificationCounts>) => {
      state.mailboxes_counts = action.payload;
      state.refresh_counts = false;
    },
    decrementDraftNotifications: (state, action: PayloadAction<number>) => {
      const draft = (state.mailboxes_counts?.draft || -1) - action.payload;
      if (draft >= 0) {
        state.mailboxes_counts = Object.assign({}, state.mailboxes_counts, { draft });
      }
    },
    setPagination: (state, action: PayloadAction<PaginationItem>) => {
      state.pagination = action.payload;
    },
    resetPagination: (state) => {
      state.pagination = initialState.pagination;
    },
    setDeleteMailError: (state) => {
      if (state.is_bulk_action) { // Note: remove selected from bulk actions
        state.is_bulk_action = false;
        state.selected = [];
      }
      state.is_loading = false;
    },
    setSearchTerm: (state, action: PayloadAction<string | undefined>) => {
      state.search_term = action.payload;
    },
    setSearchFilter: (state, action: PayloadAction<SearchResultFilterType>) => {
      state.search_filter = action.payload;
    },
    setSearchMatches: (state, action: PayloadAction<SearchMatchesType | undefined>) => {
      state.search_matches = action.payload;
    },
    setExpanded: (state, action: PayloadAction<string[]>) => {
      state.expanded = action.payload;
    },
    addComposeMessage: (state, action: PayloadAction<ComposeMail>) => {
      const new_compose = [...state.compose, action.payload.compose_mail];
      state.compose = new_compose;
    },
    setComposeMessage: (state, action: PayloadAction<ComposeMailMessage[]>) => {
      state.compose = action.payload;
    },
    setMoveDestination: (state, action: PayloadAction<MailboxItem | undefined>) => {
      state.move_destination = action.payload;
    },
    destroyMail: () => {
      return initialState;
      // Need to selectively destroy parts otherwise buggy 
    }
  }
});
// ----------------------------------------------------------------------
// Saga specific Actions
// ----------------------------------------------------------------------
const getMailThreads = (params: GetThreadsParams) => action(MailActionTypes.FETCH_MAIL_THREADS, params);
const getClientMailThreads = (params: GetThreadsParams) => action(MailActionTypes.FETCH_CLIENT_MAIL_THREADS, params);
const getCurrentThread = (params: GetThreadApiArg & { mailbox_id: string }) => action(MailActionTypes.FETCH_CURRENT_THREAD, params);
const updateFlags = (params: SetFlagsParams) => action(MailActionTypes.SET_FLAGS, params);
const deleteMail = (params: { messages: ThreadMessage[], offset: number, mailbox_id: string }) => action(MailActionTypes.DELETE_MAIL_THREADS, params);
const refreshCurrentMailThreads = (params: Partial<GetThreadsParams>) => action(MailActionTypes.REFRESH_MAIL_THREADS, params);
const moveMail = (params: MoveMailApiArg[]) => action(MailActionTypes.MOVE_MAIL, params);

const mailSagaActions = {
  getMailThreads,
  getClientMailThreads,
  getCurrentThread,
  refreshCurrentMailThreads,
  updateFlags,
  deleteMail,
  moveMail
};

// ----------------------------------------------------------------------
// Global exports
// ----------------------------------------------------------------------
export const mailActions = { ...mailSlice.actions, ...mailSagaActions };
export default mailSlice.reducer;
