import { DriveEntryTypes } from "src/common";

export interface SearchNodeBase {
    id: string;
    collection_id: string;
    parent_id: string;
    name: string;
    type: DriveEntryTypes;
    last_modification_date: string;
    name_tokens: string[];
}

export class SearchNode implements SearchNodeBase {
    id!: string;
    collection_id!: string;
    parent_id!: string;
    name!: string;
    type!: DriveEntryTypes;
    last_modification_date!: string;
    name_tokens!: string[];
    constructor(search_node: SearchNodeBase) {
        this.id = search_node.id;
        this.collection_id = search_node.collection_id;
        this.parent_id = search_node.parent_id || "";
        this.name = search_node.name;
        this.name_tokens = search_node.name_tokens;
        this.last_modification_date = search_node.last_modification_date;
        this.type = search_node.type;
    }
}

export interface SearchBase {
    id: string;
    collection_id: string;
    sequence: number;
    cursor_sequence: number;
    cursor_sub_sequence: number;
    has_more: number; // Dexie does not index types: boolean (https://dexie.org/docs/Version/Version.stores()#indexable-types)
}

export interface SearchPagination {
    id: string;
    collection_id: string;
    page: number;
}

