import React from "react";
import { Tabs, Tab } from "react-bootstrap";

function TabsComponent() {

  // Description: Tabs
  const BasicTabs = () => {
    return (
      <>
        <h4>TABS</h4>
        <Tabs
          defaultActiveKey="profile"
          id="uncontrolled-tab-example">
          <Tab eventKey="home" title="Home">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
              condimentum pellentesque sem, quis ullamcorper nunc interdum
              vehicula. Vivamus ut luctus mi. Quisque a varius leo. Duis
              condimentum sapien sed congue bibendum. Sed semper aliquam
              magna eu auctor. Donec fringilla suscipit est ut ullamcorper.
              Vivamus at rutrum quam, non ultrices erat.
            </p>
          </Tab>
          <Tab eventKey="profile" title="Profile">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
              condimentum pellentesque sem, quis ullamcorper nunc interdum
              vehicula. Vivamus ut luctus mi. Quisque a varius leo. Duis
              condimentum sapien sed congue bibendum. Sed semper aliquam
              magna eu auctor. Donec fringilla suscipit est ut ullamcorper.
              Vivamus at rutrum quam, non ultrices erat.
            </p>
          </Tab>
          <Tab eventKey="contact" title="Contact" disabled>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
              condimentum pellentesque sem, quis ullamcorper nunc interdum
              vehicula. Vivamus ut luctus mi. Quisque a varius leo. Duis
              condimentum sapien sed congue bibendum. Sed semper aliquam
              magna eu auctor. Donec fringilla suscipit est ut ullamcorper.
              Vivamus at rutrum quam, non ultrices erat.
            </p>
          </Tab>
        </Tabs>
      </>
    );
  };
  return <BasicTabs />;
};

export default React.memo(TabsComponent);
