import { UserProfile, Thread, CryptoThreadItem, ThreadMessage, GroupEmailBase, UserIdentifierBase } from "@preveil-api";
import { formatShortTimestamp, snippetFromBody, MailThreadMessage, MailFlags, formatSnippet, MailMessages, isSameUser, GlobalConstants } from "src/common";
import _ from "lodash";

export class MailThread implements Thread {
  attachment_count: number = 0;
  date: string = ""; // original date (Crypto) send or timestamp (CS)
  draft: boolean = false;
  deleted: boolean = false;
  mailbox_name?: string;
  message_count: number = 0;
  sender: UserProfile = { address: "", name: null };
  snippet: string = "";
  starred: boolean = false;
  subject: string = "";
  thread_id: string = "";
  unread: boolean = false;
  url_identifier: string = "";
  messages: ThreadMessage[] = [];
  thread_users: UserProfile[];  // NOTE: Keep a list of emails in the thread and all messages
  thread_sender_list: UserProfile[];  // NOTE: Keep a list of emails in the thread and all messages
  _timestamp: string;
  constructor(thread: CryptoThreadItem) {
    this.mailbox_name = thread.mailbox_name.length ? thread.mailbox_name : undefined;
    this.message_count = thread.messages.length;
    this.unread = this._isUnread(thread.messages);
    this.starred = this._isStarred(thread.messages);
    this.thread_id = thread.thread_id;
    this.messages = thread.messages;
    if (thread.messages.length > 0) {
      const _most_recent_message: ThreadMessage = thread.messages[0];
      const _original_message = this._getOriginalMessage();
      this._timestamp = _most_recent_message.date;
      this.date = formatShortTimestamp(_most_recent_message.date);
      this.sender = _original_message.sender;
      this.subject = _original_message.subject || MailMessages.message_no_subject; // Legacy: thread.getBaseSubject()
      this.snippet = this._getSnippet(_most_recent_message, thread.messages.length > 1 ? thread.messages[1] : null);
      this.attachment_count = this._hasAttachments(_most_recent_message);
      this.url_identifier = encodeURIComponent(this.thread_id);  //  Helpers.getURLHashFromUID(this.thread_id);
      this.thread_users = this._getAllThreadProfiles(thread.messages);
      this.thread_sender_list = this._getSenderProfiles(thread.messages);
    } else {
      this._timestamp = "";
      this.thread_users = [];
      this.thread_sender_list = [];
    }
  }

  // NOTE: Do not return messages for store 
  get thread(): Thread {
    return {
      attachment_count: this.attachment_count,
      date: this.date,
      draft: this.draft,
      deleted: this.deleted,
      mailbox_name: this.mailbox_name,
      message_count: this.message_count,
      messages: this.messages,
      sender: this.sender,
      snippet: this.snippet,
      starred: this.starred,
      subject: this.subject,
      thread_id: this.thread_id,
      unread: this.unread,
      url_identifier: this.url_identifier,
      thread_users: this.thread_users,
      thread_sender_list: this.thread_sender_list,
      _timestamp: this._timestamp
    };
  };

  get short_timestamp(): string {
    return this.date;
  }

  // Description: flag that the most current message has attachments
  private _hasAttachments(_most_recent_message: ThreadMessage): number {
    const _attachment = _most_recent_message?.attachments;
    return !!_attachment && Array.isArray(_attachment) ? _attachment.length : 0;
  }

  // Description: Get snippet from server or from text field
  private _getSnippet(msg: ThreadMessage, previous: ThreadMessage | null): string {
    return !!msg.snippet ? formatSnippet(msg.snippet, previous) : !!msg.text ? snippetFromBody(msg.text) : "";
  }

  // Description: Returns the Subject of the very first message
  private _getOriginalMessage(): ThreadMessage {
    return this.messages[this.messages.length - 1];
  }

  // Description: Find Flags with the "\\Flagged" "\\Seen" - Others avail: "\\Deleted" or "\\Draft"
  private _isUnread(messages: ThreadMessage[]): boolean {
    return MailThread.isUnread(messages);
  };

  // Description: Find Flags with the "\flagged"
  private _isStarred(messages: ThreadMessage[]): boolean {
    return _.findIndex(messages, (message) => (MailThreadMessage.isStarred(message))) >= 0;
  };

  private _getSenderProfiles(messages: ThreadMessage[]): UserProfile[] {
    const userIds = _.map(messages, (message: ThreadMessage) => (message.sender));
    return _.uniqBy(userIds, "address");
  }

  // Description: Collect all thread people in string[]: Includes "sender", "tos", "ccs", "bccs", "tos_groups", "ccs_groups"
  private _getAllThreadProfiles(messages: ThreadMessage[]): UserProfile[] {
    const group_arr: UserProfile[] = [];
    const userIds = _.map(messages, (message: ThreadMessage, i: number) => {
      const recipient_groups = _.pick(message, "tos", "ccs", "bccs", "tos_groups", "ccs_groups");
      // NOTE: make 1 array to iterate all groups at once
      const groups = _.flatten([recipient_groups.tos_groups, recipient_groups.ccs_groups]);
      if (!!groups && groups.length > 0) {
        _.map(groups, (group?: GroupEmailBase | null) => {
          !!group && group_arr.push({ address: group.alias.toLowerCase(), name: group.alias });
          return (!!group?.users && group.users.length > 0) &&
            _.map(group.users, (_user: UserIdentifierBase) =>
              (!!_user.user_id && group_arr.push({ address: _user.user_id.toLowerCase(), name: _user.user_id })));
        });
      }
      const recipients = [recipient_groups.tos, recipient_groups.ccs, recipient_groups.bccs, [message.sender]];
      return _.flatten([...recipients, ...group_arr]);
    });
    return _.uniqBy(_.flatten(userIds), "address");
  }

  // Static
  // -------------------------------------------------------------------------------------------------
  // Description: Get UniqueIds for body calls
  static getUniqueIds(messages: ThreadMessage[]): string[] {
    return _.map(messages, (message: ThreadMessage) => message.unique_id);
  }

  // Description: Get UniqueIds for body calls
  static getMessage(unique_id: string, messages: ThreadMessage[]): ThreadMessage | undefined {
    return _.find(messages, (message: ThreadMessage) => message.unique_id === unique_id);
  }

  // Description: Retrieve all user Ids in a string[]
  static getUserIdsFromThreads(threads: Thread[], current_id?: string): string[] {
    const _profiles = MailThread.getUserProfilesFromThreads(threads, current_id);
    return _profiles.map(((profile: UserProfile) => !!profile.external_email ? profile.external_email : profile.address));
  }

  // Description: Retrieve all user Profiles from all threads and thread messages
  static getUserProfilesFromThreads(threads: Thread[], current_id?: string): UserProfile[] {
    const thread_email_arrays = _.map(threads, (threads: Thread, i: number) => _.pick(threads, "thread_users").thread_users);
    const thread_email = _.uniqBy(_.flatten(thread_email_arrays), "address");
    current_id && _.remove(thread_email, (profile: UserProfile) => isSameUser(profile.address, current_id));
    return thread_email;
  }

  // Description: Display people in conversation row abbreviated for Mail List (different mailboxes)
  static getDisplaySendersField(users: UserProfile[], user_id: string, remove_self: boolean = false) {
    const [user_list, self_list] = _.partition(users.slice(), user => remove_self ? !isSameUser(user.address, user_id) : true);
    self_list.length > 1 && user_list.push(self_list[0]);
    let others_count: number = 0;
    let tooltip = user_list.slice();
    if (user_list.length > GlobalConstants.MAX_MAIL_SENDERS_PER_ROW) {
      others_count = user_list.length - GlobalConstants.MAX_MAIL_SENDERS_PER_ROW;
      tooltip = tooltip.slice(GlobalConstants.MAX_MAIL_SENDERS_PER_ROW, tooltip.length);
      user_list.splice(GlobalConstants.MAX_MAIL_SENDERS_PER_ROW);
    }
    const others_tooltip = _.map(tooltip, (tt: UserProfile) => (!!tt.external_email ? tt.external_email : tt.address)).join(", ");
    const others_string = others_count > 0 ? `, and ${others_count} other${others_count > 1 ? "s" : ""}...` : null;

    return { user_list, others_string, others_tooltip };
  }

  // Description: Find Flags with the "\\Flagged" "\\Seen" - Others avail: "\\Deleted" or "\\Draft"
  static isUnread(messages: ThreadMessage[]): boolean {
    const seen = _.filter(messages, (message) => {
      return message.flags.includes(MailFlags.SEEN_FLAG) || message.flags.includes(MailFlags.CS_SEEN_FLAG);
    });
    return seen.length !== messages.length;
  };

  // Description: Filter out messages with the "\\Flagged" "\\Seen" flags
  static unseenMessages(messages: ThreadMessage[]): ThreadMessage[] {
    return _.filter(messages, (message) =>
      !message.flags.includes(MailFlags.SEEN_FLAG) && !message.flags.includes(MailFlags.CS_SEEN_FLAG)
    );
  }
}
