import * as React from "react";
import { MessageItem } from "@preveil-api";
import { MessageHandlerDisplayType, MessageToastTypes } from "src/common";
import { UIMessageModal, Toastr, InlineMessage } from "src/components";

interface AllProps {
    message_item: MessageItem;
    onMessageHandlerDismiss: () => void;
}

function MessageHandlerComponent(props: AllProps) {
    const { message_item, onMessageHandlerDismiss } = props;
    function MessageByType() {
        switch (message_item.displayType) {
            case MessageHandlerDisplayType.toastr:
                return <Toastr message_item={message_item} onMessageDismiss={onMessageHandlerDismiss} />;
            case MessageHandlerDisplayType.inline:
                // NOTES ALERTS: needs more work to insert in the correct target container ***** Working
                return <InlineMessage message_item={message_item} onMessageDismiss={onMessageHandlerDismiss} />;
            case MessageHandlerDisplayType.confirm:
            case MessageHandlerDisplayType.dialog:
                return <UIMessageModal
                    message_item={message_item}
                    onModalDismiss={onMessageHandlerDismiss} />;
            default:
                const datetime = `time:${new Date().toLocaleString()}`;
                message_item.type === MessageToastTypes.error ?
                    console.error("%cPreveil Logger: ", "color:black; ; font-size: 16px; font-weight: bold;", message_item.message, datetime) :
                    console.log("%cPreveil Logger: ", "color:black; ; font-size: 16px; font-weight: bold;", message_item.message, datetime);
                return <></>;
        }
    }
    return <MessageByType />;
}

export default React.memo(MessageHandlerComponent);
