import React from "react";
import { Breadcrumb } from "react-bootstrap";

function BreadcrumbsComponent() {

  // Description: Breadcrumbs
  const Breadcrumbs = () => {
    return (
      <>
        <h4>BREADCRUMBS</h4>
        <Breadcrumb>
          <Breadcrumb.Item href="#">My PreVeil</Breadcrumb.Item>
          <Breadcrumb.Item href="#">Engineering</Breadcrumb.Item>
          <Breadcrumb.Item href="#">Chalk Talks</Breadcrumb.Item>
        </Breadcrumb>
        <Breadcrumb>
          <Breadcrumb.Item href="#">My PreVeil</Breadcrumb.Item>
          <Breadcrumb.Item href="#">longlonglonglonglonglonglonglongname</Breadcrumb.Item>
          <Breadcrumb.Item href="#">Chalk Talks</Breadcrumb.Item>
        </Breadcrumb>
      </>
    );
  };

  return <Breadcrumbs />;
};

export default React.memo(BreadcrumbsComponent);
