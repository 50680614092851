import { useState, useCallback } from "react";
import { MessageDisplayType, ErrorStackDataType } from "@preveil-api";
import { FSMessage, FSStatus } from "src/common/keys/protos/collections_pb";
import {
  Account, useAppDispatch, Message, MessageHandlerDisplayType, useSendRequestMutation, CollectionFilesyncAPI, DriveErrorMessages,
  DriveRequest, getEnumKey, TrashErrorMessages, ErrorStackItem
} from "src/common";
import { DriveCallbackAsyncFunction, uiActions } from "src/store";

/* Description: This is the web hook for emptying trash. */
export function useEmptyTrash(current_account: Account) {
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [sendRequest] = useSendRequestMutation();
  const dispatch = useAppDispatch();

  // Description: empty all items in trash through EMPTY_TRASH call
  async function emptyTrashItems(default_collection_id: string) {
    const drive_request = await CollectionFilesyncAPI.emptyTrash(current_account, default_collection_id);
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        setSuccess(true);
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: TrashErrorMessages.error_emptying_trash }, message);
      }
    }
    handleSendRequest(drive_request, callback, TrashErrorMessages.error_emptying_trash);
  }

  // Description: Sends Request and handles error when the request object is null, pass it custom stack message
  function handleSendRequest<T>(request: DriveRequest | null, callback: DriveCallbackAsyncFunction<T>, stack_error: string = DriveErrorMessages.error_sending_request) {
    if (!!request) {
      request.setCallback(callback);
      sendRequest(request);
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, { stack_error, stack_message: DriveErrorMessages.error_sending_request });
    }
  }

  // Description: Handle error message and send error to store
  function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, fsmessage?: FSMessage | null,
    display_type: MessageDisplayType = MessageHandlerDisplayType.logger) {
    const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
    const stack = new ErrorStackItem("[useEmptyTrash Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
    dispatch(uiActions.handleRequestErrors(new Message(message, display_type), stack));
    setError(true);
  }

  // Description: Callback for empty trash
  const emptyTrash = useCallback((default_collection_id: string) => {
    emptyTrashItems(default_collection_id);
  },
    [],
  );

  return {
    emptyTrash,
    error,
    success
  };
}
