import * as React from "react";
import { Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { AccountUIActionTypes, SupportRoutes } from "src/common";

type AllProps = {
  handleAction: ActionHandlerFunction;
}

function ErrorPanelComponent(props: AllProps) {
  const { handleAction } = props;

  return <>
    <h1 className="border-bottom pb-3">Recovery Group Unavailable</h1>
    <p>PreVeil was unable to process the recovery request.</p>

    <p>If you have set a recovery group, try again later. Otherwise
      you will not be able to access a recovery group.</p>

    <p>Learn more here: <a target="_blank" rel="noreferrer"
      href={`${SupportRoutes.account_recovery_requirements}`} >Configure Recovery Group Settings</a>.</p>
    <Button onClick={() => handleAction({ actionType: AccountUIActionTypes.Destroy })}>Retry</Button>
  </>;
}
export default React.memo(ErrorPanelComponent);
