import { useState, useCallback, useEffect, useRef } from "react";
import { MessageDisplayType, ErrorStackDataType } from "@preveil-api";
import { FSMessage, FSStatus } from "src/common/keys/protos/collections_pb";
import {
  Account, useAppDispatch, Message, MessageHandlerDisplayType, useSendRequestMutation, CollectionFilesyncAPI, DriveErrorMessages,
  useGetGrants, useAppSelector, DriveRequest, GrantSet, PermissionSetType, Directory, DirectoryPaginationInfo, Grant, DirectoryEntity,
  getEnumKey, useGetPermissions, GrantSetType, useGetCollectionInfo, DriveEntryType, EntryItemBase, useBrowseDirectoryPaginatedMutation,
  DriveDirectoryPaginated, ErrorStackItem
} from "src/common";
import { driveActions, DriveCallbackAsyncFunction, uiActions } from "src/store";
import { RootState } from "src/store/configureStore";
// import _ from "lodash";

/* Description: This is the web hook for browse directory. If we want to set current_directory on the state, you need to send true for the set_state variable 
otherwise it will just return the directory (for other cases like the move functionality, etc) */
export function useFetchDirectory(current_account: Account, set_state: boolean = false, use_filesync: boolean = false, include_deleted: boolean = false) {
  const default_permissions = useAppSelector((state: RootState) => state.drive.default_permissions);
  const default_grants = useAppSelector((state: RootState) => state.drive.default_grants);
  const [directory, setDirectory] = useState<DirectoryEntity>();
  const [pagination_info, setPaginationInfo] = useState<DirectoryPaginationInfo>();
  const [fs_directory, setFSDirectory] = useState<FSMessage.Dir>();
  const [collection_id, setCollectionId] = useState<string>("");
  const [directory_id, setDirectoryId] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [entry_base, setEntryBase] = useState<EntryItemBase | undefined>();
  const [sendRequest] = useSendRequestMutation();
  const { grants, error: grant_error, getGrants, destroyGrants } = useGetGrants(current_account, include_deleted);
  const { permissions, getPermissions, destroyPermissions } = useGetPermissions(current_account);
  const { collection_info_error } = useGetCollectionInfo(current_account, entry_base, set_state);
  const [browseDirectoryPaginated] = useBrowseDirectoryPaginatedMutation();
  const dispatch = useAppDispatch();

  const permissionsRef = useRef(permissions);
  function setPermissionsRef(_permissions?: PermissionSetType) {
    permissionsRef.current = _permissions;
  }

  // Description: Initialize fetch by type LINK, DIR or FILE (TBD)
  useEffect(() => {
    if (!!permissions) {
      setPermissionsRef(permissions);
      // NOTE: Return the permission state to undefined to be able to detect future chages
      destroyPermissions();
      use_filesync ?
        fetchMaintainer(collection_id, directory_id, permissionsRef.current, true) :
        handlefetchDirectory(collection_id, directory_id);
    }
  }, [permissions]);

  // Description: useEffect hook for when grants (from the useGetGrants hook) is initialized.
  useEffect(() => {
    if (!!grants && !!fs_directory && !!pagination_info && !!permissionsRef.current) {
      const key_version = fs_directory.getWrappedDirKey()?.getKeyVersion();
      const grant = GrantSet.getGrantbyRole(grants, key_version);
      !!grant && handleSuccess(collection_id, pagination_info, fs_directory, permissionsRef.current, grant, grants);
    }

    grant_error && handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_grant });
  }, [grants, grant_error]);

  // Description: useEffect hook for when grants (from the useGetGrants hook) is initialized.
  useEffect(() => {
    !!collection_info_error && handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_collection_information }, null, MessageHandlerDisplayType.toastr);
  }, [collection_info_error]);

  // WEB - Filesync WS to CS
  // -----------------------------
  // Description: Fetches the dir and checks whether it is an acl node, inside a maintainer or v2 collection or a regular directory
  // and calls the grants respectively. 
  async function handlefetchDirectory(_collection_id: string, id: string) {
    const request = !!permissionsRef.current ? await CollectionFilesyncAPI.fetchDirectoryRequest(_collection_id, id, 0, true, true, true, include_deleted) : null;
    const drive_request = !!request ? await DriveRequest.initializeRequest(request, current_account, permissionsRef.current) : null;
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        const dir = message.getDir();
        if (!!dir && !!permissionsRef.current) {
          setFSDirectory(dir);
          const pagination_info: DirectoryPaginationInfo = {
            total_count: message.getTotalCount() || dir?.getEntriesList().length,
            has_more: message.getHasMore(),
            next_seq: message.getNextSeq(),
            next_subseq: message.getNextSubseq(),
          };
          setPaginationInfo(pagination_info);
          const maintainer_id = dir.hasMaintainerId() ? dir.getMaintainerId_asB64() : undefined;
          // NOTE: get Nodepermissions for lists - Get Collection Info for listview
          set_state && setEntryBase({
            linked_collection_id: "",
            maintainer_id,
            collection_id: _collection_id,
            id,
            type: DriveEntryType.DIR
          });

          if (!!maintainer_id) { // NOTE: This is a V2 collection or an ACL node (belongs to a V2 collection)
            if (dir.getId_asB64() === maintainer_id) { // NOTE: Self mantainer node
              getGrants(dir.getId_asB64(), _collection_id, default_permissions, default_grants);
            } else {
              fetchMaintainer(_collection_id, dir.getMaintainerId_asB64(), permissionsRef.current);
            }
          } else { // NOTE: Regular DIR Nodes calling FETCH_DIR or V1 Collection Dir
            handleSuccess(_collection_id, pagination_info, dir, permissionsRef.current);
          }
        } else {
          handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_directory }, null, MessageHandlerDisplayType.toastr);
        }
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_directory }, message);
      }
    }
    handleSendRequest(drive_request, callback, DriveErrorMessages.error_fetching_directory);
  }

  // Description: If the dir has a maintainer node (v2) then it needs to fetch dir for that maintainer id and get the grants for that directory (to get the appropriate user keys from ACL_KEY_HISTORY)
  async function fetchMaintainer(_collection_id: string, maintainer_id: string, _permissions?: PermissionSetType, is_app: boolean = false) {
    const request = await CollectionFilesyncAPI.getDirectoryInformationRequest(_collection_id, maintainer_id, include_deleted);
    const drive_request = await DriveRequest.initializeRequest(request, current_account, _permissions);
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        const maintainer_dir = message.getDir();
        if (is_app) {
          handlefetchDirectoryApp(_collection_id, maintainer_id, maintainer_dir);
        } else if (!!maintainer_dir) {
          getGrants(maintainer_dir.getId_asB64(), _collection_id, default_permissions, default_grants);
        } else {
          handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_acl_list }, message);
        }
      } else {
        // NOTE: if it fails but this is app, send the filesync call anyway without parent dir info
        is_app ? handlefetchDirectoryApp(_collection_id, maintainer_id) :
          handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_acl_list }, message);
      }
    }
    handleSendRequest(drive_request, callback, DriveErrorMessages.error_fetching_acl_list);
  }

  // APP - Filesync
  // -----------------------------
  async function handlefetchDirectoryApp(_collection_id: string, id: string, dir?: FSMessage.Dir) {
    browseDirectoryPaginated({
      user_id: current_account.user_id,
      collection_id: _collection_id,
      directory_id: id,
      in_trash: include_deleted
    })
      .unwrap()
      .then((response: DriveDirectoryPaginated) => {
        const directory_entity = Directory.parseFSDirectory(response, { collection_id: _collection_id, id }, dir);
        handleAppSucccess(response, directory_entity);
      })
      .catch((stack_error: unknown) => {
        handlePageErrorMessage(DriveErrorMessages.default,
          {
            stack_message: DriveErrorMessages.error_fetching_fs_directory_paginated,
            stack_error
          }, null, MessageHandlerDisplayType.toastr);
      });
  }

  // Descriptions: Handle success from Filesync call to FE
  function handleAppSucccess(response: DriveDirectoryPaginated, directory_entity: DirectoryEntity) {
    // NOTE: Handle pagination
    const pagination_info: DirectoryPaginationInfo = {
      total_count: response.total_count,
      has_more: response.next_subseq > response.next_seq,
      next_seq: response.next_seq,
      next_subseq: response.next_subseq
    };
    setPaginationInfo(pagination_info);

    // NOTE: get Nodepermissions for lists - Get Collection Info for listview
    set_state && setEntryBase({
      linked_collection_id: "",
      collection_id: directory_entity.collection_id,
      id: directory_entity.id,
      type: DriveEntryType.DIR
    });

    if (!!set_state) {
      dispatch(driveActions.setPaginationInfo(pagination_info));
      dispatch(driveActions.browseDirectorySuccess(directory_entity));
    }
    setDirectory(directory_entity);
  }

  // -----------------------------
  // HOOK General
  // -----------------------------
  // Description: Initializes a new Directory, if set_state is true, we dispatch to state, otherwise we just setDirectory to be used in cases
  // such as MOVE or places where we just want the directory but not necessarily for it to be set in the state.
  async function handleSuccess(_collection_id: string, pagination_info: DirectoryPaginationInfo, dir: FSMessage.Dir,
    _permission_set: PermissionSetType, grant?: Grant, grants?: GrantSetType) {
    const directory = !!dir ? await Directory.init(_collection_id, dir, current_account, _permission_set, grant, grants) : null;
    if (!!directory && !!pagination_info) {
      if (!!set_state) {
        dispatch(driveActions.setPaginationInfo(pagination_info));
        dispatch(driveActions.browseDirectorySuccess(directory.item));
      }
      setDirectory(directory.item);
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_decrypting_entries }, null, MessageHandlerDisplayType.toastr);
    }
  }

  // Description: handles error when the request object is null
  function handleSendRequest<T>(request: DriveRequest | null, callback: DriveCallbackAsyncFunction<T>, stack_error: string = DriveErrorMessages.error_sending_request) {
    if (!!request) {
      request.setCallback(callback);
      sendRequest(request);
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, { stack_error, stack_message: DriveErrorMessages.error_sending_request });
    }
  }

  // Description: Handle error message and send error to store
  function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, fsmessage?: FSMessage | null,
    display_type: MessageDisplayType = MessageHandlerDisplayType.toastr) {
    const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
    const stack = new ErrorStackItem("[useFetchDirectory Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
    dispatch(uiActions.handleRequestErrors(new Message(message, display_type), stack));
    setError(true);
  }

  // Description: Callback for browse directory
  const fetchDirectory = useCallback((collection_id: string, id: string) => {
    getPermissions(collection_id, default_permissions);
    setCollectionId(collection_id);
    setDirectoryId(id);
    // if (use_filesync) {
    //   handlefetchDirectoryApp(collection_id, id);
    //   // getPermissions(collection_id, default_permissions);
    // } else {
    //   getPermissions(collection_id, default_permissions);
    //   setCollectionId(collection_id);
    //   setDirectoryId(id);
    // }
  }, []);

  // Description: reset hook 
  const resetDirectory = useCallback(() => {
    setError(false);
    setPaginationInfo(undefined);
    destroyGrants();
  }, []);

  return {
    directory,
    pagination_info,
    fetchDirectory,
    resetDirectory,
    error
  };
}