import React from "react";
import { UserProfile } from "@preveil-api";
import { DataExportApproversRow } from "src/components/admin/data-exports"; // TODO: Move this to shared folder.
import { UserRequest, dayjs } from "src/common";

type AllProps = {
  userRequest: UserRequest;
}

type Request = {
  users: Array<{
    user_id: string;
    key_version: number;
  }>;
  until: string;
  from: string;
};

const exportContentLabels = {
  content: {
    email: "Email",
    drive: "Drive",
    log: "Logs",
    org_acl_report: "ACL Report"
  },
};

function DataExportDetails({ userRequest }: AllProps) {
  const { parsed_payload, requester, expiration, timestamp: created_time, export_content, status } = userRequest;
  const { from, until, users: usersFromRequest } = parsed_payload.data as Request;
  const { name: requesterName } = requester as UserProfile;
  const contentFromRequest = export_content && Object.keys(export_content).filter((key) => (export_content as any)[key]).map((key) => {
    const _key = key.split("include_")[1];
    return (exportContentLabels.content as any)[_key];
  }).filter((value) => !!value).join(" & ");
  const time_started = dayjs(from).format("MM/DD/YYYY");
  const time_ended = dayjs(until).format("MM/DD/YYYY");
  const timeframe = `${time_started} - ${time_ended}`;
  return (
    <section>
      <div className="readonly-details">
        <p><span>Requester</span> {requesterName}</p>
        <p><span>Status</span> {status}</p>
        <p><span>Content</span> {contentFromRequest}</p>
        <p><span>Timeframe</span> {timeframe}</p>
        <p><span>Created</span> {created_time}</p>
        <p><span>Expires</span> {expiration}</p>
      </div>
      <div className="recovery-groups-card settings-export-members-container">
        <header>
          <p className="m-0">{`Users: ${usersFromRequest?.length}`}</p>
        </header>
        <div className="export-request-members-container">
          {
            usersFromRequest && usersFromRequest.length > 0 && usersFromRequest.map((user, index) => {
              // Note: These are the users that are part of the export request. We only get the user_id from the user object.
              if (!user || !user.user_id) {
                return null;
              }
              const key = user?.user_id;
              return <DataExportApproversRow key={key} is_read_only user={user} />;
            })
          }
        </div>
      </div>
    </section>
  );
}

export default React.memo(DataExportDetails);