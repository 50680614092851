import * as React from "react";
import { Link } from "react-router-dom";
import { Badge } from "react-bootstrap";
import { AppConfiguration, useAppSelector, PrivateRoutes, DefaultMailboxes, MailboxIcons, GlobalConstants } from "src/common";
import { Icon } from "src/components";
import { RootState } from "src/store/configureStore";

type AllProps = {
  active_path?: string;
}

function DefaultMailboxesComponent(props: AllProps) {
  const { active_path } = props;
  const isWeb = AppConfiguration.buildForWeb();
  const unread = useAppSelector((state: RootState) => state.mail.mailboxes_counts?.unseen) || 0;
  const starred = useAppSelector((state: RootState) => state.mail.mailboxes_counts?.starred) || 0;
  const drafts = useAppSelector((state: RootState) => state.mail.mailboxes_counts?.draft) || 0;

  return <>
    <Link title="My Mail Inbox"
      className={`nav-link${active_path === DefaultMailboxes.inbox ? " active" : ""}`}
      to={`/${PrivateRoutes.mail_route}/${DefaultMailboxes.inbox}/1`} >
      <Icon className={MailboxIcons.inbox} />
      <span>Inbox</span>
      {
        (unread > 0) &&
        <Badge bg="light" className={unread > GlobalConstants.MAX_MOBILE_MAIL_COUNT ? "exceed_limit" : ""}>{unread}</Badge>
      }
    </Link>
    {
      // TODO: We will develop a call to CS for web to get ClientDefaultMailboxesType:  ["outbox", "starred", "unread", "search"];
      !isWeb &&
      <Link title="My Mail Starred"
        className={`nav-link${active_path === DefaultMailboxes.starred ? " active" : ""}`}
        to={`/${PrivateRoutes.mail_route}/${DefaultMailboxes.starred}/1`}  >
        <Icon className={MailboxIcons.starred} />
        <span>Starred</span>
        {
          (starred > 0) &&
          <Badge bg="light" className={starred > GlobalConstants.MAX_MOBILE_MAIL_COUNT ? "exceed_limit" : ""}>{starred}</Badge>
        }
      </Link>
    }
    <Link title="My Mail Drafts"
      className={`nav-link${active_path === DefaultMailboxes.drafts ? " active" : ""}`}
      to={`/${PrivateRoutes.mail_route}/${DefaultMailboxes.drafts}/1`}>
      <Icon className={MailboxIcons.drafts} />
      <span>Drafts</span>
      {
        (drafts > 0) &&
        <Badge bg="light" className={drafts > GlobalConstants.MAX_MOBILE_MAIL_COUNT ? "exceed_limit" : ""}>{drafts}</Badge>
      }
    </Link>
    <Link title="My Mail Sent"
      className={`nav-link${active_path === DefaultMailboxes.sent ? " active" : ""}`}
      to={`/${PrivateRoutes.mail_route}/${DefaultMailboxes.sent}/1`}>
      <Icon className={MailboxIcons.sent} />
      <span>Sent</span>
    </Link>
    <Link title="My Mail Trash"
      className={`nav-link${active_path === DefaultMailboxes.trash ? " active" : ""}`}
      to={`/${PrivateRoutes.mail_route}/${DefaultMailboxes.trash}/1`} >
      <Icon className={MailboxIcons.trash} />
      <span>Trash</span>
    </Link>
    {
      // TODO: We will develop a call to CS for web to get ClientDefaultMailboxesType:  ["outbox", "starred", "unread", "search"];
      !isWeb &&
      <Link title="My Unread Mail"
        className={`nav-link${active_path === DefaultMailboxes.unread ? " active" : ""}${unread <= 0 ? " d-none" : ""}`}
        to={`/${PrivateRoutes.mail_route}/${DefaultMailboxes.unread}/1`} >
        <Icon className={MailboxIcons.unread} />
        <span>Unread</span>
        {
          (!!unread && unread > 0) &&
          <Badge bg="light" className={drafts > GlobalConstants.MAX_MOBILE_MAIL_COUNT ? "exceed_limit" : ""}>{unread}</Badge>
        }
      </Link>
    }
    {/* Note: Outbox will not make an apperance on phase I, it will be delegated for a later upgrade */}
    {/* TODO: We will develop a call to CS for web to get ClientDefaultMailboxesType:  ["outbox", "starred", "unread", "search"]; */}
    {/*   {!isWeb && <Link title="Mail Outbox"
      className={`nav-link${active_path === DefaultMailboxes.outbox ? " active" : ""}`}
      to={`/${PrivateRoutes.mail_route}/${DefaultMailboxes.outbox}/1`} >
      <Icon className={MailboxIcons.outbox} />
      <span>Outbox</span>
    </Link> } */}
  </>;
}

export default React.memo(DefaultMailboxesComponent);
