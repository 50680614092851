import {
  ThreadMessage, UserProfile, ThreadFlagsType, UserIdentifierBase, UserContact, ThreadAttachment, GroupEmailBase, JSONRequestBlock,
  ClientThreadMessage, CryptoAttachment
} from "@preveil-api";
import { SymmKey } from "src/common/keys";
import {
  DecryptedEmail, DecryptedEmailV4, DecryptedEmailLatest, AttachmentMetadata, Attachment, formatLongTimestamp, MailFlags, dayjs, RegexHelper,
  DefaultMailboxes, PrivateMetadataLatest
} from "src/common";
import _ from "lodash";

const ORIGINAL_SENDER_KEY = "X-PV-MIME-SENDER";
const ORIGINAL_DATE_KEY = "X-PV-MIME-DATE";

// Replaces class Email - returns a common class from Collection and Crypto returned models
export class MailThreadMessage implements ThreadMessage {
  attachments: ThreadAttachment[] | [] = []; // retrieve from private_metadata for CS 
  bccs: UserProfile[] = [];
  ccs: UserProfile[] = [];
  date: string = ""; // Note: "timestamp" in CS and "date" in crypto
  flags: ThreadFlagsType[] = [];
  in_reply_to: string | null = null;
  is_local: boolean = false; // used as label in mail viewer - set to false in CS
  mailbox_id: string = "";
  mailbox_name: string | null = null;
  message_id: string = "";
  other_headers: { [key: string]: unknown } = {};
  protocol_version: number = 0;
  references: string[] = [];
  reply_to?: UserProfile[];
  rev_id: number = 0;
  sender: UserProfile = { address: "", name: null };
  snippet: string = "";
  subject: string = "";
  thread_id: string = "";
  tos: UserProfile[] = [];
  unique_id: string = "";
  uid: number = 0;
  // NOTES: html and text are for rendering mail view: NOTE: Crypto: Message body with markup: null in get threads but filled in get thread call
  html: string | null = null;
  text: string | null = null; // this is for rendering mail view
  // Additional to ThreadMessage
  inline_attachments: string[] = []; // Holds emails inline attachments 
  tos_groups?: GroupEmailBase[] | null; // This prop only applies to web protocol_version > 6
  ccs_groups?: GroupEmailBase[] | null; // This prop only applies to web protocol_version > 6
  body_ids: string[] = []; // Holds the  body.blocks.body_ids: string[]
  decryption_key?: SymmKey;
  version?: string;
  constructor(message: DecryptedEmail | null, client_message?: ClientThreadMessage) {
    if (!!message) {
      this.initWebMessage(message);
    } else if (!!client_message) {
      this.initClientMessage(client_message);
    } else {
      throw new Error("There was a problem initializing this thread");
    }
  }

  // Description:  Initialize MailThreadMessage class through ClientThreadMessage
  initClientMessage(message: ClientThreadMessage) {
    Object.assign(this, message);
    this.date = this._getDate(message.date);
    // Set attachments:
    message.attachments.length > 0 && this._getClientAttachments(message.attachments);
    this.protocol_version = -1;
  }

  // Description: Initialize MailThreadMessage class through DecryptedEmail message as input
  initWebMessage(message: DecryptedEmail) {
    const decrypted_metadata = message.decrypted_metadata;
    this.other_headers = decrypted_metadata.other_headers;
    this.date = this._getDate(message.timestamp);
    this.subject = decrypted_metadata.subject;
    this.sender = this._getSender(decrypted_metadata.sender, (decrypted_metadata as PrivateMetadataLatest).external_sender);
    this.flags = message.flags;
    this.is_local = false;
    this.mailbox_id = message.mailbox_id;
    this.message_id = message.message_id;
    this.references = message.references;
    this.in_reply_to = message.in_reply_to || null;
    this.rev_id = message.rev_id;
    this.thread_id = message.thread_id;
    this.unique_id = message.id;
    this.uid = message.uid;
    this.decryption_key = message.decryption_key;
    this.version = message.version;
    this.protocol_version = message.protocol_version;
    // Set attachments:
    message.decrypted_attachments.length > 0 && this._getWebAttachments(message.decrypted_attachments);
    message.protocol_version > 4 ? this._initLatest(message as DecryptedEmailLatest) : this._initMessageV4(message as DecryptedEmailV4);
  }

  // Description: Returns flat object as ThreadMessage
  get ThreadMessage(): ThreadMessage {
    return {
      attachments: this.attachments,
      bccs: this.bccs,
      ccs: this.ccs,
      date: this.date,
      flags: this.flags,
      html: this.html,
      in_reply_to: this.in_reply_to,
      is_local: this.is_local,
      mailbox_id: this.mailbox_id,
      mailbox_name: this.mailbox_name,
      message_id: this.message_id,
      other_headers: this.other_headers,
      references: this.references,
      reply_to: this.reply_to,
      rev_id: this.rev_id,
      sender: this.sender,
      snippet: this.snippet,
      subject: this.subject,
      text: this.text,
      thread_id: this.thread_id,
      tos: this.tos,
      tos_groups: this.tos_groups,
      ccs_groups: this.ccs_groups,
      unique_id: this.unique_id,
      uid: this.uid,
      // Additional (web)
      protocol_version: this.protocol_version,
      body_ids: this.body_ids,
      inline_attachments: this.inline_attachments,
      decryption_key: this.decryption_key,
      version: this.version
    };
  }

  // Description: Parses the messge V4 and converts it to a ThreadMessage
  // NOTE: message.body: blocks: JSONBlockMeta[];  size: number; snippet: string; // V4
  private _initMessageV4(message: DecryptedEmailV4) {
    const metadata = message.decrypted_metadata;
    this.bccs = this._getUserProfiles(metadata.bccs);
    this.ccs = this._getUserProfiles(metadata.ccs);
    this.tos = this._getUserProfiles(metadata.tos);
    this.snippet = message.decrypted_snippet;
    this.body_ids = !!message.body.blocks ? message.body.blocks.map(b => b.id) : [];
  }

  // Description: Parses the messge V5-V7 and converts it to a ThreadMessage 
  // NOTE: decrypted_metadata.body: {block_ids: string[]; size: number; snippet: string;} // V5 and above
  private _initLatest(message: DecryptedEmailLatest) {
    const metadata = message.decrypted_metadata;
    this.bccs = this._getUserProfiles(message.bccs);
    this.ccs = this._getUserProfilesLatest(metadata.ccs);
    this.tos = this._getUserProfilesLatest(metadata.tos);
    this.tos_groups = metadata.tos_groups;
    this.ccs_groups = metadata.ccs_groups;
    this.snippet = metadata.body.snippet;
    this.body_ids = metadata.body.block_ids;
  }

  // Decription: Get Sender information from other_headers - only applies to web (need to check)
  private _getSender(sender: string, external_sender?: string | null): UserProfile {
    const user_contact = !!this.other_headers && this.other_headers[ORIGINAL_SENDER_KEY] ?
      this.other_headers[ORIGINAL_SENDER_KEY] as UserContact : undefined;
    return !!user_contact ?
      { address: user_contact.user_id, name: user_contact.display_name || user_contact.user_id, external_email: user_contact.external_email } :
      { address: sender, name: null, external_email: external_sender || null };
  }

  // Decription: Get Date from header if set up there
  private _getDate(timestamp: string): string {
    return !!this.other_headers && !!this.other_headers[ORIGINAL_DATE_KEY] ?
      this.other_headers[ORIGINAL_DATE_KEY].toString() : dayjs.utc(timestamp).local().toString();
  }

  // Description: Get UserProfile from Collection Server Messages V4
  private _getUserProfiles(users: string[]): UserProfile[] {
    return _.map(users, (user_id: string) => ({ address: user_id, name: null, external_email: null }));
  }

  // Description: Get UserProfile from Collection Server Messages V5-7
  private _getUserProfilesLatest(users: UserIdentifierBase[]): UserProfile[] {
    // console.log("_getUserProfilesLatest", users);
    return _.map(users, (user: UserIdentifierBase) => ({ address: user.user_id, name: null, external_email: user.external_email }));
  }

  // Description: Get Attachment from decrypted message (web)
  private _getWebAttachments(decrypted_attachments: AttachmentMetadata[]): void {
    const attachment_arr: Attachment[] = [];
    const thread_attachment: ThreadAttachment[] = _.map(decrypted_attachments, (attachment: AttachmentMetadata) => {
      const att = Attachment.fromJSONAttachment(attachment);
      attachment_arr.push(att);
      // Handles inline attachments: collect inline attachments in a separate array for later processing
      if (att.is_inline) {
        this.inline_attachments = this.inline_attachments.concat(...att.block_ids);
      }
      return att.threadAttachment;
    });
    this.attachments = thread_attachment;
  }

  // Description: Get Attachment from Client message ClientThreadMessage
  private _getClientAttachments(attachments: CryptoAttachment[]): void {
    const attachment_arr: Attachment[] = [];
    const thread_attachment: ThreadAttachment[] = _.map(attachments, (attachment: CryptoAttachment) => {
      const att = Attachment.fromCrypto(attachment);
      attachment_arr.push(att);
      // Handles inline attachments: collect inline attachments in a separate array for later processing
      if (att.is_inline) {
        this.inline_attachments = this.inline_attachments.concat(...att.block_ids); // this.inline_attachments.concat(att.threadAttachment);
      }
      return att.threadAttachment;
    });
    this.attachments = thread_attachment;
  }

  // ------------------------- Static
  static isUnread(message: ThreadMessage): boolean {
    return !message.flags.includes(MailFlags.CS_SEEN_FLAG) && !message.flags.includes(MailFlags.SEEN_FLAG);
  }
  // Description: Is message starred
  static isStarred(message: ThreadMessage): boolean {
    return message.flags.includes(MailFlags.STAR_FLAG) || message.flags.includes(MailFlags.CS_STAR_FLAG);
  }

  // Description: Is message a draft
  static isDraft(message: ThreadMessage): boolean {
    return message.flags.includes(MailFlags.DRAFT_FLAG);
  }

  // Description: Is message in sent mailbox
  static isSent(message: ThreadMessage): boolean {
    return message.mailbox_name === DefaultMailboxes.sent;
  }

  // Description: Retrieve Previous message
  static getPreviousMessage(message: ThreadMessage, messages: ThreadMessage[]): ThreadMessage | undefined {
    return _.find(messages, (msg: ThreadMessage) => msg.message_id === message.in_reply_to);
  }

  // Description: Collate all message body block IDs and Inline attachment for joint call to server 
  static collectAllBlocks(message: ThreadMessage, collection_id: string, include_attachments: boolean = false): JSONRequestBlock[] {
    // NOTE: Collect the message body ids
    let body_ids = message.body_ids.slice();
    if (include_attachments && message.attachments.length > 0) {
      const attachment_block_ids = _.flatten(_.map(message.attachments, (attachment: ThreadAttachment) => (attachment.content_reference_id.split(","))));
      body_ids = body_ids.concat(attachment_block_ids);
    } else if (!!message.inline_attachments && message.inline_attachments.length > 0) {
      body_ids = body_ids.concat(message.inline_attachments);
    }
    return _.map(body_ids, (block_id: string) => {
      return { collection_id, block_id };
    });
  }

  // Description: Build the Previous email message body
  static buildPreviousMailBody(message: ThreadMessage, filter_links: boolean = true) {
    const display_address = message.sender.address;
    const attachments_footprint = message.attachments.length ? Attachment.getAttachmentsFootprint(message.attachments) : "";
    let header = `On ${formatLongTimestamp(message.date)}, ${!!message.sender.name ? message.sender.name : ""}&lt;<a href="mailto:${display_address}">${display_address}</a>&gt; wrote:`;
    header = filter_links ? RegexHelper.filterLinks(header) : header;
    // if filter_links === false, this is compose and should remove the #external_link and replaced with the correct link
    const _body = message.message_body || message.snippet;
    const body = filter_links ? _body : RegexHelper.removeExternalLinks(_body);
    const compose_email = `
          <blockquote class="previous_mail">
              <h4>
                ${header}
              </h4>
              <div class="previous_mail_body">
              ${body}${attachments_footprint}
              <div>
          </blockquote>`;
    return compose_email;
  }

  static updateAttachments(message: ThreadMessage, attachments: ThreadAttachment[]): ThreadMessage {
    const new_attachments: Record<string, ThreadAttachment> = {};
    for (const attachment of message.attachments) {
      new_attachments[attachment.content_reference_id] = attachment;
    }
    const final_attachments = attachments.slice();
    attachments.forEach((attachment, index) => {
      if (attachment.content_reference_id in new_attachments) {
        final_attachments[index] = new_attachments[attachment.content_reference_id];
      }
    });
    return { ...message, attachments: final_attachments };
  }
}
