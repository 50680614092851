import * as React from "react";
import { Icon } from "src/components";

type AllProps = {
  message: string;
  className?: string;
}

function EmptyViewComponent(props: AllProps) {
  const { message, className } = props;

  return (
    <div className={`list-messages ${className}`}>
      <div className="center-vertical">
        <Icon className="pv-icon-mailbox-empty" />
        <p>{message}</p>
      </div>
    </div>
  );
}

export default React.memo(EmptyViewComponent);
