import React, { useEffect, useState } from "react";
import { ActionHandlerFunction } from "@preveil-api";
import {
  Account, ComposeMailMessage, CollectionKeyValCodes, useGetUsersKeyvalQuery, AccountUIActionTypes, SettingsErrorMessages,
  ComposeModalSizeType
} from "src/common";
import ComposeMailView from "./ComposeMail.component";
import _ from "lodash";

type AllProps = {
  current_account: Account;
  compose_messages: ComposeMailMessage[];
  handleAction: ActionHandlerFunction;
}

function ComposeMailListComponent(props: AllProps) {
  const { current_account, compose_messages, handleAction } = props;
  const [signature, setSignature] = useState<string>();
  const [arr, setSizeArr] = useState<ComposeModalSizeType[]>([]);
  const { data, error } = useGetUsersKeyvalQuery({
    account_ids: Account.getAccountIdentifiers(current_account),
    body: {
      user_id: current_account.user_id,
      key: CollectionKeyValCodes.email_signature
    }
  });

  // Description: load email signature, handle on complete and on error
  useEffect(() => {
    !!data && setSignature(data?.value || "");
    !!error && // NOTE: Dispatch error to logger quietly
      handleAction({
        actionType: AccountUIActionTypes.PageErrorMessage,
        params: {
          message: SettingsErrorMessages.error_fetching_signature,
          stack: error,
        }
      });
  }, [data, error]);


  // Description: Keep track of the all compose mails sizes to calculate offset in children
  function handleSizeChange(index: number, size?: ComposeModalSizeType) {
    const new_arr = arr.slice();
    if (size) {
      new_arr[index] = size;
    } else {
      new_arr.splice(index, 1);
    }
    setSizeArr(new_arr);
  }

  return <>
    {
      compose_messages.length > 0 &&
      _.map(compose_messages, (compose_message: ComposeMailMessage, index: number) => {
        return <ComposeMailView
          arr={arr}
          index={index}
          compose_messages={compose_messages}
          key={compose_message.uid}
          current_account={current_account}
          compose_mail_message={compose_message}
          signature={signature}
          handleAction={handleAction}
          handleSizeChange={handleSizeChange} />;
      })
    }
  </>;
}

export default React.memo(ComposeMailListComponent);
