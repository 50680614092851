import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { MailboxItem, Thread, ActionHandlerFunction } from "@preveil-api";
import { Account, useAppSelector, useContacts, MailThread } from "src/common";
import { EmptyMailbox, } from "..";
import MessageRow from "./MessageRow.component";
import _ from "lodash";

type AllProps = {
  current_account: Account;
  current_mailbox: MailboxItem;
  offset: number;
  page?: string;
  isLoading: boolean;
  selected: string[];
  handleAction: ActionHandlerFunction;
  dragging_threads?: string[];
}

function ListViewComponent(props: AllProps) {
  const { isLoading, current_mailbox, page, selected, current_account, handleAction, dragging_threads } = props;
  const threads_page = useAppSelector((state) => state.mail.threads_page);
  const [user_ids, setContactsIds] = useState<string[]>();
  useContacts(current_account, user_ids);

  useEffect(() => {
    // Note: Add contact to session via hook
    if (!!threads_page) {
      const _user_ids = MailThread.getUserIdsFromThreads(threads_page.threads, current_account.user_id);
      _user_ids.length > 0 && setContactsIds(_user_ids);
    }
  }, [threads_page]);

  return <>
    {
      (!!threads_page && threads_page.threads.length > 0) ?
        <Table className="mail-list" hover>
          <tbody>
            {
              (!!threads_page && threads_page.threads.length > 0) &&
              _.map(threads_page.threads, (thread: Thread, i: number) => {
                return <MessageRow
                  selected={selected.includes(thread.thread_id)}
                  user_id={current_account.user_id}
                  key={`thread_${i}`}
                  thread={thread}
                  current_mailbox={current_mailbox}
                  handleAction={handleAction}
                  page={page || "1"} 
                  dragging_threads={dragging_threads} />;
              })
            }
          </tbody>
        </Table> : (!isLoading && <EmptyMailbox />)
    }
  </>;
}

export default React.memo(ListViewComponent);
