import React, { useEffect, useState } from "react";
import { ThreadMessageRecipients } from "@preveil-api";
import { MailMessages, MessageAnchors, UserListStatus, ComposeMessage, getStatusCounts } from "src/common";

function ComposeMailNotificationsComponent(props: { recipients: ThreadMessageRecipients }) {
  const { recipients } = props;
  const [unclaimed, setUnclaimed] = useState<string | null>();
  const [external, setExternal] = useState<string | null>();

  useEffect(() => {
    // NOTE: Need to sort out unclaimed and external recipients
    const all = ComposeMessage.groupAllRecipients(recipients);
    const unclaimed_counts = getStatusCounts(all, [UserListStatus.unclaimed, UserListStatus.pending]);
    const external_counts = getStatusCounts(all, UserListStatus.external);
    // NOTE: Replace anchor with count and proper singular/plural "recipient(s)" strings
    setUnclaimed(!!unclaimed_counts ? MailMessages.unclaimed_recipient_notification.replace(MessageAnchors.recipient_count, unclaimed_counts) : null);
    setExternal(!!external_counts ? MailMessages.external_recipient_notification.replace(MessageAnchors.recipient_count, external_counts) : null);
  }, [recipients]);

  return (!!unclaimed || !!external) ?
    <div className="compose-row notifications">
      {
        !!unclaimed && <p className="unclaimed">{unclaimed}</p>
      }{
        !!external &&
        <p className="external">{external}</p>
      }
    </div> : null;
}

export default React.memo(ComposeMailNotificationsComponent);