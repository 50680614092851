import React, { FocusEvent } from "react";
import { AdminUIActionTypes, CheckboxStates } from "src/common";
import { Checkbox } from "src/components";
import { ActionHandlerFunction } from "@preveil-api";

type AllProps = {
  content: {
    mail: boolean;
    drive: boolean;
    logs: boolean;
    acl_report: boolean;
  },
  handleActions: ActionHandlerFunction;
}

function DataExportContent({ content, handleActions }: AllProps) {
  const { mail, drive, logs, acl_report } = content;
  return (
    <div className="data-export-content-table">
      <div className="data-export-content-row">
        <Checkbox
          className="sr-only"
          onChange={(e: FocusEvent<HTMLInputElement>) => handleActions({ actionType: AdminUIActionTypes.SelectDataExportContent, params: {e, id: "mail"}})}
          value="mail"
          selected={mail ? CheckboxStates.checked : CheckboxStates.empty}
        />
        <label>Mail</label>
      </div>
      <div className="data-export-content-row">
        <Checkbox
          className="sr-only"
          onChange={(e: FocusEvent<HTMLInputElement>) => handleActions({ actionType: AdminUIActionTypes.SelectDataExportContent, params: {e, id: "drive"}})}
          value="drive"
          selected={drive ? CheckboxStates.checked : CheckboxStates.empty}
        />
        <label>Drive</label>
      </div>
      <div className="data-export-content-row">
        <Checkbox
          className="sr-only"
          onChange={(e: FocusEvent<HTMLInputElement>) => handleActions({ actionType: AdminUIActionTypes.SelectDataExportContent, params: {e, id: "logs"}})}
          value="logs"
          selected={logs ? CheckboxStates.checked : CheckboxStates.empty }
        />
        <label>Logs</label>
      </div>
      <div className="data-export-content-row">
        <Checkbox
          className="sr-only"
          onChange={(e: FocusEvent<HTMLInputElement>) => handleActions({ actionType: AdminUIActionTypes.SelectDataExportContent, params: {e, id: "acl_report"}})}
          value="acl_report"
          selected={acl_report ? CheckboxStates.checked : CheckboxStates.empty}
        />
        <label>ACL Report</label>
      </div>
    </div>
  );
}

export default React.memo(DataExportContent);