import * as React from "react";
import { Alert } from "react-bootstrap";
import { Icon } from "src/components";

function V1ChildAlertComponent() {
  return <Alert
    variant="warning"
    className="details-alert">
    <Alert.Heading>
      <Icon className="ficon-alert-circle" />
      This folder was created using an old version of PreVeil and does not support the sharing of sub-folders.
    </Alert.Heading>
    <p>
      To share it you must recreate it with its content from the parent or root folder.
    </p>
  </Alert>;
}

export default V1ChildAlertComponent;