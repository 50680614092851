import React from "react";
import { AccounWStSteps } from "src/common";
import { DataExportDetails, DataExportAwaitConnectionSettings, DataExportEstablishedConnection } from "src/components";

type AllProps = {
  handleActions: any;
  step: number | undefined;
  data_export_request: any;
};

function DataExportStepSettings({ step, data_export_request, handleActions }: AllProps) {
  const { requester } = data_export_request;
  function renderStepUI(step: number | undefined) {
    switch (step) {
      case AccounWStSteps.GET_CONNECTION_ESTABLISHED:
      case AccounWStSteps.ERROR:
        return <DataExportEstablishedConnection handleActions={handleActions} />;
      case AccounWStSteps.INITIALIZE_CONNECTION:
      case AccounWStSteps.GET_ACCEPTED_INIT_OK:
        return <DataExportAwaitConnectionSettings requester={requester} handleActions={handleActions} />;
      default:
        return (
          <DataExportDetails
            userRequest={data_export_request}
          />
        );
    }
  }
  return (
    <> {renderStepUI(step)} </>
  );
}

export default React.memo(DataExportStepSettings);