import React from "react";
import { Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import dataExportImage from "src/assets/images/data-export.svg";
import { PrivateRoutes } from "src/common";

// Description: When there is not approval group for the data export request, this component will be rendered.
function DataExportNotApprovalGroup() {
  const navigate = useNavigate();

  return (
    <main className="data-export-no-group">
      <Card className="pv-card data-export-card">
        <Card.Img variant="top" src={dataExportImage} />
        <Card.Body>
          <Card.Title as="h4">
            Data export allows you to download a decrypted copy of your organization's data.
          </Card.Title>
          <Card.Text as="p">
            To begin, please assign an approval group to be responsible for approving all data export requests.
          </Card.Text>
          <Button className="mt-3" variant="primary" onClick={() => {
            navigate(`/${PrivateRoutes.admin_route}/${PrivateRoutes.approval_groups_route}`, {
              state: { default_active_tab: "assign" },
            });
          }}>
            Set Approval Group
          </Button>
        </Card.Body>
      </Card>
    </main>
  );
}

export default React.memo(DataExportNotApprovalGroup);