import { SearchResultItem, MailSearchMatch, SearchEmailMatches, MatchingInfo, ThreadMessage, UserProfile } from "@preveil-api";
import { regexEscape, Regex_Patterns, formatShortTimestamp, prettyUserProfile, MailFlags, SearchResultFilter } from "src/common";
import _ from "lodash";

export type ResultItem = MailResultItem | UserResultItem;
export interface SearchResult {
  [key: string]: ResultItem[];
}

// Description: Build mail results item from response received from Crypto
export class MailResultItem implements MailSearchMatch {
  date: string = "";
  has_attachments: boolean = false;;
  mailbox_name: string = "";
  snippet: string = "";
  subject: string = "";
  thread_id: string = "";
  unread: boolean = false;
  flags: string[] = [];
  sender?: string;
  readonly _query: string;
  readonly _email_matches?: SearchEmailMatches[];
  readonly _best_ranked?: SearchEmailMatches;
  constructor(result: SearchResultItem, query: string) {
    this._query = query;
    this.thread_id = result.thread.thread_id;
    this.mailbox_name = result.thread.mailbox_name;
    this._email_matches = result.email_matches;
    this._best_ranked = !!this._email_matches && this._email_matches.length > 0 ? this.selectBestMatch(this._email_matches) : undefined;
    if (!!this._best_ranked) {
      const date = this._best_ranked.email.date;
      this.date = date ? formatShortTimestamp(date) : "";
      this.snippet = this._getBody(this._best_ranked);
      this.subject = this._getSubject(this._best_ranked);
      this.sender = this._getSender(this._best_ranked, query);
      this.has_attachments = this._getHasAttachment(this._best_ranked);
      this.unread = this._handleUnseenFlags(this._best_ranked);
    }
  }

  // Description: Return the Object only
  get best_match(): MailSearchMatch {
    return {
      date: this.date,
      has_attachments: this.has_attachments,
      mailbox_name: this.mailbox_name,
      unread: this.unread,
      sender: this.sender,
      snippet: this.snippet,
      subject: this.subject,
      thread_id: this.thread_id
    };
  }

  // Description: Pass filter types by SearchResultFilter - flag if each matches the selected filter
  //  NOTES: this._email_matches = SearchEmailMatches[] - matching_infos[]
  get filter_types(): string[] {
    const filters: string[] = []; // Collect all matching_infos
    if (!!this._email_matches) {
      const matching_infos: MatchingInfo[] = _.flatten(_.map(this._email_matches, (_email_matches: SearchEmailMatches) => _email_matches.matching_infos));
      const is_message = _.find(matching_infos, (matches: MatchingInfo) => (matches.type === "subject" || matches.type === "body"));
      !!is_message && filters.push(SearchResultFilter.message);
      const _recipients = _.filter(matching_infos, (matches: MatchingInfo) => (matches.type === "recipient"));
      if (!!_recipients) {
        const _query = this._query.toLowerCase();
        // NOTES: collect all emails
        // pool all bccs: UserProfile[], ccs: UserProfile[], tos: UserProfile[]
        // pool all senders => sender: UserProfile;
        // then search for the string in address, external_email and name fields
        const emails: ThreadMessage[] = _.flatten(_.map(this._email_matches, (_email_matches: SearchEmailMatches) => _email_matches.email));
        const all_senders: UserProfile[] = [];
        let all_recipients: UserProfile[] = [];
        _.forEach(emails, (email: ThreadMessage) => {
          all_senders.push(email.sender);
          all_recipients = all_recipients.concat([...email.tos, ...email.ccs, ...email.bccs]);
        });
        const is_to = _.find(all_recipients, (recipient: UserProfile) => (this.isProfileMatch(recipient, _query)));
        !!is_to && filters.push(SearchResultFilter.to);
        const is_from = _.find(all_senders, (sender: UserProfile) => (this.isProfileMatch(sender, _query)));
        !!is_from && filters.push(SearchResultFilter.from);
      }
    }
    return filters;
  }

  // Description: Examine UserProfile for searchterm = query: string
  private isProfileMatch(profile: UserProfile, query: string): boolean {
    const is_name = !!profile.name ? profile.name.includes(query) : false;
    const is_external_email = !!profile.external_email ? profile.external_email.includes(query) : false;
    return profile.address.includes(query) || is_name || is_external_email;
  }

  // Description: Select the best mathc by rank
  private selectBestMatch(email_matches: SearchEmailMatches[]): SearchEmailMatches {
    return email_matches
      .reduce((emA, emB) => emA.rank >= emB.rank ? emA : emB);
  }

  // Description: Gets the matched email and checks flags for seen
  private _handleUnseenFlags(matched: SearchEmailMatches): boolean {
    return !matched.email.flags?.includes(MailFlags.SEEN_FLAG);
  }

  // Description: Gets the Body/snippet and highlights the found query
  // TODO: improve, take out regex business out of getters // Legacy code
  private _getBody(matched: SearchEmailMatches): string {
    const body_matches = matched.matching_infos.filter(mi => mi.type === "body");
    if (body_matches.length > 0) {
      const highlighted = body_matches[0].expression
        .replace(/<s;;/g, "<b class=\"highlight\">")
        .replace(/;;e>/g, "</b>");
      // making sure that the highlighted portion is in view
      const regex = Regex_Patterns.SEARCH_BODY_HILIGHTED;
      const match = regex.exec(highlighted);
      const first_word_before_highlight = match ? match.index : 0;

      return highlighted
        .slice(first_word_before_highlight);
    }
    return matched.email.snippet || "";
  }

  // Description: Getst the subject and highlights the found query
  private _getSubject(matched: SearchEmailMatches): string {
    const subject_matches = matched.matching_infos.filter(mi => mi.type === "subject");
    if (subject_matches.length > 0) {
      const highlighted = subject_matches[0].expression
        .replace(/<s;;/g, "<b class=\"highlight\">")
        .replace(/;;e>/g, "</b>");

      // making sure that the highlighted portion is in view
      const regex = Regex_Patterns.SEARCH_BODY_HILIGHTED;
      const match = regex.exec(highlighted);
      const first_word_before_highlight = match ? match.index : 0;

      return highlighted
        .slice(first_word_before_highlight);
    }
    return matched.email.subject || "";
  }

  // Description: this function will find the query directly into the sender field
  private _getSender(matched: SearchEmailMatches, query: string): string {
    const sender = matched.email.sender;
    // let pretty = prettyUserProfile({ email: sender.address, name: sender.name || "", external_email: sender.external_email });
    let pretty = prettyUserProfile(sender);
    if (pretty.includes(query)) {
      const found = `<b class="highlight">${query}</b>`;
      pretty = pretty.replaceAll(query, found);
    }
    return pretty;
  }

  // TODO: show matching recipients insead of only sender  // Legacy code
  private _getRecipients(matched: SearchEmailMatches): string {
    const recipient_matches = matched.matching_infos.filter(mi => mi.type === "recipient");
    const all_matches: string[] = [];
    // All recipients returned a match
    if (recipient_matches.length > 0) {
      const expression = recipient_matches[0].expression;
      const highlight = expression.replaceAll("<s;;", "<b class=\"highlight\">").replaceAll(";;e>", "</b>");
      all_matches.push(highlight);
    }
    const people = all_matches.length > 0 ? all_matches.join() : matched.email.sender.name || matched.email.sender.address;
    return people;
  }

  private _getHasAttachment(matched: SearchEmailMatches): boolean {
    return !!matched.email.attachments && matched.email.attachments.length > 0;
  }
}

// Description: Build The User search result from the Contacts services
export class UserResultItem {
  constructor(public user: UserProfile, public matched_expression: string) {
  }

  public get name(): string {
    const regexp = new RegExp(`(${regexEscape(this.matched_expression)})`, "i");
    if (!!this.user.name) {
      const m = regexp.exec(this.user.name);
      if (m) {
        return this.user.name.replace(regexp, `<b class="highlight">${m[1]}</b>`);
      }
    }
    return this.user.name || "";
  }

  public get address(): string {
    const regexp = new RegExp(`(${regexEscape(this.matched_expression)})`, "i");
    const m = regexp.exec(this.user.address);
    if (m) {
      return this.user.address.replace(regexp, `<b class="highlight">${m[1]}</b>`);
    }
    return this.user.address;
  }

  public get external_email(): string | null {
    const regexp = new RegExp(`(${regexEscape(this.matched_expression)})`, "i");
    if (this.user.external_email) {
      const m = regexp.exec(this.user.external_email);
      if (m) {
        return this.user.external_email.replace(regexp, `<b class="highlight">${m[1]}</b>`);
      }
      return this.user.external_email;
    }
    return null;
  }

  public get profile(): UserProfile {
    return {
      uid: this.user.address,
      name: this.name,
      address: this.address,
      external_email: this.external_email
    };
  }
}
