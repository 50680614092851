import { useEffect, useState, useCallback } from "react";
import { useAppSelector, DriveNotificationMap, DriveNotification, NotificationEvent } from "src/common";
import { RootState } from "src/store/configureStore";
import _ from "lodash";

export function useDriveProcessNotification(collection_id?: string, event_watch?: NotificationEvent[]) {
  const notifications = useAppSelector((state: RootState) => state.drive.notifications);
  const [latest_state, setLatestState] = useState<DriveNotificationMap>();
  const [new_notification, setNewNotification] = useState<DriveNotification | undefined>();

  // Description: Update notifications
  useEffect(() => {
    const _notification = (!!collection_id && _.has(notifications, collection_id)) && notifications[collection_id];
    !!_notification && processNotifications(_notification);
  }, [notifications]);

  // Description: Process notifications by type 
  // Check latest version and if the UI is already loading
  function processNotifications(_notification: DriveNotificationMap) {
    const _notification_arr = _.values(_notification);
    setLatestState(_notification);
    let _new_notification;
    if (!!latest_state) {
      const _latest_arr = _.values(latest_state);
      const _new = _.differenceWith(_notification_arr, _latest_arr, _.isEqual);
      _new_notification = (!!_new && _new.length > 0) ? _new[0] : undefined;
    } else if (!!_notification_arr && _notification_arr.length > 0) {
      _new_notification = _notification_arr[0];
    }

    // NOTE: Set notification only if the event_watch contains the event type OR if it is undefined
    (!!_new_notification && ((!!event_watch && event_watch.includes(_new_notification.event)) || !event_watch)) &&
      setNewNotification(_new_notification);
  }

  // Description: Reset hook for next set
  const reset = useCallback(() => {
    setNewNotification(undefined);
  }, []);

  return {
    new_notification,
    reset
  };
}
