/*
* Description: Private routes. Only allow traffic if user is "logged in"
*               Pass an optional param: setRedirectPath to keep any information coming in a Querystring
*               i.e. passing a redirect option to another path / defaults to "/get-started"
*/
import React, { useEffect } from "react";
import { AuthContextType } from "@preveil-api";
import { Navigate, RouteProps, useLocation } from "react-router-dom";
import { AppConfiguration } from "src/common";
import { IdleTimeout } from "..";
export type PrivateRouteProps = AuthContextType & RouteProps;

function PrivateRoute(props: PrivateRouteProps) {
  const { profile, setRedirectPath, children } = props;
  const currentLocation = useLocation();
  useEffect(() => {
    if (!profile) {
      // Note: set Redirect path from PrivateRouter - Include any querystring 
      setRedirectPath(`${currentLocation.pathname}${currentLocation.search}`);
    }
  }, [profile, setRedirectPath, currentLocation]);

  // If profile is set, return render child elements
  // Else, return element that will navigate to login page with whatever params are sent in QS
  return <>
    {!!profile ?
      <>
        {children}
        {
          AppConfiguration.buildForWeb() && <IdleTimeout />
        }
      </> : <Navigate to={`/${currentLocation.search}`} />
    }
  </>;
}

export default React.memo(PrivateRoute);
