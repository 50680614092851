import _ from "lodash";

const delimiters = [
    ".",
    ",",
    "~",
    "(",
    ")",
    "$",
    "-",
    "_",
    "+",
    " ",
    "%",
    "#"
];

export class FilenameTokenizer {
    // Description: Tokenize filename
    public getTokens(filename: string): string[] {
        const tokens: string[] = [];

        if (!filename) {
            return tokens;
        }

        const base_filename = this.getBaseFilename(filename);
        const delimiter_tokens = delimiters.reduce((tokens, d) => tokens.concat(base_filename.split(d)), [base_filename, filename]);
        tokens.push(...delimiter_tokens);
        tokens.push(...this.getNumbericalTokens(base_filename));
        tokens.push(...this.getCasedTokens(base_filename));

        return _.uniq(tokens.map(t => t.toLowerCase()));
    }

    private getBaseFilename(filename: string): string {
        if (!filename) {
            return filename;
        }

        const parts = filename.split(".");
        if (parts.length < 2) {
            return filename;
        }
        parts.pop();
        return parts.join(".");
    }

    private getCasedTokens(filename: string): string[] {
        const tokens: string[] = [];
        let cased_filename = filename;

        const prefix_regex = /^[a-z]+/g;
        const prefix_result = prefix_regex.exec(cased_filename);
        if (!!prefix_result) {
            const camel_prefix = prefix_result[0];
            tokens.push(camel_prefix);
            cased_filename = cased_filename.substring(camel_prefix.length, cased_filename.length);
        }

        const pascal_results: string[] = [];
        const regex = /[A-Z][a-z]+/g;
        let result: RegExpExecArray | null = regex.exec(cased_filename);
        while (!!result) {
            pascal_results.push(result.toString());
            result = regex.exec(cased_filename);
        }
        tokens.push(...pascal_results);

        return tokens;
    }

    private getNumbericalTokens(filename: string): string[] {
        let numerical_filename = filename;
        const tokens: string[] = [];
        const numbers_regex = /[0-9]+/g;
        let result: RegExpExecArray | null = numbers_regex.exec(numerical_filename);
        while (!!result) {
            const token = result.toString();
            tokens.push(token);
            const parts = numerical_filename.split(token);
            if (parts.length > 1) {
                tokens.push(parts[0]);
                numerical_filename = parts.slice(1).join(token);
            }
            result = numbers_regex.exec(numerical_filename);
            if (!result) {
                tokens.push(numerical_filename);
            }
        }
        return tokens;
    }
}
