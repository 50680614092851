import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { DeviceBase } from "@preveil-api";
import { Icon } from "src/components";

type AllProps = {
  getUserDevices: Function;
  devices: DeviceBase[];
  loading: boolean;
};

/* Description: Toolbar for Devices, Contains count for number of devices and refresh button */
function DeviceToolBarComponent(props: AllProps) {
  const { getUserDevices, devices, loading } = props;

  return (
    <Row className="toolbar m-0">
      <Col xs="auto" className="ms-auto">
        <div className="pagination_info">
          <b>{devices.length} </b>
          <span>Device{devices.length > 1 ? "s" : ""}</span>
        </div>
      </Col>
      <Col className="divider"></Col>
      <Col xs="auto" className="p-1 refresh">
        <Button variant="icon" size="sm" title="Refresh Devices" onClick={() => getUserDevices()}>
          {loading ? <i className="spinner"></i> : <Icon className="ficon-rotate-cw" />}
        </Button>
      </Col>
    </Row>
  );
}

export default React.memo(DeviceToolBarComponent);
