/*
*   File:           type-guards.ts
*   Description:    Collection of utility type guards for proper parsing and type usage
*   Author:         PreVeil, LLC
*/

import { Variant } from "react-bootstrap/types";
import { MailboxNotificationType } from "@preveil-api";
import { account_types, isNumber, GroupEmail } from "src/common";

// Description: Type guard for AccountType
export const AccountTypeGuard = (type: string): boolean => {
  return !!account_types[type];
};

// Description: react-boostrap type guard - 'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark', 'light', string;
export function isOfBoostrapVariantTypeGuard(toast_type: string): toast_type is Variant {
  return ["primary", "secondary", "success", "danger", "warning", "info", "dark", "light"].includes(toast_type);
}

// Description: Interface Guard for completed transfered creds
export function instanceofTransferCredentials(data: any): boolean {
  return !!data.device && !!data.device_signature && !!data.message && !!data.signing_device_id && !!data.user_id && !!data.user_signature;
}

// Description: Interface Guard for completed transfered creds
export function instanceofKeyTransfer(data: any): boolean {
  return !!data.public_key_hash && !!data.protocol_version;
}

// Description: Interface Guard for Approvers Lists - Account recovery
export function instanceofApproversList(data: any): boolean {
  return !!data.approvers && !!data.optionals_required;
}

export function isDeviceLockInfoBase(data: any): boolean {
  return !!data.lock_by_user_id && !!data.lock_by_user_name;
}

// Description: Checks to see if this is error returned from incorrect device key (key rotation)
// {device_id: string;device_key_version:number;user_id: string}
export function isKeyRotationError(data: any): boolean {
  return isNumber(data.device_key_version) && !!data.device_id && !!data.user_id;
}

// Description: Instance of GroupEmail
export function isGroupEmail(input: any): input is GroupEmail {
  return input instanceof GroupEmail && !!input.members;
}

// Description: Returns the type of mailboxes that have counts in the form of "bubbles" badges
export function isMailboxNotificationType(input: string): input is MailboxNotificationType {
  return ["unread", "drafts", "starred", "outobox"].includes(input);
}
