import { useCallback, useState, useEffect } from "react";
import { MessageDisplayType, ErrorStackDataType } from "@preveil-api";
import { FSMessage, FSStatus } from "src/common/keys/protos/collections_pb";
import {
  Account, DriveErrorMessages, Message, MessageHandlerDisplayType, useAppDispatch, useSendRequestMutation, CollectionFilesyncAPI, useRelinkCollection,
  getEnumKey, ErrorStackItem
} from "src/common";
import { uiActions } from "src/store";


type ResponseInfo = {
  name: string;
  accept: boolean;
  share_id: string;
  link_id?: string;
}

type ErrorInfo = {
  name: string;
  id: string;
  conflict?: boolean;
  while_relinking?: boolean;
};

// Description: hook for drive web to respond to share invitation (accept or reject)
export function useShareRespond(current_account: Account) {
  const [share_id, setShareId] = useState<string>("");
  const [success_responded, setSuccessResponded] = useState<ResponseInfo>();
  const [error_responding, setError] = useState<ErrorInfo>();
  const { relink, success, error } = useRelinkCollection(current_account);
  const [sendRequest] = useSendRequestMutation();
  const dispatch = useAppDispatch();

  // Description: handles success or error from relink hook (only applicable for accepting an invite)
  useEffect(() => {
    if (success) {
      setSuccessResponded({ name: success.collection_name, accept: true, share_id, link_id: success.link_id });
    }
    if (!!error) {
      setError({ name: error.name, id: share_id, conflict: error.conflict, while_relinking: true });
    }
  }, [success, error]);

  // Description: Responds to share invite via HANDLE_SHARE backend call (either accept or reject).
  // if accept - also use the relink hook to add back the collection to My PreVeil.
  async function respond(collection_id: string, id: string, accept: boolean, name: string) {
    setShareId(id);
    const request = await CollectionFilesyncAPI.shareRespond(current_account, collection_id, id, accept);
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        !!accept
          ? relink(collection_id, name)
          : setSuccessResponded({ name, accept, share_id: id });
      } else {
        setError({ name, id });
        handlePageErrorMessage(DriveErrorMessages.default, {
          stack_message: DriveErrorMessages.error_responding_to_invitation,
          stack_error: message.getErrorMessage()
        }, message);
      }
    }

    if (!!request) {
      request.setCallback(callback);
      sendRequest(request);
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, {
        stack_message: DriveErrorMessages.error_sending_request,
        stack_error: DriveErrorMessages.error_responding_to_invitation,
      });
    }
  }


  // Description: Handle error message and send error to store
  function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, fsmessage?: FSMessage | null,
    display_type: MessageDisplayType = MessageHandlerDisplayType.logger) {
    const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
    const stack = new ErrorStackItem("[useShareRespond Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
    dispatch(uiActions.handleRequestErrors(new Message(message, display_type), stack));

  }
  // Description: Callback for responding to a share invitation
  const respondToShareInvitation = useCallback((collection_id: string, id: string, accept: boolean, name: string) => respond(collection_id, id, accept, name), []);

  return {
    respondToShareInvitation,
    success_responded,
    error_responding
  };
}
