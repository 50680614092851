import React, { useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { AccountUIActionTypes } from "src/common";
import { Icon } from "src/components";

type AllProps = {
  request_user_id?: string;
  handleAction: ActionHandlerFunction;
}

function SuccessPanelComponent(props: AllProps) {
  const { request_user_id, handleAction } = props;
  useEffect(() => {
    handleAction({ actionType: AccountUIActionTypes.Disconnect });
  }, []);

  return <Card.Body>
    <h3 className="content-header">
      <div className="success-icon-border me-2">
        <Icon className="icon pv-icon-mark-read" />
      </div>
      Approval Granted
    </h3>
    <div>
      <p>You have approved secure key recovery for <b>{request_user_id || "the requested user"}</b></p>
      <Button className="mt-3" variant="primary"
        onClick={() => handleAction({ actionType: AccountUIActionTypes.Destroy })}>Reset</Button>
    </div>
  </Card.Body>;
}
export default React.memo(SuccessPanelComponent);