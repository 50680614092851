import React, { useEffect, useState } from "react";
import { IActionHandler, Sort } from "@preveil-api";
import { Account, DriveErrorMessages, DriveSuccessMessages, DriveSharedInfo, Message, MessageAnchors, useAppDispatch, useGetSharedFolders, useRelinkCollection, GlobalErrorMessages, MessageHandlerDisplayType, getOppositeSortDirection, SORT_DIRECTION, sortBy, MessageToastTypes } from "src/common";
import { PageHeader } from "src/components";
import { uiActions } from "src/store";
import { SharedToolbar, SharedListView } from ".";

type AllProps = {
  current_account: Account;
};

/* Description: Parent component for the Shared Tab in Drive - displays all the links under My PreVeil (this includes shared folders that are currently 
  in my preveil and also unlinked folders that have been removed from my preveil) */
function DriveSharedComponent(props: AllProps) {
  const { current_account } = props;
  const [is_loading, setIsLoading] = useState<boolean>(true);
  const [shared, setShared] = useState<DriveSharedInfo[]>([]);
  const [shared_list, setSharedList] = useState<DriveSharedInfo[]>([]);
  const [relinking, setRelinking] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>("all");
  const [sort, setSort] = useState<Sort<keyof DriveSharedInfo>>({ field: "status", direction: "asc" });
  const { shared_folders, getSharedFolders, reset, error_fetching } = useGetSharedFolders(current_account);
  const { relink, success, error, resetRelink } = useRelinkCollection(current_account);
  const dispatch = useAppDispatch();

  // Description: On load call refresh to get the list of shared folders.
  useEffect(() => {
    DriveSharedRequests.handleRefresh();
    return () => {
      dispatch(uiActions.handleMessageDismiss());
    };
  }, []);

  // Description: once the shared_folders field is populated (from the useGetSharedFolders web hook), sets shared 
  useEffect(() => {
    if (!!shared_folders) {
      setShared(shared_folders);
      setSharedList(shared_folders);
      DriveSharedRequests.handleSetFilter({ value: filter, list: shared_folders });
      setIsLoading(false);
    }
    if (!!error_fetching) {
      handleFetchSharedError();
    }
  }, [shared_folders, error_fetching]);

  // Description: on success of relinking (adding back a shared folder to my preveil): displays success message and 
  // sorts the list.
  useEffect(() => {
    if (success) {
      const { collection_name } = success;
      handleRelinkSuccess(collection_name);
    }
    if (error) {
      let error_message: string = DriveErrorMessages.error_adding_folder.replace(MessageAnchors.folder_name, error.name);
      const folder = shared.find((s) => s.id === error.id);
      if (!!folder) {
        folder.processing = false;
        setRelinking(false);
      }
      if (error.conflict) {
        error_message = DriveErrorMessages.conflict.replace(MessageAnchors.folder_name, error.name);
      }
      dispatch(uiActions.handleSetMessage(new Message(error_message, undefined, MessageToastTypes.error)));
    }
    resetRelink();
  }, [success, error]);

  function handlePageError(message: string, error?: any) {
    dispatch(uiActions.handleRequestErrors(new Message(message), error));
  }

  function handleFetchSharedError(error?: string) {
    handlePageError(DriveErrorMessages.error_fetching_shared, error);
    setIsLoading(false);
  }

  // Description: handles relink success (successfully added the folder back to my preveil)
  function handleRelinkSuccess(name: string) {
    dispatch(uiActions.handleSetMessage(new Message(DriveSuccessMessages.success_adding_folder.replace(MessageAnchors.folder_name, name))));
    DriveSharedRequests.handleRefresh();
  }

  // Description: Handle all children component actions and store it
  const DriveSharedRequests = {
    handleAddFolder: (params: { folder: DriveSharedInfo, id: string, name: string }) => {
      const { folder, id, name } = params;
      folder.processing = true;
      setRelinking(true);
      relink(id, name);
    },
    handleRefresh: () => {
      reset();
      setIsLoading(true);
      getSharedFolders();
    },
    handleSetSort: (params: { field: keyof DriveSharedInfo, list?: DriveSharedInfo[] }) => {
      const { field, list } = params;
      const folders = !!list ? list : shared_list;
      const direction = sort.field === field ? !list ? getOppositeSortDirection(sort.direction) : sort.direction : SORT_DIRECTION.ascending;
      const sorted_folders = sortBy(folders, { field, direction });
      setSort({ field, direction });
      setSharedList(sorted_folders);
    },
    handleSetFilter: (params: { value: string, list?: DriveSharedInfo[] }) => {
      const { value, list } = params;
      const folders = !!list ? list : shared;
      setFilter(value);
      let filtered: DriveSharedInfo[] = folders;
      if (value === "all") {
        filtered = folders;
      } else if (value === "hidden") {
        filtered = folders.filter(folder => folder.status === "Hidden");
      } else {
        filtered = folders.filter(folder => folder.status.includes("In"));
      }
      !!list ? DriveSharedRequests.handleSetSort({ field: sort.field, list: filtered }) : setSharedList(filtered);
    },
    handlePageErrorMessage: (params: { message: string, stack?: any; }) => {
      dispatch(uiActions.handleRequestErrors(new Message(params.message, MessageHandlerDisplayType.logger), params.stack));
    }
  };

  //  Description: Handle all actions from Children forms
  function handlePageActions(actionObj: IActionHandler) {
    dispatch(uiActions.handleMessageDismiss());
    const callback = `handle${actionObj.actionType}`;
    // Handle local calls:
    if ((DriveSharedRequests as any)[callback] instanceof Function) {
      (DriveSharedRequests as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(MessageAnchors.actionType, actionObj.actionType);
      DriveSharedRequests.handlePageErrorMessage({ message, stack: actionObj });
    }
  }

  return (
    <>
      <PageHeader>
        <h1 className="mb-3">Shared</h1>
      </PageHeader>
      <SharedToolbar is_loading={is_loading} handleAction={handlePageActions} shared_folders={shared_list}></SharedToolbar>
      <div className="cover-content">
        <SharedListView handleAction={handlePageActions} shared_folders={shared_list} current_account={current_account} is_loading={is_loading} relinking={relinking} sort={sort} />
      </div>
    </>
  );
}

export default React.memo(DriveSharedComponent);
