import React from "react";
import { Routes, Route } from "react-router-dom";
import { AppConfiguration } from "./common";
import {
  PrivateRoute, PrivateRouteProps, useAuthContext
} from "./components";
import {
  Developer,
  DeveloperRoutes,
  PublicPages,
  PublicPagesRoutes,
  PublicPagesWebRoutes,
  Mail,
  Drive,
  Settings,
  Admin,
  Chat,
  SystemPrivatePages
} from "./pages";

function Router(): React.ReactElement {
  const authContext = useAuthContext();
  const defaultPrivateRouteProps: PrivateRouteProps = { ...authContext };

  return <Routes>
    {/* Public Pages */}
    <Route path="/" element={<PublicPages />} >
      {AppConfiguration.buildForWeb() ? PublicPagesWebRoutes() : PublicPagesRoutes()}
    </Route>

    {/* DEV PAGES */}
    <Route path="developer" element={<Developer />} >
      {DeveloperRoutes()}
    </Route>

    {/* Mail Private Pages */}
    <Route path="mail/*" element={
      <PrivateRoute {...defaultPrivateRouteProps} >
        <Mail />
      </PrivateRoute>
    } />

    {/* Drive Private Pages */}
    <Route path="drive/*" element={
      <PrivateRoute {...defaultPrivateRouteProps} >
        <Drive />
      </PrivateRoute>
    } />

    {/* Drive Private Pages */}
    <Route path="chat/*" element={
      <PrivateRoute {...defaultPrivateRouteProps} >
        <Chat />
      </PrivateRoute>
    } />

    {/* Settings Private Pages */}
    <Route path="settings/*" element={
      <PrivateRoute  {...defaultPrivateRouteProps} >
        <Settings />
      </PrivateRoute>
    } />

    {/* Admin Private Pages */}
    <Route path="admin/*" element={
      <PrivateRoute  {...defaultPrivateRouteProps} >
        <Admin />
      </PrivateRoute>
    } />

    {/* Remaining System PrivatePages for Create Organization and misc error pages  */}
    <Route path="*" element={
      <PrivateRoute {...defaultPrivateRouteProps} >
        <SystemPrivatePages />
      </PrivateRoute>} />
  </Routes>;

}

export default Router;