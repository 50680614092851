import keyMirror from "keymirror";
import { DriveEntryTypes, DriveEntryType, SearchNode, extensionMatch } from "src/common";
import _ from "lodash";

// Description: Hold Search filter types
export const DriveSearchFilterType = keyMirror({
    parent_scope: null,
    fs_type: null,
    extension: null
});

export type DriveSearchFilterTypes = keyof typeof DriveSearchFilterType;
export interface DriveSearchFilter {
    filter_type: DriveSearchFilterTypes,
    extension?: string;
    fs_type?: DriveEntryTypes;
    parent_scope?: string;
    active: boolean; // true for active filter and false for remove
};

export interface DriveSearchFilterSet {
    extension?: string[];
    fs_type?: DriveEntryTypes[];
    parent_scope?: string[];
}

const DEFAULT_FS_TYPES = [DriveEntryType.DIR, DriveEntryType.FILE, DriveEntryType.LINK];
export class DriveSearchFilters {
    private _parent_scope!: string[];
    private _fs_type!: DriveEntryTypes[];
    private _extension!: string[];
    constructor() {
        this.initialize();
    }
    get extensions(): string[] | undefined {
        return this._extension.length ? this._extension : undefined;
    }

    get fs_type(): DriveEntryTypes[] | undefined {
        return this._fs_type.length ? this._fs_type : undefined;
    }

    get parent_scope(): string[] | undefined {
        return this._parent_scope.length ? this._parent_scope : undefined;
    }

    get filter_set(): DriveSearchFilterSet {
        return {
            extension: this.extensions,
            fs_type: this.fs_type,
            parent_scope: this.parent_scope
        };
    }

    // Description: Modify class by individual filter passed in - use filter_set.active to add or remove
    public modifyFilterSet(filter_set: DriveSearchFilter) {
        filter_set.active ?
            this._addFilterSet(filter_set) : this._removeFilterSet(filter_set);
    }

    private _addFilterSet(filter_set: DriveSearchFilter) {
        switch (filter_set.filter_type) {
            case DriveSearchFilterType.parent_scope:
                !!filter_set.parent_scope && this.addParentScope(filter_set.parent_scope);
                break;
            case DriveSearchFilterType.fs_type:
                !!filter_set.fs_type && this.addFSType(filter_set.fs_type);
                break;
            case DriveSearchFilterType.extension:
                !!filter_set.extension && this.addExtension(filter_set.extension);
                break;
        }
    }

    private _removeFilterSet(filter_set: DriveSearchFilter) {
        switch (filter_set.filter_type) {
            case DriveSearchFilterType.parent_scope:
                !!filter_set.parent_scope && this.removeParentScope(filter_set.parent_scope);
                break;
            case DriveSearchFilterType.fs_type:
                !!filter_set.fs_type && this.removeFSType(filter_set.fs_type);
                break;
            case DriveSearchFilterType.extension:
                !!filter_set.extension && this.removeExtension(filter_set.extension);
                break;
        }
    }

    // Description: Add to parent_scope filters
    public addParentScope(id: string) {
        const index = _.findIndex(this.parent_scope, (_id) => (_id === id));
        index < 0 && this._parent_scope.push(id);
    }
    public removeParentScope(id: string) {
        const index = _.findIndex(this.parent_scope, (_id) => (_id === id));
        index >= 0 && this._parent_scope.splice(index, 1);
    }

    // Description: handle bulk entries in parent scope by id
    public addParentScopes(ids: string[]) {
        _.forEach(ids, (id: string) => {
            this.addParentScope(id);
        });
    }
    public resetParentScope() {
        this._parent_scope = [];
    }

    // Description: Add to fs_type filters
    public addFSType(type: DriveEntryTypes) {
        const index = _.findIndex(this.fs_type, (_type) => (_type === type));
        index < 0 && this._fs_type.push(type);
    }
    public removeFSType(fs_type: DriveEntryTypes) {
        const index = _.findIndex(this.fs_type, (_fs_type) => (_fs_type === fs_type));
        index >= 0 && this._fs_type.splice(index, 1);
    }
    public resetFSTypeFilter() {
        this._fs_type = DEFAULT_FS_TYPES.slice();
    }

    // Description: Add to extension
    public addExtension(extension: string) {
        const index = _.findIndex(this._extension, (_extension) => (_extension === extension));
        index < 0 && this._extension.push(extension);
    }
    public removeExtension(extension: string) {
        const index = _.findIndex(this._extension, (_extension) => (_extension === extension));
        index >= 0 && this._extension.splice(index, 1);
    }
    public resetExtension() {
        this._extension = [];
    }

    // Description: Reset to back to defaults
    public initialize() {
        this.resetFSTypeFilter();
        this.resetParentScope();
        this.resetExtension();
    }
    // Description: Apply filters to result set // ************************** WORKING HERE ************************** //
    static applyFilters(entries: SearchNode[], filters: DriveSearchFilterSet): SearchNode[] {
        const { extension, fs_type } = filters; // parent_scope
        // console.log(fs_type, extension, parent_scope);
        const filtered_entries = _.filter(entries, (entry: SearchNode) => {
            let is_valid = true;
            // NOTE: Validate for fs_type and file extension
            if (!!fs_type && fs_type.length > 0) {
                if (entry.type === DriveEntryType.FILE) {
                    is_valid = fs_type.includes(DriveEntryType.FILE);
                    // NOTE: Filter by extension
                    is_valid = (is_valid && !!extension && extension.length > 0) ? extensionMatch(entry.name, extension) : is_valid;
                } else if (entry.type === DriveEntryType.DIR) {
                    is_valid = fs_type.includes(DriveEntryType.DIR);
                } else if (entry.type === DriveEntryType.LINK) {
                    is_valid = fs_type.includes(DriveEntryType.LINK);
                }
            }
            return is_valid;
        });
        return filtered_entries;
    } // ************************** WORKING HERE ************************** //
}
