import { useState, useEffect, useCallback, useRef } from "react";
import { UserProfile, MessageDisplayType, ContactsData, JSONOrgInfo, CollectionServerUser, ErrorStackDataType } from "@preveil-api";
import { randomBytes } from "pvcryptojs";
import { FSRequest, FSMessage, FSRole, FSStatus, Node, SealedContent, FSWrappedKey, FSType, FSBlockType } from "src/common/keys/protos/collections_pb";
import { SymmKey } from "src/common/keys";
import { EncryptionStyle } from "src/common/keys/encryption";
import { InviteMetadata, ShareInviteMetadata } from "src/common/keys/protos/invite_pb";
import {
  Account, AppConfiguration, CollectionEntity, NodePermissionTypes, useAppDispatch, useAppSelector, Message, DriveRequest, DriveSuccessMessages, DriveErrorMessages,
  MessageHandlerDisplayType, useGetGrants, getEnumKey, PermissionSetType, useGetPermissions, useFetchSnapshot, COLLECTION_PROTOCOL_VERSIONS, UserListStatus,
  EntryItem, DriveEntryType, useSendRequestMutation, CollectionFilesyncAPI, DirectoryInfoEntity, UUID, Collection, getDirSymmKey, isSameUser, ErrorStackItem,
  PermissionSet, Permission, Snapshot, BulkUpdateParams, useGetUsersOrgsByEntityIdQuery, KeyFactory, Helpers, LogKeyGrantAccessData, NodeIdentifier,
  NodePermissionType, InitV2CollectionEncryptedData, ACLRoleType, ACL, NodePermissionTypeToACLRoleType, MessageAnchors, GrantSet, GrantSetType, parseCollectionUserToPublicKeyUser,
  PublicKeyUser, FSRoleType, GrantAccessData, DriveEntryTypes, Grant, DriveLimits, dayjs, NodePermissionTypeToFSRoleType, NodeKeys, GrantsAndPermissions,
  IndexedSnapshot, usePostUsersFindMutation, AppUserKey, usePutUsersInviteMutation, useShareV2Mutation, NodePermissionsName, NodePermissionLabels,
  resolveGroupToUserProfile, usePromotePrecheckMutation, PromotePrecheckApiResponse, useSendPvInviteMutation, PermissionsShareType, PutUsersInviteApiResponse
} from "src/common";
import { RootState } from "src/store/configureStore";
import { uiActions, DriveCallbackAsyncFunction } from "src/store";
import _ from "lodash";

export interface ChangeSet {
  users: UserProfile[];
  unclaimed?: UserProfile[];
  permission_type: NodePermissionTypes;
  expiration: string;
}

interface NewCollectionInfo {
  collection_id: UUID;
  id: string;
  encrypted_name: Uint8Array;
  encrypted_access_name: Uint8Array;
  maintainer_scoped_name: SealedContent;
  version: string;
  dir_symm_key: SymmKey;
  new_permissions: PermissionSet;
  wrapped_key: Uint8Array;
  keys: FSRequest.Init.Key[];
}

interface Grantee extends PublicKeyUser {
  view_only: boolean;
  expiration?: string;
  acl: ACLRoleType[];
}

export interface ShareDataBase extends NodeIdentifier {
  type: DriveEntryTypes;
  collection_name: string;
  grantees: Grantee[];
  success_message?: string;
  is_promote?: boolean; // NOTE: needed for callback in component
}

export interface ShareData extends ShareDataBase {
  permission_set: PermissionSetType;
  acl: ACLRoleType[];
}

interface ChunkBulkUpdateParams {
  creates: FSRequest.Create[][];
  blocks: FSRequest.BulkUpdate.Block[][];
  updates: FSRequest.Update[][];
}

// Progress Report according to path for each: Promote, ShareV2 and ShareV1
const ProgressValues = {
  initial: 0,
  promoteDirectory: 10,
  initializeV2Collection: 15,
  fetchNewGrants: 20,
  bulkUpdateCollection: 25,
  createLinkMakeShareable: 40,
  shareV2: 50,
  getACLChanges: 85,
  handleBulkUpdate: 90,
  handleSuccess: 95,
  // Sharing V2 already collections
  handleShareV2: 10,
  upgradeToAclApp: 20,
  AppShareV2Complete: 70,
  // Sharing V1 collection directories
  handleShareV1: 10,
  grantAccess: 50, // working path for V1
  // Upgrade to ACL NODE
  upgradeToAcl: 10,
  getCurrentGrantees: 20,
  upgradeBulkUpdate: 25,
};

// Description: Update collection permissions in app mode
export function useWebShare(current_account: Account, collection_info: CollectionEntity, entry: EntryItem, contacts?: ContactsData) {
  const is_web = AppConfiguration.buildForWeb();
  const root_info = useAppSelector((state: RootState) => state.drive.root_info);
  const default_permissions: PermissionSetType[] = useAppSelector((state: RootState) => state.drive.default_permissions);
  const default_grants = useAppSelector((state: RootState) => state.drive.default_grants);
  const [change_set, setChangeSet] = useState<ChangeSet | undefined>();
  const [snapshot_data, setSnapshotData] = useState<NodeIdentifier | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ShareDataBase | undefined>();
  const [error, setError] = useState<boolean>(false);
  const [progress, SetProgress] = useState<number>(ProgressValues.initial);
  const [get_org_id, setGetOrgId] = useState<string | undefined>();
  const [sendInvites] = usePutUsersInviteMutation();
  const [sendPVInvite] = useSendPvInviteMutation();
  const [sendRequest] = useSendRequestMutation();
  const { permissions, getPermissions, getDefaultPermissions, destroyPermissions } = useGetPermissions(current_account);
  const { grants, getStoredGrants, destroyGrants } = useGetGrants(current_account);
  const [useShareV2] = useShareV2Mutation();
  const [promotePrecheck] = usePromotePrecheckMutation();
  const [findUsers] = usePostUsersFindMutation();
  const { data: org_info } = useGetUsersOrgsByEntityIdQuery({
    account_ids: Account.getAccountIdentifiers(current_account),
    body: { entity_id: get_org_id || "" },
  }, { skip: (!get_org_id) });
  const { snapshot, error: snapshot_error } = useFetchSnapshot(current_account, snapshot_data, permissions);
  const dispatch = useAppDispatch();
  const request_id_ref = useRef<string>(); // NOTE: Use this field to follow the Filesync notifications
  const snapshot_seq_ref = useRef<number>(0);
  const org_info_ref = useRef(org_info);
  function setOrgInfoRef(_org_info?: JSONOrgInfo) {
    org_info_ref.current = _org_info;
  }

  // Description: Set org_info in a Ref so its available to callbacks
  useEffect(() => {
    !!org_info && setOrgInfoRef(org_info);
  }, [org_info]);

  // Description: Fetch permissions with collection_info updates
  useEffect(() => {
    if (!!collection_info) {
      getPermissions(collection_info.collection_id, default_permissions);
    }
  }, [collection_info]);

  // Description: Set Grants from Store since these were just fetched in collection_info
  useEffect(() => {
    collection_info.collection_protocol_version !== COLLECTION_PROTOCOL_VERSIONS.V1 &&
      getStoredGrants(collection_info.maintainer_id || collection_info.id, collection_info.collection_id, default_grants);
  }, [default_grants]);

  // Description: Initialize calls to update collection by getting the shared with information 
  useEffect(() => {
    // NOTE: Grants are not needed for V1 collections 
    (!!change_set && !!permissions) && handleInitializeShare(permissions);
  }, [change_set]);

  // Description: Snapshot was fetched complete promoting directory and handles errors
  // NOTE: Snapshot only gets populated on entry.type === DriveEntryType.DIR && root_info.collection_id === collection_info.collection_id
  useEffect(() => {
    if (!!snapshot) {
      const indexed_snapshot = Snapshot.getIndexedSnapshot(snapshot);
      // NOTE: Checking if it has links here before initializing a collection so it doesn't leave the directory in a weird state.
      if (indexed_snapshot.links.size > 0) {
        handlePageErrorMessage(DriveErrorMessages.error_sharing_directories_with_links, {
          stack_message: DriveErrorMessages.error_sharing_directories_with_links,
        });
      } else {
        snapshot_seq_ref.current = snapshot.getSeq() || 0;
        validateV1CollectionDirectory() ? promoteDirectory(indexed_snapshot) :
          validateV2CollectionDirectory() ? upgradeToAcl(indexed_snapshot) :
            handlePageErrorMessage(DriveErrorMessages.error_sharing, { stack_message: DriveErrorMessages.error_handling_snapshot });
      }
      setSnapshotData(undefined); // NOTE: Reset snapshot data 
    }

    !!snapshot_error && handlePageErrorMessage(DriveErrorMessages.error_sharing, { stack_message: DriveErrorMessages.error_handling_snapshot });
  }, [snapshot, snapshot_error]);

  // Description: Set Dirty Warning based on loading
  useEffect(() => {
    dispatch(uiActions.handleSetDirtyMessage(loading));
  }, [loading]);

  // -------------------------------------------------------------------------------------------------------
  // Calls to fullfill share V1 and V2 collections 
  // -------------------------------------------------------------------------------------------------------
  // Description: Initialize the sharing process by Entry Type
  /**
  * STEPS:
  *   1. CHECK ENTRY TYPE AND PARENT COLLECTION VERSION. 
  *      - IF V1 check PARENT COLLECTION ROOT => then PROMOTEDIRECTORY 
  *           - IT SHOULD NOT BE ANY OTHE TYPE OF V1 but if it is then there is a bug and throw an error
  *      - ELSE Continue to share & invite new unclaimed users
  *   2. SHARE V1 OR V2
  *   3. Invite Unclaimed accounts  backend_service.sendRecipientInvite (legacy) && webV2: const [sendInvites] = usePutUsersInviteMutation();
   **/
  async function handleInitializeShare(_permissions: PermissionSetType) {
    const share_data = {
      collection_id: collection_info.collection_id,
      id: collection_info.id,
      maintainer_id: collection_info.maintainer_id,
      collection_name: collection_info.collection_name,
      permission_set: _permissions,
      type: entry.type,
      grantees: await getGrantees(false),
      acl: !!change_set ? NodePermissionTypeToACLRoleType[change_set.permission_type] : NodePermissionTypeToACLRoleType.read_only,
    };

    if (!!root_info && !!change_set) {
      // NOTE: Handle Dir first (V1 under default collections and ACL nodes)
      if (validateV1CollectionDirectory() || validateV2CollectionDirectory()) {
        setSnapshotData({ collection_id: collection_info.collection_id, id: entry.id });
      } else if (change_set.users.length > 0) {
        collection_info.collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V1 ? handleShareV1() :
          handleShareV2(share_data, ProgressValues.handleShareV2);
      } else if (!!change_set?.unclaimed && change_set?.unclaimed?.length > 0) {

        // NOTE: If only unclaimed accounts then send invite and reset component
        handleSuccess(share_data);
      } else {
        handlePageErrorMessage(DriveErrorMessages.error_sharing, { stack_message: DriveErrorMessages.error_initializing_share });
      }
    } else {
      handlePageErrorMessage(DriveErrorMessages.error_sharing, { stack_message: DriveErrorMessages.error_initializing_share });
    }
  }

  // -------------------------------------------------------------------------------------------------------
  // For Sharing Directories directly under root. 
  //    NOTE: Direct children of the Root Collection ONLY- Directories under other V1 collections are not allowed to be shared 
  // -------------------------------------------------------------------------------------------------------
  // Description: Converts directories to V1 collections, then promotes them to a V2 collection
  // Fetch Snapshot - fetchSnapshot hook 
  // Initialize collection as V2 (Legacy notes: Removes Upgrade collection to V2)
  // bulk update (with a snapshot object) - after this can call the rest
  //      - Make Shareable 
  //      - Grant logkey to admin group
  //      - shareV2 to Grant access and send invrte to recipients
  //      - Handle Success 
  async function promoteDirectory(indexed_snapshot: IndexedSnapshot) {
    !!current_account.org_info?.org_id && setGetOrgId(current_account.org_info.org_id);
    const collection_id = collection_info.collection_id;
    // NOTE: Need the directory info: version, wrapped dir key, name, and dir id
    async function callback(message: FSMessage) {
      SetProgress(ProgressValues.promoteDirectory);
      const directory = message.getDir();
      if (message.getStatus() === FSStatus.OK && !!directory) {
        const directory_info: DirectoryInfoEntity = {
          id: directory.getId_asB64(),
          collection_id,
          name: entry.name,
          version: directory.getVersion_asB64(),
          wrapped_dir_key: directory.getWrappedDirKey()
        };
        initializeV2Collection(directory_info, indexed_snapshot);
      } else {
        handlePageErrorMessage(DriveErrorMessages.error_sharing, { stack_message: DriveErrorMessages.error_fetching_acl_list }, message);
      }
    }
    const can_promote = !is_web ? await promotePrecheckApp() : true;
    (can_promote) &&
      getDirectoryInformation(collection_id, entry.id, callback, permissions);

  }

  // Description: Check that there are no active Filesync processess before continuing with Promote
  async function promotePrecheckApp(): Promise<boolean> {
    const can_promote = await promotePrecheck({
      user_id: current_account.user_id,
      directory_id: entry.id
    })
      .unwrap()
      .then((response: PromotePrecheckApiResponse) => {
        !response.can_be_promoted &&
          handlePageErrorMessage(DriveErrorMessages.fs_error_promote_precheck_user, { stack_message: DriveErrorMessages.fs_error_promote_precheck, stack_error: response });
        return response.can_be_promoted;
      })
      .catch((stack_error: unknown) => {
        handlePageErrorMessage(DriveErrorMessages.error_sharing, { stack_message: DriveErrorMessages.fs_error_promote_precheck, stack_error });
        return false;
      });
    return can_promote;
  }

  // Description: Initialize a collection for copying all children nodes into it
  // NOTE: Modidying this call to initialize collection as a V2 collection directly:
  // https://github.com/PreVeil/core/blob/dev/collection_server/docs/filesync.md#version-2-collections-with-acl-nodes
  async function initializeV2Collection(directory_info: DirectoryInfoEntity, indexed_snapshot: IndexedSnapshot) {
    const symmetric_key = (!!directory_info.wrapped_dir_key && !!permissions) ? await getDirSymmKey(directory_info.wrapped_dir_key, current_account, permissions) : undefined;
    const collection_id = new UUID();
    const owner_grantee = getOwnerGrantee();
    // NOTE: Force passing the symmetric_key even if its optional
    const collection_data = !!symmetric_key ? await Collection.getNewV2CollectionData(current_account, collection_id, directory_info.name, symmetric_key) : undefined;
    const new_collection_info = buildNewCollectionInfo(collection_id, collection_data);
    const grants_and_permissions = await createGrantsAndPermissions(owner_grantee, collection_data?.node_permissions);
    const drive_request = !!new_collection_info ?
      await CollectionFilesyncAPI.initV2Collection(
        current_account,
        collection_id.Bytes(),
        new_collection_info.id,
        new_collection_info.encrypted_access_name,
        new_collection_info.encrypted_name, // NOTE: Can be left empty (optional), but needed for Bulkupdate
        new_collection_info.maintainer_scoped_name,
        new_collection_info.version,
        new_collection_info.wrapped_key,
        new_collection_info.keys,
        grants_and_permissions.permissions,
        grants_and_permissions.grants
      ) : null;
    async function callback(message: FSMessage) {
      SetProgress(ProgressValues.initializeV2Collection);
      if (message.getStatus() === FSStatus.OK && !!new_collection_info) {
        getDefaultPermissions(new_collection_info.collection_id.B64());
        fetchNewGrants(directory_info, new_collection_info, indexed_snapshot);
      } else {
        handlePageErrorMessage(DriveErrorMessages.error_sharing, { stack_message: DriveErrorMessages.error_initializing_collection }, message);
      }
    }
    handleSendRequest(drive_request, callback, DriveErrorMessages.error_initializing_collection);
  }

  // Description: Fetch new Grants after initializing the collection
  async function fetchNewGrants(directory_info: DirectoryInfoEntity, new_collection_info: NewCollectionInfo, indexed_snapshot: IndexedSnapshot) {
    const collection_id = new_collection_info.collection_id.B64();
    const node_id = new_collection_info.id;
    async function callback(message: FSMessage) {
      SetProgress(ProgressValues.fetchNewGrants);
      const maintainer_dir = message.getDir();
      const acl_list = maintainer_dir?.getAclList();
      if (message.getStatus() === FSStatus.OK && !!acl_list) {
        const current_grants = GrantSet.getCurrentUsersGrantSets(collection_id, node_id, acl_list, current_account.user_id);
        const acl_reader = !!current_grants ? GrantSet.init(current_grants).grant(Node.ACLRole.READER) : undefined;
        const params = !!acl_reader ? await buildBulkUpdatesMessage(directory_info, new_collection_info, indexed_snapshot, acl_reader) : undefined;
        const chunked_params = !!params ? chunkBulkUpdateParams(params) : undefined;
        // NOTE: Continue to createLinkMakeShareable if no blocks to update
        !!chunked_params ? bulkUpdateCollection(
          directory_info,
          new_collection_info,
          indexed_snapshot,
          chunked_params.creates,
          chunked_params.blocks,
          chunked_params.updates,
          !!current_grants ? [current_grants] : undefined) :
          createLinkMakeShareable(directory_info, new_collection_info, snapshot_seq_ref.current);
      } else {
        handlePageErrorMessage(DriveErrorMessages.error_sharing, { stack_message: DriveErrorMessages.error_fetching_acl_history }, message);
      }
    };
    getDirectoryInformation(collection_id, node_id, callback, new_collection_info.new_permissions.permision_set);
  }

  // Description: Assemble the chunks of Update, create, block and return if not empty
  function chunkBulkUpdateParams(params: BulkUpdateParams): ChunkBulkUpdateParams | undefined {
    const creates = _.chunk(params.create, DriveLimits.DRIVE_BULK_UPDATE_LIMIT_PER_CALL);
    const blocks = _.chunk(params.blocks, DriveLimits.DRIVE_BULK_UPDATE_LIMIT_PER_CALL);
    const updates = _.chunk(params.updates, DriveLimits.DRIVE_BULK_UPDATE_LIMIT_PER_CALL);
    const update_counts = creates.length + blocks.length + updates.length;
    return update_counts > 0 ? { creates, blocks, updates } : undefined;
  }

  // Description: Bulk update and create items within the new collection
  async function bulkUpdateCollection(
    directory_info: DirectoryInfoEntity,
    new_collection_info: NewCollectionInfo,
    indexed_snapshot: IndexedSnapshot,
    creates: FSRequest.Create[][],
    blocks: FSRequest.BulkUpdate.Block[][],
    updates: FSRequest.Update[][],
    current_grants?: GrantSetType[],
    index: number = 0) {
    const new_collection_id = new_collection_info.collection_id.B64();
    const ps = new_collection_info.new_permissions.permision_set;
    const drive_request: DriveRequest | null = creates.length > 0 ?
      await CollectionFilesyncAPI.bulkUpdate(current_account, new_collection_id, creates.shift() || [], [], [], [], [], ps, current_grants) :
      (blocks.length > 0) ?
        await CollectionFilesyncAPI.bulkUpdate(current_account, new_collection_id, [], [], [], blocks.shift() || [], [], ps, current_grants) :
        (updates.length > 0) ?
          await CollectionFilesyncAPI.bulkUpdate(current_account, new_collection_id, [], updates.shift() || [], [], [], [], ps, current_grants) : null;
    async function callback(message: FSMessage) {
      SetProgress(Number(ProgressValues.bulkUpdateCollection + index));
      if (message.getStatus() === FSStatus.OK) {
        (creates.length > 0 || blocks.length > 0 || updates.length > 0) ?
          bulkUpdateCollection(directory_info, new_collection_info, indexed_snapshot, creates, blocks, updates, current_grants, Number(index + 1)) :
          createLinkMakeShareable(directory_info, new_collection_info, snapshot_seq_ref.current);
      } else {
        handlePageErrorMessage(DriveErrorMessages.error_sharing, { stack_message: DriveErrorMessages.error_bulk_updating_collection }, message);
      }
    }
    handleSendRequest(drive_request, callback, DriveErrorMessages.error_bulk_updating_collection);
  }

  // Description: Use MAKE_SHAREABLE to turn the shared dir into a link to the new collection. 
  // The server checks that the provided seq is at least as great as the dir's max rev_id. 
  // The server will also set is_complete to true at this point.
  // https://github.com/PreVeil/core/blob/dev/collection_server/docs/filesync.md#make_shareable
  async function createLinkMakeShareable(directory_info: DirectoryInfoEntity, new_collection_info: NewCollectionInfo, new_collection_seq: number) {
    const new_collection_id = new_collection_info.collection_id.B64();
    const link_id_uuid = new UUID();
    const link_version = randomBytes(32);
    const drive_request = !!permissions ? await CollectionFilesyncAPI.makeShareable(
      current_account,
      permissions,
      directory_info.collection_id,
      directory_info.id,
      directory_info.version,
      link_id_uuid.Bytes(),
      link_version,
      new_collection_id,
      new_collection_seq
    ) : null;
    async function callback(message: FSMessage) {
      SetProgress(ProgressValues.createLinkMakeShareable);
      if (message.getStatus() === FSStatus.OK) {
        (is_web && !!current_account.org_info) && grantLogKeyToAdminGroup(directory_info, new_collection_info);
        // NOTE: go directly to the right share path
        handleShareV2({
          collection_id: new_collection_info.collection_id.B64(),
          id: link_id_uuid.B64(),
          maintainer_id: new_collection_info.id, // NOTE: Needed for ShareV2App
          collection_name: entry.name, // NOTE: the new collection name is the entry name
          permission_set: PermissionSet.buildV2CollectionPermissionSet(new_collection_info.new_permissions), // Pass only the required permissions
          type: DriveEntryType.LINK,
          grantees: await getGrantees(false),
          acl: !!change_set ? NodePermissionTypeToACLRoleType[change_set.permission_type] : NodePermissionTypeToACLRoleType.read_only,
          is_promote: true
        }, ProgressValues.shareV2);
      } else {
        handlePageErrorMessage(DriveErrorMessages.error_sharing, { stack_message: DriveErrorMessages.error_making_shareable }, message);
      }
    }
    handleSendRequest(drive_request, callback, DriveErrorMessages.error_making_shareable);
  }

  // Description: Grants the log key to the admin group
  async function grantLogKeyToAdminGroup(directory_info: DirectoryInfoEntity, new_collection_info: NewCollectionInfo) {
    const log_viewer = new_collection_info.new_permissions.permission(FSRole.LOG_VIEWER);
    const collection_id = new_collection_info.collection_id.String().toLowerCase();
    const grant_data = (!!org_info_ref.current && !!log_viewer) ?
      await getLogKeyGrantData(log_viewer, org_info_ref.current, collection_id, directory_info.name) : undefined;
    const drive_request = !!grant_data ? await CollectionFilesyncAPI.grantAccess(
      current_account,
      new_collection_info.new_permissions,
      new_collection_info.collection_id.B64(),
      [grant_data.role], null, [grant_data.group]) : null;

    // NOTE: Only log if errors - success is called after shareV2
    async function callback(message: FSMessage) {
      message.getStatus() !== FSStatus.OK &&
        handlePageErrorMessage(DriveErrorMessages.error_granting_admin_logkey, { stack_message: DriveErrorMessages.error_granting_admin_logkey }, message, MessageHandlerDisplayType.logger);
    }
    handleSendRequest(drive_request, callback, DriveErrorMessages.error_granting_admin_logkey);
  }

  // Description:  Build Log Key Grant Data for granting the admin group 
  async function getLogKeyGrantData(log_viewer: Permission, _org_info: JSONOrgInfo, collection_id: string, collection_name: string):
    Promise<LogKeyGrantAccessData> {
    const group_id = new UUID({ uuid: _org_info.admin_group_id });
    const admin_group_public_key = await KeyFactory.deserializePublicUserKey(Helpers.b64Decode(_org_info.admin_group_key));
    const group_key_version = admin_group_public_key.key_version;
    const encoded_name = await admin_group_public_key.public_key.seal(Helpers.utf8Encode(collection_name));
    const unwrapped_key = await log_viewer.key(current_account);
    const wrapped_key = await admin_group_public_key.public_key.seal(unwrapped_key.serialize());
    const key_hash = Helpers.hexEncode(await Helpers.sha256Checksum(wrapped_key)).toLowerCase();
    const canonical_string = `${group_id.String().toLowerCase()},${group_key_version},${collection_id},LOG_VIEWER,${log_viewer.key_version},${key_hash}`;
    const signature = await current_account.user_key.signing_key.sign(Helpers.utf8Encode(canonical_string));
    return PermissionSet.getGroupLogViewerGrantAccess(log_viewer, encoded_name, wrapped_key, group_key_version, signature, group_id.Bytes());
  }

  // Description: Get Directory Information for various calls
  async function getDirectoryInformation(collection_id: string, node_id: string, callback: DriveCallbackAsyncFunction<unknown>, _permision_set?: PermissionSetType) {
    if (_permision_set) {
      const _request = await CollectionFilesyncAPI.getDirectoryInformationRequest(collection_id, node_id);
      const drive_request = await DriveRequest.initializeRequest(_request, current_account, _permision_set);
      handleSendRequest(drive_request, callback, DriveErrorMessages.error_fetching_maintainer_directory);
    } else {
      handlePageErrorMessage(DriveErrorMessages.error_sharing, { stack_message: `getDirectoryInformation: ${DriveErrorMessages.error_fetching_permissions}` });
    }
  }

  // -------------------------------------------------------------------------------------------------------
  // For Sharing Directories under default collection - Initialize V2 collection
  // -------------------------------------------------------------------------------------------------------
  // Description: Return owner as main grantee 
  function getOwnerGrantee(): Grantee[] {
    return [{
      user_id: current_account.user_id,
      key_version: current_account.public_user_key.key_version,
      public_user_key: current_account.public_user_key,
      view_only: false,
      acl: NodePermissionTypeToACLRoleType.edit_and_share
    }];
  }

  //  Description: Gather Grantees data for grants_and_permissions 
  async function getGrantees(include_current: boolean = true): Promise<Grantee[]> {
    const grantees: Grantee[] = include_current ? getOwnerGrantee() : [];
    if (!!change_set && !!contacts) {
      const acl = NodePermissionTypeToACLRoleType[change_set.permission_type] || NodePermissionTypeToACLRoleType.read_only;
      const expiration = !!change_set.expiration && dayjs(change_set.expiration).isValid() ? dayjs.utc(change_set.expiration).local().format() : undefined;
      // NOTE: Resolve groups and remove duplicates
      const user_profiles: UserProfile[] = _.uniqBy(resolveGroupToUserProfile(change_set.users), "address");
      await Promise.all(_.map(user_profiles, async (user: UserProfile) => {
        const user_id = user.address;
        if (!isSameUser(user_id, current_account.user_id)) {
          const cs_user = _.find(contacts.cs_profiles, (profile) => isSameUser(profile.user_id, user_id));
          if (cs_user) {
            const _user = await parseCollectionUserToPublicKeyUser(cs_user);
            if (!!_user) {
              const _grantee = {
                ..._user,
                ...{
                  view_only: change_set.permission_type === NodePermissionType.view_only,
                  expiration,
                  acl
                }
              };
              grantees.push(_grantee);
            }
          }
        }
        return user;
      }));
    }

    return grantees;
  }

  //  Description: Create Node Grants and Permissions 
  async function createGrantsAndPermissions(grantees: Grantee[], node_keys?: NodeKeys):
    Promise<GrantsAndPermissions> {
    const node_grants: Node.Grant[] = [];
    const node_permissions: Node.Permission[] = [];
    const new_node_keys: NodeKeys = {};
    for (const role_string in Node.ACLRole) {
      const role = Node.ACLRole[role_string as keyof Node.ACLRoleMap];
      const user_key = !!node_keys ? node_keys[role] : await KeyFactory.newUserKey({ style: EncryptionStyle.DRIVE });
      const wrapped_key = await current_account.public_user_key.public_key.seal(user_key.serialize());
      const _grant = await buildGrant(role, grantees, user_key.serialize());
      const _permission = await ACL.buildPermission(role, user_key, wrapped_key);
      node_grants.push(_grant);
      node_permissions.push(_permission);
      new_node_keys[role] = user_key;
    }
    return { grants: node_grants, permissions: node_permissions, node_keys: new_node_keys };
  }

  // Description: Build root dir grants 
  async function buildGrant(role: ACLRoleType, grantees: Grantee[], serialized_key: Uint8Array, key_version: number = 0): Promise<Node.Grant> {
    const _grant = new Node.Grant();
    _grant.setRole(role);
    _grant.setKeyVersion(0);
    const grantee_list: Node.Grantee[] = await Promise.all(_.map(grantees, async (grantee: Grantee) => await buildGrantee(grantee, serialized_key)));
    _grant.setGranteesList(grantee_list);
    return _grant;
  }

  // Description: Build the request to create, update and insert blocks
  async function buildBulkUpdatesMessage(directory_info: DirectoryInfoEntity, new_collection_info: NewCollectionInfo, indexed_snapshot: IndexedSnapshot, acl_reader: Grant):
    Promise<BulkUpdateParams | undefined> {
    try {
      const readers = getUpdateReaders(new_collection_info.new_permissions);
      if (!!readers && !!permissions) {
        const reader_key = await acl_reader.key(current_account);
        const old_reader_key = await readers.old_reader.key(current_account);
        const snapshot_handler = new Snapshot(indexed_snapshot, readers.default_collection_id, new_collection_info.encrypted_name);
        const current_dir = indexed_snapshot.dirs.get(directory_info.id);
        // NOTE: Validate tree has no shared children subfolders and that current_dir is not undefined
        if (!snapshot_handler.containsLink() && !!current_dir) {
          await snapshot_handler.buildSnapNodes(reader_key, old_reader_key, current_dir, new_collection_info.id, new_collection_info.version);
          return await snapshot_handler.buildUpdateMessage(current_account, permissions, reader_key);
        } else {
          // NOTE: Throw error if there are links within the snapshot
          handlePageErrorMessage(DriveErrorMessages.error_sharing_directories_with_links, { stack_message: DriveErrorMessages.error_sharing_directories_with_links });
        }
      } else {
        handlePageErrorMessage(DriveErrorMessages.error_sharing, { stack_message: DriveErrorMessages.error_fetching_permissions });
      }
    } catch (stack_error: unknown) {
      handlePageErrorMessage(DriveErrorMessages.error_sharing, { stack_error, stack_message: DriveErrorMessages.error_fetching_permissions });
    }
  }

  // Description: validate and get reader permissions for bulk update messages
  function getUpdateReaders(new_permissions: PermissionSet): { new_reader: Permission, old_reader: Permission, default_collection_id: UUID } | undefined {
    const old_permissions = !!permissions ? PermissionSet.init(permissions) : undefined;
    const old_reader = old_permissions?.permission(FSRole.READER);
    const new_reader = new_permissions.permission(FSRole.READER);
    if (!!permissions && !!old_reader && !!new_reader) {
      return {
        new_reader,
        old_reader,
        default_collection_id: permissions.collection_id
      };
    }
  }

  // -------------------------------------------------------------------------------------------------------
  // For Sharing V2 Collections and Directories under V2 collections (Acl Nodes)
  // -------------------------------------------------------------------------------------------------------
  // Description: Share V2 collections if entry is already a collection V2 from before OR from promoteDirectory step
  // Handle both TYPE: DIR
  // NEED FINAL STEP for sharing Folders under default Collection
  // NEED the node_identifier of the directory (not link), permissions new or current
  // STEPS
  // 1. GRANT ACCESS TO NEW USERS: GrantAccess passing both FSRequest.Grant.Role[] and FSRequest.Grant.User[]
  //    - Get Access Permission (class) - access_permission
  //    - Get ACLRoles as in [0,1,2,3]
  //    - Build FSRequest.Grant.Role[] (access_permission_roles) using only access_permission 
  //    - Get View_only info from changeset (?) - TBD
  //    - Get Users in changeset  =>  Build FSRequest.Grant.User[]
  // 2. Get ACL_Tree for DirList
  //    - Retrieve ACL Tree for getting nodes grants and building the grants updates
  // 3. Send bulk updates with collection_id, updates, permissionset, drive_node_grants
  //    - Build Updates
  function handleShareV2(share_data: ShareData, progress: number) { // directory_info: DirectoryInfoEntity
    SetProgress(progress);
    share_data.grantees.length > 0 ?
      ((is_web) ? shareV2(share_data) : shareV2App(share_data)) :
      change_set?.unclaimed && change_set.unclaimed.length > 0 ? handleSuccess(share_data) :
        handlePageErrorMessage(DriveErrorMessages.error_sharing, { stack_message: DriveErrorMessages.fs_error_sharing_v2 });
  }

  // Description: Grant users access - this step sends the Shared invites
  async function shareV2(share_data: ShareData) {
    const grant_access_data = await buildGrantAccessData(share_data);
    const drive_request = (!!grant_access_data) ?
      await CollectionFilesyncAPI.grantAccess(current_account, share_data.permission_set, share_data.collection_id,
        grant_access_data.roles, grant_access_data.grant_users) : null;

    async function callback(message: FSMessage) {
      SetProgress(ProgressValues.shareV2);
      (message.getStatus() === FSStatus.OK) ?
        // Determine which ACL grants need to be applied based on the ACL tree
        getACLChanges(share_data) :
        handlePageErrorMessage(DriveErrorMessages.error_granting_access_key, { stack_message: DriveErrorMessages.error_granting_access_key }, message, MessageHandlerDisplayType.logger);
    }
    handleSendRequest(drive_request, callback, DriveErrorMessages.error_granting_access_key);
  }

  // Description: Generates the list of Updates to ACL nodes
  // We scope the ACL tree to the ACL node so that all nodes in the returned tree are underneath it.
  // That means the nodes in the tree will already be the nodes we need to add grants for.
  async function getACLChanges(share_data: ShareData) {
    const request = await CollectionFilesyncAPI.getACLTreeRequest(share_data.collection_id, share_data.maintainer_id);
    const drive_request = await DriveRequest.initializeRequest(request, current_account, share_data.permission_set);
    async function callback(message: FSMessage) {
      SetProgress(ProgressValues.getACLChanges);
      const acl_tree = message.getAclTree();
      const drive_node_grants: GrantSetType[] = [];
      if (message.getStatus() === FSStatus.OK && !!acl_tree) {
        const updates = _.compact(await Promise.all(_.map(acl_tree.getDirsList(), async (dir: FSMessage.Dir) => {
          const node_grants = dir.getAclList();
          const _grants = GrantSet.getCurrentUsersGrantSets(share_data.collection_id, dir.getMaintainerId_asB64() || dir.getId_asB64(), node_grants, current_account.user_id);
          !!_grants && drive_node_grants.push(_grants);
          return await buildACLNodeUpdate(share_data, node_grants, dir.getId(), dir.getVersion(), dir.getWrappedDirKey());
        })));
        handleBulkUpdate(_.chunk(updates, DriveLimits.DRIVE_BULK_UPDATE_LIMIT_PER_CALL), share_data, drive_node_grants);
      } else {
        handlePageErrorMessage(DriveErrorMessages.error_sharing, { stack_message: DriveErrorMessages.error_fetching_acl_tree });
      }
    }
    handleSendRequest(drive_request, callback, DriveErrorMessages.error_fetching_acl_tree);
  }

  // Description: Send the Bulk Updates to update the ACL Grants on all nodes
  // NOTE: If we had more than 500 (DRIVE_BULK_UPDATE_LIMIT_PER_CALL), then we recursively update with the remaining updates
  async function handleBulkUpdate(
    update_chunks: FSRequest.Update[][],
    share_data: ShareData,
    grant_set?: GrantSetType[],
    index: number = 0,
    handle_success_callback: boolean = true // NOTE: Set to false for Upgrades ACL Nodes to grant access before handleSuccess
  ) {
    const drive_request = await CollectionFilesyncAPI.bulkUpdate(current_account, share_data.collection_id, [], update_chunks[index], [], [], [],
      share_data.permission_set, grant_set);
    const new_index = index + 1;
    async function callback(message: FSMessage) {
      // only set it on the first call
      !index && SetProgress(handle_success_callback ? ProgressValues.handleBulkUpdate : ProgressValues.upgradeBulkUpdate);
      if (message.getStatus() === FSStatus.OK) {
        (update_chunks.length > new_index) ?
          handleBulkUpdate(update_chunks, share_data, grant_set, new_index, handle_success_callback) :
          handle_success_callback ? handleSuccess(share_data) : shareV2(share_data);
      } else {
        handlePageErrorMessage(DriveErrorMessages.error_sharing, { stack_message: DriveErrorMessages.error_bulk_updating_collection }, message);
      }
    }
    handleSendRequest(drive_request, callback, DriveErrorMessages.error_bulk_updating_collection);
  }

  // Description: Takes an ACL node and generates the new grants based on new grantees in an Update // Legacy: _aclGrantsUpdate
  async function buildACLNodeUpdate(
    share_data: ShareData,
    node_grants: Node.Grant[],
    id: string | Uint8Array,
    version: string | Uint8Array,
    fs_wrapped_key?: FSWrappedKey
  ): Promise<FSRequest.Update | undefined> {
    const grantees = share_data.grantees;
    const acl_roles = share_data.acl;
    const collection_id = share_data.collection_id;
    const maintainer_id = share_data.maintainer_id || share_data.id;
    // Build current_user_grants from grants: Node.Grant[] - use GrantSet class
    const current_grants = GrantSet.getCurrentUsersGrantSets(collection_id, maintainer_id, node_grants, current_account.user_id);
    if (!!current_grants && current_grants.grants.length > 0) {
      const latest_grants = GrantSet.latestGrants(current_grants.grants);
      const _grants = _.map(acl_roles, async (role: ACLRoleType) => {
        const local_grant = _.find(latest_grants, (_grant: Grant) => (_grant.role === role)); // current_role_grant
        const local_grantee_key = !!local_grant ? current_account.getUserKeyWithVersion(local_grant.user_key_version) : current_account.user_key;
        if ((!!local_grant?.wrapped_key && !!local_grantee_key)) {
          const current_serialized_key = await local_grantee_key.encryption_key.unseal(local_grant.wrapped_key); // LEGACY: opened_grant
          return await buildACLNodeGrant(role, grantees, current_serialized_key, local_grant?.role_key_version);
        }
      });
      const node_grant_list = _.compact(await Promise.all(_grants));
      const acl = new FSRequest.Update.Change();
      acl.setOp(FSRequest.Update.Op.ACL);
      acl.setNewNodeGrantsList(node_grant_list);
      const update = new FSRequest.Update();
      update.setId(id);
      update.setChangesList([acl]);
      update.setOldVersion(version);
      update.setNewVersion(randomBytes(32));
      return update;
    }
  }

  // Description: Build new grants for users 
  async function buildACLNodeGrant(role: ACLRoleType, grantees: Grantee[], serialized_key: Uint8Array, key_version: number = 0): Promise<Node.Grant> {
    const grant = new Node.Grant();
    grant.setRole(role);
    grant.setKeyVersion(key_version);
    const grantee_list: Node.Grantee[] = await Promise.all(_.map(grantees, async (grantee: Grantee) => await buildGrantee(grantee, serialized_key)));
    grant.setGranteesList(grantee_list);
    return grant;
  }

  // Description: Build Grantee
  async function buildGrantee(grantee: Grantee, serialized_key: Uint8Array): Promise<Node.Grantee> {
    const content = await grantee.public_user_key.public_key.seal(serialized_key);
    const new_grantee = new Node.Grantee();
    new_grantee.setUserId(grantee.user_id);
    const grant = new SealedContent();
    grant.setKeyVersion(grantee.key_version);
    grant.setContent(content);
    new_grantee.setGrant(grant);
    // NOTE: Set view_only and expiration time if grantee has changes or previous values if not
    new_grantee.setViewOnly(grantee.view_only);
    !!grantee.expiration && new_grantee.setExpirationTime(grantee.expiration);
    return new_grantee;
  }

  // -------------------------------------------------------------------------------------------------------
  // ACL NODES: For Sharing Directories children of V2 collection nodes (acl nodes) 
  // -------------------------------------------------------------------------------------------------------
  // Description: Upgrade a directory under V2 collections to ACL nodes
  // NOTE: on success => shareV2
  // RefreshGrantsAndPermissions (legacy) > Returns { grants, permissions, read_key, wrapped_key }
  // FETCH_Snapshot return > FSMessage.Snapshot
  //   - GET getIndexedSnapshot
  //   - BUILD FSRequest.Update.Change
  //   - BUILD FSRequest.Update.Op.REWRAP
  //   - subtreeRewraps
  //   - BufferedBulkUpdates with UPDATE[] and drive_node_grants
  //   - handleSuccess
  // Description: Get the directory information for rebuilding this
  async function upgradeToAcl(indexed_snapshot: IndexedSnapshot) {
    const collection_id = collection_info.collection_id;
    // Need the directory info: version, wrapped dir key, name, and dir id
    async function callback(message: FSMessage) {
      SetProgress(ProgressValues.upgradeToAcl);
      const directory = message.getDir();
      if (message.getStatus() === FSStatus.OK && !!directory) {
        const directory_info: DirectoryInfoEntity = {
          id: directory.getId_asB64(),
          collection_id,
          name: entry.name,
          version: directory.getVersion_asB64(),
          wrapped_dir_key: directory.getWrappedDirKey()
        };
        is_web ? getCurrentGrantees(directory_info, indexed_snapshot) : upgradeToAclApp(directory_info);
      } else {
        handlePageErrorMessage(DriveErrorMessages.error_sharing, { stack_message: DriveErrorMessages.error_fetching_directory }, message);
      }
    }
    getDirectoryInformation(collection_id, entry.id, callback, permissions);
  }

  // Description: Fetch Maintainer node grantees
  async function getCurrentGrantees(directory_info: DirectoryInfoEntity, indexed_snapshot: IndexedSnapshot) {
    async function callback(message: FSMessage) {
      SetProgress(ProgressValues.getCurrentGrantees);
      const directory = message.getDir();
      if (message.getStatus() === FSStatus.OK && !!directory) {
        const grantees = await parseCurrentGrantees(directory.getAclList());
        handleUpgradeToAcl(directory_info, indexed_snapshot, grantees);
      } else {
        handlePageErrorMessage(DriveErrorMessages.error_sharing, { stack_message: DriveErrorMessages.error_fetching_acl_list }, message);
      }
    }

    !!collection_info.maintainer_id ?
      getDirectoryInformation(collection_info.collection_id, collection_info.maintainer_id, callback, permissions) :
      handlePageErrorMessage(DriveErrorMessages.error_sharing, { stack_message: DriveErrorMessages.error_fetching_acl_list });
  }

  async function parseCurrentGrantees(acl_list: Node.Grant[]): Promise<Grantee[]> {
    const _grantees = !!acl_list ? new ACL(acl_list, "id", current_account.user_id).grantees : null;
    // Fetch the keys for those users
    const cs_users = await findUsers({
      account_ids: Account.getAccountIdentifiers(current_account),
      body: {
        spec: _.map(_grantees, (grantee) => ({ user_id: grantee.user_id, key_version: -1 }))
      }
    }).unwrap();
    const existing_users = await Promise.all(cs_users.users.map(async (cs_user: CollectionServerUser) => await parseCollectionUserToPublicKeyUser(cs_user)));
    const grantees: Grantee[] = [];
    _.map(existing_users, (user: PublicKeyUser) => {
      const grantee = _.find(_grantees, (profile) => isSameUser(profile.user_id, user.user_id));
      if (!!grantee) {
        grantees.push({
          ...user,
          view_only: grantee.view_only,
          expiration: grantee.expiration,
          acl: grantee.acl
        });
      }
    });
    return grantees;
  }

  // Description: Upgrade current directory to ACL Node and make it its own maintaner (id === maintainer_id)
  async function handleUpgradeToAcl(directory_info: DirectoryInfoEntity, indexed_snapshot: IndexedSnapshot, maintainer_grantees: Grantee[]) {
    if (!!permissions && !!grants) {
      const grants_set = GrantSet.init(grants);
      const id = collection_info.id;
      const current_directory = indexed_snapshot.dirs.get(id);
      const grants_and_permissions = await createGrantsAndPermissions(maintainer_grantees);
      const dir_symm_key = directory_info.wrapped_dir_key;
      const grant = !!grants ? GrantSet.getGrantbyRole(grants, dir_symm_key?.getKeyVersion(), Node.ACLRole.READER) : null;
      const reader_user_key = !!grant ? await grant.key(current_account) : undefined;
      const symmetric_key = !!dir_symm_key ? await getDirSymmKey(dir_symm_key, current_account, permissions, reader_user_key) : undefined;
      if (!!current_directory && !!grants_and_permissions.node_keys && !!symmetric_key && !!reader_user_key) {
        const reader_key = grants_and_permissions.node_keys[Node.ACLRole.READER];
        const encrypted_name = await reader_key.public_user_key.public_key.seal(Helpers.utf8Encode(directory_info.name));
        const wrapped_key = await reader_key.public_user_key.public_key.seal(symmetric_key.serialize());

        const acl = new FSRequest.Update.Change();
        acl.setOp(FSRequest.Update.Op.ACL);
        acl.setNewNodeGrantsList(grants_and_permissions.grants);
        acl.setNewNodePermissionsList(grants_and_permissions.permissions);
        acl.setMaintainerId(id);

        // The node is now its own maintainer so we wrap the name with the node's new reader key
        const sealed_content = new SealedContent();
        sealed_content.setKeyVersion(reader_key.key_version);
        sealed_content.setContent(encrypted_name);
        acl.setMaintainerScopedName(sealed_content);

        const rewrap = new FSRequest.Update.Change();
        rewrap.setOp(FSRequest.Update.Op.REWRAP);
        rewrap.setReadKeyNodeId(id);
        // Build the rewrap change for the node becoming an ACL node so that its dir key is wrapped in the right key
        const wrapped_dir_key = new FSWrappedKey();
        wrapped_dir_key.setKeyVersion(reader_key.key_version);
        wrapped_dir_key.setWrappedKey(wrapped_key);
        rewrap.setNewDirKey(wrapped_dir_key);

        // Put it all together for the update to the directory itself
        const dir_update = new FSRequest.Update();
        dir_update.setId(id);
        dir_update.setChangesList([acl, rewrap]);
        dir_update.setOldVersion(current_directory.getVersion_asB64());
        dir_update.setNewVersion(randomBytes(32));

        // Next we need to create rewrap changes for all nodes the directory is going to become the maintainer for
        const subtree_rewraps: FSRequest.Update[] = await subtreeRewrap(
          current_directory,
          indexed_snapshot,
          collection_info.id,
          reader_user_key,
          grants_and_permissions.node_keys,
          grants_set);
        const updates = [dir_update].concat(subtree_rewraps);
        // For the On Complete call back 
        const new_share_data = {
          collection_id: collection_info.collection_id,
          id,
          maintainer_id: id, // new node as maintainer
          collection_name: collection_info.collection_name,
          permission_set: permissions,
          type: entry.type,
          grantees: await getGrantees(false),
          acl: !!change_set ? NodePermissionTypeToACLRoleType[change_set.permission_type] : NodePermissionTypeToACLRoleType.read_only,
          is_promote: true
        };

        handleBulkUpdate(_.chunk(updates, DriveLimits.DRIVE_BULK_UPDATE_LIMIT_PER_CALL), new_share_data, [grants_set.grant_set], 0, false);
      }
    } else {
      handlePageErrorMessage(DriveErrorMessages.error_sharing, { stack_message: DriveErrorMessages.error_fetching_permissions });
    }
  }

  // Description: Wrap Directories under the node 
  async function subtreeRewrap(
    directory_node: FSMessage.Dir,
    indexed_snapshot: IndexedSnapshot,
    new_maintainer_id: string,
    old_reader_key: AppUserKey,
    node_keys: NodeKeys,
    _grants: GrantSet
  ): Promise<FSRequest.Update[]> {
    const entries = directory_node.getEntriesList();
    const children_updates: FSRequest.Update[] = _.compact(await Promise.all(_.map(entries, async (entry: FSMessage.Dir.Entry) => {
      const child_entry = entry.getType() === FSType.FILE ? indexed_snapshot.files.get(entry.getId_asB64()) :
        entry.getType() === FSType.DIR ? indexed_snapshot.dirs.get(entry.getId_asB64()) : null;
      if (!!child_entry && child_entry.getAclList().length === 0) {
        const changes = entry.getType() === FSType.FILE ? await buildFileRewrap(child_entry as FSMessage.File, new_maintainer_id, old_reader_key, node_keys) :
          await buildDirectoryWrap(child_entry as FSMessage.Dir, new_maintainer_id, node_keys, _grants);
        const update = new FSRequest.Update();
        update.setChangesList([changes]);
        update.setId(child_entry.getId());
        update.setOldVersion(child_entry.getVersion());
        update.setNewVersion(randomBytes(32));
        return update;
      };
    })));

    // recursively rewrap children of directories as well.
    const children_directories: FSMessage.Dir[] = _.compact(_.map(entries, entry => indexed_snapshot.dirs.get(entry.getId_asB64())));
    const all_updates: FSRequest.Update[][] = _.compact(await Promise.all(
      _.map(children_directories, async (_directory_node: FSMessage.Dir) => {
        return _directory_node.getAclList().length === 0 ? await subtreeRewrap(_directory_node, indexed_snapshot, new_maintainer_id, old_reader_key, node_keys, _grants) : null;
      })));

    const updates = _.flatten(all_updates);
    return children_updates.concat(updates);
  }

  // Description: Rewrap the Nodes by type and return a FSRequest.Update.Chang
  // Description: Wrap the children directory nodes
  // NOTES:  In addition to making sure we have the dir we need to make sure the dir is not an ACL node
  // If it is an ACL node, then it has its own ACL that will be unchanged because it is its own maintainer
  async function buildDirectoryWrap(
    directory: FSMessage.Dir,
    new_maintainer_id: string,
    keys: NodeKeys,
    _grants: GrantSet
  ): Promise<FSRequest.Update.Change> {
    const change = new FSRequest.Update.Change();
    const wrapped_dir_key = directory.getWrappedDirKey();
    change.setOp(FSRequest.Update.Op.REWRAP);
    const wrapping_key = await _grants.grant(Node.ACLRole.READER, wrapped_dir_key?.getKeyVersion())?.key(current_account);
    const new_wrapping_key = keys[Node.ACLRole.READER];

    if (!!wrapping_key && !!wrapped_dir_key) {
      const unwrapped_dir_key = await wrapping_key.encryption_key.unseal(wrapped_dir_key.getWrappedKey_asU8() || new Uint8Array());
      const new_wrapped_key = await new_wrapping_key.public_user_key.public_key.seal(unwrapped_dir_key);
      const new_wrapped_dir_key = new FSWrappedKey();
      new_wrapped_dir_key.setWrappedKey(new_wrapped_key);
      new_wrapped_dir_key.setKeyVersion(new_wrapping_key.key_version);
      change.setNewDirKey(new_wrapped_dir_key);
    } else {
      console.error("There was a problem with the sub directory new wrapped key");
    }
    change.setReadKeyNodeId(new_maintainer_id);
    return change;
  }

  // Description: Wrap the children file nodes
  async function buildFileRewrap(
    file: FSMessage.File,
    new_maintainer_id: string,
    old_reader_key: AppUserKey,
    keys: NodeKeys
  ): Promise<FSRequest.Update.Change> {
    // We need to take each block and rewrap its key to the new key
    const block_updates = await Promise.all(file.getDataList().map(async block => {
      const block_update = new FSRequest.Update.BlockUpdate();
      const block_id = block.getId();
      // const wrapping_key = await maintainer_reader_grant_set.grant(Node.ACLRole.READER, block.getWrappedKey()?.getKeyVersion())?.key(current_account);
      const new_wrapping_key = keys[Node.ACLRole.READER];
      if (!!block_id) {
        // Wrap the block key under the new ACL reader key instead of the old maintainer's key
        const unwrapped_block_key = await old_reader_key.encryption_key.unseal(block.getWrappedKey()?.getWrappedKey_asU8() || new Uint8Array());
        const rewrapped_block_key = await new_wrapping_key.public_user_key.public_key.seal(unwrapped_block_key || new Uint8Array());
        const wrapped_key = new FSWrappedKey();
        wrapped_key.setWrappedKey(rewrapped_block_key);
        wrapped_key.setKeyVersion(new_wrapping_key.key_version);
        block_update.setRewrappedKey(wrapped_key);

        // There are two cases with block updates
        // 1. In the case where it's a basic block we can just pass the block id along with no changes
        // 2. Pointer blocks have an encrypted block id, so we need to unwrap that and rewrap it
        block_update.setBlockId(block_id);
        if (block.getBlockType() === FSBlockType.POINTER) {
          const wrapped_pointer_block_id = Helpers.hexDecode(block_id);
          const unwrapped_pointer_block_id = await old_reader_key.encryption_key.unseal(wrapped_pointer_block_id);
          const rewrapped_pointer_block_id = await new_wrapping_key.public_user_key.public_key.seal(unwrapped_pointer_block_id);
          block_update.setNewBlockId(Helpers.hexEncode(rewrapped_pointer_block_id));
        }
      } else {
        // error?
      }
      return block_update;
    }));
    const file_rewrap = new FSRequest.Update.Change();
    file_rewrap.setOp(FSRequest.Update.Op.REWRAP);
    file_rewrap.setBlockUpdatesList(block_updates);
    file_rewrap.setReadKeyNodeId(new_maintainer_id);
    return file_rewrap;
  }

  // -------------------------------------------------------------------------------------------------------
  // For Sharing V1 Collections 
  // -------------------------------------------------------------------------------------------------------
  // Description: Share V1 collections if entry is already a collection V1
  // NOTES: - entry.type === DriveEntryType.DIR => NOT Allowed in case throw error and get out
  //        - subdirectories of V1 collections (not defaultCollections), should have Shared action disabled
  async function handleShareV1() {
    SetProgress(ProgressValues.handleShareV1);
    if (entry.type === DriveEntryType.LINK) {
      !!permissions ? grantAccess({
        collection_id: collection_info.collection_id,
        id: collection_info.id,
        maintainer_id: collection_info.maintainer_id,
        collection_name: collection_info.collection_name,
        permission_set: permissions,
        type: DriveEntryType.LINK,
        grantees: await getGrantees(false),
        acl: !!change_set ? NodePermissionTypeToACLRoleType[change_set.permission_type] : NodePermissionTypeToACLRoleType.read_only,
      }) : handlePageErrorMessage(DriveErrorMessages.error_sharing, { stack_message: DriveErrorMessages.error_fetching_permissions });
    } else {
      handlePageErrorMessage(DriveErrorMessages.error_sharing, { stack_message: DriveErrorMessages.unable_to_share_v1_subfolders });
    }
  }

  // Description: Grant Access to a V1 collection
  async function grantAccess(share_data: ShareData) {
    const grant_access_data = await buildV1GrantAccessData(PermissionSet.init(share_data.permission_set), share_data.grantees);
    const drive_request = !!grant_access_data ? await CollectionFilesyncAPI.grantAccess(
      current_account,
      share_data.permission_set,
      collection_info.collection_id,
      grant_access_data.roles,
      grant_access_data.grant_users) : null;
    async function callback(message: FSMessage) {
      SetProgress(ProgressValues.grantAccess);
      message.getStatus() === FSStatus.OK ? handleSuccess(share_data) :
        handlePageErrorMessage(DriveErrorMessages.error_sharing, { stack_message: DriveErrorMessages.error_granting_v1_access }, message);
    }
    handleSendRequest(drive_request, callback, DriveErrorMessages.error_granting_v1_access);
  }

  // Description: Grant Access to a V1 collection
  async function buildV1GrantAccessData(
    permissions_set: PermissionSet, // latest permissions_set 
    users: Grantee[]
  ): Promise<GrantAccessData | undefined> {
    const fsRoles: FSRoleType[] = !!change_set ? NodePermissionTypeToFSRoleType[change_set.permission_type] : NodePermissionTypeToFSRoleType.read_only;
    const collection_name = collection_info.collection_name;
    // NOTE: Build FSRequest.Grant.Role Array
    const roles: FSRequest.Grant.Role[] = [];
    _.forEach(fsRoles, (fsrole: FSRoleType) => {
      const permission = permissions_set.permission(fsrole); // NOTE: returns latest
      if (!!permission) {
        const role = new FSRequest.Grant.Role();
        role.setRole(fsrole);
        role.setVersion(permission.key_version);
        roles.push(role);
      }
    });

    // NOTE: Build Grant Users
    const grant_users = await Promise.all(_.map(users, async (user: Grantee) => {
      // NOTE: Build the buildGrantRolesInfo to pass it to the buildGrantUser => FSRequest.Grant.User.RoleInfo[]
      const roles_info = await buildGrantRolesInfo(roles, user, permissions_set);
      // NOTE:Build the encrypted_name as Uint8Array
      const encryption_key = await KeyFactory.newEncryptionKey({
        protocol_version: user.public_user_key.public_key.protocol_version,
        key: user.public_user_key.public_key.public_key,
        as_public: true,
        style: EncryptionStyle.DRIVE
      });
      const encrypted_name = await encryption_key.seal(Helpers.utf8Encode(collection_name));
      return buildGrantUser(user, encrypted_name, roles_info);
    }));

    return { grant_users, roles };
  }

  // Description: Build Users Roles Info Array  
  async function buildGrantRolesInfo(roles: FSRequest.Grant.Role[], user: Grantee, permissions_set: PermissionSet) {
    const roles_info: Array<Promise<FSRequest.Grant.User.RoleInfo>> = [];
    _.forEach(roles, async (grant_role: FSRequest.Grant.Role) => {
      const fsrole = grant_role.getRole();
      const permission = fsrole !== undefined ? permissions_set.permission(fsrole) : null;
      if (!!permission) {
        roles_info.push(buildGrantRoleInfo(permissions_set.collection_id.String().toLowerCase(), user, permission, fsrole || FSRole.READER));
      }
    });
    return await Promise.all(roles_info);
  }

  // -------------------------------------------------------------------------------------------------------
  //  General 
  // -------------------------------------------------------------------------------------------------------
  // Promote Dir to V2 collection - General 
  // Description: Validate target being shared is a directory that is a child of the defaultCollection
  function validateV1CollectionDirectory(): boolean {
    return !!root_info && entry.type === DriveEntryType.DIR && root_info.collection_id === collection_info.collection_id && collection_info.collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V1;
  }

  // Description: Validate target being shared is a directory that is a child of a V2 collection && is NOT Already an ACL Node- upgrades to acl node
  function validateV2CollectionDirectory(): boolean {
    return (entry.type === DriveEntryType.DIR && collection_info.collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V2 &&
      collection_info.maintainer_id !== collection_info.id);
  }

  // Description: Validate and return a  NewCollectionInfo object - Promote Dir to V2 collection
  function buildNewCollectionInfo(
    collection_id: UUID,
    collection_data?: InitV2CollectionEncryptedData):
    NewCollectionInfo | undefined {
    if (!!collection_data) {
      return {
        collection_id,
        id: new UUID().B64(),
        version: Helpers.b64Encode(randomBytes(32)),
        encrypted_name: collection_data.encrypted_name,
        encrypted_access_name: collection_data.encrypted_access_name,
        maintainer_scoped_name: collection_data.maintainer_scoped_name,
        dir_symm_key: collection_data.key_data.dir_symm_key,
        new_permissions: collection_data.key_data.new_permissions,
        wrapped_key: collection_data.wrapped_key,
        keys: collection_data.key_data.keys
      };
    }
  }

  // -----------------------------
  // Share v2 General
  // -----------------------------
  // Description: Grant users access to the collection - Share v2
  async function buildGrantAccessData(share_data: ShareData):
    Promise<GrantAccessData | undefined> {
    const _permissions_set = PermissionSet.init(share_data.permission_set);
    const access_permission = _permissions_set.permission(FSRole.ACCESS);
    if (!!access_permission) {
      // NOTE: Create Grant Roles
      const role = new FSRequest.Grant.Role();
      role.setRole(access_permission.role);
      role.setVersion(access_permission.key_version);
      // NOTE: Build Grant Users
      const _permissions_set = PermissionSet.init(share_data.permission_set);
      const collection_uuid = _permissions_set.collection_id.String().toLowerCase();
      const grant_users = await Promise.all(_.map(share_data.grantees, async (user: Grantee) => {
        // NOTE: Build the buildGrantRole to pass it to the buildGrantUser => FSRequest.Grant.User.RoleInfo[]
        const roles_info = await buildGrantRoleInfo(collection_uuid, user, access_permission, FSRole.ACCESS);
        // NOTE:Build the encrypted_name as Uint8Array
        const encryption_key = await KeyFactory.newEncryptionKey({
          protocol_version: user.public_user_key.public_key.protocol_version,
          key: user.public_user_key.public_key.public_key,
          as_public: true,
          style: EncryptionStyle.DRIVE
        });
        const encrypted_name = await encryption_key.seal(Helpers.utf8Encode(share_data.collection_name));
        return buildGrantUser(user, encrypted_name, [roles_info]);
      }));

      return { roles: [role], grant_users };
    } else {
      handlePageErrorMessage(DriveErrorMessages.error_sharing, { stack_message: DriveErrorMessages.error_granting_access_key });
    }
  }

  // Description: Build the Grant User object - Share v2
  function buildGrantUser(
    user: Grantee,
    encrypted_name: Uint8Array,
    roleinfo: FSRequest.Grant.User.RoleInfo[]):
    FSRequest.Grant.User {
    const grant_user = new FSRequest.Grant.User();
    grant_user.setUserId(user.user_id);
    grant_user.setKeyVersion(user.key_version);
    grant_user.setEncCollectionName(encrypted_name);
    grant_user.setRoleInfoList(roleinfo);
    if (!!user.expiration) {
      grant_user.setExpirationTime(user.expiration);
    }
    return grant_user;
  }

  /**
   * Description: Build the Grant Role Info object for each user - Share v2
   * https://github.com/PreVeil/core/blob/dev/collection_server/docs/filesync.md#grant
   * Grants a set of roles (collection keys) to a set of users.
   *  All users get the same keys, but the view_only field and the expiration_time is set on a per-user basis. 
   *  The view_only field is set for the user's reader key, and the expiration_time is set for all of the user's keys at once.
   * @param collection_id -  uuid lowercase
   * @param user 
   * @param permission 
   * @param role 
   * @param key 
   * @param view_only
   * @returns Promise<FSRequest.Grant.User.RoleInfo>
   */
  async function buildGrantRoleInfo(
    _collection_id: string,
    user: Grantee,
    permission: Permission,
    role: FSRoleType):
    Promise<FSRequest.Grant.User.RoleInfo> {
    const unwrappedKey = await permission.key(current_account);
    const key = await user.public_user_key.public_key.seal(unwrappedKey.serialize());
    const hash_key = await Helpers.sha256Checksum(key);
    const user_id = user.user_id.toLowerCase();
    const user_key_version = user.public_user_key.key_version;
    const role_string = getEnumKey(FSRole, role);
    const key_version = permission.key_version || 0;
    const hash = Helpers.hexEncode(hash_key).toLowerCase();
    // Compute a signed canonical string to verify the role
    const canonicalString = `${user_id},${user_key_version},${_collection_id},${role_string},${key_version},${hash}`;
    const signed_key = await current_account.user_key.signing_key.sign(Helpers.utf8Encode(canonicalString));
    const role_info = new FSRequest.Grant.User.RoleInfo();
    role_info.setSignature(signed_key);
    role_info.setRole(role);
    role_info.setWrappedKey(key);
    // NOTE: The view_only field is set for the user's reader key (READ ONLY KEY === 1)
    role === FSRole.READER && role_info.setViewOnly(user.view_only);
    return role_info;
  }

  // -----------------------------
  // App Share V1 and V2
  // -----------------------------
  async function shareV2App(share_data: ShareDataBase) {
    const sharing_list = _.map(share_data.grantees, (grantee: Grantee) => grantee.user_id);
    const expiration = (!!change_set && !!change_set.expiration && dayjs(change_set.expiration).isValid()) ? dayjs.utc(change_set.expiration).format() : "";
    const directory_id = share_data.maintainer_id;
    if (!!directory_id && sharing_list.length > 0) {
      SetProgress(ProgressValues.AppShareV2Complete);
      useShareV2({
        user_id: current_account.user_id,
        share_name: entry.name,
        collection_name: share_data.collection_name,
        collection_id: share_data.collection_id,
        directory_id,
        sharing_list,
        permissions_name: getPermissionsName(),
        expiration,
        request_id: request_id_ref.current
      })
        .unwrap()
        .then((response: string) => {
          // Correct share_data to pass parent_collection_id for updating UI
          response === "OK" ?
            handleSuccess(share_data) :
            handlePageErrorMessage(DriveErrorMessages.error_sharing, { stack_message: DriveErrorMessages.fs_error_sharing_v2, stack_error: response });
        })
        .catch((stack_error: unknown) => {
          handlePageErrorMessage(DriveErrorMessages.error_sharing, { stack_message: DriveErrorMessages.fs_error_sharing_v2, stack_error });
        });
    } else {
      handlePageErrorMessage(DriveErrorMessages.error_sharing, { stack_message: `shareV2App: ${DriveErrorMessages.error_fetching_maintainer_directory}` });
    }
  }

  async function upgradeToAclApp(directory_info: DirectoryInfoEntity) {
    SetProgress(ProgressValues.upgradeToAclApp);
    const grantees = await getGrantees(false);
    shareV2App({
      collection_id: directory_info.collection_id,
      id: directory_info.id,
      maintainer_id: directory_info.id,
      collection_name: collection_info.collection_name,
      type: entry.type,
      grantees
    });
  }

  // Description: Get permissions name from change_set 
  function getPermissionsName(): NodePermissionsName {
    return (!!change_set?.permission_type ? NodePermissionLabels[change_set.permission_type] : NodePermissionLabels.read_only) as NodePermissionsName;
  }

  // -----------------------------
  // Send User Invites
  // -----------------------------
  // Description: Send invite to unclaimed users in parallel
  async function handleSendInvites(_unclaimed: UserProfile[], success_data: ShareDataBase) {
    return await Promise.all(_.map(_unclaimed, (profile: UserProfile) => {
      return is_web ? inviteUsersWeb(profile) : inviteUsersApp(profile, success_data);
    }));
  }

  // Description: Send invite for Web build
  function inviteUsersWeb(profile: UserProfile): Promise<PutUsersInviteApiResponse> {
    const body = {
      user_id: current_account.user_id,
      invitee_email: !!profile.external_email ? profile.external_email : profile.address
    };

    return sendInvites({
      account_ids: Account.getAccountIdentifiers(current_account),
      body
    }).unwrap();
  }

  // Description: Send invite for App build
  function inviteUsersApp(profile: UserProfile, success_data: ShareDataBase): Promise<unknown> {
    const share_metadata = new ShareInviteMetadata();
    const share_type = (!!change_set && !!change_set.permission_type) ?
      PermissionsShareType[change_set.permission_type] : PermissionsShareType.view_only;
    const expiration = (!!change_set && !!change_set.expiration && dayjs(change_set.expiration).isValid()) ? dayjs.utc(change_set.expiration).format() : "";
    share_metadata.setCollectionId(Helpers.b64Decode(Helpers.convertToURLSafeId(success_data.collection_id)));
    share_metadata.setAclNodeId(Helpers.b64Decode(Helpers.convertToURLSafeId(!!success_data.maintainer_id ? success_data.maintainer_id : success_data.id)));
    share_metadata.setShareType(share_type);
    share_metadata.setExpiration(expiration);

    const invitee = !!profile.external_email ? profile.external_email : profile.address;
    const invite_metadata = new InviteMetadata();
    invite_metadata.setInvitee(invitee);
    invite_metadata.setShareMetdata(share_metadata);
    invite_metadata.setInviteType(InviteMetadata.InviteType.SHARE);
    const metadata = Helpers.b64Encode(invite_metadata.serializeBinary());

    return sendPVInvite({
      userId: current_account.user_id,
      invitee,
      metadata
    }).unwrap()
      .catch((stack_error: unknown) => {
        handlePageErrorMessage(DriveErrorMessages.error_inviting_users, { stack_message: DriveErrorMessages.error_inviting_users, stack_error });
      });
  }
  // -----------------------------
  // HOOK General
  // -----------------------------
  // Description: Handle successful changing of the permissions
  // On Complete: 
  // - Refetch Default Permissions,
  // - fetch collection info => to reload side panel and 
  // - refresh current directory 
  async function handleSuccess(success_data: ShareDataBase) {
    let success_message = !!change_set && change_set?.users.length > 0 ?
      DriveSuccessMessages.share_folder_success.replace(MessageAnchors.folder_name, entry.name) : "";
    if (!!change_set?.unclaimed && change_set.unclaimed?.length > 0) {
      const count = await handleSendInvites(change_set.unclaimed, success_data);
      const submessage = count.length > 1 ? `${count.length} PreVeil invitations have been sent successfully` :
        `A PreVeil invite has been sent to <b>"${!!change_set.unclaimed[0].external_email
          ? change_set.unclaimed[0].external_email : change_set.unclaimed[0].address}"</b>`;
      success_message = `${success_message} ${(success_message.length > 0 && submessage.length > 0) ? "<br/>" : ""} ${submessage}`;
    }

    SetProgress(ProgressValues.handleSuccess);
    setLoading(false);
    setData(Object.assign(success_data, { success_message }));
    setGetOrgId(undefined);
    setOrgInfoRef(undefined);
  }

  // Description: Sends Request and handles error when the request object is null, pass it custom stack message
  function handleSendRequest<T>(request: DriveRequest | null, callback: DriveCallbackAsyncFunction<T>, stack_error: string = DriveErrorMessages.error_sending_request) {
    if (!!request) {
      request.setCallback(callback);
      sendRequest(request);
    } else {
      handlePageErrorMessage(DriveErrorMessages.error_sharing, { stack_error, stack_message: DriveErrorMessages.error_sending_request });
    }
  }

  // Description: Handle error message and send error to store
  function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, fsmessage?: FSMessage | null, display_type?: MessageDisplayType) {
    const _display_type = !!display_type ? display_type : MessageHandlerDisplayType.toastr;
    const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
    const stack = new ErrorStackItem("[useWebShare Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
    dispatch(uiActions.handleRequestErrors(new Message(message, _display_type), stack));
    setError(true);
  }

  // Description: Call to get collection level permissions
  // NOTE: Pass a request Id to subscribe to FS notifications
  const save = useCallback((users: UserProfile[], permission_type: NodePermissionTypes, expiration: string, request_id?: string) => {
    const active_users = users.slice();
    const unclaimed = _.remove(active_users, (user: UserProfile) => user.status === UserListStatus.unclaimed || user.status === UserListStatus.pending || user.status === UserListStatus.external);

    setChangeSet({ users: active_users, unclaimed, permission_type, expiration });
    setLoading(true);
    request_id_ref.current = request_id;
  }, []);

  // Description: Reset hook for next call
  const reset = useCallback(() => {
    setLoading(false);
    setData(undefined);
    setChangeSet(undefined);
    destroyPermissions();
    destroyGrants();
    setError(false);
    setGetOrgId(undefined);
    setOrgInfoRef(undefined);
    SetProgress(ProgressValues.initial);
  }, []);

  return {
    save,
    reset,
    loading,
    data,
    error,
    progress
  };
}
