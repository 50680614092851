import React, { Dispatch, SetStateAction } from "react";
import { Col, Row } from "react-bootstrap";
import { DeviceBase } from "@preveil-api";
import { Account } from "src/common";
import { EmptyView, Loading } from "src/components";
import { DeviceRow } from ".";
import _ from "lodash";

type AllProps = {
  loading: boolean;
  current_account: Account;
  devices: DeviceBase[];
  setDevices: Dispatch<SetStateAction<DeviceBase[]>>;
};

/* Description: Device List View Component */
function DeviceListViewComponent(props: AllProps) {
  const { loading, current_account, devices, setDevices } = props;

  // Description: Render the rows
  function RenderList() {
    return (
      <div className="list-body">
        {_.map(devices, (device: DeviceBase, i: number) => {
          return (
            <DeviceRow
              key={i}
              device={device}
              current_account={current_account}
              devices={devices}
              setDevices={setDevices}
            ></DeviceRow>
          );
        })}
      </div>
    );
  }

  return <div className={`settings-list${loading ? " isloading" : ""}`}>
    {!!devices && devices.length > 0 ? 
      <>
        <Row className="header-row">
          <Col sm={4} md={6}>Name</Col>
          <Col sm={3} md={4}>Status</Col>
        </Row>
        {RenderList()}
      </> : !loading && <EmptyView message="You have no devices" />}
    {!!loading && <Loading className="in-place"/>}
  </div>;
}

export default React.memo(DeviceListViewComponent);
