import React, { useEffect } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import { Navigate } from "react-router";
import { Row, Col, ListGroup } from "react-bootstrap";
import { LocalUser } from "@preveil-api";
import {
  PublicRoutes, QueryParamKeys, useGetLocalAccountListQuery, normalizeQueryUserId, MessageHandlerDisplayType, AccountErrorMessages, useAppDispatch,
  Message, LocalAccountStorage, QueryParamValues
} from "src/common";
import { uiActions } from "src/store";
import { Loading } from "..";
import _ from "lodash";

function ChooseAccountComponent() {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { data, error, isLoading } = useGetLocalAccountListQuery();
  const currentLocation = useLocation();
  const navigate = useNavigate();

  // Description: Check for query strings (legacy redirects with user_id, redirect_url information, and actions)
  //              Alternatively check for sessions and send to login if there
  function handleAutoLogin() {
    const page_action_qs = searchParams.get(QueryParamKeys.PAGE_ACTION_QUERY_KEY);
    if (!!page_action_qs && page_action_qs === QueryParamValues.switch) {
      searchParams.delete(QueryParamKeys.PAGE_ACTION_QUERY_KEY);
      setSearchParams(searchParams);
    } else {
      const storage = new LocalAccountStorage();
      const query_user_id = normalizeQueryUserId(searchParams.get(QueryParamKeys.USER_ID_QUERY_KEY)) || storage.pv_account?.address || storage.most_recent_user_id;
      (!!query_user_id) &&
        navigate(`/${PublicRoutes.login_route}${currentLocation.search}`);
    }
  }

  useEffect(() => {
    handleAutoLogin();
  }, []);

  // Description: Handle Errors from RTK
  useEffect(() => {
    !!error &&
      dispatch(uiActions.handleRequestErrors(new Message(AccountErrorMessages.default, MessageHandlerDisplayType.logger),
        {
          stack_message: "ChooseAccountComponent error useGetLocalAccountListQuery",
          stack_error: error
        }));
  }, [error]);

  return isLoading ? <Loading /> :
    (!!data && !!data.users && Array.isArray(data.users) && data.users.length > 0) ?
      <Row className="choose-account">
        <Col md={6} className="">
          <h1 className="main-header">Choose An Account</h1>
          <ListGroup>{
            _.map(data.users, (user: LocalUser, i: number) => {
              return <ListGroup.Item key={`name_${i}`}
                as="a"
                href={`/${PublicRoutes.login_route}?${QueryParamKeys.USER_ID_QUERY_KEY}=${encodeURIComponent(user.user_id.toLowerCase())}`}>
                <span className="name">
                  {user.display_name}
                </span>
                <span>{user.user_id}</span>
              </ListGroup.Item>;
            })
          }
          </ListGroup>
        </Col>
      </Row> : <Navigate to={`/${PublicRoutes.get_started_route}`} />;
}

export default React.memo(ChooseAccountComponent);
