import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { IActionHandler, ActionHandlerFunction, WebsocketCryptoMessage } from "@preveil-api";
import {
  useAppSelector, useAppDispatch, Message, AccounWStSteps, AccountErrorMessages, MessageHandlerDisplayType,
  AccountUIActionTypes, GlobalErrorMessages, MessageAnchors
} from "src/common";
import { uiActions, websocketActions, WebsocketStatus } from "src/store";
import { Loading } from "src/components";
import connection_img from "src/assets/images/connection.svg";
import {
  AwaitConnection,
  ConnectionEstablished,
  ErrorPanel
} from ".";

type AllProps = {
  user_id: string;
  approver_user_id: string;
  handleAction: ActionHandlerFunction;
}

function GetShardComponent(props: AllProps) {
  const { user_id, approver_user_id, handleAction } = props; // 
  const dispatch = useAppDispatch();
  const [step, setStep] = useState<number>(AccounWStSteps.DISCONNECTED);
  const [pin, setPin] = useState<string>();
  const message = useAppSelector((state) => state.websocket.message as WebsocketCryptoMessage);
  const status = useAppSelector((state) => state.websocket.status);
  const errors = useAppSelector((state) => state.websocket.errors);

  // Description: load accounts and urrent account from crypto (First onload event)
  useEffect(() => {
    GetShardRequests.handleInitWebsocketConnection();
    return () => {  // Destroy websocket State onleave
      dispatch(websocketActions.destroyWebsocket());
    };
  }, []);

  // Description: Responds to changes in message and websocket status
  useEffect(() => {
    const isError = status === WebsocketStatus.error || message?.status === WebsocketStatus.error;
    let next = isError ? AccounWStSteps.ERROR : !!message ? step + 1 : step;

    if (!!message?.pin) {
      setPin(message.pin);
      next = AccounWStSteps.GET_CONNECTION_ESTABLISHED;
    }

    if (!!message?.status && message.status === WebsocketStatus.key_transfer_complete) {
      // next = AccounWStSteps.GET_KEY_TRANSFER_COMPLETE;
      handleAction({ actionType: AccountUIActionTypes.PageSuccess });
    } else {
      setStep(next);
    }
  }, [message, status]);

  // Description: load accounts and urrent account from crypto (First onload event)
  useEffect(() => {
    !!errors &&
      dispatch(uiActions.handleRequestErrors(new Message(AccountErrorMessages.default, MessageHandlerDisplayType.logger), errors));
  }, [errors]);

  // Description: Page actions
  const GetShardRequests = {
    // Description: Initialize the connection
    handleInitWebsocketConnection: () => {
      if (!!user_id && !!approver_user_id) {
        const host = `${process.env.REACT_APP_LOCAL_WEBSOCKET_SERVER}/get/account/${encodeURIComponent(user_id)
          }/shard/${encodeURIComponent(approver_user_id)}/get`;
        dispatch(websocketActions.wsConnect({ host, init: { action: "init" } }));
      } else {
        GetShardRequests.handlePageError({ message: AccountErrorMessages.missing_user_id });
      }
    },
    handleDestroy: () => {
      handleAction({ actionType: AccountUIActionTypes.Destroy });
      setStep(AccounWStSteps.DISCONNECTED);
    },
    handlePageError: (params: { message: string, stack?: any }) => {
      dispatch(uiActions.handleRequestErrors(new Message(params.message, MessageHandlerDisplayType.logger), params.stack));
      setStep(AccounWStSteps.ERROR);
    },
    handlePageErrorMessage: (params: { message: string, stack?: any }) => {
      dispatch(uiActions.handleRequestErrors(new Message(params.message, MessageHandlerDisplayType.logger), params.stack));
    }
  };

  //  Description: Handle all actions from Children forms
  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    if ((GetShardRequests as any)[callback] instanceof Function) {
      (GetShardRequests as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(MessageAnchors.actionType, actionObj.actionType);
      GetShardRequests.handlePageErrorMessage({ message, stack: actionObj });
    }
  }
  // Description: Render panels by steps
  const GetShardPanels = () => {
    switch (step) {
      case AccounWStSteps.ERROR:
        return <ErrorPanel handleAction={handlePageActions} />;
      case AccounWStSteps.DISCONNECTED:
      case AccounWStSteps.INITIALIZE_CONNECTION:
      case AccounWStSteps.GET_ACCEPTED_INIT_OK:
        return <AwaitConnection handleAction={handlePageActions} />;
      case AccounWStSteps.GET_CONNECTION_ESTABLISHED:
      case AccounWStSteps.GET_KEY_TRANSFER_COMPLETE:
        return !!pin ? <ConnectionEstablished pin={pin} handleAction={handlePageActions} /> : <Loading className="in-place" />;
      default:
        return <Loading className="in-place" />;
    };
  };

  return <Row className="justify-content-center">
    <Col md={8}>
      <h1 className="main-header">Request Recovery</h1>
      <Card className="pv-card">
        <Card.Img variant="top" src={connection_img} className="py-3" />
        <Card.Body className="p-4">
          <GetShardPanels />
        </Card.Body>
      </Card>
    </Col>
  </Row>;
}

export default React.memo(GetShardComponent);
