/* eslint-disable @typescript-eslint/no-var-requires */
/* 
 * Add Day.js plugins here as needed and import as any asset:
*/
import dayjs from "dayjs";
import "dayjs/plugin/utc";
import "dayjs/plugin/duration";
import "dayjs/plugin/customParseFormat";
import "dayjs/plugin/isToday";
import "dayjs/plugin/calendar";
import "dayjs/plugin/localizedFormat";

dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/duration"));
dayjs.extend(require("dayjs/plugin/customParseFormat"));
dayjs.extend(require("dayjs/plugin/isToday"));
dayjs.extend(require("dayjs/plugin/calendar"));
dayjs.extend(require("dayjs/plugin/localizedFormat"));

// Add more plugins here
export default dayjs;
