import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import { Icon } from "..";
import { dayjs, useAppSelector, useAppDispatch } from "src/common";
import { accountActions, appActions } from "src/store";
import SettingsImage from "src/assets/images/device-lock.svg";

function DeviceLockedComponent() {
  const dispatch = useAppDispatch();
  const lock_info = useAppSelector((state) => state.app.locked_devices);
  const current_account = useAppSelector((state) => state.account.current_account);
  const lockedOrDeleted = !!lock_info?.deleted ? "deleted" : "locked";
  const org_name = !!current_account ? current_account.org_info?.org_name : null;
  // NOTE: CONVERTS UTC TO LOCAL:
  const formatted_locked_date = !!lock_info?.lock_time ? dayjs.utc(lock_info.lock_time).local().format("MMMM D, YYYY h:mm A") : null;

  useEffect(() => {
    dispatch(accountActions.logout());
    return () => {
      dispatch(appActions.deviceLockedSet(undefined));
    };
  }, []);

  return <div className="mx-auto max-600 mt-4">
    <h1 className="main-header">This Device is {lockedOrDeleted}
      {
        !!lock_info?.display_name &&
        <>
          &nbsp; for <b>{lock_info.display_name}</b>
        </>
      }
    </h1>
    <Card className="pv-card mb-2">
      <Card.Img variant="top" src={SettingsImage} />
      <Card.Body>
        <p className="semi-bold fs-3">For your security, you can't access your PreVeil account from this computer.</p>
        {
          !!lock_info &&
          <p>
            This device was {lockedOrDeleted} by <b>{lock_info.lock_by_user_name}</b>&nbsp;
            {
              !!lock_info.lock_by_user_id &&
              <b>[{lock_info.lock_by_user_id}]&nbsp;</b>
            }
            {
              !!formatted_locked_date &&
              <>
                on <b>{formatted_locked_date}</b>&nbsp;
              </>
            }
            {
              !!lock_info.lock_using_device &&
              <>from <b>{lock_info.lock_using_device}</b></>
            }
          </p>
        }
        <p>This device will remain locked until unlocked from one of your other devices.
          {
            !!org_name && <>
              or an administrator for <b>{org_name}</b>
            </>
          }
        </p>
      </Card.Body>
    </Card>
    <p><Icon className="pv-icon-question" /><span>Learn more about locked devices <a target="_blank" rel="noreferrer" href="https://preveil.com/support">preveil.com/support</a></span></p>
  </div>;
}

export default React.memo(DeviceLockedComponent);
