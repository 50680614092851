import React from "react";
import { Button, Row, Col } from "react-bootstrap";
import { CheckboxStates, ApprovalRequestStatus, UserRequest } from "src/common";
import { Checkbox } from "src/components";

type AllProps = {
  request: UserRequest;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  checked_list: UserRequest[];
  setCheckedList: React.Dispatch<React.SetStateAction<UserRequest[]>>;
  can_select: boolean;
};

function ApprovalRequestsRowComponent(props: AllProps) {
  const { request, setShow, show, checked_list, setCheckedList, can_select } = props;

  /* Description: When clicking on a row, opens up the side panel. */
  function NavigateToView() {
    if (checked_list.length === 0) {
      setCheckedList([request]);
      setShow(true);
    }
    if (checked_list.length > 0 && checked_list.filter((r) => r.request_id === request.request_id).length === 1) {
      setShow(!show);
      setCheckedList([]);
    } else if (checked_list.length > 0 && checked_list.filter((r) => r.request_id === request.request_id).length === 0) {
      setCheckedList([request]);
    }
  }

  /* Description: Updates the checkedlist depending on different situations */
  function handleCheckboxChange() {
    if (checked_list.length === 0) {
      setCheckedList([request]);
      setShow(true);
    } else if (
      checked_list.length === 1 &&
      checked_list.filter((r) => r.request_id === request.request_id).length === 0
    ) {
      setShow(false);
      setCheckedList(checked_list.concat([request]));
    } else if (
      checked_list.length === 1 &&
      checked_list.filter((r) => r.request_id === request.request_id).length === 1
    ) {
      setShow(false);
      setCheckedList([]);
    } else if (
      checked_list.length > 1 &&
      checked_list.filter((r) => r.request_id === request.request_id).length === 0
    ) {
      setCheckedList(checked_list.concat([request]));
    } else if (
      checked_list.length > 1 &&
      checked_list.filter((r) => r.request_id === request.request_id).length === 1
    ) {
      setCheckedList(checked_list.filter((r) => r.request_id !== request.request_id));
    }
  }

  /* Description: Returns a color as a string based on the request status 
  for notification bubble */
  function StatusClassMapping(status: string): string {
    if (status === ApprovalRequestStatus.pending) {
      return "blue";
    } else if (status === ApprovalRequestStatus.approved) {
      return "green";
    } else {
      return "gray";
    }
  };

  function getSelectedState() {
    return !!checked_list &&
      checked_list.filter((r) => r.request_id === request.request_id).length === 1
      ? CheckboxStates.checked
      : CheckboxStates.empty;
  }

  return (
    <Row onClick={NavigateToView}>
      {can_select && (
        <Col md="1" lg="auto">
          <Checkbox
            onChange={() => handleCheckboxChange()}
            value={getSelectedState()}
            selected={getSelectedState()}
          />
        </Col>
      )}
      <Col lg={3} className="col">
        {request.mapped_action}
      </Col>
      <Col lg={2} >
        {request.requester?.name}
      </Col>
      <Col lg={!!can_select ? 2 : 3}>
        {request.timestamp}
      </Col>
      <Col lg={3}>
        {request.expiration}
      </Col>
      <Col lg={1}>
        <Button
          variant="transparent"
          className={`notification-count ${StatusClassMapping(request.status)} with_cap`}
          aria-label="notification bubble"
        >
          {request.status}
        </Button>
      </Col>
    </Row>
  );
}

export default React.memo(ApprovalRequestsRowComponent);
