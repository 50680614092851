import * as React from "react";
import { Alert } from "react-bootstrap";
import { NavigationPath } from "src/common";
import { Wrapper, PageHeader } from "src/components";

function ChatPage() {
  return (
    <Wrapper type={NavigationPath.ChatNavigation}>
      <div className="content-wrapper" >
        <PageHeader>
          <h1>PreVeil Chat</h1>
        </PageHeader>
        <Alert variant="info">
          <Alert.Heading>CHAT PAGE</Alert.Heading>
          <p>Under Construction!</p>
        </Alert>
      </div>
    </Wrapper>
  );
}

export default React.memo(ChatPage);
