import * as React from "react";
import { NodePermissionSet } from "src/common";
import { AccessHeader, AccessRow } from ".";
import _ from "lodash";

function PeopleWithAccessComponent(props: { shared_with: NodePermissionSet[] }) {
  return <div className="drive-share">
    <div className="access-list">
      <AccessHeader />
      <div className="access-body">
        {
          _.map(props.shared_with, (node_permission: NodePermissionSet & { class_name?: string }, i: number) => {
            return <AccessRow
              key={`access_${i}`}
              class_name=""
              edit_mode={true}
              node_permission={node_permission} />;
          })
        }
      </div>
    </div>
  </div>;
}

export default React.memo(PeopleWithAccessComponent);
