import { Account, AppUserKey, KeyFactory, GrantItem, isSameUser, GrantSetType, ACLRoleType, ActiveGrants } from "src/common";
import { Node, FSMessage, SealedContent } from "src/common/keys/protos/collections_pb";
import { EncryptionStyle } from "src/common/keys/encryption";
import _ from "lodash";


export class GrantSet {
  constructor(
    public readonly collection_id: string,
    public readonly id: string,
    public readonly user_id: string,
    public readonly grants: Grant[]
  ) { }

  get grant_set(): GrantSetType {
    return {
      collection_id: this.collection_id,
      id: this.id,
      user_id: this.user_id,
      grants: this.grants
    };
  }

  // Description: Build the grant for the set - returns the latest if role_key_version is undefined
  public grant(role: ACLRoleType, role_key_version?: number): Grant | undefined {
    const role_grants = this.grants.filter(g => g.role === role);
    if (role_grants.length <= 0) {
      return;
    }

    if (role_key_version !== undefined) {
      return role_grants.find(p => p.role_key_version === role_key_version) || undefined;
    } else {
      return _.maxBy(role_grants, "role_key_version") || undefined;
    }
  }

  // Description: Quick init form converting GrantSetType to GrantSet
  static init(grand_set_type: GrantSetType): GrantSet {
    return new GrantSet(grand_set_type.collection_id, grand_set_type.id, grand_set_type.user_id, grand_set_type.grants);
  }

  // Description: Get the latest grants from the set
  static latestGrants(grants: Grant[]): Grant[] {
    const max_version = _.maxBy(grants, "role_key_version")?.role_key_version;
    return _.filter(grants, p => p.role_key_version === max_version);
  }

  // Description: Get latest grant and latest_key_version for a set
  static latestNodeGrants(grants: Node.Grant[]): { grants: Node.Grant[], latest_key_version: number } {
    const latest_key_version = _.maxBy(grants, (g: Node.Grant) => { return g.getKeyVersion(); })?.getKeyVersion() || 0;
    return { grants: _.filter(grants, p => p.getKeyVersion() === latest_key_version), latest_key_version };
  }

  // Description: Build the acl / grants 
  static getActiveGrantSets(collection_id: string, id: string, grants: Node.Grant[]): GrantSetType[] {
    const new_grants: ActiveGrants[] = [];
    _.each(grants, (grant: Node.Grant) => {
      const grant_list: Node.Grantee[] = grant.getGranteesList();
      const role = grant.getRole();
      const role_key_version = grant.getKeyVersion();
      if (role !== undefined && role_key_version !== undefined) {
        _.forEach(grant_list, (grantee: Node.Grantee) => {
          const user_id = grantee.getUserId();
          const grant = !!user_id ? new Grant(role, role_key_version, grantee) : null;
          (!!grant && !!user_id) &&
            new_grants.push({
              user_id,
              grant
            });
        });
      }
    });
    const user_grants = _.groupBy(new_grants, p => p.user_id);
    return _.map(user_grants, (grants, user_id) => {
      return new GrantSet(collection_id, id, user_id, _.map(grants, (g) => g.grant)).grant_set;
    });
  }

  // Description: Build the acl / grants  and return only the active users
  static getCurrentUsersGrantSets(collection_id: string, id: string, grants: Node.Grant[], user_id: string): GrantSetType | undefined {
    const grant_sets = GrantSet.getActiveGrantSets(collection_id, id, grants);
    return GrantSet.getActive(grant_sets, collection_id, id, user_id);
  }

  // Description: get grants by getActiveGrantSets
  static getGrantbyRole(set: GrantSetType, requested_key_version: number = 0, AclRole: ACLRoleType = Node.ACLRole.READER): Grant | undefined {
    return _.find(set.grants, (grant: Grant) => (grant.role === AclRole && grant.role_key_version === requested_key_version));
  }

  // Description: Recursively unwrap previous key history versions 
  static async unwrapAclKeyHistory(
    collection_id: string,
    id: string,
    entries: FSMessage.KeyHistoryEntry[],
    role: ACLRoleType,
    grant_sets: GrantSet,
    current_account: Account): Promise<GrantSetType> {
    if (entries.length === 0) {
      return grant_sets.grant_set;
    }

    const entry = entries[entries.length - 1];
    const previous_grant = grant_sets.grant(role, entry.getVersion());
    if (!previous_grant) {
      throw new Error("no available previous grant");
    }

    const previous_key = await previous_grant.key(current_account);
    const unwrapped_key = await previous_key.encryption_key.unseal(entry.getWrappedLastKey_asU8());
    const key = await KeyFactory.deserializeUserKey(unwrapped_key, EncryptionStyle.DRIVE);
    if (key) {
      const grant = new Grant(role, key.key_version, previous_grant.grantee, key);
      let new_grants = grant_sets.grants.slice();
      const index = new_grants.findIndex((_grant: Grant) => (grant.role === _grant.role && grant.role_key_version === _grant.role_key_version));
      if (index >= 0) {
        new_grants[index] = grant;
      } else {
        new_grants = [grant, ...new_grants];
      }
      const new_grant_set = new GrantSet(collection_id, id, current_account.user_id, new_grants);
      return await GrantSet.unwrapAclKeyHistory(collection_id, id, entries.slice(0, entries.length - 1), role, new_grant_set, current_account);
    } else {
      throw new Error("error unwrapping and decrypting the grant key");
    }
  }

  // Description: Add grand set to the state array default_grants: GrantSetType[] 
  static updateDefaultGrantSets(current_grants: GrantSetType, default_grants: GrantSetType[]): GrantSetType[] {
    const new_default_grants = default_grants.slice();
    const index = _.findIndex(new_default_grants, (set: GrantSetType) =>
      (set.collection_id === current_grants.collection_id && set.id === current_grants.id && set.user_id === current_grants.user_id));
    if (index >= 0) { // Replace permission if it exists:
      new_default_grants[index] = current_grants;
    } else {
      new_default_grants.push(current_grants);
    }
    return new_default_grants;
  }

  // Description: get grantset by props GrantSet.getActive
  static getActive(default_grants: GrantSetType[], collection_id: string, id: string, user_id: string): GrantSetType | undefined {
    return _.find(default_grants, (grant: GrantSetType) =>
      isSameUser(grant.user_id, user_id) && collection_id === grant.collection_id && id === grant.id);
  }
}

// NOTES: user_key_version is different from the role_key_version
// - user_key_version is set from Grantee.getGrant().getKeyVersion() 
//    and it is required to unwrap the correct grantee key = Grantee.getGrant().getContent_asU8() (_wrapped_key)
// - role_key_version is set from Node.Grant.getKeyVersion(); - Node.Grant has GranteeList: Node.Grantee[]
export class Grant implements GrantItem {
  user_key_version: number;
  expiration: string;
  grant?: SealedContent;
  view_only: boolean; // default to false
  private readonly _wrapped_key?: Uint8Array;
  private _key?: AppUserKey;
  private readonly _grantee: Node.Grantee;
  constructor(
    public readonly role: ACLRoleType,
    public readonly role_key_version: number,
    grantee: Node.Grantee,
    key?: AppUserKey,
  ) {
    this.expiration = grantee.getExpirationTime() || "";
    this.view_only = grantee.getViewOnly() || false;
    this._grantee = grantee;
    this.grant = grantee.getGrant();
    this.user_key_version = this.grant?.getKeyVersion() || 0;
    this._wrapped_key = this.grant?.getContent_asU8();
    if (!!key) {
      this._key = key;
    }
  }

  get grantee(): Node.Grantee {
    return this._grantee;
  }

  // Description: returns grantee.getGrant().getContent_asU8()
  get wrapped_key(): Uint8Array | undefined {
    return this._wrapped_key;
  }

  get item(): GrantItem {
    return {
      user_key_version: this.user_key_version,
      expiration: this.expiration,
      grant: this.grant,
      role: this.role,
      role_key_version: this.role_key_version,
      view_only: this.view_only,
    };
  }

  // Description: return AppUserKey from _wrapped_key (the unseal & deserializeUserKey)
  public async key(account: Account): Promise<AppUserKey> {
    if (!this._key) {
      // NOTE: Get the appropriate user key when unwrapping 
      const user_key = this.user_key_version !== undefined ? account.getUserKeyWithVersion(this.user_key_version) : account.user_key;
      if (!!user_key && !!this._wrapped_key) {
        const unwrapped_key = await user_key.encryption_key.unseal(this._wrapped_key);
        this._key = await KeyFactory.deserializeUserKey(unwrapped_key, EncryptionStyle.DRIVE);
      } else {
        throw new Error("UserKey is not defined");
      }
    }
    return this._key;
  }
}
