import * as React from "react";
import { Card, Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { AccountUIActionTypes } from "src/common";

type AllProps = {
  user_id?: string;
  handleAction: ActionHandlerFunction;
}

function ErrorPanelComponent(props: AllProps) {
  const { user_id, handleAction } = props;
  return <Card.Body>
    <h1 className="border-bottom pb-3">Connection Failed</h1>
    <p>There was an error connecting your account <b>{user_id}</b></p>
    <Button onClick={() => handleAction({ actionType: AccountUIActionTypes.Destroy })}>Retry</Button>
  </Card.Body>;
}
export default React.memo(ErrorPanelComponent);
