import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { AppConfiguration } from "src/common";
import { AuthContextProvider, UIManager } from "./components/";
import Router from "./routes";

function App() {
  // Description: Redirect users localhost to 127.0.0.1
  useEffect(() => {
    if (AppConfiguration.buildForApp() && window.location.host.startsWith("localhost")) {
      const new_url = window.location.href.replace("localhost", "127.0.0.1");
      location.replace(new_url);
    }
  }, []);

  return (
    <BrowserRouter>
      <AuthContextProvider>
        <Router />
        <UIManager />
      </AuthContextProvider>
    </BrowserRouter>
  );
}

export default App;
