import React, { useState, useEffect } from "react";
import { IActionHandler, Sort } from "@preveil-api";
import {
    Account, CollectionEntity, useAppDispatch, GlobalErrorMessages, Message, MessageHandlerDisplayType, MessageAnchors, useDriveSearch, EntryItem,
    DirectoryEntity, DriveSearchFilterType, DriveSearchFilterTypes, SORT_DIRECTION, SearchNode, SearchFieldType, DriveSearchFilters,
    DriveSearchFilter, DriveSearchFilterSet, SearchBase
} from "src/common";
import { uiActions, driveActions } from "src/store";
import { SearchWrapper, SearchForm, SearchDropdown } from ".";

type AllProps = {
    current_account: Account;
    collection_info: CollectionEntity;
    current_directory: DirectoryEntity;
    selected: string[];
}

const search_filters = new DriveSearchFilters();
function DriveSearchComponent(props: AllProps) {
    const { current_account, collection_info } = props; // current_directory, selected // Add this for scoping search 
    const [active, setActive] = useState<boolean>(false);
    const [search_term, setSearchTerm] = useState<string | undefined>();
    const [selected_folders, setSelectedFolders] = useState<EntryItem[]>([]);
    const [sort, setSort] = useState<Sort<keyof SearchNode>>({ field: SearchFieldType.last_modification_date, direction: SORT_DIRECTION.descending });
    const [filters, setFilters] = useState<DriveSearchFilterSet>({});
    const { initSearch, fetchMore, resetSearch, results, progress, error } = useDriveSearch(current_account, sort, collection_info, search_term);
    const dispatch = useAppDispatch();

    // Description:  Catch changes to search_term state (input)
    useEffect(() => {
        if (!!search_term && search_term.length > 0 && !active) {
            setActive(true);
            initSearch(collection_info.collection_id, collection_info.id);
        }
    }, [search_term]);

    // Description: accumulate selected as filters   
    // TODO: Uncomment this for scoping search 
    // useEffect(() => {
    //     !!selected && handleSelectedItems(selected);
    // }, [selected]);

    // Description: Handle Search state and actions
    const DriveSearchRequests = {
        handleLoadMore: (pending_searches: SearchBase[]) => {
            fetchMore(pending_searches);
        },
        handleReset: () => {
            setActive(false);
            setSearchTerm(undefined);
            setSelectedFolders([]);
            resetSearch();
            search_filters.initialize();
            setFilters(search_filters.filter_set);
        },
        handleSetSort: setSort,
        handleSetFilter: (_filter_set: DriveSearchFilter) => {
            search_filters.modifyFilterSet(_filter_set);
            setFilters(search_filters.filter_set);
        },
        handleResetFilters: (params: DriveSearchFilterTypes) => {
            // NOTE: Clear selected to reset parent scoped filters
            params === DriveSearchFilterType.parent_scope && dispatch(driveActions.setSelected([]));
            if (params === DriveSearchFilterType.fs_type) {
                search_filters.resetFSTypeFilter();
                search_filters.resetExtension();
            }
            params === DriveSearchFilterType.extension && search_filters.resetExtension();
            setFilters(search_filters.filter_set);
        },
        handlePageErrorMessage: (params: { message: string, stack?: any }) => {
            dispatch(uiActions.handleRequestErrors(new Message(params.message, MessageHandlerDisplayType.logger), params.stack));
        },
    };

    //  Description: Handle all actions from Children forms
    function handlePageActions(actionObj: IActionHandler) {
        const callback = `handle${actionObj.actionType}`;
        if ((DriveSearchRequests as any)[callback] instanceof Function) {
            (DriveSearchRequests as any)[callback](actionObj.params);
        } else {
            const message = GlobalErrorMessages.no_handler_found.replace(MessageAnchors.actionType, actionObj.actionType);
            DriveSearchRequests.handlePageErrorMessage({ message, stack: actionObj });
        }
    }

    // Description: Set selected items Only DIR and LINK Types
    // TODO: Uncomment this for scoping search
    // function handleSelectedItems(_selected: string[]) {
    //     search_filters.resetParentScope();
    //     const selected_entries = _.filter(current_directory.entries, (entry: EntryItem) => {
    //         const is_entry = (selected.includes(entry.id) && (entry.type === DriveEntryType.DIR || entry.type === DriveEntryType.LINK));
    //         !!is_entry && search_filters.addParentScope(entry.id);
    //         return is_entry;
    //     });
    //     setFilters(search_filters.filter_set);
    //     setSelectedFolders(selected_entries);
    // }

    return <SearchWrapper
        active={active}
        handleAction={handlePageActions}>
        <div className="search-box">
            <SearchForm
                search_term={search_term}
                setSearchTerm={setSearchTerm}
                handleAction={handlePageActions} />
            {
                active &&
                <SearchDropdown
                    search_term={search_term}
                    results={results}
                    sort={sort}
                    filters={filters}
                    progress={progress}
                    error={error}
                    selected_folders={selected_folders}
                    setSearchTerm={setSearchTerm}
                    handleAction={handlePageActions} />
            }
        </div>
    </SearchWrapper>;
}

export default React.memo(DriveSearchComponent);
