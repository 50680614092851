import React, { useRef, useState, useEffect } from "react";
import { ActionHandlerFunction, IActionHandler, CollectionServerUser } from "@preveil-api";
import { 
  Account, 
  AdminUIActionTypes, 
  GlobalErrorMessages, 
  MessageAnchors, 
  useAppDispatch, 
  Message, 
  MessageHandlerDisplayType, 
  useCsvDataValidation, 
  AdminErrorMessages, 
  usePostUsersFindMutation,
  InviteEntity,
  AdminUser,
  AdminUserExternalInvite,
  AdminUserSubsumeInvite
} from "src/common";
import { CsvSubmitData, DraggableContainer, Icon } from "src/components";
import { uiActions } from "src/store";
import { AddSingleUserForm, UserInfoCard, ConfirmAccountInvite, AdminUsersInvitesListModalPanel } from "..";
import { Modal, Tab, Tabs, Button, Collapse } from "react-bootstrap";
import _ from "lodash";

type AllProps = {
  current_account: Account;
  orgUsers: any;
  show_modal: boolean;
  local_users_accounts_map: Map<string, Account> | undefined;
  handleActions: ActionHandlerFunction;
  submit_success: boolean;
}

const CSV_COLUMNS = {
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email Address",
  department: "Department"
} as const;

type UserInviteMap = {
  [key: string]: InviteEntity;
};

function AddUsersModal({ current_account, orgUsers, show_modal, local_users_accounts_map, handleActions, submit_success }: AllProps) {
  const dispatch = useAppDispatch();
  const tabEventKey = {
    individual: "Add Users",
    csvUpload: "Importing Users"
  };
  const addUsersColumnsName: string[] = [...Object.values(CSV_COLUMNS)];
  const [tab_heading, setTabHeading] = useState<string>(tabEventKey.individual);
  const [open_collapse, setOpenCollapse] = useState<boolean>(false);
  const [show_existing_account_step, setShowExistingAccountStep] = useState<boolean>(false);
  const [subsume_accounts, setSubsumeAccount] = useState<any>([]);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [csv_data_hash, setCsvDataHash] = useState<UserInviteMap>({}); // We want to keep a reference of the csv data to be used later.
  const addressesFromOrgUsers: string[] = Array.from(orgUsers.keys()); // Contains all the user_ids/addresses from the organization (including pending/invited users).

  const csv_rows_with_missing_fields = useRef<string[]>([]);
  const { validAddresses, invalidAddresses, duplicateAddresses, isDataDrop, handleValidateData, resetData } = useCsvDataValidation(addressesFromOrgUsers);

  const [ findUsers ] = usePostUsersFindMutation();

  useEffect(() => {
    if (submit_success && show_modal && isDataDrop) {
      setIsDataLoading(false);
      resetData();
    }
  }, [submit_success]);

  const AddUsersRequests = {
    handleShowModal: () => {
      handleActions({ actionType: AdminUIActionTypes.ShowModal });
      resetData();
    },
    handleSubmitUserData: (user: InviteEntity) => {
      submitUserData(user);
    },
    handleCsvData: (csvFile: any) => {
      handleCsvFileData(csvFile);
    },
    handleReset: () => {
      resetData();
    },
    handleResetSingleSubsumeInvite: () => {
      setShowExistingAccountStep(false);
      setSubsumeAccount([]);
    },
    handleCancelSingleSubsumeInvite: () => {
      handleActions({ actionType: AdminUIActionTypes.ShowModal });
      setSubsumeAccount([]);
      setShowExistingAccountStep(false);
    },
    handleDelete: (params: string[]) => {
      const user_id = params[0];
      handleDeleteAction(user_id);
    },
    handleSubmitCsvData: () => {
      // Note: we could probably saved in state the validSubsumeAccountInvites and validExternalAccountInvites
      // and there would be no need to iterate over the csv_data_hash to get the user data again.
      // but this is probably easier and less costly than updating state everytime invites are changed or removed.
      const externalAccountsCollection: AdminUserExternalInvite[] = [];
      const subsumeAccountsCollection: AdminUserSubsumeInvite[] = [];
      validAddresses.forEach((address: string) => {
        const { firstName, lastName, email, department } = csv_data_hash[address];
        if (local_users_accounts_map?.has(email)) {
          const subsumeUserInvite = new AdminUser(email, firstName, lastName, department).subsumeAccountFormat();
          subsumeAccountsCollection.push(subsumeUserInvite);
        } else {
          // format for external accounts
          const externalUserInvite = new AdminUser(email, firstName, lastName, department).apiPayloadFormat();
          externalAccountsCollection.push(externalUserInvite);
        }
      });
      setIsDataLoading(true);
      handleActions({ actionType: AdminUIActionTypes.SubmitCsvData, params: { externalAccounts: externalAccountsCollection, subsumeAccounts: subsumeAccountsCollection } });
    },
    handleSubmitUserInvite: () => {
      // This is for the subsume account invite (individual invite), if the account is external (individual invite) it won't go through this process.
      setShowExistingAccountStep(false);
      setSubsumeAccount([]);
      const subsumeAccount = subsume_accounts[0].subsumeAccountFormat();
      handleActions({ actionType: AdminUIActionTypes.SubmitSubsumeUserInvite, params: subsumeAccount });
    },
    unHandleErrorMessage: (params: { message: string, stack?: any }) => {
      const { message, stack } = params;
      dispatch(uiActions.handleRequestErrors(new Message(message, MessageHandlerDisplayType.logger), stack));
    }
  };

  // Description: This is for the individual user invite in the modal.
  // We are checking if the user is an express subsume account, full subsume account or a new/external account.
  async function submitUserData(user: InviteEntity) {
    const { userInviteEntity, isSubsume } = parseIndividualUserInviteData(user);
    if (isSubsume) {
      setSubsumeAccount([userInviteEntity]);
      setShowExistingAccountStep(true);
    } else {
      const { email } = user;
      const response = await getUser([email]);
      if (!!response && response.users.length > 0) {
        const { users } = response;
        if (users.length === 1 && users[0].user_id === email) { 
          const isExpressSubsume = true;
          const { userInviteEntity } = parseIndividualUserInviteData(user, isExpressSubsume);
          setSubsumeAccount([userInviteEntity]);
          setShowExistingAccountStep(true);
        }
      } else {
        handleActions({ actionType: AdminUIActionTypes.SubmitUserInvite, params: [userInviteEntity] });
      }
    }
  }

  // Description: After getting the response from getUser
  // if there are any express subsume accounts, we will add them to the local_users_accounts_map.
  async function findCsvUsers(addressesFromCsv: string[]) {
    const result = await getUser(addressesFromCsv);
    if (!!result) {
      const { users } = result;
      if (!!users && users.length > 0) {
        users.forEach((user: CollectionServerUser) => {
          const { user_id } = user;
          local_users_accounts_map?.set(user_id, user as any);
        });
      }
    }
  };

  // Description: Get the user data from the API.
  // We are using the findUsers API to see if the user is an express subsume account.
  async function getUser(user_ids: string[]) {
    if (current_account && user_ids.length > 0) {
      const users = user_ids.map((userId) => {
        return { user_id: userId, key_version: -1 };
      });
      return await findUsers({
        account_ids: Account.getAccountIdentifiers(current_account),
        body: { spec: users }
      })
      .unwrap()
      .then((response) => {
        const { users, errors } = response;
        return { users, errors };
      })
      .catch((error) => {
        handleActions({ actionType: AdminUIActionTypes.PageErrorMessage, params: { message: AdminErrorMessages.error_finding_user_accounts_from_csv, stack: error } });
      });
    }
  }

  // Description: Identify if the user invite is for a subsume account (full or express) or a new account (external account).
  // After that, we will format the user invite data to be sent to the API (external account)
  // or to be displayed in the UI (subsume account).
  // This is for Individual User Invite.
  function parseIndividualUserInviteData(userInvite: InviteEntity, isExpressSubsume?: boolean) {
    const { email, firstName, lastName, department } = userInvite;
    const _email = email.toLowerCase();
    let userInviteEntity;
    let isSubsume = false;
    
    if (local_users_accounts_map?.has(userInvite.email) || isExpressSubsume) {
      userInviteEntity = new AdminUser(_email, firstName, lastName, department);
      isSubsume = true;
    } else {
      userInviteEntity = new AdminUser(_email, firstName, lastName, department).apiPayloadFormat();
    }
    return { userInviteEntity, isSubsume };
  }

  // Description: After the CSV data is validated, we will split the valid addresses into subsume and external accounts.
  function separateSubsumeAndExternalAccounts(usersInvites: string[]) {
    if (usersInvites.length === 0) return { validSubsumeAccountInvites: [], validExternalAccountInvites: [] };
    // We are partitioning the valid addresses into subsume and external accounts.
    const [subsumeCollection, collection] = _.partition(usersInvites, (address: string) => local_users_accounts_map?.has(address));
    return {
      validSubsumeAccountInvites: subsumeCollection,
      validExternalAccountInvites: collection
    };
  }

  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    if ((AddUsersRequests as any)[callback] instanceof Function) {
      (AddUsersRequests as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(MessageAnchors.actionType, actionObj.actionType);
      AddUsersRequests.unHandleErrorMessage({ message, stack: actionObj });
    }
  }

  // Description: Handling the CSV data after it's been parsed.
 async function handleCsvFileData(csvFile: any) {
    if (csvFile?.errors && csvFile?.errors.length >= 1) {
      // Papaparse returns some errors when the CSV formatting is not correct
      // or the data is missing a delimiter in each row, but that doesn't mean the data will not be parse.
      // so we are just logging those errors.
      handleActions({ actionType: AdminUIActionTypes.PageErrorMessage, params: { message: AdminErrorMessages.error_csv_upload_format, stack: csvFile.errors } });
    }
    const { data, meta } = csvFile;
    const { fields } = meta;
    const isValidColumns = fields.length === addUsersColumnsName.length && fields.every((field: string) => addUsersColumnsName.includes(field));
    if (!isValidColumns || data.length === 0) {
      if (data.length === 0) {
        handleActions({ actionType: AdminUIActionTypes.PageErrorMessage, params: { message: AdminErrorMessages.error_csv_empty_file, stack: data } });
      } else if (!isValidColumns) {
        handleActions({ actionType: AdminUIActionTypes.PageErrorMessage, params: { message: AdminErrorMessages.error_csv_wrong_heading, stack: meta.fields } });
      }
    } else {
      // Description: We only need the email address/user_id to validate each row data of the CSV.
      // And also keeping a reference (inside a ref) of the csv data to be used later.
      const userAddressesFromCsv: string[] = [];
      const externalAccountsCollection: any = [];
      const csv_data_hash: UserInviteMap = {};
      csv_rows_with_missing_fields.current = [];
      data.forEach((row: any, index: string) => {
        const address = row[CSV_COLUMNS.email] || "";
        const department = row[CSV_COLUMNS.department] || "";
        const firstName = row[CSV_COLUMNS.firstName] || "";
        const lastName = row[CSV_COLUMNS.lastName] || "";
        // Note: We'll consider a row valid, if the email and department are present and at least one of first-name and last-name
        const isValidRow = !!address && !!department && (!!firstName || !!lastName);
        // Note: We are using the email as the id for the csv data hash, if the row is not valid we'll generate a unique id.
        // This is to keep the reference of the csv data to be used later.
        // All the invalid rows will fail the validation inside useCsvDataValidation and will be stored in the invalidAddresses array.
        const id = isValidRow ? address : _.uniqueId(`row_${index}`);
        userAddressesFromCsv.push(id);
        !local_users_accounts_map?.has(address) && externalAccountsCollection.push(address);
        csv_data_hash[id] = { firstName, lastName, email: address, department };
      });
      setCsvDataHash(csv_data_hash); // keeping the reference of the csv data in state since we are going to pass it down to other components.
      if (externalAccountsCollection.length > 0) {
        try {
          // if there are accounts that are not in the local_users_accounts_map, we need to find them and see if they are express subsume accounts.
          await findCsvUsers(externalAccountsCollection);
        } catch(error) {
          handleActions({ actionType: AdminUIActionTypes.PageErrorMessage, params: { message: AdminErrorMessages.error_finding_user_accounts_from_csv, stack: error } });
        }
      }
      handleValidateData(userAddressesFromCsv);
    }
  };

  // Description: Handling the delete action for the csv addresses
  function handleDeleteAction(id: string | undefined) {
    if (!!id) {
      const data = [...validAddresses, ...invalidAddresses, ...duplicateAddresses].filter((address: string) => address !== id);
      handleValidateData(data);
    }
  }

  // Description: Handling changing modal tabs and including the correct header.
  function handleTabSelect(eventKey: string | null) {
    const tab_heading = eventKey === "csvUpload" ? tabEventKey.csvUpload : tabEventKey.individual;
    setTabHeading(tab_heading);
  }

  // Description: Handling the modal close event.
  function handleCancelOnClose() {
    handleActions({ actionType: AdminUIActionTypes.ShowModal });
    resetData();
  }

  const { validSubsumeAccountInvites, validExternalAccountInvites } = separateSubsumeAndExternalAccounts(validAddresses);
  const showInvalidAreaContent = invalidAddresses.length > 0 || duplicateAddresses.length > 0;
  const isValid = validSubsumeAccountInvites.length > 0 || validExternalAccountInvites.length > 0;
  const showDivisor = isValid;
  return (
    <>
      <Modal
        size="lg"
        show={show_modal}
        onHide={handleCancelOnClose}
        aria-labelledby="invite-users"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{tab_heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!show_existing_account_step ?
            <Tabs defaultActiveKey="individual" id="invite-new-users" onSelect={handleTabSelect}>
              <Tab eventKey="individual" title="Individual">
                <AddSingleUserForm users_list={addressesFromOrgUsers} org_users={orgUsers} handleActions={handlePageActions} />
              </Tab>
              <Tab eventKey="csvUpload" title="Group Upload">
                <CsvSubmitData handleAction={handlePageActions} isValid={isValid} submitButtonLabel="Add/Create Accounts" isDataLoading={isDataLoading}>
                  {validSubsumeAccountInvites.length > 0 && isDataDrop && (
                    <AdminUsersInvitesListModalPanel
                      csv_data_hash={csv_data_hash}
                      validation_state="valid_subsume"
                      collection={validSubsumeAccountInvites}
                      handleActions={handlePageActions}
                    />
                  )}
                  {validExternalAccountInvites.length > 0 && isDataDrop && (
                    <AdminUsersInvitesListModalPanel
                      csv_data_hash={csv_data_hash}
                      validation_state="valid_external"
                      collection={validExternalAccountInvites}
                      handleActions={handlePageActions}
                    />
                  )}
                  {showInvalidAreaContent && (
                    <div className={`invalid-addresses-card ${showDivisor ? "show-divider-line" : ""}`}>
                      <header>
                        <h3 className="mb-2">
                          Invites that will <i className="emphasis">not</i> be sent
                        </h3>
                        <Button
                          variant="icon"
                          onClick={() => setOpenCollapse(!open_collapse)}
                          aria-controls="collapse-invalid-area"
                        >
                          <Icon
                            className={`${open_collapse ? "ficon-chevron-up" : "ficon-chevron-down"}`}
                          />
                        </Button>
                      </header>
                      <Collapse in={open_collapse}>
                        <div id="collapse-invalid-area">
                          <div className="pt-2 pb-0">
                            {duplicateAddresses.length >= 1 && isDataDrop && (
                              <AdminUsersInvitesListModalPanel
                                csv_data_hash={csv_data_hash}
                                validation_state="duplicate"
                                collection={duplicateAddresses}
                                handleActions={handlePageActions}
                              />
                            )}
                            {invalidAddresses.length >= 1 && isDataDrop && (
                              <AdminUsersInvitesListModalPanel
                                csv_data_hash={csv_data_hash}
                                validation_state="invalid"
                                collection={invalidAddresses}
                                handleActions={handlePageActions}
                              />
                            )}
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  )}
                  {!isDataDrop && (
                    <>
                      <p className="download-csv-legend">
                        Download and use the upload template to accurately add multiple users at once
                        <a
                          href="/templates/PreVeil-User-Upload-Template.csv"
                          target="_blank">
                          <Icon className="ficon-download" />
                          Preveil-User-Upload-Template.csv
                        </a>
                      </p>
                      <DraggableContainer handleAction={handlePageActions} />
                    </>
                  )}
                </CsvSubmitData>
              </Tab>
            </Tabs>
            : <ConfirmAccountInvite
              handleActions={handlePageActions}
            >
              <div className="mt-3 mb-3">
                <h3>Existing PreVeil Account</h3>
                <UserInfoCard users={[subsume_accounts[0].memberUiFormat()]} />
              </div>
            </ConfirmAccountInvite>}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default React.memo(AddUsersModal);