import React from "react";
import { ActionHandlerFunction } from "@preveil-api";
import { AdminUser, InviteEntity } from "src/common";
import { AdminRow } from "src/components";

type RefHashHash = { [key: string]: InviteEntity };

type AllProps = {
  csv_data_hash: RefHashHash;
  validation_state: "duplicate" | "invalid" | "valid_subsume" | "valid_external";
  collection: string[];
  handleActions?: ActionHandlerFunction;
  is_delete?: boolean;
};

const statusHeading = {
  duplicate: "Accounts already in Organization.",
  invalid: "Accounts with error.",
  valid_subsume: "Existing PreVeil Accounts.",
  valid_external: "Accounts to be created."
};

function AdminUsersInvitesListModalPanel({ csv_data_hash, validation_state, collection, handleActions, is_delete }: AllProps) {
  const headingText = statusHeading[validation_state];
  const totalInvites = collection.length;
  const heading = totalInvites === 1 ? headingText.replace("Accounts", "Account") : headingText;

  return (
    <section className="csv-upload-card-section">
      <div className="groups-card">
        <header className="d-flex align-items-center pt-2 pb-2">
          <h5 className="m-0">{`${totalInvites} ${heading}`}</h5>
        </header>
        <div>
          {collection.map((userId) => {
            const { firstName = "", lastName = "", email = "", department = "" } = csv_data_hash[userId];
            const user = new AdminUser(email, firstName, lastName, department).memberUiFormat();
            const isDelete = validation_state !== "invalid" && validation_state !== "duplicate";
            return (
              <AdminRow
                key={user.id}
                member={user}
                handleActions={handleActions}
                is_delete={isDelete}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default React.memo(AdminUsersInvitesListModalPanel);