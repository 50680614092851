import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Message, MessageHandlerDisplayType, PrivateRoutes, useAppDispatch } from "src/common";
import { uiActions } from "src/store";

interface FileInfo {
  name: string;
  type: string;
  coll_id: string;
  node_id: string;
  entry_state_tracker: string | null;
  local_node_info: string | null;
  user_root?: string;
}

function Diagnostic() {
  const { user_id, path } = useParams();
  const [files, setFiles] = useState<FileInfo[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!!user_id) {
      fetchDirectoryListing(user_id, path);
    } else {
      dispatch(uiActions.handleRequestErrors(new Message("User ID not set!", MessageHandlerDisplayType.toastr)));
    }
  }, []);

  const fetchDirectoryListing = (user_id: string, path?: string) => {
    const formData = new FormData();
    formData.append("uid", user_id);
    if (!!path) {
      formData.append("path", path);
    }

    fetch(`${process.env.REACT_APP_FILESYNC_SERVER}/diagnostics/files`, {
      method: "PUT",
      body: formData,
    })
      .then((response) => response.json())
      .then((filesData: FileInfo[]) => {
        setFiles(filesData);
      })
      .catch((error) => {
        dispatch(uiActions.handleRequestErrors(new Message(error, MessageHandlerDisplayType.toastr), error));
      });
  };

  const renderFileRow = (file: FileInfo) => {
    const linkHref = `${process.env.REACT_APP_PORT}/developer/${PrivateRoutes.diagnostics}/${encodeURIComponent(user_id || "").replace(
      /\+/g,
      "%2B"
    )}${encodeURIComponent(path || "").replace(/\+/g, "%2B")}/${encodeURIComponent(`${file.user_root}/${file.name}`)}`;

    return (
      <tr key={file.name}>
        <td>
          {file.type === "Directory" ? (
            <a href={linkHref}>
              {file.name}
            </a>
          ) : (
            file.name
          )}
        </td>
        <td>{file.type}</td>
        <td>{file.coll_id}</td>
        <td>{file.node_id}</td>
        <td>{file.entry_state_tracker && Object.keys(file.entry_state_tracker).length > 0 ? "Yes" : "No"}</td>
        <td>{file.local_node_info && file.local_node_info !== "" ? "Yes" : "No"}</td>
      </tr>
    );
  };

  return (
    <div>
      <h1>Directory Listing</h1>
      <table className="diagnostics table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Collection ID</th>
            <th>Node ID</th>
            <th>Entry State Tracker</th>
            <th>Node Store</th>
          </tr>
        </thead>
        <tbody>
          {files.map(renderFileRow)}
        </tbody>
      </table>
    </div>
  );
};

export default React.memo(Diagnostic);
