import React, { useState, MouseEvent, Dispatch, SetStateAction } from "react";
import { Button, Dropdown, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { DeviceBase } from "@preveil-api";
import {
  Account, AppConfiguration, Device, DevicePlatform, DeviceStatus, LockDeviceApiResponse, Message, MessageHandlerDisplayType,
  MessageToastTypes, OrgUserRoles, PublicRoutes, useAppDispatch, useDeleteUsersDevicesKeysByDeviceIdMutation, useLockDeviceMutation,
  useRemoveAccountFromCurrentDeviceMutation, useUnlockDeviceMutation, QueryParamKeys
} from "src/common";
import { Icon } from "src/components";
import { uiActions, accountActions } from "src/store";
import { DeviceConfirmation, RenameForm } from ".";

type AllProps = {
  device: DeviceBase;
  current_account: Account;
  devices: DeviceBase[];
  setDevices: Dispatch<SetStateAction<DeviceBase[]>>;
};

function DeviceRowComponent(props: AllProps) {
  const { device, current_account, devices, setDevices } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [editing_device, setEditingDevice] = useState<DeviceBase | undefined>();
  const [action, setAction] = useState<string>("");
  const [modal_show, setModalShow] = useState<boolean>(false);
  const [lockDevice] = useLockDeviceMutation();
  const [unlockDevice] = useUnlockDeviceMutation();
  const [disconnectDevice] = useRemoveAccountFromCurrentDeviceMutation();
  const [deleteDevice] = useDeleteUsersDevicesKeysByDeviceIdMutation();
  const current_device_id = current_account.current_device_id;

  /* Description: Gets the status of the device: locked, current, or active. */
  function status() {
    if (!device.is_active) {
      return DeviceStatus.locked;
    }
    return device.device_id === current_device_id
      ? DeviceStatus.current_device
      : DeviceStatus.active;
  }

  /* Description: Locks or Unlocks Device depending on the action. */
  async function lockOrUnlockDevice() {
    let query;
    if (action === "lock") {
      query = lockDevice({ userId: current_account.user_id, deviceId: device.device_id });
    } else {
      query = unlockDevice({ userId: current_account.user_id, deviceId: device.device_id });
    }
    query
      .unwrap()
      .then((response: LockDeviceApiResponse) => {
        const devs = devices.filter((d) => d.device_id !== response.device_id);
        devs.push(response);
        setDevices(devs);
        dispatch(
          uiActions.handleSetMessage(
            new Message(`Successfully ${action}ed “${device.device_name}”`),
          ),
        );
      })
      .catch((stack) => {
        dispatch(
          uiActions.handleRequestErrors(
            new Message(`Error ${action}ing “${device.device_name}”`),
            stack,
          ),
        );
      });
  }

  /* Description: Disconnects device and then logs out, sets current_account to undefined and redirects to the choose
  account page. */
  async function DisconnectDevice() {
    disconnectDevice({ userId: current_account.user_id })
      .unwrap()
      .then(() => {
        dispatch(accountActions.revokeCurrentUser());
        // NOTE: Navigate to Logout to get full logout (store and sessions cleared)
        navigate(`/${PublicRoutes.logout_route}?${QueryParamKeys.REDIRECT_URL_QUERY_KEY}=/${PublicRoutes.choose_account_route}`);
      })
      .catch((msg) => {
        dispatch(
          uiActions.handleRequestErrors(
            new Message(
              `${msg.status === 403 ? "FORBIDDEN" : msg.status}: disconnecting device!`,
            ),
            msg,
          ),
        );
      });
  }

  /* Description: Delete the device */
  async function DeleteDevice() {
    if (AppConfiguration.buildForWeb() && device.platform === DevicePlatform.session.text.toLowerCase()) {
      return;
    }
    const request = {
      account_ids: Account.getAccountIdentifiers(current_account),
      body: {
        device_id: device.device_id,
        user_id: current_account.user_id,
      },
    };
    deleteDevice(request)
      .unwrap()
      .then(() => {
        !AppConfiguration.buildForWeb() && setDevices(devices.filter((d) => d.device_id !== device.device_id));
      })
      .catch((msg) => {
        dispatch(
          uiActions.handleRequestErrors(
            new Message(`Error deleting “${device.device_name}”`),
            msg,
          ),
        );
      });
  }

  /* Description: returns a boolean, whether the device can be unlocked or not. */
  function canBeUnlocked(lock_by_user_id: string): boolean {
    if (!!current_account.org_info && current_account.org_info.role === OrgUserRoles.admin) {
      // NOTE: admin can always unlock their device regardless of who has locked theirs.
      return true;
    }
    return current_account.user_id.toLowerCase() === lock_by_user_id.toLowerCase();
  };

  /* Description: Checks if device has been locked by admin. */
  function lockedByAdmin(): boolean {
    return (
      !device.is_active && !!device.lock_info && !canBeUnlocked(device.lock_info.lock_by_user_id)
    );
  };

  /* Description: Modal for Unlock Action. */
  function confirmUnlock() {
    const confirmation_dialog = new Message(
      `Are you sure you want to unlock this device? ${device.device_name} will be able to access your account again.`,
      MessageHandlerDisplayType.confirm,
      MessageToastTypes.primary,
      "Unlock Device?",
      {
        label: "Yes",
        data: true,
        action: lockOrUnlockDevice,
      },
      { label: "No" },
    );
    dispatch(uiActions.handleSetMessage(confirmation_dialog));
  }

  return (
    <>
      <DeviceConfirmation
        device_name={device.device_name}
        modal_show={modal_show}
        setModalShow={setModalShow}
        action={action}
        func={
          action === "lock"
            ? lockOrUnlockDevice
            : action === "disconnect"
              ? DisconnectDevice
              : DeleteDevice
        }
      ></DeviceConfirmation>
      <Row>
        <Col sm={4} md={6} className="d-flex align-items-center ps-0">
          <Icon
            className={`pv-icon-${DevicePlatform[device.platform].class || DevicePlatform.default.class
              } device-icon`}
          ></Icon>
          {!!editing_device && editing_device.device_id === device.device_id && (
            <RenameForm
              device={device}
              current_account={current_account}
              setEditingDevice={setEditingDevice}
              devices={devices}
              setDevices={setDevices}
            ></RenameForm>
          )}
          {!editing_device && <div onClick={() => setEditingDevice(device)}>{device.device_name}</div>}
        </Col>
        <Col sm={3} md={4}>
          <Button
            variant="transparent"
            className={`notification-count ${Device.deviceStatusColor(status().class)}`}
            aria-label="Close"
          >
            {status().text}
            {!!lockedByAdmin() && <span>&nbsp;by Admin</span>}
          </Button>
        </Col>
        <Col sm="auto" className="ms-auto">
          <Dropdown as="button" className="btn btn-icon btn-sm"
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              e.preventDefault();
            }}>
            <Dropdown.Toggle as="span">
              <Icon className="pv-icon-more"></Icon>
            </Dropdown.Toggle>
            <Dropdown.Menu align="end">
              <Dropdown.Item onClick={() => { setEditingDevice(device); }}>Rename</Dropdown.Item>
              {device.device_id === current_device_id ? (
                <Dropdown.Item
                  onClick={() => {
                    setAction("disconnect");
                    setModalShow(true);
                  }}
                >
                  Disconnect this device
                </Dropdown.Item>
              ) : (
                <>
                  {!!device.is_active && (
                    <Dropdown.Item
                      onClick={() => {
                        setAction("lock");
                        setModalShow(true);
                      }}
                    >
                      Lock
                    </Dropdown.Item>
                  )}
                  {!device.is_active && (
                    <Dropdown.Item
                      onClick={() => {
                        setAction("unlock");
                        confirmUnlock();
                      }}
                    >
                      Unlock
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    onClick={() => {
                      setAction("delete");
                      setModalShow(true);
                    }}
                  >
                    Delete
                  </Dropdown.Item>{" "}
                </>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
    </>
  );
}

export default React.memo(DeviceRowComponent);
