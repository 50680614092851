import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Navbar, Nav, Image } from "react-bootstrap";
import { OrgInfo } from "@preveil-api";
import { AppConfiguration, Account, NavigationPath, NavigationPathTypes, OrgUserRoles, defaultWebPublicUrls } from "src/common";
import { UserNavigation, SupportDropdown, SettingsDropdown, AccountDropdown, NotificationsButton } from "./components";
import logo from "src/assets/images/logos/prevel-logo.png";
import express_logo from "src/assets/images/logos/prevel-express-logo.png";

type AllProps = {
  current_account?: Account;
  organization?: OrgInfo;
  accounts?: Account[];
  type?: NavigationPathTypes;
  active_path?: string;
}
const is_web = AppConfiguration.buildForWeb();
function TopNavigationComponent(props: AllProps) {
  const { current_account, accounts, organization, type, active_path } = props;
  const current_location = useLocation();
  const [logo_src, setLogo] = useState<string>(getLogoSrc());
  const admin_enabled = !is_web && !!organization && organization.role === OrgUserRoles.admin;

  // Description: Update on navigation if needed
  useEffect(() => {
    const _logo_src = getLogoSrc();
    _logo_src !== logo_src && setLogo(_logo_src);
  }, [current_location]);

  // NOTE: Will not be using web_logo
  function getLogoSrc() {
    return !is_web || (defaultWebPublicUrls.includes(current_location.pathname)) ? logo : express_logo;
  }

  return <header id="preveil-header">
    <Navbar variant="dark" expand="md" fixed="top"  >
      <Container fluid>
        <Navbar.Brand href="/">
          <span className="d-none">PreVeil</span>
          <Image alt="PreVeil Logo" src={logo_src} />
        </Navbar.Brand>
        {
          !!current_account &&
          <Nav className="mobile-user-nav">
            <UserNavigation type={type} admin_enabled={admin_enabled}/>
            {/* TO DO: Hide Notifications until ready to dev */}
            <NotificationsButton type={type} />
          </Nav>
        }
        <Navbar.Toggle aria-controls="top-navbar" />
        <Navbar.Collapse id="top-navbar" >
          <Nav className="user-nav hide-mobile">
            {!!current_account && <UserNavigation type={type} admin_enabled={admin_enabled}/>}
          </Nav>
          <Nav>
            {
              !!current_account ?
                <>
                  <NotificationsButton type={type} className="hide-mobile" />
                  <SupportDropdown />
                  <SettingsDropdown active={type === NavigationPath.SettingsNavigation} active_path={active_path} current_account={current_account} />
                  <AccountDropdown current_account={current_account}
                    accounts={accounts}
                    active={type === NavigationPath.MailNavigation} />
                </> :
                <SupportDropdown />
            }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </header>;
}

export default React.memo(TopNavigationComponent);
