import React from "react";
import { Card } from "react-bootstrap";
import { Account, AppConfiguration, useAppSelector } from "src/common";
import { PageHeader } from "src/components";
import { DeleteAccount, EmailSignature, ProfileForm } from ".";

function AccountProfileComponent() {
  const current_account = useAppSelector((state) => state.account.current_account);
  const is_express = AppConfiguration.buildForExpress();

  return <>
    <PageHeader>
      <h1>Profile</h1>
    </PageHeader>
    <Card className="card-section">
      <ProfileForm />
      {
        !!current_account &&
        <EmailSignature account_ids={Account.getAccountIdentifiers(current_account)} />
      }
      {
        (!!current_account && is_express) && <DeleteAccount current_account={current_account} />
      }
    </Card>
  </>;
}

export default React.memo(AccountProfileComponent);
