import * as React from "react";
import { useEffect, useState } from "react";
import { IActionHandler, MessageDisplayType, Sort, OrgInfo, CollectionServerUser } from "@preveil-api";
import {
  GlobalErrorMessages,
  Message,
  MessageAnchors,
  MessageHandlerDisplayType,
  RequestTab,
  SimplePaginationAction,
  SimplePaginationActionType,
  useAppDispatch,
  Account,
  ApprovalRequestStatus,
  GlobalConstants,
  ApprovalRequestStatusType,
  SettingsSuccessMessages,
  SettingsErrorMessages,
  useGetOrgInfo,
  collectionApi,
  GetUsersOrgsByEntityIdRequestsAndRequestIdResponsesApiResponse,
  useDeleteUsersOrgsByEntityIdRequestsAndRequestIdMutation,
  DeleteUsersOrgsByEntityIdRequestsAndRequestIdApiResponse,
  SORT_DIRECTION,
  sortBy,
  ApprovalGroup,
  getOppositeSortDirection,
  Pagination,
  useAppSelector,
  AdminErrorMessages,
  UserRequest
} from "src/common";
import { adminActions, uiActions } from "src/store";
import { ApprovalRequestsParent, DefaultTemplate } from "../..";

type AllProps = {
  current_account: Account;
  org_info: OrgInfo;
};

/* Description: This is the Admin Approval Requests Component which is similar in functionality 
  to the settings UserRequests and Approvals components as it uses the same shared component */
function AdminApprovalRequestsComponent(props: AllProps) {
  const { current_account, org_info } = props;
  const [is_loading, setIsLoading] = useState<boolean>(true);
  const [is_loading_history, setIsLoadingHistory] = useState<boolean>(false);
  const [requests, setRequests] = useState<UserRequest[]>([]);
  const [history_requests, setHistoryRequests] = useState<UserRequest[]>([]);
  const [org_users, setOrgUsers] = useState<CollectionServerUser[]>();
  const [approval_groups, setApprovalGroups] = useState<ApprovalGroup[]>();
  const [is_details_loading, setIsDetailsLoading] = useState<boolean>(false);
  const [sort, setSort] = useState<Sort<keyof UserRequest>>({ field: "timestamp", direction: "asc" });
  const dispatch = useAppDispatch();
  const pagination = useAppSelector((state) => state.admin.pagination);
  const [page, setPage] = useState<number>(0);
  const [getResponses] = collectionApi.endpoints.getUsersOrgsByEntityIdRequestsAndRequestIdResponses.useLazyQuery();
  const [deleteAdminRequest] = useDeleteUsersOrgsByEntityIdRequestsAndRequestIdMutation();
  const [filter_status, setFilterStatus] = useState<ApprovalRequestStatusType>(ApprovalRequestStatus.approved);
  const { data, error, refresh } = useGetOrgInfo(current_account, org_info);

  /* Description: On load, the refresh call from the usegetorginfo hook is called for getting the 
  pending requests. On cleanup - reset pagination */
  useEffect(() => {
    refresh({
      history: false,
      status: "pending",
      hideExpired: true,
      limit: 200,
    });
    return () => {
      dispatch(adminActions.resetPagination());
    };
  }, []);

  /* Description: This handles the data and error whenever refresh() is called and sets all the 
  data in the components state. */
  useEffect(() => {
    if (!!data && !!data.org_requests) {
      if (!!is_loading_history) {
        const sorted_requests = sortBy(data.org_requests, { field: sort.field, direction: sort.direction });
        setHistoryRequests(sorted_requests);
        setIsLoadingHistory(false);
      } else {
        setRequests(data.org_requests);
      }
      const totalPageRows = data.org_requests.length;
      const totalRows = data.total_request_rows || data.org_requests.length;
      if (totalPageRows > 0 && totalRows > 0) {
        const paginationItem = new Pagination(
          page,
          totalPageRows,
          totalRows,
          GlobalConstants.REQUESTS_PER_PAGE_COUNT,
        ).pagination_item;
        !!paginationItem && dispatch(adminActions.setPagination(paginationItem));
      } else {
        dispatch(adminActions.resetPagination());
      }
      setOrgUsers(data.org_users);
      setApprovalGroups(data.approval_groups);
      setIsLoading(false);
    }
    !!error &&
      dispatch(
        uiActions.handleRequestErrors(
          new Message(AdminErrorMessages.error_fetching_requests),
          error,
        ),
      );
  }, [data, error]);

  /* Description: Gets the Request Responses to display the progress section on the side panel.
  i.e which users have approved or rejected the request and which users we are still waiting for. */
  async function getRequestResponses(request_id: string) {
    setIsDetailsLoading(true);
    const account_ids = Account.getAccountIdentifiers(current_account);
    const params = {
      account_ids,
      body: {
        entityId: org_info.org_id,
        requestId: request_id,
      },
    };
    await getResponses(params)
      .unwrap()
      .then((response: GetUsersOrgsByEntityIdRequestsAndRequestIdResponsesApiResponse) => {
        if (!!org_users) {
          const returned_response = response.responses.map((r) => {
            const user = org_users.find((user) => user.user_id.toLowerCase() === r.approver.toLowerCase());
            return {
              approver: { name: user?.display_name || null, address: user?.user_id || "" },
              response: r.response,
            };
          });
          const updated_request_index = requests.findIndex((request) => request.request_id === request_id);
          requests[updated_request_index].request_responses = returned_response;
          setRequests(requests);
        }
        setIsDetailsLoading(false);
      })
      .catch((stack) => {
        dispatch(
          uiActions.handleRequestErrors(
            new Message(SettingsErrorMessages.error_fetching_responses),
            stack,
          ),
        );
        setIsDetailsLoading(false);
      });
  }

  /* Description: Handles all children component actions and store it */
  const AdminApprovalsCalls = {
    /* Description: This is the Admin Approval Requests Component which is similar in functionality 
    to the settings UserRequests and Approvals components as it uses the same shared component */
    handleSetSort: async (params: { tab: string; field: "mapped_action" | "requester_id" | "expiration" | "timestamp" }) => {
      const { tab, field } = params;
      const _requests = tab === RequestTab.history ? history_requests : requests;
      const direction =
        sort.field === field ? getOppositeSortDirection(sort.direction) : SORT_DIRECTION.ascending;
      const sorted_requests = sortBy(_requests, { field, direction });
      setSort({ field, direction });
      tab === RequestTab.history ? setHistoryRequests(sorted_requests) : setRequests(sorted_requests);
    },
    /* Description: This is the Admin Approval Requests Component which is similar in functionality 
    to the settings UserRequests and Approvals components as it uses the same shared component */
    handleSimplePaging: (action: SimplePaginationActionType) => {
      if (!!action) {
        setIsLoadingHistory(true);
        const new_page = action === SimplePaginationAction.next ? page + 1 : page - 1;
        const offset = new_page * GlobalConstants.REQUESTS_PER_PAGE_COUNT;
        setPage(new_page);
        refresh({
          history: true,
          status: filter_status,
          hideExpired: false,
          limit: GlobalConstants.REQUESTS_PER_PAGE_COUNT,
          offset,
        });
      }
    },
    /* Description: This is called when updating the filter status to approved or denied. It sets the new filter status and
    refreshes the requests again. */
    handleUpdateFilterStatus: (params: any) => {
      setFilterStatus(params);
      setIsLoadingHistory(true);
      dispatch(adminActions.resetPagination());
      refresh({
        history: true,
        status: params,
        hideExpired: false,
        limit: GlobalConstants.REQUESTS_PER_PAGE_COUNT,
      });
    },
    handleGetRequestResponses: async (params: string) => {
      setIsDetailsLoading(true);
      await getRequestResponses(params);
    },
    /* Description: This is the handler for refresh or on load. It checks which tab the user is on and calls refresh (from the get org info hook)
    accordingly. NOTE: for the history tab org_users and approval_groups is sent so that those two backend calls are not called again
    unecessarily. */
    handleRefresh: (tab: string) => {
      if (tab === RequestTab.pending) {
        setIsLoading(true);
        refresh({ history: false, status: "pending", hideExpired: true });
      } else {
        setIsLoadingHistory(true);
        const history = !!org_users && !!approval_groups ? { orgUsers: org_users, approvalGroups: approval_groups } : undefined;
        refresh(
          {
            history: true,
            status: filter_status,
            hideExpired: false,
            limit: GlobalConstants.REQUESTS_PER_PAGE_COUNT,
            offset: pagination.pageIndex * GlobalConstants.REQUESTS_PER_PAGE_COUNT,
          }, history
        );
      }
    },
    /* Description: This is the handler for the delete functionality, it deletes one or multiple requests 
    through the backend. */
    handleDelete: async (params: {
      approvals: UserRequest[];
      setCheckedList: React.Dispatch<React.SetStateAction<UserRequest[]>>;
    }) => {
      const total = params.approvals.length;
      let errors: number = 0;

      const entityId = org_info.org_id;
      const delete_responses = await Promise.all(
        params.approvals.map(async (approval) => {
          const account_ids = Account.getAccountIdentifiers(current_account);
          const params = {
            account_ids,
            body: {
              entityId,
              requestId: approval.request_id,
            },
          };
          return await deleteAdminRequest(params)
            .unwrap()
            .then((response: DeleteUsersOrgsByEntityIdRequestsAndRequestIdApiResponse) => {
              if (!!response.errors) {
                errors++;
              }
              return !!response.errors ? null : approval;
            });
        }),
      );
      const successes = delete_responses.flatMap((response) => (!!response ? [response] : []));
      const success_ids = successes.map((s) => s.request_id);
      if (successes.length > 0) {
        setRequests(requests.filter((approval) => !successes.find((response) => response.uid === approval.uid)));
      }
      params.setCheckedList(params.approvals.filter((r) => !success_ids.includes(r.request_id)));
      if (total === 1) {
        if (successes.length === 1) {
          dispatch(
            uiActions.handleSetMessage(new Message(SettingsSuccessMessages.request_deleted)),
          );
        } else {
          dispatch(
            uiActions.handleRequestErrors(
              new Message(SettingsErrorMessages.error_deleting_request),
            ),
          );
        }
        return;
      }
      if (successes.length === 0) {
        dispatch(uiActions.handleRequestErrors(new Message(`Error deleting ${total} requests`)));
      } else if (errors > 0) {
        dispatch(
          uiActions.handleRequestErrors(
            new Message(`Error deleting ${errors} of ${total} requests`),
          ),
        );
      } else {
        dispatch(
          uiActions.handleSetMessage(
            new Message(`Successfully deleted ${successes.length} requests`),
          ),
        );
      }
    },
    handlePageErrorMessage: (params: {
      message: string;
      stack?: any;
      display_type?: MessageDisplayType;
    }) => {
      const display_type = !!params.display_type
        ? params.display_type
        : MessageHandlerDisplayType.logger;
      dispatch(
        uiActions.handleRequestErrors(new Message(params.message, display_type), params.stack),
      );
    },
  };

  function handlePageActions(actionObj: IActionHandler) {
    dispatch(uiActions.handleMessageDismiss());
    const callback = `handle${actionObj.actionType}`;
    // Handle local calls:
    if ((AdminApprovalsCalls as any)[callback] instanceof Function) {
      (AdminApprovalsCalls as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(
        MessageAnchors.actionType,
        actionObj.actionType,
      );
      AdminApprovalsCalls.handlePageErrorMessage({ message, stack: actionObj });
    }
  }

  return (
    <DefaultTemplate>
      <ApprovalRequestsParent
        handleAction={handlePageActions}
        is_approval={false}
        approval_requests={requests}
        history_requests={history_requests}
        current_sort={sort}
        loading={is_loading || is_loading_history}
        can_select={true}
        pagination={pagination}
        is_details_loading={is_details_loading}
        get_responses={true}
        current_recovery_group={!!approval_groups ? approval_groups.find((ag) => ag.roles.includes("admin_approval_group")) : undefined}
      ></ApprovalRequestsParent>
    </DefaultTemplate>
  );
}

export default React.memo(AdminApprovalRequestsComponent);
