import Dexie from "dexie";
import { SearchNode, SearchBase, SearchPagination } from "./search-node.class";
const SCHEMA_VERSION = 1;

export class SearchDatabase extends Dexie {
    nodes!: Dexie.Table<SearchNode, string[]>;
    search!: Dexie.Table<SearchBase, string[]>;
    pagination!: Dexie.Table<SearchPagination, string[]>; // TO DO: Handle custom pagination of complex trees
    constructor(db_name: string) {
        super(db_name);
        this.version(SCHEMA_VERSION).stores({
            nodes: "[collection_id+id], id, collection_id, parent_id, *name_tokens, name, type, last_modification_date",
            search: "[collection_id], collection_id, id, sequence, cursor_sequence, cursor_sub_sequence, has_more",
            pagination: "[collection_id], collection_id, id, page"
        });
        this.nodes.mapToClass(SearchNode);
    }

}
