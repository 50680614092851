import { ErrorStackType } from "@preveil-api";

// Description: begin adding some typing into the stack so errors can be handled
export class ErrorStackItem implements ErrorStackType {
  constructor(
    public source: string,
    public message: string,
    public status: string,
    public data?: unknown,
  ) { }

  // Description: Return MessageItem type
  get info(): ErrorStackType {
    return {
      message: this.message,
      source: this.source,
      status: this.status,
      data: this.data,
    };
  }
}