import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { DriveUIActionTypes } from "src/common";
import { Icon } from "src/components";

type AllProps = {
  is_loading: boolean;
  total: number;
  handleAction: ActionHandlerFunction;
};

function ToolbarComponent(props: AllProps) {
  const { is_loading, handleAction, total } = props;

  return (
    <Row className="toolbar">
      <Col xs="auto" className="order-sm-2 order-3 ms-auto">
      </Col>
      <Col xs="auto" className="order-sm-3 order-4 me-2 pagination_info">
        {total === 1 ? `${total} Invitation` : total > 1 ? `${total} Invitations` : ""}
      </Col>
      <Col className="divider order-sm-3 order-1"></Col>
      <Col xs="auto" className="p-0 order-sm-3 order-4 ms-1 refresh">
        <Button
          variant="icon"
          size="sm"
          onClick={() => handleAction({ actionType: DriveUIActionTypes.Refresh })}
          title="Refresh Invitations"
        >
          {is_loading ? <i className="spinner"></i> : <Icon className="ficon-rotate-cw" />}
        </Button>
      </Col>
    </Row>
  );
}

export default React.memo(ToolbarComponent);
