import * as React from "react";
import { Card } from "react-bootstrap";
import { Icon } from "..";
import SettingsImage from "src/assets/images/device-lock.svg";

function UpdateRequiredComponent(props: any) {
  return <div className="mx-auto max-600 mt-5">
    <h1 className="main-header">You need to download a newer version of PreVeil</h1>
    <Card className="pv-card mb-2">
      <Card.Img variant="top" src={SettingsImage} />
      <Card.Body>
        <p className="semi-bold fs-3">For your security, you can't access your PreVeil account from this computer</p>
        <p>Please download <a href="https://www.preveil.com/download/" target="_blank" rel="noreferrer">latest version</a> and install.</p>
      </Card.Body>
    </Card>
    <p>
      <Icon className="pv-icon-question" />
      <span>
        Learn more about PreVeil <a target="_blank" rel="noreferrer" href="https://preveil.com/support">preveil.com/support</a>
      </span>
    </p>
  </div>;
}

export default React.memo(UpdateRequiredComponent);
