import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { ActionHandlerFunction, Invite, InviteNotifications } from "@preveil-api";
import { Account, useGetUsersInviteQuery, MailUIActionTypes, AccountErrorMessages, Helpers } from "src/common";
import { InviteMetadata } from "src/common/keys/protos/invite_pb";
import _ from "lodash";

type AllProps = {
  current_account: Account;
  handleAction: ActionHandlerFunction;
}

function InviteNotificationsComponent(props: AllProps) {
  const { current_account, handleAction } = props;
  const [show, setShow] = useState(true);
  const [notifications, setNotifications] = useState<InviteNotifications[]>();
  const { data, error } = useGetUsersInviteQuery({
    account_ids: Account.getAccountIdentifiers(current_account),
    body: { invitee: current_account?.user_id }
  }, { skip: !current_account });

  useEffect(() => {
    !!data && _parseInviteNotifications(data.invites);
    !!error && handleAction({
      actionType: MailUIActionTypes.PageErrorMessage, params: {
        message: AccountErrorMessages.error_fetching_user_notifications,
        stack: error
      }
    });
  }, [error, data]);

  // Description: Process Array of invites:
  function _parseInviteNotifications(invite_notifications: Invite[]): void {
    // NOTE: Group invites by inviter type (MAIL and SHARE)
    const notes = _.groupBy(invite_notifications, "inviter");
    const _notifications: InviteNotifications[] = [];
    Object.keys(notes).map((inviter: string) => {
      const share_type = notes[inviter].slice();
      const mail_type = _.remove(share_type, (invite: Invite) => {
        return _getInviteType(invite) === InviteMetadata.InviteType.MAIL;
      });

      if (share_type.length > 0) {
        _notifications.push({
          inviter,
          type: InviteMetadata.InviteType.SHARE,
          count: share_type.length,
          message: share_type.length > 1 ? "invitations to shared folders" : "invitation to a shared folder"
        });
      }

      if (mail_type.length > 0) {
        _notifications.push({
          inviter,
          type: InviteMetadata.InviteType.MAIL,
          count: mail_type.length,
          message: mail_type.length > 1 ? "emails" : "email"
        });
      }
      return _notifications;
    });
    _notifications.length > 0 && setNotifications(_notifications);
  }
  // Description: get Invite Type from Metadata
  function _getInviteType(invite: Invite): InviteMetadata.InviteTypeMap[keyof InviteMetadata.InviteTypeMap] | undefined {
    return InviteMetadata.deserializeBinary(Helpers.b64Decode(invite.metadata)).getInviteType();
  }

  return <>
    {
      (!!notifications && show) &&
      <Alert variant="primary" dismissible onClose={() => setShow(false)} >
        <Alert.Heading className="bold">You have pending messages!</Alert.Heading>
        {
          _.map(notifications, (notification: InviteNotifications, i: number) => {
            return <p key={`note${i}`}>
              You will receive <b>{notification.count}</b> {notification.message} from <b>{notification.inviter}</b> next time they are online
            </p>;
          })
        }
      </Alert>
    }
  </>;
}

export default React.memo(InviteNotificationsComponent);