import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { DeviceBase } from "@preveil-api";
import { GetUserDevicesApiResponse, Message, SettingsErrorMessages, useAppDispatch, useAppSelector, useGetUserDevicesMutation } from "src/common";
import { PageHeader } from "src/components";
import { uiActions } from "src/store";
import { DeviceListView, DeviceToolBar } from ".";

function DeviceManagementComponent() {
  const dispatch = useAppDispatch();
  const [devices, setDevices] = useState<DeviceBase[]>([]);
  const [getDevices, { isLoading }] = useGetUserDevicesMutation();
  const current_account = useAppSelector((state) => state.account.current_account);

  /* Description: On initial load: Call Get User Devices */
  useEffect(() => {
    getUserDevices();
  }, []);

  /* Description: Gets the users devices from the backend and sets it in the store, also handles errors. */
  async function getUserDevices() {
    if (!!current_account) {
      await getDevices({ userId: current_account.user_id })
        .unwrap()
        .then((response: GetUserDevicesApiResponse) => {
          setDevices(response);
        })
        .catch((msg) => {
          dispatch(
            uiActions.handleRequestErrors(
              new Message(SettingsErrorMessages.error_fetching_devices),
              msg,
            ),
          );
        });
    }
  }

  return (
    <>
      <PageHeader>
        <h1>Device Management</h1>
      </PageHeader>
      <Card className="card-section h-100">
        <DeviceToolBar
          getUserDevices={getUserDevices}
          devices={devices}
          loading={isLoading}
        ></DeviceToolBar>
        {!!current_account && (
          <DeviceListView
            loading={isLoading}
            current_account={current_account}
            devices={devices}
            setDevices={setDevices}
          ></DeviceListView>
        )}
      </Card>
    </>
  );
}

export default React.memo(DeviceManagementComponent);
