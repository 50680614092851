import React, { useState, FocusEvent } from "react";
import { Card, Button, Col, Form } from "react-bootstrap";
import { ActionHandlerFunction, } from "@preveil-api";
import {
  AccountUIActionTypes, RegexHelper, SettingsErrorMessages, usePostUsersFindMutation, Account, useAppSelector,
  ValidateUserExists, isSameUser, MessageHandlerDisplayType
} from "src/common";
import { Icon, Loading } from "src/components";

type AllProps = {
  handleAction: ActionHandlerFunction;
}

function AwaitConnectionComponent(props: AllProps) {
  const { handleAction } = props;
  const [validated, setValidated] = useState<boolean>(false);
  const [request_user_id, setRequestUserId] = useState<string>("");
  const current_account = useAppSelector((state) => state.account.current_account);
  const user_id = current_account?.user_id;
  const [findUsers, { isLoading }] = usePostUsersFindMutation();

  // Description: Submit User ID form
  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    e.stopPropagation();
     // TODO - to Fix: Not getting currentTarget property from the Event when in testing environment
    // currentTarget returns undefined.
    // https://github.com/testing-library/dom-testing-library/issues/73
    const _user_id = (e.currentTarget?.request_user_id?.value || request_user_id).trim().toLowerCase();
    // Validate email in form that is not self
    if (!!current_account && RegexHelper.testEmailAddress(_user_id) && !isSameUser(user_id, _user_id)) {
      // Description: Server side validation that user exists
      findUsers({
        account_ids: Account.getAccountIdentifiers(current_account),
        body: { spec: [{ user_id: _user_id, key_version: -1 }] },
      }).unwrap()
        .then(({ users, errors }) => {
          // Description: Validates user Id for existing PV account
          if (ValidateUserExists(users, _user_id)) {
            handleAction({ actionType: AccountUIActionTypes.InitWebsocketConnection, params: _user_id });
            setValidated(true);
          } else {
            handlePageErrors(SettingsErrorMessages.not_valid_user, errors);
          };
        })
        .catch((msg: unknown) => {
          handlePageErrors(SettingsErrorMessages.default, msg);
        });
    } else {
      setRequestUserId("");
      setValidated(false);
      handlePageErrors(SettingsErrorMessages.missing_requested_user_id);
    };
  };

  // Description: Handle all errors in components and pass up to parent
  function handlePageErrors(message: string, stack?: any): void {
    handleAction({
      actionType: AccountUIActionTypes.PageErrorMessage,
      params: { message, stack, displayType: MessageHandlerDisplayType.toastr }
    });
  }

  // Description: Reset form and handle reset action to parent *** TO DO
  function handleCancel() {
    setRequestUserId("");
    setValidated(false);
    handleAction({ actionType: AccountUIActionTypes.Destroy });
  };

  return !isLoading ? <Card.Body>
    <h3 className="content-header">
      <i className="waiting"></i>Awaiting Connection&hellip;
    </h3>
    <div className="max-700">
      <p className="mb-4 fs-3">
        Enter the PreVeil ID (email address) of the user whose private key you are helping to
        recover.
      </p>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group as={Col} xs="8" controlId="validationConnecting" className="mb-3">
          <Form.Label visuallyHidden>PreVeil ID to Recover</Form.Label>
          <div className="input-icon-prepend">
            <Icon className="ficon-user" />
            <Form.Control
              required
              name="request_user_id"
              placeholder="Enter the PreVeil ID (e-mail) to Recover"
              autoComplete="off"
              autoFocus={true}
              value={request_user_id}
              onChange={(e: FocusEvent<HTMLInputElement>) => setRequestUserId(e.currentTarget.value)} />
          </div>
        </Form.Group>
        <div className="btn-panel my-3">
          <Button type="submit" disabled={!request_user_id || !RegexHelper.testEmailAddress(request_user_id)} >Continue</Button>
          <Button variant="outline-primary" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  </Card.Body> : <Loading className="in-place" />;
}
export default React.memo(AwaitConnectionComponent);
