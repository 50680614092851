import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { ActionHandlerFunction, PaginationItem, Sort } from "@preveil-api";
import {
  ApprovalGroup, Message, MessageHandlerDisplayType, MessageToastTypes, UserRequest, RecoveryGroup, SettingsUIActionTypes, useAppDispatch,
  SettingsMessages,
} from "src/common";
import { ApprovalRequestSidePanel, ApprovalRequestsListView, ApprovalRequestsToolbar } from "src/components";
import { uiActions } from "src/store";

type AllProps = {
  handleAction: ActionHandlerFunction;
  is_approval: boolean;
  requests: UserRequest[];
  tab: string;
  current_sort: Sort<keyof UserRequest>;
  loading: boolean;
  can_select: boolean;
  pagination?: PaginationItem;
  is_details_loading: boolean;
  get_responses: boolean;
  current_recovery_group?: RecoveryGroup | ApprovalGroup;
};

function ApprovalRequestsTabContentComponent(props: AllProps) {
  const {
    handleAction,
    is_approval,
    requests,
    tab,
    current_sort,
    loading,
    can_select,
    pagination,
    is_details_loading,
    get_responses,
    current_recovery_group
  } = props;
  const { request_id } = useParams();
  const navigate = useNavigate();
  const [show, setShow] = useState<boolean>(false);
  const [checked_list, setCheckedList] = useState<UserRequest[]>([]);
  const [_request_id, setRequestId] = useState<string | undefined>(request_id);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!!_request_id && !loading) {
      const request = requests.find((r) => r.request_id === _request_id);
      if (!!request) {
        setCheckedList([request]);
        setShow(true);
      } else {
        // NOTE: Pass a warning to the user and remove request_id
        dispatch(uiActions.handleSetMessage(new Message(SettingsMessages.approval_not_found, MessageHandlerDisplayType.toastr,
          MessageToastTypes.warning)));
      }
      setRequestId(undefined);
      const path = window.location.pathname;
      const new_path = path.slice(0, path.lastIndexOf("/"));
      navigate(new_path);
    }
  }, [requests]);

  /* Description: Deletes a user request and closes the side panel. */
  function handleDeleteRequests() {
    handleAction({ actionType: SettingsUIActionTypes.Delete, params: { approvals: checked_list, setCheckedList } });
    setShow(false);
  }

  /* Description: Confirmation Modal for deleting a request(s). */
  function confirmDelete() {
    const confirmation_dialog = new Message(
      SettingsMessages.confirm_delete,
      MessageHandlerDisplayType.confirm,
      MessageToastTypes.primary,
      "Delete request(s)?",
      {
        label: "Yes",
        data: true,
        action: handleDeleteRequests,
      },
      { label: "No" },
    );
    dispatch(uiActions.handleSetMessage(confirmation_dialog));
  }

  return (
    <>
      <ApprovalRequestsToolbar
        tab={tab}
        handleAction={handleAction}
        confirmDelete={confirmDelete}
        checked_list={checked_list}
        setCheckedList={setCheckedList}
        approval_requests={requests}
        setShow={setShow}
        is_approval={is_approval}
        pagination={!!pagination ? pagination : undefined}
        is_loading={loading}
      />
      <div id="outer-scope" className="cover-content overflow-hidden">
        <ApprovalRequestsListView
          show={show}
          setShow={setShow}
          checked_list={checked_list}
          setCheckedList={setCheckedList}
          approval_requests={requests}
          can_select={can_select}
          current_sort={current_sort}
          loading={loading}
          handleAction={handleAction}
          tab={tab}
        ></ApprovalRequestsListView>
      </div>
      <Offcanvas
        show={show}
        onHide={() => {
          setShow(false);
          setCheckedList([]);
        }}
        className="sidebar narrower-panel"
        placement="end"
        backdrop={false}
      >
        {show && (
          <ApprovalRequestSidePanel
            handleAction={handleAction}
            is_approval={is_approval}
            request={checked_list[0]}
            setShow={setShow}
            current_recovery_group={current_recovery_group}
            confirmDelete={confirmDelete}
            setCheckedList={setCheckedList}
            is_details_loading={is_details_loading}
            get_responses={get_responses}
          ></ApprovalRequestSidePanel>
        )}
      </Offcanvas>
    </>
  );
}

export default React.memo(ApprovalRequestsTabContentComponent);

