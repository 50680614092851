import React from "react";
import { AdminRow } from "src/components";
import { Col, Row } from "react-bootstrap";

type GroupMember = {
  name: string | null;
  address: string;
  role?: string | undefined;
};

type AllProps = {
  members: GroupMember[];
}

function RecoveryGroupMembersCard({ members }: AllProps) {
  return (
    <div className="groups-card">
      <header>
        <Row className="header-card-row">
          <Col xs={7}>
            <p className="m-0">User</p>
          </Col>
          <Col xs={5} className="ps-2">
            <p className="m-0">Role</p>
          </Col>
        </Row>
      </header>
      {members.map((member: GroupMember) => {
        const { name, address, role} = member;
        const user = {
          display_name: name,
          user_id: address,
          department: role // TODO/NOTE: Role is not department, just using it like this for now, because reusable component (GroupMembersRow) is expecting a department string.
        };
        return <AdminRow key={address} member={user} />;
      })}
    </div>
  );
}

export default React.memo(RecoveryGroupMembersCard);