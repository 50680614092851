import { ACLInfo, FSRoleType, isSameUser, GranteeInfo, ACLRoleType, Grantee, AppUserKey } from "src/common";
import { Node, FSRole, PublicUserKey } from "src/common/keys/protos/collections_pb";
import { USER_KEY_PROTOCOLS } from "src/common/keys/protocols";
import _ from "lodash";

// Description: Manage Node.Grants and Grantee[] for the UI
export class ACL implements ACLInfo {
  acl_reader: boolean = false;
  acl_writer: boolean = false;
  expiration: string = "";
  grantees: Grantee[] | null = null;  // NOTES: this is null when the call is not to Link
  reader: boolean = false;
  viewer: boolean = false;
  writer: boolean = false;
  // shareable = grant.role === Node.ACLRole.ACL_WRITER; // NOT NEEDED **** Should remove from Filesync call
  constructor(
    grants: Node.Grant[],
    public node_id: string,
    public user_id: string
  ) {
    this.grantees = this._buildGrantees(grants, user_id);
  }

  // Description: Return the ACLInfo for directory access
  get info(): ACLInfo {
    return {
      acl_reader: this.acl_reader,
      acl_writer: this.acl_writer,
      expiration: this.expiration,
      grantees: this.grantees,
      node_id: this.node_id,
      reader: this.reader,
      viewer: this.viewer,
      writer: this.writer
    };
  }

  // Description: Get ACL_Info Grantee[]
  private _buildGrantees(grants: Node.Grant[], current_user_id: string): Grantee[] {
    // NOTE: Iterate through all grants get grantees and current users roles
    const _grantees: GranteeInfo[] = [];
    _.forEach(grants, (grant: Node.Grant) => {
      const acl_role = grant.getRole() as ACLRoleType;
      const grantee_list = grant.getGranteesList();

      // NOTE: Examine current users grants and set roles in instance
      const current_grants = _.find(grantee_list, (grantee: Node.Grantee) => (isSameUser(grantee.getUserId(), current_user_id)));
      !!current_grants && this._setCurrentGrantRole(current_grants, acl_role);

      // NOTE: Build all grantees info
      _.forEach(grantee_list, (grantee: Node.Grantee) => {
        const user_id = grantee.getUserId() || "";
        const expiration = grantee.getExpirationTime() || "";
        const view_only = grantee.getViewOnly() || false;
        if (!acl_role !== undefined) {
          _grantees.push({
            user_id,
            expiration,
            role: this._convertToFSRole(acl_role),
            acl: acl_role,
            view_only,
            //  NOTE: https://preveil.atlassian.net/browse/BACK-916
            pending: false, // grantee.getPending() // Will come in the next BE FIX ****************** WORKING **************** 
            is_owner: false, // grantee.getIsOwner() // Will come in the next BE FIX ****************** WORKING ****************
          });
        }
      });
    });
    return this._getActiveGranteesInfo(_grantees);
  }

  // Description: Set Current users Roles
  private _setCurrentGrantRole(current_grantee: Node.Grantee, role: ACLRoleType): void {
    const view_only = current_grantee.getViewOnly() || false;
    this.viewer = view_only;
    switch (role) {
      case Node.ACLRole.READER:
        this.reader = true;
        break;
      case Node.ACLRole.WRITER:
        this.writer = true;
        break;
      case Node.ACLRole.ACL_READER:
        this.acl_reader = true;
        break;
      case Node.ACLRole.ACL_WRITER:
        this.acl_writer = true;
        break;
    }
  }

  // Description: get grantee information
  private _getActiveGranteesInfo(_grantees: GranteeInfo[]): Grantee[] {
    const user_grants = _.groupBy(_grantees, g => g.user_id);
    const grantees: Grantee[] = [];
    _.forOwn(user_grants, (value, user_id) => {
      let expiration = "";
      let view_only = false;
      let pending = false;
      let is_owner = false;
      const acl: ACLRoleType[] = [];
      const roles: FSRoleType[] = [];
      _.forEach(value, (info: GranteeInfo) => {
        if (!!info.expiration && info.expiration.length > 0) {
          expiration = info.expiration;
        }
        if (info.view_only) {
          view_only = info.view_only;
        }
        if (info.pending) {
          pending = info.pending;
        }
        if (info.is_owner) {
          is_owner = info.is_owner;
        }
        acl.push(info.acl);
        roles.push(info.role);
      });

      grantees.push({
        user_id,
        acl,
        roles,
        expiration,
        view_only,
        pending,
        is_owner
      });
    });

    return grantees;
  }

  // Description: convert ACL roles to FSRoles for grantees
  private _convertToFSRole(role: ACLRoleType): FSRoleType {
    switch (role) {
      case Node.ACLRole.READER:
        return FSRole.READER;
      case Node.ACLRole.WRITER:
        return FSRole.WRITER;
      case Node.ACLRole.ACL_READER:
        return FSRole.ACL_VIEWER;
      case Node.ACLRole.ACL_WRITER:
        return FSRole.SHARER;
      default:
        return FSRole.VIEWER;
    }
  }


  // Description: Build node directory permissions - sharing and updating collection permissions
  static async buildPermission(role: ACLRoleType, user_key: AppUserKey, wrapped_key: Uint8Array): Promise<Node.Permission> {
    const new_permissions = new Node.Permission();
    new_permissions.setRole(role);
    const public_user_key = new PublicUserKey();
    public_user_key.setPublicKey(user_key.public_user_key.public_key.public_buffer);
    public_user_key.setVerifyKey(user_key.public_user_key.verify_key.public_buffer);
    public_user_key.setKeyVersion(user_key.key_version);
    public_user_key.setProtocolVersion(USER_KEY_PROTOCOLS.Current);
    new_permissions.setKey(public_user_key);
    new_permissions.setWrappedLastKey(wrapped_key);
    return new_permissions;
  }
};
