import { ActionHandlerFunction } from "@preveil-api";
import React from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { DriveSharedInfo, DriveUIActionTypes } from "src/common";
import { Icon } from "src/components";

type AllProps = {
  is_loading: boolean;
  handleAction: ActionHandlerFunction;
  shared_folders: DriveSharedInfo[];
};

function SharedToolbarComponent(props: AllProps) {
  const { is_loading, shared_folders, handleAction } = props;

  function handleOptionSelect(e: React.ChangeEvent<HTMLSelectElement>) {
    const value = e.target.value;
    handleAction({ actionType: DriveUIActionTypes.SetFilter, params: { value } });
  }

  return (
    <Row className="toolbar">
      <Col xs="auto" className="order-0">
        <Form.Select size="sm" onChange={handleOptionSelect}>
          <option value={"all"}>
            All
          </option>
          <option value={"hidden"}>
            Hidden
          </option>
          <option value={"shown"}>
            In My PreVeil
          </option>
        </Form.Select>
      </Col>
      <Col xs="auto" className="order-sm-2 order-3 ms-auto">
      </Col>
      <Col xs="auto" className="order-sm-3 order-4 me-2 pagination_info">
        {shared_folders.length} Items
      </Col>
      <Col className="divider order-sm-3 order-1"></Col>
      <Col xs="auto" className="p-0 order-sm-3 order-4 ms-1 refresh">
        <Button
          variant="icon"
          size="sm"
          onClick={() => handleAction({ actionType: DriveUIActionTypes.Refresh })}
          title="Refresh Drive Shared List"
        >
          {is_loading ? <i className="spinner"></i> : <Icon className="ficon-rotate-cw" />}
        </Button>
      </Col>
    </Row>
  );
}

export default React.memo(SharedToolbarComponent);
