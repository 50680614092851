// @ts-nocheck
// @ts-ignore
// tslint:disable
import { EncryptionKey, EncryptionStyle, PublicKey } from "./encryption";
import { NaclCrypto, PVCryptoUsage } from "pvcryptojs";

export const text_header = new Uint8Array([144, 0, 0, 0]);
export const binary_header = new Uint8Array([16, 0, 0, 0]);

export class EncryptionKeyV0 extends EncryptionKey {
  static protocol_version = 0;

  constructor(public_key: Uint8Array, public private_key?: Uint8Array, private style?: EncryptionStyle) {
    super(EncryptionKeyV0.protocol_version, public_key, private_key);
  }

  static async new(sk?: Uint8Array, style?: EncryptionStyle): Promise<EncryptionKeyV0> {
    if (sk && sk.length === 0) {
      throw Error("empty key");
    }

    const [_sk, _pk] = await NaclCrypto.generateAsymmKey(PVCryptoUsage.ENCRYPT, sk);
    return new EncryptionKeyV0(_pk, _sk, style);
  }


  public serialize() {
    return new Uint8Array(this.private_key);
  }

  public clonePublic(): PublicKey {
    return new EncryptionKeyV0(this.public_key, undefined, this.style);
  }

  public async unseal(cipher: Uint8Array): Promise<Uint8Array> {
    if (!this.private_key) {
      throw Error("Invalid usage");
    }
    let decrypted = NaclCrypto.hybridDecrypt(this.private_key, cipher);
    if (this.style === EncryptionStyle.EMAIL) {
      decrypted = decrypted.then(p => !p ? p : p.slice(text_header.length));
    }
    return decrypted;
  }

  public async seal(plaintext: Uint8Array, is_text: boolean = false): Promise<Uint8Array> {
    if (this.style === EncryptionStyle.EMAIL) {
      plaintext = is_text ?
        new Uint8Array([...text_header, ...plaintext]) :
        new Uint8Array([...binary_header, ...plaintext]);
    }
    return NaclCrypto.hybridEncrypt(this.public_key, plaintext);
  }
}
