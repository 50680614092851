import { UserProfile } from "@preveil-api";
import { Entity } from "src/common";


export class ApprovalGroup extends Entity {
  constructor(
    public approval_group_id: string,
    public name: string,
    public approvers: UserProfile[],
    public required_approvers: number,
    public group_version: string,
    public roles: string[],
    public is_deleted: boolean = false
  ) {
    super (approval_group_id);
  }

  public addRole(role: string): void {
    if (this.roles.includes(role)) {
      return;
    }
    this.roles = this.roles.concat(role);
  }
}

