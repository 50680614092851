import * as React from "react";
import { Routes, Route, Navigate, Params } from "react-router-dom";
import { NavigationPath } from "src/common";
import { Wrapper, MailWrapper } from "src/components";

function MailPage() {
  return <Wrapper type={NavigationPath.MailNavigation}>
    <MailPagesRoutes />
  </Wrapper>;
}

export function MailPagesRoutes(props: Readonly<Params<string>>) {
  return <Routes>
    <Route path=":mailbox/:page" element={<MailWrapper />} />
    <Route path=":mailbox/:page/:thread_id" element={<MailWrapper />} />
    <Route path=":mailbox" element={<Navigate to="1" replace />} />
    <Route path="/" element={<Navigate to="inbox/1" replace />} />
  </Routes>;
}

export default React.memo(MailPage);
