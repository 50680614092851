/* eslint-disable */
/* tslint:disable */
/*
*   File:           uuid.ts
*   Description:    Utility functions for generating uuids
*   Author:         PreVeil, LLC
*/
import { Helpers } from "src/common";
import { v4 as _UUID, stringify } from "uuid";

export class UUID {
  private _uuid;
  private _b64;
  private _bytes: Uint8Array;
  constructor(options: { uuid?: string; bytes?: Uint8Array; b64?: string } = {}) {
    const { uuid, bytes, b64 } = options;
    this._bytes = new Uint8Array(16);
    const getBytes = (uuid: string) => {
      const bytes: number[] = [];
      uuid.replace(/[a-fA-F0-9]{2}/g, (hex: string) => {
        return (bytes.push(parseInt(hex, 16))).toString();
      });
      return bytes;
    };

    if (uuid) {
      this._bytes = new Uint8Array(getBytes(uuid));
    } else if (bytes) {
      this._bytes = new Uint8Array(bytes);
    } else if (b64) {
      _UUID({ random: Helpers.b64Decode(b64) }, this._bytes, 0);
    } else {
      _UUID(null, this._bytes, 0);
    }
    this._uuid = this._convertBytestoString(this._bytes);
    this._b64 = Helpers.b64Encode(this._bytes);
  }

  // Description: Convert Uint8Array to an uuid String
  //  Current Validation rules for new stringify method
  // if (arr.length - offset < 16) throw('Not enough values in array');
  // if ((arr[offset + 6] >>> 4) & 0x07 > 5) throw ('Invalid version');
  // if (arr[offset+8] & 0xc0 !== 0x80) throw ('Invalid variant');
  private _convertBytestoString(arr: Uint8Array): string {
    try {
      return stringify(arr, 0);
    } catch (m) {
      // NOTE: Will use older library code for back compliance
      // Returns string "ERROR" if it fails
      return bytesToUuid(arr);
    }
  }

  String(): string {
    return this._uuid;
  }

  Bytes(): Uint8Array {
    return new Uint8Array(this._bytes);
  }

  B64(): string {
    return this._b64;
  }

  B64WebSafe(): string {
    return Helpers.b64Encode(this._bytes, true);
  }
}


/**
 * Convert array of 16 byte values to UUID string format of the form:
 * - Deprecated in current UUID library 
 * Brought this library over for back compliance
 */
function bytesToUuid(buf: Uint8Array, offset: number = 0) {
  const byteToHex: string[] = [];
  for (let i = 0; i < 256; ++i) {
    byteToHex[i] = (i + 0x100).toString(16).substring(1);
  }

  function _bytesToUuid() {
    let i = offset;
    let bth: string[] = byteToHex;
    // join used to fix memory issue caused by concatenation: https://bugs.chromium.org/p/v8/issues/detail?id=3175#c4
    return ([
      bth[buf[i++]], bth[buf[i++]],
      bth[buf[i++]], bth[buf[i++]], '-',
      bth[buf[i++]], bth[buf[i++]], '-',
      bth[buf[i++]], bth[buf[i++]], '-',
      bth[buf[i++]], bth[buf[i++]], '-',
      bth[buf[i++]], bth[buf[i++]],
      bth[buf[i++]], bth[buf[i++]],
      bth[buf[i++]], bth[buf[i++]]
    ]).join('');
  }
  try {
    return _bytesToUuid();
  } catch (m) {
    return "ERROR";
  }
}