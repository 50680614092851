import * as React from "react";
type AllProps = {
  message: string;
}

// Description: Custom form error message component 
function ErrorMessage(props: AllProps) {
  const { message } = props;
  return (
    <div className="form-text text-danger">{message}</div>
  );
}

export default React.memo(ErrorMessage);
