import React, { useState, useRef, useEffect } from "react";
import { Button, Modal, Tabs, Tab, Collapse } from "react-bootstrap";
import { ActionHandlerFunction, IActionHandler, IActionHandlerTyped, CollectionServerUser, UserListData, UserProfile, SelectedEmailGroup } from "@preveil-api";
import {
  Account, AdminUIActionTypes, UserListActionTypes, useCsvDataValidation, AdminErrorMessages, useAppDispatch, Message, MessageHandlerDisplayType,
  GlobalErrorMessages, MessageAnchors, account_types,
  usePostUsersFindMutation,
  isSameUser,
} from "src/common";
import { Icon, CsvSubmitData, DraggableContainer, AddressListModalPanel, UserList } from "src/components";
import { uiActions } from "src/store";
import { EmailGroupForm, EmailGroupMembersTable } from ".";
import { ParseError, ParseMeta } from "papaparse";
import _ from "lodash";

type AllProps = {
  handleAction: ActionHandlerFunction;
  current_account: Account;
  show_modal: boolean;
  selected_group?: SelectedEmailGroup;
  aliases: string[];
  org_users: CollectionServerUser[];
  is_update_mode?: boolean;
}

type PapaparseData = {
  data: {
    "User Address": string;
  }
}

function CreateGroupEmailModalComponent(props: AllProps) {
  const { show_modal, selected_group, org_users, current_account, is_update_mode, handleAction, aliases } = props;
  const dispatch = useAppDispatch();
  const tab_event_key = {
    enter_users: "Create Group Email",
    csv_upload: "Importing Users Group Emails",
  };
  const csv_column_name = "User Address";
  const valid_group_users_collection: string[] = [];
  const non_org_group_users_collection: string[] = [];
  const user_list_ref = useRef<{ handlePageActions: ActionHandlerFunction }>();
  const removed_users_list_ref = useRef<Map<string, string>>(new Map);
  const [findUsers] = usePostUsersFindMutation();

  // Local State
  const [group_alias, setGroupAlias] = useState<string>(!!selected_group ? selected_group.alias : "");
  const [tab_heading, setTabHeading] = useState<string>(tab_event_key.enter_users);
  const [group_account_users_data, setAccountUsersData] = useState<CollectionServerUser[]>([]);
  const [open_collapse, setopen_collapse] = useState<boolean>(false);
  const [is_valid, setIsValid] = useState<boolean>(false);
  const [invalid_alias, setInvalidAlias] = useState<boolean>(false);
  const [full_users, setFullUsers] = useState<CollectionServerUser[]>();
  const [error, setError] = useState<string>("");
  const { validAddresses, invalidAddresses, duplicateAddresses, isDataDrop, handleValidateData, resetData } = useCsvDataValidation(group_account_users_data.map((user) => user.user_id));

  useEffect(() => {
  }, [group_account_users_data]);

  useEffect(() => {
    !!selected_group && setGroupAlias(selected_group?.alias);
  }, [selected_group]);

  useEffect(() => {
    const _valid = group_alias.length > 0 && (!_.isEmpty(valid_group_users_collection) || group_account_users_data.length >= 1) && !invalid_alias;
    setIsValid(_valid);
  }, [group_alias, valid_group_users_collection, group_account_users_data, invalid_alias]);

  useEffect(() => {
    if (!!org_users && org_users.length > 0) {
      const _full_users = _.filter(org_users, ((_user: CollectionServerUser) =>
        _user.account_type !== account_types.nouser && !_user.deleted));
      setFullUsers(_full_users);
    }
  }, [org_users]);

  const CreateEmailGroupRequests = {
    handleCsvData: (csvFile: { data: PapaparseData[], errors: ParseError[], meta: ParseMeta }) => {
      handleCsvFileData(csvFile);
    },
    handleSubmitCsvData: () => {
      if (is_update_mode && !!selected_group) {
        // if Email Group already exists.
        const add = validAddresses; 
        const params = {
          add,
          remove: [],
          alias: group_alias
        };
        handleAction({ actionType: AdminUIActionTypes.Update, params });
      } else {
        // New Email group email creation
        const members = validAddresses;
        handleAction({
          actionType: AdminUIActionTypes.Create,
          params: { alias: group_alias, members },
        });
      }
      handleAction({ actionType: AdminUIActionTypes.ShowModal });
      setGroupAlias("");
      resetData();
    },
    handleReset: () => {
      setGroupAlias("");
      resetData();
    },
    handleShowModal: () => {
      setGroupAlias("");
      resetData();
      handleAction({ actionType: AdminUIActionTypes.ShowModal });
    },
    handlePageError: (params: { message: string, stack?: any }) => {
      const { message, stack } = params;
      dispatch(uiActions.handleRequestErrors(new Message(message, MessageHandlerDisplayType.logger), stack));
    }
  };

  function reset() {
    setGroupAlias("");
    if (!!user_list_ref.current) {
      user_list_ref.current.handlePageActions({
        actionType: UserListActionTypes.Reset
      });
    }
  }

  function handleTabSelect(eventKey: string | null) {
    const tab_heading = eventKey === "csv_upload" ? tab_event_key.csv_upload : tab_event_key.enter_users;
    setTabHeading(tab_heading);
  }

  // Description: Add users to the list on select
  function handleUpdateUsers(actionObj: IActionHandlerTyped<UserListData>) {
    const params = actionObj?.params;
    const { current_contacts } = params;
    const members: CollectionServerUser[] = [];
    _.forEach(current_contacts, (contact: UserProfile) => {
      const address = contact?.address;
      // Note: In some cases when the account was created in V1 using uppercase letters,
      // the user_id will be returned with the mixed-case/uppercase letters (full_users array)
      // causing issues with case sensitivity in V2 (ex: in this case user won't be found).
      // so this is why we are using isSameUser to compare the user_id.
      const user = full_users?.find((user) => isSameUser(user.user_id, address));
      if (!!user) {
        const user_details = {
          ...user,
          user_id: user.user_id.toLowerCase()
        };
        members.push(user_details);
        setError("");
      } else {
        setError(AdminErrorMessages.error_unable_add_user.replace(MessageAnchors.user_id, address));
      }
      if (removed_users_list_ref && removed_users_list_ref?.current.has(address)) {
        removed_users_list_ref.current.delete(address);
      }
    });
    setAccountUsersData(members);
  }

  async function handleRemoveUser(user: string[]) {
    if (!!user_list_ref.current) {
      if (is_update_mode) {
        handleAdminGroupUpdates(user[0], "remove");
      }
      const org_user = org_users.find((org_user) => isSameUser(org_user.user_id, user[0]));
      if (org_user) {
        const user_account = await Account.initAccount(Account.parseCollectionServerUser(org_user));
        const user_profile = Account.getAccountProfile(user_account);
        user_list_ref.current.handlePageActions({
          actionType: UserListActionTypes.RemoveProfile,
          params: user_profile,
        });
      }
    }
  }

  function handleAdminGroupUpdates(key: string, action: "add" | "remove") {
    if (action === "remove") {
      removed_users_list_ref.current.set(key, key);
    } else if (action === "add" && removed_users_list_ref.current?.has(key)) {
      removed_users_list_ref.current.delete(key);
    }
  }

  function handleSubmitGroup() {
    if (is_update_mode && !!selected_group) {
      const member_ids = selected_group.members.map((member) => member.user_id);
      const removed_addresses = member_ids.filter((member) => removed_users_list_ref.current.has(member.toLowerCase()));
      const added_addresses = group_account_users_data.filter((userAccount) => !member_ids.includes(userAccount.user_id)).map((added_accounts) => added_accounts.user_id);
      const add = added_addresses.length >= 1 ? added_addresses : [];
      const remove = removed_addresses.length >= 1 ? removed_addresses : [];
      const params = {
        add,
        remove,
        alias: group_alias
      };
      setGroupAlias("");
      handleAction({ actionType: AdminUIActionTypes.Update, params });
    } else {
      validateGroupEmail();
    }
  }

  // Description: Before creating the group email, this function validates that the alias provided 
  // by the user is not an actual preveil users id or is not an already existing group email
  async function validateGroupEmail() {
    return await findUsers({
      account_ids: Account.getAccountIdentifiers(current_account),
      body: { spec: [{ user_id: group_alias, key_version: -1 }] }
    })
      .unwrap()
      .then(({ users, groups }) => {
        let err: string | undefined;
        if (!!users.find((user) => user.user_id === group_alias)) {
          err = AdminErrorMessages.group_email_alias_error.replace(MessageAnchors.user_id, `User '${group_alias}'`);
        } else if (!!groups.find((group) => group.alias === group_alias)) {
          err = AdminErrorMessages.group_email_alias_error.replace(MessageAnchors.user_id, `Email Group '${group_alias}'`);
        }
        if (!!err) {
          dispatch(uiActions.handleRequestErrors(new Message(err)));
          setGroupAlias("");
        } else {
          const members = group_account_users_data.map((account) => account.user_id);
          handleAction({
            actionType: AdminUIActionTypes.Create,
            params: { alias: group_alias, members },
          });
          setGroupAlias("");
        }
      })
      .catch((msg: string) => {
        dispatch(uiActions.handleRequestErrors(new Message(AdminErrorMessages.error_creating_group_email), msg));
        setIsValid(false);
      });
  }

  function handleShowModal() {
    handleAction({ actionType: AdminUIActionTypes.ShowModal });
    setGroupAlias("");
  }

  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    if ((CreateEmailGroupRequests as any)[callback] instanceof Function) {
      (CreateEmailGroupRequests as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(MessageAnchors.actionType, actionObj.actionType);
      CreateEmailGroupRequests.handlePageError({ message, stack: actionObj });
    }
  }

  // handling csv data.
  function handleCsvFileData(csvFile: { data: PapaparseData[], errors: ParseError[], meta: ParseMeta }) {
    if (csvFile?.errors && csvFile?.errors.length >= 1) {
      // Papaparse returns some errors when the CSV formatting is not correct
      // or the data is missing a delimiter in each row, but that doesn't mean the data will not be parse.
      // so we are just logging those errors.
      handleAction({ actionType: AdminUIActionTypes.PageErrorMessage, params: { message: AdminErrorMessages.error_csv_upload_format, stack: csvFile.errors } });
    }
    const { data, meta } = csvFile;
    // we need to verify if the columns name inside the csv file matches the one on the csv template.
    const isValidColumns = meta.fields?.includes(csv_column_name);

    if (data.length === 0 || !isValidColumns) {
      if (data.length === 0) {
        handleAction({ actionType: AdminUIActionTypes.PageError, params: { message: AdminErrorMessages.error_csv_empty_group_file, stack: data, type: MessageHandlerDisplayType.toastr } });
      } else if (!isValidColumns) {
        handleAction({ actionType: AdminUIActionTypes.PageError, params: { message: AdminErrorMessages.error_csv_wrong_heading, stack: meta.fields, type: MessageHandlerDisplayType.toastr } });
      }
    } else {
      // any repeated address/domain from the csv file will be removed (only within the document).
      const addressesFromCsvFile: string[] = _.uniq(data.map((dataRow: any) => dataRow[csv_column_name].trim().toLowerCase()));
      handleValidateData(addressesFromCsvFile);
    }
  };

  // Description: Handle delete of a single valid address
  function handleDeleteAction(id: string | undefined) {
    if (!!id) {
      const data = [...validAddresses, ...invalidAddresses, ...duplicateAddresses].filter((address: string) => address !== id);
      handleValidateData(data);
    }
  };

  // After checking if the address is in a valid format, we also need to verify if the address/user is part of the organization.
  validAddresses.forEach((validAddress) => {
    const part_of_org = org_users.find(user => isSameUser(user.user_id, validAddress));
    if (part_of_org) {
      valid_group_users_collection.push(validAddress);
    } else if (!part_of_org) {
      non_org_group_users_collection.push(validAddress);
    }
  });

  const showInvalidAreaContent = !(_.isEmpty(invalidAddresses) && _.isEmpty(duplicateAddresses) && _.isEmpty(non_org_group_users_collection));

  const updateGroupEmailLabel = !!is_update_mode ? "Update Group Email" : "Create Group Email";

  return (
    <Modal
      size="lg"
      show={show_modal}
      onHide={handleShowModal}
      aria-labelledby="create group email"
      centered >
      <Modal.Header closeButton>
        <Modal.Title>{!!is_update_mode ? `${updateGroupEmailLabel} ${!!selected_group && `: ${selected_group?.alias}`}` : tab_heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <EmailGroupForm
            is_edit={!is_update_mode}
            group_alias={group_alias}
            selected_group={selected_group}
            setGroupAlias={setGroupAlias}
            setInvalidAlias={setInvalidAlias}
            aliases={aliases} />

          <Tabs className="pt-2" defaultActiveKey="enter_users" id="create-email-group" onSelect={handleTabSelect}>
            <Tab eventKey="enter_users" title="Enter Users">
              <div>
                <h5>Add Users</h5>
                <p className="mb-1">Please add one or more people to your group list.</p>
                <UserList
                  placeholder="Start typing to add a user"
                  ref={user_list_ref}
                  current_account={current_account}
                  handleAction={handleUpdateUsers}
                  hide_badges={true}
                  org_users={full_users}
                  user_ids={!!selected_group ? selected_group.members.map((member) => member.user_id) : []}
                  show_external_users={false}
                />
                {!!error && <small className="error">{error}</small>}
                {group_account_users_data.length > 0 && (
                  <EmailGroupMembersTable members={group_account_users_data} removeUser={handleRemoveUser}></EmailGroupMembersTable>
                )}
                <footer className="mt-4">
                  <Button type="submit" className="me-2" onClick={handleSubmitGroup} disabled={!is_valid}>
                    {updateGroupEmailLabel}
                  </Button>
                  <Button
                    onClick={reset}
                    disabled={group_account_users_data.length === 0 && (!is_update_mode && group_alias.length === 0)}
                    className="me-2"
                    variant="no-outline-primary">
                    Reset
                  </Button>
                  <Button
                    onClick={handleShowModal}
                    variant="no-outline-primary">
                    Cancel
                  </Button>
                </footer>
              </div>
            </Tab>
            <Tab eventKey="csv_upload" title="CSV Upload">
              <CsvSubmitData handleAction={handlePageActions} isValid={is_valid} submitButtonLabel={updateGroupEmailLabel} showResetButton>
                {valid_group_users_collection.length >= 1 && isDataDrop && (
                  <AddressListModalPanel
                    validation_state="valid_group"
                    collection={valid_group_users_collection}
                    handleDeleteAction={handleDeleteAction}
                  />
                )}
                {showInvalidAreaContent && (
                  <div
                    className={`invalid-addresses-card ${is_valid ? "show-divider" : ""}`}>
                    <header>
                      <h3 className="mb-2">
                        Addresses that will <i className="emphasis">not</i> be added
                      </h3>
                      <Button
                        variant="icon"
                        onClick={() => setopen_collapse(!open_collapse)}
                        aria-controls="collapse-invalid-area">
                        <Icon
                          className={`${open_collapse ? "ficon-chevron-up" : "ficon-chevron-down"}`}
                        />
                      </Button>
                    </header>
                    <Collapse in={open_collapse}>
                      <div id="collapse-invalid-area">
                        <div className="pt-2 pb-0">
                          {duplicateAddresses.length >= 1 && isDataDrop && (
                            <AddressListModalPanel
                              validation_state="duplicate"
                              collection={duplicateAddresses}
                              handleDeleteAction={handleDeleteAction}
                            />
                          )}
                          {invalidAddresses.length >= 1 && isDataDrop && (
                            <AddressListModalPanel
                              validation_state="invalid"
                              collection={invalidAddresses}
                              handleDeleteAction={handleDeleteAction}
                            />
                          )}
                          {non_org_group_users_collection.length >= 1 && isDataDrop &&
                            <AddressListModalPanel
                              validation_state="non_org_user"
                              collection={non_org_group_users_collection}
                              handleDeleteAction={handleDeleteAction}
                            />
                          }
                        </div>
                      </div>
                    </Collapse>
                  </div>
                )}
                {!isDataDrop && (
                  <>
                    <p className="download-csv-legend">
                      Download and use the upload template to accurately add multiple users at
                      once
                      <a
                        href="/templates/PreVeil-Group-Email-Upload-Template.csv"
                        target="_blank"
                      >
                        <Icon className="ficon-download" />
                        PreVeil-Group-Email-Upload-Template.csv
                      </a>
                    </p>
                    <DraggableContainer handleAction={handlePageActions} />
                  </>
                )}
              </CsvSubmitData>
            </Tab>
          </Tabs>
        </>
      </Modal.Body>
    </Modal>
  );
}

export default React.memo(CreateGroupEmailModalComponent);
