import React, { ReactElement } from "react";
// import { Button } from "react-bootstrap";
import { useAppSelector, NavigationPathTypes, PanelStateType, useAppDispatch, PanelState, GlobalConstants } from "src/common";
import { LeftNavigation, RightSidebar, SplitPane, ToggleLeftNav } from "src/components";
import { uiActions } from "src/store";
import { RootState } from "src/store/configureStore";

type AllProps = {
  type: NavigationPathTypes;
  children: ReactElement;
  scrollable?: boolean;
  active_path?: string;
}

function SplitPaneTemplate(props: AllProps) {
  const { type, active_path, children } = props;
  const dispatch = useAppDispatch();
  const nav_state = useAppSelector((state: RootState) => state.ui.left_nav_state);

  // Description: Trigger toggle the state of the Left Navigation
  // Only trigger toggle dispatch, if current left nav state is NOT equal new state 
  function toggleLeftNav(new_state: PanelStateType) {
    nav_state !== new_state && dispatch(uiActions.handleToggleLeftNavigation(new_state));
  }

  return <SplitPane
    className="wrapper-panels"
    minWidth={GlobalConstants.LEFT_NAV_MIN_WIDTH} // Left Nav Min width or closed width and also Min Width
    offWidth={GlobalConstants.LEFT_NAV_MIN_WIDTH}
    onWidth={GlobalConstants.LEFT_NAV_EXPANDED_WIDTH}  // Left Nav MAX Width or Right panel closed width window.innerWidth / 2
    toggleWidth={GlobalConstants.LEFT_NAV_TOGGLE_WIDTH}
    maxWidth={window.innerWidth / 2}
    panelState={nav_state}
    togglePanel={() => { toggleLeftNav(nav_state === PanelState.off ? PanelState.on : PanelState.off); }}
    left={
      <LeftNavigation type={type} active_path={active_path} handleAction={toggleLeftNav} />
    }
    right={
      <>
        <ToggleLeftNav
          className="hide-desktop"
          handleAction={() => { toggleLeftNav(nav_state === PanelState.off ? PanelState.on : PanelState.off); }} />
        {children}
        <RightSidebar />
      </>
    }
  />;
}

export default React.memo(SplitPaneTemplate);
