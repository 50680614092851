import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { GlobalConstants, PublicRoutes, dayjs } from "src/common";
import { CountdownDisplay } from "src/components";

function IdleTimeoutComponent() {
  const expires = dayjs().add(GlobalConstants.EXPRESS_IDLE_MODAL_TIMEOUT, "minutes").format("YYYY-MM-DD HH:mm:ss");
  const navigate = useNavigate();
  const [show, setShow] = useState<boolean>(false);
  let time: NodeJS.Timeout;
  const activeShowRef = useRef(show);
  function setActiveShow(_show: boolean) {
    activeShowRef.current = _show;
    setShow(_show);
  }

  // First onload event: bind listeners to mouse move and keydown
  useEffect(() => {
    document.addEventListener("mousemove", resetTimer);
    document.addEventListener("keydown", resetTimer);
    resetTimer();
    return () => { // Destroy code here:
      document.removeEventListener("mousemove", resetTimer);
      document.removeEventListener("keydown", resetTimer);
    };
  }, []);

  // Description: set Timeout for modal pop up
  function showWarningMessage(): void {
    clearTimeout(time);
    setActiveShow(true);
  }

  // Description: Resets the Main Timer for triggering the  modal
  function resetTimer() {
    if (!activeShowRef.current) {
      clearTimeout(time);
      time = setTimeout(() => {
        showWarningMessage();
      }, GlobalConstants.EXPRESS_IDLE_TIMEOUT);
    }
  }

  // Description: Logout and clear states
  function logout() {
    setActiveShow(false);
    clearTimeout(time);
    navigate(`/${PublicRoutes.logout_route}`);
  }

  // Description: Close modal, Close and clear all timeouts
  function closeModal() {
    setActiveShow(false);
    resetTimer();
  }

  return <Modal size="sm"
    show={show}
    onHide={() => closeModal()}
    centered
    aria-labelledby="Session Timeout Warning" >
    <Modal.Header>
      <Modal.Title>Session Timeout Warning</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>Your PreVeil session will end in <b><CountdownDisplay expires={expires} handleTimeExpired={() => logout()} /></b> due to inactivity</p>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={() => logout()}>Logout </Button>
      <Button
        onClick={() => closeModal()}
        variant="no-outline-primary">
        Stay Logged In
      </Button>
    </Modal.Footer>
  </Modal>;
}

export default React.memo(IdleTimeoutComponent);
