import React from "react";
import { Row, Col } from "react-bootstrap";
import { Account, DriveSharedInfo, DriveUIActionTypes, SORT_DIRECTION } from "src/common";
import { EmptyMessage } from "../drive-manager";
import { SharedRow } from ".";
import _ from "lodash";
import { Icon, Loading } from "src/components";
import { ActionHandlerFunction, Sort } from "@preveil-api";

type AllProps = {
  handleAction: ActionHandlerFunction;
  shared_folders: DriveSharedInfo[];
  current_account: Account;
  is_loading: boolean;
  relinking: boolean;
  sort: Sort<keyof DriveSharedInfo>;
};

function SharedListViewComponent(props: AllProps) {
  const { shared_folders, current_account, is_loading, relinking, sort, handleAction } = props;

  // Descriptions: Shareable react fragment - for each field - returns the header with sort icon
  function headerWithSortIcon(name: string, field: string) {
    return (
      <>
        {name}
        {!!sort && sort.field === field && shared_folders.length > 1 && (
          <Icon className={`${sort.direction === SORT_DIRECTION.descending ? "ficon-arrow-down" : "ficon-arrow-up"} small-icon ps-1`} />
        )}
      </>
    );
  }


  // Description: Render the rows
  function RenderList(_entries: DriveSharedInfo[]) {
    return (
      <div className="list-body">
        {_.map(_entries, (entry: DriveSharedInfo, i: number) => {
          return <SharedRow key={`item_${i}`} entry={entry} handleAction={handleAction} current_account={current_account} relinking={relinking} />;
        })}
      </div>
    );
  }

  return (
    <div className={`drive-list shared${is_loading ? " isloading" : ""}`}>
      {!!shared_folders && shared_folders.length > 0 && (
        <Row className="header-row">
          <Col md="5" className="ps-2" onClick={() => handleAction({ actionType: DriveUIActionTypes.SetSort, params: { field: "name" } })}>
            {headerWithSortIcon("Name", "name")}
          </Col>
          <Col md="2" onClick={() => handleAction({ actionType: DriveUIActionTypes.SetSort, params: { field: "status" } })}>{headerWithSortIcon("Status", "status")}</Col>
          <Col md="auto">
            <span hidden>actions</span>
          </Col>
        </Row>
      )}
      {is_loading && <Loading />}

      {!!shared_folders && shared_folders.length > 0 ? (
        RenderList(shared_folders)
      ) : !is_loading ? <EmptyMessage message="You have no shared folders" /> : null}
    </div>
  );
}

export default React.memo(SharedListViewComponent);
