import { Action } from "redux";
import keyMirror from "keymirror";
import { FSMessage } from "src/common/keys/protos/collections_pb";
import { Notification as FSNotification } from "src/common/keys/protos/async_job_pb";

/*
* Description: Types for both build mode App and Web calls
*/
export const DriveActionTypes = keyMirror({
  INITIALIZE_COLLECTION: null,
  SET_DEFAULT_COLLECTION_ID: null,
  FETCH_DEFAULT_COLLECTION_ID: null,
  FETCH_DEFAULT_PERMISSIONS: null,
  FETCH_COLLECTION_PERMISSIONS: null,
  FETCH_ROOT_INFO: null,
  INIT_DRIVE: null,
  FETCH_DIRECTORY: null,
  FETCH_LINK: null,
  FETCH_MAINTAINER_ACL_LIST: null,
  FETCH_MAINTAINER_GRANTS: null,
  FETCH_ACL_TREE: null,
  FETCH_ACL_KEY_HISTORY: null,
  FETCH_FILE: null,
  FETCH_COLLECTION: null,
  FETCH_COLLECTION_INFO: null,
  FETCH_TRASH: null,
  DESTROY_DRIVE: null
});

export type DriveCallbackAsyncFunction<T> = (message: FSMessage, response_action?: ResponseCompleteAction<T>) => Promise<ResponseCompleteAction<unknown> | void>;
export type ResponseCompleteAction<T> = { payload?: T; type?: string };

export interface MessageResponse<T> {
  message: FSMessage;
  callback?: DriveCallbackAsyncFunction<T>;
  success_action?: ResponseCompleteAction<T>;
  error_action?: Action;
}

export type MessageRequest = {
  id: string;
  message: FSMessage;
  type?: FSMessage.TypeMap[keyof FSMessage.TypeMap];
};

export type AsyncJobRequest = {
  message: FSNotification;
  type?: FSNotification.TypeMap[keyof FSNotification.TypeMap];
};

