import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { ApprovalGroup, CheckboxStates } from "src/common";
import { Checkbox } from "src/components";

type AllProps = {
  approval_group: ApprovalGroup;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  checkedList: ApprovalGroup[];
  setCheckedList: React.Dispatch<React.SetStateAction<ApprovalGroup[]>>;
};

/* Description: Row component for each approval group in the list. */
function ApprovalGroupRowComponent(props: AllProps) {
  const { approval_group, show, setShow, checkedList, setCheckedList } = props;

  /* Description: When clicking on a row, opens up the side panel or closes it */
  function NavigateToView() {
    if (checkedList.length === 0) {
      setCheckedList([approval_group]);
      setShow(true);
    }
    if (
      checkedList.length > 0 &&
      checkedList.filter((ag) => ag.uid === approval_group.uid).length === 1
    ) {
      setShow(!show);
      setCheckedList([]);
    } else if (
      checkedList.length > 0 &&
      checkedList.filter((ag) => ag.uid === approval_group.uid).length === 0
    ) {
      setCheckedList([approval_group]);
    }
  }

  /* Description: returns the current state of the checkbox */
  function getSelectedState() {
    return !!checkedList && checkedList.filter((ag) => ag.uid === approval_group.uid).length === 1
      ? CheckboxStates.checked
      : CheckboxStates.empty;
  }

  /* Description: Updates the checkedlist depending on different situations */
  function handleCheckboxChange() {
    if (checkedList.length === 0) {
      setCheckedList([approval_group]);
      setShow(true);
    } else if (
      checkedList.length === 1 &&
      checkedList.filter((ag) => ag.uid === approval_group.uid).length === 0
    ) {
      setShow(false);
      setCheckedList(checkedList.concat([approval_group]));
    } else if (
      checkedList.length === 1 &&
      checkedList.filter((ag) => ag.uid === approval_group.uid).length === 1
    ) {
      setShow(false);
      setCheckedList([]);
    } else if (
      checkedList.length > 1 &&
      checkedList.filter((ag) => ag.uid === approval_group.uid).length === 0
    ) {
      setCheckedList(checkedList.concat([approval_group]));
    } else if (
      checkedList.length > 1 &&
      checkedList.filter((ag) => ag.uid === approval_group.uid).length === 1
    ) {
      setCheckedList(checkedList.filter((ag) => ag.uid !== approval_group.uid));
    }
  }

  return (
    <Row className="align-items-center body" onClick={NavigateToView}>
      <Col xs={1} className="pe-3">
        <Checkbox
          onChange={() => handleCheckboxChange()}
          value={getSelectedState()}
          selected={getSelectedState()}
        />
      </Col>
      <Col xs={4}>{approval_group.name}</Col>
      <Col xs={3}>{approval_group.approvers.length}</Col>
      <Col xs={3}>{approval_group.required_approvers}</Col>
    </Row>
  );
}

export default React.memo(ApprovalGroupRowComponent);
