import React from "react";
import DatePicker from "react-datepicker";
import { dayjs } from "src/common";
import { Icon } from "src/components";

type AllProps = {
  startDate: Date | null;
  endDate: Date | null;
  handleChange: (dates: [any, any]) => void;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
};

function DateSelectorComponent(props: AllProps) {
  const { setStartDate, setEndDate } = props;
  const today = dayjs().toDate();

  const handleBlur = (event: React.FocusEvent<HTMLInputElement, Element>, start: boolean) => {
    const input = event.target.value;
    if (input) {
      const date = dayjs(new Date(input).toISOString());
      if (start) {
        if (date.isValid() && !!props.endDate && date.toDate() < props.endDate && date < dayjs()) {
          setStartDate(date.toDate());
        }
      } else {
        if (
          date.isValid() &&
          !!props.startDate &&
          date.toDate() > props.startDate &&
          date < dayjs()
        ) {
          setEndDate(date.toDate());
        }
      }
    }
  };

  const DateSelector = () => {
    return (
      <>
        <div>
          <div className="date-picker-inputs p-2">
            <div className="date-picker-input">
              <span>
                <Icon className="pv-icon-calendar text-muted " />
              </span>
              <DatePicker
                focusSelectedMonth={false}
                className="border-0 input"
                selected={props.startDate}
                startDate={props.startDate}
                onChange={(date) => setStartDate(date)}
                onBlur={(e) => handleBlur(e, true)}
                selectsStart
                popperClassName="d-none"
                dateFormat={["MMM d, yyyy", "MMM dd, yyyy"]}
                placeholderText="Select start date"
                maxDate={today}
                shouldCloseOnSelect={false}
                autoComplete={"off"}
              />
            </div>
            <div className="date-picker-input">
              <span>
                <Icon className="pv-icon-calendar text-muted" />
              </span>
              <DatePicker
                focusSelectedMonth={false}
                className="border-0 input"
                selected={props.endDate}
                startDate={props.startDate}
                endDate={props.endDate}
                onChange={(date) => setEndDate(date)}
                onBlur={(e) => handleBlur(e, false)}
                selectsEnd
                popperClassName="d-none"
                dateFormat={["MMM d, yyyy", "MMM dd, yyyy"]}
                placeholderText="Select end date"
                minDate={props.startDate}
                maxDate={today}
                shouldCloseOnSelect={false}
                autoComplete={"off"}
              />
            </div>
          </div>
          <DatePicker
            focusSelectedMonth={false}
            calendarClassName="logs"
            dateFormat="MMM d, yyyy"
            selected={props.startDate}
            startDate={props.startDate}
            endDate={props.endDate}
            onChange={props.handleChange}
            monthsShown={2}
            selectsRange={true}
            shouldCloseOnSelect={false}
            maxDate={today}
            inline
            autoComplete="off"
          />
        </div>
      </>
    );
  };

  return <DateSelector />;
}

export default React.memo(DateSelectorComponent);
