import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { Account, DriveMessages, EntryItem, DriveUIActionTypes, Feature, DriveFeature, CollectionEntity, useAppSelector, useListFileVersionsMutation, ListFileVersionsApiResponse, VersionHistoryItem, dayjs, DriveErrorMessages, useAppDispatch, Message } from "src/common";
import { RootState } from "src/store/configureStore";
import { uiActions } from "src/store";
import { CollapseWrapper, FileLock, VersionHistory } from "..";
import { filesize } from "filesize";
import _ from "lodash";

type AllProps = {
  current_account: Account;
  entry: EntryItem;
  action?: string;
  collection_info: CollectionEntity;
  handleAction: ActionHandlerFunction;
}

function FileDetailsComponent(props: AllProps) {
  const { current_account, entry, action, collection_info, handleAction } = props;
  const root_info = useAppSelector((state: RootState) => state.drive.root_info);
  const [fetching_history, setFetchingHistory] = useState<boolean>(false);
  const [downloading_or_reverting, setDownloadingOrReverting] = useState<boolean>(false);
  const [open_version_history, setOpenVersionHistory] = useState<boolean>(false);
  const [file_history, setFileHistory] = useState<VersionHistoryItem[]>([]);
  const [list_file_versions] = useListFileVersionsMutation();
  const dispatch = useAppDispatch();

  // Description: Fetch the Version History for the file entry from filesync (only through app as version history does not exist on web)
  async function getVersionHistory() {
    setFetchingHistory(true);
    await list_file_versions({
      user_id: current_account.user_id,
      file_id: entry.id,
      collection_id: entry.collection_id,
    })
      .unwrap()
      .then((response: ListFileVersionsApiResponse) => {
        const sorted = _.orderBy(response, [item => new Date(String(item.lastModificationDate))], ["desc"]);
        const history = sorted.map(h => ({ last_modified: dayjs(h.lastModificationDate).utc().local().format("MMM DD, YYYY h:mm A"), size: filesize(h.size).toString(), version: h.version }));
        setFileHistory(history);
        setFetchingHistory(false);
      })
      .catch((msg) => {
        dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.error_fetching_file_history), msg));
        setOpenVersionHistory(false);
        setFetchingHistory(false);
      });
  }

  return <>
    {/* File Lock Component  - default opened  */}
    <CollapseWrapper
      hidden={Feature.hidden(DriveFeature.Lock, collection_info.permissions) || entry.collection_id === root_info?.collection_id}
      handleAction={handleAction}
      title={"File Lock"}
      popover={DriveMessages.file_lock_info}
      open={action === DriveUIActionTypes.Lock}>
      <FileLock
        current_account={current_account}
        entry={entry}
        permissions={collection_info.permissions}
        handleAction={handleAction}
      />
    </CollapseWrapper>
    {/* Version History Component */}
    {
      !open_version_history &&
      !Feature.hidden(DriveFeature.VersionHistory, collection_info.permissions) && (
        <Button
          variant="outline-secondary"
          className="mt-3"
          onClick={() => {
            getVersionHistory();
            setOpenVersionHistory(true);
          }}>
          Get Version History
        </Button>
      )}
    {
      !!open_version_history &&
      <CollapseWrapper
        hidden={Feature.hidden(DriveFeature.VersionHistory, collection_info.permissions)}
        handleAction={handleAction}
        title={"Version History"}
        isLoading={fetching_history || downloading_or_reverting}
        refresh={getVersionHistory}
        open={open_version_history} >
        <VersionHistory
          permissions={collection_info.permissions}
          entry={entry}
          is_loading={fetching_history}
          history_list={file_history}
          current_account={current_account}
          getVersionHistory={getVersionHistory}
          setDownloadingOrReverting={setDownloadingOrReverting}
        ></VersionHistory>
      </CollapseWrapper>
    }
  </>;
}

export default React.memo(FileDetailsComponent);
