import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { ActionHandlerFunction, WebsocketCryptoMessage, IActionHandler } from "@preveil-api";
import { Button, Offcanvas } from "react-bootstrap";
import { WebsocketStatus, websocketActions, uiActions } from "src/store";
import {
  ApprovalGroup,
  ApprovalRequestStatus,
  RecoveryGroup,
  RequestTypes,
  SettingsUIActionTypes,
  UserRequest,
  useAppDispatch,
  useAppSelector,
  AccounWStSteps,
  Message,
  RequestTab,
  WebsocketErrors,
  MessageHandlerDisplayType
} from "src/common";
import { Loading } from "src/components";
import { SidePanelBody } from ".";
import _ from "lodash";

type AllProps = {
  is_approval: boolean;
  request: UserRequest;
  current_recovery_group?: RecoveryGroup | ApprovalGroup;
  setShow: Dispatch<SetStateAction<boolean>>;
  setCheckedList: Dispatch<SetStateAction<UserRequest[]>>;
  confirmDelete: Function;
  handleAction: ActionHandlerFunction;
  is_details_loading: boolean
  get_responses: boolean
};

const FAILED_PIN_VERIFICATION = "pin verification failed";

function ApprovalRequestSidePanelComponent(props: AllProps) {
  const dispatch = useAppDispatch();
  const websocketActiveStatus = new Set([
    AccounWStSteps.GET_CONNECTION_ESTABLISHED,
    AccounWStSteps.INITIALIZE_CONNECTION,
    AccounWStSteps.GET_ACCEPTED_INIT_OK,
    AccounWStSteps.ERROR
  ]);
  const current_account = useAppSelector((state) => state.account.current_account);
  const { is_approval, request, setShow, current_recovery_group,
    confirmDelete, setCheckedList, handleAction, is_details_loading, get_responses } = props;
  const [group, setGroup] = useState<RecoveryGroup | ApprovalGroup | undefined>(undefined);
  const approve_text =
    is_approval && request.type === RequestTypes.subsume_account
      ? "Join Organization"
      : "Approve";
  const reject_text =
    is_approval && request.type === RequestTypes.subsume_account
      ? "Reject"
      : "Vote No";

  // Websocket
  const message = useAppSelector((state) => state.websocket.message as WebsocketCryptoMessage);
  const status = useAppSelector((state) => state.websocket.status);
  const errors = useAppSelector((state) => state.websocket.errors);

  // Local State
  const [step, setStep] = useState<number>(AccounWStSteps.DISCONNECTED);

  /* Description: On Intial Load, get the request responses to show the progress of the request - this is for recovery group
  user requests. */
  useEffect(() => {
    const group = request.current_group || current_recovery_group;
    setGroup(group);
    if (get_responses && !!group && request.status === ApprovalRequestStatus.pending) {
      handleAction({
        actionType: SettingsUIActionTypes.GetRequestResponses,
        params: request.request_id,
      });
    }
    setStep(AccounWStSteps.DISCONNECTED);
  }, []);

  useEffect(() => {
    const isError = status === WebsocketStatus.error || message?.status === WebsocketStatus.error;
    let next = isError ? AccounWStSteps.ERROR : !!message ? step + 1 : step;
    if (!!message?.status) {
      next = message.status === WebsocketStatus.key_transfer_complete ?
        AccounWStSteps.GET_KEY_TRANSFER_COMPLETE :
        message.status === WebsocketStatus.connection_established ?
          AccounWStSteps.GET_CONNECTION_ESTABLISHED : next;
    }

    if (!!message?.status && message.status === WebsocketStatus.verify_request) {
      const message = JSON.stringify({
        action: "approve"
      });
      dispatch(websocketActions.wsOnSendMessage(message));
    }
    if (next === AccounWStSteps.GET_KEY_TRANSFER_COMPLETE) {
      setShow(false);
      setCheckedList([]);
      handleAction({ actionType: SettingsUIActionTypes.Refresh, params: RequestTab.pending });
      dispatch(uiActions.handleSetMessage(new Message("Successfully Approved Export Request")));
    }

    setStep(next);
    
  }, [message, status]);

  // Description: First onload and unload events - destroy ws on leave
	useEffect(() => {
		return () => {  // Destroy websocket State onleave
			dispatch(websocketActions.destroyWebsocket());
		};
	}, []);

  useEffect(() => {
    if (!!errors && !!errors?.status && step === AccounWStSteps.ERROR) {
      const { msg } = errors;
      if (msg === FAILED_PIN_VERIFICATION) {
        dispatch(uiActions.handleRequestErrors(new Message(WebsocketErrors.bad_pin, MessageHandlerDisplayType.toastr), errors));
      } else {
        dispatch(uiActions.handleRequestErrors(new Message(WebsocketErrors.default, MessageHandlerDisplayType.toastr), errors));
      }
    }
  }, [errors, step]);
  
  const DataExportRequest = {
    handleInitWebsocketConnection: () => {
      handleDataExportRequest();
    },
    handleTransferKeyRequest: (params: { pin: string }) => {
      if (!!params.pin && params.pin.length > 0 && !!current_account?.user_id) {
        const verifyMessage: string = JSON.stringify({
          user_id: current_account.user_id,
          action: "verify",
          pin: params.pin,
        });
        dispatch(websocketActions.wsOnSendMessage(verifyMessage));
      } else {
        const error = {
          message: WebsocketErrors.bad_pin,
          stack: params
        };
        dispatch(uiActions.handleRequestErrors(new Message(WebsocketErrors.bad_pin, MessageHandlerDisplayType.toastr), error));
      }
    },
    handleDestroy: () => {
      setStep(AccounWStSteps.DISCONNECTED);
      dispatch(websocketActions.destroyWebsocket());
    },
  };

  function handleDataExportRequest() {
    if (!_.isEmpty(request) && request?.requester_id && !!current_account?.user_id) {
      const { requester_id } = request;
      const host = `${process.env.REACT_APP_LOCAL_WEBSOCKET_SERVER}/users/${encodeURIComponent(current_account.user_id)}/connect/${encodeURIComponent(requester_id)}`;
      dispatch(websocketActions.wsConnect({ host, init: { action: "pre_init" } }));
    }
  }

  function respondToRequest(approved: boolean) {
    if (request && request.type !== RequestTypes.export) { 
      setShow(false);
      setCheckedList([]);
      handleAction({
        actionType: SettingsUIActionTypes.RespondToRequest,
        params: { request, approved },
      });
    } else if (approved && request && request.type === RequestTypes.export) {
      handleDataExportRequest();
    } else if (!approved && request && request.type === RequestTypes.export) {
      setShow(false);
    }
  }

  function title(): string {
    return request.type !== RequestTypes.subsume_account
      ? `${is_approval ? "Approval: " : "Change Summary: "} ${request.mapped_action} Request`
      : `Invitation to join ${!!request.org_details && !!request.org_details.org_name ? `"${request.org_details.org_name}"` : "Organization"}`;
  }

  function handlePageActions(actionObj: IActionHandler) { 
    const callback = `handle${actionObj.actionType}`;
    if ((DataExportRequest as any)[callback] instanceof Function) {
      (DataExportRequest as any)[callback](actionObj.params);
    }
  }

  // The Approve button will be hidden if the websocket screen is active
  // because it will have its own approve button.
  const showApproveButton = !websocketActiveStatus.has(step);
  const isDataExportRequest = !!request && request?.type === RequestTypes.export;
  const approval_button_label = isDataExportRequest ? "Start Approval" : approve_text;
  return (
    <>
      {!!is_details_loading ? <Loading className="in-place"/> :
        <>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className="semi-bold" as="h3">
              {title()}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <SidePanelBody
              handleActions={handlePageActions}
              step={step}
              is_approval={is_approval}
              request={request}
              current_recovery_group={group}
            ></SidePanelBody>
            {is_approval && request.status === ApprovalRequestStatus.pending && showApproveButton && (
              <>
                <Button variant="primary" onClick={() => respondToRequest(true)}>
                  {approval_button_label}
                </Button>
                {!isDataExportRequest && (
                  <Button variant="no-outline-primary" onClick={() => respondToRequest(false)}>
                    {reject_text}
                  </Button>
                )}
              </>
            )}
            {!is_approval && request.status === ApprovalRequestStatus.pending && (
              <Button variant="outline-secondary" onClick={() => confirmDelete()}>
                Delete Pending Changes
              </Button>
            )}
            {!!request.pending_response && (
              <p className="text-muted mt-3">
                <span className="text-primary">*</span>
                This request is pending a response from another approver.
              </p>
            )}
          </Offcanvas.Body>
        </>
      }
    </>
  );
}

export default React.memo(ApprovalRequestSidePanelComponent);
