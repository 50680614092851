import { all, fork, call, put, takeEvery } from "redux-saga/effects";
import { IFetchResponse } from "@preveil-api";
import { adminActions, AdminActionTypes } from "./slice";
import { uiActions } from "../ui-manager/slice";
import { CollectionAPI, Message, MessageHandlerDisplayType, AdminErrorMessages } from "src/common";
//  CryptoAPI, Admin, PublicRoutes, KeyStorageData, Helpers, StatusCode, 
// KeystorageResponseErrorCodes, AccountIdentifiers, CreateAccountSteps, LoginWebSteps, AccountErrorMessages, MessageAnchors,
// MessageHandlerDisplayType

// import _ from "lodash";

// -----------------------------------------------------------------------------------------------------------------------------
// Description: Get Organization information for org users (standard and admins)
// NOTES: - This call will populate state for Org users only. 
//        - COLLECTION SAGA 
// -----------------------------------------------------------------------------------------------------------------------------
function* getOrganizationInfoRequest(action: ReturnType<typeof adminActions.getOrganizationInfo>): Generator {
  const response = (yield call(CollectionAPI.getOrganizationInfo, action.payload.account_ids, action.payload.org_id)) as IFetchResponse;
  if (!response.isError) {
    yield put(adminActions.getOrganizationInfoSuccess(response.data));
  } else {
    yield put(uiActions.handleRequestErrors(new Message(AdminErrorMessages.error_fetching_org_info, MessageHandlerDisplayType.toastr), response));
  }
}

function* watchGetOrganizationInfo() {
  yield takeEvery(AdminActionTypes.GET_ORGANIZATION_INFO, getOrganizationInfoRequest);
}


// -----------------------------------------------------------------------------------------------------------------------------
// Export ALL Admin Saga:
// -----------------------------------------------------------------------------------------------------------------------------
export function* adminSaga() {
  yield all([
    fork(watchGetOrganizationInfo),

  ]);
}
