import React, { FocusEvent } from "react";
import { Col, Row } from "react-bootstrap";
import { Checkbox } from "src/components";
import { AdminUIActionTypes, CheckboxStatesTypes } from "src/common";
import { ActionHandlerFunction, CollectionServerUser } from "@preveil-api";

type AllProps = {
  user: CollectionServerUser;
  handleActions: ActionHandlerFunction;
  isChecked: CheckboxStatesTypes;
}

function DataExportUserListRow({ user, handleActions, isChecked }: AllProps) {
  const { user_id, display_name, entity_metadata } = user;
  return (
    <Row className="align-items-center address-row m-0 flex-nowrap">
      <div className="user-row-checkbox">
        <Checkbox
          onChange={(e: FocusEvent<HTMLInputElement>) => handleActions({ actionType: AdminUIActionTypes.SingleSelect, params: {e, user}})}
          value={user_id}
          selected={isChecked}
        />
      </div>
      <Col sm={4} className="user-row-field">
        <p>{display_name}</p>
      </Col>
      <Col sm={4} className="user-row-field">
        <p>{user_id}</p>
      </Col>
      <Col sm={4} className="user-row-field">
        <p>{entity_metadata.department}</p>
      </Col>
    </Row>
  );
}

export default React.memo(DataExportUserListRow);