export const ENCRYPTION_KEY_PROTOCOLS = {
  V0: 0,
  V1: 1,
  V2: 2,
  V3: 3,
  Current: 3,
};

export const SIGN_KEY_PROTOCOLS = {
  V0: 0,
  V1: 1,
  V3: 3,
  Current: 3
};

export const SYMM_KEY_PROTOCOLS = {
  V0: 0,
  V1: 1,
  Current: 1
};

export const USER_KEY_PROTOCOLS = {
  V0: 0,
  V1: 1,
  Current: 1
};
