import * as React from "react";

type AllProps = {
  className: string;
  title?: string;
  tooltip?: string;
  // size?: string;
  // animate?: string;
}

function IconComponent(props: AllProps) {
  const { className, title, tooltip } = props;
  return (
    <i
      className={className}
      title={title}
      data-tooltip-id="pv-tooltip"
      data-tooltip-content={tooltip}
    ></i>
  );
}

export default React.memo(IconComponent);

