import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";

function Sandbox() {
  const [status, setStatus] = useState("Uninnitialized");

  useEffect(() => {
    startWS();
  }, []);

  function startWS() {
    const host = "ws://127.0.0.1:2420/subscribe_all";
    let socket = new WebSocket(host);
    socket = _socketListener(socket);
    console.log(socket);
  }
  function _socketListener(_socket: WebSocket) {
    _socket.onopen = () => {
      setStatus("OPENED");
      console.log("onopen");
    };
    _socket.onmessage = (message_event: MessageEvent) => {
      console.log(message_event.data);
      setStatus(`onmessage: ${JSON.stringify(message_event.data)}`);
    };
    _socket.onclose = () => {
      console.log("onclose");
      setStatus("CLOSED");
    };
    _socket.onerror = (error: unknown) => {
      console.log("Error", error);
      setStatus(`Error: ${JSON.stringify(error)}`);
    };
    return _socket;
  }

  return <Card body>
    <p>Websocket status:</p>
    <p>{status}</p>
  </Card>;
}

export default React.memo(Sandbox);
