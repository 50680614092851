import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import { MailboxItem, Thread, ActionHandlerFunction, PaginationItem } from "@preveil-api";
import {
  Account, useAppDispatch, useAppSelector, PrivateRoutes, AppConfiguration, MailErrorMessages, ClientDefaultMailboxes, isNumber, Pagination,
  SearchResultFilter
} from "src/common";
import { mailActions } from "src/store";
import { EmptyMailbox } from "..";
import MessageRow from "./MessageRow.component";
import _ from "lodash";

type AllProps = {
  current_account: Account;
  current_mailbox: MailboxItem;
  offset: number;
  pagination: PaginationItem;
  isLoading: boolean;
  page?: string;
  selected: string[];
  handleAction: ActionHandlerFunction;
}

function SearchResultsViewComponent(props: AllProps) {
  const { current_account, page, pagination, isLoading, selected, handleAction } = props;
  const [page_index, setPageIndex] = useState<number>(Pagination.getPageIndex(page));
  const [search_threads, setPageThreads] = useState<Thread[] | undefined>();
  const threads_page = useAppSelector((state) => state.mail.threads_page);
  const search_filter = useAppSelector((state) => state.mail.search_filter);
  const search_matches = useAppSelector((state) => state.mail.search_matches);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Description: Handles specific threads to display in search (i.e based on pagination and filters)
  function handleSearchThreadsPagination(reset: boolean = false) {
    let _threads = threads_page?.threads;
    if (!!_threads && _threads.length > 0) {
      const chunks = _.chunk(_threads, pagination.pageSize);
      const new_page_index = !!page && isNumber(page) ? (Number(page) - 1) : pagination.pageIndex;
      _threads = chunks[new_page_index];
      (page_index !== new_page_index || reset) &&
        handlePaginationUpdate(new_page_index, _threads.length, threads_page?.total || _threads.length);
    }
    setPageThreads(_threads);
  }

  // Description: Update store pagination item
  function handlePaginationUpdate(new_page_index: number, totalPageRows: number, totalRows: number) {
    const paginationItem = new Pagination(new_page_index, totalPageRows, totalRows).pagination_item;
    !!paginationItem && dispatch(mailActions.setPagination(paginationItem));
    setPageIndex(new_page_index);
  }

  // Description: Filter search results or reset them
  function handleFilterSearchThreads() {
    const _threads = threads_page?.threads;
    if (!!_threads && search_filter !== SearchResultFilter.all) {
      const selected_search_matches = !!search_matches ? search_matches[search_filter] : null;
      const new_threads: Thread[] = (!!selected_search_matches) ?
        _.filter(_threads, (thread: Thread) => {
          return selected_search_matches.includes(thread.thread_id);
        }) : [];
      setPageThreads(new_threads);
      handlePaginationUpdate(0, new_threads.length, new_threads.length);
    } else {
      // NOTE: Else reset to original search threads
      handleSearchThreadsPagination(true);
    }
  }

  // Description: just in case, onload block web access to search mailboxes (a crypto only functionality)
  useEffect(() => {
    (AppConfiguration.buildForWeb()) && navigate(`/${PrivateRoutes.mail_inbox_route}`);
  }, []);

  // Description: update client side pagination and filters
  useEffect(() => {
    handleSearchThreadsPagination();
  }, [threads_page, page]);

  // Description: Filter results
  useEffect(() => {
    handleFilterSearchThreads();
  }, [search_filter]);

  return <>
    {
      !!search_threads && search_threads.length > 0 ?
        <Table className="mail-list" hover>
          <tbody>
            {
              _.map(search_threads, (thread: Thread, i: number) => {
                return <MessageRow
                  selected={selected.includes(thread.thread_id)}
                  user_id={current_account.user_id}
                  key={`thread_${i}`}
                  thread={thread}
                  current_mailbox={ClientDefaultMailboxes.search}
                  page={"1"}
                  handleAction={handleAction} />;
              })
            }
          </tbody>
        </Table> : (!isLoading && <EmptyMailbox message={MailErrorMessages.no_search_results_found} />)
    }
  </>;
}

export default React.memo(SearchResultsViewComponent);
