import * as React from "react";
import { RecoveryGroup, ApprovalGroup, UserRequest } from "src/common";

type AllProps = {
  request: UserRequest;
  current_recovery_group?: RecoveryGroup | ApprovalGroup;
  current_optionals_required: number | false;
  compact?: boolean;
};

function DetailsComponent(props: AllProps) {
  const { request, current_recovery_group, current_optionals_required, compact } = props;

  return (
    <div className={`metadata-list ${!compact ? "mb-3" : ""}`}>
      {compact ? (
        <div>
          <div className="details-block">
            <div className="details-section">
              <div>
                <p>Requester</p>
                <p>Status</p>
              </div>
              <div className="details-values">
                <p>
                  <b>{request.requester?.name || request.requester_id}</b>
                </p>
                <p>
                  <b className="with_cap">{request.status}</b>
                </p>
              </div>
            </div>
            <div className="details-section">
              <div>
                <p>Created</p>
                <p>Expiration</p>
              </div>
              <div className="details-values">
                <p>
                  <b>{request.timestamp}</b>
                </p>
                <p>
                  <b>{request.expiration}</b>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <ul>
          <li>
            <p>Requester </p>
            <b>{request.requester?.name || request.requester_id}</b>
          </li>
          <li>
            <p>Status </p>
            <b className="with_cap">{request.status}</b>
          </li>
          {!!current_recovery_group && (
            <li>
              <p>Required Approvals</p>
              <b>{current_optionals_required}</b>
            </li>
          )}
          <li>
            <p>Created</p>
            <b>{request.timestamp}</b>
          </li>
          <li>
            <p>Expiration</p>
            <b>{request.expiration}</b>
          </li>
          {!!current_recovery_group && current_recovery_group instanceof ApprovalGroup && (
            <li>
              <p>Approval Group </p>
              <b>{current_recovery_group.name}</b>
            </li>
          )}
        </ul>
      )}
    </div>
  );
}

export default React.memo(DetailsComponent);
