import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useLiveQuery } from "dexie-react-hooks";
import { ActionHandlerFunction, Sort } from "@preveil-api";
import { useAppSelector, SearchClient, SearchNode, DriveUIActionTypes, SearchProgressValues, SearchFieldType, getOppositeSortDirection, SORT_DIRECTION } from "src/common";
import { Icon, Loading } from "src/components";
import { SearchRow } from ".";
import _ from "lodash";

type AllProps = {
  search_term?: string;
  results?: SearchNode[];
  error: boolean;
  sort: Sort<keyof SearchNode>;
  progress: number;
  handleAction: ActionHandlerFunction;
}

function SearchResultsComponent(props: AllProps) {
  const { search_term, results, sort, progress, error, handleAction } = props;
  const current_account = useAppSelector((state) => state.account.current_account);
  const [search_client] = useState<SearchClient | null>(current_account ? new SearchClient(current_account.user_id) : null);
  // Description: get all the results from DB
  const pending_searches = useLiveQuery(
    async () => {
      if (!!search_client) {
        const result = await search_client.db.search
          .where("has_more")
          .equals(1)
          .toArray();
        return result;
      } else {
        return undefined;
      }
    }, [results]
  );

  // Description: Error Message block
  function ErrorMessage() {
    return <div className="empty">
      <p>  <Icon className="ficon-alert-octagon me-2" />
        An error occurred during the search.
      </p>
      <Button
        variant="no-outline-primary"
        onClick={() => handleAction({ actionType: DriveUIActionTypes.Reset })} >Try Again</Button>
    </div>;
  }

  // Description: Search more results
  function SearchMore() {
    return <Button variant="link" size="sm"
      onClick={() => handleAction({ actionType: DriveUIActionTypes.LoadMore, params: pending_searches })}>
      Search More ...</Button>;
  }

  return !!results && results.length ?
    <ul>
      <li className="results-header">
        <div onClick={() => handleAction({
          actionType: DriveUIActionTypes.SetSort,
          params: {
            field: SearchFieldType.name,
            direction:
              sort.field === SearchFieldType.name ? getOppositeSortDirection(sort.direction) : SORT_DIRECTION.ascending
          }
        })}>Name
          {
            sort.field === SearchFieldType.name && <Icon className={`${sort.direction === SORT_DIRECTION.descending ? "ficon-arrow-down" : "ficon-arrow-up"}`} />
          }
        </div>
        <div onClick={() => handleAction({
          actionType: DriveUIActionTypes.SetSort,
          params: {
            field: SearchFieldType.last_modification_date,
            direction: sort.field === SearchFieldType.last_modification_date ? getOppositeSortDirection(sort.direction) : SORT_DIRECTION.ascending
          }
        })}>Modified
          {
            sort.field === SearchFieldType.last_modification_date && <Icon className={`${sort.direction === SORT_DIRECTION.descending ? "ficon-arrow-down" : "ficon-arrow-up"}`} />
          }
        </div>
      </li>
      <>
        {
          _.map(results, (result: SearchNode, i: number) => {
            return <SearchRow
              key={`row_${i}`}
              result={result}
              handleAction={handleAction} />;
          })
        }
      </>
      {
        ((!!pending_searches && pending_searches.length > 0) && progress === SearchProgressValues.complete) &&
        <li className="show-more">
          <SearchMore />
        </li>
      }
    </ul > : progress === SearchProgressValues.complete ?
      <div className="empty">
        {
          !!search_term ? <>
            <Icon className="ficon-alert-octagon me-2" />
            No matching results found for your search
          </> :
            <>Enter a search value to find an item.</>
        }
        {
          (!!pending_searches && pending_searches.length > 0) && <SearchMore />
        }
      </div> :
      (error ? <ErrorMessage /> : <Loading className="in-place" />);
}

export default React.memo(SearchResultsComponent);
