import React from "react";
import { Row, Col } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { Account, DriveInvitationInfo } from "src/common";
import { InvitationsRow } from ".";
import _ from "lodash";
import { EmptyView, Loading } from "src/components";

type AllProps = {
  current_account: Account;
  handleAction: ActionHandlerFunction;
  invitations: DriveInvitationInfo[];
  is_loading: boolean;
  responding: boolean;
};

function ListViewComponent(props: AllProps) {
  const { handleAction, invitations, is_loading, responding } = props;

  // Description: Render the rows
  function RenderList() {
    return (
      <div className="list-body">
        {_.map(invitations, (invitation: any, i: number) => {
          return <InvitationsRow key={`item_${i}`} invitation={invitation} handleAction={handleAction} responding={responding} />;
        })}
      </div>
    );
  }

  return (
    <div className={`drive-list invitation${is_loading ? " isloading" : ""}`}>
      {!!invitations && invitations.length > 0 && (
        <Row className="header-row">
          <Col md="3" className="ps-2">
            Name
          </Col>
          <Col md="3">Owner</Col>
          <Col md="2">Permissions</Col>
          <Col className="d-md-none d-lg-block">Expiration</Col>
          <Col md="1">
            <span hidden>actions</span>
          </Col>
        </Row>
      )}
      {is_loading && <Loading />}
      {!!invitations && invitations.length > 0 ? RenderList() : !is_loading ? <EmptyView message="You have no invitations" /> : null}
    </div>
  );
}

export default React.memo(ListViewComponent);
