import React, { useEffect } from "react";
import { Row, Col, ButtonGroup, Button } from "react-bootstrap";
import { SearchResultFilter, useAppSelector, useAppDispatch } from "src/common";
import { mailActions } from "src/store";

function SearchFiltersComponent() {
  const dispatch = useAppDispatch();
  const search_threads = useAppSelector((state) => state.mail.threads_page);
  const search_term = useAppSelector((state) => state.mail.search_term);
  const search_filter = useAppSelector((state) => state.mail.search_filter);
  const threads_page = useAppSelector((state) => state.mail.threads_page);

  // Description: Reset filters on unmount
  useEffect(() => {
    return () => {
      dispatch(mailActions.setSearchFilter(SearchResultFilter.all));
    };
  }, []);

  // Description: disable filters when search has no results
  function isDisabled(): boolean {
    return !threads_page || threads_page.threads.length <= 0;
  }

  return <Row className="search-filters">
    {
      !!search_threads && search_threads.total > 0 &&
      <Col>
        Showing {search_threads.total || "0"} mail results for <b>"{search_term}"</b>
      </Col>
    }
    <ButtonGroup as={Col} md="auto" size="sm">
      <Button variant={search_filter === SearchResultFilter.all ? "secondary" : "outline-light"}
        disabled={isDisabled()}
        data-tooltip-id="pv-tooltip" data-tooltip-content="Search All message fields"
        onClick={() => dispatch(mailActions.setSearchFilter(SearchResultFilter.all))}>ALL</Button>
      <Button variant={search_filter === SearchResultFilter.to ? "secondary" : "outline-light"}
        disabled={isDisabled()}
        data-tooltip-id="pv-tooltip" data-tooltip-content="Search message recipients"
        onClick={() => dispatch(mailActions.setSearchFilter(SearchResultFilter.to))}>TO</Button>
      <Button variant={search_filter === SearchResultFilter.from ? "secondary" : "outline-light"}
        disabled={isDisabled()}
        data-tooltip-id="pv-tooltip" data-tooltip-content="Search message senders"
        onClick={() => dispatch(mailActions.setSearchFilter(SearchResultFilter.from))}>FROM</Button>
      <Button variant={search_filter === SearchResultFilter.message ? "secondary" : "outline-light"}
        disabled={isDisabled()}
        data-tooltip-id="pv-tooltip" data-tooltip-content="Search message contents"
        onClick={() => dispatch(mailActions.setSearchFilter(SearchResultFilter.message))}>MESSAGE</Button>
    </ButtonGroup>
  </Row>;
}

export default React.memo(SearchFiltersComponent);
