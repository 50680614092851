
import * as React from "react";
import { Pagination } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { UIManagerActionTypes, SimplePaginationAction } from "src/common";
import { Icon } from "src/components";

interface AllProps {
  isFirst: boolean;
  isLast: boolean;
  className?: string;
  handleAction: ActionHandlerFunction;
}

function SimplePaginationComponent(props: AllProps) {
  const { isFirst, isLast, className, handleAction } = props;
  return <Pagination
    size="sm"
    className={`${className}`}
    aria-label={"Simple Pagination"} >
    <Pagination.Prev
      onClick={() => handleAction({ actionType: UIManagerActionTypes.SimplePaging, params: SimplePaginationAction.previous })}
      disabled={isFirst} >
      <Icon className="ficon-chevron-left" />
    </Pagination.Prev>
    <Pagination.Next
      onClick={() => handleAction({ actionType: UIManagerActionTypes.SimplePaging, params: SimplePaginationAction.next })}
      disabled={isLast}>
      <Icon className="ficon-chevron-right" />
    </Pagination.Next>
  </Pagination>;
}

export default React.memo(SimplePaginationComponent);

