import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import { IActionHandler, WebsocketCollectionServerMessage, TransferAccountCredentials, MessageDisplayType } from "@preveil-api";
import {
  WebsocketConnectionRoutes, useAppDispatch, useAppSelector, QueryParamKeys, instanceofTransferCredentials, instanceofKeyTransfer, MessageHandlerDisplayType,
  KeyExchangeManager, TransferAccountSteps, TransferAccountActions, TransferAccountTypes, AccountIdentifiers, account_types, Message,
  AccountErrorMessages, GlobalErrorMessages, MessageAnchors, normalizeQueryUserId
} from "src/common";
import { uiActions, accountActions, websocketActions, WebsocketStatus } from "src/store";
import { Loading, useAuthContext } from "src/components";
import {
  DefaultPanel,
  AwaitConnection,
  ConnectionEstablished,
  ErrorPanel
} from ".";
import connect_img from "src/assets/images/copy_account.svg";

interface PublicKeyHashType {
  protocol_version: number;
  public_key_hash: string;
  other_public_key_hash?: string;
}

function ConnectAccountComponent() {
  const { loginComplete } = useAuthContext();
  const [user_id, setUserId] = useState<string>();
  const [step, setStep] = useState<number>(TransferAccountSteps.DISCONNECTED);
  const [pin, setPin] = useState<string | undefined>();
  const [protocol, setProtocol] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [key_exchange_manager, setKeyExchangeManager] = useState<KeyExchangeManager>();
  const dispatch = useAppDispatch();
  const message = useAppSelector((state) => state.websocket.message as WebsocketCollectionServerMessage);
  const status = useAppSelector((state) => state.websocket.status);
  const current_account = useAppSelector((state) => state.account.current_account);

  const public_key_hash_ref = useRef<PublicKeyHashType | null>(null);

  // Description: load accounts and urrent account from crypto (First onload event)
  useEffect(() => {
    handleInitConnectAccount();
    return () => {  // Destroy websocket State onleave
      dispatch(websocketActions.destroyWebsocket());
    };
  }, []);

  // Description: Responds to changes in message and websocket status
  useEffect(() => {
    const next = step + 1;
    setStep(status === WebsocketStatus.disconnected ? TransferAccountSteps.DISCONNECTED :
      (!!message && !!message.data) ? handleMapSteps(message.data, next) : next);
  }, [message, status]);

  // Description: Update the login status when current account is set in state
  useEffect(() => {
    (!!current_account) && loginComplete(current_account, account_types.full);
  }, [current_account]);


  // Description: Analizes incoming message and current step 
  // Handle action steps based on server responses
  function handleMapSteps(data: any, next: number): number {
    const current_step = !!data.accept_protocols ? TransferAccountSteps.SEND_ACTION_CONNECT :
      instanceofKeyTransfer(data) ? TransferAccountSteps.SEND_KEY_TRANSFER :
        instanceofTransferCredentials(data) ? TransferAccountSteps.SEND_COMPLETE_LOGIN :
          !!data.user_id ? TransferAccountSteps.SET_PIN_CONFIRMATION_KEY_TRANSFER : next;

    //  STEP 3 : receives messages: with accept_protocols and 
    if (current_step === TransferAccountSteps.SEND_ACTION_CONNECT) {
      DeviceRequests.handleConnectMessage(data.accept_protocols);
    }

    // STEP 4 : DeviceRequests.handleKeyTransferMessage - Send public key hash to server "action": "key_transfer"
    if (current_step === TransferAccountSteps.SEND_KEY_TRANSFER) {
      const _public_key_hash = { public_key_hash: data.public_key_hash, protocol_version: data.protocol_version };
      public_key_hash_ref.current = _public_key_hash;
      DeviceRequests.handleKeyTransferMessage(_public_key_hash);
    }

    // STEP 5: Sets Pin change Screen
    if (current_step === TransferAccountSteps.SET_PIN_CONFIRMATION_KEY_TRANSFER && !!data.message) {
      const _public_key_hash = Object.assign({}, public_key_hash_ref.current, { other_public_key_hash: data.message });
      public_key_hash_ref.current = _public_key_hash;
      DeviceRequests.handleVerifyHashCompleteHandshake(_public_key_hash);
    }

    // LOGIN WITH data
    if (current_step === TransferAccountSteps.SEND_COMPLETE_LOGIN) {
      DeviceRequests.handleSuccess(data);
    }
    return current_step;
  }

  /* 
  *   Description: Initialize Component:
  *            - Get user ID from QS and pass it to the email input
  *            - Sets up KeyExchangeManager Instance
  */
  function handleInitConnectAccount() {
    const query = searchParams.get(QueryParamKeys.USER_ID_QUERY_KEY);
    const query_user_id = normalizeQueryUserId(query);
    !!query_user_id && setUserId(query_user_id);
    if (!!query) {
      searchParams.delete(QueryParamKeys.USER_ID_QUERY_KEY);
      setSearchParams(searchParams);
    }
    setKeyExchangeManager(KeyExchangeManager.initKeyExchangeManager());
  }

  // Description: Render forms conditioanally
  function ConnectAccountPanels() {
    switch (step) {
      case TransferAccountSteps.ERROR:
        return <ErrorPanel user_id={user_id} handleAction={handlePageActions} />;
      case TransferAccountSteps.DISCONNECTED:
        return <>
          <Card.Img variant="top" src={connect_img} />
          <Card.Body>
            <DefaultPanel handleAction={handlePageActions} user_id={user_id} />
          </Card.Body>
        </>;
      case TransferAccountSteps.INITIALIZE_CONNECTION:
      case TransferAccountSteps.GET_ACCEPTED_PROTOCOL_VERSIONS:
      case TransferAccountSteps.SEND_ACTION_CONNECT: // send: action: "connect" ...
      case TransferAccountSteps.GET_HASH:  // gets data: protocol_version, public_key_hash
      case TransferAccountSteps.GET_PUBLIC_KEY: // gets data: message, user_id
      case TransferAccountSteps.SEND_KEY_TRANSFER: // gets data: message, user_id
        return <>
          <Card.Img variant="top" src={connect_img} />
          <Card.Body>
            <AwaitConnection handleAction={handlePageActions} />
          </Card.Body>
        </>;
      case TransferAccountSteps.SET_PIN_CONFIRMATION_KEY_TRANSFER:
        return <Card.Body className="p-4">
          {!!pin ? <ConnectionEstablished pin={pin} handleAction={handlePageActions} /> : <Loading className="in-place" />}
        </Card.Body>;
      default:
        return <Loading className="in-place" />;
    }
  }

  // Description: Handle login from message and data passed
  async function handleLogin(decrypted_user_key: string, data: TransferAccountCredentials) {
    const device_key = key_exchange_manager?.proposed_device?.device_key;
    const user_key = await key_exchange_manager?.getDeserializeUserKey(decrypted_user_key);
    if (!!device_key && !!user_key) {
      const account_identifier: AccountIdentifiers = {
        user_id: data.user_id,
        user_key,
        device_id: data.device.device_id,
        device_key
      };
      dispatch(accountActions.findCollectionServerCurrentUser(account_identifier));
    } else {
      throw (new Error("Failed to log in"));
    }
  }

  /**
   * Description: Handles Page level actions
   * Steps: 
   * action: connect_handshake - data.accept_protocols // step 1
   * action: connect - data.protocol_version public_key_hash  // step 2a
   *                 data.user_id message // step 2b
   * action: key_transfer - data.user_id message // step 3a
   *                        data instanceofTransferCredentials => true
   */
  const DeviceRequests = {
    // Description: Initialize the connection
    handleInitWebsocketConnection: (params: { user_id: string }) => {
      const _user_id = params.user_id;
      if (!!_user_id) {
        const host = `${process.env.REACT_APP_BACKEND_WEBSOCKET_SERVER}/${WebsocketConnectionRoutes.relay_ws}`;
        const init = {
          action: TransferAccountActions.ConnectHandshake,
          type: TransferAccountTypes.TransferKey,
          accept_protocols: KeyExchangeManager.supported_protocols,
          handshake_protocol: KeyExchangeManager.handshake_protocol,
          user_id: _user_id,
          connect_to: _user_id
        };
        dispatch(websocketActions.wsConnect({ host, init }));
        setUserId(_user_id);
      } else {
        DeviceRequests.handlePageErrorMessage({ message: AccountErrorMessages.default, stack: { error: AccountErrorMessages.missing_user_id } });
      }
    },

    // Description: Send Step 2 message after Accepted_protocols has been returned from initial Handshake
    handleConnectMessage: (accepted_protocols: number[]) => {
      if (!!key_exchange_manager) {
        key_exchange_manager.getProtocol(accepted_protocols).then((protocol: number) => {
          setProtocol(protocol);
          key_exchange_manager.getPublicKeyHash()
            .then((public_key_hash: string) => {
              const params = {
                action: TransferAccountActions.Connect,
                type: TransferAccountTypes.TransferKey,
                protocol_version: KeyExchangeManager.current_protocol,
                public_key_hash,
                user_id
              };
              dispatch(websocketActions.wsOnSendMessage(JSON.stringify(params)));
            });
        });
      } else {
        DeviceRequests.handlePageErrorMessage({
          message: AccountErrorMessages.default,
          stack: { error: AccountErrorMessages.kss_undefined }
        });
      }
    },

    // Description: Send Step 3 message after publicKeyHash and other_public_key has been returned from step 2
    handleKeyTransferMessage: (publicKeyHash: PublicKeyHashType): void => {
      if (!!key_exchange_manager) {
        key_exchange_manager.preparePublicKey()
          .then((_public_key_object: { device_req: string; message: string; signature: string }) => {
            const { device_req, message, signature } = _public_key_object;
            const params = {
              action: TransferAccountActions.KeyTransfer,
              user_id,
              device_req,
              message,
              signature
            };
            dispatch(websocketActions.wsOnSendMessage(JSON.stringify(params)));
          });
      } else {
        DeviceRequests.handlePageErrorMessage({
          message: AccountErrorMessages.default,
          stack: { error: AccountErrorMessages.kss_transfer_undefined }
        });
      }
    },

    handleVerifyHashCompleteHandshake: (publicKeyHash: PublicKeyHashType): void => {
      const { public_key_hash, other_public_key_hash } = publicKeyHash;
      if (!!key_exchange_manager && !!other_public_key_hash && !!public_key_hash && protocol > 0) {
        key_exchange_manager.verifyHashAndCompleteHandshake(other_public_key_hash, public_key_hash, protocol)
          .then((_pin: string | null) => {
            !!_pin ? setPin(_pin) : console.error(new Error("Could not generate pin"));
          });
      } else {
        DeviceRequests.handlePageErrorMessage({
          message: AccountErrorMessages.default,
          stack: { error: AccountErrorMessages.kss_handshake_undefined }
        });
      }
    },

    handleSuccess: (data: TransferAccountCredentials): void => {
      if (!!key_exchange_manager && !!data.message) {
        key_exchange_manager.decryptMessage(data.message, protocol)
          .then((user_key: string) => {
            setPin(undefined);
            handleLogin(user_key, data);
          });
      } else {
        DeviceRequests.handlePageErrorMessage({
          message: AccountErrorMessages.default,
          stack: { error: AccountErrorMessages.kss_success_undefined }
        });
      }
    },

    // Description: Reset message state and ws connection
    handleResetMessage: () => {
      dispatch(websocketActions.wsOnResetMessage());
    },

    // Description: Reset   ws connection
    handleDisconnect: () => {
      dispatch(websocketActions.wsDisconnect());
    },

    // Description: Reset page state and ws connection
    handleDestroy: () => {
      setStep(TransferAccountSteps.DISCONNECTED);
      setUserId(undefined);
      dispatch(websocketActions.destroyWebsocket());
    },

    handlePageErrorMessage: (params: { message: string, stack?: any, displayType?: MessageDisplayType }) => {
      const displayType = params.displayType || MessageHandlerDisplayType.toastr;
      dispatch(uiActions.handleRequestErrors(new Message(params.message, displayType), params.stack));
      setStep(TransferAccountSteps.ERROR);
    }
  };

  //  Description: Handle all actions from Children forms
  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    if ((DeviceRequests as any)[callback] instanceof Function) {
      (DeviceRequests as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(MessageAnchors.actionType, actionObj.actionType);
      DeviceRequests.handlePageErrorMessage({ message, stack: actionObj, displayType: MessageHandlerDisplayType.logger });
    }
  }

  return <Row className="justify-content-center">
    <Col md={7}>
      <h2>Login Using Another PreVeil Device</h2>
      <Card className="pv-card">
        <ConnectAccountPanels />
      </Card>
    </Col>
  </Row>;
}

export default React.memo(ConnectAccountComponent);
