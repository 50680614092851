import React, { useEffect, useState, createRef, ReactElement, useRef } from "react";
import { SplitPaneWidth, SplitPaneFullWidth, PanelStateType, PanelState, MobileMenuDisplay } from "src/common";
import LeftPane from "./LeftPane.component";
type AllProps = {
  left: ReactElement; // Children that will populate the Left Panel
  right: ReactElement; // Children that will populate the Right Panel
  panelState: PanelStateType;
  minWidth: number; // Width panelState off or Min width
  maxWidth: number; // Max width of the Left Panel
  onWidth: SplitPaneWidth; // Width panelState when it is opened / on or default
  offWidth: SplitPaneWidth; // Width panelState when it is closed / off
  toggleWidth?: number; // Optional: trigger TogglePanel when Left Nav reaches this width (or less)
  className?: string;
  togglePanel: (leftWidth?: number) => void;
};

function SplitPaneComponent(props: AllProps) {
  const {
    left,
    right,
    minWidth,
    maxWidth,
    onWidth,
    offWidth,
    toggleWidth,
    className,
    panelState,
    togglePanel
  } = props;
  const [dividerXPosition, setDividerXPosition] = useState<undefined | number>();
  const [leftWidth, setLeftWidth] = useState<SplitPaneWidth>(panelState === PanelState.on ? onWidth : offWidth);
  const [dragging, setDragging] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(MobileMenuDisplay);
  const splitPaneRef = createRef<HTMLDivElement>();

  // Description: Allow eventlisteners (resize) to have access to current states
  const activeDisabledRef = useRef(disabled);
  function setActiveDisabled(_disabled: boolean) {
    activeDisabledRef.current = _disabled;
    setDisabled(_disabled);
  }

  function onMouseDown(e: React.MouseEvent) {
    e.preventDefault();
    setDividerXPosition(e.clientX);
    setDragging(true);
  };

  function onTouchStart(e: React.TouchEvent) {
    setDividerXPosition(e.touches[0].clientX);
    setDragging(true);
  };

  function onMove(clientX: number, e?: MouseEvent) {
    if (!!dragging && !!dividerXPosition && !!leftWidth && leftWidth !== SplitPaneFullWidth) {
      !!e && e.preventDefault();
      const _new_left = Number(leftWidth) + clientX - dividerXPosition;
      setDividerXPosition(clientX);
      _adjustLeftNavigation(_new_left);
    }
  };

  function onMouseMove(e: MouseEvent) {
    onMove(e.clientX, e);
  };

  function onTouchMove(e: TouchEvent) {
    onMove(e.touches[0].clientX);
  };

  function onMouseUp() {
    setDragging(false);
  };

  // Description: Disable panel in mobile or resizing to small view
  function handleResize() {
    const _disabled = MobileMenuDisplay;
    if (_disabled !== activeDisabledRef.current) {
      setActiveDisabled(_disabled);
    }
  };

  // Custom Functions
  // ---------------------------------------------------------------------------
  // Description: Only set Toggle if toggleWidth is set otherwise it is disabled
  // if toggleWidth is not set, let parent handle the toggle conditions
  function _adjustLeftNavigation(new_left: number) {
    if (!!toggleWidth) {
      const toggleOff = panelState === PanelState.on && new_left <= toggleWidth;
      const toggleOn = panelState === PanelState.off && new_left > toggleWidth;
      (toggleOff || toggleOn) && togglePanel();
      if (new_left > minWidth && new_left <= maxWidth) {
        setLeftWidth(toggleOn ? minWidth : toggleOff ? onWidth : new_left);
      };
    } else { // Take into consideration Limits
      const _left_width = new_left <= minWidth ? minWidth : new_left >= maxWidth ? maxWidth : new_left;
      togglePanel(_left_width);
      setLeftWidth(_left_width);
    }
  }

  // Description: Add and remove event listeners for user actions
  function _addEventListeners() {
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("touchmove", onTouchMove);
    document.addEventListener("mouseup", onMouseUp);
  }

  function _removeEventListeners() {
    document.removeEventListener("mousemove", onMouseMove);
    document.removeEventListener("touchmove", onTouchMove);
    document.removeEventListener("mouseup", onMouseUp);
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    !disabled && _addEventListeners();
    return () => {
      !disabled && _removeEventListeners();
    };
  });

  // Description: Adjust the Width when Parent changes the on/off state
  useEffect(() => {
    // const _onWidth = disabled ? SplitPaneFullWidth : onWidth;
    setLeftWidth(panelState === PanelState.on ? onWidth : offWidth);
  }, [panelState]);

  return <div className={`split-view ${panelState} ${className ?? ""}${dragging ? " dragging" : ""}`} ref={splitPaneRef}>
    <LeftPane leftWidth={leftWidth} setLeftWidth={setLeftWidth}>
      {left}
    </LeftPane>
    {
      disabled ? <div className="divider disabled"></div> :
        <div className="divider"
          onMouseDown={onMouseDown}
          onTouchStart={onTouchStart}
          onTouchEnd={onMouseUp} />
    }
    <div className="right-pane">{right}</div>
  </div>;
}

export default SplitPaneComponent;
