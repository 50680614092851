import React from "react";

function DrangAndDropComponent() {
  // Description: Headers
  const DrangAndDrop = () => {
    return (
      <>
        <h4>DRAG AND DROP</h4>
        <div className="draggable-container">
          <div className="draggable-container-cover"></div>
          <div className="draggable-container-text">
            <div>Drag .CSV file here</div>
            <div>
              <i>or</i>
            </div>
            <div>Click to choose file</div>
          </div>
        </div>
      </>
    );
  };

  return <DrangAndDrop />;
};

export default React.memo(DrangAndDropComponent);
