import React from "react";
import { ActionHandlerFunction } from "@preveil-api";
import { AdminUIActionTypes } from "src/common";
import { Icon } from "src/components";
import { Card, Button } from "react-bootstrap";


type AllProps = {
  handleAction: ActionHandlerFunction;
}

function EmptyViewCardComponent(props: AllProps) {
  const { handleAction } = props;

  return (
    <Card className="admin-card">
      <Icon className="trusted-community" />
      <Card.Body>
        <Card.Title as="p">Create a Group Email</Card.Title>
        <Card.Text as="p">
          <span className="text-muted">Learn More:</span>{" "}
          <a href="https://www.preveil.com">preveil.com/support</a>
        </Card.Text>
        <Button variant="primary" onClick={() => handleAction({ actionType: AdminUIActionTypes.ShowModal })}>
          <Icon className="icon-plus2" />
          Create Group Email
        </Button>
      </Card.Body>
    </Card>
  );
}

export default React.memo(EmptyViewCardComponent);

