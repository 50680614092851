import React, { useState, Dispatch, SetStateAction, useEffect } from "react";
import { Button } from "react-bootstrap";
import { UserProfile } from "@preveil-api";
import {
  Account, useAppSelector, usePostUsersFindMutation, RegexHelper, Message, SettingsErrorMessages, SettingsSuccessMessages,
  useAppDispatch, useChangeApprovalGroupMutation, useCreateApprovalGroupMutation, CreateApprovalGroupApiResponse, ChangeApprovalGroupApiResponse,
  RecoveryGroup, UserRequest, ApprovalRequestStatus, SupportRoutes, MessageToastTypes, MessageHandlerDisplayType
} from "src/common";
import { uiActions } from "src/store";
import { OptionsAlert, AddUserForm, ApproversTable } from "..";

type AllProps = {
  disable_edits: boolean;
  setSubmittingForm: Dispatch<SetStateAction<boolean>>;
  optionals_required: number;
  setOptionalsRequired: React.Dispatch<React.SetStateAction<number>>;
  submitting_form: boolean;
  current_recovery_group?: RecoveryGroup;
  setCurrentRecoveryGroup: React.Dispatch<React.SetStateAction<RecoveryGroup | undefined>>;
  pending_requests: UserRequest[];
  setPendingRequests: React.Dispatch<React.SetStateAction<UserRequest[]>>;
};

function RecoveryGroupFormComponent(props: AllProps) {
  const dispatch = useAppDispatch();
  const {
    current_recovery_group,
    setCurrentRecoveryGroup,
    disable_edits,
    setSubmittingForm,
    optionals_required,
    setOptionalsRequired,
    submitting_form,
    pending_requests,
    setPendingRequests,
  } = props;
  const [new_user, setNewUser] = useState<string>("");
  const [edited, setEdited] = useState<boolean>(false);
  const current_account = useAppSelector((state) => state.account.current_account);
  const user_id = current_account?.user_id;
  const [findUsers] = usePostUsersFindMutation();
  const [createApprovalGroup] = useCreateApprovalGroupMutation();
  const [changeApprovalGroup] = useChangeApprovalGroupMutation();
  const [editing, setEditing] = useState<boolean>(false);
  const [editing_users, setEditingUsers] = useState<UserProfile[]>(new Array<UserProfile>());

  /* Description: when current_recovery_group is populated/changed - set whether the user can edit it or not
  depending on the number of optional users and whether the user is in an organization. (Users in organizations)
  cannot edit their recovery group. */
  useEffect(() => {
    if (
      !!current_recovery_group &&
      !!current_recovery_group.optional_users &&
      current_recovery_group.optional_users.length > 0
    ) {
      setEditing(false);
    } else {
      setEditing(true);
    }
  }, []);

  /* Description: Sets editing to true. If there is an existing recovery group, the editing users array will be set to the optional users
  // of the existing recovery group and similarly for optionals_required. */
  function editGroup() {
    setEditing(true);
    if (!!current_recovery_group) {
      setEditingUsers(current_recovery_group.optional_users);
      setOptionalsRequired(current_recovery_group.optionals_required);
    }
  }

  /* Description: Confirmation Modal for making changes to recovery group. Uses the MessageHandler component. */
  function confirmEdit() {
    const confirmation_dialog = new Message(
      "Are you sure you want to make changes to your recovery group? This will require approval from the current members of the group.",
      MessageHandlerDisplayType.confirm,
      MessageToastTypes.primary,
      "Change Recovery Group?",
      {
        label: "Yes",
        data: true,
        action: editGroup,
      },
      { label: "No" },
    );
    dispatch(uiActions.handleSetMessage(confirmation_dialog));
  }

  /* Description: On submit of form, handle errors with invalid email, existing user, or adding yourself
   If no errors call getUser(), Set the user back to empty string. */
  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      const email = form.new_user.value.toLowerCase();
      if (!RegexHelper.testEmailAddress(email)) {
        dispatch(uiActions.handleRequestErrors(new Message(SettingsErrorMessages.invalid_email)));
      } else if (email === user_id) {
        dispatch(
          uiActions.handleRequestErrors(new Message(SettingsErrorMessages.cannot_add_yourself)),
        );
      } else if (!!editing_users.filter((user) => user.address === email)[0]) {
        dispatch(uiActions.handleRequestErrors(new Message(SettingsErrorMessages.existing_user)));
      } else {
        getUser(email);
      }
      setNewUser("");
    }
  }

  /* Description: Removes the user from the editing list and updates the optionals required number. */
  function removeUser(user: UserProfile) {
    const updated_recovery_users = editing_users.filter((u) => u.address !== user.address);
    const updated_optionals_required =
      updated_recovery_users.length - 1 > 0 ? updated_recovery_users.length - 1 : 1;
    setEdited(true);
    setEditingUsers(updated_recovery_users);
    setOptionalsRequired(updated_optionals_required);
  }

  /* Description: Gets the user object from the backend based on a given user_id. Adds that user to the editing user list. */
  async function getUser(user_id: string) {
    if (!!current_account) {
      findUsers({
        account_ids: Account.getAccountIdentifiers(current_account),
        body: { spec: [{ user_id, key_version: -1 }] },
      })
        .unwrap()
        .then(({ users }) => {
          let new_user: UserProfile | undefined;
          const u = users.find((u) => u.user_id === user_id.toLowerCase());
          if (!!u) {
            new_user = { name: u.display_name, address: u.user_id };
            const updated_recovery_users = editing_users.concat([new_user]);
            const updated_optionals_required =
              updated_recovery_users.length - 1 > 0 ? updated_recovery_users.length - 1 : 1;
            setEditingUsers(updated_recovery_users);
            setOptionalsRequired(updated_optionals_required);
            !edited && setEdited(true);
          } else {
            dispatch(uiActions.handleRequestErrors(new Message(`${user_id} is not on PreVeil`)));
          }
        })
        .catch((msg: unknown) => {
          dispatch(
            uiActions.handleRequestErrors(new Message(SettingsErrorMessages.invalid_user), msg),
          );
        });
    }
  }

  /* Description: Sets editing to false and saves the recovery group */
  async function saveRecoveryGroup() {
    if (!!disable_edits) {
      return;
    }
    setSubmittingForm(true);
    setEdited(false);
    if (!!current_account) {
      let query;
      if (
        !current_recovery_group ||
        [...current_recovery_group.required_users, ...current_recovery_group.optional_users]
          .length === 0
      ) {
        query = createApprovalGroup({
          userId: current_account.user_id,
          requiredUsers: [],
          optionalUsers: editing_users.map((user) => user.address),
          optionalsRequired: optionals_required,
        });
      } else {
        query = changeApprovalGroup({
          userId: current_account.user_id,
          requiredUsers: [],
          optionalUsers: editing_users.map((user) => user.address),
          optionalsRequired: optionals_required,
        });
      }
      query
        .unwrap()
        .then((response: CreateApprovalGroupApiResponse | ChangeApprovalGroupApiResponse) => {
          setSubmittingForm(false);
          const recovery_group =
            response.request.status === ApprovalRequestStatus.approved
              ? new RecoveryGroup(
                  current_account.user_id,
                  [],
                  editing_users,
                  response.request.metadata.approval_group.optionals_required,
                )
              : current_recovery_group;
          if (!!recovery_group) {
            setCurrentRecoveryGroup(recovery_group);
          }
          const requests =
            response.request.status === ApprovalRequestStatus.pending &&
            !!response.request.request_id
              ? pending_requests.concat([
                  new UserRequest(
                    response.request.request_id,
                    response.request.metadata,
                    response.request.payload,
                    response.request.status,
                    response.request.requester,
                    editing_users,
                    { name: current_account.display_name, address: current_account.user_id },
                  ),
                ])
              : pending_requests;
          if (!!requests) {
            setPendingRequests(requests);
          }
          dispatch(
            uiActions.handleSetMessage(new Message(SettingsSuccessMessages.recovery_group_saved)),
          );
          setEditing(false);
        })
        .catch((msg: unknown) => {
          setSubmittingForm(false);
          dispatch(
            uiActions.handleRequestErrors(
              new Message(SettingsErrorMessages.error_saving_recovery_group),
              msg,
            ),
          );
        });
    }
  }

  return (
    <div className="max-700">
      <h3 className="text-muted">Approvers</h3>
      {editing && editing_users.length < 3 && (
        <p>
          Please add <u>three</u> or more people to your list of approvers.
        </p>
      )}
      <div className="settings-table">
        <ApproversTable
          editing={editing}
          editing_users={editing_users}
          current_recovery_group={current_recovery_group}
          removeUser={removeUser}
        ></ApproversTable>
        {editing && (
          <>
            {editing_users.length === 0 && (
              <p className="text-muted p-3 m-0">
                Only PreVeil members can be part of your Recovery Group, and you should only add
                people you trust.{" "}
                <a href={SupportRoutes.recovery_group} target="_blank" rel="noreferrer">
                  Click here{" "}
                </a>
                to learn more about Recovery Groups.
              </p>
            )}
            {!disable_edits && (
              <AddUserForm
                handleSubmit={handleSubmit}
                new_user={new_user}
                setNewUser={setNewUser}
              ></AddUserForm>
            )}
          </>
        )}
      </div>
      <OptionsAlert
        editing={editing}
        optionals_required={optionals_required}
        setOptionalsRequired={setOptionalsRequired}
        editing_users={editing_users}
        current_recovery_group={current_recovery_group}
      ></OptionsAlert>
      {!editing && !disable_edits && !submitting_form && (
        <Button className="btn-info" onClick={() => confirmEdit()}>
          Change
        </Button>
      )}
      {editing && !disable_edits && !submitting_form && (
        <>
          <Button
            variant="primary"
            onClick={() => saveRecoveryGroup()}
            disabled={editing_users.length === 0 || !edited}
          >
            Save
          </Button>{" "}
          <Button
            variant="no-outline-primary"
            onClick={() => setEditing(false)}
            disabled={!current_recovery_group || !current_recovery_group.group_set}
          >
            Cancel
          </Button>
        </>
      )}
    </div>
  );
}

export default React.memo(RecoveryGroupFormComponent);
