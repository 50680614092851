import React, { useState, useEffect } from "react";
import { ActionHandlerFunction } from "@preveil-api";
import { Account, CollectionEntity, DriveUIActionTypes, NodePermissionSet, EntryItem } from "src/common";
import { NewShare, AccessList } from ".";

type AllProps = {
  current_account: Account;
  collection_info: CollectionEntity;
  entry: EntryItem;
  action?: string;
  handleAction: ActionHandlerFunction;
};

function ShareDetailsComponent(props: AllProps) {
  const { current_account, collection_info, entry, action, handleAction } = props;
  const [shared_with, setSharedWith] = useState<NodePermissionSet[]>(collection_info.shared_with);
  const [edit_mode, setEditMode] = useState<boolean>(action === DriveUIActionTypes.Share);
  const [edit_readonly, setEditReadonly] = useState<boolean>(false);

  useEffect(() => {
    (action === DriveUIActionTypes.Share && !edit_mode) &&
      setEditMode(true);
  }, [action]);

  useEffect(() => {
    setSharedWith(collection_info.shared_with);
  }, [collection_info.shared_with]);

  // NOTE: Only allow the share editor if permissions for collection are either write or share (Edit or Edit&Share)
  return <>
    {
      <div className={`drive-share pt-3${edit_readonly ? " readonly" : ""}`}>
        {
          (!!edit_mode) &&
          <NewShare
            entry={entry}
            collection_info={collection_info}
            current_account={current_account}
            setEditMode={setEditMode}
            setEditReadonly={setEditReadonly}
            handleAction={handleAction} />
        }
        <AccessList
          collection_info={collection_info}
          shared_with={shared_with}
          current_account={current_account}
          edit_mode={edit_mode}
          setEditMode={setEditMode}
          edit_readonly={edit_readonly}
          handleAction={handleAction} />
      </div>
    }
  </>;
}

export default React.memo(ShareDetailsComponent);
