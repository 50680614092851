import React from "react";
import { Button } from "react-bootstrap";
import { Icon } from "src/components";

function ButtonsComponent() {

  // Description: Buttons Introduction and references
  const ButtonsIntro = () => {
    return <>
      <h4>BUTTONS</h4>
      <p>Default buttons available:</p>
      <ul className="list-unstyled">
        <li>
          <code>
            <p>Colors (9 + outlines): primary, secondary, info, success, warning, danger, light, dark, link </p>
            <p>Outlines same colors</p>
            <p>Sizes (3 sizes): default, sm, lg </p>
            <p>Action buttons for toolbar on tables</p>
          </code>
        </li>
        <li className="mt-4">
          <Button variant="primary">Primary</Button>{" "}
          <Button variant="secondary">Secondary</Button>{" "}
          <Button variant="success">Success</Button>{" "}
          <Button variant="warning">Warning</Button>{" "}
          <Button variant="danger">Danger</Button> <Button variant="info">Info</Button>{" "}
          <Button variant="light">Light</Button> <Button variant="dark">Dark</Button>{" "}
          <Button variant="link">Link</Button>
        </li>
        <li className="mt-4">
          <Button variant="outline-primary">Primary</Button>{" "}
          <Button variant="outline-secondary">Secondary</Button>{" "}
          <Button variant="outline-success">Success</Button>{" "}
          <Button variant="outline-warning">Warning</Button>{" "}
          <Button variant="outline-danger">Danger</Button>{" "}
          <Button variant="outline-info">Info</Button>{" "}
          <Button variant="outline-light">Light</Button>{" "}
          <Button variant="outline-dark">Dark</Button>{" "}
          <Button variant="no-outline-primary">Primary</Button>{" "}
        </li>
        <li className="mt-4">
          <Button variant="primary"><Icon className="pv-icon-add" />Primary</Button>{" "}
          <Button variant="secondary"><Icon className="pv-icon-mark-read" /> Secondary</Button>{" "}
          <Button variant="outline-primary"><Icon className="pv-icon-add" />Primary</Button>{" "}
          <Button variant="outline-secondary"><Icon className="pv-icon-mark-read" /> Secondary</Button>{" "}
        </li>
        <li className="mt-4">
          <Button variant="primary"><Icon className="icon-plus2" />Primary</Button>{" "}
          <Button variant="secondary"><Icon className="icon-checkmark" /> Secondary</Button>{" "}
          <Button variant="outline-primary"><Icon className="icon-plus2" />Primary</Button>{" "}
          <Button variant="outline-secondary"><Icon className="icon-minus2" /> Secondary</Button>{" "}
        </li>
        <li className="mt-4">
          <code>Add Class <b>"btn-icon"</b></code>
          <Button variant="primary"><Icon className="icon-plus2" />Sample</Button>{" "}
          <Button variant="primary" className="btn-icon"><Icon className="pv-icon-refresh" /></Button>{" "}
          <Button variant="secondary" className="btn-icon"><Icon className="pv-icon-trash" /></Button>{" "}
          <Button variant="outline-primary" className="btn-icon"><Icon className="pv-icon-move" /></Button>{" "}
          <Button variant="outline-secondary" className="btn-icon"><Icon className="pv-icon-new-folder" /></Button>{" "}
        </li>
        <li className="mt-4">
          <Button variant="primary"><Icon className="icon-plus2" />Sample</Button>{" "}
          <Button variant="primary" className="btn-icon"><Icon className="icon-plus2" /></Button>{" "}
          <Button variant="secondary" className="btn-icon"><Icon className="icon-minus2" /> </Button>{" "}
          <Button variant="outline-primary" className="btn-icon"><Icon className="icon-plus2" /></Button>{" "}
          <Button variant="outline-secondary" className="btn-icon"><Icon className="icon-minus2" /> </Button>{" "}
        </li>
        <li className="mt-4">
          <code>Button smalls - with <b>"btn-icon"</b></code>
          <Button variant="primary" size="sm" ><Icon className="icon-plus2" />Sample</Button>{" "}
          <Button variant="primary" className="btn-icon" size="sm"><Icon className="pv-icon-refresh" /></Button>{" "}
          <Button variant="secondary" className="btn-icon" size="sm"><Icon className="pv-icon-trash" /></Button>{" "}
          <Button variant="outline-primary" className="btn-icon" size="sm"><Icon className="pv-icon-move" /></Button>{" "}
          <Button variant="outline-secondary" className="btn-icon" size="sm"><Icon className="pv-icon-new-folder" /></Button>{" "}
          <Button variant="primary" className="btn-icon" size="sm"><Icon className="icon-plus2" /></Button>{" "}
          <Button variant="secondary" className="btn-icon" size="sm"><Icon className="icon-minus2" /> </Button>{" "}
          <Button variant="outline-primary" className="btn-icon" size="sm"><Icon className="icon-plus2" /></Button>{" "}
          <Button variant="outline-secondary" className="btn-icon" size="sm"><Icon className="icon-minus2" /> </Button>{" "}
          <Button variant="outline-secondary" className="btn-icon" size="sm"><Icon className="ficon-download" /> </Button>{" "}
          <Button variant="outline-secondary" className="btn-icon" size="sm"><Icon className="ficon-lock" /> </Button>{" "}
          <Button variant="transparent" className="btn-icon" size="sm"><Icon className="pv-icon-info" /> </Button>{" "}
          <Button variant="transparent" className="btn-icon" size="sm"><Icon className="ficon-eye" /> </Button>
        </li>
        <li className="my-4">
          <Button variant="primary" size="lg">Large button</Button>{" "}
          <Button variant="secondary" size="lg">Large button</Button>{" "}
          <Button variant="primary" size="sm">Small button</Button>{" "}
          <Button variant="secondary" size="sm">Small button</Button>{" "}
        </li>
        <li>
          more: <a target="_blank" rel="noreferrer" href="https://react-bootstrap.github.io/components/buttons/">React Bootstrap Buttons</a>
        </li>
        <li>
          <a target="_blank" rel="noreferrer" href="https://react-bootstrap.github.io/components/button-group/">React Bootstrap Button Groups</a>
        </li>
        <li>
          <a target="_blank" rel="noreferrer" href=" https://react-bootstrap.github.io/components/badge/">React Bootstrap Badges</a>
        </li>
      </ul>
    </>;
  };

  return <ButtonsIntro />;
}

export default React.memo(ButtonsComponent);
