import React from "react";
import { Row, Col, Button, OverlayTrigger, Popover } from "react-bootstrap";
import { Icon } from "src/components";

function PopoversComponent() {

  const Popovers = () => {
    return (
      <>
        <h4>Popovers</h4>
        <Row>
          <Col>
            <OverlayTrigger
              trigger={["click"]}
              placement="bottom-end"
              overlay={
                <Popover>
                  <Popover.Body>
                    <p>When PreVeil Drive folders are shared with others, this setting
                      defines the default behavior for file sync.</p>
                    <p>Sync means shared folders will sync to user devices’ Drive folders and file
                      explorer.</p>
                    <p>No-sync means shared folders will remain in the
                      PreVeil cloud and are visible in the PreVeil browser app only.</p>
                  </Popover.Body>
                </Popover>
              }
            >
              <Button variant="transparent" size="sm" className="btn-icon popover-button"><Icon className="pv-icon-info" /> </Button>
            </OverlayTrigger>
          </Col>
          <Col>
            <OverlayTrigger
              trigger={["click", "hover"]}
              placement="right"
              overlay={
                <Popover>
                  <Popover.Body>
                    <p>When PreVeil Drive folders are shared with others, this setting
                      defines the default behavior for file sync.</p>
                  </Popover.Body>
                </Popover>
              }  >
              <Button variant="transparent" size="sm" className="btn-icon popover-button"><Icon className="pv-icon-info" /> </Button>
            </OverlayTrigger>
          </Col>
          <Col>
            <OverlayTrigger
              trigger={["click", "hover"]}
              placement="left"
              overlay={
                <Popover>
                  <Popover.Body>
                    <p>When PreVeil Drive folders are shared with others, this setting
                      defines the default behavior for file sync.</p>
                  </Popover.Body>
                </Popover>
              }
            >
              <Button variant="transparent" size="sm" className="btn-icon popover-button"><Icon className="pv-icon-info" /> </Button>
            </OverlayTrigger>
          </Col>
          <Col>
            <OverlayTrigger
              trigger={["click"]}
              placement="top"
              overlay={
                <Popover>
                  <Popover.Body>
                    <p>When PreVeil Drive folders are shared with others, this setting
                      defines the default behavior for file sync.</p>
                  </Popover.Body>
                </Popover>
              }
            >
              <Button variant="transparent" size="sm" className="btn-icon popover-button"><Icon className="pv-icon-info" /> </Button>
            </OverlayTrigger>
          </Col>
        </Row>
      </>
    );
  };

  return <Popovers />;
};

export default React.memo(PopoversComponent);
