import React from "react";
import { Button, Alert, Toast } from "react-bootstrap";
import { UserRequest, RecoveryGroup, ApprovalRequestStatus } from "src/common";

type AllProps = {
  current_recovery_group?: RecoveryGroup;
  disable_edits: boolean;
  pending_requests: UserRequest[];
  goToPendingRequests: Function;
};

/* Description: Form for adding users to the editing list for recovery group. */
function NotificationsComponent(props: AllProps) {
  const { current_recovery_group, disable_edits, pending_requests, goToPendingRequests } = props;

  /* Description: Returns number of pending requests that a user has. Used for Toast Message. */
  function getPendingRequests(): number {
    if (pending_requests.length) {
      return pending_requests.filter((request) => request.status === ApprovalRequestStatus.pending).length;
    } else {
      return 0;
    }
  };

  return (
    <>
      {!current_recovery_group ||
        (!current_recovery_group.group_set && (
          <Alert variant="primary" className="mb-4 max-700">
            <Alert.Heading>
              {!disable_edits && pending_requests.length === 0
                ? "Protect your account against loss or theft."
                : "Your recovery group is not set up"}
            </Alert.Heading>
            <p>
              {!disable_edits && pending_requests.length === 0
                ? "Set a Recovery Group to regain access to your account even if you don't have any of your devices."
                : "Please contact your administrator to set up your approval group."}
            </p>
          </Alert>
        ))}
      {getPendingRequests() !== 0 && (
        <Toast bg="warning" show={true} className="mb-3 d-inline-block mt-0 max-700">
          <Toast.Body>
            Updates to this group are awaiting approval
            <Button
              className="notification-count yellow float-end"
              aria-label="updates"
              onClick={() => goToPendingRequests()}
            >
              {getPendingRequests()} Update{getPendingRequests() > 1 ? "s" : ""}
            </Button>
          </Toast.Body>
        </Toast>
      )}
    </>
  );
}

export default React.memo(NotificationsComponent);