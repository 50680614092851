import React, { useEffect } from "react";
import { useCountdown } from "src/common";

type AllProps = {
  is_lock?: boolean;
  expires: string;
  handleTimeExpired: () => void;
}

// Description: Displays counter in mm:ss format for right now. Capable of other formats *****  working *****
function CountdownDisplayComponent(props: AllProps) {
  const { is_lock, expires, handleTimeExpired } = props;
  const [days, hours, minutes, seconds] = useCountdown(expires);

  useEffect(() => {
    (Number(days) + Number(hours) + Number(minutes) + Number(seconds)) <= 0 && handleTimeExpired();
  }, [seconds]);

  function parseTimeDisplay(value: number): string {
    return !isNaN(value) ? (value >= 10 ? value.toString() : `0${value}`) : "00";
  }

  function validateTime(): boolean {
    return !isNaN(minutes) || !isNaN(seconds);
  }

  return validateTime() ? (
    !!is_lock ? (
      <div className="side-bar-counter">
        <div>
          <label>{parseTimeDisplay(hours)}</label>
          <span>Hrs</span>
        </div>
        <span>:</span>
        <div>
          <label>{parseTimeDisplay(minutes)}</label>
          <span>Mins</span>
        </div>
        <span>:</span>
        <div>
          <label>{parseTimeDisplay(seconds)}</label>
          <span>Secs</span>
        </div>
      </div>
    ) : (
      <span className="no-wrap"><b>{hours > 0 && `${parseTimeDisplay(hours)} : `}{parseTimeDisplay(minutes)} : {parseTimeDisplay(seconds)} minutes</b></span>
    )
  ) : (
    <small className="text-danger">not found</small>
  );
}

export default React.memo(CountdownDisplayComponent);
