import React, { ReactNode, useState } from "react";
import { Button, Collapse } from "react-bootstrap";
import { Icon, InfoPopover } from "src/components";

type AllProps = {
  title: string;
  children?: ReactNode;
  popover?: string;
  refresh?: Function;
  isLoading?: boolean;
};

function CollapseWrapperComponent(props: AllProps) {
  const { children, title, popover, refresh, isLoading } = props;
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className="collapse-section">
      <h5>
        {title}
        {!!popover ? <InfoPopover message={popover} /> : null}
        <span className="ms-auto">
          {!!refresh && (
            <Button variant="icon" size="sm" onClick={() => refresh()} title="Refresh">
              {isLoading ? <i className="spinner"></i> : <Icon className="ficon-rotate-cw" />}
            </Button>
          )}
          <Button
            onClick={() => setOpen(!open)}
            tabIndex={-1}
            className="toggle-btn"
            variant="icon"
          >
            {!!open ? (
              <Icon className="ficon-chevron-up m-0" />
            ) : (
              <Icon className="ficon-chevron-down m-0" />
            )}
          </Button>
        </span>
      </h5>
      <Collapse in={open}>
        <div>{children}</div>
      </Collapse>
    </div>
  );
}

export default CollapseWrapperComponent;
