import { KSSUser, DeviceBase, LogViewerKey, KSSPublicKeys, JSONExpressAccount } from "@preveil-api";
import { Helpers, KeyFactory, DevicePlatform, KeyStorageData, AppUserKey, Device, account_types } from "src/common";


const current_protocol_version = 1;
export class KeyStorageUser implements KSSUser {
  user_id: string = "";
  password: string = "";
  account_claim_receipt: string = "";
  wrapped_data: string = "";
  display_name: string | null = null;
  phone_number?: string;
  sms_code?: string;
  totp_code?: string;
  totp_secret?: string;
  auth_token?: string;
  protocol_version: number = 1;

  constructor(
    kss_user: KSSUser,
    readonly _user_key: AppUserKey,
    readonly _device_key: AppUserKey,
    readonly _log_viewer_key: AppUserKey,
    readonly _public_keys: KSSPublicKeys,
    readonly _device: DeviceBase,
    readonly _wrapped_log_viewer_private_key: LogViewerKey,
    readonly _secret: string
  ) {
    Object.assign(this, kss_user);
  }
  get user_key() {
    return this._user_key;
  }
  get device_key() {
    return this._device_key;
  }
  get log_viewer_key() {
    return this._log_viewer_key;
  }
  get public_keys() {
    return this._public_keys;
  }
  get device() {
    return this._device;
  }
  get wrapped_log_viewer_private_key() {
    return this._wrapped_log_viewer_private_key;
  }
  get secret() {
    return this._secret;
  }

  public setPhoneNumber(phone_number: string) {
    this.phone_number = phone_number;
  }

  public setSMSCode(sms_code: string) {
    this.sms_code = sms_code;
  }

  public setTOTPSecret(totp_secret: string) {
    this.totp_secret = totp_secret;
  }

  // Description: Create a KeyStorageUser with all the keys required to create an express acct
  // NOTES: Includes the public side of the user/device keys.
  // The frontend will generate a new log key as well, putting the public side in public_keys.log_viewer_key,
  // and the private side encrypted under the public side of the user key in wrapped_keys.wrapped_log_viewer_private_key.
  public static async initKeyStorageUser(kss_user: KSSUser, secret: string, key_version?: number): Promise<KeyStorageUser> {
    const protocol_version = !!kss_user.protocol_version ? kss_user.protocol_version : current_protocol_version;
    const user_key = await KeyFactory.newUserKey({ protocol_version, key_version: !!key_version ? key_version : 0 });

    // Handle Log viewer key
    const log_viewer_key = await KeyFactory.newUserKey();
    const wrapped_log_viewer_private_key = Helpers.b64Encode(await user_key.public_user_key.public_key.seal(log_viewer_key.serialize()));
    const log_viewer_private_key = { wrapped_log_viewer_private_key };

    // Handle device and keys:
    const device_key = await KeyFactory.newUserKey();
    const kss_device: DeviceBase = await Device.createNewJSONLocalDevice(DevicePlatform.express.text, "express", device_key);

    // Create auth token
    if (!!kss_user.password) {
      kss_user.auth_token = Helpers.b64Encode(await KeyStorageData.authenticationToken(kss_user.user_id, kss_user.password));
      kss_user = Object.assign({}, kss_user, {
        wrapped_data: await KeyStorageData.wrapKSSData(kss_user.password, user_key, device_key, protocol_version, kss_device.device_id),
        protocol_version
      });
    }

    // encrypt the serialized data with the pass and then base_64 encode
    const public_keys: KSSPublicKeys = {
      public_key: Helpers.b64Encode(user_key.public_user_key.serialize()),
      log_viewer_key: Helpers.b64Encode(log_viewer_key.public_user_key.serialize())
    };

    return new KeyStorageUser(kss_user, user_key, device_key, log_viewer_key, public_keys, kss_device, log_viewer_private_key, secret);
  }

  // Description: Returns object necessary for KSS requests
  public getKSSUser(): Partial<KSSUser> {
    return {
      user_id: this.user_id,
      auth_token: this.auth_token,
      wrapped_data: this.wrapped_data,
      protocol_version: this.protocol_version,
      account_claim_receipt: this.account_claim_receipt,
      phone_number: this.phone_number,
      totp_secret: this.totp_secret
    };
  }

  getJSONExpressAccount(): JSONExpressAccount {
    return {
      user_id: this.user_id,
      account_type: account_types.express,
      display_name: this.display_name || this.user_id,
      secret: this.secret,
      public_keys: this.public_keys,
      wrapped_keys: this.wrapped_log_viewer_private_key,
      device: this.device
    };
  }
}
