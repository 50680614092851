/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
// @ts-ignore
import { Key as KeyPB } from "../protos/keys_pb";

export enum EncryptionStyle {
  EMAIL,
  DRIVE
}

export abstract class PublicKey {
  constructor(
    public protocol_version: number,
    public public_key: Uint8Array
  ) {}

  public get public_buffer(): KeyPB {
    const pbIns = new KeyPB();
    pbIns.setProtocolVersion(this.protocol_version);
    pbIns.setKey(this.public_key);
    return pbIns;
  }

  abstract async seal(plaintext: Uint8Array): Promise<Uint8Array>;
}

export abstract class EncryptionKey extends PublicKey {
  constructor(
    protocol_version: number,
    public_key: Uint8Array,
    public private_key?: Uint8Array
  ) {
    super(protocol_version, public_key);
  }

  public get buffer(): KeyPB {
    const pbIns = new KeyPB();
    pbIns.setProtocolVersion(this.protocol_version);
    pbIns.setKey(this.private_key);
    return pbIns;
  }

  public serialize(): Uint8Array {
    return this.buffer.serializeBinary();
  }

  abstract clonePublic(): PublicKey;
  abstract async unseal(cipher: Uint8Array): Promise<Uint8Array>;
}
