import React, { useState, FocusEvent } from "react";
import { Card, Button, Form, Col, InputGroup } from "react-bootstrap";
import { RecoveryCodeInfo } from "@preveil-api";
import img from "src/assets/images/restore-account.svg";
import {
  AppUserKey,
  GetUserKeyApiResponse,
  Helpers,
  KeyFactory,
  Message,
  MessageHandlerDisplayType,
  SettingsErrorMessages,
  useAppDispatch,
  useAppSelector,
  useGetUserKeyMutation,
  usePutUsersBackupMutation,
  Account,
  RecoveryCode,
  PutUsersBackupApiResponse
} from "src/common";
import { Icon } from "src/components";
import { uiActions } from "src/store";

type AllProps = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setDownloadRecoveryCode: React.Dispatch<React.SetStateAction<RecoveryCodeInfo | undefined>>
};

function EmptyBackupComponent(props: AllProps) {
  const { setIsLoading, setDownloadRecoveryCode } = props;
  const [show_password, setShowPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [getUserKey] = useGetUserKeyMutation();
  const dispatch = useAppDispatch();
  const current_account = useAppSelector((state) => state.account.current_account);
  const user_id = current_account?.user_id || "";
  const [saveAccountBackup] = usePutUsersBackupMutation();

  /* Description: Saves the new recovery code to the backend. This will generate a pdf on success. */
  function saveBackup(new_recovery_code: RecoveryCode) {
    if (!!new_recovery_code.backup_public_key && !!current_account) {
      const account_ids = Account.getAccountIdentifiers(current_account);
      const params = {
        account_ids,
        body: {
          user_id: account_ids.user_id,
          backup_id: new_recovery_code.backup_id,
          backup_public_key: new_recovery_code.backup_public_key,
        },
      };
      saveAccountBackup(params)
        .unwrap()
        .then((response: PutUsersBackupApiResponse) => {
          !!response.errors
            ? dispatch(
              uiActions.handleRequestErrors(
                new Message(SettingsErrorMessages.error_creating_backup),
                response,
              ),
            )
            : setDownloadRecoveryCode({
              backup_id: new_recovery_code.backup_id,
              text_encoding: new_recovery_code.text_encoding,
            });
        })
        .catch((msg) => {
          dispatch(
            uiActions.handleRequestErrors(
              new Message(SettingsErrorMessages.error_creating_backup),
              msg,
            ),
          );
        });
    }
  };

  /* Description: Creates the new recovery code and then calls saveBackup for that recovery code. */
  function handleCreate() {
    const current_user_key = getCurrentUserKey();
    if (!!current_user_key) {
      setIsLoading(true);
      const newRecoveryCode = new RecoveryCode(user_id, current_user_key, password.trim());
      newRecoveryCode
        .createBackup()
        // Save to the backend and generate pdf on success/complete
        .then((new_recovery_code) => {
          if (!!new_recovery_code) {
            saveBackup(new_recovery_code);
          } else {
            dispatch(
              uiActions.handleRequestErrors(
                new Message(SettingsErrorMessages.error_creating_backup),
              ),
            );
          }
        });
    }
  };

  /* Description: Gets the users current user key. This is needed in order to create a new Recovery Code 
  (specifically used for creating the BackupPB.Data object in the backup.class.ts file) */
  async function getCurrentUserKey(): Promise<AppUserKey | undefined> {
    return await getUserKey({ userId: user_id })
      .unwrap()
      .then((params: GetUserKeyApiResponse) => {
        if (!!params.key) {
          const decoded_user_key = Helpers.b64Decode(params.key);
          return KeyFactory.deserializeUserKey(decoded_user_key);
        }
        return undefined;
      })
      .catch((msg) => {
        dispatch(
          uiActions.handleRequestErrors(
            new Message(
              SettingsErrorMessages.error_creating_backup,
              MessageHandlerDisplayType.logger,
            ),
            msg,
          ),
        );
        return undefined;
      });
  }

  return <>
    <Card.Img variant="top" src={img} />
    <Card.Body>
      <Card.Title>
        <b>Recover your account from a file or QR Code instead of a Recovery Group.</b>
      </Card.Title>
      <Card.Text>
        To protect your private PreVeil key from being lost, you can generate an individual QR
        code and key that can be used to recover your account.{" "}
      </Card.Text>
      <Card.Text>
        Print your recovery code and keep it somewhere safe or store the recovery file on a
        trusted device (not on your computer).{" "}
      </Card.Text>{" "}
      <Card.Text>
        In the future, if you lose access to all your PreVeil devices, you can recover your
        account and its data by using this information on a new device.
      </Card.Text>
      <label>Set a password to encrypt your recovery file</label>{" "}
      <span className="text-muted fs-6">(optional)</span>:
      <Form>
        <Form.Group as={Col} xs={6}>
          <Form.Label visuallyHidden>Enter the password for your recovery file </Form.Label>
          <InputGroup className="mb-3 mt-3">
            <Form.Control
              type={!!show_password ? "text" : "password"}
              onChange={(e: FocusEvent<HTMLInputElement>) => setPassword(e.currentTarget.value)}
              autoComplete="off"
              value={password}
              aria-describedby="passwordInput"
              autoFocus={true}
              aria-label="Backup Password Form"
            />
            <Button
              variant="transparent"
              className="btn-icon"
              size="sm"
              onClick={() => setShowPassword(!show_password)}
              aria-label="show password"
            >
              <span>
                <Icon className={!!show_password ? "ficon-eye" : "ficon-eye-off"} />
              </span>
            </Button>
          </InputGroup>
        </Form.Group>
        <Button variant="primary" onClick={handleCreate}>
          Create Recovery File
        </Button>
      </Form>
    </Card.Body>
  </>;
}

export default React.memo(EmptyBackupComponent);
