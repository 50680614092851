import { useState, useCallback, useRef } from "react";
import { MessageDisplayType, ErrorStackDataType } from "@preveil-api";
import {
  Account, DriveErrorMessages, Helpers, Message, MessageHandlerDisplayType, useAppDispatch, useSendRequestMutation, CollectionFilesyncAPI,
  DriveInvitationInfo, NodePermissions, dayjs, ErrorStackItem, getEnumKey
} from "src/common";
import { FSMessage, FSStatus } from "src/common/keys/protos/collections_pb";
import { ProcessingType } from "src/common/drive/types";
import { uiActions } from "src/store";
import _ from "lodash";

// Description: Hook for fetching folder invitations for a user in drive.
export function useGetInvitations(current_account: Account) {
  const [error, setError] = useState<boolean>(false);
  const [invitations, setInvitations] = useState<DriveInvitationInfo[]>();
  const [sendRequest] = useSendRequestMutation();
  const dispatch = useAppDispatch();
  const invitation_list_ref = useRef<DriveInvitationInfo[]>([]);
  const index_ref = useRef<number>(0);

  // Description: gets invitations list from backend, decrypts name for each entry and adds information to invitations list.
  async function getSharedWithMe() {
    const request = await CollectionFilesyncAPI.getSharedWithMe(current_account);
    async function callback(message: FSMessage) {
      message.getSharesList().length === 0 && setInvitations([]);
      _.forEach(message.getSharesList(), async (collection: FSMessage.Share) => {
        const collection_id = collection.getCollectionId_asB64();
        const share_id = collection.getId_asB64();
        const user_key_version = collection.getUserKeyVersion();
        let name: string = "";
        if (!!user_key_version || user_key_version === 0) {
          const user_key = current_account.getUserKeyWithVersion(user_key_version);
          name = !!user_key ? Helpers.utf8Decode(await user_key.encryption_key.unseal(collection.getEncCollectionName_asU8())) : "";
        }
        const sharer_id = collection.getSharerId();
        const sharer_user_id = !!sharer_id ? sharer_id : "";
        let v1_view_only = false;
        const permissions_list = collection.getRolesList().map((roleInfo) => {
          if (roleInfo.getViewOnly()) {
            v1_view_only = true;
          }
          const fs_role = roleInfo.getRole();
          const role = roleInfo.getViewOnly() ? 7 : !!fs_role ? fs_role : 1;
          return role;
        });
        const exp_time = collection.getExpirationTime();
        let expiration;
        if (exp_time) {
          const utcExpiration = new Date(exp_time);
          expiration = dayjs(utcExpiration).utc().local().format("MM/DD/YYYY HH:mm");
        } else {
          expiration = "--";
        }
        const grantee = {
          user_id: current_account.user_id,
          expiration,
          view_only: collection.hasAclViewOnly()
            ? collection.getAclViewOnly() || false
            : v1_view_only,
          pending: false,
          is_owner: false,
          acl: collection.getAclRolesList(),
          roles: permissions_list,
        };
        const permissions = new NodePermissions(grantee);
        const sync_by_default = false; // setting this to false since web preveil does not support the local sync feature.
        if (name.length > 0) {
          invitation_list_ref.current.push({
            collection_id,
            share_id,
            name,
            sharer_user_id,
            permissions_label: permissions.label,
            sync_by_default,
            processing: ProcessingType.not_processing,
            expiration,
          });
        } else {
          handlePageErrorMessage(DriveErrorMessages.error_decrypting_name, {
            stack_message: DriveErrorMessages.error_decrypting_name,
            stack_error: { user_key_version: collection.getUserKeyVersion() },
          });
        }
        index_ref.current++;
        index_ref.current === message.getSharesList().length && setInvitations(invitation_list_ref.current);
      });
    }
    if (!!request) {
      request.setCallback(callback);
      sendRequest(request);
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, {
        stack_message: DriveErrorMessages.error_fetching_invitations_request,
        stack_error: DriveErrorMessages.error_sending_request,
      });
    }
  }

  // Description: Handle error message and send error to store
  function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, fsmessage?: FSMessage | null,
    display_type: MessageDisplayType = MessageHandlerDisplayType.logger) {
    const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
    const stack = new ErrorStackItem("[useGetInvitations Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
    dispatch(uiActions.handleRequestErrors(new Message(message, display_type), stack));
    setError(true);
  }

  // Description: Callback for getting invitations
  const getInvitations = useCallback(() => getSharedWithMe(), []);

  // Description: Reset the hook
  const resetInvites = useCallback(() => {
    setInvitations(undefined);
    invitation_list_ref.current = [];
    index_ref.current = 0;
  }, []);

  return {
    error,
    invitations,
    getInvitations,
    resetInvites
  };
}
