import React, { ReactNode, DragEventHandler } from "react";
type AllProps = {
  children: ReactNode;
  className?: string;
  onDrop?: DragEventHandler<HTMLElement>;
  onDragOver?: DragEventHandler<HTMLElement>;
  onDragLeave?: DragEventHandler<HTMLElement>;
}

function CoverTemplate(props: AllProps) {
  const { children, className, onDrop, onDragOver, onDragLeave } = props;
  return <div
    className={`content-wrapper cover${!!className ? ` ${className}` : ""}`}
    onDrop={onDrop}
    onDragOver={onDragOver}
    onDragLeave={onDragLeave}
  >
    {children}
  </div>;
}

export default React.memo(CoverTemplate);
