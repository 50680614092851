import React from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { DriveUIActionTypes, getDriveRouteType, getEntityIcon, DriveEntryType, UUID, Helpers, EntryItem, dayjs, DefaultRoutes } from "src/common";
import { Icon } from "src/components";


type AllProps = {
  entry: EntryItem;
  handleAction: ActionHandlerFunction;
};

// Description: This is the row component for a trash child that is under a directory (I used a different component since the type of a child is EntryItem where as in the root view the type of the items is DriveTrashItem)
function TrashChildComponent(props: AllProps) {
  const { entry, handleAction } = props;
  const navigate = useNavigate();

  // Description: Build the url and navigate
  function handleRowClick() {
    if (entry.type === DriveEntryType.DIR) {
      const _collection_id = new UUID({ bytes: Helpers.b64Decode(entry.collection_id) }).String();
      const _id = new UUID({ bytes: Helpers.b64Decode(entry.id) }).String();
      navigate(`${DefaultRoutes.drive_trash}/${_collection_id}/${encodeURIComponent(_id)}/${getDriveRouteType(entry.type)}`);
    } 
    return false;
  }

  return (
    <Row className={entry.type === DriveEntryType.FILE ? "hidden" : ""} onClick={handleRowClick}>
      <Col className="d-none"></Col>
      <Col md="5" className="entry-name col">
        <span>
          <Icon className={getEntityIcon(entry.type)} />{entry.name}
        </span>
      </Col>
      <Col md="2" className="order-3">
        {entry.type_label || entry.type}
      </Col>
      <Col md="3" className="order-4 overflow-text">
        {dayjs.unix(entry.deleted_at).format("MM/DD/YYYY h:mm A")}
      </Col>
      <Col xs="auto" className="order-md-5 order-2">
        {entry.type === DriveEntryType.FILE &&
          <Button variant="outline-secondary" size="sm" className="me-2" onClick={() => handleAction({ actionType: DriveUIActionTypes.Download, params: { collection_id: entry.collection_id, id: entry.id, name: entry.name }})}>
              Download
          </Button>
        }
      </Col>
    </Row>
  );
}

export default React.memo(TrashChildComponent);
