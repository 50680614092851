import React, { useEffect, useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import { AppConfiguration, ComposeModalSize, ComposeModalSizeType, DonwloadProgressTracker, useAppSelector } from "src/common";
import { Icon } from "src/components";
import { RootState } from "src/store/configureStore";
import { DownloadProgressRow } from "../..";

type AllProps = {
  progress: DonwloadProgressTracker[];
}

/* Description: Component to display download file progress */
function DownloadProgressComponent(props: AllProps) {
  const { progress } = props;
  const is_app = AppConfiguration.buildForApp();
  const upload_request = useAppSelector((state: RootState) => state.drive.upload_request);
  const [show, setShow] = useState<boolean>(false);
  const [modal_size, setModalSize] = useState<ComposeModalSizeType>(ComposeModalSize.lg);
  const [completed_downloads, setCompletedDownloads] = useState<number>(0);
  const [total_downloads, setTotalDownloads] = useState<number>(0);
  const [displace, setDisplace] = useState<boolean>(false);

  /* Description: This use effect is detecting whether upload is also taking place - and if it is it will move the canvas to the
  left more to not block the upload progress. */
  useEffect(() => {
    !upload_request ? setDisplace(false) : setDisplace(true);
  }, [upload_request]);

  useEffect(() => {
    if (progress.length > 0) {
      setTotalDownloads(progress.length);
      setCompletedDownloads(progress.filter(t => t.done === t.total).length);
      setShow(true);
    } else {
      show && setShow(false);
    }
  }, [progress]);

  useEffect(() => {
    show && setModalSize(ComposeModalSize.lg);
  }, [show]);

  return (
    <Offcanvas
      className={`bottom-progress ${modal_size} ${displace ? "displace" : ""}`}
      backdrop={false}
      show={show}
      enforceFocus={false}
      placement="bottom">
      <Offcanvas.Header>
        <Offcanvas.Title className="download-header">
          {`Downloaded ${completed_downloads} out of ${total_downloads} ${is_app ? " to the PreVeil Downloads folder" : "to your Browsers Downloads"}`}
        </Offcanvas.Title>
        <div className="btns">
          <Button variant="transparent" onClick={() => (
            modal_size === ComposeModalSize.minimized ? setModalSize(ComposeModalSize.lg) : setModalSize(ComposeModalSize.minimized)
          )}>
            {modal_size === ComposeModalSize.minimized ? <Icon className="ficon-maximize-2" /> : <Icon className="pv-icon-minimize" />}
          </Button>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {modal_size === ComposeModalSize.lg && (
          <div className="py-1">
            {progress.map((tracker, index) => (
              <div key={index} className="align-items-center">
                <DownloadProgressRow
                  tracker={tracker}
                />
                {index !== progress.length - 1 && (
                  <hr className="my-1" />
                )}
              </div>
            ))}
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default React.memo(DownloadProgressComponent);
