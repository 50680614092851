import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import {
  Account, CollectionEntity, NodePermissionSet, NodePermissionChange, isSameDate, NodePermissionType, GranteesNodePermissions,
  useUpdateCollection, useUpdateV1Collection, COLLECTION_PROTOCOL_VERSIONS, DriveUIActionTypes
} from "src/common";
import { Icon, Loading } from "src/components";
import { ReviewChangesModal, AccessHeader, AccessRow } from ".";
import _ from "lodash";

type AllProps = {
  current_account: Account;
  edit_readonly: boolean;
  edit_mode: boolean;
  collection_info: CollectionEntity;
  shared_with: NodePermissionSet[];
  setEditMode: Dispatch<SetStateAction<boolean>>;
  handleAction: ActionHandlerFunction;
};

// NOTE: THIS Strategy is a temporary solution to address: https://react.dev/warnings/invalid-hook-call-warning
enum modes {
  webv1,
  webv2
}

function AccessListComponent(props: AllProps) {
  const { current_account, collection_info, shared_with, edit_mode, edit_readonly, setEditMode, handleAction } = props;
  const mode = collection_info.collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V1 ? modes.webv1 : modes.webv2;
  const [showReviewModal, setShowReviewModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [changes, setChanges] = useState<GranteesNodePermissions | undefined>();
  const { save: webV1Save, reset: webV1Reset, loading: web_v1_loading, data: web_v1_data } = useUpdateV1Collection(current_account, collection_info, mode === modes.webv1);
  const { save: webV2Save, reset: webV2Reset, loading: web_v2_loading, data: web_v2data } = useUpdateCollection(current_account, collection_info, mode === modes.webv2);

  // Description: Reset type value when 
  useEffect(() => {
    const data = mode === modes.webv1 ? web_v1_data : web_v2data;
    if (!!data) {
      // NOTE: Manually update V1 collections as notifications are not included in event_watch
      // REMOVE WHEN https://preveil.atlassian.net/browse/BACK-1025 comes back
      mode === modes.webv1 &&
        handleAction({ actionType: DriveUIActionTypes.GetCollectionInfo });
      setRefreshing(true); // Reset changes if there is new data from the server (callback)
      mode === modes.webv1 ? webV1Reset() : webV2Reset();
    }
  }, [web_v1_data, web_v2data]);

  // Description: Reset changes if there is new data from the server (callback)
  useEffect(() => {
    if (!!changes && refreshing) {
      setChanges(undefined);
      setRefreshing(false);
    }
  }, [shared_with]);

  // Description: Collect Loading
  useEffect(() => {
    const _loading = mode === modes.webv1 ? web_v1_loading : web_v2_loading;
    setLoading(_loading);
  }, [web_v1_loading, web_v2_loading]);

  // Description: Reset changeset on editmode changes
  useEffect(() => {
    (edit_mode && changes) && setChanges(undefined);
  }, [edit_mode]);

  // Description: Save Changes to permissions and expiration date
  // NOTE: Pass a NodePermissionSet Array
  function saveChanges() {
    const params = _.values(changes);
    // save(_.values(changes));
    mode === modes.webv1 ? webV1Save(params) : webV2Save(params);
    showReviewModal && setShowReviewModal(false);
  }

  // Description: Handles permission changes. Pass the modified new classname in the object for the row
  // NOTE: old_value is the one saved in the server
  function handleChange(new_permission: NodePermissionSet, old_value: NodePermissionChange) {
    let new_changes = !!changes ? Object.assign({}, changes) : {};
    // NOTE: If changes were reverted to old_value values reset them in the object
    if (old_value.type === new_permission.type && isSameDate(old_value.expiration, new_permission.expiration)) {
      new_changes = _.omit(new_changes, new_permission.user_id);
    } else {
      const class_name = `${(old_value.type !== new_permission.type) ? "new-type" : ""}${new_permission.type === NodePermissionType.unshare ? " strike" : ""}${old_value.expiration !== new_permission.expiration ? " new-date" : ""}`;
      new_changes[new_permission.user_id] = { ...new_permission, ...{ class_name } };
    }
    setChanges(new_changes);
  }

  // Description: Disable buttons dirty form
  function buttonDisabled(): boolean {
    return _.isEmpty(changes) || edit_readonly;
  }

  return shared_with.length > 0 ?
    <div className={edit_mode ? "edit_mode" : ""}>
      <h5 className="mb-1">People with Access ({shared_with.length})

        <Button
          className="ms-auto"
          variant="no-outline-primary"
          size="sm"
          onClick={() => setEditMode(!edit_mode)}
          disabled={!!edit_readonly}>
          <Icon className={edit_mode ? "ficon-edit-3" : "ficon-plus"} />{edit_mode ? "Edit" : "Add"}
        </Button>

      </h5>
      <div className={`access-list${loading || refreshing ? " saving" : ""}`}>
        <AccessHeader />
        {(loading || refreshing) && <Loading />}
        <div className="access-body">
          {
            _.map(shared_with, (node_permission: NodePermissionSet, i) => {
              const changed = !!changes ? changes[node_permission.user_id] : undefined;
              return <AccessRow
                class_name={changed?.class_name || ""}
                key={`access_${i}`}
                edit_mode={edit_mode}
                owner_row={!collection_info.permissions.is_owner && node_permission.is_owner}
                node_permission={node_permission}
                handleAction={handleChange} />;
            })
          }
        </div>
        <div className="btn-panel mb-3">
          <Button
            size="sm"
            disabled={buttonDisabled()}
            onClick={() => saveChanges()}>
            Save
          </Button>
          <Button
            size="sm"
            disabled={buttonDisabled()}
            onClick={() => setShowReviewModal(true)}>
            Review
          </Button>
          <Button
            size="sm"
            variant="no-outline-primary"
            disabled={buttonDisabled()}
            onClick={() => setChanges(undefined)}>
            Reset
          </Button>
        </div>
      </div>
      <ReviewChangesModal
        changes={changes || {}}
        show={showReviewModal}
        setShow={setShowReviewModal}
        handleAction={saveChanges} />
    </div > :
    <Button
      className="mb-3"
      hidden={edit_mode}
      onClick={() => setEditMode(true)}>
      <Icon className="pv-icon-share" />Share</Button>;
}

export default React.memo(AccessListComponent);
