/*
* Description: Collection Servers Filesync websocket Notifications
* Author:      PreVeil, LLC
*/
import { FSMessage } from "src/common/keys/protos/collections_pb";
export type NotificationNode = FSMessage.Notification.Node.AsObject;
export type NotificationEvent = FSMessage.Notification.EventMap[keyof FSMessage.Notification.EventMap];
export const NotificationACKLimit = 8;

export interface DriveNotification {
  collection_id: string;
  event: NotificationEvent;
  sequence: number;
  topic?: string
};

export type DriveNotificationMap = { [key: string]: DriveNotification; };

export interface DriveNotifications {
  [key: string]: DriveNotificationMap;
};

export interface ACK {
  sequence: number;
  topic: string;
};

export const DriveManagerEventWatch = [
  FSMessage.Notification.Event.CREATE,
  FSMessage.Notification.Event.UPDATE,
  FSMessage.Notification.Event.DELETE,
  FSMessage.Notification.Event.PERMISSIONS,
  FSMessage.Notification.Event.GRANT,
  FSMessage.Notification.Event.SHARE,
  FSMessage.Notification.Event.HANDLE_SHARE,
  FSMessage.Notification.Event.LOG_ENTRY,
  FSMessage.Notification.Event.UNSHARE,
  FSMessage.Notification.Event.PERMISSIONS_LOST,
  FSMessage.Notification.Event.NODE_GRANT,
  FSMessage.Notification.Event.NODE_UNSHARE
];

export const DriveListViewEventWatch = [
  FSMessage.Notification.Event.CREATE,
  FSMessage.Notification.Event.UPDATE,
  FSMessage.Notification.Event.DELETE,
  FSMessage.Notification.Event.PERMISSIONS,
  FSMessage.Notification.Event.NODE_GRANT
];

// Description: Handle Notifications 
export class FilesyncNotifications {
  node?: NotificationNode;
  event?: NotificationEvent;
  collection_id: string;
  sequence: number = 0;
  topic?: string;
  constructor(notification: FSMessage.Notification) {
    this.event = notification?.getEvent();
    this.collection_id = notification?.getCollectionId_asB64(); // IMPORTANT - Node affected
    this.node = notification?.getNode()?.toObject();
    this.sequence = notification.getSeq() || 0;
    this.topic = notification.getTopic();
  }

  // Description: Flatten and return the notification by event
  get info(): DriveNotification | null {
    return this.event !== undefined ? {
      collection_id: this.collection_id,
      event: this.event,
      sequence: this.sequence,
      topic: this.topic
    } : null;
  }
}
