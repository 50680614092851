import React from "react";
import { Button } from "react-bootstrap";


// Description: Buttons Introduction and references
function TypographyComponent() {
  const TypographyIntro = () => {
    return (
      <>
        <h4>TYPOGRAPHY</h4>
        <ul className="list-unstyled">
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://github.com/PreVeil/core/wiki/UI-Guide:-Typography"
            >
              PreVeil Typography Styling
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://getbootstrap.com/docs/5.1/content/typography/"
            >
              Bootstrap Typography
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://getbootstrap.com/docs/5.1/utilities/text/"
            >
              Bootstrap Utility Classes: Text and fonts
            </a>
          </li>
        </ul>
        <hr />
        <div>
          <h2>PreVeil Colors</h2>
          <li className="mt-4">
            <Button variant="primary">Primary</Button>{" "}
            <Button variant="secondary">Secondary</Button>{" "}
            <Button variant="success">Success</Button>{" "}
            <Button variant="warning">Warning</Button>{" "}
            <Button variant="danger">Danger</Button> <Button variant="info">Info</Button>{" "}
            <Button variant="light">Light</Button> <Button variant="dark">Dark</Button>{" "}
            <Button variant="link">Link</Button>
          </li>
        </div>
        <div>
          <h2>Text Utilities</h2>
          <h1>h1. Bootstrap heading</h1>
          <h2>h2. Bootstrap heading</h2>
          <h3>h3. Bootstrap heading</h3>
          <h4>h4. Bootstrap heading</h4>
          <h5>h5. Bootstrap heading</h5>
          <h6>h6. Bootstrap heading</h6>
          <hr />
        </div>
      </>
    );
  };

  return <TypographyIntro />;
};

export default React.memo(TypographyComponent);
