import React from "react";
import { Button, Row, Col } from "react-bootstrap";
import { AdminUserEntity, DeviceBase } from "@preveil-api";
import { Account, Device, DevicePlatform, DeviceStatus, Message, MessageHandlerDisplayType,
  MessageToastTypes, useAppDispatch, useLockDeviceMutation, useUnlockDeviceMutation
} from "src/common";
import { Icon } from "src/components";
import { uiActions } from "src/store";

type AllProps = {
  device: DeviceBase;
  current_account: Account;
  org_user: AdminUserEntity;
  refresh: Function;
};

function DeviceRowComponent(props: AllProps) {
  const { device, org_user, current_account, refresh } = props;
  const dispatch = useAppDispatch();
  const [lockDevice] = useLockDeviceMutation();
  const [unlockDevice] = useUnlockDeviceMutation();

  /* Description: Gets the status of the device: locked, current, or active. */
  function status() {
    if (!device.is_active) {
      return DeviceStatus.locked;
    } else {
      return DeviceStatus.active;
    }
  }

  /* Description: Locks or Unlocks Device depending on the action. */
  async function lockOrUnlockDevice(lock_action: string) {
    let query;
    if (lock_action === "lock") {
      query = lockDevice({ userId: current_account.user_id, deviceId: device.device_id, forUser: org_user.userEmail });
    } else {
      query = unlockDevice({ userId: current_account.user_id, deviceId: device.device_id, forUser: org_user.userEmail });
    }
    query
      .unwrap()
      .then(() => {
        refresh();
        dispatch(
          uiActions.handleSetMessage(
            new Message(`Successfully ${lock_action}ed “${device.device_name}”`),
          ),
        );
      })
      .catch((stack) => {
        dispatch(
          uiActions.handleRequestErrors(
            new Message(`Error ${lock_action}ing “${device.device_name}”`),
            stack,
          ),
        );
      });
  }

  /* Description: Modal for Unlock Action. */
  function confirmUnlock(lock_action: string) {
    const message = lock_action === "lock" ? `${org_user.userEmail} will not be able to access their account on ${device.device_name} until it is unlocked.` : `${org_user.userEmail} will be able to access their account on ${device.device_name} again.`;
    const title = lock_action === "lock" ? "Lock this Device?" : "Unlock this Device?";
    const confirmation_dialog = new Message(
      message,
      MessageHandlerDisplayType.confirm,
      MessageToastTypes.primary,
      title,
      {
        label: "Yes",
        data: true,
        action: () => lockOrUnlockDevice(lock_action),
      },
      { label: "No" },
    );
    dispatch(uiActions.handleSetMessage(confirmation_dialog));
  }

  return (
    <>
      <Row>
        <Col sm={4} md={6} className="d-flex align-items-center ps-0">
          <Icon
            className={`pv-icon-${DevicePlatform[device.platform].class || DevicePlatform.default.class
              } device-icon`}
          ></Icon>
          <div>{device.device_name}</div>
        </Col>
        <Col sm={3} md={4}>
          <Button
            variant="transparent"
            className={`notification-count ${Device.deviceStatusColor(status().class)}`}
            aria-label="Close"
          >
            {status().text}
          </Button>
        </Col>
        <Col sm="auto" className="ms-auto">
          <Button
              variant="outline-secondary"
              aria-label="Lock or Unlock"
              size="sm"
              onClick={() => confirmUnlock(device.is_active ? "lock" : "unlock") } 
            >
              {device.is_active ? "Lock" : "Unlock"}
            </Button>
        </Col>
      </Row>
    </>
  );
}

export default React.memo(DeviceRowComponent);
