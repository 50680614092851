import React, { FocusEvent } from "react";
import { Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { CheckboxStates, EntryItem, DriveUIActionTypes, DriveSearchFilterType } from "src/common";
import { Checkbox } from "src/components";
import _ from "lodash";

type AllProps = {
  search_term?: string;
  selected_folders: EntryItem[];
  handleAction: ActionHandlerFunction;
}

function SearchScopeFilterComponent(props: AllProps) {
  const { selected_folders, handleAction } = props;

  return <section>
    <header>
      <h5>Filter by Folder</h5>
      <Button variant="link" size="sm"
        onClick={() => handleAction({ actionType: DriveUIActionTypes.ResetFilters, params: DriveSearchFilterType.parent_scope })}>
        Clear All
      </Button>
    </header>
    <ul>
      {
        // Description: Render the filter
        _.map(selected_folders, (entry: EntryItem, i: number) => {
          return <li key={`entry_${i}`}>
            <Checkbox
              onChange={(e: FocusEvent<HTMLInputElement>) => {
                handleAction({
                  actionType: DriveUIActionTypes.SetFilter,
                  params: { filter_type: DriveSearchFilterType.parent_scope, parent_scope: entry.id, active: e.target.checked }
                });
              }}
              selected={CheckboxStates.checked}
              value={entry} label={entry.name} />
          </li>;
        })
      }
    </ul>
  </section>;
}

export default React.memo(SearchScopeFilterComponent);
