import * as React from "react";
import { Routes, Route, Params, Navigate } from "react-router-dom";
import { SystemRoutes } from "src/common";
import {
  Wrapper,
  CreateOrganization,
  PageNotFound
} from "src/components";

// Description: We can add more system pages - needs to be wired in the routes.tsx
function SystemPrivatePages() {
  return <Wrapper className="public-page">
    <SystemPagesRoutes />
  </Wrapper>;
}

export function SystemPagesRoutes(props: Readonly<Params<string>>) {
  return <Routes>
    <Route path={SystemRoutes.create_organization_route} element={<CreateOrganization />} />
    <Route path={SystemRoutes.page_not_found_route} element={<PageNotFound />} />
    <Route path={SystemRoutes.create_org_route} element={<Navigate replace to={`/${SystemRoutes.create_organization_route}`} />} />
    <Route path="*" element={<Navigate replace to={`/${SystemRoutes.page_not_found_route}`} />} />
  </Routes>;
}

export default React.memo(SystemPrivatePages);
