import React, { useState, DragEvent, ChangeEvent } from "react";
import Papa from "papaparse";
import { AdminUIActionTypes, AdminErrorMessages, removeNonAsciiChars } from "src/common";
import { ActionHandlerFunction } from "@preveil-api";

type AllProps = {
  handleAction: ActionHandlerFunction;
}

// These are the user_id/user email column names for each one of the csv files we provide in Admin.
enum UserIdColumn {
  DOMAIN_OR_EMAIL = "Domain or User Address",
  USER_ADDRESS = "User Address",
  EMAIL_ADDRESS = "Email Address"
}

function DraggableComponent({ handleAction }: AllProps) {
  const [dragActive, setDragActive] = useState(false);

  function handleFileParse(data: File) {
    Papa.parse(data, {
      header: true,
      skipEmptyLines: true,
      transformHeader: function(header) {
        return header.trim();
      },
      transform: (value, column) => {
        let _value;
        if (column === UserIdColumn.DOMAIN_OR_EMAIL || column === UserIdColumn.USER_ADDRESS || column === UserIdColumn.EMAIL_ADDRESS) {
          _value = value.trim().toLowerCase();
        } else {
          _value = value.trim();
        }
        return removeNonAsciiChars(_value);
      },
      complete: function(results) {
        return handleAction({actionType: AdminUIActionTypes.CsvData, params: results});
      }
    });
  };

  function handleDragOver(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  }

  function handleDrop(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    const data = e?.dataTransfer?.files[0];
    const isCsvFileExtension = (/^.*\.(csv|CSV)$/).test(data.name);
    if (isCsvFileExtension) {
      handleFileParse(data);
    } else {
      handleAction({ actionType: AdminUIActionTypes.PageError, params: { message: AdminErrorMessages.error_csv_wrong_file_type, stack: data.name } });
    }
  }
  
  function handleDragLeave(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    setDragActive(false);
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    if (e?.target?.files && e?.target?.files[0]) {
      handleFileParse(e.target.files[0]);
    };
  }

  return (
    <div className={`draggable-container ${dragActive ? "drag-active" : "drag-inactive"}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <input onChange={handleChange} accept=".csv" className="d-none" type="file" id="file-upload" aria-hidden="true" />
      <label id="label-file-upload" htmlFor="file-upload" className="d-flex flex-column justify-content-center align-items-center draggable-container-text">
        <p role="text" className="mb-0">Drag .CSV file here <br/><i>or</i></p >
        <p role="text" className="mt-0">Click to choose file</p>
      </label>
    </div>
  );
}

export default React.memo(DraggableComponent);
