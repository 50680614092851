import { MailboxesBase, MailboxItem, MailboxesState, Thread } from "@preveil-api";
import { DefaultMailboxes, ClientDefaultMailboxes, Helpers, ClientDefaultMailboxesType, TreeItemType, capitalize, VIRTUAL_MAILBOXES_SERVER_IDS, treeRoot, TreeItem } from "src/common";
import _ from "lodash";

// Description: Build the custom Mailbox Children and url_identifier
export function generateURLIdentifier(mailbox_name: string, mailbox_id: string): string {
  return `${mailbox_name}_${Helpers.getURLHashFromUID(mailbox_id)}`;
}

// Description: Replace path name with new name
export function renameFolderPath(path: string, folder_name: string): string {
  if (path.indexOf("/") > 0) {
    const arr = path.split("/");
    arr.splice(-1);
    return `${arr.join("/")}/${folder_name}`;
  }
  return folder_name;
}

// Description: Separate Mailboxes into custom vs default - Handle success callback from CS 
// Add title and url_identifiers for custom mailboxes
export function prepareMailboxes(root_mailbox: MailboxesBase): MailboxesState {
  const default_mailboxes: MailboxItem[] = [];
  const custom_mailboxes: MailboxItem[] = [];
  _.each(root_mailbox.mailboxes.slice(), (mb: MailboxItem) => {
    if (isCustomMailbox(mb)) {
      const arr = mb.name.split("/");
      let title = !!arr && arr.length > 0 ? arr[arr.length - 1] : mb.name;
      title = capitalize(title.trim());
      isValidMailbox(mb) &&
        custom_mailboxes.push(Object.assign({}, mb, { url_identifier: generateURLIdentifier(mb.name, mb.mailbox_id), title }));
    } else {
      default_mailboxes.push(Object.assign({}, mb, {
        title:
          capitalize(mb.role === DefaultMailboxes.deleted ? DefaultMailboxes.trash : mb.name.toLowerCase())
      }));
    }
  });

  // Note: Sort custom_mailboxes by alphabetical order case insensitive
  // custom_mailboxes.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
  return { rev_id: root_mailbox.last_rev_id, default_mailboxes, custom_mailboxes: custom_mailboxes.length ? custom_mailboxes : undefined };
}

// Description: Find Active Mailbox by URL name (url identifier for custom_mailboxes) 
export function getActiveMailboxByUrlName(mailbox_name: string, default_mailboxes: MailboxItem[], custom_mailboxes?: MailboxItem[]): MailboxItem | undefined {
  const _mailbox_name = mailbox_name === DefaultMailboxes.trash ? DefaultMailboxes.deleted : mailbox_name;
  const mailboxes = !!custom_mailboxes ? default_mailboxes.concat(custom_mailboxes) : default_mailboxes;
  const active_mailbox = ClientDefaultMailboxesType.includes(_mailbox_name) ? ClientDefaultMailboxes[_mailbox_name] :
    _.find(mailboxes, (mailbox: MailboxItem) => {
      return !!mailbox.url_identifier ? mailbox.url_identifier === _mailbox_name :
        mailbox.role === _mailbox_name || mailbox.mailbox_id === _mailbox_name;
    });
  return active_mailbox;
}

// Description: Find Active Mailbox by mailbox name
export function getActiveMailboxByName(mailbox_name: string, default_mailboxes: MailboxItem[], custom_mailboxes?: MailboxItem[]): MailboxItem | undefined {
  const _mailbox_name = mailbox_name === DefaultMailboxes.trash ? DefaultMailboxes.deleted : mailbox_name;
  const mailboxes = !!custom_mailboxes ? default_mailboxes.concat(custom_mailboxes) : default_mailboxes;
  const active_mailbox = ClientDefaultMailboxesType.includes(_mailbox_name) ? ClientDefaultMailboxes[_mailbox_name] :
    _.find(mailboxes, (mailbox: MailboxItem) => (mailbox.name === _mailbox_name || mailbox.role === _mailbox_name));
  return active_mailbox;
}

// Description: Returns true for custom mailboxes
export function isCustomMailbox(mailbox: MailboxItem): boolean {
  return !mailbox.role || mailbox.role.trim().length <= 0;
}

// Description: Build Tree array from flat MailboxItem[]
function buildPseudoTree(source: string, target: any, mailbox: MailboxItem, disabled_nodes: string[] = [], index: number = 0) {
  const items = source.split("/");
  const item_name = items.shift();
  if (!!item_name) {
    const item = {
      type: TreeItemType.folder,
      name: item_name,
      children: undefined
    };
    if (items.length) {
      if (typeof target[item_name] !== "object") {
        !!target.children ? target.children.push(item) : (target.children = [item]);
        target[item_name] = item;
      }
      buildPseudoTree(items.join("/"), target[item_name], mailbox, disabled_nodes, index + 1);
    } else { // Final Piece of the name:
      const disabled = disabled_nodes.includes(mailbox.mailbox_id);
      const mailbox_item = Object.assign(item, { id: mailbox.mailbox_id, url_identifier: mailbox.url_identifier, path: mailbox.name, disabled });
      !!target.children ? target.children.push(mailbox_item) : (target.children = [mailbox_item]);
      target[item_name] = item;
    }
  }
}

// Description: Removes excess object artifacts from tree creation
function cleanTreeObject(treeItem: TreeItem): TreeItem | undefined {
  const tree = _.pick(treeItem, ["type", "name", "path", "children", "type", "id", "url_identifier", "disabled", "active"]) as TreeItem;
  if (!!tree.children && tree.children.length) {
    const children: TreeItem[] = [];
    _.map(tree.children, (child: TreeItem) => {
      if (!!child.id) {
        const _child = cleanTreeObject(child);
        _child && children.push(_child);
      }
    });
    tree.children = children;
  }
  return tree;
}

function isValidMailbox(mailbox: MailboxItem): boolean {
  return !mailbox.is_deleted && !!mailbox.mailbox_id;
}

// Description:  Build Custom Mailbox tree
export function buildCustomMailboxTree(custom_mailboxes: MailboxItem[], disabled_nodes: string[] = []): TreeItem[] | undefined {
  const root = { ...treeRoot };
  const first_level: TreeItem[] = [];
  _.map(custom_mailboxes, (mailbox: MailboxItem) => {
    const name_array = mailbox.name.split("/");
    const item = {
      id: mailbox.mailbox_id,
      type: TreeItemType.folder,
      name: mailbox.name, // Use name here because Title will not match in subsequent operations
      path: mailbox.name, // Path will remain the original string
      url_identifier: mailbox.url_identifier,
      disabled: disabled_nodes.includes(mailbox.mailbox_id)
      // Might need to add more props from MailboxItem: rev_id (do not need is_deleted - as it is removed from state)
    };

    return (name_array.length === 1) ?
      first_level.push(item) :
      buildPseudoTree(mailbox.name, root, mailbox, disabled_nodes);
  });

  let tree = first_level;
  if (root.children.length > 0) {
    const children = _.values(_.merge(_.keyBy(root.children, "name"), _.keyBy(first_level, "name")));
    const custom_tree = cleanTreeObject(Object.assign({}, root, { children }));
    if (!!custom_tree && !!custom_tree.children) {
      tree = custom_tree.children;
    }
  }

  return tree;
}

// Other helpers:
// Description: retrive mailbox collectionId by role
export function getMailboxCollectionIdByRole(role: string, default_mailboxes: MailboxItem[]): string | null {
  const mailbox = _.find(default_mailboxes, (mailbox: MailboxItem) => {
    return mailbox.role === role.toLowerCase();
  });
  return mailbox?.mailbox_id || null;
}

export function isSentMailbox(role: string): boolean {
  return role === DefaultMailboxes.sent;
}

export function isDraftMailbox(role: string): boolean {
  return role === DefaultMailboxes.drafts;
}

export function isTrashtMailbox(role: string): boolean {
  return role === DefaultMailboxes.deleted || role === DefaultMailboxes.trash;
}

export function isStarredMailbox(role: string): boolean {
  return role === DefaultMailboxes.starred;
}

export function isUnreadMailbox(role: string): boolean {
  return role.toLowerCase() === DefaultMailboxes.unread;
}

export function isSearchMailbox(role: string): boolean {
  return role === DefaultMailboxes.search;
}

// Description: Get the vitural mailbox id if any returns null if not a Virtual mailbox
export function getVirtualMailboxId(mailbox_id: string): string | null {
  return Object.prototype.hasOwnProperty.call(VIRTUAL_MAILBOXES_SERVER_IDS, mailbox_id) ?
    VIRTUAL_MAILBOXES_SERVER_IDS[mailbox_id] : null;
}


// Description: Get URL params from threads and mailboxes for search
export function getSearchFolderUrlParam(thread: Thread, default_mailboxes?: MailboxItem[], custom_mailboxes?: MailboxItem[]): string | null {
  const thread_mailbox = (!!default_mailboxes && !!custom_mailboxes && !!thread.mailbox_name) ?
    getActiveMailboxByName(thread.mailbox_name, default_mailboxes, custom_mailboxes) : null;

  return !!thread_mailbox ? (!!thread_mailbox.url_identifier ? thread_mailbox.url_identifier :
    (thread_mailbox.role === DefaultMailboxes.deleted ? DefaultMailboxes.trash : thread_mailbox.role)) : null;
}
