import { useState, useCallback, useEffect, useRef } from "react";
import { MessageDisplayType, ErrorStackDataType } from "@preveil-api";
import {
  Account, useAppDispatch, Message, MessageHandlerDisplayType, DriveErrorMessages, useAppSelector, getEnumKey, CollectionFilesyncAPI,
  DriveRequest, useSendRequestMutation, DriveEntryType, DriveTrashItem, dayjs, encryptName, ErrorStackItem
} from "src/common";
import { FSMessage, FSRequest, FSStatus } from "src/common/keys/protos/collections_pb";
import { PermissionSetType } from "src/common/drive/permissions.class";
import { DriveCallbackAsyncFunction, uiActions } from "src/store";
import { RootState } from "src/store/configureStore";
import _ from "lodash";

/* Description: The Web hook for restoring items from trash */
export function useRestoreItems(current_account: Account) {
  const default_permissions = useAppSelector((state: RootState) => state.drive.default_permissions);
  const [conflict_errors, setConflictErrors] = useState<DriveTrashItem[]>([]);
  const [successes, setSuccesses] = useState<DriveTrashItem[]>([]);
  const [errors, setErrors] = useState<DriveTrashItem[]>([]);
  const [items, setItems] = useState<DriveTrashItem[]>([]);
  const [done, setDone] = useState<boolean>(false);
  const [total, setTotal] = useState<number>();
  const [sendRequest] = useSendRequestMutation();
  const dispatch = useAppDispatch();

  const restore_requests_ref = useRef<Map<string, FSRequest.Restore[]>>(new Map<string, FSRequest.Restore[]>());
  const count_ref = useRef<number>(0);

  // Description: Handles responses for each item to move and sets done when all items have been responded.
  useEffect(() => {
    const responded = errors.length + successes.length + conflict_errors.length;
    if (!!total && responded === total) {
      setDone(true);
    }
  }, [errors, successes, conflict_errors]);

  useEffect(() => {
    if (!!total && items.length > 0) {
      fetchItemVersion(items);
    }
  }, [total, items]);

  // Description: fetch item information based on type.
  function fetchItemVersion(items: DriveTrashItem[]) {
    _.forEach(items, (item: DriveTrashItem) => {
      const permissions = _.find(default_permissions, (set: PermissionSetType) => item.collection_id === set.collection_id.B64());
      if (!!permissions) {
        switch (item.type) {
          case DriveEntryType.FILE:
            handleFetchFile(item, permissions);
            break;
          default:
            handlefetchDirectory(item, permissions);
            break;
        }
      } else {
        errors.push(item);
      }
    });
  }

  // Description: Fetches directory to get version
  async function handlefetchDirectory(item: DriveTrashItem, permissions: PermissionSetType) {
    const request = await CollectionFilesyncAPI.getDirectoryInformationRequest(item.collection_id, item.id, true);
    const drive_request = !!request && !!permissions ? await DriveRequest.initializeRequest(request, current_account, permissions) : null;
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        const dir = message.getDir();
        if (!!dir) {
          const version = dir.getVersion_asB64();
          _getRestoreRequest(item.collection_id, dir.getParentId_asB64(), item.id, version);
        } else {
          handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_directory }, message, item);
        }
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_directory }, message, item);
      }
    }
    handleSendRequest(drive_request, callback, item);
  }

  // Description: fetches file to get version
  async function handleFetchFile(item: DriveTrashItem, permissions: PermissionSetType) {
    const request = !!permissions ? await CollectionFilesyncAPI.fetchFile(current_account, item.collection_id, item.id, permissions, true) : null;
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        const file = message.getFile();
        if (!!file) {
          const version = file.getVersion_asB64();
          _getRestoreRequest(item.collection_id, file.getParentId_asB64(), item.id, version);
        } else {
          handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_file }, message, item);
        }
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_file }, message, item);
      }
    }
    handleSendRequest(request, callback, item);
  }

  // Description: creates restore request and adds it to map (so that items of the same collection_id are grouped together - need to do this for bulk update call)
  function _getRestoreRequest(collection_id: string, parent_id: string, id: string, version: string, encrypted_name?: Uint8Array) {
    const restore = new FSRequest.Restore();
    restore.setId(id);
    restore.setVersion(version);
    if (!!encrypted_name) {
      restore.setName(encrypted_name);
    }
    restore.setParentId(parent_id);
    if (restore_requests_ref.current.has(collection_id)) {
      const _restore = restore_requests_ref.current.get(collection_id);
      !!_restore && _restore.push(restore);
    } else {
      restore_requests_ref.current.set(collection_id, [restore]);
    }
    count_ref.current++;
    if (!!total && count_ref.current === total) {
      for (const _collection_id of restore_requests_ref.current.keys()) { // for each collection_id in the map, send a bulk update request with those restore requests
        const restores = restore_requests_ref.current.get(_collection_id);
        const permissions = _.find(default_permissions, (set: PermissionSetType) => _collection_id === set.collection_id.B64());
        !!restores && !!permissions && bulkUpdate(_collection_id, permissions, restores);
      }
    }
  }

  // Description: Bulk update entries after restoring items
  async function bulkUpdate(collection_id: string, permissions: PermissionSetType, restored_entries: FSRequest.Restore[], final?: boolean) {
    const drive_request = !!permissions ? await CollectionFilesyncAPI.bulkUpdate(current_account, collection_id, [], [], [], [], restored_entries, permissions) : null;
    async function callback(message: FSMessage) {
      const failure_list = message.getBulkUpdateFailureList();
      if (failure_list.length > 0) { // handles failure list. 
        const conflicting_ids: string[] = [];
        const shared_parent_errors: string[] = [];
        for (const result of failure_list) {
          switch (result.getStatus()) {
            case 1:
              conflicting_ids.push(result.getId_asB64());
              break;
            default:
              shared_parent_errors.push(result.getId_asB64());
              break;
          }
        }
        setErrors((errors) => errors.concat(items.filter(i => shared_parent_errors.includes(i.id))));
        if (conflicting_ids.length > 0) {
          if (final) {
            setConflictErrors((conflict_errors) => conflict_errors.concat(items.filter(i => conflicting_ids.includes(i.id))));
          } else {
            const conflict_acl_node_ids: string[] = [];
            const conflict_acl_nodes = items.filter(item => {
              if (conflicting_ids.includes(item.id) && item.is_acl_node) {
                conflict_acl_node_ids.push(item.id);
                return true;
              }
              return false;
            });
            setConflictErrors((conflict_errors) => conflict_errors.concat(conflict_acl_nodes));
            const conflict_restores = restored_entries.filter(item => conflicting_ids.includes(item.getId_asB64()) && !conflict_acl_node_ids.includes(item.getId_asB64()));
            conflict_restores.length > 0 && handleConflicts(collection_id, permissions, conflict_restores);
          }
        }
        if (failure_list.length < restored_entries.length) {
          const success_restores = restored_entries.filter((restore) => !shared_parent_errors.includes(restore.getId_asB64()) && !conflicting_ids.includes(restore.getId_asB64()));
          success_restores.length > 0 && handleSuccess(success_restores);
        }
      } else {
        handleSuccess(restored_entries);
      }
    }
    handleSendRequest(drive_request, callback);
  }

  // Description: Handles items that have been successfully restored
  function handleSuccess(restores: FSRequest.Restore[]) {
    const restored_entries_ids = restores.map((restore) => restore.getId_asB64());
    const success_items = items.filter((item) => restored_entries_ids.includes(item.id));
    setSuccesses((successes) => successes.concat(success_items));
  }

  // Description: Handles situation when theres a conflict and the name - this renames the item and creates a new request object and bulk updates all the conflict entries again
  async function handleConflicts(collection_id: string, permissions: PermissionSetType, conflicts: FSRequest.Restore[]) {
    const conflict_entries = await Promise.all(conflicts.map(async conflict_item => {
      const item = items.find(i => i.id === conflict_item.getId_asB64());
      const extension = !!item ? item.type === DriveEntryType.FILE ? `.${item.name.split(".").pop()}`.toLowerCase() : "" : "";
      const without_extension = !!item ? item.type === DriveEntryType.FILE ? item.name.split(".")[0] : item.name : "";
      const name = `${without_extension}_Restored_${dayjs().format("MMDDYYYY")}_${dayjs().format("hhmm")}${extension}`;
      const encrypted_name = !!item && !!item.parent_dir_key && !!name ? await encryptName(name, item.parent_dir_key) : undefined;
      const restore = new FSRequest.Restore();
      restore.setId(conflict_item.getId_asB64());
      restore.setVersion(conflict_item.getVersion_asB64());
      if (!!encrypted_name) {
        restore.setName(encrypted_name);
      }
      restore.setParentId(conflict_item.getParentId_asB64());
      return restore;
    }));
    bulkUpdate(collection_id, permissions, conflict_entries, true);
  }

  // Description: Sends Request and handles error when the request object is null, pass it custom stack message
  function handleSendRequest<T>(request: DriveRequest | null, callback: DriveCallbackAsyncFunction<T>, item?: DriveTrashItem, stack_error: string = DriveErrorMessages.error_sending_request) {
    if (!!request) {
      request.setCallback(callback);
      sendRequest(request);
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, { stack_error, stack_message: DriveErrorMessages.error_sending_request }, null, item);
    }
  }

  // Description: Logs Errors and sets Error to true
  function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, fsmessage?: FSMessage | null, item?: DriveTrashItem,
    display_type: MessageDisplayType = MessageHandlerDisplayType.logger) {
    const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
    const stack = new ErrorStackItem("[useRestoreItems Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
    dispatch(uiActions.handleRequestErrors(new Message(message, display_type), stack));
    !!item && setErrors((errors) => errors.concat([item]));
  }

  // Description: Callback for restoring items
  const restoreItems = useCallback((items: DriveTrashItem[]) => {
    setItems(items);
    setTotal(items.length);
  }, []);

  // Description: reset the hook
  const resetRestoreItems = useCallback(() => {
    setErrors([]);
    setSuccesses([]);
    setTotal(undefined);
    setDone(false);
    count_ref.current = 0;
    restore_requests_ref.current = new Map<string, FSRequest.Restore[]>();
  }, []);

  return {
    restoreItems,
    resetRestoreItems,
    errors,
    conflict_errors,
    successes,
    done,
  };
}
