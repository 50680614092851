import React from "react";
import { Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import expressImage from "src/assets/images/upgrade_express_account.svg";
import { PrivateRoutes, QueryParamKeys, QueryParamValues, WelcomeModalStates, WelcomeModalType } from "src/common";

type AllProps = {
  handleShowModal: (modal: WelcomeModalType) => void;
};

function ExpressWelcomeModal(props: AllProps) {
  const { handleShowModal } = props;
  return (
    <>
      <Card.Img variant="top" src={expressImage} className="mb-4 welcome-image" />
      <p>
        PreVeil Express requires no installation and uses a password + 2FA to protect your secret key.
      </p>
      <p>
        <b>
          Please note: The only way to reset your password is with an Account Recovery file.
        </b>
      </p>
      <p>
        {"Save your Account Recovery file in Settings -> Account Recovery."}
      </p>
      <p>
        For your security, we do not store passwords. If you lose your password you will need your account recovery file to create a new one.
      </p>
      <div className="welcome-buttons">
        <Link className="btn btn-primary" to={`/${PrivateRoutes.settings_route}/${PrivateRoutes.password_recovery_route}?${QueryParamKeys.PAGE_ACTION_QUERY_KEY}=${QueryParamValues.save}`}>
          Save now
        </Link>
        <Button
          variant="outline-primary"
          className="float-end"
          onClick={() => handleShowModal(WelcomeModalStates.close)}
        >
          Save Later
        </Button>
      </div>
    </>
  );
}

export default React.memo(ExpressWelcomeModal);
