import * as React from "react";
import { Card } from "react-bootstrap";
import { Icon } from "..";

function SystemErrorComponent() {
  return <Card className="mx-auto max-600 mt-5">
    <Card.Body>
      <h2 className="border-bottom pb-3">
        <Icon className="ficon-alert-triangle error-icon" />
        PreVeil System Message</h2>
      <p>PreVeil is updating. You may need to log into your account again.</p>
      <p>If you have a question, please contact us at <a href="mailto:support@preveil.com"
        target="_top">support@preveil.com</a>. </p>
      <p>We apologize for the inconvenience.</p>
    </Card.Body>
  </Card>;
}

export default React.memo(SystemErrorComponent);
