import * as React from "react";
import { Form, Button } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";
import { ActionHandlerFunction } from "@preveil-api";
import { CreateAccountUIActionTypes, useCreateOrganizationMutation, CreateOrganizationApiResponse, AccountErrorMessages } from "src/common";
import { FormField, Loading } from "src/components";

type AllProps = {
  user_id: string;
  handleAction: ActionHandlerFunction;
}

type FormProps = {
  organization_name: string;
  user_department: string;
}


function DefaultPanelComponent(props: AllProps) {
  const { user_id, handleAction } = props;
  const [createOrganization, { isLoading }] = useCreateOrganizationMutation();
  const schema = yup.object().shape({
    organization_name: yup.string().required("The organization name is required."),
    user_department: yup.string().required("The user department is required."),
  });

  // Description: Handle form submit
  async function handleSubmit(formValues: FormProps) {
    await createOrganization({ userId: user_id, orgName: formValues.organization_name, userDepartment: formValues.user_department })
      .unwrap()
      .then((params: CreateOrganizationApiResponse) => {
        handleAction({ actionType: CreateAccountUIActionTypes.SubmitCreateOrganization, params });
      })
      .catch((msg) => {
        handleAction({ actionType: CreateAccountUIActionTypes.PageError, params: AccountErrorMessages.create_organization_error });
      });
  }

  // Description: Returns the form
  function CreateOrganizationForm() {
    return <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={{
        organization_name: "",
        user_department: "",
      }}
    >
      {({ values, errors, touched, handleChange, handleSubmit, handleBlur }) => (
        <Form noValidate className="m-2" onSubmit={handleSubmit}>
          <p>This will create an organization and make you the administrator.</p>
          <FormField
            autoComplete="off"
            autoFocus={true}
            className="mb-4"
            label="Organization Name"
            type="text"
            errors={errors.organization_name}
            touched={touched.organization_name}
            handleBlur={handleBlur("organization_name")}
            handleChange={handleChange("organization_name")}
            value={values.organization_name}
            required={true} />
          <FormField
            autoComplete="off"
            className="mb-3"
            label="User Department"
            type="text"
            errors={errors.user_department}
            touched={touched.user_department}
            handleBlur={handleBlur("user_department")}
            handleChange={handleChange("user_department")}
            value={values.user_department}
            helperText="This is the department that you belong to in the organization"
            required={true} />
          <div className="mt-4 pt-4">
            <Button type="submit"
              disabled={!values.organization_name || !values.user_department}
              className="me-2">
              Create Organization
            </Button>
          </div>
        </Form>
      )}
    </Formik>;
  }

  return !isLoading ? <CreateOrganizationForm /> : <Loading className="in-place" />;

}

export default React.memo(DefaultPanelComponent);
