import {
  Account, UUID, PermissionSet, DriveRoot, RootInfo, EntryItemBase, NodePermissionSet, COLLECTION_PROTOCOL_VERSIONS, CollectionEntity,
  isSameUser, DriveEntryTypes, DriveEntryType, Helpers, InitCollectionEncryptedData, InitV2CollectionEncryptedData, KeyFactory, AppUserKey
} from "src/common";
import { FSRole, Node, SealedContent, FSMessage } from "src/common/keys/protos/collections_pb";
import { SymmKey } from "src/common/keys";
import { EncryptionStyle } from "src/common/keys/encryption";
import _ from "lodash";


export class Collection implements CollectionEntity {
  collection_id!: string;
  collection_name!: string;
  id!: string;
  node_type!: DriveEntryTypes;
  maintainer_id!: string; // Only set for V2 collections
  shared_with!: NodePermissionSet[]; // LEGACY: shared_with
  permissions!: NodePermissionSet;
  root_id!: string; // Only set for V1 collections 
  is_root!: boolean; // Is this a Collection node (v2)
  is_maintainer!: boolean; // Is the current Entry the maintainer for this V2 collection
  disjointed_acl_node!: boolean; // Is this a child dir node of a V2 collection
  constructor(
    entry: EntryItemBase,
    node_permissions: NodePermissionSet[],
    current_user_id: string, // "Me"
    root_id: string, // NOTE:  This one will be the maintainer_id for V2 collections and root id for V1 collections
    public collection_protocol_version: COLLECTION_PROTOCOL_VERSIONS = COLLECTION_PROTOCOL_VERSIONS.V2,
    collection_name?: string,
    dir?: FSMessage.Dir
  ) {
    this.collection_id = entry.linked_collection_id || entry.collection_id;
    this.id = entry.id;
    this.node_type = entry.type;
    this.root_id = collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V1 ? root_id : "";
    this.maintainer_id = collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V2 ? root_id : "";
    this.collection_name = !!collection_name ? collection_name : ""; // NOTE: Pass all instances of name through the EntryItemBase
    !!dir && this._setACLNodeInformation(dir);
    this._initializePermissions(node_permissions, current_user_id, entry.type);
  }

  // Description: get Collection Information for building the Viewer panel
  get collection_info(): CollectionEntity {
    return {
      collection_id: this.collection_id,
      collection_name: this.collection_name,
      collection_protocol_version: this.collection_protocol_version,
      id: this.id,
      maintainer_id: this.maintainer_id,
      node_type: this.node_type,
      permissions: this.permissions,
      shared_with: this.shared_with,
      // Describe the DIR node: Acl, effective root, and is_root_node 
      root_id: this.root_id,
      is_root: this.is_root,
      is_maintainer: this.is_maintainer,
      disjointed_acl_node: this.disjointed_acl_node
    };
  }

  // Description: Remove self from shared_with and save to permissions
  // If the entry is a directory the node_id is NOT the same as the maintainer id, then the shared with will be a parent node and should be emptied
  private _initializePermissions(node_permissions: NodePermissionSet[], current_user_id: string, type: DriveEntryTypes) {
    this.permissions = _.remove(node_permissions, (node_permission: NodePermissionSet) => isSameUser(node_permission.user_id, current_user_id))[0];
    // NOTE: Sort shared_with alphabetically by user_id: string
    this.shared_with = (type === DriveEntryType.DIR && this.id !== this.maintainer_id) ? [] : _.sortBy(node_permissions, "user_id");
    const disjointed_maintainer = this.is_maintainer && this.disjointed_acl_node;
    this.permissions.disjointed_maintainer = disjointed_maintainer;
  }

  // Description: Set information about the current directory node
  //             - IF the node is a maintainer: If this is a shared folder under a V2 collection or acl_node with or without a true root
  //             - IF DISJOINTED ACL NODE: Recipients of an acl node will not have a true root and FE creates an effective root (fake dir)
  private _setACLNodeInformation(dir: FSMessage.Dir) {
    this.is_root = dir.getIsRoot() || this.collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V1;
    const is_maintainer = !!dir.getMaintainerId_asB64() && dir.getMaintainerId_asB64() === dir.getId_asB64();
    // NOTE: Is a direct disjointed acl node if parent Id is empty otherwise user created the node directly from the tree
    // Specifically relates to deeply nested shared folders children of disjointed acl nodes (with effective roots at Root) DA-4710
    this.disjointed_acl_node = !this.is_root && is_maintainer && !dir.getParentId_asB64();
    this.is_maintainer = !!dir.getMaintainerId_asB64() && dir.getMaintainerId_asB64() === this.id;
  }

  // Description: To initialize a new v1 collection, build new permissions, keys, encrypted_name and wrapped_key
  // Returns name encrypted with  newly generated SymmKey for using previous pass it as an optional param to PermissionSet.getNewPermissions
  static async getNewV1CollectionData(current_account: Account, collection_id: UUID, name: string, dir_symm_key?: SymmKey):
    Promise<InitCollectionEncryptedData | undefined> {
    const key_data = await PermissionSet.getNewPermissions(current_account, collection_id, dir_symm_key);
    const new_reader_userkey = await key_data.new_permissions.permission(FSRole.READER)?.key(current_account);
    if (!!new_reader_userkey) {
      const wrapped_key = await new_reader_userkey.encryption_key.seal(key_data.dir_symm_key.serialize());
      const encrypted_name = await new_reader_userkey.encryption_key.seal(Helpers.utf8Encode(name));
      return { key_data, encrypted_name, wrapped_key };
    }
  }

  // Description: To initialize a new v2 collection - used in WebShare
  static async getNewV2CollectionData(current_account: Account, collection_id: UUID, name: string, dir_symm_key?: SymmKey):
    Promise<InitV2CollectionEncryptedData | undefined> {
    // Create the collection level keys
    const key_data = await PermissionSet.getNewPermissions(current_account, collection_id, dir_symm_key, COLLECTION_PROTOCOL_VERSIONS.V2);
    const access_key = await key_data.new_permissions.permission(FSRole.ACCESS)?.key(current_account);
    // Create the ACL node level keys for the root node
    const roles = [Node.ACLRole.READER, Node.ACLRole.WRITER, Node.ACLRole.ACL_READER, Node.ACLRole.ACL_WRITER];
    const node_permissions: { [role: number]: AppUserKey } = {};
    for (const role of roles) {
      node_permissions[role] = await KeyFactory.newUserKey({ style: EncryptionStyle.DRIVE });
    }
    const reader_key = node_permissions[Node.ACLRole.READER];
    if (!!access_key && !!reader_key) {
      // Name is encrypted with the collection level access key
      const encrypted_access_name = await access_key.encryption_key.seal(Helpers.utf8Encode(name));
      // The maintainer scoped name and the wrapped key are scoped to the maintainer
      const wrapped_key = await reader_key.encryption_key.seal(key_data.dir_symm_key.serialize());
      const encrypted_name = await reader_key.encryption_key.seal(Helpers.utf8Encode(name));
      const maintainer_scoped_name = new SealedContent(); // READER ENCRYPTED NAME
      maintainer_scoped_name.setKeyVersion(0);
      maintainer_scoped_name.setContent(encrypted_name);
      return { key_data, encrypted_name, encrypted_access_name, maintainer_scoped_name, wrapped_key, node_permissions };
    }
  }

  // Description: Parse DriveRoot to Root Info - this node will always be v1
  static parseFSRootInfo(browse_drive: DriveRoot): RootInfo {
    const root_info = {
      collection_id: browse_drive.id,
      id: browse_drive.rootid, // THIS IS THE directory_id of the collection  
      name: browse_drive.name,
      // collection_protocol_version: COLLECTION_PROTOCOL_VERSIONS.V1,
      version: null,
      wrapped_dir_key: null,
      localSyncStatus: browse_drive.localSyncStatus,
      acl: browse_drive.rootacl || null,
    };
    return root_info;
  }
}
