import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { ActivityLogInfo } from "@preveil-api";
import { EmptyViewMessages } from "src/common";
import { Loading, EmptyView } from "src/components";
import { LogSidePanel, LogsRow } from ".";
import _ from "lodash";

type AllProps = {
  loading: boolean;
  activity_logs: ActivityLogInfo[];
};

/* Description: List View component for activity logs. Using grids (Rows and Cols) rather than Table for 
better responsiveness since there are 4 headers - Event, Timestamp, Topic and Device. */
function LogsListViewComponent(props: AllProps) {
  const { loading, activity_logs } = props;
  const [open_log, setOpenLog] = useState<ActivityLogInfo>();

  // Description: Render the rows
  function RenderList() {
    return (
      <div className="list-body">
        {_.map(activity_logs, (log: ActivityLogInfo, i: number) => {
          return <LogsRow key={i} log={log} setOpenLog={setOpenLog}></LogsRow>;
        })}
      </div>
    );
  }

  return <div className={`settings-list activity-logs${loading ? " isloading" : ""} clickable`}>
    {!loading && <>{activity_logs.length > 0 ? 
      <>
        <Row className="header-row">
          <Col lg={6}>Event</Col>
          <Col lg={3}>Timestamp</Col>
          <Col lg={1}>Topic</Col>
          <Col lg={2}>Device</Col>
        </Row>
        {RenderList()}
        </> : <EmptyView message={EmptyViewMessages.no_activity} />}</>}
    {loading && <Loading className="in-place" message="Fetching Activity" />}
    <LogSidePanel log={open_log} setLog={setOpenLog}></LogSidePanel>
  </div>;
}

export default React.memo(LogsListViewComponent);
