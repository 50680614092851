import React from "react";

type AllProps = {
    message?: string; // Pass a string message to display information instead of the Logo
    className?: string; // Additional specific css classes for custom styling
    darkbg?: boolean;
    noLogo?: boolean;
}

function LoadingComponent(props: AllProps) {
    const { message, className, darkbg, noLogo } = props;

    return <div className={`loading${!!message ? " loading-msg" : ""}${!!className ? " " + className : ""} `}>
        {
            <>
                {!noLogo && <div className={`logo${darkbg ? " white" : ""}`}></div>}
                {!!message && <p>{message}</p>}
            </>
        }
        <div className="loading-progress">
            <div></div>
        </div>
    </div>;
}

export default React.memo(LoadingComponent);
