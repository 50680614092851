// import * as React from "react";
import React, { useEffect } from "react";
import { UserProfile } from "@preveil-api";
import { isSameProfile, prettyUserProfile, highlightProfileSubstrings } from "src/common";
import _ from "lodash";

type AllProps = {
  search_term: string;
  found_profiles: UserProfile[];
  active: UserProfile | null;
  handleAction: (profile: UserProfile | null) => void;
}

function UserAutocompleteComponent(props: AllProps) {
  const { found_profiles, search_term, active, handleAction } = props;

  useEffect(() => {
    window.addEventListener("click", resetAutocomplete);
    return () => {
      resetAutocomplete();
      window.removeEventListener("click", resetAutocomplete);
    };
  }, []);

  // Description: Remove autocomplete on click outside
  function resetAutocomplete() {
    handleAction(null);
  }
  // Description: Renders a user list item
  function UserResult(props: { profile: UserProfile }) {
    const profile = props.profile;
    const _adjusted = !!search_term && search_term.length ? highlightProfileSubstrings(profile, search_term) : prettyUserProfile(profile);
    return <li
      className={!!active && isSameProfile(active, profile) ? "active" : ""}
      onClick={() => { handleAction(profile); }} dangerouslySetInnerHTML={{ __html: _adjusted }} />;
  }

  return <div className="autocomplete-results">
    <ul>
      {
        _.map(found_profiles, (profile: UserProfile, i: number) => {
          return <UserResult key={`user_${i}`} profile={profile} />;
        })
      }
    </ul>
  </div>;
}

export default React.memo(UserAutocompleteComponent);