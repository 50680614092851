import React, { useState, Dispatch, SetStateAction } from "react";
import { IActionHandler, MessageDisplayType, Sort } from "@preveil-api";
import {
  GetUserRequestResponsesApiResponse,
  GlobalErrorMessages,
  Message,
  MessageAnchors,
  MessageHandlerDisplayType,
  GlobalConstants,
  RequestTab,
  SettingsErrorMessages,
  SettingsSuccessMessages,
  SimplePaginationAction,
  SimplePaginationActionType,
  useAppDispatch,
  useAppSelector,
  useDeleteUserRequestMutation,
  useGetUserRequestResponsesMutation,
  UserRequest,
  ApprovalRequestStatusType,
  ApprovalRequestStatus,
  DeleteUserRequestApiResponse,
  RecoveryGroup,
  getOppositeSortDirection,
  sortBy,
  SORT_DIRECTION
} from "src/common";
import { ApprovalRequestsParent } from "src/components";
import { settingsActions, uiActions } from "src/store";

type AllProps = {
  getUserRequests: Function;
  is_loading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  pending_requests: UserRequest[];
  setPendingRequests: Dispatch<SetStateAction<UserRequest[]>>;
  history_requests: UserRequest[];
  setHistoryRequests: Dispatch<SetStateAction<UserRequest[]>>;
  current_recovery_group?: RecoveryGroup;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

function UserRequestsComponent(props: AllProps) {
  const { getUserRequests, is_loading, setIsLoading, pending_requests, setPendingRequests, history_requests, 
    setHistoryRequests, current_recovery_group, page, setPage } = props;
  const dispatch = useAppDispatch();
  const header_navigate_list = [{ path: "/settings/recovery", display: "Recovery Group" }];
  const [deleteUserRequest] = useDeleteUserRequestMutation();
  const current_account = useAppSelector((state) => state.account.current_account);
  const approvers = current_recovery_group?.optional_users;
  const pagination = useAppSelector((state) => state.settings.pagination);
  const [is_details_loading, setIsDetailsLoading] = useState<boolean>(true);
  const [sort, setSort] = useState<Sort<keyof UserRequest>>({ field: "timestamp", direction: "asc" });
  const [getResponses] = useGetUserRequestResponsesMutation();
  const [filter_status, setFilterStatus] = useState<ApprovalRequestStatusType>(ApprovalRequestStatus.approved);

  /* Description: Gets the Request Responses to display the progress section on the side panel.
  i.e displays which users have approved or rejected the request and which users have not yet responded to the request. */
  async function getRequestResponses(request_id: string) {
    if (!!current_account) {
      setIsDetailsLoading(true);
      await getResponses({ userId: current_account.user_id, requestId: request_id })
        .unwrap()
        .then((response: GetUserRequestResponsesApiResponse) => {
          if (!!approvers) {
            const returned_response = response.responses.map((r) => ({
              approver:
                approvers.find(
                  (user) => user.address.toLowerCase() === r.approver.toLowerCase(),
                ) || { address: r.approver, name: "" },
              response: r.response,
            }));
            const updated_request_index = pending_requests.findIndex(
              (request) => request.request_id === request_id,
            );
            pending_requests[updated_request_index].request_responses = returned_response;
            setIsDetailsLoading(false);
          }
        })
        .catch((stack) => {
          dispatch(
            uiActions.handleRequestErrors(new Message(SettingsErrorMessages.error_fetching_responses), stack),
          );
          setIsDetailsLoading(false);
        });
    }
  }

  /* Description: Handle all children component actions and store it */
  const UserRequestsCalls = {
    /* Description: handler for when the user goes to the next page or previous page 
    calls getUserRequests based on a specific offset. */
    handleSimplePaging: (action: SimplePaginationActionType) => {
      if (!!action) {
        setIsLoading(true);
        const new_page = action === SimplePaginationAction.next ? page + 1 : page - 1;
        const offset = new_page * GlobalConstants.REQUESTS_PER_PAGE_COUNT;
        setPage(new_page);
        getUserRequests(
          true,
          filter_status,
          false,
          GlobalConstants.REQUESTS_PER_PAGE_COUNT,
          offset
        );
      }
    },
    handleGetRequestResponses: async (params: string) => {
      await getRequestResponses(params);
    },
    /* Description: handler for sorting. This is for the history tab. It gets the direction first by checking if we're clicking on
    the same field or a different field, sorts the historyRequests and sets it. */
    handleSetSort: async (
      params: { tab: string; field: "mapped_action" | "requester_id" | "expiration" | "timestamp" },
    ) => {
      const { tab, field } = params;
      const _requests = tab === RequestTab.history ? history_requests : pending_requests;
      const direction =
        sort.field === field ? getOppositeSortDirection(sort.direction) : SORT_DIRECTION.ascending;
      const sorted_requests = sortBy(_requests, { field, direction });
      setSort({ field, direction });
      tab === RequestTab.history ? setHistoryRequests(sorted_requests) : setPendingRequests(sorted_requests);
    },
    /* Description: updates filter status and reloads user requests with updated filter. */
    handleUpdateFilterStatus: (params: ApprovalRequestStatusType) => {
      dispatch(settingsActions.resetPagination());
      setFilterStatus(params);
      setIsLoading(true);
      getUserRequests(true, params, false, GlobalConstants.REQUESTS_PER_PAGE_COUNT);
    },
    /* Description: handler for refresh - reloads depending on which tab the user is on. */
    handleRefresh: (tab: string) => {
      setIsLoading(true);
      if (tab === RequestTab.pending) {
        getUserRequests(false, "pending", true);
      } else {
        getUserRequests(
          true,
          filter_status,
          false,
          GlobalConstants.REQUESTS_PER_PAGE_COUNT,
          page * GlobalConstants.REQUESTS_PER_PAGE_COUNT,
        );
      }
    },
    /* Description: deletes a user request and removes from checkedList. */
    handleDelete: async (params: {
      approvals: UserRequest[];
      setCheckedList: React.Dispatch<React.SetStateAction<UserRequest[]>>;
    }) => {
      const total = params.approvals.length;
      let errors: number = 0;
      if (!!current_account) {
        const delete_responses = await Promise.all(
          params.approvals.map(async (approval) => {
            return await deleteUserRequest({
              userId: current_account.user_id,
              requestId: approval.request_id,
            })
              .unwrap()
              .then((response: DeleteUserRequestApiResponse) => {
                if (!!response.errors) {
                  errors++;
                }
                return !!response.errors ? null : approval;
              });
          }),
        );
        const successes = delete_responses.flatMap((response) => (!!response ? [response] : []));
        const success_ids = successes.map((s) => s.request_id);
        if (successes.length > 0) {
          setPendingRequests(
            pending_requests.filter(
              (approval) => !successes.find((response) => response.uid === approval.uid),
            ),
          );
        }
        params.setCheckedList(params.approvals.filter((r) => !success_ids.includes(r.request_id)));
        if (total === 1) {
          if (successes.length === 1) {
            dispatch(
              uiActions.handleSetMessage(new Message(SettingsSuccessMessages.request_deleted)),
            );
          } else {
            dispatch(
              uiActions.handleRequestErrors(
                new Message(SettingsErrorMessages.error_deleting_request),
              ),
            );
          }
          return;
        }
        if (successes.length === 0) {
          dispatch(uiActions.handleRequestErrors(new Message(`Error deleting ${total} requests`)));
        } else if (errors > 0) {
          dispatch(
            uiActions.handleRequestErrors(
              new Message(`Error deleting ${errors} of ${total} requests`),
            ),
          );
        } else {
          dispatch(
            uiActions.handleSetMessage(
              new Message(`Successfully deleted ${successes.length} requests`),
            ),
          );
        }
      }
    },
    handlePageErrorMessage: (params: {
      message: string;
      stack?: any;
      display_type?: MessageDisplayType;
    }) => {
      const display_type = !!params.display_type
        ? params.display_type
        : MessageHandlerDisplayType.logger;
      dispatch(
        uiActions.handleRequestErrors(new Message(params.message, display_type), params.stack),
      );
    },
  };

  function handlePageActions(actionObj: IActionHandler) {
    dispatch(uiActions.handleMessageDismiss());
    const callback = `handle${actionObj.actionType}`;
    // Handle local calls:
    if ((UserRequestsCalls as any)[callback] instanceof Function) {
      (UserRequestsCalls as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(
        MessageAnchors.actionType,
        actionObj.actionType,
      );
      UserRequestsCalls.handlePageErrorMessage({ message, stack: actionObj });
    }
  }

  return (
    <ApprovalRequestsParent
      header_navigate_list={header_navigate_list}
      handleAction={handlePageActions}
      is_approval={false}
      approval_requests={pending_requests}
      history_requests={history_requests}
      current_sort={sort}
      loading={is_loading}
      can_select={true}
      pagination={pagination}
      is_details_loading={is_details_loading}
      get_responses={true}
      current_recovery_group={current_recovery_group}
    ></ApprovalRequestsParent>
  );
}

export default React.memo(UserRequestsComponent);
