/*
*   File:           user_key.ts
*   Description:    Custom built User Key for PreVeil UI
*   Author:         PreVeil, LLC
*/
import {
  UserKey as UserKeyPB,
  PublicUserKey as PublicUserKeyPB
} from "../protos/keys_pb";
import { EncryptionKey, PublicKey } from "../encryption";
import { SigningKey, VerifyKey } from "../sign";

export class AppPublicUserKey {
  constructor(
    public protocol_version: number,
    public key_version: number,
    public public_key: PublicKey,
    public verify_key: VerifyKey
  ) { }

  get buffer(): PublicUserKeyPB {
    const pbInst = new PublicUserKeyPB();
    pbInst.setProtocolVersion(this.protocol_version);
    pbInst.setKeyVersion(this.key_version);
    pbInst.setPublicKey(this.public_key.public_buffer);
    pbInst.setVerifyKey(this.verify_key.public_buffer);
    return pbInst;
  }

  serialize(): Uint8Array {
    return this.buffer.serializeBinary();
  }
}

export class AppUserKey {
  public_user_key: AppPublicUserKey;
  constructor(
    public protocol_version: number,
    public key_version: number,
    public encryption_key: EncryptionKey,
    public signing_key: SigningKey
  ) {
    this.public_user_key = new AppPublicUserKey(
      protocol_version,
      key_version,
      encryption_key.clonePublic(),
      signing_key.cloneVerify()
    );
  }

  get buffer(): UserKeyPB {
    const pbInst = new UserKeyPB();
    pbInst.setProtocolVersion(this.protocol_version);
    pbInst.setKeyVersion(this.key_version);
    pbInst.setPrivateKey(this.encryption_key.buffer);
    pbInst.setSigningKey(this.signing_key.buffer);
    return pbInst;
  }

  serialize(): Uint8Array {
    return this.buffer.serializeBinary();
  }
}


export interface KSSUserKeys { // Previously KSSDATA
  protocol_version: number;
  user_key: AppUserKey;
  device_key: AppUserKey;
}
