import React from "react";
import { AdminUIActionTypes } from "src/common";
import { ActionHandlerFunction, SelectedEmailGroup } from "@preveil-api";
import { Icon, Loading } from "src/components";
import { Button, Offcanvas } from "react-bootstrap";
import { EmailGroupMembersTable } from "..";


type AllProps = {
  is_loading: boolean;
  show: boolean;
  handleAction: ActionHandlerFunction;
  selected_group?: SelectedEmailGroup;
  selected: string[];
}

function EmailGroupSidePanelComponent(props: AllProps) {
  const { is_loading, show, handleAction, selected_group, selected } = props;

  return <Offcanvas show={show} placement="end" backdrop={false} className="sidebar narrower-panel">
    {is_loading && (
      <div className="sidenav-group-loading">
        <Loading className="in-place" />
      </div>
    )}

    {!is_loading && selected.length === 1 && !!selected_group && (
      <>
        <Offcanvas.Header 
          closeButton
          onHide={() => handleAction({ actionType: AdminUIActionTypes.ShowSideNavMenu})}
        >
          <Offcanvas.Title className="groups-header" as="h2">
            {selected_group.alias}
            <Button
              variant="icon"
              onClick={() => handleAction({ actionType: AdminUIActionTypes.ShowModal, params: true })}
              data-tooltip-id="pv-tooltip"
              data-tooltip-content="Edit Group"
              data-tooltip-place="left">
              <Icon className="ficon-edit-2" />
            </Button>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <h3>Group Members</h3>
          {!!selected_group && <EmailGroupMembersTable members={selected_group?.members}></EmailGroupMembersTable>}
        </Offcanvas.Body>
      </>
    )}

    {!is_loading && selected.length > 1 && (
      <div className="multiselected-group-section">
        <Icon className="ficon-user" />
        <h3 className="text-muted">{selected.length} Groups Selected</h3>
        <button
          className="btn btn-no-outline-primary"
          onClick={() => handleAction({ actionType: AdminUIActionTypes.Reset })}
        >
          Deselect All
        </button>
      </div>
    )}
  </Offcanvas>;
}

export default React.memo(EmailGroupSidePanelComponent);
