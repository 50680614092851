/*
* Description: User class on the client side
*
*/
import { LocalUser, UserBase, UserIdentifierBase } from "@preveil-api";
import { AccountUserPublicKey, AccountUserKey } from "src/common";

export interface EmailUser { // Legacy MetadataUserV7
  user_id: string;
  key_version?: number;
  external_email?: string | null;
  public_user_key: AccountUserPublicKey | null;
}

export class User implements UserBase {
  display_name: string; // Note: legacy prop name: string;
  user_id: string; // Note: will ALWAYS BE LOWER CASE;
  external_email: string | null;
  mail_cid: string;
  protected _user_keys: AccountUserKey[] | [];
  protected _public_user_keys: AccountUserPublicKey[];
  constructor(
    user_base: LocalUser,
    user_keys: AccountUserKey[],
    public_user_keys: AccountUserPublicKey[]) {
    this.user_id = user_base.user_id.toLowerCase();
    this.display_name = user_base.display_name;
    this.external_email = user_base.external_email?.toLowerCase() || null;
    this.mail_cid = user_base.mail_cid;
    this._public_user_keys = public_user_keys.sort((a, b) => b.key_version - a.key_version);
    this._user_keys = user_keys.sort((a, b) => b.key_version - a.key_version);
  }

  // Description: Get display address for users if external address then use that first
  getDisplayEmail() {
    return !!this.external_email ? this.external_email : this.user_id;
  }

  // Desciption: retrieve user keys
  public get public_user_keys(): AccountUserPublicKey[] {
    return this._public_user_keys;
  }

  public get user_keys(): AccountUserKey[] {
    return this._user_keys;
  }

  public get user_key(): AccountUserKey {
    return this.user_keys[0];
  }

  // Desciption: Is user claimed
  public isClaimed(): boolean {
    return this._public_user_keys.length > 0;
  }

  // Desciption: Get active User PublicKey
  get public_user_key(): AccountUserPublicKey {
    if (this.isClaimed()) {
      return this._public_user_keys[0];
    } else {
      throw new Error("invalid access of public_key for unclaimed user");
    }
  }

  get user_identifier_base(): UserIdentifierBase {
    return {
      user_id: this.user_id,
      external_email: this.external_email,
      key_version: this.public_user_key.key_version || -1
    };
  }
}
