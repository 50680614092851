import * as React from "react";
import { isSameUser, useAppSelector, GlobalConstants } from "src/common";

type AllProps = {
  className?: string;
  email: string;
  display_name: string | null;
  external_email: string | null;
  members?: string[];
  abbreviated?: boolean;
}

function UserEmailComponent(props: AllProps) {
  const { email, display_name, external_email, members, className, abbreviated } = props;
  const current_user_id = useAppSelector((state) => state.account.current_account?.user_id);
  const _display = abbreviated ? `${!!display_name ? display_name : email}` :
    !!display_name && !isSameUser(display_name, email) ? `${display_name} <${email}>` : email;
  function getUserTooltip(): string {
    if (!!members) {
      return members
        .slice(0, Math.max(GlobalConstants.MAX_GROUP_MEMBERS_TOOLTIP, members.length))
        .join(", ");
    } else {
      return !!external_email ? `[External] ${external_email}` : email;
    }
  }
  // Description: Format external emails or self emails "Me"
  return <span
    className={`user-email${!!className ? ` ${className}` : ""}`}
    title={getUserTooltip()}>
    {
      !!external_email ? `[External] ${external_email}` :
        isSameUser(email, current_user_id) ? "Me" : _display
      // (!!display_name && !isSameUser(display_name, email) ? `${display_name} <${email}>` : email)
    }
  </span>;
}

export default React.memo(UserEmailComponent);